/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback, useMemo } from 'react';

import { createContext } from 'use-context-selector';
import { ICardsData, IFilterOptions, IGetFinancialPropertyAPI } from '../../components/FinancialFeedback/types';
import { getFinancialProperty } from '../../services/FinancialFeedBack/request';
import { useToast } from '../ToastContext';

interface IFinancialFeedbackContext {
  cardList: ICardsData[];
  setCardList: Function;
  date: Date;
  setDate: Function;
  loading: boolean;
  setLoading: Function;
  cardListAPI: IGetFinancialPropertyAPI;
  setCardListAPI: Function;
  filterOption: IFilterOptions;
  setFilterOption: (filter: IFilterOptions) => void;
}

interface IFinancialFeedbackProvider {
  children: React.ReactNode;
}

// eslint-disable-next-line max-len
export const financialFeedbackContext = createContext<IFinancialFeedbackContext>({} as IFinancialFeedbackContext);

export const FinancialFeedbackProvider = ({
  children,
}: IFinancialFeedbackProvider) => {
  const toast = useToast();
  const isFirstRender = React.useRef(true);
  const isClearTimeOut = React.useRef(false);

  const [cardList, setCardList] = useState<ICardsData[]>([] as ICardsData[]);

  const [filterOption, setFilterOption] = useState<IFilterOptions>('all');

  const [
    cardListAPI,
    setCardListAPI,
  ] = useState<IGetFinancialPropertyAPI>({} as IGetFinancialPropertyAPI);

  const [date, setDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(true);

  const handleFilterOption = useCallback((option: IFilterOptions) => {
    setFilterOption(option);
  }, []);

  const handleCardListAPI = useCallback((newCardListAPI: IGetFinancialPropertyAPI) => {
    setCardListAPI(newCardListAPI);
  }, []);

  const handleCardList = useCallback((newCardList: ICardsData[]) => {
    setCardList(newCardList);
  }, []);

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleDate = useCallback((newDate: Date) => {
    setDate(newDate);
  }, []);

  React.useEffect(() => {
    let timeLoad: any;

    const finishLoadWithTime = () => {
      if (!isClearTimeOut.current) {
        timeLoad = setTimeout(() => {
          setLoading(false);
          isClearTimeOut.current = true;
        }, 1500);
      } else {
        setLoading(false);
      }
    };

    const handleGetFinancialProperty = async () => {
      try {
        setLoading(true);
        const { dataFormatted, dataAPI } = await getFinancialProperty(date);
        handleCardList(dataFormatted);
        handleCardListAPI(dataAPI);
        finishLoadWithTime();
      } catch (e) {
        finishLoadWithTime();
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    };

    handleGetFinancialProperty();

    if (isClearTimeOut.current) {
      window.clearTimeout(timeLoad);
      isClearTimeOut.current = false;
    }

    return () => {
      window.clearTimeout(timeLoad);
    };
  }, [date]);

  const value = useMemo(() => ({
    cardList,
    setCardList: handleCardList,
    date,
    setDate: handleDate,
    loading,
    setLoading: handleLoading,
    cardListAPI,
    setCardListAPI: handleCardListAPI,
    filterOption,
    setFilterOption: handleFilterOption,
  }),
  [
    cardList,
    handleCardList,
    date,
    handleDate,
    loading,
    handleLoading,
    cardListAPI,
    handleCardListAPI,
    filterOption,
    handleFilterOption,
  ]);

  return (
    <financialFeedbackContext.Provider value={value}>
      {children}
    </financialFeedbackContext.Provider>
  );
};
