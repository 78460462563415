export default function ArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <path
        d="M9.55795 13.4827L3.30795 7.54516C3.24988 7.49 3.20382 7.42451 3.17239 7.35243C3.14097 7.28035 3.12479 7.2031 3.12479 7.12509C3.12479 7.04707 3.14097 6.96982 3.17239 6.89774C3.20382 6.82566 3.24988 6.76017 3.30795 6.70501C3.36602 6.64984 3.43496 6.60608 3.51083 6.57623C3.5867 6.54637 3.66802 6.53101 3.75014 6.53101C3.83226 6.53101 3.91358 6.54637 3.98945 6.57623C4.06532 6.60608 4.13426 6.64984 4.19233 6.70501L10.0001 12.2232L15.808 6.70501C15.9252 6.5936 16.0843 6.53101 16.2501 6.53101C16.416 6.53101 16.5751 6.5936 16.6923 6.70501C16.8096 6.81642 16.8755 6.96753 16.8755 7.12509C16.8755 7.28265 16.8096 7.43375 16.6923 7.54516L10.4423 13.4827C10.3843 13.5379 10.3154 13.5817 10.2395 13.6115C10.1636 13.6414 10.0823 13.6568 10.0001 13.6568C9.91801 13.6568 9.83668 13.6414 9.7608 13.6115C9.68493 13.5817 9.616 13.5379 9.55795 13.4827Z"
        fill="#0D4BD0"
      />
    </svg>
  );
}
