import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.99);

  button{
    border: none;
    background: transparent;
  }

  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;

  transition: all 0.4s;

  bottom: 0;
  width: 100%;
  z-index: 120;
  position: fixed;
  z-index: 10000;
  
  svg{
    user-select: none;
  }

  a{
    text-decoration: none;
  }
`;
