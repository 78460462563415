import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../hooks/useMobile/useMobile';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled(motion.form)`
  gap: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 30px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    padding: 30px 70px;
  }

  h1,
  p {
    display: flex;
    font-style: normal;
    align-items: center;
    font-family: "Inter";
  }

  &&&&& {
    input,
    select,
    textarea {
      border-radius: 10px;
      border: 1px solid var(--neuter-300, #d9d9d9);
      background: var(--neuter-50, #f9f9f9c7);

      color: var(--gray-800, #394760);
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &::placeholder {
        color: var(--neuter-500, #969696);
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &:disabled {
        color: var(--neuter-500, #969696);
      }
    }
  }
`;

export const Header = styled(motion.div)`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div` 
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`;

export const Title = styled.h1`
  color: var(--darkk-blue-900, #001840);
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
`;

export const SectionPadding = styled.div`
  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    padding-left: 30px;
  }
`;

export const FormContainer = styled(motion.div)`
  gap: 15px;
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput-root {
      fieldset {
        margin-top: 0px !important;
        border: none !important;
      }
  };

  input,
  textarea {
    border: 1px solid #bec6cc;
    padding: 8px 15px;
    background: transparent;
    border-radius: 5px;
    width: 100%;
  };

  .label {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    padding-bottom: 8px;
  }
`;

export const PropertyForm = styled(motion.div)<{
  customGap?: string;
  isColumn?: boolean;
}>`
  gap: ${({ customGap }) => customGap || '20px'};
  display: flex;
  width: 100%;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    gap: 15px;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
  }
`;

export const RowContent = styled(motion.div)<{
  isColumn?: boolean;
}>`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  align-items: center;
`;

export const PropertyFormTitle = styled.h1`
  color: #394760;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  strong {
    color: ${({ theme }) => theme.palette.red._300.hex()};
    font-size: 1rem;
  }
`;

export const PropertyFormInput = styled(motion.input)``;
export const PropertyFormInputLabel = styled(motion.p)`
  gap: 10px;
  display: flex;
  font-size: 1rem;
  align-items: center;

  color: var(--gray-800, #394760);
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const ObservationInput = styled(motion.textarea)`
  resize: none;
  &::-webkit-scrollbar {
    width: 3px;
  }
`;
export const FooterContainer = styled(motion.div)`
  gap: 15px;
  display: flex;
  flex-direction: column;
`;

export const AddButton = styled(motion.button)`
  width: 100%;
  display: flex;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: transparent;
  justify-content: center;
  border: 2px dashed #bec6cc;
  h1 {
    color: #0d4bd0;
    font-size: 1rem;
  }
`;

export const SubmitButton = styled(motion.button)`
  border: 0;
  width: 100%;
  display: flex;
  border-radius: 5px;
  padding: 15px 20px;
  background: #0d4bd0;
  justify-content: center;
  &:disabled {
    background: #bec6cc;
  }
  h1 {
    color: white;
    font-weight: 600;
  }
`;

export const Error = styled.p`
  opacity: 0;
  animation: ${showItems} 0.4s 0.1s ease forwards;

  && {
    font-weight: 700;
    font-size: 9pt;
    color: ${({ theme }) => theme.palette.red._350.hex()};
  }
`;

export const ErrorWrapper = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const DividerSidesContainer = styled.div<{
  isColumn?: boolean,
}>`
  gap: 60px;
  display: grid;
  flex-direction: ${({ isColumn }) => (isColumn ? '1fr' : '1fr 1.5fr')};
`;

export const SingleSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Spacing = styled.div`
  margin: 1.5rem 0;
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  &.box-allow-barter {
    max-height: 80px;
    border: 1px solid ${({ theme }) => theme.palette.grey._410.hex()};
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }

`;

export const SelectContainer = styled.div`
  width: 100%;

  .MuiOutlinedInput-root {
    max-height: 100% !important;
  }

  .MuiNativeSelect-select {
    padding: 0.8rem 0.5rem !important;
  }
`;
