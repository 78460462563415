import React, { FC } from 'react';

import { OutlinedRedButton } from './styles';

import {
  Color,
  ButtonProps,
  ColorsEnum,
  ButtonTypeProps,
  ButtonTypeEnum,
} from './types';

export interface FormButtonProps extends ButtonProps {
  link?: boolean;
  dataCy?: string;
  disable?: boolean;
  invisible?: boolean;
  customColor?: Color;
  type?: ButtonTypeProps;
  customClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const FormButtonOwner: FC<FormButtonProps> = ({
  link,
  type,
  dataCy,
  disable,
  variant,
  onClick,
  children,
  invisible,
  customColor,
  customClassName,
}) => (
  <OutlinedRedButton
    type={type}
    data-cy={dataCy}
    variant={variant}
    onClick={onClick}
    disabled={disable}
    linkto={link ? 1 : 0}
    invisible={invisible}
    customcolorbg={customColor}
    className={variant ? `${variant}` : `grey ${customClassName}`}
  >
    {children}
  </OutlinedRedButton>
);

FormButtonOwner.defaultProps = {
  dataCy: '',
  link: false,
  invisible: false,
  onClick: () => {},
  customClassName: '',
  type: ButtonTypeEnum.SUBMIT,
  customColor: ColorsEnum.BLUE,
};

export default FormButtonOwner;
