import React from 'react';

import { CircularProgress } from '@mui/material';
import {
  StyledImage,
  Content,
  Container,
  AddImageButtonContainer,
  ViewMoreImagesButton,
} from './style';

import { CameraIcon } from './icon/Camera';
import { FileIcon } from './icon/FileIcon';

import { useUpload } from '../../../../../../../../hooks/useUpload';
import useSingleUpload from '../../../../../../../../hooks/useUpload/useSingleUpload';
import { ACTUAL_WINDOW_WIDTH } from '../../../../../../../../hooks/useMobile/useMobile';

import { FileProps } from '../../../../../../../../context/FileContext/types';
import { FileTypes, usePartnerIndicate } from '../../../../../../../../context/Partners/IndicateContext/IndicateContext';

import { PropertyFormInputLabel } from '../../style';
import { PDF_PREVIEW } from './icon/previewPDF';

interface ImageProps {
  previewUrl: string;
  previewContent?: {
    showQuantity: boolean;
    quantity: number;
  };
  uploadType?: FileTypes,
}

const Image = ({ previewUrl, previewContent, uploadType = 'photos' }: ImageProps) => {
  const { setOpenModalWithFiles } = usePartnerIndicate();

  const handleOpenModalWithFiles = () => {
    setOpenModalWithFiles({
      is: true,
      uploadType,
    });
  };

  return (
    <StyledImage previewUrl={previewUrl} onClick={() => handleOpenModalWithFiles()}>
      {previewContent?.showQuantity && (
        <ViewMoreImagesButton>
          <p>{previewContent?.quantity}</p>
        </ViewMoreImagesButton>
      )}
    </StyledImage>
  );
};

Image.defaultProps = {
  previewContent: {
    showQuantity: false,
    quantity: 0,
  },
  uploadType: 'photos',
};

interface IAddImageButton {
  accept: string[],
  uploadType: FileTypes,
}

const AddImageButton = ({ accept, uploadType = 'photos' }: IAddImageButton) => {
  const { setPropertyImages, setPropertyDocuments } = usePartnerIndicate();

  const onSuccess = (file?: FileProps | undefined) => {
    if (file) {
      if (uploadType === 'photos') {
        setPropertyImages((old) => [...old, file]);
      } else if (uploadType === 'documents') {
        setPropertyDocuments((old) => [...old, file]);
      }
    }
  };

  const { file, onUpload, loading } = useSingleUpload({
    callbacks: { success: onSuccess },
    sendToS3: `${process.env.NODE_ENV}` !== 'development',
  });

  const { open, getRootProps, getInputProps } = useUpload({
    file,
    setFile: onUpload,
    accept,
  });

  return (
    <AddImageButtonContainer
      onClick={open}
      {...getRootProps()}
      whileTap={{ scale: 0.9 }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {uploadType === 'photos' ? <CameraIcon /> : <FileIcon />}
          <p>Enviar</p>
          <input id={file.id} {...getInputProps()} type="file" />
        </>
      )}
    </AddImageButtonContainer>
  );
};

interface IImageList {
  uploadType: FileTypes,
}

const ImageList = ({ uploadType = 'photos' }: IImageList) => {
  const { propertyImages, propertyDocuments } = usePartnerIndicate();

  if (uploadType === 'documents') {
    const limitByScreenSize = React.useMemo(() => {
      if (ACTUAL_WINDOW_WIDTH <= 330) {
        return propertyDocuments.length > 0 ? [propertyDocuments.shift()] : [];
      }

      return propertyDocuments.slice(0, 2);
    }, [propertyDocuments]);

    return (
      <>
        {limitByScreenSize?.map((image, index) => (
          <Image
            key={image?.id}
            previewUrl={PDF_PREVIEW}
            previewContent={{
              showQuantity: index === limitByScreenSize?.length - 1
                && propertyDocuments?.length > 1,
              quantity: propertyDocuments?.length,
            }}
            uploadType={uploadType}
          />
        ))}
      </>
    );
  }

  const limitByScreenSize = React.useMemo(() => {
    if (ACTUAL_WINDOW_WIDTH <= 330) {
      return propertyImages.length > 0 ? [propertyImages.shift()] : [];
    }

    return propertyImages.slice(0, 2);
  }, [propertyImages]);

  return (
    <>
      {limitByScreenSize?.map((image, index) => (
        <Image
          key={image?.id}
          previewUrl={image?.previewURL || ''}
          previewContent={{
            showQuantity: index === limitByScreenSize?.length - 1
              && propertyImages?.length > 1,
            quantity: propertyImages?.length,
          }}
          uploadType={uploadType}
        />
      ))}
    </>
  );
};

interface IImageUpload {
  label?: string,
  uploadType?: FileTypes,
  accept?: string[],
}

const ImagesUpload = ({ label, uploadType = 'photos', accept = ['image/*'] }: IImageUpload) => (
  <Container>
    {label && (
      <PropertyFormInputLabel>
        {label}
      </PropertyFormInputLabel>
    )}

    <Content>
      <AddImageButton accept={accept} uploadType={uploadType} />
      <ImageList uploadType={uploadType} />
    </Content>
  </Container>
);

ImagesUpload.defaultProps = {
  label: undefined,
  uploadType: 'photos',
  accept: ['image/*'],
};

export default ImagesUpload;
