import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import {
  Image,
  Container,
  ImageContent,
  TextContainer,
  ImageContainer,
  ImageBackground,
} from './style';

import { ICard } from './types';
import ShareButton from './ShareButton/ShareButton';
import AccessButton from './AccessButton/AccessButton';
import { ImageShimmer } from '../../../../../../../../../assets/animations/components/ImageShimmer';

interface ImageProps {
  image: ICard['image'];
}

const ImageComponent = ({ image }: ImageProps) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <AnimatePresence>
      {loading && (
        <ImageBackground key={`loading_image-${image.alt}`}>
          <ImageShimmer />
        </ImageBackground>
      )}

      <motion.img
        loading="eager"
        key={`image_loaded-${image.alt}`}
        onError={() => setLoading(true)}
        onLoad={() => setLoading(false)}
        src={image.source}
        alt={image.alt}
        style={{ backgroundPosition: 'left' }}
      />
    </AnimatePresence>
  );
};

const Card = ({
  title,
  image,
  description,
  withShareButton,
  access,
}: ICard) => (
  <Container withShareButton={withShareButton?.is}>
    <TextContainer>
      <h1>{title}</h1>
      <p>{description}</p>
    </TextContainer>

    <ImageContainer>
      <ImageContent>
        <Image>
          <ImageComponent image={image} />
        </Image>

        <AccessButton title={title} access={access} />
      </ImageContent>
    </ImageContainer>

    <div />

    {withShareButton?.is && (
      <ShareButton title={title} withShareButton={withShareButton} />
    )}
  </Container>
);

Card.defaultProps = {
  withShareButton: false,
};

export default Card;
