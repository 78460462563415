import React, { useEffect } from 'react';
import { Container } from './styles';
import OwnerClose from '../../../components/FinancialClose/OwnerClose';
import { FinancialCloseProvider } from '../../../context/FinancialClosePage/FinancialCloseContext/FinancialCloseContext';
import { FinancialCloseOwnerProvider } from '../../../context/FinancialClosePage/FinancialCloseOwnerContext/FinancialCloseOwnerContext';

const OwnerClosePage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Fechamento';
  }, []);

  return (
    <FinancialCloseProvider>
      <FinancialCloseOwnerProvider>
        <Container>
          <OwnerClose />
        </Container>
      </FinancialCloseOwnerProvider>
    </FinancialCloseProvider>
  );
};
export default OwnerClosePage;
