import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 100px;
  position: fixed;
  z-index: 999;
  right: 0.8rem;
  bottom: 0.8rem;

   div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media(max-width: 900px) {
    bottom: 10px;
  }
`;
