import styled from 'styled-components';

export const Wrapper = styled.div<{
  openModal: boolean;
}>`
  position: fixed;
  z-index: 999;
  left: 0;
  display: ${({ openModal }) => (openModal ? 'flex' : 'none')};
  justify-content: flex-end;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  align-items: center;
  justify-content: center;

  transition: 0.2s;
`;

export const Container = styled.form`
  position: absolute;
  z-index: 999;

  display: flex;
  flex-direction: column;

  width: 600px;
  height: 85%;
  border-radius: 10px;
  padding: 10px 40px;
  background-color: white;

  font-family: ${({ theme }) => theme.fonts.familys._6};

  @media(max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;

export const ContentInputs = styled.div`
  position: relative;
  overflow-y: scroll;
  width: 100%;

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 395px) {
      flex-direction: column;
      width: 100%;
      &.type-person {
        align-items: flex-start;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

`;

export const RowInput = styled.section`
  margin-bottom: 10px;
  margin-top: 10px;

  &.selector {
    border: 1px solid ${({ theme }) => theme.palette.grey._445.hex()};
    border-radius: 10px;
    padding: 5px 5px;

  }
    @media(max-width: 395px) {
      width: 100%;
      &:first-child {
        margin-bottom: 20px;
      }
    }

    .column {
    label {
      margin: 0;
      font-weight: bold !important;
    }

    @media(max-width: 395px) {
      width: 100%;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
  
  section {
    width: 900px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      position: unset !important;
      height: 40px !important;
      background: transparent !important;
    }
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  label {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

`;

export const ButtonSave = styled.button`
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};

  width: 100%;
  height: 40px;

  border-radius: 10px;
  border: none;

  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;

  :hover {
    filter: brightness(0.9);
  }
`;

export const RowButtons = styled.div`
  width: 100%;
  height: 100px;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.blue._910.hex()};
  line-height: 125%;
  width: 100%;
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
