import styled from 'styled-components';
import { genericBorderRoundedMinWidth } from '../../utils/var.utils';

export const Container = styled.div``;

export const GenericBorderRoundedContainer = styled.div`
  gap: 10px;
  display: flex;
  padding: 10px 25px;
  align-items: center;
  flex-direction: row;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0;
  justify-content: space-around;
  border-top: 2px solid #d9dcdf;
  border-left: 2px solid #d9dcdf;
  border-right: 2px solid #d9dcdf;
  width: 100%;
  min-width: ${genericBorderRoundedMinWidth};

  button {
    background: transparent;
    border: none;
  }
`;

export const RightSide = styled.div`
  gap: 10px;
  cursor: pointer;
  padding-left: 15px;
`;

export const LeftSide = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: #283143;
  font-size: 1.1rem;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.familys._6};
`;
