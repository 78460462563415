import { Container } from './styles';

import Backdrop from '../../../Backdrop';
import Steppers from '../../../Steppers';

interface ILoadingStepper {
  progress: number;
  stepperOptions?: string[];
}

const LoadingStepper = ({
  progress,
  stepperOptions,
}: ILoadingStepper) => (
  <Backdrop onClose={() => {}}>
    <Container>
      <Steppers progress={progress} stepperOptions={stepperOptions} />
    </Container>
  </Backdrop>
);

LoadingStepper.defaultProps = {
  stepperOptions: [
    'Estamos processando seu pedido',
    'Preparando CSV',
    'CSV Baixado',
  ],
};

export default LoadingStepper;
