import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end
`;

export const Content = styled.div`
  gap: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  h2{
    font-weight: 500;
    font-family: "Inter";
  }

  h3{
    font-weight: 400;
    font-family: "Inter";
  }
`;

export const Section = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
`;
