import styled, { css } from 'styled-components';

export const Container = styled.div<{
  loading?: string;
}>`
  display: flex;
  flex-direction: column;

  #linear-progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    visibility: hidden;
    transition: 0.5s ease all;
  }

  ${({ loading }) => loading === 'true' && css`
    pointer-events: none;

    #linear-progress {
      visibility: visible;
      width: 100%;
    }
  `}
`;
