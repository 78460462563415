import React, { useEffect, useMemo } from 'react';
import { Grid, Divider } from '@mui/material';

import { UseFormik } from '../../../../../../../utils/Formik/types';
import { IFormValues } from '../../types';

import { typeOptions } from '../../utils';
import { compareList } from '../../../../../../../utils/Sorting';
import data from '../lib/Questions/utils/data';
import { IValidateList, validateErrorMessage } from '../../utils/validateErrorMessage';
import { analyticsEvent } from '../../../../../utils/analytics';

import { getB2BCondominium } from '../../../../../../../services/Partner';
import { RequestB2BCondominium } from '../../../../../../../services/Partner/types';
import { getAddressById } from '../../../../../../../services/Address/request';
import { AddressDetail } from '../../../../../../../services/Address/types';

import { useUser } from '../../../../../../../context/UserContext';
import { useMobile } from '../../../../../../../hooks/useMobile/useMobile';
import { usePartners } from '../../../../../../../context/Partners/Partner/PartnerContext';
import { useIBGE } from '../../../../../../../hooks/useIBGE/useIBGE';

import { SelectOption } from '../../../../../../../components/SimpleSelect/SimpleSelect';
import SimpleSelect from '../../../../../../../components/SimpleSelect';
import TextField from '../../../../../../../components/TextField/TextField';
import FormGridRow from '../../../../../../../components/FormGridRow/FormGridRow';
import Phone from '../lib/Phone/Phone';
import Quest from '../lib/Questions/Quest';

import {
  Address,
  UserTerms,
  StateSelector,
  Questions,
  ImagesUpload,
} from '../lib';

import {
  Error,
  PropertyForm,
  ErrorWrapper,
  SubmitButton,
  FormContainer,
  FooterContainer,
  PropertyFormTitle,
  Spacing,
  SelectContainer,
  RowContent,
} from '../style';

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 10 },
};

interface ILocationIndicationForm {
  formik: UseFormik<IFormValues>;
}

const LocationIndicationForm = ({ formik }: ILocationIndicationForm) => {
  const { isMobile } = useMobile();
  const { userInformation } = useUser();
  const { questions } = usePartners();
  const { states } = useIBGE({});
  const { stateMappings } = states;

  const [checkbox, setCheckbox] = React.useState(false);
  const [B2BOptions, setB2BOptions] = React.useState<RequestB2BCondominium[]>([]);
  const [foundAddress, setFoundAddress] = React.useState<AddressDetail>({} as AddressDetail);

  const stateWasSelected = (state: string) => !['null', 'undefined', ''].includes(state);

  const getB2BOptionsFilteredsByState = (state: string, allOptions: SelectOption[]) => {
    const normalizedState = stateMappings[state.toLowerCase()];

    if (!normalizedState) return allOptions;

    const B2BOptionsFilteredByState = B2BOptions.filter((option) => normalizedState.includes(`${option.condominium_state}`.toLowerCase()));
    return B2BOptionsFilteredByState.map<SelectOption>(({
      id,
      condominium_name,
    }) => ({ value: id, valueLabel: condominium_name }));
  };

  const selectOptions = useMemo(() => {
    const allOptions = B2BOptions.map<SelectOption>(({ id, condominium_name }) => ({
      value: id,
      valueLabel: condominium_name,
    }));

    const state = `${formik.values?.state}`;
    if (questions?.IsIndicatedWithinSeazoneOperationArea && stateWasSelected(state)) {
      const optionsFilteredsByState = getB2BOptionsFilteredsByState(state, allOptions);
      return optionsFilteredsByState;
    }

    return allOptions;
  }, [B2BOptions, questions?.IsIndicatedWithinSeazoneOperationArea, formik.values?.state]);

  const handleCheckbox = (e: boolean) => {
    setCheckbox(e);
    analyticsEvent({
      userInformation,
      name: 'Aceitou os termos de indicação estando fora do modal de leitura',
    });
  };

  const validateForm = () => {
    const noError = Object.keys(formik.errors).length === 0;
    if (checkbox && noError) {
      return false;
    }
    return true;
  };

  const validateErrorMsgLocal = (obj: IValidateList) => validateErrorMessage({
    checkbox,
    formik,
    obj,
  });

  useEffect(() => {
    async function handleGetB2BCondominium() {
      const response = await getB2BCondominium();
      setB2BOptions(response.sort((a, b) => compareList(a.condominium_name!, b.condominium_name!)));
    }
    handleGetB2BCondominium();
  }, []);

  useEffect(() => {
    async function handleGetAddress(id: number) {
      const foundCondominium = B2BOptions.find((option) => option.id === id);
      if (foundCondominium) {
        const response = await getAddressById(foundCondominium.address);
        setFoundAddress(response);
        formik.setFieldValue('b2bCondominium', foundCondominium.condominium_name);
      }
    }
    if (formik.values.b2bCondominiumID) {
      handleGetAddress(Number(formik.values.b2bCondominiumID));
    }
  }, [formik.values.b2bCondominiumID]);

  useEffect(() => {
    if (foundAddress) {
      formik.setFieldValue('street', foundAddress.street);
      formik.setFieldValue('number', foundAddress.number);
      formik.setFieldValue('neighborhood', foundAddress.neighborhood);
      formik.setFieldValue('complement', foundAddress.complement);
    }
  }, [foundAddress]);

  return (
    <>
      <FormContainer
        translate="yes"
        transition={{ duration: 0.5 }}
        variants={item}
      >
        <Divider />
        <PropertyFormTitle>Dados do contato</PropertyFormTitle>

        <FormGridRow grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="name"
              dataCy="input-name"
              formik={formik}
              label="Nome"
              placeholder="Ex: Paulo Machado"
              required
              requireSymbolPosition="right"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Phone
              label="Telefone"
              formik={formik}
              required
            />
          </Grid>
        </FormGridRow>
      </FormContainer>

      <Divider />

      <PropertyFormTitle>Dados do imóvel</PropertyFormTitle>

      <FormGridRow grid>
        <Grid item sm={6} xs={12}>
          <PropertyForm>
            <Quest
              key={data?.[0]?.id}
              title={data?.[0]?.title}
              option={data?.[0]?.option}
              tooltip={data?.[0]?.tooltip}
            />

            <ErrorWrapper>
              <StateSelector
                formik={formik}
                labelState="Localização do Imóvel"
                labelCity="Cidade"
                required
                requireSymbolPosition="right"
              />
              {validateErrorMsgLocal('state/city') && (
                <Error>{formik.errors.state}</Error>
              )}
            </ErrorWrapper>
          </PropertyForm>

          <Spacing />
          <SelectContainer>
            <SimpleSelect
              id="b2bCondominiumID"
              formik={formik}
              placeholder="Selecione"
              label="Seu imóvel está localizado em algum desses empreendimentos?"
              options={[{ value: -1, valueLabel: 'Não' }, ...selectOptions]}
              required
              requireSymbolPosition="right"
            />
          </SelectContainer>
          <Spacing />

          {!isMobile && (
            <>
              <Spacing />
              <Address formik={formik} />
              <Spacing />
              <RowContent isColumn={isMobile}>
                <ImagesUpload
                  label="Fotos do imóvel"
                  accept={['image/*']}
                  uploadType="photos"
                />
              </RowContent>
            </>
          )}
        </Grid>

        <Grid item sm={6} xs={12}>
          <SelectContainer>
            <SimpleSelect
              id="propertyType"
              formik={formik}
              dataCy="select-property-type"
              placeholder="Selecione"
              label="Tipo de propriedade"
              options={typeOptions}
              required
              requireSymbolPosition="right"
            />
          </SelectContainer>

          {isMobile && (
            <>
              <Spacing />
              <Address formik={formik} />
            </>
          )}

          <TextField
            formik={formik}
            id="comment"
            dataCy="input-comment"
            label="Observação"
            placeholder="Digite aqui..."
            type="textarea"
            minRows={isMobile ? 1 : 2.45}
          />

          {isMobile && (
            <>
              <Spacing />
              <RowContent isColumn={isMobile}>
                <ImagesUpload
                  label="Fotos do imóvel"
                  accept={['image/*']}
                  uploadType="photos"
                />
              </RowContent>
            </>
          )}
        </Grid>
      </FormGridRow>

      <Divider />

      <Questions />

      <FooterContainer variants={item}>
        <UserTerms checkbox={checkbox} setCheckbox={handleCheckbox} />
        <SubmitButton
          disabled={validateForm()}
          type="submit"
          whileTap={{ scale: 0.9 }}
        >
          <h1>Enviar</h1>
        </SubmitButton>
      </FooterContainer>
    </>
  );
};

export default LocationIndicationForm;
