import moment from 'moment';
import { ChangePropertyHostProps } from '../../context/ChangePropertyHostContext/types';
import { checkEmptyString, getStringFormatted } from '../../utils/Formatter';
import { GetPropertyChangeHostProps } from './types';

const MAX_LENGHT_USERNAME = 40;
const MAX_DIGITS_SUBSTRING = 20;

export const formatPropertyChangeHostData = (data: GetPropertyChangeHostProps[]) => {
  const dataFormatted: ChangePropertyHostProps[] = data.map((item, index) => {
    const oldHostUser = item?.old_host?.user;
    const oldHostNamePF = `${oldHostUser?.first_name || ''} ${oldHostUser?.last_name || ''}`;

    const oldHostName = getStringFormatted(
      checkEmptyString(oldHostNamePF), MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
    ).trim();

    const newHostUser = item?.new_host?.user;
    const newHostNamePF = `${newHostUser?.first_name || ''} ${newHostUser?.last_name || ''}`;

    const newHostName = getStringFormatted(
      checkEmptyString(newHostNamePF), MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
    ).trim();

    return {
      id: item?.id || index + 1,
      property: {
        id: item?.property?.id || -1,
        code: item?.property?.code || '',
      },
      old_host: {
        id: item?.old_host?.id || -1,
        first_name: item?.old_host?.user?.first_name || '',
        last_name: item?.old_host?.user?.last_name || '',
        trading_name: item?.old_host?.user?.trading_name || '',
        is_individual: item?.old_host?.user?.is_individual || true,
        full_name: oldHostName,
      },
      new_host: {
        id: item?.new_host?.id || -1,
        first_name: item?.new_host?.user?.first_name || '',
        last_name: item?.new_host?.user?.last_name || '',
        trading_name: item?.new_host?.user?.trading_name || '',
        is_individual: item?.new_host?.user?.is_individual || true,
        full_name: newHostName,
      },
      replacement_date: item?.replacement_date ? moment(item.replacement_date).format('DD/MM/YYYY') : '',
      updated_at: item?.updated_at ? moment(item.updated_at).format('DD/MM/YYYY') : '',
      created_at: item?.created_at ? moment(item.created_at).format('DD/MM/YYYY') : '',
      status: item?.status ?? '',
    };
  });

  return dataFormatted;
};
