import { useContextSelector } from 'use-context-selector';
import { RerservatioFormContext } from '../../context/ReservationFormContext';

export function useReservationForm() {
  const resetSelection = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.resetSelection);

  const handleResetSelection = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.handleResetSelection);

  const resetDataGrouped = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.resetDataGrouped);

  const handleResetDataGrouped = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.handleResetDataGrouped);

  const data = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.data);

  const setData = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.setData);

  const dataGrouped = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.dataGrouped);

  const setDataGrouped = useContextSelector(RerservatioFormContext,
    (reservation) => reservation.setDataGrouped);

  return {
    resetSelection,
    handleResetSelection,
    resetDataGrouped,
    handleResetDataGrouped,
    data,
    setData,
    dataGrouped,
    setDataGrouped,
  };
}
