import { StepOne, StepTwo } from '../Content/Steps';

export const choiseStep = [{
  step: 1,
  title: 'Resgate de saldos',
  description: (
    <p>
      {' '}
      Preencha para solicitar o pagamento das suas comissões da Seazone
      conforme a sua planilha de acompanhamento. Pedidos realizados até o dia
      10 serão pagos no dia 15 do mesmo mês. Pedidos realizados após o dia 10
      serão pagos até o dia 15 do mês subsequente. Enviando previamente o
      recibo ou NF para
      <a href={'mailto:administrativo@seazone.com.br'}>
        administrativo@seazone.com.br
      </a>
      , o pagamento ocorrerá mais rapidamente.
    </p>
  ),
  component: <StepOne />,
}, {
  step: 2,
  title: 'Dados bancários',
  description: (
    <></>
  ),
  component: <StepTwo />,
},
];
