import React from 'react';

import { usePartnersEarning } from '../../../../../../context/Partners/Earning/Earning';

const Content = () => {
  const { filterChoiseStep } = usePartnersEarning();

  return filterChoiseStep?.component || <p>não encontrei</p>;
};

export default Content;
