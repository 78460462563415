import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useExpense } from '../../../hooks/ExpenseHook/useExpense';

export default function LoadMoreData() {
  const { ref, inView } = useInView();

  const {
    expensesPage,
    filterParams,
    fetchExpenseData,
  } = useExpense();

  React.useEffect(() => {
    if (inView) {
      if (expensesPage !== null) {
        if (filterParams) {
          fetchExpenseData(expensesPage, filterParams);
        } else {
          fetchExpenseData(expensesPage);
        }
      }
    }
  }, [inView]);

  return (
    <div ref={ref} />
  );
}
