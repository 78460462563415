import { Dispatch, SetStateAction } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  CircularButton, Container, ContainerLabel, Input, NumberInputContainer, StarSymbol,
} from './styles';

export interface NumberInputProps {
  maxValue?: number;
  minValue?: number;
  value: number;
  onValueChange: Dispatch<SetStateAction<number>>;
  id: string;
  label?: string;
  labelClassName?: string;
  required?: boolean;
}

export function NumberInput({
  id,
  maxValue = 25,
  minValue = 1,
  value,
  onValueChange,
  label,
  labelClassName,
  required = false,
  ...rest
}: NumberInputProps) {
  const isMaxValue = value === maxValue;
  const isMinValue = value === minValue;

  function incresseValue() {
    if (value < maxValue) {
      onValueChange((oldState) => oldState + 1);
    }
  }
  function decreesValue() {
    if (value > minValue) {
      onValueChange((oldState) => oldState - 1);
    }
  }

  return (
    <Container>
      <ContainerLabel>
        {required && <StarSymbol>*</StarSymbol>}
        <label className={labelClassName} htmlFor={label}>{label}</label>
      </ContainerLabel>
      <NumberInputContainer>
        <CircularButton
          type="button"
          onClick={decreesValue}
          disabled={isMinValue}
        >
          <RemoveCircleOutlineIcon />
        </CircularButton>
        <Input
          type="number"
          id={id}
          css={{
            textAlign: 'center',
          }}
          value={value}
          onChange={(e) => {
            if (Number(e.target.value) >= maxValue) {
              return onValueChange(maxValue);
            }
            if (Number(e.target.value) <= minValue) {
              return onValueChange(minValue);
            }
            return onValueChange(Number(e.target.value));
          }}
          {...rest}
        />
        <CircularButton
          type="button"
          onClick={incresseValue}
          disabled={isMaxValue}
        >
          <AddCircleOutlineIcon height={5} />
        </CircularButton>
      </NumberInputContainer>
    </Container>
  );
}
