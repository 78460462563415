import styled from 'styled-components';

export const Container = styled.div`
`;

export const StyledTextField = styled.div`
  position: relative;

  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid #949ba0;
  width: 315px;
  height: 100%;

  .icon {
    cursor: pointer;
    left: 0;
    margin-left: 10px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  input {
    height: 100%;
    width: 305px;
    font-family: ${({ theme }) => theme.fonts.familys._3};
    font-weight: 600;
    color: #898f94;
    font-size: 1.2rem;
    margin-left: 25px;
    border: none;

    &::placeholder{
      font-weight: normal;
    }
  }
`;
