import React, { useEffect, useState } from 'react';

import { motion } from 'framer-motion';

import {
  masksRecord,
  IMaskOptions,
} from './types';

import { UseFormik } from '../../utils/Formik/types';

interface ICustomInputMaskProps {
  id: string;
  initial?: string;
  placeholder: string;
  option: IMaskOptions;
  formik: UseFormik<any>,
  customValue?: string;

  [key: string]: any;
}

const CustomInputMask = ({
  id,
  option,
  formik,
  initial,
  customValue,
  ...rest
}: ICustomInputMaskProps) => {
  const [maskedValue, setMaskedValue] = useState<string>(initial || '');

  const formatValue = (str: string) => (id ? masksRecord[option](str) : str);
  const handleChange = (value: string) => {
    const newValue: string = formatValue(value);
    setMaskedValue(newValue);

    if (formik) {
      if (option === 'brPhone') {
        if (newValue === '') {
          formik.setFieldValue(id, newValue);
          formik.setFieldTouched(
            id, true, false,
          );
        }

        formik.setFieldValue(id, `+55 ${newValue}`);
        formik.setFieldTouched(
          id, true, false,
        );
      } else {
        formik.setFieldValue(id, newValue);
        formik.setFieldTouched(
          id, true, false,
        );
      }
    }
  };

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(id, formatValue((formik?.values.id || initial || '').toString()));
    }
  }, []);

  useEffect(() => {
    if (customValue) {
      setMaskedValue(formatValue(customValue));
    } else {
      setMaskedValue(formatValue((formik?.values.id || initial || '').toString()));
    }
  }, [customValue]);

  return (
    <>
      <motion.input
        defaultValue={initial}
        value={maskedValue}
        onChange={(e) => handleChange(e.target.value)}
        {...rest}
      />
    </>
  );
};

CustomInputMask.defaultProps = {
  initial: undefined,
  customValue: undefined,
};

export default CustomInputMask;
