import { useEffect } from 'react';
import ManagementPanelComponent from '../../components/ManagementPanelComponent';
import { ManagementPanelProvider } from '../../context/ManagementPanelContext/ManagementPanelContext';

const ManagementPanelPage = () => {
  useEffect(() => {
    document.title = 'Sapron | Painel de gerenciamento';
  }, []);

  return (
    <ManagementPanelProvider>
      <ManagementPanelComponent />
    </ManagementPanelProvider>
  );
};

export default ManagementPanelPage;
