/* eslint-disable max-len */

import { useState } from 'react';

import { postPopupsAck } from '../../../services/Popups/request';

import { PopupsProps } from '../../../context/CustomPopupsContext/types';

import { useToast } from '../../../context/ToastContext';
import { useCustomPopups } from '../../../hooks/useCustomPopups/useCustomPopups';

import CloseButton from '../components/CloseButton';
import Checkbox from '../components/Checkbox';
import FormButton from '../../FormButton';

import {
  Container,
  Header,
  Body,
  Content,
  Footer,
  Text,
  TextBold,
  Title,
  ButtonContainer,
} from './styles';

interface IPopupTemplate2 {
  id: number,
  headline: string,
  content: HTMLElement | string,
  confirmButtonText: string,
  consentRequired: boolean,
  consentText: HTMLElement | string,
  consentDescription: HTMLElement | string,
}

const PopupTemplate2 = ({
  id,
  headline,
  content,
  confirmButtonText,
  consentRequired,
  consentText,
  consentDescription,
}: IPopupTemplate2) => {
  const { setPopupsList } = useCustomPopups();
  const toast = useToast();

  const [checked, setChecked] = useState<boolean>(false);

  const handleClosePopup = () => {
    setPopupsList((state: PopupsProps[]) => state.map((item) => ({
      ...item,
      popup_is_open: item.id === id ? false : item.popup_is_open,
    })));
  };

  const handlePopupConfirmation = async () => {
    try {
      await postPopupsAck(id);
      toast.success('Confirmação realizada com sucesso!');
      handleClosePopup();
    } catch {
      toast.error('Não foi possível realizar a confirmação!');
    }
  };

  const contentHTML = content instanceof HTMLElement ? content.outerHTML : content;
  const consentTextHTML = consentText instanceof HTMLElement ? consentText.outerHTML : consentText;
  const consentDescriptionHTML = consentDescription instanceof HTMLElement ? consentDescription.outerHTML : consentDescription;

  return (
    <Container key={id}>
      <Header>
        <Title>{headline}</Title>
        <CloseButton onClose={() => handleClosePopup()} />
      </Header>
      <Body>
        <Content>
          <Text dangerouslySetInnerHTML={{ __html: contentHTML }} />
        </Content>
      </Body>
      <Footer>
        {consentRequired && (
          <>
            <TextBold dangerouslySetInnerHTML={{ __html: consentDescriptionHTML }} />
            <Checkbox
              label={consentTextHTML}
              checked={checked}
              setChecked={() => setChecked(!checked)}
            />
          </>
        )}

        <ButtonContainer>
          {consentRequired ? (
            <FormButton
              type="button"
              disable={!checked}
              customColor={checked ? 'blue' : 'blue-light'}
              onClick={checked ? () => handlePopupConfirmation() : () => {}}
            >
              {confirmButtonText}
            </FormButton>
          ) : (
            <FormButton
              type="button"
              customColor="blue"
              onClick={() => handlePopupConfirmation()}
            >
              {confirmButtonText}
            </FormButton>
          )}

        </ButtonContainer>
      </Footer>
    </Container>
  );
};

export default PopupTemplate2;
