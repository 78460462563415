import styled from 'styled-components';

export const Container = styled.div<{ alignment?: string }>`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: ${({ alignment }) => (alignment || 'flex-start')};
`;

export const YesText = styled.p<{
  checked: boolean
}>`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: ${({ checked, theme }) => (checked ? theme.palette.blue._250.hex() : theme.palette.grey._670.hex())};
`;

export const NoText = styled.p<{
  checked: boolean
}>`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: ${({ checked, theme }) => (checked ? theme.palette.grey._670.hex() : theme.palette.blue._250.hex())};
`;

export const SwitchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 12px;
`;
