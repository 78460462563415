import styled from 'styled-components';
import { MAX_SIZE_MOBILE } from '../../../utils';

export const Form = styled.form`
  width: 100%;
  height: auto;
`;

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  gap: 0.7rem;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
 
  .MuiOutlinedInput-root {
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()} !important;
  }
   
  &:hover {
    border-color: ${({ theme }) => theme.palette.green.main.hex()};
    transition: 0.7s;
  }

  select {
    width: 243px !important;
  }
  
  fieldset {
    border: 0 !important;
    width: 100% !important;
  }

  .MuiSvgIcon-root {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    fill: ${({ theme }) => theme.palette.blue._970.hex()} !important;
    font-size: 2.5rem !important;
    position: relative !important;
    right: 0px !important;
    top: 2px !important;
  }

  .MuiFormControl-root {
    gap: 0 !important;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    max-width: 100%;
    
    select, fieldset {
      width: 100% !important;
    }
  }
`;

export const DatePickerContainer = styled.div`
   display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const Label = styled.p`
 font-family: Inter;
 font-size: 14px;
 font-weight: 400;
 line-height: 24px;
 padding-bottom: 8px;
 color: ${({ theme }) => theme.palette.blue._250.hex()};
`;
