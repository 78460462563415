import React from 'react';
import { useFormik } from 'formik';

import moment from 'moment';

import { validation } from './validation';
import { usePartners } from '../../../../../../../../context/Partners/Partner/PartnerContext';
import { usePartnersEarning } from '../../../../../../../../context/Partners/Earning/Earning';

import {
  currencyToNumber,
  formatCNPJToPost,
  formatCPFtoSubmit,
} from '../../../../../../../../utils/Formatter';

import { analyticsEvent } from '../../../../../../utils/analytics';
import { useUser } from '../../../../../../../../context/UserContext';
import { useToast } from '../../../../../../../../context/ToastContext';

import { postPartnersFinancialWithdrawRequest } from '../../../../../../../../services/Partner/Financial';
import { postPartnersBankDetails } from '../../../../../../../../services/Partner/Financial/BankDetails/request';
import { GetPartnersBankDetailsBase, PostPartnersBankDetailsResponseBase } from '../../../../../../../../services/Partner/Financial/BankDetails/types';

export function useStepOne() {
  const { userInformation } = useUser();
  const {
    resume, setFinancialWithdrawRequest, setBankDetails, banks,
  } = usePartners();

  const toast = useToast();

  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const {
    steps,
    formValues,
    setFormValues,
  } = usePartnersEarning();

  const formik = useFormik({
    initialValues: {
      pixKey: formValues.pixKey,

      bankId: formValues.bankId,
      bankAgency: formValues.bankAgency,
      bankAccountType: formValues.bankAccountType,
      bankAccountNumber: formValues.bankAccountNumber,
      emailNF: formValues.emailNF,
      cpfOrCnpj: formValues.cpfOrCnpj,
      typePixKey: formValues.typePixKey,
      rescueValue: formValues.rescueValue,
      favoredName: formValues.favoredName,
      cpfOrCnpjValue: formValues.cpfOrCnpjValue,
      wantToReceiveByPix: formValues.wantToReceiveByPix,
      email: formValues.email || resume?.partner.user.email || '',
      nameOfPartner:
        formValues.nameOfPartner || resume?.partner.user.first_name || '',
    },
    validationSchema: validation({ resume }),
    onSubmit: async (values) => {
      setFormValues((oldValues) => ({
        ...oldValues,
        ...values,
      }));

      // PIX

      if (values.wantToReceiveByPix === 'yes') {
        if (resume?.partner.id) {
          try {
            setLoading(true);
            let bankDetails = {} as PostPartnersBankDetailsResponseBase;

            if (!formValues.selectedBankFromSavedList) {
              bankDetails = await postPartnersBankDetails({
                bank_id: values.bankId,
                pix_key_type: values.typePixKey,
                pix_key: values.pixKey,
                user_id: resume.partner.user.id,

                cpf:
                values.cpfOrCnpj === 'cpf'
                  ? formatCPFtoSubmit(values.cpfOrCnpjValue)
                  : '',

                cnpj:
                values.cpfOrCnpj === 'cnpj'
                  ? formatCNPJToPost(values.cpfOrCnpjValue)
                  : '',
              });

              const newBankDetails = {
                ...bankDetails,
                bank: banks.find((item) => item.id === bankDetails?.bank_id),
              };

              setBankDetails((old) => [...old, newBankDetails as GetPartnersBankDetailsBase]);
            }

            const response = await postPartnersFinancialWithdrawRequest({
              status: 'Requested',
              invoice_notice: values.emailNF === 'sendEmail',
              partner: resume.partner.id,
              payment_method: 'Pix',
              date_requested: moment(new Date()).format('YYYY-MM-DD'),

              bank_details:
                bankDetails.id
                  ? bankDetails.id
                  : Number(formValues.selectedBankFromSavedList),

              value: currencyToNumber(values.rescueValue),
            });

            setFinancialWithdrawRequest((oldValues) => [
              ...oldValues,
              response,
            ]);

            toast.success('Dados bancários salvos com sucesso');
            analyticsEvent({
              name: 'Concluiu a solicitação de saque',
              userInformation,
            });
            setSuccess(true);
          } catch {
            toast.error('Não foi possível salvar os dados');
          } finally {
            setLoading(false);
          }
        } else {
          toast.error('Não foi possível identificar o parceiro');
          analyticsEvent({
            name: 'Um erro aconteceu durante a solicitação',
            userInformation,
          });
        }
      } else if (resume?.partner.id) {
        // TED

        try {
          setLoading(true);

          let bankDetails = {} as PostPartnersBankDetailsResponseBase;
          if (!formValues.selectedBankFromSavedList) {
            bankDetails = await postPartnersBankDetails({
              bank_id: formValues.bankId,
              account_number: values.bankAccountNumber,
              account_type: values.bankAccountType,
              branch_number: values.bankAgency,
              user_id: resume.partner.user.id,
              entity_name: formValues.favoredName,

              cpf:
                formValues.cpfOrCnpj === 'cpf'
                  ? formatCPFtoSubmit(formValues.cpfOrCnpjValue)
                  : '',

              cnpj:
              formValues.cpfOrCnpj === 'cnpj'
                ? formatCNPJToPost(formValues.cpfOrCnpjValue)
                : '',
            });
          }

          const response = await postPartnersFinancialWithdrawRequest({
            status: 'Requested',
            invoice_notice: values.emailNF === 'sendEmail',
            partner: resume.partner.id,
            payment_method: 'Ted',

            bank_details:
              bankDetails.id
                ? bankDetails.id
                : Number(formValues.selectedBankFromSavedList),

            value: currencyToNumber(formValues.rescueValue),
            date_requested: moment(new Date()).format('YYYY-MM-DD'),
          });

          setFinancialWithdrawRequest((oldValues) => [...oldValues, response]);

          toast.success('Dados bancários salvos com sucesso');
          analyticsEvent({
            name: 'Concluiu a solicitação de saque',
            userInformation,
          });
          setSuccess(true);
        } catch {
          toast.error('Não foi possível salvar os dados bancários');
        } finally {
          setLoading(false);
        }
      } else {
        toast.error('Não foi possível identificar o parceiro');
        analyticsEvent({
          name: 'Um erro aconteceu durante a solicitação',
          userInformation,
        });
      }
    },
  });

  const isDisabledButton = () => {
    if (formik.isValid) {
      return false;
    }

    return true;
  };

  React.useEffect(() => {
    formik.setFieldValue('cpfOrCnpj', formValues.cpfOrCnpj);
    formik.setFieldValue('rescueValue', formValues.rescueValue);
  }, [steps, formValues]);

  return {
    formik,
    success,
    loading,
    formValues,
    isDisabledButton,
  };
}
