import styled from 'styled-components';
import { baseStyle } from '../../config/base';
import { shimmerAnimation } from '../../../../../components/Calendar/LoadingShimmer/animationShimmer';

export const Container = styled.div`
  ${baseStyle}

  border-radius: 10px;
  background: var(--neuter-50, #f9f9f9);
  border-bottom: 1px solid var(--neuter-300, #d9d9d9);
`;

export const StyledItem = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  font-style: normal;
  font-family: Inter;
  color: var(--gray-800, #394760);

  gap: 16px;
  height: 100%;
  display: flex;
  flex-direction: row;

  button {
    border: none;
    cursor: pointer;
    background: transparent;

    svg {
      stroke: #001840;
    }
  }
`;

export const StyledItemShimmer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--neuter-50, #f9f9f9);
  border-bottom: 1px solid var(--neuter-300, #d9d9d9);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  animation: ${shimmerAnimation} 1s infinite;
`;

export const Line = styled.div`
  width: 1px;
  height: 100%;
  opacity: 0.6;
  background: #969696;
`;
