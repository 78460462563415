import styled, { css } from 'styled-components';

export const Container = styled.div`
  ul {
    gap: 20px;
    display: flex;
    list-style: none;
    flex-direction: column;
  }
`;

export const ItemContainer = styled.button<{
  active: boolean;
}>`
  border: none;
  background: none;
  cursor: pointer;

  text-align: left;

  margin: 0;
  padding: 0;
  background: transparent;

  ${({ active }) => active
    && css`
      li {
        color: ${({ theme }) => theme.palette.blue._920.hex()};
        border-bottom: 1.5px solid ${({ theme }) => theme.palette.blue._920.hex()};

        p {
          transform: translateX(10px);
        }
      }
    `}

  li {
    cursor: pointer;
    user-select: none;

    transition: 0.16s;

    &:hover {
      color: ${({ theme }) => theme.palette.blue._920.hex()};
      border-bottom-color: ${({ theme }) => theme.palette.blue._920.hex()};

      p {
        transform: translateX(10px);
      }
    }

    padding-bottom: 15px;
    min-width: calc(100% + 30px);
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);

    p {
      transition: 0.26s;
      font-size: 1.2rem;
    }
  }
`;
