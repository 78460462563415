import Lottie, { LottieComponentProps } from 'lottie-react';
import React from 'react';

import source from '../source/squareLoading.json';

interface GeneralAnimationProps extends Omit<LottieComponentProps, 'animationData'> {}

export const SquareLoading = ({ ...rest }: GeneralAnimationProps) => (
  <Lottie
    {...rest}
    animationData={source}
  />
);
