import React, { useEffect } from 'react';
import MainComponent from '../../components/PaymentVoucher/PaymentVoucher';
import { Container } from './styles';
import { PaymentVoucherProvider } from '../../context/PaymentVoucher/PaymentVoucherContext';

const PaymentVoucher = () => {
  useEffect(() => {
    document.title = 'Sapron | Voucher';
  }, []);

  return (
    <PaymentVoucherProvider>
      <Container>
        <MainComponent />
      </Container>
    </PaymentVoucherProvider>
  );
};

export default PaymentVoucher;
