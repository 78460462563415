import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import { getPaymentAirbnbConciliation } from '../../../services/Conciliation/request';
import { FiltersType } from '../../../services/Conciliation/types';
import { getOtas } from '../../../services/Property/request';
import { RequestOta } from '../../../services/Property/types';
import { formatedDateOfRequest } from '../../../utils/Formatter';
import CustomSlider from '../../CustomSlider';
import FormButton from '../../FormButton';
import SimpleSelect from '../../SimpleSelect';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import TextField from '../../TextField';
import SelectPeriod from '../FormReservationsFilters/SelectPeriod';
import {
  FormContent, TitleFilter, Text, Row, ContainerButtons,
} from './styles';

/* const options = [
  {
    id: '1',
    description: 'Airbnb',
  },
];
*/
interface Props {
  onClose: () => void;
}

const FormPaymentsFilters = ({ onClose }: Props) : JSX.Element => {
  const [otas, setOtas] = useState<Array<RequestOta>>([]);
  const { setListPayments } = useConciliation();

  const formik = useFormik({
    initialValues: {
      GuestName: '',
      ota: '',
      startDate: '',
      endDate: '',
      confirmationCode: '',
      paymentValueMin: 0,
      paymentValueMax: 0,
    },
    onSubmit: async (values) => {
      const filterArray = [];
      if (values.ota) {
        filterArray.push({ name: 'ota_id', value: values.ota } as FiltersType);
      }
      if (values.startDate) {
        filterArray.push({ name: 'start_date', value: formatedDateOfRequest(values.startDate) } as FiltersType);
      }
      if (values.endDate) {
        filterArray.push({ name: 'end_date', value: formatedDateOfRequest(values.endDate) } as FiltersType);
      }
      if (values.confirmationCode) {
        filterArray.push({ name: 'confirmation_code', value: values.confirmationCode } as FiltersType);
      }
      if (values.GuestName) {
        filterArray.push({ name: 'guest_name', value: values.GuestName } as FiltersType);
      }

      if (values.paymentValueMin) {
        filterArray.push({ name: 'payment_initial_value', value: values.paymentValueMin } as unknown as FiltersType);
      }
      if (values.paymentValueMax) {
        filterArray.push({ name: 'Payment Final Value', value: values.paymentValueMax } as unknown as FiltersType);
      }

      const results = await getPaymentAirbnbConciliation(filterArray);
      setListPayments(results);
      onClose();
    },
  });

  useEffect(() => {
    async function getAllOtas() {
      const result = await getOtas();
      setOtas(result);
    }
    getAllOtas();
  }, []);

  return (
    <FormContent onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <TitleFilter>
        <Text>Hóspede</Text>
      </TitleFilter>
      <Row>
        <TextField
          id="GuestName"
          label="Nome"
          formik={formik}
        />
      </Row>
      <TitleFilter>
        <Text>Código de confirmação</Text>
      </TitleFilter>
      <Row>
        <TextField
          id="confirmationCode"
          placeholder="Digite o código aqui ..."
          formik={formik}
        />
      </Row>
      <TitleFilter>
        <Text>Data pagamento</Text>
      </TitleFilter>
      <Row>
        <SelectPeriod formik={formik} />
      </Row>
      {/*
      <TitleFilter>
        <Text>Plataforma de pagamento</Text>
      </TitleFilter>
      <Row>
        <SimpleSelect
          id="indication"
          dataCy="indication"
          placeholder="Selecione"
          options={
          (options || []).map<SelectOption>(({
            id,
            description,
          }) => ({ value: id, valueLabel: description }))
        }
        />
      </Row>
      */}
      <TitleFilter>
        <Text>OTA</Text>
      </TitleFilter>
      <Row>
        <SimpleSelect
          id="ota"
          placeholder="Selecione"
          formik={formik}
          options={
          (otas || []).map<SelectOption>(({
            id,
            name,
          }) => ({ value: id, valueLabel: name }))
        }
        />
      </Row>
      <TitleFilter>
        <Text>Valor pago</Text>
      </TitleFilter>
      <Row>
        <CustomSlider id="paymentValue" formik={formik} valueMax={5000} valueMin={300} />
      </Row>
      <ContainerButtons>
        <FormButton variant="outlined" type="button" onClick={onClose}>Cancelar</FormButton>
        <FormButton type="submit">Aplicar</FormButton>
      </ContainerButtons>
    </FormContent>
  );
};

export default FormPaymentsFilters;
