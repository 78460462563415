import styled, { css, keyframes } from 'styled-components';

const animateCheck = keyframes`
   from{
    transform: scale(0.5);
  }
  to{
    transform: scale(1);
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  margin: 10px 15px 20px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  @media (max-width: 900px) {
    margin: 0.625rem 0 1.25rem;
  }

`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
`;

export const Image = styled.img`
  margin-left: 4px;
`;

export const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: 2px;
`;

export const StyledCheckBox = styled.div<{ checked: boolean; size?: string }>`
  display: flex;
  align-items: center;
  width: ${(props) => (props.size ? props.size : '14px')};
  height: ${(props) => (props.size ? props.size : '14px')};
  border: ${(props) => (props.checked ? 'none' : '1.2px solid')};
  background: ${(props) => (props.checked
    ? 'linear-gradient(180deg, #0D4BD0 0%, #0038B9 100%);'
    : 'white')};
  border-radius: 2px;
  transition: all 150ms;
  border-color: ${({ theme }) => theme.palette.grey._720.hex()};

  animation-name: ${(props) => props.checked && animateCheck};
  animation-duration: 0.1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const StyledDivLabel = styled.div<{ size?: string; notMargin?: boolean }>`
  margin-left: 10px;
  margin-top: ${(props) => (props.size ? '.1rem' : '0px')};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: ${({ theme }) => theme.fonts.weights.regular};
  font-size: ${(props) => (props.size ? props.size : '14px')};
  line-height: 17px;
  word-break: break-word;
  letter-spacing: -0.02em;

  &.unchecked {
    color:  ${({ theme }) => theme.palette.red._330.hex()};
  }

  @media (max-width: 900px) {
    margin-left: 0.188rem;
  }

  @media (max-width: 900px) {
    font-size: 12px;
    line-height: 10px;
  }

  ${({ notMargin }) => notMargin
    && css`
      margin-left: 0;
    `}
`;
