import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StyledImage = styled.div<{
  previewUrl: string;
}>`
  border: none;
  border-radius: 10px;
  background: var(--Blue-50, #e7e9fa);
  cursor: pointer;
  z-index: 1;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 70px;

  padding: 15px 20px;

  ${({ previewUrl }) => previewUrl
    && css`
      background-image: url(${previewUrl});
      background-size: cover;
      background-position: center;
    `}
`;

export const ViewMoreImagesButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 10px;
  background: #e7e9fa;
  border: 1px solid #042675;
  z-index: 9999999;
  cursor: pointer;

  font-size: 1.1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #042675;
`;

export const AddImageButtonContainer = styled.div`
  border: 2px dashed #ccc;
  border-radius: 10px;
  background: var(--Blue-50, #e7e9fa);

  cursor: pointer;
  width: 80px;
  height: 70px;

  padding: 10px 20px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;

  p {
    color: #394760;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
