import styled from 'styled-components';
import { motion } from 'framer-motion';
import { config } from './utils/config';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../../../hooks/useMobile/useMobile';

export const GeneralContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;

  user-select: none;
`;

export const Container = styled.div`
  padding: 30px;

  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    display: -webkit-inline-box;
    overflow-x: scroll;

    width: 100%;
    height: 100%;
  }

  button {
    position: absolute;
    z-index: 100;

    background: black;
    height: ${config.height};
    width: 70px;
    border: none;
    opacity: 0.5;
    background: var(--darkk-blue-900, #001840);
    backdrop-filter: blur(20px);
  }
`;

export const LeftButton = styled(motion.button)`
  left: 0;

  svg {
    transform: rotate(180deg);
  }
`;

export const RightButton = styled(motion.button)`
  right: 0;
`;
