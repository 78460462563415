import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 3rem;
    font-weight: bolder;
    opacity: 0;
    animation: ${fadeIn} 0.5s 0.2s ease forwards;
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: normal;
    opacity: 0;
    font-size: 1.5rem;
    animation: ${fadeIn} 0.5s 0.3s ease forwards;
  }


  @media (max-width: 1400px) {
    gap: 5rem;
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
`;

export const SearchBar = styled.form`
  display: flex;
  position: relative;
  transition: 0.4s;
  height: 70px;

  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    height: 15rem;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }


  .name-guest {

  }

  .code {
    width: 120px;
    margin-left: 5px;

    @media (max-width: 1400px) {
      width: 100%;
      margin: 0;
    }
  }

  .property-type {
    width: 135px;
    margin-left: 5px;
    margin-top: -3px;
    @media (max-width: 1400px) {
      width: 100%;
      margin: 0;
    }
  }

  .checkin {
    width: 135px;
    margin-left: 5px;
    margin-right: 5px;
    @media (max-width: 1400px) {
      width: 100%;
      margin: 0;
    }
  }

  .save-button {
    width: 120px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 5px;

    @media (max-width: 1400px) {
      width: 100%;
      margin: 0;
    }
  }
`;
