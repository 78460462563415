import React from 'react';

import { FormButton } from '../../../index';

import { ButtonContainer } from './styles';

interface Props {
  className: string;
}

const RequestBlockProperty = ({ className }: Props) => (
  <ButtonContainer className={className}>
    <FormButton>Novo investimento</FormButton>
  </ButtonContainer>
);

export default RequestBlockProperty;
