import React, {
  FC, ReactElement, useState, useCallback, useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

const modalInitialState = {
  open: false,
  coors: {
    x: 0,
    y: 0,
  },
};

interface ICoors {
  x: number;
  y: number;
}
interface IModal {
  open: boolean;
  coors: ICoors;
}

interface IPortfolioContextProps {
  isShowAll: boolean;
  setIsShowAll: Function;
  modal: IModal;
  handleOpenModal: Function;
  handleCloseModal: Function;
}

export const PortfolioContext = createContext<IPortfolioContextProps>({} as IPortfolioContextProps);

export const PortfolioProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [isShowAll, setIsShowAll] = useState<boolean>(true);
  const [modal, setModal] = useState<IModal>(modalInitialState);

  const handleOpenModal = useCallback(({ x, y }: ICoors) => {
    setModal({
      open: true,
      coors: {
        x,
        y,
      },
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    setModal({
      open: false,
      coors: {
        x: 0,
        y: 0,
      },
    });
  }, []);

  const handleIsShowAll = useCallback((newValue: boolean) => {
    setIsShowAll(newValue);
  }, []);

  const value = useMemo(() => ({
    isShowAll,
    setIsShowAll: handleIsShowAll,
    modal,
    handleOpenModal,
    handleCloseModal,
  }), [
    isShowAll,
    handleIsShowAll,
    modal,
    handleOpenModal,
    handleCloseModal,
  ]);

  return (
    <PortfolioContext.Provider value={value}>
      {children}
    </PortfolioContext.Provider>
  );
};
