import { IFormValues } from '../../../types';
import { UseFormik } from '../../../../../../../../utils/Formik/types';
import { TextField } from '../../../../../../../../components';
import { FormContainer } from '../../style';

interface IAddress {
  formik: UseFormik<IFormValues>
}

const Address = ({
  formik,
}: IAddress) => (
  <FormContainer>
    <TextField
      id="street"
      value={formik.values.street}
      dataCy="input-street"
      formik={formik}
      label="Nome da rua"
      placeholder="Ex: Vila Mariana"
    />
    <TextField
      id="number"
      value={formik.values.number}
      dataCy="input-number"
      formik={formik}
      label="Número da casa ou apartamento"
      placeholder="Ex: 1800"
    />
    <TextField
      id="neighborhood"
      value={formik.values.neighborhood}
      dataCy="input-neighborhood"
      formik={formik}
      label="Bairro"
      placeholder="Ex: Jardim Vila Mariana"
    />
    <TextField
      id="complement"
      value={formik.values.complement}
      dataCy="input-complement"
      formik={formik}
      label="Complemento"
      placeholder="Ex: Digite aqui..."
    />
  </FormContainer>
);

export default Address;
