import { useContextSelector } from 'use-context-selector';
import { PaymentVoucherContext } from '../../context/PaymentVoucher/PaymentVoucherContext';

export const usePaymentVoucher = () => {
  const isFinishOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.isFinishOpen);

  const handleChangeIsFinishOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.setIsFinishOpen);

  const isIncompleteOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.isIncompleteOpen);

  const handleChangeIsIncompleteOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.setIsIncompleteOpen);

  const isTwoCardOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.isTwoCardOpen);

  const handleChangeIsTwoCardsOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.setIsTwoCardOpen);

  const paymentData = useContextSelector(PaymentVoucherContext,
    (state) => state.paymentData);

  const handleChangePaymentData = useContextSelector(PaymentVoucherContext,
    (state) => state.setPaymentData);

  const paymentDataStatus = useContextSelector(PaymentVoucherContext,
    (state) => state.paymentDataStatus);

  const handleChangePaymentDataStatus = useContextSelector(PaymentVoucherContext,
    (state) => state.setPaymentDataStatus);

  const loading = useContextSelector(PaymentVoucherContext,
    (state) => state.loading);

  const handleChangeLoading = useContextSelector(PaymentVoucherContext,
    (state) => state.setLoading);

  const modalOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.modalOpen);

  const handleChangeModalOpen = useContextSelector(PaymentVoucherContext,
    (state) => state.setModalOpen);

  const checked = useContextSelector(PaymentVoucherContext,
    (state) => state.checked);

  const handleChangeChecked = useContextSelector(PaymentVoucherContext,
    (state) => state.setChecked);

  const checkedList = useContextSelector(PaymentVoucherContext,
    (state) => state.checkedList);

  const handleChangeCheckedList = useContextSelector(PaymentVoucherContext,
    (state) => state.setCheckedList);

  const checkedCounter = useContextSelector(PaymentVoucherContext,
    (state) => state.checkedCounter);

  const handleChangeCheckedCounter = useContextSelector(PaymentVoucherContext,
    (state) => state.setCheckedCounter);

  const reservationIdEditedButton = useContextSelector(PaymentVoucherContext,
    (state) => state.reservationIdEditedButton);

  const handleChangeReservationIdEditedButton = useContextSelector(PaymentVoucherContext,
    (state) => state.setReservationIdEditedButton);

  return {
    isFinishOpen,
    handleChangeIsFinishOpen,
    isIncompleteOpen,
    handleChangeIsIncompleteOpen,
    isTwoCardOpen,
    handleChangeIsTwoCardsOpen,
    paymentData,
    handleChangePaymentData,
    paymentDataStatus,
    handleChangePaymentDataStatus,
    loading,
    handleChangeLoading,
    modalOpen,
    handleChangeModalOpen,
    checked,
    handleChangeChecked,
    checkedList,
    handleChangeCheckedList,
    checkedCounter,
    handleChangeCheckedCounter,
    reservationIdEditedButton,
    handleChangeReservationIdEditedButton,
  };
};
