import { GridFinancialSummaryProps, IncomeGridFinancialSummaryProps, OwnerFinancialSummaryProps } from './types';

export const expenses: GridFinancialSummaryProps[] = [
  {
    id: 'expense1',
    date: '-',
    type: 'Despesa',
    value: '-',
    status_color: 'expense',
  },
];

export const income: IncomeGridFinancialSummaryProps[] = [
  {
    id: 'income1',
    date: '10/2023',
    type: 'Receita',
    value: 'R$ 2.835,00',
    status_color: 'income',

    reservations: [
      {
        id: '1',
        date: '28/10/2023 - 30/10/2023',
        type: 'Reserva',
        value: 'R$ 1.335,00',
        guest: 'Joe Doe',
        comment: 'Lorem Ipsum',
        ota: 'AIR',
        dailys_quantity: 3,
        adults_quantity: 1,
        kids_quantity: 0,
        pets_allowed: true,

        details: [{
          id: '1',
          type: 'Diária 1',
          date: '28/10/2023',
          value: 'R$ 445,00',
        },
        {
          id: '2',
          type: 'Diária 2',
          date: '29/10/2023',
          value: 'R$ 445,00',
        },
        {
          id: '3',
          type: 'Diária 3',
          date: '30/10/2023',
          value: 'R$ 445,00',
        },
        ],
      },
      {
        id: '2',
        date: '31/10/2023 - 01/11/2023',
        type: 'Reserva',
        value: 'R$ 3.000,00',
        guest: 'Joe Doe',
        comment: 'Lorem Ipsum',
        ota: 'STA',
        dailys_quantity: 2,
        adults_quantity: 1,
        kids_quantity: 1,
        pets_allowed: false,

        details: [{
          id: '1',
          type: 'Diária 1',
          date: '31/10/2023',
          value: 'R$ 1.500,00',
        }],
      },
    ],
  },
];

export const manualfit: GridFinancialSummaryProps[] = [
  {
    id: 'manualfit1',
    date: '-',
    type: 'Ajuste',
    value: '-',
    status_color: 'manual_fit',
  },
];

export const commission: GridFinancialSummaryProps[] = [
  {
    id: 'commission1',
    date: '-',
    type: 'Comissão',
    value: '-',
    status_color: 'commission',
  },
];

export const transfer: GridFinancialSummaryProps[] = [
  {
    id: 'transfer1',
    date: '-',
    type: 'Repasse',
    value: '-',
    status_color: 'transfer',
  },
];

export const initialValuesOwnerFinancialSummary: OwnerFinancialSummaryProps = {
  balance: {
    date_ref: '-',
    total_balance: '-',
    total_balance_info: '',
  },
  period_summation: [
    {
      id: 1,
      title: 'Despesas',
      value: '-',
    },
    {
      id: 2,
      title: 'Receita',
      value: '-',
    },
    {
      id: 3,
      title: 'Ajuste',
      value: '-',
    },
    {
      id: 4,
      title: 'Comissão',
      value: '-',
    },
    {
      id: 5,
      title: 'Repasse',
      value: '-',
    },
    {
      id: 6,
      title: 'Saldo do Período',
      value: '-',
    },
  ],
  properties: {
    expenses,
    income,
    manualfit,
    commission,
    transfer,
    all: expenses.concat(income).concat(manualfit).concat(commission).concat(transfer),
  },
};
