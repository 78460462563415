import { IconButton } from '@mui/material';
import { MoreVertIcon } from '../../FinancialClose/Grid/styles';
import {
  CardCodeShimmer,
  CardImageShimmer,
  CardInputShimmer,
  CardSubTitleShimmer,
  CardTotalPriceShimmer,
  Container,
  ContentValueTotal,
  HeaderCard,
  ImageProperty,
  Price, PropertyTitle, RowInformations, Separator, Total,
} from './styles';

export function CardShimmer() {
  return (
    <Container>
      <ImageProperty>
        <CardImageShimmer />
      </ImageProperty>
      <HeaderCard>
        <PropertyTitle>
          <CardCodeShimmer />
        </PropertyTitle>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
        >
          <MoreVertIcon />
        </IconButton>
      </HeaderCard>
      <RowInformations>
        <CardSubTitleShimmer />
      </RowInformations>
      <Separator />
      <ContentValueTotal>
        <Total>Total</Total>
        <Price>
          <CardTotalPriceShimmer />
        </Price>
      </ContentValueTotal>
      <CardInputShimmer />
    </Container>
  );
}
