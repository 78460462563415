import { motion, Variants } from 'framer-motion';
import moment from 'moment';
import {
  TableContainer,
} from './styles';
import { PostGuestDamageNegotiationHistory } from '../../../../../../services/GuestDamage/types';

const tableVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 120, transition: { duration: 0.2 } },
};
const theadVariants: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};
const tbodyVariants: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.2,
    },
  },
};

const animationDiv: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const animationDivWithDelay: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

interface ValidationAndHistoryTableProps {
  histories: PostGuestDamageNegotiationHistory[]
}

export const ValidationAndHistoryTable = ({ histories }: ValidationAndHistoryTableProps) => (
  <TableContainer>
    <motion.table
      variants={tableVariants}
      initial="closed"
      animate="open"
      key="table"
    >
      <motion.thead variants={theadVariants} initial="hidden" animate="visible">
        <tr>
          <th>
            <motion.div variants={animationDiv} key="1">
              Data
            </motion.div>
          </th>
          <th>
            <motion.div variants={animationDiv} key="1">
              História
            </motion.div>
          </th>
        </tr>
      </motion.thead>
      <motion.tbody
        variants={tbodyVariants}
        initial="hidden"
        animate="visible"
      >
        {histories.map((history) => (
          <motion.tr key={history.history} variants={animationDivWithDelay}>
            <td>
              {moment(history.contact_date).format('DD/MM/YYYY')}
            </td>
            <td>
              {history.history}
            </td>
          </motion.tr>
        ))}
      </motion.tbody>
    </motion.table>
  </TableContainer>

);
