import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;

  h2 {
    font-weight: ${({ theme }) => theme.fonts.weights.normal};
    span {
      color: red;
      margin-right: 0.5rem
    }
  }

  div {
    justify-self: flex-end;
  }
  @media (max-width: 400px){
    gap: 1rem;
    flex-direction: column;
  }
`;
