import React, { useEffect } from 'react';
import EstimatePage from '../../components/EstimateComponent';
import { EstimateFilterProvider } from '../../hooks/useEstimate/useEstimatePage';
import { Container } from './styles';

const Estimate: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Orçamento';
  }, []);
  return (
    <Container>
      <EstimateFilterProvider>
        <EstimatePage />
      </EstimateFilterProvider>
    </Container>
  );
};

export default Estimate;
