export type BankDetail = {
  id: number,
  entity_name: string,
  bank_name:string,
  bank_number: string,
  branch_number: string,
  account_number: string,
  account_type: string,
  cpf: string,
  cnpj: string,
  pix_key: string,
  user: number,
};

export type Bank = {
  id?: number,
  bank_number?: string,
  long_name?: string,
  short_name?: string,
};

export enum AccountTypeLabel {
  SAVING_ACCOUNT = 'Conta poupança',
  INDIVIDUAL_CHECKING_ACCOUNT = 'Conta corrente individual',
  JOINT_CHECKING_ACCOUNT = 'Conta corrente conjunta',
}

export enum AccountTypeValue {
  SAVING_ACCOUNT = 'Saving_Account',
  INDIVIDUAL_CHECKING_ACCOUNT = 'Individual_Checking_Account',
  JOINT_CHECKING_ACCOUNT = 'Joint_Checking_Account',
}

export type AccountTypeOptionsProps = {
  label: AccountTypeLabel,
  value: AccountTypeValue,
};
