import React, { useEffect } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';

// import iconFilter from '../../../assets/icons/multicalendar/filter.svg';

import TedListDatePicker from './DatePicker';
import ExportListButton from './Button/ExportListButton';
// import TedListTextField from './TextField/TedListTextField';
// import GenerateOrLoadListButton from './Button/GenerateOrLoadListButton';
// import Tooltip from '../../Tooltip';

import { useOwnerTedList } from '../../../hooks/OwnerTedListHook/useOwnerTedList';
import { IGetOwnerTedsParams } from '../../../services/OwnerTedList/types';

import {
  LeftSide,
  RightSide,
  Container,
  // FilterButton,
} from './styles';

const Header = () => {
  const {
    // handleOpenModalFilters,
    filters,
    handleSetFilters,
  } = useOwnerTedList();

  const formik = useFormik({
    initialValues: {
      search: '',
      period: new Date(),
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    const newFilters: IGetOwnerTedsParams = {
      ...filters,
      period: moment(formik.values.period).format('YYYY-MM'),
      termToFilter: formik.values.search,
    };

    handleSetFilters(newFilters);
  }, [formik.values]);

  return (
    <Container>
      <LeftSide>
        {/* <GenerateOrLoadListButton formik={formik}>
          Gerar / Carregar lista
        </GenerateOrLoadListButton> */}
        <TedListDatePicker formik={formik} />
      </LeftSide>
      <RightSide>
        <ExportListButton formik={formik}>Exportar lista</ExportListButton>
        {/* <TedListTextField id="search" formik={formik} />
        <Tooltip text="Mais filtros">
          <FilterButton
            type="button"
            onClick={() => handleOpenModalFilters(true)}
          >
            <img src={iconFilter} alt="ícone de filtros" />
          </FilterButton>
        </Tooltip> */}
      </RightSide>
    </Container>
  );
};

export default Header;
