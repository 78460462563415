import { FC, useEffect, useState } from 'react';
import { Drawer } from '@mui/material';
import { X } from 'react-feather';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import moment from 'moment';
import {
  Header,
  Container,
  Content,
  HeaderText,
  CloseButton,
  HeaderContainer,
  ComingContainer,
  ComingTitle,
  ResCodeContainer,
  ResCodeTitle,
  ComingData,
  ResCodeData,
  ContentContainer,
  ButtonsContainer,
  HeaderRightContainer,
  HeadContainer,
  InputCheckBoxContainer,
} from './styles';

import { DatePicker, FormButton, SimpleSelect, TextField } from '../../index';

import { useModal } from '../../../hooks/ModalHook/useModal';
import { HotBed } from '../Modal/pins/Pins';
import { Checkin, Checkout, Clearning, RequestEvent } from '../../../services/Control/types';
import {
  getCheckin,
  getCheckout,
  getClearning,
  postCheckin,
  postCheckout,
  postClearning,
  putCheckin,
  putCheckout,
  putClearning,
} from '../../../services/Control/request';
import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import {
  currencyToNumber,
  formatedDateOfRequest,
  formatedDateToShow,
} from '../../../utils/Formatter';
import { useChecklist } from '../../../context/ControllerPage/ChecklistContext';
import InputCheckBox from '../../InputCheckBox';

type Props = {
  anchor?: 'right' | 'left';
  setOpen?: Function;
  onClose?: Function;
  open?: boolean;

  options?: Array<{
    icon: string;
    alt: string;
  }>;

  activeTabs?: boolean;
  headerTitle?: string;

  localData?: RequestEvent[];

  checkin?: boolean;
  checkout?: boolean;
  clearning?: boolean;
  hot?: boolean;
  recive?: boolean;
  reservation?: RequestEvent;
  setReservation: Function;
  updateLocalData: Function;
  statusContact: boolean;
  setStatusContact: (a: boolean) => void;
};

interface ReservationChecklist {
  notes: string;
  concluded: boolean;
  questionList: any[];
}

export interface ReservationCheckin {
  id: number;
  reservation: number;
  was_contacted: boolean;
  guest_observation: string;
  checklist: ReservationChecklist;
  status: string;
  check_in_time: string;
  check_in_date: string;
  card_concluded: boolean;
}

export interface ReservationCheckout {
  id: number;
  reservation: number;
  was_contacted: boolean;
  guest_feedback: string;
  check_out_date: string;
  check_out_time: string;
  status: string;
  checklist: ReservationChecklist;
  card_concluded: boolean;
}

export interface CleaningReservation {
  id: number;
  reservation: number;
  was_checked: boolean;
  cleaning_date: string;
  cleaning_time: string;
  maid_name: string;
  bedsheets_quantity: number;
  cleaning_duration: string;
  cleaning_price: string;
  status: string;
  checklist: ReservationChecklist;
  card_concluded: boolean;
}

interface FillCheckin {
  id?: number;
  reservation: number;
  check_in_time: string;
  was_contacted: string;
  guest_observation: string;
}
interface FillCheckout {
  id?: number;
  reservation: number;
  check_out_time: string;
  was_contacted: string;
  guest_feedback: string;
}
interface FillCleaning {
  id?: number;
  reservation: number;
  was_checked: string;
  cleaning_date: string;
  cleaning_time: string;
  maid_name: string;
  cleaning_duration: string;
  cleaning_price: string;
}

const LateralModal: FC<Props> = ({
  anchor = 'right',
  open = false,
  setOpen = () => {},
  onClose,
  hot,
  clearning,
  checkin,
  checkout,
  reservation,
  setReservation,
  updateLocalData,
  localData,
  statusContact,
  setStatusContact,
}) => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const [informationCheckin, setInformationCHeckin] = useState<Checkin | null>(null);
  const [informationCheckout, setInformationCheckout] = useState<Checkout | null>(null);
  const [informationClearning, setInformationClearning] = useState<Clearning | null>(null);
  const { finishChecklist, setFinishChecklist } = useChecklist();
  const [isMonthlyPayment, setMonthlyPayment] = useState<boolean>(false);

  const handleSetMonthPayment = (monthlyPaymentCheckbox: boolean) => {
    setMonthlyPayment(monthlyPaymentCheckbox);
  };

  const validationCheckin = Yup.object().shape({
    check_in_time: Yup.string().required('Horário do check-in deve ser preenchido!'),
    was_contacted: Yup.string().required('Este campo deve ser preenchido!'),
  });

  const { handleModalActive } = useModal();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    if (setOpen) {
      setOpen(false);
    }
    handleModalActive(0);
  };

  const handleClickCancelButton = () => {
    handleClose();
  };

  const fillCheckin = useMutation(async (values: FillCheckin) => {
    try {
      let response = {} as ReservationCheckin;
      if (informationCheckin && informationCheckin.id) {
        response = await putCheckin(
          {
            ...values,
            reservation: reservation?.id,
            check_in_time: values.check_in_time,
            was_contacted: values.was_contacted,
            guest_observation: values.guest_observation,
          } as unknown as Checkin,
          informationCheckin.id,
        );
      } else {
        response = await postCheckin({
          ...values,
          reservation: reservation?.id,
          check_in_time: values.check_in_time,
          was_contacted: values.was_contacted,
          guest_observation: values.guest_observation,
        } as unknown as Checkin);
      }

      toast.success('Informações preenchidas com sucesso!');
      handleClose();

      setReservation({
        ...reservation,
        checklist: {
          ...reservation?.checklist,
        },
        was_contacted: values.was_contacted !== '0',
        check_in_time: values.check_in_time,
      });

      const newLocalData = localData?.map((item: any) =>
        item.id === reservation?.id && item.type === reservation?.type
          ? {
              ...item,
              was_contacted: response?.was_contacted,
              checklist: response.checklist,
              check_in_time: values.check_in_time,
            }
          : item,
      );

      updateLocalData(newLocalData);

      if (setStatusContact) {
        setStatusContact(!statusContact);
      }
    } catch (e) {
      toastErrorRequest(e);
    }
    return values;
  });

  const handleFillCheckin = async (values: FillCheckin) => {
    await fillCheckin.mutateAsync(values);
  };

  const formikCheckin = useFormik({
    initialValues: {
      reservation: 0,
      check_in_time: '',
      was_contacted: '',
      guest_observation: '',
    },
    validationSchema: validationCheckin,
    onSubmit: handleFillCheckin,
  });

  useEffect(() => {
    formikCheckin.setFieldValue('was_contacted', '0');
    if (formikCheckin.values.check_in_time.trim().length === 5) {
      formikCheckin.setFieldValue('was_contacted', '1');
    }
  }, [formikCheckin.values.check_in_time]);

  const validationCheckout = Yup.object().shape({
    check_out_time: Yup.string().required('Horário do check-out deve ser preenchido!'),
    was_contacted: Yup.string().required('Este campo deve ser preenchido!'),
  });

  const fillCheckout = useMutation(async (values: FillCheckout) => {
    try {
      let response = {} as ReservationCheckout;
      if (informationCheckout && informationCheckout.id) {
        response = await putCheckout(
          {
            ...values,
            reservation: reservation?.id,
            check_out_time: values.check_out_time,
            was_contacted: values.was_contacted,
            guest_feedback: values.guest_feedback,
          } as unknown as Checkout,
          informationCheckout.id,
        );
      } else {
        response = await postCheckout({
          ...values,
          reservation: reservation?.id,
          check_out_time: values.check_out_time,
          was_contacted: values.was_contacted,
          guest_feedback: values.guest_feedback,
        } as unknown as Checkout);
      }
      toast.success('Informações preenchidas com sucesso!');
      handleClose();

      setReservation({
        ...reservation,
        checklist: {
          ...reservation?.checklist,
        },
        was_contacted: values.was_contacted !== '0',
        check_out_time: values.check_out_time,
      });

      const newLocalData = localData?.map((item: any) =>
        item.id === reservation?.id && item.type === reservation?.type
          ? {
              ...item,
              was_contacted: response?.was_contacted,
              checklist: response.checklist,
              check_out_time: values.check_out_time,
            }
          : item,
      );

      updateLocalData(newLocalData);

      if (setStatusContact) {
        setStatusContact(!statusContact);
      }
    } catch (e) {
      toastErrorRequest(e);
    }
    return values;
  });

  const handleFillCheckout = async (values: FillCheckout) => {
    await fillCheckout.mutateAsync(values);
  };

  const formikCheckout = useFormik({
    initialValues: {
      reservation: 0,
      check_out_time: '',
      was_contacted: '',
      guest_feedback: '',
    },
    validationSchema: validationCheckout,
    onSubmit: handleFillCheckout,
  });

  useEffect(() => {
    formikCheckout.setFieldValue('was_contacted', '0');
    if (formikCheckout.values.check_out_time.trim().length === 5) {
      formikCheckout.setFieldValue('was_contacted', '1');
    }
  }, [formikCheckout.values.check_out_time]);

  const validationClearning = Yup.object().shape({
    cleaning_date: Yup.string().required('Data da limpeza deve ser preenchida!'),
    cleaning_time: Yup.string().required('Horário da limpeza deve ser preenchido!'),
    was_checked: Yup.string().required('Este campo deve ser preenchido'),
  });

  const fillCleaning = useMutation(async (values: FillCleaning) => {
    try {
      let response = {} as CleaningReservation;
      if (informationClearning && informationClearning.id) {
        response = await putClearning(
          {
            ...values,
            maid_name: values.maid_name,
            reservation: reservation?.id,
            was_checked: values.was_checked,
            cleaning_time: values.cleaning_time,
            cleaning_duration: values.cleaning_duration,
            cleaning_price: isMonthlyPayment ? '0' : currencyToNumber(values.cleaning_price),
            cleaning_date: formatedDateOfRequest(moment(values.cleaning_date).format('YYYY-MM-DD')),
          } as unknown as Clearning,
          informationClearning.id,
        );
      } else {
        response = await postClearning({
          ...values,
          maid_name: values.maid_name,
          reservation: reservation?.id,
          was_checked: values.was_checked,
          cleaning_time: values.cleaning_time,
          cleaning_duration: values.cleaning_duration,
          cleaning_price: isMonthlyPayment ? '0' : currencyToNumber(values.cleaning_price),
          cleaning_date: formatedDateOfRequest(values.cleaning_date),
        } as unknown as Clearning);
      }

      toast.success('Informações preenchidas com sucesso!');

      setReservation({
        ...reservation,
        checklist: {
          ...reservation?.checklist,
        },
        was_checked: values.was_checked !== '0',
        cleaning_time: values.cleaning_time,
      });

      const newLocalData = localData?.map((item: any) =>
        item.id === reservation?.id && reservation?.type === item?.type
          ? {
              ...item,
              was_checked: response?.was_checked,
              checklist: response?.checklist,
              cleaning_time: values.cleaning_time,
              cleaning_date: formatedDateOfRequest(values.cleaning_date),
            }
          : item,
      );

      updateLocalData(newLocalData);

      setFinishChecklist(!finishChecklist);
      handleClose();
    } catch (e) {
      toastErrorRequest(e);
    }
    return values;
  });
  const handleFillCleaning = async (values: FillCleaning) => {
    await fillCleaning.mutateAsync(values);
  };
  const formikClearning = useFormik({
    initialValues: {
      maid_name: '',
      reservation: 0,
      was_checked: '',
      cleaning_date: `${new Date()}`,
      cleaning_time: '',
      cleaning_price: '',
      cleaning_duration: '',
    },

    validationSchema: validationClearning,
    onSubmit: handleFillCleaning,
  });

  function handleType() {
    if (checkin) {
      return formikCheckin.handleSubmit;
    }
    if (clearning) {
      return formikClearning.handleSubmit;
    }
    return formikCheckout.handleSubmit;
  }

  function setShowValuesFields(formik: any, field: string, value: string) {
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true, false);
  }

  useEffect(() => {
    async function getCheckinList() {
      const resultCheckin = await getCheckin(reservation?.id);
      if (resultCheckin.length > 0) {
        const check = resultCheckin[0]?.check_in_time;
        setInformationCHeckin(resultCheckin[0]);
        if (check) {
          setShowValuesFields(
            formikCheckin,
            'check_in_time',
            `${check.split(':')[0]}:${check.split(':')[1]}` || '',
          );
        }
        setShowValuesFields(
          formikCheckin,
          'guest_observation',
          resultCheckin[0]?.guest_observation || '',
        );
        setShowValuesFields(
          formikCheckin,
          'was_contacted',
          resultCheckin[0]?.was_contacted ? '1' : '0',
        );
      }
    }
    async function getCheckoutList() {
      const resultCheckout = await getCheckout(reservation?.id);
      if (resultCheckout.length > 0) {
        const check = resultCheckout[0]?.check_out_time;
        setInformationCheckout(resultCheckout[0]);
        if (check) {
          setShowValuesFields(
            formikCheckout,
            'check_out_time',
            `${check.split(':')[0]}:${check.split(':')[1]}` || '',
          );
        }
        setShowValuesFields(
          formikCheckout,
          'guest_feedback',
          resultCheckout[0]?.guest_feedback || '',
        );
        setShowValuesFields(
          formikCheckout,
          'was_contacted',
          resultCheckout[0]?.was_contacted ? '1' : '0',
        );
      }
    }
    async function getClearningList() {
      const resultClearning = await getClearning(reservation?.id);
      if (resultClearning.length > 0) {
        const clearningDataFormatted = moment(resultClearning[0]?.cleaning_date).toString();

        setInformationClearning(resultClearning[0]);
        setShowValuesFields(
          formikClearning,
          'cleaning_date',
          new Date(clearningDataFormatted).toString() || '',
        );
        setShowValuesFields(
          formikClearning,
          'cleaning_time',
          resultClearning[0]?.cleaning_time || '',
        );
        setShowValuesFields(
          formikClearning,
          'cleaning_duration',
          resultClearning[0]?.cleaning_duration || '',
        );
        setShowValuesFields(formikClearning, 'maid_name', resultClearning[0]?.maid_name || '');
        setShowValuesFields(
          formikClearning,
          'was_checked',
          resultClearning[0]?.was_checked ? '1' : '0',
        );
        setShowValuesFields(
          formikClearning,
          'cleaning_price',
          resultClearning[0]?.cleaning_price || '',
        );

        if (resultClearning[0].cleaning_price === '0.00') {
          setMonthlyPayment(true);
        }
      }
    }
    if (reservation?.type === 'checkin') {
      getCheckinList();
    } else if (reservation?.type === 'checkout' && !clearning) {
      getCheckoutList();
    } else {
      getClearningList();
    }
  }, []);

  return (
    <Container>
      <Drawer open={open} anchor={anchor} onClose={handleClose} style={{ overflow: 'none' }}>
        <Header>
          <HeaderText>
            {checkin && <>Detalhes do Check-in</>}
            {checkout && <>Detalhes do Check-out</>}
            {clearning && <>Detalhes da Limpeza</>}
          </HeaderText>
          <HeaderRightContainer>
            {hot && <HotBed />}
            <CloseButton data-cy="btn-close-sidebar" onClick={handleClose}>
              <X size={16} />
            </CloseButton>
          </HeaderRightContainer>
        </Header>
        <Content>
          <HeaderContainer>
            <ComingContainer>
              {checkin && <ComingTitle>Chegada</ComingTitle>}
              {checkout && <ComingTitle>Saída</ComingTitle>}
              {clearning && <ComingTitle>Limpeza</ComingTitle>}
              <ComingData>{formatedDateToShow(reservation?.check_in_date || '')}</ComingData>
            </ComingContainer>
            <ResCodeContainer>
              <ResCodeTitle>Cód da reserva</ResCodeTitle>
              <ResCodeData>{reservation && reservation.code}</ResCodeData>
            </ResCodeContainer>
          </HeaderContainer>
          <div
            onContextMenu={(event): void => {
              event.stopPropagation();
            }}
          >
            <form onSubmit={handleType()}>
              <ContentContainer>
                {checkin && (
                  <TextField
                    dataCy="checkin-time"
                    label="*Horário de chegada"
                    placeholder="__:__"
                    id="check_in_time"
                    mask="hour"
                    autoComplete="off"
                    value={formikCheckin.values.check_in_time}
                    formik={formikCheckin}
                  />
                )}
                {checkout && (
                  <TextField
                    dataCy="checkout-time"
                    label="*Horário de saída"
                    placeholder="__:__"
                    id="check_out_time"
                    mask="hour"
                    autoComplete="off"
                    formik={formikCheckout}
                    value={formikCheckout.values.check_out_time}
                  />
                )}

                {clearning && (
                  <>
                    <HeadContainer>
                      <DatePicker
                        dataCy="datepicker-cleaning"
                        label="*Data"
                        id="cleaning_date"
                        minDate={new Date('2000-01-02')}
                        formik={formikClearning}
                      />
                      <TextField
                        dataCy="input-cleaning-time"
                        label="*Horário de limpeza"
                        placeholder="__:__"
                        id="cleaning_time"
                        mask="hour"
                        autoComplete="off"
                        formik={formikClearning}
                        value={formikClearning.values.cleaning_time}
                      />
                    </HeadContainer>
                    <TextField
                      dataCy="input-cleaning-duration"
                      label="Duração da limpeza (horas)"
                      placeholder="__:__"
                      id="cleaning_duration"
                      mask="hour"
                      autoComplete="off"
                      formik={formikClearning}
                      value={formikClearning.values.cleaning_duration}
                    />
                    <SimpleSelect
                      dataCy="select-cleaning-checked"
                      id="was_checked"
                      placeholder="Selecione"
                      label="*Limpeza conferida?"
                      disabled={false}
                      options={[
                        { value: '1', valueLabel: 'Sim' },
                        { value: '0', valueLabel: 'Não' },
                      ]}
                      formik={formikClearning}
                    />
                    <TextField
                      dataCy="input-maid-name"
                      placeholder="Digite um nome"
                      id="maid_name"
                      label="Camareira responsável pela limpeza "
                      formik={formikClearning}
                      value={formikClearning.values.maid_name}
                    />
                    <TextField
                      dataCy="input-cleaning-price"
                      label="Valor pago pela limpeza"
                      placeholder="R$ 0,00"
                      id="cleaning_price"
                      mask="money"
                      autoComplete="off"
                      formik={formikClearning}
                      disabled={!!isMonthlyPayment}
                      value={formikClearning.values.cleaning_price}
                    />
                    <InputCheckBoxContainer>
                      <InputCheckBox
                        dataCy="checkbox-monthly-payment"
                        labelName="Pagamento Mensal"
                        checked={isMonthlyPayment}
                        onChange={() => handleSetMonthPayment(!isMonthlyPayment)}
                      />
                    </InputCheckBoxContainer>
                  </>
                )}

                {checkin && (
                  <SimpleSelect
                    dataCy="was_contacted"
                    id="was_contacted"
                    placeholder="Selecione"
                    label="*Já foi feito contato com o hóspede?"
                    disabled={false}
                    options={[
                      { value: '1', valueLabel: 'Sim' },
                      { value: '0', valueLabel: 'Não' },
                    ]}
                    formik={formikCheckin}
                  />
                )}

                {checkout && (
                  <SimpleSelect
                    dataCy="was_contacted"
                    id="was_contacted"
                    placeholder="Selecione"
                    label="*Contato feito ?"
                    disabled={false}
                    options={[
                      { value: '1', valueLabel: 'Sim' },
                      { value: '0', valueLabel: 'Não' },
                    ]}
                    formik={formikCheckout}
                  />
                )}
                {(checkin || checkout) && (
                  <TextField
                    dataCy="textarea-notes"
                    label="Observações"
                    id={checkin ? 'guest_observation' : 'guest_feedback'}
                    formik={checkin ? formikCheckin : formikCheckout}
                    type="textarea"
                  />
                )}
              </ContentContainer>
              <ButtonsContainer>
                <FormButton
                  dataCy="btn-cancel"
                  onClick={() => handleClickCancelButton()}
                  variant="outlined"
                  type="button"
                >
                  Cancelar
                </FormButton>
                <FormButton dataCy="btn-save" type="submit">
                  {(checkin && informationCheckin) ||
                  (checkout && informationCheckout) ||
                  (clearning && informationClearning)
                    ? 'Atualizar'
                    : 'Salvar'}
                </FormButton>
              </ButtonsContainer>
            </form>
          </div>
        </Content>
      </Drawer>
    </Container>
  );
};

export default LateralModal;
