import styled from 'styled-components';

export const ReconcileDamageReservationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin-bottom: 1rem;
`;
