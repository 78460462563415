import styled from 'styled-components';

interface Props {
  isMain: boolean;
}

export const Container = styled.div<Props>`
  position: relative;
  padding: 12px;
  width: 100%;
  height: 153px;
  margin-bottom: 3px;

  background: ${({ theme }) => theme.palette.white._100.hex()};
  border: 3px solid ${(props) => (props.isMain ? '#AAE0AC' : '#E8E8E8')};
  box-sizing: border-box;
  border-radius: 10px;

  @media (max-width: 630px) {
    height: auto;
  }
`;

export const Warning = styled.div`
display: flexbox;
position: relative;
padding: 7px;
width: 100%;
height: 52px;
margin-top: 2px;
margin-bottom: 10px;

background: ${({ theme }) => theme.palette.white._100.hex()};
box-sizing: border-box;
border-radius: 10px;

@media (max-width: 630px) {
  height: auto;
}
`;

export const TagMain = styled.div`
  width: auto;
  padding: 5px 10px;

  background: ${({ theme }) => theme.palette.green._300.hex()};
  border-radius: 20px;

  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 9px;
`;

export const Options = styled.div<Props>`
  width: ${({ isMain }) => (isMain ? '20%' : '8%')};
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg,
  img {
    transition: filter(0.3s);

    :hover {
      cursor: pointer;
      filter: brightness(0.9);
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
    }
    &.delete {
      fill: ${({ theme }) => theme.palette.orange.main.hex()};
    }
  }
  @media (max-width: 630px) {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 45px;
    margin-bottom: 20px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  div {
    width: 40%;

    &.new-styled {
      width: 20%;
    }
  }

  h2 {
    font-family: "Arial";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }

  @media (max-width: 630px) {
    flex-direction: column;
    gap: 20px;

    div {
      width: 100%;
      gap: 20px;
    }

    &.secondaryContent {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &.warning-data-account {
      background: ${({ theme }) => theme.palette.white._100.hex()};
      border: 3px solid;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 5px;
      margin-bottom: 50px;
    }
  }
`;

export const Divider = styled.div`
  width: 1px !important;
  margin: 0 19px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.grey._300.hex()};

  @media (max-width: 630px) {
    display: none;
  }
`;

export const Line = styled.div`
  width: 100%;
  margin: 7px 0;
  height: 1px !important;
  background-color: ${({ theme }) => theme.palette.grey._300.hex()};

  @media (max-width: 630px) {
    display: none;
  }
`;
