import React from 'react';

import Lottie from 'lottie-react';
import { Container } from './style';

import animationSource from '../../../../assets/animations/source/notFoundError.json';

const NotFoundScreenAnimation = () => <Lottie animationData={animationSource} />;

const RequestToken = () => (
  <Container
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <NotFoundScreenAnimation />
    <h1>Ops !</h1>
    <div>
      <p>
        Parece que você se esqueceu de colocar o token na URL
      </p>
      <p>Tente passar o seu token de acesso para esse pre-checkin como no exemplo:</p>
      <span>precheckin/token</span>
      <h2>Ou comunique o suporte do sapron</h2>
    </div>
  </Container>
);

export default RequestToken;
