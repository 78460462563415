import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)<{
  offsetTop: number;
  offsetLeft: number;
}>`
  z-index: 15;
  width: 200px;
  height: fit-content;
  padding: 10px;
  position: fixed;
  border-radius: 5px;
  background-color: #fff;

  top: ${({ offsetTop }) => `calc(${offsetTop}px + 10px)`};
  right: ${({ offsetLeft }) => `calc(${offsetLeft}px + 15px)`};

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #cccc;
  }

  .partners-container {
    overflow: hidden;
  }
`;

export const Content = styled.div`
  gap: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 10px 0;
  overflow-x: hidden;
`;

export const ItemContainer = styled(motion.div)`
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.8;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background: #000;
`;

export const Backdrop = styled(motion.button)`
  background: black;
  border: none;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 14;

  overflow: hidden;
`;
