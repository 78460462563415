import {
  FormControlLabel,
  styled,
  Switch,
  SwitchProps,
} from '@mui/material';

import * as React from 'react';
import { Container, Label } from './styles';

interface SwitcherButtonProps {
  selected: boolean;
  onChange: () => void;
  showLabel?: boolean;
  className?: string;
  idTestCypress?: string;
  style?: React.CSSProperties;
}

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  transition: 'all 0.3s',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? '#41B592' : '#41B592',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#41B592',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#9E9E9F' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const SwitcherButton = ({
  selected,
  onChange,
  showLabel,
  idTestCypress,
  className,
  style,
}: SwitcherButtonProps) => (
  <Container className={className} showLabel={showLabel}>
    {showLabel && <Label selected={!selected}>Não</Label>}

    <div>
      <FormControlLabel
        data-cy={idTestCypress}
        control={<IOSSwitch style={style} sx={{ m: 1 }} />}
        value={selected}
        checked={selected}
        onChange={onChange}
        label={false}
      />
    </div>

    {showLabel && (
    <Label green={selected} selected={selected}>
      Sim
    </Label>
    )}
  </Container>
);

SwitcherButton.defaultProps = {
  showLabel: true,
  idTestCypress: 'id',
  className: '',
  style: {},
};

export default SwitcherButton;
