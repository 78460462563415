import React, { useContext } from 'react';

import { useFormik } from 'formik';

import { Close, Search } from '@mui/icons-material';

import { InputAdornment } from '@mui/material';

import { CompReservationContext } from '../PaymentVoucher';

import {
  Container,
  SearchBar,
  BlankContainer,
  CloseContainer,
  GeneralSearchContainer,
} from './styles';

import { useToast } from '../../../context/ToastContext';

import Tooltip from '../../Tooltip';
import TextField from '../../TextField';

const Header = () => {
  const { addPaymentVoucher } = useContext(CompReservationContext);

  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: async (values) => {
      try {
        await addPaymentVoucher(values.search);
      } catch (error) {
        toast.error('Não foi possível obter os dados');
      }
    },
  });

  const handleResetValues = () => {
    formik.resetForm();
  };

  const handleClearSearch = () => {
    formik.setFieldValue('search', '');
    formik.handleSubmit();
  };

  const CloseButton = () => (
    <CloseContainer>
      <button onClick={handleClearSearch} type="button" disabled={formik.values.search === ''}>
        <Tooltip text="Limpar pesquisa">
          <Close />
        </Tooltip>
      </button>
    </CloseContainer>
  );

  return (
    <Container>
      <div>
        <h1>Comprovantes de pagamento</h1>
        <h2>Confirme os pagamentos</h2>
      </div>

      <GeneralSearchContainer>
        <Tooltip text="Aperte Enter para pesqusiar">
          <SearchBar onSubmit={formik.handleSubmit}>
            <TextField
              className="paymentVoucher-search"
              formik={formik}
              id="search"
              onClick={() => handleResetValues()}
              placeholder="Buscar dono da reserva"
              value={formik.values.search}
              startAdornment={(
                <BlankContainer>
                  <InputAdornment position="start" onClick={() => formik.handleSubmit()}>
                    <Search />
                  </InputAdornment>
                </BlankContainer>
          )}
            />
          </SearchBar>
        </Tooltip>
        <CloseButton />
      </GeneralSearchContainer>
    </Container>
  );
};

export default Header;
