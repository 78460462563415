import React from 'react';

import {
  ShoppingCartOutlined as CartIcon,
  Close,
} from '@mui/icons-material';

import NonePropertiesInCart from '../NonePropertiesInCart';
import CategoryCart from '../CategoryCart';

import {
  Cart,
  Container,
  HeaderModal,
  Title,
  AreaClose,
  ButtonCloseModal,
  ContentButtons,
  ButtonCancel,
  ButtonConfirm,
  ButtonCopy,
} from './styles';

import { useEstimateCart } from '../../../context/EstimateCart';
import { useUser } from '../../../context/UserContext';
import iconCopy from '../../../assets/icons/generals/copy.svg';

interface Props {
  handleCloseModalLateral: () => void;
  checkinDate: string;
  checkoutDate: string;
  handleOpenModalMonthly: () => void;
}

const ModalLateral = ({
  handleCloseModalLateral,
  handleOpenModalMonthly,
  checkinDate,
  checkoutDate,
}: Props): JSX.Element => {
  const { count, estimateCart } = useEstimateCart();
  const { userInformation } = useUser();
  const username = `${userInformation?.first_name || ''}`;

  const textCopy = () => {
    let text: string = `Olá primeiro nome do hóspede tudo bem? %0ASou ${userInformation?.gender === 'Female' ? 'a' : 'o'} *${username}* e serei responsável pelo seu atendimento. %0AComo solicitado, este é o orçamento e as possibilidades de locação para o período de *${checkinDate}* até *${checkoutDate}*:`;
    estimateCart.categories.forEach((item) => {
      text += `%0A%0A *${item.name}*`;
      item.properties.forEach((property) => {
        text += `%0A${property.code} - %0A(${property.address?.street},${property.address?.number}, ${property.address?.neighborhood}, ${property.address?.city})`;
      });
    });
    text += '%0A%0A Estou à disposição para qualquer esclarecimento que se fizer necessário. Esperamos concretizar a sua reserva em breve 😁 ';
    return text;
  };

  const copyToClipboard = (text: string | EventTarget | any) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  return (
    <>
      <AreaClose onClick={() => handleCloseModalLateral()} />
      <Container>
        <ButtonCloseModal onClick={() => handleCloseModalLateral()}>
          <Close />
          <p>Fechar</p>
        </ButtonCloseModal>
        <HeaderModal>
          <Title>Detalhes do aluguel</Title>
          <Cart>
            <CartIcon />
            <p>
              Selecionados:
              {' '}
              {count}
            </p>
          </Cart>
        </HeaderModal>
        {estimateCart.categories.map((item) => (
          <CategoryCart
            checkoutDate={checkoutDate}
            checkinDate={checkinDate}
            categoria={item.name}
            properties={item.properties}
          />
        ))}

        {estimateCart.categories.length > 0 && (
        <ButtonCopy onClick={() => copyToClipboard(textCopy())}>
          Copiar informações de orçamento
          <img src={iconCopy} alt="copiar" />
        </ButtonCopy>
        )}

        {estimateCart.categories.length <= 0 && (
          <NonePropertiesInCart />
        )}
        <ContentButtons>
          <ButtonCancel onClick={() => handleCloseModalLateral()}>Cancelar</ButtonCancel>
          <ButtonConfirm data-cy="btn-confirm" onClick={() => handleOpenModalMonthly()}>Confirmar</ButtonConfirm>
        </ContentButtons>
      </Container>
    </>
  );
};

export default ModalLateral;
