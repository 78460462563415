import { motion } from 'framer-motion';
import styled from 'styled-components';
import { getMaxWidthDesktop } from '../../style';

export const Container = styled(motion.div)`
  padding: 20px;
  padding-bottom: 100px;

  gap: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${getMaxWidthDesktop};

  &::-webkit-scrollbar {
    width: 0;
  }
`;
