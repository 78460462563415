import {
  CodeShimmer,
  BlockButton,
  ButtonsContainer,
  Divider,
  ExtractButton,
  PropertyCardContainer,
  PropertyInformationCardContainer,
  TextContainer,
  CurrencyShimmer,
  MonthShimmer,
  PropertyStatusContainer,
  StatusCircleShimmer,
  PropertyInformationContainer,
  TextShimmer,
} from './styles';

const IconStatement = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7L11 1Z" fill="none" stroke="#F9F9F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 1V7H17" stroke="#727272F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 12H5" stroke="#F9F9F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 16H5" stroke="#F9F9F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 8H6H5" stroke="#F9F9F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const IconBlock = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.936 0.501953L19.501 6.06692V13.937L13.936 19.502H6.06595L0.500977 13.937V6.06692L6.06595 0.501953H13.936ZM13.1076 2.50195H6.89437L2.50098 6.89534V13.1086L6.89437 17.502H13.1076L17.501 13.1086V6.89534L13.1076 2.50195ZM6.00024 9.00117H14.0002V11.0012H6.00024V9.00117Z" fill="#F9F9F9" />
  </svg>
);

export const CardShimmer = () => (
  <PropertyCardContainer>
    <PropertyInformationCardContainer>
      <PropertyInformationContainer>
        <CodeShimmer />
        <Divider isToHide />
        <TextContainer>
          <CurrencyShimmer />
          <MonthShimmer />
        </TextContainer>
      </PropertyInformationContainer>
      <Divider isToHide={false} />
      <PropertyStatusContainer>
        <StatusCircleShimmer />
        <TextShimmer />
      </PropertyStatusContainer>
    </PropertyInformationCardContainer>
    <ButtonsContainer>
      <ExtractButton
        type="button"
      >
        <IconStatement />
        Extrato
      </ExtractButton>
      <BlockButton
        type="button"
      >
        <IconBlock />
        Bloqueio
      </BlockButton>
    </ButtonsContainer>
  </PropertyCardContainer>
);
