import { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import iconHouse from '../../../../../assets/icons/generals/house.svg';

import { useFinancialStatement } from '../../../../../hooks/HostDashboardHook/HostGridFinances/useFinancialStatement';

import MonthViewerWithPortal from '../../../../MonthViewerWithPortal';
import { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import SimpleSelect from '../../../../SimpleSelect';

import {
  Content,
  Form,
  ContainerSelect,
  Label,
  DatePickerContainer,
} from './styles';

interface IFinancialStatementModal {
  propertiesCodes: SelectOption[];
}

const FinancialStatementModal = ({ propertiesCodes }: IFinancialStatementModal) => {
  const {
    period,
    setPeriod,
    setPropertyId,
  } = useFinancialStatement();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      property_id: undefined,
    },
    onSubmit: async () => {},
  });

  useEffect(() => {
    setPropertyId(!['', 'null', 'undefined'].includes(`${formik.values?.property_id}`) ? Number(formik.values?.property_id) : undefined);
  }, [formik.values?.property_id]);

  return (
    <Form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <Content>
        <ContainerSelect>
          <Label>Selecione o imóvel</Label>
          <SimpleSelect
            dataCy="select-properties-list"
            id="property_id"
            placeholder="Todos"
            formik={formik}
            icon={iconHouse}
            showIcon
            options={propertiesCodes || []}
          />
        </ContainerSelect>
        <DatePickerContainer>
          <Label>Selecione o período</Label>
          <MonthViewerWithPortal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            defaultDate={period}
            handleSetDate={(date: Date) => setPeriod(date)}
          />
        </DatePickerContainer>
      </Content>
    </Form>
  );
};

export default FinancialStatementModal;
