import {
  FC,
  useMemo,
  useEffect,
} from 'react';

import TextField from '../../../TextField';
import CardPropertyRule from './CardPropertyRule';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import usePropertyManager from '../../../../hooks/usePropertyManager';

import {
  Form,
  Container,
} from './styles';

import { formatPropertiesDropdown } from '../../utils';
import { UseFormik } from '../../../../utils/Formik/types';
import { Rules } from '../../../../context/InsertPropertyRulesContext/types';
import { getPropertyRulesById } from '../../../../services/InsertData/request';
import { usePropertyRules } from '../../../../hooks/usePropertyRules/usePropertyRules';
import { initialValuesRules } from '../../../../context/InsertPropertyRulesContext/utils';

interface PropertyRulesFormikValuesType {
  id: number;
  property: number,
  check_in_time: string;
  check_out_time: string;
}

type FormAddRulesProps = {
  formik?: UseFormik<PropertyRulesFormikValuesType>,
};

const FormAddPropertyRules:FC<FormAddRulesProps> = ({ formik }) => {
  const {
    data: properties,
    loading: propertiesLoad,
  } = usePropertyManager();

  const {
    allRules,
    handleUpdateRule,
    handleSetAllRules,
  } = usePropertyRules();

  const filteredProperties = useMemo(() => {
    const resolver = properties?.filter((property) => property.status === 'Active' || property.status === 'Onboarding');
    return resolver;
  }, [properties]);

  const formatted = useMemo(() => {
    const result = formatPropertiesDropdown(filteredProperties);
    return result;
  }, [filteredProperties]);

  async function handlePropertyInitialValues(id: number) {
    const results: any = await getPropertyRulesById(id);
    if (results?.length > 0) {
      let newRules: Rules[] = [];

      allRules.forEach((rule) => {
        newRules = [...newRules, {
          ...rule,
          value: rule.key in results[0] ? results[0]?.[rule.key] : false,
        }];
      });
      formik?.setFieldValue('id', results[0]?.id);
      formik?.setFieldValue('check_in_time', results[0]?.check_in_time);
      formik?.setFieldValue('check_out_time', results[0]?.check_out_time);

      handleSetAllRules(newRules);
    } else {
      formik?.setFieldValue('check_in_time', '');
      formik?.setFieldValue('check_out_time', '');
      handleSetAllRules(initialValuesRules);
    }
  }

  useEffect(() => {
    if (formik?.values.property && formik?.values.property !== -1) {
      handlePropertyInitialValues(formik?.values.property);
    }
  }, [formik?.values.property]);

  return (
    <Container>
      <Form>
        <div className="two-columns">
          <DropdownAutocomplete
            required
            id="property"
            formik={formik}
            options={formatted}
            loading={propertiesLoad}
            label="Selecione o Imóvel"
            labelClassName="labelClass"
          />
        </div>
        <div className="two-columns">
          <TextField
            required
            mask="hour"
            formik={formik}
            id="check_in_time"
            placeholder="00:00"
            labelClassName="labelClass"
            label="Horário de Check-in"
            value={formik?.values.check_in_time}
          />
          <TextField
            required
            mask="hour"
            formik={formik}
            id="check_out_time"
            placeholder="00:00"
            labelClassName="labelClass"
            label="Horário de Check-out"
            value={formik?.values.check_out_time}
          />
        </div>
        <div className="one-column">
          {allRules.map((rule) => (
            <CardPropertyRule
              required
              rules={rule}
              updateRule={handleUpdateRule}
            />
          ))}
        </div>
      </Form>
    </Container>
  );
};

export default FormAddPropertyRules;
