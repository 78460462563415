import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Grid } from '@mui/material';

import Backdrop from '../../../../../Backdrop';
import TextField from '../../../../../TextField';
import FormButton from '../../../../../FormButton';
import FormGridRow from '../../../../../FormGridRow';

import { useToast } from '../../../../../../context/ToastContext';
import { postLocation } from '../../../../../../services/location/request';
import { CreateLocationProps } from '../../../../../../services/location/types';
import {
  ErrorMessage,
  useToastErrorMessage,
} from '../../../../../../utils/Messages';
import {
  usePropertyCategoryLocation,
} from '../../../../../../hooks/usePropertyCategoryLocation/usePropertyCategoryLocation';

import {
  Container,
  Content,
  FormContent,
  ButtonsContainer,
} from './styles';
import { useIBGE } from '../../../../../../hooks/useIBGE/useIBGE';
import SimpleSelect from '../../../../../SimpleSelect/SimpleSelect';

const NewLocationCodeModal = () => {
  const {
    locations,
    setOpenModalNewLocationCode,
    handleAddLocation,
  } = usePropertyCategoryLocation();

  const toast = useToast();

  const toastErrorRequest = useToastErrorMessage();

  const handleCloseModal = () => {
    setOpenModalNewLocationCode(false);
  };

  const validation = Yup.object().shape({
    city: Yup.string().required('Campo obrigatório'),
    state: Yup.string().required('Campo obrigatório'),
    region: Yup.string().required('Campo obrigatório'),
    country: Yup.string().required('Campo obrigatório'),
    neighborhood: Yup.string().required('Campo obrigatório'),
    code: Yup.string().min(3, 'Campo obrigatório').required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      city: '',
      code: '',
      state: '',
      region: '',
      country: '',
      neighborhood: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const locationAlreadyExists = locations.some((item) => item.location.toUpperCase().trim()
        === values.code.toUpperCase().trim());

        if (locationAlreadyExists) {
          toast.alert('O código da localização informada já existe!');
        } else {
          const payload: CreateLocationProps = {
            neighborhood: values.neighborhood,
            city: values.city,
            country: values.country,
            code: values.code.toUpperCase().trim(),
            state: values.state,
            region: values.region,
          };

          const response = await postLocation(payload);
          handleAddLocation(response);
          handleCloseModal();
          toast.success('Código da localização criado com sucesso!');
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error(e.message || ErrorMessage.default());
          toastErrorRequest(e);
        }
      }
    },
  });

  const { citys, country, states } = useIBGE({
    uf: formik.values.state,
    enable: {
      country: true,
    },
  });

  return (
    <Backdrop onClose={handleCloseModal}>
      <Container
        onSubmit={formik.handleSubmit}
        onContextMenu={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      >
        <Content>
          <FormGridRow grid>
            <Grid item sm={12} xs={12}>
              <TextField
                formik={formik}
                id="code"
                required
                label="Código"
                placeholder="Digite aqui..."
                labelClassName="labelClass"
                value={formik.values.code.toUpperCase()}
              />
            </Grid>
          </FormGridRow>

          <FormGridRow grid>
            <Grid item sm={12} xs={12}>
              <TextField
                formik={formik}
                id="neighborhood"
                required
                label="Bairro"
                placeholder="Digite aqui..."
                labelClassName="labelClass"
              />
            </Grid>
          </FormGridRow>

          <FormGridRow grid>
            <FormContent>
              <Grid item sm={6} xs={12}>
                <SimpleSelect
                  required
                  id="state"
                  label="Estado"
                  labelClassName="labelClass"
                  placeholder="Selecione"
                  formik={formik}
                  options={(states.data || [])?.map((item) => ({
                    value: item.sigla,
                    valueLabel: item.nome,
                  }))}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <SimpleSelect
                  required
                  id="city"
                  label="Cidades"
                  formik={formik}
                  placeholder="Selecione"
                  labelClassName="labelClass"
                  disabled={(formik.values.state.length < 1)}
                  options={(citys.data || [])?.map((item) => ({
                    value: item.nome,
                    valueLabel: item.nome,
                  }))}
                />
              </Grid>
            </FormContent>
          </FormGridRow>

          <FormGridRow grid>
            <FormContent>
              <Grid item sm={6} xs={12}>
                <TextField
                  formik={formik}
                  id="region"
                  required
                  label="Região"
                  placeholder="Digite aqui..."
                  labelClassName="labelClass"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <SimpleSelect
                  required
                  id="country"
                  label="País"
                  formik={formik}
                  placeholder="Selecione"
                  disabled={!country.loading}
                  labelClassName="labelClass"
                  options={(country.data || [])?.map((item) => ({
                    value: item.nome.abreviado,
                    valueLabel: item.nome.abreviado,
                  }))}
                />
              </Grid>
            </FormContent>
          </FormGridRow>
        </Content>
        <ButtonsContainer>
          <FormButton type="button" variant="outlined" onClick={() => handleCloseModal()}>Cancelar</FormButton>
          <FormButton type="submit">Salvar</FormButton>
        </ButtonsContainer>
      </Container>
    </Backdrop>
  );
};

export default NewLocationCodeModal;
