import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 30px 30px 20px 30px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  box-shadow: rgba(0, 0, 0, 0.096) 0px 25px 20px -20px;

  opacity: 0;
  animation: ${fadeIn} 0.5s 0.6s ease forwards;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 1.3rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: bold;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
`;

export const FormContainer = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
