import { useDate } from '../../hooks/DateHook/useDate';

import Portal from '../Portal';
import CustomCalendar from './CustomCalendar';
import IconCalendar from './components/IconCalendar';

import { Container, Label } from './styles';

interface MonthProps {
  withShortMonths?: boolean;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  defaultDate?: Date;
  handleSetDate?: (date: Date) => void;
}

const MonthViewerWithPortal = ({
  withShortMonths = false,
  isModalVisible = false,
  setIsModalVisible,
  defaultDate,
  handleSetDate,
}: MonthProps) => {
  const { dateSelected } = useDate();

  const months = withShortMonths
    ? ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    : [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

  const handleSetIsModalVisible = (value?: boolean) => {
    setIsModalVisible(value || !isModalVisible);
  };

  return (
    <>
      {isModalVisible && (
        <Portal onClose={() => handleSetIsModalVisible(false)}>
          <CustomCalendar
            id="monthModalViewer"
            defaultValue={defaultDate || dateSelected}
            onClose={() => handleSetIsModalVisible(false)}
            defaultView="year"
            handleSetDate={(date: Date) => {
              if (handleSetDate) {
                handleSetDate(date);
                handleSetIsModalVisible(false);
              }
            }}
          />
        </Portal>
      )}

      <Container onClick={() => handleSetIsModalVisible(!isModalVisible)}>
        <IconCalendar />
        <Label>{`${months[dateSelected.getMonth()]} - ${dateSelected.getFullYear()}`}</Label>
      </Container>
    </>
  );
};

MonthViewerWithPortal.defaultProps = {
  withShortMonths: false,
  defaultDate: new Date(),
  handleSetDate: (date: Date) => date,
};

export default MonthViewerWithPortal;
