import { useInfiniteQuery } from 'react-query';
import { getHostProperties } from '../../services/HostProperties/request';

export function useHostProperties(
  code: string | undefined,
  status: string | undefined,
  bedroomsQuantity: number,
  location: string | undefined,
  hostNameFilter: string | undefined,
) {
  return useInfiniteQuery(
    ['hostProperties', code, status, bedroomsQuantity, location, hostNameFilter], async ({ pageParam = 1 }) => {
      const data = await getHostProperties(
        pageParam,
        code,
        status,
        bedroomsQuantity,
        location,
        hostNameFilter,
      );
      return data;
    }, {
      getNextPageParam: (lastPage) => {
        const currentPage = lastPage?.current_page || 1;
        const nextPage = lastPage?.next || null;
        const totalPages = lastPage?.total_pages || 1;

        if ((currentPage < totalPages) && nextPage) {
          const urlParams = new URLSearchParams(nextPage);
          return urlParams.get('page');
        }

        return undefined;
      },
      staleTime: 1000 * 60 * 15,
    },
  );
}
