import { Number as InputMaskOptions } from '../../utils/InputMask';

export type IMaskOptions = 'money' | 'cpf' | 'cnpj'| 'cpfOrCnpj' | 'cep' | 'brPhone' |'phone' | 'hour' | 'agency' | 'account' | 'internationalPhone';

export const masksRecord: Record<IMaskOptions, (value: string) => string> = {
  money: InputMaskOptions.currency,
  cpf: InputMaskOptions.cpf,
  cnpj: InputMaskOptions.cnpj,
  cpfOrCnpj: InputMaskOptions.cpfOrCnpj,
  cep: InputMaskOptions.cep,
  phone: InputMaskOptions.phone,
  brPhone: InputMaskOptions.brPhone,
  internationalPhone: InputMaskOptions.internationalPhone,
  hour: InputMaskOptions.hour,
  agency: InputMaskOptions.agency,
  account: InputMaskOptions.account,
};
