import { IIndicationsStatus } from '../../types';

export const statusText = [
  {
    name: 'Lost',
    translated: 'Perda',
    color: '#FB8181',
  },
  {
    name: 'Canceled',
    color: '#cccccc',
    translated: 'Cancelado',
  },
  {
    name: 'Won',
    color: '#0ACF83',
    translated: 'Aprovado',
  },
  {
    name: 'In_Progress',
    color: '#F6CE91',
    translated: 'Em andamento',
  },
];

export function findStatus(status: IIndicationsStatus) {
  const newStatus = `${status}`.toLowerCase();
  const resolve = statusText.find((item) => item.name.toLowerCase() === newStatus);

  if (resolve) {
    return resolve;
  }

  return statusText[0];
}
