import {
  Zoom,
  styled,
  Divider,
  TooltipProps,
  tooltipClasses,
  Tooltip as MuiTooltip,
} from '@mui/material';

import React from 'react';

import {
  Content, Section, Backdrop, Container,
} from './style';

import {
  formatDateToShow,
  formatHourToShow,
} from '../../../../../../../../../../utils/Formatter';

import { useMobile } from '../../../../../../../../../../hooks/useMobile/useMobile';
import { usePartners } from '../../../../../../../../../../context/Partners/Partner/PartnerContext';

const Info = ({ ...rest }) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM13.5 16.5C13.5 16.6989 13.421 16.8897 13.2803 17.0303C13.1397 17.171 12.9489 17.25 12.75 17.25C12.3522 17.25 11.9706 17.092 11.6893 16.8107C11.408 16.5294 11.25 16.1478 11.25 15.75V12C11.0511 12 10.8603 11.921 10.7197 11.7803C10.579 11.6397 10.5 11.4489 10.5 11.25C10.5 11.0511 10.579 10.8603 10.7197 10.7197C10.8603 10.579 11.0511 10.5 11.25 10.5C11.6478 10.5 12.0294 10.658 12.3107 10.9393C12.592 11.2206 12.75 11.6022 12.75 12V15.75C12.9489 15.75 13.1397 15.829 13.2803 15.9697C13.421 16.1103 13.5 16.3011 13.5 16.5ZM10.5 7.875C10.5 7.6525 10.566 7.43499 10.6896 7.24998C10.8132 7.06498 10.9889 6.92078 11.1945 6.83564C11.4001 6.75049 11.6263 6.72821 11.8445 6.77162C12.0627 6.81502 12.2632 6.92217 12.4205 7.0795C12.5778 7.23684 12.685 7.43729 12.7284 7.65552C12.7718 7.87375 12.7495 8.09995 12.6644 8.30552C12.5792 8.51109 12.435 8.68679 12.25 8.8104C12.065 8.93402 11.8475 9 11.625 9C11.3266 9 11.0405 8.88147 10.8295 8.6705C10.6185 8.45952 10.5 8.17337 10.5 7.875Z"
      fill="#394760"
    />
  </svg>
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    margin: 10,
    maxWidth: 220,
    maxHeight: 300,
    lineHeight: 1.3,
    overflowY: 'scroll',
    backgroundColor: '#ffffff',
    border: '1px solid #dadde9',
    borderRadius: 10,

    '& h1': {
      fontSize: '1.1rem',
      fontWeight: 600,
      margin: 0,
      color: 'rgba(0, 0, 0, 0.87)',
    },

    '& h2': {
      fontSize: '1rem',
      fontWeight: 400,
      margin: 0,
      marginBottom: 10,
      color: 'rgba(0, 0, 0, 0.87)',

      '&:last-child': {
        marginBottom: 0,
      },
    },

    '&::-webkit-scrollbar': {
      width: 3,
      borderRadius: 2,
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: 2,
      background: '#f1f1f1',
    },
  },
}));

interface ITooltip {
  changedDate: Date;
  name: string;
  phone: string;
  status: string;
  comment: string;

  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;

  street?: string;
  number?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  type?: string;
  allotmentValue?: string;
}

const Tooltip = ({
  name,
  changedDate,
  phone,
  status,
  comment,
  open,
  setOpen,
  city,
  state,
  number,
  street,
  neighborhood,
  type,
  allotmentValue,
}: ITooltip) => {
  const { isMobile } = useMobile();
  const { setHideElements } = usePartners();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (isMobile) {
      if (open) {
        setHideElements((old) => ({
          ...old,
          whatsapp: {
            is: true,
          },
          header: {
            is: true,
          },
          footer: {
            is: true,
          },
        }));
      } else {
        setHideElements((old) => ({
          ...old,
          whatsapp: {
            is: false,
          },
          header: {
            is: false,
            animate: false,
          },
          footer: {
            is: false,
            animate: false,
          },
        }));
      }
    }
  }, [open]);

  const modificationDate = formatDateToShow(changedDate);
  const modificationHour = formatHourToShow(changedDate);

  return (
    <Container>
      <HtmlTooltip
        open={open}
        enterTouchDelay={0}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleClose}
        title={(
          <Content>
            {type !== 'terreno' && (
              <>
                <Section>
                  <h1>Nome:</h1>
                  <h2>{name}</h2>
                </Section>
                <Divider />
                <Section>
                  <h1>Telefone:</h1>
                  <h2>{phone}</h2>
                </Section>
                <Divider />
              </>
            )}
            <Section>
              <h1 style={{ marginBottom: '2px' }}>Endereço: </h1>

              {city && (
              <h2>
                Cidade:
                {' '}
                {city}
              </h2>
              )}

              {state && (
              <h2>
                Estado:
                {' '}
                {state}
              </h2>
              )}

              {street && (
              <h2>
                Rua:
                {' '}
                {street}
              </h2>
              )}

              {neighborhood && (
              <h2>
                Bairro:
                {' '}
                {neighborhood}
              </h2>
              )}

              {number && (
              <h2>
                Número:
                {' '}
                {number}
              </h2>
              )}

              {allotmentValue && (
                <h2>
                  Valor:
                  {' '}
                  {allotmentValue}
                </h2>
              )}
            </Section>

            <Divider />

            <Section>
              <h1 style={{ marginBottom: '5px' }}>Ultima modificação:</h1>
              <h2>
                Data:
                {' '}
                {modificationDate}
              </h2>

              <h2>
                Hora:
                {' '}
                {modificationHour}
              </h2>
            </Section>
            {type !== 'terreno' && (
              <>
                <Divider />
                <Section>
                  <h1>Status da indicação:</h1>
                  <h2>{status}</h2>
                </Section>
              </>
            )}
            {comment && (
              <>
                <Divider />
                <Section>
                  <h1>Comentário:</h1>
                  <h2>{comment}</h2>
                </Section>
              </>
            )}
          </Content>
        )}
        TransitionComponent={Zoom}
        PopperProps={{ disablePortal: false }}
        style={{ opacity: 0.8, fontSize: 20 }}
      >
        <button type="button" style={{ border: 'none', background: 'transparent', cursor: 'pointer' }} id="info" onClick={handleOpen}>
          <Info
            style={{
              fill: open ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.7)',
            }}
          />
        </button>
      </HtmlTooltip>
      {open && <Backdrop onClick={handleClose} />}
    </Container>
  );
};

Tooltip.defaultProps = {
  city: undefined,
  state: undefined,
  number: undefined,
  street: undefined,
  neighborhood: undefined,
  type: undefined,
  allotmentValue: undefined,
};

export default Tooltip;
