import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { compareList } from '../../../../../utils/Sorting';
import DropdownAutocomplete from '../../../../DropdownAutocomplete/DropdownAutocomplete';
import FormButton from '../../../../FormButton/FormButton';
import NewDatePickerRange from '../../../../NewDatePickerRange';
import SimpleSelect, { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import { SearchFormContainer } from './styles';
import usePropertiesList from '../../../../../hooks/GuestDamage/usePropertiesList';
import { GetGuestDamageNegotiationParams } from '../../../../../services/GuestDamage/types';
import { getGuestDamageNegotiation } from '../../../../../services/GuestDamage/request';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../../utils/Messages';
import { formatPropertiesDropdown } from '../../../utils/format-properies-to-dropdown';

const statusOptions: SelectOption[] = [
  { value: 'Tratativa passou para o atendimento', valueLabel: 'Tratativa passou para o atendimento' },
  { value: 'Anfitrião finalizou as pendências', valueLabel: 'Anfitrião finalizou as pendências' },
  { value: 'Atendimento verificou pendências na validação de informações', valueLabel: 'Atendimento verificou pendências na validação de informações' },
  { value: 'Cobrança solicitada pelo atendimento', valueLabel: 'Cobrança solicitada pelo atendimento' },
  { value: 'Cobrança em disputa com o hóspede', valueLabel: 'Cobrança em disputa com o hóspede' },
  { value: 'Cobrança pendente, ainda em tratativa', valueLabel: 'Cobrança pendente, ainda em tratativa' },
  { value: 'Tratativa finalizada. Aguardando pagamento', valueLabel: 'Tratativa finalizada. Aguardando pagamento' },
  { value: 'Atendimento realizou todas as tratativas porém não obteve sucesso', valueLabel: 'Atendimento realizou todas as tratativas porém não obteve sucesso' },
  { value: 'Pagamento recebido, encaminhado pelo financeiro', valueLabel: 'Pagamento recebido, encaminhado pelo financeiro' },
  { value: 'Dano cobrado. Repasse realizado ao dono do reembolso', valueLabel: 'Dano cobrado. Repasse realizado ao dono do reembolso' },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const transferredRefundOptions: SelectOption[] = [
  { value: 'true', valueLabel: 'Sim' },
  { value: 'false', valueLabel: 'Não' },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const stageOptions: SelectOption[] = [
  { value: 'Detalhes do dano', valueLabel: 'Detalhes do dano' },
  { value: 'Histórico e tratativas de cobrança', valueLabel: 'Histórico e tratativas de cobrança' },
  { value: 'Reembolso do dano', valueLabel: 'Reembolso do dano' },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

interface SearchFormProps {
  havePendency: boolean;
}

export const SearchForm = ({ havePendency }: SearchFormProps) => {
  const { setGuestDamageNegotiations, setIsLoading } = useGuestDamageNegotiation();
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const formik = useFormik<GetGuestDamageNegotiationParams>({
    initialValues: {
      property_code: undefined,
      status: undefined,
      stage: undefined,
      is_refunded: undefined,
      start_date: undefined,
      end_date: undefined,
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const filters: GetGuestDamageNegotiationParams = {
          property_code: values.property_code,
          is_refunded: values.is_refunded,
          stage: values.stage,
          status: values.status,
          start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : undefined,
          end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : undefined,
        };
        const data = await getGuestDamageNegotiation(filters);
        if (data.length === 0) {
          setIsLoading(false);
          toast.alert('Nenhum dano encontrado!');
        } else {
          setIsLoading(false);
          setGuestDamageNegotiations(data);
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsLoading(false);
          toastErrorRequest(e);
        }
      }
    },
  });

  const {
    data: properties,
    loading,
  } = usePropertiesList();

  useEffect(() => {
    if (havePendency) {
      formik.setFieldValue('status', 'Atendimento verificou pendências na validação de informações');
      formik.handleSubmit();
    }
  }, []);

  const filteredProperties = useMemo(() => {
    const resolver = properties?.filter((property) => property.status === 'Active' || property.status === 'Onboarding');
    return resolver;
  }, [properties]);

  const formattedPropertiesToShow = useMemo(() => {
    const resolver = formatPropertiesDropdown(filteredProperties);
    return resolver;
  }, [filteredProperties]);

  return (
    <SearchFormContainer onSubmit={formik.handleSubmit}>
      <DropdownAutocomplete
        label="Imóvel"
        id="property_code"
        options={formattedPropertiesToShow}
        placeholder="Digite aqui..."
        formik={formik}
        loading={loading}
      />
      <SimpleSelect
        options={statusOptions}
        label="Status"
        id="status"
        placeholder="Selecione"
        formik={formik}
      />
      <NewDatePickerRange
        formik={formik}
        label="Período"
        id1="start_date"
        id2="end_date"
        startDatePlaceholderText="Data inicial"
        endDatePlaceholderText="Data final"
      />
      <SimpleSelect
        options={transferredRefundOptions}
        label="Reembolso Repassado"
        id="is_refunded"
        formik={formik}
        placeholder="Selecione"
      />
      <SimpleSelect
        options={stageOptions}
        label="Etapa"
        formik={formik}
        id="stage"
        placeholder="Selecione"
      />
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        width: '100%',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
      >
        <div style={{ padding: '3px' }} />
        <FormButton>Buscar</FormButton>
      </div>
    </SearchFormContainer>
  );
};
