import { ContentCopy } from '@mui/icons-material';
import React from 'react';
import { GetOwnerRequest } from '../../../services/Owner/types';
import Tooltip from '../../Tooltip';

import { Container, Name, Text } from './styles';

interface Props {
  owner: GetOwnerRequest;
  setOpenModal: (a: boolean) => void;
  setOwnerSelected: (a: GetOwnerRequest) => void;
}

const LineGrid = ({
  owner, setOpenModal, setOwnerSelected,
}: Props) => {
  function handleClick() {
    setOwnerSelected(owner);
    setOpenModal(false);
  }

  const copyToClipboard = (text: string | EventTarget | any) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Container>
      <Name onClick={handleClick}>
        {`${owner.user.first_name} ${owner.user.last_name}` || owner.user.corporate_name}
      </Name>
      <Text>
        <p>
          {owner.user.phone_number1}
          {' '}
        </p>
        <Tooltip text="Copiar telefone">
          <ContentCopy onClick={() => copyToClipboard(owner.user.phone_number1)} />
        </Tooltip>
      </Text>
      <Text className="email">
        <p>
          {owner.user.email}
          {' '}
        </p>
        <Tooltip text="Copiar e-mail">
          <ContentCopy onClick={() => copyToClipboard(owner.user.email)} />
        </Tooltip>
      </Text>
      <Text className="qtd-property"><p>{owner.properties}</p></Text>

    </Container>
  );
};

export default LineGrid;
