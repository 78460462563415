/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {
  FC,
  useState,
  useEffect,
  Fragment,
  KeyboardEvent,
  memo,
  useMemo,
} from 'react';

import moment from 'moment';

import { useFormik } from 'formik';
import { useInView } from 'react-intersection-observer';

import theme from '../../../../styles/themes';
import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../assets/icons/generals/arrowTop.svg';
import arrowBottomWhite from '../../../../assets/icons/generals/arrowBottomWhite.svg';
import arrowTopWhite from '../../../../assets/icons/generals/arrowTopWhite.svg';
import copyIcon from '../../../../assets/icons/generals/copy.svg';
import addIcon from '../../../../assets/icons/financialClose/add.svg';

import {
  Container,
  Content,
  ContainerTable,
  Table,
  TableHeader,
  TableBody,
  Tr, Th, Td,
  ThContainer,
  TdContainer,
  TitleContainer,
  Title,
  Subtitle,
  ContainerArrows,
  Image,
  MoreVertButtonContainer,
  MoreVertButton,
  MoreVertIcon,
  RadioButton,
  ExpandIcon,
  CollapseIcon,
  KeyboardArrowUpIcon,
  WrapperGridRow,
  ContainerGridRow,
  CollapseRow,
  manualFitColor,
  ContainerInformation,
  ContentInformation,
  TextFieldContainer,
  DatePickerContainer,
  PencilIcon,
  CheckButton,
  CheckIcon,
  CancelButton,
  CancelIcon,
  ContainerSpinner,
  Spinner,
  DropdownContainer,
  ReservationCode,
  CopyContainer,
} from '../styles';

import TextField from '../../../TextField';
import SimpleSelect from '../../../SimpleSelect';
import Tooltip from '../../../Tooltip';
import DatePicker from '../../../DatePicker';
import TransferIcon from '../../../IconsComponents/TransferIcon';

import { useFinancialClose } from '../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseProperty } from '../../../../hooks/FinancialCloseHook/useFinancialCloseProperty';

import { useToast } from '../../../../context/ToastContext';
import useKeys from '../../../../hooks/GlobalHook/useKeys';

import {
  ExpenseProps,
  ReservationProps,
  GridPropertyProps,
  OrderGridOptions,
} from '../../../../context/FinancialClosePage/FinancialClosePropertyContext/types';

import {
  sortFinancialDataOfProperty,
} from '../../../../context/FinancialClosePage/FinancialClosePropertyContext/utils';

import { currencyToNumber, formatedDateOfRequest }
  from '../../../../utils/Formatter';
import { translatedPaidBy, translatePropertyStatus, translateReservationStatus } from '../../../../utils/Translator';
import { currency } from '../../../../utils/InputMask/Number';

import { ReservationPatchProps, ReservationStatus } from '../../../../services/Reservation/types';
import { patchReservation } from '../../../../services/Reservation/request';
import { ExpensePatchProps } from '../../../../services/Expenses/types';
import { patchExpenses } from '../../../../services/Expenses/request';

type EditTextFieldProps = {
  id: number;
  isEdit: boolean;
};

const Copy = () => (
  <img src={copyIcon} alt="icone de copiar" className="copyIcon" />
);

const GridProperty: FC = () => {
  const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    openActions,
    handleOpenActions,
    actionSelected,
    activeLinearProgress,
  } = useFinancialClose();

  const {
    financialDataProperty,
    handleSetFinancialDataProperty,
    handleUpdateReservationProperty,
    handleUpdateExpenseProperty,
    handleUpdateCheckedOfPropertySelected,
    handleUpdateAllCheckedsOfPropertySelected,
    handleUpdateExpandedOfPropertySelected,
    handleOpenModalAddManualFitProperty,
    handleOpenModalEditManualFitProperty,
    handleOpenModalAddManualFitDaily,
    handleOpenModalEditManualFitDaily,
    handleOpenModalAddManualFitCleaning,
    handleOpenModalEditManualFitCleaning,
  } = useFinancialCloseProperty();

  const propertiesSelected = useMemo(() => financialDataProperty
    .filter((item) => item.checked), [financialDataProperty]);

  const [expandCommission, setExpandCommission] = useState<boolean>(false);
  const [expandRevenue, setExpandRevenue] = useState<boolean>(false);
  const [expandIncome, setExpandIncome] = useState<boolean>(false);
  const [expandExpense, setExpandExpense] = useState<boolean>(false);
  const [expandRefund, setExpandRefund] = useState<boolean>(false);

  const [editTotalPrice, setEditTotalPrice] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editDailyValue, setEditDailyValue] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editDailyNetValue, setEditDailyNetValue] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editCleaningValue, setEditCleaningValue] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editCleaningNetValue, setEditCleaningNetValue] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editOTAComission, setEditOTAComission] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editCheckin, setEditCheckin] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editCheckout, setEditCheckout] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editStatus, setEditStatus] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [editExpensePaidBy, setEditExpensePaidBy] = useState<EditTextFieldProps>({
    id: -1,
    isEdit: false,
  });

  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'property',
    order: 'asc',
  });

  const DEFAULT_LIMIT = 15;
  const [limitFinancialData, setLimitFinancialData] = useState<number>(financialDataProperty.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : financialDataProperty.length);

  const [isLoadMoreDatas, setIsLoadMoreDatas] = useState<boolean>(false);

  const { ref, inView } = useInView();

  const toast = useToast();

  const shorcutESC = useKeys('Escape');

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const fieldReservationTranslated: Record<string, string> = {
    total_price: 'Valor',
    gross_daily_value: 'Valor',
    daily_net_value: 'Valor',
    cleaning_fee_value: 'Valor',
    cleaning_net_value: 'Valor',
    check_in_date: 'Check-in',
    check_out_date: 'Check-out',
    ota_comission: 'Valor',
    status: 'Status',
  };

  const formik = useFormik({
    initialValues: {
      total_price: 'R$ 0,00',
      gross_daily_value: 'R$ 0,00',
      daily_net_value: 'R$ 0,00',
      cleaning_fee_value: 'R$ 0,00',
      cleaning_net_value: 'R$ 0,00',
      ota_comission: 'R$ 0,00',
      conciliada: true,
      paid_by: '',
      check_in_date: today,
      check_out_date: tomorrow,
      status: '',
    },
    onSubmit: () => { },
  });

  const handleEditFieldOfReservation = (fieldClicked: string, reservation: ReservationProps) => {
    const editing: EditTextFieldProps = {
      id: reservation.id,
      isEdit: true,
    };

    if (fieldClicked === 'total_price') {
      formik.setFieldValue('total_price', currency(reservation.total_value));
      setEditTotalPrice({ ...editing });
    } else if (fieldClicked === 'gross_daily_value') {
      formik.setFieldValue('gross_daily_value', currency(reservation.gross_daily_value));
      setEditDailyValue({ ...editing });
    } else if (fieldClicked === 'daily_net_value') {
      formik.setFieldValue('daily_net_value', currency(reservation.daily_net_value));
      setEditDailyNetValue({ ...editing });
    } else if (fieldClicked === 'cleaning_fee_value') {
      formik.setFieldValue('cleaning_fee_value', currency(reservation.cleaning_fee_value));
      setEditCleaningValue({ ...editing });
    } else if (fieldClicked === 'cleaning_net_value') {
      formik.setFieldValue('cleaning_net_value', currency(reservation.cleaning_net_value));
      setEditCleaningNetValue({ ...editing });
    } else if (fieldClicked === 'ota_comission') {
      formik.setFieldValue('ota_comission', currency(reservation.ota_comission));
      setEditOTAComission({ ...editing });
    } else if (fieldClicked === 'check_in_date') {
      const checkinDate = formatedDateOfRequest(reservation.check_in_date, 'DD/MM/YYYY');
      formik.setFieldValue('check_in_date', moment(checkinDate).toDate());
      setEditCheckin({ ...editing });
    } else if (fieldClicked === 'check_out_date') {
      const checkoutDate = formatedDateOfRequest(reservation.check_out_date, 'DD/MM/YYYY');
      formik.setFieldValue('check_out_date', moment(checkoutDate).toDate());
      setEditCheckout({ ...editing });
    } else if (fieldClicked === 'status') {
      formik.setFieldValue('status', reservation.status);
      setEditStatus({ ...editing });
    }
  };

  const handleResetFieldOfReservationEdited = (fieldToUpdate: string) => {
    if (fieldToUpdate === 'total_price') {
      setEditTotalPrice({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('total_price', 'R$ 0,00');
    } else if (fieldToUpdate === 'gross_daily_value') {
      setEditDailyValue({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('gross_daily_value', 'R$ 0,00');
    } else if (fieldToUpdate === 'daily_net_value') {
      setEditDailyNetValue({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('daily_net_value', 'R$ 0,00');
    } else if (fieldToUpdate === 'cleaning_fee_value') {
      setEditCleaningValue({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('cleaning_fee_value', 'R$ 0,00');
    } else if (fieldToUpdate === 'cleaning_net_value') {
      setEditCleaningNetValue({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('cleaning_net_value', 'R$ 0,00');
    } else if (fieldToUpdate === 'ota_comission') {
      setEditOTAComission({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('ota_comission', 'R$ 0,00');
    } else if (fieldToUpdate === 'check_in_date') {
      setEditCheckin({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('check_in_date', today);
    } else if (fieldToUpdate === 'check_out_date') {
      setEditCheckout({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('check_out_date', tomorrow);
    } else if (fieldToUpdate === 'status') {
      setEditStatus({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('status', '');
    }
  };

  const handleUpdateReservationState = (fieldUpdated: string,
    currentReservation: ReservationProps) => {
    let reservationUpdated: ReservationProps = {
      ...currentReservation,
      conciliada: true,
    };

    if (fieldUpdated === 'total_price') {
      reservationUpdated = {
        ...reservationUpdated,
        total_value: currency(formik.values.total_price),
      };
    } else if (fieldUpdated === 'gross_daily_value') {
      reservationUpdated = {
        ...reservationUpdated,
        gross_daily_value: currency(formik.values.gross_daily_value),
      };
    } else if (fieldUpdated === 'daily_net_value') {
      reservationUpdated = {
        ...reservationUpdated,
        daily_net_value: currency(formik.values.daily_net_value),
      };
    } else if (fieldUpdated === 'cleaning_fee_value') {
      reservationUpdated = {
        ...reservationUpdated,
        cleaning_fee_value: currency(formik.values.cleaning_fee_value),
      };
    } else if (fieldUpdated === 'cleaning_net_value') {
      reservationUpdated = {
        ...reservationUpdated,
        cleaning_net_value: currency(formik.values.cleaning_net_value),
      };
    } else if (fieldUpdated === 'ota_comission') {
      reservationUpdated = {
        ...reservationUpdated,
        ota_comission: currency(formik.values.ota_comission),
      };
    } else if (fieldUpdated === 'check_in_date') {
      reservationUpdated = {
        ...reservationUpdated,
        check_in_date: moment(formik.values.check_in_date).format('DD/MM/YYYY'),
      };
    } else if (fieldUpdated === 'check_out_date') {
      reservationUpdated = {
        ...reservationUpdated,
        check_out_date: moment(formik.values.check_out_date).format('DD/MM/YYYY'),
      };
    } else if (fieldUpdated === 'status') {
      reservationUpdated = {
        ...reservationUpdated,
        status: formik.values.status as ReservationStatus,
      };
    }

    handleUpdateReservationProperty({ ...reservationUpdated });
  };

  const handlePatchReservation = async (fieldToUpdate: string, reservation: ReservationProps) => {
    try {
      let dataUpdated: ReservationPatchProps = {
        check_in_date: formatedDateOfRequest(reservation.check_in_date, 'DD/MM/YYYY'),
        check_out_date: formatedDateOfRequest(reservation.check_out_date, 'DD/MM/YYYY'),
        status: reservation.status,
        comment: reservation.comment,
        conciliada: true,
      };

      if (fieldToUpdate === 'total_price') {
        dataUpdated = {
          ...dataUpdated,
          total_price: currencyToNumber(currency(formik.values.total_price)),
        };
      } else if (fieldToUpdate === 'gross_daily_value') {
        dataUpdated = {
          ...dataUpdated,
          gross_daily_value: currencyToNumber(currency(formik.values.gross_daily_value)),
        };
      } else if (fieldToUpdate === 'daily_net_value') {
        dataUpdated = {
          ...dataUpdated,
          daily_net_value: currencyToNumber(currency(formik.values.daily_net_value)),
        };
      } else if (fieldToUpdate === 'cleaning_fee_value') {
        dataUpdated = {
          ...dataUpdated,
          cleaning_fee_value: currencyToNumber(currency(formik.values.cleaning_fee_value)),
        };
      } else if (fieldToUpdate === 'cleaning_net_value') {
        dataUpdated = {
          ...dataUpdated,
          net_cleaning_fee: currencyToNumber(currency(formik.values.cleaning_net_value)),
        };
      } else if (fieldToUpdate === 'ota_comission') {
        dataUpdated = {
          ...dataUpdated,
          ota_comission: currencyToNumber(currency(formik.values.ota_comission)),
        };
      } else if (fieldToUpdate === 'check_in_date') {
        dataUpdated = {
          ...dataUpdated,
          check_in_date: moment(formik.values.check_in_date).format('YYYY-MM-DD'),
        };
      } else if (fieldToUpdate === 'check_out_date') {
        dataUpdated = {
          ...dataUpdated,
          check_out_date: moment(formik.values.check_out_date).format('YYYY-MM-DD'),
        };
      } else if (fieldToUpdate === 'status') {
        dataUpdated = {
          ...dataUpdated,
          status: formik.values.status as ReservationStatus,
        };
      }

      await patchReservation(reservation.id, dataUpdated);

      handleUpdateReservationState(fieldToUpdate, reservation);
      handleResetFieldOfReservationEdited(fieldToUpdate);

      toast.success(`${fieldReservationTranslated[fieldToUpdate]} atualizado com sucesso!`);
    } catch (err) {
      toast.error('Não foi possível atualizar o valor!');
    }
  };

  const handleEditFieldOfExpense = (fieldClicked: string, expense: ExpenseProps) => {
    const editing: EditTextFieldProps = {
      id: expense.id,
      isEdit: true,
    };

    if (fieldClicked === 'paid_by') {
      formik.setFieldValue('paid_by', expense.paid_by);
      setEditExpensePaidBy({ ...editing });
    }
  };

  const handleResetFieldOfExpenseEdited = (fieldToUpdate: string) => {
    if (fieldToUpdate === 'paid_by') {
      setEditExpensePaidBy({
        id: -1,
        isEdit: false,
      });
      formik.setFieldValue('paid_by', '');
    }
  };

  const handleUpdateExpenseState = (fieldUpdated: string, currentExpense: ExpenseProps) => {
    let expenseUpdated: ExpenseProps = {
      ...currentExpense,
    };

    if (fieldUpdated === 'paid_by') {
      expenseUpdated = {
        ...expenseUpdated,
        paid_by: translatedPaidBy[formik.values.paid_by],
      };
    }

    handleUpdateExpenseProperty({ ...expenseUpdated });
  };

  const handlePatchExpense = async (fieldToUpdate: string, expense: ExpenseProps) => {
    try {
      let dataUpdated: ExpensePatchProps = {};

      if (fieldToUpdate === 'paid_by') {
        dataUpdated = {
          ...dataUpdated,
          paid_by: formik.values.paid_by,
        };
      }

      await patchExpenses(expense.id, dataUpdated);

      handleUpdateExpenseState(fieldToUpdate, expense);
      handleResetFieldOfExpenseEdited(fieldToUpdate);

      toast.success('Despesa atualizada com sucesso!');
    } catch (err) {
      toast.error('Não foi possível atualizar o valor!');
    }
  };

  const handleSortFinancialCloseData = () => {
    let response: GridPropertyProps[] = financialDataProperty;

    if (['property', 'owner', 'host', 'property_status'].includes(orderGridBy.order_by)) {
      response = sortFinancialDataOfProperty(
        financialDataProperty, orderGridBy.order_by, orderGridBy.order,
      );
    } else {
      response = sortFinancialDataOfProperty(
        financialDataProperty,
        orderGridBy.order_by,
        orderGridBy.order,
        financialDataProperty.filter((item) => item.expanded).map((item) => item.id),
      );
    }
    return response;
  };

  useEffect(() => {
    const response = handleSortFinancialCloseData();
    handleSetFinancialDataProperty([...response]);
  }, [orderGridBy]);

  const handleUpdateChecked = (property: GridPropertyProps) => {
    handleUpdateCheckedOfPropertySelected(property.id);
  };

  const handleUpdateExpandedRows = (id: number) => {
    handleUpdateExpandedOfPropertySelected(id);
  };

  const handleClickMoreVertButton = (property: GridPropertyProps) => {
    handleOpenActions(!openActions);
    handleUpdateAllCheckedsOfPropertySelected(false);
    handleUpdateCheckedOfPropertySelected(property.id);
  };

  const handleLoadNext = () => {
    setLimitFinancialData(financialDataProperty
      && limitFinancialData >= financialDataProperty.length
      ? financialDataProperty.length : limitFinancialData + DEFAULT_LIMIT);
  };

  const checkIfAllPropertyIsSelected = () => {
    const totalSelected = financialDataProperty.filter((item) => item.checked).length;
    return totalSelected === financialDataProperty.length;
  };

  const handleCheckAll = () => {
    const allIsChecked: boolean = checkIfAllPropertyIsSelected();
    handleUpdateAllCheckedsOfPropertySelected(!allIsChecked);
  };

  useEffect(() => {
    if (inView) {
      setIsLoadMoreDatas(true);

      setTimeout(() => {
        handleLoadNext();
      }, 300);
    }
  }, [inView]);

  const handleShorcutESCPressed = () => {
    if (shorcutESC) {
      handleResetFieldOfReservationEdited('total_price');
      handleResetFieldOfReservationEdited('gross_daily_value');
      handleResetFieldOfReservationEdited('daily_net_value');
      handleResetFieldOfReservationEdited('cleaning_fee_value');
      handleResetFieldOfReservationEdited('cleaning_net_value');
      handleResetFieldOfReservationEdited('ota_comission');
      handleResetFieldOfReservationEdited('check_in_date');
      handleResetFieldOfReservationEdited('check_out_date');
      handleResetFieldOfReservationEdited('status');
      handleResetFieldOfExpenseEdited('paid_by');
    }
  };

  useEffect(() => {
    handleShorcutESCPressed();
  }, [shorcutESC]);

  const handleShorcutEnterPressedReservation = (
    e: KeyboardEvent<HTMLInputElement>,
    field: string,
    reservation: ReservationProps,
  ) => {
    if (e.key === 'Enter') {
      handlePatchReservation(field, reservation);
    }
  };

  const handleShorcutEnterPressedExpense = (
    e: KeyboardEvent<HTMLInputElement>,
    field: string,
    expense: ExpenseProps,
  ) => {
    if (e.key === 'Enter') {
      handlePatchExpense(field, expense);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Código da reserva copiado para área de transferência');
  };

  const itemIsSelectedInGrid = () => {
    if (propertiesSelected.length === 0) {
      toast.error('Você deve selecionar um imóvel para adicionar um ajuste');
      return false;
    }
    return true;
  };

  const openAddManualFitModal = () => {
    if (itemIsSelectedInGrid()) {
      handleOpenModalAddManualFitProperty(true);
    }
  };

  const openEditManualFitModal = (property: GridPropertyProps) => {
    if (property?.property_manual_fits?.length === 0) {
      toast.error('Primeiro adicione um ajuste clicando em "Ajuste Imóvel Proprietário +"');
      return;
    }

    handleUpdateAllCheckedsOfPropertySelected(false);
    handleUpdateCheckedOfPropertySelected(property.id, true);
    handleOpenModalEditManualFitProperty(true);
  };

  const openAddManualFitDailyModal = (property: GridPropertyProps, booking: ReservationProps) => {
    const ota_id = property.reservations.find((item) => item.id === booking.id)?.ota_id;
    handleOpenModalAddManualFitDaily({
      open: true,
      reservation: {
        ...booking,
        ota_id,
        property: { ...property.property },
      },
    });
  };

  const openEditManualFitDailyModal = (property: GridPropertyProps, booking: ReservationProps) => {
    handleOpenModalEditManualFitDaily({
      open: true,
      reservation: {
        ...booking,
        property: { ...property.property },
      },
    });
  };

  const openAddManualFitCleaningModal = (property: GridPropertyProps, booking: ReservationProps) => {
    handleOpenModalAddManualFitCleaning({
      open: true,
      reservation: {
        ...booking,
        property: { ...property.property },
        host: { ...property.host },
      },
    });
  };

  const openEditManualFitCleaningModal = (property: GridPropertyProps, booking: ReservationProps) => {
    handleOpenModalEditManualFitCleaning({
      open: true,
      reservation: {
        ...booking,
        property: { ...property.property },
        host: { ...property.host },
      },
    });
  };

  return (
    <Container>
      <Content
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <ContainerTable>
          <Table>

            {/* Header Main Grid */}
            <TableHeader>
              <Tr>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={20}
                    maxWidth={widthWindow < 1440 ? 34 : 60}
                  >
                    <Tooltip text="Selecionar todos">
                      <RadioButton
                        data-cy="radio-button-select-all"
                        type="radio"
                        id={'radio'}
                        name={'radio'}
                        checked={checkIfAllPropertyIsSelected()}
                        onClick={() => handleCheckAll()}
                      />
                    </Tooltip>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={130}
                  >
                    <TitleContainer>
                      <Title data-cy="title-property" disableWidth>Imóvel</Title>
                      <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar imóveis (Z-A)' : 'Ordenar imóveis (A-Z)'}`}>
                        <ContainerArrows onClick={() => setOrderGridBy({
                          order_by: 'property',
                          order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                        })}
                        >
                          <Image src={arrowTop} alt="Seta para cima" />
                          <Image src={arrowBottom} alt="Seta para baixo" />
                        </ContainerArrows>
                      </Tooltip>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={140}
                  >
                    <TitleContainer>
                      <Title data-cy="title-owner" disableWidth>Proprietário</Title>
                      <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar proprietários (Z-A)' : 'Ordenar proprietários (A-Z)'}`}>
                        <ContainerArrows onClick={() => setOrderGridBy({
                          order_by: 'owner',
                          order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                        })}
                        >
                          <Image src={arrowTop} alt="Seta para cima" />
                          <Image src={arrowBottom} alt="Seta para baixo" />
                        </ContainerArrows>
                      </Tooltip>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={140}
                  >
                    <TitleContainer>
                      <Title data-cy="title-host" disableWidth>Anfitrião</Title>
                      <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar anfitriões (Z-A)' : 'Ordenar anfitriões (A-Z)'}`}>
                        <ContainerArrows
                          onClick={() => setOrderGridBy({
                            order_by: 'host',
                            order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                          })}
                        >
                          <Image src={arrowTop} alt="Seta para cima" />
                          <Image src={arrowBottom} alt="Seta para baixo" />
                        </ContainerArrows>
                      </Tooltip>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={140}
                  >
                    <TitleContainer>
                      <Title data-cy="title-host" disableWidth>Status</Title>
                      <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar status (Z-A)' : 'Ordenar status (A-Z)'}`}>
                        <ContainerArrows
                          onClick={() => setOrderGridBy({
                            order_by: 'property_status',
                            order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                          })}
                        >
                          <Image src={arrowTop} alt="Seta para cima" />
                          <Image src={arrowBottom} alt="Seta para baixo" />
                        </ContainerArrows>
                      </Tooltip>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-balance-initial">Saldo Inicial</Title>
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableMarginHorizontal={expandRevenue}
                    disableBorderTopRightRadius={expandRevenue}
                    disableBorderRight={expandRevenue}
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-revenue">Faturamento</Title>
                      {!expandRevenue
                        && (
                          <Tooltip text="Expandir">
                            <ExpandIcon
                              data-cy="btn-expand-revenue"
                              onClick={() => setExpandRevenue(true)}
                            />
                          </Tooltip>
                        )}
                    </TitleContainer>
                    <Subtitle data-cy={!expandRevenue ? 'subtitle-revenue-all' : 'subtitle-revenue-airbnb'}>
                      {!expandRevenue ? 'Todos' : 'Airbnb'}
                    </Subtitle>
                  </ThContainer>
                </Th>
                {expandRevenue && (
                  <>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRevenue}
                        disableBorderRadius={expandRevenue}
                        disableBorderLeft={expandRevenue}
                        disableBorderRight={expandRevenue}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-revenue-booking">Booking</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRevenue}
                        disableBorderRadius={expandRevenue}
                        disableBorderLeft={expandRevenue}
                        disableBorderRight={expandRevenue}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-revenue-contract">Contrato</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRevenue}
                        disableBorderRadius={expandRevenue}
                        disableBorderLeft={expandRevenue}
                        disableBorderRight={expandRevenue}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-revenue-expedia">Expedia</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRevenue}
                        disableBorderRadius={expandRevenue}
                        disableBorderLeft={expandRevenue}
                        disableBorderRight={expandRevenue}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-revenue-homeaway">HomeAway</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRevenue}
                        disableBorderRadius={expandRevenue}
                        disableBorderLeft={expandRevenue}
                        disableBorderRight={expandRevenue}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-revenue-stays">Stays</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRevenue}
                        disableBorderTopLeftRadius={expandRevenue}
                        disableBorderLeft={expandRevenue}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer expanded={expandRevenue}>
                          <Tooltip text="Recolher">
                            <CollapseIcon
                              data-cy="btn-collapse-revenue"
                              onClick={() => setExpandRevenue(false)}
                            />
                          </Tooltip>
                        </TitleContainer>
                        <Subtitle data-cy="subtitle-revenue-decolar">Decolar</Subtitle>
                      </ThContainer>
                    </Th>
                  </>
                )}
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableMarginHorizontal={expandIncome}
                    disableBorderTopRightRadius={expandIncome}
                    disableBorderRight={expandIncome}
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-income">Receita</Title>
                      {!expandIncome
                        && (
                          <Tooltip text="Expandir">
                            <ExpandIcon
                              data-cy="btn-expand-income"
                              onClick={() => setExpandIncome(true)}
                            />
                          </Tooltip>
                        )}
                    </TitleContainer>
                    <Subtitle data-cy={!expandIncome ? 'subtitle-income-all' : 'subtitle-income-airbnb'}>
                      {!expandIncome ? 'Todos' : 'Airbnb'}
                    </Subtitle>
                  </ThContainer>
                </Th>
                {expandIncome && (
                  <>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandIncome}
                        disableBorderRadius={expandIncome}
                        disableBorderLeft={expandIncome}
                        disableBorderRight={expandIncome}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-income-booking">Booking</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandIncome}
                        disableBorderRadius={expandIncome}
                        disableBorderLeft={expandIncome}
                        disableBorderRight={expandIncome}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-income-contract">Contrato</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandIncome}
                        disableBorderRadius={expandIncome}
                        disableBorderLeft={expandIncome}
                        disableBorderRight={expandIncome}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-income-expedia">Expedia</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandIncome}
                        disableBorderRadius={expandIncome}
                        disableBorderLeft={expandIncome}
                        disableBorderRight={expandIncome}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-income-homeaway">HomeAway</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandIncome}
                        disableBorderRadius={expandIncome}
                        disableBorderLeft={expandIncome}
                        disableBorderRight={expandIncome}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-income-stays">Stays</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandIncome}
                        disableBorderTopLeftRadius={expandIncome}
                        disableBorderLeft={expandIncome}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer expanded={expandIncome}>
                          <Tooltip text="Recolher">
                            <CollapseIcon
                              data-cy="btn-collapse-income"
                              onClick={() => setExpandIncome(false)}
                            />
                          </Tooltip>
                        </TitleContainer>
                        <Subtitle data-cy="subtitle-income-decolar">Decolar</Subtitle>
                      </ThContainer>
                    </Th>
                  </>
                )}
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableMarginHorizontal={expandCommission}
                    disableBorderTopRightRadius={expandCommission}
                    disableBorderRight={expandCommission}
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-commission">Comissão</Title>
                      {!expandCommission
                        && (
                          <Tooltip text="Expandir">
                            <ExpandIcon
                              data-cy="btn-expand-commission"
                              onClick={() => setExpandCommission(true)}
                            />
                          </Tooltip>
                        )}
                    </TitleContainer>
                    <Subtitle data-cy={!expandCommission ? 'subtitle-commission-all' : 'subtitle-commission-host'}>
                      {!expandCommission ? 'Todos' : 'Comissão Anfitrião'}
                    </Subtitle>
                  </ThContainer>
                </Th>
                {expandCommission && (
                  <Th
                    className="th-main-grid-fixed-top"
                    customColor={theme.palette.white.main.hex()}
                  >
                    <ThContainer
                      disableMarginHorizontal={expandCommission}
                      disableBorderTopLeftRadius={expandCommission}
                      disableBorderLeft={expandCommission}
                      disableBorderBottom
                      minWidth={120}
                    >
                      <TitleContainer expanded={expandCommission}>
                        <Tooltip text="Recolher">
                          <CollapseIcon
                            data-cy="btn-collapse-commission"
                            onClick={() => setExpandCommission(false)}
                          />
                        </Tooltip>
                      </TitleContainer>
                      <Subtitle data-cy="subtitle-commission-seazone">Comissão Seazone</Subtitle>
                    </ThContainer>
                  </Th>
                )}
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableMarginHorizontal={expandExpense}
                    disableBorderTopRightRadius={expandExpense}
                    disableBorderRight={expandExpense}
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-expenses">Despesas</Title>
                      {!expandExpense
                        && (
                          <Tooltip text="Expandir">
                            <ExpandIcon
                              data-cy="btn-expand-expenses"
                              onClick={() => setExpandExpense(true)}
                            />
                          </Tooltip>
                        )}
                    </TitleContainer>
                    <Subtitle data-cy={!expandExpense ? 'subtitle-expenses-all' : 'subtitle-expenses-host'}>
                      {!expandExpense ? 'Todos' : 'Despesas Anfitrião'}
                    </Subtitle>
                  </ThContainer>
                </Th>
                {expandExpense && (
                  <>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandExpense}
                        disableBorderRadius={expandExpense}
                        disableBorderLeft={expandExpense}
                        disableBorderRight={expandExpense}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-expenses-seazone">Despesas Seazone</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandExpense}
                        disableBorderTopLeftRadius={expandExpense}
                        disableBorderLeft={expandExpense}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer expanded={expandExpense}>
                          <Tooltip text="Recolher">
                            <CollapseIcon
                              data-cy="btn-collapse-expenses"
                              onClick={() => setExpandExpense(false)}
                            />
                          </Tooltip>
                        </TitleContainer>
                        <Subtitle data-cy="subtitle-expenses-owner">Despesas Proprietário</Subtitle>
                      </ThContainer>
                    </Th>
                  </>
                )}
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableMarginHorizontal={expandRefund}
                    disableBorderTopRightRadius={expandRefund}
                    disableBorderRight={expandRefund}
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-expenses">Reembolso</Title>
                      {!expandRefund
                        && (
                          <Tooltip text="Expandir">
                            <ExpandIcon
                              data-cy="btn-expand-refund"
                              onClick={() => setExpandRefund(true)}
                            />
                          </Tooltip>
                        )}
                    </TitleContainer>
                    <Subtitle data-cy={!expandRefund ? 'subtitle-refund-all' : 'subtitle-refund-host'}>
                      {!expandRefund ? 'Todos' : 'Reembolso Anfitrião'}
                    </Subtitle>
                  </ThContainer>
                </Th>
                {expandRefund && (
                  <>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRefund}
                        disableBorderRadius={expandRefund}
                        disableBorderLeft={expandRefund}
                        disableBorderRight={expandRefund}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer />
                        <Subtitle data-cy="subtitle-refund-seazone">Reembolso Seazone</Subtitle>
                      </ThContainer>
                    </Th>
                    <Th
                      className="th-main-grid-fixed-top"
                      customColor={theme.palette.white.main.hex()}
                    >
                      <ThContainer
                        disableMarginHorizontal={expandRefund}
                        disableBorderTopLeftRadius={expandRefund}
                        disableBorderLeft={expandRefund}
                        disableBorderBottom
                        minWidth={120}
                      >
                        <TitleContainer expanded={expandRefund}>
                          <Tooltip text="Recolher">
                            <CollapseIcon
                              data-cy="btn-collapse-refund"
                              onClick={() => setExpandRefund(false)}
                            />
                          </Tooltip>
                        </TitleContainer>
                        <Subtitle data-cy="subtitle-refund-owner">Reembolso Proprietário</Subtitle>
                      </ThContainer>
                    </Th>
                  </>
                )}
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title isInputData data-cy="title-adjustments" data-testid="text-manualfit-property">Ajuste Imóvel Proprietário</Title>
                      <Image
                        data-cy="btn-add-manualfit-property"
                        data-testid="btn-add-manualfit-property"
                        src={addIcon}
                        alt="ícone adicionar ajuste"
                        style={{ cursor: 'pointer' }}
                        onClick={() => openAddManualFitModal()}
                      />
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-transfer">Repasse</Title>
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-balance-final">Saldo Final</Title>
                    </TitleContainer>
                    <TitleContainer />
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={20}
                  />
                </Th>
              </Tr>
            </TableHeader>

            {/* Body Main Grid */}
            <TableBody>
              {financialDataProperty.length > 0 && financialDataProperty
                .slice(0, limitFinancialData).map((item) => (
                  <Fragment key={item.id}>
                    <Tr data-cy="grid-row-property" key={item.id}>
                      <Td customColor={item.status}>
                        <TdContainer
                          disableBorder
                          disableBorderRadius
                          minWidth={20}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <RadioButton
                            data-cy={`radio-button-select-property-${item.id}`}
                            type="radio"
                            id={`radio_${item.id}`}
                            name={`radio_${item.id}`}
                            checked={item.checked}
                            onClick={() => handleUpdateChecked(item)}
                          />
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          maxWidth={95}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <TitleContainer>
                            <Title data-cy={`property-${item.id}-code`} disableWidth>{item.property.code}</Title>
                            {(item.expanded) ? (
                              <Tooltip text="Recolher">
                                <CollapseIcon
                                  data-cy={`btn-collapse-grid-row-${item.id}`}
                                  onClick={() => handleUpdateExpandedRows(item.id)}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip text="Expandir">
                                <ExpandIcon
                                  data-cy={`btn-expand-grid-row-${item.id}`}
                                  onClick={() => handleUpdateExpandedRows(item.id)}
                                />
                              </Tooltip>
                            )}
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          minWidth={140}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <TitleContainer>
                            <Title disableWidth>{item.owner.name}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          minWidth={140}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <TitleContainer>
                            <Title disableWidth>{item.host.name}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          minWidth={140}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <TitleContainer>
                            <Title disableWidth>
                              {translatePropertyStatus(item.property_status)}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {item.balance_initial}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder={!expandRevenue}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded={expandRevenue}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {!expandRevenue ? item.revenue.total : item.revenue.airbnb}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      {expandRevenue && (
                        <>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.revenue.booking}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.revenue.contract}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.revenue.expedia}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.revenue.homeaway}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.revenue.stays}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.revenue.decolar}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                        </>
                      )}
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder={!expandIncome}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded={expandIncome}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {!expandIncome ? item.income.total : item.income.airbnb}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      {expandIncome && (
                        <>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.income.booking}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.income.contract}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.income.expedia}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.income.homeaway}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.income.stays}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.income.decolar}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                        </>
                      )}
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder={!expandCommission}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded={expandCommission}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {!expandCommission ? item.commission.total : item.commission.host}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      {expandCommission && (
                        <Td customColor={item.status}>
                          <TdContainer
                            loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                            disableBorderRadius
                            customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                            expanded
                            minWidth={120}
                          >
                            <TitleContainer>
                              <Title>{item.commission.seazone}</Title>
                            </TitleContainer>
                          </TdContainer>
                        </Td>
                      )}
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder={!expandExpense}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded={expandExpense}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {!expandExpense ? item.expense.total : item.expense.expense_host}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      {expandExpense && (
                        <>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.expense.expense_seazone}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.expense.expense_owner}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                        </>
                      )}
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder={!expandRefund}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded={expandRefund}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {!expandRefund ? item.refund.total : item.refund.refund_host}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      {expandRefund && (
                        <>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.refund.refund_seazone}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                          <Td customColor={item.status}>
                            <TdContainer
                              loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                              disableBorderRadius
                              customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                              expanded
                              minWidth={120}
                            >
                              <TitleContainer>
                                <Title>{item.refund.refund_owner}</Title>
                              </TitleContainer>
                            </TdContainer>
                          </Td>
                        </>
                      )}
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title
                              data-testid="text-manualfit-property-total-value"
                              className={`manualfit-total-value-color-is-${item.property_manual_fit.customColor}`}
                              customColor={manualFitColor[item.property_manual_fit.customColor]}
                            >
                              {item.property_manual_fit.value}
                            </Title>
                            <Tooltip text="Editar">
                              <PencilIcon
                                data-cy="btn-edit-manualfit-property"
                                data-testid="btn-edit-manualfit-property"
                                onClick={() => openEditManualFitModal(item)}
                              />
                            </Tooltip>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {item.transfer}
                            </Title>
                            <TransferIcon color={item.is_to_keep_funds_in_seazone ? 'red' : 'green'} />
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{item.balance_final}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <MoreVertButtonContainer>
                          <MoreVertButton
                            data-cy={`btn-more-options-${item.id}`}
                            type="button"
                            onClick={() => handleClickMoreVertButton(item)}
                          >
                            <MoreVertIcon />
                          </MoreVertButton>
                        </MoreVertButtonContainer>
                      </Td>
                    </Tr>

                    {item.expanded && (
                      <Tr>
                        <Td
                          colSpan={100}
                          customColor={theme.palette.white._350.hex()}
                          className="td-secondary-grid-fixed-top"
                        >

                          {/* Reservations Grid */}
                          <WrapperGridRow>
                            <ContainerGridRow>
                              <Title className="reservation-list" data-cy="title-reservations-list">Lista de reservas</Title>
                              <ContainerTable className="reservation-table">
                                <Table>

                                  {/* Header Reservations Grid */}
                                  <TableHeader>
                                    <Tr>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title
                                              data-cy="title-reservation-code"
                                              disableWidth
                                            >
                                              Reserva
                                            </Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Reserva (Z-A)' : 'Ordenar Reserva (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_code',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={200}
                                        >
                                          <TitleContainer>
                                            <Title
                                              data-cy="title-reservation-checkin"
                                              disableWidth
                                            >
                                              Checkin
                                            </Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Checkin (Z-A)' : 'Ordenar Checkin (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_checkin',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={200}
                                        >
                                          <TitleContainer>
                                            <Title
                                              data-cy="title-reservation-checkout"
                                              disableWidth
                                            >
                                              Checkout
                                            </Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Checkout (Z-A)' : 'Ordenar Checkout (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_checkout',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-guestname" disableWidth>Status</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Status (Z-A)' : 'Ordenar Status (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_status',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-total-value" disableWidth>Valor Total</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Valor Total (Z-A)' : 'Ordenar Valor Total (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_total_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-dailys-value" disableWidth>Valor Diárias</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Valor Diárias (Z-A)' : 'Ordenar Valor Diárias (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_dailys_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-reservation-manualfit-daily" disableWidth>Ajuste diária</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Ajuste diária (Z-A)' : 'Ordenar Ajuste diária (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_manualfit_daily',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-net-daily-value" disableWidth>Valor total líquido diárias</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Valor total líquido diárias (Z-A)' : 'Ordenar Valor total líquido diárias (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_net_daily_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-cleaning-value" disableWidth>Valor Limpeza</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Valor Limpeza (Z-A)' : 'Ordenar Valor Limpeza (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_cleaning_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-reservation-cleaning-daily" disableWidth>Ajuste limpeza</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Ajuste limpeza (Z-A)' : 'Ordenar Ajuste limpeza (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_manualfit_cleaning',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-net-cleaning-value" disableWidth>Valor líquido da limpeza</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Valor líquido da limpeza (Z-A)' : 'Ordenar Valor líquido da limpeza (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_net_cleaning_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-comission-ota" disableWidth>Comissão OTA</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Comissão OTA (Z-A)' : 'Ordenar Comissão OTA (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_ota_comission',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-platform" disableWidth>Plataforma</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Plataforma (Z-A)' : 'Ordenar Plataforma (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_platform',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title data-cy="title-reservation-guestname" disableWidth>Nome do hóspede</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Hóspede (Z-A)' : 'Ordenar Hóspede (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'reservation_guest',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>

                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={1000}
                                        />
                                      </Th>
                                    </Tr>
                                  </TableHeader>

                                  {/* Body Reservations Grid */}
                                  <TableBody>
                                    {item.reservations.length === 0
                                      ? (
                                        <ContainerInformation>
                                          <ContentInformation>
                                            <Title>Nenhuma reserva foi encontrada!</Title>
                                          </ContentInformation>
                                        </ContainerInformation>
                                      ) : item.reservations.map((reservation: ReservationProps) => (
                                        <Tr key={reservation.id}>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <ReservationCode
                                                  onClick={() => copyToClipboard(reservation?.code)}
                                                >
                                                  <p>{reservation?.code}</p>
                                                  <Tooltip text="Copiar código">
                                                    <CopyContainer>
                                                      <Copy />
                                                    </CopyContainer>
                                                  </Tooltip>
                                                </ReservationCode>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editCheckin.isEdit ? (
                                                <TitleContainer>
                                                  <Title disableWidth>
                                                    {reservation.check_in_date}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-ckeckin"
                                                      onClick={() => handleEditFieldOfReservation('check_in_date', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editCheckin.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-checkin"
                                                        onClick={() => setEditCheckin({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>

                                                    <DatePickerContainer>
                                                      <DatePicker
                                                        id="check_in_date"
                                                        dataCy="datepicker-checkin"
                                                        formik={formik}
                                                        viewsCustom={['day', 'month', 'year']}
                                                        hasInitialDates
                                                        disableInput
                                                        showDaysOutsideCurrentMonth
                                                      />
                                                    </DatePickerContainer>

                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-checkin"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('check_in_date', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-checkin" disableWidth>
                                                    {reservation.check_in_date}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-checkin"
                                                      onClick={() => handleEditFieldOfReservation('check_in_date', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editCheckout.isEdit ? (
                                                <TitleContainer>
                                                  <Title disableWidth>
                                                    {reservation.check_out_date}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-ckeckout"
                                                      onClick={() => handleEditFieldOfReservation('check_out_date', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editCheckout.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-checkout"
                                                        onClick={() => setEditCheckout({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>

                                                    <DatePickerContainer>
                                                      <DatePicker
                                                        id="check_out_date"
                                                        dataCy="datepicker-checkout"
                                                        formik={formik}
                                                        viewsCustom={['day', 'month', 'year']}
                                                        hasInitialDates
                                                        disableInput
                                                        showDaysOutsideCurrentMonth
                                                      />
                                                    </DatePickerContainer>

                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-checkout"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('check_out_date', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-checkout" disableWidth>
                                                    {reservation.check_out_date}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-checkout"
                                                      onClick={() => handleEditFieldOfReservation('check_out_date', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editStatus.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-reservation-status" disableWidth>
                                                    {translateReservationStatus(reservation.status)}
                                                  </Title>
                                                  <Tooltip text="Editar">
                                                    <PencilIcon
                                                      data-cy="btn-edit-reservation-status"
                                                      onClick={() => handleEditFieldOfReservation('status', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editStatus.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-edit-reservation-status"
                                                        onClick={() => setEditStatus({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>

                                                    <DropdownContainer>
                                                      <SimpleSelect
                                                        dataCy="input-reservation-status"
                                                        id="status"
                                                        placeholder="Selecione"
                                                        required
                                                        formik={formik}
                                                        options={[
                                                          { value: 'Active', valueLabel: 'Ativa' },
                                                          { value: 'No-Show', valueLabel: 'No-Show' },
                                                          { value: 'Concluded', valueLabel: 'Concluída' },
                                                          { value: 'Canceled', valueLabel: 'Cancelada' },
                                                          { value: 'In progress', valueLabel: 'Em progresso' },
                                                          { value: 'Pre-booking', valueLabel: 'Pré reserva' },
                                                        ]}
                                                        onKeyPress={
                                                          (e: KeyboardEvent<HTMLInputElement>) => {
                                                            handleShorcutEnterPressedReservation(
                                                              e, 'status', reservation,
                                                            );
                                                          }
                                                        }
                                                      />
                                                    </DropdownContainer>

                                                    <Tooltip text="Salvar">
                                                      <CheckButton
                                                        data-cy="btn-save-reservation-status"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('status', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-reservation-status" disableWidth>
                                                    {translateReservationStatus(reservation.status)}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-reservation-status"
                                                      onClick={() => handleEditFieldOfReservation('status', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editTotalPrice.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-total-price" disableWidth>
                                                    {reservation.total_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-total-price"
                                                      onClick={() => handleEditFieldOfReservation('total_price', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editTotalPrice.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-edit-total-price"
                                                        onClick={() => setEditTotalPrice({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>
                                                    <TextField
                                                      dataCy="input-total-price"
                                                      id="total_price"
                                                      formik={formik}
                                                      mask="money"
                                                      value={reservation.total_value}
                                                      onChange={(e) => formik.setFieldValue('total_price', e.target.value)}
                                                      onKeyPress={
                                                        (e: KeyboardEvent<HTMLInputElement>) => {
                                                          handleShorcutEnterPressedReservation(
                                                            e, 'total_price', reservation,
                                                          );
                                                        }
                                                      }
                                                    />
                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-total-price"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('total_price', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-total-price" disableWidth>
                                                    {reservation.total_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-total-price"
                                                      onClick={() => handleEditFieldOfReservation('total_price', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editDailyValue.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-dailys-value" disableWidth>
                                                    {reservation.gross_daily_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-dailys-value"
                                                      onClick={() => handleEditFieldOfReservation('gross_daily_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editDailyValue.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-dailys-value"
                                                        onClick={() => setEditDailyValue({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>
                                                    <TextField
                                                      dataCy="input-dailys-value"
                                                      id="gross_daily_value"
                                                      formik={formik}
                                                      mask="money"
                                                      value={reservation.gross_daily_value}
                                                      onChange={(e) => formik.setFieldValue('gross_daily_value', e.target.value)}
                                                      onKeyPress={(e:
                                                        KeyboardEvent<HTMLInputElement>) => {
                                                        handleShorcutEnterPressedReservation(
                                                          e, 'daily_net_value', reservation,
                                                        );
                                                      }}
                                                    />
                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-dailys-value"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('gross_daily_value', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-dailys-value" disableWidth>
                                                    {reservation.gross_daily_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-dailys-value"
                                                      onClick={() => handleEditFieldOfReservation('gross_daily_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title data-cy="content-manualfit-daily" disableWidth customColor={reservation.daily_manual_fit.customColor}>
                                                  {reservation.daily_manual_fit.value}
                                                </Title>
                                                <Tooltip text="Editar valor">
                                                  <PencilIcon
                                                    data-cy="btn-edit-manualfit-daily"
                                                    onClick={() => openEditManualFitDailyModal(item, reservation)}
                                                  />
                                                </Tooltip>
                                                <Image
                                                  data-cy="btn-add-manualfit-daily"
                                                  data-testid="btn-add-manualfit-daily"
                                                  src={addIcon}
                                                  alt="ícone adicionar ajuste"
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() => openAddManualFitDailyModal(item, reservation)}
                                                />
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editDailyNetValue.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-daily-net-value" disableWidth>
                                                    {reservation.daily_net_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-daily-net-value"
                                                      onClick={() => handleEditFieldOfReservation('daily_net_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editDailyNetValue.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-daily-net-value"
                                                        onClick={() => setEditDailyValue({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>
                                                    <TextField
                                                      dataCy="input-daily-net-value"
                                                      id="daily_net_value"
                                                      formik={formik}
                                                      mask="money"
                                                      value={reservation.daily_net_value}
                                                      onChange={(e) => formik.setFieldValue('daily_net_value', e.target.value)}
                                                      onKeyPress={(e:
                                                        KeyboardEvent<HTMLInputElement>) => {
                                                        handleShorcutEnterPressedReservation(
                                                          e, 'daily_net_value', reservation,
                                                        );
                                                      }}
                                                    />
                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-daily-net-value"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('daily_net_value', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-daily-net-value" disableWidth>
                                                    {reservation.daily_net_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-daily-net-value"
                                                      onClick={() => handleEditFieldOfReservation('daily_net_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editCleaningValue.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-cleaning-value" disableWidth>
                                                    {reservation.cleaning_fee_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-cleaning-value"
                                                      onClick={() => handleEditFieldOfReservation('cleaning_fee_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editCleaningValue.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-cleaning-value"
                                                        onClick={() => setEditCleaningValue({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>
                                                    <TextField
                                                      dataCy="input-cleaning-value"
                                                      id="cleaning_fee_value"
                                                      formik={formik}
                                                      mask="money"
                                                      value={reservation.cleaning_fee_value}
                                                      onChange={(e) => formik.setFieldValue('cleaning_fee_value', e.target.value)}
                                                      onKeyPress={
                                                        (e: KeyboardEvent<HTMLInputElement>) => {
                                                          handleShorcutEnterPressedReservation(
                                                            e, 'cleaning_fee_value', reservation,
                                                          );
                                                        }
                                                      }
                                                    />
                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-cleaning-value"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('cleaning_fee_value', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-cleaning-value" disableWidth>
                                                    {reservation.cleaning_fee_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-cleaning-value"
                                                      onClick={() => handleEditFieldOfReservation('cleaning_fee_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title data-cy="content-manualfit-cleaning" disableWidth customColor={reservation.cleaning_manual_fit.customColor}>
                                                  {reservation.cleaning_manual_fit.value}
                                                </Title>
                                                <Tooltip text="Editar valor">
                                                  <PencilIcon
                                                    data-cy="btn-edit-manualfit-cleaning"
                                                    onClick={() => openEditManualFitCleaningModal(item, reservation)}
                                                  />
                                                </Tooltip>
                                                <Image
                                                  data-cy="btn-add-manualfit-cleaning"
                                                  data-testid="btn-add-manualfit-cleaning"
                                                  src={addIcon}
                                                  alt="ícone adicionar ajuste"
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() => openAddManualFitCleaningModal(item, reservation)}
                                                />
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editCleaningNetValue.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-cleaning-net-value" disableWidth>
                                                    {reservation.cleaning_net_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-cleaning-net-value"
                                                      onClick={() => handleEditFieldOfReservation('cleaning_net_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editCleaningNetValue.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-cleaning-net-value"
                                                        onClick={() => setEditCleaningNetValue({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>
                                                    <TextField
                                                      dataCy="input-cleaning-net-value"
                                                      id="cleaning_net_value"
                                                      formik={formik}
                                                      mask="money"
                                                      value={reservation.cleaning_net_value}
                                                      onChange={(e) => formik.setFieldValue('cleaning_net_value', e.target.value)}
                                                      onKeyPress={
                                                        (e: KeyboardEvent<HTMLInputElement>) => {
                                                          handleShorcutEnterPressedReservation(
                                                            e, 'cleaning_net_value', reservation,
                                                          );
                                                        }
                                                      }
                                                    />
                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-cleaning-net-value"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('cleaning_net_value', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-cleaning-net-value" disableWidth>
                                                    {reservation.cleaning_net_value}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-cleaning-net-value"
                                                      onClick={() => handleEditFieldOfReservation('cleaning_net_value', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editOTAComission.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-ota-comission" disableWidth>
                                                    {reservation.ota_comission}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-ota-comission"
                                                      onClick={() => handleEditFieldOfReservation('ota_comission', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              ) : editOTAComission.id === reservation.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-ota-comission"
                                                        onClick={() => setEditOTAComission({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>
                                                    <TextField
                                                      dataCy="input-ota-comission"
                                                      id="ota_comission"
                                                      formik={formik}
                                                      mask="money"
                                                      value={reservation.ota_comission}
                                                      onChange={(e) => formik.setFieldValue('ota_comission', e.target.value)}
                                                      onKeyPress={
                                                        (e: KeyboardEvent<HTMLInputElement>) => {
                                                          handleShorcutEnterPressedReservation(
                                                            e, 'ota_comission', reservation,
                                                          );
                                                        }
                                                      }
                                                    />
                                                    <Tooltip text="Salvar valor">
                                                      <CheckButton
                                                        data-cy="btn-save-ota-comission"
                                                        type="submit"
                                                        onClick={() => handlePatchReservation('ota_comission', reservation)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-ota-comission" disableWidth>
                                                    {reservation.ota_comission}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-ota-comission"
                                                      onClick={() => handleEditFieldOfReservation('ota_comission', reservation)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{reservation.platform}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={reservation.conciliada ? theme.palette.green._100.hex() : theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{reservation.guest}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>

                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              disableBorder
                                              minWidth={1000}
                                              customColor={theme.palette.grey._330.hex()}
                                            />
                                          </Td>
                                        </Tr>
                                      ))}
                                  </TableBody>
                                </Table>
                              </ContainerTable>
                            </ContainerGridRow>
                          </WrapperGridRow>

                          {/* Expenses Grid */}
                          <WrapperGridRow>
                            <ContainerGridRow
                              className="expense-table"
                              customColor={theme.palette.grey._315.hex()}
                            >
                              <Title className="expense-list" data-cy="title-expenses-list">Lista de despesas</Title>
                              <ContainerTable>
                                <Table>

                                  {/* Header Expenses Grid */}
                                  <TableHeader>
                                    <Tr>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-code" disableWidth>Despesa</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Despesa (Z-A)' : 'Ordenar Despesa (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_code',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-register-date" disableWidth>Data de inserção</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Data de inserção (Z-A)' : 'Ordenar Data de inserção (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_register_date',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-date" disableWidth>Data de referência</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Data de referência (Z-A)' : 'Ordenar Data de referência (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_date',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-category" disableWidth>Categoria</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Categoria (Z-A)' : 'Ordenar Categoria (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_category',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-description" disableWidth>Descrição</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Descrição (Z-A)' : 'Ordenar Descrição (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_description',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-value" disableWidth>Valor</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Valor da Despesa (Z-A)' : 'Ordenar Valor da Despesa (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_value',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-owner-approved" disableWidth>Pedido autorização</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Pedido Autorização (Z-A)' : 'Ordenar Pedido Autorização (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_owner_approved',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData data-cy="title-expense-status" disableWidth>Status</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Status (Z-A)' : 'Ordenar Status (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_status',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title isInputData disableWidth>Pago por</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Pago Por (Z-A)' : 'Ordenar Pago Por (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'expense_paid_by',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._315.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={1000}
                                        />
                                      </Th>
                                    </Tr>
                                  </TableHeader>

                                  {/* Body Expenses Grid */}
                                  <TableBody>
                                    {item.expenses.length === 0
                                      ? (
                                        <ContainerInformation>
                                          <ContentInformation>
                                            <Title>Nenhuma despesa foi encontrada!</Title>
                                          </ContentInformation>
                                        </ContainerInformation>
                                      ) : item.expenses.map((expense: ExpenseProps) => (
                                        <Tr key={expense.id}>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.code}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.register_date}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.expense_date}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.category}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.description}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.value}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{expense.owner_approved ? 'Sim' : 'Não'}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {expense.status}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              {!editExpensePaidBy.isEdit ? (
                                                <TitleContainer>
                                                  <Title data-cy="content-paid_by" disableWidth>
                                                    {expense.paid_by}
                                                  </Title>
                                                  {/* <Tooltip text="Editar">
                                                    <PencilIcon
                                                      data-cy="btn-edit-paid_by"
                                                      onClick={() => handleEditFieldOfExpense('paid_by', expense)}
                                                    />
                                                  </Tooltip> */}
                                                </TitleContainer>
                                              ) : editExpensePaidBy.id === expense.id ? (
                                                <>
                                                  <TextFieldContainer>
                                                    <Tooltip text="Cancelar">
                                                      <CancelButton
                                                        data-cy="btn-cancel-edit-paid_by"
                                                        onClick={() => setEditExpensePaidBy({
                                                          id: -1,
                                                          isEdit: false,
                                                        })}
                                                      >
                                                        <CancelIcon />
                                                      </CancelButton>
                                                    </Tooltip>

                                                    <DropdownContainer>
                                                      <SimpleSelect
                                                        dataCy="input-paid_by"
                                                        id="paid_by"
                                                        placeholder="Selecione"
                                                        required
                                                        formik={formik}
                                                        options={[
                                                          { value: 'Host', valueLabel: 'Anfitrião' },
                                                          { value: 'Owner', valueLabel: 'Proprietário' },
                                                          { value: 'Guest', valueLabel: 'Hóspede' },
                                                          { value: 'Seazone', valueLabel: 'Seazone' },
                                                        ]}
                                                        onKeyPress={
                                                          (e: KeyboardEvent<HTMLInputElement>) => {
                                                            handleShorcutEnterPressedExpense(
                                                              e, 'paid_by', expense,
                                                            );
                                                          }
                                                        }
                                                      />
                                                    </DropdownContainer>

                                                    <Tooltip text="Salvar">
                                                      <CheckButton
                                                        data-cy="btn-save-paid_by"
                                                        type="submit"
                                                        onClick={() => handlePatchExpense('paid_by', expense)}
                                                      >
                                                        <CheckIcon />
                                                      </CheckButton>
                                                    </Tooltip>
                                                  </TextFieldContainer>
                                                </>
                                              ) : (
                                                <TitleContainer>
                                                  <Title data-cy="content-paid_by" disableWidth>
                                                    {expense.paid_by}
                                                  </Title>
                                                  <Tooltip text="Editar valor">
                                                    <PencilIcon
                                                      data-cy="btn-edit-paid_by"
                                                      onClick={() => handleEditFieldOfExpense('paid_by', expense)}
                                                    />
                                                  </Tooltip>
                                                </TitleContainer>
                                              )}
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._315.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              disableBorder
                                              minWidth={1000}
                                              customColor={theme.palette.grey._315.hex()}
                                            />
                                          </Td>
                                        </Tr>
                                      ))}
                                  </TableBody>
                                </Table>
                              </ContainerTable>
                              <CollapseRow>
                                <KeyboardArrowUpIcon
                                  onClick={() => handleUpdateExpandedRows(item.id)}
                                />
                              </CollapseRow>
                            </ContainerGridRow>
                          </WrapperGridRow>
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                ))}
            </TableBody>
          </Table>

          {financialDataProperty && financialDataProperty?.length > DEFAULT_LIMIT
            && financialDataProperty?.length > limitFinancialData && (
              <ContainerSpinner ref={ref}>
                <Spinner isLoading={isLoadMoreDatas} />
              </ContainerSpinner>
            )}
        </ContainerTable>

      </Content>
    </Container>
  );
};

export default memo(GridProperty);
