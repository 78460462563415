import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ListContent = styled(motion.ul)`
  gap: 10px;
  display: flex;
  width: 100%;
  list-style: none;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ListItem = styled(motion.li)`
  background: #fff;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled(motion.div)<{
  isOpen: boolean;
}>`
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  ${({ isOpen }) => !isOpen && 'border-bottom: none;'};
`;

export const Title = styled.h1`
  font-size: 1.1rem;
`;

export const Content = styled(motion.div)`
  padding: 0 20px 20px 20px;
`;

export const Description = styled(motion.p)`
  font-size: 1.2rem;
`;

export const DescriptionContainer = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
`;

export const ListsContainer = styled.div`
  padding: 20px;
  background: #fff;
  gap: 20px;

  ul {
    gap: 15px;
    display: flex;
    list-style: disc;
    flex-direction: column;

    li {
      font-size: 1.2rem;
    }
  }
`;
