import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import iconHouse from '../../../../../assets/icons/generals/house.svg';
import iconCalendar from '../../../../../assets/icons/generals/calendar2.svg';
import { Property } from '../../../../../services/Property/types';

import { useGridFinances } from '../../../../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';
import { useFinancialStatement } from '../../../../../hooks/HostDashboardHook/HostGridFinances/useFinancialStatement';

import { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import SimpleSelect from '../../../../SimpleSelect';
import DownloadButton from '../../../../OwnerPage/OwnerReport/Buttons/DownloadButton';

import {
  Form,
  Container,
  Content,
  ContainerSelect,
  Label,
} from './styles';

interface IFilters {
  propertiesCodes: SelectOption[];
  propertiesHost: Property[];
}

const Filters = ({ propertiesCodes, propertiesHost }: IFilters) => {
  const [selectedDates] = useState(new Date());
  const [dates, setDates] = useState<Array<number>>([]);
  const { setOpenFinancialStatementModal } = useFinancialStatement();
  const {
    propertyId,
    setPropertyId,
    year,
    setYear,
  } = useGridFinances();

  const formik = useFormik({
    initialValues: {
      year,
      property_id: propertyId,
    },
    onSubmit: async () => {},
  });

  const defineDates = (dateStart: number) => {
    const arrayDates = [];
    for (let i = dateStart; i <= selectedDates.getFullYear() + 1; i += 1) {
      arrayDates.push(i);
    }
    setDates(arrayDates.reverse());
  };

  useEffect(() => {
    setPropertyId(!['', 'null', 'undefined'].includes(`${formik.values?.property_id}`) ? Number(formik.values?.property_id) : undefined);
    if (formik.values?.property_id && propertiesHost) {
      const prop = propertiesHost.filter((item) => `${item.id}` === `${formik.values?.property_id}`);
        if (prop.length > 0) {
          const contractDate = prop[0]?.contract_start_date || '2011-01-01';
          defineDates(Number(contractDate.split('-')[0]));
        }
      } else if (propertiesHost) {
        const contractDates: number[] = [];
        propertiesHost.forEach((item) => {
          const contractDate = item?.contract_start_date || '2011-01-01';
          contractDates.push(Number(contractDate.split('-')[0]));
        });
        contractDates.sort();
        if (contractDates.length > 0) {
          defineDates(contractDates[0]);
        }
      }
  }, [formik.values?.property_id, propertiesHost]);

  useEffect(() => {
    if (formik.values?.year) {
      setYear(Number(formik.values?.year));
    }
  }, [formik.values?.year]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Container>
        <Content className="content-filters">
          <ContainerSelect>
            <Label>Selecione o imóvel</Label>
            <SimpleSelect
              dataCy="select-properties-list"
              id="property_id"
              placeholder="Todos"
              formik={formik}
              icon={iconHouse}
              showIcon
              options={propertiesCodes || []}
            />
          </ContainerSelect>
          <ContainerSelect>
            <Label>Selecione o ano</Label>
            <SimpleSelect
              dataCy="select-year-list"
              id="year"
              placeholder="Selecione"
              formik={formik}
              icon={iconCalendar}
              showIcon
              options={(dates || []).map((itemYear) => ({
                value: itemYear,
                valueLabel: itemYear,
              }))}
            />
          </ContainerSelect>
        </Content>

        <Content className="button-download">
          <DownloadButton
            type="button"
            label="Baixar extrato"
            onClick={() => setOpenFinancialStatementModal(true)}
            variantForMobile="secondary"
            variantForDesktop="secondary"
          />
        </Content>
      </Container>
    </Form>
  );
};

export default Filters;
