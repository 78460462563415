export enum Grid {
  Imovel = '200px',
  Status = '120px',
  InicioDeContrato = '150px',
  Proprietario = '300px',
  TelefoneProprietario = '300px',
  Endereco = '220px',
  Comissao = '150px',
  TaxLimpeza = '200px',
  Camas = '200px',
  Banheiros = '200px',
  Quartos = '200px',
}

const gridTemplateColumnsWithEnums = `${Grid.Imovel} ${Grid.Status} ${Grid.InicioDeContrato} ${Grid.Proprietario} ${Grid.TelefoneProprietario} ${Grid.Endereco} ${Grid.Comissao} ${Grid.TaxLimpeza} ${Grid.Camas} ${Grid.Banheiros} ${Grid.Quartos}`;

export const gridTemplateColumns = gridTemplateColumnsWithEnums;

export const bedRoomsWidth = '175px';

export const bedsWidth = '350px';

export const genericBorderRoundedMinWidth = '150px';

export const gridPadding = '50px';
