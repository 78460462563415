import styled, { css, keyframes } from 'styled-components';

const MAX_SIZE_MOBILE = 900;
const tooLevel = 17;
const showFabAnimationSpeed = '0.2s';

const showModalFabAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const showElementAnimation = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const rotate = keyframes`
  from  {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const FabShowItemsContainer = styled.div`
  position: fixed;
  z-index: 10000;
  left: 50%;
  top: 50%;
  height: fit-content;
  width: calc(100% - 40px);
  transform: translate(-50%, -50%);
  background: white;
  padding: 15px 20px;
  border-radius: 7px;
  display: flex;
  gap: 30px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  div {
    &:nth-child(n) {
      opacity: 0;
      animation: ${showModalFabAnimation} ${showFabAnimationSpeed} ease forwards;
    }

    &:nth-child(2n) {
      opacity: 0;
      animation: ${showModalFabAnimation} ${showFabAnimationSpeed} ease forwards;
    }
  }
`;

export const FabBackdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  background: black;
  opacity: 0.7;
`;

export const Container = styled.form`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.white._250.hex()};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    flex-direction: column;
    justify-content: center;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 16px;

  && {
    .MuiOutlinedInput-root {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      min-width: 80px;
      min-height: 32px;
      border: 0;

      svg {
        fill: ${({ theme }) => theme.palette.blue._400.hex()};
      }

      & fieldset {
        border: solid 1px ${({ theme }) => theme.palette.grey._450.hex()};
        animation: ${showElementAnimation} 0.5s ease forwards;
      }

      &:hover fieldset {
        border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
      }

      &.Mui-focused fieldset {
        border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
      }
    }
  }
`;

export const RightSide = styled.div`
  max-width: 100%;
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: column;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 255px;
  position: relative;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    justify-content: center;
    gap: 8px;
    max-width: 100%;
    margin-top: 8px;
  }
`;

export const IconClear = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  right: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill:  ${({ theme }) => theme.palette.grey._455.hex()};
  }
`;

export const ButtonSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HostNameContainer = styled.div`
  .MuiOutlinedInput-root {
    min-width: 125px !important;
  }
  .MuiOutlinedInput-input::placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    font-family: Arial !important;
  }

  .MuiSvgIcon-root {
    position: relative;
    top: 3px;
   }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    margin-bottom: 16px;

    .simpleSelectLabel {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
      font-family: Inter !important;
    }
  }
`;

export const StatusContainer = styled.div`
  .MuiOutlinedInput-root {
    min-width: 125px !important;
  }
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    .simpleSelectLabel {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
      font-family: Inter !important;
    }
  }
`;

export const RoomsContainer = styled.div`
  .MuiOutlinedInput-root {
    min-width: 125px !important;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    margin: 16px 0;
    .simpleSelectLabel {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
      font-family: Inter !important;
    }
  }
`;

export const LocationContainer = styled.div`
  .MuiOutlinedInput-root {
    min-width: 125px !important;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    margin: 16px 0;
    .simpleSelectLabel {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
      font-family: Inter !important;
    }
  }
`;

export const PricingSuggestButton = styled.div`
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    .simpleSelectLabel {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
      font-family: Inter !important;
    }
  }
`;

export const ContainerActions = styled.div`
  display: none;
  //quando desocultar por display flex
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(${tooLevel}px + 10px);
`;

export const ButtonActions = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 101px;
  height: 40px;

  border: 0;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.blue._400.hex()};
  margin-right: 2rem;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.palette.white.main.hex()};
  }

  p {
    color: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const ModalActions = styled.div<{ showActionsOptions: boolean }>`
  display: ${({ showActionsOptions }) => (showActionsOptions ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 180px;
  height: 150px;
  border-radius: 10px;

  position: absolute;
  top: 105px;
  margin-right: 105px;
  z-index: 11;
`;

export const Action = styled.div<{ loading?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 3px solid #d9dcdf;
  margin-bottom: 0.5rem;
  padding: 0 1.2rem;

  position: relative;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  animation: ${showElementAnimation} 0.5s ease forwards;

  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    animation: ${fadeIn} 2s ease forwards;
  }

  p {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-size: 16px;
    animation: ${fadeIn} 2s ease forwards;
  }

  ${({ loading }) => loading
    && css`
      img {
        animation: ${rotate} 1s linear infinite;
      }
    `}
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);
  z-index: 10;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 6px;
  gap: 8px;
  padding: 10px 16px;
  margin-top: 8px;
  cursor: pointer;

  h6 { 
    padding-left: 35%;
    font-weight: 600;
    font-size: 14px;
    font-family: Inter;
    text-align: center;
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const Spacing = styled.div`
  margin: 4px 0;
`;

export const WrapperModal = styled.div`
  width: 100%;
  height: auto;
`;
