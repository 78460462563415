import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin: 5rem 0;

  .content-others {
    margin-top: 3rem;
  }
`;

export const Content = styled.div``;

export const Divider = styled.div<{ isDashed?: boolean }>`
  border-top: 0.5px ${({ isDashed }) => (isDashed ? 'dashed' : 'solid')} ${({ theme }) => theme.palette.grey._305.hex()};
  margin: 0 1rem;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;

  span {
    width: 100%;
    max-width: fit-content;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.19rem;
  }
  
  .total-commission, .total-others {
    font-weight: 700;
  }
`;

export const SummaryContainer = styled.section<{ disableMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ disableMarginTop }) => (disableMarginTop ? 0 : '2.5rem')};
  margin-left: 3.5rem;
  width: 100%;

  .origin-platform {
    width: 100%;
    max-width: fit-content;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 17px;
  }
  
  .text-none-expenses {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0.3rem 0;
  }

  @media(max-width: 768px) {
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
`;

export const SummaryContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0 1rem;

  .range-dates, .expense-reason {
    width: 100%;
    max-width: fit-content;

    strong, span {
      color: ${({ theme }) => theme.palette.black._100.hex()};
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 0.938rem;
      line-height: 1rem;
      font-style: normal;
    }
  }

  .total-value {
    width: 100%;
    max-width: fit-content;
    margin-right: 3.5rem;

    span {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
  
  @media(max-width: 768px) {
    margin: 0.5rem 0 0 0.5rem;

    .total-value {
      margin-right: 1rem;
    }
  }
`;
