import React, {
  FC, useCallback, useMemo,
} from 'react';

import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import photoSketch from '../../assets/icons/expense/photoSketch.svg';

import { FileProps } from '../../context/FileContext/types';
import { useUpload } from '../../hooks/useUpload/useUpload';

import {
  MdError,
  PreviewImage,
  ContainerFile,
  UploadMessage,
  MdCheckCircle,
  DropContainer,
  ContainerProgressbar,
} from './styles';

interface UploadProps {
  dataCy?: string;
  file: FileProps;
  accept?: string[];
  setFile: Function;
  multiple?: boolean;
  disabled?: boolean;
  onCloseFile?: boolean;
  defaultPreview?: string;
  useDefaultPreview?: boolean;
  disableDragAndDrop?: boolean;
}

const Upload: FC<UploadProps> = ({
  file,
  disabled,
  multiple,
  dataCy = '',
  useDefaultPreview,
  setFile = () => {},
  onCloseFile = false,
  disableDragAndDrop = false,
  defaultPreview = photoSketch,
  accept = ['image/*', 'application/*'],
}) => {
  const {
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useUpload({
    file,
    accept,
    dataCy,
    setFile,
    multiple,
    disabled,
    onCloseFile,
    defaultPreview,
  });

  const imgSketch: HTMLImageElement = new Image();
  imgSketch.src = defaultPreview;

  const imgSrc = useMemo(() => {
    if (file?.previewURL && !useDefaultPreview) {
      return file.previewURL;
    }
    return defaultPreview;
  }, [file]);

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <PreviewImage
          data-cy={dataCy}
          src={imgSrc}
          alt="file"
          previewImageWidth={
            imgSketch.width <= 120 && imgSketch.width !== 0
              ? `${imgSketch.width}px`
              : '99px'
          }
          previewImageHeight={
            imgSketch.height <= 105 && imgSketch.height !== 0
              ? `${imgSketch.height}px`
              : '78px'
          }
        />
      );
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Arquivo suportado</UploadMessage>;
  }, [isDragActive, isDragReject, file]);

  const rootProps = !disableDragAndDrop ? { ...getRootProps() } : {};

  return (
    <>
      <DropContainer
        {...rootProps}
        dropzoneWidth={imgSketch.width <= 120 ? `${imgSketch.width}px` : '100%'}
        dropzoneHeight={
          imgSketch.height <= 105 ? `${imgSketch.height}px` : '105px'
        }
        disableDragAndDrop={disableDragAndDrop}
      >
        <input id={file.id} {...getInputProps()} type="file" />
        <ContainerProgressbar>
          {!file.uploaded && !file.error && file.uploadProgress !== 0 && (
            <CircularProgressbar
              styles={{
                root: {
                  width: 35,
                },
                path: {
                  stroke: '#3CC3EE',
                },
                text: {
                  fontSize: 40,
                  fontWeight: 'bold',
                },
              }}
              strokeWidth={10}
              text={`${file.uploadProgress === 0 ? '' : file.uploadProgress}`}
              value={file.uploadProgress || 0}
            />
          )}
          {file.uploaded && <MdCheckCircle />}
          {file.error && <MdError />}
        </ContainerProgressbar>
        {renderDragMessage()}
      </DropContainer>
      <ContainerFile>
        {file.file && <span>{file.readableSize}</span>}
      </ContainerFile>
    </>
  );
};

Upload.defaultProps = {
  multiple: undefined,
  disabled: undefined,
  useDefaultPreview: undefined,
};

export default Upload;
