import styled from 'styled-components';
import { ArrowRight } from 'react-feather';
import { Alert as AlertComponent } from '@mui/material';

export const RightArrowIndicator = styled(ArrowRight)`
  margin: 20px 8px 0 8px;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
`;

export const FormContainer = styled.div`
  display: flex;
  margin: 16px;
  justify-content: space-between;
  align-items: center;
  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 72px);
    flex-direction: column;
  }
`;

export const BoldText = styled.p`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 5px 0;
`;

export const BoldTextMargin = styled(BoldText)`
  margin: 25px 0;
`;

export const MidlleText = styled(BoldText)`
  font-weight: 500;
`;

export const Space = styled.div`
  height: 10px;
`;

export const DataGrid = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const BoldSpan = styled.span`
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 5px 0;
`;

export const ContainerName = styled.div`
  font-weight: 500;
  background: ${({ theme }) => theme.palette.white._400.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 10px;
  margin-bottom: 15px;
`;

export const ValueContainer = styled.div`
  background: ${({ theme }) => theme.palette.white._400.hex()};
  border-radius: 10px;
  padding:15px;
  margin-top: 10px;
`;
export const TotalPriceContainer = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey._400.hex()};
  margin: 10px 5px;
  display: flex;
  justify-content: space-between;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const EditGuest = styled.div`
  && {
    .MuiButton-root {
      padding: 0;
      margin: 0;
      height: auto;
      margin-right: 10px;
    }

    .MuiButton-label {
      font-size: 14px;
    }
  }
`;

export const ContainerAutoComplete = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

export const Alert = styled(AlertComponent)`
  margin: 10px 0;
`;

export const Reason = styled.div`
  margin-top: 33px;
`;

export const Notes = styled.div`
  margin-top: 10px;
`;

export const InformationDiarias = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
