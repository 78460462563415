import React, { useEffect } from 'react';
import { Container } from './styles';
import InsertChangePropertyStatus from '../../../components/InsertData/InsertChangePropertyStatus';

const InsertChangePropertyStatusPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <InsertChangePropertyStatus />
    </Container>
  );
};

export default InsertChangePropertyStatusPage;
