import { numberToCurrency } from '../../../utils/Formatter';
import { ProperPayOwnerDashboardResponse } from './types';

export const formatMonetaryValue = (value: number) => (value === 0 ? '-' : numberToCurrency(value));

export const sumExecutedDailys = (toReceive: number, toReceiveFollowingMonth: number) => {
  const total = toReceive + toReceiveFollowingMonth;
  return total;
};

export const sumPaidDailys = (revenue: number, revenuePriorMonth: number) => {
  const total = revenue + revenuePriorMonth;
  return total;
};

export const sumOutgoing = (commission: number, expenses: number) => {
  const total = commission + expenses;
  return total;
};

export const calculateTotalSum = (data: any) => {
  const totalSum = Object.keys(data).reduce((acc, key) => {
    if (key === 'total') return acc;
    return acc + (data?.[key] || 0);
  }, 0);

  return totalSum;
};

export const getFormattedFinancialData = (data: any) => ({
  1: formatMonetaryValue(data?.[1] || 0),
  2: formatMonetaryValue(data?.[2] || 0),
  3: formatMonetaryValue(data?.[3] || 0),
  4: formatMonetaryValue(data?.[4] || 0),
  5: formatMonetaryValue(data?.[5] || 0),
  6: formatMonetaryValue(data?.[6] || 0),
  7: formatMonetaryValue(data?.[7] || 0),
  8: formatMonetaryValue(data?.[8] || 0),
  9: formatMonetaryValue(data?.[9] || 0),
  10: formatMonetaryValue(data?.[10] || 0),
  11: formatMonetaryValue(data?.[11] || 0),
  12: formatMonetaryValue(data?.[12] || 0),
  total: formatMonetaryValue(data?.total || 0),
});

export const getMonthlyFormattedFinancialData = (data: any) => ({
  ...getFormattedFinancialData(data),
});

export const initialValuesOwnerDashboard: ProperPayOwnerDashboardResponse = {
  executed_dailys: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  paid_dailys: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  refunds: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  to_receive: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  to_receive_following_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  revenue: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  revenue_prior_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  outgoing: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  commission: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  expenses: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  results: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  transfer: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  final_balance: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
};
