import React from 'react';

import {
  ConversionBar,
  Item,
  ConversionBarMask,
  ConversionBarSpace,
} from './style';

import CloseButton from './CloseButton';

interface IHeader {
  progress: number;
}

const Bar = ({
  progress,
}: IHeader) => (
  <>
    <ConversionBarSpace>
      <CloseButton />

      {Array(5)
        .fill(1)
        .map((item) => (
          <Item key={item} />
        ))}
    </ConversionBarSpace>

    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
    }}
    >
      <ConversionBarMask progress={progress} />
    </div>
    <ConversionBar />
  </>
);

export default Bar;
