import React from 'react';
import {
  Title, Container, Text, Header,
} from '../../../../common/style';
import Card from '../../../../common/Card/Card';

import boyMoney from './img/boy-money.png';
import { Content, Texts } from './style';
import { actualSize } from '../../../../../../../../../hooks/useMobile/useMobile';

const EarningMoneyIndicating = () => (
  <Container>
    <Title>Como ganhar dinheiro indicando imóveis para a Seazone?</Title>
    <Card style={{ maxWidth: actualSize === 'Desktop' ? '50%' : 'auto' }}>
      <Content>
        <Header>
          <img
            loading="lazy"
            style={{ width: '100%', maxWidth: '150px' }}
            src={boyMoney}
            alt="desenho de uma pessoa com um sinal de moeda"
          />
          <Text style={{ color: '#FC605B', fontSize: '1.8rem' }}>
            Remuneração Variável (profissionais):
          </Text>
        </Header>

        <Texts>
          <Text desktopMaxWidth="700px" style={{ fontWeight: 'normal' }}>
            Receba 2% de toda a receita gerada pelo proprietário indicado para a
            Seazone após contrato assinado nos 12 primeiros meses.
          </Text>
          <Text desktopMaxWidth="500px" style={{ fontWeight: 'normal' }}>
            Esse valor será creditado na sua carteira após o fechamento financeiro
            mensal do imóvel convertido.
          </Text>
        </Texts>
      </Content>
    </Card>
  </Container>
);

export default EarningMoneyIndicating;
