import React, { FC } from 'react';

import {
  Container,
  HeaderContainer,
  HeaderContent,
  BodyContainer,
  BodyContent,
  TitleContainer,
  Title,
  Subtitle,
  ContainerCircle,
  CircleIcon,
} from './styles';

import { ContainerArrows, Image } from '../../../Grid/styles';

import arrowBottom from '../../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../../assets/icons/generals/arrowTop.svg';

type GridType = 'property' | 'host' | 'owner';

type GridSkeletonProps = {
  type: GridType;
};

const GridSkeleton: FC<GridSkeletonProps> = ({
  type = 'property',
}) => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const checkIfDisableColor = (index: number) => {
    let disable = false;
    if (type === 'property' && index <= 2) {
      disable = true;
    } else if (['host', 'owner'].includes(type) && index === 0) {
      disable = true;
    }
    return disable;
  };

  const checkIfDisableSubtitle = (index: number) => checkIfDisableColor(index);

  const checkIfDisableArrows = (index: number) => {
    let disable = false;
    if (type === 'property' && index > 2) {
      disable = true;
    } else if (['host', 'owner'].includes(type) && index > 0) {
      disable = true;
    }
    return disable;
  };

  const HeaderContentComponent = () => (
    <>
      {items.map((id, index) => (
        <HeaderContent key={id} disableColor={checkIfDisableColor(index)}>
          <TitleContainer>
            <Title>
              <p>xxxxxxxx</p>
            </Title>
            {!checkIfDisableSubtitle(index) && (
              <Subtitle>
                <p>yyyyy</p>
              </Subtitle>
            )}
          </TitleContainer>

          {!checkIfDisableArrows(index) && (
            <ContainerArrows>
              <Image src={arrowTop} alt="Seta para cima" />
              <Image src={arrowBottom} alt="Seta para baixo" />
            </ContainerArrows>
          )}
        </HeaderContent>
      ))}
    </>
  );

  const BodyContentComponent = () => (
    <>
      {items.map((id, index) => (
        <BodyContent key={id} disableColor={checkIfDisableColor(index)}>
          <TitleContainer>
            {index === 0 ? (
              <ContainerCircle>
                <CircleIcon />
                <Title>
                  <p>xxxxxxxx</p>
                </Title>
                <CircleIcon />
              </ContainerCircle>
            ) : (
              <Title>
                <p>xxxxxxxx</p>
              </Title>
            )}
          </TitleContainer>
        </BodyContent>
      ))}
    </>
  );

  return (
    <Container>
      <HeaderContainer>
        <HeaderContentComponent />
      </HeaderContainer>
      {items.map((id) => (
        <BodyContainer key={id}>
          <BodyContentComponent />
        </BodyContainer>
      ))}
    </Container>
  );
};

export default GridSkeleton;
