import styled, { css, keyframes } from 'styled-components';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const PreCheckinButton = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  div {
    opacity: 0;
    animation: ${showItems} 0.3s ease forwards;
  }

  button {
    opacity: 0;
    animation: ${showItems} 0.3s ease forwards;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    background-color: transparent;
    margin-left: 1.4rem;

    img {
      width: 18px;
      opacity: 0.6;
      transition: 0.2s;
      background-color: transparent;
    }

    :hover {
      img {
        opacity: 1;
        transform: translateY(-3px);
      }
    }
  }
`;

export const SvgContainer = styled.div<{
  isPrecheckinLinkSent: boolean;
  isPreCheckinFullFiled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${({ isPrecheckinLinkSent, theme }) => (isPrecheckinLinkSent
    ? theme.palette.blue._970.hex()
    : theme.palette.grey._800.hex())};
    }
  }

  &&&&& {
    ${({ isPreCheckinFullFiled }) => isPreCheckinFullFiled
      && css`
        path {
          fill: green;
        }
      `}
  }
`;
