import React from 'react';
import { Container } from './style';

import GuestInfo from './GuestInfo';
import CopyGuests from './CopyGuests';
import GuestsList from './GuestsList';
import MainGuestText from './MainGuest';
import AddGuestButton from './AddGuestButton';

const AddGuest = () => (
  <Container>
    <GuestInfo />
    <AddGuestButton />
    <MainGuestText />
    <CopyGuests />
    <GuestsList />
  </Container>
);

export default AddGuest;
