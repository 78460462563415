import React, { useEffect, useState } from 'react';
import SimpleSelect from '../../SimpleSelect';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import CardReservation from '../CardReservation';
import iconFilter from '../../../assets/icons/multicalendar/filter.svg';

import {
  Container,
  Divider, Header, SearchButton, Title, ReservationsContent,
} from './styles';
import LateralModal from '../../LateralModal';
import { useModal } from '../../../hooks/ModalHook/useModal';
import FormReservationsFilters from '../FormReservationsFilters/FormReservationsFilters';
import Tooltip from '../../Tooltip';
import { getConciliations } from '../../../services/Conciliation/request';
import CardSkeleton from '../LoadingShimmer/CardSkeleton';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';

const statusPayment = [
  {
    name: 'Parcialmente pago',
  },
  {
    name: 'Não Pago',
  },
];

const ContentConciledReservations = (): JSX.Element => {
  const [openLateralModal, setOpenLateralModal] = useState(false);
  const { handleOpen } = useModal();
  const { setListConciliations, listConciliations } = useConciliation();

  const handleOpenlLateral = () => {
    handleOpen(false);
    setOpenLateralModal(true);
  };

  const handleCloseAll = () => {
    setOpenLateralModal(false);
  };

  useEffect(() => {
    async function listReservationsConciliation() {
      const response = await getConciliations();
      setListConciliations(response);
    }
    listReservationsConciliation();
  }, []);

  return (
    <Container>
      <Header>
        <Title>Reservas</Title>
        <div className="filters">
          <Tooltip text="Mais filtros">
            <SearchButton type="button" onClick={handleOpenlLateral}><img src={iconFilter} alt="" /></SearchButton>
          </Tooltip>
          <SimpleSelect
            id="status"
            options={(statusPayment || []).map<SelectOption>(({ name }) => ({
              value: name,
              valueLabel: name,
            }))}
            placeholder="Status pagamento"
          />
        </div>
      </Header>
      <ReservationsContent>
        {listConciliations.length > 0 ? (
          listConciliations.map((reservation) => (
            <>
              <CardReservation reservation={{
                check_in_date: reservation.reservation.check_in_date,
                check_out_date: reservation.reservation.check_out_date,
                guest: reservation.reservation.guest,
                id: reservation.id.toString(),
                ota: reservation.reservation.ota,
                property: reservation.reservation.property,
                total_price: parseInt(reservation.reservation.total_price, 10),
                payment: {
                  id: reservation.airbnb_payment.id,
                  amount_paid: reservation.reservation.paid_amount,
                  guest: reservation.airbnb_payment.guest,
                  ota: reservation.airbnb_payment.ota,
                  payment_date: reservation.airbnb_payment.payment_date,
                  property: reservation.airbnb_payment.property,
                  cleaning_fee: reservation.airbnb_payment.cleaning_fee,
                  confirmation_code: reservation.airbnb_payment.confirmation_code,
                  host_fee: reservation.airbnb_payment.host_fee,
                  payment_type: reservation.airbnb_payment.payment_type,
                },
                owing_value: reservation.reservation.owing_value,
              }}
              />
              <Divider />
            </>
          ))) : (
            <CardSkeleton />
        )}
      </ReservationsContent>
      <LateralModal
        open={openLateralModal}
        setOpen={setOpenLateralModal}
        onClose={handleCloseAll}
        headerTitle="Filtros"
        anchor="left"
      >
        <FormReservationsFilters conciliation={false} onClose={handleCloseAll} />
      </LateralModal>
    </Container>
  );
};

export default ContentConciledReservations;
