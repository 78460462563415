export const containerAnimation = {
  transition: { delay: 2 },
  whileTap: { scale: 0.85 },
  animate: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [1, -0.05, 0.66, 1],
    },
  },
  initial: { scale: 0, y: 80, opacity: 0.5 },
  exit: { scale: 0, y: 80, opacity: 0.5 },
};
