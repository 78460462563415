import React, {
  createContext, useState, ReactNode, useContext, Dispatch, SetStateAction,
} from 'react';

interface CreateBlockPropertyHostPageContextData {
  isLateralModalOpen: boolean
  isSuccessModalOpen: boolean
  selectedProperty: number
  checkInDate: string
  checkOutDate: string
  blockPropertyCode: string
  handleOpenModal: Dispatch<SetStateAction<boolean>>
  handleCloseModal: () => void
  handleCloseSuccessModal: () => void
  handleOpenSuccessModal: () => void
  handleSelectProperty: (propertyId: number) => void
  handleCheckIn: (checkIn: string) => void
  handleCheckOut: (checkOut: string) => void
  handleBlockPropertyCode: (propertyCode: string) => void
}

interface CreateBlockPropertyHostPageProviderProps {
  children: ReactNode;
}

const CreateBlockPropertyHostPageContext = createContext<CreateBlockPropertyHostPageContextData>({

} as CreateBlockPropertyHostPageContextData);

export const CreateBlockPropertyHostPageProvider = ({
  children,
}: CreateBlockPropertyHostPageProviderProps) => {
  const [isLateralModalOpen, setIsLateralModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(-1);
  const [blockCheckInDate, setBlockCheckInDate] = useState('dd/mm/yyyy');
  const [blockCheckOutDate, setBlockCheckOutDate] = useState('dd/mm/yyyy');
  const [blockPropertyCode, setBlockPropertyCode] = useState('');

  const closeModal = () => {
    setIsLateralModalOpen(false);
    setSelectedProperty(-1);
  };

  const handleSetSelectedProperty = (propertyId: number) => {
    setSelectedProperty(propertyId);
  };

  const handleBlockCheckInDate = (checkIn: string) => {
    setBlockCheckInDate(checkIn);
  };

  const handleBlockCheckOutDate = (checkOut: string) => {
    setBlockCheckOutDate(checkOut);
  };

  const handleBlockPropertyCode = (propertyCode: string) => {
    setBlockPropertyCode(propertyCode);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setBlockPropertyCode('');
    setBlockCheckOutDate('dd/mm/yyyy');
    setBlockCheckInDate('dd/mm/yyyy');
  };

  return (
    <CreateBlockPropertyHostPageContext.Provider value={{
      isLateralModalOpen,
      handleOpenModal: setIsLateralModalOpen,
      handleCloseModal: closeModal,
      selectedProperty,
      handleSelectProperty: handleSetSelectedProperty,
      isSuccessModalOpen,
      handleCloseSuccessModal,
      checkInDate: blockCheckInDate,
      checkOutDate: blockCheckOutDate,
      handleCheckIn: handleBlockCheckInDate,
      handleCheckOut: handleBlockCheckOutDate,
      blockPropertyCode,
      handleBlockPropertyCode,
      handleOpenSuccessModal: () => setIsSuccessModalOpen(true),
    }}
    >
      {children}
    </CreateBlockPropertyHostPageContext.Provider>
  );
};

export function useCreateBlockPropertyHostPageData(): CreateBlockPropertyHostPageContextData {
  const {
    isLateralModalOpen,
    handleCloseModal,
    handleOpenModal,
    handleSelectProperty,
    selectedProperty,
    handleCloseSuccessModal,
    isSuccessModalOpen,
    checkInDate,
    checkOutDate,
    handleCheckIn,
    handleCheckOut,
    blockPropertyCode,
    handleBlockPropertyCode,
    handleOpenSuccessModal,
  } = useContext(CreateBlockPropertyHostPageContext);
  return {
    isLateralModalOpen,
    handleCloseModal,
    handleOpenModal,
    handleSelectProperty,
    selectedProperty,
    handleCloseSuccessModal,
    isSuccessModalOpen,
    checkInDate,
    checkOutDate,
    handleCheckIn,
    handleCheckOut,
    blockPropertyCode,
    handleBlockPropertyCode,
    handleOpenSuccessModal,
  };
}
