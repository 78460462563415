import styled from 'styled-components';

export const Container = styled.form`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  width: 100%;
  height: 100%;
  h1 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 0 2rem;
    margin-top: 1rem;
  }

  .two-columns {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }

    @media (max-width: 780px){
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .one-column {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
  }

  .switch {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
    border-radius: 5px;
    padding: 1rem;
    width: 100%;

    h2 {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
    }

    div {
      justify-self: flex-end;
    }

  }

  @media (max-width: 1100px){
    grid-template-columns: 1fr;
  }
`;

export const PasswordText = styled.p`
  grid-column-start: 1;
  grid-column-end: 2;
  width: 60%;
  color: ${({ theme }) => theme.palette.grey._750.hex()};
  font-size: 0.9rem;
`;
