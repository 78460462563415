import React, { useState } from 'react';
import { formatedDateToShow } from '../../../../utils/Formatter';
import { UseFormik } from '../../../../utils/Formik/types';
import DatePicker from '../../../DatePicker';
import SelectModal from '../../../SelectModal/SelectModal';
import { Container, Row } from './styles';

interface Props {
  formik: UseFormik<any>;
}

const SelectPeriod = ({ formik }: Props) => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [inputDate, setInputDate] = useState<string>('Clique para selecionar o período de datas');

  const handleClickOpenDateTransfer = () => {
    setOpenDate(!openDate);
  };

  function handleAppliedDateFilter() {
    setInputDate(`${formatedDateToShow(formik.values.startDate)} á ${formatedDateToShow(formik.values.endDate)}`);
  }

  return (
    <>
      <SelectModal
        id="dates"
        dataCy="export-reservations-modal-dates"
        onClick={handleClickOpenDateTransfer}
        value={inputDate}
      />
      {openDate && (
      <Container>
        <Row>
          <p>Início</p>
          <DatePicker
            id="startDate"
            formik={formik}
            viewsCustom={['day', 'month', 'year']}
          />
        </Row>
        <Row>
          <p>Fim</p>
          <DatePicker
            id="endDate"
            formik={formik}
            viewsCustom={['day', 'month', 'year']}
          />
        </Row>
        <Row className="content-buttons">
          <button type="button" className="clear">Limpar</button>
          <button type="button" className="apply" onClick={() => handleAppliedDateFilter()}>Aplicar</button>
        </Row>
      </Container>
      )}
    </>
  );
};

export default SelectPeriod;
