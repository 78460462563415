import React from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ArrowsContainer, Container, Item } from './style';
import { tableConfig } from '../config';
import { useOwnerPerspectiveDashboard } from '../../../../context/OwnerPerspectiveDashboard/OwnerPerspectiveDashboard';

const Arrows = ({ value }: { value: string }) => {
  const { setSort, sort } = useOwnerPerspectiveDashboard();

  const handleClick = () => {
    if (sort.direction === 'asc' && sort.name === value) {
      setSort({ name: value, direction: 'desc' });
    } else if (sort.direction === 'desc' && sort.name === value) {
      setSort({ name: value, direction: null });
    } else if (sort.direction === null && sort.name === value) {
      setSort({ name: value, direction: 'asc' });
    } else {
      setSort({ name: value, direction: null });
    }
  };

  return (
    <ArrowsContainer onClick={handleClick}>
      {sort.direction === null && (
        <>
          <KeyboardArrowUpIcon />
          <KeyboardArrowDownIcon style={{ marginTop: '-10px' }} />
        </>
      )}
      {sort.direction === 'asc' && sort.name === value && (
        <KeyboardArrowUpIcon />
      )}
      {sort.direction === 'desc' && sort.name === value && (
        <KeyboardArrowDownIcon />
      )}
    </ArrowsContainer>
  );
};

const Header = () => (
  <Container>
    {tableConfig.headers.map((item) => (
      <Item key={item.id}>
        {item.title}
        <Arrows value={item.id} />
      </Item>
    ))}
  </Container>
);

export default Header;
