import React from 'react';
import { useFinancialFeedback } from '../../../../../hooks/FinancialFeedbackContext/FinancialFeedbackContext';
import { IFilterOptions, ITableCardsData } from '../../../types';
import Status from './Status';

import {
  Body,
  Title,
  Header,
  LeftSide,
  RightSide,
  ValueBody,
  Container,
  Description,
} from './styles';

interface ICard {
  title: string;
  status?: number;
  filter: IFilterOptions;
  description: string;
  onlyStatus?: boolean;
  table: ITableCardsData[];
}

const Card = ({
  title,
  table,
  status,
  filter,
  onlyStatus,
  description,
}: ICard) => {
  const { filterOption } = useFinancialFeedback();

  const validateFunction = () => {
    if (filter === filterOption) {
      return true;
    } if (filterOption === 'all') {
      return true;
    }

    return false;
  };

  const validate = validateFunction();

  if (validate) {
    return (
      <Container>
        <LeftSide>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </LeftSide>
        <RightSide>
          <Header>
            <h1>Indicadores</h1>
            <h1>Valor</h1>
            <Status onlyStatus={onlyStatus} status={status && status * 100} />
          </Header>
          <Body>
            {table?.map((card) => (
              <ValueBody key={card.id}>
                <h2>{card?.indicator}</h2>
                <h2>{card?.value || '-'}</h2>
              </ValueBody>
            ))}
          </Body>
        </RightSide>
      </Container>
    );
  }

  return <></>;
};

Card.defaultProps = {
  status: undefined,
  onlyStatus: false,
};

export default Card;
