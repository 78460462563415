import styled from 'styled-components';
import wave from './img/wave.svg';

export const Container = styled.div`
  background: #00153E;
  padding-bottom: 200px;

  background-image: url(${wave});
  background-size: cover;
`;
