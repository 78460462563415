import styled from 'styled-components';

export const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  color: #394760;
`;

export const SubText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  font-size: 1rem;
  color: #394760;

  a {
    margin: 0 0 0 6px;
    border: none;
    background: none;
    text-decoration: none;
    color: #394760;
    border-bottom: 1px solid #394760;
  }
`;
