import { Container } from './style';

import Body from './Body';
import Header from './Header';

import { useGuest } from './hooks/useGuest';
import { IGuestFront } from '../../../../../../../services/PreCheckin/types';

interface IGuest {
  index: number;
  isMain: boolean;
  guest: IGuestFront;
}

const Guest = ({
  index,
  guest,
  isMain,
}: IGuest) => {
  const {
    ref,
    formik,
    loading,
    expanded,
    setExpanded,
  } = useGuest({ guest, isMain });

  return (
    <Container
      ref={ref}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 20, opacity: 0 }}
      transition={{ delay: index * 0.1 }}
      exit={{
        y: 20,
        opacity: 0,
        transition: {
          duration: 0.4,
          ease: [1, -0.05, 0.66, 1],
        },
      }}
    >
      <Header
        guest={guest}
        index={index}
        isMain={isMain}
        expanded={expanded}
        guestLoading={loading}
        setExpanded={setExpanded}
      />

      <Body
        guest={guest}
        isMain={isMain}
        formik={formik}
        expanded={expanded}
        loading={loading.is}
      />
    </Container>
  );
};

export default Guest;
