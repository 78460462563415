/* eslint-disable no-param-reassign */
import axios from 'axios';

const slackRequest = axios.create({
  baseURL: process.env.REACT_APP_SLACK_WEBHOOK,
  transformRequest: [(data, headers) => {
    // @ts-ignore
    delete headers.post['Content-Type'];
    return data;
  }],
});

export default slackRequest;
