/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { FormButton } from '../../../..';

import CardInfo from './CardInfo';
import ValueTable from './ValueTable';

import {
  Line,
  Title,
  Header,
  Content,
  SubTitle,
  Container,
  TopContent,
  ButtonsContainer,
  CardInfoHeaderContainer,
} from './styles';

import arrowBottom from '../../../../../assets/icons/generals/arrowBottom.svg';
import { usePaymentVoucher } from '../../../../../hooks/PaymentVoucherHook/usePaymentVoucher';
import { formatteMoneyVoucher } from '../../../../../services/PaymentVoucher/utils';

interface MainContentProps {
  onClickFinish: Function;
  handleOpenShowAddVoucherScreen: Function;
}

const MainContent = ({
  onClickFinish,
  handleOpenShowAddVoucherScreen,
}: MainContentProps) => {
  const { checkedList } = usePaymentVoucher();
  const [isShowingCardInfo, setIsShowingCardInfo] = useState(true);

  const handleHideOrShowCardInfo = () => {
    setIsShowingCardInfo(!isShowingCardInfo);
  };

  const getTotalValueFromCheckedList = () => {
    let totalValue = 0;
    checkedList.completes.forEach((item) => {
      totalValue += item.totalValue;
    });
    checkedList.pendings.forEach((item) => {
      totalValue += item.totalValue;
    });
    return totalValue;
  };

  const getPendingValueFromCheckedList = () => {
    let pendingValue = 0;
    checkedList.completes.forEach((item) => {
      pendingValue += item.pendingValue;
    });
    checkedList.pendings.forEach((item) => {
      pendingValue += item.pendingValue;
    });
    return pendingValue;
  };

  const pendingValueGetted = getPendingValueFromCheckedList();
  const totalValueGetted = getTotalValueFromCheckedList();

  const pendingValueFormatted = formatteMoneyVoucher(pendingValueGetted);
  const totalValueFormatted = formatteMoneyVoucher(totalValueGetted);

  return (
    <>
      <Container>
        <Content>
          <TopContent>
            <Header>
              <Title>Envie os comprovantes de pagamento</Title>
            </Header>

            <CardInfoHeaderContainer
              onClick={handleHideOrShowCardInfo}
              isShowingCardInfo={isShowingCardInfo}
            >
              <SubTitle>Imóvel (s)</SubTitle>
              <div>
                <Line />
              </div>
              <img
                src={arrowBottom}
                alt="seta para mostrar ou ocultar componentes"
              />
            </CardInfoHeaderContainer>

            {isShowingCardInfo
              && checkedList.completes.map((item) => {
                if (item.checked === true) {
                  return (
                    <CardInfo
                      key={item.id}
                      reservationType="completes"
                      code={item.propertyCode}
                      staff={item.reservationStaff}
                      address={item.propertyAddress}
                      checkin={item.checkin}
                    />
                  );
                }
                return <></>;
              })}

            {isShowingCardInfo
              && checkedList.pendings.map((item) => {
                if (item.checked === true) {
                  return (
                    <CardInfo
                      key={item.id}
                      reservationType="pendings"
                      code={item.propertyCode}
                      staff={item.reservationStaff}
                      address={item.propertyAddress}
                      checkin={item.checkin}
                    />
                  );
                }
                return <></>;
              })}

            <ValueTable
              totalValue={totalValueFormatted}
              pendingValue={pendingValueFormatted}
              handleOpenShowAddVoucherScreen={handleOpenShowAddVoucherScreen}
            />
          </TopContent>
        </Content>
        {/* <ButtonsContainer>
          <FormButton onClick={() => onClickFinish()} type="button" variant="outlined">
            Cancelar
          </FormButton>
        </ButtonsContainer> */}
      </Container>
    </>
  );
};

export default MainContent;
