/* eslint-disable no-await-in-loop */
import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { UseFormik } from '../../../../../utils/Formik/types';

import { Container } from './styles';
import { sleep } from '../../../../../utils/Sleep';
import { addZeroLeft } from '../../../../../utils/Formatter';
import { useToast } from '../../../../../context/ToastContext';
import { useNfList } from '../../../../../hooks/NfListHook/useNfList';

import {
  getFilesByFileUid,
  generateNFSCsv,
} from '../../../../../services/NFlist/request';

interface IExportSpreadsheet {
  children: string;
  formik: UseFormik<any>;
}

const nfsCsvController = new AbortController();

const ExportSpreadsheet = ({ formik, children }: IExportSpreadsheet) => {
  const toast = useToast();

  const { handleLoadingFiles } = useNfList();

  const polling = useCallback(async (fileID: string, signal: AbortSignal) => {
    let counter = 0;
    const TRIES = 5;
    const CONDITIONAL_TRIES = TRIES - 1;

    while (counter !== TRIES) {
      await sleep(2000);
      const { url } = await getFilesByFileUid(fileID, signal);

      if (url) {
        window.open(url, '_blank');
        toast.success('Arquivo baixado com sucesso');
        handleLoadingFiles(false);
        break;
      }

      if (counter === CONDITIONAL_TRIES) {
        toast.error('Erro ao baixar aquivo, tente novamente mais tarde');
        handleLoadingFiles(false);
        break;
      }

      counter += 1;
    }
  }, []);

  const handleClick = async () => {
    handleLoadingFiles(true);
    const monthWithZeroLeft = addZeroLeft(`${formik.values.period.getMonth() + 1}`);
    const formattedPeriod = `${formik.values.period.getFullYear()}-${monthWithZeroLeft}`;

    const params = {
      period: formattedPeriod,
    };

    const {
      status: csvStatus,
      file_uid: csvFileId,
    } = await generateNFSCsv(params);

    function choiseFetch(
      status: string,
      id: string,
      signal: AbortSignal,
    ) {
      switch (status) {
        case 'Processing':
          polling(id, signal);
          break;
        case undefined:
          toast.error(' novamente mais tarde');
          handleLoadingFiles(false);
          break;
        default:
          toast.error('Nenhum dado foi encontrado');
          handleLoadingFiles(false);
          break;
      }
    }

    choiseFetch(
      csvStatus,
      csvFileId,
      nfsCsvController.signal,
    );
  };

  return (
    <Container>
      <Button
        type="button"
        data-cy="btn-export-csv-nfList"
        onClick={handleClick}
      >
        <h1>{children}</h1>
      </Button>
    </Container>
  );
};

export default ExportSpreadsheet;
