import React from 'react';
import { UseFormik } from '../../../../utils/Formik/types';
import DatePickerRange from '../../../DatePickerRange';
import FormButton from '../../../FormButton';
import {
  ButtonClear, Buttons, DateContainer, ErrorIcon, Line,
} from '../styles';

interface Props {
  formik: UseFormik<any>
  error: Boolean
  onClear: Function
  onApply: Function
  ids: {
    main: string
    start: string
    end: string
  }

  [x: string]: any;
}

export const FilterPopoverContent: React.FC<Props> = ({
  formik,
  error,
  onClear,
  onApply,
  ids,
  ...rest
}) => {
  const handleApply = () => {
    onApply(ids.main);

    if (rest?.onClose) {
      rest.onClose();
    }
  };

  const handleClear = () => {
    onClear(
      ids.main,
      ids.start,
      ids.end,
    );

    if (rest?.onClose) {
      rest.onClose();
    }
  };

  return (
    <>
      <DateContainer>
        <DatePickerRange
          formik={formik}
          calendars={2}
          id1={ids.start}
          id2={ids.end}
          minDate={new Date('2000-01-02')}
          hasInitialDates
        />
      </DateContainer>
      <Line />
      <Buttons>
        <ButtonClear
          onClick={handleClear}
        >
          Limpar
        </ButtonClear>
        <FormButton
          type="button"
          variant="outlined"
          onClick={handleApply}
        >
          Aplicar
        </FormButton>
      </Buttons>
      {error && (
        <p className="messageError">
          <ErrorIcon />
          A data final deve ser maior do que a inicial!
        </p>
      )}
    </>
  );
};
