import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px 0px 0px 0px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.palette.grey._340.hex()};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey._320.hex()};
    border-radius: 10px;
  }

  @media(min-width: 1821px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } 
`;

export const GridContainer = styled.div`
  width: max-content;
`;

export const GridContent = styled.div`
  width: max-content;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GridHeaderContainer = styled.div<{ sidebarFull?: boolean }>`
  width: max-content;
  height: 51px;
  background-color: ${({ theme }) => theme.palette.blue._900.hex()};
  display: grid;
  grid-template-columns: 235px 0vw repeat(13, 100px);
  align-items: center; 
  border-radius: 10px 10px 0px 0px;

  :first-child {
    padding: 1.125rem;
  }

  .highLigth {
    color: ${({ theme }) => theme.palette.grey._465.hex()};
    
    svg {
      color: ${({ theme }) => theme.palette.grey._465.hex()};
    }
  }
`;

export const GridHeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.palette.grey._580.hex()};

  :first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    background-color: ${({ theme }) => theme.palette.blue._900.hex()};
    border-right: 0;
  }
  
  :last-child {
    border-right: 0;
  }

  span, .txt-total {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: 700;
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 0.925rem;

    &:last-child {
      border-right: none;
    }
  }

  span, p {
    color: ${({ theme }) => theme.palette.white.main.hex()};
  }
  
  svg {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    width: 15px;
    height: 15px;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    cursor: pointer;
  }
`;
