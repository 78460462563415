import {
  CleaningReservation,
  ReservationCheckin,
  ReservationCheckout,
} from '../../components/ControllerPage/LateralModal/LateralModal';
import { formatedDateOfRequest } from '../../utils/Formatter';
import request from '../request';
import {
  Checkin,
  ChecklistDataProps,
  QuestionListDataProps,
  Checkout,
  Clearning,
  RequestEvent,
  CardRequestCheckout,
  TypeProps,
} from './types';

export const getTasks = async (
  initial: string,
  final: string,
  type: string,
): Promise<RequestEvent[]> => {
  const { data } = await request.get(
    `/host_controller/?${type}_initial_date=${initial}&${type}_final_date=${final}`,
  );
  return data;
};

export const getControllerPageData = async (
  initial: string,
  final: string,
  type: TypeProps,
): Promise<CardRequestCheckout[]> => {
  const { data } = await request.get(`host_controller/${type}/`, {
    params: {
      start_date: initial,
      end_date: final,
    },
  });
  return data;
};

export const postCheckin = async (values: Checkin): Promise<ReservationCheckin> => {
  const { data } = await request.post('/check_in_controller/', {
    reservation: values.reservation,
    check_in_time: values.check_in_time,
    was_contacted: values.was_contacted,
    guest_observation: values.guest_observation,
    checklist: values?.checklist || {
      notes: '',
      concluded: false,
      questionList: [],
    },
  });
  return data;
};

export const getCheckin = async (reservation: number | undefined): Promise<Checkin[]> => {
  const { data } = await request.get(`/check_in_controller/?reservation=${reservation}`);
  return data;
};

export const putCheckin = async (values: Checkin, id: number): Promise<ReservationCheckin> => {
  const { data } = await request.put(`/check_in_controller/${id}/`, {
    reservation: values.reservation,
    check_in_time: values.check_in_time,
    was_contacted: values.was_contacted,
    guest_observation: values.guest_observation,
    checklist: values?.checklist,
  });
  return data;
};
export const postCheckout = async (values: Checkout): Promise<ReservationCheckout> => {
  const { data } = await request.post('/check_out_controller/', {
    reservation: values.reservation,
    check_out_time: values.check_out_time,
    was_contacted: values.was_contacted,
    guest_feedback: values.guest_feedback,
    checklist: values?.checklist || {
      notes: '',
      concluded: false,
      questionList: [],
    },
  });
  return data;
};
export const getCheckout = async (reservation: number | undefined): Promise<Checkout[]> => {
  const { data } = await request.get(`/check_out_controller/?reservation=${reservation}`);
  return data;
};

export const putCheckout = async (values: Checkout, id: number): Promise<ReservationCheckout> => {
  const { data } = await request.put(`/check_out_controller/${id}/`, {
    reservation: values.reservation,
    check_out_time: values.check_out_time,
    was_contacted: values.was_contacted,
    guest_feedback: values.guest_feedback,
    checklist: values?.checklist,
  });
  return data;
};
export const postClearning = async (values: Clearning): Promise<CleaningReservation> => {
  const { data } = await request.post('/cleaning_controller/', {
    reservation: values.reservation,
    was_checked: values.was_checked,
    cleaning_date: formatedDateOfRequest(values.cleaning_date),
    cleaning_time: values.cleaning_time,
    cleaning_duration: values.cleaning_duration,
    maid_name: values.maid_name,
    bedsheets_quantity: 1,
    cleaning_price: values.cleaning_price,
    checklist: values?.checklist || {
      notes: '',
      concluded: false,
      questionList: [],
    },
  });
  return data;
};

export const getClearning = async (reservation: number | undefined): Promise<Clearning[]> => {
  const { data } = await request.get(`/cleaning_controller/?reservation=${reservation}`);
  return data;
};

export const putClearning = async (values: Clearning, id: number): Promise<CleaningReservation> => {
  const { data } = await request.put(`/cleaning_controller/${id}/`, {
    reservation: values.reservation,
    was_checked: values.was_checked,
    cleaning_date: formatedDateOfRequest(values.cleaning_date),
    cleaning_time: values.cleaning_time,
    cleaning_duration: values.cleaning_duration,
    maid_name: values.maid_name,
    bedsheets_quantity: 1,
    cleaning_price: values.cleaning_price,
    checklist: values?.checklist,
  });
  return data;
};

export const getChecklistById = async (
  reservation: number | undefined,
  checkin: boolean | undefined,
  checkout: boolean | undefined,
  clearning: boolean | undefined,
): Promise<QuestionListDataProps> => {
  let type = '';
  if (checkin) {
    type = 'checkin';
  }
  if (checkout) {
    type = 'checkout';
  }
  if (clearning) {
    type = 'cleaning';
  }
  const { data } = await request.get(`/${type}/checklist/reservation/${reservation}/`);
  return data;
};

export const getAllChecklist = async (
  checkin: boolean | undefined,
  checkout: boolean | undefined,
  clearning: boolean | undefined,
): Promise<ChecklistDataProps> => {
  let type = '';
  if (checkin) {
    type = 'checkin';
  }
  if (checkout) {
    type = 'checkout';
  }
  if (clearning) {
    type = 'cleaning';
  }
  const { data } = await request.get(`/${type}/checklist/reservation/`);
  return data;
};

export const postChecklist = async (
  id: number,
  checkin: boolean | undefined,
  checkout: boolean | undefined,
  // clearning: boolean | undefined,
  checklistData: ChecklistDataProps,
): Promise<ChecklistDataProps> => {
  let type = '';
  if (checkin) {
    type = 'checkin';
  }
  if (checkout) {
    type = 'checkout';
  }
  // if (clearning) {
  //   type = 'cleaning';
  // }
  const { data } = await request.post(`/${type}/checklist/reservation/`, {
    reservation: id,
    checklist: checklistData,
  });
  return data;
};

export const putChecklist = async (
  id: number,
  checkin: boolean | undefined,
  checkout: boolean | undefined,
  // clearning: boolean | undefined,
  checklistData: ChecklistDataProps,
): Promise<ChecklistDataProps> => {
  let type = '';
  if (checkin) {
    type = 'checkin';
  }
  if (checkout) {
    type = 'checkout';
  }
  // if (clearning) {
  //   type = 'cleaning';
  // }
  const { data } = await request.put(`/${type}/checklist/reservation/${id}/`, {
    checklist: checklistData,
  });
  return data;
};
