import styled from 'styled-components';
import { motion } from 'framer-motion';
import { shimmerAnimation } from '../../../../../Calendar/LoadingShimmer/animationShimmer';

export const TableContainer = styled.div`
  background: #E4EAEF;
  flex: 1;
  border-radius: 10px 10px 0 0;
  overflow: auto;

  table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    width: 100%;
    min-width: 1400px;

    thead {
      position: sticky;
      top: 0;
      background: #E4EAEF;
      z-index: 1;

      th {
        padding: 1rem;
        text-align: center;
        color: #394760;
        font-size: 1rem;
        line-height: 1.6;
        font-weight: 700;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
        }

        &:first-child {
          padding-left: 1.5rem;
        }

        &:last-child {
          padding-right: 1.5rem;
        }
      }
    }

    tbody {
      td {
        background: #EBF0F5;
        padding: 1rem;
        font-size: 1rem;
        line-height: 1.6;
        text-align: center;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 15%;
          left: 0;
          right: 0;
          height: 70%;
          border-right: 1px solid #D9D9D9;
        }
        &:first-child {
          padding-left: 1.5rem;
        }

        &:nth-last-child(-n+1) {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 1rem;
    width: 0.5rem;
    background-color: ${({ theme }) => theme.palette.grey._400.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.blue._400.hex()};
    border-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.palette.blue._50.hex()};
  }
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #0D4BD0;
`;

export const LineShimmer = styled.div`
  display: flex;
  width: 100%;
  height: 2rem;
  border-radius: 10px;
  animation: ${shimmerAnimation} 1s forwards infinite;
  &:after{
    content: '';
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  button {
    width: 50%;
  }
`;

export const TrashButton = styled(motion.button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

export const LinkText = styled.div`
  text-decoration: underline;
  color: #0D4BD0;
  z-index: 2;
  cursor: pointer;
`;

export const Td = styled.td``;

export const ArrowsButton = styled.button`
  border: none;
  background-color: transparent;
  z-index: 20000000 !important;
`;
