import React, { useEffect } from 'react';
import { Container } from './styles';
import InsertOwnerDataComponent from '../../../components/InsertData/InsertAddOwnerData';

const InsertOwnerUserDataPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <InsertOwnerDataComponent />
    </Container>
  );
};

export default InsertOwnerUserDataPage;
