import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { useFormik } from 'formik';
import {
  BarLabel,
  ButtonStyle,
  Container, ContainerButtons, FormContent, OptionFilter,
  MutiSelectors, Selectors,
  StyledFormGroup, StyledFormLabel,
} from './styles';
import { useFilterBar } from '../../context/FilterBar';
import SimpleSelect from '../SimpleSelect';
import { SelectOption } from '../SimpleSelect/SimpleSelect';
import FormButton from '../FormButton';
import { RequestHost } from '../../services/Host/types';
import { getHosts } from '../../services/Host/request';
import { RequestCategory } from '../../services/Category/types';
import { getCategories } from '../../services/Category/request';
import { RequestLocation } from '../../services/location/types';
import { getLocations } from '../../services/location/request';
import InputCheckBox from '../InputCheckBox';
import SelectCheckbox, { CheckboxOptions } from '../SelectCheckbox/SelectCheckbox';
import SelectModal from '../SelectModal/SelectModal';
import { InputHideSelect, InputModal } from '../FilterBar/styles';
import { useUser } from '../../context/UserContext';
import { useUpdateReservation } from '../../context/UpdateReservationContext';
import { HostDetails } from '../../services/Property/types';

const quantity = [
  {
    id: '1',
    quantityNumber: '1',
  },
  {
    id: '2',
    quantityNumber: '2',
  },
  {
    id: '3',
    quantityNumber: '3',
  },
  {
    id: '4',
    quantityNumber: '4',
  },
  {
    id: '5',
    quantityNumber: '5',
  },
  {
    id: '6',
    quantityNumber: '6',
  },
];

interface Props {
  onClose: () => void;
  showFieldLocation?: boolean;
  isReloadShimmer: boolean;
}

const FormMoreFilters = ({ onClose, showFieldLocation, isReloadShimmer }: Props) => {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  const { setFilterBar, filterBar } = useFilterBar();
  const { setReloadLoadingShimmer } = useUpdateReservation();
  const [apartment, setApartment] = useState(filterBar.apartment || false);
  const [hotel, setHotel] = useState(filterBar.hotel || false);
  const [house, setHouse] = useState(filterBar.house || false);
  const [seaView, setSeaView] = useState(filterBar.seaView || false);
  const [allowPet, setAllowPet] = useState(filterBar.allowPet || false);
  const [barbecue, setBarbecue] = useState(filterBar.barbecue || false);
  const [acceptMonthly, setAcceptMonthly] = useState(filterBar.acceptMonthly || false);

  const formik = useFormik({
    initialValues: {
      categories: filterBar.categories || ['Selecione'],
      location: filterBar.location || ['Selecione'],
      bathroom: filterBar.bathroom || 'Selecione',
      bedroom: filterBar.bedroom || 'Selecione',
      host: filterBar?.host || {} as HostDetails,
    },
    onSubmit: async (values) => {
      if (isReloadShimmer) {
        setReloadLoadingShimmer(true);
      }

      let filters = {
        ...filterBar,
        apartment,
        house,
        hotel,
        seaView,
        barbecue,
        allowPet,
        acceptMonthly,
        bedroom: values.bedroom,
        bathroom: values.bathroom,
        host: values.host,
        moreSearch: true,
        categories: values.categories,
      };

      if (showFieldLocation) filters = { ...filters, location: values.location };
      setFilterBar(filters);
      onClose();
    },
  });

  const [hosts, setHosts] = useState<Array<RequestHost>>();
  const [categories, setCategories] = useState<Array<RequestCategory>>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogLocation, setOpenDialogLocation] = useState(false);
  const [locations, setLocations] = useState<Array<RequestLocation>>();

  const getLocationList = async () => {
    const values = await getLocations();
    setLocations(values);
  };

  useEffect(() => {
    if (showFieldLocation) {
      getLocationList();
    }
  }, [showFieldLocation]);

  const getCategoriesList = async () => {
    const values = await getCategories();
    setCategories(values);
  };

  const getHostsList = async () => {
    const values = await getHosts();
    setHosts(values);
  };

  const handleOutMultipleSelect = (event: any) => {
    if (event.target.id === 'MultipleSelect') {
      setOpenDialog(false);
      setOpenDialogLocation(false);
    }
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClearLocalStorage = () => {
    localStorage.removeItem('firstTimeFilter');
  };

  useEffect(() => {
    getHostsList();
    getCategoriesList();
  }, []);

  return (
    <FormContent disableScroll={openDialog || openDialogLocation} onSubmit={formik.handleSubmit}>
      <FormControl component="fieldset">
        <Container>
          <OptionFilter>
            <BarLabel>
              <StyledFormLabel>Tipo de propriedade</StyledFormLabel>
            </BarLabel>
            <StyledFormGroup>
              <InputCheckBox
                className="styleCheckbox"
                checked={apartment}
                labelName="Apartamento"
                onChange={() => setApartment(!apartment)}
              />
              <InputCheckBox
                className="styleCheckbox"
                checked={hotel}
                labelName="Hotel"
                onChange={() => setHotel(!hotel)}
              />
              <InputCheckBox
                className="styleCheckbox"
                checked={house}
                labelName="Casa"
                onChange={() => setHouse(!house)}
              />
            </StyledFormGroup>
          </OptionFilter>
          {showFieldLocation && (
          <OptionFilter>
            <BarLabel className="barLabelStyle">
              <StyledFormLabel>Localização</StyledFormLabel>
            </BarLabel>
            <MutiSelectors>
              <SelectModal
                id="location"
                onClick={() => setOpenDialogLocation(true)}
                value={formik.values.location}
              />
            </MutiSelectors>
            { openDialogLocation && (
            <>
              <InputHideSelect id="MultipleSelect" onClick={handleOutMultipleSelect} />
              <InputModal className="input-modal-style">
                <SelectCheckbox
                  id="location"
                  formik={formik}
                  defaultValue={formik.values.location}
                  handleClose={() => setOpenDialogLocation(false)}
                  options={
                      (locations || []).map<CheckboxOptions>(({
                        location,
                      }) => ({ value: location, valueLabel: location, checked: false }))
                    }
                />
              </InputModal>
            </>
            )}
          </OptionFilter>
          )}
          <OptionFilter>
            <BarLabel className="barLabelStyle">
              <StyledFormLabel>Categoria</StyledFormLabel>
            </BarLabel>
            <MutiSelectors>
              <SelectModal
                id="category"
                onClick={handleClickOpen}
                value={formik.values.categories}
              />
            </MutiSelectors>
            { openDialog && (
            <>
              <InputHideSelect id="MultipleSelect" onClick={handleOutMultipleSelect} />
              <InputModal className="input-modal-style">
                <SelectCheckbox
                  id="categories"
                  formik={formik}
                  defaultValue={formik.values.categories}
                  handleClose={handleClose}
                  options={
                (categories || []).map<CheckboxOptions>(({
                  code,
                }) => ({ value: code, valueLabel: code, checked: false }))
              }
                />
              </InputModal>
            </>
            )}
          </OptionFilter>
          <OptionFilter>
            <BarLabel className="barLabelStyle">
              <StyledFormLabel>Cômodos</StyledFormLabel>
            </BarLabel>
            <Selectors className="space">
              <span className="labelSelect">Quartos</span>
              <SimpleSelect
                id="bedroom"
                placeholder="Selecione"
                formik={formik}
                options={
                    (quantity || []).map<SelectOption>(({
                      id,
                      quantityNumber,
                    }) => ({ value: id, valueLabel: quantityNumber }))
                  }
              />
            </Selectors>
            <Selectors>
              <span className="labelSelect">Banheiros</span>
              <SimpleSelect
                id="bathroom"
                placeholder="Selecione"
                formik={formik}
                options={
                    (quantity || []).map<SelectOption>(({
                      id,
                      quantityNumber,
                    }) => ({ value: id, valueLabel: quantityNumber }))
                  }
              />
            </Selectors>
          </OptionFilter>
          <OptionFilter>
            <BarLabel className="details">
              <StyledFormLabel>Detalhes</StyledFormLabel>
            </BarLabel>
            <StyledFormGroup>
              <InputCheckBox
                className="styleCheckbox"
                checked={seaView}
                labelName="Vista para o mar"
                onChange={() => setSeaView(!seaView)}
              />
              <InputCheckBox
                className="styleCheckbox"
                checked={barbecue}
                labelName="Churrasqueira"
                onChange={() => setBarbecue(!barbecue)}
              />
              <InputCheckBox
                className="styleCheckbox"
                checked={allowPet}
                labelName="Aceita pets"
                onChange={() => setAllowPet(!allowPet)}
              />
              <InputCheckBox
                className="styleCheckbox"
                checked={acceptMonthly}
                labelName="Aceita reserva mensal"
                onChange={() => setAcceptMonthly(!acceptMonthly)}
              />
            </StyledFormGroup>
          </OptionFilter>
          {!roles.includes('Host') && (
            <OptionFilter>
              <BarLabel className="barLabelStyle">
                <StyledFormLabel>Anfitrião</StyledFormLabel>
              </BarLabel>
              <Selectors>
                <SimpleSelect
                  id="host"
                  placeholder="Selecione"
                  formik={formik}
                  className="style-multi-select"
                  options={
                    (hosts || []).map<SelectOption>(({
                      id,
                      name,
                    }) => ({ value: id, valueLabel: name }))
                  }
                />
              </Selectors>
            </OptionFilter>
          )}
          <ContainerButtons>
            <ButtonStyle>
              <FormButton
                type="button"
                variant="outlined"
                onClick={onClose}
              >
                Cancelar
              </FormButton>
            </ButtonStyle>
            <ButtonStyle>
              <FormButton
                onClick={handleClearLocalStorage}
                type="submit"
                dataCy="button-apply"
              >
                Aplicar
              </FormButton>
            </ButtonStyle>
          </ContainerButtons>
        </Container>
      </FormControl>
    </FormContent>
  );
};

FormMoreFilters.defaultProps = {
  showFieldLocation: false,
};

export default FormMoreFilters;
