import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';
import { HostProperties } from '../../services/HostProperties/types';
import { getLocations } from '../../services/location/request';
import { RequestLocation } from '../../services/location/types';
import { compareList, OrderBy } from '../../utils/Sorting';
import { Actions } from './types';

interface HostPropertiesType {
  openActions: boolean;
  setOpenActions: Function;
  actionSelected: Actions;
  setActionSelected: Function;
  loading: boolean;
  setLoading: Function;
  bedsGridExpanded: boolean;
  setBedsGridExpanded: Function;
  bathroomsGridExpanded: boolean;
  setBathroomsGridExpanded: Function;
  statusFilter: string;
  setStatusFilter: Function;
  hostNameFilter: string;
  setHostNameFilter: Function;
  codeFilter: string;
  setCodeFilter: Function;
  bedroomsFilter: number;
  setBedroomsFilter: Function;
  locationFilter: string,
  setLocationFilter: Function,
  order: OrderBy,
  setOrder: Function,
  allItemsFormatted: HostProperties[];
  setAllItemsFormatted: Function;
  handleSortingByCode: Function,
  handleSortingByStatus: Function;
  handleSortingByCotractStartDate: Function;
  handleSortingByOwnerName: Function;
  handleSortingByPhone: Function;
  handleSortingByAddress: Function;
  handleSortingByCommission: Function;
  handleSortingByHostName: Function;
  handleSortingByCleaningFee: Function;
  handleSortingByBedroomQuantity: Function;
  handleSortingByCategory: Function;
  handleSortingByImmobileId: Function;
  locations: RequestLocation[];
  setLocations: React.Dispatch<React.SetStateAction<RequestLocation[]>>;
}

export const HostPropertiesContext = createContext<HostPropertiesType>({
  openActions: false,
  setOpenActions: () => {},
  actionSelected: 'none',
  setActionSelected: () => {},
  loading: false,
  setLoading: () => {},
  bedsGridExpanded: false,
  setBedsGridExpanded: () => {},
  bathroomsGridExpanded: false,
  setBathroomsGridExpanded: () => {},
  statusFilter: '',
  setStatusFilter: () => {},
  codeFilter: '',
  setCodeFilter: () => {},
  bedroomsFilter: 0,
  setBedroomsFilter: () => {},
  locationFilter: '',
  setLocationFilter: () => {},
  order: 'asc',
  setOrder: () => {},
  allItemsFormatted: [],
  setAllItemsFormatted: () => {},
  handleSortingByCode: () => {},
  handleSortingByStatus: () => {},
  handleSortingByCotractStartDate: () => {},
  handleSortingByOwnerName: () => {},
  handleSortingByPhone: () => {},
  handleSortingByAddress: () => {},
  handleSortingByCommission: () => {},
  handleSortingByHostName: () => {},
  handleSortingByCleaningFee: () => {},
  handleSortingByBedroomQuantity: () => {},
  handleSortingByCategory: () => {},
  locations: [] as RequestLocation[],
  setLocations: () => {},
  hostNameFilter: '',
  setHostNameFilter: () => {},
  handleSortingByImmobileId: () => {},
});

export const HostPropertiesProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [codeFilter, setCodeFilter] = useState<string>('');
  const [bedroomsFilter, setBedroomsFilter] = useState<number>(0);
  const [locationFilter, setLocationFilter] = useState<string>('');
  const [openActions, setOpenActions] = useState<boolean>(false);
  const [actionSelected, setActionSelected] = useState<Actions>('none');
  const [loading, setLoading] = useState<boolean>(false);
  const [bedsGridExpanded, setBedsGridExpanded] = useState<boolean>(false);
  const [bathroomsGridExpanded, setBathroomsGridExpanded] = useState<boolean>(false);
  const [hostNameFilter, setHostNameFilter] = useState<string>('');
  const handleChangeAction = useCallback((action: Actions) => {
    setActionSelected(action);
  }, []);
  const [order, setOrder] = useState<OrderBy>('asc');
  const [allItemsFormatted, setAllItemsFormatted] = useState<HostProperties[]>([]);
  const [locations, setLocations] = useState<RequestLocation[]>([] as RequestLocation[]);

  const handleChangeOpenActions = useCallback((open: boolean) => {
    setOpenActions(open);
  }, []);

  const handleChangeHostNameFilter = useCallback((newHostNameFilter: string) => {
    setHostNameFilter(newHostNameFilter);
  }, []);

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleChangeBedsGridExpanded = useCallback((newBedsGridExpanded: boolean) => {
    setBedsGridExpanded(newBedsGridExpanded);
  }, []);

  const handleChangeBathroomsGridExpanded = useCallback((newBathroomsGridExpanded: boolean) => {
    setBathroomsGridExpanded(newBathroomsGridExpanded);
  }, []);

  const handleChangeStatusFilter = useCallback((newStatusFilter: string) => {
    setStatusFilter(newStatusFilter);
  }, []);

  const handleChangeCodeFilter = useCallback((newCodeFilter: string) => {
    setCodeFilter(newCodeFilter);
  }, []);

  const handleChangeBedroomsFilter = useCallback((newBedroomsFilter: number) => {
    setBedroomsFilter(newBedroomsFilter);
  }, []);

  const handleChangeLocationFilter = useCallback((newLocationFilter: string) => {
    setLocationFilter(newLocationFilter);
  }, []);

  const handleSetOrder = useCallback((newOrder: OrderBy) => {
    setOrder(newOrder);
  }, []);

  const handleAllItemsFormatted = useCallback((newallItemsFormatted: HostProperties[]) => {
    setAllItemsFormatted(newallItemsFormatted);
  }, []);

  const handleSortingByImmobileId = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.id, b.id, order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.id, b.id, order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByCode = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.code, b.code, order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.code, b.code, order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByCategory = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.category_location, b.category_location, order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.category_location, b.category_location, order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByStatus = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.status, b.status, order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.status, b.status, order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByCotractStartDate = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => {
          const newA = a?.contract_start_date?.split('/')?.reverse()?.join('-');
          const newB = b?.contract_start_date?.split('/')?.reverse()?.join('-');
          return +new Date(newA) - +new Date(newB);
        }));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => {
          const newA = a?.contract_start_date?.split('/')?.reverse()?.join('-');
          const newB = b?.contract_start_date?.split('/')?.reverse()?.join('-');
          return +new Date(newA) - +new Date(newB);
        }).reverse());
      handleSetOrder('asc');
    }
  };

  const handleSortingByHostName = () => {
    if (order === 'asc') {
      const result = allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.host.user.first_name, b.host.user.first_name, order,
        ));
      setAllItemsFormatted(result);
      handleSetOrder('desc');
      return result;
    }

    const result = allItemsFormatted
      .map((item) => item).sort((a, b) => compareList(
        a.host.user.first_name, b.host.user.first_name, order,
      ));
    setAllItemsFormatted(result);
    handleSetOrder('asc');
    return result;
  };

  const handleSortingByOwnerName = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => {
          if (a.owners[0].user.trading_name === '' && b.owners[0].user.trading_name === '') {
            return compareList(
              `${a.owners[0].user.first_name} ${a.owners[0].user.last_name}`,
              `${b.owners[0].user.first_name} ${b.owners[0].user.last_name}`,
              order,
            );
          } if (a.owners[0].user.trading_name !== '' && b.owners[0].user.trading_name === '') {
            return compareList(
              a.owners[0].user.trading_name,
              `${b.owners[0].user.first_name} ${b.owners[0].user.last_name}`,
              order,
            );
          } if (a.owners[0].user.trading_name === '' && b.owners[0].user.trading_name !== '') {
            return compareList(
              `${a.owners[0].user.first_name} ${a.owners[0].user.last_name}`,
              b.owners[0].user.trading_name,
              order,
            );
          }
          return compareList(
            a.owners[0].user.trading_name,
            b.owners[0].user.trading_name,
            order,
          );
        }));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => {
          if (a.owners[0].user.trading_name === '' && b.owners[0].user.trading_name === '') {
            return compareList(
              `${a.owners[0].user.first_name} ${a.owners[0].user.last_name}`,
              `${b.owners[0].user.first_name} ${b.owners[0].user.last_name}`,
              order,
            );
          } if (a.owners[0].user.trading_name !== '' && b.owners[0].user.trading_name === '') {
            return compareList(
              a.owners[0].user.trading_name,
              `${b.owners[0].user.first_name} ${b.owners[0].user.last_name}`,
              order,
            );
          } if (a.owners[0].user.trading_name === '' && b.owners[0].user.trading_name !== '') {
            return compareList(
              `${a.owners[0].user.first_name} ${a.owners[0].user.last_name}`,
              b.owners[0].user.trading_name,
              order,
            );
          }
          return compareList(
            a.owners[0].user.trading_name,
            b.owners[0].user.trading_name,
            order,
          );
        }));
      handleSetOrder('asc');
    }
  };

  const handleSortingByPhone = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.owners[0].user.phone_number1, b.owners[0].user.phone_number1, order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.owners[0].user.phone_number1, b.owners[0].user.phone_number1, order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByAddress = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          `${a.address.street} - ${a.address.neighborhood}, ${a.address.city} - ${a.address.state}, ${a.address.postal_code}`,
          `${b.address.street} - ${b.address.neighborhood}, ${b.address.city} - ${b.address.state}, ${b.address.postal_code}`,
          order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          `${a.address.street} - ${a.address.neighborhood}, ${a.address.city} - ${a.address.state}, ${a.address.postal_code}`,
          `${b.address.street} - ${b.address.neighborhood}, ${b.address.city} - ${b.address.state}, ${b.address.postal_code}`,
          order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByCommission = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.comission_fee,
          b.comission_fee,
          order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.comission_fee,
          b.comission_fee,
          order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByCleaningFee = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.cleaning_fee,
          b.cleaning_fee,
          order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.cleaning_fee,
          b.cleaning_fee,
          order,
        )));
      handleSetOrder('asc');
    }
  };

  const handleSortingByBedroomQuantity = () => {
    if (order === 'asc') {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.bedroom_quantity,
          b.bedroom_quantity,
          order,
        )));
      handleSetOrder('desc');
    } else {
      setAllItemsFormatted(allItemsFormatted
        .map((item) => item).sort((a, b) => compareList(
          a.bedroom_quantity,
          b.bedroom_quantity,
          order,
        )));
      handleSetOrder('asc');
    }
  };

  React.useEffect(() => {
    const handleGetLocations = async () => {
      const response = await getLocations();
      setLocations(response);
    };

    handleGetLocations();
  }, []);

  const value = useMemo(() => ({
    openActions,
    setOpenActions: handleChangeOpenActions,
    actionSelected,
    setActionSelected: handleChangeAction,
    loading,
    setLoading: handleLoading,
    bedsGridExpanded,
    setBedsGridExpanded: handleChangeBedsGridExpanded,
    bathroomsGridExpanded,
    setBathroomsGridExpanded: handleChangeBathroomsGridExpanded,
    statusFilter,
    setStatusFilter: handleChangeStatusFilter,
    codeFilter,
    setCodeFilter: handleChangeCodeFilter,
    bedroomsFilter,
    setBedroomsFilter: handleChangeBedroomsFilter,
    locationFilter,
    setLocationFilter: handleChangeLocationFilter,
    order,
    setOrder: handleSetOrder,
    handleSortingByCode,
    handleSortingByStatus,
    handleSortingByCotractStartDate,
    handleSortingByOwnerName,
    handleSortingByHostName,
    handleSortingByPhone,
    handleSortingByAddress,
    handleSortingByCommission,
    handleSortingByCleaningFee,
    handleSortingByBedroomQuantity,
    handleSortingByCategory,
    handleSortingByImmobileId,
    allItemsFormatted,
    setAllItemsFormatted: handleAllItemsFormatted,
    locations,
    setLocations,
    hostNameFilter,
    setHostNameFilter: handleChangeHostNameFilter,
  }),
  [
    openActions,
    actionSelected,
    loading,
    setOpenActions,
    setActionSelected,
    setLoading,
    bedsGridExpanded,
    setBedsGridExpanded,
    bathroomsGridExpanded,
    setBathroomsGridExpanded,
    statusFilter,
    setStatusFilter,
    codeFilter,
    setCodeFilter,
    bedroomsFilter,
    setBedroomsFilter,
    locationFilter,
    setLocationFilter,
    order,
    setOrder,
    handleSortingByCode,
    handleSortingByStatus,
    handleSortingByCotractStartDate,
    handleSortingByOwnerName,
    handleSortingByPhone,
    handleSortingByAddress,
    handleSortingByCommission,
    handleSortingByHostName,
    handleSortingByCleaningFee,
    handleSortingByBedroomQuantity,
    handleSortingByCategory,
    allItemsFormatted,
    handleAllItemsFormatted,
    locations,
    setLocations,
    hostNameFilter,
    setHostNameFilter,
    handleSortingByImmobileId,
  ]);

  return (
    <HostPropertiesContext.Provider value={value}>
      {children}
    </HostPropertiesContext.Provider>
  );
};
