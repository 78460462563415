import styled from 'styled-components';
import { Mode } from '../../context/ViewMode/type';

export const Container = styled.div<{
  mode: Mode | null;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: ${({ mode }) => (mode?.is === 'view' ? '100%' : '100vh')};
  background: ${({ theme }) => theme.palette.white._210.hex()};
`;
