/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSendingVoucher } from '../../../context/SendingVoucherContext';
import { getReservationVoucher } from '../../../services/SendingVouchers/request';
import { RequestReservationVoucher } from '../../../services/SendingVouchers/types';

import Modal from '../Modal';
import Body from './Body/Body';
import Header from './Header/Header';
import {
  Container,
  BodyContainer,
} from './styles';

interface GridProps {
  index?: number;
  color: 'normal' | 'green';
}
const Grid = ({ color, index }: GridProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [listReservationVouchers,
    setListReservationVoucher] = useState<Array<RequestReservationVoucher>>([]);
  const {
    setIdReservation,
    setIdReservationVoucher,
    setSendingVoucher,
    nameGuest,
    reload,
    reloadConfirm,
    sendingVoucher,
    setReload,
  } = useSendingVoucher();

  const handleOpenModal = (
    idReservation: number,
    idReservationVoucher: number,
    sentVoucher: boolean,
  ) => {
    setIdReservation(idReservation);
    setIdReservationVoucher(idReservationVoucher);
    setSendingVoucher(!sentVoucher);
    setOpenModal(true);
  };
  async function getListReservationVouchers() {
    const results = await getReservationVoucher();
    setListReservationVoucher(results);
  }

  const handleCloseModal = (manualClick: boolean = false) => {
    if (!manualClick) {
      setSendingVoucher(!sendingVoucher);
      setReload(Math.random());
    }
    setOpenModal(false);
  };

  useEffect(() => {
    getListReservationVouchers();
  }, [reload, reloadConfirm]);

  return (
    <Container>
      {openModal && <Modal guestName={nameGuest} onClose={handleCloseModal} />}
      <Header />
      <BodyContainer data-cy="list-vouchers" isTwoCardOpen={false}>
        {
          listReservationVouchers.map((item, i) => (
            <Body
              key={item.id}
              color={color}
              index={i + 1}
              checkIn={item.check_in_date}
              immobileCode={item.property_code}
              whatsApp={item.guest.phone}
              reservation={item.id}
              handleOpenModal={() => handleOpenModal(
                item.id, item.reservation_voucher.id, item.reservation_voucher.voucher_sent,
              )}
              reservationCode={item.code}
              sentVoucher={item.reservation_voucher.voucher_sent}
              reservationStaff={item.guest.fullname}
              comment={item.comment}
              reservationOta={item.ota}
            />
          ))
        }
      </BodyContainer>
    </Container>
  );
};
Grid.defaultProps = {
  index: 1,
};
export default Grid;
