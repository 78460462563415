import styled, { keyframes } from 'styled-components';
import { ReportProblemOutlined } from '@mui/icons-material';

const mobile = '1000px';

const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
    }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #00000050;
  backdrop-filter: blur(1px);
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  z-index: 999;
  @media (max-width: ${mobile}) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 2rem;
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const Container = styled.div<{
  openDateSelector: boolean;
}>`
  position: absolute;
  display: flex;
  transition: 0.3s;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: ${({ openDateSelector }) => (openDateSelector ? '280px' : '320px')};
  z-index: 3;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 15px;
  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  user-select: none;

  .property {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }


  @media (max-width: ${mobile}) {
    width: 100%;
    height: 100%;
    max-height: 400px;
    max-width: 400px;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.palette.grey._300.hex()};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 1.25rem;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 1.25rem;
  cursor: pointer;
  img {
    width: 20px;
    opacity: 0.5;
    filter: invert(1);
    transition: 0.5s;
    &:hover {
      opacity: 1;
      transform: rotate(90deg);
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 25px;
  }
  h1 {
    font-size: 1rem;
    text-align: justify;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    margin: 0 3rem 0 1rem;
  }
  @media (max-width: ${mobile}) {
    h1 {
      font-size: 0.9rem;
      margin: 0 4rem 0 2rem;
      text-align: center;
    }
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  form {
    margin: 0 3rem 0 2rem;
  }
  @media (max-width: ${mobile}) {
    margin-top: 5rem;
  }
`;

export const ContainerMonthView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  img {
    width: 28px;
  }
  .title{
    margin-left: 1rem;
  }
  @media (max-width: 600px) {
    align-items: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const ContainerTextView = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 3.5rem;
  button {
    margin: 1.5rem 0.5rem;
  }
`;

export const InputModal = styled.div`
  position: absolute;
  border-radius: 10px;
  border: none;
  width: 500px;
  background-color:#FFFFFF;
  margin-top: 5px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 25px;
  padding-top: 15px;
  z-index: 9999;

  strong {
    :first-child {
      margin-left: 0!important;
    }
  }

  &.input-modal-style {
    height: auto;
  }

  .messageError {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .css-1qz17ui-MuiPaper-root {
    z-index: 10010 !important;
  }

  .group-price {
    width: 100%;
    display: flex;
    flex-direction: row;

    span {
    margin-top: 35px;
    margin-left: 5px;
    margin-right: 6px;
    }

    .min {
      width: 127.5px;
    }

    .max {
      width: 127.5px;
    }
  }

  .buttonApply {
    margin-right: 0;
    margin-left: auto;
    margin-top: 5px;
  }

  @media (max-width: 1080px) {
    padding-left: 10px;
    padding-right: 10px;
    width: 450px;
    margin-left: -50px;
  }
  @media (max-width: 580px) {
    position: fixed;
    top: 50;
    right:0;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    margin-left: 0px;
  }
  @media (max-width: 580px) {
    width: 100%;
    display: flex

  }
`;

export const ButtonClear = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  width: 150px;
  cursor: pointer;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right:15px;
  padding-top: 10px;

  .style-date {
    width: 150px !important;
  }
`;

export const ErrorIcon = styled(ReportProblemOutlined)`
  && {
    margin-right: 3px;
    height: 16px;
  }
`;

export const InputHideModal = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const Line = styled.div`
  width: 90%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.grey._420.hex()};
  margin-bottom: 10px;
  margin-top: 18px;
`;
