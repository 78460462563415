import {
  Container,
  UploadWrapper,
} from './style';

import Upload from './Upload';

import { IFormik } from '../../types';
import { UseFormik } from '../../../../../../../../../utils/Formik/types';
import { IGuestFront } from '../../../../../../../../../services/PreCheckin/types';
import { usePreCheckin } from '../../../../../../../../../context/PreCheckin/PreCheckin';

interface IDocumentUpload {
  guest: IGuestFront;
  formik: UseFormik<IFormik>;
}

const DocumentUpload = ({ formik, guest }: IDocumentUpload) => {
  const { setGuests } = usePreCheckin();

  const handleSetGuest = (key: string) => {
    if (guest.is_principal) {
      setGuests((prev) => ({
        ...prev,
        main: {
          ...prev.main,
          isPut: {
            ...prev.main.isPut,
            is: prev.main?.isPut?.is,
            updated: {
              ...prev.main?.isPut?.updated,
              documentPhoto: {
                ...prev.main?.isPut?.updated?.documentPhoto,
                [key]: true,
              },
            },
          },
        },
      }));
    } else {
      setGuests((prev) => ({
        ...prev,
        main: {
          ...prev.main,
        },
        list: prev.list.map((item) => {
          if (item.id === guest.id) {
            return {
              ...item,
              isPut: {
                ...item.isPut,
                is: item?.isPut?.is,
                updated: {
                  ...item?.isPut?.updated,
                  documentPhoto: {
                    ...item?.isPut?.updated?.documentPhoto,
                    [key]: true,
                  },
                },
              },
            };
          }
          return item;
        }),
      }));
    }
  };

  return (
    <Container>
      <UploadWrapper>
        <Upload
          type="front_document_photo"
          text="Frente"
          formik={formik}
          callbacks={{ success: () => handleSetGuest('front') }}
        />

        <Upload
          type="back_document_photo"
          text="Verso"
          formik={formik}
          callbacks={{ success: () => handleSetGuest('back') }}
        />
      </UploadWrapper>
    </Container>
  );
};

export default DocumentUpload;
