import styled, { DefaultTheme, css, keyframes } from 'styled-components';
import { Button } from '@mui/material';
import { ButtonProps, Color, Variant } from './types';

const FadeOut = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const styles = (
  color: Color, variant: Variant, styledTheme: DefaultTheme, link: boolean, invisible: boolean,
): string => {
  let customCss;
  const { palette } = styledTheme;

  if (color === 'new-blue') {
    customCss = css`
      background: ${palette?.blue._400.hex()};
      color: ${palette?.white.main.hex()};

      border-radius: 8px;

      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 175% */
      letter-spacing: -0.003px;
    `;
  }

  if (color === 'new-blue-outline') {
    customCss = css`
      border: 1px solid #042675;
      background-color: transparent;
      color: #042675;

      border-radius: 8px;

      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 175% */
      letter-spacing: -0.003px;
    `;
  }

  if (color === 'new-black-outline') {
    customCss = css`
      border: 1px solid #394760;
      background-color: transparent;
      color: #394760;

      border-radius: 8px;

      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.003px;
    `;
  }

  if (color === 'blue') {
    customCss = `
      background: ${variant === 'outlined' ? palette?.white.main.hex() : `linear-gradient(180deg,${palette?.blue._400.hex()} 0%, ${palette?.blue._400.hex()} 100%)`};
    border: 1px solid ${palette?.blue._400.hex()};
      &, .MuiButton-label {
        color: ${variant === 'outlined' ? palette?.blue._400.hex() : palette?.white.main.hex()};
      }
      &:hover {
        border: 1px solid ${palette?.blue._400.hex()};
      }
      &:disabled {
        border: 1px solid ${palette?.grey._430.hex()};
        background: ${palette?.grey._425.hex()};
        opacity: 0.6;
      }
    `;
  }

  if (color === 'blue-dark') {
    customCss = `
      background: ${variant === 'outlined' ? palette?.white.main.hex() : `linear-gradient(180deg,${palette?.blue._910.hex()} 0%, ${palette?.blue._910.hex()} 100%)`};
    border: 1px solid ${palette?.blue._910.hex()};
      &, .MuiButton-label {
        color: ${variant === 'outlined' ? palette?.blue._910.hex() : palette?.white.main.hex()};
      }
      &:hover {
        border: 1px solid ${palette?.blue._910.hex()};
      }
      &:disabled {
        border: 1px solid ${palette?.grey._430.hex()};
        background: ${palette?.grey._425.hex()};
        opacity: 0.6;
      }
    `;
  }

  if (color === 'red') {
    customCss = `
      background: ${variant === 'outlined' ? palette?.white.main.hex() : `linear-gradient(180deg,${palette?.orange._400.hex()} 0%, ${palette?.orange._400.hex()} 100%)`};
    border: 1px solid ${palette?.orange._400.hex()};
      &, .MuiButton-label {
        color: ${variant === 'outlined' ? palette?.orange._400.hex() : palette?.white.main.hex()};
      }
      &:hover {
        border: 1px solid ${palette?.orange._400.hex()};
      }
      &:disabled {
        border: 1px solid ${palette?.grey._430.hex()};
        background: ${palette?.grey._425.hex()};
        opacity: 0.6;
      }
    `;
  }

  if (color === 'red-dark') {
    customCss = `
      background: ${variant === 'outlined' ? palette?.white.main.hex() : `linear-gradient(180deg,${palette?.orange._450.hex()} 0%, ${palette?.orange._450.hex()} 100%)`};
    border: 1px solid ${palette?.orange._450.hex()};
      &, .MuiButton-label {
        color: ${variant === 'outlined' ? palette?.orange._450.hex() : palette?.white.main.hex()};
      }
      &:hover {
        border: 1px solid ${palette?.orange._450.hex()};
      }
      &:disabled {
        border: 1px solid ${palette?.grey._430.hex()};
        background: ${palette?.grey._425.hex()};
        opacity: 0.6;
      }
    `;
  }

  if (color === 'grey') {
    customCss = `
      &, &:hover {
        background: ${palette?.white.main.hex()};
        border: 1px solid ${palette?.grey._400.hex()};
        &, .MuiButton-label {
          color: ${variant === 'outlined' ? palette?.grey._600.hex() : palette?.grey._720.hex()};
        }
        &:hover {
          background: ${palette?.grey._200.hex()};
        }
        &:disabled {
          opacity: 0.6;
          border: 1px solid ${palette?.grey._400.hex()};
          background: ${palette?.grey._300.hex()};
          color: ${variant === 'outlined' ? palette?.grey._900.hex() : palette?.grey._900.hex()};
        }
      }
    `;
  }

  if (color === 'grey-dark') {
    customCss = `
      &, &:hover {
        background: ${palette?.grey._280.hex()};
        border: 1px solid ${palette?.grey._280.hex()};
        &, .MuiButton-label {
          color: ${variant === 'outlined' ? palette?.grey._600.hex() : palette?.grey._720.hex()};
        }
        &:hover {
          background: ${palette?.grey._200.hex()};
        }
        &:disabled {
          opacity: 0.6;
          border: 1px solid ${palette?.grey._280.hex()};
          background: ${palette?.grey._280.hex()};
          color: ${variant === 'outlined' ? palette?.grey._900.hex() : palette?.grey._900.hex()};
        }
      }
    `;
  }

  if (color === 'blue-light') {
    customCss = `
      &, &:hover {
        background: ${palette?.blue._90.hex()};
        border: 1px solid ${palette?.grey._280.hex()};
        &, .MuiButton-label {
          color: ${variant === 'outlined' ? palette?.white.main.hex() : palette?.white.main.hex()};
        }
        &:hover {
          background: ${palette?.grey._200.hex()};
        }
        &:disabled {
          opacity: 0.6;
          border: 1px solid ${palette?.grey._280.hex()};
          background: ${palette?.blue._90.hex()};
          color: ${variant === 'outlined' ? palette?.white.main.hex() : palette?.white.main.hex()};
        }
      }
    `;
  }

  if (color === 'black') {
    customCss = `
    &, &:hover {
        background: rgb(21,27,38);
        background: linear-gradient(360deg, rgba(21,27,38,1) 0%, rgba(89,93,102,1) 100%);
        &, .MuiButton-label {
          color: ${variant === 'outlined' ? palette.white.main.hex() : palette.white.main.hex()};
        }
        &:hover {
          background: linear-gradient(360deg, rgba(21,27,38,1) 0%, rgba(89,93,102,1) 80%);
        }
        &:disabled {
          border: 1px solid ${palette.grey._400.hex()};
          background: ${palette.grey._300.hex()};
          color: ${variant === 'outlined' ? palette.grey._900.hex() : palette.grey._900.hex()};
        }
      }
    `;
  }

  if (link) {
    customCss = `
      background: unset;
      color: ${palette.blue._850.hex()};
      padding: 0;
      margin: 0;
      display: inline;
      &:hover {
        background: unset;
        color: ${palette.blue._900.hex()};
        text-decoration: underline;
      }
      & > .MuiTouchRipple-root {
        display: none;
      }
    `;
  }

  if (invisible) {
    customCss = `
    display: none;
    `;
  }

  return customCss as any;
};

export const OutlinedButton = styled(Button)<ButtonProps>`
  animation-name: ${FadeOut};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;

  && {
    border-radius: 10px;
    height: ${({ newStyle }) => !newStyle && '40px'};
    padding: ${({ newStyle }) => newStyle && '8px 16px'};
    display: flex;
    flex: 1;

    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.02em;
    height: 40px;

    width: ${({ isFull }) => isFull && '100%'};
    min-width: ${({ isFull }) => isFull && '100px'};
    text-transform: none;
    white-space: nowrap;

    ${({
    theme, customcolorbg, variant, linkto, invisible,
  }) => styles(
    customcolorbg, variant, theme, !!linkto, invisible || false,
  )}
  }
`;
