import styled from 'styled-components';

export const Container = styled.div``;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 20px 15px;

  color: #000f2d;
`;
export const CardContent = styled.div`
  gap: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardHeader = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const CardTitle = styled.h1`
  font-weight: normal;
  font-size: 1.3rem;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardName = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;
export const CardSubName = styled.h2`
  font-size: 1.3rem;
  font-weight: 500;
  opacity: 0.5;
`;
export const CardFooter = styled.div`
  outline: none;
  display: flex;
  align-items: end;
  justify-content: flex-end;

  a {
    text-decoration: none;
    color: #0d4bd0;
  }
`;

export const TextButton = styled.div``;
