import styled, { css } from 'styled-components';

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  width: 100%;
  height: 100%;
`;

export const ButtonCanceled = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.orange.main.hex()};
  background: linear-gradient(
    360deg,
    ${({ theme }) => theme.palette.orange.main.hex()} 0%,
    ${({ theme }) => theme.palette.orange._600.hex()} 100%
  );
  border: none;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 600;
  cursor: pointer;
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 2rem;
  line-height: 1.8rem;

  p, strong, span, h2 , textarea {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  }

  && strong {
    font-weight: bold;
  }

  && p, span {
    color: ${({ theme }) => theme.palette.grey._700.hex()};
    font-size: 1.3rem;
  }

  .notes {
    word-wrap: break-word;
    p{
      font-size: 1.1rem;
      color: ${({ theme }) => theme.palette.grey._900.hex()};
      margin-bottom: -1.7rem;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const HeaderContainer = styled.div<{ isBlocked?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, isBlocked }) => (!isBlocked ? theme.palette.grey._395.hex() : theme.palette.red._200.hex())};
  padding: 16px 31px;
  border-bottom: 1px solid #9696969b;

  * {
    -webkit-tap-highlight-color: transparent;
  }

  #close-btn {
    cursor: pointer;
    transition: 0.3s ease all;
    
    & {
      path {
        transition: 0.4s ease all;
      }
    }

    &:active, &:hover {
      transform: scale(0.9) rotate(180deg);

      & {
        path {
          stroke: ${({ theme }) => theme.palette.red._500.hex()};
        }
      }
    }


    ${({ isBlocked }) => isBlocked && css`
        path {
          stroke: ${({ theme }) => theme.palette.white.main.hex()};
        }

        &:active, &:hover {
          transform: scale(0.9) rotate(180deg);

          & {
            path {
              stroke: ${({ theme }) => theme.palette.black.main.hex()};
            }
          }
      }
    `}
  } 
`;

export const Title = styled.h2<{
  blocked?: boolean;
}>`
  font-family: Inter;
  color: var(--Darkk-Blue-900, #001840);
  font-size: 1.57143rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.28571rem;
`;

export const CloseButtonContainer = styled.div`
  max-width: 7rem;
  height: auto;

  background: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.palette.grey._550.hex()};
  border-radius: 0.7rem;
  cursor: pointer;

  padding: 0.8rem;

  svg {
    margin-right: 0.2rem;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  @media(min-width: 320px)  and (max-width: 500px) {
    margin-bottom: 5rem;

    strong {
      display: none;
    }
  }
`;

export const HorizontalRow = styled.div`
  margin: 0.5rem 0;
  border-top: 2px solid ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const DatesSelected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(min-width: 320px)  and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DateGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .date {
    strong {
      font-size: 0.8rem;
      padding-right: 0.3rem;
    }
    span {
      font-size: 1.2rem;
    }
  }

  @media(min-width: 320px)  and (max-width: 500px) {
    margin: 0;
    padding: 0.5rem 0;

    .date {
      span {
        font-size: 1rem;
      }
      strong {
        font-size: 1rem;
        font-weight: 700;
        margin-right: 0.5rem;
        color: ${({ theme }) => theme.palette.orange.main.hex()};
      }
    }

    .arrow-icon {
        transform: rotate(90deg);
    }
  }
`;

export const Indicators = styled.div`
  && {

    grid-area: indicators;
    color: ${({ theme }) => theme.palette.grey._600.hex()};
    font-weight: 500;
    font-size: 12px;
    display: flex;

    .adultIcon, .childIcon {
      display: flex;
      align-items: center;
      margin-right: 6px;
    }

    svg {
      vertical-align: bottom;
      height: 15px;
      width: 15px;
    }

    .childIcon {
      svg {
        height: 11px;
        width: 11px;
        margin-bottom: -2px;
      }
    }

    span {
      font-family: 'Quicksand', sans-serif;
      font-size: 12px;
      margin-left: 2px;
    }

    .petsIcon {
      height: 13px;
      width: 13px;
      margin-right: 6px;
    }
  }
`;

export const SmallTextNote = styled.div`
  border: none;
  padding-right: 2rem;
  width: 100%;
  resize: none;
  text-align: justify;
  background: transparent;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  &::-webkit-scrollbar {
    border-radius: 20px;
    height: 10px;
    width: 7px;
    border: 1px solid ${({ theme }) => theme.palette.white._600.hex()};
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.white._600.hex()};
    width: 5px;
    border-radius: 100px;
    transform: scale(0.3);
  }

  &::-webkit-scrollbar-thumb:hover{
    background: ${({ theme }) => theme.palette.orange._600.hex()};
  }

`;
