import { useScreenResize } from '../../../../../hooks/useSceenResize/useScreenResize';
import { MAX_SIZE_MOBILE } from '../../utils';

import { Container, VariantOptions } from './styles';

interface IDownloadButton {
  type: 'button' | 'submit',
  label?: string,
  onClick?: Function,
  variantForMobile?: VariantOptions,
  variantForDesktop?: VariantOptions,
}

interface IIconDownload {
  variantForMobile: VariantOptions,
  variantForDesktop: VariantOptions,
}

const IconDonwload = ({ variantForMobile, variantForDesktop }: IIconDownload) => {
  const { isMobile } = useScreenResize(MAX_SIZE_MOBILE);

  if (isMobile && variantForMobile === 'primary') {
    return (
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 11.5V17.5C18 17.6989 17.921 17.8897 17.7803 18.0303C17.6397 18.171 17.4489 18.25 17.25 18.25H0.75C0.551088 18.25 0.360322 18.171 0.21967 18.0303C0.0790178 17.8897 0 17.6989 0 17.5V11.5C0 11.3011 0.0790178 11.1103 0.21967 10.9697C0.360322 10.829 0.551088 10.75 0.75 10.75C0.948912 10.75 1.13968 10.829 1.28033 10.9697C1.42098 11.1103 1.5 11.3011 1.5 11.5V16.75H16.5V11.5C16.5 11.3011 16.579 11.1103 16.7197 10.9697C16.8603 10.829 17.0511 10.75 17.25 10.75C17.4489 10.75 17.6397 10.829 17.7803 10.9697C17.921 11.1103 18 11.3011 18 11.5ZM8.46937 12.0306C8.53903 12.1004 8.62175 12.1557 8.7128 12.1934C8.80384 12.2312 8.90144 12.2506 9 12.2506C9.09856 12.2506 9.19616 12.2312 9.2872 12.1934C9.37825 12.1557 9.46097 12.1004 9.53063 12.0306L13.2806 8.28063C13.3503 8.21094 13.4056 8.12822 13.4433 8.03717C13.481 7.94613 13.5004 7.84855 13.5004 7.75C13.5004 7.65145 13.481 7.55387 13.4433 7.46283C13.4056 7.37178 13.3503 7.28906 13.2806 7.21937C13.2109 7.14969 13.1282 7.09442 13.0372 7.0567C12.9461 7.01899 12.8485 6.99958 12.75 6.99958C12.6515 6.99958 12.5539 7.01899 12.4628 7.0567C12.3718 7.09442 12.2891 7.14969 12.2194 7.21937L9.75 9.68969V1C9.75 0.801088 9.67098 0.610322 9.53033 0.46967C9.38968 0.329018 9.19891 0.25 9 0.25C8.80109 0.25 8.61032 0.329018 8.46967 0.46967C8.32902 0.610322 8.25 0.801088 8.25 1V9.68969L5.78063 7.21937C5.63989 7.07864 5.44902 6.99958 5.25 6.99958C5.05098 6.99958 4.86011 7.07864 4.71937 7.21937C4.57864 7.36011 4.49958 7.55098 4.49958 7.75C4.49958 7.94902 4.57864 8.13989 4.71937 8.28063L8.46937 12.0306Z" fill="#F9F9F9" />
      </svg>
    );
  }

  if (isMobile && variantForMobile === 'secondary') {
    return (
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 11.5V17.5C18 17.6989 17.921 17.8897 17.7803 18.0303C17.6397 18.171 17.4489 18.25 17.25 18.25H0.75C0.551088 18.25 0.360322 18.171 0.21967 18.0303C0.0790178 17.8897 0 17.6989 0 17.5V11.5C0 11.3011 0.0790178 11.1103 0.21967 10.9697C0.360322 10.829 0.551088 10.75 0.75 10.75C0.948912 10.75 1.13968 10.829 1.28033 10.9697C1.42098 11.1103 1.5 11.3011 1.5 11.5V16.75H16.5V11.5C16.5 11.3011 16.579 11.1103 16.7197 10.9697C16.8603 10.829 17.0511 10.75 17.25 10.75C17.4489 10.75 17.6397 10.829 17.7803 10.9697C17.921 11.1103 18 11.3011 18 11.5ZM8.46937 12.0306C8.53903 12.1004 8.62175 12.1557 8.7128 12.1934C8.80384 12.2312 8.90144 12.2506 9 12.2506C9.09856 12.2506 9.19616 12.2312 9.2872 12.1934C9.37825 12.1557 9.46097 12.1004 9.53063 12.0306L13.2806 8.28063C13.3503 8.21094 13.4056 8.12822 13.4433 8.03717C13.481 7.94613 13.5004 7.84855 13.5004 7.75C13.5004 7.65145 13.481 7.55387 13.4433 7.46283C13.4056 7.37178 13.3503 7.28906 13.2806 7.21937C13.2109 7.14969 13.1282 7.09442 13.0372 7.0567C12.9461 7.01899 12.8485 6.99958 12.75 6.99958C12.6515 6.99958 12.5539 7.01899 12.4628 7.0567C12.3718 7.09442 12.2891 7.14969 12.2194 7.21937L9.75 9.68969V1C9.75 0.801088 9.67098 0.610322 9.53033 0.46967C9.38968 0.329018 9.19891 0.25 9 0.25C8.80109 0.25 8.61032 0.329018 8.46967 0.46967C8.32902 0.610322 8.25 0.801088 8.25 1V9.68969L5.78063 7.21937C5.63989 7.07864 5.44902 6.99958 5.25 6.99958C5.05098 6.99958 4.86011 7.07864 4.71937 7.21937C4.57864 7.36011 4.49958 7.55098 4.49958 7.75C4.49958 7.94902 4.57864 8.13989 4.71937 8.28063L8.46937 12.0306Z" fill="#0D4BD0" />
      </svg>
    );
  }

  if (!isMobile && variantForDesktop === 'primary') {
    return (
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 11.5V17.5C18 17.6989 17.921 17.8897 17.7803 18.0303C17.6397 18.171 17.4489 18.25 17.25 18.25H0.75C0.551088 18.25 0.360322 18.171 0.21967 18.0303C0.0790178 17.8897 0 17.6989 0 17.5V11.5C0 11.3011 0.0790178 11.1103 0.21967 10.9697C0.360322 10.829 0.551088 10.75 0.75 10.75C0.948912 10.75 1.13968 10.829 1.28033 10.9697C1.42098 11.1103 1.5 11.3011 1.5 11.5V16.75H16.5V11.5C16.5 11.3011 16.579 11.1103 16.7197 10.9697C16.8603 10.829 17.0511 10.75 17.25 10.75C17.4489 10.75 17.6397 10.829 17.7803 10.9697C17.921 11.1103 18 11.3011 18 11.5ZM8.46937 12.0306C8.53903 12.1004 8.62175 12.1557 8.7128 12.1934C8.80384 12.2312 8.90144 12.2506 9 12.2506C9.09856 12.2506 9.19616 12.2312 9.2872 12.1934C9.37825 12.1557 9.46097 12.1004 9.53063 12.0306L13.2806 8.28063C13.3503 8.21094 13.4056 8.12822 13.4433 8.03717C13.481 7.94613 13.5004 7.84855 13.5004 7.75C13.5004 7.65145 13.481 7.55387 13.4433 7.46283C13.4056 7.37178 13.3503 7.28906 13.2806 7.21937C13.2109 7.14969 13.1282 7.09442 13.0372 7.0567C12.9461 7.01899 12.8485 6.99958 12.75 6.99958C12.6515 6.99958 12.5539 7.01899 12.4628 7.0567C12.3718 7.09442 12.2891 7.14969 12.2194 7.21937L9.75 9.68969V1C9.75 0.801088 9.67098 0.610322 9.53033 0.46967C9.38968 0.329018 9.19891 0.25 9 0.25C8.80109 0.25 8.61032 0.329018 8.46967 0.46967C8.32902 0.610322 8.25 0.801088 8.25 1V9.68969L5.78063 7.21937C5.63989 7.07864 5.44902 6.99958 5.25 6.99958C5.05098 6.99958 4.86011 7.07864 4.71937 7.21937C4.57864 7.36011 4.49958 7.55098 4.49958 7.75C4.49958 7.94902 4.57864 8.13989 4.71937 8.28063L8.46937 12.0306Z" fill="#F9F9F9" />
      </svg>

    );
  }

  if (!isMobile && variantForDesktop === 'secondary') {
    return (
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 11.5V17.5C18 17.6989 17.921 17.8897 17.7803 18.0303C17.6397 18.171 17.4489 18.25 17.25 18.25H0.75C0.551088 18.25 0.360322 18.171 0.21967 18.0303C0.0790178 17.8897 0 17.6989 0 17.5V11.5C0 11.3011 0.0790178 11.1103 0.21967 10.9697C0.360322 10.829 0.551088 10.75 0.75 10.75C0.948912 10.75 1.13968 10.829 1.28033 10.9697C1.42098 11.1103 1.5 11.3011 1.5 11.5V16.75H16.5V11.5C16.5 11.3011 16.579 11.1103 16.7197 10.9697C16.8603 10.829 17.0511 10.75 17.25 10.75C17.4489 10.75 17.6397 10.829 17.7803 10.9697C17.921 11.1103 18 11.3011 18 11.5ZM8.46937 12.0306C8.53903 12.1004 8.62175 12.1557 8.7128 12.1934C8.80384 12.2312 8.90144 12.2506 9 12.2506C9.09856 12.2506 9.19616 12.2312 9.2872 12.1934C9.37825 12.1557 9.46097 12.1004 9.53063 12.0306L13.2806 8.28063C13.3503 8.21094 13.4056 8.12822 13.4433 8.03717C13.481 7.94613 13.5004 7.84855 13.5004 7.75C13.5004 7.65145 13.481 7.55387 13.4433 7.46283C13.4056 7.37178 13.3503 7.28906 13.2806 7.21937C13.2109 7.14969 13.1282 7.09442 13.0372 7.0567C12.9461 7.01899 12.8485 6.99958 12.75 6.99958C12.6515 6.99958 12.5539 7.01899 12.4628 7.0567C12.3718 7.09442 12.2891 7.14969 12.2194 7.21937L9.75 9.68969V1C9.75 0.801088 9.67098 0.610322 9.53033 0.46967C9.38968 0.329018 9.19891 0.25 9 0.25C8.80109 0.25 8.61032 0.329018 8.46967 0.46967C8.32902 0.610322 8.25 0.801088 8.25 1V9.68969L5.78063 7.21937C5.63989 7.07864 5.44902 6.99958 5.25 6.99958C5.05098 6.99958 4.86011 7.07864 4.71937 7.21937C4.57864 7.36011 4.49958 7.55098 4.49958 7.75C4.49958 7.94902 4.57864 8.13989 4.71937 8.28063L8.46937 12.0306Z" fill="#0D4BD0" />
      </svg>
    );
  }

  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 11.5V17.5C18 17.6989 17.921 17.8897 17.7803 18.0303C17.6397 18.171 17.4489 18.25 17.25 18.25H0.75C0.551088 18.25 0.360322 18.171 0.21967 18.0303C0.0790178 17.8897 0 17.6989 0 17.5V11.5C0 11.3011 0.0790178 11.1103 0.21967 10.9697C0.360322 10.829 0.551088 10.75 0.75 10.75C0.948912 10.75 1.13968 10.829 1.28033 10.9697C1.42098 11.1103 1.5 11.3011 1.5 11.5V16.75H16.5V11.5C16.5 11.3011 16.579 11.1103 16.7197 10.9697C16.8603 10.829 17.0511 10.75 17.25 10.75C17.4489 10.75 17.6397 10.829 17.7803 10.9697C17.921 11.1103 18 11.3011 18 11.5ZM8.46937 12.0306C8.53903 12.1004 8.62175 12.1557 8.7128 12.1934C8.80384 12.2312 8.90144 12.2506 9 12.2506C9.09856 12.2506 9.19616 12.2312 9.2872 12.1934C9.37825 12.1557 9.46097 12.1004 9.53063 12.0306L13.2806 8.28063C13.3503 8.21094 13.4056 8.12822 13.4433 8.03717C13.481 7.94613 13.5004 7.84855 13.5004 7.75C13.5004 7.65145 13.481 7.55387 13.4433 7.46283C13.4056 7.37178 13.3503 7.28906 13.2806 7.21937C13.2109 7.14969 13.1282 7.09442 13.0372 7.0567C12.9461 7.01899 12.8485 6.99958 12.75 6.99958C12.6515 6.99958 12.5539 7.01899 12.4628 7.0567C12.3718 7.09442 12.2891 7.14969 12.2194 7.21937L9.75 9.68969V1C9.75 0.801088 9.67098 0.610322 9.53033 0.46967C9.38968 0.329018 9.19891 0.25 9 0.25C8.80109 0.25 8.61032 0.329018 8.46967 0.46967C8.32902 0.610322 8.25 0.801088 8.25 1V9.68969L5.78063 7.21937C5.63989 7.07864 5.44902 6.99958 5.25 6.99958C5.05098 6.99958 4.86011 7.07864 4.71937 7.21937C4.57864 7.36011 4.49958 7.55098 4.49958 7.75C4.49958 7.94902 4.57864 8.13989 4.71937 8.28063L8.46937 12.0306Z" fill="#F9F9F9" />
    </svg>
  );
};

const DownloadButton = ({
  type = 'button',
  label = undefined,
  onClick = () => {},
  variantForMobile = 'secondary',
  variantForDesktop = 'primary',
}: IDownloadButton) => (
  <Container
    type={type}
    onClick={onClick ? () => onClick() : () => {}}
    variantForMobile={variantForMobile}
    variantForDesktop={variantForDesktop}
  >
    <IconDonwload variantForMobile={variantForMobile} variantForDesktop={variantForDesktop} />
    {label && <p>{label}</p>}
  </Container>
);

DownloadButton.defaultProps = {
  label: undefined,
  onClick: () => {},
  variantForMobile: 'secondary',
  variantForDesktop: 'primary',
};

export default DownloadButton;
