import React, {
  useState, useEffect, createContext, memo, useCallback,
} from 'react';

import {
  Container,
  Content,
  ContainerInformation,
  Information,
} from './styles';

import Header from '../Header';
import ManualFitOwner from '../ManualFit/ManualFitOwner';
import GridOwner from '../Grid/GridOwner';
import GridSkeleton from '../LoadingShimmer/Grid/GridSkeleton';
import {
  getFinancialCloseOwner, patchRevenuesOwner, postConsolidationTask,
} from '../../../services/FinancialClose/request';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../utils/Messages';
import { useFinancialClose } from '../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseOwner } from '../../../hooks/FinancialCloseHook/useFinancialCloseOwner';
import { useAbortRequest } from '../../../hooks/AbortRequestHook/useAbortRequest';

import { FinancialCloseParams, RevenuesOwnerProps, Status } from '../../../services/FinancialClose/types';
import { formatDate } from '../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { GridOwnerProps } from '../../../context/FinancialClosePage/FinancialCloseOwnerContext/types';

export interface OwnerCloseContextProps {
  addFinancialCloseOwner: Function;
  refetchSelectedOwners: Function;
  validationRevenuesOwner: Function;
}

export const OwnerCloseContext = createContext<OwnerCloseContextProps>({
  addFinancialCloseOwner: (params: FinancialCloseParams) => params,
  refetchSelectedOwners: (params: FinancialCloseParams) => params,
  validationRevenuesOwner: (revenues: RevenuesOwnerProps) => revenues,
});

const OwnerClose: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();
  const { dateSelected, search } = useFinancialClose();

  const {
    financialDataOwner,
    handleSetFinancialDataOwner,
    handleUpdateFinancialDataOwner,
  } = useFinancialCloseOwner();

  const { abortController, setAbortController } = useAbortRequest();

  const getFinancialClose = useCallback(async (params: FinancialCloseParams) => {
    try {
      setLoading(true);
      const response: GridOwnerProps[] | any = await getFinancialCloseOwner(params,
        abortController);
      handleSetFinancialDataOwner([...response]);
      setLoading(false);
    } catch (e: unknown) {
      if (e instanceof Error && !abortController?.signal?.aborted) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
      setLoading(false);
    }
  }, [financialDataOwner]);

  useEffect(() => {
    setAbortController(() => new AbortController());
    return () => {
      abortController.abort();
    };
  }, [getFinancialClose]);

  const refetchSelectedOwners = useCallback(async (params: FinancialCloseParams,
    consolidateTask: boolean = false) => {
    try {
      if (consolidateTask) {
        await postConsolidationTask({
          task: 'owner',
          date: params.start_date,
          owners: params.owners?.map((owner) => Number(owner)),
        });
      }

      const response: GridOwnerProps[] | any = await getFinancialCloseOwner(params);
      handleUpdateFinancialDataOwner(financialDataOwner, response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoading(false);
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  }, [financialDataOwner]);

  const validationRevenuesOwner = useCallback(async (
    revenues: RevenuesOwnerProps,
    params: FinancialCloseParams,
    status: Status,
  ) => {
    try {
      await patchRevenuesOwner(revenues);
      const response: GridOwnerProps[] | any = await getFinancialCloseOwner(params);
      handleUpdateFinancialDataOwner(financialDataOwner, response);
      toast.success(`Dados ${status === 'Closed' ? 'validados' : 'desvalidados'} com sucesso!`);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  }, [financialDataOwner]);

  useEffect(() => {
    let params = formatDate(dateSelected);
    if (search !== '') {
      params = {
        ...params,
        search,
      };
    }
    getFinancialClose(params);
  }, [dateSelected]);

  useEffect(() => {
    if (!loading && financialDataOwner.length === 0) {
      toast.alert('Não há registros para o mês selecionado');
    }
  }, [loading]);

  return (
    <OwnerCloseContext.Provider
      value={{
        addFinancialCloseOwner: getFinancialClose,
        refetchSelectedOwners,
        validationRevenuesOwner,
      }}
    >
      <Container>
        <Content>
          <Header />
          {loading ? (
            <GridSkeleton type="owner" />
          ) : (
            <>
              <GridOwner />
              <ManualFitOwner />
            </>
          )}
          {!loading && financialDataOwner.length === 0 && (
          <ContainerInformation>
            <Information>
              Fechamento financeiro não realizado
            </Information>
          </ContainerInformation>
          )}
        </Content>
      </Container>
    </OwnerCloseContext.Provider>
  );
};

export default memo(OwnerClose);
