import React, { useEffect, useState } from 'react';
import { UseFormik } from '../../../utils/Formik/types';

import { Type } from '../../HelperText/types';

import { Number } from '../../../utils/InputMask';

import HelperText from '../../HelperText';
import FormButton from '../../FormButton';

import {
  ErrorIcon,
  StyledFormControl,
  ContainerLabel,
  Content,
  StyledTextField,
  StarSymbol,
  StyledTextArea,
  ActiveButton,
} from './styles';
import Tooltip from '../../Tooltip';
import { InfoIcon } from '../../TimeLine/InformationReservation/styles';

type Mask = 'money' | 'cpf' | 'cnpj' | 'cep' | 'phone' | 'hour';

interface OnboardingTextFieldProps {
  label?: string;
  id: string;
  dataCy?: string,
  value?: any;
  autoComplete?: 'on' | 'off';
  pattern?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  minRows?: number | string;
  startAdornment?: React.ReactNode,
  endAdornment?: React.ReactNode,
  formik?: UseFormik<any>,
  mask?: Mask,
  className?: string,
  disabled?: boolean,
  activeButton?: boolean,
  generateCodeButton?: boolean,
  isLargeText?: boolean,
  tooltip?: string,
  onClick?: () => void,
  onChange?:
  ((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formatedValue: string) => void) | undefined;
}

const OnboardingTextField: React.FC<OnboardingTextFieldProps> = ({
  label,
  value,
  id,
  dataCy = '',
  autoComplete,
  error,
  helperText,
  placeholder,
  multiline,
  rows,
  required,
  type = 'text',
  minRows,
  startAdornment,
  endAdornment,
  formik,
  onClick,
  onChange,
  mask,
  className,
  disabled,
  activeButton = false,
  generateCodeButton = false,
  isLargeText = false,
  tooltip,
}) => {
  const masks: Record<Mask, (value: string) => string> = {
    money: Number.currency,
    cpf: Number.cpf,
    cnpj: Number.cnpj,
    cep: Number.cep,
    phone: Number.phone,
    hour: Number.hour,
  };

  const [stateValue, setStateValue] = useState<any>();
  const [modifyValue, setModifyValue] = useState<string>(value);

  const formatValue = (str: string) => (mask ? masks[mask](str) : str);

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(id, formatValue((formik?.values[id] || '').toString()));
    }
  }, []);

  useEffect(() => {
    setModifyValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const customValue: string = formatValue(e.currentTarget.value);
    setModifyValue(customValue);
    if (onChange) {
      onChange(e, customValue);
      return;
    }

    if (formik) {
      formik.setFieldValue(id, customValue);
      formik.setFieldTouched(
        id, true, false,
      );
      return;
    }

    setStateValue(customValue);
  };

  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const getValue = () => {
    if (onChange || value) {
      return value;
    }

    return formik ? formik?.values[id] : stateValue;
  };

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  return (
    <>
      <StyledFormControl isLargeText={isLargeText} className="styledTextFieldContainer">
        <ContainerLabel>
          <label className="styledTextFieldLabel" htmlFor={label}>{label}</label>
          {required && <StarSymbol>*</StarSymbol>}
          {tooltip && (
            <>
              <div style={{ marginLeft: '4px' }} />
              <Tooltip text={tooltip} whiteSpace="pre-line">
                <InfoIcon />
              </Tooltip>
            </>
          )}
        </ContainerLabel>
        {type !== 'textarea'
          ? (
            <Content generateButton={generateCodeButton}>
              {generateCodeButton && (
              <FormButton
                dataCy="generate-button"
                type="button"
              >
                Gerar Código
              </FormButton>
              )}
              <StyledTextField
                activeButton={activeButton}
                className={className}
                autoComplete={autoComplete}
                name={id}
                id={id}
                data-cy={dataCy}
                variant="outlined"
                type={type}
                error={error}
                placeholder={placeholder}
                multiline={multiline}
                rows={rows}
                required={required}
                value={modifyValue}
                onClick={onClick}
                onChange={handleChange}
                disabled={disabled}
                fullWidth
                InputProps={{
                  startAdornment,
                  endAdornment,
                }}
              />
              {activeButton && (
              <ActiveButton type="submit">Salvar</ActiveButton>
              )}
            </Content>
          )
          : (
            <StyledTextArea
              name={id}
              id={id}
              data-cy={dataCy}
              placeholder={placeholder}
              minRows={minRows}
              required={required}
              value={getValue()}
              onChange={handleChange}
              disabled={disabled}
            />
          )}
        { !!helperTextValue() && (
          <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
        )}
      </StyledFormControl>
    </>
  );
};

export default OnboardingTextField;
