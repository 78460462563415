import React from 'react';

import { Value } from '../styles';

import { Container, Content } from './styles';

interface ExpansiveBathroomsItemProps {
  open: boolean;
  value:{
    bathroom_quantity: number;
    lavatory_quantity: number
    total: number;
  };
}

const ExpansiveBathroomsItem = ({ open, value }: ExpansiveBathroomsItemProps) => (
  <Container>
    <Content isOpen={open}>
      <Value>{value.total}</Value>
      {open && (
      <>
        <Value>{value.bathroom_quantity}</Value>
        <Value>{value.lavatory_quantity}</Value>
      </>
      )}
    </Content>
  </Container>
);
export default ExpansiveBathroomsItem;
