import React, { Fragment } from 'react';
import { PhoneInputProps } from 'react-phone-input-2';
import { ContainerLabel, CountrySelectorStyled } from './style';
import { ErrorIcon } from '../TextField/styles';
import HelperText from '../HelperText';
import { Type } from '../HelperText/types';

interface ICountrySelector extends PhoneInputProps {
  label?: string;
  overWrite?: string;
  id?: any;
  formik?: any;
}

const CountrySelector = ({ id, formik, label, overWrite, ...rest }: ICountrySelector) => {
  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }
    return '';
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;
  return (
    <>
      {label && (
        <ContainerLabel>
          <p>{label}</p>
        </ContainerLabel>
      )}

      {overWrite && (
        <div className={overWrite}>
          <CountrySelectorStyled {...rest} />
        </div>
      )}

      {!overWrite && <CountrySelectorStyled {...rest} />}

      {!!helperTextValue() && <HelperText type={helperTextType}>{helperTextValue()}</HelperText>}
    </>
  );
};

CountrySelector.defaultProps = {
  label: undefined,
  overWrite: undefined,
  id: undefined,
  formik: undefined,
};

export default CountrySelector;
