/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import Tooltip from '../../../Tooltip';
import copyIcon from '../../../../assets/icons/generals/copy.svg';

import { LateralModal, SwitcherButton } from '../../..';

import {
  Container,
  ImmobileCode,
  Item,
  ItemContainer,
  ReservationCode,
  ReservationDate,
  ReservationStaff,
  Title,
  WhatsApp,
  ReservationOta,
} from './styles';
import { useSendingVoucher } from '../../../../context/SendingVoucherContext';
import ReservationDataModal from '../../LateralModal/ReservationDataModal';

import RingBell from '../../../../assets/icons/multicalendar/ring-bell.svg';
import Guest from '../../../../assets/icons/multicalendar/guest.svg';
import Finance from '../../../../assets/icons/multicalendar/finance.svg';
import { useModal } from '../../../../hooks/ModalHook/useModal';
import FinanceForm from '../../../Calendar/Reservation/ModalFinance/FinanceReservationModal';
import GuestForm from '../../LateralModal/GuestModal';

interface BodyProps {
  index?: number;
  reservation: number;
  checkIn?: string;
  whatsApp: string;
  immobileCode?: string;
  reservationCode?: string;
  sentVoucher: boolean;
  comment: string;
  color: 'normal' | 'green';
  reservationStaff: string;
  reservationOta: string;
  handleOpenModal: () => void;
}

const Body = ({
  color,
  index,
  reservation,
  checkIn,
  comment,
  whatsApp,
  immobileCode,
  sentVoucher,
  reservationCode,
  reservationStaff,
  handleOpenModal,
  reservationOta,
}: BodyProps) => {
  const [selectedSwitcher, setSelectedSwitcher] = React.useState(sentVoucher);
  const { modalActive } = useModal();
  const [openReservatioDataModal, setOpenReservationDataModal] = useState(false);
  const {
    idReservation, reload, setNameGuest, reloadConfirm,
  } = useSendingVoucher();

  const handleCopyWhatsAppNumber = () => {
    navigator.clipboard.writeText(whatsApp);
  };

  const handleOpenModalReservationData = () => {
    setOpenReservationDataModal(true);
  };

  const handleChangeSwitcher = () => {
    setSelectedSwitcher(!selectedSwitcher);
    handleOpenModal();
  };

  useEffect(() => {
    if (idReservation === reservation) {
      setSelectedSwitcher(sentVoucher);
      setNameGuest(reservationStaff);
    }
  }, [reload, sentVoucher]);

  useEffect(() => {
    if (idReservation === reservation) {
      setSelectedSwitcher(!sentVoucher);
      setNameGuest(reservationStaff);
    }
  }, [reloadConfirm]);

  const options = [
    { icon: RingBell, alt: 'Ring Bell', title: 'Dados da reserva' },
    { icon: Guest, alt: 'Guest', title: 'Hóspede' },
    { icon: Finance, alt: 'Finance', title: 'Financeiro' },
  ];

  return (
    <>
      <Container>
        <ItemContainer color={color} index={index || 2}>
          <Item>
            <ReservationCode>
              <Tooltip text="Ver reserva">
                <Title className="code" data-cy="code-reservation" onClick={handleOpenModalReservationData}>{reservationCode}</Title>
              </Tooltip>
            </ReservationCode>
            <ImmobileCode>
              <Title>{immobileCode}</Title>
            </ImmobileCode>

            <ReservationDate>
              <Title>{checkIn}</Title>
            </ReservationDate>

            <ReservationStaff>
              <Title>{reservationStaff}</Title>
            </ReservationStaff>

            <WhatsApp onClick={handleCopyWhatsAppNumber}>
              <Title>{whatsApp}</Title>

              <Tooltip text="Copiar Número">
                <img src={copyIcon} alt="icone de cópia" />
              </Tooltip>
            </WhatsApp>

            <ReservationOta>
              <Title>{reservationOta}</Title>
            </ReservationOta>

            <div>
              <SwitcherButton
                idTestCypress={`btn-toggle-${reservation}`}
                onChange={handleChangeSwitcher}
                selected={selectedSwitcher}
              />
            </div>
          </Item>
        </ItemContainer>
      </Container>
      <LateralModal
        open={openReservatioDataModal}
        setOpen={setOpenReservationDataModal}
        activeTabs
        options={options}
        headerTitle="Dados da reserva"
      >
        <>
          {modalActive === 0 && (
          <ReservationDataModal
            comment={comment}
            idReservation={reservation}
          />
          )}
          {modalActive === 1 && (
            <GuestForm idReservation={reservation} />
          )}
          {modalActive === 2 && (
            <FinanceForm />
          )}
        </>
      </LateralModal>
    </>
  );
};

Body.defaultProps = {
  index: 2,
  checkIn: '12/12/2021',
  immobileCode: 'INGSUI3Q',
  reservationCode: '00425583483983',
};

export default Body;
