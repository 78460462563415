import { Container } from './styles';

interface ISpinner {
  loading: boolean;
}

const Spinner = ({ loading = false }: ISpinner) => (
  <Container loading={loading}>
    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75ZM37.5 8.92563C21.7188 8.92563 8.92563 21.7188 8.92563 37.5C8.92563 53.2812 21.7188 66.0744 37.5 66.0744C53.2812 66.0744 66.0744 53.2812 66.0744 37.5C66.0744 21.7188 53.2812 8.92563 37.5 8.92563Z" fill="url(#paint0_angular_200_2085)" />
      <defs>
        <radialGradient id="paint0_angular_200_2085" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(37.5 37.5) rotate(180) scale(37.5)">
          <stop stopColor="#3996DA" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  </Container>
);

export default Spinner;
