import styled from 'styled-components';
import { MAX_SIZE_MOBILE } from '../utils';

export const Container = styled.div`
  .MuiOutlinedInput-root  {
    width: 100% !important;
    height: 40px !important;
  }
  .MuiFormControl-root {
    width: 100% !important;
    fieldset {
      border-color: ${({ theme }) => theme.palette.grey._425.hex()} !important;
    }
  }
  .MuiInputBase-input {
    padding-left: 0.8rem !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    line-height: 22px !important;
    font-family: Inter !important;
  }

  @media (max-width: 640px) {
    margin-bottom: 0px;
    .MuiOutlinedInput-root  {
      width: calc(100vw - 125px) !important;
    }
  }

  @media (min-width: 641px) and (max-width: ${MAX_SIZE_MOBILE}px) {
    .MuiOutlinedInput-root  {
      width: calc(100vw - 185px) !important;
    }
  }
`;

export const Label = styled.p`
 font-family: Inter;
 font-size: 14px;
 font-weight: 400;
 padding-bottom: 4px;
 color: ${({ theme }) => theme.palette.blue._250.hex()};
`;
