import styled from 'styled-components';

export const ColoredBar = styled.div`
  background-color: ${({ theme }) => theme.palette.blue._950.hex()};
  display: flex;
  height: 95px;
  width: 100%;
`;
export const LogoImage = styled.img`
  max-width: 200px;
  margin: auto;
`;
export const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white._250.hex()};
`;
