import styled from 'styled-components';
import { animationTime, shimmerAnimation } from '../animationShimmer';

export const Container = styled.div`
  position: fixed;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  line-height: 0;
  left: 20;
  z-index: 1000000;
  width: 185px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  `;

export const Content = styled.div`
  width: 80%;
  height: 35px;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  border-radius: 8px;
  animation: ${animationTime}s ${shimmerAnimation} ease-in-out infinite;
`;
