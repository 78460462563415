import { FC, ReactNode } from 'react';

import { Container } from './styles';

const DEFAULT_ZINDEX = 10000000;

interface IBackdrop {
  onClose: Function,
  children: ReactNode,
  zIndex?: number,
}

const Backdrop: FC<IBackdrop> = ({ onClose, children, zIndex = DEFAULT_ZINDEX }) => (
  <Container onClick={() => onClose()} zIndex={zIndex}>
    {children}
  </Container>
);

Backdrop.defaultProps = {
  zIndex: DEFAULT_ZINDEX,
};

export default Backdrop;
