import { MouseEvent } from 'react';

import { Close } from '@mui/icons-material';
import { useToast } from '../../../../context/ToastContext';
import { DeleteOwnerBank } from '../../../../services/Owner/request';
import { useToastErrorMessage } from '../../../../utils/Messages';
import { BankAccountStateProps } from '../../AccountBankManager';

import {
  ButtonCancel,
  ButtonClose,
  ButtonSave,
  Container,
  HeaderModalNewBankAccount,
  RowButtons,
  Wrapper,
} from './styles';

interface Props {
  bankAccountState: BankAccountStateProps;
  setBankAccountState: (item: BankAccountStateProps) => void;
  updateListBank: () => void;
  sliderRef: any,
}

const ModalConfirmDeleteBankAccount = ({
  bankAccountState,
  setBankAccountState,
  updateListBank,
  sliderRef = null,
}: Props) => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const handleCloseModal = () => {
    setBankAccountState({
      state: 'viewing',
      bank: null,
    });
  };

  async function handleDeleteAccount(id: number | null) {
    if (!id) {
      return;
    }

    try {
      await DeleteOwnerBank(id);
      updateListBank();
      if (sliderRef?.current) {
        sliderRef?.current?.slickGoTo(0);
      }
      toast.success('Conta bancária excluída com sucesso!');
    } catch (e: unknown) {
      if (e instanceof Error) {
        toastErrorRequest(e);
      }
    }
    handleCloseModal();
  }

  function handleWrapperClick(e: MouseEvent<HTMLElement>) {
    const classList = (e.target as Element).classList.value.split(' ');
    if (classList.includes('area-close')) {
      handleCloseModal();
    }
  }
  return (
    <Wrapper
      className="area-close"
      onClick={(e) => handleWrapperClick(e)}
      openModal={bankAccountState.state === 'deleting'}
    >
      <Container>
        <ButtonClose onClick={handleCloseModal}>
          <Close />
          Fechar
        </ButtonClose>
        <HeaderModalNewBankAccount>
          <h1>Excluir conta bancária?</h1>
        </HeaderModalNewBankAccount>
        <RowButtons>
          <ButtonCancel onClick={handleCloseModal} type="button">
            Cancelar
          </ButtonCancel>
          <ButtonSave type="button" onClick={() => handleDeleteAccount(bankAccountState?.bank?.id || null)}>
            Excluir
          </ButtonSave>
        </RowButtons>
      </Container>
    </Wrapper>
  );
};

export default ModalConfirmDeleteBankAccount;
