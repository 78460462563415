import styled, { css } from 'styled-components';

export const ContainerBox = styled.form``;

export const HeaderBox = styled.div`
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  padding: 2rem;
  line-height: 3rem;
  max-width: 1045px;
  width: 100vw;

  @media(min-width: 320px)  and (max-width: 1200px) {
    line-height: 2rem;
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(min-width: 320px)  and (max-width: 500px) {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
  }

  @media(min-width: 500px)  and (max-width: 980px) {
    h2 {
      font-size: 1.4rem;
    }
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  `;

export const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
`;

export const CloseButtonContainer = styled.div`
  width: 8rem;
  height: 2.8rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.palette.grey._550.hex()};
  border-radius: 0.7rem;
  cursor: pointer;

  margin-bottom: 3rem;
  padding: 0.1rem 0.3rem;

  svg {
    margin-right: 0.2rem;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  @media(min-width: 320px)  and (max-width: 500px) {
    margin-bottom: 5rem;
    width: 3rem;

    strong {
      display: none;
    }
  }
`;

export const HorizontalRow = styled.div`
  margin: 0.5rem 0;
  border-top: 2px solid ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const DateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-width: 320px)  and (max-width: 500px) {
    flex-direction: column;

    .MuiDateRangePickerInput-root {
      flex-direction: column !important;
      strong {
        margin-left: 0 !important;
        margin-top: 15px !important;
      }
    }
  }
`;

export const DateGrid = styled.div<{ buttonIsBlocked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;

  margin: 0.7rem 0 0 3rem;

  .MuiButton-root > .MuiButton-label {
    font-size: 1rem !important;
  }

  ${({ buttonIsBlocked }) => buttonIsBlocked && css`
    button {
      cursor: not-allowed !important;
    }
  `}

  @media(min-width: 320px) and (max-width: 500px) {
    width: 100%;
    margin: 2.5rem 0 0 0;
  }
`;
