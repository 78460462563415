import React, { useEffect, useState } from 'react';
import PersonalDataOwner from '../../components/PersonalDataOwner';
import WhatsappFloatButton from '../../components/WhatsappFloatButton';
import { CustomToastProvider } from '../../context/CustomToastContext';

const PersonalDataOwnerPage: React.FC = () => {
  const [mobile, setMobile] = useState<boolean>(false);

  function useWindowSize() {
    const { innerWidth: width } = window;
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', useWindowSize);
  }, [mobile]);

  useEffect(() => {
    useWindowSize();
  }, []);

  return (
    <CustomToastProvider>
      <WhatsappFloatButton phoneNumber="5548991020350" />
      <PersonalDataOwner />
    </CustomToastProvider>
  );
};

export default PersonalDataOwnerPage;
