import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 4px;


  h1 {
    opacity: 0.9
  }
`;
