import styled, { css } from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../../hooks/useMobile/useMobile';

export const Container = styled.div`
  z-index: 22;
  margin-top: 20px;
  position: relative;
  align-items: center;
  width: 100%;
`;

export const GridContent = styled.div<{
  desktopCustomColumns?: string;
}>`
  gap: 5rem;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;

    ${({ desktopCustomColumns }) => desktopCustomColumns
      && css`
        grid-template-columns: ${desktopCustomColumns};
      `}
  }
`;

export const Content = styled.div`
  padding: 50px 30px;
  border-radius: 9px;
  background: var(--branco, #fff);
`;

export const Backdrop = styled.div`
  position: absolute;
  border-radius: 9px;

  left: 5%;
  width: 90%;
  height: 50px;
  top: -10px;
  background: var(--rosa, #fc605b);
  z-index: -1;
`;
