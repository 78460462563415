import React from 'react';

import Modal from './Modal/Modal';
import Header from './Header/Header';

import { Container } from './styles';

import Grid from './Grid/Grid';
import { CardDataTypeProps, ColorProps } from './types';
import { PaymentItemsProps } from '../../../context/PaymentVoucher/types/paymentStatus.types';
import { usePaymentVoucher } from '../../../hooks/PaymentVoucherHook/usePaymentVoucher';

interface CardsProps {
  title: string;
  color?: ColorProps;
  isOpenCard: boolean;
  setIsOpenCard: Function;
  data: PaymentItemsProps[];
  cardDataType?: CardDataTypeProps;
}

const Cards = ({
  data,
  title,
  isOpenCard,
  cardDataType,
  setIsOpenCard,
  color = 'normal',
}: CardsProps) => {
  const { modalOpen, checkedCounter } = usePaymentVoucher();

  const handleChangeVisible = () => {
    setIsOpenCard(!isOpenCard);
  };

  return (
    <>
      {modalOpen && (<Modal />)}

      <Container>
        <Header
          title={title}
          isOpen={isOpenCard}
          changeVisible={() => handleChangeVisible()}
        />

        {isOpenCard && (
          <Grid
            data={data}
            color={color}
            cardDataType={cardDataType}
            isSelectedItemsOpen={checkedCounter > 0}
          />
        )}
      </Container>
    </>
  );
};

Cards.defaultProps = {
  color: 'normal',
  cardDataType: 'default',
};

export default Cards;
