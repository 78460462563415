import moment from 'moment';
import request from '../request';
import {
  PaymentArbnbConciliation,
  ReservationConciliation,
  FiltersType,
  ConciliationReservationPayment,
  Conciliation,
} from './types';

export const getReservationConciliation = async (params?: Array<FiltersType>):
Promise<ReservationConciliation[]> => {
  const startDate = moment().format('YYYY-MM-DD');
  const endDate = moment().add(5, 'days').format('YYYY-MM-DD');

  let response;
  let urlParamsEncode = '?';
  if (params && params?.length > 0) {
    params.forEach((item) => {
      urlParamsEncode += `${item.name}=${item.value}&`;
    });
  }
  if (params) {
    response = await request.get(`/conciliation/reservation/${urlParamsEncode}`);
  } else {
    response = await request.get(`/conciliation/reservation/?start_date=${startDate}&end_date=${endDate}`);
  }

  const { data } = response;
  return data as ReservationConciliation[];
};

export const getPaymentAirbnbConciliation = async (params?: Array<FiltersType>):
Promise<PaymentArbnbConciliation[]> => {
  let response;
  let urlParamsEncode = '?';
  if (params && params?.length > 0) {
    params.forEach((item) => {
      urlParamsEncode += `${item.name}=${item.value}&`;
    });
  }

  if (params) {
    response = await request.get(`/conciliation/airbnb_payment/${urlParamsEncode}`);
  } else {
    response = await request.get('/conciliation/airbnb_payment/?start_date=2022-05-10&end_date=2022-05-11');
  }

  const { data } = response;
  return data as PaymentArbnbConciliation[];
};

export const postConciliation = async (data: ConciliationReservationPayment) => (
  request.post('/conciliation/airbnb/', data)
);

export const getConciliations = async (params?: Array<FiltersType>): Promise<Conciliation[]> => {
  let response;
  let urlParamsEncode = '?';
  if (params && params?.length > 0) {
    params.forEach((item) => {
      urlParamsEncode += `${item.name}=${item.value}&`;
    });
  }

  if (params) {
    response = await request.get(`/conciliation/airbnb/${urlParamsEncode}`);
  } else {
    response = await request.get('/conciliation/airbnb/');
  }

  const { data } = response;
  return data as Conciliation[];
};

export const postUploadCSVPaymments = async (file: File) => {
  const form = new FormData();
  form.append('file', file);
  return request.post('/conciliation/airbnb_payment/', form);
};

export const getConciliationById = async (id: number): Promise<Conciliation> => {
  const { data } = await request.get(`/conciliation/airbnb/${id}`);
  return data as Conciliation;
};
