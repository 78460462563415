import { Add } from '@mui/icons-material';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FormButton from '../FormButton/FormButton';
import { SearchCard } from './ViewCards/SearchCard';
import {
  BackInitialButton,
  ButtonContainer,
  CardContainer,
  CardsContainer,
  Container,
  Subtitle,
  Title,
} from './styles';
import StepsCard from './StepsCard';
import { useUser } from '../../context/UserContext';
import { ReconcileDamageReservationCard } from './ViewCards/ViewReconcileDamageReservationCard';
import { ViewDamageDetailsCard } from './ViewCards/ViewDamageDetailsCard';
import { ViewBillingHistoryNegotiationsCard } from './ViewCards/ViewBillingHistoryNegotiationsCard';
import { ViewRefundDamageCard } from './ViewCards/ViewRefundDamageCard';
import { InsertBillingHistoryNegotiationsCard } from './InsertionCards/InsertBillingHistoryNegotiationsCard';
import { InsertDamageDetailsCard } from './InsertionCards/InsertDamageDetailsCard';
import { InsertRefundDamageCard } from './InsertionCards/InsertRefundDamageCard';
import { useGuestDamageStep } from '../../hooks/GuestDamage/useGuestDamageStep';
import { PhotoModal } from './components/PhotoModal/PhotoModal';
import { useGuestDamage } from '../../hooks/GuestDamage/useGuestDamage';
import { getReservationGuestDamageNegotiation } from '../../services/GuestDamage/request';
import { useToast } from '../../context/ToastContext';
import { getReservationById } from '../../services/Reservation/request';
import { useGuestDamageNegotiation } from '../../hooks/GuestDamage/useGuestDamageNegotiation';
import { useReservations } from '../../hooks/GuestDamage/useReservations';
import { GetGuestDamage, GetGuestDamageNegotiation, ReservationDetails } from '../../services/GuestDamage/types';
import { FileProps } from '../../context/FileContext/types';

const GuestDamage = () => {
  const {
    shouldDisplayStepCard,
    guestDamageStep,
    handleReconcileDamageWithReservationStep,
    handleInsertDamageDetailsStep,
    handleViewDamageDetailsStep,
    handleInsertBillingHistoryNegotiationsStep,
    handleViewBillingHistoryNegotiationsStep,
    handleViewRefundDamageStep,
    handleDamageSearchStep,
  } = useGuestDamageStep();
  const {
    selectedPhoto,
    setSelectedGuestDamage,
    setQuotationFile,
    setEvidences,
    setGuestDamagesToInsert,
    setReservationGuestDamages,
  } = useGuestDamage();
  const { setReservations } = useReservations();
  const {
    setSelectedGuestDamageNegotiation,
    selectedGuestDamageNegotiation,
    setGuestDamageNegotiations,
  } = useGuestDamageNegotiation();
  const { setSelectedReservation } = useReservations();
  const { userInformation } = useUser();
  const { id } = useParams();
  const toast = useToast();
  const roles = userInformation?.roles;

  function handleRedirectToCorrectCard(damageNegotiation: GetGuestDamageNegotiation) {
    switch (damageNegotiation.stage) {
      case 'Detalhes do dano': {
        if (damageNegotiation.status === 'Tratativa passou para o atendimento'
          || damageNegotiation.status === 'Atendimento verificou pendências na validação de informações'
          || damageNegotiation.status === 'Anfitrião finalizou as pendências'
        ) {
          if (roles?.includes('Attendant')) {
            handleInsertBillingHistoryNegotiationsStep();
          } else {
            handleViewDamageDetailsStep();
          }
        }

        break;
      }
      case 'Histórico e Tratativa de Cobrança': {
        handleViewBillingHistoryNegotiationsStep();
        break;
      }
      case 'Reembolso do dano': {
        handleViewRefundDamageStep();
        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
    async function handleInitialRedirect(reservationId?: string) {
      if (reservationId && reservationId?.length > 1 && !selectedGuestDamageNegotiation.id) {
        try {
          const response = await getReservationGuestDamageNegotiation(Number(reservationId));
          setSelectedGuestDamageNegotiation(response);
          handleRedirectToCorrectCard(response);
        } catch (e: unknown) {
          if (e instanceof Error) {
            toast.alert('Nenhum dano foi encontrado para essa reserva. Adicione o novo dano!');
            try {
              const reservationResponse = await getReservationById(Number(reservationId));
              setSelectedReservation({
                check_in_date: reservationResponse.check_in_date,
                check_out_date: reservationResponse.check_out_date,
                code: reservationResponse.code,
                guest_name: `${reservationResponse.guest.user.first_name} ${reservationResponse.guest.user.last_name}`,
                guest_phone_number: reservationResponse.guest.user.phone,
                id: reservationResponse.id,
                ota_name: reservationResponse.listing.ota.name,
                property_code: reservationResponse.listing.property.code,
              });
              handleInsertDamageDetailsStep();
            } catch (error: unknown) {
              if (error instanceof Error) {
                toast.error('Não foi possível encontrar a reserva!');
              }
            }
          }
        }
      }
    }
    if (id !== 'pendency') {
      handleInitialRedirect(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedGuestDamageNegotiation) {
      handleRedirectToCorrectCard(selectedGuestDamageNegotiation);
    }
  }, [selectedGuestDamageNegotiation]);

  const handleInitialCard = () => {
    setSelectedGuestDamageNegotiation({} as GetGuestDamageNegotiation);
    setSelectedGuestDamage({} as GetGuestDamage);
    setSelectedReservation({} as ReservationDetails);
    handleDamageSearchStep();
    setGuestDamageNegotiations([]);
    setEvidences([]);
    setQuotationFile({} as FileProps);
    setGuestDamagesToInsert([]);
    setReservationGuestDamages([]);
    setReservations([]);
  };

  return (
    <>
      <Container shouldDisplayStepCard={shouldDisplayStepCard}>
        <CardsContainer>
          <CardContainer
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            noMobile={shouldDisplayStepCard}
          >
            <Title>Danos do hóspede</Title>
            {guestDamageStep !== 'damageSearch' && (
              <BackInitialButton
                onClick={() => handleInitialCard()}
              >
                <ArrowBackIosIcon style={{ color: '#0D4BD0', fontSize: '0.75rem' }} />
                Voltar a página inicial
              </BackInitialButton>
            )}
          </CardContainer>
          <AnimatePresence>
            {guestDamageStep === 'damageSearch' && (
            <>
              <CardContainer
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                }}
                exit={{ y: -100, opacity: 0 }}
                key="searchResultsCard"
                style={{ overflow: 'auto', height: '100%' }}
              >
                <Subtitle>Busca de danos de hóspede</Subtitle>
                <SearchCard havePendency={id === 'pendency'} />
              </CardContainer>
              {(roles?.includes('Attendant')
                || roles?.includes('Host')
                || roles?.includes('Admin')
              )
                && (
                <CardContainer
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    delay: 0.5,
                  }}
                  exit={{ y: -100, opacity: 0 }}
                  key="addDamageCard"
                >
                  <Subtitle>Inserir novo dano</Subtitle>
                  <ButtonContainer>
                    <FormButton
                      isFull
                      customColor="blue-dark"
                      variant="outlined"
                      onClick={handleReconcileDamageWithReservationStep}
                    >
                      <Add />
                      Adicionar
                    </FormButton>
                  </ButtonContainer>
                </CardContainer>
                )}
            </>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {guestDamageStep === 'reconcileDamageWithReservation' && (
            <CardContainer
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.8,
                delay: !shouldDisplayStepCard ? 1.3 : 0.5,
              }}
              exit={{ y: -100, opacity: 0 }}
              style={{ overflow: 'auto', height: '100%' }}
            >
              <Subtitle>Conciliar dano com reserva</Subtitle>
              <ReconcileDamageReservationCard />
            </CardContainer>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {guestDamageStep === 'insertDamageDetails' && (
            <CardContainer
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.8,
                delay: !shouldDisplayStepCard ? 1.3 : 0.5,
              }}
              exit={{ y: -100, opacity: 0 }}
              style={{ overflow: 'auto', height: '100%' }}
            >
              <Subtitle>Inserir detalhes do dano</Subtitle>
              <InsertDamageDetailsCard />
            </CardContainer>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {guestDamageStep === 'viewDamageDetails' && (
            <CardContainer
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.8,
                delay: !shouldDisplayStepCard ? 1.3 : 0.5,
              }}
              exit={{ y: -100, opacity: 0 }}
              style={{ overflow: 'auto', height: '100%' }}
            >
              <Subtitle>Visualizar detalhes do dano</Subtitle>
              <ViewDamageDetailsCard />
            </CardContainer>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {guestDamageStep === 'insertBillingHistoryNegotiations' && (
            <CardContainer
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.8,
                delay: !shouldDisplayStepCard ? 1.3 : 0.5,
              }}
              exit={{ y: -100, opacity: 0 }}
              style={{ width: '100%', height: '100%', overflow: 'auto' }}
            >
              <Subtitle>Inserir histórico e tratativas de cobrança</Subtitle>
              <InsertBillingHistoryNegotiationsCard />
            </CardContainer>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {guestDamageStep === 'viewBillingHistoryNegotiations' && (
            <CardContainer
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.8,
                delay: !shouldDisplayStepCard ? 1.3 : 0.5,
              }}
              exit={{ y: -100, opacity: 0 }}
              style={{ width: '100%', height: '100%', overflow: 'auto' }}
            >
              <Subtitle>Visualizar histórico e tratativas de cobrança</Subtitle>
              <ViewBillingHistoryNegotiationsCard />
            </CardContainer>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {guestDamageStep === 'insertRefundDamage' && (
            <InsertRefundDamageCard />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {guestDamageStep === 'viewRefundDamage' && (
            <ViewRefundDamageCard />
            )}
          </AnimatePresence>
        </CardsContainer>
        <AnimatePresence>
          {shouldDisplayStepCard && (
          <StepsCard />
          )}
        </AnimatePresence>
      </Container>
      {selectedPhoto.src && (
        <PhotoModal
          src={selectedPhoto.src}
          alt={selectedPhoto.alt}
        />
      )}
    </>
  );
};

export default GuestDamage;
