import React from 'react';

import { Container, TitleContainer, TitleContent } from './style';
import { PageTitle } from '../utils/style';

import Accompaniment from './Accompaniment';
import IndicateViews from './IndicateViews';

const Indications = () => (
    <Container>
      <TitleContainer>
        <TitleContent>
          <PageTitle>Minhas indicações</PageTitle>
        </TitleContent>

        <Accompaniment />
      </TitleContainer>

      <IndicateViews />
    </Container>
  );

export default Indications;
