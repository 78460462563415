import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.button)`
  width: 100%;
  display: flex;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px dashed #cbcbcb;

  h1 {
    color: #2e2e2e;
    font-size: 1.2rem;
    font-weight: medium;
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.6;
  }
`;
