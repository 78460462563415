import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  gap: 3rem;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;

  img {
    width: 100px;
    height: 100px;

    opacity: 0;
    animation: ${fadeIn} 0.5s 0.1s ease forwards;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};

    opacity: 0;
    animation: ${fadeIn} 0.5s 0.12s ease forwards;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  opacity: 0;
  animation: ${fadeIn} 0.5s 0.2s ease forwards;

  button {
    width: 170px;
    height: 40px;
    border-radius: 7px;
    border: none;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};

    cursor: pointer;
    background: #2043cf;
    color: #fff;
    transition: 0.2s;

    &:hover {
      transform: translateY(-10px);
      box-shadow: #2043cfa6 0px 12px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
  }
`;

export const IconContainerConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
`;

export const ConfirmationTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.familys._1};

  opacity: 0;
  animation: ${fadeIn} 0.5s 3.3s ease forwards;
`;
