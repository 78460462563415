/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { PropertyOwner } from '../../../services/Owner/types';
import { compareList } from '../../../utils/Sorting';
import { CardShimmer } from './CardShimmer';
import { Pagination } from './Pagination';
import { PropertyCard } from './PropertyCard';
import ModalPropertyLock from '../Modal/ModalPropertyLock';
import ModalFinancialStatement from '../Modal/ModalFinancialStatement';
import TooltipInfo from '../TooltipInfo';

import {
  PropertyCardsContainer,
  PropertyListContainer, 
  TitleContainer,
  TitleContent,
  Title,
  FilterContainer,
  Label,
} from './styles';

interface PropertyListProps {
  ownerProperties: PropertyOwner[];
  isLoading: boolean;
}

type OwnerPropertyStatus =
| 'Active'
| 'Inactive'
| 'Onboarding'
| 'Closed'
| 'Signed_Contract';

type OwnerPropertyCondition = 'Available' | 'Blocked' | 'Rented';
const PER_PAGE = 4;

type MultiSelectOptions = {
  label: string
  value: string,
}

const STATUS_ACTIVE = 'Active,Onboarding';
const STATUS_INACTIVE = 'Inactive';

const optionsStatus: MultiSelectOptions[] = [
  {
    label: 'Ativos',
    value: STATUS_ACTIVE,
  },
  {
    label: 'Inativos',
    value: STATUS_INACTIVE,
  },
];

export default function PropertyList({
  ownerProperties,
  isLoading,
}: PropertyListProps) {
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPropertiesFiltereds, setTotalPropertiesFiltereds] = useState<number>(0);
  const [paginatedProperties, setPaginateProperties] = useState<PropertyOwner[]>([]);
  const [openModalPropertyLock, setOpenModalPropertyLock] = useState<boolean>(false);
  const [openModalFinancialStatement, setOpenModalFinancialStatement] = useState<boolean>(false);
  const [selectedGroupsStatus, setSelectedGroupsStatus] = useState<MultiSelectOptions[]>([{
      label: 'Ativos',
      value: STATUS_ACTIVE,
    },
  ]);

  function filterProperties(pageIndexSelected: number, itemsPerPage: number, status: string[]) {
    const startIndex = pageIndexSelected * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const filteredProperties = ownerProperties
      .filter((item) => status.includes(item.status))
      .sort((a, b) => compareList(a.code, b.code));

    setTotalPropertiesFiltereds(filteredProperties.length);
    setPaginateProperties(filteredProperties.slice(startIndex, endIndex));
  }

  useEffect(() => {
    const values = selectedGroupsStatus.map((item) => item.value);
    const status = values.length > 0 ? values.join(',').split(',') : [];

    filterProperties(pageIndex, PER_PAGE, status);
  }, [pageIndex, ownerProperties, selectedGroupsStatus]);

  const onSelectGroupStatus = (selected: MultiSelectOptions[]) => {
    setPageIndex(0);
    setSelectedGroupsStatus(selected);
  };

  return (
    <>
      <PropertyListContainer>
        <TitleContainer>
          <TitleContent>
            <Title>Meus imóveis</Title>
            <TooltipInfo size="lg" title="Taxa de ocupação" subtitle="O valor apresentado é referente as diárias executadas." />
          </TitleContent>
          <FilterContainer>
              <Label>Status</Label>
              <div style={{ padding: '4px 0' }} />
              <MultiSelect
                options={optionsStatus}
                value={selectedGroupsStatus}
                onChange={onSelectGroupStatus}
                isLoading={optionsStatus.length <= 0}
                labelledBy="Select"
                closeOnChangedValue={false}
                overrideStrings={{
                  allItemsAreSelected: 'Todos status selecionados',
                  clearSearch: 'Limpar Pesquisa',
                  clearSelected: 'Limpar seleção',
                  noOptions: 'Sem opções',
                  search: 'Pesquisar',
                  selectAll: 'Selecionar todos',
                  selectAllFiltered: 'Selecionar todos (Filtrados)',
                  selectSomeItems: 'Selecionar...',
                  create: 'Criar',
                }}
              />
          </FilterContainer>
        </TitleContainer>
        {isLoading ? (Array.from({ length: PER_PAGE }).map((_, i) => (<CardShimmer key={`property-owner-shimmer-${i}`} />))) : (
          <>
            <PropertyCardsContainer>
              {paginatedProperties.map((ownerProperty) => (
                <PropertyCard
                  key={ownerProperty.id}
                  propertyCode={ownerProperty.code}
                  propertyId={ownerProperty.id}
                  setOpenModalFinancialStatement={setOpenModalFinancialStatement}
                  setOpenModalPropertyLock={setOpenModalPropertyLock}
                  propertyNeighborhood={ownerProperty.address?.neighborhood}
                  condition={ownerProperty.property_condition as OwnerPropertyCondition}
                  status={ownerProperty.status as OwnerPropertyStatus}
                />
              ))}
            </PropertyCardsContainer>
            <Pagination
              onPageChange={setPageIndex}
              pageIndex={pageIndex}
              perPage={PER_PAGE}
              totalCount={totalPropertiesFiltereds}
            />
          </>
        )}
      </PropertyListContainer>
      <ModalPropertyLock
        openModalPropertyLock={openModalPropertyLock}
        onClose={setOpenModalPropertyLock}
        showOnlyHeader
        dataCy="modal-2"
      />

      <ModalFinancialStatement
        open={openModalFinancialStatement}
        handleOpen={setOpenModalFinancialStatement}
      />
    </>
  );
}
