import React, {
  FC,
  ReactElement,
  useState,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';
import { Amenities } from './types';
import { initialValuesAmenities } from './utils';

type PropertyAmenitiesContextProps = {
  allAmenities: Amenities[];
  setAllAmenities: (newAllAmenities: Amenities[]) => void;
  handleUpdateAmenitie: (amenitieKey: string) => void;
};

export const PropertyAmenitiesContext = createContext<PropertyAmenitiesContextProps>({
  allAmenities: initialValuesAmenities,
  setAllAmenities: () => {},
  handleUpdateAmenitie: () => {},
});

export const PropertyAmenitiesProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [allAmenities, setAllAmenities] = useState<Amenities[]>(initialValuesAmenities);

  const handleAllAmenities = (newAllAmenities: Amenities[]) => {
    setAllAmenities(newAllAmenities);
  };

  const handleUpdateAmenitie = (amenitieKey: string) => {
    setAllAmenities((prevState) => prevState.map((amenitie) => ({
      ...amenitie,
      value: amenitie.key === amenitieKey ? !amenitie.value : amenitie.value,
    })));
  };

  const value = useMemo(() => ({
    allAmenities,
    setAllAmenities: handleAllAmenities,
    handleUpdateAmenitie,
  }), [
    allAmenities,
    setAllAmenities,
    handleUpdateAmenitie,
  ]);

  return (
    <PropertyAmenitiesContext.Provider
      value={value}
    >
      {children}
    </PropertyAmenitiesContext.Provider>
  );
};
