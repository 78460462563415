import styled from 'styled-components';

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  max-width: 48rem;
`;

export const ContentBox = styled.div<{ variant: 'red' | 'blue' }>`
  width: 100%;

  p, strong, span, h1, h2 {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  }

  .MuiFormControl-root > label {
    color: ${({ theme, variant }) => variant === 'red' && theme.palette.orange.main.hex()};
    color: ${({ theme, variant }) => variant === 'blue' && theme.palette.blue._920.hex()};
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.grey._900.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 1.7rem;
  text-align: center;
  margin: 1rem 0;

  span {
    font-size: 2.2rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
  }

  @media(min-width: 320px)  and (max-width: 500px) {
    font-size: 1.2rem;
    span {
      font-size: 1.2rem;
    }
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
`;

export const DatesSelected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;

  @media(min-width: 320px)  and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DateGrid = styled.div<{ variant: 'red' | 'blue' }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0.5rem;
  padding: 0.2rem;

  .date, .time {
    span {
      font-size: 1.1rem;
    }
  }

  .date {
    strong {
      font-size: 1.3rem;
      font-weight: 700;
      margin-right: 0.5rem;
      color: ${({ theme, variant }) => variant === 'red' && theme.palette.orange.main.hex()};
      color: ${({ theme, variant }) => variant === 'blue' && theme.palette.blue._920.hex()};
    }
  }

  .time {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      fill: ${({ theme, variant }) => variant === 'red' && theme.palette.orange.main.hex()};
      fill: ${({ theme, variant }) => variant === 'blue' && theme.palette.blue._920.hex()};
      width: 16px;
      height: auto;
      margin-right: 0.5rem;
    }
  }

  @media(min-width: 320px)  and (max-width: 500px) {
    margin: 0;
    padding: 0.5rem 0;

    .date, .time {
      span {
        font-size: 1rem;
      }
      strong {
        font-size: 1rem;
        font-weight: 700;
        margin-right: 0.5rem;
        color: ${({ theme, variant }) => variant === 'red' && theme.palette.orange.main.hex()};
        color: ${({ theme, variant }) => variant === 'blue' && theme.palette.blue._920.hex()};
      }
    }

    .arrow-icon {
        transform: rotate(90deg);
    }
  }
`;

export const ButtonContainer = styled.div<{ variant: 'red' | 'blue' }>`
  display: flex;

  button {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    margin-top: 2rem;
    background-color: ${({ theme, variant }) => variant === 'red' && theme.palette.orange.main.hex()};
    background-color: ${({ theme, variant }) => variant === 'blue' && theme.palette.blue._920.hex()};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: ${({ theme }) => theme.palette.white.main.hex()};
    padding: 0.8rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    font-size: 1.2rem;

    &:hover {
      background-color: ${({ theme, variant }) => variant === 'red' && theme.palette.orange._700.hex()};
      background-color: ${({ theme, variant }) => variant === 'blue' && theme.palette.blue._910.hex()};
      transition: background-color 300ms;
    }
  }
`;

export const Text = styled.p`
  font-size: 1.2;
  color: ${({ theme }) => theme.palette.grey._700.hex()};
  font-weight: ${({ theme }) => theme.fonts.weights.regular};
`;
