/* eslint-disable react/require-default-props */
import React from 'react';

import { RadioYesOrNo, TextField } from '../../../../..';

import {
  Container,
  Header,
  LeftSide,
  FormContainer,
} from './styles';

import { UseFormik } from '../../../../../../utils/Formik/types';
import { hour } from '../../../../../../utils/InputMask/Number';

interface Props {
  formik?: UseFormik<any>;
  label: string;
  id: string;
  idTextField: string;
  value: string | null;
}

const CheckinCheckoutAuthorization = ({
  formik, label, id, idTextField, value = null,
}: Props) => (
  <Container>
    <Header>
      <LeftSide>
        <h1>
          {label}
        </h1>
        <RadioYesOrNo
          formLabel=""
          id={id}
          labelPlacement="end"
          formik={formik}
        />
      </LeftSide>

    </Header>
    {formik && formik.values[id].toString() === 'true' && (
    <>
      <FormContainer className="form-1">
        <TextField
          dataCy="checkin-time"
          label={id === 'earlyCheckin' ? '*Horário de chegada' : '*Horário de saída'}
          placeholder="__:__"
          id={idTextField}
          mask="hour"
          autoComplete="off"
          formik={formik}
          value={value ? hour(value) : value}
        />
      </FormContainer>
    </>
    )}
  </Container>
);

export default CheckinCheckoutAuthorization;
