import { keyframes } from 'styled-components';

export const animationTime = 2;

export const shimmerAnimation = keyframes`
  0% {
      background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
      background-size: 400% 400%;
      background-position: 0% 0%;
    }
  50% {
    background-position: -135% 0%;
  }
  100%{
    background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
    background-size: 400% 400%;
    background-position: 0% 0%;
  }
`;
