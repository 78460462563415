import React, { useEffect } from 'react';
import NewPassword from '../../components/NewPassword';
import { ColoredBar, LogoImage, PageContainer } from './styles';

import Logo from '../../assets/icons/generals/logo.png';

const NewPasswordPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Nova Senha';
  }, []);

  return (
    <>
      <PageContainer>
        <ColoredBar>
          <LogoImage src={Logo} alt="Seazone Logo" />
        </ColoredBar>
        <NewPassword />
      </PageContainer>
    </>
  );
};

export default NewPasswordPage;
