import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import CustomInputMask from '../../../../../../../../components/CustomInputMask';

export const FormContainer = styled(motion.form)<{
  expanded: boolean;
  disabled?: boolean;
}>`
  gap: 20px;
  width: 100%;
  height: 100%;

  padding: 20px 0;
  > *:not(.isMain) {
    padding: 0 20px;
  }

  display: flex;
  background: #fefefe;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
  border: 1px solid ${({ expanded }) => (expanded ? '#e0e0e0' : 'transparent')};

  ${({ disabled }) => disabled
    && css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
        background: #fff;
        opacity: 0.7;
      }
    `}
`;

export const SelectStyled = styled.select<{
  error?: boolean;
}>`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #D9D9D9;

  transition: 0.2s;

  ${({ error }) => error
    && css`
      border: 1px solid #f15151;
    `}

  &:disabled {
    opacity: 0.5;
  }
`;

export const InputMaskStyled = styled(CustomInputMask)<{
  error?: boolean;
}>`
  &&& {
    width: 100%;
    padding: 13px 17px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid #D9D9D9;

    ${({ error }) => error
      && css`
        border: 1px solid #f15151;
      `}
  }
`;
