import { FC } from 'react';
import ControlledSwitchButton from '../../../../ControlledSwitchButton';
import { Container } from './styles';

type Rules = {
  name: string,
  key: string,
  value: boolean,
};

type CardPropertyRulesProps = {
  rules: Rules,
  updateRule: Function,
  required?: boolean;
};

const CardPropertyRules:FC<CardPropertyRulesProps> = ({
  rules,
  updateRule,
  required,
}) => (
  <Container>
    <h2>
      {required && (<span>*</span>)}
      {rules.name}
    </h2>
    <ControlledSwitchButton
      checked={rules.value}
      handleChange={() => updateRule(rules.key)}
    />
  </Container>
);

export default CardPropertyRules;
