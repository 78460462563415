import styled, { keyframes } from 'styled-components';

const slideTop = keyframes`
  from {

    -webkit-transform: translateY(-10px);
    transform: translateY(10px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
`;

export const Container = styled.div<{ opacity: number }>`
  width: 100%;
  min-height: 100px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  opacity: ${({ opacity }) => opacity};

  -webkit-animation: ${slideTop} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
animation: ${slideTop} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  &:hover {
    cursor: pointer;

    box-shadow:
    0px 0.2px 0.5px rgba(0, 0, 0, 0.029),
    0px 0.4px 1.1px rgba(0, 0, 0, 0.043),
    0px 0.8px 2.1px rgba(0, 0, 0, 0.053),
    0px 1.3px 3.8px rgba(0, 0, 0, 0.06),
    0px 2.5px 7.1px rgba(0, 0, 0, 0.065),
    0px 6px 17px rgba(0, 0, 0, 0.07)
  ;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;
  padding: 6px 0;


`;

export const GuestName = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  margin-bottom: 0.375rem;

`;

export const ImmobileOta = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  margin-bottom: 0.625rem;
`;

export const Date = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 300;
  font-size: 0.625rem;
  letter-spacing: -0.02em;
  margin-bottom: 3px;
`;

export const TotalValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 7px;
`;
export const AmountPaid = styled.div`
  width: 25%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.green._120.hex()};
`;
export const Text = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: center;
`;
export const Amount = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
`;

export const MissingValues = styled.div`
  width: 25%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.red._50.hex()};
  border-radius: 0 0.75rem 0.75rem 0;
`;
