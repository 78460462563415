// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PlanOptions } from '../../../../../services/InsertData/types';
import { UseFormik } from '../../../../../utils/Formik/types';
// import FormButton from '../../../../FormButton';
import TextField from '../../../../TextField';
import { Container, Content, TextFieldContainer } from './styles';

type PropertyType = 'House' | 'Apartment' | 'Hotel';
type PropertyStatus = 'Active' | 'Inactive' | 'Onboarding' | 'Closed';

interface InsertDataPropertyFormik {
  code: string;
  singleBedQuantity?: number;
  doubleBedQuantity?: number;
  queenBedQuantity?: number;
  kingBedQuantity?: number;
  singleSofaBedQuantity?: number;
  doubleSofaBedQuantity?: number;
  pillowQuantity?: number;
  bedroomQuantity?: number;
  bathroomQuantity?: number;
  lavatoryQuantity?: number;
  boundAmount?: string;
  guestCapacity?: number;
  propertyType?: PropertyType;
  status?: PropertyStatus;
  activationDate?: Date;
  inactivationDate?: Date;
  contractStartDate?: Date;
  contractEndDate?: Date;
  street: string;
  number?: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  host?: number;
  owners?: number;
  cleaningFee: string;
  comissionFee: string;
  categoryLocation?: string;
  category: number;
  location: number;
  plan: PlanOptions;
  hostReservationComissionFee?: number;
  extraDayPreparation?: number;
}

type CodeGeneratorProps = {
  required?: boolean;
  formik?: UseFormik<InsertDataPropertyFormik>;
};

const CodeGenerator = ({
  required = false,
  formik,
}: CodeGeneratorProps) => (
  <Container>
    <h2>
      {required && (
        <span>
          *
        </span>
      )}
      Código do imóvel
    </h2>
    <Content>
      {/* <FormButton disable type="button">Gerar Código</FormButton> */}
      <TextFieldContainer>
        <TextField
          className="codeGenerator"
          id="code"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.code}
        />
        {/* <CheckCircleIcon /> */}
      </TextFieldContainer>
    </Content>
  </Container>
);

CodeGenerator.defaultProps = {
  required: false,
  formik: undefined,
};

export default CodeGenerator;
