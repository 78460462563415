import { keyframes } from 'styled-components';
import { v4 as uuid } from 'uuid';
import addPhotoSketchIcon from '../../../../../../assets/icons/expense/photoSketch.svg';

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const initialValues = {
  size: 0,
  uid: '',
  name: '',
  id: uuid(),
  error: false,
  MIMEtype: '',
  uploaded: false,
  uploadProgress: 0,
  readableSize: '0 KB',
  url: addPhotoSketchIcon,
  file: addPhotoSketchIcon,
  previewURL: addPhotoSketchIcon,
};

export const list = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

export const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 },
};
