import React from 'react';
import { Modal as ModalMui } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { Container } from './style';

interface IModal {
  open: boolean;
  setOpen?: Function;
  children: React.ReactNode;
}

const Template = ({ open, setOpen, children }: IModal) => {
  const handleClick = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <AnimatePresence>
      <ModalMui open={open} onClose={handleClick}>
        <Container
          exit={{
            x: '50%',
            opacity: 0,
          }}
          animate={{
            top: '50%',
            x: 'auto',
            opacity: 1,
          }}
          initial={{
            top: '60%',
            x: 'auto',
            opacity: 0,
          }}
        >
          {children}
        </Container>
      </ModalMui>
    </AnimatePresence>
  );
};

Template.defaultProps = {
  setOpen: undefined,
};

export default Template;
