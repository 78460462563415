import { useContextSelector } from 'use-context-selector';
import { SearchContext } from '../../context/SearchContext';

export function useSearch() {
  const loadingSearch = useContextSelector(SearchContext, (search) => search.loadingSearch);
  const setLoadingSearch = useContextSelector(SearchContext, (search) => search.setLoadingSearch);

  const contentSearch = useContextSelector(SearchContext, (search) => search.contentSearch);
  const setContentSearch = useContextSelector(SearchContext, (search) => search.setContentSearch);

  const filteredResults = useContextSelector(SearchContext, (search) => search.filteredResults);
  const setFilteredResults = useContextSelector(SearchContext,
    (search) => search.setFilteredResults);

  const isFirstPage = useContextSelector(SearchContext, (search) => search.isFirstPage);
  const setIsFirstPage = useContextSelector(SearchContext,
    (search) => search.setIsFirstPage);

  return {
    contentSearch,
    setContentSearch,
    loadingSearch,
    setLoadingSearch,
    filteredResults,
    setFilteredResults,
    isFirstPage,
    setIsFirstPage,
  };
}
