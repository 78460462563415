/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef } from 'react';
import moment from 'moment';

import Header from '../Header';
import Footer from '../Footer';
import IncomeSection from '../IncomeSection';
import ExpenseSection from '../ExpenseSection';
import WalletSection from '../WalletSection';
import FormButton from '../../../FormButton';
import Steppers from '../../../Steppers/Steppers';

import { useConversorHtmlToPdf } from '../../../../hooks/GlobalHook/useConversorHtmlToPdf';

import { useOwnerExtract } from '../../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';

import {
  Wrapper,
  Container,
  Content,
  ContainerButton,
  ProgressContainer,
} from './styles';

interface Props {
  openModal: boolean;
  setOpenModal: Function;
}

const ModalFinancialExtract: FC<Props> = ({
  openModal,
  setOpenModal,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { isDownloading, handleConvertHtmlToPdf } = useConversorHtmlToPdf();
  const { showExtractDate, setShowExtractDate, setExtractGeneratedIn } = useOwnerExtract();

  const handleDownloadExtract = () => {
    setExtractGeneratedIn(`${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm:ss')}`);
    setShowExtractDate(true);
  };

  const handleCloseExtract = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (showExtractDate) {
      handleConvertHtmlToPdf(componentRef, {
        filename: 'extrato',
        orientation: 'portrait',
      });
    }
  }, [showExtractDate]);

  useEffect(() => {
    if (!isDownloading) {
      setTimeout(() => {
        setShowExtractDate(false);
      }, 1000);
    }
  }, [isDownloading]);

  return (
    <Wrapper
      openModal={openModal}
      onContextMenu={(e) => { e.preventDefault(); }}
      onClick={() => setOpenModal(false)}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Content
          ref={componentRef}
        >
          <Header
            setOpenModal={setOpenModal}
            handleClickDownload={() => handleDownloadExtract()}
          />

          <IncomeSection />
          <ExpenseSection />
          <WalletSection />

          <ContainerButton>
            <FormButton
              id="download-financial-statement-button-footer"
              type="button"
              onClick={() => handleDownloadExtract()}
            >
              Baixar extrato
            </FormButton>
            <FormButton
              variant="outlined"
              onClick={() => handleCloseExtract()}
            >
              Fechar extrato
            </FormButton>
          </ContainerButton>

          {/* <Footer /> */}
        </Content>
      </Container>

      {isDownloading && (
        <ProgressContainer>
          <Steppers
            progress={isDownloading ? 99 : 100}
            stepperOptions={[
              'Processando pedido',
              'Preparando extrato',
              'Extrato baixado',
            ]}
          />
        </ProgressContainer>
      )}
    </Wrapper>
  );
};

export default ModalFinancialExtract;
