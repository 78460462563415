export type ButtonTypeProps = 'button' | 'submit' | 'reset';

export type Color = 'blue' | 'red' | 'grey' | 'black' | undefined;
export type Variant = 'outlined' | undefined;

export type ButtonProps = {
  customcolorbg?: Color,
  variant?: Variant,
  linkto?: number,
  invisible?: boolean | undefined,
};

export enum VariantEnum {
  OUTLINED = 'outlined',
}

export enum ButtonTypeEnum {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ColorsEnum {
  BLUE = 'blue',
  RED = 'red',
  GREY = 'grey',
  BLACK = 'black',
}
