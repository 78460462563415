import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { getMaxWidthDesktop } from '../../style';

export const Container = styled(motion.div)`
  gap: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;

  ${getMaxWidthDesktop};

  input {
    padding: 15px;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid #d9dcdf;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Alert = styled.div`
  gap: 20px;
  padding: 20px;
  position: relative;
  align-items: center;
  border-radius: 10px;
  background-color: #fefefe;
  /* background-color: transparent; */
  border: 2.5px solid rgb(245, 166, 35);

  h1 {
    color: rgb(245, 166, 35);
    font-weight: 500;
    font-size: 1.3rem;
    font-family: "Inter";
  }
`;

export const InfoAnimationContainer = styled.div`
  margin: 0;
  top: -20px;
  width: 50px;
  right: -20px;
  position: absolute;
`;

export const Section = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const SubTitle = styled.h2`
  color: #394760;
  font-weight: 500;
  font-size: 1.3rem;
  font-style: normal;
  font-family: "Inter";
`;

export const TertiaryTitle = styled.h2`
  width: 100%;
  color: #394760;
  font-weight: 500;
  font-size: 1.1rem;
  font-style: normal;
  font-family: "Inter";
`;

export const TotalBalanceContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1000px) {
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Balance = styled(motion.h1)<{
  negative?: boolean;
}>`
  color: #394760;
  font-weight: 500;
  font-size: 1.1rem;
  width: 100%;
  text-align: right;
  font-style: normal;
  font-family: "Inter";

  ${({ negative }) => negative
    && css`
      color: red;
    `}

  @media (max-width: 1000px) {
    text-align: left;
  }
`;

export const BalanceShimmer = styled(motion.div)`
  width: 10%;
  height: 5px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  background-color: #fefefe;
`;

export const Title = styled(motion.h1)`
  color: #394760;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const TableContainer = styled(motion.div)``;
