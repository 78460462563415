import styled, { css } from 'styled-components';
import { Star } from '@mui/icons-material';

type StarProps = {
  starColor: string
  disableChangeStar: boolean
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: auto;
`;

export const ContainerLabel = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17.5px;
  margin-left: 0.3rem;
`;

export const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.7rem;
`;

export const StarContent = styled.label``;

export const Input = styled.input`
  display: none;
`;

export const StarIconContainer = styled.div<StarProps>`
  cursor: ${({ disableChangeStar }) => (disableChangeStar ? 'default' : 'pointer')};
  margin-left: 0.2rem;
  width: 28px;
  height: 28px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${({ starColor }) => css`
    color: ${starColor} !important;
  `}
`;

export const StarIcon = styled(Star)``;

export const RatingCounter = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.red._500.hex()};
`;
