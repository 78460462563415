/* eslint-disable @typescript-eslint/no-unused-vars */
import request from '../request';
import { IListItems } from '../../components/NfList/types/listItems';

import {
  IPutNFs,
  IGetNFList,
  IGetNFListReturn,
  IGetNFListParams,
  IPathNFListParams,
  IGetFilesByFileUid,
  IParamsGenerateNFSpreadsheet,
  IResponseGenerateNFSpreadsheet,
} from './types';

export const syncTaskId = async ({
  period,
}: IPutNFs) => {
  const { data } = await request.put('/financial_closing/owner/sync_nfs/', {
    period,
  });
  return data;
};

export const syncTasksWithTaskId = async (taskId: string) => {
  const { data } = await request.get(`tasks/${taskId}/`);
  return data;
};

export const getNFList = async ({
  q,
  period, // yyymm
  status,
}: IGetNFListParams): Promise<IGetNFListReturn> => {
  const { data } = await request.get(`/financial_closing/owner/nfs/?period=${period}`, {
    params: {
      q,
      status,
    },
  });

  const formatDataToListItem: IListItems[] = data.map((item: IGetNFList) => ({
    id: item.id,
    name: item.invoice_details.invoice_entity_name,
    nameOfNf: item.invoice_details.invoice_entity_name,
    host: item.host.user.name,
    commission: item.commission,
    cpf: item.invoice_details.cpf,
    address: item.invoice_details.address,
    address_number: item.invoice_details.address_number,
    complement: item.invoice_details.complement,
    district: item.invoice_details.district,
    city: item.invoice_details.city,
    state: item.invoice_details.state,
    postal_code: item.invoice_details.postal_code,
    email: item.invoice_details.email,
    status: item.status,
    cnpj: item.invoice_details.cnpj,
  }));

  return { data, formatDataToListItem };
};

export const pathNFList = async ({
  nfId,
  newStatus,
}: IPathNFListParams) => {
  const { data } = await request.patch(`/financial_closing/owner/nfs/${nfId}/`, {
    status: newStatus,
  });
  return data;
};

export const generateNFSCsv = async ({
  period,
}: IParamsGenerateNFSpreadsheet): Promise<IResponseGenerateNFSpreadsheet> => {
  const { data } = await request.put('/financial_closing/property/generate_nfs_csv/', {
    period,
  });

  return data;
};

export const getFilesByFileUid = async (fileUid: string, signal?: AbortSignal) => request
  .get(`/files/${fileUid}/`, {
    signal,
  })
  .then((response) => response.data)
  .catch((error) => {
    if (error.response) {
      if (error.response.status === 423) {
        return 'Processing';
      }
    }
    return error;
  });
