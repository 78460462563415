import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
  border-radius: 10px;
  h2 {
    font-weight: ${({ theme }) => theme.fonts.weights.normal};
    font-family: ${({ theme }) => theme.fonts.familys._2};
    color: ${({ theme }) => theme.palette.blue._900.hex()};
    line-height: 1.4;
    font-size: 1rem;

    span {
      color: red;
      margin-right: 0.5rem;
    }
  }

`;

export const Content = styled.div`
  margin-top: 1rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  /* svg {
    color: ${({ theme }) => theme.palette.green.main.hex()};
  } */
  .codeGenerator {
    margin-top: -0.5rem;
  }
`;
