import React from 'react';
import Lottie from 'lottie-react';

import { Content } from './style';
import { IModal } from '../types';
import Template from '../Template/Template';
import animationSource from '../../../../../../../../assets/animations/source/confirmationAnimation.json';

interface ISuccess {
  open: IModal['open'];
}

const Success = ({ open }: ISuccess) => (
  <Template open={open}>
    <Content initial={{ opacity: 0, y: 10 }} animate={{ y: 0, opacity: 1 }}>
      <Lottie animationData={animationSource} />
      <h1>
        Dados enviados com sucesso!
      </h1>
      <p>
        Se precisar atualizar a sua reserva, entre em contato com o atendimento!
      </p>
    </Content>
  </Template>
);

export default Success;
