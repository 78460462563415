import React from 'react';

import { useMobile } from '../../../../../hooks/useMobile/useMobile';
import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';

import Body from './Body';
import Footer from './Footer';
import Header from './Header';

import {
  Content,
  Backdrop,
  Container,
} from './style';

interface IExpanded {
  onClose?: Function;
}

const Expanded = ({
  onClose,
}: IExpanded) => {
  const { setShowBurgerMenu } = usePartners();
  const { isDesktop } = useMobile();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setShowBurgerMenu(false);
    }
  };

  return (
    <>
      <Container
        initial={{ width: 0 }}
        style={{ originX: 0 }}
        exit={{ x: '-100%', opacity: 0 }}
        animate={{ width: isDesktop ? 'auto' : '100%' }}
      >
        <Content
          transition={{ delay: 0.1 }}
          initial={{ y: 5, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <Header onClose={() => handleClose()} />
          <Body onClose={() => handleClose()} />
          <Footer />
        </Content>
      </Container>
      {isDesktop && (
        <Backdrop
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={() => handleClose()}
        />
      )}
    </>
  );
};

Expanded.defaultProps = {
  onClose: undefined,
};

export default Expanded;
