import {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

interface IHostFinancialStatement {
  period: Date,
  setPeriod: (date: Date) => void,
  propertyId: number | undefined,
  setPropertyId: (id: number | undefined) => void,
  openFinancialStatementModal: boolean,
  setOpenFinancialStatementModal: (open: boolean) => void,
}

export const HostFinancialStatementContext = createContext<IHostFinancialStatement>({
  period: new Date(),
  setPeriod: (date: Date) => date,
  propertyId: undefined,
  setPropertyId: (id: number | undefined) => id,
  openFinancialStatementModal: false,
  setOpenFinancialStatementModal: (open: boolean) => open,
});

export const HostFinancialStatementProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [period, setPeriod] = useState<Date>(new Date());
  const [propertyId, setPropertyId] = useState<number | undefined>(undefined);
  const [openFinancialStatementModal, setOpenFinancialStatementModal] = useState<boolean>(false);

  const handleSetPeriod = useCallback((date: Date) => {
    setPeriod(date);
  }, []);

  const handleSetPropertyId = useCallback((id: number | undefined) => {
    setPropertyId(id);
  }, []);

  const handleSetOpenFinancialStatementModal = useCallback((open: boolean) => {
    setOpenFinancialStatementModal(open);
  }, []);

  const value = useMemo(() => ({
    period,
    setPeriod: handleSetPeriod,
    propertyId,
    setPropertyId: handleSetPropertyId,
    openFinancialStatementModal,
    setOpenFinancialStatementModal: handleSetOpenFinancialStatementModal,
  }), [
    period,
    setPeriod,
    propertyId,
    setPropertyId,
    openFinancialStatementModal,
    setOpenFinancialStatementModal,
  ]);

  return (
    <HostFinancialStatementContext.Provider
      value={value}
    >
      {children}
    </HostFinancialStatementContext.Provider>
  );
};
