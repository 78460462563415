import moment from 'moment';
import { formatDateToShow, formatHourToShow, numberToCurrency } from '../Formatter';
import { translateMonth } from '../Translator';
import { ReservationById, ReservationData, ReservationTimeLine } from '../../types/Reservations';
import { getPaymentStatus } from '../../services/Reservation/request';

export async function processReservationData(reservationDetails: ReservationById,
  reservation: ReservationTimeLine | any): Promise<ReservationData> {
  const reservationData = {
    ...reservationDetails,
    ...reservation,
    guest: { ...reservation.guest, ...reservationDetails.guest },
    property: { ...reservationDetails.property, ...reservation.property },
  };

  const {
    adult_guest_quantity,
    child_guest_quantity,
    created_at,
    check_in_date,
    check_out_date,
    cleaning_fee,
    comment,
    check_in_time,
    check_out_time,
    code,
    property,
    was_contacted_checkin,
    ota,
    daily_net_value,
    status,
    is_blocking,
    blocking_reason,
    pet_quantity,
    number_of_days,
    number_of_nights,
  } = reservationData;

  const { id } = reservationData;
  const createdAtDate = formatDateToShow(created_at || '');
  const createdAtHour = formatHourToShow(created_at || '');

  const adultGuests = adult_guest_quantity || 0;
  const childrenGuests = child_guest_quantity || 0;

  const checkInDate = moment(check_in_date).format('YYYY/MM/DD') || '';
  const checkOutDate = moment(check_out_date).format('YYYY/MM/DD') || '';

  const checkInTime = check_in_time || '';
  const checkOutTime = check_out_time || '';

  const netCleaningFee = numberToCurrency(cleaning_fee);
  const totalPrice = numberToCurrency(Number(daily_net_value) || 0);

  const otaName = ota?.name || '';
  const bondAmount = numberToCurrency(Number(property?.bond_amount) || 0);
  const propertyCode = property?.code || 0;
  const propertyId = property?.id || 0;

  const reservationCopyInformation = `Código de reserva: ${code}\nCódigo do imóvel: ${propertyCode}\nData de Check-in: ${checkInDate}\nData de Check-out: ${checkOutDate}\nNome do hóspede: ${reservationData.guest?.first_name}`;

  const commentSplitted = comment?.split('; cancellation:').filter((txt: any) => txt.trim() !== '') || '';

  const wasContacted = was_contacted_checkin;

  const propertyAddress = property.address ? `
    ${property.address?.street} ${property.address?.number}, ${property.address?.neighborhood} - ${property.address?.complement} ${property.address?.city} - ${property.address?.state} - ${property.address?.country}, CEP: ${property.address?.postal_code}` : '';

  const birthSplitted = reservationData?.guest?.birth_date || '';

  // guest data
  const dataMoment = moment(birthSplitted, 'DD/MM/YYYY');

  const day = dataMoment.date();
  const month = dataMoment.month() + 1;
  const year = dataMoment.year();

  const paymentStatus = getPaymentStatus(status);

  const {
    street, number, neighborhood, complement, city, state, country, postalCode,
  } = reservationData.guest.address || '';

  const guest = {
    id: reservationData.guest?.id,
    guestFirstName: reservationData.guest?.first_name || '',
    guestLastName: reservationData.guest?.last_name || '',
    phoneNumber1: reservationData.guest?.phone_number1 || '',
    phoneNumber2: reservationData?.guest?.phone_number2 || '',
    client: `${reservationData.guest?.first_name || ''} ${reservationData.guest?.last_name || ''}`,
    born: day && month && year ? `${day} de ${translateMonth(`${month}`)} de ${year}` : '',
    isIndividual: reservationData?.guest?.is_individual || false,
    idNumber: reservationData.guest?.id_number || '',
    cnpj: reservationData.guest?.cnpj ?? '',
    cpf: reservationData.guest?.cpf ?? '',
    gender: reservationData.guest?.gender ?? '',
    email: reservationData.guest?.email ?? '',
    birthDate: reservationData.guest?.birth_date ?? '',
    corporate_name: reservationData.guest?.corporate_name ?? '',
    trading_name: reservationData.guest?.trading_name ?? '',

    address: {
      ...reservationData.guest.address,
      ...(reservationData?.guest?.address?.postal_code
        && { postalCode: reservationData.guest.address.postal_code }),
    },

    addressGuest: `${street || ''} ${number || ''} ${neighborhood ? `, ${neighborhood}` : ''} ${
      complement ? ` - ${complement}` : ''
    } ${city || ''} ${state ? ` - ${state}` : ''} ${country ? ` - ${country}` : ''} ${
      postalCode ? `, CEP: ${postalCode}` : ''
    }`,
  };

  return {
    id,
    propertyId,
    guest,
    createdAtDate,
    createdAtHour,
    adultGuests,
    childrenGuests,
    checkInDate,
    checkOutDate,
    netCleaningFee,
    totalPrice,
    otaName,
    bondAmount,
    propertyCode,
    reservationCopyInformation,
    commentSplitted,
    wasContacted,
    paymentStatus,
    checkInTime,
    checkOutTime,
    status,
    is_blocking,
    blocking_reason,
    pet_quantity,
    number_of_days,
    number_of_nights,
    propertyAddress,
    code,
  };
}
