import styled from 'styled-components';

const height = '25px';

export const Container = styled.div``;

export const ConversionBarSpace = styled.div`
  position: absolute;
  top: -${height};
  width: 100%;
  height: ${height};
  box-shadow: 26px 0px 7px -1px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: row;
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  border-top: 1.5px solid var(--gray-800, #394760);
  border-right: 1.5px solid var(--gray-800, #394760);
  border-bottom: 1.5px solid var(--gray-800, #394760);
`;

export const ConversionBarMask = styled.div<{
  progress: number;
}>`
  width: calc(100% - ${({ progress }) => progress}%);
  position: absolute;

  transition: width 0.5s ease;

  top: -${height};
  height: ${height};

  z-index: 2;
  background: #e7e9fa;
`;

export const ConversionBar = styled.div`
  width: 100%;

  top: -${height};
  height: ${height};

  position: absolute;

  background: linear-gradient(
    90deg,
    #0d4bd0 12.68%,
    #f90 21.4%,
    #ff5c00 77.08%,
    #eb0000 93.75%
  );
`;
