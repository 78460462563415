import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import chevronRightIcon from '../../assets/icons/propertySearch/chevron-right.svg';

export type Way = {
  title: string,
  path: string,
};

interface Props {
  ways: Array<Way> | undefined
}

export default function Breadcrumb({ ways }: Props) {
  return (
    <Container>
      {
        ways && ways.map((way, i) => (
          <div key={way.path}>
            <Link title={way.path === '#' ? '' : `Voltar para ${way.title}`} to={way.path}>{way.title}</Link>
            { ways.length === i + 1 ? '' : <img src={chevronRightIcon} alt="Arrow next" /> }
          </div>
        ))
      }
    </Container>
  );
}
