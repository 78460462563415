import React from 'react';
import { CircularProgress } from '@mui/material';

import { Container, NotFoundContainer } from './style';

import SelectView from './SelectView';

import { NoDataFoundAnimation } from '../../../../assets/animations/components/NoDataFoundAnimation';
import { useOwnerPerspectiveDashboard } from '../../../../context/OwnerPerspectiveDashboard/OwnerPerspectiveDashboard';

const Body = () => {
  const { loading, viewOwners } = useOwnerPerspectiveDashboard();

  if (viewOwners?.owners?.length <= 0) {
    return (
      <NotFoundContainer>
        <h1>Nenhum proprietário encontrado</h1>
        <p>
          Experimente buscar por outros termos, ou deixe a caixa de busca vazia
          para remover os filtros
        </p>
        <NoDataFoundAnimation />
      </NotFoundContainer>
    );
  }

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <SelectView />
    </Container>
  );
};

export default Body;
