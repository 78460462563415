import React, { useState, useEffect } from 'react';
import {
  CalendarTodayOutlined as CalendarIcon,
  ArrowForwardSharp as ArrowIcon,
} from '@mui/icons-material';
import moment from 'moment';
import CardResumeProperty from '../CardResumeProperty';

import {
  CategoryProperties,
  ContentPart,
  DateReservation,
  HeaderCategory,
  NameCategory,
  SmallPart,
  TotalValue,
} from './styles';
import { MonthlyBudgetPropertyDetailsModal } from '../../../services/MonthlyBudget/types';

interface Props {
  categoria: string;
  properties: Array<MonthlyBudgetPropertyDetailsModal>;
  checkinDate: string;
  checkoutDate: string;
}

const CategoryCart = ({
  categoria, properties, checkoutDate, checkinDate,
}: Props): JSX.Element => {
  const [totalValue, setTotalValue] = useState(0);

  function prepareValues() {
    let aux = 0;
    properties.forEach((property) => {
      aux += property.totalPrice;
    });
    setTotalValue(aux);
  }
  useEffect(() => {
    prepareValues();
  }, []);
  return (
    <>
      <CategoryProperties>
        <HeaderCategory>
          <NameCategory>
            <strong>Categoria</strong>
            {' '}
            {categoria}
          </NameCategory>
        </HeaderCategory>
        <h2>Imóveis</h2>
        {properties.map((item) => (
          <CardResumeProperty code={item.code || ''} address={item.address} idProperty={item.id || 0} />
        ))}
        <h2>Duração</h2>
        <DateReservation>
          {checkinDate && (
          <>
            <CalendarIcon />
            <p>{moment(checkinDate).format('DD/MM/YYYY')}</p>
            <ArrowIcon />
            <p>{moment(checkoutDate).format('DD/MM/YYYY')}</p>
          </>
          )}
          {!checkinDate && (
          <span>Selecione o checkin e checkout na barra de filtros</span>
          )}
        </DateReservation>
      </CategoryProperties>
      <TotalValue>
        <p>Valor total</p>
        <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValue)}</p>
      </TotalValue>
      <ContentPart>
        <h2>Pagamento</h2>
        {properties.map((property) => (
          <>
            <SmallPart>
              <h1>{property.code}</h1>
            </SmallPart>
            {property.monthlyPrice.map((price, index) => (
              <SmallPart>
                <p>{`Parcela ${index + 1}`}</p>
                <p>02/Jun/2021</p>
                <p>{price.installment}</p>
              </SmallPart>
            ))}
          </>
        ))}
      </ContentPart>
    </>
  );
};

export default CategoryCart;
