import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 2rem;
  border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
  width: 100%;
  border-radius: 10px;
  h2 {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fonts.weights.normal};

    span {
      color: red;
      margin-right: 0.5rem
    }
  }
`;
