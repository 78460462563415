import Lottie, { LottieComponentProps } from 'lottie-react';
import React from 'react';

interface GeneralAnimationProps extends Omit<LottieComponentProps, 'animationData'> {
  source: any;
}

export const GeneralAnimation = ({ source, ...rest }: GeneralAnimationProps) => (
  <Lottie
    {...rest}
    animationData={source}
  />
);
