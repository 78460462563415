import { UseFormik } from '../../../../utils/Formik/types';

import DatePickerRange from '../../../DatePickerRange';

import { Container, Label } from './styles';

interface ICustomDatePickerRange {
  id1: string,
  id2: string,
  formik?: UseFormik<any>,
  calendars?: 1 | 2,
  hasInitialDates?: boolean,
  disableCloseOnSelect?: boolean,
  minDate?: Date,
  dataCyTextField1?: string
  dataCyTextField2?: string,
  labelVariant?: 'primary' | 'secondary',
  inputVariant?: 'primary' | 'secondary',
}

const CustomDatePickerRange = ({
  id1,
  id2,
  formik,
  calendars = 1,
  hasInitialDates = false,
  disableCloseOnSelect = false,
  minDate = new Date('2000-01-02'),
  dataCyTextField1 = '',
  dataCyTextField2 = '',
  labelVariant = 'secondary',
  inputVariant = 'secondary',
}: ICustomDatePickerRange) => (
  <Container>
    <Label>Selecione o período</Label>
    <DatePickerRange
      id1={id1}
      id2={id2}
      calendars={calendars}
      formik={formik}
      hasInitialDates={hasInitialDates}
      disableCloseOnSelect={disableCloseOnSelect}
      minDate={minDate}
      dataCyTextField1={dataCyTextField1}
      dataCyTextField2={dataCyTextField2}
      labelVariant={labelVariant}
      inputVariant={inputVariant}
    />
  </Container>
);

CustomDatePickerRange.defaultProps = {
  formik: undefined,
  calendars: 1,
  hasInitialDates: false,
  disableCloseOnSelect: false,
  minDate: new Date('2000-01-02'),
  dataCyTextField1: '',
  dataCyTextField2: '',
  labelVariant: 'secondary',
  inputVariant: 'secondary',
};

export default CustomDatePickerRange;
