import {
  Title,
  Container,
} from './styles';

const Header = () => (
  <Container>
    <Title>Relatórios</Title>
  </Container>
);

export default Header;
