import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ViewDamageDetailsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .label {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #394760 !important;
    font-family: 'Inter' !important;
  }
`;

export const DamageDetailsHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: row;
  gap: 1rem;
  margin-top: 1rem;
`;

export const DamageDetailsHeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-family: 'Inter';
  color: #394760;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  > p {
    font-size: 0.75;
    font-weight: 400;
  }
`;

export const DamageTypeContainer = styled.form`
  display: flex;
  flex-direction: column;
  font-family: 'Inter' !important;
  gap: 1rem;
  overflow: scroll;
  margin-top: 1rem;

  h3 {
    color: #394760;
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const TypeButton = styled(motion.button)<{ isActive: boolean }>`
  display: flex;
  gap: 1rem;
  background-color: #D9D9D9;
  border: ${({ isActive }) => (isActive ? '1px solid #042675' : '1px solid #D9D9D9')};
  padding: 1.5rem;
  color: ${({ isActive }) => (isActive ? '#042675' : '#394760')};
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  align-items: center;

  svg {
    path: {
      fill: ${({ isActive }) => (isActive ? '#042675' : '#394760')};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;
