import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
  }

  &::-webkit-scrollbar-track {
    margin: 0 50px;
    border-radius: 10px;
    border: 1px solid #C4C4C4;
  }

  &::-webkit-scrollbar-thumb:horizontal:hover{
    background-color: #aaaaaa;
  }
  @media (max-width: 900px) {
   height: 100%;
  }
`;

export const MoreButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  position: fixed;
  bottom: 10px;
  left: 150px;

  button {
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: ${({ theme }) => theme.palette.blue._920.hex()};
    color: ${({ theme }) => theme.palette.white.main.hex()};
    cursor: pointer;
    transition: all 700ms;
    &:hover {
      background-color: ${({ theme }) => theme.palette.blue._850.hex()};
    }
  }

  @media (max-width: 900px) {
    bottom: 100px;
    left: 0px;
    z-index: 150;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10%;
  h1 {
    margin-top: 10px;
    font-size: 30px;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.grey._550.hex()};
  }
`;
