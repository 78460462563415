/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';
import { addDays } from 'date-fns';

import {
  FormButton,
} from '../../..';

import iconClose from '../../../../assets/icons/generals/iconClose.svg';
import newExpense from '../../../../assets/icons/expense/newExpense.svg';

import {
  Container,
  Content,
  Backdrop,
  CloseButton,
  HeaderContainer,
  BodyContainer,
  ButtonsContainer,
  InputModal,
} from './styles';

import { useToast } from '../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../utils/Messages';

import { useLoader } from '../../../../context/LoaderContext';

import { useUser } from '../../../../context/UserContext';

import { getExpensesReport } from '../../../../services/Expenses/request';

import { ExpenseReportProps } from '../../../../services/Expenses/types';

import SelectModal from '../../../SelectModal/SelectModal';

import {
  ButtonClear, Buttons, DateContainer, ErrorIcon, InputHideModal, Line,
} from '../styles';

import DatePickerRange from '../../../DatePickerRange';
import { useExpense } from '../../../../hooks/ExpenseHook/useExpense';

interface DateRangeProps {
  startDate: Date;
  endDate: Date;
}

const today = new Date();
const tomorrow = addDays(new Date(), 1);

const initialDateRangeValues = {
  startDate: today,
  endDate: tomorrow,
};

const ExportExpensesModal: FC = () => {
  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();
  const { setLoad } = useLoader();
  const { userInformation } = useUser();

  const {
    setOpenExportExpensesModal,
    openExportExpensesModal,
  } = useExpense();

  const handleClickExportExpensesModal = () => {
    setOpenExportExpensesModal(!openExportExpensesModal);
  };

  const [dateRange, setDateRange] = useState<DateRangeProps>(initialDateRangeValues);
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [inputDate, setInputDate] = useState<string>('Selecione o período de datas');
  const [showError, setShowError] = useState<Boolean>(false);

  const handleClickOpenDateTransfer = () => {
    setOpenDate(true);
  };

  const handleOutDateTransfer = (event: any) => {
    if (event.target.id === 'inputDates') setOpenDate(false);
  };

  const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');
  const handleGetExportExpenses = async () => {
    try {
      setLoad(true);
      const params: ExpenseReportProps = {
        start_date: formatDate(dateRange.startDate),
        end_date: formatDate(dateRange.endDate),
      };
      await getExpensesReport(params);
      setLoad(false);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoad(false);
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      startDateTransfer: today,
      endDateTransfer: tomorrow,
    },
    onSubmit: () => {},
  });

  const handleClearDateFilter = (startDate: string, endDate: string) => {
    if (formik) {
      formik.setFieldValue(startDate, today);
      formik.setFieldTouched(
        startDate, true, false,
      );
      formik.setFieldValue(endDate, tomorrow);
      formik.setFieldTouched(
        endDate, true, false,
      );
    }
    setInputDate('Selecione o período de datas');
    setShowError(false);
  };

  const checkDateFilter = (startDate: Date, endDate: Date) => moment(startDate).format('DD/MM/YYYY').toString() === 'Data inválida' || moment(endDate).format('DD/MM/YYYY').toString() === 'Data inválida' || moment(endDate) <= moment(startDate);
  const handleAppliedDateFilter = () => {
    const isInvalidDate = checkDateFilter(formik.values.startDateTransfer,
      formik.values.endDateTransfer);
    if (isInvalidDate) {
      setOpenDate(true);
      setShowError(true);
      return false;
    }
    setDateRange({
      startDate: formik.values.startDateTransfer,
      endDate: formik.values.endDateTransfer,
    });
    setInputDate(`${moment(formik.values.startDateTransfer).format('DD/MM/YYYY')} à ${moment(formik.values.endDateTransfer).format('DD/MM/YYYY')}`);
    setOpenDate(false);
    setShowError(false);
    return true;
  };

  return (
    <>
      <Backdrop
        onClick={() => {
          handleClickExportExpensesModal();
        }}
      >
        <Container
          openDateSelector={!openDate}
          onClick={(e) => e.stopPropagation()}
        >
          <Content>
            <CloseButton onClick={handleClickExportExpensesModal}>
              <img src={iconClose} alt="botão de fechar o modal" />
            </CloseButton>
            { userInformation?.main_role !== 'Host' && (
            <HeaderContainer>
              <img src={newExpense} alt="Ícone de download da despesa" />
              <h1 data-cy="title-export-expenses-modal">Selecione o intervalo de referência que deseja exportar as despesas</h1>
            </HeaderContainer>
            )}
            { userInformation?.main_role === 'Host' && (
            <HeaderContainer>
              <img src={newExpense} alt="Ícone de download da despesa" />
              <h1 data-cy="title-export-expenses-modal">Selecione o intervalo de referência que deseja exportar os reembolsos</h1>
            </HeaderContainer>
            )}
            <BodyContainer>
              <form>

                <SelectModal
                  id="dates"
                  dataCy="export-expenses-modal-dates"
                  onClick={handleClickOpenDateTransfer}
                  value={inputDate}
                />

                { openDate && (
                  <>
                    <InputHideModal id="inputDates" onClick={handleOutDateTransfer} />
                    <InputModal className="input-modal-style">
                      <DateContainer onClick={(e) => e.stopPropagation()}>
                        <DatePickerRange
                          formik={formik}
                          calendars={2}
                          data-ci="date-picker-range-export-expenses"
                          id1="startDateTransfer"
                          id2="endDateTransfer"
                          disableCloseOnSelect={false}
                          minDate={new Date('2000-01-02')}
                          hasInitialDates
                        />
                      </DateContainer>
                      <Line />
                      <Buttons>
                        <ButtonClear onClick={() => handleClearDateFilter('startDateTransfer', 'endDateTransfer')}>
                          Limpar
                        </ButtonClear>
                        <FormButton type="button" variant="outlined" onClick={() => handleAppliedDateFilter()}>Aplicar</FormButton>
                      </Buttons>
                      { showError && (
                      <p className="messageError">
                        <ErrorIcon />
                        A data final deve ser maior do que a inicial!
                      </p>
                      )}
                    </InputModal>
                  </>
                )}
                {
                  !openDate && (
                    <ButtonsContainer>
                      <FormButton dataCy="btn-cancel-expenses" variant="outlined" onClick={handleClickExportExpensesModal}>
                        Cancelar
                      </FormButton>
                      <FormButton dataCy="btn-download-expenses" type="button" onClick={() => handleGetExportExpenses()}>Baixar</FormButton>
                    </ButtonsContainer>
                  )
                }
              </form>
            </BodyContainer>
          </Content>
        </Container>
      </Backdrop>
    </>
  );
};

export default ExportExpensesModal;
