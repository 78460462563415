import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const Container = styled.div`
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 8px;
  color: ${({ theme }) => theme.palette.black.main.hex()};
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 400;
  margin: 8px 0;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    width: 93%;
  }
`;
