import React, { useMemo, useEffect } from 'react';

import iconClose from '../../../../../../../../../assets/icons/generals/iconClose.svg';

import { usePartnerIndicate } from '../../../../../../../../../context/Partners/IndicateContext/IndicateContext';

import ModalTemplate from '../../../../../../../../../components/Modal/ModalTemplate';

import { PDF_PREVIEW } from '../../ImagesUpload/icon/previewPDF';
import DeleteFile from '../../ImagesUpload/DeleteFile';
import OpenNewWindow from '../../ImagesUpload/OpenNewWindow';

import {
  Header,
  Title,
  CloseButton,
  Container,
  Content,
  ContentImage,
  Image,
} from './styles';

interface IModalFiles {
  open: boolean,
}

const ModalFiles = ({ open }: IModalFiles) => {
  const {
    openModalWithFiles,
    propertyImages,
    propertyDocuments,
    setOpenModalWithFiles,
  } = usePartnerIndicate();

  const files = useMemo(() => (openModalWithFiles.uploadType === 'photos' ? propertyImages : propertyDocuments), [openModalWithFiles, propertyImages, propertyDocuments]);

  useEffect(() => {
    if (files.length === 0) {
      setOpenModalWithFiles({
        is: false,
        uploadType: openModalWithFiles.uploadType,
      });
    }
  }, [files]);

  return (
    <ModalTemplate open={open} handleClose={setOpenModalWithFiles}>
      <>
        <Header>
          <Title>Documentos anexados</Title>
          <CloseButton onClick={() => setOpenModalWithFiles({
            is: false,
            uploadType: 'documents',
          })}
          >
            <img src={iconClose} alt="Botão de fechar o modal" />
          </CloseButton>
        </Header>
        <Container>
          <Content>
            {files.map((item) => (
              <ContentImage key={item.id}>
                <Image src={openModalWithFiles.uploadType === 'photos' ? `${item?.previewURL}` : PDF_PREVIEW} alt="Foto do documento" />
                <DeleteFile activeBorder fileId={item.id} />
                <OpenNewWindow activeBorder previewURL={item?.previewURL || '#'} />
              </ContentImage>
            ))}
          </Content>
        </Container>
      </>
    </ModalTemplate>
  );
};

export default ModalFiles;
