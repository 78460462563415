import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 16px;
  margin-bottom: 24px;
`;

export const ContentMobile = styled.div`
 .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin: 0 8px !important;
  }
`;

export const Content = styled.div`
  padding: 0 16px 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: flex-start;
  align-items: flex-start;

  .slick-prev {
    left: -10px !important;
    z-index: 100 !important;
  };

  .slick-next {
    z-index: 100 !important;
  };

  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  }

`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;  
`;

export const Title = styled.h4`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  padding-left: 0px;

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    padding-left: 8px;
    font-weight: 500;
    font-size: 20px;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    margin: 24px 0;
  }
`;

export const CardContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 2px;
  margin: 0 8px;

  .card-list {
    width: 100%;
    min-height: 95px;
    border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px 16px;
    list-style-type: '- ';

    li {
      margin-left: 8px;
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: ${({ theme }) => theme.palette.grey._560.hex()};
    }

    @media(max-width: ${MAX_SIZE_MOBILE}px) {
      min-height: auto;
    }
  }

  @media(max-width: 650px) {
    margin: 0;
  }
`;

export const Button = styled.button`
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${({ theme }) => theme.palette.green._300.hex()};
  outline: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  margin-top: 8px;
  gap: 8px;

  h6 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.green._300.hex()};
  }
`;

export const AlertMessageContainer = styled.div`
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    margin: 0 16px;
  }
`;
