import styled, { keyframes } from 'styled-components';

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateY(400px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const ExportCSVModalBackdrop = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000 !important;
`;

export const ExportCSVModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001 !important;
`;

export const ExportCSVModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  padding: 1.125rem 1.5rem;
  min-width: 600px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  z-index: 1002 !important;

  opacity: 0;
  animation-name: ${FadeInOpacity};
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  form {
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100vh;
    min-width: 0px;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  h1 {
    font-size: 1.5rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey._600.hex()};

    &:hover {
      transition: all 400ms;
      color: ${({ theme }) => theme.palette.grey._900.hex()};
    }
  }
`;

export const FormButtonContainer = styled.div`
  padding: 0.8rem 0;
  position: absolute;
  bottom: 0;
  width: 100%;

  button[type="submit"] {
    width: 100%;

    &:disabled {
      cursor: not-allowed !important;
    }
  }
`;

export const CircularProgressContainer = styled.div`
  width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;
