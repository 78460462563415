import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  gap: 40px;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  gap: 20px;
  display: flex;
  flex-direction: column;

  label {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  input {
    width: 100%;
    padding: 10px 15px;
    background: transparent;
    box-sizing: border-box;
    border: 1px solid #d2d5d8;
    border-radius: 10px;
  }
`;

export const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  color: #394760;
`;

export const InlineForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;

export const ButtonContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  
  button {
    height: 20px;
    padding: 20px;
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SendButton = styled(motion.button)`
  color: white;
  background: #0d4bd0;
  border: 1px solid transparent;
`;

export const CancelButton = styled(motion.button)`
  border: 2px solid #0d4bd0;
  background: transparent;
  color: #042675;
`;
