import { IDocumentPhoto } from '../../../../../../../../services/PreCheckin/types';

export enum FormikFields {
  CPF = 'cpf',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  NATIONALITY = 'nationality',
  IS_PRINCIPAL = 'isPrincipal',
  BED_ORGANIZATION = 'bedOrganization',

  BACK_DOCUMENT_PHOTO = 'back_document_photo',
  FRONT_DOCUMENT_PHOTO = 'front_document_photo',
}

export type FormikKeys = keyof typeof FormikFields;

export type FormikValues = FormikFields[keyof FormikFields];

export interface IFormik {
  [FormikFields.CPF]: string;
  [FormikFields.NAME]: string;
  [FormikFields.EMAIL]: string;
  [FormikFields.PHONE]: string;
  [FormikFields.NATIONALITY]: 'brazilian' | 'other';
  [FormikFields.IS_PRINCIPAL]: boolean;
  [FormikFields.BED_ORGANIZATION]: string;
  [FormikFields.BACK_DOCUMENT_PHOTO]: IDocumentPhoto['back'];
  [FormikFields.FRONT_DOCUMENT_PHOTO]: IDocumentPhoto['front'];
}
