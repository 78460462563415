import styled from 'styled-components';
import { Mode } from '../../context/ViewMode/type';

export const Container = styled.div<{
  mode: Mode | null;
}>`
  display: flex;
  background: #ebebf5;
  width: 100%;
  height: ${({ mode }) => (mode?.is === 'view' ? '100%' : '100vh')};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 900px) {
    /* height: calc(100% - 100px); */
  }
`;
