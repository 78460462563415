import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SimpleSelect, { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import {
  ButtonContainer, FullDiv, GridContainer, ItemDamageFormContainer,
} from './styles';
import { compareList } from '../../../../../utils/Sorting';
import TextField from '../../../../TextField/TextField';
import FormButton from '../../../../FormButton/FormButton';
import { UseFormik } from '../../../../../utils/Formik/types';
import { NumberInput } from '../../NumberInput';
import { UploadInput } from '../../UploadInput';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { useUser } from '../../../../../context/UserContext';

const damageClassificationOptions: SelectOption[] = [
  {
    value: 'Móveis',
    valueLabel: 'Móveis (mesa, sofá, cama, guarda roupa...)',
  },
  {
    value: 'Eletrodomésticos',
    valueLabel: 'Eletrodomésticos (TV, ar condicionado, torradeira...)',
  },
  {
    value: 'Utensílios',
    valueLabel: 'Utensílios (chave, copo, talher, panela...)',
  },
  {
    value: 'Banheiro',
    valueLabel: 'Banheiro (chuveiro, vaso, torneira...)',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const utensilOptions: SelectOption[] = [
  {
    value: 'Copos',
    valueLabel: 'Copo',
  },
  {
    value: 'Xícara',
    valueLabel: 'Xícara',
  },
  {
    value: 'Talher',
    valueLabel: 'Talher',
  },
  {
    value: 'Taça',
    valueLabel: 'Taça',
  },
  {
    value: 'Panela',
    valueLabel: 'Panela',
  },
  {
    value: 'Chaves',
    valueLabel: 'Chaves',
  },
  {
    value: 'Prato',
    valueLabel: 'Prato',
  },
  {
    value: 'Outro',
    valueLabel: 'Outro',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const furnitureOptions: SelectOption[] = [
  {
    value: 'Sofá',
    valueLabel: 'Sofá',
  },
  {
    value: 'Cama',
    valueLabel: 'Cama',
  },
  {
    value: 'Mesa',
    valueLabel: 'Mesa',
  },
  {
    value: 'Guarda Roupa',
    valueLabel: 'Guarda Roupa',
  },
  {
    value: 'Balcão',
    valueLabel: 'Balcão',
  },
  {
    value: 'Cadeira',
    valueLabel: 'Cadeira',
  },
  {
    value: 'Outro',
    valueLabel: 'Outro',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const homeAppliancesOptions: SelectOption[] = [
  {
    value: 'TV',
    valueLabel: 'TV',
  },
  {
    value: 'Fogão',
    valueLabel: 'Fogão',
  },
  {
    value: 'Ar condicionado',
    valueLabel: 'Ar condicionado',
  },
  {
    value: 'Torradeira',
    valueLabel: 'Torradeira',
  },
  {
    value: 'Geladeira',
    valueLabel: 'Geladeira',
  },
  {
    value: 'Chaleira elétrica',
    valueLabel: 'Chaleira elétrica',
  },
  {
    value: 'Secador de cabelo',
    valueLabel: 'Secador de cabelo',
  },
  {
    value: 'Outro',
    valueLabel: 'Outro',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const bathroomOptions: SelectOption[] = [
  {
    value: 'Chuveiro',
    valueLabel: 'Chuveiro',
  },
  {
    value: 'Vaso sanitário',
    valueLabel: 'Vaso sanitário',
  },
  {
    value: 'Torneira',
    valueLabel: 'Torneira',
  },
  {
    value: 'Pia',
    valueLabel: 'Pia',
  },
  {
    value: 'Espelho',
    valueLabel: 'Espelho',
  },
  {
    value: 'Saboneteira',
    valueLabel: 'Saboneteira',
  },
  {
    value: 'Outro',
    valueLabel: 'Outro',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const needToDoneOptions: SelectOption[] = [
  {
    value: 'Repor item',
    valueLabel: 'Repor item',
  },
  {
    value: 'Conserto',
    valueLabel: 'Conserto',
  },
  {
    value: 'Limpeza',
    valueLabel: 'Limpeza',
  },
  {
    value: 'Contratar Serviço Terceirizado',
    valueLabel: 'Contratar Serviço Terceirizado',
  },
  {
    value: 'Cobrar Pendência',
    valueLabel: 'Cobrar Pendência',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

interface ItemDamageFormProps {
  formik: UseFormik<any>;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  isEditingForm: boolean;
}
const ItemDamageForm = ({
  formik, quantity, setQuantity, isEditingForm,
}: ItemDamageFormProps) => {
  const {
    evidences,
    setEvidences,
    quotationFile,
    setQuotationFile,
  } = useGuestDamage();
  const { userInformation } = useUser();
  const roles = userInformation?.roles;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  function getDamageClassificationLabels() {
    switch (formik.values.itemType) {
      case 'Móveis': {
        return 'Qual móvel foi danificado? *';
      }
      case 'Eletrodomésticos': {
        return 'Qual eletrodoméstico foi danificado? *';
      }
      case 'Utensílios': {
        return 'Qual utensílio foi danificado? *';
      }
      case 'Banheiro': {
        return 'Qual item do banheiro foi danificado? *';
      }
      default: {
        return 'Qual item foi danificado *';
      }
    }
  }

  function getDamageClassificationOptions() {
    switch (formik.values.itemType) {
      case 'Móveis': {
        return furnitureOptions;
      }
      case 'Eletrodomésticos': {
        return homeAppliancesOptions;
      }
      case 'Utensílios': {
        return utensilOptions;
      }
      case 'Banheiro': {
        return bathroomOptions;
      }
      default: {
        return [];
      }
    }
  }

  useEffect(() => {
    formik.setFieldValue('itemName', '');
  }, [formik.values.itemType]);

  return (
    <ItemDamageFormContainer
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      key="ItemDamageForm"
      transition={{
        duration: 0.4,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <GridContainer>
        <SimpleSelect
          id="itemType"
          options={damageClassificationOptions}
          placeholder={formik.values.itemType ? formik.values.itemType : 'Selecione'}
          label="Classifique o dano *"
          labelClassName="label"
          formik={formik}
        />
        <SimpleSelect
          id="itemName"
          options={getDamageClassificationOptions()}
          placeholder={formik.values.itemName ? formik.values.itemName : 'Selecione'}
          label={getDamageClassificationLabels()}
          labelClassName="label"
          formik={formik}
        />
        <TextField
          id="other"
          labelClassName="label"
          label={formik.values.itemName !== 'Outro' ? 'Outro' : 'Outro *'}
          placeholder="Digite aqui"
          disabled={formik.values.itemName !== 'Outro'}
          formik={formik}
          value={formik.values.other}
        />
        <NumberInput
          id="quantity"
          onValueChange={setQuantity}
          value={quantity}
          label="Quantidade *"
          labelClassName="label"
        />
        <SimpleSelect
          id="resolution"
          options={needToDoneOptions}
          placeholder={formik.values.resolution ? formik.values.resolution : 'Selecione'}
          label="O que precisa ser feito? *"
          labelClassName="label"
          formik={formik}
        />
        <UploadInput
          label="Orçamento/nota fiscal"
          labelClassName="label"
          setUploadedFile={setQuotationFile}
          uploadedFile={quotationFile}
        />
        <TextField
          id="quotationLink"
          labelClassName="label"
          label="Link do orçamento"
          placeholder="https://www.exemplo.com.br"
          formik={formik}
          value={formik.values.quotationLink}
        />
        <div>
          <TextField
            id="itemPrice"
            labelClassName="label"
            label="Valor unitário"
            mask="money"
            placeholder={formik.values.itemPrice}
            formik={formik}
            value={formik.values.itemPrice}
          />
          <p
            style={{
              marginTop: '0.5rem',
              fontSize: '1rem',
              color: '#B0BED8',
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <InfoOutlinedIcon />
            Caso seja adicionado mais de um item este valor será contabilizado conforme quantidade
            na seção de Resumo de danos
          </p>
        </div>
        <FullDiv>
          <UploadInput
            label="Evidências"
            labelClassName="label"
            setUploadedFiles={setEvidences}
            uploadedFiles={evidences}
            isMultiple
            required
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </FullDiv>
        <FullDiv>
          <TextField
            id="observation"
            labelClassName="label"
            label="Observações"
            placeholder="Digite aqui"
            type="textarea"
            formik={formik}
            value={formik.values.observation}
          />
        </FullDiv>
      </GridContainer>
      {(roles?.includes('Attendant')
        || roles?.includes('Host')
        || roles?.includes('Admin'))
        && (
        <ButtonContainer>
          <FormButton disable={errorMessage !== undefined}>
            {isEditingForm ? 'Editar Item' : 'Adicionar item'}
          </FormButton>
        </ButtonContainer>
        )}
    </ItemDamageFormContainer>
  );
};

export default ItemDamageForm;
