import React, {
  FC, useState, useCallback, memo,
} from 'react';
import { X } from 'react-feather';
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ReservationForm from '../Calendar/Reservation/Modal';
import LateralModal from '../LateralModal';
import ReserveLogo from '../../assets/icons/multicalendar/create-reserve.svg';
import BlockLogo from '../../assets/icons/multicalendar/create-block.svg';
import BudgetLogo from '../../assets/icons/multicalendar/create-budget.svg';
import EditPricesLogo from '../../assets/icons/multicalendar/edit-price.svg';

import {
  ModalBox,
  Title,
  TitleContainer,
  RoundedDialog,
  OptionsContainer,
  TextOptionContainer,
  Description,
  LogoContainer,
  CloseButtonContainer,
} from './styles';

import { useReservationForm } from '../../hooks/ReservationHook/useReservationForm';
import { useModal } from '../../hooks/ModalHook/useModal';
import { useUser } from '../../context/UserContext';
import ModalEstimate from '../Calendar/BudgetModal';

const datas = [
  {
    id: 0,
    title: 'Criar reserva',
    description:
      'Você precisa saber as informações do hóspede e escolher uma acomodação para completar o processo',
    imgSrc: ReserveLogo,
  },
  {
    id: 1,
    title: 'Criar bloqueio',
    description:
      'Impede que a acomodação receba reservas para o período selecionado.',
    imgSrc: BlockLogo,
  },
  {
    id: 2,
    title: 'Editar preços e regras de calendário',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: EditPricesLogo,
  },
  {
    id: 3,
    title: 'Gerar orçamento',
    description: 'Calcule o preço total para o período selecionado.',
    imgSrc: BudgetLogo,
  },
];

interface PropsClose {
  onClose?: any;
  open?: boolean;
}

const Transition = React.forwardRef((props:
| (TransitionProps & {
  children?: React.ReactElement<any, any>;
})
| any,
ref: React.Ref<unknown>) => <Slide direction="up" ref={ref} {...props} />);

const ReservationModal: FC<PropsClose> = () => {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  const { handleResetSelection, data } = useReservationForm();
  const [openLateralModal, setOpenLateralModal] = useState(false);
  const [openModalEstimate, setOpenModalEstimate] = useState(false);
  const [isReservation, setIsReservation] = useState(true);
  const { open, handleOpen } = useModal();

  const noAttendentDataJSON = datas.filter((item) => item.id !== 3);
  const attendentDataJSON = datas;

  const handleClose = useCallback(() => {
    handleOpen(false);
    handleResetSelection();
  }, [handleOpen, handleResetSelection]);

  const handleCloseAll = () => {
    handleClose();
    setOpenLateralModal(false);
  };

  const handleModal = (index: number) => {
    if (roles.includes('Host')) {
      setIsReservation(false);
      setOpenLateralModal(!openLateralModal);
    } else if (index === 0) {
      setIsReservation(true);
      setOpenLateralModal(!openLateralModal);
    } else if (index === 1) {
      setIsReservation(false);
      setOpenLateralModal(!openLateralModal);
    } else if (index === 3) {
      setOpenModalEstimate(true);
    }
  };

  interface ILoopOptionsToShow {
    customData?: any;
    customPosition?: number;
  }
  const LoopOptionsToShow = ({
    customData = undefined,
    customPosition = undefined,
  }: ILoopOptionsToShow) => {
    if (customData) {
      return (
        <>
          {customData
            .map((values: any, index: any) => (
              <OptionsContainer
                key={String(index)}
                onClick={() => handleModal(index)}
              >
                <LogoContainer>
                  <img src={values?.imgSrc} alt={values?.title} />
                </LogoContainer>
                <TextOptionContainer onClick={() => handleOpen(false)}>
                  <Title>{values?.title}</Title>
                  <Description>{values?.description}</Description>
                </TextOptionContainer>
              </OptionsContainer>
            ))
            .filter((_: any, index: any) => data
              ?.properties.length === 1 || (index !== 0 && index !== 3))}
        </>
      );
    }

    if (customPosition) {
      return (
        <OptionsContainer onClick={() => handleModal(customPosition)}>
          <LogoContainer>
            <img src={datas[customPosition].imgSrc} alt={datas[customPosition].title} />
          </LogoContainer>
          <TextOptionContainer onClick={() => handleOpen(false)}>
            <Title>{datas[customPosition].title}</Title>
            <Description>{datas[customPosition].description}</Description>
          </TextOptionContainer>
        </OptionsContainer>
      );
    }

    return null;
  };

  const ValidateOptionsToShow = () => {
    enum ENUMType {
      host = 'Host',
      default = 'Default',
      attendent = 'Attendant',
    }

    type IVerifyUser = () => ENUMType;
    const verifyUser: IVerifyUser = () => {
      if (roles.includes(ENUMType.host)) {
        return ENUMType.host;
      }
      if (roles.includes(ENUMType.attendent)) {
        return ENUMType.attendent;
      }
      return ENUMType.default;
    };

    const userRole = verifyUser();

    switch (userRole) {
      case ENUMType.host:
        return <LoopOptionsToShow customPosition={1} />;
      case ENUMType.attendent:
        return <LoopOptionsToShow customData={attendentDataJSON} />;
      default:
        return <LoopOptionsToShow customData={noAttendentDataJSON} />;
    }
  };

  return (
    <RoundedDialog
      TransitionComponent={Transition}
      keepMounted
      open={open}
      onClose={handleClose}
    >
      <ModalBox>
        <TitleContainer>
          <Title>O que você deseja fazer?</Title>
          <CloseButtonContainer>
            <X size={20} onClick={handleClose} />
          </CloseButtonContainer>
        </TitleContainer>
        <ValidateOptionsToShow />
      </ModalBox>

      <LateralModal
        open={openLateralModal}
        setOpen={setOpenLateralModal}
        onClose={handleResetSelection}
        headerTitle={isReservation ? 'Criar reserva' : 'Criar bloqueio'}
      >
        <ReservationForm
          onClose={handleCloseAll}
          isReservation={isReservation}
        />
      </LateralModal>

      {openModalEstimate && (
        <ModalEstimate
          openModal={openModalEstimate}
          setOpenModal={setOpenModalEstimate}
          setOpenLateralModal={setOpenLateralModal}
        />
      )}
    </RoundedDialog>
  );
};

export default memo(ReservationModal);
