import styled from 'styled-components';

import {
  MAX_SIZE_MOBILE,
  expensesFiltersWidthDesktop,
  buttonsWidthDesktop,
  inputsGap,
} from '../../utils';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  
  width: 100%;
  margin-top: 8px;
  gap: ${inputsGap}px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    justify-content: space-between;
  }

  @media (min-width: ${MAX_SIZE_MOBILE}px) { 
    .MuiOutlinedInput-root  {
      width: ${expensesFiltersWidthDesktop - buttonsWidthDesktop - inputsGap}px !important;
    }
  }
`;
