import { ITableConfig } from '../types';

const isDesktop = window.innerWidth > 1000;

export const tableConfig: ITableConfig = {
  headers: [
    {
      id: 'name',
      title: 'Nome',
      space: isDesktop ? '2fr' : '200px',
    },
    {
      id: 'personID',
      title: 'Person ID',
      space: isDesktop ? '1.1fr' : '120px',
    },
    {
      id: 'email',
      title: 'Email',
      space: isDesktop ? '2fr' : '350px',
    },
    {
      id: 'actions',
      title: 'Ações',
      space: isDesktop ? '1fr' : '100px',
    },
  ],
};

export const gridTemplateColumns = tableConfig.headers.map((header) => header.space).join(' ');
