import { useContextSelector } from 'use-context-selector';
import { InsertPropertyCategoryLocationContext } from '../../context/InsertPropertyCategoryLocationContext/InsertPropertyCategoryLocationContext';

export function usePropertyCategoryLocation() {
  const openModalCategoryLocation = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.openModalCategoryLocation);

  const setOpenModalCategoryLocation = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.setOpenModalCategoryLocation);

  const categoriesLocations = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.categoriesLocations);

  const setCategoriesLocations = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.setCategoriesLocations);

  const categories = useContextSelector(InsertPropertyCategoryLocationContext, (state) => state
    .categories);

  const setCategories = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.setCategories);

  const openModalNewCategoryCode = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.openModalNewCategoryCode);

  const setOpenModalNewCategoryCode = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.setOpenModalNewCategoryCode);

  const handleAddCategory = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.handleAddCategory);

  const locations = useContextSelector(InsertPropertyCategoryLocationContext, (state) => state
    .locations);

  const setLocations = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.setLocations);

  const openModalNewLocationCode = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.openModalNewLocationCode);

  const setOpenModalNewLocationCode = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.setOpenModalNewLocationCode);

  const handleAddLocation = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.handleAddLocation);

  const isNewCategoryLocation = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.isNewCategoryLocation);

  const setIsNewCategoryLocation = useContextSelector(InsertPropertyCategoryLocationContext,
    (state) => state.setIsNewCategoryLocation);

  return {
    openModalCategoryLocation,
    setOpenModalCategoryLocation,
    categoriesLocations,
    setCategoriesLocations,
    categories,
    setCategories,
    openModalNewCategoryCode,
    setOpenModalNewCategoryCode,
    handleAddCategory,
    locations,
    setLocations,
    openModalNewLocationCode,
    setOpenModalNewLocationCode,
    handleAddLocation,
    isNewCategoryLocation,
    setIsNewCategoryLocation,
  };
}
