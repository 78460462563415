import React, { useEffect } from 'react';

import Lottie from 'lottie-react';
import { motion } from 'framer-motion';
import InfoIcon from '@mui/icons-material/Info';
import { Divider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Container, Content } from './style';

import animationSource from '../../../../assets/animations/source/notFoundError.json';
import { localStoragePreCheckin } from '../../utils/localStorageUrl';
import { usePreCheckin } from '../../../../context/PreCheckin/PreCheckin';
import { useToast } from '../../../../context/ToastContext';

const products = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const content = {
  animate: {
    transition: { staggerChildren: 0.1 },
  },
};

const NotFoundScreenAnimation = () => (
  <Lottie animationData={animationSource} />
);

function getUrl() {
  return localStoragePreCheckin({
    action: 'get',
  });
}

function getReservation() {
  const url = getUrl();

  if (url) {
    const regex = /reservation=(\d+)/;
    const match = url.match(regex);
    if (match && match.length >= 2) {
      return match[1];
    }
    return '';
  }

  return '';
}

const NotFound = () => {
  const toast = useToast();
  const { error } = usePreCheckin();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => () => {
    navigate(location.pathname);
  }, [navigate, location]);

  const handleReloadPage = () => {
    const link = getUrl();

    if (link) {
      window.location.href = link;
    } else {
      window.location.reload();
    }
  };

  const stringError = JSON.stringify({
    try_url: getUrl(),
    reservation: getReservation(),
    error,
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(stringError).then(() => {
      toast.alert('O código de erro foi copiado para a sua área de transferência!');
    });
  };

  return (
    <motion.div initial="initial" animate="animate" variants={products}>
      <Container>
        <NotFoundScreenAnimation />
        <Content variants={content}>
          <h1>Ops !</h1>
          <div>
            <p>O token não foi reconhecido.</p>
            <p>Ou um erro aconteceu durante a requisição.</p>
          </div>
          <Alert>
            <InfoIcon
              style={{
                top: -10,
                left: -10,
                fontSize: 25,
                color: '#EF9400',
                background: 'white',
                borderRadius: '50%',
                position: 'absolute',
              }}
            />
            <h1>Certifique-se de que o link que você recebeu esta correto</h1>
          </Alert>
          <h1>Conte-nos o que aconteceu</h1>
          <p>
            Por favor, pressione o botão abaixo para copiar o código associado
            ao erro que você encontrou.
            <br />
            <br />
            Em seguida, envie-o para nossa equipe de atendimento ao cliente.
            Isso nos permitirá identificar e resolver o problema de maneira
            eficiente e rápida. Agradecemos sua colaboração!
          </p>
          <button type="button" onClick={() => copyToClipboard()}>
            Copiar código de erro
          </button>

          <Divider />

          <p>Ou clique no botão para tentar novamente</p>
          <button
            className="outline"
            type="button"
            onClick={() => handleReloadPage()}
          >
            Tentar novamente
          </button>
        </Content>
      </Container>
    </motion.div>
  );
};

export default NotFound;
