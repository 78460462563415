import styled, { DefaultTheme, css, keyframes } from 'styled-components';
import { InfoOutlined } from '@mui/icons-material';
import { UrlLink } from '../../../services/types';
import defaultImage from '../../../assets/icons/generals/property-not-found.svg';
import { ReservationStatus } from '../../../services/Reservation/types';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const getBackgroundStatus = (
  theme: DefaultTheme,
  status: ReservationStatus,
  blocked: boolean = false,
): string => {
  const color = {
    Concluded: theme.palette.green.main.hex(),
    Active: theme.palette.green.main.hex(),
    'In progress': theme.palette.green.main.hex(),
    'Pre-booking': theme.palette.yellow.main.hex(),
    Canceled: theme.palette.grey._550.hex(),
  };

  if (blocked) {
    return theme.palette.red._200.hex();
  }

  return color[status];
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const ContainerOverflow = styled.div`
  overflow-y: auto;
  padding: 16px;
`;

export const Chip = styled.div`
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  background: rgb(65, 181, 146);
  color: rgb(243, 246, 249);
`;

export const Wrapper = styled.div<{ $flexDireciton?: string, $alignItems?: string, $justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  
  ${({ $flexDireciton }) => $flexDireciton
    && css`
      flex-direction: ${$flexDireciton};
    `}

  ${({ $alignItems }) => $alignItems
    && css`
      align-items: ${$alignItems};
    `}

  ${({ $justifyContent }) => $justifyContent
    && css`
      justify-content: ${$justifyContent};
    `}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const PropertyImage = styled.div<{ backgroundImage?: UrlLink }>`
  display: flex;
  justify-content: flex-end;
  min-width: 52px;
  width: 52px;
  height: 44px;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : `url(${defaultImage})`)};
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  position: relative;

  &:after {
    content: '';
    left: 45px;
    top: -7px;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
    background-color: ${({ theme }) => theme.palette.green.main.hex()};
    border-radius: 50%;
    position: absolute;
    width: 15px;
    height: 15px;
  }
`;

export const SmallText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1},
    ${({ theme }) => theme.fonts.familys._2};
`;

export const LastSmallText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1},
    ${({ theme }) => theme.fonts.familys._2};
  margin-left: 5px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const InfoIcon = styled(InfoOutlined)`
  color: ${({ theme }) => theme.palette.blue._850.hex()};
  cursor: pointer;
  transition: all 400ms;

  &:hover {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid rgb(227, 231, 235);
  margin: 10px 0;
`;

export const WrapperLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  :not(last-child) {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 10px;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 1.5rem;
`;

export const CopyButton = styled.button`
  background: linear-gradient(
    360deg,
    ${({ theme }) => theme.palette.blue._800.hex()} 0%,
    ${({ theme }) => theme.palette.blue._850.hex()} 100%
  );
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-box-shadow: 1px 3px 12px 2px rgba(0, 0, 0, 0.35);
  box-shadow: 1px 3px 12px 2px rgba(0, 0, 0, 0.35);
  img {
    margin-right: 0.8rem;
  }
  &:hover {
    opacity: 0.85;
  }
`;

export const NotesContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  padding: 1rem 0;
  margin: 1rem 0;
  line-height: 2rem;
  > p:first-child {
    line-height: 3rem;
  }
`;

export const BarLabel = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 20px !important;
  font-family: ${({ theme }) => theme.fonts.familys._1},
    ${({ theme }) => theme.fonts.familys._2};
  font-weight: 700;
  border-bottom: 2px solid ${({ theme }) => theme.palette.grey._420.hex()};
`;

export const SmallTextNote = styled.textarea`
  border: none;
  padding-right: 2rem;
  width: 100%;
  resize: none;
  text-align: justify;
  background: transparent;
  &::-webkit-scrollbar {
    border-radius: 20px;
    height: 10px;
    width: 7px;
    border: 1px solid ${({ theme }) => theme.palette.white._600.hex()};
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.white._600.hex()};
    width: 5px;
    border-radius: 100px;
    transform: scale(0.3);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.orange._600.hex()};
  }
`;

export const SmallTextBold = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};

  &.notes {
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
  }
`;

export const Space = styled.div`
  height: 20px;
`;

export const FinanceContent = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  border-radius: 0.8rem;
  margin: 0.5rem 0;
  padding: 1rem;
  line-height: 2.5rem;

  > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};

  button {
    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.palette.blue._850.hex()};
    font-weight: bold;
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 0.3rem;
  }
  p {
    font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
  }

  @media (max-width: 900px) {
    width: 135px;
    img {
      width: 15px;
    }
    p {
      font-size: 12px;
      font-weight: ${({ theme }) => theme.fonts.weights.regular};
    }
  }
`;

export const MediumText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.regular}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1},
    ${({ theme }) => theme.fonts.familys._2};
`;

export const MediumTextBold = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const ButtonStyled = styled.button<{
  reservationStatus: ReservationStatus;
  blocked?: boolean;
}>`
  width: 100%;
  background: ${({ theme, reservationStatus, blocked }) => getBackgroundStatus(
    theme, reservationStatus, blocked,
  )};
  color: ${({ theme }) => theme.palette.white._400.hex()};
  border: 0;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 20vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:  ${({ theme }) => theme.palette.white._400.hex()};
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};


  button, svg{
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 900px){
      margin: 0;
      margin-right: 3px;
    }
  }
  @media (max-width: 320px) {
    padding-right: 2.5rem;
  }
`;

export const Menu = styled.div<{
  right?: string, left?: string, top?: string, bottom?: string, width?: string
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 150px;
  /* height: 160px; */
  position: absolute;
  right: 0;
  bottom: 20px;
  background-color: #FFF;
  padding: 16px;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.3s ease-in;
  
   ${({ right }) => right
    && css`
        right: ${right}
    `}
   ${({ left }) => left
    && css`
        left: ${left}
    `}
   ${({ top }) => top
    && css`
        top: ${top}
    `}
   ${({ bottom }) => bottom
    && css`
        bottom: ${bottom}
    `}
   ${({ bottom }) => bottom
    && css`
        bottom: ${bottom}
    `}

   ${({ width }) => width
    && css`
        width: ${width}
    `}
`;

export const InputLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  input[type="radio"]{
    background-color: transparent;
    border: 1px solid blue !important;
  } 
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;
