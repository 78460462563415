import styled from 'styled-components';

export const TitlePage = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  `;

export const Container = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;
