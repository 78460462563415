import styled from 'styled-components';

// const GRID_WIDTH = '1.2fr 0.8fr 2.2fr 0.2fr';
const GRID_WIDTH = '35% 25% 40%';

export const ContainerArrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  img {
    padding: 0.05rem 0 0.05rem 0.5rem;
    cursor: pointer;
  }
`;

export const Image = styled.img``;

export const InfoOutlinedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 8px 0 4px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 24px;
  
  @media(max-width: 900px) {
    margin-top: 8px;
    height: fit-content;
  }
`;

export const GridContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  
  @media(max-width: 900px) {
    min-height: 150px;
  }
`;

export const GridHeaderContainer = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: ${GRID_WIDTH};
  background: ${({ theme }) => theme.palette.white.main.hex()};
`;

export const ContentInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 50px;
  width: 100%;

  p {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 400;
    font-size: 0.985rem;
    line-height: 15px;
  }

  @media(max-width: 900px) {
    padding: 8px;
    height: 25px;
  }
`;

export const GridHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    width: fit-content;
  }
  
  div {
    width: fit-content;
  }
`;

export const GridHeaderTitle = styled.span<{ hide: string }>`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  text-align: start;

  visibility: ${({ hide }) => ['Ação'].includes(hide) ? 'hidden' : 'visible'};
`;

export const GridBodyContainer = styled.div`
  width: 100%;
`;

export const GridBodyContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  cursor: pointer;
`;

export const GridBodyRow = styled.div`
  width: calc(100% - 16px);
  height: 50px;
  display: grid;
  grid-template-columns: ${GRID_WIDTH};
  cursor: pointer;
  :hover {
    background: #CCE4FF;
  }
`;


export const GridBodyTitle = styled.span`
  display: flex;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Plus Jakarta Sans', 'Inter';
  color: #161616;
  hyphens: auto;
  -webkit-hyphens: auto;
  max-width: 340px;
  text-align: start;

  @media(max-width: 900px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;

  :hover {
    background: #CCE4FF;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  background: #9fd;

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.grey._320.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border-left: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
    border-right: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
  }
`;

export const CardContentLeft = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
`;

export const CardContentRight = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 0;
`;

export const FormButtonContainer = styled.div`
  button {
    margin-top: 8px;
    width: 78%;
    gap: 10px;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #CCE4FF;
  width: 70px;
  height: fit-content;
  gap: 2px;
  padding: 2px 0;
  margin-left: 8px;
`;
