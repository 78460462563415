import React from 'react';
import { usePartners } from '../../../../../../context/Partners/Partner/PartnerContext';
import { numberToCurrency } from '../../../../../../utils/Formatter';
import { PartnersInlineLink } from '../../../../components';
import { partnersRoutes } from '../../../../types/paths';

import {
  TitleCards,
  HeaderCards,
  CardContainerTemplate,
} from '../utils';

import {
  Money,
  Content,
  Container,
  EyeButton,
  HideMoney,
  MoneyContainer,
  HideBalanceContainer,
} from './style';

import {
  OpenEye,
  CloseEye,
} from './svg';

const ShowBalanceText = ({ children }: { children: React.ReactNode }) => {
  const isMinWidth: boolean = window.innerWidth > 320;
  if (!isMinWidth) {
    return null;
  }
  return <p>{children}</p>;
};

const ControlBalance = ({ show }: { show: boolean }) => (
  <HideBalanceContainer>
    {show ? <OpenEye /> : <CloseEye />}
    {show ? (
      <ShowBalanceText>Ocultar saldo</ShowBalanceText>
    ) : (
      <ShowBalanceText>Mostrar saldo</ShowBalanceText>
    )}
  </HideBalanceContainer>
);

const Balance = () => {
  const [hideBalance, setHideBalance] = React.useState(true);

  const { resume } = usePartners();

  const calculateTotal = () => {
    if (resume?.total_balance) {
      return numberToCurrency(resume?.total_balance.balance);
    }
    return numberToCurrency(0);
  };

  const earningLink = `/${partnersRoutes.partners.main}/${partnersRoutes.earnings.main}`;

  return (
    <CardContainerTemplate>
      <Container>
        <HeaderCards>
          <TitleCards>Seu saldo com a Seazone</TitleCards>
          <PartnersInlineLink to={earningLink}>
            Detalhes
          </PartnersInlineLink>
        </HeaderCards>
        <Content>
          <EyeButton type="button" onClick={() => setHideBalance(!hideBalance)}>
            <ControlBalance show={!hideBalance} />
          </EyeButton>

          <MoneyContainer>
            {hideBalance ? (
              <HideMoney
                initial={{
                  width: 0,
                  opacity: 0,
                }}
                animate={{
                  width: '85%',
                  opacity: 1,
                }}
              />
            ) : (
              <Money
                initial={{
                  y: 10,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
              >
                {calculateTotal()}
              </Money>
            )}
          </MoneyContainer>
        </Content>
      </Container>
    </CardContainerTemplate>
  );
};
export default Balance;
