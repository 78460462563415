import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.white._300.hex()};
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  width: 85%;
  height: 100%;
  display: grid;
  grid-template-rows: 80% 20%;
  padding: 0 16px;
  
  @media(max-width: 900px) {
    width: 80%;
    grid-template-rows: 65% 15%;
    padding: 0 2px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border-bottom: 2px solid ${({theme}) => theme.palette.grey._425.hex()};
  background: ${({theme}) => theme.palette.white.main.hex()};
`;

export const Title = styled.h1`
  font-family: 'Quicksand';
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 95%;
  height: 20%;
  margin-top: 50px;
  margin-left: 30px;
  button {
    max-width: 100px;
    max-height: 35px;
  }
`;

export const PhasesCard = styled.div`
  width: 25%;
  height: 100%;
  background: ${({theme}) => theme.palette.white.main.hex()};
  
  @media(max-width: 900px) {
    width: 25%;
  }
`;
