export enum EnumRoutes {
  whitePage = 'w',

  panel = 'painel',
  earnings = 'ganhos',
  indicate = 'indicar',
  partners = 'parceiros',
  portfolio = 'portfolio',

  indications = 'indicacoes/todos',
  indicateForm = 'indicar/formulario',
}

export type IType = 'spot' | 'locacao' | 'terreno';

export enum EnumType {
  terrain = 'terreno',
  location = 'locacao',
  spot = 'spot',
}

export type IParntersRoutes = typeof partnersRoutes;

export const partnersRoutes = {
  panel: {
    main: 'painel',
    name: 'Painel',
  },

  welcome: {
    main: `${EnumRoutes.whitePage}/intro`,
    name: 'Bem vindo(a)',
  },

  withdraw: {
    main: 'saque',
    name: 'Saque',
  },

  earnings: {
    main: 'ganhos',
    name: 'Ganhos',
    rescue: {
      main: `${EnumRoutes.whitePage}/resgate`,
      name: 'Resgatar',
    },
  },

  questions: {
    main: 'duvidas',
    name: 'Dúvidas',
  },

  partners: {
    main: 'parceiros',
    name: 'Parceiros',
  },

  whitePage: { main: EnumRoutes.whitePage },

  portfolio: {
    main: 'portfolio',
    name: 'Portfolio',
  },

  indications: {
    main: 'indicacoes',
    name: 'Indicações',
    filter: {
      all: 'todos',
      terrain: EnumType.terrain,
      location: EnumType.location,
    },
  },

  indicate: {
    main: 'indicar',
    name: 'Indicar',
    form: {
      main: 'formulario',
      terrain: EnumType.terrain,
      location: EnumType.location,
      spot: EnumType.spot,
    },
  },
};

export function validateWhitePage(url: string) {
  const padrao = new RegExp(`^/${partnersRoutes.partners.main}/${EnumRoutes.whitePage}/[\\w-]+$`);
  return padrao.test(url);
}
