import {
  CheckboxContainer, HiddenCheckBox, Icon, StyledCheckBox, StyledDivLabel,
} from './styles';

interface CheckboxProps {
  id?: string;
  dataCy?: string;
  className?: string;
  classNameLabel?: string;
  checked: boolean;
  onClick: () => void;
  labelName?: string;
  htmlForName?: string;
  size?: string;
}

export default function Checkbox({
  id = '',
  dataCy,
  className,
  classNameLabel = 'StyledDivLabel',
  checked,
  labelName,
  htmlForName,
  size = '',
  onClick,
  ...props
}: CheckboxProps) {
  return (
    <CheckboxContainer className={className} onClick={onClick}>
      <HiddenCheckBox data-cy={dataCy} checked={checked} {...props} />
      <StyledCheckBox id={id} size={size} checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckBox>
      <StyledDivLabel data-testid="checkbox-label" className={classNameLabel} size={size}>{labelName}</StyledDivLabel>
    </CheckboxContainer>
  );
}

Checkbox.defaultProps = {
  id: undefined,
  dataCy: undefined,
  className: undefined,
  classNameLabel: undefined,
  labelName: undefined,
  htmlForName: undefined,
  size: undefined,
};
