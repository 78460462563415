import React from 'react';

import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';

import {
  Container,
  Line,
} from './styles';

interface IProps {
  isOpen: boolean;
  changeVisible: () => void;
  title: string;
}

const Header = ({ isOpen, changeVisible, title }: IProps) => (
  <Container onClick={changeVisible} isOpen={isOpen}>
    <h1>{title}</h1>
    <Line />
    <button onClick={changeVisible} type="button">
      <img src={arrowBottom} alt="seta para mostrar ou ocultar componentes" />
    </button>
  </Container>
);

export default Header;
