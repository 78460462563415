import {
  RefundDamageHeaderContentContainer,
  RefundDamageHeaderContainer,
  RefundDamageHeaderItem,
  Title,
  ButtonContainer,
  RefundDamageHeaderColumnItem,
} from './styles';

import FormButton from '../../../../FormButton/FormButton';
import { useGuestDamageStep } from '../../../../../hooks/GuestDamage/useGuestDamageStep';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { useUser } from '../../../../../context/UserContext';

export const RefundDamageHeader = () => {
  const { handleInsertRefundDamageStep } = useGuestDamageStep();
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const { userInformation } = useUser();
  const roles = userInformation?.roles;
  return (
    <RefundDamageHeaderContainer>
      <Title>Validação do recebimento</Title>
      <RefundDamageHeaderContentContainer>
        <div style={{
          display: 'flex', justifyContent: 'center', gap: '1rem', flexDirection: 'column',
        }}
        >
          <RefundDamageHeaderItem>
            <p>
              <span>Valor Total do Dano:</span>
              {' '}
              {numberToCurrency(selectedGuestDamageNegotiation.amount_to_receive) || '-'}
            </p>
          </RefundDamageHeaderItem>
          <RefundDamageHeaderItem>
            <p>
              <span>Diferença:</span>
              {' '}
              {numberToCurrency(selectedGuestDamageNegotiation.amount_difference) || '-'}
            </p>
          </RefundDamageHeaderItem>
          <RefundDamageHeaderItem>
            <p>
              <span>Valor Recebido:</span>
              {' '}
              {numberToCurrency(selectedGuestDamageNegotiation.amount_received) || '-'}
            </p>
          </RefundDamageHeaderItem>
        </div>
        <div style={{
          display: 'flex', gap: '1rem', flexDirection: 'column',
        }}
        >
          <RefundDamageHeaderColumnItem>
            <h3>Transferência recebida?</h3>
            <p>{selectedGuestDamageNegotiation.is_receiving_confirmed ? 'Sim' : 'Não'}</p>
          </RefundDamageHeaderColumnItem>
        </div>
      </RefundDamageHeaderContentContainer>
      {(roles?.includes('SeazoneAdministrative')
        || roles?.includes('Admin'))
        && (
        <ButtonContainer>
          <FormButton type="button" onClick={handleInsertRefundDamageStep}>Editar</FormButton>
        </ButtonContainer>
        )}
    </RefundDamageHeaderContainer>
  );
};
