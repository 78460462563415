import * as Yup from 'yup';
import { IPartnersResume } from '../../../../../../../types';
import { currencyToNumber, numberToCurrency } from '../../../../../../../../../utils/Formatter';

export type IValidate = {
  resume: IPartnersResume | undefined;
};

export const validation = ({ resume }: IValidate) => {
  const value = resume?.total_balance.balance || 0;
  const balance = {
    total: value || 0,
    formated: numberToCurrency(value || 0),
  };

  return Yup.object().shape({
    wantToReceiveByPix: Yup.string().required('Campo obrigatório'),
    cpfOrCnpj: Yup.string().required('O seu CPF ou CNPJ é obrigatório'),
    // nameOfPartner: resume?.partner.user.first_name
    //   ? Yup.string() : Yup.string().required('O nome do parceiro é obrigatório'),
    // email: resume?.partner.user.first_name ? Yup.string() : Yup.string()
    //   .email('Insira um email válido')
    //   .required('Email é obrigatório'),

    favoredName: Yup.string().required('O nome do favorecido é obrigatório'),

    rescueValue: Yup.string()
      .required('O valor do resgate é obrigatório')
      .test(
        'rescueValue', `O valor do resgate não pode ser maior que o seu saldo total: ${balance.formated}`, (rescueValue) => {
          if (rescueValue) {
            const valueNumber = currencyToNumber(rescueValue);

            if (valueNumber > balance.total) {
              return false;
            }
          }

          return true;
        },
      ),

    emailNF: Yup.string().when('cpfOrCnpj', {
      is: 'cnpj',
      then: Yup.string().required('Como um CNPJ é obrigatório responder esse campo'),
    }),

    bankId: Yup.string().when('wantToReceiveByPix', {
      is: 'yes',
      then: Yup.string().required('É necessário escolher um banco relacionado a chave PIX'),
    }),

    bankAccountType: Yup.string()
      .when('wantToReceiveByPix', {
        is: 'no',
        then: Yup.string().required('O tipo da conta é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX'),
      })
      .notOneOf(['Selecione'],
        'O tipo da conta é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX"'),

    bankAccountNumber: Yup.string()
      .when('wantToReceiveByPix', {
        is: 'no',
        then: Yup.string().required('O número da conta é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX'),
      }),

    bankAgency: Yup.string()
      .when('wantToReceiveByPix', {
        is: 'no',
        then: Yup.string().required('A agência é obrigatória, pois você selecionou que não deseja receber a transferencia via PIX'),
      }),

    cpfOrCnpjValue: Yup.string().when('cpfOrCnpj', {
      is: 'cpf',
      then: Yup.string().required('O seu CPF é obrigatório'),
      otherwise: Yup.string().required('O seu CNPJ é obrigatório'),
    }),

    pixKey: Yup.string().when('wantToReceiveByPix', {
      is: 'yes',
      then: Yup.string().required('A chave pix é obrigatória, pois você selecionou que deseja receber a transferencia via PIX'),
    }),

    typePixKey: Yup.string().when('wantToReceiveByPix', {
      is: 'yes',
      then: Yup.string()
        .required('Campo obrigatório')
        .notOneOf(['Não tenho chave'],
          'Você selecionou que deseja receber a transferencia via PIX, o tipo da chave precisa ser diferente de "Não tenho chave"'),
    }),
  });
};
