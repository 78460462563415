import styled, { css, keyframes, DefaultTheme } from 'styled-components';

import {
  ReservationStatus,
  PaymentStatus,
} from '../../services/Reservation/types';

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

interface MarkerProps {
  size: number;
  isWeeklyCalendarMarker?: boolean;
  isInitMarker?: boolean;
  sizeOriginal?: boolean;
  widthMarker?: number;
  isEndMarker?: boolean;
  shiftRight?: number;
  reservationStatus: ReservationStatus;
  blocked: boolean;
  isShowingCanceledReservations?: boolean;
  extension?: boolean;
  reason: boolean;
  isShowingMarker?: boolean;
  mobile?: boolean;
  isBookingOrBlockAutomatic?: boolean,
}

interface MarkerIconProps {
  reservationStatus: ReservationStatus;
  paymentStatus: PaymentStatus;
  isShowingStatus?: boolean;
  showIcon?: boolean;
  isBookingOrBlockAutomatic?: boolean,
  mobile?: boolean;
}

const getBackgroundStatus = (
  theme: DefaultTheme,
  status: ReservationStatus,
  blocked: boolean = false,
  extension: boolean = false,
  reason: boolean,
  isBookingOrBlockAutomatic?: boolean,
): string => {
  const color = {
    Concluded: theme.palette.green.main.hex(),
    Active: theme.palette.green.main.hex(),
    'In progress': theme.palette.green.main.hex(),
    'Pre-booking': theme.palette.yellow.main.hex(),
    Canceled: theme.palette.grey._550.hex(),
  };

  if (isBookingOrBlockAutomatic) {
    return theme.palette.blue._20.hex();
  }

  if (blocked) {
    return theme.palette.red._200.hex();
  }
  if (reason && status === 'Canceled') {
    return theme.palette.grey._550.hex();
  }
  if (extension) {
    return theme.palette.green._250.hex();
  }

  return color[status];
};

const getBackgroundPayment = (
  theme: DefaultTheme,
  paymentStatus: PaymentStatus,
  isBookingOrBlockAutomatic?: boolean,
): string => {
  const color = {
    fullyPaid: theme.palette.green.main.hex(),
    partiallyPaid: theme.palette.yellow.main.hex(),
    unpaid: theme.palette.red._200.hex(),
    canceled: theme.palette.grey._550.hex(),
    extension: theme.palette.green._250.hex(),
  };

  return isBookingOrBlockAutomatic ? theme.palette.blue._20.hex() : color[paymentStatus];
};

const getZIndex = (isInitMarker: boolean = false,
  status: ReservationStatus) => {
  if (status === 'Concluded') {
    // nota: essa logica visa forçar que reservas com status "Concluded" sempre
    // sobreponham reservas com outros status
    return isInitMarker ? '122' : '121';
  }

  return isInitMarker ? '121' : '120';
};

export const Marker = styled.button<MarkerProps>`
  animation-delay: 0.4s;
  animation-name: ${FadeInOpacity};
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;

  && {
    font-family: "Quicksand", sans-serif;
    cursor: pointer;
    display: ${(props) => (!props.isShowingMarker
      || (!props.isShowingCanceledReservations
        && props.reservationStatus === 'Canceled')
    ? 'none'
    : 'flex')};
    position: absolute;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-top-left-radius: ${({ isInitMarker }) => (isInitMarker ? '20px' : '0')};
    border-bottom-left-radius: ${({ isInitMarker }) => (isInitMarker ? '20px' : '0')};
    border-top-right-radius: ${({ isEndMarker }) => (isEndMarker ? '20px' : '0')};
    border-bottom-right-radius: ${({ isEndMarker }) => (isEndMarker ? '20px' : '0')};
    color: ${({ theme }) => theme.palette.grey._900.hex()};
    left: ${({ isInitMarker }) => (isInitMarker ? 90 : 5)}px;
    margin-left: calc(-50%);
    z-index: ${({ isInitMarker, reservationStatus }) => getZIndex(isInitMarker, reservationStatus)};
    transition: all 0.3s ease-in-out;
    height: 48px;
    width: ${({ size, widthMarker }) => size && `calc((${widthMarker}px * ${size - 1}) + (1px * ${size - 1}))`};
    background-color: ${({
    theme,
    reservationStatus,
    blocked,
    extension,
    reason,
    isBookingOrBlockAutomatic,
  }) => getBackgroundStatus(
    theme,
    reservationStatus,
    blocked,
    extension,
    reason,
    isBookingOrBlockAutomatic,
  )};
    &:hover {
      opacity: 75%;
    }
  }
`;

export const MarkerContainerIcon = styled.div<MarkerIconProps>`
  && {
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border-radius: 50%;
    padding:${({ mobile }) => (mobile ? 0.4 : 10)}pix;
    display: ${({ showIcon }) => (showIcon ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 2px -1px ${({ theme }) => theme.palette.blue._950.hex()};
    transform: translateX(${({ mobile }) => (mobile ? 0.4 : 20)}pix);
    margin-right: -11px;
    img,
    svg.lockLogo {
      width: ${({ mobile }) => (mobile ? 0.4 : 1)}rem;
      height: ${({ mobile }) => (mobile ? 0.4 : 1)}rem;
      margin: 3px;
    }
    svg.lockLogo {
      color: ${(props) => (props.reservationStatus === 'Canceled'
    ? ({ theme }) => theme.palette.grey._720.hex()
    : ({ theme }) => theme.palette.orange.main.hex())};
    }
    ${(props) => props.reservationStatus === 'Canceled'
      && css`
        background-color: ${({ theme }) => theme.palette.grey._400.hex()};
        img {
          filter: grayscale(1);
        }
      `}
  }
`;

export const MarkerContainer = styled.div<MarkerProps>`
  && {
    display: ${({ isInitMarker }) => (isInitMarker ? 'flex' : 'none')};
    flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
    justify-content: ${({ mobile }) => (mobile ? 'center' : 'space-between')};
    align-items: ${({ mobile }) => (mobile ? 'flex-start' : 'center')};
    width: ${({ size, widthMarker }) => size && `calc((${widthMarker}px * ${size - 1}) + (1px * ${size - 1}))`};
    height: 100%;
    position: absolute;
    margin-left: ${({ mobile }) => (mobile ? 1 : 1.5)}rem;
    
    ${(props) => {
    if (props.mobile) {
      return css`
          margin-left: 1rem;
          padding-top: 0.15rem;
        `;
    } if (props.size >= 3) {
      return css`
        margin-left: 2rem;
      `;
    }
    return css`
        margin-left: 1.5rem;
     `;
  }
}
    .wrapper-name, .totalPrice, .status {
      max-width: 100%;
    }

    .name, .name > span {
      display: ${({ isInitMarker }) => (isInitMarker ? 'flex' : 'none')};
      flex-direction: column;
      color: ${({ theme }) => theme.palette.white.main.hex()};
      font-family: "Quicksand", sans-serif;
      font-weight: 800;
      font-size: ${({ mobile }) => (mobile ? 0.6 : 0.875)}rem;
      text-align: start;
      align-self: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${(props) => props.reservationStatus === 'Canceled'
        && css`
          text-decoration: line-through;
        `}
    }
    .totalPrice {
      display: ${({ size, isInitMarker }) => (size >= 3 && isInitMarker ? 'flex' : 'none')};
      justify-content: flex-start;
      padding-left: ${({ size, mobile }) => (size >= 3 && !mobile ? 0.8 : 0.2)}rem;
      align-items: center;
      color: ${({ theme }) => theme.palette.white.main.hex()};
      width: 100%;
      height: auto;
      font-weight: 700;
      font-family: "Quicksand", sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-wrap: wrap;

      .currency {
        font-weight: 500;
        font-size: ${({ size, mobile }) => (size >= 3 && !mobile ? 1 : 0.5)}rem;
        margin: 0.5px 3px 0 0;
      }
      .price {
        font-weight: 700;
        font-size: ${({ size, mobile }) => (size >= 3 && !mobile ? 1.2 : 0.7)}rem;
      }
    }
    ${(props) => typeof props.size === 'number'
      && props.size < 3
      && css`
        flex-wrap: wrap;
        .wrapper-name1,
        .totalPrice,
        .status {
          width: 100%;
          margin: 0;
          padding: 0;
        }
        .name, .name > span {
          font-size: ${(props.mobile ? 0.6 : 1)}rem;
        }
        .totalPrice {
          .currency {
            font-size: ${props.mobile ? 0.5 : 0.7}rem;
          }
          .price {
            font-size: ${props.mobile ? 0.7 : 0.9}rem;
          }
        }
      `}
  }

  @media (max-width: 795px) {
    height: 25px;
    bottom: 0.1rem;
    align-items: center;
    width: 200px;
  }
`;

export const Indicators = styled.div<{ showIndicators?: boolean, mobile?: boolean }>`
  && {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-weight: 500;
    font-size:  ${({ mobile }) => (!mobile ? 12 : 10)}px;
    display: ${({ showIndicators }) => (showIndicators ? 'flex' : 'none')};
    align-self: center;
    justify-self: self-start;
    padding-top: 1px;

    .adultIcon,
    .childIcon,
    .dailyValueIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
    }
    svg {
      vertical-align: center;
      height: 15px;
      width: 15px;
    }
    .childIcon {
      svg {
        height: 11px;
        width: 11px;
      }
    }
    span {
      color: ${({ theme }) => theme.palette.white.main.hex()};
      font-family: "Quicksand", sans-serif;
      font-size: 12px;
      margin-left: 2px;
      font-weight: 900;
    }
    .petsIcon {
      height: 13px;
      width: 13px;
      margin-right: 6px;
    }
  }
`;

export const ReservationIcon = styled.div<{ showIcon?: boolean }>`
  display: ${({ showIcon }) => (showIcon ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  .reservationIcon {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    opacity: 50%;
    margin-right: 3rem;
  }
`;

export const IconStatus = styled.div<MarkerIconProps>`
  && {
    display: ${({ isShowingStatus }) => (isShowingStatus ? 'flex' : 'none')};
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    right: 0;
    top: -7px;
    .paymentIcon {
      visibility: ${({ showIcon, isShowingStatus }) => (showIcon || isShowingStatus ? 'visible' : 'hidden')};
      height: 24px;
      width: 24px;
      color: ${({ theme }) => theme.palette.white.main.hex()};
      border-style: solid;
      border-width: 2px;
      border-radius: 50px;
      background-color: ${({ theme, paymentStatus, isBookingOrBlockAutomatic }) => getBackgroundPayment(
    theme, paymentStatus, isBookingOrBlockAutomatic,
  )};
      border-color: ${({ theme, paymentStatus, isBookingOrBlockAutomatic }) => getBackgroundPayment(
    theme, paymentStatus, isBookingOrBlockAutomatic,
  )};
      box-shadow: -0.5px 0.5px 2px -0.5px ${({ theme, paymentStatus, isBookingOrBlockAutomatic }) => getBackgroundPayment(
    theme, paymentStatus, isBookingOrBlockAutomatic,
  )};
    }
  }
`;
