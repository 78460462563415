import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import { PhotoModalContainer, PhotoModalOverlay } from './styles';

interface PhotoModalProps {
  src?: string;
  alt?: string;
}
export function PhotoModal({ src, alt }: PhotoModalProps) {
  const { handleClosePhotoModal } = useGuestDamage();

  return (
    <PhotoModalOverlay onClick={handleClosePhotoModal}>
      <PhotoModalContainer>
        <img src={src} alt={alt} />
      </PhotoModalContainer>
    </PhotoModalOverlay>
  );
}

PhotoModal.defaultProps = {
  src: '',
  alt: '',
};
