import {
  FC,
  useMemo,
  useState,
  useCallback,
  ReactElement,
} from 'react';

import { createContext } from 'use-context-selector';

export interface IGuestListReport {
  loading: boolean;
  setLoading: Function;
  startDate: Date;
  setStartDate: Function;
  endDate: Date;
  setEndDate: Function;
  guestListModalOpen: boolean;
  setGuestListModalOpen: Function;
}

const today = new Date();
today.setDate(1);
const tomorrow = new Date(today);
tomorrow.setMonth(tomorrow.getMonth() + 1);
tomorrow.setDate(0);

export const GuestListReportContext = createContext<IGuestListReport>({
  loading: false,
  setLoading: () => {},
  startDate: today,
  setStartDate: () => {},
  endDate: tomorrow,
  setEndDate: () => {},
  guestListModalOpen: false,
  setGuestListModalOpen: () => {},
});

export const GuestListReportProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [guestListModalOpen, setGuestListModalOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(tomorrow);

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleStartDate = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  const handleEndDate = useCallback((date: Date) => {
    setEndDate(date);
  }, []);

  const handleSetGuestListModalOpen = useCallback((newGuestList: boolean) => {
    setGuestListModalOpen(newGuestList);
  }, []);

  const value = useMemo(() => ({
    loading,
    setLoading: handleLoading,
    startDate,
    setStartDate: handleStartDate,
    endDate,
    setEndDate: handleEndDate,
    guestListModalOpen,
    setGuestListModalOpen: handleSetGuestListModalOpen,
  }),
  [
    loading,
    handleLoading,
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    guestListModalOpen,
    handleSetGuestListModalOpen,
  ]);

  return (
    <GuestListReportContext.Provider value={value}>
      {children}
    </GuestListReportContext.Provider>
  );
};
