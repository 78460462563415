import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';
import { NEW_DATE_DEFAULT } from '../../utils/Date';

type Direction = 'next' | 'preview' | 'none';

export interface ModalType {
  dateSelected: Date;
  setDateSelected: Function;
  direction: Direction;
  setDirection: Function;
  month: number;
  setMonth: Function;
  year: number;
  setYear: Function;
  selectInModal: boolean;
  setSelectInModal: Function;
}

export const DateContext = createContext<ModalType>({
  dateSelected: NEW_DATE_DEFAULT,
  setDateSelected: () => {},
  direction: 'none',
  setDirection: () => {},
  month: 0,
  setMonth: () => {},
  year: 0,
  setYear: () => {},
  selectInModal: false,
  setSelectInModal: () => {},
});

export const DateProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const [dateSelected, setDateSelected] = useState<Date>(NEW_DATE_DEFAULT);
  const [direction, setDirection] = useState<Direction>('none');
  const [month, setMonth] = useState<number>(NEW_DATE_DEFAULT.getMonth());
  const [year, setYear] = useState<number>(NEW_DATE_DEFAULT.getFullYear());
  const [selectInModal, setSelectInModal] = useState<boolean>(false);

  const handleDateSelected = useCallback((date: Date) => {
    setDateSelected(date);
  }, []);

  const handleDirection = useCallback((direct: Direction) => {
    setDirection(direct);
  }, []);

  const handleMonth = useCallback((m: number) => {
    setMonth(m);
  }, []);

  const handleYear = useCallback((y: number) => {
    setYear(y);
  }, []);

  const handleSelectInModal = useCallback((select: boolean) => {
    setSelectInModal(select);
  }, []);

  const value = useMemo(() => ({
    dateSelected,
    setDateSelected: handleDateSelected,
    direction,
    setDirection: handleDirection,
    month,
    setMonth: handleMonth,
    year,
    setYear: handleYear,
    selectInModal,
    setSelectInModal: handleSelectInModal,
  }), [
    dateSelected,
    setDateSelected,
    direction,
    setDirection,
    month,
    setMonth,
    year,
    setYear,
    selectInModal,
    setSelectInModal,
  ]);

  return (
    <DateContext.Provider
      value={value}
    >
      {children}
    </DateContext.Provider>

  );
};
