import React from 'react';

import { TitlePage, Container } from './styles';

const HeaderPage: React.FC = () => (
  <Container>
    <TitlePage>Proprietários</TitlePage>
  </Container>

);

export default HeaderPage;
