import React from 'react';

import {
  GRIDMAXITEMS,
} from '../../utils';

import {
  Content,
  Container,
} from './styles';

const BodyShimmer = () => {
  const mockArray = Array.from(Array(GRIDMAXITEMS).keys());

  return (
    <Container>
      {mockArray.map((item) => (
        <Content key={item}>
          <div />
          <div />
          <div />
          <div />
          <div />
        </Content>
      ))}
    </Container>
  );
};

export { BodyShimmer };
