import { NfList as NfListComponent } from '../../components/NfList';
import { NfListProvider } from '../../context/NfList/NfListContext';
import { Container } from './styles';

const NfList = () => (
  <NfListProvider>
    <Container>
      <NfListComponent />
    </Container>
  </NfListProvider>
);

export default NfList;
