import { useState, useEffect, useMemo } from 'react';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import { motion } from 'framer-motion';

import {
  Content,
  PropertyContainer,
  StyledPropertyContent,
  PropertyHeader,
  CloseContainer,
} from './style';

import PropertyTable from './PropertyTable/PropertyTable';
import { useUser } from '../../../../context/UserContext';
import { patchPropertiesOwner } from '../../../../services/Owner/request';
import { useToast } from '../../../../context/ToastContext';
import { useOwnerTransferControl } from '../../../../hooks/OwnerPage/OwnerTransferControl/useOwnerTransferControl';
import { changeRequiredActions } from '../../../../services/User/request';
import { UserInformation } from '../../../../services/User/types';
import { PropertyOwner } from '../../../../services/Owner/types';

interface IModal {
  open: boolean;
  onClose: Function;
  hideSaveButton?: boolean;
  hideSwitch?: boolean;
  description?: string;
}

interface IPropertyContent {
  hideSaveButton: IModal['hideSaveButton'];
  hideSwitch: IModal['hideSwitch'];
  onClose: IModal['onClose'];
  description: IModal['description'],
}

export const PropertyContent = ({
  onClose,
  hideSaveButton = false,
  hideSwitch = false,
  description = '',
}: IPropertyContent) => {
  const toast = useToast();
  const { setTransferControlAction } = useOwnerTransferControl();
  const {
    propertiesOwnerLogged,
    setPropertiesOwnerLogged,
    userInformation,
    setUserInformation,
  } = useUser();

  const propertiesOwner = useMemo(() => (propertiesOwnerLogged
    && propertiesOwnerLogged?.length !== 0 ? propertiesOwnerLogged : []), [propertiesOwnerLogged]);

  const handleChange = (propertyId: number) => {
    const data = propertiesOwner.map((prop) => ({
      ...prop,
      is_to_keep_funds_in_seazone: prop.id === propertyId
        ? !prop.is_to_keep_funds_in_seazone : prop.is_to_keep_funds_in_seazone,
    }));

    setPropertiesOwnerLogged(data);
  };

  const handleSubmit = async () => {
    try {
      if (userInformation?.user_id) {
        await Promise.all(propertiesOwner.map(async (item) => {
          await patchPropertiesOwner(item.id, {
            is_to_keep_funds_in_seazone: item.is_to_keep_funds_in_seazone,
          });
        }));

        const ownerActions: string[] = userInformation?.required_actions?.Owner || [];
        const response = await changeRequiredActions({
          user_id: userInformation.user_id,
          body: {
            Owner: ownerActions.filter((item) => item !== 'OWNER_TRANSFER_CONTROL_NOT_ADDED'),
          },
        });

        setUserInformation({
          ...userInformation,
          main_address: { ...userInformation?.main_address },
          host: { ...userInformation?.host },
          ...response.body,
        } as UserInformation);
      }

      setTransferControlAction('change_transfer');
      toast.success('Configuração de repasses atualizada com sucesso!');
      onClose();
    } catch {
      toast.error('Não foi possível realizar a configuração dos repasses!');
    }
  };

  return (
    <>
      <PropertyHeader>
        <h6 className="column-property">Imóvel</h6>
        <h6 className="column-transfer">Deseja guardar o repasse deste imóvel na Seazone?</h6>
      </PropertyHeader>

      <PropertyTable onChange={handleChange} hideSwitch={hideSwitch} />

      <StyledPropertyContent>
        <p>{description}</p>

        {!hideSaveButton && (
          <motion.button
            whileTap={{ scale: 0.9 }}
            type="button"
            onClick={() => handleSubmit()}
          >
            Salvar
          </motion.button>
        )}
      </StyledPropertyContent>
    </>
  );
};

const ModalTransferControlSettings = ({
  open,
  onClose,
  hideSaveButton = false,
  hideSwitch = false,
  description = '',
}: IModal) => {
  const { propertiesOwnerLoggedCopy, setPropertiesOwnerLogged } = useUser();

  const handleClose = () => {
    setPropertiesOwnerLogged(propertiesOwnerLoggedCopy);
    onClose();
  };

  return (
    <Modal open={open}>
      <Content>
        <CloseContainer onClick={() => handleClose()}>
          <Close />
        </CloseContainer>

        <h4 style={{ marginBottom: 20 }}>Configuração do Controle de Repasses</h4>

        <PropertyContainer>
          <PropertyContent
            hideSaveButton={hideSaveButton}
            hideSwitch={hideSwitch}
            onClose={onClose}
            description={description}
          />
        </PropertyContainer>
      </Content>
    </Modal>
  );
};

ModalTransferControlSettings.defaultProps = {
  hideSaveButton: false,
  hideSwitch: false,
  description: '',
};

export default ModalTransferControlSettings;
