import {
  FC,
  useState,
  useEffect,
  useMemo,
  memo,
  useContext,
} from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { X } from 'react-feather';
import { Grid } from '@mui/material';

import {
  Container,
  Content,
  FormContainer,
  Header,
  Title,
  CardContainer,
  CardHostContainer,
  CardHostContent,
  CardAddRemoveValue,
  ContainerQuestion,
  ContainerAddRemove,
  SwitchButton,
  SwitchCircle,
  ContainerValue,
  ContainerDescription,
  ButtonsContainer,
  CloseButton,
  Backdrop,
  ContentLoadMoreDatas,
  ArrowDownIcon,
  StarSymbol,
  SelectContainer,
  Spacing,
} from './styles';

import FormButton from '../../../../FormButton';
import TextField from '../../../../TextField';
import DatePicker from '../../../../DatePicker';
import SimpleSelect from '../../../../SimpleSelect';

import {
  GridHostProps,
} from '../../../../../context/FinancialClosePage/FinancialCloseHostContext/types';

import { HostCloseContext } from '../../../HostClose/HostClose';

import { FinancialCloseParams, ManualFitProps } from '../../../../../services/FinancialClose/Host/types';

import { postManualFitHost } from '../../../../../services/FinancialClose/Host/request';
import { getPropertiesHost } from '../../../../../services/Property/request';

import { currencyToNumber, numberToCurrency } from '../../../../../utils/Formatter';
import { currency } from '../../../../../utils/InputMask/Number';
import { formatDate } from '../../../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { compareList } from '../../../../../utils/Sorting';

import { useFinancialClose } from '../../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseHost } from '../../../../../hooks/FinancialCloseHook/useFinancialCloseHost';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../../utils/Messages';

const AddManualFitHost: FC = () => {
  const {
    handleOpenActions,
    dateSelected,
    handleActiveLinearProgress,
  } = useFinancialClose();

  const {
    financialDataHost,
    openModalAddManualFitHost,
    handleOpenModalAddManualFitHost,
  } = useFinancialCloseHost();

  const {
    refetchSelectedHosts,
  } = useContext(HostCloseContext);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  type SwitchProps = {
    id: number;
    active: boolean;
  };

  const DEFAULT_LIMIT = 3;
  const [limitFinancialData, setLimitFinancialData] = useState<number>(financialDataHost.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : financialDataHost.length);

  const params: FinancialCloseParams = {
    start_date: formatDate(dateSelected).start_date,
  };

  type PropertyInfos = {
    id: number,
    code: string;
  };

  type PropertyHostProps = Record<string, Array<PropertyInfos>>;

  const [propertiesHost, setPropertiesHost] = useState<PropertyHostProps>({});
  const [switchsIsRelatedProperty, setSwitchsIsRelatedProperty] = useState<SwitchProps[]>([]);
  const [switchsIsAdding, setSwitchsIsAdding] = useState<SwitchProps[]>([]);

  const [hostsSelected,
    setHostsSelected] = useState<GridHostProps[]>(financialDataHost
    .filter((item) => item.checked));

  const getAllPropertiesHost = async (hosts: GridHostProps[]) => {
    let allResponses: PropertyHostProps = {};

    await Promise.all(hosts.map(async (item) => {
      const { results } = await getPropertiesHost(500, item.id);

      const response: PropertyInfos[] = results.map((res) => ({
        id: res.id,
        code: res.code,
      }));

      allResponses = {
        ...allResponses,
        [item.id]: response.sort((a, b) => compareList(a.code, b.code)),
      };
    }));

    setPropertiesHost(allResponses);
  };

  useEffect(() => {
    if (openModalAddManualFitHost) {
      const hosts = financialDataHost.filter((item) => item.checked);

      setSwitchsIsAdding(hosts.length > 0
        ? hosts.map((item: GridHostProps) => ({
          id: item.id,
          active: false,
        })) : []);

      setSwitchsIsRelatedProperty(hosts.length > 0
        ? hosts.map((item: GridHostProps) => ({
          id: item.id,
          active: false,
        })) : []);

      setHostsSelected(() => [...hosts]);

      getAllPropertiesHost(hosts);
    }
  }, [openModalAddManualFitHost, financialDataHost]);

  const updateSwitchIsAdding = (id: number) => {
    setSwitchsIsAdding((prevSwitchs) => prevSwitchs.map((item) => ({
      ...item,
      active: item.id === id ? !item.active : item.active,
    })));
  };

  const updateSwitchIsPropertyManualFit = (id: number) => {
    setSwitchsIsRelatedProperty((prevSwitchs) => prevSwitchs.map((item) => ({
      ...item,
      active: item.id === id ? !item.active : item.active,
    })));
  };

  const findIndexOfItemSelected = (id: number) => hostsSelected
    .findIndex((item) => item.id === id);

  const handlePostManualFit = async (manualFitData: ManualFitProps) => {
    try {
      const response = await postManualFitHost(manualFitData);
      return response.data;
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const initialValuesFormik = useMemo(() => {
    let initial: any = {};
    const hosts = financialDataHost.filter((item) => item.checked);

    if (hosts.length > 0) {
      hosts.forEach((item) => {
        initial = {
          ...initial,
          [`is_adding_${item.id}`]: false,
          [`is_related_property_${item.id}`]: false,
          [`description_${item.id}`]: '',
          [`value_${item.id}`]: numberToCurrency(0),
          [`date_${item.id}`]: dateSelected,
          [`property_${item.id}`]: null,
        };
      });
    }

    return { ...initial };
  }, [financialDataHost, dateSelected]);

  const validation = useMemo(() => {
    let schema = {};

    financialDataHost.filter((item) => item.checked).forEach((item) => {
      schema = {
        ...schema,
        [`is_adding_${item.id}`]: Yup.boolean().required('Campo obrigatório'),
        [`is_related_property_${item.id}`]: Yup.boolean().required('Campo obrigatório'),
        [`description_${item.id}`]: Yup.string().required('Campo obrigatório'),
        [`value_${item.id}`]: Yup.string().required('Campo obrigatório'),
        [`date_${item.id}`]: Yup.date().required('Campo obrigatório'),
        [`property_${item.id}`]: Yup.string().nullable().test(
          'test-if-property-is-required', 'Campo obrigatório',
          (value: any) => {
            const isActive = switchsIsRelatedProperty?.[findIndexOfItemSelected(item.id)]?.active
            || false;

            return isActive ? !['null', 'undefined', ''].includes(`${value}`) : true;
          },
        ),
      };
    });

    return Yup.object().shape(schema);
  }, [financialDataHost, switchsIsRelatedProperty]);

  const formik = useFormik({
    initialValues: initialValuesFormik,
    validationSchema: validation,
    onSubmit: async (values) => {
      handleActiveLinearProgress(true);

      await Promise.all(hostsSelected.map(async (item) => {
        const value = values?.[`value_${item.id}`];
        const description = values?.[`description_${item.id}`];
        const isAdding = switchsIsAdding?.[findIndexOfItemSelected(item.id)]?.active || false;
        const isRelatedProperty = switchsIsRelatedProperty?.
          [findIndexOfItemSelected(item.id)]?.active || false;
        const dateRef = values?.[`date_${item.id}`];
        const propertyId = isRelatedProperty && values?.[`property_${item.id}`] !== null
          ? values?.[`property_${item.id}`] : null;

        await handlePostManualFit({
          date_ref: dateRef ? dateRef.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }) : new Date(),
          value: typeof value === 'string' ? currencyToNumber(currency(value)) : value,
          is_adding: isAdding,
          is_related_property: isRelatedProperty,
          host: item.host.id,
          description,
          property: propertyId,
        });
      }));

      await refetchSelectedHosts({
        ...params,
        hosts: hostsSelected.map((item) => `${item.host.id}`),
      });

      formik.resetForm();

      setTimeout(() => {
        handleOpenActions(false);
        handleOpenModalAddManualFitHost(false);
        handleActiveLinearProgress(false);
        toast.success('Ajustes realizados com sucesso!');
      }, 1000);
    },
  });

  const handleLoadNext = () => {
    setLimitFinancialData(financialDataHost.length > 0
      && limitFinancialData >= financialDataHost.length
      ? financialDataHost.length : limitFinancialData + DEFAULT_LIMIT);
  };

  return (
    <>
      <FormContainer onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Container isOpen={openModalAddManualFitHost}>
          <Header>
            <Title>Ajuste direto anfitrião</Title>
            <CloseButton type="button" onClick={() => handleOpenModalAddManualFitHost(false)}>
              <X size={22} />
            </CloseButton>
          </Header>
          <Content>
            {hostsSelected.length > 0
            && hostsSelected.slice(0, limitFinancialData).map((item) => (
              <CardContainer key={item.id}>
                <CardHostContainer>
                  <CardHostContent>
                    <div>
                      <p>
                        Anfitrião
                      </p>
                      <strong data-cy={`title-host-code-${item.id}`}>
                        {`- ${item.host.name}`}
                      </strong>
                    </div>
                  </CardHostContent>
                </CardHostContainer>
                <Spacing />

                <CardAddRemoveValue>
                  <ContainerQuestion>
                    <strong>O ajuste é relacionado a um imóvel?</strong>
                    <StarSymbol>*</StarSymbol>
                  </ContainerQuestion>
                  <ContainerAddRemove
                    switchIsActive={switchsIsRelatedProperty?.[findIndexOfItemSelected(item.id)]
                      ?.active || false}
                  >
                    <p>Não</p>
                    <SwitchButton data-cy={`btn-is-related-property-${item.id}`} type="button" onClick={() => updateSwitchIsPropertyManualFit(item.id)}>
                      <SwitchCircle
                        switchIsActive={
                          switchsIsRelatedProperty?.[findIndexOfItemSelected(item.id)]
                            ?.active || false
}
                      />
                    </SwitchButton>
                    <p>Sim</p>
                  </ContainerAddRemove>
                </CardAddRemoveValue>
                <Spacing />

                {switchsIsRelatedProperty?.[findIndexOfItemSelected(item.id)]?.active && (
                  <>
                    <Grid>
                      <SelectContainer>
                        <SimpleSelect
                          dataCy={`property_${item.id}`}
                          id={`property_${item.id}`}
                          placeholder="Selecione"
                          label="Imóvel"
                          formik={formik}
                          required
                          requireSymbolPosition="right"
                          options={item.id in propertiesHost
                            ? propertiesHost[item.id].map((property) => ({
                              value: property.id,
                              valueLabel: property.code,
                            })) : []}
                        />
                      </SelectContainer>
                    </Grid>
                    <Spacing />
                  </>
                )}

                <CardAddRemoveValue>
                  <ContainerQuestion>
                    <strong>É um ajuste positivo ou negativo?</strong>
                    <StarSymbol>*</StarSymbol>
                  </ContainerQuestion>
                  <ContainerAddRemove
                    switchIsActive={switchsIsAdding?.[findIndexOfItemSelected(item.id)]
                      ?.active || false}
                  >
                    <p>Negativo</p>
                    <SwitchButton data-cy={`btn-is-adding-${item.id}`} type="button" onClick={() => updateSwitchIsAdding(item.id)}>
                      <SwitchCircle
                        switchIsActive={switchsIsAdding?.[findIndexOfItemSelected(item.id)]
                          ?.active || false}
                      />
                    </SwitchButton>
                    <p>Positivo</p>
                  </ContainerAddRemove>
                </CardAddRemoveValue>

                <ContainerValue>
                  <TextField
                    dataCy={`input-value-${item.id}`}
                    id={`value_${item.id}`}
                    formik={formik}
                    label="Valor"
                    mask="money"
                    placeholder="R$ 0,00"
                    required
                    requireSymbolPosition="right"
                  />
                </ContainerValue>

                <ContainerValue>
                  <DatePicker
                    dataCy={`input-date-${item.id}`}
                    id={`date_${item.id}`}
                    formik={formik}
                    label="Data de referência"
                    hasInitialDates
                    required
                    requireSymbolPosition="right"
                    showDaysOutsideCurrentMonth={false}
                    showOnlyMonth={dateSelected}
                  />
                </ContainerValue>

                <ContainerDescription>
                  <TextField
                    dataCy={`textarea-description-${item.id}`}
                    formik={formik}
                    id={`description_${item.id}`}
                    label="Motivos"
                    type="textarea"
                    placeholder="Motivos"
                    required
                    requireSymbolPosition="right"
                  />
                </ContainerDescription>
              </CardContainer>
            ))}

            {hostsSelected.length > DEFAULT_LIMIT && (
              <ContentLoadMoreDatas
                onClick={() => handleLoadNext()}
              >
                <p>Mostrar mais</p>
                <ArrowDownIcon />
              </ContentLoadMoreDatas>
            )}

          </Content>

          <ButtonsContainer className="btn-submit">
            <FormButton dataCy="btn-cancel" type="button" variant="outlined" onClick={() => handleOpenModalAddManualFitHost(false)}>Cancelar</FormButton>
            <FormButton dataCy="btn-confirm" type="submit">Salvar</FormButton>
          </ButtonsContainer>
        </Container>
      </FormContainer>

      { openModalAddManualFitHost && (
        <Backdrop onClick={() => handleOpenModalAddManualFitHost(false)} />
      )}
    </>
  );
};

export default memo(AddManualFitHost);
