import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div``;
export const PropertyFormInput = styled(motion.input)``;

export const PropertyForm = styled(motion.div)`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const PropertyFormInputLabel = styled(motion.p)`
  gap: 10px;
  display: flex;
  font-size: 1rem;
  align-items: center;

  color: var(--gray-800, #394760);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const ErrorWrapper = styled.div`
  gap: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Error = styled.p`
  opacity: 0;
  animation: ${showItems} 0.4s 0.1s ease forwards;

  && {
    font-weight: 700;
    font-size: 9pt;
    color: ${({ theme }) => theme.palette.red._350.hex()};
  }
`;
