import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { Error } from './style';
import { IError } from './types';

const ErrorMessage = ({ id, formik, text }: IError) => (
  <AnimatePresence>
    {formik.errors[id] && formik.touched[id] && (
    <Error
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{
        y: 20,
        opacity: 0,
        transition: {
          duration: 0.2,
          ease: 'easeIn',
        },
      }}
    >
      {formik.errors[id]}
    </Error>
    )}
    {text && formik.touched[id] && (
    <Error
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{
        y: 20,
        opacity: 0,
        transition: {
          duration: 0.2,
          ease: 'easeIn',
        },
      }}
    >
      {text}
    </Error>
    )}
  </AnimatePresence>
);

ErrorMessage.defaultProps = {
  text: undefined,
};

export default ErrorMessage;
