/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import {
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';

import moment from 'moment';

import { InfoOutlined } from '@mui/icons-material';
import arrowBottom from '../../../../assets/icons/generals/arrowBottomBlack.svg';
import arrowTop from '../../../../assets/icons/generals/arrowTopBlack.svg';

import { ChangePropertyHostProps } from '../../../../context/ChangePropertyHostContext/types';
import { getPropertyChangeHost } from '../../../../services/InsertData/request';
import { translateHeaderLabel } from '../../../../context/ChangePropertyHostContext/utils';
import { compareDates } from '../../../../utils/Sorting';

import { useChangePropertyHost } from '../../../../hooks/useChangePropertyHost/useChangePropertyHost';
import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';

import Tooltip from '../../../Tooltip';
import Pagination from '../Pagination';
import FormButton from '../../../FormButton';

import {
  ContainerArrows,
  Image,
  InfoOutlinedIcon,
  GridContainer,
  GridContent,
  ContentInfo,
  GridHeaderContainer,
  GridHeaderContent,
  GridHeaderTitle,
  GridBodyContainer,
  GridBodyContent,
  GridBodyTitle,
  IconContainer,
  CardContainer,
  CardContentLeft,
  CardContentRight,
  CardInfo,
  FormButtonContainer,
} from './styles';

interface IGrid {
  indexActivedGrid: number,
}

const Grid = ({ indexActivedGrid }: IGrid) => {
  const { isMobile } = useScreenResize(900);

  const {
    exchangedHosts,
    setExchangedHosts,
    orderGridBy,
    setOrderGridBy,
    setModalEditExchangeHost,
    setModalDeleteExchangeHost,
  } = useChangePropertyHost();

  const keysDeliveredInformation = `A data de entrega das chaves é o primeiro dia do novo franqueado no imóvel. 
    Para o antigo anfitrião, registramos o seu último dia no imóvel como a data anterior a 
    de entrega das chaves.
  `;

  const handleGetExchangedHosts = async () => {
    const response = await getPropertyChangeHost();
    const sorted = response.map((item) => item).sort((a, b) => compareDates(
      a.created_at, b.created_at, 'desc',
    ));
    setExchangedHosts([...sorted]);
  };

  useEffect(() => {
    handleGetExchangedHosts();
  }, []);

  const ITEMS_PER_PAGE = useMemo(() => (isMobile ? 3 : 4), [isMobile]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data,
    maxPages,
    order,
  } = useMemo(() => {
    if (exchangedHosts.length === 0) {
      return {
        data: [],
        maxPages: 0,
        order: {
          startPosition: 0,
          endPosition: 0,
        },
      };
    }

    const inprogress: Array<ChangePropertyHostProps> = [];
    const historic: Array<ChangePropertyHostProps> = [];

    exchangedHosts.forEach((item) => {
      if (item.status?.toLocaleLowerCase() === 'canceled') return;

      if (moment(item.replacement_date, 'DD-MM-YYYY').isBefore(moment(), 'day') || item?.status?.toLowerCase() === 'done') {
        historic.push(item);
      } else {
        inprogress.push(item);
      }
    });

    const items = indexActivedGrid === 0 ? inprogress : historic;

    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = items.length > ITEMS_PER_PAGE ? start + ITEMS_PER_PAGE : items.length;

    return {
      data: items.slice(start, end),
      maxPages: Math.ceil(items.length / ITEMS_PER_PAGE),
      order: {
        startPosition: start,
        endPosition: end,
      },
    };
  }, [exchangedHosts, currentPage, indexActivedGrid]);

  const handlePrevPage = () => {
    setCurrentPage((page) => (page - 1 > 0 ? page - 1 : page));
  };

  const handleNextPage = () => {
    setCurrentPage((page) => (page + 1 <= maxPages ? page + 1 : page));
  };

  const handleSortGrid = (label: string) => {
    setOrderGridBy({
      order_by: translateHeaderLabel[label],
      order_option: 'all',
      order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const handleDelete = (item: ChangePropertyHostProps) => {
    setModalDeleteExchangeHost({
      open: true,
      infos: item,
    });
  };

  const handleEdit = (item: ChangePropertyHostProps) => {
    setModalEditExchangeHost({
      open: true,
      infos: item,
    });
  };

  type IconProps = {
    item: ChangePropertyHostProps,
  };

  const ButtonDelete = ({ item }: IconProps) => {
    if (isMobile) {
      return (
        <FormButtonContainer>
          <FormButton
            type="button"
            customColor="red"
            onClick={() => handleDelete(item)}
          >
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 4.83337H15.5C15.0353 4.83337 14.803 4.83337 14.6098 4.8718C13.8164 5.02962 13.1962 5.64981 13.0384 6.44319C13 6.63639 13 6.86872 13 7.33337V11.6667C13 13.5523 13 14.4951 12.4142 15.0809C11.8284 15.6667 10.8856 15.6667 9 15.6667H7C5.11438 15.6667 4.17157 15.6667 3.58579 15.0809C3 14.4951 3 13.5523 3 11.6667V7.33337C3 6.86872 3 6.63639 2.96157 6.44319C2.80376 5.64981 2.18356 5.02962 1.39018 4.8718C1.19698 4.83337 0.964655 4.83337 0.5 4.83337Z" fill="white" fillOpacity="0.25" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
              <path d="M6.3902 1.80883C6.48516 1.72023 6.6944 1.64194 6.98548 1.5861C7.27655 1.53027 7.63319 1.5 8.00008 1.5C8.36697 1.5 8.72361 1.53027 9.01468 1.5861C9.30576 1.64194 9.515 1.72023 9.60996 1.80883" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
            </svg>
            <p>Excluir</p>
          </FormButton>
        </FormButtonContainer>
      );
    }

    return (
      <IconContainer onClick={() => handleDelete(item)}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 15L10 12" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
          <path d="M14 15L14 12" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
          <path d="M3 7H21C20.0681 7 19.6022 7 19.2346 7.15224C18.7446 7.35523 18.3552 7.74458 18.1522 8.23463C18 8.60218 18 9.06812 18 10V16C18 17.8856 18 18.8284 17.4142 19.4142C16.8284 20 15.8856 20 14 20H10C8.11438 20 7.17157 20 6.58579 19.4142C6 18.8284 6 17.8856 6 16V10C6 9.06812 6 8.60218 5.84776 8.23463C5.64477 7.74458 5.25542 7.35523 4.76537 7.15224C4.39782 7 3.93188 7 3 7Z" fill="white" fillOpacity="0.25" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
          <path d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
      </IconContainer>
    );
  };

  const ButtonEdit = ({ item }: IconProps) => {
    if (isMobile) {
      return (
        <FormButtonContainer>
          <FormButton
            type="button"
            onClick={() => handleEdit(item)}
          >
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.12071 12.2133L2.12072 12.2133L2.14685 12.2068L2.14686 12.2068L4.10818 11.7164C4.12437 11.7124 4.14052 11.7084 4.15663 11.7044C4.3754 11.65 4.5857 11.5978 4.77715 11.4894C4.9686 11.381 5.12162 11.2275 5.28079 11.0679C5.29251 11.0561 5.30426 11.0444 5.31606 11.0325L11.1743 5.17426L11.1946 5.15399C11.4315 4.91712 11.6411 4.70758 11.7951 4.51774C11.9602 4.31435 12.1054 4.08237 12.1593 3.79022L11.5693 3.68139L12.1593 3.79021C12.1947 3.59836 12.1947 3.40164 12.1593 3.20979L11.5693 3.31861L12.1593 3.20978C12.1054 2.91763 11.9602 2.68565 11.7951 2.48226C11.6411 2.29242 11.4315 2.08289 11.1946 1.84602L11.1743 1.82574L11.1541 1.80545C10.9172 1.56855 10.7077 1.35899 10.5178 1.20495C10.3144 1.03991 10.0825 0.89464 9.7903 0.840754C9.59844 0.805367 9.40173 0.805367 9.20987 0.840754C8.91771 0.89464 8.68573 1.03991 8.48234 1.20495C8.2925 1.359 8.08296 1.56856 7.84609 1.80546L7.82582 1.82574L1.96753 7.68402C1.95573 7.69582 1.94395 7.70757 1.93219 7.71929C1.77257 7.87847 1.61912 8.03148 1.51073 8.22293L2.03285 8.51855L1.51073 8.22293C1.40233 8.41438 1.35007 8.62468 1.2957 8.84345C1.2917 8.85956 1.28769 8.87571 1.28364 8.89191L0.786776 10.8794C0.784367 10.889 0.781929 10.8987 0.77947 10.9085C0.740859 11.0626 0.697187 11.2369 0.682683 11.3851C0.666529 11.5503 0.668015 11.8495 0.9093 12.0908C1.15058 12.3321 1.44982 12.3336 1.61494 12.3174C1.7632 12.3029 1.93746 12.2592 2.09153 12.2206C2.10135 12.2182 2.11107 12.2157 2.12071 12.2133Z" stroke="white" strokeWidth="1.2" />
            </svg>
            <p>Editar</p>
          </FormButton>
        </FormButtonContainer>
      );
    }

    return (
      <IconContainer onClick={() => handleEdit(item)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.95396 14.38L2.95397 14.38L2.9801 14.3734L2.98012 14.3734L5.60809 13.7164C5.62428 13.7124 5.64043 13.7084 5.65654 13.7044C5.87531 13.65 6.08562 13.5978 6.27707 13.4894C6.46852 13.381 6.62153 13.2275 6.7807 13.0679C6.79242 13.0561 6.80418 13.0444 6.81598 13.0325L14.0101 5.83848L14.0101 5.83847L14.0369 5.81165C14.3472 5.50137 14.6215 5.22715 14.8128 4.97638C15.0202 4.70457 15.1858 4.39104 15.1858 4C15.1858 3.60896 15.0202 3.29543 14.8128 3.02361C14.6215 2.77285 14.3472 2.49863 14.0369 2.18835L14.01 2.16152L13.8385 1.98995L13.8117 1.96314C13.5014 1.6528 13.2272 1.37853 12.9764 1.1872C12.7046 0.979813 12.391 0.814214 12 0.814214C11.609 0.814214 11.2954 0.979813 11.0236 1.1872C10.7729 1.37853 10.4986 1.65278 10.1884 1.96311L10.1615 1.98995L2.96745 9.18402C2.95565 9.19582 2.94386 9.20758 2.93211 9.21929C2.77249 9.37847 2.61904 9.53148 2.51064 9.72293C2.40225 9.91438 2.34999 10.1247 2.29562 10.3435C2.29162 10.3596 2.28761 10.3757 2.28356 10.3919L1.62003 13.046C1.61762 13.0557 1.61518 13.0654 1.61272 13.0752C1.57411 13.2293 1.53044 13.4035 1.51593 13.5518C1.49978 13.7169 1.50127 14.0162 1.74255 14.2574C1.98383 14.4987 2.28307 14.5002 2.44819 14.4841C2.59646 14.4696 2.77072 14.4259 2.92479 14.3873C2.9346 14.3848 2.94433 14.3824 2.95396 14.38Z" stroke="#33363F" strokeWidth="1.2" />
        </svg>
      </IconContainer>
    );
  };

  const ContentDesktop = () => {
    const GRIDHEADERS = useMemo(() => (indexActivedGrid === 0
      ? ['Registro', 'Imóvel', 'Anfitrião atual', 'Anfitrião novo', 'Data de entrega', 'Ação']
      : ['Registro', 'Imóvel', 'Anfitrião antigo', 'Anfitrião novo', 'Data de entrega']), [indexActivedGrid]);

    return (
      <GridContent>
        {data.length === 0 ? (
          <ContentInfo>
            <p>{`Nenhuma troca ${indexActivedGrid === 0 ? 'em andamento' : 'finalizada'}`}</p>
          </ContentInfo>
        ) : (
          <>
            <GridHeaderContainer activeGrid={indexActivedGrid === 0 ? 'inprogress' : 'historic'}>
              {GRIDHEADERS.map((header) => (
                <GridHeaderContent key={header}>
                  <GridHeaderTitle data-cy={`title-${header}`}>{header}</GridHeaderTitle>
                  {header === 'Data de entrega' && (
                    <Tooltip text={keysDeliveredInformation}>
                      <InfoOutlinedIcon>
                        <InfoOutlined />
                      </InfoOutlinedIcon>
                    </Tooltip>
                  )}

                  {header !== 'Ação' && (
                    <Tooltip text={`Ordenar ${header}`}>
                      <ContainerArrows onClick={() => handleSortGrid(header)}>
                        <Image src={arrowTop} alt="Seta para cima" />
                        <Image src={arrowBottom} alt="Seta para baixo" />
                      </ContainerArrows>
                    </Tooltip>
                  )}
                </GridHeaderContent>
              ))}
            </GridHeaderContainer>

            <GridBodyContainer activeGrid={indexActivedGrid === 0 ? 'inprogress' : 'historic'}>
              {data.map((item: ChangePropertyHostProps) => (
                <Fragment key={item.id}>
                  <GridBodyContent>
                    <GridBodyTitle data-cy={`title-${item.created_at}`}>{item.created_at}</GridBodyTitle>
                  </GridBodyContent>
                  <GridBodyContent>
                    <GridBodyTitle data-cy={`title-${item.property.code}`}>{item.property.code}</GridBodyTitle>
                  </GridBodyContent>
                  <GridBodyContent>
                    <GridBodyTitle data-cy={`title-${item.old_host.full_name}`}>{item.old_host.full_name}</GridBodyTitle>
                  </GridBodyContent>

                  <GridBodyContent>
                    <GridBodyTitle data-cy={`title-${item.new_host.full_name}`}>{item.new_host.full_name}</GridBodyTitle>
                  </GridBodyContent>

                  <GridBodyContent>
                    <GridBodyTitle data-cy={`title-${item.replacement_date}`}>{item.replacement_date}</GridBodyTitle>
                  </GridBodyContent>

                  {indexActivedGrid === 0 && (
                    <GridBodyContent>
                      <ButtonDelete item={item} />
                      <ButtonEdit item={item} />
                    </GridBodyContent>
                  )}
                </Fragment>
              ))}
            </GridBodyContainer>
          </>
        )}
      </GridContent>
    );
  };

  interface IContentMobile {
    item: ChangePropertyHostProps;
  }

  const ContentMobile = ({ item }: IContentMobile) => {
    const CARDHEADERS = ['Registro', 'Imóvel', 'Anfitrião antigo', 'Anfitrião novo', 'Data de entrega'];
    const INDEXS = CARDHEADERS.map((_, index) => index);

    const CARDBODY = [
      item.created_at,
      item.property.code,
      item.old_host.full_name,
      item.new_host.full_name,
      item.replacement_date,
    ];

    return (
      <CardContainer>
        <CardContentLeft>
          {INDEXS.slice(0, 3).map((idx) => (
            <CardInfo key={idx}>
              <GridHeaderTitle data-cy={`title-${CARDHEADERS[idx]}`}>{CARDHEADERS[idx]}</GridHeaderTitle>
              <GridBodyTitle data-cy={`title-${CARDBODY[idx]}`}>{CARDBODY[idx]}</GridBodyTitle>
            </CardInfo>
          ))}

          {indexActivedGrid === 0 && (
            <ButtonEdit item={item} />
          )}
        </CardContentLeft>
        <CardContentRight>
          {INDEXS.slice(3, INDEXS.length).map((idx) => (
            <CardInfo key={idx}>
              <GridHeaderTitle data-cy={`title-${CARDHEADERS[idx]}`}>{CARDHEADERS[idx]}</GridHeaderTitle>
              <GridBodyTitle data-cy={`title-${CARDBODY[idx]}`}>{CARDBODY[idx]}</GridBodyTitle>
            </CardInfo>
          ))}

          {indexActivedGrid === 0 && (
            <ButtonDelete item={item} />
          )}
        </CardContentRight>
      </CardContainer>
    );
  };

  return (
    <GridContainer>
      {isMobile ? data.map((item) => (
        <ContentMobile
          key={item.id}
          item={item}
        />
      )) : <ContentDesktop />}

      {maxPages > 1 && (
        <Pagination
          page={currentPage}
          totalPages={maxPages}
          onClickPrevPage={handlePrevPage}
          onClickNextPage={handleNextPage}
          disableButtonPrev={currentPage === 1}
          disableButtonNext={currentPage === maxPages}
        />
      )}
    </GridContainer>
  );
};

export default Grid;
