/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  FC,
  useState,
  useEffect,
  useContext,
} from 'react';

import { Grid } from '@mui/material';
import { AccessTime, Close } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  ContainerBox,
  ContentBox,
  Header,
  ButtonClose,
  Title,
  DatesSelected,
  DateGrid,
  ButtonContainer,
  ErrorIcon,
  PhoneLabel,
} from './styles';

import FormButton from '../../../FormButton';
import FormGridRow from '../../../FormGridRow';
import SimpleSelect from '../../../SimpleSelect';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';

import ModalTemplate from '../ModalTemplate';
import ModalSuccessLock from '../ModalSuccessLock';

import { BlockFormData, RequestReasons } from '../../../../services/Reservation/types';
import { postBlocking } from '../../../../services/Reservation/request';

import { useToast } from '../../../../context/ToastContext';
import { useLoader } from '../../../../context/LoaderContext';
import { useReload } from '../../../../context/OwnerPage/GridCalendarReload';
import { ErrorMessage, useToastErrorMessage } from '../../../../utils/Messages';

import { CalendarContext } from '../../GridCalendar';

import TextField from '../../../TextField/TextField';
import AlertMessage from './AlertMessage';
import CountrySelector from '../../../CountrySelector';

const dataReasons: RequestReasons[] = [
  {
    id: 0,
    name: 'Uso próprio',
    value: 'Owner use',
  },
  {
    id: 1,
    name: 'Manutenção',
    value: 'Maintenance',
  },
];

interface Props {
  onClose: Function;
  onClosePropertyModal: Function;
  open?: boolean;
  startDate?: string;
  endDate?: string;
  propertyCode?: string;
  propertyId?: number;
  dataCy?: string;
}

const ModalConfirmLock: FC<Props> = ({
  open = false,
  onClose,
  onClosePropertyModal,
  startDate = 'dd/mm/yyyy',
  endDate = 'dd/mm/yyyy',
  propertyCode = '',
  propertyId,
  dataCy = 'modal-1',
}) => {
  const [openModalSuccessLock, setOpenModalSuccessLock] = useState<boolean>(false);
  const [currentUseCase, setCurrentUseCase] = useState<string>('');
  const { setLoad } = useLoader();
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { getReservation } = useContext(CalendarContext);
  const { setReload } = useReload();

  const [dayStartDate, monthStartDate, yearStartDate] = startDate.split('/');
  const [dayEndDate, monthEndDate, yearEndDate] = endDate.split('/');

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required('Você deve selecionar o motivo do bloqueio'),
    firstName: Yup.string().when('reason', {
      is: '0',
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    lastName: Yup.string().when('reason', {
      is: '0',
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    phoneNumber: Yup.string().when('reason', {
      is: '0',
      then: Yup.string().min(13, 'Telefone inválido. Ex. +55(48)99999-9999').required(),
      otherwise: Yup.string().nullable(),
    }),
    email: Yup.string().when('reason', {
      is: '0',
      then: Yup.string().email('E-mail inválido').required(),
      otherwise: Yup.string().nullable(),
    }),
    notes: Yup.string().when('reason', {
      is: '1',
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      checkInDate: `${yearStartDate}-${monthStartDate}-${dayStartDate}`,
      checkOutDate: `${yearEndDate}-${monthEndDate}-${dayEndDate}`,
      reason: 'Não informado',
      notes: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (['Não informado', 'Selecione', ''].includes(values.reason)) {
          return;
        }

        const reason = dataReasons.find((item) => item.id === Number(values.reason));
        setLoad(true);

        let payload = {
          ...values,
          checkInDate: `${yearStartDate}-${monthStartDate}-${dayStartDate}`,
          checkOutDate: `${yearEndDate}-${monthEndDate}-${dayEndDate}`,
          properties: [{ id: propertyId }],
          blockingReason: reason,
          notes: values.notes,
        } as unknown as BlockFormData;

        if (`${reason?.name}` === 'Uso próprio') {
          payload = {
            ...payload,
            guest: {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              phone_number: values.phoneNumber,
            },
          };
        }

        await postBlocking(payload);
        await getReservation();

        setOpenModalSuccessLock(true);
        setReload(true);
        setLoad(false);
        onClose();
        toast.success('Pedido de bloqueio realizado com sucesso!');
      } catch (e: any) {
        setLoad(false);
        if ([`${e?.error}`, `${e?.message}`].includes('property has extra day preparation')) {
          toast.error('Não é possível inserir um bloqueio, pois o imóvel necessita de tempo de preparo antes e depois do período selecionado. Tente selecionar um novo período que respeite esse intervalo.');
        } else if (e instanceof Error) {
          toastErrorRequest(e);
        } else {
          toast.error('Erro ao realizar bloqueio');
        }
      }
    },
  });

  const handleClose = () => {
    if (openModalSuccessLock) {
      setTimeout(() => {
        onClose(false);
        onClosePropertyModal(false);
        formik.setFieldValue('reason', 'Não informado');
      }, 2000);
    }
  };

  const handleCloseModalSuccessLock = () => {
    setOpenModalSuccessLock(false);
  };

  const handleSelecReason = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setCurrentUseCase(e.target.value);
  };

  useEffect(() => handleClose(), [openModalSuccessLock]);

  return (
    <>
      <ModalTemplate
        open={open}
        handleClose={onClose}
      >
        <ContainerBox onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
          <ContentBox>

            <Header>
              <Title data-cy="txt-notify-blocking">{`Você vai bloquear o imóvel ${propertyCode}`}</Title>
              <ButtonClose type="button" onClick={() => onClose(false)}>
                <Close />
              </ButtonClose>
            </Header>

            <DatesSelected>
              <DateGrid>
                <div className="date">
                  <strong>De</strong>
                  <span data-cy={`${dataCy}-start-date`}>{startDate}</span>
                </div>
                <div className="time">
                  <AccessTime />
                  <span data-cy={`${dataCy}-start-time`}>15h</span>
                </div>
              </DateGrid>

              <DateGrid>
                <div className="date">
                  <strong>Até</strong>
                  <span data-cy={`${dataCy}-end-date`}>{endDate}</span>
                </div>
                <div className="time">
                  <AccessTime />
                  <span data-cy={`${dataCy}-end-time`}>11h</span>
                </div>
              </DateGrid>
            </DatesSelected>

            {currentUseCase === '0' && (
              <AlertMessage startDate={startDate} endDate={endDate} />
            )}

            <FormGridRow>
              <SimpleSelect
                dataCy={`${dataCy}-select-block-reason`}
                id="reason"
                formik={formik}
                label="Motivo do bloqueio"
                placeholder="Selecione"
                required
                firstValue={currentUseCase}
                onChange={handleSelecReason}
                options={
                (dataReasons || []).map<SelectOption>(({ id, name }) => ({
                  value: id,
                  valueLabel: name,
                }))
              }
              />
            </FormGridRow>

            {currentUseCase === '0' && (
              <>
                <FormGridRow grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      dataCy={`${dataCy}-guest-name`}
                      id="firstName"
                      formik={formik}
                      label="Nome"
                      placeholder="Digite aqui..."
                      required
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="lastName"
                      formik={formik}
                      label="Sobrenome"
                      placeholder="Digite aqui..."
                      required
                    />
                  </Grid>
                </FormGridRow>
                <FormGridRow>
                  <Grid>
                    <PhoneLabel>
                      <div>
                        <span>*</span>
                        <label id="phoneNumber" htmlFor="phoneNumber">Número de telefone</label>
                      </div>
                      <p>Você deve informar o telefone do responsável que utilizará o imóvel.</p>
                    </PhoneLabel>
                    <CountrySelector
                      country="br"
                      autoFormat
                      enableLongNumbers
                      value={formik.values.phoneNumber}
                      enableClickOutside={false}
                      onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                      countryCodeEditable={false}
                    />
                    {formik.errors?.phoneNumber && (
                      <p data-cy={`${dataCy}-msg-phone-required`} className="messageError">
                        <ErrorIcon />
                        {formik.errors?.phoneNumber}
                      </p>
                    )}
                  </Grid>
                </FormGridRow>
                <FormGridRow>
                  <Grid>
                    <TextField
                      id="email"
                      formik={formik}
                      label="E-mail"
                      placeholder="Digite aqui..."
                      required
                    />
                  </Grid>
                </FormGridRow>
                <FormGridRow>
                  <TextField
                    id="notes"
                    formik={formik}
                    label="Observação"
                    placeholder="Digite aqui..."
                  />
                </FormGridRow>
              </>
            )}

            {currentUseCase === '1' && (
              <FormGridRow>
                <TextField
                  id="notes"
                  formik={formik}
                  label="Motivo da manutenção"
                  placeholder="Digite aqui..."
                  required
                />
              </FormGridRow>
            )}

            <ButtonContainer>
              <FormButton
                dataCy={`${dataCy}-btn-cancel`}
                type="button"
                customColor="blue-dark"
                variant="outlined"
                onClick={() => onClose(false)}
              >
                Cancelar
              </FormButton>
              <FormButton
                dataCy={`${dataCy}-btn-confirm-block`}
                type="submit"
                customColor="red-dark"
              >
                Bloquear
              </FormButton>
            </ButtonContainer>
          </ContentBox>
        </ContainerBox>
      </ModalTemplate>

      <ModalSuccessLock
        open={openModalSuccessLock}
        startDate={startDate}
        endDate={endDate}
        propertyCode={propertyCode}
        dataCy={dataCy}
        handleCloseModalSuccessLock={handleCloseModalSuccessLock}
      />
    </>
  );
};

export default ModalConfirmLock;
