import React from 'react';
import {
  Title,
  Container,
  Description,
  Header,
} from '../../../../common/style';

const SeazoneTip = () => (
  <Container>
    <Header>
      <Title>#DicaSeazone</Title>
      <Description>
        Envie o nosso e-book de rentabilidade de imóveis e o nosso conteúdo
        institucional aos proprietários para que eles tenham conhecimento prévio
        sobre todas as vantagens de fazer parte da Seazone. Essa simples ação pode
        ser o diferencial para aumentar a conversão.
      </Description>
    </Header>
  </Container>
);

export default SeazoneTip;
