import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  height: 50px;
  padding: 20px 0;
  z-index: 20;
  display: flex;
  border-radius: 8px;

  backdrop-filter: blur(10px);
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  background-color: ${({ theme }) => theme.palette.blue._950.hex()};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  justify-content: center;
`;

export const HamburguerMenu = styled.div`
  width: 100%;
  filter: invert(1);

  img {
    width: 20px;
  }
`;
