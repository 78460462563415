import { motion } from 'framer-motion';
import React from 'react';

interface IIndicationsProps {
  delay: number;
}

const Indications = ({
  delay,
}: IIndicationsProps) => (
  <motion.svg
    whileTap={{
      scale: 1.2,
    }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.rect width="32" height="32" rx="10" fill="#E7EAF1" />
    <motion.path
      initial={{
        opacity: 0,
        scale: 0,
        y: 10,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        scale: 0,
        y: 10,
      }}
      transition={{ delay: 1.3 + delay * 0.1 }}
      d="M21.002 23.752C21.002 24.4819 20.7121 25.1819 20.196 25.698C19.6799 26.2141 18.9799 26.504 18.25 26.504H13.752C13.0221 26.504 12.3221 26.2141 11.806 25.698C11.2899 25.1819 11 24.4819 11 23.752C11 23.0221 11.2899 22.3221 11.806 21.806C12.3221 21.2899 13.0221 21 13.752 21H18.25C18.6114 21 18.9693 21.0712 19.3031 21.2095C19.637 21.3478 19.9404 21.5505 20.196 21.806C20.4515 22.0616 20.6542 22.365 20.7925 22.6989C20.9308 23.0327 21.002 23.3906 21.002 23.752ZM19.502 23.752C19.502 23.061 18.942 22.5 18.25 22.5H13.752C13.4199 22.5 13.1015 22.6319 12.8667 22.8667C12.6319 23.1015 12.5 23.4199 12.5 23.752C12.5 24.0841 12.6319 24.4025 12.8667 24.6373C13.1015 24.8721 13.4199 25.004 13.752 25.004H18.25C18.941 25.004 19.502 24.444 19.502 23.752Z"
      fill="#B8C6DC"
    />
    <motion.path
      initial={{
        opacity: 0,
        scale: 0,
        y: 10,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
      }}
      transition={{ delay: 1.4 + delay * 0.1 }}
      d="M24.0018 16.752C24.0018 17.4819 23.7118 18.1819 23.1957 18.698C22.6796 19.2141 21.9796 19.504 21.2498 19.504H10.7518C10.0219 19.504 9.3219 19.2141 8.8058 18.698C8.2897 18.1819 7.99976 17.4819 7.99976 16.752C7.99976 16.0221 8.2897 15.3221 8.8058 14.806C9.3219 14.2899 10.0219 14 10.7518 14H21.2498C21.9796 14 22.6796 14.2899 23.1957 14.806C23.7118 15.3221 24.0018 16.0221 24.0018 16.752ZM22.5018 16.752C22.5018 16.06 21.9418 15.5 21.2498 15.5H10.7518C10.4197 15.5 10.1013 15.6319 9.86646 15.8667C9.63166 16.1015 9.49976 16.4199 9.49976 16.752C9.49976 17.0841 9.63166 17.4025 9.86646 17.6373C10.1013 17.8721 10.4197 18.004 10.7518 18.004H21.2498C21.9418 18.004 22.5018 17.444 22.5018 16.752Z"
      fill="#B8C6DC"
    />
    <motion.path
      initial={{
        opacity: 0,
        scale: 0,
        y: 10,
      }}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
      }}
      transition={{ delay: 1.5 + delay * 0.1 }}
      d="M26.002 9.752C26.002 10.4819 25.7121 11.1819 25.196 11.698C24.6799 12.2141 23.9799 12.504 23.25 12.504H8.752C8.02212 12.504 7.32214 12.2141 6.80604 11.698C6.28994 11.1819 6 10.4819 6 9.752C6 9.02212 6.28994 8.32214 6.80604 7.80604C7.32214 7.28994 8.02212 7 8.752 7H23.25C23.9799 7 24.6799 7.28994 25.196 7.80604C25.7121 8.32214 26.002 9.02212 26.002 9.752ZM24.502 9.752C24.502 9.06 23.942 8.5 23.25 8.5H8.752C8.58758 8.5 8.42478 8.53238 8.27288 8.5953C8.12098 8.65822 7.98296 8.75044 7.8667 8.8667C7.75044 8.98296 7.65822 9.12098 7.5953 9.27288C7.53238 9.42478 7.5 9.58758 7.5 9.752C7.5 9.91641 7.53238 10.0792 7.5953 10.2311C7.65822 10.383 7.75044 10.521 7.8667 10.6373C7.98296 10.7536 8.12098 10.8458 8.27288 10.9087C8.42478 10.9716 8.58758 11.004 8.752 11.004H23.25C23.942 11.004 24.502 10.444 24.502 9.752Z"
      fill="#B8C6DC"
    />
  </motion.svg>
);

export default Indications;
