import styled from 'styled-components';

export const Container = styled.div``;

export const TextBold = styled.div`
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 700;
  font-size: 14px;
  line-height: 17.5px;
  padding-bottom: 8px;
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.palette.grey._400.hex()};
  height: 1px;
  width: 100%;
  margin: 5px 0 15px 0;
`;
