import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ColumnFiltersState } from '@tanstack/react-table';
import { CircularProgress } from '@mui/material';

import {
  ExpenseStatus,
  ExpenseParams,
  SimpleHost,
} from '../../../services/Expenses/types';

import { getHosts, getProperties } from '../../../services/Expenses/request';

import { translatePropertyStatus } from '../../../utils/Translator';
import { compareList } from '../../../utils/Sorting';

import { useUser } from '../../../context/UserContext';
import { useExpense } from '../../../hooks/ExpenseHook/useExpense';
import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';

import { FormButton, SimpleSelect } from '../../index';
import { FilterPopover } from './FilterPopover';
import DropdownAutocomplete, { SelectProps } from '../../DropdownAutocomplete/DropdownAutocomplete';

import {
  Form,
  Container,
  ButtonsContainer,
  Separator,
  SearchBtnContainer,
  RightSide,
  LeftSide,
} from './styles';

export type ExpenseChecked = {
  id: number;
  checked: boolean;
};

interface HeaderProps {
  setExpensesList: Function;
  listUpdateWhoPays: ExpenseChecked[];
}

const Header: FC<HeaderProps> = ({
  setExpensesList,
  listUpdateWhoPays,
}) => {
  const { userInformation } = useUser();
  const { isMobile } = useScreenResize();
  const roles = userInformation?.roles || [];

  const {
    handleNextPage,
    handleHasFiltered,
    handleFilterParams,
    handleFilteredValues,
    handleIsEditingModal,
    handleOpenModalExpenses,
    setGlobalFilter,
    setModalSelectWhoPays,
    setOpenExportExpensesModal,
    openExportExpensesModal,
    fetchExpenseData,
    expensesPage,
    filterParams,
    loading,
    properties,
    setProperties,
    handleExpenseData,
  } = useExpense();

  const [showErrorRegisterDate, setShowErrorRegisterDate] = useState<Boolean>(false);
  const [showErrorTransferDate, setShowErrorTransferDate] = useState<Boolean>(false);
  const [showErrorApprovalDate, setShowErrorApprovalDate] = useState<Boolean>(false);
  const [hosts, setHosts] = useState<SimpleHost[]>([]);
  const [inputRegisterDate, setInputRegisterDate] = useState<string>('Selecione');
  const [inputTransferDate, setInputTransferDate] = useState<string>('Selecione');
  const [inputApprovalDate, setInputApprovalDate] = useState<string>('Selecione');

  const handleClickExportExpensesModal = () => {
    setOpenExportExpensesModal(!openExportExpensesModal);
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const validation = Yup.object().shape({
    startDateRegister: Yup.date(),
    endDateRegister: Yup.date().when('startDateRegister',
      (startDateRegister: any, schema: Yup.DateSchema) => startDateRegister
        && schema.min(startDateRegister,
          'A data final deve ser maior do que a inicial!')),
    startDateTransfer: Yup.date(),
    endDateTransfer: Yup.date().when('startDateTransfer',
      (startDateTransfer: any, schema: Yup.DateSchema) => startDateTransfer
        && schema.min(startDateTransfer,
          'A data final deve ser maior do que a inicial!')),
    startDateApproval: Yup.date(),
    endDateApproval: Yup.date().when('startDateApproval',
      (startDateApproval: any, schema: Yup.DateSchema) => startDateApproval
        && schema.min(startDateApproval,
          'A data final deve ser maior do que a inicial!')),
  });

  const initialValues = {
    property_code: 0,
    host_id: 0,
    startDateRegister: today,
    endDateRegister: tomorrow,
    startDateTransfer: today,
    endDateTransfer: tomorrow,
    startDateApproval: today,
    endDateApproval: tomorrow,
    expense_status: 'Selecione',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      const validateIsFilterWithRequest = () => {
        if (filterParams) {
          return true;
        } if (expensesPage) {
          return false;
        }
        return true;
      };

      if (validateIsFilterWithRequest()) {
        const getPropertyIdByCode = [...properties.actives, ...properties.inactives]
        .find((item) => item.code === String(values.property_code));

        const params: ExpenseParams = {
          property_id: Number(getPropertyIdByCode?.id),
          host_id: Number(values.host_id),
          expense_status:
            values.expense_status !== 'Selecione'
              ? (values.expense_status as ExpenseStatus)
              : undefined,
          refund_date_start:
            inputTransferDate !== 'Selecione'
              ? moment(values.startDateTransfer).format('YYYY-MM-DD')
              : undefined,
          refund_date_end:
            inputTransferDate !== 'Selecione'
              ? moment(values.endDateTransfer).format('YYYY-MM-DD')
              : undefined,
          register_date_start:
            inputRegisterDate !== 'Selecione'
              ? moment(values.startDateRegister).format('YYYY-MM-DD')
              : undefined,
          register_date_end:
            inputRegisterDate !== 'Selecione'
              ? moment(values.endDateRegister).format('YYYY-MM-DD')
              : undefined,
          approval_date_start:
            inputApprovalDate !== 'Selecione'
              ? moment(values.startDateApproval).format('YYYY-MM-DD')
              : undefined,
          approval_date_end:
            inputApprovalDate !== 'Selecione'
              ? moment(values.endDateApproval).format('YYYY-MM-DD')
              : undefined,
        };

        handleFilteredValues(values);
        handleFilterParams(params);
        fetchExpenseData(1, params);
      } else {
        const dateObj: ColumnFiltersState = [];

        const isDateValid = (field: any) => Boolean(field?.trim() !== 'Selecione');
        const isValidStatus = values.expense_status !== 'Selecione';

        if (isDateValid(inputRegisterDate)) {
          dateObj.push({
            id: 'startDateRegister',
            value: moment(values.startDateRegister).format('DD/MM/YYYY'),
          });
        }

        if (isDateValid(inputTransferDate)) {
          dateObj.push({
            id: 'startDateTransfer',
            value: moment(values.startDateTransfer).format('DD/MM/YYYY'),
          });
        }

        if (isDateValid(inputApprovalDate)) {
          dateObj.push({
            id: 'startDateApproval',
            value: moment(values.startDateApproval).format('DD/MM/YYYY'),
          });
        }

        setGlobalFilter(() => [
          ...dateObj,
          {
            id: 'imovel',
            value: String(values.property_code || ''),
          },
          {
            id: 'status',
            value: isValidStatus ? String(values.expense_status || '') : '',
          },
        ]);
      }
    },
  });

  const handleResetAllFormikData = () => {
    formik.resetForm();
    handleHasFiltered(false);
    handleFilteredValues({});
    handleFilterParams({});
    setExpensesList([]);
    handleNextPage(false);
  };

  useEffect(() => {
    handleResetAllFormikData();

    async function getProperty() {
      const response = await getProperties();
      const filteredActives = response.filter((property) => property.status === 'Active' || property.status === 'Onboarding').sort((a, b) => compareList(a.code, b.code));
      const filteredInactives = response.filter((property) => property.status !== 'Active' && property.status !== 'Onboarding').sort((a, b) => compareList(a.code, b.code));

      setProperties({
        actives: filteredActives,
        inactives: filteredInactives,
      });
    }

    async function getHost() {
      const hostResponse = await getHosts();
      const filteredHosts = hostResponse
        .filter((host) => host.is_host_active === true)
        .sort((a, b) => compareList(a.full_name, b.full_name));
      setHosts(filteredHosts);
    }

    getProperty();
    getHost();
  }, []);

  useEffect(() => {
    handleFilteredValues(formik.values);
  }, [formik.values]);

  const checkDateFilter = (startDate: Date, endDate: Date) => moment(startDate).format('DD/MM/YYYY').toString() === 'Data inválida'
    || moment(endDate).format('DD/MM/YYYY').toString() === 'Data inválida'
    || moment(endDate) <= moment(startDate);

  const handleAppliedDateFilter = (dateOption: string) => {
    const isInvalidDate = dateOption === 'register'
      ? checkDateFilter(formik.values.startDateRegister,
        formik.values.endDateRegister)
      : checkDateFilter(formik.values.startDateTransfer,
        formik.values.endDateTransfer);

    if (isInvalidDate) {
      if (dateOption === 'register') {
        setShowErrorRegisterDate(true);
      } else {
        setShowErrorTransferDate(true);
      }
    } else if (dateOption === 'register') {
      setInputRegisterDate(`${moment(formik.values.startDateRegister).format('DD/MM/YYYY')} à ${moment(formik.values.endDateRegister).format('DD/MM/YYYY')}`);
      setShowErrorRegisterDate(false);
    } else if (dateOption === 'transfer') {
      setInputTransferDate(`${moment(formik.values.startDateTransfer).format('DD/MM/YYYY')} à ${moment(formik.values.endDateTransfer).format('DD/MM/YYYY')}`);
      setShowErrorTransferDate(false);
    } else {
      setInputApprovalDate(`${moment(formik.values.startDateApproval).format('DD/MM/YYYY')} à ${moment(formik.values.endDateApproval).format('DD/MM/YYYY')}`);
      setShowErrorApprovalDate(false);
    }
  };

  const handleClearDateFilter = (
    dateOption: string,
    startDate: string,
    endDate: string,
  ) => {
    if (formik) {
      formik.setFieldValue(startDate, today);
      formik.setFieldTouched(
        startDate, true, false,
      );
      formik.setFieldValue(endDate, tomorrow);
      formik.setFieldTouched(
        endDate, true, false,
      );
    }
    if (dateOption === 'register') {
      setInputRegisterDate('Selecione');
      setShowErrorRegisterDate(false);
    } else if (dateOption === 'transfer') {
      setInputTransferDate('Selecione');
      setShowErrorTransferDate(false);
    } else {
      setInputApprovalDate('Selecione');
      setShowErrorApprovalDate(false);
    }
  };

  const handleGetProperty = (): SelectProps[] => [...properties.actives, ...properties.inactives]
    .map((property) => ({
      optionText: `${property.code} - ${translatePropertyStatus(property?.status || '')}`,
      optionValue: `${property.code}`,
    }));

  const handleGetHosts = (): SelectProps[] => hosts
    .map((host) => ({
      optionText: `${host.full_name}`,
      optionValue: `${host.id}`,
    }));

  const handleOpenExpensesModal = () => {
    handleExpenseData({} as any);
    handleIsEditingModal(false);
    handleOpenModalExpenses(true);
  };

  return (
    <>
      <Container>
        <Form
          onSubmit={formik.handleSubmit}
          onChange={formik.handleChange}
        >
          <LeftSide>
            <div className="row">
              <DropdownAutocomplete
                dataCy="select-properties-list"
                placeholder="Selecione"
                label="Imóvel"
                id="property_code"
                options={handleGetProperty()}
                formik={formik}
              />
            </div>

            {((userInformation?.main_role === 'Seazone'
            && (roles.includes('SeazoneAdministrative')
              || roles.includes('SeazoneOnboarding'))) || roles.includes('Admin')) && (
              <div className="row">
                <DropdownAutocomplete
                  dataCy="select-host-list"
                  placeholder="Selecione"
                  label="Anfitrião"
                  id="host_id"
                  options={handleGetHosts()}
                  formik={formik}
                />
              </div>
            )}

            <FilterPopover.Root
              title="Data de registro"
              value={inputRegisterDate}
            >
              <FilterPopover.Content
                ids={{
                  main: 'register',
                  end: 'endDateRegister',
                  start: 'startDateRegister',
                }}
                error={showErrorRegisterDate}
                formik={formik}
                onApply={handleAppliedDateFilter}
                onClear={handleClearDateFilter}
              />
            </FilterPopover.Root>
{/*
            <FilterPopover.Root
              title="Data de repasse"
              value={inputTransferDate}
            >
              <FilterPopover.Content
                formik={formik}
                ids={{
                  main: 'transfer',
                  end: 'endDateTransfer',
                  start: 'startDateTransfer',
                }}
                error={showErrorTransferDate}
                onApply={handleAppliedDateFilter}
                onClear={handleClearDateFilter}
              />
            </FilterPopover.Root> */}

            <FilterPopover.Root
              title="Data de aprovação"
              value={inputApprovalDate}
            >
              <FilterPopover.Content
                ids={{
                  main: 'approval',
                  end: 'endDateApproval',
                  start: 'startDateApproval',
                }}
                error={showErrorApprovalDate}
                formik={formik}
                onApply={handleAppliedDateFilter}
                onClear={handleClearDateFilter}
              />
            </FilterPopover.Root>

            <div className="row">
              <SimpleSelect
                dataCy="select-status"
                id="expense_status"
                placeholder="Selecione"
                label="Status"
                options={[
                  { value: '', valueLabel: 'Todos os status' },
                  { value: 'Analyzing', valueLabel: 'Em análise' },
                  { value: 'Pre_Approved', valueLabel: 'Pré Aprovado' },
                  { value: 'Approved', valueLabel: 'Aprovado' },
                  { value: 'Denied', valueLabel: 'Reprovado' },
                  { value: 'Canceled', valueLabel: 'Cancelado' },
                  { value: 'Pending', valueLabel: 'Pendente' },
                  { value: 'Paid', valueLabel: 'Pago' },
                ]}
              />
            </div>
          </LeftSide>

          <RightSide>
            <SearchBtnContainer>
              <FormButton
                disable={loading}
                newStyle
                dataCy="btn-apply-filter"
                type="submit"
              >
                {loading ? 'Carregando...' : 'Aplicar filtro'}
                {loading && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{
                    position: 'absolute',
                    left: '10px',
                  }}
                />
                )}
              </FormButton>
            </SearchBtnContainer>

            {isMobile && <Separator />}

            <ButtonsContainer>
              {listUpdateWhoPays.length >= 1 && (
              <FormButton
                newStyle
                type="button"
                onClick={() => setModalSelectWhoPays(true)}
              >
                Definir quem paga
              </FormButton>
              )}
              {listUpdateWhoPays.length === 0
              && userInformation?.main_role !== 'Host' && (
                <>
                  <FormButton
                    newStyle
                    dataCy="btn-export-expenses"
                    customColor="new-black-outline"
                    type="submit"
                    onClick={() => handleClickExportExpensesModal()}
                  >
                    Baixar despesas
                  </FormButton>
                  <FormButton
                    dataCy="new-blue-outline"
                    newStyle
                    type="submit"
                    onClick={() => handleOpenExpensesModal()}
                  >
                    Nova despesa
                  </FormButton>
                </>
              )}
              {userInformation?.main_role === 'Host'
              && roles.includes('Host') && (
                <>
                  <FormButton
                    dataCy="btn-export-expenses"
                    customColor="new-black-outline"
                    newStyle
                    type="submit"
                    onClick={() => handleClickExportExpensesModal()}
                  >
                    Baixar reembolsos
                  </FormButton>
                  <FormButton
                    dataCy="btn-export-expenses"
                    customColor="new-blue-outline"
                    newStyle
                    type="submit"
                    onClick={() => handleOpenExpensesModal()}
                  >
                    Novo Reembolso
                  </FormButton>
                </>
              )}
            </ButtonsContainer>
          </RightSide>
        </Form>
      </Container>
    </>
  );
};

export default Header;
