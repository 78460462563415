import jwt_decode, { JwtDecodeOptions } from 'jwt-decode';

interface DecodedJwt{
  exp: any;
  token: string,
  options?: JwtDecodeOptions;
}

export function getToken() {
  const userData = localStorage.getItem('sapron-pms-user');
  if (userData) {
    const { access } = JSON.parse(userData);
    return access;
  }
  return null;
}

export function checkIfTokenExpired() {
  const token = getToken();

  if (token) {
    const decodeJwt: DecodedJwt = jwt_decode(token);
    const expiry = decodeJwt.exp;
    const now = new Date();
    return now.getTime() > expiry * 1000;
  }
  return false;
}
