import React, { useState } from 'react';

import { LinearProgress } from '@mui/material';

import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';
import Tooltip from '../../../Tooltip';

import {
  Container,
  Line,
} from './styles';

interface IProps {
  isOpen: boolean;
  changeVisible: () => void;
  title: string;
}

const Header = ({ isOpen, changeVisible, title }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [itsOpen, setIsOpen] = useState(false);

  const handleChangeVisibility = () => {
    if (!itsOpen) {
      setLoading(true);
      setTimeout(() => {
        changeVisible();
        setLoading(false);
        setIsOpen(true);
      }, 1000);
    } else {
      changeVisible();
      setIsOpen(false);
    }
  };

  return (
    <Tooltip text="Clique para expandir ou recolher">
      <Container onClick={() => handleChangeVisibility()} isOpen={isOpen}>
        <h1>{title}</h1>
        <Line>
          {loading && (<LinearProgress />)}
        </Line>
        <button onClick={() => handleChangeVisibility()} type="button">
          <img src={arrowBottom} alt="seta para mostrar ou ocultar componentes" />
        </button>
      </Container>
    </Tooltip>
  );
};

export default Header;
