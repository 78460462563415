import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import FormAddListing from './FormAddListing/FormAddListing';

import {
  Container, Title, Divider,
} from './styles';

type FormState = 'user';

const InsertAddListingData = () => {
  const [isEditingForm, setIsEditingForm] = useState(false);
  const [formState, setFormState] = useState<FormState>('user');
  const formik = useFormik({
    initialValues: {
      formType: 'user' as FormState,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    setFormState(formik.values.formType);
  }, [formik.values.formType]);

  useEffect(() => {
    const paths = [
      '/editardados/listing',
      'editardados/listing',
      '/editardados/listing/',
      'editardados/listing/',
    ];
    if (paths.includes(window.location.pathname)) {
      setIsEditingForm(true);
    } else {
      setIsEditingForm(false);
    }
  }, [window.location.pathname]);

  return (
    <Container>
      <div className="header">
        <div>
          <Title>{isEditingForm ? 'Editar listing' : 'Novo listing'}</Title>
        </div>
        <Divider />
      </div>
      {formState === 'user' && <FormAddListing isEditingForm={isEditingForm} />}
    </Container>
  );
};
export default InsertAddListingData;
