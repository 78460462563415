import React, { useState, useEffect, useCallback } from 'react';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';

import {
  Content,
  Container,
} from './styles';

import photoSketch from '../../../../../../assets/icons/expense/photoSketch.svg';
import photoSketchBlocked from '../../../../../../assets/icons/expense/photoSketchBlock.svg';

import {
  FileProps,
} from '../../../../../../context/FileContext/types';

import { useFile } from '../../../../../../hooks/FileHook/useFile';
import { initialValuesFile } from '../../../../../../context/FileContext/FileContext';

import VoucherList from '../VoucherList';
import Upload from '../../../../../Upload';

interface TitleProps {
  text: string;
}

interface UploadFileProps {
  fileArray: FileProps[];
  setFileArray: Function;
  voucherFile: FileProps;
  setVoucherFile: Function;
  setHasSendedFile: Function;
  setSendingFileArray: Function;
  sendFileDataToS3: Function;
  handleRequestDeleteFile?: Function;
  requestDeleteIsConfirmed?: boolean;
  limitFile?: boolean;
  disabled?: boolean;
}

const UploadFile = ({
  fileArray,
  voucherFile,
  limitFile = true,
  setFileArray,
  setVoucherFile,
  setHasSendedFile,
  sendFileDataToS3,
  handleRequestDeleteFile,
  requestDeleteIsConfirmed,
  setSendingFileArray,
  disabled = false,
}: UploadFileProps) => {
  const { deleteFile } = useFile();
  const [fileToDelete, setFileToDelete] = useState<string | null>(null);

  const handleSetFileInFileArray = () => {
    setFileArray([...fileArray, voucherFile]);
  };

  const handleRemoveFileFromFileArray = (fileId: string) => {
    const newFileArray = fileArray.filter((file) => file.id !== fileId);
    setFileArray(newFileArray);
  };

  const handleClickOnDeleteFile = useCallback((id: string) => {
    setFileToDelete(id);
    if (handleRequestDeleteFile) {
      handleRequestDeleteFile();
    }
  }, []);

  const handleDeleteFile = async (id: string) => {
    await deleteFile(id);
    handleRemoveFileFromFileArray(id);
    setHasSendedFile(false);
    setSendingFileArray([]);
    setVoucherFile(initialValuesFile);
  };

  useEffect(() => {
    if (fileToDelete !== null && requestDeleteIsConfirmed === true) {
      handleDeleteFile(fileToDelete);
    }
  }, [fileToDelete, requestDeleteIsConfirmed]);

  useEffect(() => {
    setVoucherFile(initialValuesFile);
    sendFileDataToS3();
  }, [fileArray]);

  useEffect(() => {
    if (voucherFile !== initialValuesFile) {
      handleSetFileInFileArray();
    }
  }, [voucherFile]);

  // Returns
  const Title = ({ text }: TitleProps) => (
    <>
      <h1>{text}</h1>
      <AttachFileIcon
        style={{
          color: '#949BA0',
          fontSize: '30px',
          transform: 'rotate(30deg)',
        }}
      />
    </>
  );

  if (limitFile) {
    return (
      <>
        <Container>
          <Content>
            <Title text={fileArray?.length > 0 ? 'Comprovante Anexado' : 'Anexar Comprovante'} />
            {
            fileArray?.length > 0 || disabled ? (
              <img src={photoSketchBlocked} alt="" />
            ) : (
              <Upload
                file={voucherFile}
                setFile={setVoucherFile}
                dataCy="input-voucher-file"
                accept={['.jpeg', '.jpg', '.pdf', '.png']}
                defaultPreview={photoSketch}
              />
            )
          }
          </Content>
        </Container>

        {fileArray.map((file: FileProps, index) => file.MIMEtype !== '' && (
        <VoucherList
          index={index}
          MIMEtype={file.MIMEtype}
          preview={file?.previewURL || file?.url}
          id={file.id}
          text={file.name}
          onDelete={() => handleClickOnDeleteFile(file.id)}
        />
        ))}
      </>
    );
  }

  return (
    <>
      <Container>
        <Content>
          <Title text={fileArray?.length > 0 ? 'Comprovante Anexado' : 'Anexar Comprovante'} />
          <Upload
            file={voucherFile}
            setFile={setVoucherFile}
            dataCy="input-voucher-file"
            accept={['.jpeg', '.jpg', '.pdf', '.png']}
            defaultPreview={photoSketch}
          />
        </Content>
      </Container>

      {fileArray.map((file: FileProps, index) => file.MIMEtype !== '' && (
        <VoucherList
          index={index}
          MIMEtype={file.MIMEtype}
          preview={file.previewURL}
          id={file.id}
          text={file.name}
          onDelete={() => handleClickOnDeleteFile(file.id)}
        />
      ))}
    </>
  );
};

UploadFile.defaultProps = {
  limitFile: true,
  disabled: false,
  handleRequestDeleteFile: () => {},
  requestDeleteIsConfirmed: false,
};

export default UploadFile;
