import styled from 'styled-components';

export const Container = styled.div`
  width: 230px;
  height: 201px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  border: 1px solid #D2D5D8;
  padding: 20px 12px;
  box-shadow:
    0px 0.2px 0.5px rgba(0, 0, 0, 0.029),
    0px 0.4px 1.1px rgba(0, 0, 0, 0.043),
    0px 0.8px 2.1px rgba(0, 0, 0, 0.053),
    0px 1.3px 3.8px rgba(0, 0, 0, 0.06),
    0px 2.5px 7.1px rgba(0, 0, 0, 0.065),
    0px 6px 17px rgba(0, 0, 0, 0.07)
  ;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    width: 40%;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #151B26;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    &.clear {
      font-family: ${({ theme }) => theme.fonts.familys._6};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #62656F;
    }
    &.apply {
      border: 1px solid #0D4BD0;
      border-radius: 10px;
      padding: 4px 30px;
      font-family: ${({ theme }) => theme.fonts.familys._6};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #0D4BD0;
    }
  }
  &.content-buttons {
    padding-top: 18px;
    margin-top: 15px;
    border-top: 1px solid #D9DCDF;;
  }
`;
