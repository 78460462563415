import React from 'react';

import { AnimatePresence } from 'framer-motion';
import { ItemProps } from '../types';

import CardContent from './CardContent';

import {
  Title,
  ListItem,
  TitleContainer,
} from './style';

const FolderIconSVG = () => (
  <svg
    width="20"
    fill="none"
    height="15"
    viewBox="0 0 22 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#C4C4C4"
      d="M2 19C1.45 19 0.979333 18.8043 0.588 18.413C0.196 18.021 0 17.55 0 17V4H2V17H19V19H2ZM6 15C5.45 15 4.97933 14.8043 4.588 14.413C4.196 14.021 4 13.55 4 13V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H11L13 2H20C20.55 2 21.021 2.196 21.413 2.588C21.8043 2.97933 22 3.45 22 4V13C22 13.55 21.8043 14.021 21.413 14.413C21.021 14.8043 20.55 15 20 15H6Z"
    />
  </svg>
);

const Item = ({
  title,
  category,
  propertyId,
  filesLoaded,
  imagesLoaded,
}: ItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <ListItem
      layout
      initial={{ borderRadius: 10 }}
    >
      <TitleContainer
        layout
        isOpen={isOpen}
        onClick={toggleOpen}
      >
        <FolderIconSVG />
        <Title>
          {title}
        </Title>
      </TitleContainer>
      <AnimatePresence>
        <CardContent
          isOpen={isOpen}
          category={category}
          propertyId={propertyId}
          filesLoaded={filesLoaded}
          imagesLoaded={imagesLoaded}
        />
      </AnimatePresence>
    </ListItem>
  );
};

export default Item;
