import styled from 'styled-components';
import { MAX_SIZE_MOBILE } from '../../utils';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  @media (min-width: ${MAX_SIZE_MOBILE}px) and ((max-height: 699px)) {
    max-width: 400px;
    max-height: 95vh;
    border-radius: 8px;
  }

  @media (min-width: ${MAX_SIZE_MOBILE + 1}px) and (min-height: 700px) {
    max-width: 400px;
    max-height: 668px;
    border-radius: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 32px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: none;
    height: none;
  }

  &::-webkit-scrollbar-thumb {
    width: none;
    height: none;
  }

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    max-width: 400px;
    max-height: 80vh;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #425987;

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    border-radius: 8px 8px 0 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.palette.blue._970.hex()};

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    border-radius: 8px 8px 0 0;
  }
`;

export const Title = styled.h5`
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.white.main.hex()};
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 32px);
  height: auto;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentItems = styled.div`
  width: 100%;
  height: auto;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  word-break: break-all;
  word-wrap: break-word;
  padding: 16px 0;

  &.attachments {
    border-bottom: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Text = styled.p`
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  line-height: 22px;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  padding-bottom: 8px;

  @media(max-width: 360px) and (min-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const TextSmall = styled(Text)`
  font-size: 12px;
  line-height: 16px;
`;

export const TextBold = styled.p`
  font-family: Inter;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  line-height: 24px;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;

  @media(max-width: 360px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    font-weight: 400;
    line-height: 22px;
  }
`;

export const TextBoldBig = styled(TextBold)`
  font-size: 22px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  line-height: 32px;

  @media(max-width: 360px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0 20px 0;
`;

export const Button = styled.button`
  width: calc(100% - 16px);
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.blue._400.hex()};
  border-radius: 10px;
  padding: 8px, 70px, 8px, 70px;
  gap: 10px;

  p {
    font-family: Inter;
    font-weight: 700;
    line-height: 14px;
    font-size: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;
