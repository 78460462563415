import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { config } from '../utils/config';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../../../../hooks/useMobile/useMobile';
import {
  shimmerAnimation,
} from '../../../../../../../../../components/FinancialFeedback/Body/CardList/CardShimmer/styles';

export const Image = styled.div`
  width: 100%;
  height: 100%;

  img {
    && {
      background: white;
      animation: ${shimmerAnimation} 0.5s ease infinite;
    }
  }
`;
export const ImageBackground = styled.div`
  width: 100%;
  height: ${config.height};
  z-index: 22;
`;

export const Container = styled.div<{
  withShareButton?: boolean;
}>`
  width: ${config.width};
  height: ${config.height};
  position: relative;
  overflow: hidden;

  border-radius: 10px;
  background: var(--neuter-50, #f9f9f9);

  display: grid;
  grid-template-rows: 1fr 50px;
  grid-template-columns: 1fr ${config.image.break};

  ${({ withShareButton }) => withShareButton
    && css`
      grid-template-rows: 1fr;
    `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 10px;
  padding: 20px;

  h1 {
    color: var(--darkk-blue-900, #001840);

    /* Heading 4 */
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  p {
    color: var(--gray-800, #394760);

    /* fonte/corpo/legenda */
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
`;
export const Text = styled(motion.div)`
  position: absolute;
  bottom: 30px;

  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  z-index: 22;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    left: auto;
    transform: auto;
  }

  h1 {
    color: var(--neuter-50, #f9f9f9);

    /* Heading 5 */
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }
`;

export const ShareContainer = styled.div`
  background: orange;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;

  background: #fc605b;

  svg {
    cursor: pointer;
    user-select: none;
  }

  h1 {
    color: var(--neuter-50, #f9f9f9);
    cursor: pointer;
    user-select: none;
    /* Heading 5 */
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  }
`;

export const ImageContent = styled.div`
  position: relative;
`;

export const ImageContainer = styled.div`
  position: absolute;

  right: -1px;
  height: 100%;
  width: calc(${config.image.break} + 1px);

  &::after {
    content: "";
    position: absolute;

    right: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;

    backdrop-filter: blur(1px);
  }

  img {
    position: relative;
    width: 100%;
    object-fit: cover;
    min-height: 100%;
    height: ${config.height};
    background: #ccc;
  }
`;
