import React, { useState, useEffect } from 'react';

import ConfirmPay from './ConfirmPay';
import MainContent from './MainContent';
import AddVoucherScreen from './AddVoucherScreen';
import ConfirmScreen from './AddVoucherScreen/ConfirmScreen';

import { useToast } from '../../../../context/ToastContext';
import { getPaymentDataById, getPaymentStatus } from '../../../../services/PaymentVoucher/request';
import { usePaymentVoucher } from '../../../../hooks/PaymentVoucherHook/usePaymentVoucher';

import {
  X, Backdrop, Content, Container, CloseButton,
} from './styles';

const Modal = () => {
  const {
    paymentDataStatus,
    handleChangeModalOpen,
    handleChangePaymentData,
    reservationIdEditedButton,
    handleChangePaymentDataStatus,
    handleChangeReservationIdEditedButton,
  } = usePaymentVoucher();

  const [showSendEmailScreen, setShowSendEmailScreen] = useState(false);
  const [showAddVoucherScreen, setShowAddVoucherScreen] = useState(false);
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);

  const toast = useToast();

  const handleGetPaymentDataStatus = async () => {
    try {
      const response = await getPaymentStatus();
      handleChangePaymentDataStatus(response);
      return null;
    } catch (e) {
      if (e instanceof Error) {
        return e;
      }
      return null;
    }
  };

  const handleGetPaymentDataById = async () => {
    try {
      const response = await getPaymentDataById(reservationIdEditedButton);
      return handleChangePaymentData(response);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === 'Request failed with status code 404') {
          return null;
        }
        return toast.error(e.message);
      }
      return null;
    }
  };

  const handleOpenSendEmailScreen = () => {
    setShowSendEmailScreen(true);
  };

  const handleOpenShowAddVoucherScreen = () => {
    setShowAddVoucherScreen(true);
  };

  const handleCloseShowAddVoucherScreen = () => {
    setShowAddVoucherScreen(false);
  };

  const handleCancelAllSelectedItems = () => {
    handleChangeReservationIdEditedButton(null);
    handleChangeModalOpen(false);
    handleGetPaymentDataStatus();
    const newPaymentDataStatusCheckedCompletes = paymentDataStatus.completes.map((item) => ({
      ...item,
      checked: false,
    }));

    const newPaymentDataStatusCheckedPendings = paymentDataStatus.pendings.map((item) => ({
      ...item,
      checked: false,
    }));

    handleChangePaymentDataStatus({
      completes: newPaymentDataStatusCheckedCompletes,
      pendings: newPaymentDataStatusCheckedPendings,
    });
  };

  useEffect(() => {
    handleGetPaymentDataById();
  }, []);

  // -- Components return --

  const SendEmailScreenComponent = () => {
    if (showSendEmailScreen) {
      return (
        <ConfirmPay
          onCloseModal={() => handleCloseShowAddVoucherScreen()}
          onCloseScreen={setShowSendEmailScreen}
        />
      );
    }
    return null;
  };

  const VoucherScreenComponent = () => {
    if (showAddVoucherScreen) {
      return (
        <AddVoucherScreen
          showConfirmaionScreen={() => setShowConfirmationScreen(true)}
          handleCloseShowAddVoucherScreen={() => handleCloseShowAddVoucherScreen()}
        />
      );
    }
    return null;
  };

  const MainContentComponent = () => {
    if (!showSendEmailScreen && !showAddVoucherScreen) {
      return (
        <MainContent
          onClickFinish={handleOpenSendEmailScreen}
          handleOpenShowAddVoucherScreen={handleOpenShowAddVoucherScreen}
        />
      );
    }
    return null;
  };

  const CloseButtonComponent = () => (
    <CloseButton onClick={() => handleCancelAllSelectedItems()}>
      <X />
      <h1>Fechar</h1>
    </CloseButton>
  );

  if (showConfirmationScreen) {
    return (
      <>
        <Container>
          <CloseButtonComponent />

          <Content>
            <ConfirmScreen
              onCancel={() => setShowConfirmationScreen(false)}
              onFinish={() => handleCancelAllSelectedItems()}
            />
          </Content>
        </Container>

        <Backdrop onClick={() => handleCancelAllSelectedItems()} />
      </>
    );
  }

  return (
    <>
      <Container>
        <CloseButtonComponent />

        <Content>
          <MainContentComponent />

          <VoucherScreenComponent />

          <SendEmailScreenComponent />
        </Content>
      </Container>

      <Backdrop onClick={() => handleCancelAllSelectedItems()} />
    </>
  );
};

export default Modal;
