import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

interface ITagContext {
  isExpandedDailyExecuted: boolean,
  setIsExpandedDailyExecuted: (isExpanded: boolean) => void,
  isExpandedCleaningExecuted: boolean,
  setIsExpandedCleaningExecuted: (isExpanded: boolean) => void,
  isExpandedDailyPaid: boolean,
  setIsExpandedDailyPaid: (isExpanded: boolean) => void,
  isExpandedCleaningPaid: boolean,
  setIsExpandedCleaningPaid: (isExpanded: boolean) => void,
  isExpandedOthersReceipts: boolean,
  setIsExpandedOthersReceipts: (isExpanded: boolean) => void,
  isExpandedCashOut: boolean,
  setIsExpandedCashOut: (isExpanded: boolean) => void,
  isExpandedWallet: boolean,
  setIsExpandedWallet: (isExpanded: boolean) => void,
}

export const TagContext = createContext<ITagContext>({
  isExpandedDailyExecuted: false,
  setIsExpandedDailyExecuted: () => {},
  isExpandedCleaningExecuted: false,
  setIsExpandedCleaningExecuted: () => {},
  isExpandedDailyPaid: false,
  setIsExpandedDailyPaid: () => {},
  isExpandedCleaningPaid: false,
  setIsExpandedCleaningPaid: () => {},
  isExpandedOthersReceipts: false,
  setIsExpandedOthersReceipts: () => {},
  isExpandedCashOut: false,
  setIsExpandedCashOut: () => {},
  isExpandedWallet: false,
  setIsExpandedWallet: () => {},
});

export const TagProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [isExpandedDailyExecuted, setIsExpandedDailyExecuted] = useState<boolean>(false);
  const [isExpandedCleaningExecuted, setIsExpandedCleaningExecuted] = useState<boolean>(false);
  const [isExpandedDailyPaid, setIsExpandedDailyPaid] = useState<boolean>(false);
  const [isExpandedCleaningPaid, setIsExpandedCleaningPaid] = useState<boolean>(false);
  const [isExpandedOthersReceipts, setIsExpandedOthersReceipts] = useState<boolean>(false);
  const [isExpandedCashOut, setIsExpandedCashOut] = useState<boolean>(false);
  const [isExpandedWallet, setIsExpandedWallet] = useState<boolean>(false);

  const handleChangeIsExpandedDailyExecuted = useCallback((isExpanded: boolean) => {
    setIsExpandedDailyExecuted(isExpanded);
  }, []);

  const handleChangeIsExpandedCleaning = useCallback((isExpanded: boolean) => {
    setIsExpandedCleaningExecuted(isExpanded);
  }, []);

  const handleChangeIsExpandedDailyPaid = useCallback((isExpanded: boolean) => {
    setIsExpandedDailyPaid(isExpanded);
  }, []);

  const handleChangeIsExpandedCleaningPaid = useCallback((isExpanded: boolean) => {
    setIsExpandedCleaningPaid(isExpanded);
  }, []);

  const handleChangeIsExpandedOthersReceipts = useCallback((isExpanded: boolean) => {
    setIsExpandedOthersReceipts(isExpanded);
  }, []);

  const handleChangeIsExpandedCashOut = useCallback((isExpanded: boolean) => {
    setIsExpandedCashOut(isExpanded);
  }, []);

  const handleChangeIsExpandedWallet = useCallback((isExpanded: boolean) => {
    setIsExpandedWallet(isExpanded);
  }, []);

  const value = useMemo(() => ({
    isExpandedDailyExecuted,
    setIsExpandedDailyExecuted: handleChangeIsExpandedDailyExecuted,
    isExpandedCleaningExecuted,
    setIsExpandedCleaningExecuted: handleChangeIsExpandedCleaning,
    isExpandedDailyPaid,
    setIsExpandedDailyPaid: handleChangeIsExpandedDailyPaid,
    isExpandedCleaningPaid,
    setIsExpandedCleaningPaid: handleChangeIsExpandedCleaningPaid,
    isExpandedOthersReceipts,
    setIsExpandedOthersReceipts: handleChangeIsExpandedOthersReceipts,
    isExpandedCashOut,
    setIsExpandedCashOut: handleChangeIsExpandedCashOut,
    isExpandedWallet,
    setIsExpandedWallet: handleChangeIsExpandedWallet,
  }), [
    isExpandedDailyExecuted,
    setIsExpandedDailyExecuted,
    isExpandedCleaningExecuted,
    setIsExpandedCleaningExecuted,
    isExpandedDailyPaid,
    setIsExpandedDailyPaid,
    isExpandedCleaningPaid,
    setIsExpandedCleaningPaid,
    isExpandedOthersReceipts,
    setIsExpandedOthersReceipts,
    isExpandedCashOut,
    setIsExpandedCashOut,
    isExpandedWallet,
    setIsExpandedWallet,
  ]);

  return (
    <TagContext.Provider
      value={value}
    >
      {children}
    </TagContext.Provider>
  );
};
