import styled, { keyframes } from 'styled-components';

import { CARDGRIDTEMPLATECOLUM } from '../../../utils';

export const animationTime = 2;

export const shimmerAnimation = keyframes`
  0% {
      background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
      background-size: 400% 400%;
      background-position: 0% 0%;
    }
  50% {
    background-position: -135% 0%;
  }
  100%{
    background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
    background-size: 400% 400%;
    background-position: 0% 0%;
  }
`;

const time = '1s';

const radiusBorder = '10px';

export const Container = styled.div`
  height: fit-content;
  padding: 20px;
  width: 100%;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Status = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  animation: ${shimmerAnimation} ${time} infinite ease forwards;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  width: 100%;
  border-radius: 5px;
  height: 20px;
  animation: ${shimmerAnimation} ${time} infinite ease forwards;
`;

export const Description = styled.p`
  font-size: 1.3rem;
  font-size: 1.5rem;
  width: 100%;
  border-radius: 6px;
  height: 100%;
  animation: ${shimmerAnimation} ${time} infinite ease forwards;
`;

export const CardInfo = styled.div`
  min-height: 20px;
  border-radius: 5px;
  width: 50%;
  animation: ${shimmerAnimation} ${time} infinite ease forwards;
`;

export const LeftSide = styled.div`
  border-top-left-radius: ${radiusBorder};
  border-bottom-left-radius: ${radiusBorder};
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 15px;
  background-color: ${({ theme }) => theme.palette.white._700.hex()};
`;

export const RightSide = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: grid;
  border-top-right-radius: ${radiusBorder};
  align-items: center;
  grid-template-columns: ${CARDGRIDTEMPLATECOLUM};
  background-color: ${({ theme }) => theme.palette.white._750.hex()};
  padding: 20px 15px;
`;

export const Body = styled.div`
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-right-radius: ${radiusBorder};
`;

export const ValueBody = styled.div`
  grid-template-columns: ${CARDGRIDTEMPLATECOLUM};
  grid-template-rows: 1fr;
  display: grid;
  width: 100%;
  height: 100%;
  padding: 20px 15px;

  &:first-child {
    border-top: none;
  }

  border-top: 1px solid #d2d4d8;
`;
