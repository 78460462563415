import styled from 'styled-components';

export const Wrapper = styled.div<{
  openModal: boolean;
}>`
  position: fixed;
  z-index: 9999;
  left: 0;
  display: ${({ openModal }) => (openModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
`;

export const Container = styled.div`
  position: absolute;
  z-index: 99999;

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  height: 100vh;
  max-height: calc(100% - 50px);
  padding: 0rem 0rem 10rem 0rem;
  border-radius: 10px;
  background-color: white;

  overflow-x: hidden;
  overflow-y: auto;
  
  ::-webkit-scrollbar {
      width: 0.3rem;
      height: 8px;
      background: ${({ theme }) => theme.palette.grey._320.hex()};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      border: 0.1px solid ${({ theme }) => theme.palette.grey._320.hex()};
    }
    
  section {
    display: flex;
  }

  @media(max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  padding: 4rem;
  width: 100%;
  height: auto;
  break-before: page;

  @media(max-width: 768px) {
    padding: 1.5rem;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  height: 100%;
  max-height: 150px;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 8rem 0 3rem 0;

  button {
    min-width: 449px;
    max-height: 40px;
    margin-top: 0.8rem;
  }

  @media(max-width: 768px) {
    margin: 4rem 0 1.5rem 0;

    button {
      min-width: 249px;
      max-height: 40px;
      margin-top: 0.8rem;
    }
  }
`;

export const ProgressContainer = styled.div`
  position: fixed;
  bottom: 0;

  transition: 0.5s;

  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  backdrop-filter: blur(2px);

  opacity: 1;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  max-width: 900px;
  max-height: 180px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
`;
