import styled from 'styled-components';
import { Dialog, DialogContent } from '@mui/material';

export const Background = styled(Dialog)`
  && {
    .MuiPaper-rounded {
      border-radius: 10px;
      max-width: 1045px;
    }
    .MuiPaper-elevation24 {
      box-shadow: none;
    }
  }
`;

export const ModalBox = styled(DialogContent)`
  && {
    padding: 0px !important;
  }

  && {
    p, strong, h1, input, .MuiButton-root > .MuiButton-label {
      font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
      font-style: normal;
      font-weight: 500;
    }
  }
`;
