import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.white.main.hex()};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 20px 16px 0 16px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 15px 16px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.grey._425.hex()};
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 30;
    box-shadow: 0px 2px 4px 0px #00000040;
  }
`;

export const TitleContainer = styled.div``;

export const TitleH1 = styled.h1`
  font-size: 20px;
  line-height: 25px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  font-style: normal;
  font-weight: 600;
`;

export const TitleH2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.black.main.hex()};
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`;

export const DividerContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  background: ${({ theme }) => theme.palette.white.main.hex()};
`;

export const Divider = styled.div`
  border-top: 0.5px solid ${({ theme }) => theme.palette.grey._450.hex()};
  margin: 0.5rem 0;
`;

export const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 400;
  margin: 8px 0;
`;
