import styled from 'styled-components';
import { desktopGridLayout, mobileGridLayout } from '../../utils';
import { shimmerAnimation } from './animationShimmer';

export const Container = styled.div`
  height: 60px;
  width: 100%;

  border-radius: 10px;

  margin: 20px 0;

  &:nth-child(2n) {
    animation: ${shimmerAnimation} 2s ease infinite forwards;
  }

  background: red;
  animation: ${shimmerAnimation} 1s ease infinite forwards;

  gap: 20px;
  display: grid;
  grid-template-columns: ${desktopGridLayout};

  @media screen and (max-width: 600px) {
    grid-template-columns: ${mobileGridLayout};
    width: max-content;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 1.1rem;
    font-family: ${({ theme }) => theme?.fonts?.familys?._1};
    color: ${({ theme }) => theme?.palette?.grey?._900?.hex()};
    font-weight: 500;
  }

  padding: 10px;
  margin: 10px 0;
  transition: 0.3s;

  button {
    border: none;
    background: none;
    transition: 0.2s;

    &:hover {
      img {
        opacity: 0.9;
      }
    }
  }

  img {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const RegisterDatePickerShimmerContainer = styled.div`
  p {
    font-size: 1rem;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    margin-bottom: 5px;
    opacity: 0.8;
  }
  div {
    border-radius: 10px;
    height: 45px;
    width: 150px;
    animation: ${shimmerAnimation} 1s ease infinite forwards;
  }
`;
