import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.button)`
  z-index: 90;
  position: fixed;
  right: 0;
  top: 70%;
  border: none;
  width: 200px;
  background-color: transparent;
`;

export const Content = styled(motion.div)`
  background-color: #58ab92;
  border-radius: 14px 0 0 14px;
  padding: 10px;
  border: none;

  display: flex;

  justify-content: left;
  align-items: center;
  gap: 30px;
`;
