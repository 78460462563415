import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../hooks/useMobile/useMobile';

export function setScrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export const partnerMediaValidation = () => {
  if (
    window.innerHeight > 620
    || window.innerWidth < DEFAULT_WIDTH_TO_DESKTOP
  ) {
    return true;
  }
  return false;
};

export const callWhatsAppNumber = (phone?: string, name?: string) => {
  const whatsapp = {
    message: 'Olá',
    phone: '5548998200407',
  };

  if (phone && name) {
    const link = `https://api.whatsapp.com/send?phone=${phone}&text=${`${whatsapp.message}, ${name}`}`;
    window.open(link);
  } else {
    const link = `https://api.whatsapp.com/send?phone=${whatsapp.phone}&text=${whatsapp.message}`;
    window.open(link);
  }
};
