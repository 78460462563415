import {
  FC, useEffect, useMemo,
} from 'react';

import {
  Form,
  Container,
  CardsContainer,
} from './styles';

import CardPropertyAmenities from '../CardPropertyAmenities';
import usePropertyManager from '../../../../hooks/usePropertyManager';

import { UseFormik } from '../../../../utils/Formik/types';
import { getPropertyAmenitiesById } from '../../../../services/InsertData/request';
import { initialValuesAmenities } from '../../../../context/InsertPropertyAmenitiesContext/utils';
import { usePropertyAmenities } from '../../../../hooks/usePropertyAmenities/usePropertyAmenities';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import { formatPropertiesDropdown } from '../../utils';

interface PropertyAmenitiesFormikValuesType {
  id: number;
  property: number,
}

type FormAddAmenitiesProps = {
  formik?: UseFormik<PropertyAmenitiesFormikValuesType>,
};

const FormAddPropertyAmenities:FC<FormAddAmenitiesProps> = ({ formik }) => {
  const { data: properties, loading: propertiesLoading } = usePropertyManager();

  const filteredProperties = useMemo(() => {
    const result = properties?.filter((property) => property.status === 'Active' || property.status === 'Onboarding');
    return result;
  }, [properties]);

  const formatted = useMemo(() => {
    const result = formatPropertiesDropdown(filteredProperties);
    return result;
  }, [filteredProperties]);

  const {
    allAmenities,
    handleUpdateAmenitie,
    handleSetAllAmenities,
  } = usePropertyAmenities();

  type Amenities = {
    key: string,
    name: string,
    value: boolean,
  };

  async function handlePropertyInitialValues(id: number) {
    const results: any = await getPropertyAmenitiesById(id);
    if (results?.length > 0) {
      let newAmenities: Amenities[] = [];

      allAmenities.forEach((amenitie) => {
        newAmenities = [...newAmenities, {
          ...amenitie,
          value: amenitie.key in results[0] ? results[0]?.[amenitie.key] : false,
        }];
      });

      formik?.setFieldValue('id', results[0]?.id);
      handleSetAllAmenities(newAmenities);
    } else {
      handleSetAllAmenities(initialValuesAmenities);
    }
  }

  useEffect(() => {
    if (formik?.values.property && formik?.values.property !== -1) {
      handlePropertyInitialValues(formik?.values.property);
    }
  }, [formik?.values.property]);

  return (
    <Container>
      <Form>
        <div className="one-column">
          <DropdownAutocomplete
            required
            id="property"
            formik={formik}
            options={formatted}
            loading={propertiesLoading}
            label="Selecione o Imóvel"
            placeholder="Digite aqui..."
          />
        </div>
        <div className="one-column">
          <CardsContainer>
            {allAmenities.map((amenitie) => (
              <CardPropertyAmenities
                required
                amenities={amenitie}
                updateAmenitie={handleUpdateAmenitie}
              />
            ))}
          </CardsContainer>
        </div>
      </Form>
    </Container>
  );
};

export default FormAddPropertyAmenities;
