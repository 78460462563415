import React, {
  FC,
  createContext,
  useContext,
  ReactElement,
  useState,
} from 'react';

import { AlertColor as Color } from '@mui/material/Alert';
import Toast from '../components/Toast/Toast';

export interface ToastOptionType {
  type?: Color,
  autoHideDuration?: number,
}

export type ToastFunction = (message: string | string[], option?: ToastOptionType) => void;

export interface ToastType {
  alert: ToastFunction,
  success: ToastFunction,
  error: ToastFunction
}

export const ToastContext = createContext<ToastType>({
  alert: () => {},
  success: () => {},
  error: () => {},
});

export const ToastProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string | string[]>('');
  const [option, setOptions] = useState<ToastOptionType | null>(null);

  const handleToast = (toastMessage: string | string[], toastOption?: ToastOptionType) => {
    setMessage(toastMessage);
    setShow(true);
    setOptions(toastOption || null);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <ToastContext.Provider
      value={{
        alert: handleToast,
        success: (toastMessage: string | string[], toastOption?: ToastOptionType) => {
          const op: ToastOptionType = {
            ...toastOption,
            type: 'success',
          };
          handleToast(toastMessage, op);
        },
        error: (toastMessage: string | string[], toastOption?: ToastOptionType) => {
          const op: ToastOptionType = {
            ...toastOption,
            type: 'error',
          };
          handleToast(toastMessage, op);
        },
      }}
    >
      <Toast
        show={show}
        message={message}
        handleClose={handleClose}
        autoHideDuration={option?.autoHideDuration || 6000}
        type={option?.type || 'info'}
      />
      {children}
    </ToastContext.Provider>

  );
};

export const useToast = () => useContext(ToastContext);
