import styled, { keyframes } from 'styled-components';

const shakeAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftSide = styled.div<{
  isOpen: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    span {
      transition: 0.4s;
      opacity: ${({ isOpen }) => (isOpen ? '1' : '0.6')};
      margin-top: -5px;
      display: flex;
      justify-content: baseline;
      flex-direction: row;
      align-items: center;
      font-size: 1.4rem;
      font-family: ${({ theme }) => theme.fonts.familys._1};
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
  }

  img {
    transition: 0.3s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(-90deg)')};
    width: 12px;
    cursor: pointer;
  }
`;
export const Title = styled.div<{
  isOpen: boolean;
}>`
  transition: 0.4s;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0.6')};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: bold;
  cursor: pointer;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  width: 100%;
  max-width: 40px;
  height: 100%;
  max-height: 40px;

  img {
    width: 20px;
    transition: 0.4s;

    &:hover {
      animation: ${shakeAnimation} 0.8s ease;
    }
  }
`;
