import request from '../request';
import { IGetFilesByFileUid } from './types';

export const getFilesByFileUid = async (
  fileUid: string,
  signal?: AbortSignal,
  setProgress?: React.Dispatch<React.SetStateAction<number>>,
): Promise<IGetFilesByFileUid> => request
  .get(`/files/${fileUid}/`, {
    signal,
    onDownloadProgress: (event) => {
      const progress: number = Math.round((event.loaded * 100) / event.total);
      if (setProgress) setProgress(progress);
    },
  })
  .then((response) => response.data)
  .catch((error) => {
    if (error.response) {
      if (error.response.status === 423) {
        return 'Processing';
      }
    }
    return error;
  });
