import { EnumType } from '../../../../types/paths';
import textsJSON from './texts.json';

export enum ENUMPropertyType {
  apartment = 'Apartment',
  house = 'House',
  hotel = 'Hotel',
}

export const typeOptions = [
  {
    id: 1,
    value: ENUMPropertyType.apartment,
    valueLabel: 'Apartamento',
  },
  {
    id: 2,
    value: ENUMPropertyType.house,
    valueLabel: 'Casa',
  },
  {
    id: 3,
    value: ENUMPropertyType.hotel,
    valueLabel: 'Hotel',
  },
];

export interface ITexts {
  headerTitle: string;
  headerDescription: string;
}

export const initialText: ITexts = textsJSON.default;

export function validateText(type: string | undefined): ITexts { // type is IType
  switch (type) {
    case EnumType.terrain:
      return textsJSON.terrain;
    case EnumType.location:
      return textsJSON.location;
    case EnumType.spot:
      return textsJSON.spot;
    default:
      return textsJSON.default;
  }
}
