import React from 'react';
import { useNavigate } from 'react-router-dom';
import { partnersRoutes } from '../../../../types/paths';

import { TitleCards, CardContainerTemplate } from '../utils';

import {
  Item, Content, Container, ItemsContainer,
} from './style';

import { TagSVGIcon, SpeakerSVGIcon, LocationSVGIcon } from './svg';

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 50 },
};

const whileTap = {
  scale: 0.9,
  border: '1px solid #BFC9E1',
};

const Category = () => {
  const navigate = useNavigate();

  const formLink = `/${partnersRoutes.partners.main}/${partnersRoutes.indicate.main}/${partnersRoutes.indicate.form.main}`;

  const handleFormNavigation = (type: string) => {
    navigate(`${formLink}/${type}`);
  };

  return (
    <CardContainerTemplate>
      <Container
        initial={{
          opacity: 0,
          y: 10,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
      >
        <Content
          transition={{
            delay: 1,
          }}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          <TitleCards>
            Escolha uma categoria e cadastre uma nova indicação
          </TitleCards>

          <ItemsContainer>
            <Item
              variants={item}
              whileTap={whileTap}
              onClick={() => handleFormNavigation(partnersRoutes.indicate.form.terrain)}
            >
              <LocationSVGIcon />
              <h1>
                Indicar
                <br />
                Terreno
              </h1>
            </Item>
            <Item
              variants={item}
              whileTap={whileTap}
              onClick={() => handleFormNavigation(partnersRoutes.indicate.form.location)}
            >
              <SpeakerSVGIcon />
              <h1>
                Indicar para
                <br />
                Locação
              </h1>
            </Item>
            <Item
              variants={item}
              whileTap={whileTap}
              onClick={() => handleFormNavigation(partnersRoutes.indicate.form.spot)}
            >
              <TagSVGIcon />
              <h1>
                Vender
                <br />
                SPOTS
              </h1>
            </Item>
          </ItemsContainer>
        </Content>
      </Container>
    </CardContainerTemplate>
  );
};

export default Category;
