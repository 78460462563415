import styled from 'styled-components';
import { FormLabel, FormControlLabel, Radio } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 700ms;
`;

export const FormLabelStylized = styled(FormLabel)`
  && {
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
  }
`;

export const FormControlLabelStylized = styled(FormControlLabel)<{ isDeploymentFeeValues: boolean }>`
  && {
    margin-bottom: ${({ isDeploymentFeeValues }) => (isDeploymentFeeValues && '16px')};
    span {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
      font-weight: ${({ isDeploymentFeeValues }) => (isDeploymentFeeValues ? '400' : '700')};
      font-size: 14px;
      line-height: 17.5px;
      text-align: center;
    }
  }
`;

export const RadioStylized = styled(Radio)`
  && {
    & svg.MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.grey._450.hex()};
    }

    & svg.MuiSvgIcon-root.PrivateRadioButtonIcon-layer-6 {
      color: ${({ theme }) => theme.palette.blue._400.hex()};
    }

    &.MuiRadio-colorSecondary.Mui-checked {
      & svg.MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.blue._400.hex()};
      }
    }
  }
`;

export const CardContainer = styled.div<{
  isChecked?: boolean;
}>`
  background: ${({ isChecked }) => (!isChecked ? ({ theme }) => theme.palette.white._350.hex() : '#D3DCE5')};
  width: 180px;
  height: 130px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 700ms;
  margin: 0.5rem 1rem 0.5rem 0;
`;

export const SimpleSelectContainer = styled.div`
  position: relative;
  bottom: 1rem;
`;
