import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const UploadWrapper = styled.div<{
  loading?: boolean;
}>`
  display: flex;
  flex-direction: row;
  gap: 10px;

  input {
    display: none;
  }

  ${({ loading }) => loading
    && css`
      div {
        div {
          img {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              background-color: #000;
              width: 100%;
              height: 100%;
              opacity: 0.5;
              z-index: 200;
            }
          }
        }
      }
    `}
`;
