import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FavoriteButton = styled(motion.div)`
`;

export const DotOptions = styled.div`
`;

export const SubTitleContainer = styled.div``;
