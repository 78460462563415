import React, {
  createContext, useState, ReactNode, useContext,
} from 'react';

interface UpdateReservationContextData {
  updateReservation: number;
  setUpdateReservation: (a: number) => void;
  reloadLoadingShimmer: boolean;
  setReloadLoadingShimmer: (a: boolean) => void;
}

interface Props {
  children: ReactNode;
}

const UpdateReservationContext = createContext<UpdateReservationContextData>({} as
  UpdateReservationContextData);

export const UpdateReservationProvider = ({
  children,
}: Props) => {
  const [updateReservation, setUpdateReservation] = useState<number>(0);
  const [reloadLoadingShimmer, setReloadLoadingShimmer] = useState<boolean>(false);

  return (
    <UpdateReservationContext.Provider value={{
      updateReservation,
      setUpdateReservation,
      reloadLoadingShimmer,
      setReloadLoadingShimmer,
    }}
    >
      {children}
    </UpdateReservationContext.Provider>
  );
};

export function useUpdateReservation(): UpdateReservationContextData {
  const {
    updateReservation,
    setUpdateReservation,
    reloadLoadingShimmer,
    setReloadLoadingShimmer,
  } = useContext(UpdateReservationContext);

  return {
    updateReservation,
    setUpdateReservation,
    reloadLoadingShimmer,
    setReloadLoadingShimmer,
  };
}
