/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Lottie, { LottieComponentProps } from 'lottie-react';

import animationSource from '../source/trash.json';

interface TrashAnimationProps extends Omit<LottieComponentProps, 'animationData'> {
  startPlay?: boolean;
}

export const TrashAnimation = ({
  startPlay = false,
  ...rest
}: TrashAnimationProps) => (
  <Lottie
    {...rest}
    autoPlay={startPlay}
    animationData={animationSource}
  />
);

TrashAnimation.defaultProps = {
  startPlay: false,
};
