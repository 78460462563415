import styled from 'styled-components';
import { shimmerAnimation } from '../../../../../../../../components/Calendar/LoadingShimmer/animationShimmer';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;
export const Content = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
`;

export const SwitcherContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const SwitcherOption = styled.h1<{
  active: boolean;
}>`
  color: var(--gray-800, #394760);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  opacity: ${({ active }) => (active ? '1' : '0.5')};
`;

export const CitysShimmer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;
  justify-content: center;
  height: 40px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #bec6cc;
  animation: ${shimmerAnimation} 1s ease infinite;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 1px;
    width: 0;
    height: 0;

    transform: scale(0.4);

    border-left: 15px solid transparent;
    border-radius: 3px;
    background-color: red;
    border-right: 15px solid transparent;
    border-top: 15px solid #bec6cc;
    font-size: 0;
    line-height: 0;
    float: left;
    animation: ${shimmerAnimation} 1s ease infinite;
  }

  p {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const DeleteButton = styled.button`
  background: transparent;
  border: 0;
`;

export const TooltipText = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-family: sans-serif;

  h1 {
    font-family: sans-serif;
    font-size: 1.2rem;
    font-weight: medium;
  }
  p {
    font-family: sans-serif;
    font-size: 1.2rem;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ContainerLabel = styled.div`
 .label-description  {
    margin: 0.3rem 0 0.6rem 0;
    font-size: 0.7rem;
    font-weight: 500;
    font-family: Inter;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
 }
`;

export const ContentLabel = styled.div`
  display: flex;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;
