import { useContextSelector } from 'use-context-selector';
import { OwnerTedListContext } from '../../context/OwnerTedList/OwnerTedListContext';

export function useOwnerTedList() {
  const listItems = useContextSelector(OwnerTedListContext,
    (state) => state.listItems);
  const handleListItems = useContextSelector(OwnerTedListContext,
    (state) => state.setListItems);

  const filteredItems = useContextSelector(OwnerTedListContext,
    (state) => state.filteredItems);
  const handleFilteredItems = useContextSelector(OwnerTedListContext,
    (state) => state.setFilteredItems);

  const loading = useContextSelector(OwnerTedListContext,
    (state) => state.loading);
  const handleLoading = useContextSelector(OwnerTedListContext,
    (state) => state.setLoading);

  const openModalID = useContextSelector(OwnerTedListContext,
    (state) => state.openModalID);
  const handleOpenModalID = useContextSelector(OwnerTedListContext,
    (state) => state.setOpenModalID);

  const openModalConfirmDeleteVoucher = useContextSelector(OwnerTedListContext,
    (state) => state.openModalConfirmDeleteVoucher);
  const handleOpenModalConfirmDeleteVoucher = useContextSelector(OwnerTedListContext,
    (state) => state.setOpenModalConfirmDeleteVoucher);

  const deleteVoucherIsConfirmed = useContextSelector(OwnerTedListContext,
    (state) => state.deleteVoucherIsConfirmed);
  const handleDeleteVoucherIsConfirmed = useContextSelector(OwnerTedListContext,
    (state) => state.setDeleteVoucherIsConfirmed);

  const modalImage = useContextSelector(OwnerTedListContext,
    (state) => state.modalImage);
  const handleModalImage = useContextSelector(OwnerTedListContext,
    (state) => state.setModalImage);

  const orderGridBy = useContextSelector(OwnerTedListContext,
    (state) => state.orderGridBy);
  const setOrderGridBy = useContextSelector(OwnerTedListContext,
    (state) => state.setOrderGridBy);

  const handleUpdateCheckedOfTedSelected = useContextSelector(OwnerTedListContext,
    (state) => state.updateCheckedOfTedSelected);

  const handleUpdateAllCheckedsOfTedSelected = useContextSelector(OwnerTedListContext,
    (state) => state.updateAllCheckedsOfTedSelected);

  const buttonUpdateStatusIsVisible = useContextSelector(OwnerTedListContext,
    (state) => state.buttonUpdateStatusIsVisible);

  const openModalUpdateTedStatus = useContextSelector(OwnerTedListContext,
    (state) => state.openModalUpdateTedStatus);

  const handleOpenModalUpdateTedStatus = useContextSelector(OwnerTedListContext,
    (state) => state.setOpenModalUpdateTedStatus);

  const openModalFilters = useContextSelector(OwnerTedListContext,
    (state) => state.openModalFilters);

  const handleOpenModalFilters = useContextSelector(OwnerTedListContext,
    (state) => state.setOpenModalFilters);

  const filters = useContextSelector(OwnerTedListContext,
    (state) => state.filters);

  const handleSetFilters = useContextSelector(OwnerTedListContext,
    (state) => state.setFilters);

  return {
    listItems,
    handleListItems,
    filteredItems,
    handleFilteredItems,
    loading,
    handleLoading,
    openModalID,
    handleOpenModalID,
    openModalConfirmDeleteVoucher,
    handleOpenModalConfirmDeleteVoucher,
    deleteVoucherIsConfirmed,
    handleDeleteVoucherIsConfirmed,
    modalImage,
    handleModalImage,
    orderGridBy,
    setOrderGridBy,
    handleUpdateCheckedOfTedSelected,
    handleUpdateAllCheckedsOfTedSelected,
    buttonUpdateStatusIsVisible,
    openModalUpdateTedStatus,
    handleOpenModalUpdateTedStatus,
    openModalFilters,
    handleOpenModalFilters,
    filters,
    handleSetFilters,
  };
}
