/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Close /* ReportProblemOutlined */ } from '@mui/icons-material';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Bank } from '../../../services/Bank/types';
import { BankDetails, PixType } from '../../../services/Owner/types';
import { patchHostBank, postHostBank } from '../../../services/Host/request';
import { getBanks } from '../../../services/Bank/request';
import { accountTypeOptions } from '../../../services/Bank/utils';
import { cpf, cnpj } from '../../../utils/InputMask/Number';

import { useToastErrorMessage } from '../../../utils/Messages';
import { useToast } from '../../../context/ToastContext';

import SimpleSelect, { SelectOption } from '../../SimpleSelect/SimpleSelect';
import TextField from '../../TextField';
import RadioTypePerson from '../../RadioButton/RadioTypePerson';
import DropdownAutocomplete from '../../DropdownAutocomplete';
import type { SelectProps } from '../../DropdownAutocomplete/DropdownAutocomplete';

import {
  ButtonCancel,
  ButtonClose,
  ButtonSave,
  Container,
  ContentInputs,
  HeaderModalNewBankAccount,
  RowButtons,
  RowInput,
  Wrapper,
} from './styles';

const listTypePix = [
  {
    id: 'CPF',
    type: 'CPF',
  },
  {
    id: 'CNPJ',
    type: 'CNPJ',
  },
  {
    id: 'Email',
    type: 'E-mail',
  },
  {
    id: 'Phone_Number',
    type: 'Número de telefone celular',
  },
  {
    id: 'Random',
    type: 'Chave aleatória',
  },
];

interface Props {
  openModal: boolean;
  setOpenModal: (a: boolean) => void;
  dataAccountBank?: BankDetails;
  setDataAccountBank: (a: BankDetails) => void;
  getListBankAccountHost: () => void;
  isEdit: boolean;
}

const addLeftZeroInAgency = (agencyNumber: string): string => {
  const numberAgency = Number(agencyNumber);
  const agencyNumberFormated = numberAgency.toString().padStart(4, '0');
  return agencyNumberFormated;
};

const ModalNewBankAccount = ({
  openModal,
  setOpenModal,
  dataAccountBank,
  setDataAccountBank,
  getListBankAccountHost,
  isEdit,
}: Props) : JSX.Element => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const [selectedSwitcher, setSelectedSwitcher] = React.useState(false);
  const [isValidationWithCpf, setIsValidationWithCpf] = useState(false);
  const [listBank, setListBank] = useState<Array<Bank>>([]);

  const handleCloseModal = () => {
    setDataAccountBank({} as BankDetails);
    setOpenModal(false);
  };

  const requiredBankField = Yup.string().nullable().test(
    'required',
    'Campo obrigatório',
    (value) => !['null', 'undefined', ''].includes(`${value}`),
  );

  const validationWithCPF = Yup.object().shape({
    bank: requiredBankField,
    agency: Yup.string().required(),
    account: Yup.string().required(),
    type: Yup.string().required(),
    pix: Yup.string().required(),
    pixKeyType: Yup.string().required(),
    name: Yup.string().required(),
    cpf: Yup.string()
      .when('typePerson', (isIndividual: string, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (isIndividual !== 'individual') return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )).required('Campo obrigatório'),
  });

  const getSelectedBankId = (bank: string) => {
    const bankId = listBank?.find((item) => `${item.bank_number} - ${item.short_name}`.trim().toLowerCase() === `${bank}`.trim().toLowerCase())?.id;
    return bankId || undefined;
  };

  const validationWithCNPJ = Yup.object().shape({
    bank: requiredBankField,
    agency: Yup.string().required(),
    account: Yup.string().required(),
    type: Yup.string().required(),
    pix: Yup.string().required(),
    pixKeyType: Yup.string().required(),
    name: Yup.string().required(),
    cnpj: Yup.string()
      .when('typePerson', (isIndividual: string, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual === 'individual') return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )).required('Campo obrigatório'),
  });

  const initialValues = {
    typePerson: dataAccountBank?.cnpj ? 'legal' : 'individual',
    bank: dataAccountBank && (dataAccountBank?.bank as Bank)?.id ? `${(dataAccountBank?.bank as Bank)?.bank_number} - ${(dataAccountBank?.bank as Bank)?.short_name || ''}` : null,
    agency: dataAccountBank?.branch_number ? dataAccountBank.branch_number : '',
    account: dataAccountBank ? dataAccountBank.account_number : '',
    type: dataAccountBank ? dataAccountBank.account_type : '',
    pix: dataAccountBank ? dataAccountBank.pix_key : '',
    pixKeyType: dataAccountBank ? dataAccountBank.pix_key_type : 'CPF' as PixType,
    cnpj: dataAccountBank?.cnpj ? cnpj(dataAccountBank.cnpj) : '',
    cpf: dataAccountBank?.cpf ? cpf(dataAccountBank.cpf) : '',
    name: dataAccountBank ? dataAccountBank.entity_name : '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: isValidationWithCpf ? validationWithCPF : validationWithCNPJ,
    onSubmit: async (values) => {
      try {
        const bankAccount = Number(`${values?.bank}`?.split('-')?.[0]) as any || undefined;
        const bankId = getSelectedBankId(`${values?.bank}`) || bankAccount;

        if (!bankId || Number.isNaN(bankAccount)) {
          formik.setFieldError('bank', 'Selecione uma das opções de Banco disponíveis');
          return;
        }

        if (isEdit && dataAccountBank?.id) {
          await patchHostBank({
            bank: bankId,
            account_type: values.type,
            account_number: values.account,
            branch_number: addLeftZeroInAgency(values.agency),
            pix_key: values.pix,
            pix_key_type: values.pixKeyType as PixType,
            is_default: !!selectedSwitcher,
            entity_name: values.name,
            cnpj: formik.values.typePerson === 'legal' ? (((values.cnpj.split('.').join('')).split('.').join(''))
              .split('/')
              .join(''))
              .split('-')
              .join('') : '',
            cpf: formik.values.typePerson === 'individual' ? (values.cpf.split('.').join('')).split('-').join('') : '',
          }, dataAccountBank.id);
          toast.success('Conta bancária atualizada com sucesso!');
          getListBankAccountHost();
        } else {
          await postHostBank({
            bank: bankId,
            account_type: values.type,
            account_number: values.account,
            branch_number: addLeftZeroInAgency(values.agency),
            pix_key: values.pix,
            pix_key_type: values.pixKeyType as PixType,
            is_default: !!selectedSwitcher,
            entity_name: values.name,
            cnpj: (((values.cnpj.split('.').join('')).split('.').join(''))
              .split('/')
              .join(''))
              .split('-')
              .join(''),
            cpf: (values.cpf.split('.').join('')).split('-').join(''),
          });
          toast.success('Conta bancária cadastrada com sucesso!');
          getListBankAccountHost();
        }
        handleCloseModal();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  function handleGetBankOptions() {
    const options = listBank?.map<SelectProps>((item: any) => {
      const id = item?.id ? `${item?.id}` : null;
      const shortName = `${item?.bank_number || ''}`;
      const bankNumber = `${item?.short_name || ''}`;

      return {
        optionText: `${shortName} - ${bankNumber}`,
        optionValue: `${id}`,
      };
    });

    return options;
  }

  useEffect(() => {
    setIsValidationWithCpf(formik.values.typePerson === 'individual');
  }, [formik.values.typePerson]);

  useEffect(() => {
    async function getListBank() {
      const result = await getBanks();
      setListBank(result);
    }

    if (dataAccountBank) {
      setSelectedSwitcher(dataAccountBank.is_default);
    }

    getListBank();
  }, []);

  function handleWrapperClick(e: React.MouseEvent<HTMLElement>) {
    const classList = (e.target as Element).classList.value.split(' ');
    if (classList.includes('area-close')) {
      handleCloseModal();
    }
  }

  useEffect(() => {
    formik.setValues(initialValues);
  }, [dataAccountBank]);

  return (
    <Wrapper data-testid="modal-bank-account" className="area-close" onClick={(e) => handleWrapperClick(e)} openModal={openModal}>
      <Container onSubmit={formik.handleSubmit}>
        <ButtonClose type="button" onClick={handleCloseModal}>
          <Close />
          Fechar
        </ButtonClose>
        <ContentInputs>
          <HeaderModalNewBankAccount>
            <h1 data-testid="modal-bank-account-title">
              {dataAccountBank?.id ? 'Editar conta bancária' : 'Adicione uma conta bancária'}

            </h1>
          </HeaderModalNewBankAccount>
          <RowInput>
            <div className="column">
              <DropdownAutocomplete
                dataCy="select-properties-list"
                id="bank"
                label="*Banco"
                placeholder={formik.values?.bank || ''}
                options={handleGetBankOptions()}
                formik={formik}
              />
            </div>
            <div className="column">
              <TextField
                label="*Agência (sem dígito)"
                id="agency"
                placeholder="Digite aqui..."
                formik={formik}
                mask="agency"
                value={dataAccountBank?.branch_number}
                dataCy="agency-field"
              />
            </div>
          </RowInput>
          <RowInput>
            <div className="column">
              <TextField
                label="*Conta (com o dígito)"
                id="account"
                placeholder="Digite aqui..."
                formik={formik}
                mask="account"
                value={dataAccountBank?.account_number}
                dataCy="account-field"
              />
            </div>
            <div className="column">
              <SimpleSelect
                id="type"
                placeholder="Selecione"
                label="*Tipo da conta"
                formik={formik}
                options={
                    (accountTypeOptions || []).map<SelectOption>(({
                      label,
                      value,
                    }) => ({ valueLabel: label, value }))
                  }
                dataCy="type-field"
              />

            </div>
          </RowInput>
          <RowInput>
            <div className="fullRow">
              <SimpleSelect
                id="pixKeyType"
                placeholder="Selecione"
                label="*Tipo da chave PIX"
                formik={formik}
                options={
                  (listTypePix || []).map<SelectOption>(({
                    id,
                    type,
                  }) => ({ value: id, valueLabel: type }))
                }
                dataCy="pix-type-field"
              />
            </div>
          </RowInput>
          <RowInput>
            <TextField
              label="*Pix"
              id="pix"
              placeholder="Digite aqui..."
              formik={formik}
              value={dataAccountBank?.pix_key}
              dataCy="pix-field"
            />
          </RowInput>
          <RowInput>
            <TextField
              label="*Nome do destinatário"
              id="name"
              placeholder="Digite aqui..."
              formik={formik}
              value={dataAccountBank?.entity_name}
              dataCy="name-field"
            />
          </RowInput>
          <RowInput className="type-person">
            <RadioTypePerson
              labelPlacement="end"
              id="typePerson"
              formik={formik}
            />
          </RowInput>
          {formik.values.typePerson === 'individual' && (
          <RowInput>
            <TextField
              label="*CPF"
              id="cpf"
              placeholder="Digite aqui..."
              mask="cpf"
              formik={formik}
              value={formik.values.cpf}
              dataCy="cpf-field"
            />
          </RowInput>
          )}
          {formik.values.typePerson === 'legal' && (
          <RowInput>
            <TextField
              label="*CNPJ"
              id="cnpj"
              placeholder="Digite aqui..."
              mask="cnpj"
              formik={formik}
              value={formik.values.cnpj}
              dataCy="cnpj-field"
            />
          </RowInput>
          )}
        </ContentInputs>
        <RowButtons>
          <ButtonCancel onClick={handleCloseModal} type="button">
            {dataAccountBank?.id ? 'Descartar' : 'Cancelar'}
          </ButtonCancel>
          <ButtonSave data-cy="btn-save" type="submit">
            {dataAccountBank?.id ? 'Salvar Ajustes' : 'Salvar'}
          </ButtonSave>
        </RowButtons>
      </Container>
    </Wrapper>
  );
};

export default ModalNewBankAccount;
