import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import { StyledTypography } from './styles';

const IOSSwitchPrimary = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#fff',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#41B592',
        transition: '500ms',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    backgroundColor: '#E35B4C',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#ffffff',
    opacity: 1,
    border: '1px solid #D1D1D1',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const IOSSwitchSecondary = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#9E9E9F',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#FFFFFF',
        transition: '500ms',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    backgroundColor: '#FFFFFF',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#9E9E9F',
    opacity: 1,
    border: '1px solid #9E9E9F',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface ControlledSwitchButtonProps {
  checked?: boolean;
  handleChange?: () => void;
  variant?: 'PRIMARY' | 'SECONDARY';
}

const ControlledSwitchButton = ({
  checked = false,
  handleChange = () => {},
  variant = 'PRIMARY',
}: ControlledSwitchButtonProps) => (
  <FormGroup>
    <Stack direction="row" spacing={1} alignItems="center">
      <StyledTypography isActive={!checked}>Não</StyledTypography>
      {variant === 'PRIMARY' && (
        <IOSSwitchPrimary
          onChange={handleChange}
          checked={checked}
          inputProps={{ 'aria-label': 'ant design' }}
        />
      )}
      {variant === 'SECONDARY' && (
        <IOSSwitchSecondary
          onChange={handleChange}
          checked={checked}
          inputProps={{ 'aria-label': 'ant design' }}
        />
      )}
      <StyledTypography isActive={checked}>Sim</StyledTypography>
    </Stack>
  </FormGroup>
);

ControlledSwitchButton.defaultProps = {
  checked: false,
  handleChange: () => {},
  variant: 'PRIMARY',
};

export default ControlledSwitchButton;
