import { useContextSelector } from 'use-context-selector';
import { HostPropertiesContext } from '../../context/HostProperties/HostPropertiesContext';

export function useHostPropertiesFilter() {
  const openActions = useContextSelector(HostPropertiesContext,
    (state) => state.openActions);

  const handleOpenActions = useContextSelector(HostPropertiesContext,
    (state) => state.setOpenActions);

  const actionSelected = useContextSelector(HostPropertiesContext,
    (state) => state.actionSelected);

  const handleActionSelected = useContextSelector(HostPropertiesContext,
    (state) => state.setActionSelected);

  const loading = useContextSelector(HostPropertiesContext,
    (state) => state.loading);

  const handleLoading = useContextSelector(HostPropertiesContext,
    (state) => state.setLoading);

  const handleChangeBedsGridExpanded = useContextSelector(HostPropertiesContext,
    (state) => state.setBedsGridExpanded);

  const bedsGridExpanded = useContextSelector(HostPropertiesContext,
    (state) => state.bedsGridExpanded);

  const handleChangeBathroomsGridExpanded = useContextSelector(HostPropertiesContext,
    (state) => state.setBathroomsGridExpanded);

  const bathroomsGridExpanded = useContextSelector(HostPropertiesContext,
    (state) => state.bathroomsGridExpanded);

  const handleChangeStatusFilter = useContextSelector(HostPropertiesContext,
    (state) => state.setStatusFilter);

  const statusFilter = useContextSelector(HostPropertiesContext,
    (state) => state.statusFilter);

  const handleChangeCodeFilter = useContextSelector(HostPropertiesContext,
    (state) => state.setCodeFilter);

  const codeFilter = useContextSelector(HostPropertiesContext,
    (state) => state.codeFilter);

  const handleChangeBedroomsFilter = useContextSelector(HostPropertiesContext,
    (state) => state.setBedroomsFilter);

  const bedroomsFilter = useContextSelector(HostPropertiesContext,
    (state) => state.bedroomsFilter);

  const handleChangeLocationFilter = useContextSelector(HostPropertiesContext,
    (state) => state.setLocationFilter);

  const locationFilter = useContextSelector(HostPropertiesContext,
    (state) => state.locationFilter);

  const handleChangeOrder = useContextSelector(HostPropertiesContext,
    (state) => state.setOrder);

  const order = useContextSelector(HostPropertiesContext,
    (state) => state.order);

  const handleSortingByCode = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByCode);

  const handleSortingByStatus = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByStatus);

  const handleSortingByContractStartDate = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByCotractStartDate);

  const handleSortingByOwnersName = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByOwnerName);

  const handleSortingByHostName = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByHostName);

  const handleSortingByPhone = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByPhone);

  const handleSortingByAddress = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByAddress);

  const handleSortingByCommission = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByCommission);

  const handleSortingByCleaningFee = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByCleaningFee);

  const handleSortingByBedroomQuantity = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByBedroomQuantity);

  const handleChangeAllItemsFormatted = useContextSelector(HostPropertiesContext,
    (state) => state.setAllItemsFormatted);

  const allItemsFormatted = useContextSelector(HostPropertiesContext,
    (state) => state.allItemsFormatted);

  const locations = useContextSelector(HostPropertiesContext,
    (state) => state.locations);

  const handleLocations = useContextSelector(HostPropertiesContext,
    (state) => state.setLocations);

  const hostNameFilter = useContextSelector(HostPropertiesContext,
    (state) => state.hostNameFilter);

  const handleChangeHostNameFilter = useContextSelector(HostPropertiesContext,
    (state) => state.setHostNameFilter);

  const handleSortingByCategory = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByCategory);

  const handleSortingByImmobileId = useContextSelector(HostPropertiesContext,
    (state) => state.handleSortingByImmobileId);

  return {
    openActions,
    handleOpenActions,
    actionSelected,
    handleActionSelected,
    loading,
    handleLoading,
    bedsGridExpanded,
    bathroomsGridExpanded,
    handleChangeBathroomsGridExpanded,
    handleChangeBedsGridExpanded,
    handleChangeStatusFilter,
    statusFilter,
    handleChangeCodeFilter,
    codeFilter,
    handleChangeBedroomsFilter,
    bedroomsFilter,
    handleChangeLocationFilter,
    locationFilter,
    handleChangeOrder,
    order,
    handleSortingByCode,
    handleSortingByStatus,
    handleSortingByContractStartDate,
    handleSortingByOwnersName,
    handleSortingByPhone,
    handleSortingByAddress,
    handleSortingByCommission,
    handleSortingByCleaningFee,
    handleSortingByBedroomQuantity,
    handleChangeAllItemsFormatted,
    allItemsFormatted,
    locations,
    handleLocations,
    handleSortingByHostName,
    hostNameFilter,
    handleChangeHostNameFilter,
    handleSortingByCategory,
    handleSortingByImmobileId,
  };
}
