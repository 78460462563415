import styled, { keyframes } from 'styled-components';
import { shimmerAnimation } from '../../Calendar/LoadingShimmer/animationShimmer';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  } to{
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  min-height: 100px;
  animation: ${shimmerAnimation} 1s ease infinite;
`;

export const Container = styled.div`
  img {
    opacity: 0;
    animation: 0.5s 0.3s ease ${fadeIn} forwards;
  }

  svg {
    opacity: 0;
    animation: 0.5s 0.2s ease ${fadeIn} forwards;

    #svg {
      #background {
        opacity: 0;
        animation: 0.5s 0.7s ease ${fadeIn} forwards;
      }

      #ball {
        opacity: 0;
        animation: 0.5s 1s ease ${fadeIn} forwards;
      }

      #main {
        opacity: 0;
        animation: 0.5s 0.3s ease ${fadeIn} forwards;
      }
    }
  }
`;
