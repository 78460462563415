import { IListItems } from '../../components/HostTedList/types';
import { translatedAccountType } from '../../utils/Translator';
import request from '../request';

import {
  IGetHostTeds,
  ISyncHostTeds,
  IGetHostTedsParams,
  IVerifyTaskStatus,
  IConcludedCardsParams,
  IGenFileIdDownloadHostTedListCSV,
  IGetHostTedsResponse,
  ISendHostTedVouchers,
} from './types';

export const syncHostTeds = async (): Promise<ISyncHostTeds> => {
  const { data } = await request.put('/financial_closing/host/sync_teds/');
  return data;
};

export const verifyTaskStatus = async (taskID: string): Promise<IVerifyTaskStatus> => {
  const { data } = await request.get(`/tasks/${taskID}`);
  return data;
};

export const getHostTeds = async ({
  status,
  period,
  termToFilter,
}: IGetHostTedsParams): Promise<IGetHostTedsResponse> => {
  const { data } = await request.get('/financial_closing/host/teds/', {
    params: {
      period,
      status,
      q: termToFilter,
    },
  });

  const formatDataToListItem: IListItems[] = data.map((item: IGetHostTeds, index: number) => ({
    id: item?.id || index + 1,
    name: item?.host?.user?.is_individual ? `${item?.host?.user?.first_name} ${item?.host?.user?.last_name}` : item?.host?.user?.trading_name || 'Não informado',
    nameToTed: item?.bank_details?.entity_name || 'Não informado',
    transfer: item?.transfer || 'Não informado',
    cpf: item?.bank_details?.cpf || 'Não informado',
    bank: item?.bank_details?.bank.long_name || 'Não informado',
    agency: item?.bank_details?.branch_number || 'Não informado',
    account: item?.bank_details?.account_number || 'Não informado',
    pixKey: item?.bank_details?.pix_key || 'Não informado',
    pixKeyType: item?.bank_details?.pix_key_type || 'Não informado',
    status: item?.status || 'Não informado',
    data: item?.date_ref || new Date(),
    cnpj: item?.bank_details?.cnpj || 'Não informado',
    accountType: translatedAccountType[item?.bank_details?.account_type],
    bankNumber: item?.bank_details?.bank.bank_number || 'Não informado',
    statementImage: item?.statement_image,
  }));

  return { data, formatDataToListItem };
};

export const changeHostTedStatus = async ({
  ted_status,
  ted_ids,
}: IConcludedCardsParams) => {
  const { data } = await request.patch('/financial_closing/host/teds/', {
    status: ted_status,
    ids: ted_ids,
  });

  return data;
};

export const genFileIdToDownloadHostTedListCSV = async ({
  status,
  period,
  // termToFilter,
}: IGetHostTedsParams): Promise<IGenFileIdDownloadHostTedListCSV> => {
  const { data } = await request.put('/financial_closing/host/generate_teds_csv/', {
    status,
    period,
    // q: termToFilter,
  });

  return data;
};

export const sendHostTedVouchers = async ({
  tedID,
  fileUID,
}: ISendHostTedVouchers) => {
  const { data } = await request.patch('/financial_closing/host/teds/', {
    ids: [tedID],
    statement_image: fileUID,
  });

  return data;
};
