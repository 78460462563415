import Filters from './Filters';

import { Header, Title } from '../styles';

import {
  Container,
} from './styles';

const GuestList = () => (
  <Container>
    <Header className="header-guestlist">
      <Title>Lista de hóspedes</Title>
    </Header>
    <Filters />
  </Container>
);

export default GuestList;
