import React from 'react';
import Lottie from 'lottie-react';

import animationSource from '../source/infoAnimation.json';

interface IInfoAnimations {
  width?: number;
}

export const InfoAnimation = ({ width }: IInfoAnimations) => (
  <Lottie
    width={width}
    animationData={animationSource}
  />
);

InfoAnimation.defaultProps = {
  width: undefined,
};
