import { TimeLine as TimeLineComponent } from '../../components';
import { ReservationProvider } from '../../context/ReservationDetailsContext';

const TimeLine = () => (
  <ReservationProvider>
    <TimeLineComponent />
  </ReservationProvider>
);

export default TimeLine;
