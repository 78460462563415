import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.button`
  width: 100%;
  height: 50px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 0 0 8px 8px;
  gap: 24px;

  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  
  :hover {
    opacity: 0.7;
    transition: 0.5s;
  }

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    max-width: 300px;
    border: 0;
    gap: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;
