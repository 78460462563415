import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  width: 95%;
  margin: 30px;
  display: flex;
  border-radius: 10px;
  padding: 30px;
  flex-direction: column;
  overflow-y: scroll;
  min-height: 80%;

  @media (max-width: 1400px){
    max-height: 90vh;
  }

  max-height: 93vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const Dividers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
