import { useUser } from '../../../../../context/UserContext';

import PartnerCluster from '../PartnerCluster';

import {
  Title,
  HappyFace,
  Container,
  TitleContainer,
  ClusterContainer,
} from './style';

const Hello = () => {
  const { userInformation } = useUser();
  const name = userInformation?.first_name;

  return (
    <Container>
      <TitleContainer>
        <Title data-cy="[partner-panel]-hello-message">
          Bem-vindo(a),
        </Title>
        <Title data-cy="[partner-panel]-hello-message" capitalize>
          {name}
        </Title>
        <HappyFace
          initial={{
            x: -10,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 0.7,
          }}
          transition={{
            delay: 0.5,
          }}
        >
          {':)'}
        </HappyFace>
      </TitleContainer>
      <ClusterContainer>
        <PartnerCluster />
      </ClusterContainer>
    </Container>
  );
};

export default Hello;
