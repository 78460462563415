import {
  FC,
  ReactElement,
  useState,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

interface RequestSupportModalContextProps {
  isOpenedReportErrorModal: boolean,
  handleCloseReportBugModal: () => void,
  handleOpenReportBugModal: () => void,
}

export const RequestSupportModalContext = createContext<RequestSupportModalContextProps>({
  isOpenedReportErrorModal: false,
  handleCloseReportBugModal: () => {},
  handleOpenReportBugModal: () => {},
});

export const RequestSupportModalProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [isOpenedReportErrorModal, setIsOpenedReportErrorModal] = useState(false);

  const handleCloseReportBugModal = () => {
    setIsOpenedReportErrorModal(false);
  };

  const handleOpenReportBugModal = () => {
    setIsOpenedReportErrorModal(true);
  };

  const value = useMemo(() => ({
    isOpenedReportErrorModal,
    handleCloseReportBugModal,
    handleOpenReportBugModal,
  }), [
    isOpenedReportErrorModal,
    handleCloseReportBugModal,
    handleOpenReportBugModal,
  ]);

  return (
    <RequestSupportModalContext.Provider
      value={value}
    >
      {children}
    </RequestSupportModalContext.Provider>
  );
};
