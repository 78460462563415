import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0;
`;

export const Input = styled.input`
  margin-top: 0.3rem;
`;

export const Text = styled.h5`
  font-size: 0.875rem !important;
  font-weight: 400;
  font-family: Inter;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  margin-left: 0.7rem;

  a {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;
