import React from 'react';

import { useTable } from 'react-table';
import { AnimatePresence } from 'framer-motion';

import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';
import { usePartnersEarning } from '../../../../../context/Partners/Earning/Earning';
import { currencyToNumber, numberToCurrency } from '../../../../../utils/Formatter';

import {
  NotFound,
  StyledTR,
  StyledTH,
  StyledTD,
  StyledTBody,
  StyledTHead,
  Styledtable,
  Container,
} from './style';

const columns = [{
  Header: 'Data',
  accessor: 'data', // accessor is the "key" in the data
}, {
  Header: 'Motivo',
  accessor: 'motivo',
}, {
  Header: 'Imóvel',
  accessor: 'imovel',
}, {
  Header: 'Valor',
  accessor: 'valor',
}];

const Table = () => {
  const { filter, setTotalBalance } = usePartnersEarning();
  const { resume } = usePartners();

  const data = React.useMemo(() => {
    const field = resume?.total_balance.statement;

    const translateReason = (reason: string) => {
      switch (reason) {
        case 'commission_withdraw':
          return 'Crédito de Resgate';
        case 'commission_credit':
          return 'Crédito de Comissão';
        default:
          return 'Desconhecido';
      }
    };

    const response = field?.map((item) => ({
      data: item.date_ref,
      motivo: translateReason(item.reason),
      imovel: item.property,
      valor: numberToCurrency(item.value),
    }));

    return response || [];
  }, [resume]);

  const filteredDataByDateFilter = data.filter((item) => {
    const date = new Date(item.data).toISOString().slice(0, 7);
    return date === filter.date;
  });

  React.useEffect(() => {
    const sumOfAllValor = filteredDataByDateFilter.reduce((acc, item) => {
      const value = currencyToNumber(item.valor);
      if (item.motivo === 'Crédito de Resgate') {
        return acc - value;
      }
      return acc + value;
    }, 0);

    setTotalBalance(sumOfAllValor);
  }, [filteredDataByDateFilter]);

  // @ts-ignore - bug in react-table
  const tableInstance = useTable({ columns, data: filteredDataByDateFilter || [] });

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
  } = tableInstance;

  if (data.length === 0 || !data) {
    return <NotFound>Nenhum balanço foi encontrado na data selecionada</NotFound>;
  }

  return (
    <Container>
      <Styledtable {...getTableProps()}>
        <StyledTHead>
          {headerGroups.map((headerGroup) => (
            <StyledTR lenght={columns.length} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTH {...column.getHeaderProps()}>{column.render('Header')}</StyledTH>
              ))}
            </StyledTR>
          ))}
        </StyledTHead>
        <StyledTBody
          {...getTableBodyProps()}
        >
          <AnimatePresence>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <StyledTR
                  initial={{
                    y: 20,
                    opacity: 0,
                  }}
                  animate={{
                    y: 0,
                    opacity: 1,
                  }}
                  exit={{
                    y: -20,
                    height: 0.5,
                    opacity: 0,
                  }}
                  {...row.getRowProps()}
                  lenght={columns.length}
                >
                  {row.cells.map((cell) => (
                    <StyledTD {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </StyledTD>
                  ))}
                </StyledTR>
              );
            })}
          </AnimatePresence>
        </StyledTBody>
      </Styledtable>
    </Container>
  );
};

export default Table;
