import request from '../request';
import { CommentProperty, CommentPropertyUser } from './types';

export const getComments = async (id: number): Promise<CommentPropertyUser[]> => {
  const { data } = await request.get(`/property/comments/?property=${id}`);
  return data as CommentPropertyUser[];
};

export const createComment = async (data: CommentProperty) => (
  request.post('/property/comments/', data)
);

export const editComment = async (data: CommentProperty) => (
  request.put(`/property/comments/${data.id}/`, data)
);
