import styled, { css } from 'styled-components';

import {
  genericBorderRoundedMinWidth,
} from '../utils/var.utils';

export const Container = styled.div<{
  isBedExpanded: boolean;
  isBathroomsExpanded: boolean;
  validateHostSearch: boolean;
}>`
  width: max-content;
  display: grid;
  grid-template-columns: ${({ isBedExpanded, isBathroomsExpanded }) => `200px 200px 200px 120px 150px 300px 300px 300px 220px ${isBedExpanded ? '700px' : '200px'} 150px ${isBathroomsExpanded ? '400px' : '200px'}`};

  ${({ validateHostSearch, isBedExpanded, isBathroomsExpanded }) => validateHostSearch && css`
    grid-template-columns: ${`200px 200px 200px 120px 150px 300px 300px 300px 220px 200px ${isBedExpanded ? '700px' : '200px'} 150px ${isBathroomsExpanded ? '400px' : '200px'}`};
  `};
  
  transition: 0.3s;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #FFF;
  z-index: 101;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  gap: 10px;
  display: flex;
  cursor: pointer;
  padding-left: 5px;
  flex-direction: row;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  font-size: 16px;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.familys._6};
`;

export const LeftSide = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const RightSide = styled.div`
  gap: 10px;
  cursor: pointer;
  padding-left: 15px;
`;

export const GenericBorderRoundedContainer = styled.div`
  gap: 10px;
  display: flex;
  padding: 10px 25px;
  align-items: center;
  flex-direction: row;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0;
  justify-content: space-around;
  border-top: 2px solid #d9dcdf;
  border-left: 2px solid #d9dcdf;
  border-right: 2px solid #d9dcdf;
  width: 100%;
  min-width: ${genericBorderRoundedMinWidth};

  button {
    background: transparent;
    border: none;
  }

`;

export const SubTitle = styled.h2`
  font-size: 1rem;
  color: #909AAC;
  font-weight: 200;
  padding: 0 10px;
  text-align: center;
`;

export const OrderContainer = styled.div`
  gap: 3px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;


  img {
    width: 7px;
    user-select: none;
  }
`;

export const ImmobileContainer = styled.div`
  padding-left: 10px;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  background: #FFF;
  z-index: 100;
`;

export const ImmobileCategoryContainer = styled.div`

`;

export const BedsContainer = styled.div``;
export const OwnerContainer = styled.div``;
export const StatusContainer = styled.div``;
export const AddressContainer = styled.div``;
export const BedroomsContainer = styled.div`
  margin-left: 13px;
`;
export const BathroomsContainer = styled.div``;
export const CommissionContainer = styled.div``;
export const TaxCleaningContainer = styled.div``;
export const StartOfContractContainer = styled.div``;
export const ProprietaryPhoneContainer = styled.div``;
