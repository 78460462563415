import React, { useEffect } from 'react';

import NewTable from './NewTable';
import Header from './Header/Header';
import ModalSelectWhoPays from './ModalSelectWhoPays';
import NewExpensesModal from './Header/NewExpensesModal/NewExpensesModal';
import ExportExpensesModal from './Header/ExportExpensesModal/ExportExpensesModal';
import ExpensesInformationModal from './ExpensesInformationModal/ExpensesInformationModal';

import { ContentContainer, NoDataFound } from './styles';
import { useExpense } from '../../hooks/ExpenseHook/useExpense';

export type ExpenseChecked = {
  id: number;
  checked: boolean;
  status?: string;
};

export const Expenses = () => {
  const isFirstRender = React.useRef(true);
  const [checkAll, setCheckAll] = React.useState<boolean>(false);
  const [listUpdateWhoPays, setListUpdateWhoPays] = React.useState<Array<ExpenseChecked>>([]);
  const {
    page,
    expensesList,
    filterParams,
    setExpensesList,
    fetchExpenseData,
    openModalExpenses,
    openExportExpensesModal,
  } = useExpense();

  const dataFound: boolean = expensesList?.current_page > 0;
  const noDataFound: boolean = expensesList?.results?.length === 0;

  useEffect(() => {
    function setAllWhoPays() {
      if (checkAll) {
        setListUpdateWhoPays(expensesList?.results?.map((item) => ({
            id: item.id || -1,
            checked: true,
            status: item.expense_status,
          })));
      } else {
        setListUpdateWhoPays([]);
      }
    }

    setAllWhoPays();
  }, [checkAll]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      fetchExpenseData(page, filterParams);
    }
  }, []);

  return (
    <>
      <ExpensesInformationModal />

      {openModalExpenses && <NewExpensesModal />}
      {openExportExpensesModal && <ExportExpensesModal />}

      <ContentContainer>
        <Header
          setExpensesList={setExpensesList}
          listUpdateWhoPays={listUpdateWhoPays}
        />

        {noDataFound && (
          <NoDataFound>
            <h1>Nenhum dado foi encontrado</h1>
            <h1>Experimente fazer uma nova pesquisa</h1>
          </NoDataFound>
        )}

        {dataFound && (
          <NewTable
            listUpdateWhoPays={listUpdateWhoPays}
            setListUpdateWhoPays={setListUpdateWhoPays}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
          />
        )}
      </ContentContainer>

      <ModalSelectWhoPays listUpdateWhoPays={listUpdateWhoPays} />
    </>
  );
};
