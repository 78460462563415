import { useNavigate, useParams } from 'react-router-dom';

import { UseFormik } from '../../../../../../utils/Formik/types';
import { EnumType } from '../../../../types';
import { IFormValues } from '../types';

import { usePartners } from '../../../../../../context/Partners/Partner/PartnerContext';

import LandIndicationForm from './LandIndicationForm/LandIndicationForm';
import LocationIndicationForm from './LocationIndicationForm/LocationIndicationForm';
import SpotIndicationForm from './SpotIndicationForm/SpotIndicationForm';
import FormTemplate from '../FormTemplate';
import SuccessPage from '../../../../components/SuccessPage';

interface IContent {
  formik: UseFormik<IFormValues>;
}

const Content = ({ formik }: IContent) => {
  const { type } = useParams();
  const navigate = useNavigate();
  const { successScreen, setSuccessScreen } = usePartners();

  if (successScreen) {
    return (
      <SuccessPage
        onClose={() => {
          setSuccessScreen(false);
          navigate('/parceiros/indicacoes/todos', {
            state: { isReloadFetch: true },
          });
        }}
      />
    );
  }

  return (
    <FormTemplate formik={formik}>
      <>
        {type === EnumType.terrain && <LandIndicationForm formik={formik} />}
        {type === EnumType.location && <LocationIndicationForm formik={formik} />}
        {type === EnumType.spot && <SpotIndicationForm formik={formik} />}
      </>
    </FormTemplate>
  );
};

export default Content;
