import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, #E8EAF3, #FFFFFF);
  width: 100%;
  padding: 0 50px;

  @media(max-width: 900px) {
    margin-top: 25px;
    height: auto;
  }
`;

export const DataGrid = styled.div`
  width: 100%;
  background-color: none;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: 830px) {
    flex-direction: column;
  }
`;

export const Localization = styled.div`
  width: 110px;
  margin-right: 20px;

  @media(max-width: 830px) {
    width: 45%;
    margin-right: 0;
  }
`;

export const Price = styled.div`
  width: 220px;
  margin-right: 20px;

  @media(max-width: 830px) {
    width: 45%;
    margin-right: 0;
  }
`;

export const Period = styled.div`
  width: 110px;
  margin-right: 20px;

  @media(max-width: 830px) {
    width: 45%;
    margin-right: 0;
  }
`;

export const QuantityGuest = styled.div`
  width: 150px;
  margin-right: 20px;

  .qtdGuests {
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border: 1px solid;
    margin-top: 13px !important;
    border-color: ${({ theme }) => theme.palette.grey._450.hex()};
    border-radius: 10px;
    height: 40px;
    padding-top: 2px;
  }

  @media(max-width: 830px) {
    width: 45%;
    margin-right: 0;
  }
`;

export const FormContent = styled.form`
  width: 100%;
  height: 100px;
  margin-top: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};

  .styleMultiselect {
    .css-w27m94-MuiInputBase-root-MuiInput-root {
      margin-top: 8px !important;
    }
  }

  @media(max-width: 830px) {
    height: 250px;
    margin-top: 50px;
  }
`;

export const RowMobile = styled.div`
  display: flex;
  align-items: center;

  @media(max-width: 830px){
    justify-content: space-between;
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonClear = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  width: 150px;
  cursor: pointer;
`;

export const SearchButton = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-top: 35px;
  display: flex;
  width: 90px;

  @media(max-width: 830px){
    width: 100%;
    padding-top: 15px;
  }
`;

export const MoreFilters = styled.div`
  height: 112px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${({ theme }) => theme.palette.blue._850.hex()};
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }

  @media(max-width: 1010px) {
    margin-left: 15px;
  }

  @media(max-width: 830px) {
    height: 50px;
    width: 100%;
  }

`;

export const InputHideSelect = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`;

export const InputHideModal = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
`;

export const InputModal = styled.div`
  position: fixed;
  top: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23);
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey._450.hex()};
  width: 100%;
  max-width: 260px;
  background-color:#FFFFFF;
  margin: 40px 5px 5px 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  z-index: 6;

  &.input-modal-style {
    height: auto;
  }

  .messageError {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-top: 5px;
  }

  .css-1qz17ui-MuiPaper-root {
    z-index: 10010 !important;
  }

  .group-price {
    width: 100%;
    display: flex;
    flex-direction: row;

    span {
      margin-top: 35px;
      margin-left: 5px;
      margin-right: 6px;

      @media (max-width: 600px) {
        margin-top: 5px;
      }
    }

    .min {
      width: 127.5px;
    }

    .max {
      width: 127.5px;
    }

    @media(max-width: 600px) {
      flex-direction: column;
    }
  }

  .buttonApply {
    margin-right: 0;
    margin-left: auto;
    margin-top: 5px;

    @media (max-width: 600px) {
      width: 100%;
    }

  }
`;

export const ItemCheckbox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right:15px;
  padding-top: 10px;

  .style-date {
    width: 150px !important;
  }
`;

export const Line = styled.hr`
    background-color: ${({ theme }) => theme.palette.grey._420.hex()};
    margin-bottom: 14px;
    margin-top: 16px;
`;
