import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  B2BButtons,
  B2BCard, B2BContainer, B2BForm, B2BHeader, B2BTitle,
} from './styles';
import { FormAddB2BCondominium } from './Forms/FormAddB2BCondominium';
import FormButton from '../../FormButton';
import { deleteAddress, postAddress } from '../../../services/Address/request';
import { postB2BCondominium } from '../../../services/InsertData/request';
import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import { onlyNumbers } from '../../../utils/Formatter';

const InsertB2BCondominium = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const validationSchema = Yup.object().shape({
    condominiumName: Yup.string().required(),
    street: Yup.string().required(),
    number: Yup.number(),
    complement: Yup.string(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    postal_code: Yup.string().required(),
    country: Yup.string().required(),
  });
  const formik = useFormik({
    initialValues: {
      condominiumName: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      let createdAddressId: number|undefined = -1;
      try {
        const response = await postAddress({
          city: values.city,
          country: values.country,
          neighborhood: values.neighborhood,
          number: values.number,
          postal_code: onlyNumbers(values.postal_code),
          state: values.state,
          street: values.street,
          complement: values.complement,
          condominium: values.condominiumName,
        });

        createdAddressId = response.id;

        try {
          await postB2BCondominium({
            address: createdAddressId!,
            condominium_name: values.condominiumName,
          });
        } catch (e: unknown) {
          if (e instanceof Error) {
            toastErrorRequest(e);
          }
        }
        navigate('/inserirdados');
        toast.success('Condomínio salvo com sucesso!');
      } catch (e: unknown) {
        if (e instanceof Error) {
          if (createdAddressId !== -1) {
            await deleteAddress(createdAddressId!);
            toastErrorRequest(e);
          }
        }
      }
    },
  });
  return (
    <B2BContainer>
      <B2BCard>
        <B2BHeader>
          <B2BTitle>Inserir Condomínio</B2BTitle>
        </B2BHeader>
        <B2BForm onSubmit={formik.handleSubmit}>
          <FormAddB2BCondominium formik={formik} />
          <B2BButtons>
            <div>
              <FormButton
                type="button"
                variant="outlined"
                onClick={() => navigate('/inserirdados')}
              >
                Cancelar
              </FormButton>
              <FormButton
                type="submit"
              >
                Salvar
              </FormButton>
            </div>
          </B2BButtons>
        </B2BForm>
      </B2BCard>
    </B2BContainer>
  );
};

export default InsertB2BCondominium;
