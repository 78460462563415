import React, { useState } from 'react';

import { deleteGuestReservations } from '../../../../../../services/CompReservations/request';
import { Guest } from '../../../../../../services/CompReservations/types';

import Body from './Body';
import Header from './Header';

import { Container } from './styles';

import { useCompleteReservation } from '../../../../../../hooks/CompleteReservationHook/useCompleteReservation';
import { useToast } from '../../../../../../context/ToastContext';

interface GuestsProps {
  index: number;
  isMain?: boolean;
  guestId: number;
  guestInformation: Guest;
  setGuestMain?: (guest: Guest) => void;
  guestsSecondary: Guest[];
  setGuestsSecondary: (guests: Guest[]) => void;
}

const Guests = ({
  index,
  isMain,
  guestId,
  guestInformation,
  setGuestMain,
  guestsSecondary,
  setGuestsSecondary,
}: GuestsProps) => {
  const [open, setOpen] = useState(false);
  const { reservationsList, updateReservationsList } = useCompleteReservation();
  const toast = useToast();

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const handleRemoveGuest = async (id: number) => {
    try {
      if (!guestInformation.isNewGuest) {
        await deleteGuestReservations(id);

        const reservationUpdated = reservationsList.map((item) => ({
          ...item,
          guests: item.reservation_id === guestInformation.reservation
            ? item.guests.filter((guest) => guest.id !== id)
            : item.guests,
        }));

        updateReservationsList(reservationsList, reservationUpdated);
      }

      const guestsUpdated = guestsSecondary.filter((guest) => guest.id !== id);
      setGuestsSecondary(guestsUpdated);

      toast.success('Hóspede removido com uscesso!');
    } catch (error) {
      toast.error('Não foi possível remover o hóspede!');
    }
  };

  return (
    <Container animationIndex={index}>
      <Header
        index={index}
        onBashClick={() => handleRemoveGuest(guestId)}
        open={open}
        onClose={handleOpenModal}
      />
      <Body
        open={open}
        isMain={isMain}
        guestInformations={guestInformation}
        setGuestMain={setGuestMain}
        guestSecondary={guestsSecondary}
        setGuestsSecondary={setGuestsSecondary}
      />
    </Container>
  );
};

Guests.defaultProps = {
  setGuestMain: null,
  isMain: false,
};

export default Guests;
