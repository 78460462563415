import ReactGA from 'react-ga4';
import { useUser } from '../../context/UserContext';

interface IAnalytics {
  action: string;
}

export function useAnalytics() {
  const { userInformation } = useUser(); // Use your hook here
  const detailedCategory = `${userInformation?.main_role}-${userInformation?.user_id}-${userInformation?.nickname || userInformation?.first_name}`;

  function dispatchEvent({ action }: IAnalytics) {
    ReactGA.event(detailedCategory, {
      category: detailedCategory,
      action,
    });
  }

  return {
    dispatchEvent,
  };
}
