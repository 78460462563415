import React from 'react';
import { Title, Container } from '../../../../common/style';
import Card from '../../../../common/Card/Card';
import { data } from './data';

const WhatTypesReferralsCanYouMake = () => (
  <Container>
    <Title>Quais tipos de indicações você pode realizar?</Title>
    <Card items={data} />
  </Container>
);

export default WhatTypesReferralsCanYouMake;
