import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import { motion } from 'framer-motion';

export const ReportBugModalBackdrop = styled.div<{ isModalOpen: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999999 !important;
  background-color: rgba(0,0,0, 0.7);
  display: ${({ isModalOpen }) => (isModalOpen ? 'block' : 'none')};
  overflow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ReportBugModalCard = styled.div`
  position: relative;
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  width: 100%;
  height: max-content;
  max-width: 600px;
  
  @media (max-width: 900px) {
    top: 5%;
    width: 90%;
    height: auto;
    min-height: 500px;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ReportBugModalImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 280px;
    height: 280px;

    @media (max-width: 400px) {
      width: 150px;
      min-height: 150px;
      height: 150px;
    }
  }
`;

export const ReportBugModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const ReportBugModalCloseButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  align-self: baseline;
  z-index: 9999999;
  background-color: transparent;
`;

export const ReportBugModalCloseIcon = styled(Close)`
  color: ${({ theme }) => theme.palette.grey._900.hex()} !important;
`;

export const ReportBugModalContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
  
  @media (max-width: 400px) {
    gap: 1rem;
    height: 90%;
    overflow: hidden;
  }
`;

export const ReportBugModalTextContainer = styled.div`
  background: #E7E9FA;
  width: 100%;
  height: auto;
  padding: 12px;
  border-radius: 10px;
  box-shadow: -4px 5px 8px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 400px) {
    &::-webkit-scrollbar {
      border-radius: 10px;
      height: 0.5rem;
      width: 0.5rem;
      background-color: ${({ theme }) => theme.palette.grey._400.hex()};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.blue._400.hex()};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
      background-color: ${({ theme }) => theme.palette.blue._50.hex()};
    }
    overflow-y: auto;
  }
`;

export const ReportBugModalTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 22px;
  font-weight: bold;
  line-height: 32px;
  color: #001840;
  margin-bottom: 0.5rem;

  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

export const ReportBugModalText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 16px;
  line-height: 22px;
  color: #394760 !important;
  margin-bottom: 2.5rem;
  
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const ReportBugModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
