import React, {
  createContext,
  useContext,
  useState,
} from 'react';

import { IWelcome } from '../Popups';
import { IChangeRequiredActions, changeRequiredActions } from '../../../services/User/request';
import { useUser } from '../../../context/UserContext';
import { UserInformation } from '../../../services/User/types';

interface StepsContextData {
  step: number;
  data: IWelcome['data'];
  options: ISteps['options'];
  notShowAgainCheck: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setNotShowAgainCheck: React.Dispatch<React.SetStateAction<boolean>>;
  filterButtons: IOptions['buttons']
  handleNextStep: Function;
  handlePrevStep: Function;
  handleFinish: Function;
}

const StepsContext = createContext<StepsContextData>({} as StepsContextData);

type IClick = {
  nextStep: Function;
  prevStep: Function;
  finish: Function;
};

type Aux = {
  is: boolean;
  text: string;
  step: number;
  style?: string;
  onClick: ({ nextStep, prevStep, finish }: IClick) => void;
};

export interface IOptions {
  buttons?: Aux[]
  showNotShowAgainCheckbox?: {
    is: boolean;
    onTrue?: (x?: boolean) => void;
    onFalse?: (x?: boolean) => void;
  }
}

interface Callback {
  currentStep: number;
}

type MyCallback = ({ currentStep }: Callback) => { currentStep: number };

interface ISteps {
  data: IWelcome['data']
  children: React.ReactNode
  options?: IOptions;
  newBody: {};
  onFinish: {
    success?: (res: IChangeRequiredActions) => void;
    error?: () => void;
  };
  callback?: MyCallback;
}

export const StepsProvider = ({
  children,
  data,
  options,
  onFinish,
  newBody,
  callback,
}: ISteps) => {
  const { userInformation, setUserInformation } = useUser();

  const [step, setStep] = useState(0);
  const [notShowAgainCheck, setNotShowAgainCheck] = useState(false);

  const filterButtons = React.useMemo(() => {
    if (options?.buttons) {
      return options?.buttons.filter((aux) => aux.step === step);
    }

    return [];
  }, [step]);

  const handleFinish = async (payload?: any) => {
    if (userInformation?.user_id) {
      try {
        const res = await changeRequiredActions({
          user_id: userInformation.user_id,
          body: payload || { ...newBody },
        });

        if (res) {
          if (onFinish.success) {
            setUserInformation({
              ...userInformation,
              main_address: { ...userInformation?.main_address },
              host: { ...userInformation?.host },
              ...res.body,
            } as UserInformation);
            onFinish.success(res);
          }
        }
      } catch {
        if (onFinish.error) {
          onFinish.error();
        }
      }
    }
  };

  const handleNext = async () => {
    if (callback) {
      callback({
        currentStep: step,
      });
    }

    const limit = data.length - 1;
    const isLastStep = step === limit;

    if (isLastStep) {
      await handleFinish();
    } else {
      setStep((old) => {
        if (old === limit) {
          return old;
        }
        return old + 1;
      });
    }
  };

  const handlePrevStep = () => {
    setStep((prevState) => prevState - 1);
  };

  return (
    <StepsContext.Provider
      value={{
        step,
        data,
        setStep,
        options,
        notShowAgainCheck,
        setNotShowAgainCheck,
        filterButtons,
        handleNextStep: handleNext,
        handlePrevStep,
        handleFinish,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};

StepsProvider.defaultProps = {
  options: {},
  callback: () => {},
};

export function useSteps(): StepsContextData {
  const context = useContext(StepsContext);
  return context;
}
