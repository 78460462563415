import styled, { keyframes } from 'styled-components';

const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: #F9F9F9;

  display: flex;
  justify-content: center;
  animation-name: ${FadeInAnimation};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;

  @media (max-width: 900px) {
    display: block;
  }
`;
