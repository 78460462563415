import React from 'react';
import { Container } from './style';

const GuestInfo = () => (
  <Container>
    <h1>Hóspedes</h1>

    <p>
      Aqui é onde você deve preencher os dados relacionados ao hóspede.
      {' '}
      <span style={{ color: '#0D4BD0' }}>
        Pode ficar tranquilo(a), valorizamos sua privacidade.
      </span>
      {' '}
    </p>

    <p>
      <strong style={{ color: '#FF9900' }}>Aviso: </strong>
      Crianças acima de 06 anos entram na contagem de leitos e devem ser
      incluídas como hóspede.
    </p>
  </Container>
);

export default GuestInfo;
