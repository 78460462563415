import React from 'react';

import { Container } from './styles';

const Header = () => (
  <Container>
    <div>
      <h1>Vouchers enviados</h1>
      <h2>Verifique o envio para o hóspede</h2>
    </div>
  </Container>
);

export default Header;
