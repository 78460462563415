import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const PagesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 900px) {
    gap:  0.25rem;
  }
`;

export const Button = styled.button<{ isActive: boolean }>`
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 99999999999px;
  border: ${({ isActive }) => (isActive ? 'none' : '1px solid #D9D9D9')};
  background-color: ${({ isActive }) => (isActive ? '#0D4BD0' : '#FFFFFF')};
  color: ${({ isActive }) => (isActive ? '#FFFFFF' : '#001840')};
  cursor: pointer;
  font-weight: bold;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconButton = styled.button`
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 99999999999px;
  background-color: #FFFFFF;
  color: #0D4BD0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: #D9D9D9;
  }
`;
