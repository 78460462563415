import React, {
  FC,
  createContext,
  useContext,
  ReactElement,
  useState,
} from 'react';

export interface ReservationContextType {
  reservationDetails?: any;
  setReservationDetails: (data: any) => void;
  groupSelect?: any;
  setGroupSelect: (data: any) => void;
  items?: any;
  setItems: (data: any) => void;
}

export const ReservationContext = createContext<ReservationContextType>({
  reservationDetails: {},
  setReservationDetails: (reservation: any) => reservation,
  groupSelect: {},
  setGroupSelect: (reservation: any) => reservation,
  items: {},
  setItems: (reservation: any) => reservation,
});

export const ReservationProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const [reservationDetails, setReservationDetails] = useState<any>();
  const [groupSelect, setGroupSelect] = useState<any>();
  const [items, setItems] = useState<any>([]);

  return (
    <ReservationContext.Provider
      value={{
        reservationDetails,
        setReservationDetails,
        groupSelect,
        setGroupSelect,
        items,
        setItems,
      }}
    >
      {children}
    </ReservationContext.Provider>

  );
};

export const useReservationDetails = () => useContext(ReservationContext);
