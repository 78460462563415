import React from 'react';
import {
  Container, Content, ItemContentShimmer, ItemShimmer,
} from './styles';

const ItemsShimmer = () => (
  <Container>
    <Content>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
      <ItemShimmer>
        <ItemContentShimmer />
      </ItemShimmer>
    </Content>
  </Container>
);

export default ItemsShimmer;
