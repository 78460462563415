import React from 'react';

import { ArrowBack } from '@mui/icons-material';

import {
  Container,
  BackButtonStyled,
  Content,
} from './styles';

interface BackButtonProps {
  haveBackgroudColor?: boolean
}

const BackButton = ({ haveBackgroudColor = false }: BackButtonProps) => {
  const handleLink = () => {
    window.history.back();
  };

  return (
    <Container haveColor={haveBackgroudColor}>
      <BackButtonStyled onClick={handleLink}>
        <Content>
          <ArrowBack fontSize="small" />
          <p>Voltar</p>
        </Content>
      </BackButtonStyled>
    </Container>
  );
};

BackButton.defaultProps = {
  haveBackgroudColor: false,
};

export default BackButton;
