import { motion } from 'framer-motion';
import styled from 'styled-components';

export const PendencyFormContainer = styled(motion.form)`
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PendencyFormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-family: 'Inter';
  color: #394760;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  > p {
    font-size: 0.75;
    font-weight: 400;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  button {
    width: 100%;
  }
`;

export const LinkText = styled.div`
  text-decoration: underline;
  color: #0D4BD0;
  z-index: 2;
  cursor: pointer;
`;
