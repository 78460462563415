import styled from 'styled-components';

export const Container = styled.div`
  width: 38%;
  height: 80vh;
  padding: 12px 30px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const Header = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._370.hex()};
  margin-bottom: 58px;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }
`;

export const HeaderCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  margin-bottom: -12px;
  padding-left: 15px;
  margin-right: -5px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

export const TitleCard = styled.h2`
    width: 79px;
    padding-left: 10px;
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    color: ${({ theme }) => theme.palette.blue._850.hex()};

`;

export const ButtonRemove = styled.button`
  width: 22px;
  height: 22px;
  border-radius: 15px;
  background: ${({ theme }) => theme.palette.white._300.hex()};
  border: none;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  svg {
    width: 80%;
  }
`;

export const CardAreaSelected = styled.div`
  width: 100%;
  height: 122px;
  border-radius: 12px;
  border: 2px dashed ${({ theme }) => theme.palette.grey._280.hex()};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 14px;

  margin-bottom: 39px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;

    color: ${({ theme }) => theme.palette.blue._850.hex()};
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: ${({ theme }) => theme.palette.grey._450.hex()};
  }

  img {
    &.rigth {
      transform: rotate(180deg)
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  margin: 0 0 58px 0;

  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._370.hex()};
`;

export const ContentButtons = styled.div`
  width: 100%;

  button {
    width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }
`;
