import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, ShadowImage } from './style';

const Image = () => {
  const { filter } = useParams();

  const size = filter === 'all' ? '1280x720' : '1920x1080';
  const RANDOM_IMAGE = `https://source.unsplash.com/random/${size}?immobile`;

  return (
    <>
      <ShadowImage />
      <Container
        initial={{
          opacity: 0,
          scale: 1.2,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
        transition={{
          delay: 1.2,
        }}
        whileHover={{
          scale: 1.2,
        }}
        src={RANDOM_IMAGE}
      />
    </>
  );
};

export default Image;
