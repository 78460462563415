import { FC, useEffect, useState } from 'react';
import { getAddressByCep } from '../../../../../services/Address/request';
import { getOwners } from '../../../../../services/InsertData/request';
import { GetOwnersData, PixKeyType } from '../../../../../services/InsertData/types';
import { isBrazil, onlyNumbers } from '../../../../../utils/Formatter';
import { UseFormik } from '../../../../../utils/Formik/types';
import { compareList } from '../../../../../utils/Sorting';
import { SelectProps } from '../../../../Autocomplete/Autocomplete';
import ControlledSwitchButton from '../../../../ControlledSwitchButton';
import DatePicker from '../../../../DatePicker';
import DropdownAutocomplete from '../../../../DropdownAutocomplete';
import SimpleSelect from '../../../../SimpleSelect';
import CountrySelect from '../../../../SimpleSelect/CountrySelect';
import StateSelect from '../../../../SimpleSelect/StateSelect';
import TextField from '../../../../TextField';
import { Container, PasswordText } from './styles';

const genderOptions = [
  {
    value: 'Not informed',
    valueLabel: 'Não informado',
  },
  {
    value: 'Male',
    valueLabel: 'Masculino',
  },
  {
    value: 'Female',
    valueLabel: 'Feminino',
  },
];

type FormState = 'user' | 'owner';
type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed' | undefined;
interface AddOwnerFormikValuesType {
  formType: FormState;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber1: string;
  phoneNumber2: string;
  gender: string;
  birthDate?: Date | null;
  cpf?: string;
  cnpj?: string;
  corporateName: string;
  tradingName: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  nationality: string;
  maritalStatus: MaritalStatus;
  profession: string;
  emailForOperation: string;
  transferDay: number;
  bank: number;
  branchNumber: string;
  accountNumber: string;
  accountType: string;
  pixKey: string;
  pixKeyType?: PixKeyType | 'Dont_Have';
  entityName: string;
  bankCpfOrCnpj: string;
  invoiceEntityName: string;
  invoiceCpfOrCnpj: string;
  invoiceEmail: string;
  invoicePhoneNumber1: string;
  invoiceStreet: string;
  invoiceAddressNumber: string;
  invoiceAddressComplement: string;
  invoiceDistrict: string;
  invoiceCity: string;
  invoiceState: string;
  invoiceCountry: string;
  invoicePostalCode: string;
  ownerId?: number;
  isIndividual: boolean;
  personID?: string | undefined;
}

type FormAddUserProps = {
  formik?: UseFormik<AddOwnerFormikValuesType>,
  isEditingForm: boolean,
  handleChangePersonType: (manualType?: boolean) => void;
};

const FormAddUser:FC<FormAddUserProps> = ({
  formik, isEditingForm, handleChangePersonType,
}) => {
  const [ownersList, setOwnersList] = useState<GetOwnersData[]>([]);
  const ownersIds: string[] = [];
  const ownersNames: string[] = [];

  async function handleGetAddress() {
    if (formik) {
      const response = await getAddressByCep(onlyNumbers(formik.values.postalCode));

      if ('erro' in response) {
        formik.setFieldError('postalCode', 'O cep não é válido!');
      } else {
        const {
          bairro, complemento, localidade, logradouro, uf,
        } = response;
        formik.setFieldValue('neighborhood', bairro);
        formik.setFieldValue('complement', complemento);
        formik.setFieldValue('city', localidade);
        formik.setFieldValue('street', logradouro);
        formik.setFieldValue('state', uf);
        formik.setFieldValue('country', 'Brasil');
      }
    }
  }

  const getSelectOwners = async () => {
    const results = await getOwners();
    setOwnersList(results);
  };

  const handleGetOwner = (): SelectProps[] => {
    ownersList.forEach((owner) => {
      if (owner.user.trading_name === '') {
        ownersIds.push(`${owner.id}`);
        ownersNames.push(`${owner.user.first_name} ${owner.user.last_name}`);
      } else {
        ownersIds.push(`${owner.id}`);
        ownersNames.push(`${owner.user.trading_name}`);
      }
    });

    return ownersNames
      .map((name, index) => ({
        optionText: name,
        optionValue: ownersIds[index],
      }))
      .sort((a, b) => compareList(a.optionText, b.optionText));
  };

  const getOwnerSelected = (ownerId: number) => {
    const index = ownersIds.findIndex((id) => Number(id) === ownerId);
    return ownersNames?.[index] || '';
  };

  useEffect(() => {
    getSelectOwners();
  }, []);

  useEffect(() => {
    if (formik && isBrazil(formik?.values?.country)) {
      if (formik?.values?.postalCode?.length >= 9) {
        handleGetAddress();
      }
    }
  }, [formik?.values?.postalCode, formik?.values?.country]);

  return (
    <Container>
      {isEditingForm && (
        <div className="one-column">
          <DropdownAutocomplete
            required
            label="Proprietário"
            id="ownerId"
            options={handleGetOwner()}
            formik={formik}
            placeholder={formik?.values?.ownerId ? `${getOwnerSelected(formik?.values?.ownerId)}` : 'Digite aqui...'}
            labelClassName="labelClass"
          />
        </div>
      )}

      <div className="two-columns">
        <TextField
          required
          id="personID"
          label="Person ID"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values?.personID}
        />
        <TextField
          required
          id="firstName"
          label="Nome"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.firstName}
        />
        <TextField
          required
          id="lastName"
          label="Sobrenome"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.lastName}
        />
      </div>
      <div className="one-column">
        <TextField
          id="nickname"
          label="Apelido"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.nickname}
        />
      </div>
      <div className="one-column">
        <TextField
          required={!isEditingForm}
          id="email"
          type="email"
          label="E-mail"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.email}
        />
      </div>
      {!isEditingForm && (
        <>
          <div className="two-columns">
            <TextField
              required
              labelClassName="labelClass"
              id="password"
              type="password"
              label="Senha"
              placeholder="Digite aqui..."
              formik={formik}
              value={formik?.values.password}
            />
            <TextField
              required
              labelClassName="labelClass"
              value={formik?.values.passwordConfirmation}
              id="passwordConfirmation"
              type="password"
              label="Confirmar senha"
              placeholder="Digite aqui..."
              formik={formik}
            />
          </div>
          <div className="one-column">
            <PasswordText>
              no mín.: 8 caracteres, diferente do nome ou outra informação pessoal,
              conter letras e números, não pode ser senha comum
            </PasswordText>
          </div>
        </>
      )}
      <div className="two-columns">
        <TextField
          required={!isEditingForm}
          labelClassName="labelClass"
          value={formik?.values.phoneNumber1}
          id="phoneNumber1"
          mask="phone"
          label="Telefone 1"
          placeholder="+00 (00) 00000-0000"
          formik={formik}
        />
        <TextField
          labelClassName="labelClass"
          value={formik?.values.phoneNumber2}
          id="phoneNumber2"
          mask="phone"
          label="Telefone 2"
          placeholder="+00 (00) 00000-0000"
          formik={formik}
        />
      </div>
      <div className="two-columns">
        <SimpleSelect
          required
          id="gender"
          label="Gênero"
          labelClassName="labelClass"
          className="selector"
          options={genderOptions}
          placeholder="Selecione..."
          firstValue="not_informed"
          formik={formik}
        />
        <DatePicker
          label="Data de nascimento"
          id="birthDate"
          viewsCustom={['day', 'month', 'year']}
          labelClassName="labelClass"
          disableFuture
          showDaysOutsideCurrentMonth
          formik={formik}
        />
      </div>
      <div className="one-column">
        <div className="switch">
          <h2>É pessoa jurídica?</h2>
          <ControlledSwitchButton
            checked={!formik?.values.isIndividual}
            handleChange={() => handleChangePersonType()}
          />
        </div>
      </div>
      {!formik?.values.isIndividual ? (
        <>
          <div className="one-column">
            <TextField
              required={!isEditingForm && !formik?.values.isIndividual}
              labelClassName="labelClass"
              value={formik?.values.cnpj}
              id="cnpj"
              mask="cnpj"
              label="CNPJ"
              placeholder="Apenas números"
              formik={formik}
            />
          </div>
          <div className="two-columns">
            <TextField
              labelClassName="labelClass"
              value={formik?.values.corporateName}
              id="corporateName"
              label="Razão social"
              placeholder="Digite aqui..."
              formik={formik}
              required={!formik?.values.isIndividual}
            />
            <TextField
              labelClassName="labelClass"
              value={formik?.values.tradingName}
              id="tradingName"
              label="Nome fantasia"
              placeholder="Digite aqui..."
              formik={formik}
              required={!formik?.values.isIndividual}
            />
          </div>
        </>
      ) : (
        <div className="one-column">
          <TextField
            required={!isEditingForm && formik.values.isIndividual}
            labelClassName="labelClass"
            value={formik?.values.cpf}
            id="cpf"
            mask="cpf"
            label="CPF"
            placeholder="Apenas números"
            formik={formik}
          />
        </div>
      )}
        <div className="one-column">
          <CountrySelect
            required
            id="country"
            formik={formik}
            label="País"
            labelClassName="labelClass"
            valueIsFullName
          />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          value={formik?.values.postalCode}
          id="postalCode"
          {...(isBrazil(formik?.values.country) ? { mask: 'cep' } : {})}
          label="CEP"
          placeholder="Digite aqui..."
          formik={formik}
        />
        {isBrazil(formik?.values.country) ? (
          <StateSelect
            required
            id="state"
            formik={formik}
            label="Estado"
            labelClassName="labelClass"
          />
        ) : (
          <TextField
            required
            id="state"
            formik={formik}
            label="Estado"
            placeholder="Digite aqui..."
            value={formik?.values.state}
            labelClassName="labelClass"
          />
        )}
      </div>
      <div className="two-columns">
        <TextField
          labelClassName="labelClass"
          value={formik?.values.street}
          id="street"
          label="Lagradouro"
          placeholder="Digite aqui..."
          formik={formik}
          required
        />
        <TextField
          labelClassName="labelClass"
          value={formik?.values.number}
          id="number"
          label="Número"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          value={formik?.values.neighborhood}
          id="neighborhood"
          label="Bairro"
          placeholder="Digite aqui..."
          formik={formik}
        />
        <TextField
          required
          labelClassName="labelClass"
          value={formik?.values.city}
          id="city"
          label="Cidade"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="one-column">
        <TextField
            labelClassName="labelClass"
            value={formik?.values.complement}
            id="complement"
            label="Complemento"
            placeholder="Digite aqui..."
            formik={formik}
          />
      </div>
    </Container>
  );
};

export default FormAddUser;
