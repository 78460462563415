import React, { useMemo } from 'react';

import { IQuest } from '../types';
import { PartnersSwitcher, PartnersTooltip } from '../../../../../../../components';
import { usePartners } from '../../../../../../../../../context/Partners/Partner/PartnerContext';

import { QuestContainer, QuestContent } from './style';

const LINK_EBOOK = 'https://api.whatsapp.com/send?text=https://sapron.com.br/static/media/ebook-rentabilize.05ac0956.pdf';
const LINK_ALLOTMENTS_LOCATION = 'https://www.google.com/maps/d/viewer?mid=1W7ivGvlo6hGKx-fa9mWIPyg8OFncixw&ll=-22.388727970158094%2C-42.84507501927432&z=10';

const Quest = ({ title, option, tooltip }: IQuest) => {
  const { setQuestions, questions } = usePartners();
  const isEbookQuestion = option === 'hasTheOwnerAlreadyReceivedTheEBook';
  const isLocationQuestion = option === 'IsIndicatedWithinSeazoneOperationArea';

  const getActive = useMemo(() => {
    if (questions) {
      return Boolean(questions[option]);
    }

    return false;
  }, [questions]);

  const handleChange = (newValue: boolean) => {
    setQuestions((old) => ({ ...old, [option]: newValue }));
  };

  return (
    <QuestContainer>
      <QuestContent>
        <p>
          {title}
          <span>*</span>
        </p>
        <PartnersTooltip
          text={(
            <>
              <p>{tooltip}</p>
            </>
          )}
        />
      </QuestContent>

      {isEbookQuestion && (
        <p>
          <a href={LINK_EBOOK} target="_blank">Clique aqui</a>
          {' '}
          e envie agora !
        </p>
      )}

      {isLocationQuestion && (
        <p>
          <a href={LINK_ALLOTMENTS_LOCATION} target="blank">{'Clique aqui'}</a>
          {' '}
          {'e verifique quais as áreas de atuação da Seazone'}
        </p>
      )}

      <PartnersSwitcher
        active={getActive}
        onChange={() => handleChange(!getActive)}
      />
    </QuestContainer>
  );
};

export default Quest;
