import styled, { DefaultTheme, keyframes } from 'styled-components';
import { Button } from '@mui/material';
import { ButtonProps, Color, Variant } from './types';

const FadeOut = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const styles = (
  color: Color,
  variant: Variant,
  styledTheme: DefaultTheme,
  link: boolean,
  invisible: boolean,
): string => {
  let css = '';
  const { palette } = styledTheme;

  if (color === 'blue') {
    css = `
      background: ${'transparent'};
    border: 1px solid ${variant === 'outlined' ? palette?.white.main.hex()
    : palette?.blue._910.hex()};
      &, .MuiButton-label {
        color: ${variant === 'outlined' ? palette?.white.main.hex() : palette?.blue._910.hex()};
      }
      &:hover {
        border: 1px solid ${palette?.blue._910.hex()};
      }
      &:disabled {
        opacity: 0.6;
      }
    `;
  }

  if (color === 'red') {
    css = `
      background: ${variant === 'outlined' ? palette?.white.main.hex()
    : `linear-gradient(180deg,${palette?.blue._800.hex()} 0%, ${palette?.white.main.hex()} 100%)`};
    border: 1px solid ${palette?.white.main.hex()};
      &, .MuiButton-label {
        color: ${variant === 'outlined' ? palette?.blue._850.hex() : palette?.white.main.hex()};
      }
      &:hover {
        border: 1px solid ${palette?.white.main.hex()};
      }
      &:disabled {
        opacity: 0.6;
      }
    `;
  }

  if (color === 'grey') {
    css = `
      &, &:hover {
        background: ${palette?.white.main.hex()};
        border: 1px solid ${palette?.grey._400.hex()};
        &, .MuiButton-label {
          color: ${variant === 'outlined' ? palette?.grey._600.hex() : palette?.grey._720.hex()};
        }
        &:hover {
          background: ${palette?.grey._200.hex()};
        }
        &:disabled {
          opacity: 0.6;
          border: 1px solid ${palette?.grey._400.hex()};
          background: ${palette?.grey._300.hex()};
          color: ${variant === 'outlined' ? palette?.grey._900.hex() : palette?.grey._900.hex()};
        }
      }
    `;
  }

  if (color === 'black') {
    css = `
    &, &:hover {
        background: rgb(21,27,38);
        background: linear-gradient(360deg, rgba(21,27,38,1) 0%, rgba(89,93,102,1) 100%);
        &, .MuiButton-label {
          color: ${variant === 'outlined' ? palette.white.main.hex() : palette.white.main.hex()};
        }
        &:hover {
          background: linear-gradient(360deg, rgba(21,27,38,1) 0%, rgba(89,93,102,1) 80%);
        }
        &:disabled {
          border: 1px solid ${palette.grey._400.hex()};
          background: ${palette.grey._300.hex()};
          color: ${variant === 'outlined' ? palette.grey._900.hex() : palette.grey._900.hex()};
        }
      }
    `;
  }

  if (link) {
    css = `
      background: unset;
      color: ${palette.blue._850.hex()};
      padding: 0;
      margin: 0;
      display: inline;
      &:hover {
        background: unset;
        color: ${palette.blue._900.hex()};
        text-decoration: underline;
      }
      & > .MuiTouchRipple-root {
        display: none;
      }
    `;
  }

  if (invisible) {
    css = `
    display: none;
    `;
  }

  return css;
};

export const OutlinedRedButton = styled(Button) <ButtonProps>`
  animation-name: ${FadeOut};
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;

  && {
    width: auto;
    min-width: 120px;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    display: flex;
    flex: 1;

    ${({
    theme, customcolorbg, variant, linkto, invisible,
  }) => styles(
    customcolorbg, variant, theme, !!linkto, (invisible || false),
  )}

    text-transform: none;
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.02em;
  }
`;
