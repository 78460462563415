import React, { FC, ReactElement } from 'react';
import { Drawer } from '@mui/material';
import { X } from 'react-feather';

import {
  Header,
  Container,
  Content,
  VerticalTabs,
  SelectableTab,
  HeaderText,
  CloseButton,
  PropertyNameContainer,
} from './styles';

import { useModal } from '../../hooks/ModalHook/useModal';
import Tooltip from '../Tooltip';

type Props = {
  children: ReactElement,
  anchor?: 'right' | 'left',
  setOpen?: Function,
  onClose?: Function,
  open?: boolean,
  options?: Array<{
    icon: string;
    alt: string;
    title: string;
  }>;
  activeTabs?: boolean;
  headerTitle?: string;
  propertyName?: string;
  customStyle?: {
    height?: string;
  }
};

const LateralModal: FC<Props> = ({
  children,
  anchor = 'right',
  open = false,
  setOpen = () => {},
  options,
  activeTabs,
  headerTitle,
  onClose,
  propertyName,
  customStyle = undefined,
}) => {
  const { modalActive, handleModalActive } = useModal();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleModalActive(newValue);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    if (setOpen) {
      setOpen(false);
    }
    handleModalActive(0);
  };

  return (
    <Container height={customStyle?.height}>
      <Drawer
        open={open}
        anchor={anchor}
        onClose={handleClose}
        style={{ overflow: 'none' }}
      >
        <Header>
          <HeaderText>
            {headerTitle}
            {propertyName && (
              <PropertyNameContainer>
                {propertyName}
              </PropertyNameContainer>
            )}
          </HeaderText>
          <CloseButton onClick={handleClose}>
            <X size={16} />
          </CloseButton>
        </Header>
        {activeTabs && (
          <VerticalTabs
            value={modalActive}
            orientation="vertical"
            variant="scrollable"
            onChange={handleChange}
          >
            {options?.map((data, idx) => (

              <SelectableTab className={idx === modalActive ? 'active' : ''} key={data.alt} icon={<Tooltip text={data.title}><img src={data.icon} alt={data.alt} /></Tooltip>} />

            ))}
          </VerticalTabs>
        )}
        <Content>
          {children}
        </Content>
      </Drawer>

    </Container>
  );
};

export default LateralModal;
