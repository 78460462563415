import styled from 'styled-components';

export const Container = styled.div`
  .styleCheckbox div{
    font-family: 'Quicksand', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.grey._900.hex()}
  }

  .eiZKwC {
    margin: 15px;

    &:last-child{
      margin-top: 0px;
    }

  }
`;

export const ContainerCheckbox = styled.div`
  height: 100%;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Line = styled.hr`
    background-color: ${({ theme }) => theme.palette.grey._420.hex()};
    margin-bottom: 15px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonClear = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  width: 150px;
  cursor: pointer;
`;
