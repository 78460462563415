import React from 'react';

import Body from './Body';
import Header from './Header';

import {
  Container,
} from './styles';

const FinancialFeedBack = () => (
  <Container>
    <Header />
    <Body />
  </Container>
);

export default FinancialFeedBack;
