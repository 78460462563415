import { Close } from '@mui/icons-material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { getViaCeps } from '../../../../services/Address/request';
import { ViaCepResponse } from '../../../../services/Address/types';
import { patchOwnerInvoice, postOwnerInvoice } from '../../../../services/Owner/request';
import { OwnerInvoiceDetails } from '../../../../services/Owner/types';
import TextField from '../../../TextField';
import {
  ButtonCancel,
  ButtonClose,
  ButtonSave,
  Container,
  ContentInputs,
  HeaderModalNewBankAccount,
  RowButtons,
  RowInput,
  Wrapper,
} from './styles';
import RadioTypePerson from '../../../RadioButton/RadioTypePerson';
import SwitcherButton from '../../../SwitcherButton/SwitcherButton';
import { useToastErrorMessage } from '../../../../utils/Messages';
import { useToast } from '../../../../context/ToastContext';
import { useCustomToast } from '../../../../context/CustomToastContext';

interface Props {
  openModal: boolean;
  setOpenModal: () => void;
  dataFiscalNote: OwnerInvoiceDetails;
  setDataFiscalNote: (a: OwnerInvoiceDetails) => void;
}

const ModalNewDataFiscalNote = ({
  openModal,
  setOpenModal,
  dataFiscalNote,
  setDataFiscalNote,
}: Props) : JSX.Element => {
  const handleCloseModal = () => {
    setDataFiscalNote({} as OwnerInvoiceDetails);
    setOpenModal();
  };

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const [address, setAddress] = useState<ViaCepResponse>();
  const [selectedSwitcher, setSelectedSwitcher] = React.useState(false);
  const [isValidationWithCpf, setIsValidationWithCpf] = useState(false);

  const ownerToast = useCustomToast();

  const validationWithCpf = Yup.object().shape({
    email: Yup.string().required().email('E-mail inválido!'),
    name: Yup.string().required(),
    phone: Yup.string().required(),
    cep: Yup.string().required(),
    address: Yup.string().required(),
    number: Yup.string().required(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    cpf: Yup.string().required(),
  });

  const validationWithCnpj = Yup.object().shape({
    email: Yup.string().required().email('E-mail inválido!'),
    name: Yup.string().required(),
    phone: Yup.string().required(),
    cep: Yup.string().required(),
    address: Yup.string().required(),
    number: Yup.string().required(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    cnpj: Yup.string().required(),
  });

  const initialValues = {
    typePerson: dataFiscalNote?.cnpj ? 'legal' : 'individual',
    email: dataFiscalNote ? dataFiscalNote.email : '',
    name: dataFiscalNote ? dataFiscalNote.invoice_entity_name : '',
    cpf: dataFiscalNote ? dataFiscalNote.cpf : '',
    cnpj: dataFiscalNote ? dataFiscalNote.cnpj : '',
    phone: dataFiscalNote ? dataFiscalNote.phone_number : '',
    cep: dataFiscalNote ? dataFiscalNote.postal_code : '',
    address: dataFiscalNote ? dataFiscalNote.address : '',
    number: dataFiscalNote ? dataFiscalNote.address_number : '',
    complement: dataFiscalNote ? dataFiscalNote.complement : '',
    neighborhood: dataFiscalNote ? dataFiscalNote.district : '',
    city: dataFiscalNote ? dataFiscalNote.city : '',
    state: dataFiscalNote ? dataFiscalNote.state : '',

  };

  const formik = useFormik({
    initialValues,
    validationSchema: isValidationWithCpf ? validationWithCpf : validationWithCnpj,
    onSubmit: async (values) => {
      try {
        if (dataFiscalNote.id) {
          await patchOwnerInvoice({
            address: values.address || address?.logradouro as string,
            address_number: values.number,
            city: values.city || address?.localidade as string,
            complement: values.complement || address?.complemento as string,
            district: values.neighborhood || address?.bairro as string,
            email: values.email,
            invoice_entity_name: values.name,
            postal_code: values.cep || address?.cep as string,
            phone_number: values.phone,
            state: values.state || address?.uf as string,
            cpf: (values?.cpf?.split('.').join(''))?.split('-').join(''),
            cnpj: (((values?.cnpj?.split('.').join(''))?.split('.').join(''))
              ?.split('/')
              ?.join(''))
              ?.split('-')
              ?.join(''),
            is_default: !!selectedSwitcher,
          }, dataFiscalNote.id);
          toast.success('Dados de nota fiscal atualizados com sucesso!');

          const verifyIfIsDefaultChange = dataFiscalNote.is_default !== !!selectedSwitcher;

          if (verifyIfIsDefaultChange) {
            ownerToast.addToast({
              type: 'success',
              title: 'Seu contato principal foi alterado com sucesso!',
              description: 'Todas as informações foram remanejadas para o novo contato principal!',
            });
          }

          ownerToast.addToast({
            type: 'success',
            title: 'Dados de nota fiscal atualizados com sucesso!',
            description: `Os dados da nota de: ${values.name}, foram atualizados com sucesso!`,
          });
        } else {
          await postOwnerInvoice({
            address: values.address || address?.logradouro as string,
            address_number: values.number,
            city: values.city || address?.localidade as string,
            complement: values.complement || address?.complemento as string,
            district: values.neighborhood || address?.bairro as string,
            email: values.email,
            invoice_entity_name: values.name,
            postal_code: values.cep || address?.cep as string,
            phone_number: values.phone,
            state: values.state || address?.uf as string,
            cpf: (values?.cpf?.split('.').join(''))?.split('-').join(''),
            cnpj: (((values?.cnpj?.split('.').join(''))?.split('.').join(''))
              ?.split('/')
              ?.join(''))
              ?.split('-')
              ?.join(''),
            is_default: !!selectedSwitcher,
          });
          toast.success('Dados de nota fiscal salvos com sucesso!');
        }
        handleCloseModal();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  useEffect(() => {
    setIsValidationWithCpf(formik.values.typePerson === 'individual');
  }, [formik.values.typePerson]);

  const getAddressByCep = async (cep: string) => {
    const cepAux = cep.split('-').join('');
    if (cepAux.length === 8) {
      const result = await getViaCeps(cepAux);
      setAddress(result);
      formik.setFieldValue('city', result.localidade);
      formik.setFieldTouched(
        'city', true, false,
      );
      formik.setFieldValue('state', result.uf);
      formik.setFieldTouched(
        'state', true, false,
      );
      formik.setFieldValue('cep', result.cep);
      formik.setFieldTouched(
        'cep', true, false,
      );
    }
  };

  useEffect(() => {
    setAddress({
      bairro: dataFiscalNote.district || '',
      cep: dataFiscalNote.postal_code || '',
      complemento: dataFiscalNote.complement || '',
      localidade: dataFiscalNote.city || '',
      logradouro: dataFiscalNote.address || '',
      uf: dataFiscalNote.state || '',
      ddd: '',
      gia: '',
      ibge: '',
      siafi: '',
    });
  }, []);

  const handleChangeSwitcher = () => {
    setSelectedSwitcher(!selectedSwitcher);
  };

  function handleWrapperClick(e: React.MouseEvent<HTMLElement>) {
    const classList = (e.target as Element).classList.value.split(' ');
    if (classList.includes('area-close')) {
      handleCloseModal();
    }
  }

  useEffect(() => {
    if (dataFiscalNote.is_default) {
      setSelectedSwitcher(true);
    }
  }, []);

  return (
    <Wrapper data-testid="modal-data-fiscal-note" openModal={openModal} className="area-close" onClick={(e) => handleWrapperClick(e)}>
      <Container onSubmit={formik.handleSubmit}>
        <ButtonClose onClick={handleCloseModal}>
          <Close />
          Fechar
        </ButtonClose>
        <ContentInputs>
          <HeaderModalNewBankAccount>
            <h1 data-testid="modal-data-fiscal-note-title">{dataFiscalNote.id ? 'Editar dados da nota fiscal' : 'Adicione dados para nota fiscal' }</h1>
            {/* <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span> */}
          </HeaderModalNewBankAccount>
          <RowInput>
            <TextField
              label="*E-mail"
              id="email"
              placeholder="Digite aqui..."
              formik={formik}
              value={dataFiscalNote.email}
              dataCy="email-field"
            />
          </RowInput>
          <RowInput>
            <TextField
              label="*Nome"
              id="name"
              placeholder="Digite aqui..."
              formik={formik}
              value={dataFiscalNote.invoice_entity_name}
              dataCy="name-field"
            />
          </RowInput>
          <RowInput>
            <RadioTypePerson
              labelPlacement="end"
              id="typePerson"
              formik={formik}
            />
          </RowInput>
          {formik.values.typePerson === 'individual' && (
          <RowInput>
            <TextField
              label="*CPF"
              id="cpf"
              placeholder="Digite aqui..."
              formik={formik}
              mask="cpf"
              value={dataFiscalNote.cpf}
              dataCy="cpf-field"
            />
          </RowInput>
          )}
          {formik.values.typePerson === 'legal' && (
          <RowInput>
            <TextField
              label="*CNPJ"
              id="cnpj"
              placeholder="Digite aqui..."
              mask="cnpj"
              formik={formik}
              value={dataFiscalNote.cnpj}
            />
          </RowInput>
          )}
          <RowInput>
            <TextField
              label="*Telefone"
              id="phone"
              placeholder="Digite aqui..."
              formik={formik}
              mask="phone"
              value={dataFiscalNote.phone_number}
              dataCy="phone-field"
            />
          </RowInput>
          <RowInput>
            <TextField
              label="*CEP"
              id="cep"
              placeholder="Digite aqui..."
              onChange={(event) => getAddressByCep(event.target.value)}
              formik={formik}
              mask="cep"
              value={address?.cep}
              dataCy="cep-field"
            />
          </RowInput>
          <RowInput>
            <TextField
              label="*Endereço"
              id="address"
              placeholder="Digite aqui..."
              formik={formik}
              value={dataFiscalNote.address}
              dataCy="address-field"
            />
          </RowInput>
          <RowInput>
            <div className="column">
              <TextField
                label="*Número"
                id="number"
                placeholder="Digite aqui..."
                formik={formik}
                value={dataFiscalNote.address_number}
                dataCy="number-field"
              />
            </div>
            <div className="column">
              <TextField
                label="Complemento"
                id="complement"
                placeholder="Digite aqui..."
                formik={formik}
                value={dataFiscalNote.complement}
                dataCy="complement-field"
              />
            </div>
          </RowInput>
          <RowInput>
            <TextField
              label="*Bairro"
              id="neighborhood"
              placeholder="Digite aqui..."
              value={address?.bairro}
              formik={formik}
              dataCy="neighborhood-field"
            />
          </RowInput>
          <RowInput>
            <div className="column">
              <TextField
                label="*Cidade"
                id="city"
                placeholder="Digite aqui..."
                value={address?.localidade}
                formik={formik}
                dataCy="city-field"
              />
            </div>
            <div className="column">
              <TextField
                label="*Estado"
                id="state"
                placeholder="Digite aqui..."
                value={address?.uf}
                formik={formik}
                dataCy="state-field"
              />
            </div>
          </RowInput>
          <RowInput className="selector">
            <span>Esse contato vai ser seu principal contato?</span>
            <section>
              <SwitcherButton
                onChange={handleChangeSwitcher}
                selected={selectedSwitcher}
              />
            </section>
          </RowInput>
        </ContentInputs>
        <RowButtons>
          <ButtonCancel onClick={handleCloseModal} type="button">
            {dataFiscalNote.id ? 'Descartar' : 'Cancelar'}
          </ButtonCancel>
          <ButtonSave data-cy="btn-save" type="submit">
            {dataFiscalNote.id ? 'Salvar Ajustes' : 'Salvar'}
          </ButtonSave>
        </RowButtons>
      </Container>
    </Wrapper>
  );
};

export default ModalNewDataFiscalNote;
