import { RevenuesPropertyProps, Status } from '../../../services/FinancialClose/types';
import { compareList, compareDates } from '../../../utils/Sorting';
import { Order } from '../FinancialCloseContext/types';
import { GridPropertyProps, OrderBy } from './types';

export const initialValuesFinancialCloseProperty: GridPropertyProps = {
  id: 0,
  property: {
    id: 0,
    code: 'Não informado',
  },
  owner: {
    id: 0,
    name: 'Não informado',
  },
  host: {
    id: 0,
    name: 'Não informado',
  },
  balance_initial: 'R$ 0,00',
  balance_final: 'R$ 0,00',
  revenue: {
    airbnb: 'R$ 0,00',
    booking: 'R$ 0,00',
    contract: 'R$ 0,00',
    expedia: 'R$ 0,00',
    homeaway: 'R$ 0,00',
    stays: 'R$ 0,00',
    decolar: 'R$ 0,00',
    total: 'R$ 0,00',
  },
  income: {
    airbnb: 'R$ 0,00',
    booking: 'R$ 0,00',
    contract: 'R$ 0,00',
    expedia: 'R$ 0,00',
    homeaway: 'R$ 0,00',
    stays: 'R$ 0,00',
    decolar: 'R$ 0,00',
    total: 'R$ 0,00',
  },
  expense: {
    expense_host: 'R$ 0,00',
    expense_seazone: 'R$ 0,00',
    expense_owner: 'R$ 0,00',
    total: 'R$ 0,00',
  },
  refund: {
    refund_host: 'R$ 0,00',
    refund_seazone: 'R$ 0,00',
    refund_owner: 'R$ 0,00',
    total: 'R$ 0,00',
  },
  transfer: 'R$ 0,00',
  commission: {
    host: 'R$ 0,00',
    seazone: 'R$ 0,00',
    total: 'R$ 0,00',
  },
  property_manual_fit: {
    value: 'R$ 0,00',
    customColor: 'default',
  },
  property_manual_fits: [],
  reservations: [{
    id: 0,
    code: '',
    check_in_date: '',
    check_out_date: '',
    total_value: 'R$ 0,00',
    gross_daily_value: 'R$ 0,00',
    daily_net_value: 'R$ 0,00',
    daily_manual_fit: {
      value: 'R$ 0,00',
      customColor: 'default',
    },
    cleaning_fee_value: 'R$ 0,00',
    cleaning_net_value: 'R$ 0,00',
    cleaning_manual_fit: {
      value: 'R$ 0,00',
      customColor: 'default',
    },
    ota_comission: 'R$ 0,00',
    platform: '',
    guest: 'Não informado',
    status: 'Active',
    comment: '',
    conciliada: false,
  }],
  expenses: [{
    id: 0,
    code: '',
    register_date: '',
    expense_date: '',
    cash_date: '',
    category: '',
    description: '',
    value: 'R$ 0,00',
    owner_approved: false,
    status: '',
    paid_by: '',
  }],
  status: 'Open',
  property_status: '',
  revenue_id: undefined,
  revenues_ota_ids: [],
  checked: false,
  expanded: false,
  is_to_keep_funds_in_seazone: false,
};

export const getFinancialClosePropertyUpdated = (data: GridPropertyProps) => ({
  ...data,
  property: {
    ...data.property,
  },
  owner: {
    ...data.owner,
  },
  host: {
    ...data.host,
  },
  revenue: {
    ...data.revenue,
  },
  income: {
    ...data.income,
  },
  expense: {
    ...data.expense,
  },
  property_manual_fit: {
    ...data.property_manual_fit,
  },
  reservations: [...data.reservations],
  expenses: [...data.expenses],
});

export const formatRevenuesProperty = (properties: GridPropertyProps[], status: Status) => {
  const revenues: RevenuesPropertyProps[] = properties.map((item) => ({
    revenue: {
      id: item?.revenue_id || -1,
      fields: {
        status,
      },
    },
    revenues_ota: {
      ids: item?.revenues_ota_ids || [],
      fields: {
        status,
      },
    },
  }));
  return revenues;
};

export const sortFinancialDataOfProperty = (
  financialData: GridPropertyProps[],
  orderBy: OrderBy,
  order: Order,
  financialDataIds?: number[], // ids of financial data selected in grid
): GridPropertyProps[] => {
  let sorted: GridPropertyProps[] = financialData;

  switch (orderBy) {
    case 'property':
      sorted = financialData.map((item) => item).sort((a, b) => compareList(
        a.property.code, b.property.code, order,
      ));
      break;

    case 'host':
      sorted = financialData.map((item) => item).sort((a, b) => compareList(
        a.host.name, b.host.name, order,
      ));
      break;

    case 'owner':
      sorted = financialData.map((item) => item).sort((a, b) => compareList(
        a.owner.name, b.owner.name, order,
      ));
      break;

    case 'property_status':
      sorted = financialData.map((item) => item).sort((a, b) => compareList(
        a.property_status, b.property_status, order,
      ));
      break;

    case 'reservation_code':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.code, b.code, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_checkin':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareDates(
            a.check_in_date, b.check_in_date, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_checkout':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareDates(
            a.check_out_date, b.check_out_date, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_total_value':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.total_value, b.total_value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_dailys_value':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.gross_daily_value, b.gross_daily_value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_net_daily_value':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.daily_net_value, b.daily_net_value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_manualfit_daily':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.daily_manual_fit.value, b.daily_manual_fit.value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_cleaning_value':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.cleaning_fee_value, b.cleaning_fee_value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_net_cleaning_value':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.cleaning_net_value, b.cleaning_net_value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_manualfit_cleaning':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.cleaning_manual_fit.value, b.cleaning_manual_fit.value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_ota_comission':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.ota_comission, b.ota_comission, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_platform':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.platform, b.platform, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_guest':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.guest, b.guest, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_status':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.status, b.status, order,
          )) : [...property.reservations],
      }));
      break;

    case 'expense_code':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.code, b.code, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_register_date':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.register_date, b.register_date, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_cash_date':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.cash_date, b.cash_date, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_date':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.expense_date, b.expense_date, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_category':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.category, b.category, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_description':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.description, b.description, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_value':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.value, b.value, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_owner_approved':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            `${a.owner_approved}`, `${b.owner_approved}`, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_status':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.status, b.status, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_paid_by':
      sorted = financialData.map((property) => ({
        ...getFinancialClosePropertyUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.paid_by, b.paid_by, order,
          )) : [...property.expenses],
      }));
      break;

    default:
      return sorted;
  }
  return sorted;
};

export const propertyIsSelected = (id: number) => {
  const response = localStorage.getItem('@saprom-pms-web/financialclose_property_selected');
  if (response) {
    const data = JSON.parse(response);
    return data?.[id] || false;
  }
  return false;
};

export const gridRowIsExpanded = (id: number) => {
  const response = localStorage.getItem('@saprom-pms-web/financialclose_property_expanded');
  if (response) {
    const data = JSON.parse(response);
    return data?.[id] || false;
  }
  return false;
};
