import React from 'react';
import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';

import {
  Item,
  Label,
  Number,
  Container,
  ItemsContainer,
  Title,
} from './style';

const Accompaniment = () => {
  const { resumeIndications } = usePartners();

  return (
    <Container>
      <Title>Acompanhamento</Title>
      <ItemsContainer>
        <Item>
          <Number>{resumeIndications.inProgress}</Number>
          <Label>Em andamento</Label>
        </Item>
        <Item>
          <Number>{resumeIndications.gains}</Number>
          <Label>Ganhos</Label>
        </Item>
        <Item>
          <Number>{resumeIndications.lost}</Number>
          <Label>Perdidos</Label>
        </Item>
      </ItemsContainer>
    </Container>
  );
};

export default Accompaniment;
