import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)<{ isActive: boolean }>`
  color: ${({ isActive, theme }) => (isActive ? `${theme.palette.blue._900.hex()}` : `${theme.palette.grey._670.hex()}`)}
`;
