import { Fragment, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import TextField from '../../../TextField/TextField';

import {
  AlertText,
  InsertDamageDetailsCardContainer,
  DamageDetailsHeaderContainer,
  DamageDetailsHeaderItem,
  TypeButton,
  ButtonContainer,
  DamageTypeForm,
} from './styles';

import trousseauIcon from '../../../../assets/icons/guestDamage/trousseauIcon.svg';
import itemDamageIcon from '../../../../assets/icons/guestDamage/itemDamageIcon.svg';
import itemLossIcon from '../../../../assets/icons/guestDamage/itemLossIcon.svg';
import propertyDamageIcon from '../../../../assets/icons/guestDamage/propertyDamageIcon.svg';
import pendenciesIcon from '../../../../assets/icons/guestDamage/pendenciesIcon.svg';
import { DamageResume } from './DamageResume';
import FormButton from '../../../FormButton/FormButton';
import { useReservations } from '../../../../hooks/GuestDamage/useReservations';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import {
  GetGuestDamageNegotiation,
  GuestDamageItemType,
  GuestDamageResolution,
  GuestDamageType,
} from '../../../../services/GuestDamage/types';
import {
  getReservationGuestDamages,
  postGuestDamage,
  postGuestDamageEvidences,
  postGuestDamageNegotiation,
} from '../../../../services/GuestDamage/request';
import { useToast } from '../../../../context/ToastContext';
import { currencyToNumber } from '../../../../utils/Formatter';
import { FileProps } from '../../../../context/FileContext/types';
import { useGuestDamageStep } from '../../../../hooks/GuestDamage/useGuestDamageStep';
import { useGuestDamageNegotiation } from '../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import TrousseauForm from '../../components/Forms/TrousseauForm';
import ItemDamageForm from '../../components/Forms/ItemDamageForm';
import ItemLossForm from '../../components/Forms/ItemLossForm';
import PropertyDamageForm from '../../components/Forms/PropertyDamageForm';
import PendencyForm from '../../components/Forms/PendencyForm';

interface DamageTypeProblemsProps {
  name: string;
  icon: string;
  iconAlt: string;
  isActive: boolean;
  value: GuestDamageType;
}

const initialDamageTypeProblems: DamageTypeProblemsProps[] = [
  {
    name: 'Enxoval',
    value: 'Enxoval',
    icon: trousseauIcon,
    iconAlt: 'ícone do enxoval',
    isActive: false,
  },
  {
    name: 'Danos a itens(ex: móveis, eletrodomésticos, utensílios, banheiro)',
    value: 'Danos a itens',
    icon: itemDamageIcon,
    iconAlt: 'ícone de danos a itens',
    isActive: false,
  },
  {
    name: 'Extravio de item',
    value: 'Extravio de item',
    icon: itemLossIcon,
    iconAlt: 'ícone de extravio de item',
    isActive: false,
  },
  {
    name: 'Danos à Propriedade (ex: janela, porta, parede)',
    value: 'Danos à propriedade',
    icon: propertyDamageIcon,
    iconAlt: 'ícone de danos a propriedade',
    isActive: false,
  },
  {
    name: 'Pendências (multa, restaurante)',
    icon: pendenciesIcon,
    value: 'Pendências',
    iconAlt: 'ícone de pendências',
    isActive: false,
  },
];

const validation = Yup.object().shape({
  itemName: Yup.string().required('Este campo é obrigatório!'),
  resolution: Yup.string().required('Este campo é obrigatório!'),
  itemPrice: Yup.string().required('Este campo é obrigatório!'),
  description: Yup.string().required('Este campo é obrigatório'),
  quotationLink: Yup.string().matches(/^(https?:\/\/).*/, 'A URL deve começar com http:// ou https://'),
  other: Yup.string().when('itemName', {
    is: 'Outro',
    then: Yup.string().required('Este campo é obrigatório!'),
    otherwise: Yup.string(),
  }),
});

export const InsertDamageDetailsCard = () => {
  const { selectedReservation } = useReservations();
  const { handleViewDamageDetailsStep } = useGuestDamageStep();
  const { setSelectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const {
    guestDamagesToInsert,
    setGuestDamagesToInsert,
    quotationFile,
    evidences,
    setEvidences,
    setQuotationFile,
    setReservationGuestDamages,
    setIsLoading,
  } = useGuestDamage();
  const { setReservations } = useReservations();
  const toast = useToast();
  const [damageTypeProblems,
    setDamageTypeProblems] = useState<DamageTypeProblemsProps[]>(initialDamageTypeProblems);
  const [quantity, setQuantity] = useState(1);
  const [selectedDamageType,
    setSelectedDamageType] = useState<GuestDamageType | undefined>(undefined);
  const today = moment();
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const isLessThanFourteenDaysAfterCheckout = today.diff(moment(selectedReservation.check_out_date), 'days') < 14;
  const finishSafeDate = moment(selectedReservation?.check_out_date
    || selectedGuestDamageNegotiation?.reservation?.check_out_date).add(14, 'days');

  const initialValues = {
    itemName: '',
    resolution: '',
    itemPrice: '',
    description: '',
    itemType: undefined as GuestDamageItemType | undefined | 'Outro',
    observation: '',
    other: '',
    quotationLink: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      const formattedEvidences = evidences.map((evidence) => ({
        evidence,
      }));

      setGuestDamagesToInsert((oldValue) => [...oldValue, {
        damage_type: selectedDamageType!,
        item_name: (values.itemName === 'Outro' || values.itemType === 'Outro') ? values.other : values.itemName,
        item_quantity: quantity,
        item_type: values.itemType === 'Outro' ? undefined : values.itemType,
        item_price: currencyToNumber(values.itemPrice),
        reservation: selectedGuestDamageNegotiation.reservation?.id || selectedReservation.id!,
        resolution: values.resolution as GuestDamageResolution,
        description: values.description,
        quotation_link: values.quotationLink,
        quotation_file: quotationFile,
        evidences: formattedEvidences,
        observation: values.observation,
        id: uuid(),
      }]);

      setDamageTypeProblems((oldState) => {
        const resetDamageType = oldState.map((damageType) => {
          if (damageType.isActive) {
            return {
              ...damageType,
              isActive: false,
            };
          }
          return damageType;
        });
        return resetDamageType;
      });
      setEvidences([]);
      setQuotationFile({} as FileProps);
      formik.resetForm({
        values: {
          ...initialValues,
          description: values.description,
        },
      });
    },
  });

  const handleShowProblemForm = (value: GuestDamageType) => {
    setQuantity(1);
    setQuotationFile({} as FileProps);
    setEvidences([]);
    formik.resetForm({
      values: {
        ...initialValues,
        description: formik.values.description,
      },
    });
    setDamageTypeProblems((oldState) => {
      const resetDamageType = oldState.map((damageType) => {
        if (damageType.isActive && damageType.value !== value) {
          return {
            ...damageType,
            isActive: false,
          };
        }
        return damageType;
      });
      const updatedDamageType = resetDamageType.map((damageType) => {
        if (damageType.value === value) {
          return {
            ...damageType,
            isActive: !damageType.isActive,
          };
        }
        return damageType;
      });
      return updatedDamageType;
    });
    setSelectedDamageType(value);
  };

  const handleGetReservationGuestDamages = async (reservation?: number) => {
    if (reservation) {
      try {
        setIsLoading(true);
        const response = await getReservationGuestDamages(reservation);
        if (response.length > 1) {
          setReservationGuestDamages(response);
        }
        if (response.length === 1) {
          setReservationGuestDamages([...response]);
        }
        setIsLoading(false);
      } catch (err) {
        toast.error('Não foi possível recuperar os danos do hóspede!');
        setIsLoading(false);
      }
    } else {
      toast.error('Não foi possível recuperar os dados de negociação do dano!');
    }
  };

  async function connectGuestDamageWithNegotiation() {
    try {
      let data: GetGuestDamageNegotiation | undefined;
      if (!selectedGuestDamageNegotiation.id) {
        data = await postGuestDamageNegotiation({
          reservation: selectedReservation.id!,
          stage: 'Detalhes do dano',
          status: 'Tratativa passou para o atendimento',
        });
        toast.success('A tratativa passou para o atendimento.');
        if (data) {
          setSelectedGuestDamageNegotiation({
            ...data,
            reservation: {
              ...selectedReservation,
            },
          });
          handleGetReservationGuestDamages(data.reservation as number);
        }
      } else {
        handleGetReservationGuestDamages(selectedGuestDamageNegotiation.id);
      }
      setGuestDamagesToInsert([]);
      setReservations([]);
      handleViewDamageDetailsStep();
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  }
  async function handleInsertGuestDamageDetails() {
    if (guestDamagesToInsert.length > 0) {
      const promises = guestDamagesToInsert.map(async (guestDamage) => {
        try {
          const createdGuestDamage = await postGuestDamage({
            reservation: guestDamage.reservation,
            damage_type: guestDamage.damage_type,
            item_type: guestDamage.item_type,
            item_name: guestDamage.item_name,
            item_quantity: guestDamage.item_quantity,
            resolution: guestDamage.resolution,
            description: guestDamage.description,
            item_price: guestDamage.item_price,
            observation: guestDamage.observation,
            quotation_file: guestDamage.quotation_file?.uid || undefined,
            quotation_link: guestDamage.quotation_link,
          });
          const evidencePromises = guestDamage?.evidences?.map(async (evidence) => {
            if (evidence.evidence) {
              await postGuestDamageEvidences(createdGuestDamage.id as number,
                evidence.evidence.uid);
            }
          });
          if (evidencePromises) {
            await Promise.all(evidencePromises);
          }
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e?.message || 'Aconteceu um erro inesperado');
          }
        }
      });
      Promise.all(promises)
        .then(async () => {
          await connectGuestDamageWithNegotiation();
          const successMessage = guestDamagesToInsert.length === 1
            ? 'Os danos foram inseridos com sucesso!'
            : 'Os danos foram inseridos com sucesso!';
          toast.success(successMessage);
        })
        .catch((e) => {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        });
    } else {
      toast.error('Por favor, insira pelo menos um dano relacionado a essa reserva!');
    }
  }

  return (
    <InsertDamageDetailsCardContainer>
      <DamageDetailsHeaderContainer>
        <DamageDetailsHeaderItem>
          <h3>Imóvel</h3>
          <p>
            {
            selectedReservation.property_code
            || selectedGuestDamageNegotiation.reservation?.property_code
            }
          </p>
        </DamageDetailsHeaderItem>
        <DamageDetailsHeaderItem>
          <h3>Data de check-out</h3>
          <p>
            {
              moment(selectedReservation.check_out_date).format('DD/MM/YYYY')
              || moment(selectedGuestDamageNegotiation.reservation?.check_out_date).format('DD/MM/YYYY')
            }
          </p>
        </DamageDetailsHeaderItem>
        <DamageDetailsHeaderItem>
          <h3>Nome do hóspede</h3>
          <p>
            {
              selectedReservation.guest_name
              || selectedGuestDamageNegotiation.reservation?.guest_name
            }
          </p>
        </DamageDetailsHeaderItem>
        <DamageDetailsHeaderItem>
          <h3>Plataforma</h3>
          <p>
            {
              selectedReservation.ota_name
              || selectedGuestDamageNegotiation.reservation?.ota_name
            }
          </p>
          {selectedReservation.ota_name === 'Airbnb' && (
            <AlertText>
              {isLessThanFourteenDaysAfterCheckout
                ? (
                  `Atenção! O atendimento tem até o dia ${finishSafeDate.format('DD/MM/YYYY')} para solicitar o pedido de reembolso com seguro pela plataforma`
                )
                : ('Atenção! O prazo para realizar o pedido de reembolso com seguro pela plataforma já expirou! O Atendimento prosseguirá com a cobrança sem o respaldo do Aircover, sem garantia de sucesso!')}
            </AlertText>
          )}
        </DamageDetailsHeaderItem>
      </DamageDetailsHeaderContainer>
      <DamageTypeForm onSubmit={formik.handleSubmit}>
        <TextField
          id="description"
          label="Descreva o ocorrido *"
          type="textarea"
          labelClassName="label"
          formik={formik}
          value={formik.values.description}
        />
        <h3>Tipo do problema</h3>
        {damageTypeProblems.map((damageType) => (
          <Fragment key={damageType.name}>
            <TypeButton
              key={damageType.name}
              isActive={damageType.isActive}
              type="button"
              onClick={() => handleShowProblemForm(damageType.value)}
            >
              <img src={damageType.icon} alt={damageType.iconAlt} />
              {damageType.name}
            </TypeButton>
            <AnimatePresence>
              {damageType.isActive && damageType.name === 'Enxoval' && (
                <TrousseauForm
                  formik={formik}
                  key={damageType.name}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  isEditingForm={false}
                />
              )}
              {damageType.isActive && damageType.value === 'Danos a itens' && (
                <ItemDamageForm
                  quantity={quantity}
                  setQuantity={setQuantity}
                  formik={formik}
                  key={damageType.value}
                  isEditingForm={false}
                />
              )}
              {damageType.isActive && damageType.value === 'Extravio de item' && (
                <ItemLossForm
                  quantity={quantity}
                  setQuantity={setQuantity}
                  formik={formik}
                  key={damageType.value}
                  isEditingForm={false}
                />
              )}
              {damageType.isActive && damageType.value === 'Danos à propriedade' && (
                <PropertyDamageForm
                  quantity={quantity}
                  setQuantity={setQuantity}
                  formik={formik}
                  key={damageType.value}
                  isEditingForm={false}
                />
              )}
              {damageType.isActive && damageType.value === 'Pendências' && (
                <PendencyForm
                  formik={formik}
                  key={damageType.value}
                  isEditingForm={false}
                />
              )}
            </AnimatePresence>
          </Fragment>
        ))}
      </DamageTypeForm>
      <DamageResume />
      <ButtonContainer>
        <FormButton
          disable={!!formik.errors.description}
          onClick={handleInsertGuestDamageDetails}
        >
          Finalizar inserção
        </FormButton>
      </ButtonContainer>
    </InsertDamageDetailsCardContainer>
  );
};
