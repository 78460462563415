import {
  FC, useState, useEffect, useContext,
} from 'react';

import { useFormik } from 'formik';
import { Close as CloseIcon } from '@mui/icons-material';
import SearchIcon from '../../../assets/icons/generals/search.svg';
import FilterIcon from '../../../assets/icons/multicalendar/filter.svg';

import FormGridRow from '../../FormGridRow';
import Autocomplete from '../../Autocomplete';

import { SelectProps } from '../../Autocomplete/Autocomplete';
import { searchProperties } from '../../../services/Property/request';
import { HostDetails, Property, PropertyDetails } from '../../../services/Property/types';

import { CalendarContext } from '../Calendar';
import { ErrorMessage } from '../../../utils/Messages';
import { removeParams } from '../../../utils/FilterBar';
import { useToast } from '../../../context/ToastContext';
import { useFilterBar } from '../../../context/FilterBar';
import { useLoader } from '../../../context/LoaderContext';
import { useSearch } from '../../../hooks/SearchHook/useSearch';

import LateralModal from '../../LateralModal';
import FormMoreFilters from '../../FormMoreFilters/FormMoreFilters';

import {
  Container,
  Content,
  SearchContainer,
  IconContainer,
  ImgFilter,
  ButtonClearFilters,
} from './styles';
import useLocalStorage from '../../../hooks/GlobalHook/useLocalStorage';
import Tooltip from '../../Tooltip';

type Props = {
  className?: string;
  page?: number;
  setPage: Function;
  INITIAL_PROPERTIES_PER_PAGE: number;
};

const Navigation: FC<Props> = ({
  className, page, setPage, INITIAL_PROPERTIES_PER_PAGE,
}) => {
  const {
    filteredResults,
    setFilteredResults,
    loadingSearch,
    setLoadingSearch,
    contentSearch,
    setContentSearch,
  } = useSearch();
  const toast = useToast();
  const { setLoad } = useLoader();
  const { filterBar, setFilterBar, defaultProps } = useFilterBar();
  const { getReservation } = useContext(CalendarContext);
  const [inputEnabled, setInputEnabled] = useState<boolean>(false);
  const [openLateralModal, setOpenLateralModal] = useState<boolean>(false);
  const firstTimeFilter = useLocalStorage('firstTimeFilter', true);

  const formik = useFormik({
    initialValues: {
      searchTerm: contentSearch.length > 0 ? contentSearch : '',
    },
    onSubmit: async (values, { resetForm }) => {
      setLoad(true);
      setLoadingSearch(true);

      try {
        setContentSearch(values.searchTerm);
        const rangeProperties = filteredResults
          .filter((prop: Property) => prop.code.indexOf(values.searchTerm.toUpperCase()) > -1);
        await getReservation(rangeProperties);
        setInputEnabled(false);
        setLoad(false);
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error(e.message || ErrorMessage.default());
          setFilterBar(defaultProps);
          setFilteredResults([]);
          setLoadingSearch(false);
          setInputEnabled(false);
          resetForm();
          setContentSearch('');
        }
      }
    },
  });

  const handleClearFilters = () => {
    localStorage.removeItem('firstTimeFilter');
    setPage(1);
    setFilterBar(defaultProps);
    setFilteredResults([]);
    setLoadingSearch(false);
    setInputEnabled(false);
    formik.resetForm();
    setContentSearch('');
  };

  const handleClearSearchField = () => {
    localStorage.removeItem('firstTimeFilter');
    setInputEnabled(false);
    formik.resetForm();
    setContentSearch('');
  };

  useEffect(() => {
    if (!loadingSearch) {
      getReservation();
    }
  }, [loadingSearch]);

  useEffect(() => {
    setInputEnabled(formik.values.searchTerm !== '');
  }, [formik.values.searchTerm]);
  const getParamsFilters = (firstPage?: boolean) => {
    const typeProperty: Array<string> = [];
    if (filterBar.hotel) {
      typeProperty.push('Hotel');
    }
    if (filterBar.house) {
      typeProperty.push('House');
    }
    if (filterBar.apartment) {
      typeProperty.push('Apartment');
    }

    const locationSplit: Array<string> = [];
    filterBar.location?.forEach((name) => {
      locationSplit.push(name.split('-')[0]);
    });

    const params: PropertyDetails = {
      property_type: typeProperty.join(','),
      guest_capacity__gte: filterBar.guestsCapacity,
      bedroom_quantity: filterBar.bedroom,
      host: filterBar?.host || {} as HostDetails,
      bathroom_quantity: filterBar.bathroom,
      categories: filterBar.categories?.join(','),
      location: locationSplit.join(','),
      search: formik.values.searchTerm,
      sea_view: filterBar.seaView,
      allow_pet: filterBar.allowPet,
      barbecue: filterBar.barbecue,
      page: firstPage ? 1 : page,
      page_size: INITIAL_PROPERTIES_PER_PAGE,
    };

    const paramsAux = removeParams(filterBar, params);
    return paramsAux;
  };

  useEffect(() => {
    const getFilter = async () => {
      const setFirstTimeFilter = firstTimeFilter[1];
      const verifyFirstTimeFilterLocalStorage = localStorage.getItem('firstTimeFilter');

      if (filterBar.moreSearch || filterBar.search) {
        if (!verifyFirstTimeFilterLocalStorage) {
          setPage(1);
          setFirstTimeFilter(true);
          setLoadingSearch(true);

          const params = getParamsFilters(true);
          const values = await searchProperties(params);

          setFilteredResults(values.results);
          await getReservation(values.results);
        } else {
          setLoadingSearch(true);

          const params = getParamsFilters();
          const values = await searchProperties(params);

          setFilteredResults(values.results);
          await getReservation(values.results);
        }
      }
    };

    getFilter();
  }, [filterBar]);

  const handleGetPropertie = async (value: string): Promise<SelectProps[]> => {
    const allParams = getParamsFilters();
    const params: PropertyDetails = {
      ...allParams,
      search: value,
    };

    const values = await searchProperties(params);
    setFilteredResults(values.results);

    return values.results.map((property) => ({
      optionText: property.code,
      optionValue: property.code,
    }));
  };

  return (
    <>
      <Container className={className}>
        <Content
          onChange={formik.handleChange}
          onSubmit={formik.handleSubmit}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <FormGridRow>
            <SearchContainer
              inputEnabled={inputEnabled}
              onClick={() => setInputEnabled(true)}
            >
              {!inputEnabled && <img src={SearchIcon} alt="Filter" />}
              <Autocomplete
                id="searchTerm"
                placeholder={
                  contentSearch.length > 0 ? contentSearch : 'Buscar'
                }
                formik={formik}
                asyncOptions={handleGetPropertie}
                noOptionText="Nenhuma propriedade localizada"
                dataCy="search"
              />
            </SearchContainer>
            {inputEnabled && (
              <CloseIcon
                onClick={() => handleClearSearchField()}
                className="closed-icon"
              />
            )}
            {!inputEnabled && (
              <Tooltip text="Filtrar">
                <IconContainer>
                  <ImgFilter
                    src={FilterIcon}
                    alt="Filter"
                    onClick={() => setOpenLateralModal(true)}
                  />
                </IconContainer>
              </Tooltip>
            )}
          </FormGridRow>
          {!inputEnabled && (
            <ButtonClearFilters
              type="button"
              onClick={() => handleClearFilters()}
            >
              Limpar filtros
            </ButtonClearFilters>
          )}
        </Content>
      </Container>

      <LateralModal
        open={openLateralModal}
        setOpen={setOpenLateralModal}
        onClose={() => setOpenLateralModal(false)}
        anchor={'left'}
        headerTitle="Filtros"
      >
        <FormMoreFilters
          onClose={() => setOpenLateralModal(false)}
          showFieldLocation
          isReloadShimmer
        />
      </LateralModal>
    </>
  );
};

Navigation.defaultProps = {
  page: 1,
  setPage: () => {},
};

export default Navigation;
