import styled from 'styled-components';

export const SettingsHideModal = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;

  .container{
    position: fixed;
    width: 253px;
    height: 246px;
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border: 1px solid #D9DCDF;
    border-radius: 10px;
    bottom: 12vh;
    right: 5vw;
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0,0,0,0.1);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 13px 16px;

      h1{
        font-family: ${({ theme }) => theme.fonts.familys._1};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fonts.weights.bold};
        font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
        line-height: 17px;
      }

      button{
        border: none;
        background-color: ${({ theme }) => theme.palette.white.main.hex()};
        cursor: pointer;
      }
    }

    .checkbox{
      border-bottom: 1px solid rgba(0,0,0,0.2);

      @media(max-width: 900px) {
        margin-left: 15px
      }
    }

    .edit{
      margin: 10px 13px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon{
        img{
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
      }
      .text{
        font-family: ${({ theme }) => theme.fonts.familys._1};
        font-size: 14px;
        font-weight: ${({ theme }) => theme.fonts.weights.bold - 100};
      }
    }
  }
`;
