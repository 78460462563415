/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../hooks/useMobile/useMobile';
import { getMaxWidthDesktop } from '../../style';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Teste = styled.div`
  background-color: red;
  height: 50px;
`;

export const Content = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;

  padding: 30px 15px;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    max-width: 1200px;
    padding: 30px 0;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
`;
