import React from 'react';
import { CircularProgress } from '@mui/material';

import { Container } from './style';
import { IBody } from '../../pages/Main/AddGuest/GuestsList/Guest/Body/types';

interface IButton extends Omit<IBody, 'formik' | 'expanded' | 'isMain'> {}
const Button = ({ guest, loading }: IButton) => {
  if (loading) {
    return (
      <Container
        type="submit"
        whileTap={{ scale: 0.95 }}
        exit={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        initial={{ y: 10, opacity: 0 }}
      >
        <CircularProgress size={20} style={{ color: '#fff' }} />
      </Container>
    );
  }

  return (
    <Container
      type="submit"
      whileTap={{ scale: 0.95 }}
      exit={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 10, opacity: 0 }}
    >
      {guest?.isPut?.is ? 'Atualizar' : 'Finalizar'}
    </Container>
  );
};

export default Button;
