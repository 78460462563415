import styled, { keyframes } from 'styled-components';

const Up = keyframes`
  from{
    transform:  translateY(0);
  }
  to{
    transform:  translateY(-0.3rem);
  }
`;

export const ImmobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  background: #ebf0f5;
  z-index: 100;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    margin-top: 0.3rem;

    &:hover {
    animation: ${Up} 300ms ease-out forwards;
  }
  }
`;

export const BlockCalendarButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 2rem;
  }

  &:hover {
    animation: ${Up} 300ms ease-out forwards;
  }
`;
