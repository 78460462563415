import React from 'react';
import { Close } from '@mui/icons-material';

import { AddressDetail } from '../../../services/Address/types';
import { useEstimateCart } from '../../../context/EstimateCart';

import {
  Address,
  ButtonDelete,
  ContantInfoReservation,
  HeaderContent,
  ImageProperty,
} from './styles';

import propertyImage from '../../../assets/icons/generals/property-not-found.svg';

interface Props {
  code: string;
  address?: AddressDetail;
  idProperty: number;
}

const CardResumeProperty = ({
  code, address = {
    id: 0, city: '', complement: '', country: '', neighborhood: '', number: '', street: '', postal_code: '', state: '',
  }, idProperty,
}: Props) : JSX.Element => {
  const { removePropertyToCart } = useEstimateCart();

  const handleClickToRemove = () => {
    removePropertyToCart(idProperty);
  };

  return (
    <ContantInfoReservation>
      <ButtonDelete onClick={handleClickToRemove}>
        <Close />
      </ButtonDelete>
      <HeaderContent>
        <Address>
          <strong>
            {code}
            .
          </strong>
          {' '}
          {address.street}
          ,
          {' '}
          {address.neighborhood}
          ,
          {' '}
          {address.city}
          {' '}
          -
          {' '}
          {address.state}
          {' '}
          .
          {' '}
          {address.postal_code}
        </Address>
        <ImageProperty src={propertyImage} alt="foto imóvel" />
      </HeaderContent>
    </ContantInfoReservation>
  );
};

CardResumeProperty.defaultProps = {
  address: {
    id: 0,
    city: '',
    complement: '',
    country: '',
    neighborhood: '',
    number: '',
    street: '',
    postal_code: '',
    state: '',
  },
};

export default CardResumeProperty;
