import { CompReservationsType } from '../../services/CompReservations/types';

export const getReservationsListUpdated = (data: CompReservationsType) => ({
  ...data,
  guest_owner: {
    ...data.guest_owner,
  },
  address: {
    ...data.address,
  },
  guests: [...data.guests],
});
