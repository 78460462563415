import React, { useEffect } from 'react';
import CompReservationComponent from '../../components/CompReservation';
import { Container } from './styles';

const CompReservation = () => {
  useEffect(() => {
    document.title = 'Sapron | Completar reservas';
  }, []);
  return (
    <Container>
      <CompReservationComponent />
    </Container>
  );
};

export default CompReservation;
