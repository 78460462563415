import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px
const ZINDEX = 999;

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DragContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZINDEX};
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    justify-content: center;
    align-items: center;
  }
`;

export const DragContent = styled(motion.div)<{ maxWidthForModalOnDesk?: string, maxHeightForModalOnDesk?: string }>`
  width: 100%;
  height: 100%;
  min-height: 360px;
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    width:  ${({ maxWidthForModalOnDesk }) => (maxWidthForModalOnDesk ? `${maxWidthForModalOnDesk}` : '400px')};
    height: auto;
    min-height: ${({ maxHeightForModalOnDesk }) => (maxHeightForModalOnDesk ? `${maxHeightForModalOnDesk}` : '360px')};
    max-height: ${({ maxHeightForModalOnDesk }) => (maxHeightForModalOnDesk ? `${maxHeightForModalOnDesk}` : '360px')};
    border-radius: 8px;
  }
`;

export const DragButton = styled(motion.button)`
  width: 30px;
  height: 2px;
  border: 2px;
  outline: 0;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.palette.grey._560.hex()};
  border-radius: 10px;
  cursor: grab !important;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    display: none;
  }
`;

export const Content = styled(motion.div)<{ maxWidthForModalOnDesk?: string, maxHeightForModalOnDesk?: string }>`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 0 16px;
  margin-bottom: 16px;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    height: 100%;
    max-width:  ${({ maxWidthForModalOnDesk }) => (maxWidthForModalOnDesk ? `${maxWidthForModalOnDesk}` : '400px')};
    max-height: ${({ maxHeightForModalOnDesk }) => (maxHeightForModalOnDesk ? `${maxHeightForModalOnDesk}` : '360px')};
    border-radius: 8px;
    padding: 0 24px;

    scrollbar-color: ${({ theme }) => theme.palette.grey._425.hex()} ${({ theme }) => theme.palette.blue._400.hex()} !important;

    &::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      background: ${({ theme }) => theme.palette.grey._425.hex()} !important;
      border-radius: 17px !important;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px !important;
      height: 10px !important;
      background: ${({ theme }) => theme.palette.blue._400.hex()} !important;
      border-radius: 17px !important;
    }
  }  
`;

export const Header = styled(motion.div)`
  width: 100%;
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: ${ZINDEX + 1};
  background: ${({ theme }) => theme.palette.white.main.hex()};
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 16px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    padding: 24px 0;
    margin-bottom: 24px;
  }  
`;

export const Body = styled(motion.div)`
  width: 100%;
  height: auto;
  overflow: hidden;
  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
`;

export const Footer = styled(motion.footer)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZINDEX + 1};
  width: 100%;
  height: auto;
  min-height: 70px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  margin-top: auto;

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    position: sticky;
    position: -webkit-sticky;
    border-radius: 0 0 16px 16px;
  }
`;

export const Title = styled.h5`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
`;

export const ButtonsContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  gap: 16px;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  
  button {
    width: 100%;
    padding: 8px 70px 8px 70px;
  }
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    padding: 16px;
  }
`;

export const DragHandContainer = styled(motion.div)`
  position: absolute;
  z-index: 10000000000000;
  bottom: 148px;
  left: 32px;
`;
