import React, { useEffect } from 'react';

import { Container } from './styles';
import InsertAddListingDataPageComponent from '../../../components/InsertData/InsertAddListingData';

const InsertAddListingDataPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <InsertAddListingDataPageComponent />
    </Container>
  );
};

export default InsertAddListingDataPage;
