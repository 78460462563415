import styled from 'styled-components';

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.familys._6}, Arial, Helvetica, sans-serif;
  font-weight: bold !important;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;
