/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { AnimatePresence } from 'framer-motion';
import { Outlet, useLocation } from 'react-router-dom';

import {
  MobileFooter,
} from './Menu';

import {
  usePartners,
  PartnersContextProvider,
} from '../../context/Partners/Partner/PartnerContext';

import Header from './Header';

import { useDefineTitle } from './hooks';
import { setScrollToTop } from './utils';
import { validateWhitePage } from './types';
import { ExpandedBurgerMenu } from './components/Burger';
import { useMobile } from '../../hooks/useMobile/useMobile';

import {
  Content,
  Container,
  DesktopWrapperContent,
} from './style';

import ConversionProbability from './ConversionProbability/ConversionProbability';
import Welcome from './pages/Welcome/Welcome';
import Loading from './pages/Loading';

const disablePaddingBottomForThesePages = [
  '/parceiros/painel',
];

const Partners = () => {
  useDefineTitle();

  const { isDesktop } = useMobile();
  const { pathname } = useLocation();
  const {
    showBurgerMenu, welcome, validateAction, loading,
  } = usePartners();

  const validateWelcome = React.useMemo(() => {
    if (validateAction) {
      if (welcome.is) {
        return true;
      }
    }

    return false;
  }, [welcome]);

  React.useEffect(() => {
    const handleRedirect = () => {
      const disabled = [
        '/parceiros/indicacoes/todos',
        '/parceiros/indicacoes/terreno',
        '/parceiros/indicacoes/locacao',
      ];

      if (disabled.includes(pathname)) {
        return null;
      }

      return setScrollToTop();
    };

    handleRedirect();
  }, [pathname]);

  if (loading.main) {
    return (
      <Loading />
    );
  }

  // renderiza páginas que não precisam de menu e footer
  const isWhitePage = validateWhitePage(pathname);
  if (isWhitePage) {
    return (
      <>
        <AnimatePresence>
          {showBurgerMenu && (
            <ExpandedBurgerMenu key={`expanded_burguer_w_${pathname}`} />
          )}
        </AnimatePresence>
        <Content>
          <Outlet />
        </Content>
      </>
    );
  }

  // valida para o desktop
  if (isDesktop && window.innerHeight > 620) {
    return (
      <>
        <AnimatePresence>
          {showBurgerMenu && (
            <ExpandedBurgerMenu key="expanded_burguer__desktop" />
          )}
        </AnimatePresence>

        {validateWelcome && <Welcome />}

        <Container disablePadding={disablePaddingBottomForThesePages.includes(pathname)}>
          <ConversionProbability />

          <DesktopWrapperContent>
            <Content disablePadding={disablePaddingBottomForThesePages.includes(pathname)}>
              <Header />
              <Outlet />
            </Content>
            <AnimatePresence>
              <MobileFooter key="footer__mobile" />
            </AnimatePresence>
          </DesktopWrapperContent>
        </Container>
      </>
    );
  }

  // renderiza todos os filhos no arquivo de rotas
  // o primeiro deles é o Painel ./pages/Panel
  // valida para o mobile
  return (
    <>
      <AnimatePresence>
        {showBurgerMenu && (
          <ExpandedBurgerMenu key="expanded_burguer__mobile" />
        )}
      </AnimatePresence>

      {validateWelcome && <Welcome />}

      <Container disablePadding={disablePaddingBottomForThesePages.includes(pathname)}>
        <ConversionProbability />
        <Header />
        <Content disablePadding={disablePaddingBottomForThesePages.includes(pathname)}>
          <Outlet />
        </Content>
        <AnimatePresence>
          <MobileFooter key="footer__mobile" />
        </AnimatePresence>
      </Container>
    </>
  );
};

const PartersProvided = () => (
  <PartnersContextProvider>
    <Partners />
  </PartnersContextProvider>
);

export default PartersProvided;
