/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo } from 'react';

import { getPopups } from '../../services/Popups/request';

import { useUser } from '../../context/UserContext';
import { useCustomPopups } from '../../hooks/useCustomPopups/useCustomPopups';

import PopupTemplate1 from '../../components/CustomPopups/PopupTemplate1';
import PopupTemplate2 from '../../components/CustomPopups/PopupTemplate2';

import { Container, Content, Backdrop } from './styles';
import { PopupsProps } from '../../context/CustomPopupsContext/types';

const INTERVAL_TIME_IN_MILLISECONDS = 10 * 60 * 1000; // 10 * 60 * 1000 = 10 minutos;
const TIMEOUT_TIME_IN_MILLISECONDS = 5000; // 5000 = 5 segundos;

const CustomPopupsLayout = () => {
  const { isLoggedIn } = useUser();
  const { popupsList, setPopupsList } = useCustomPopups();
  const [isLogged, setIsLogged] = useState(false);

  const fetchPopups = async () => {
    try {
      const response = await getPopups();
      setPopupsList(response);
    } catch {
      setPopupsList([]);
    }
  };

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;
    let timeoutDelay: NodeJS.Timeout;

    if (isLoggedIn) {
      fetchPopups(); // GET inicial na api /popups/

      // GET na api de /popups/ a cada x intervalos de tempo em milissegundos
      timerInterval = setInterval(() => {
        fetchPopups();
      }, INTERVAL_TIME_IN_MILLISECONDS);

      // Tempo extra de espera para que o popup seja exibido em tela
      timeoutDelay = setTimeout(() => {
        setIsLogged(true);
      }, TIMEOUT_TIME_IN_MILLISECONDS);
    } else {
      setIsLogged(false);
    }

    return () => {
      clearInterval(timerInterval);
      clearTimeout(timeoutDelay);
    };
  }, [isLoggedIn]);

  const popupIsOpen = useMemo(() => popupsList
    .some((item) => item.popup_is_open === true), [popupsList]);

  if (!popupIsOpen) return null;

  interface IPopups {
    popup: PopupsProps,
  }

  const Popups = ({ popup }: IPopups) => (
    <Container key={popup.id} type={popup.type}>
      <Content type={popup.type}>
        {popup.type === 'Default' && (
          <PopupTemplate1
            id={popup.id}
            headline={popup.headline}
            content={popup.content}
            confirmButtonText={popup.confirm_button_text}
            consentRequired={popup?.consent_required || false}
            consentText={popup?.consent_text || ''}
            consentDescription={popup?.consent_description || ''}
          />
        )}
        {popup.type === 'InfoWithImage' && (
          <PopupTemplate2
            id={popup.id}
            headline={popup.headline}
            content={popup.content}
            confirmButtonText={popup.confirm_button_text}
            imageUrl={popup?.image_url || '#'}
          />
        )}
      </Content>
    </Container>
  );

  return (
    <Backdrop isLogged={isLogged}>
      {popupsList.map((popup) => (popup.popup_is_open && <Popups popup={popup} />))}
    </Backdrop>
  );
};

export default CustomPopupsLayout;
