import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Container = styled.div`
  gap: 2rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0.3rem;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #c0c0c0;
  }
`;

export const Content = styled.div`
  gap: 2rem;
  display: flex;
  overflow-x: hidden;
  padding: 30px 30px;
  flex-direction: column;
  justify-content: space-between;

  opacity: 0;
  animation: ${fadeIn} 0.5s 0.35s ease forwards;
`;

export const TopContent = styled.div`
  gap: 2rem;
  opacity: 0;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s 0.35s ease forwards;
`;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const AddGuestContainer = styled.div`
  opacity: 0;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s 0.35s ease forwards;
`;

export const ButtonsContainer = styled.div`
  right: 0;
  top: 100;
  bottom: 0;
  width: 100%;
  height: 12vh;
  display: flex;
  transition: 1s;
  position: fixed;
  max-width: 500px;
  background: #ebebf5;
  align-items: center;
  grid-area: "buttons";
  justify-content: space-between;
  border-top: 1px solid #00000018;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);

  opacity: 0;
  animation: ${fadeIn} 0.5s 0.45s ease forwards;

  button,
  svg {
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 900px) {
      margin: 0;
      margin-right: 3px;
    }

    @media (max-width: 320px) {
      margin: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 320px) {
    padding-right: 3rem;
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid #00000018;
  opacity: 0;
  animation: ${fadeIn} 1s 0.4s ease forwards;
`;

export const CardInfoHeaderContainer = styled.div<{
  isShowingCardInfo: boolean;
}>`
  gap: 20px;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  justify-content: space-between;

  div{
    width: 250px;
  }

  h2 {
    transition: 0.3s;
    color: ${({ theme, isShowingCardInfo }) => !isShowingCardInfo && theme.palette.grey._550.hex()};
  }

  img {
    width: 15px;
    transition: 0.3s;
    transform: ${({ isShowingCardInfo }) => !isShowingCardInfo && 'rotate(90deg)'};
  }
`;

export const SubTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;
