import styled from 'styled-components';

export const Content = styled.div<{
  mobile: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ mobile }) => !mobile && 'repeat(7, 1fr)'};
  grid-template-rows: 100px 100%;
  background: ${({ theme }) => theme.palette.grey._250.hex()};
  height: 100%;

  .grid {
    &:last-child {
      border: none;
    }
  }
`;

export const ControllerContainer = styled.div<{
  checkList: boolean;
}>`
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  height: ${({ checkList }) => checkList && '1px'};
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  height: calc(100% - 4rem);
  width: calc(100% - 4rem);
  overflow-y: hidden;
  overflow-x: auto;

  @media (max-width: 900px) {
    height: calc(92% - 2rem);
    width: calc(100% - 2rem);
    margin-bottom: 4rem;
  }
`;

export const WrapperCards = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContentContainer = styled.div<{
  checkList: boolean;
  backgroundColor: boolean;
}>`
  width: 100%;
  height: calc(100% - 160px);
  background-color: ${({ backgroundColor }) => (backgroundColor ? '#E4EAEF' : '#F7FAFC')};
  display: ${({ checkList }) => checkList && 'none'};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #0D4BD0 !important;
    &:hover {
      background: #0D4BD0 !important;
    }
  }
  scrollbar-color: #0D4BD0 !important;

  @media (max-width: 900px) {
    height: calc(100% - 150px);
  }
`;

export const ConcludedCardsTitle = styled.h2<{
  backgroundColor: boolean;
}>`
  box-sizing: border-box;
  color: #394760;
  background-color: ${({ backgroundColor }) => (backgroundColor ? '#E4EAEF' : '#F7FAFC')};
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  padding: 1rem;
`;

export const ContentConcludedCards = styled.div`
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const NoTasksToday = styled.div`
  width: 90%;
  height: 280px;
  margin: 0 auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 4rem;

  p {
    color: #93A4C2;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 1.25rem;
  }

  img {
    height: 60px;
  }
`;

export const NoMoreTasksToday = styled.div`
  width: 90%;
  height: 280px;
  margin: 0 auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 4rem;

  p {
    color: #93A4C2;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 18px;
  }

  svg {
    color: #93A4C2;
    font-size: 75px;
  }
`;
