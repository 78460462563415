import styled from 'styled-components';

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
`;

export const TooltipText = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-family: sans-serif;

  h1 {
    font-family: sans-serif;
    font-size: 1.2rem;
    font-weight: medium;
  }
  p {
    font-family: sans-serif;
    font-size: 1.2rem;
  }
`;
