import React from 'react';

import { OpenInNew } from '@mui/icons-material';

import { useToast } from '../../../../../context/ToastContext';

import copyIcon from '../../../../../assets/icons/generals/copy.svg';
import Tooltip from '../../../../Tooltip';
import CustomTooltip from './Tooltip';

import { Value } from '../styles';
import { Link, AddressContainer, BottomSideContainer } from './styles';
import { AddressDetail } from '../../../../../services/Address/types';

interface AddressProps {
  address: AddressDetail;
}

const CopyIcon = () => (
  <Tooltip text="Copiar endereço">
    <img alt="copy" src={copyIcon} className="AddressCopyIcon" />
  </Tooltip>
);

const Address = ({ address }: AddressProps) => {
  const [open, setOpen] = React.useState(false);

  const toast = useToast();
  const formattedAddress = `${address.street} - ${address.neighborhood}, ${address.city} - ${address.state}, ${address.postal_code}`;
  const handleGotToAddress = () => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`,
      '_blank');
  };

  const handleCopyAddress = () => {
    try {
      navigator.clipboard.writeText(formattedAddress);
      toast.success('Endereço copiado para a área de transferência!');
    } catch (err) {
      toast.error('Não foi possível copiar o endereço!');
    }
  };

  const limitAddresSizeAndAddDots = (addressToLimit: string) => {
    if (addressToLimit.length > 30) {
      return `${addressToLimit.substring(0, 9)}...`;
    }
    return addressToLimit;
  };

  return (
    <AddressContainer open={open}>
      <div>
        <Value>{limitAddresSizeAndAddDots(formattedAddress)}</Value>
        <button type="button" onClick={handleCopyAddress}>
          <CopyIcon />
        </button>
        <button type="button">
          <CustomTooltip
            open={open}
            setOpen={setOpen}
            address={formattedAddress}
            handleGotToAddress={handleGotToAddress}
          />
        </button>
      </div>

      <BottomSideContainer onClick={handleGotToAddress}>
        <Link>Abrir mapa</Link>
        <OpenInNew style={{ color: '#1D9BE1', fontSize: '16px' }} />
      </BottomSideContainer>
    </AddressContainer>
  );
};

export default Address;
