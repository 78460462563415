import styled, { keyframes } from 'styled-components';

const showSearchBar = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 250px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 3rem;
    font-weight: bolder;
    opacity: 0;
    animation: ${fadeIn} 0.5s 0.2s ease forwards;
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: normal;
    opacity: 0;
    font-size: 1.5rem;
    animation: ${fadeIn} 0.5s 0.3s ease forwards;
  }

  .paymentVoucher-search {
    box-shadow: none;
    border: none;
  }

  @media (max-width: 1400px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
`;

export const SearchBar = styled.form`
  display: flex;
  position: relative;
  transition: 0.4s;
  align-items: center;

  input {
    opacity: 0;
    animation: ${showSearchBar} 0.5s ease forwards;
    padding: 15px 20px 15px 10px;
    border: none;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.2rem;
    transition: 0.2s;

    &::placeholder {
      padding: 10px;
      font-size: 1.2rem;
      font-family: ${({ theme }) => theme.fonts.familys._1};
    }

    @media (max-width: 1400px) {
      width: 300px;
    }
  }
`;

export const BlankContainer = styled.div`
  cursor: pointer;
  border-right: 1px solid #cccccc86;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GeneralSearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  margin-left: 30px;

  button {

    transition: 0.4s;

    &:hover {
      color: #ff000071;
      opacity: 1;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        color: #292929;
        opacity: 0.5;
      }
    }

    color: #292929;

    border: none;
    cursor: pointer;
    background: none;
  }
`;
