import React from 'react';
import { Button, MobileContainer } from '../style';
import { usePreCheckin } from '../../../../../../context/PreCheckin/PreCheckin';
import { containerAnimation } from '../animation';

export const View = () => {
  const { mode, setMode } = usePreCheckin();

  const handleClick = () => {
    if (mode.is === 'edit') {
      setMode('view');
    } else {
      setMode('edit');
    }
  };

  return (
    <MobileContainer {...containerAnimation}>
      <Button whileTap={{ scale: 0.95 }} onClick={handleClick} type="button">
        {mode.is === 'view' ? 'Editar' : 'Sair do modo de edição'}
      </Button>
    </MobileContainer>
  );
};
