import { AnimatePresence } from 'framer-motion';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import { OwnerResume } from './OwnerResumeCard';
import { RefundDamageHeader } from './RefundDamgeHeader';
import { SeazoneResume } from './SeazoneResumeCard';

import {
  RefundDamageCardContainer, CardContainer, Subtitle, ButtonContainer,
} from './styles';
import { getGuestDamageNegotiationById, patchGuestDamageNegotiation, postDamageNegotiationOwnerPayment } from '../../../../services/GuestDamage/request';
import { useGuestDamageNegotiation } from '../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { useToast } from '../../../../context/ToastContext';
import { FileProps } from '../../../../context/FileContext/types';
import { useGuestDamageStep } from '../../../../hooks/GuestDamage/useGuestDamageStep';
import FormButton from '../../../FormButton';
import { compareList } from '../../../../utils/Sorting';
import { GetGuestDamage } from '../../../../services/GuestDamage/types';

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';
type Order = 'asc' | 'desc';

export const InsertRefundDamageCard = () => {
  const {
    selectedGuestDamageNegotiation,
    setSelectedGuestDamageNegotiation,
  } = useGuestDamageNegotiation();
  const { handleViewRefundDamageStep } = useGuestDamageStep();
  const [isReceivingConfirmed, setIsReceivingConfirmed] = useState(selectedGuestDamageNegotiation
    .is_receiving_confirmed || false);
  const [isRefunded, setIsRefunded] = useState(selectedGuestDamageNegotiation.is_refunded || false);
  const [ownerPaymentReceipts, setOwnerPaymentReceipts] = useState<FileProps[]>([]);
  const { reservationGuestDamages } = useGuestDamage();
  const [sortedBy, setSortedBy] = useState<GuestDamageSortedBy>('type');
  const [order, setOrder] = useState<Order>('asc');
  const [sortedGuestDamages,
    setSortedGuestDamages] = useState<GetGuestDamage[]>(reservationGuestDamages);

  function handleSortGuestDamage(sortBy: GuestDamageSortedBy) {
    switch (sortBy) {
      case 'type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.damage_type, b.damage_type, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_type || '', b.item_type || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_name': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name, b.item_name, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name, b.item_name, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_name');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_name, b.item_name, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'resolution': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution, b.resolution, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution, b.resolution, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('resolution');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.resolution, b.resolution, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'refund_holder': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('refund_holder');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.refund_holder || '', b.refund_holder || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_quantity': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_quantity');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_quantity, b.item_quantity, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'price': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('price');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            (a?.item_quantity * (a?.item_price || 0)) || '-',
            (b?.item_quantity * (b?.item_price || 0)) || '-',
            'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      default: {
        const sortedNegotiations = reservationGuestDamages.sort((a, b) => compareList(
          a.damage_type, b.damage_type, 'asc',
        ));
        setSortedGuestDamages(sortedNegotiations);
        break;
      }
    }
  }

  useEffect(() => {
    handleSortGuestDamage('type');
  }, [reservationGuestDamages]);

  const seazoneReservationGuestDamages = sortedGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Seazone');
  const haveSeazoneReservations = seazoneReservationGuestDamages.length > 0;
  const ownerReservationGuestDamages = sortedGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Proprietário');
  const haveOwnerReservations = ownerReservationGuestDamages.length > 0;
  const toast = useToast();
  const refundFormik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const patchResponse = await patchGuestDamageNegotiation(selectedGuestDamageNegotiation.id!,
          {
            is_receiving_confirmed: isReceivingConfirmed === selectedGuestDamageNegotiation
              .is_receiving_confirmed ? undefined : isReceivingConfirmed,

          });
        if (patchResponse.id) {
          const response = await getGuestDamageNegotiationById(patchResponse.id);
          setSelectedGuestDamageNegotiation(response);
          toast.success('Dados enviados com sucesso!');
        } else {
          setSelectedGuestDamageNegotiation((oldValues) => ({
            ...oldValues,
            ...patchResponse,
            guest_payment_status: oldValues.guest_payment_status,
          }));
        }
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Falha ao enviar os dados!');
        }
      }
    },
  });

  const ownerResumeFormik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        if (ownerPaymentReceipts.length > 0) {
          ownerPaymentReceipts.forEach(async (receipt) => {
            await postDamageNegotiationOwnerPayment(selectedGuestDamageNegotiation.id!,
              receipt.uid!);
          });
        }
        const patchResponse = await patchGuestDamageNegotiation(selectedGuestDamageNegotiation.id!,
          {
            is_refunded: isRefunded === selectedGuestDamageNegotiation
              .is_refunded ? undefined : isRefunded,
            status: isRefunded === true ? 'Dano cobrado. Repasse realizado ao dono do reembolso' : undefined,
          });
        if (patchResponse.id) {
          const response = await getGuestDamageNegotiationById(patchResponse.id);
          setSelectedGuestDamageNegotiation(response);
          toast.success('Dados enviados com sucesso!');
        } else {
          setSelectedGuestDamageNegotiation((oldValues) => ({
            ...oldValues,
            ...patchResponse,
            guest_payment_status: oldValues.guest_payment_status,
          }));
        }
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Falha ao enviar os dados!');
        }
      }
    },
  });

  return (
    <RefundDamageCardContainer>
      <CardContainer
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
        }}
        exit={{ y: -100, opacity: 0 }}
        style={{ width: '100%' }}
        onSubmit={refundFormik.handleSubmit}
      >
        <Subtitle>Inserir reembolso do dano</Subtitle>
        <RefundDamageHeader
          isReceivingConfirmed={isReceivingConfirmed}
          setIsReceivingConfirmed={setIsReceivingConfirmed}
        />
      </CardContainer>
      <AnimatePresence>
        {haveOwnerReservations && (
          <CardContainer
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
            }}
            exit={{ y: -100, opacity: 0 }}
            style={{ width: '100%' }}
            onSubmit={ownerResumeFormik.handleSubmit}
          >
            <Subtitle>Resumo de danos lançados - Proprietário</Subtitle>
            <OwnerResume
              ownerReservationGuestDamages={ownerReservationGuestDamages}
              isRefunded={isRefunded}
              setIsRefunded={setIsRefunded}
              ownerPaymentReceipts={ownerPaymentReceipts}
              setOwnerPaymentReceipts={setOwnerPaymentReceipts}
              handleSortGuestDamage={handleSortGuestDamage}
            />
          </CardContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {haveSeazoneReservations && (
          <CardContainer
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
            }}
            exit={{ y: -100, opacity: 0 }}
            style={{ width: '100%' }}
          >
            <Subtitle>Resumo de danos lançados - Seazone</Subtitle>
            <SeazoneResume
              seazoneReservationGuestDamages={seazoneReservationGuestDamages}
              handleSortGuestDamage={handleSortGuestDamage}
            />
          </CardContainer>
        )}
      </AnimatePresence>
      <CardContainer
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
        }}
        exit={{ y: -100, opacity: 0 }}
        style={{ width: '100%' }}
      >
        <ButtonContainer>
          <FormButton type="button" onClick={handleViewRefundDamageStep}>Finalizar edição</FormButton>
        </ButtonContainer>
      </CardContainer>
    </RefundDamageCardContainer>
  );
};
