import React from 'react';

import {
  Container,
  Questions,
  Subtitle,
  SucessContainer,
  Title,
} from './style';

import checklistSuccess from '../../../../assets/icons/controll/checklistSuccess.svg';
import checklistIntroduction from '../../../../assets/icons/controll/checklistIntroduction.svg';

import InputCheckBox from '../../../InputCheckBox';
import { TextField } from '../../..';

interface PageProps {
  page: {
    id: number;
    title: string;
    questions: {
      id: number;
      name: string;
      checked: boolean;
    }[];
  }[];
  setPage: Function;
  checked: boolean[];
  setChecked: Function;
  currentPage: number;
  finish: boolean;
  started: boolean;
  mobile: boolean | undefined;
  checkin: boolean | undefined;
  checkout: boolean | undefined;
  clearning: boolean | undefined;
  notes: string | undefined;
  setNotes: Function;
}

export const MainContent = ({
  page,
  setPage,
  checked,
  setChecked,
  currentPage,
  finish,
  started,
  mobile,
  checkin,
  checkout,
  clearning,
  setNotes,
  notes,
}: PageProps) => {
  const handleChecked = (id: number) => {
    const newChecked = [...checked];
    newChecked[id] = !newChecked[id];
    setChecked(newChecked.map((item) => item || false));

    const newPage = [...page];
    newPage[currentPage].questions.forEach((item) => {
      const check = item;
      if (item.id === id) {
        check.checked = newChecked[item.id] || false;
      }
    });
    setPage(newPage);
  };
  const ChecklistSuccessIMG = () => (
    <img
      src={checklistSuccess}
      alt="icone do zap"
      className="checkliistSuccessIcon"
    />
  );

  const ChecklistIntroductionIMG = () => (
    <img src={checklistIntroduction} alt="checklist introduction icon" />
  );

  const handleSetNotes = (value: string) => {
    setNotes(value || '');
  };

  return (
    <Container mobile={mobile}>
      {checked && started && page[currentPage] && (
        <>
          {checkin && (
            <Title>
              Passos de checkin que devem ser concluidos para finalizar o imóvel
              em perfeito estado
            </Title>
          )}

          {checkout && (
            <Title>
              Passos de checkout que devem ser concluidos para finalizar o
              imóvel em perfeito estado
            </Title>
          )}

          {clearning && (
            <Title>
              Passos de limpeza que devem ser concluidos para finalizar o imóvel
              em perfeito estado
            </Title>
          )}
        </>
      )}
      <Questions>
        {started
          && checked
          && page[currentPage]?.questions.map((question) => (
            <InputCheckBox
              id="checkbox-checklist"
              dataCy="checkbox-checklist"
              size={mobile ? '1.1rem' : '1.3rem'}
              className="question"
              checked={checked[question?.id]}
              onChange={() => handleChecked(question?.id)}
              labelName={question?.name}
            />
          ))}
        {finish && !page[currentPage] && (
          <>
            <SucessContainer>
              <ChecklistSuccessIMG />
              <Subtitle>Checklist finalizado com sucesso !</Subtitle>
              <div>
                <TextField
                  onChange={(e) => handleSetNotes(e.target.value)}
                  value={notes}
                  label="Notas"
                  id="notes"
                  type="textarea"
                  placeholder={'Adicione alguma observação sobre este imóvel'}
                />
              </div>
            </SucessContainer>
          </>
        )}
        {!started && page[currentPage] && (
          <>
            <SucessContainer>
              <ChecklistIntroductionIMG />
              <Subtitle>
                Preencha todos os checkboxes para finalizar o checklist
              </Subtitle>
              {checkin && (
                <Subtitle>
                  Estes são alguns passos que precisam ser concluidos para
                  finalizar o checkin do imóvel em perfeito estado
                </Subtitle>
              )}

              {checkout && (
                <Subtitle>
                  Estes são alguns passos que precisam ser concluidos para
                  finalizar o checkout do imóvel em perfeito estado
                </Subtitle>
              )}

              {clearning && (
                <Subtitle>
                  Estes são alguns passos que precisam ser concluidos para
                  finalizar a limpeza do imóvel em perfeito estado
                </Subtitle>
              )}
              {finish && (
                <Subtitle className="finished">
                  Todos os passos foram concluidos para este imóvel

                </Subtitle>
              )}
            </SucessContainer>
          </>
        )}
        {!finish && !page[currentPage] && (
          <>
            <SucessContainer>
              <Subtitle>Alguns passoa ainda não foram finalizados</Subtitle>
              <Subtitle>
                Volte e finalize todos os passos Para que possa concluir o
                imóvel em perfeito estado
              </Subtitle>
              <div>
                <TextField
                  onChange={(e) => handleSetNotes(e.target.value)}
                  value={notes}
                  label="Notas"
                  id="notes"
                  type="textarea"
                  placeholder={'Adicione alguma observação sobre este imóvel'}
                />
              </div>
            </SucessContainer>
          </>
        )}
      </Questions>
    </Container>
  );
};
