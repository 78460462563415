import handsKey from './img/hands-key.png';
import immobile from './img/immobile.png';
import hands from './img/hands.png';
import map from './img/map.png';

export const data = [
  {
    id: 0,
    icon: <img src={handsKey} alt="chave na mão" />,
    text: 'O contato é realmente proprietário do imóvel, e ele está ciente da indicação.',
  },
  {
    id: 1,
    icon: <img src={immobile} alt="poutrona com um abajur" />,
    text: 'O imóvel está mobiliado.',
  },
  {
    id: 2,
    icon: <img src={hands} alt="aperto de mão" />,
    text: 'O imóvel não está em construção e já está disponível para alugar.',
  },
  {
    id: 3,
    icon: <img src={map} alt="mapa" />,
    text: 'O imóvel fica em uma região em que a Seazone atua.',
  },
];
