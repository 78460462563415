import styled from 'styled-components';

const fullhd = '2300px';
const ultrawide = '2560px';
const notebook = '1500px';

export const Container = styled.div<{
  mobile: boolean | undefined;
}>`
  width: 100%;
  height: 100%;
  max-width: 47vw;
  min-height: 350px;
  max-height: 50vh;
  background: ${({ theme }) => theme.palette.white._200.hex()};
  border-radius: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 2rem 5rem;
  margin: 0;

  @media (max-width: ${notebook}) {
    max-width: calc(100% - 30vw);
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${ultrawide}) {
    justify-content: center;
    padding: 2rem 7rem;
    max-width: calc(100% - 55vw);
  }

  @media (max-width: ${fullhd}) {
    max-width: calc(100% - 30vw);
    padding: 2rem 5rem;
  }

  @media (max-width: 900px) {
    width: 110vw;
    padding: 0 25px 0 0;
    margin-left: -15px;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 3rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};

  @media (max-width: ${notebook}) {
    font-size: 1.3rem;
  }

  @media (max-width: 900px) {
    font-size: 13pt;
    padding: 1rem;
    word-break: break-word;
    margin-bottom: 0;
  }
`;

export const Questions = styled.div`
  word-break: break-word;
  width: 100%;

  .question {
    @media (max-width: 900px) {
      display: grid;
      grid-template-columns: 20px 1fr;
      .StyledDivLabel {
        padding: 0 1rem;
        line-height: 18px;
        font-size: 12pt;
      }
      margin: 1.3rem 1rem;
    }
  }
`;

export const SucessContainer = styled.div`
  text-align: center;
  justify-content: center;
  font-size: 1rem;

  h2:not(:first-child) {
    margin-top: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 700;
  }

  .finished {
    margin-top: 2rem;
    font-weight: 500;
  }

  div {
    margin-top: 1rem;
    justify-content: left;
    text-align: left;
  }

  img {
    max-width: 100px;
    height: 100%;
    width: 10vw;
    margin-bottom: 2rem;
  }

  @media (max-width: 900px) {
    margin-top: 0;
    padding: 0;

    h2 {
      text-align: left;
      font-size: 11pt;
      margin-bottom: 1rem;
    }

    margin-left: 1rem;

    h2:not(:first-child) {
      margin-top: 1rem;
    }

    .finished {
      margin-top: 0;
      font-weight: 500;
    }

    div {
      margin-top: 0;
      justify-content: left;
      text-align: left;
    }

    img {
      max-width: 100px;
      height: 100%;
      width: 20vw;
      margin-bottom: 1rem;
    }
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  text-align: left;
`;

export const TextField = styled.textarea``;
