import styled from 'styled-components';

export const Container = styled.div`
  width: 457px;

`;

export const CategoryProperties = styled.div`
  width: 100%;
`;

export const HeaderCategory = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const NameCategory = styled.h1`
  font-size: 18px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.familys._1};

  strong {
    font-size: 18px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;

export const DateReservation = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 25px;
  svg{fill:${({ theme }) => theme.palette.orange.main.hex()}};

  p {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  span {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }
`;

export const TotalValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 700;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.green.main.hex()};
  }
`;

export const SmallPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey._420.hex()};

  p {
    font-weight: 400;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
  h1 {
    font-weight: 600;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;

export const ContentPart = styled.div`
  margin-bottom: 30px;
`;
