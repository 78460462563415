import { motion } from 'framer-motion';
import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../../hooks/useMobile/useMobile';

export const Container = styled.div`
  width: 100%;
  padding: 34px 0;
`;

export const Description = styled.p`
  color: var(--darkk-blue-900, #001840);
  text-align: center;
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const HeadContainer = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const WaveContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const WaveContent = styled(motion.div)`
`;
export const Content = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const WaveText = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  span {
    color: var(--azul, #00153e);
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  color: var(--azul, #00153e);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const WaveTexts = styled.div`
  display: grid;
  gap: 40px;
  width: 100%;
  justify-content: left;
  align-items: left;
  padding: 0 15px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
  }
`;
