import {
  Container,
  TitleContainer,
  Title,
  Description,
} from './styles';

const IconKey = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7137 11.556C14.6323 11.4752 14.5249 11.4302 14.4108 11.4302H13.2831V10.3444C13.2831 10.2311 13.2378 10.1227 13.1565 10.0436L12.3426 9.23526C12.2612 9.15443 12.1538 9.10943 12.0397 9.10943H10.8172V8.21114C10.8172 8.09781 10.7719 7.98947 10.6905 7.91031L9.89758 7.12284C10.2433 6.31621 10.3742 5.43123 10.2777 4.54629C10.0159 2.16302 8.08265 0.263147 5.68145 0.0257159C4.12836 -0.127612 2.60557 0.409873 1.50796 1.49984C0.410479 2.5898 -0.129025 4.10219 0.0262081 5.6471C0.267016 8.02375 2.17417 9.94112 4.56464 10.2061C5.45151 10.3061 6.34089 10.1786 7.15057 9.83941L13.2269 15.8742C13.3083 15.955 13.4157 16 13.5298 16H15.5704C15.807 16 16 15.8083 16 15.5733V13.0134C16 12.9001 15.9547 12.7918 15.8733 12.7126L14.7137 11.556ZM4.07874 5.65117C3.18936 5.65117 2.46777 4.93452 2.46777 4.05123C2.46777 3.16793 3.18936 2.45129 4.07874 2.45129C4.96813 2.45129 5.68971 3.16793 5.68971 4.05123C5.68971 4.93452 4.96813 5.65117 4.07874 5.65117Z" fill="black" />
  </svg>
);

const DEFAULT_TITLE = 'Regras de mudança';
const DEFAULT_DESCRIPTION = 'Não é possível realizar troca de anfitrião durante o período de reserva vigente. Apenas na data de check-out. Nos casos em que ocorre troca com check-out e check-in no mesmo dia, a taxa de limpeza fica para o anfitrião antigo e a comissão referente ao check-in fica para o anfitrião novo. Para casos excepcionais entre em contato com o suporte.';

interface IRulesMessage {
  title?: string,
  description?: string,
}

const RulesMessage = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
}: IRulesMessage) => (
  <Container>
    <TitleContainer>
      <IconKey />
      <Title>{title}</Title>
    </TitleContainer>
    <Description>{description}</Description>
  </Container>
);

RulesMessage.defaultProps = {
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
};

export default RulesMessage;
