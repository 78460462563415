import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../hooks/useMobile/useMobile';

export const Container = styled.div`
  gap: 30px;
  display: flex;
  flex-direction: column;

  padding: 20px;
  border-radius: 0px 0px 60px 60px;
  padding-bottom: 30px;
  background-color: #001840;
`;

export const Title = styled.div`
  font-size: 1.3rem;
  color: #f9f9f9;
  font-weight: bold;
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    div {
      &:last-child {
        border-right: none;
        padding-right: 0;
      }

      &:first-child {
        padding-right: 20px;
      }

      border-right: 1px solid #e7eaf1;
    }
  }
`;

export const Item = styled.div`
  font-family: "Inter";
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const Number = styled.h1`
  font-size: 2rem;
  color: #f9f9f9;
`;

export const Label = styled.h2`
  font-size: 1rem;
  color: #f9f9f9;
`;
