import styled, { keyframes } from 'styled-components';

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const ManagementPanelCardContainer = styled.div<{ time: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;

  background: ${({ theme }) => theme.palette.white._300.hex()};
  padding: 2rem;
  border-radius: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grey._900.hex()};
  opacity: 0;
  animation-name: ${FadeInOpacity};
  animation-duration: ${({ time }) => time && `${time}ms`};
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  p {
    font-size: 1rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.white._650.hex()};
    box-shadow: 0 0 2px 1px ${({ theme }) => theme.palette.blue._850.hex()};
    transition: background 300ms ease-in-out;
    div {
      background-color: ${({ theme }) => theme.palette.blue._930.hex()};
    }
    p {
      font-size: 1.2rem;
    }
  }
`;
