import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img, svg {
  margin-right: 10px;
  width: 20px;
}
`;

export const Card = styled.div`
  width: 250px;
  height: 80px;
  background: ${({ theme }) => theme.palette.blue._30.hex()};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    width: 100%;
    font-size: 20px;
    text-align: center;
  }

  span {
    font-weight: 700;
  }


`;

export const Wrapper = styled.div`
  width: 950px;
  height: 550px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px 20px;
`;

export const Title = styled.h1`
  padding-right: 60px !important;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 28px !important;
`;

export const ContentRight = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const ContentLeft = styled.div`
  width: 60%;

`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;

  .row {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    margin-bottom: 5px;
  }

  svg {
    margin-right: 8px;
    color: ${({ theme }) => theme.palette.blue._930.hex()};
  }

  span {
    font-weight: 700;
    margin-right: 10px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;
