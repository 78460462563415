import styled, { keyframes } from 'styled-components';

import { Link } from 'react-router-dom';

const FadeInAnimationItems = keyframes`
  0% {
    transform: translateY(30px);
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const animationTime = '0.5s';

export const Container = styled.div`
  width: 100vw;
  height: 100px;
  background-color: ${({ theme }) => theme.palette.blue._950.hex()};
  display: none;
  align-items: center;
  padding: 0 15px;
  z-index: 998;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100vw;

  .active {
    animation-name: ${FadeInAnimationItems};
    animation-duration: ${animationTime};
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    p {
      color: ${({ theme }) => theme.palette.white.main.hex()};
      font-weight: 600;
    }

    img {
      filter: invert(6090%) sepia(1%) saturate(7487%) hue-rotate(580deg)
        brightness(160%) contrast(1156%);
    }

    svg {
      path {
        fill: #0D4BD0;
        filter: brightness(160%);
      }
    }
  }

  @media (max-width: 900px) {
    display: flex;
  }
`;

export const NavBar = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const ItemNav = styled(Link)`
  display: flex;
  min-width: 50px;
  height: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 500px) {
    width: 80px;
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.palette.grey._670.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.25px;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const Image = styled.img`
  width: 30px;
  margin-bottom: 5px;

  &:hover {
    filter: invert(50%) sepia(1%) saturate(7487%) hue-rotate(326deg)
        brightness(7899%) contrast(86%);
  }

  @media (max-width: 500px) {
    width: 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const FinanceOptionsContainer = styled.div`
  width: 100%;
  position: relative;
  bottom: 32px;

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.25rem 0.25rem 0.25rem 0;

    div {
      margin-right: 16px;
      padding-left: 60px;
    }
  }
`;

export const ImageResized = styled.img`
  z-index: 2;
  cursor: pointer;

  max-width: 20px;
  height: 20px;
  @media (max-width: 580px) {
    max-width: 20px;
    height: 20px;
  }

  img {
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }

  svg {
    path {
      fill: ${({ theme }) => theme.palette.white.main.hex()};
    }
  }
`;

export const SVGContainer = styled.div`
  z-index: 2;
  cursor: pointer;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.white.main.hex()};
      filter: brightness(0.6);
    }
  }

`;
