import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 10px;
  padding: 0 12px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 14px;
`;

export const Label = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;
