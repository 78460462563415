import React from 'react';

import { AnimatePresence } from 'framer-motion';

import Card from './Card';
import Table from './Table';
import Cashout from './Cashout';

import { PageTitle } from '../utils/style';

import {
  Section,
  Balance,
  SubTitle,
  Container,
  TertiaryTitle,
  BalanceShimmer,
  TableContainer,
  TotalBalanceContainer,
} from './style';

import { numberToCurrency } from '../../../../utils/Formatter';
import { EarningProvider, usePartnersEarning } from '../../../../context/Partners/Earning/Earning';

const defaultValueString = new Date().toISOString().slice(0, 7);

const Earnings = () => {
  const ref = React.useRef<HTMLInputElement>(null);

  const {
    setFilter,
    totalBalance,
  } = usePartnersEarning();

  return (
    <Container
      animate={{ y: 0 }}
      initial={{ y: 20 }}
    >
      <PageTitle
        transition={{ delay: 0.1 }}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        Saldo com a Seazone
      </PageTitle>

      <Card />

      <Cashout />

      <Section>
        <SubTitle>Visualizar dados</SubTitle>
        <input
          ref={ref}
          id="date"
          type="month"
          defaultValue={defaultValueString}
          onClick={() => ref.current?.showPicker()}
          onChange={(e) => setFilter((old) => ({
            ...old,
            date: e.target.value,
          }))}
        />
      </Section>

      <TotalBalanceContainer>
        <TertiaryTitle>Seu saldo no período selecionado é</TertiaryTitle>
        <AnimatePresence>
          {totalBalance ? (
            <Balance
              key={totalBalance}
              negative={totalBalance < 0}
              exit={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 10 }}
            >
              {numberToCurrency(totalBalance)}
            </Balance>
          ) : (
            <BalanceShimmer
              exit={{ opacity: 0, width: 0 }}
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: '10%' }}
            />
          )}
        </AnimatePresence>
      </TotalBalanceContainer>
      <TableContainer
        initial={{
          x: 20,
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        exit={{
          x: 20,
          opacity: 0,
        }}
      >
        <Table />
      </TableContainer>
    </Container>
  );
};

const EarningsProvided = () => (
  <EarningProvider>
    <Earnings />
  </EarningProvider>
);

export default EarningsProvided;
