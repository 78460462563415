/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';

import { getUserMe } from '../../services/User/request';
import { UserMeProps } from '../../services/User/types';
import { BankDetails } from '../../services/Owner/types';

import { useMobile } from '../../hooks/useMobile/useMobile';
import { useOwnerTransferControl } from '../../hooks/OwnerPage/OwnerTransferControl/useOwnerTransferControl';
import { useOwnerServicesTerms } from '../../hooks/OwnerPage/OwnerServicesTerms/useOwnerServicesTerms';

import { useUser } from '../../context/UserContext';
import { useViewMode } from '../../context/ViewMode/ViewMode';
import { OwnerFinancialSummaryProvider } from '../../context/OwnerPage/OwnerFinancialSummaryContext/OwnerFinancialSummaryContext';

import Header from '../../components/OwnerPage/Header/Header';
import Footer from '../../components/OwnerPage/Footer';
import WelcomePopup from '../../components/OwnerPage/Popups/WelcomePopup/WelcomePopup';
import ServicesTermsPopup from '../../components/OwnerPage/Popups/ServicesTermsPopup';
import FirstLoginOwnerModal from '../../components/FirstLoginOwnerModal';
import FirstLoginBankModal from '../../components/BankDetailsModal/FirstLoginBankModal';

import { Container } from './styles';
import { useRequestSupportModal } from '../../hooks/useRequestModal';
import { ReportBugModal } from '../../components/ReportBugModal/ReportBugModal';
import ConfirmationModal from '../../components/OwnerPage/Popups/ServicesTermsPopup/ConfirmationModal';
import { getPropertyAgreements } from '../../services/Owner/OwnerServicesTerms/request';

const OwnerLayout = () => {
  // const params = useParams();
  const { pathname } = useLocation();
  const { userInformation } = useUser();
  const { mode } = useViewMode();
  const { isMobile } = useMobile();
  const { setTransferControlAction } = useOwnerTransferControl();
  const { setProperties, setOpenServicesTerms } = useOwnerServicesTerms();

  const [openWelcomePopup, setOpenWelcomePopup] = useState<boolean>(false);
  // const [isShowingBackButton, setIsShowingBackButton] = useState<boolean>(false);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [isFirstLoginBankDetails, setIsFirstLoginBankDetails] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserMeProps | null>({} as UserMeProps);
  const [dataAccountBank, setDataAccountBank] = useState<BankDetails>();
  const { isOpenedReportErrorModal, handleCloseReportBugModal } = useRequestSupportModal();

  const ownerId = useMemo(() => userInformation?.owner?.id || undefined, [userInformation]);
  const viewModeOwnerId = useMemo(() => mode?.user_info?.owner?.id || undefined, [mode]);

  function handleCloseModal(bank: BankDetails) {
    setDataAccountBank(bank);
  }

  const handleIsOwnerFirstLogin = async () => {
    const response = await getUserMe();
    setUserDetails(response);

    if (response?.required_actions?.Owner?.includes('COMPLETE_PERSONAL_DATA')) {
      setIsFirstLogin(true);
    } else {
      setIsFirstLogin(false);
    }

    if (response?.required_actions?.Owner?.includes('COMPLETE_BANK_DETAILS')) {
      setIsFirstLoginBankDetails(true);
    } else {
      setIsFirstLoginBankDetails(false);
    }

    if (pathname === '/proprietario') {
      if (response?.required_actions?.Owner?.includes('OWNER_FINANCIAL_SUMMARY_POPUP')) {
        setOpenWelcomePopup(false); // receive false and hide popup forced
      } else {
        setOpenWelcomePopup(false);
      }

      if (response?.required_actions?.Owner?.includes('OWNER_HOUSEHOLD_LINEN_AGREEMENT_POPUP')) {
        setOpenServicesTerms({
          servicesTermsPopup: true,
          confirmationModal: false,
        });
      } else {
        setOpenServicesTerms({
          servicesTermsPopup: false,
          confirmationModal: false,
        });
      }
    }

    if (pathname === '/meusdados') {
      if (response?.required_actions?.Owner?.includes('OWNER_TRANSFER_CONTROL_NOT_ADDED')) {
        setTransferControlAction('add_transfer');
      } else {
        setTransferControlAction('change_transfer');
      }
    }
  };

  // useEffect(() => {
  //   if (params.id) {
  //     setIsShowingBackButton(true);
  //   } else {
  //     setIsShowingBackButton(false);
  //   }
  // }, [params]);

  useEffect(() => {
    handleIsOwnerFirstLogin();
  }, [pathname]);

  const handleGetPropertyAgreements = async () => {
    if (viewModeOwnerId) {
      const response = await getPropertyAgreements({
        owner_id: viewModeOwnerId,
      });
      setProperties(response);
    } else if (ownerId) {
      const response = await getPropertyAgreements({
        owner_id: ownerId,
      });
      setProperties(response);
    }
  };

  useEffect(() => {
    handleGetPropertyAgreements();
  }, []);

  if (isMobile && openWelcomePopup) {
    return (
      <>
        <OwnerFinancialSummaryProvider>
          <Header />
        </OwnerFinancialSummaryProvider>
        <ServicesTermsPopup />
        <ConfirmationModal />
        <WelcomePopup open={openWelcomePopup} setOpen={setOpenWelcomePopup} />
      </>
    );
  }

  return (
    <>
      <Container mode={mode}>
        <OwnerFinancialSummaryProvider>
          <>
            {/* <Header showBackButton={isShowingBackButton} /> */}
            <Header />
            <Outlet />
          </>
        </OwnerFinancialSummaryProvider>
        <Footer />
      </Container>

      {isFirstLogin && (
        <FirstLoginOwnerModal
          userDetails={userDetails}
          handleIsOwnerFirstLogin={handleIsOwnerFirstLogin}
        />
      )}

      {isFirstLoginBankDetails && (
        <FirstLoginBankModal
          setOpenModal={setIsFirstLoginBankDetails}
          openModal={isFirstLoginBankDetails || false}
          dataAccountBank={dataAccountBank}
          setDataAccountBank={handleCloseModal}
        />
      )}
      {isOpenedReportErrorModal && (
        <ReportBugModal
          isModalOpen={isOpenedReportErrorModal}
          handleCloseModal={handleCloseReportBugModal}
          url="https://app.pipefy.com/public/form/-nNLt-Dp"
        />
      )}
      <ServicesTermsPopup />
      <ConfirmationModal />
      <WelcomePopup open={openWelcomePopup} setOpen={setOpenWelcomePopup} />
    </>
  );
};

export default OwnerLayout;
