import { ExpenseReasonLabel } from '../../services/Expenses/types';
import { PaymentStatus, BlockingReason } from '../../services/Reservation/types';
import { Gender, Roles } from '../../services/types';
import { AccountTypeLabel } from '../../services/Bank/types';
import { PixType } from '../../services/Owner/types';

export const translatePaymentStatus = (paymentStatus: PaymentStatus): string => {
  let status = '';
  if (paymentStatus === 'fullyPaid') {
    status = 'Totalmente Pago';
  } else if (paymentStatus === 'partiallyPaid') {
    status = 'Parcialmente pago';
  } else if (paymentStatus === 'unpaid') {
    status = 'Não pago';
  } else {
    status = 'Cancelado';
  }

  return status;
};

export const translatePropertyStatus = (propertyStatus: string): string => {
  let status = '';
  if (propertyStatus === 'Active') {
    status = 'Ativo';
  } else if (propertyStatus === 'Inactive') {
    status = 'Inativo';
  } else if (propertyStatus === 'Onboarding') {
    status = 'Onboarding';
  } else {
    status = 'Fechado';
  }

  return status;
};

export const translateGender = (gender: Gender): string => {
  let genderType = '';
  if (gender === 'Male') {
    genderType = 'Masculino';
  } else if (gender === 'Female') {
    genderType = 'Feminino';
  } else {
    genderType = 'Não informado';
  }

  return genderType;
};

export const translateMonth = (month: string = '01'): string => {
  let convertedMonth = '';
  if (month === '01') {
    convertedMonth = 'Janeiro';
  } else if (month === '02') {
    convertedMonth = 'Fevereiro';
  } else if (month === '03') {
    convertedMonth = 'Março';
  } else if (month === '04') {
    convertedMonth = 'Abril';
  } else if (month === '05') {
    convertedMonth = 'Maio';
  } else if (month === '06') {
    convertedMonth = 'Junho';
  } else if (month === '07') {
    convertedMonth = 'Julho';
  } else if (month === '08') {
    convertedMonth = 'Agosto';
  } else if (month === '09') {
    convertedMonth = 'Setembro';
  } else if (month === '10') {
    convertedMonth = 'Outubro';
  } else if (month === '11') {
    convertedMonth = 'Novembro';
  } else if (month === '12') {
    convertedMonth = 'Dezembro';
  } else {
    convertedMonth = 'Mês inválido!';
  }

  return convertedMonth;
};

export const translateReservationStatus = (status: string) => {
  let newStatus = 'No-Show';
  switch (status) {
    case 'Active':
      newStatus = 'Ativa';
      break;
    case 'Concluded':
      newStatus = 'Concluída';
      break;
    case 'Canceled':
      newStatus = 'Cancelada';
      break;
    case 'In progress':
      newStatus = 'Em progresso';
      break;
    case 'Pre-booking':
      newStatus = 'Pré reserva';
      break;
    case 'No-Show':
      newStatus = 'No Show';
      break;
    default:
      return newStatus;
  }
  return newStatus;
};

export const reverseTranslateReservationStatus = (status: string) => {
  let newStatus = 'No-Show';
  switch (status) {
    case 'Ativa':
      newStatus = 'Active';
      break;
    case 'No-Show':
      newStatus = 'No-Show';
      break;
    case 'Concluída':
      newStatus = 'Concluded';
      break;
    case 'Cancelada':
      newStatus = 'Canceled';
      break;
    case 'Em progresso':
      newStatus = 'In progress';
      break;
    case 'Pré reserva':
      newStatus = 'Pre-booking';
      break;
    default:
      return newStatus;
  }
  return newStatus;
};

export const translateBlockingReason = (blockingReason: BlockingReason | string): string => {
  let reason = '';
  switch (blockingReason) {
    case 'Maintenance':
      reason = 'Manutenção';
      break;
    case 'Cleaning':
      reason = 'Limpeza';
      break;
    case 'Owner use':
      reason = 'Proprietário';
      break;
    case 'Host':
      reason = 'Anfitrião';
      break;
    case 'Preparation':
      reason = 'Preparação';
      break;
    default:
      reason = 'Indefinido';
      break;
  }
  return reason;
};

export const translateRole = (role: Roles | string): string => {
  let mainRole = '';
  switch (role) {
    case 'Host':
      mainRole = 'Anfitrião';
      break;
    case 'Admin':
      mainRole = 'Administrador';
      break;
    case 'Guest':
      mainRole = 'Hóspede';
      break;
    case 'Partner':
      mainRole = 'Parceiro';
      break;
    case 'Owner':
      mainRole = 'Proprietário';
      break;
    case 'Attendant':
      mainRole = 'Atendente';
      break;
    case 'Seazone':
      mainRole = 'Seazone';
      break;
    default:
      mainRole = 'Indefinido';
      break;
  }
  return mainRole;
};

export const translateExpenseStatus: Record<string, string> = {
  Approved: 'Aprovado',
  Pre_Approved: 'Pré Aprovado',
  Canceled: 'Cancelado',
  Denied: 'Reprovado',
  Analyzing: 'Em análise',
  Paid: 'Pago',
  Pending: 'Pendente',
  '': '',
};

export const translateExpenseStatusInvert: Record<string, string> = {
  Aprovado: 'Approved',
  'Pré Aprovado': 'Pre_Approved',
  Cancelado: 'Canceled',
  Reprovado: 'Denied',
  'Em análise': 'Analyzing',
  Pago: 'Paid',
  Pendente: 'Pending',
  '': '',
};

export const translateBedArrangement = (bedArrangement: string): string => {
  let translate = '';
  const bedOption = bedArrangement?.toLocaleUpperCase();

  if (bedOption === 'DOUBLE_BED') {
    translate = 'Cama de casal';
  } else if (bedOption === 'TWO_SINGLE_BEDS') {
    translate = 'Duas camas de solteiro';
  } else if (bedOption === 'EXTRA_BED') {
    translate = 'Cama extra';
  } else if (bedOption === 'SOFA_BED') {
    translate = 'Sofá cama';
  } else if (bedOption === 'CRADLE') {
    translate = 'Berço';
  }

  return translate;
};

export const transformStringBedArrangement = (bedArrangement: string): string => {
  let stringTransform = '';
  const bedOption = bedArrangement.toLocaleUpperCase();

  if (bedOption === 'DOUBLE_BED') {
    stringTransform = 'Double_Bed';
  } else if (bedOption === 'TWO_SINGLE_BEDS') {
    stringTransform = 'Two_Single_Beds';
  } else if (bedOption === 'EXTRA_BED') {
    stringTransform = 'Extra_Bed';
  } else if (bedOption === 'SOFA_BED') {
    stringTransform = 'Sofa_Bed';
  } else if (bedOption === 'CRADLE') {
    stringTransform = 'Cradle';
  }

  return stringTransform;
};

export const translateTypeProperty = (propertyType: string): string => {
  let typeProperty = '';
  if (propertyType === 'Apartamento') {
    typeProperty = 'Apartment';
  } else if (propertyType === 'Hotel') {
    typeProperty = 'Hotel';
  } else if (propertyType === 'Casa') {
    typeProperty = 'House';
  }

  return typeProperty;
};

export const translateExpenseReason: Record<string, string> = {
  Account_Management_Energy: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_ENERGY,
  Account_Management_Water: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_WATER,
  Account_Management_Condominium: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_CONDOMINIUM,
  Account_Management_Internet: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_INTERNET,
  Account_Management_Garden: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_GARDEN,
  Account_Management_Pool: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_POOL,
  Account_Management_IPTU: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_IPTU,
  Account_Management_TV: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_TV,
  Account_Management_Subscription: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_SUBSCRIPTION,
  Account_Management_Gas: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_GAS,
  Maintenance: ExpenseReasonLabel.MAINTENANCE,
  Puchase_Required_Items: ExpenseReasonLabel.PURCHASE_REQUIRED_ITEMS,
  Third_party_services: ExpenseReasonLabel.THIRD_PARTY_SERVICES,
  Onboarding_Keys_Cleaning: ExpenseReasonLabel.ONBOARDING_KEYS_CLEANING,
  Onboarding_Laundry: ExpenseReasonLabel.ONBOARDING_LAUNDRY,
  Onboarding_Administrative: ExpenseReasonLabel.ONBOARDING_ADMINISTRATIVE,
  Onboarding_Commission: ExpenseReasonLabel.ONBOARDING_COMMISSION,
  Reccurent_Expense: ExpenseReasonLabel.RECCURENT_EXPENSE,
  Guest_Damage: ExpenseReasonLabel.GUEST_DAMAGE,
  Seazone_Charges: ExpenseReasonLabel.SEAZONE_CHARGES,
  // Reservation_Commission: ExpenseReasonLabel.RESERVATION_COMMISSION,
  // Owner_Cleaning: ExpenseReasonLabel.OWNER_CLEANING,
  Owner_Reservation: ExpenseReasonLabel.OWNER_RESERVATION,
  Nothing: ExpenseReasonLabel.NOTHING,
  Buying_Layette: ExpenseReasonLabel.BUYING_LAYETTE,
  Professional_Photo_Review: ExpenseReasonLabel.PRO_PHOTO_REVIEW,
  Material_Purchase: ExpenseReasonLabel.MATERIAL_PURCHASE,
  Refund_Expense: ExpenseReasonLabel.REFUND_EXPENSE,
};

export const translatedPaidBy: Record<string, string> = {
  Owner: 'Proprietário',
  Host: 'Anfitrião',
  Guest: 'Hóspede',
  Seazone: 'Seazone',
  '': 'Não informado',
};

export const translatedReceivedBy: Record<string, string> = {
  Owner: 'Proprietário',
  Host: 'Anfitrião',
  Seazone: 'Seazone',
  '': 'Não informado',
};

export const translatedAccountType: Record<string, string> = {
  Saving_Account: AccountTypeLabel.SAVING_ACCOUNT,
  Individual_Checking_Account: AccountTypeLabel.INDIVIDUAL_CHECKING_ACCOUNT,
  Joint_Checking_Account: AccountTypeLabel.JOINT_CHECKING_ACCOUNT,
};

export const translatePixType = (pixType: PixType) => {
  switch (pixType) {
    case 'CNPJ':
      return 'CNPJ';
    case 'CPF':
      return 'CPF';
    case 'Email':
      return 'E-mail';
    case 'Phone_Number':
      return 'Número de telefone';
    case 'Random':
      return 'Chave aleatória';
    default:
      return 'Não informado';
  }
};

export const translateAccountType = (type: string): string => {
  let accountType = '';
  if (type === 'Saving_Account') {
    accountType = 'Conta poupança';
  } else if (type === 'Individual_Checking_Account') {
    accountType = 'Conta corrente individual';
  } else if (type === 'Joint_Checking_Account') {
    accountType = 'Conta corrente conjunta';
  }
  return accountType;
};

export const fieldLabels: { [key: string]: string } = {
  neighborhood: 'Bairro',
  street: 'Rua',
  number: 'Número',
  categoryLocationId: 'Categoria do imóvel',
  bedroomsAmount: 'Quantidade de quartos',
  extraDayPreparation: 'Preparação extra',
  comment: 'Comentário',
  onboardingPhone: 'Telefone',
  name: 'Nome',
  email: 'E-mail',
  lastname: 'Sobrenome',
  born: 'Data de nascimento',
  phone: 'Telefone',
  cpf: 'CPF',
  cnpj: 'CNPJ',
  city: 'Cidade',
  country: 'País',
  state: 'Estado',
  zipCode: 'Cep',
  postal_code: 'Cep',
  nationality: 'Nacionalidade',
  marital_status: 'Estado civíl',
  password: 'Senha',
  passwordConfirm: 'Confirmação de senha',
  propertycode: 'Código do imóvel',
  propertyType: 'Tipo de imóvel',
  singleBeds: 'Camas de solteiro',
  doubleBeds: 'Camas de casal',
  queenBeds: 'Camas queen',
  kingBeds: 'Camas king',
  singleSofaBeds: 'Sofás-cama de solteiro',
  sofaDoubleBeds: 'Sofás-cama de casal',
  bathrooms: 'Banheiros',
  totalImplantationFee: 'Taxa de adesão',
  plan: 'Plano',
  commissions: 'Comissão sobre reservas',
  hostReservationComissionFee: 'Comissão do anfitrião',
  paymentMethod: 'Forma de pagamento da taxa',
  host: 'Anfitrião responsável',
};

export const translateMaritalStatus: Record<string, string | null> = {
  'solteiro(a)': 'Single', 
  'solteiro': 'Single', 
  'solteira': 'Single',
  'casado(a)': 'Married', 
  'casado': 'Married', 
  'casada': 'Married', 
  'divorciado(a)': 'Divorced', 
  'divorciado': 'Divorced',
  'divorciada': 'Divorced', 
  'viúvo(a)': 'Widowed',
  'viúvo': 'Widowed',
  'viúva': 'Widowed',
  '': null,
};
