import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  gap: 1rem;
  width: 100%;
  padding: 30px;
  display: flex;
  background: #e5e5e5;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 30px;

  div {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
  }

  p {
    font-size: 1.5rem;
    text-align: left;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.grey._600.hex()};
  }

  button {
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    transition: all 0.2s ease;
    color: ${({ theme }) => theme.palette.white.main.hex()};
    background: ${({ theme }) => theme.palette.blue._900.hex()};
  }

  .outline {
    font-weight: bold;
    background: transparent;
    color: ${({ theme }) => theme.palette.blue._900.hex()};
    border: 2px solid ${({ theme }) => theme.palette.blue._900.hex()};
  }
`;

export const Alert = styled(motion.div)`
  padding: 20px;
  position: relative;
  border-radius: 10px;
  background: #ef93002f;
  border: 2px solid #ef930097;

  gap: 20px;
  display: flex;
  flex-direction: row;

  h1 {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;
