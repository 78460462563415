/* eslint-disable @typescript-eslint/no-shadow */
import { compareList } from '../../../utils/Sorting';

import {
  EnumStatus,
  StatusProps,
  IListItems,
  OrderBy,
  Order,
} from '../types';

export const TEDLISTPAGEMARGIN = 50;

export const GRIDMAXITEMS = 10;

export const tedListDefaultLoadingTime = 2000;

export const gridTemplateColumnsTedListDesktop = '70px 170px 250px 350px 350px repeat(11, 250px)';

export const GRIDITEMS = [
  'Ajustar',
  'Status',
  'Nome',
  'Nome para repasse',
  'Repasse',
  'CPF',
  'CNPJ',
  'Número do banco',
  'Banco',
  'Agência',
  'Tipo de conta',
  'Conta',
  'Tipo da chave Pix',
  'PIX',
  'Dia de repasse',
];

// important, the order of the items must be the same as the order of the columns
export const gridItemsObject = {
  radioButton: '70px',
  adjustButton: '170px',
  status: '250px',
  name: '350px',
  nameToTed: '350px',
  repasse: '250px',
  cpf: '250px',
  bank: '250px',
  cnpj: '250px',
  pix: '250px',
  pixKeyType: '250px',
  accountType: '250px',
  bankNumber: '300px',
  agency: '250px',
  account: '250px',
  transferDay: '250px',
};

export const newGridTemplateColumnsBasedOnGridObject = (gridItemsObject: {
  [key: string]: string;
}) => {
  const gridTemplateColumns = Object.values(gridItemsObject).join(' ');
  return gridTemplateColumns;
};

export const convertStringToStatusProps = (status: string): StatusProps => {
  switch (status) {
    case EnumStatus.notDone:
      return 'Not_Concluded';
    case EnumStatus.done:
      return 'Concluded';
    case EnumStatus.withAdjustments:
      return 'Pending';
    case EnumStatus.exception:
      return 'Exception';
    default:
      return EnumStatus.notDone;
  }
};

export const convertStatusPropsToStatusToShow = (status: StatusProps): string => {
  switch (status) {
    case EnumStatus.notDone:
      return 'Não concluído';
    case EnumStatus.done:
      return 'Concluido';
    case EnumStatus.withAdjustments:
      return 'Com ajustes';
    case EnumStatus.exception:
      return 'Exceção';
    default:
      return 'Não concluído';
  }
};

export const translateTedLabel: Record<string, string> = {
  Nome: 'name',
  'Nome para repasse': 'nameToTed',
  Repasse: 'transfer',
  CPF: 'cpf',
  CNPJ: 'cnpj',
  PIX: 'pixKey',
  'Tipo da chave Pix': 'pixKeyType',
  Banco: 'bank',
  'Número do banco': 'bankNumber',
  'Tipo de conta': 'accountType',
  Agência: 'agency',
  Conta: 'account',
  Status: 'status',
  transferDay: 'Dia de repasse',
};

export const sortTedListItems = (
  listItems: IListItems[],
  orderBy: OrderBy,
  order: Order,
): IListItems[] => {
  let sorted: IListItems[] = listItems;

  switch (orderBy) {
    case 'name':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.name, b.name, order,
      ));
      break;

    case 'nameToTed':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.nameToTed, b.nameToTed, order,
      ));
      break;

    case 'transfer':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.transfer, b.transfer, order,
      ));
      break;

    case 'cpf':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.cpf, b.cpf, order,
      ));
      break;

    case 'cnpj':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.cnpj, b.cnpj, order,
      ));
      break;

    case 'pixKey':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.pixKey, b.pixKey, order,
      ));
      break;
    case 'pixKeyType':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.pixKeyType, b.pixKeyType, order,
      ));
      break;

    case 'bank':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.bank, b.bank, order,
      ));
      break;

    case 'bankNumber':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.bankNumber, b.bankNumber, order,
      ));
      break;

    case 'accountType':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.accountType, b.accountType, order,
      ));
      break;

    case 'agency':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.agency, b.agency, order,
      ));
      break;

    case 'account':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.account, b.account, order,
      ));
      break;

    case 'status':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.status, b.status, order,
      ));
      break;
    case 'transferDay':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.transferDay, b.transferDay, order,
      ));
      break;
    default:
      return sorted;
  }

  return sorted;
};

export const getTedUpdated = (data: IListItems) => ({
  ...data,
});
