/* eslint-disable max-len */
import { useState, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';

import {
  BankDetails,
  PropertyInvoiceBank,
} from '../../../../services/Owner/types';

import { Number as Mask } from '../../../../utils/InputMask';

import { Bank } from '../../../../services/Bank/types';

import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import { putOwnerPropertyInvoiceBank } from '../../../../services/Owner/request';

import { useToast } from '../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../utils/Messages';
import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';

import SimpleSelect from '../../../SimpleSelect';

import {
  Container,
  ContentButton,
  Text,
  TextBold,
  Button,
  Content,
  TextLight,
  DefaultBadgeContainer,
  DefaultBadge,
} from './styles';

interface Props {
  code: string;
  neighborhood: string;
  testid: string;
  listBank: Array<BankDetails>;
  propertyInvoiceBank: PropertyInvoiceBank;
}

const CardPropertyBankAccount = ({
  code,
  neighborhood,
  testid,
  listBank,
  propertyInvoiceBank,
}: Props): JSX.Element => {
  const [showSaveButton, setShowSaveButton] = useState(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { isMobile } = useScreenResize();

  const getDocument = useCallback(() => {
    if (!['', 'undefined', 'null'].includes(`${propertyInvoiceBank?.invoice_details?.cpf}`)) {
      return `CPF - ${Mask.cpf(`${propertyInvoiceBank?.invoice_details?.cpf}`)}`;
    }

    if (!['', 'undefined', 'null'].includes(`${propertyInvoiceBank?.invoice_details?.cnpj}`)) {
      return `CNPJ - ${Mask.cnpj(`${propertyInvoiceBank?.invoice_details?.cnpj}`)}`;
    }

    return '';
  }, [propertyInvoiceBank]);

  const initialValues = useMemo(() => ({
    account: propertyInvoiceBank?.bank_details?.id,
    document: getDocument(),
  }), [propertyInvoiceBank, getDocument]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await putOwnerPropertyInvoiceBank(
          {
            bank_details: values.account || propertyInvoiceBank?.bank_details?.bank?.id || 0,
            invoice_details: propertyInvoiceBank?.invoice_details?.id || 0,
          },
          propertyInvoiceBank.id || 0,
        );
        setShowSaveButton(false);
        toast.success('Conta do imóvel atualizada com sucesso! As mudanças de conta bancária realizadas após o dia 30 do mês só serão utilizadas para repasses dos meses posteriores');
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  function handleCancel() {
    formik.setValues({ ...initialValues });
    setShowSaveButton(false);
  }

  const ButtonCancel = () => (
    <Button type="button" onClick={handleCancel}>
      <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="0.5" width="40" height="40" rx="10" stroke="#394760" />
        <path d="M28.281 26.7198C28.3507 26.7895 28.406 26.8722 28.4437 26.9632C28.4814 27.0543 28.5008 27.1519 28.5008 27.2504C28.5008 27.349 28.4814 27.4465 28.4437 27.5376C28.406 27.6286 28.3507 27.7114 28.281 27.781C28.2114 27.8507 28.1286 27.906 28.0376 27.9437C27.9465 27.9814 27.849 28.0008 27.7504 28.0008C27.6519 28.0008 27.5543 27.9814 27.4632 27.9437C27.3722 27.906 27.2895 27.8507 27.2198 27.781L21.0004 21.5607L14.781 27.781C14.6403 27.9218 14.4494 28.0008 14.2504 28.0008C14.0514 28.0008 13.8605 27.9218 13.7198 27.781C13.5791 27.6403 13.5 27.4494 13.5 27.2504C13.5 27.0514 13.5791 26.8605 13.7198 26.7198L19.9401 20.5004L13.7198 14.281C13.5791 14.1403 13.5 13.9494 13.5 13.7504C13.5 13.5514 13.5791 13.3605 13.7198 13.2198C13.8605 13.0791 14.0514 13 14.2504 13C14.4494 13 14.6403 13.0791 14.781 13.2198L21.0004 19.4401L27.2198 13.2198C27.3605 13.0791 27.5514 13 27.7504 13C27.9494 13 28.1403 13.0791 28.281 13.2198C28.4218 13.3605 28.5008 13.5514 28.5008 13.7504C28.5008 13.9494 28.4218 14.1403 28.281 14.281L22.0607 20.5004L28.281 26.7198Z" fill="#394760" />
      </svg>
    </Button>
  );

  const ButtonSave = () => (
    <Button
      type="button"
      data-cy="button-save-property"
      onClick={() => formik.handleSubmit()}
      disabled={formik.isSubmitting}
    >
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="40" height="40" rx="10" fill="#0D4BD0" />
        <path d="M29.531 15.781L17.531 27.781C17.4614 27.8508 17.3787 27.9061 17.2876 27.9438C17.1966 27.9816 17.099 28.001 17.0004 28.001C16.9019 28.001 16.8043 27.9816 16.7132 27.9438C16.6222 27.9061 16.5394 27.8508 16.4698 27.781L11.2198 22.531C11.0791 22.3903 11 22.1994 11 22.0004C11 21.8014 11.0791 21.6105 11.2198 21.4698C11.3605 21.3291 11.5514 21.25 11.7504 21.25C11.9494 21.25 12.1403 21.3291 12.281 21.4698L17.0004 26.1901L28.4698 14.7198C28.6105 14.5791 28.8014 14.5 29.0004 14.5C29.1994 14.5 29.3903 14.5791 29.531 14.7198C29.6718 14.8605 29.7508 15.0514 29.7508 15.2504C29.7508 15.4494 29.6718 15.6403 29.531 15.781Z" fill="white" />
      </svg>
    </Button>
  );

  const accountSelected = useMemo(() => {
    const accountFiltered = listBank.find((bank) => `${bank.id}` === `${formik.values.account}`);
    return accountFiltered;
  }, [formik.values.account]);

  return (
    <Container
      onSubmit={formik.handleSubmit}
      data-testid={testid}
      isEditing={showSaveButton}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <Content className="content-property" activeBorder>
        <Content>
          <TextBold>{code}</TextBold>
          <Text>{`Imóvel em ${neighborhood}`}</Text>
        </Content>

        {showSaveButton && isMobile && (
          <ContentButton>
            <ButtonCancel />
            <ButtonSave />
          </ContentButton>
        )}
      </Content>

      <Content activeBorder>
        {isMobile ? (
          <>
            <TextLight>Nota para:</TextLight>
            <Text>{formik.values?.document ? `${formik.values.document}` : ''}</Text>
          </>
        ) : (
          <>
            <Text>Nota para:</Text>
            <TextLight>{formik.values?.document ? `${formik.values.document}` : ''}</TextLight>
          </>
        )}
      </Content>

      <Content>
        <DefaultBadgeContainer>
          {isMobile && accountSelected && accountSelected.is_default && (
            <DefaultBadge>
              <p>Principal</p>
            </DefaultBadge>
          )}
          <SimpleSelect
            id="account"
            label="Conta"
            options={(listBank || []).map<SelectOption>(({ id, bank, is_default }) => ({
              value: id,
              valueLabel: is_default ? 'Principal' : `${(bank as Bank)?.bank_number} - ${(bank as Bank)?.short_name}`,
            }))}
            formik={formik}
            onChange={(item) => (!['', 'undefined'].includes(`${item.target.value}`) ? setShowSaveButton(true) : setShowSaveButton(false))}
            firstValue={(propertyInvoiceBank.bank_details?.id || 0).toString()}
            defaultSelected={propertyInvoiceBank.bank_details?.id ? `${propertyInvoiceBank.bank_details?.id}` : undefined}
            dataCy={`account-select-${propertyInvoiceBank.id}`}
            className="inputLabel"
          />
        </DefaultBadgeContainer>
      </Content>

      {showSaveButton && !isMobile && (
        <ContentButton>
          <ButtonCancel />
          <ButtonSave />
        </ContentButton>
      )}
    </Container>
  );
};

export default CardPropertyBankAccount;
