import styled, { keyframes } from 'styled-components';
import {
  Accordion,
  AccordionDetails,
} from '@mui/material';

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const ContainerGroup = styled(Accordion)`
  && {
    background: ${({ theme }) => theme.palette.grey._100.hex()};
    border-right: solid 1px ${({ theme }) => theme.palette.grey._400.hex()};
    &.Mui-expanded, &.MuiAccordion-rounded:last-child {
      margin: 0;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: none !important;
    }
  }
`;

export const CalendarGroup = styled.div`
  background: ${({ theme }) => theme.palette.grey._100.hex()};
  border-bottom: solid 1px ${({ theme }) => theme.palette.grey._400.hex()};
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0 10px;
`;

export const CalendarContainerRow = styled.div`
  display: flex;
  height: 61px;
`;

export const CalendarContainerCol = styled.div<{ borderActive: boolean, weekend: boolean }>`
  height: auto;
  width: 110px;
  border-right: ${({ theme, borderActive }) => (borderActive ? 'unset' : `1px dashed ${theme.palette.grey._440.hex()}`)};
  background: ${({ theme, weekend }) => (weekend ? theme.palette.white._400.hex() : theme.palette.white.main.hex())};
  border-bottom: solid 1px ${({ theme }) => theme.palette.grey._400.hex()};
  padding:  10px 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:last-child {
    border-right: none;
  }
  &.selected {
    border: 1px dashed ${({ theme }) => theme.palette.green.main.hex()};
    p {
      color: ${({ theme }) => theme.palette.green.main.hex()};
    }
  }
  &::before {
    content: "";
    z-index: 110;
    background-color:  ${({ theme, borderActive }) => (borderActive ? theme.palette.orange._200.hex() : 'unset')};
    position: absolute;
    width: 3px;
    height: 100vh;
    margin: 0px 108px;
  }
  &:after {
    content: "";
    z-index: 110;
    background-color:  ${({ theme, borderActive }) => (borderActive ? theme.palette.orange._200.hex() : 'unset')};
    position: absolute;
    width: 3px;
    height: 100vh;
  }

  @media (max-width: 320px) {
    width: 98px;
  }
`;

export const ContainerProperty = styled(AccordionDetails)`
  && {
    padding: 0;
    display: block;
  }
`;

export const Price = styled.p`
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  margin-right: 30px;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.grey._450.hex()};
  animation-delay: .4s;
  animation-name: ${FadeInOpacity};
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;
`;

type BorderSelectionType = {
  rows: number,
  cols: number,
  groups: number,
};

export const BorderSelection = styled.div<BorderSelectionType>`
  border: 2px solid ${({ theme }) => theme.palette.blue._950.hex()};
  background: ${({ theme }) => theme.palette.blue._50.hex()} !important;
  z-index: 40;
  pointer-events: none;
  width: ${({ cols }) => cols * 110}px;
  height: ${({ rows, groups }) => (rows * 61) + (groups * 30)}px;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;

`;
