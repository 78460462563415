import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';

import { getInfosPipedrive } from '../../../services/Onboarding/request';
import { ErrorMessage } from '../../../utils/Messages';

import { useOnboardingPage } from '../../../hooks/useOnboarding/useOnboarding';
import { useOnboardingHandover } from '../../../context/OnboardingContext/OnboardingHandoverContext';
import { useToast } from '../../../context/ToastContext';
import { InsertPropertyCategoryLocationProvider } from '../../../context/InsertPropertyCategoryLocationContext/InsertPropertyCategoryLocationContext';

import CardOwner from './CardOwner';
import CardPropertyInformation from './CardPropertyInformation';
import CardDeploymentFeeValues from './CardDeploymentFeeValues';
import CardFinish from './CardFinish';
import TextField from '../../TextField';
import FormButton from '../../FormButton';

import {
  CardContainer,
  Content,
  CircleLoading,
  CardLoading,
  ContentShowCards,
  Section,
  Title,
  Form,
  ButtonSyncPipedrive,
  TextFieldSyncPipedrive,
  Divider,
} from './styles';

export default function OnboardingCard() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const {
    setIsOpenModal,
    isOwnerCard,
    isPropertyInformationCard,
    isDeploymentFeeValuesCard,
    isFinishCard,
    listOwner,
    loadingOwner,
    handleClickPhase,
  } = useOnboardingPage();

  const {
    setInfosPipeDrive,
    dealID,
    setDealID,
    setResetFormik,
    setOwner,
  } = useOnboardingHandover();

  const validation = Yup.object().shape({
    dealID: Yup.string().required(),
  });

  const ownerAlreadyExists = (personId: string) => {
    const ownerFiltered = listOwner.filter((item) => `${item?.pipedrive_person_id}` === `${personId}`);
    return ownerFiltered.length !== 0 ? ownerFiltered?.[0] : null;
  };

  const formik = useFormik({
    initialValues: { dealID },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const result = await getInfosPipedrive(values.dealID);
        setInfosPipeDrive(result);
        setDealID(values.dealID);
        setLoading(false);
        
        const ownerExists = ownerAlreadyExists(`${result?.owner_infos?.owner_person_id}`);

        if (ownerExists) {
          setOwner(ownerExists);
          handleClickPhase(1);
        } else {
          setIsOpenModal(true);
        }
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          if (e?.response) {
            if (e?.response?.status === 404) {
              toast.error('DEAL ID não encontrado');
            } else {
              toast.error(e.response.data || ErrorMessage.default());
            }
          }
        } else {
          toast.error(ErrorMessage.default());
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const handleOpenModal = () => {
    setResetFormik(true);
    setIsOpenModal(true);
  };
  
  const handleSubmit = () => {
    setResetFormik(true);
    formik.handleSubmit();
  };

  return (
    <CardContainer>
      <Content>
        {isOwnerCard && (
          <Section>
            <Title>Adicionar Proprietário</Title>
            <Form onSubmit={formik.handleSubmit} isShowingErrorMessage={!formik.errors.dealID} className="first-form">
              <TextFieldSyncPipedrive>
                <TextField
                  formik={formik}
                  id="dealID"
                  label="Sincronizar Código Pipedrive"
                  placeholder="DEAL ID:"
                  labelClassName="field-deal"
                  value={formik.values.dealID}
                  disabled={loading || loadingOwner}
                />
              </TextFieldSyncPipedrive>
              <ButtonSyncPipedrive>
                <FormButton
                  dataCy="btn-pull-pipedrive"
                  type="button"
                  customClassName="button-deal"
                  disable={loading || loadingOwner}
                  onClick={() => handleSubmit()}
                >
                  {loading ? 'Carregando' : 'Sincronizar'}
                </FormButton>
              </ButtonSyncPipedrive>
              <Divider />
              <ButtonSyncPipedrive>
                <FormButton
                  dataCy="btn-pull-pipedrive"
                  type="button"
                  variant="outlined"
                  customColor="blue"
                  disable={loading}
                  onClick={() => handleOpenModal()}
                >
                  Adicionar manualmente
                </FormButton>
              </ButtonSyncPipedrive>
            </Form>
          </Section>
        )}

        {loading ? (
          <CardLoading>
            <CircleLoading size="120px" />
            <p>Estamos carregando os dados do pipedrive, aguarde um instante.</p>
          </CardLoading>
        ) : (
          <ContentShowCards>
              <>
                <div className={`steps ${isOwnerCard ? 'active' : ''}`}><CardOwner /></div>
                <div className={`steps ${isPropertyInformationCard ? 'active' : ''}`}>
                  <InsertPropertyCategoryLocationProvider>
                    <CardPropertyInformation />
                  </InsertPropertyCategoryLocationProvider>
                </div>
                <div className={`steps ${isDeploymentFeeValuesCard ? 'active' : ''}`}><CardDeploymentFeeValues /></div>
                {isFinishCard && (
                  <CardFinish />
                )}
              </>
          </ContentShowCards>
        )}
      </Content>
    </CardContainer>
  );
}
