export const Arrow = () => (
  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M4.58062 6.58041L0.869141 2.45551L2.72422 0.391602L8.29079 6.58041L2.72422 12.7692L0.869141 10.7053L4.58062 6.58041Z" fill="#F9F9F9" />
  </svg>
);

export const WhatsApp = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 2050">
      <path id="Rectangle 1804" d="M0 12.234C0 5.47737 5.47737 0 12.234 0H12.766C19.5226 0 25 5.47737 25 12.234C25 18.9907 19.5226 24.4681 12.766 24.4681H12.234C5.47737 24.4681 0 18.9907 0 12.234Z" fill="#58AB92" />
      <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M15.7619 13.9182C15.5827 13.8288 14.7042 13.3981 14.5407 13.3381C14.3771 13.2787 14.2581 13.2493 14.1384 13.4281C14.0194 13.6057 13.6772 14.0076 13.5732 14.1264C13.4686 14.2458 13.3646 14.2602 13.186 14.1714C13.0074 14.0814 12.4314 13.8936 11.7489 13.2865C11.218 12.8138 10.859 12.2301 10.755 12.0513C10.651 11.8732 10.7436 11.7766 10.8332 11.6878C10.9137 11.608 11.0117 11.4796 11.1013 11.3758C11.1909 11.2715 11.2204 11.1971 11.2799 11.0777C11.34 10.9589 11.31 10.8551 11.2649 10.7657C11.2204 10.6764 10.8632 9.7987 10.7141 9.44176C10.5692 9.09442 10.4219 9.14181 10.3125 9.13581C10.2078 9.13101 10.0888 9.12981 9.96972 9.12981C9.85067 9.12981 9.65705 9.1742 9.4935 9.35297C9.32935 9.53114 8.86817 9.96247 8.86817 10.8401C8.86817 11.7172 9.50793 12.5649 9.59753 12.6842C9.68712 12.803 10.8572 14.6039 12.6496 15.376C13.0766 15.5596 13.4091 15.6694 13.6682 15.7509C14.0963 15.8871 14.486 15.8679 14.7938 15.8217C15.1366 15.7707 15.8509 15.3904 16 14.9741C16.1485 14.5577 16.1485 14.2008 16.104 14.1264C16.0595 14.052 15.9405 14.0076 15.7613 13.9182H15.7619ZM12.5017 18.3593H12.4993C11.4347 18.3595 10.3896 18.074 9.47366 17.5327L9.2572 17.4043L7.00719 17.9934L7.60788 15.8049L7.46657 15.5806C6.87139 14.6353 6.55641 13.5414 6.55803 12.4251C6.55924 9.15561 9.22533 6.49564 12.5041 6.49564C14.0915 6.49564 15.5839 7.11354 16.7059 8.23415C17.2593 8.78401 17.6979 9.4379 17.9964 10.158C18.2949 10.8781 18.4473 11.65 18.4448 12.4293C18.4436 15.6987 15.7775 18.3593 12.5017 18.3593ZM17.5597 7.38289C16.8972 6.71755 16.109 6.19001 15.2406 5.83082C14.3723 5.47164 13.4411 5.28796 12.5011 5.29043C8.56031 5.29043 5.35186 8.49091 5.35066 12.4245C5.34883 13.6763 5.67798 14.9065 6.30489 15.9909L5.29053 19.6881L9.08102 18.6959C10.1296 19.2658 11.3046 19.5645 12.4987 19.5645H12.5017C16.4425 19.5645 19.651 16.364 19.6522 12.4299C19.6551 11.4924 19.4717 10.5636 19.1125 9.69734C18.7533 8.83104 18.2256 8.04439 17.5597 7.38289Z" fill="white" />
    </g>
  </svg>
);
