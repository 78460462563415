import { FC } from 'react';
import {
  Container,
  Content,
  Logo,
  LogoContainer,
  Username,
  Margin,
} from './styles';

import logoSeazone from '../../../assets/icons/generals/logoBlack.svg';
import { ProfileLogout } from '../../Header/Header';
import { useUser } from '../../../context/UserContext';
import BackButton from '../../OwnerPage/Header/BackButton/BackButton';

interface HeaderMobileProps {
  isOwnerPropertyDetails?: boolean;
}

const HeaderMobile: FC<HeaderMobileProps> = ({ isOwnerPropertyDetails = false }) => {
  const { userInformation } = useUser();
  const nickname = `${userInformation?.nickname || ''}`;

  return (
    <>
      <Container>
        {isOwnerPropertyDetails ? (
          <LogoContainer>
            <BackButton mobile />
            <Logo src={logoSeazone} alt="logo" isOwnerPropertyDetails />
          </LogoContainer>
        ) : <Logo src={logoSeazone} alt="logo" />}

        <Content>
          <Username>
            {nickname}
          </Username>
          <ProfileLogout useOnlyArrow={false} color="#151B26" />
        </Content>
      </Container>
      <Margin />
    </>
  );
};

export default HeaderMobile;
