import { useMemo } from 'react';

import { UserInformation } from '../../../../../services/User/types';
import { changeRequiredActions } from '../../../../../services/User/request';
import { patchPropertyAgreements } from '../../../../../services/Owner/OwnerServicesTerms/request';

import { useOwnerServicesTerms } from '../../../../../hooks/OwnerPage/OwnerServicesTerms/useOwnerServicesTerms';
import { useToast } from '../../../../../context/ToastContext';
import { useUser } from '../../../../../context/UserContext';
import { useGeolocation } from '../../../../../hooks/useGeolocation/useGeolocation';

import Backdrop from '../../../../Backdrop';
import Button from '../../../../FormButton';

import {
  Container,
  Header,
  Title,
  TermDescription,
  Text,
  Footer,
  PropertiesList,
  TwoSpaces,
  ButtonContainer,
} from '../styles';

const ConfirmationModal = () => {
  const {
    properties,
    openServicesTerms,
    setOpenServicesTerms,
    readAndAgreedTerms,
  } = useOwnerServicesTerms();

  const {
    latitude,
    longitude,
    IPAddress,
    browser,
    os,
  } = useGeolocation();

  const { userInformation, setUserInformation } = useUser();
  const toast = useToast();

  const ownerName = useMemo(() => properties?.[0]?.owner?.name || '', [properties]);

  if (!openServicesTerms.confirmationModal) return null;

  const handleSubmit = async () => {
    try {
      if (userInformation?.user_id) {
        await Promise.all(properties.map(async (item) => {
          const payload = {
            agreed: readAndAgreedTerms,
            owner_ip_address: IPAddress,
            owner_latitude: latitude,
            owner_longitude: longitude,
            owner_browser: browser,
            owner_os: os,
          };

          await patchPropertyAgreements(item.id, payload);
        }));

        const ownerActions: string[] = userInformation?.required_actions?.Owner || [];
        const response = await changeRequiredActions({
          user_id: userInformation.user_id,
          body: {
            Owner: ownerActions
              .filter((item) => item !== 'OWNER_HOUSEHOLD_LINEN_AGREEMENT_POPUP'),
          },
        });

        setUserInformation({
          ...userInformation,
          main_address: { ...userInformation?.main_address },
          host: { ...userInformation?.host },
          ...response.body,
        } as UserInformation);
      }

      setOpenServicesTerms({
        servicesTermsPopup: false,
        confirmationModal: false,
      });

      toast.success('Atualização foi feita com sucesso!');
    } catch {
      toast.error('Não foi possível realizar a atualização!');
    }
  };

  return (
    <Backdrop onClose={() => {}}>
      <Container
        onClick={(e) => e.stopPropagation()}
      >
        <Header>
          <Title data-testid="title">Confirmação de Atualização dos Termos de Serviços</Title>
        </Header>
        <TwoSpaces />

        <TermDescription>
          <div style={{ display: 'flex' }}>
            <Text data-cy="text-first-paragraph" data-testid="text-first-paragraph">
              {`Eu, ${ownerName} confirmo que li e`}
              <strong data-testid={`${readAndAgreedTerms ? 'text-accepted' : 'text-refused'}`} style={{ color: readAndAgreedTerms ? 'green' : 'red' }}>{`${readAndAgreedTerms ? ' aceito ' : ' recuso '}`}</strong>
              {'a atualização dos Termos de Serviços no ANEXO II - Diretrizes Referentes à Implantação do Imóvel (especificamente nos itens 6, 7 e 8), para o(s) imóvel(eis) listados a seguir:'}
            </Text>
          </div>
          <TwoSpaces />

          <PropertiesList>
            <ul>
              {properties.map((item) => (
                <li data-cy="property-code" data-testid="property-code" key={item.property.id}>{item.property.code}</li>
              ))}
            </ul>
          </PropertiesList>
        </TermDescription>
        <TwoSpaces />

        <Footer>
          <ButtonContainer>
            <Button dataCy="btn-send" onClick={() => handleSubmit()}>
              Enviar
            </Button>
            <Button
              dataCy="btn-comeback"
              variant="outlined"
              onClick={() => setOpenServicesTerms({
                servicesTermsPopup: true,
                confirmationModal: false,
              })}
            >
              Voltar
            </Button>
          </ButtonContainer>
        </Footer>
      </Container>
    </Backdrop>
  );
};

export default ConfirmationModal;
