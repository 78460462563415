import request from '../request';
import { PopupsProps } from '../../context/CustomPopupsContext/types';
import { PopupsResponseProps } from './types';

export const getPopups = async () => {
  const { data } = await request.get<PopupsResponseProps>('/popups/');

  const popups = data?.popups || [];

  const response: any = popups.map((item, index) => ({
    ...item,
    id: item?.id || index + 1,
    headline: item?.headline || '',
    content: item?.content || '',
    confirm_button_text: item?.confirm_button_text || '',
    consent_required: item?.consent_required || false,
    consent_description: item?.consent_description || '',
    consent_text: item?.consent_text || '',
    main_image: item?.image_url || '',
    type: item?.type || 'Default',
    popup_is_open: true,
  }));

  return response as PopupsProps;
};

export const postPopupsAck = async (popupId: number) => {
  await request.post('/popups/ack/', {
    popup_id: popupId,
  });
};
