const AddressInitialValues = {
  neighborhood: '',
  number: '',
  id: 0,
  city: '',
  complement: '',
  country: '',
  postal_code: '',
  state: '',
  street: '',
};

export const initialValues = [
  {
    idIndex: 1,
    code: '',
    price: 0,
    valuePerNigth: 0,
    totalValue: 0,
    totalNigth: 0,
    quantityGuest: 0,
    cleaningFee: 0,
    checkoutDate: '',
    propertyId: 0,
    checkinDate: '',
    address: AddressInitialValues,
    newProperty: true,
    category: '',
  },
];

export const [initialValuesWithowIdIndex] = initialValues.map((item) => {
  const { idIndex, ...rest } = item;
  return rest;
});
