import styled, { css } from 'styled-components';

export const Container = styled.div<{
  checked: boolean;
}>`
  height: 30px;
  width: 30px;
  display: flex;
  transition: 0.2s;
  background: #f2f2f2;
  border-radius: 100px;
  cursor: pointer;
  user-select: none;
  ${({ checked }) => checked
    && css`
      background: #00bfa6;
    `}
`;
