import React from 'react';

import { Description } from '@mui/icons-material';

import {
  Container,
  ReportButtonStyled,
  Content,
} from './styles';

const ReportButton = () => (
  <Container>
    <ReportButtonStyled>
      <Content>
        <Description htmlColor="#e35b4c" />
        <p>Relatórios</p>
      </Content>
    </ReportButtonStyled>
  </Container>
);

export default ReportButton;
