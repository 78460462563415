import styled from 'styled-components';
import { FormLabel, FormControlLabel, Radio } from '@mui/material';

export const Container = styled.div``;

export const FormLabelStylized = styled(FormLabel)`
  && {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const FormControlLabelStylized = styled(FormControlLabel)`
  && {
    color: ${({ theme }) => theme.palette.blue._950.hex()};

    span {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 17.5px;
    }
  }
`;

export const RadioStylized = styled(Radio)`
  && {
    & svg.MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.grey._450.hex()};
    }

    & svg.MuiSvgIcon-root.PrivateRadioButtonIcon-layer-6 {
      color: ${({ theme }) => theme.palette.blue._400.hex()};
    }

    &.MuiRadio-colorSecondary.Mui-checked {
      & svg.MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.blue._400.hex()};
      }
    }
    
    svg {
      color: ${({ theme }) => theme.palette.blue._400.hex()} !important;
    }
  }
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const Label = styled.h1`
  color: ${({ theme }) => theme.palette.blue._950.hex()};
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 8px;
`;
