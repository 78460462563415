import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 10px;
  padding: 16px;
  position: relative;

  width: 100%;
  height: auto;

  @media (max-width: 900px) {
    margin-bottom: 50px;
    padding: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  margin-top: 24px;
`;

export const WrapperModal = styled.div`
  width: 100%;
  height: auto;
`;
