import { motion } from 'framer-motion';
import React from 'react';
import { Container } from './style';

const variants = {
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    scale: 0,
    y: 10,
  },
};

interface IPortfolioProps {
  active: boolean;
  text: string;
  children: React.ReactNode;
  delay: number;
}

const Portfolio = ({
  active, text, children, delay = 0,
}: IPortfolioProps) => {
  const width = window.innerWidth;
  if (width <= 320) {
    return (
      <Container
        exit="hidden"
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{
          delay: delay * 0.1,
          type: 'spring',
        }}
        active={active}
      >
        {children}

        {active && (
        <motion.h1
          animate={{
            y: 0,
          }}
          initial={{
            y: 10,
          }}
          exit={{
            y: 10,
          }}
          transition={{
            delay: !active ? 1.75 : 0,
          }}
        >
          {text}
        </motion.h1>
        )}
      </Container>
    );
  }

  return (
    <Container
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{
        delay: delay * 0.1,
        type: 'spring',
      }}
      active={active}
    >
      {children}

      <motion.h1
        animate={{
          opacity: 1,
          y: 0,
        }}
        initial={{
          opacity: 0,
          y: 10,
        }}
        transition={{
          delay: 1.75 + delay * 0.1,
        }}
      >
        {text}
      </motion.h1>
    </Container>
  );
};

export default Portfolio;
