/* eslint-disable no-restricted-globals */
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useManagementPanel } from '../../../hooks/useManagementPanel/useManagementPanel';
import { putImportStaysReservation, putImportStaysReservations } from '../../../services/ManagementPanel/request';
import { PutImportStaysReservationData } from '../../../services/ManagementPanel/types';
import { compareList } from '../../../utils/Sorting';
import DatePicker from '../../DatePicker';
import FormButton from '../../FormButton';
import SimpleSelect from '../../SimpleSelect';
import TextField from '../../TextField';
import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';

import {
  CircularProgressContainer,
  FormButtonContainer,
  ImportStaysReservationModalBackdrop,
  ImportStaysReservationModalContainer,
  ImportStaysReservationModalContent,
  TitleContainer,
} from './styles';

type ImportType = 'creationDate' | 'checkIn' | 'checkOut' | 'staysCode' | undefined;
interface ImportStaysReservationModalFormData {
  importBy: ImportType;
  checkInDate: Date | null;
  checkOutDate: Date | null;
  creationDate: Date | null;
  staysCode?: string;
}

const ImportStaysReservationModal = () => {
  const { handleCloseImportStaysReservationModal } = useManagementPanel();
  const [isFormButtonDisabled, setIsFormButtonDisabled] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  function padTo2Digits(number: number) {
    return number.toString().padStart(2, '0');
  }

  function formatDate(date?: Date | string) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return [
          current.getFullYear(),
          padTo2Digits(current.getMonth() + 1),
          padTo2Digits(current.getDate()),
        ].join('-');
      }
    }
    return undefined;
  }

  const formik = useFormik<ImportStaysReservationModalFormData>({
    initialValues: {
      importBy: undefined,
      checkInDate: null,
      checkOutDate: null,
      creationDate: null,
      staysCode: '',
    },
    onSubmit: async (values) => {
      event?.preventDefault();
      setIsLoadingSubmit(true);
      try {
        if (values.importBy === 'staysCode') {
          await putImportStaysReservation(values.staysCode || '');
        } else {
          let importStaysReservationObject: PutImportStaysReservationData = {
            date: '',
            type: 'creation',
          };
          switch (values.importBy) {
            case 'checkIn': {
              importStaysReservationObject = {
                type: 'arrival',
                date: formatDate(values.checkInDate || new Date()),
              };
              break;
            }
            case 'checkOut': {
              importStaysReservationObject = {
                type: 'departure',
                date: formatDate(values.checkOutDate || new Date()),
              };
              break;
            }
            default: {
              importStaysReservationObject = {
                type: 'creation',
                date: formatDate(values.creationDate || new Date()),
              };
              break;
            }
          }
          await putImportStaysReservations(importStaysReservationObject);
        }
        toast.success('Reservas importadas com sucesso!');
        handleCloseImportStaysReservationModal();
      } catch (err) {
        if (err instanceof Error) {
          toastErrorRequest('Não foi possível importar a reserva!');
        }
      } finally {
        setIsLoadingSubmit(false);
      }
    },
  });

  const isButtonDisabled = () => {
    switch (formik.values.importBy) {
      case 'creationDate': {
        if (formik.values.creationDate && formik.values.creationDate.toString().length !== 15) {
          return false;
        }
        return true;
      }
      case 'checkIn': {
        if (formik.values.checkInDate && formik.values.checkInDate.toString().length !== 15) {
          return false;
        }
        return true;
      }
      case 'checkOut': {
        if (formik.values.checkOutDate && formik.values.checkOutDate.toString().length !== 15) {
          return false;
        }
        return true;
      }
      case 'staysCode': {
        if (formik.values.staysCode && formik.values.staysCode.length > 0) {
          return false;
        }
        return true;
      }
      default:
        return true;
    }
  };

  useEffect(() => {
    setIsFormButtonDisabled(isButtonDisabled());
  }, [formik.values]);

  return (
    <>
      <ImportStaysReservationModalContainer onClick={handleCloseImportStaysReservationModal}>
        <ImportStaysReservationModalContent onClick={(e) => e.stopPropagation()}>
          <TitleContainer>
            <h1>Importar reservas da Stays</h1>
            <CloseIcon onClick={handleCloseImportStaysReservationModal} />
          </TitleContainer>
          {isLoadingSubmit
            ? (
              <CircularProgressContainer>
                <CircularProgress size={80} />
              </CircularProgressContainer>
            )
            : (
              <form onSubmit={formik.handleSubmit}>
                <SimpleSelect
                  id="importBy"
                  formik={formik}
                  label="Importar por:"
                  placeholder="Escolha uma opção..."
                  options={[
                    { value: 'creationDate', valueLabel: 'Data de Criação' },
                    { value: 'checkIn', valueLabel: 'Data de Check-in' },
                    { value: 'checkOut', valueLabel: 'Data de Check-out' },
                    { value: 'staysCode', valueLabel: 'Código de reserva da Stays' },
                  ].sort((firstType,
                    nextType) => compareList(firstType.valueLabel, nextType.valueLabel))}
                />
                {formik.values.importBy === 'checkIn' && (
                <DatePicker
                  id="checkInDate"
                  formik={formik}
                  label="Selecione a data de check-in da reserva"
                  viewsCustom={['day', 'month', 'year']}
                  required
                />
                )}
                {formik.values.importBy === 'checkOut' && (
                <DatePicker
                  id="checkOutDate"
                  formik={formik}
                  label="Selecione a data de check-out da reserva"
                  viewsCustom={['day', 'month', 'year']}
                  required
                />
                )}
                {formik.values.importBy === 'creationDate' && (
                <DatePicker
                  id="creationDate"
                  formik={formik}
                  label="Selecione a data de criação da reserva"
                  viewsCustom={['day', 'month', 'year']}
                  required
                />
                )}
                {formik.values.importBy === 'staysCode' && (
                <TextField
                  id="staysCode"
                  required
                  label="Digite o código de reserva da Stays"
                  formik={formik}
                />
                )}
                <FormButtonContainer>
                  <FormButton
                    disable={isFormButtonDisabled || isLoadingSubmit}
                  >
                    Importar
                  </FormButton>
                </FormButtonContainer>
              </form>
            )}
        </ImportStaysReservationModalContent>
      </ImportStaysReservationModalContainer>
      <ImportStaysReservationModalBackdrop onClick={handleCloseImportStaysReservationModal} />
    </>
  );
};

export default ImportStaysReservationModal;
