import { FC } from 'react';

import TooltipInfo from '../../../../OwnerPage/TooltipInfo';

import {
  Container,
  TitleContainer,
} from './styles';
import { Data } from '../Tag';

interface Props {
  title: string;
  data: Data
  transfer?: boolean;
  totalValue?: string;
  isVisible: boolean;
  hideTotal?: boolean;
  hideTooltip?: boolean;
  isCountGrid?: boolean
}

const Item: FC<Props> = ({
  title,
  data,
  totalValue = '-',
  isVisible = true,
  hideTotal = false,
  hideTooltip = false,
  isCountGrid,
}) => {
  const months = Array.from({ length: 12 }, (_, i) => `${i + 1}`);

  const getDescription: Record<string, string | JSX.Element> = {
    'Diárias do mês atual': 'Valor da comissão das diárias com estadia no mês que não possuem atraso no recebimento, somado aos ajustes financeiros positivos. São as diárias vendidas via Airbnb ou Website Seazone.',
    'Diárias do mês seguinte': 'Valor da comissão das diárias com estadia no mês mas que possuem atraso de um mês para o recebimento. São reservas vendidas via Booking, Expedia ou Decolar.',
    'Limpezas do mês atual': 'Valor das taxas de limpeza de reservas com check-out no mês que não possuem atraso no recebimento. São as diárias vendidas via Airbnb ou Website Seazone.',
    'Limpezas do mês seguinte': 'Valor das taxas de limpeza de reservas com check-out no mês mas que possuem atraso de um mês para o recebimento. São as diárias vendidas via Booking, Expedia ou Decolar.',
    'Diárias do mês atual ': 'Valor da comissão das diárias com estadia no mês que não possuem atraso no recebimento. São as diárias vendidas via Airbnb ou Website Seazone.',
    'Diárias do mês anterior': 'Valor total da comissão das  diárias com estadia no mês anterior com atraso de recebimento para o mês atual. Este valor representa o valor total da comissão das diárias que entraram via Booking, Expedia ou Decolar no mês anterior.',
    'Limpezas do mês atual ': 'Valor das taxas de limpeza de reservas com check-out no mês que não possuem atraso no recebimento. São as diárias vendidas via Airbnb ou Website Seazone.',
    'Limpezas do mês anterior': 'Valor das taxas de limpeza de reservas com check-out no mês anterior, com atraso de recebimento para o mês atual. São limpezas realizadas em imóveis com resevas que entraram via Booking, Expedia ou Decolar no mês anterior.',
    'Reembolsos pagos': 'Valores refererentes ao reembolso de despesas relacionados ao imóvel ou reserva, somado ao reembolso de despesas de onboarding do imóvel. Exemplo: limpeza de proprietário, troca de chuveiro, manutenções no imóvel.',
    Ajustes: 'Ajustes financeiros positivos referente a processos que fogem do padrão. Exemplo:  Diferença de taxa de limpeza por realocação, limpezas extras, diferenças no valor das diárias não repassadas.',
    'Taxa de franquia': 'Valor de abatimento da taxa de franquia. Valores futuros podem sofrer variações pois ainda não estão consolidados.',
    'Repasse (TED)': 'Valor da TED realizada. Este valor foi transferido no mês posterior ao mês de competência. Exemplo: Repasse de Janeiro foi transferido no mês de Fevereiro.',
    Saldo: 'Este valor reflete os créditos (diárias pagas, limpezas pagas) ou débitos pendentes no fechamento do mês. Não inclui os valores de "Diárias do mês seguinte" e "Limpezas do mês seguinte".',
  };

  const getDescriptionCount: Record<string, string | JSX.Element> = {
    'Diárias do mês atual': 'Total das diárias com estadia no mês que não possuem atraso no recebimento, somado às diárias do mês anterior que possuem atraso no recebimento.',
    'Diárias do mês seguinte': 'Total das diárias com estadia no mês mas que possuem atraso de um mês para o recebimento. São reservas vendidas via Booking, Expedia ou Decolar.',
    'Limpezas do mês atual': 'Total de limpezas de reservas com check-out no mês que não possuem atraso no recebimento e com check-out no mês passado mas possuem atraso no recebimento.',
    'Limpezas do mês seguinte': 'Total de limpezas de reservas com check-out no mês mas que possuem atraso de um mês para o recebimento. São as diárias vendidas via Booking, Expedia ou Decolar.',
  };

  return (
    <Container isVisible={isVisible}>
      <TitleContainer>
        <span>{title}</span>
        {!hideTooltip && (
          <TooltipInfo
            title={title === 'Saldo final' ? `${title} do período` : title}
            subtitle={isCountGrid ? getDescriptionCount[title] : getDescription[title]}
            marginRight="1.2rem"
          />
        )}
      </TitleContainer>

      <p className="spacing" />

      {months.map((item) => <p>{data[item]}</p>)}

      {!hideTotal && (
      <p style={{ fontWeight: 700 }}>{totalValue}</p>)}
    </Container>
  );
};

export default Item;
