import styled from 'styled-components';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

export const StyledLocalizationProvider = styled(LocalizationProvider)``;

export const Container = styled.div<{ hidden: Boolean, showOnlyModalPicker?: Boolean }>`
  && label {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    padding-bottom: 8px;
    display: ${({ showOnlyModalPicker }) => (showOnlyModalPicker ? 'none' : 'block')}
  }
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};

  .css-plngxy-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey._450.hex()} !important;
  }
`;

export const ContainerLabel = styled.div`
  display: flex;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;

export const StyledTextField = styled(TextField)<{
  showOnlyModalPicker?: Boolean,
}>`
  && {
    width: 100%;

    width: ${({ showOnlyModalPicker }) => showOnlyModalPicker && '0px'};
    height: ${({ showOnlyModalPicker }) => showOnlyModalPicker && '0px'};
    opacity: ${({ showOnlyModalPicker }) => showOnlyModalPicker && '0'};
    z-index: ${({ showOnlyModalPicker }) => showOnlyModalPicker && '-100'};
    background-color: #ffffff;
    border-radius: 10px;

    fieldset {
      border-color: ${({ theme }) => theme.palette.grey._450.hex()};
      border-radius: 10px;
      height: 45px;
    }

    &:hover fieldset,
    .Mui-focused fieldset,
    fieldset:focus,
    fieldset:active {
      border: solid 1px ${({ theme }) => theme.palette.green.main.hex()} !important;
    }

    &
      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      box-sizing: border-box;
      border-radius: 10px;
      display: ${({ showOnlyModalPicker }) => showOnlyModalPicker && 'none'};

      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.grey._720.hex()};
    }

    & ::placeholder {
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  & .filter {
    width: 20px !important;
  }

  & .date-picker-owner-report-custom {
    position: absolute;
    left: 10px;
    margin-left: 50px;
  }

  && {
    .MuiPickersCalendar-root {
      min-height: 245px;
    }

    & .MuiPickersCalendarHeader-labelItem {
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-size: 16px;
      font-weight: 700;
      color: ${({ theme }) => theme.palette.blue._950.hex()};
    }

    & .MuiSvgIcon-root.MuiPickersCalendarHeader-switchView {
      display: none;
    }
    & .MuiPickersCalendar-daysHeader {
      & span {
        font-family: Quicksand, sans-serif;
        font-style: normal;
        font-size: 16px;
        font-weight: 700;
        color: ${({ theme }) => theme.palette.grey._450.hex()};
      }
    }
    & .MuiPickersCalendar-week,
    & .MuiPickersCalendar-daysHeader {
      justify-content: space-around;
    }

    & .MuiPickersDay-root:hover {
      background-color: ${({ theme }) => theme.palette.orange._100.hex()};
      border-radius: 50%;
    }

    & .MuiPickersDay-root.Mui-selected {
      border: none;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        ${({ theme }) => theme.palette.red._400.hex()} 0%,
        ${({ theme }) => theme.palette.orange.main.hex()} 100%
      );
    }

    & .MuiPickersDay-dayLabel {
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      border-radius: 10px;
    }

    & .MuiPickersDay-today:not(.Mui-selected) {
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.orange._200.hex()};
      border: none;
    }

    & .MuiMonthPicker-root {
      width: 310px;
      padding: 8px;
      gap: 10px;
    }

    &.MuiPickersPopper-root {
      &.MuiPickersPopper-paper {
        margin-left: 10px;
      }
    }

    & .MuiPickersMonth-root {
      border: none;
      border-radius: 10px;
      font-weight: bold;
      transition: 0.2s;

      &:hover {
        background: ${({ theme }) => theme.palette.orange.main.hex()};
        color: ${({ theme }) => theme.palette.white.main.hex()};
      }
    }

    & .MuiPickersMonth-root.Mui-selected {
      color: ${({ theme }) => theme.palette.orange.main.hex()};

      &:hover {
        color: ${({ theme }) => theme.palette.white.main.hex()};
      }
    }
  }
`;
