import React from 'react';

import { BodyShimmer } from '../LoadingShimmer';
import { useNfList } from '../../../hooks/NfListHook/useNfList';

import {
  convertStringToStatusProps,
} from '../utils';

import Body from './Body';
import Header from './Header';
import NoDataFound from './NoDataFound';

import {
  Container,
  ShimmerContainer,
} from './styles';

const Grid = () => {
  const { listItems, loading } = useNfList();

  if (loading) {
    return (
      <ShimmerContainer>
        <BodyShimmer />
      </ShimmerContainer>
    );
  }

  if (listItems.length === 0) {
    return (
      <Container>
        <NoDataFound />
      </Container>
    );
  }

  return (
    <Container>
      <Header />

      {listItems.map((item) => (
        <Body
          id={item.id}
          key={item.id}
          status={convertStringToStatusProps(item.status)}
          name={item.name}
          nameOfNf={item.nameOfNf}
          host={item.host}
          commission={item.commission}
          cpf={item.cpf}
          cnpj={item.cnpj}
          address={item.address}
          email={item.email}
          address_number={item.address_number}
          complement={item.complement}
          district={item.district}
          city={item.city}
          state={item.state}
          postal_code={item.postal_code}
        />
      ))}
    </Container>
  );
};

export default Grid;
