import request from '../request';
import slackRequest from '../slackRequest';

import {
  OwnerEventsResponseProps,
  PropertyOwner,
  QueryParamsFilter,
  FinancialDataRecordType,
  BankDetails,
  OwnerInvoiceDetails,
  PropertyInvoiceBank,
  PropertyInvoiceBankAux,
  OwnerContact,
  OwnerType,
  SeazoneEnterpriseRequest,
  SeazoneEcosystemSlackRequest,
  PropertyOwnerPatchProps,
} from './types';
import { Mode } from '../../context/ViewMode/type';

import { getFinancialClosingDataFormatted } from './utils';

export const getPropertiesOwner = async (filters?: QueryParamsFilter | undefined,
  mode?: Mode): Promise<Array<PropertyOwner>> => {
  const userID = mode?.user_id || null;

  if (filters) {
    const params = {
      start_date: filters.start_date,
      end_date: filters.end_date,
      ordering: filters.ordering,
    };

    const { data } = await request.get('/properties/owner/', {
      params: {
        ...params,
        user_id: userID,
      },
    });
    return data;
  }

  const { data } = await request.get('/properties/owner/', {
    params: {
      user_id: userID,
    },
  });

  return data;
};

export const patchPropertiesOwner = async (propertyId: number,
  payload: PropertyOwnerPatchProps) => {
  const { data } = await request.patch(`/properties/owner/${propertyId}/`, {
    ...payload,
  });

  return data;
};

export const getFinancialClosing = async (
  propertyId: number | undefined,
  year: number,
  month_start: number,
  month_end: number,
  mode?: Mode | null,
) => {
  const userID = mode?.user_id || null;

  const params = {
    start_date: `${year}-${month_start <= 9 ? `0${month_start}` : month_start}`,
    end_date: `${year}-${month_end <= 9 ? `0${month_end}` : month_end}`,
  };

  const allParams = propertyId
    ? { property_id: propertyId, ...params }
    : { ...params };
  const { data } = await request.get('/financial_closing/', {
    params: {
      ...allParams,
      user_id: userID,
    },
  });

  if (propertyId) {
    return getFinancialClosingDataFormatted(
      propertyId, year, data,
    );
  }

  const properties: number[] = Object.keys(data).map((id) => Number(id));
  let dataAllProperties: FinancialDataRecordType = {};

  properties.forEach((id) => {
    dataAllProperties = {
      ...dataAllProperties,
      ...getFinancialClosingDataFormatted(
        id, year, data,
      ),
    };
  });

  return dataAllProperties;
};

export const getOwnerEvents = async () => {
  const { data } = await request.get('/property/owner_events/');
  return data as OwnerEventsResponseProps;
};

export const postOwnerBank = async (params: BankDetails) => {
  const { data } = await request.post('/financial/owner/bank_details/', params);
  return data;
};

export const patchOwnerBank = async (params: BankDetails, id: number) => {
  const { data } = await request.patch(`/financial/owner/bank_details/${id}/`,
    params);
  return data;
};

export const getOwnerBank = async (mode?: Mode | null) => {
  const userID = mode?.user_id || null;
  const { data } = await request.get('/financial/owner/bank_details/', {
    params: {
      user_id: userID,
    },
  });
  return data as BankDetails[];
};

export const DeleteOwnerBank = async (id: number) => {
  const { data } = await request.delete(`/financial/owner/bank_details/${id}/`);
  return data;
};

export const getOwnerInvoice = async (mode?: Mode | null) => {
  const userID = mode?.user_id || null;
  const { data } = await request.get('/financial/owner/invoice_details/', {
    params: {
      user_id: userID,
    },
  });
  return data as OwnerInvoiceDetails[];
};

export const postOwnerInvoice = async (params: OwnerInvoiceDetails) => {
  const { data } = await request.post('/financial/owner/invoice_details/',
    params);
  return data;
};

export const patchOwnerInvoice = async (params: OwnerInvoiceDetails,
  id: number) => {
  const { data } = await request.patch(`/financial/owner/invoice_details/${id}/`,
    params);
  return data;
};

export const DeleteOwnerInvoice = async (id: number) => {
  const { data } = await request.delete(`/financial/owner/invoice_details/${id}/`);
  return data;
};

export const getOwnerPropertyInvoiceBank = async (mode?: Mode) => {
  const userID = mode?.user_id || null;
  const { data } = await request.get('/property/bank_invoice_details/', {
    params: {
      user_id: userID,
    },
  });
  return data as PropertyInvoiceBank[];
};

export const putOwnerPropertyInvoiceBank = async (params: PropertyInvoiceBankAux,
  id: number) => {
  const { data } = await request.patch(`/property/bank_invoice_details/${id}/`,
    {
      bank_details: params?.bank_details === 0 ? undefined : params?.bank_details,
      invoice_details:
        params.invoice_details === 0 ? undefined : params?.invoice_details,
    });
  return data;
};

type GetOwnerContacts = {
  mode: Mode | null
};
export const getOwnerContact = async ({ mode }: GetOwnerContacts) => {
  const userID = mode?.user_id || null;

  const { data } = await request.get('/account/owner_contacts/', {
    params: {
      user_id: userID,
    },
  });
  return data as OwnerContact[];
};

export const postOwnerContact = async (params: OwnerContact) => {
  const { data } = await request.post('/account/owner_contacts/', params);
  return data;
};

export const patchOwnerContact = async (params: OwnerContact, id: number) => {
  const { data } = await request.patch(`/account/owner_contacts/${id}/`, params);
  return data;
};

export const deleteOwnerContact = async (id: number) => {
  const { data } = await request.delete(`/account/owner_contacts/${id}/`);
  return data;
};

export const postOwner = async (params: OwnerType) => {
  const { data } = await request.post('/account/owner/', params);
  return data;
};

export const deleteOwner = async (id: number) => {
  const { data } = await request.delete(`/account/owner/${id}`);
  return data;
};

export const postSeazoneEnterprise = async ({
  corporateName,
  email,
  mainRole,
  phoneNumber1,
  tradingName,
  userName,
}: SeazoneEnterpriseRequest) => {
  const { data } = await slackRequest.post('',
    JSON.stringify({
      text: `O cliente ${
        tradingName || userName
      } acabou de pedir para receber um contato da investimentos.\nDados de contato do proprietário:\n - Nome: ${
        tradingName || userName
      } \n - Nome comercial: ${corporateName} \n - Razão social: ${corporateName} \n - Telefone: ${phoneNumber1}\n - Email: ${email}\n - Função: ${mainRole}`,
      username: '[Sapron] Investimento em imóveis',
      icon_emoji: ':seazone:',
      channel: '#distribuição-leads-szi',
    }));
  return data;
};

export const postSeazoneEcosystemSlackRequest = async ({
  color,
  fallback,
  fields,
  icon_emoji,
  username,
}: SeazoneEcosystemSlackRequest) => {
  const { data } = await slackRequest.post('',
    JSON.stringify({
      fallback,
      color,
      username,
      icon_emoji,
      fields,
    }));
  return data;
};

export const getOwners = async () => {
  const { data } = await request.get('/account/owner/');
  return data;
};
