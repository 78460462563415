import axios from 'axios';
import request from '../request';
import {
  AddressRequest,
  AddressDetail,
  Country,
  State,
  ViaCepResponse,
  IBGEresponse,
} from './types';
import { countries } from './countries';
import viacepRequest from '../viacepRequest';
import ibgeRequestByUF from '../IBGErequest';

export const getStates = async (): Promise<State[]> => ([
  { id: 1, name: 'Acre', acronym: 'AC' },
  { id: 2, name: 'Alagoas', acronym: 'AL' },
  { id: 3, name: 'Amapá', acronym: 'AP' },
  { id: 4, name: 'Amazonas', acronym: 'AM' },
  { id: 5, name: 'Bahia', acronym: 'BA' },
  { id: 6, name: 'Ceará', acronym: 'CE' },
  { id: 7, name: 'Distrito Federal', acronym: 'DF' },
  { id: 8, name: 'Espírito Santo', acronym: 'ES' },
  { id: 9, name: 'Goiás', acronym: 'GO' },
  { id: 10, name: 'Maranhão', acronym: 'MA' },
  { id: 11, name: 'Mato Grosso', acronym: 'MT' },
  { id: 12, name: 'Mato Grosso do Sul', acronym: 'MS' },
  { id: 13, name: 'Minas Gerais', acronym: 'MG' },
  { id: 14, name: 'Pará', acronym: 'PA' },
  { id: 15, name: 'Paraíba', acronym: 'PB' },
  { id: 16, name: 'Paraná', acronym: 'PR' },
  { id: 17, name: 'Pernambuco', acronym: 'PE' },
  { id: 18, name: 'Piauí', acronym: 'PI' },
  { id: 19, name: 'Rio de Janeiro', acronym: 'RJ' },
  { id: 20, name: 'Rio Grande do Norte', acronym: 'RN' },
  { id: 21, name: 'Rio Grande do Sul', acronym: 'RS' },
  { id: 22, name: 'Rondônia', acronym: 'RO' },
  { id: 23, name: 'Roraima', acronym: 'RR' },
  { id: 24, name: 'Santa Catarina', acronym: 'SC' },
  { id: 25, name: 'São Paulo', acronym: 'SP' },
  { id: 26, name: 'Sergipe', acronym: 'SE' },
  { id: 27, name: 'Tocantins', acronym: 'TO' },
]);

export const getCountry = async (): Promise<Country[]> => countries;

export const getAddressByCep = async (cep: string): Promise<AddressRequest> => {
  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
  return data as AddressRequest;
};

export const COUNTRY_BRAZIL = {
  name: 'Brasil',
  acronym: 'BR',
};

export const getAddressById = async (id: number): Promise<AddressDetail> => {
  const { data } = await request.get(`/account/address/${id}/`);
  return data as AddressDetail;
};

export const putAddress = async (id: number, addressUpdated: AddressDetail) => {
  const { data } = await request.put(`/account/address/${id}/`, {
    ...addressUpdated,
  });
  return data as AddressDetail;
};

export const patchAddress = async (id: number, addressUpdated: AddressDetail) => {
  const { data } = await request.patch(`/account/address/${id}/`, {
    ...addressUpdated,
  });
  return data as AddressDetail;
};

export const postAddress = async (newAddress: AddressDetail) => {
  const { data } = await request.post('/account/address/', {
    ...newAddress,
  });
  return data as AddressDetail;
};

export const deleteAddress = async (id: number) => {
  await request.delete(`/account/address/${id}/`);
};

export const getViaCeps = async (cep: string): Promise<ViaCepResponse> => {
  const { data } = await viacepRequest.get(`/${cep}/json/`);
  return data as ViaCepResponse;
};

export const getCitiesByUF = async (UF: string):Promise<IBGEresponse[]> => {
  const { data } = await ibgeRequestByUF.get(`/estados/${UF}/distritos/`, {
    params: {
      orderBy: 'nome',
    },
  });
  return data as IBGEresponse[];
};
