import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 500px;
  height: calc(100vh - 120px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-areas:
    'form nav'
    'buttons nav'
    ;
`;

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const FormContainer = styled.div`
  grid-area: 'form';
  button{
    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.palette.red._400.hex()};
    font-weight: bold;
    cursor: pointer;
  }
`;

export const FormContent = styled.form`
  width: 100%;
  height: auto;
  margin: 1rem;

  animation-delay: .4s;
  animation-name: ${FadeInOpacity};
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;
`;

export const Wrapper = styled.div`
  line-height: 2rem;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  h2{
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    color: ${({ theme }) => theme.palette.orange._700.hex()}
  }
  div{
    display: flex;
    justify-content: space-between;
    text-align: left;

    margin: 1rem 0;
    line-height: 2.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  }
`;

export const DailysContainer = styled.div`
  flex-direction: column;
  div{
    line-height: 0.5rem;
    border: 0;
  }
`;

export const TransactionContainer = styled.div`
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 2fr 0.5fr 0.5fr;
  grid-template-areas: 'date value payment icon-download icon-more';

  .date, .value, .payment, .icon-download, .icon-more-horiz {
    line-height: 2.5rem;
    p {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
    }
  }
  .date{
    grid-area: 'date';
  }
  .value{
    grid-area: 'value';
  }
  .payment{
    grid-area: 'payment';
  }
  .icon-download{
    grid-area: 'icon-download';
  }
  .icon-more-horiz{
    grid-area: 'icon-more';
  }
  .icon-download, .icon-more-horiz{
    div:first-child{
      width: 20px;
      height: 40px;
    }
  }

  div > p:first-child{
    font-size: ${({ theme }) => theme.fonts.sizes.smaller - 0.2}rem;
  }

  svg {
    fill: ${({ theme }) => theme.palette.grey._600.hex()};
    width: 18px;
    height: auto;
    cursor: pointer;
  }

  .tooltip {
    display: none;
    position: relative;
  }

  .icon-download div:hover, .icon-more-horiz div:hover {
    .tooltip {
      display: block;
      text-align: center;
      position: absolute;
      width: 10rem;
      height: auto;
      background: ${({ theme }) => theme.palette.white.main.hex()};
      border: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
      border-radius: 3px;
      margin-right: 128px;
      margin-top: 25px;
      right: 0;
    }
  }
  .icon-more-horiz div:hover {
    .tooltip {
      margin-right: 90px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  grid-area: 'buttons';
  width: 100%;
  max-width: 500px;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:  ${({ theme }) => theme.palette.white._400.hex()};
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 4rem;

  button, svg{
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 900px){
      margin: 0;
      margin-right: 3px;
    }
  }
  @media (max-width: 320px) {
    padding-right: 2.5rem;
  }
`;

export const SmallText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
`;

export const SmallTextBold = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const MediumText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.regular}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
`;

export const MediumTextBold = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const ButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;

  padding-left: 4rem;
  button{
    color: ${({ theme }) => theme.palette.grey._720.hex()};
  }
  svg {
    fill: ${({ theme }) => theme.palette.grey._720.hex()};
  }

  @media (max-width: 900px){
    padding-left: 0;
  }
`;
