import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100vw;
    position: relative;
    top: -30px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 94vh;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  flex-direction: column;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    width: 95%;
    height: 63vh;
    border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
    margin: 16px;
    overflow-x: hidden;
  }
`;
