/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback, useMemo } from 'react';

import { createContext, useContextSelector } from 'use-context-selector';
import { IIndicationsStatus } from '../../../pages/Partners';
import { FileProps } from '../../FileContext/types';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export type FileTypes = 'photos' | 'documents';
export type OpenModalWithFilesProps = {
  is: boolean,
  uploadType: FileTypes,
};

interface IIndicateContextProps {
  activeFilter: IIndicationsStatus | undefined;
  setActiveFilter: SetState<IIndicationsStatus | undefined>;
  options: Questions;
  setOptions: SetState<Questions>
  propertyImages: FileProps[];
  propertyDocuments: FileProps[];
  setPropertyImages: SetState<FileProps[]>;
  setPropertyDocuments: SetState<FileProps[]>;
  openModalWithFiles: OpenModalWithFilesProps;
  setOpenModalWithFiles: SetState<OpenModalWithFilesProps>;
}

interface Questions {
  doesThePropertyHaveFurniture: boolean;
  isThePropertyUnderConstruction: boolean;
  isTheOwnerAwareOfTheIndication: boolean;
  hasTheOwnerAlreadyReceivedTheEBook: boolean;
  IsIndicatedWithinSeazoneOperationArea: boolean
}

export type KeyQuestions = keyof Questions;

function construirObjeto<T>(): { [K in keyof T]: false } {
  return new Proxy({} as { [K in keyof T]: false }, {
    get: () => false,
  });
}

const initialQuestions = construirObjeto<Questions>();

export const IndicateContext = createContext<IIndicateContextProps>({} as IIndicateContextProps);

export const IndicateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeFilter, setActiveFilter] = useState<IIndicationsStatus | undefined>(undefined);
  const [options, setOptions] = useState<Questions>({} as Questions);
  const [propertyImages, setPropertyImages] = useState<FileProps[]>([]);
  const [propertyDocuments, setPropertyDocuments] = useState<FileProps[]>([]);
  const [openModalWithFiles, setOpenModalWithFiles] = useState<OpenModalWithFilesProps>({
    is: false,
    uploadType: 'photos',
  });

  const handleActiveFilter = useCallback((newActiveFilter) => {
    setActiveFilter(newActiveFilter);
  }, []);

  const value = useMemo(() => ({
    options,
    setOptions,
    activeFilter,
    setActiveFilter: handleActiveFilter,
    propertyImages,
    setPropertyImages,
    propertyDocuments,
    setPropertyDocuments,
    openModalWithFiles,
    setOpenModalWithFiles,
  }),
  [
    activeFilter,
    handleActiveFilter,
    options,
    setOptions,
    propertyImages,
    setPropertyImages,
    propertyDocuments,
    setPropertyDocuments,
    openModalWithFiles,
    setOpenModalWithFiles,
  ]);

  return (
    <IndicateContext.Provider value={value}>
      {children}
    </IndicateContext.Provider>
  );
};

export const usePartnerIndicate = () => {
  const activeFilter = useContextSelector(IndicateContext,
    (state) => state.activeFilter);
  const setActiveFilter = useContextSelector(IndicateContext,
    (state) => state.setActiveFilter);

  const options = useContextSelector(IndicateContext,
    (state) => state.options);
  const setOptions = useContextSelector(IndicateContext,
    (state) => state.setOptions);

  const propertyImages = useContextSelector(IndicateContext,
    (state) => state.propertyImages);
  const setPropertyImages = useContextSelector(IndicateContext,
    (state) => state.setPropertyImages);

  const propertyDocuments = useContextSelector(IndicateContext,
    (state) => state.propertyDocuments);
  const setPropertyDocuments = useContextSelector(IndicateContext,
    (state) => state.setPropertyDocuments);

  const openModalWithFiles = useContextSelector(IndicateContext,
    (state) => state.openModalWithFiles);
  const setOpenModalWithFiles = useContextSelector(IndicateContext,
    (state) => state.setOpenModalWithFiles);

  return {
    activeFilter,
    setActiveFilter,
    options,
    setOptions,
    propertyImages,
    setPropertyImages,
    propertyDocuments,
    setPropertyDocuments,
    openModalWithFiles,
    setOpenModalWithFiles,
  };
};
