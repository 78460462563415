/* eslint-disable max-len */
import {
  IPropertyIndication,
  IAllotmentIndication,
  IInvestmentIndication,
} from '../../pages/Partners/types';

import {
  Partner,
  RequestB2BCondominium,
  RequestPartner,
  UserPatch,
} from './types';

import request from '../request';
import { FileProps } from '../../context/FileContext/types';

export const getPartners = async (): Promise<RequestPartner[]> => {
  const { data } = await request.get('/account/partner/');
  return data;
};

export const getPartnerById = async (id: number): Promise<RequestPartner> => {
  const { data } = await request.get(`/account/partner/${id}/`);
  return data;
};

export const postPartner = async (params: Partner) => {
  const { data } = await request.post('/account/partner/', params);
  return data;
};

export const deletePartner = async (id: number) => {
  await request.delete(`/account/partner/${id}/`);
};

export const getPropretyIndication = async (): Promise<IPropertyIndication[]> => {
  const { data } = await request.get('/partners/indications/property/');
  return data;
};

export const getPropretyIndicationByID = async (id: number): Promise<IPropertyIndication[]> => {
  const { data } = await request.get(`/partners/indications/property/${id}`);
  return data;
};

export type IPropertyIndicatePost = Omit<IPropertyIndication, 'id' | 'created_at' | 'updated_at' | 'status_change_date'>;
export const postPropertyIndication = async (data: IPropertyIndicatePost): Promise<IPropertyIndication> => {
  const payload = {
    ...data,
    condominium_name: `${data.condominium_name}` === '-1' ? undefined : data.condominium_name,
  };
  const { data: response } = await request.post('/partners/indications/property/', payload);
  return response;
};

export const postPropertyIndicationFiles = async (indicationId: number, files: FileProps[]) => {
  if (files.length === 0) return [];

  const filesUids = files.map((item) => item.uid);
  const { data: response } = await request.post('/partners/indications/property_files/', {
    indication_id: indicationId,
    files: filesUids,
  });

  return response;
};

export const getAllotmentIndication = async (): Promise<IAllotmentIndication[]> => {
  const { data } = await request.get('/partners/indications/allotment/');
  return data;
};

export const getAllotmentIndicationByID = async (id: number): Promise<IAllotmentIndication[]> => {
  const { data } = await request.get(`/partners/indications/allotment/${id}`);
  return data;
};

export type IAllotmentIndicatePost = Omit<IAllotmentIndication, 'id' | 'created_at' | 'updated_at' | 'status_change_date'>;
export const postAllotmentIndication = async (data: IAllotmentIndicatePost): Promise<IAllotmentIndication> => {
  const { data: response } = await request.post('/partners/indications/allotment/', data);
  return response;
};

export const getInvestmentIndication = async (): Promise<IInvestmentIndication[]> => {
  const { data } = await request.get('/partners/indications/investment/');
  return data;
};
export const postAllotmentIndicationFiles = async (indicationId: number, files: FileProps[]) => {
  if (files.length === 0) return [];

  const filesUids = files.map((item) => item.uid);
  const { data: response } = await request.post('/partners/indications/allotment_files/', {
    indication_id: indicationId,
    files: filesUids,
  });

  return response;
};

export const postInvestmentIndication = async (data: IInvestmentIndication): Promise<IInvestmentIndication> => {
  const { data: response } = await request.post('/partners/indications/investment/', data);
  return response;
};

export const getB2BCondominium = async (): Promise<RequestB2BCondominium[]> => {
  const { data } = await request.get('/partners/b2b_condominium/');
  return data;
};

export const patchUser = async (id: number, data: UserPatch) => {
  await request.patch(`/account/user/${id}/`, {
    ...data,
  });
};
