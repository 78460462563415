import moment from 'moment';

export type OrderBy = 'asc' | 'desc';

export const compareList = (
  a: string | number,
  b: string | number,
  order: OrderBy = 'asc',
) => {
  if (order === 'asc') {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

export const compareDates = (
  a: string, // Format dd/mm/YYYY
  b: string, // Format dd/mm/YYYY
  order: OrderBy = 'asc',
) => {
  if (order === 'asc') {
    const newA = moment(a, 'DD/MM/YYYY');
    const newB = moment(b, 'DD/MM/YYYY');
    if (newA.isAfter(newB)) {
      return 1;
    }
    if (newA.isBefore(newB)) {
      return -1;
    }
    return 0;
  }

  const newA = moment(a, 'DD/MM/YYYY');
  const newB = moment(b, 'DD/MM/YYYY');
  if (newA.isAfter(newB)) {
    return -1;
  }
  if (newA.isBefore(newB)) {
    return 1;
  }
  return 0;
};

export const compareBoolean = (
  a: boolean,
  b: boolean,
  order: OrderBy = 'asc',
) => {
  const aValue = a ? 1 : 0;
  const bValue = b ? 1 : 0;

  if (order === 'asc') {
    return aValue - bValue;
  }
  return bValue - aValue;
};
