import styled from 'styled-components';
import { FormControl, TextField, TextareaAutosize } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    width: 12px;
    height: 12px;
    margin-left: -25px;
    z-index: 2;
    margin-top: 5px;
  }
`;

export const Arrow = styled.span`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.palette.grey._720.hex()};
`;

export const StyledFormControl = styled(FormControl)`
  margin-bottom: ${({ theme }) => theme.spacing.form};
  width: 100%;

  && label {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 8px;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      height: 40px;
      background: ${({ theme }) => theme.palette.white.main.hex()};
      box-sizing: border-box;
      border-radius: 10px;

      fieldset {
        border: 1px solid ${({ theme }) => theme.palette.grey._650.hex()};
      }

      &:hover fieldset {
        border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
      }

      &.Mui-focused fieldset {
        border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
      }

      @media (max-width: 1000px) {
        &:hover fieldset,
        &.Mui-focused fieldset {
          border: 1px solid ${({ theme }) => theme.palette.grey._650.hex()};
        }
      }
    }

    .MuiInputBase-root {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }

    .MuiInputBase-input {
      padding-right: 30px;
      cursor: pointer;
    }

    .MuiInputAdornment-root {
      color: ${({ theme }) => theme.palette.grey._560.hex()};
    }
  }
`;

export const StyledTextArea = styled(TextareaAutosize)`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border: none;
    display: block;
    background-color: transparent;
    overflow: hidden;
  }
  && {
    background: ${({ theme }) => theme.palette.white.main.hex()};
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
    padding: 10px 13px 10px 13px;
    resize: none;
    min-height: 120px !important;
    max-height: 120px;
    overflow-y: scroll !important;
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.5px;
    letter-spacing: 0.00938em;

    ::placeholder {
      color: ${({ theme }) => theme.palette.grey._720.hex()};
    }

    :focus,
    :active,
    :hover {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.palette.green._700.hex()};
    }
  }
`;

export const ErrorIcon = styled(ReportProblemOutlined)`
  && {
    margin-right: 3px;
    height: 16px;
  }
`;
