import styled from 'styled-components';

export const ManagementPanelContainer = styled.div`
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid black;
`;

export const CardContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 2rem;
`;
