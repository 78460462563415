import styled, { css } from 'styled-components';
import { IColor } from '../../../../types';

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Color = styled.div<{
  color: IColor;
}>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  transform: scale(0.8);

  ${({ color }) => color === 'concluded'
    && css`
      background-color: ${({ theme }) => theme.palette.green.main.hex()};
    `};

  ${({ color }) => color === 'not_concluded'
    && css`
      background-color: ${({ theme }) => theme.palette.red._330.hex()};
    `};

  ${({ color }) => color === 'not_defined'
    && css`
      background-color: ${({ theme }) => theme.palette.yellow.main.hex()};
    `};
`;
