import styled from 'styled-components';

export const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;

  min-width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  width: fit-content;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--blue-50, #D9D9D9);
    border-radius: 10px;
  }
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  padding: 30px 0;

  h1 {
    opacity: 0.6;
    font-size: 2rem;
  }

  p {
    opacity: 0.6;
    font-size: 1.2rem;
  }

  div {
    max-width: 500px;
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
