/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */

import React from 'react';
import { Container, StyledItem, Line } from './style';
import { tableConfig } from '../../config';
import { RowData } from '../../types';
import Tooltip from '../../../../../components/Tooltip';

interface IRow {
  row: RowData;
}

interface ItemProps {
  header: (typeof tableConfig.headers)[0];
  row: RowData;
  index: number;
  onClick?: () => void;
}

const ViewButton = ({
  header, row, index, onClick,
}: ItemProps) => (
  <StyledItem onClick={onClick} key={header.id} style={{ width: header.space }}>
    {index !== 0 && <Line />}
    <Tooltip text={`Ver o sistema como: ${row.name}.`}>
      <button type="button">
        {row[header.id] ? row[header.id] : 'Não informado'}
      </button>
    </Tooltip>
  </StyledItem>
);

const Item = ({
  header, row, index,
}: ItemProps) => {
  const handleClick = () => {
    if (row.userID) {
      window.location.href = `/proprietario?mode=view&user_id=${row.userID}`;
    }
  };

  if (header.id === 'actions') {
    return <ViewButton onClick={handleClick} header={header} row={row} index={index} />;
  }

  return (
    <StyledItem key={header.id} style={{ width: header.space }}>
      {index !== 0 && <Line />}
      {row[header.id] ? row[header.id] : 'Não informado'}
    </StyledItem>
  );
};

Item.defaultProps = {
  onClick: undefined,
};

const Row = ({ row }: IRow) => (
  <Container>
    {tableConfig.headers.map((header, index) => (
      <Item key={header.id} header={header} row={row} index={index} />
    ))}
  </Container>
);

export default Row;
