import React, {
  FC,
  ReactElement,
  useState,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';
import { Rules } from './types';
import { initialValuesRules } from './utils';

type PropertyRulesContextProps = {
  allRules: Rules[];
  setAllRules: (newAllRules: Rules[]) => void;
  handleUpdateRule: (ruleKey: string) => void;
};

export const PropertyRulesContext = createContext<PropertyRulesContextProps>({
  allRules: initialValuesRules,
  setAllRules: () => {},
  handleUpdateRule: () => {},
});

export const PropertyRulesProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [allRules, setAllRules] = useState<Rules[]>(initialValuesRules);

  const handleAllRules = (newAllRules: Rules[]) => {
    setAllRules(newAllRules);
  };

  const handleUpdateRule = (ruleKey: string) => {
    setAllRules((prevState) => prevState.map((rule) => ({
      ...rule,
      value: rule.key === ruleKey ? !rule.value : rule.value,
    })));
  };

  const value = useMemo(() => ({
    allRules,
    setAllRules: handleAllRules,
    handleUpdateRule,
  }), [
    allRules,
    setAllRules,
    handleUpdateRule,
  ]);

  return (
    <PropertyRulesContext.Provider
      value={value}
    >
      {children}
    </PropertyRulesContext.Provider>
  );
};
