import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ItemLossFormContainer = styled(motion.div)`
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 1rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const FullDiv = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width: 900px) {
    grid-column-start: 1;
    grid-column-end: 2;
  }
`;
export const ButtonContainer = styled.div`
  margin-top: 1rem;
  button {
    width: 100%;
  }
`;
