import styled, { keyframes } from 'styled-components';

import { Button } from '@mui/material';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  display: none;
  margin-right: 1rem;

  @media (max-width: 1400px) {
    transform: scale(0.9);
  }
  @media (max-width: 1276px) {
    transform: scale(0.8);
  }

  @media (max-width: 1180px) {
    margin-right: 0!important;
  }
`;

export const Content = styled.div`
  display: flex;

  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
`;

export const ReportButtonStyled = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.palette.white._900.hex()};
    border-radius: 10px;
    padding: 7px 10px;
    width: 100%;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};

    margin-left: 1rem;
    opacity: 0;
    font-size: 10pt;
    animation-name: ${FadeAnimation};
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  &&:hover {
    border: 2px solid ${({ theme }) => theme.palette.orange._700.hex()};
    color: ${({ theme }) => theme.palette.orange._700.hex()};
  }
`;
