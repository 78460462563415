import React from 'react';

import { useParams } from 'react-router-dom';

import {
  Content,
  Container,
  CitysShimmer,
  ContainerLabel,
  ContentLabel,
  StarSymbol,
} from './style';

// import Options from './Options';
import { UseFormik } from '../../../../../../../../utils/Formik/types';
import { usePartners } from '../../../../../../../../context/Partners/Partner/PartnerContext';
import { ErrorWrapper, PropertyFormInputLabel } from '../../style';
import { PartnersCustomSelect } from '../../../../../../components';
import { EnumType } from '../../../../../../types';
import { actionsCitysByState, actionsStates } from '../../../../../../../../hooks/useIBGE/actionPlaces';
import { useIBGE } from '../../../../../../../../hooks/useIBGE/useIBGE';
import InfoIcon from '../icon/InfoIcon';

interface IStateSelectorProps {
  formik: UseFormik<any>;
  labelState?: string;
  labelCity?: string;
  required?: boolean;
  requireSymbolPosition?: 'left' | 'right';
  labelStateDescription?: JSX.Element;
  labelCityDescription?: JSX.Element;
}

const StateSelector = ({
  formik,
  labelState,
  labelCity,
  required,
  requireSymbolPosition,
  labelStateDescription,
  labelCityDescription,
}: IStateSelectorProps) => {
  const { type } = useParams();
  const { questions } = usePartners();

  const isOccupationArea = React.useMemo(() => {
    if (type === EnumType.location) {
      return Boolean(questions.IsIndicatedWithinSeazoneOperationArea || false);
    }

    return false;
  }, [questions, type]);

  const { citys, states } = useIBGE({
    uf: formik.values.state,
  });

  const actionStates = states.data?.filter(actionsStates);
  const actionCitys = actionsCitysByState(formik.values.state);

  const validateShowCitys = () => {
    if (citys.loading !== undefined && !citys.loading) {
      return true;
    }

    if (citys.loading === undefined) {
      return true;
    }

    return false;
  };

  return (
    <Container>
      <Content>
        {/* {type === EnumType.location && (
          <Options />
        )} */}

        {states.data && (
          <>
            <ErrorWrapper>
              <PropertyFormInputLabel>
                <ContainerLabel>
                  <ContentLabel>
                    {required && requireSymbolPosition === 'left' && <StarSymbol>*</StarSymbol>}
                    <label htmlFor={labelState}>{labelState}</label>
                    {required && requireSymbolPosition === 'right' && <StarSymbol>*</StarSymbol>}
                    {type === EnumType.terrain && (
                      <InfoIcon title="Um dos principais fatores que influenciam na probabilidade de compra do terreno é se ele se encontra dentro da área de abrangência da Seazone." />
                    )}
                  </ContentLabel>
                  {labelStateDescription && <div className="label-description">{labelStateDescription}</div>}
                </ContainerLabel>
              </PropertyFormInputLabel>
              <PartnersCustomSelect
                id={'state'}
                name="state"
                formik={formik}
                placeholder="Estado"
                option={(isOccupationArea ? actionStates : states.data)?.map((state) => ({
                  text: state?.nome,
                  id: state?.id,
                  sigla: state?.sigla,
                }))}
              />
            </ErrorWrapper>
          </>
        )}

        {validateShowCitys() && formik.values.state && citys && (
          <>
            <ErrorWrapper>
              <PropertyFormInputLabel>
                <ContainerLabel>
                  <ContentLabel>
                    {required && requireSymbolPosition === 'left' && <StarSymbol>*</StarSymbol>}
                    <label htmlFor={labelCity}>{labelCity}</label>
                    {required && requireSymbolPosition === 'right' && <StarSymbol>*</StarSymbol>}
                  </ContentLabel>
                  {labelCityDescription && <div className="label-description">{labelCityDescription}</div>}
                </ContainerLabel>
              </PropertyFormInputLabel>
              <PartnersCustomSelect
                name="city"
                id={'city'}
                formik={formik}
                placeholder="Cidade"
                option={(isOccupationArea ? actionCitys : citys.data)?.map((city) => ({
                  text: city?.nome,
                  sigla: city?.nome,
                  id: city?.id || city?.nome,
                }))}
              />
            </ErrorWrapper>
          </>
        )}

        {validateShowCitys() === false && (
          <CitysShimmer>
            <p>Carregando municipios</p>
          </CitysShimmer>
        )}
      </Content>
    </Container>
  );
};

StateSelector.defaultProps = {
  labelState: undefined,
  labelCity: undefined,
  required: false,
  requireSymbolPosition: 'right',
  labelStateDescription: undefined,
  labelCityDescription: undefined,
};

export default StateSelector;
