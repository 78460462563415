import React from 'react';

import entranceIcon from '../../../../assets/icons/ownerPage/entranceIcon.svg';
import payoutIcon from '../../../../assets/icons/ownerPage/payoutIcon.svg';
import walletIcon from '../../../../assets/icons/ownerPage/walletIcon.svg';

import {
  Container,
  Content,
  Icon,
  Title,
  ExpandMoreRoundedIcon,
  ExpandMoreRoundedContainer,
  Description,
} from './styles';

interface ITag {
  label?: string,
  disableDropIcon?: boolean,
  description?: string,
  setIsExpanded?: Function,
  stickTag?: boolean,
}

const Tag: React.FC <ITag> = ({
  label = 'Entradas',
  disableDropIcon = false,
  description = '',
  setIsExpanded = () => {},
  stickTag = false,
}) => {
  const TagIcon: Record <string, string> = {
    Entradas: entranceIcon,
    Saídas: payoutIcon,
    Carteira: walletIcon,
  };

  return (
    <Container customColor={label} stickTag={stickTag}>
      <Content>
        <Icon src={TagIcon[label]} />
        <Title>{label}</Title>
      </Content>
      {!disableDropIcon
        ? (
          <ExpandMoreRoundedContainer
            onClick={() => setIsExpanded((prevState:boolean) => !prevState)}
          >
            <ExpandMoreRoundedIcon />
          </ExpandMoreRoundedContainer>
        ) : (
          <Description>{description}</Description>
        )}
    </Container>
  );
};

export default Tag;
