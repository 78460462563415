import React, { useEffect } from 'react';
import { Container } from './styles';
import InsertChangePropertyHostDataPageComponent from '../../../components/InsertData/InsertChangePropertyHostData';
import { ChangePropertyHostProvider } from '../../../context/ChangePropertyHostContext/ChangePropertyHostContext';

const InsertChangePropertyHostDataPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Edição de Dados';
  }, []);

  return (
    <Container>
      <ChangePropertyHostProvider>
        <InsertChangePropertyHostDataPageComponent />
      </ChangePropertyHostProvider>
    </Container>
  );
};

export default InsertChangePropertyHostDataPage;
