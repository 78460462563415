import { motion } from 'framer-motion';
import React from 'react';
import { Container } from './style';

const variants = {
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    scale: 0,
    y: 10,
  },
};

interface IPanelProps {
  active: boolean;
  delay: number;
}

const Panel = ({
  active,
  delay,
}: IPanelProps) => {
  const width = window.innerWidth;

  return (
    <Container
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      transition={{
        type: 'spring',
      }}
      active={active}
    >
      <motion.svg
        whileTap={{
          scale: 1.2,
        }}
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.g
          id="Panel"
        >
          <motion.rect id="background" width="32" height="32" rx="10" fill="#394760" />
          <motion.g id="dots">
            <motion.path
              initial={{
                y: -1,
                scale: 0,
                opacity: 0,
              }}
              animate={{
                y: 0,
                scale: 1,
                opacity: 1,
              }}
              transition={{ delay: 1.2 + (delay * 0.1) }}
              id="dot3"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 8H9.57146C8.62469 8 7.85718 8.76751 7.85718 9.71429V23.4286C7.85718 24.3753 8.62469 25.1429 9.57146 25.1429H13C13.9468 25.1429 14.7143 24.3753 14.7143 23.4286V9.71429C14.7143 8.76751 13.9468 8 13 8Z"
            />
            <motion.path
              initial={{
                y: -1,
                scale: 0,
              }}
              animate={{
                y: 0,
                scale: 1,
              }}
              transition={{ delay: 1.3 }}
              id="dot2"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M23.2857 8H19.8571C18.9103 8 18.1428 8.76751 18.1428 9.71429V13.1429C18.1428 14.0896 18.9103 14.8571 19.8571 14.8571H23.2857C24.2325 14.8571 25 14.0896 25 13.1429V9.71429C25 8.76751 24.2325 8 23.2857 8Z"
            />
            <motion.path
              initial={{
                scale: 0,
                rotate: 360,
              }}
              animate={{
                scale: 1,
                rotate: 0,
              }}
              transition={{ delay: 1.5 }}
              id="dot1"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M23.2857 18.2858H19.8571C18.9103 18.2858 18.1428 19.0533 18.1428 20.0001V23.4286C18.1428 24.3754 18.9103 25.1429 19.8571 25.1429H23.2857C24.2325 25.1429 25 24.3754 25 23.4286V20.0001C25 19.0533 24.2325 18.2858 23.2857 18.2858Z"
            />
          </motion.g>
        </motion.g>
      </motion.svg>
      {
        width <= 320 ? (
          active && (
            <motion.h1
              animate={{
                y: 0,
              }}
              initial={{
                y: 10,
              }}
              transition={{
                delay: !active ? 1.75 : 0,
              }}
            >
              Home
            </motion.h1>
          )
        ) : (
          <motion.h1
            animate={{
              opacity: 1,
              y: 0,
            }}
            initial={{
              opacity: 0,
              y: 10,
            }}
            transition={{
              delay: 1.75 + delay * 0.1,
            }}
          >
            Home
          </motion.h1>
        )
      }

    </Container>
  );
};

export default Panel;
