import styled from 'styled-components';

export const Container = styled.div <{ isVisible:boolean }>`
  width: 100%;
  border-radius: 10px;
  display: ${({ isVisible }) => (isVisible ? 'grid' : 'none')};
  grid-template-columns: 210px 0vw repeat(13, 100px);
 
  @media(min-width: 1500px) and (max-width: 1706px){
    grid-template-columns: 230px 8vw repeat(13, 100px);
  }

  align-items: center;
  justify-content: center;
  height: 50px;
  z-index: 1;

  p{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 0.875rem;
    width: 100%;
    border-right: 1px solid ${({ theme }) => theme.palette.grey._340.hex()};

    &:last-child {
      border-right: none;
    }

    @media(max-width: 768px) {
      font-size: 1rem;
    }
  }

  span {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: 600;
    width: max-content;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    font-size: 0.875rem;

    position: sticky;
    position: -webkit-sticky;
    left: 0;
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  @media(min-width: 1500px) and (max-width: 1706px){
    padding-left: 1rem;
  }
`;
