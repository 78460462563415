import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin-bottom: 8px;

  div {
    display: flex;
  }

  a {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    color: ${({ theme }) => theme.palette.grey._900.hex()};
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    text-decoration: none;
  }

  img {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
`;
