import React from 'react';
import { HostTedList as TedListMainComponent } from '../../components/HostTedList';
import { CustomToastProvider } from '../../context/CustomToastContext';
import { HostTedListProvider } from '../../context/HostTedList/HostTedListContext';

import { Container } from './styles';

const HostTedList = () => (
  <HostTedListProvider>
    <Container>
      <CustomToastProvider>
        <TedListMainComponent />
      </CustomToastProvider>
    </Container>
  </HostTedListProvider>
);

export default HostTedList;
