import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ListContent = styled(motion.div)`
  gap: 15px;
  width: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
