import React from 'react';
import { Container } from './style';
import Header from './Header';
import Body from './Body';

const Table = () => (
  <Container>
    <Header />
    <Body />
  </Container>
);

export default Table;
