import styled from 'styled-components';

import { genericBorderRoundedMinWidth } from '../../utils/var.utils';

export const Container = styled.div`
  display: flex;
  background: #e0f1fb;
  justify-content: space-around;
  border-right: 1px solid #cccccc;
  min-width: ${genericBorderRoundedMinWidth};
`;

export const Content = styled.div<{ isOpen: boolean }>`
  width: 81%;
  display: flex;
  margin-left: ${({ isOpen }) => (isOpen && '-60px')};
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? 'space-between' : 'center')}!important;
`;
