import styled from 'styled-components';

export const FastDataForwardButtonContainer = styled.button`
  position: fixed;
  z-index: 501;
  width: 40px;
  height: 40px;
  top: 9vh;
  right: 2vw;
  border-radius:10px;
  border: none;
  color: white;
  background: linear-gradient(180deg, #EB6B57 0%, #CF4F44 100%);
  box-shadow: 0px 10px 14px rgba(234, 101, 83, 0.4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{

    opacity: 0.85;

  }

  @media (max-width: 900px) {
    top: 14vh;
    right: 5vw;
  }
`;
