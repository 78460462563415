import React, {
  FC,
  createContext,
  useContext,
  ReactElement,
  useState,
  useEffect,
} from 'react';

export interface CheckBoxType {
  isShowingPrice: boolean;
  isShowingStatus: boolean;
  isShowingCanceledReservations: boolean;
  isShowingCalendarRules: boolean;
  handleSetIsShowingPrice: Function;
  handleSetIsShowingStatus: Function;
  handleSetIsShowingCanceledReservations: Function;
  handleSetIsShowingCalendarRules: Function;
}

export const CheckBoxContext = createContext<CheckBoxType>({
  isShowingPrice: true,
  isShowingStatus: true,
  isShowingCanceledReservations: true,
  isShowingCalendarRules: true,
  handleSetIsShowingPrice: () => {},
  handleSetIsShowingStatus: () => {},
  handleSetIsShowingCanceledReservations: () => {},
  handleSetIsShowingCalendarRules: () => {},
});

export const CheckBoxProvider:FC<{ children: ReactElement | any }> = ({ children }) => {
  const [isShowingPrice, setIsShowingPrice] = useState<boolean>(true);
  const [isShowingStatus, setIsShowingStatus] = useState<boolean>(true);
  const [isShowingCalendarRules, setIsShowingCalendarRules] = useState<boolean>(true);
  const [
    isShowingCanceledReservations,
    setIsShowingCanceledReservations,
  ] = useState<boolean>(true);

  const loadIsShowing = async () => {
    const isShowingP = await localStorage.getItem('isShowingPriceData');
    const isShowingS = await localStorage.getItem('isShowingStatusData');
    const isShowingCR = await localStorage.getItem('isShowingCalendarRulesData');
    const isShowingCRes = await localStorage.getItem('isShowingCancReservationsData');

    if (isShowingP) {
      setIsShowingPrice(JSON.parse(isShowingP));
    }
    if (isShowingS) {
      setIsShowingStatus(JSON.parse(isShowingS));
    }
    if (isShowingCR) {
      setIsShowingCalendarRules(JSON.parse(isShowingCR));
    }
    if (isShowingCRes) {
      setIsShowingCanceledReservations(JSON.parse(isShowingCRes));
    }
  };

  useEffect(() => {
    loadIsShowing();
  }, []);

  const handleSetIsShowingPrice = async (isShowingPriceData: boolean) => {
    await localStorage.setItem('isShowingPriceData', JSON.stringify(isShowingPriceData));
    setIsShowingPrice(isShowingPriceData);
  };
  const handleSetIsShowingStatus = async (isShowingStatusData: boolean) => {
    await localStorage.setItem('isShowingStatusData', JSON.stringify(isShowingStatusData));
    setIsShowingStatus(isShowingStatusData);
  };
  const handleSetIsShowingCalendarRules = async (isShowingCalendarRulesData: boolean) => {
    await localStorage.setItem('isShowingCalendarRulesData', JSON.stringify(isShowingCalendarRulesData));
    setIsShowingCalendarRules(isShowingCalendarRulesData);
  };
  const handleSetIsShowingCanceledReservations = async (isShowingCancReservationsData: boolean) => {
    await localStorage.setItem('isShowingCancReservationsData', JSON.stringify(isShowingCancReservationsData));
    setIsShowingCanceledReservations(isShowingCancReservationsData);
  };
  return (
    <CheckBoxContext.Provider
      value={{
        isShowingPrice,
        isShowingStatus,
        isShowingCanceledReservations,
        isShowingCalendarRules,
        handleSetIsShowingPrice,
        handleSetIsShowingStatus,
        handleSetIsShowingCanceledReservations,
        handleSetIsShowingCalendarRules,
      }}
    >
      {children}
    </CheckBoxContext.Provider>
  );
};

export const useCheckBox = () => useContext(CheckBoxContext);
