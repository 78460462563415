import { useQuery } from 'react-query';
import { getPropertyManager } from '../../services/Property/Manager/request';
import { IPropertyManagerAPI } from '../../services/Property/Manager/types';

type IPropertyManager = (
  id?: number,
  order?: string
) => {
  loading: boolean;
  data: IPropertyManagerAPI[] | undefined,
  refetch: () => void;
};

const usePropertyManager: IPropertyManager = (id, order) => {
  const {
    data,
    isFetching: loading,
    refetch,
  } = useQuery(
    'property-manager', async () => getPropertyManager(id, order), {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  return { data, loading, refetch };
};

export default usePropertyManager;
