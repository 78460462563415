import styled from 'styled-components';
import {
  MAX_SIZE_MOBILE,
  buttonsWidthMobile,
  buttonsWidthDesktop,
  buttonsHeightDesktop,
} from '../../utils';

export type VariantOptions = 'primary' | 'secondary';

export const Container = styled.button<{
  variantForMobile: VariantOptions,
  variantForDesktop: VariantOptions,
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
  width: ${buttonsWidthDesktop}px;
  height: ${buttonsHeightDesktop}px;
  max-height: ${buttonsHeightDesktop}px;
  border-radius: 6px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  cursor: pointer;
  background: ${({ variantForDesktop, theme }) => (variantForDesktop === 'primary' ? theme.palette.blue._400.hex() : 'transparent')};
  border: ${({ variantForDesktop, theme }) => (variantForDesktop === 'primary' ? 'none' : `1px solid ${theme.palette.grey._425.hex()}`)};
  outline: none;
  
  p{
    color: ${({ variantForDesktop, theme }) => (variantForDesktop === 'primary' ? theme.palette.white.main.hex() : theme.palette.blue._400.hex())};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: 400;
    line-height: 22px;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    background: ${({ variantForMobile, theme }) => (variantForMobile === 'primary' ? theme.palette.blue._400.hex() : 'transparent')};
    width: 100%;
    max-width: ${buttonsWidthMobile}px;
    border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
    padding: 8px;

    p {
      display: none;
    }
  }
`;
