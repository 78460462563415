import { useEffect, useState } from 'react';

import {
  DamageResumeContainer,
  Title,
  TotalContainer,
  TotalText,
} from './styles';

import { DamageResumeTable } from './DamageResumeTable';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { compareList } from '../../../../../utils/Sorting';
import { GetGuestDamage } from '../../../../../services/GuestDamage/types';

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';
type Order = 'asc' | 'desc';

export const DamageResume = () => {
  const { guestDamagesToInsert, setGuestDamagesToInsert } = useGuestDamage();
  const [sortedBy, setSortedBy] = useState<GuestDamageSortedBy>('type');
  const [order, setOrder] = useState<Order>('asc');
  const [sortedGuestDamages,
    setSortedGuestDamages] = useState<GetGuestDamage[]>(guestDamagesToInsert);

  function handleSortGuestDamage(sortBy: GuestDamageSortedBy) {
    switch (sortBy) {
      case 'type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('type');
          const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
            a.damage_type, b.damage_type, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_type');
          const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
            a.item_type || '', b.item_type || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_name': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.item_name, b.item_name, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.item_name, b.item_name, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_name');
          const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
            a.item_name, b.item_name, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'resolution': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.resolution, b.resolution, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.resolution, b.resolution, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('resolution');
          const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
            a.resolution, b.resolution, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'refund_holder': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('refund_holder');
          const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
            a.refund_holder || '', b.refund_holder || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_quantity': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_quantity');
          const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
            a.item_quantity, b.item_quantity, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'price': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('price');
          const sortGuestDamage = guestDamagesToInsert.sort((a, b) => compareList(
            (a?.item_quantity * (a?.item_price || 0)) || '-',
            (b?.item_quantity * (b?.item_price || 0)) || '-',
            'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      default: {
        const sortedNegotiations = guestDamagesToInsert.sort((a, b) => compareList(
          a.damage_type, b.damage_type, 'asc',
        ));
        setSortedGuestDamages(sortedNegotiations);
        break;
      }
    }
  }

  useEffect(() => {
    handleSortGuestDamage('type');
  }, [guestDamagesToInsert]);

  const handleRemoveGuestDamageToInsert = (id: number | string) => {
    setGuestDamagesToInsert((oldValues) => {
      const filteredVales = oldValues.filter((value) => value.id !== id);
      return filteredVales;
    });
  };

  const calculateTotalValue = guestDamagesToInsert.reduce((total, item) => {
    const itemPrice = item.item_price || 0;
    const itemQuantity = item.item_quantity || 1;
    return total + itemPrice * itemQuantity;
  }, 0);

  return (
    <>
      {guestDamagesToInsert.length > 0 && (
        <DamageResumeContainer>
          <Title>Resumo de danos a serem lançados</Title>
          <DamageResumeTable
            guestDamagesToInsert={sortedGuestDamages}
            handleRemoveGuestDamageToInsert={handleRemoveGuestDamageToInsert}
            handleSortGuestDamage={handleSortGuestDamage}
          />
          <TotalContainer>
            <TotalText>
              <span>Total:</span>
              {numberToCurrency(calculateTotalValue)}
            </TotalText>
          </TotalContainer>
        </DamageResumeContainer>
      )}
    </>
  );
};
