import axios from 'axios';

export interface Contents {
  en: string;
}

export interface Heading {
  en: string;
}

export interface OneSignalType {
  include_external_user_ids: string[];
  channel_for_external_user_ids: string;
  app_id: string;
  contents: Contents;
  heading: Heading;
}

export const oneSignalRequest = axios.create({
  baseURL: 'https://onesignal.com/api/v1/',
});
const appId = '7409d890-6c9e-4399-8da9-9a8fd0ce6d55';

export const sendOneSignalPushNotificationByUserId = async (userId: string,
  content: string): Promise<void> => {
  oneSignalRequest.post(
    'notifications',
    {
      include_external_user_ids: [`${userId}`],
      channel_for_external_user_ids: 'push',
      app_id: appId,
      contents: {
        en: content,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_ONESIGNAL_REST_API_KEY}`,
      },
    },
  );
};

export const getOneSignalExternalUserId = async () => {
  const { data } = await oneSignalRequest.get('players', {
    params: {
      app_id: appId,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${process.env.REACT_APP_ONESIGNAL_REST_API_KEY}`,
    },
  });
  return data;
};
