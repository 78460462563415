import React from 'react';

import {
  WhatsApp,
} from '@mui/icons-material';

import Tooltip from '../../../../Tooltip';

import copyIcon from '../../../../../assets/icons/generals/copy.svg';

import {
  ProprietaryPhoneContainer,
  ProprietaryPhoneIcons,
} from './styles';

import { Value } from '../styles';
import { useToast } from '../../../../../context/ToastContext';

interface ProprietaryPhoneProps {
  proprietaryPhone: string;
}

const CopyIcon = () => (
  <Tooltip text="Copiar número">
    <img
      alt="copy"
      src={copyIcon}
      className="ProprietaryPhoneCopyIcon"
    />
  </Tooltip>
);

const ProprietaryPhone = ({
  proprietaryPhone,
}: ProprietaryPhoneProps) => {
  const toast = useToast();

  const handleCopyPhoneNumber = () => {
    try {
      navigator.clipboard.writeText(proprietaryPhone);
      toast.success('Número copiado para a área de transferência!');
    } catch (err) {
      toast.error('Não foi possível copiar o número!');
    }
    return null;
  };

  const formattedPhone = proprietaryPhone.replace(/[^\d]+/g, '');
  const handleSendWhatsAppMessage = () => {
    window.open(`https://api.whatsapp.com/send?phone=${formattedPhone}`, '_blank');
  };
  return (
    <ProprietaryPhoneContainer>
      <div>
        <Value>{proprietaryPhone}</Value>
      </div>

      <ProprietaryPhoneIcons>
        <button onClick={handleCopyPhoneNumber} type="button">
          <CopyIcon />
        </button>

        <Tooltip text="Ir para o WhatsApp">
          <button type="button" onClick={handleSendWhatsAppMessage}>
            <WhatsApp
              className="ProprietaryPhoneWhatsAppIcon"
              style={{ color: '#62656E' }}
            />
          </button>
        </Tooltip>

      </ProprietaryPhoneIcons>
    </ProprietaryPhoneContainer>
  );
};

export default ProprietaryPhone;
