/* eslint-disable max-len */
import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';

import Modal from './Modal';
import Tooltip from '../../../../../Tooltip';
import { PreCheckinButton, SvgContainer } from './style';
import { useToast } from '../../../../../../context/ToastContext';
import { preCheckinGenerateLink } from '../../../../../../services/PreCheckin';
// import preCheckinReception from '../../../../../../assets/icons/compReservation/precheckin_bell.svg';
import { IModal } from '../../../../../Precheckin/ConfirmationLinkSentModal/ConfirmationLinkSentModal';

interface IPreCheckin{
  reservationId: number;
  immobileCode: string | undefined;
  reservationCode: string | undefined;
  reservationDate: string | undefined;
  reservationStaff: string | undefined;
  isPrecheckinLinkSent: boolean;
  isPreCheckinFullFiled: boolean;
  isHotel: boolean;
}

const Icon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 10.9834C16 7.33673 13.5444 4.25472 10.2004 3.29813V1.81165C10.4516 1.63919 10.606 1.41917 10.606 1.14602C10.606 0.354375 9.33033 0 8.06638 0C6.80242 0 5.52678 0.354375 5.52678 1.14602C5.52678 1.46284 5.73346 1.70873 6.05974 1.89065V3.22999C2.5897 4.10118 0.0117188 7.24661 0.0117188 10.9834V11.5397H16V10.9834ZM1.14661 10.4271C1.39167 7.37929 3.63196 4.88698 6.55625 4.25751C7.02384 4.15681 7.50839 4.10202 8.00574 4.10202C8.50309 4.10202 8.98764 4.15681 9.45523 4.25751C12.3795 4.88726 14.6195 7.37929 14.8646 10.4271H1.14661ZM9.25912 1.31848C8.96789 1.3958 8.56734 1.45755 8.06666 1.45755C7.56597 1.45755 7.16542 1.3958 6.87419 1.31848C6.66779 1.26368 6.51453 1.20137 6.42635 1.14602C6.63942 1.01306 7.21132 0.834478 8.06666 0.834478C8.922 0.834478 9.49417 1.01306 9.70668 1.14602C9.61878 1.20081 9.46552 1.2634 9.25912 1.31848ZM7.17265 2.43111H9.08806V3.06476C8.73396 3.01664 8.37319 2.98938 8.00574 2.98938C7.72452 2.98938 7.44664 3.0044 7.17265 3.03277V2.43111Z" fill="#0D4BD0" />
    <path d="M10.0442 4.87964C9.88477 4.80426 9.71815 4.76587 9.5507 4.76587C9.02248 4.76587 8.53403 5.1667 8.4147 5.69826C8.30789 6.17419 8.53236 6.62036 8.98659 6.8351C10.2052 7.41089 10.924 8.05705 11.3162 8.92936C11.4875 9.31016 11.823 9.53797 12.2144 9.53797C12.5929 9.53797 12.9793 9.31934 13.1976 8.98109C13.4127 8.64869 13.4424 8.24926 13.2786 7.88543C12.7184 6.64011 11.6302 5.62872 10.0442 4.87964ZM12.497 8.52797C12.4221 8.64341 12.2981 8.70377 12.2138 8.70377C12.1843 8.70377 12.1292 8.70377 12.0772 8.5875C11.4625 7.22062 10.2928 6.52996 9.34264 6.08101C9.23833 6.03177 9.20773 5.97753 9.22943 5.88157C9.25919 5.7461 9.39632 5.6009 9.5507 5.6009C9.59437 5.6009 9.64055 5.61231 9.68811 5.63484C11.0889 6.29658 12.0408 7.16889 12.517 8.22784C12.5651 8.3341 12.5584 8.43256 12.497 8.52797Z" fill="#0D4BD0" />
    <path className="aaa" d="M15.9942 12.116H0V13.2286H15.9942V12.116Z" fill="#0D4BD0" />
  </svg>
);

const PreCheckin = ({
  immobileCode,
  reservationId,
  reservationCode,
  reservationDate,
  reservationStaff,
  isPrecheckinLinkSent,
  isPreCheckinFullFiled,
  isHotel,
}: IPreCheckin) => {
  const toast = useToast();
  const [modal, setModal] = useState<IModal>({} as IModal);
  const [loading, setLoading] = useState({ preCheckin: false });

  function getTooltipText() {
    if (isPreCheckinFullFiled && isPrecheckinLinkSent) {
      return 'O hóspede preencheu o pré-checkin';
    }
    if (isPrecheckinLinkSent) {
      return 'Link de pré-checkin enviado !';
    }
    return 'Gerar link para o pré checkin';
  }

  async function handleGeneratePreCheckinLink() {
    try {
      setLoading((old) => ({ ...old, preCheckin: true }));
      const response = await preCheckinGenerateLink({
        reservation: reservationId,
      });
      setModal((prev) => ({
        ...prev,
        open: true,
        token: response.link,
        isHotel,
        reservation: {
          code: immobileCode,
          id: reservationCode,
          reservationID: reservationId,
          owner: reservationStaff,
          check_in: reservationDate,
        },
      }));
    } catch {
      toast.error('Ocorreu um erro ao gerar o link');
    } finally {
      setLoading((old) => ({ ...old, preCheckin: false }));
    }
  }

  return (
    <>
      <Modal modal={modal} setModal={setModal} />
      <PreCheckinButton>
        {loading.preCheckin ? (
          <CircularProgress size={18} />
        ) : (
          <Tooltip text={getTooltipText()}>
            <button
              onClick={() => handleGeneratePreCheckinLink()}
              type="button"
            >
              <SvgContainer isPreCheckinFullFiled={isPreCheckinFullFiled} isPrecheckinLinkSent={isPrecheckinLinkSent}>
                <Icon />
              </SvgContainer>
            </button>
          </Tooltip>
        )}
      </PreCheckinButton>
    </>
  );
};

export default PreCheckin;
