import React from 'react';

import Grid from './Grid';
import Header from './Header';
import NfListFab from './Fab/NfListFab';

import {
  useMobile,
} from '../../hooks/useMobile/useMobile';

import {
  useNfList,
} from '../../hooks/NfListHook/useNfList';

import {
  Container,
  AnimationBackdrop,
  AnimationContainer,
} from './styles';

const NfList = () => {
  const { isMobile } = useMobile();
  const { loadingFiles } = useNfList();

  const LoadingComponent = () => (
    <>
      <AnimationContainer>
        {/* <SearchFileAnimation /> */}
        {isMobile === false && (<h1>Estamos procurando seus arquivos ...</h1>)}
      </AnimationContainer>
      <AnimationBackdrop />
    </>
  );

  if (isMobile) {
    return (
      <Container>
        {loadingFiles && (<LoadingComponent />)}
        <NfListFab />
        <Grid />
      </Container>
    );
  }

  return (
    <Container>
      {loadingFiles && (<LoadingComponent />)}
      <Header />
      <Grid />
    </Container>
  );
};

export { NfList };
