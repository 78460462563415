import { useContextSelector } from 'use-context-selector';
import { financialFeedbackContext } from '../../context/FinancialFeedback/FinancialFeedbackContext';

export function useFinancialFeedback() {
  const cardList = useContextSelector(financialFeedbackContext, (state) => state.cardList);
  const handleCardList = useContextSelector(financialFeedbackContext, (state) => state.setCardList);

  const cardListAPI = useContextSelector(financialFeedbackContext,
    (state) => state.cardListAPI);
  const handleCardListAPI = useContextSelector(financialFeedbackContext,
    (state) => state.setCardListAPI);

  const filterOption = useContextSelector(financialFeedbackContext,
    (state) => state.filterOption);

  const handleFilterOption = useContextSelector(financialFeedbackContext,
    (state) => state.setFilterOption);

  const date = useContextSelector(financialFeedbackContext, (state) => state.date);
  const handleDate = useContextSelector(financialFeedbackContext, (state) => state.setDate);

  const loading = useContextSelector(financialFeedbackContext, (state) => state.loading);
  const handleLoading = useContextSelector(financialFeedbackContext, (state) => state.setLoading);

  return {
    cardList,
    handleCardList,
    date,
    handleDate,
    loading,
    handleLoading,
    cardListAPI,
    handleCardListAPI,
    filterOption,
    handleFilterOption,
  };
}
