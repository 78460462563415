import React, { FC, useContext } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import { ChevronRight, ChevronLeft } from '@mui/icons-material/';
import {
  Image,
  CardCounter,
  CardCounterItem,
  Container,
  Date,
  DateMonth,
  DateNumber,
  DateWeekDay,
  LastWeek,
  MobileContainer,
  NextWeek,
} from './styles';
import { HeaderConfigContext } from '../../../../context/ControllerPage/HeaderConfigContext';
import blockingIcon from '../../../../assets/icons/controll/bloqueio.svg';
import checkinIcon from '../../../../assets/icons/controll/checkin.svg';
import checkoutIcon from '../../../../assets/icons/controll/checkout.svg';
import { RequestEvent } from '../../../../services/Control/types';

type Props = {
  data?: RequestEvent[]
  dates: Moment[],
  handleNextWeek: () => void;
  handlePrevWeek: () => void;
  mobile: boolean
};

interface PropsNavigation {
  data?: RequestEvent[]
  day: Moment;
  index: number;
}

const ItemNavigation: FC<{ data?: RequestEvent[], date: Moment, index: number }> = ({
  data, date, index,
}) => {
  const {
    showCheckin,
    showCheckout,
  } = useContext(HeaderConfigContext);

  const weekendDay = date.format('dddd').split('-')[0];
  const weekendState = !!/sábado|sexta/.test(weekendDay);
  const validateDateToday: boolean = moment().isSame(date, 'day');

  function filterAndCountItems() {
    const formatDate = moment(date).format('YYYY-MM-DD');
    const filteredItems = (data || []).filter(
      (item: any) => item.date === formatDate);

    const checkinItems = filteredItems.filter((item) => item.type === 'checkin' && item.ota !== 'Blocking');
    const checkoutItems = filteredItems.filter((item) => item.type === 'checkout' && item.ota !== 'Blocking');
    const blockingItems = filteredItems.filter((item) => item.type !== 'cleaning' && item.ota === 'Blocking');

    const checkinConcluded = checkinItems.filter(
      (item: any) => item.checklist?.concluded).length;
    const checkoutConcluded = checkoutItems.filter(
      (item: any) => item.checklist?.concluded).length;
    const blockingConcluded = blockingItems.filter(
      (item: any) => item.checklist?.concluded).length;

    return {
      totalCheckin: checkinItems.length,
      totalCheckinConcluded: checkinConcluded,
      totalCheckout: checkoutItems.length,
      totalCheckoutConcluded: checkoutConcluded,
      totalBlocking: blockingItems.length,
      totalBlockingConcluded: blockingConcluded,
    };
  }

  const result = filterAndCountItems();
  return (
    <Date
      key={date.format('YYYY-MM-DD')}
      id={date.format('YYYY-MM-DD')}
      data-cy={`weekday_${date.format('YYYY-MM-DD')}`}
      className="dateItem"
      borderActive={validateDateToday}
      weekend={weekendState}
      showBackgroudColor={index % 2 === 0}
    >
      <div>
        <div>
          <DateNumber active={validateDateToday}>
            <p>
              {date.format('DD')}
            </p>
          </DateNumber>
          <DateMonth>
            {date.format('MMM')}
          </DateMonth>
        </div>
        <DateWeekDay weekend={weekendState}>
          {weekendDay}
        </DateWeekDay>
      </div>

      <CardCounter>
        <CardCounterItem>
          <Image src={checkinIcon} alt="checkinIcon" />
          <span>
            {result.totalCheckin === 0 ? 0 : `${result.totalCheckinConcluded}/${result.totalCheckin}`}
          </span>
        </CardCounterItem>
        <CardCounterItem>
          <Image src={checkoutIcon} alt="checkoutIcon" />
          <span>
            {result.totalCheckout === 0 ? 0 : `${result.totalCheckoutConcluded}/${result.totalCheckout}`}
          </span>
        </CardCounterItem>
        <CardCounterItem>
          <Image src={blockingIcon} alt="total" />
          <span>
            {result.totalBlocking === 0 ? 0 : `${result.totalBlockingConcluded}/${result.totalBlocking}`}
          </span>
        </CardCounterItem>
      </CardCounter>
    </Date>
  );
};

const NavigationDates: FC<PropsNavigation> = ({
  data,
  day,
  index,
}) => (
  <Container>
    <ItemNavigation
      data={data}
      date={day}
      index={index}
    />
  </Container>
);

const DatesGridHeader: FC<Props> = ({
  data,
  dates,
  handleNextWeek,
  handlePrevWeek,
  mobile,
}) => (
  <>
    {mobile ? (
      <MobileContainer showBackgroundColor>
        <LastWeek onClick={handlePrevWeek} className="lastWeek">
          <ChevronLeft />
        </LastWeek>
        {
          dates.map((date, index) => (
            <NavigationDates
              data={data}
              key={date.format('YYYY-MM-DD')}
              day={date}
              index={index}
            />
          ))
        }
        <NextWeek onClick={handleNextWeek} className="nextWeek">
          <ChevronRight />
        </NextWeek>
      </MobileContainer>
    ) : (
      <>
        {
          dates.map((date, index) => (
            <NavigationDates
              data={data}
              key={date.format('YYYY-MM-DD')}
              day={date}
              index={index}
            />
          ))
        }
      </>
    )}
  </>
);

export default DatesGridHeader;
