import styled from 'styled-components';

export const Container = styled.form`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  height: 100px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 25px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 900px) {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const FilterButton = styled.button`
  background-color: transparent;
  border: none;
  margin-right: 5px;
  cursor: pointer;
`;
