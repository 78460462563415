import React from 'react';

import { Tooltip as MaterialTooltip } from '@mui/material';

interface Props {
  text: string;
  children: JSX.Element;
  placement?: any;
  open?: boolean;
  zIndex?: number;
  whiteSpace?: string
}

const Tooltip: React.FC<Props> = ({
  text, placement, children, open, zIndex = 8877, whiteSpace,
}) => {
  if (text === '') {
    return <>{children}</>;
  }

  return (
    <MaterialTooltip
      arrow
      placement={placement}
      style={{ zIndex }}
      open={open}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: whiteSpace || 'normal',
          },
        },
      }}
      title={(
        <h1 style={{
          fontSize: '1rem',
          fontWeight: 600,
          fontFamily: 'Quicksand',
        }}
        >
          {text}
        </h1>
  )}
    >
      {children}
    </MaterialTooltip>
  );
};

export default Tooltip;
