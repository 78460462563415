import LateralModal from '../../LateralModal';
import FormTedFilters from './FormTedFilters';

import { useOwnerTedList } from '../../../hooks/OwnerTedListHook/useOwnerTedList';

import { Container } from './styles';

const Filters = () => {
  const { openModalFilters, handleOpenModalFilters } = useOwnerTedList();

  const handleCloseAll = () => {
    handleOpenModalFilters(false);
  };

  return (
    <Container open={openModalFilters}>
      <LateralModal
        open={openModalFilters}
        setOpen={handleOpenModalFilters}
        onClose={handleCloseAll}
        headerTitle="Filtros"
        anchor="right"
      >
        <FormTedFilters onClose={handleCloseAll} />
      </LateralModal>
    </Container>
  );
};

export default Filters;
