import styled from 'styled-components';

export const Form = styled.form``;

export const FormContent = styled.div<{ isVisible: boolean }>`
  padding-bottom: 8px;

  .inputLabel {
    font-family: ${({ theme }) => theme.fonts.familys._6} !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }

  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  .datePickerContainer {
    &:hover fieldset, .Mui-focused fieldset, fieldset:focus, fieldset:active {
      border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()} !important;
    }
  }

  input {
    width: 100% !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
`;
