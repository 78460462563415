import { motion } from 'framer-motion';
import styled from 'styled-components';

export const RefundDamageCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    &:hover {
      background: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  color: #394760;
  font-family: 'Inter';
`;

export const CardContainer = styled(motion.form)<{ noMobile?: boolean }>`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  padding: 2rem;

  @media(max-width: 900px) {
    display: ${({ noMobile }) => noMobile && 'none'};
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    &:hover {
      background: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }

  scrollbar-color: ${({ theme }) => theme.palette.blue._400.hex()} ${({ theme }) => theme.palette.grey._400.hex()};
`;
