import React, { useEffect } from 'react';
import { Container } from './styles';
import InsertPropertyDataComponent from '../../../components/InsertData/InsertPropertyData/InsertPropertyData';
import { InsertPropertyCategoryLocationProvider } from '../../../context/InsertPropertyCategoryLocationContext/InsertPropertyCategoryLocationContext';

const InsertPropertyDataPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <InsertPropertyCategoryLocationProvider>
        <InsertPropertyDataComponent />
      </InsertPropertyCategoryLocationProvider>
    </Container>
  );
};

export default InsertPropertyDataPage;
