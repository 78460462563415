import styled from 'styled-components';

export const CopyItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: row;
  gap: 2rem;
`;

export const CopyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  color: #394760;

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
    font-family: 'Inter';
  }

  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
