import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import moment from 'moment';

import { createContext } from 'use-context-selector';

import { OwnerExtractProps, PropertyProps } from './types';
import { initialValuesOwnerExtract } from './utils';

interface IOwnerExtract {
  openModalFinancialExtract: boolean,
  setOpenModalFinancialExtract: (open: boolean) => void,
  extractList: OwnerExtractProps[],
  setExtractList: Function,
  showExtractDate: boolean,
  setShowExtractDate: Function,
  extractGeneratedIn: string;
  setExtractGeneratedIn: Function;
  period: Date;
  setPeriod: Function;
  property: PropertyProps;
  setProperty: Function;
}

export const OwnerExtractContext = createContext<IOwnerExtract>({
  openModalFinancialExtract: false,
  setOpenModalFinancialExtract: (open: boolean) => open,
  extractList: [],
  setExtractList: () => {},
  showExtractDate: false,
  setShowExtractDate: () => {},
  extractGeneratedIn: `${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm:ss')}`,
  setExtractGeneratedIn: () => {},
  period: new Date(),
  setPeriod: () => {},
  property: { id: -1, code: '' },
  setProperty: () => {},
});

export const OwnerExtractProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [
    extractList,
    setExtractList,
  ] = useState<OwnerExtractProps[]>([initialValuesOwnerExtract]);

  const [openModalFinancialExtract, setOpenModalFinancialExtract] = useState<boolean>(false);
  const [showExtractDate, setShowExtractDate] = useState<boolean>(false);
  const [extractGeneratedIn, setExtractGeneratedIn] = useState<string>(`${moment().format('DD/MM/YYYY')} às ${moment().format('HH:mm:ss')}`);
  const [period, setPeriod] = useState<Date>(new Date());
  const [property, setProperty] = useState<PropertyProps>({ id: -1, code: '' });

  const handleSetOpenModalFinancialExtract = useCallback((open: boolean) => {
    setOpenModalFinancialExtract(open);
  }, []);

  const handleSetExtractList = useCallback((data: OwnerExtractProps[]) => {
    setExtractList([...data]);
  }, []);

  const handleSetShowExtractDate = useCallback((show: boolean) => {
    setShowExtractDate(show);
  }, []);

  const handleSetExtractGeneratedIn = useCallback((dateStr: string) => {
    setExtractGeneratedIn(dateStr);
  }, []);

  const handleSetPeriod = useCallback((date: Date) => {
    setPeriod(date);
  }, []);

  const handleSetProperty = useCallback((propertie: PropertyProps) => {
    setProperty(propertie);
  }, []);

  const value = useMemo(() => ({
    openModalFinancialExtract,
    setOpenModalFinancialExtract: handleSetOpenModalFinancialExtract,
    extractList,
    setExtractList: handleSetExtractList,
    showExtractDate,
    setShowExtractDate: handleSetShowExtractDate,
    extractGeneratedIn,
    setExtractGeneratedIn: handleSetExtractGeneratedIn,
    period,
    setPeriod: handleSetPeriod,
    property,
    setProperty: handleSetProperty,
  }), [
    openModalFinancialExtract,
    setOpenModalFinancialExtract,
    extractList,
    setExtractList,
    showExtractDate,
    setShowExtractDate,
    extractGeneratedIn,
    setExtractGeneratedIn,
    period,
    setPeriod,
    property,
    setProperty,
  ]);

  return (
    <OwnerExtractContext.Provider
      value={value}
    >
      {children}
    </OwnerExtractContext.Provider>
  );
};
