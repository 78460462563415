import { motion } from 'framer-motion';
import styled from 'styled-components';

interface IContainer {
  customMargin?: string;
}

export const Container = styled(motion.div)<IContainer>`
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  gap: 5px;

  transition: 1ms;

  flex-direction: column;
  align-items: center;
  margin-top: -30px;

  ${({ customMargin }) => customMargin && customMargin}
`;
