import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  height: 80px;
  padding: 11px 11px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;

  .button-csv {
    width: 150px;

    button {
      width: 100%;
    }
  }

  .buttons-rigth {
    display: flex;
    align-items: center;
  }

  .dates {
    width: 50%;
    display: flex;
    align-items: flex-end;
  }

  .date {
    width: 175px;
    margin-right: 40px;
  }

  .btn-filter {
    width: 121px;

    button {
      width: 100%;
    }
  }


`;

export const ButtonToReconcile = styled.button`
  width: 165px;
  height: 38px;
  background: ${({ theme }) => theme.palette.red._50.hex()};
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;

  letter-spacing: -0.003px;

  color: ${({ theme }) => theme.palette.red._600.hex()};
  cursor: pointer;

  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.palette.red._100.hex()};

    box-shadow:
    0px 0.2px 0.5px rgba(0, 0, 0, 0.029),
    0px 0.4px 1.1px rgba(0, 0, 0, 0.043),
    0px 0.8px 2.1px rgba(0, 0, 0, 0.053),
    0px 1.3px 3.8px rgba(0, 0, 0, 0.06),
    0px 2.5px 7.1px rgba(0, 0, 0, 0.065),
    0px 6px 17px rgba(0, 0, 0, 0.07)
  }
`;

export const ButtonConciliation = styled.button`
width: 165px;
height: 38px;
background: ${({ theme }) => theme.palette.grey._340.hex()};
border-radius: 10px;
border: none;
display: flex;
align-items: center;
justify-content: center;
margin-right: 12px;

font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 28px;

letter-spacing: -0.003px;

color: ${({ theme }) => theme.palette.blue._850.hex()};

transition: 0.2s;
  &:hover {
    background-color: #${({ theme }) => theme.palette.blue._20.hex()};

    box-shadow:
    0px 0.2px 0.5px rgba(0, 0, 0, 0.029),
    0px 0.4px 1.1px rgba(0, 0, 0, 0.043),
    0px 0.8px 2.1px rgba(0, 0, 0, 0.053),
    0px 1.3px 3.8px rgba(0, 0, 0, 0.06),
    0px 2.5px 7.1px rgba(0, 0, 0, 0.065),
    0px 6px 17px rgba(0, 0, 0, 0.07)
  }
`;
