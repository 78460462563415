import styled from 'styled-components';
import Menu from '@mui/material/Menu';

export const StyledMenu = styled(Menu)`
  && {
    top: 55px;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const ButtonContainer = styled.div`
  button {
    border: 2px solid ${({ theme }) => theme.palette.blue._850.hex()};
    border-radius: 5px;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.1rem;
    color: ${({ theme }) => theme.palette.blue._850.hex()};

    &:disabled {
      cursor: not-allowed;
    }
  }

  margin-right: 10px;

  .menu {
    position: absolute;
    top: 10;
  }
`;
