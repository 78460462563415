import React from 'react';
import { Container, MinimalContainer } from './style';
import { usePartners } from '../../../../context/Partners/Partner/PartnerContext';
import { data } from './data';

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
    },
  },

  hidden: {
    y: 10,
    opacity: 0,
  },
};

const Body = () => {
  const { questions, setConversion, conversion } = usePartners();

  const result = React.useMemo(() => data.find((item) => {
    const trueConditions = item.requireTrue || [];
    const falseConditions = item.requireFalse || [];

    const meetsTrueConditions = trueConditions.every((condition) => questions[condition]);
    const meetsFalseConditions = falseConditions.every((condition) => !questions[condition]);

    return meetsTrueConditions && meetsFalseConditions;
  }),
  [questions]);

  React.useEffect(() => {
    setConversion((old) => ({ ...old, progress: result?.progress || 20 }));
  }, [result]);

  if (conversion.isMaxBar) {
    return (
      <Container variants={variants} animate="visible" initial="hidden">
        <h1>{result?.title}</h1>
        <p>{result?.description}</p>
      </Container>
    );
  }

  return (
    <MinimalContainer variants={variants} animate="visible" initial="hidden">
      <h1>{result?.title}</h1>
    </MinimalContainer>
  );
};

export default Body;
