import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  max-width: 400px;
  height: auto;
  padding: 2rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  .labelClass {
    font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
    font-family: ${({ theme }) => theme.fonts.familys._3};
    font-size: 1rem;
  }
`;

export const FormContent = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100px;
  gap: 2rem;
  margin-left: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  width: 100%;
`;
