import Tooltip from '../../../../../../../../../components/Tooltip';

import { Link, Container } from './styles';

interface Props {
  activeBorder?: boolean,
  previewURL?: string,
}

const OpenNewWindow = ({ activeBorder = false, previewURL = '#' }: Props) => (
  <Tooltip text="Abrir em nova aba">
    <Link href={previewURL || '#'} target="_blank">
      <Container activeBorder={activeBorder}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V14M12 12L20 4M20 4V9M20 4H15" stroke="#001840" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </Container>
    </Link>
  </Tooltip>
);

OpenNewWindow.defaultProps = {
  activeBorder: false,
  previewURL: '#',
};

export default OpenNewWindow;
