import request from '../request';
import slackRequest from '../slackRequest';
import {
  PostUser, ResponseUser,
  RequestHostOpsInSlack,
} from './types';

const postRequestHostOpsInSlack = async ({
  color, fallback, fields, icon_emoji, username, channel,
}: RequestHostOpsInSlack) => {
  const { data } = await slackRequest.post('',
    JSON.stringify({
      channel,
      fallback,
      color,
      username,
      icon_emoji,
      fields,
    }));
  return data;
};

const postHostUser = async (user: PostUser) => {
  const createdUser = await request.post<ResponseUser>('/account/user/', user);
  return createdUser.data;
};

const postHostProfile = async (userId: number, HostId: number) => {
  await request.post('/account/host_profile/', {
    type: 'Ops',
    user: userId,
    host: HostId,
  });
};

export const handleRequestsCreateHostOpsModal = async (
  {
    color, fallback, fields, icon_emoji, username, channel,
  }: RequestHostOpsInSlack, user: PostUser, hostId: number,
) => {
  const [createdUser] = await Promise.all([
    await postHostUser(user),
    await postRequestHostOpsInSlack({
      channel,
      fallback,
      color,
      username,
      icon_emoji,
      fields,
    }),
  ]);
  await postHostProfile(createdUser.id, hostId);
};
