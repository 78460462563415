import { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import {
  Title,
  ValidationAndHistoryContainer,
} from './styles';
import RadioButton from '../../../components/RadioButton';
import TextField from '../../../../TextField/TextField';
import CopyItems from '../CopyItems';
import SimpleSelect, { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { UseFormik } from '../../../../../utils/Formik/types';
import { FileProps } from '../../../../../context/FileContext/types';
import { UploadInput } from '../../../components/UploadInput';

const validateOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

const guestDamageNegotiationPaymentStatusOptions: SelectOption[] = [
  { value: 'Solicitado', valueLabel: 'Solicitado' },
  { value: 'Disputa', valueLabel: 'Disputa' },
  { value: 'Pago', valueLabel: 'Pago' },
  { value: 'Finalizado sem sucesso', valueLabel: 'Finalizado sem sucesso' },
  { value: 'Pendente', valueLabel: 'Pendente' },
  { value: 'À pagar', valueLabel: 'À pagar' },
];

interface ValidationAndHistoryProps {
  formik?: UseFormik<any>;
  isToInformOwner: boolean;
  setIsToInformOwner: Dispatch<SetStateAction<boolean>>;
  guestPaymentReceipts: FileProps[]
  setGuestPaymentReceipts: Dispatch<SetStateAction<FileProps[]>>
}
export const ValidationAndHistory = ({
  formik,
  isToInformOwner,
  guestPaymentReceipts,
  setGuestPaymentReceipts,
  setIsToInformOwner,
}: ValidationAndHistoryProps) => {
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const { reservationGuestDamages } = useGuestDamage();
  const formattedCheckInDate = moment(selectedGuestDamageNegotiation.reservation?.check_in_date).format('DD/MM/YYYY');
  const formattedCheckOutDate = moment(selectedGuestDamageNegotiation.reservation?.check_out_date).format('DD/MM/YYYY');
  const allDamagedItens = reservationGuestDamages.reduce((damagedItens: string[], guestDamage) => {
    damagedItens.push(guestDamage.item_name);
    return damagedItens;
  }, []);
  const totalPrice = reservationGuestDamages.reduce((total, item) => {
    const itemPrice = item.item_price || 0;
    const itemQuantity = item.item_quantity || 1;
    return total + itemPrice * itemQuantity;
  }, 0);
  const formattedDamagedItens = allDamagedItens.join(', ');
  const formattedPrice = numberToCurrency(totalPrice);
  const copyItemsOptions = [
    {
      message: `
      Olá, ${(selectedGuestDamageNegotiation.reservation?.guest_name)}!

      Esperamos que a sua hospedagem com a Seazone com check-in ${formattedCheckInDate} e check-out
      ${formattedCheckOutDate} tenha sido de grande proveito!

      Infelizmente, nosso anfitrião notou que após a sua saída alguns itens haviam sido
      danificados: ${formattedDamagedItens}

      Isso acabou por gerar danos e consequentemente um pagamento no valor de ${formattedPrice} para reposição. Você pode acertar os valores através de envio de PIX para:

      Chave de CNPJ > 32018829000108
      SEAZONE SERVICOS LTDA
      CNPJ 32.018.829/0001-08
      Banco Inter (077)
      Agência 0001
      Conta 2038489-0

      *Caso prefira, também fizemos o envio da solicitação de pagamento via plataforma

      No mais, esperamos te receber novamente em nossas acomodações :)

      Até breve!
    `,
      text: 'Cobrança Airbnb',
    },
    {
      message: `
      Olá, ${(selectedGuestDamageNegotiation.reservation?.guest_name)}!

      Esperamos que a sua hospedagem com a Seazone com check-in ${formattedCheckInDate} e check-out
      ${formattedCheckOutDate} tenha sido de grande proveito!

      Infelizmente, nosso anfitrião notou que após a sua saída alguns itens haviam sido
      danificados: ${formattedDamagedItens}

      Isso acabou por gerar danos e consequentemente um pagamento no valor de ${formattedPrice} para reposição. Você pode acertar os valores através de envio de PIX para:

      Chave de CNPJ > 32018829000108
      SEAZONE SERVICOS LTDA
      CNPJ 32.018.829/0001-08
      Banco Inter (077)
      Agência 0001
      Conta 2038489-0

      No mais, esperamos te receber novamente em nossas acomodações :)

      Até breve!
      `,
      text: 'Cobrança Sirena/Booking',
    },
    {
      message: `
      Olá, ${(selectedGuestDamageNegotiation.reservation?.guest_name)}!

      Esperamos que a sua hospedagem com a Seazone com check-in ${formattedCheckInDate} e check-out
      ${formattedCheckOutDate} tenha sido de grande proveito!

      Infelizmente, nosso anfitrião notou que após a sua saída alguns itens haviam sido
      danificados: ${formattedDamagedItens}

      Isso acabou por gerar danos e consequentemente um pagamento no valor de ${formattedPrice} para reposição. Você pode acertar os valores através do link Paypal abaixo:

      (inserir o link Paypal)

      Pedimos que nos envie o comprovante após o pagamento.

      No mais, esperamos te receber novamente em nossas acomodações :)

      Até breve!
      `,
      text: 'Pagamento estrangeiro',
    },
    {
      message: `
      Olá, ${(selectedGuestDamageNegotiation.reservation?.guest_name)}! Aguardamos o pagamento no valor de ${formattedPrice} para reparo de
      danos. Você pode acertar através do PIX:

      Chave de CNPJ > 32018829000108
      SEAZONE SERVICOS LTDA
      CNPJ 32.018.829/0001-08
      Banco Inter (077)
      Agência 0001
      Conta 2038489-0

      Qualquer dúvida pode entrar em contato :) Até breve!
      `,
      text: 'Segunda Cobrança',
    },
    {
      message: `
      Olá, ${(selectedGuestDamageNegotiation.reservation?.guest_name)}! Boa tarde!

      Ainda não recebemos um retorno seu sobre os danos, caso tenha interesse em sanar
      a pendência, peço sua gentileza de encaminhar o Pix para a chave:

      Chave de CNPJ > 32018829000108
      SEAZONE SERVICOS LTDA
      CNPJ 32.018.829/0001-08
      Banco Inter (077)
      Agência 0001
      Conta 2038489-0

      *Você também pode utilizar a opção "Enviar dinheiro" pela sua página de reservas.

      Qualquer dúvida, basta nos chamar!
      `,
      text: 'Cobrança sem retorno',
    },
    {
      message: `
      Entendemos que não houve má intenção da sua parte, mas o prejuízo ocorreu durante
      a sua estada. Nesse caso gostaríamos de dividir o prejuízo em 50% do valor total para
      cada parte, ou seja, um pagamento no valor de ${formattedPrice}. Você pode acertar esse
      pagamento através do PIX:

      Chave de CNPJ > 32018829000108
      SEAZONE SERVICOS LTDA
      CNPJ 32.018.829/0001-08
      Banco Inter (077)
      Agência 0001
      Conta 2038489-0

      Esperamos que essa negociação seja a mais coerente.
      Até breve!
      `,
      text: 'Argumentação Booking/Stays/Expedia',
    },
    {
      message: `
      Olá, ${(selectedGuestDamageNegotiation.reservation?.guest_name)}! Como vai?

      Como não obtivemos seu retorno para finalização do processo, estamos acionando o
      seguro da plataforma Airbnb para nos auxiliar e para buscarmos solução para a
      questão.

      Qualquer dúvida permanecemos à disposição.

      Atenciosamente,

      Equipe Seazone
      `,
      text: 'Cobrança sendo enviada para o Aircover - sem contato',
    },
    {
      message: `
      ${(selectedGuestDamageNegotiation.reservation?.guest_name)}, como não conseguimos chegar a um acordo para finalização do processo,
      estamos acionando o seguro da plataforma Airbnb para nos auxiliar e para buscarmos
      solução para a questão.

      Qualquer dúvida permanecemos à disposição.

      Atenciosamente,

      Equipe Seazone
      `,
      text: 'Cobrança sendo enviada para o Aircover - discorda',
    },
    {
      message: `
      Olá, ${(selectedGuestDamageNegotiation.reservation?.guest_name)}! Como vai?

      Como não obtivemos seu retorno para finalização do processo, acionamos o seguro
      da plataforma Airbnb para nos auxiliar e o dano já foi pago pela plataforma.

      Qualquer dúvida permanecemos à disposição.

      Atenciosamente,

      Equipe Seazone
            `,
      text: 'Pagamento de danos pelo seguro',
    },
    {
      message: `
      ${(selectedGuestDamageNegotiation.reservation?.guest_name)}, boa tarde! Como vai?

      Recebemos o pagamento do valor de danos através da plataforma, estamos
      encerrando o processo!

      Agradecemos sua compreensão e esperamos revê-lo em breve!
      `,
      text: 'Pagamento de danos pelo seguro',
    },
  ];

  return (
    <ValidationAndHistoryContainer>
      <Title>Copiar texto para envio de cobrança</Title>
      <CopyItems options={copyItemsOptions} />
      <Title>Histórico de tratativas</Title>
      <TextField
        id="aircoverCode"
        label="Código Aircover"
        labelClassName="label"
        placeholder="Digite aqui..."
        formik={formik}
        value={formik?.values.aircoverCode}
      />
      <TextField
        id="amountReceived"
        label="Valor recebido"
        labelClassName="label"
        placeholder="Digite aqui..."
        formik={formik}
        mask="money"
        value={formik?.values.amountReceived}
      />
      <UploadInput
        label="Upload de comprovante"
        labelClassName="label"
        setUploadedFiles={setGuestPaymentReceipts}
        uploadedFiles={guestPaymentReceipts}
        isMultiple
      />
      <SimpleSelect
        id="guestPaymentStatus"
        options={guestDamageNegotiationPaymentStatusOptions}
        label="Status"
        placeholder={formik?.values?.guestPaymentStatus || 'Selecione'}
        formik={formik}
      />
      <RadioButton
        options={validateOptions}
        selectedOption={isToInformOwner}
        setSelectedOption={setIsToInformOwner}
        label="Informar Proprietário"
        labelClassName="label"
      />
    </ValidationAndHistoryContainer>
  );
};

ValidationAndHistory.defaultProps = {
  formik: undefined,
};
