import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
0% {
    background: linear-gradient(-90deg, #ccc 0%, #eee 50%, #ccc 100%);
    background-size: 400% 400%;
    background-position: 0% 0%;
  }
50% {
  background-position: -135% 0%;
}
100%{
  background: linear-gradient(-90deg, #ccc 0%, #eee 50%, #ccc 100%);
  background-size: 400% 400%;
  background-position: 0% 0%;
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const ProfileTexts = styled.div`
  gap: 5px;
  display: flex;
  max-width: 70%;
  align-items: left;
  flex-direction: column;

  h1 {
    font-weight: 600;
    font-size: 1.6rem;
  }
  h2 {
    font-weight: normal;
    font-size: 1.2rem;
  }
`;

export const ProfilePic = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  animation: ${shimmerAnimation} 0.9s infinite;
`;

export const ProfileName = styled.h1`
  text-transform: capitalize;
`;
export const ProfileSubtitle = styled.h2``;
