import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const Form = styled.form`
  margin-top: 2rem;
`;

export const FormContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5, calc(90% / 5));
  gap: 16px;
  margin-bottom: 24px;
  align-items: flex-start;
  justify-content: flex-start;

  .inputLabel {
    font-family: ${({ theme }) => theme.fonts.familys._6} !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 60%;
  margin-top: 24px;
  margin-left: 10px;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    margin-top: 16px;
    width: 100%;
    margin-left: 0;
  }
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  .datePickerContainer {
    &:hover fieldset, .Mui-focused fieldset, fieldset:focus, fieldset:active {
      border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()} !important;
    }
  }

  input {
    width: 100% !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
`;

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const DividerContent = styled.div`
  width: 35%;
  height: 5px;
  border-top: 1px solid #C2C8F1;
  padding-bottom: 32px;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
  }
`;
