import React from 'react';
import ViewHostsComponent from '../../components/ViewHostComponent';

// import { Container } from './styles';

const ViewHosts: React.FC = () => (
  <ViewHostsComponent />
);

export default ViewHosts;
