import React from 'react';
import { motion } from 'framer-motion';
import { IInput } from './types';

import ErrorMessage from './ErrorMessage';

const Input = ({
  id, label, formik, placeholder, disabled,
}: IInput) => (
  <>
    <p>{label}</p>
    <motion.input
      disabled={disabled}
      value={formik.values[id]}
      onChange={formik.handleChange}
      id={id}
      placeholder={placeholder}
    />

    <ErrorMessage id={id} formik={formik} />
  </>
);

export default Input;
