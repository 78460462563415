export const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
  >
    <path
      d="M13.875 27.75L23.125 18.5L13.875 9.25"
      stroke="#ffffff"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
