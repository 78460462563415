import styled from 'styled-components';

export const PropertyListContainer = styled.div`
  margin: 1rem 0 2rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const Title = styled.div`
  font-family: 'Inter';
  font-size: 1.6rem;
  color: #001840;

  @media (max-width: 900px) {
    font-size: 1.375rem;
  }
`;

export const FilterContainer = styled.div`
  min-width: 160px;
  padding-bottom: 24px;
  
  @media(max-width: 600px) {
    width: 160px;
  }
`;

export const PropertyCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-size: 1rem !important;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  text-align: start;
  margin-right: 0.5rem;
  padding-top: 0.7rem;
  
  @media (max-width: 500px) {
    font-size: 0.9rem !important;
  }
`;
