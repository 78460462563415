/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  ArrowForward as Arrow,
} from '@mui/icons-material';

import moment from 'moment';

import {
  Container,
  FormContainer,
  FormContent,
  Wrapper,
  TitleContainer,
  PhoneContainer,
  AddressContainer,
  CheckContainer,
  DetailsContainer,
  NotesContainer,
  FinanceContainer,
  FinanceContent,
  SmallText,
  SmallTextBold,
  MediumTextBold,
  ButtonStyled,
  PropertyImage,
  Space,
  SmallTextNote,
  Separator,
  BarLabel,
  CopyContainer,
  CopyButton,
  LastSmallText,
} from './styles';

import imgPhone from '../../../assets/icons/controll/whatsapp.svg';
import CopyIcon from '../../../assets/icons/generals/copywhite.svg';

import { translatePaymentStatus, translateReservationStatus, translateBlockingReason } from '../../../utils/Translator';
import {
  formatDateToShow, formatedDateToShow, removeSecondsHourToShow,
} from '../../../utils/Formatter';

import { Reservation } from '../../../services/Reservation/types';

import { getAddressById } from '../../../services/Address/request';

import { useReservation } from '../../../context/ReservationContext';

import { useUser } from '../../../context/UserContext';

import { useModal } from '../../../hooks/ModalHook/useModal';
import { getReservationById } from '../../../services/Reservation/request';

interface Props {
  comment: string;
  bookings?: Reservation | any;
  idReservation: number;
}

const ReservationDataModal = ({
  comment,
  bookings,
  idReservation,
}: Props) => {
  const [dataReservation, setDataReservation] = useState<Reservation>({} as Reservation);
  useEffect(() => {
    async function getReservation() {
      const result = await getReservationById(idReservation);
      setDataReservation(result);
    }
    getReservation();
  }, []);
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const { handleModalActive } = useModal();

  const { reservationData } = useReservation();
  const phoneNumber = reservationData?.guest?.user?.phone_number1 || '';
  const guestName = dataReservation.guest?.user?.trading_name
    ? dataReservation.guest?.user?.trading_name
    : `${dataReservation.guest?.user?.first_name} ${dataReservation.guest?.user?.last_name}`;

  const checkouts = bookings && bookings
    .filter((booking: Reservation) => (booking.id !== reservationData.id))
    .map((checkout: Reservation) => checkout.check_out_date);

  const hasHotbed = checkouts
   && checkouts.some((date: string) => date === reservationData?.check_in_date);
  const [addressProperty, setAddressProperty] = useState<string>('');

  const getAddress = async () => {
    if (dataReservation.listing?.property?.id) {
      const address = await getAddressById(dataReservation.listing?.property?.id);
      if (address) {
        const {
          street,
          number,
          neighborhood,
          complement,
          city,
          state,
          country,
        } = address;

        setAddressProperty(`${street} ${number}, ${neighborhood} - ${complement} ${city} - ${state} - ${country}, CEP: ${address.postal_code}`);
      } else {
        setAddressProperty('');
      }
    }
  };

  const dailyQuantity = () => {
    const begin = moment(dataReservation.check_in_date);
    const end = moment(dataReservation.check_out_date);
    const duration = moment.duration(end.diff(begin));
    return Math.round(duration.asDays());
  };

  useEffect(() => {
    getAddress();
    return () => {
      setAddressProperty('');
    };
  }, [dataReservation]);

  let commentSplitted = comment?.split('; cancellation:');
  commentSplitted = commentSplitted?.filter((txt: string) => txt !== '; cancellation:');

  const reservationCopyInformation = `Código de reserva: ${dataReservation.code}\nCódigo do imóvel: ${dataReservation.listing?.property?.code}\nData de Check-in: ${dataReservation.check_in_date}\nData de Check-out: ${dataReservation.check_out_date}\nNome do hóspede: ${guestName}`;

  return (
    <Container role={userInformation?.main_role || ''}>
      <FormContainer
        onContextMenu={(event) : void => {
          event.stopPropagation();
        }}
      >
        <FormContent>
          <TitleContainer>
            <Wrapper>
              {!reservationData?.is_blocking && (
                <>
                  {dataReservation.guest && (
                  <MediumTextBold>{guestName}</MediumTextBold>
                  )}
                </>
              )}
              <div>
                <SmallText>
                  {`${dataReservation.adult_guest_quantity} adultos . ${dailyQuantity()} diárias`}
                </SmallText>
                <LastSmallText>
                  .
                  {' '}
                  {roles.includes('Host') ? '-' : dataReservation.daily_net_value}
                </LastSmallText>
              </div>
            </Wrapper>
            <Wrapper>
              <ButtonStyled type="button" reservationStatus={dataReservation?.status} blocked={(reservationData?.is_blocking && reservationData?.status !== 'Canceled') || false}>
                <SmallTextBold>{!reservationData?.is_blocking ? translateReservationStatus(dataReservation.status) : 'Bloqueada'}</SmallTextBold>
              </ButtonStyled>
              {reservationData?.is_blocking
                ? (
                  <SmallText>
                    {`Motivo: ${translateBlockingReason(reservationData?.blocking_reason)}`}
                  </SmallText>
                )
                : (
                  <PhoneContainer onClick={() => window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank')}>
                    <img src={imgPhone} alt="Phone" />
                    <SmallText>{dataReservation.guest?.user?.phone_number1}</SmallText>
                  </PhoneContainer>
                )}
            </Wrapper>
          </TitleContainer>

          <AddressContainer>
            <SmallText>
              <strong>
                {dataReservation.listing?.property?.code}
                .
                {' '}
              </strong>
              {addressProperty}
            </SmallText>
            <PropertyImage backgroundImage="" />
          </AddressContainer>

          <CheckContainer>
            <Wrapper>
              <SmallTextBold>Check-in</SmallTextBold>
              <SmallText>{formatedDateToShow(dataReservation.check_in_date)}</SmallText>
            </Wrapper>
            <Arrow />
            <Wrapper>
              <SmallTextBold>Check-out</SmallTextBold>
              <SmallText>{formatedDateToShow(dataReservation.check_out_date)}</SmallText>
            </Wrapper>
            {hasHotbed && (
              <Wrapper>
                <ButtonStyled type="button" reservationStatus={'Pre-booking'}><SmallTextBold>Cama quente</SmallTextBold></ButtonStyled>
              </Wrapper>
            )}
          </CheckContainer>

          <DetailsContainer>
            <Wrapper>
              <SmallText>Código da reserva</SmallText>
              <SmallText>{dataReservation.code}</SmallText>
            </Wrapper>
            <Wrapper>
              <SmallText>Total de noites</SmallText>
              <SmallText>{dailyQuantity()}</SmallText>
            </Wrapper>
            <Wrapper>
              <SmallText>Valor total</SmallText>
              <SmallText>{roles.includes('Host') ? '-' : dataReservation.daily_net_value}</SmallText>
            </Wrapper>
            {dataReservation.check_in_time && (
              <Wrapper>
                <SmallText>Horário check-in</SmallText>
                <SmallText>{reservationData?.is_blocking ? '00:00' : removeSecondsHourToShow(dataReservation.check_in_time)}</SmallText>
              </Wrapper>
            )}
            {dataReservation.check_out_time && (
              <Wrapper>
                <SmallText>Horário check-out</SmallText>
                <SmallText>{reservationData?.is_blocking ? '00:00' : removeSecondsHourToShow(dataReservation.check_out_time)}</SmallText>
              </Wrapper>
            )}
            {dataReservation.created_at && (
            <Wrapper>
              <SmallText>Data de criação</SmallText>
              <SmallText>{formatDateToShow(moment(dataReservation.created_at).toDate())}</SmallText>
            </Wrapper>
            )}
            {!reservationData?.is_blocking && (
            <Wrapper>
              <SmallText>Origem da reserva</SmallText>
              <SmallText>{dataReservation.listing?.ota?.name}</SmallText>
            </Wrapper>
            )}
            <Wrapper>
              <SmallText>N°. de hóspedes</SmallText>
              <SmallText>{`${dataReservation.adult_guest_quantity} adulto(s), ${dataReservation.child_guest_quantity} criança(s) ${reservationData?.has_pet > 0 ? `e ${reservationData.has_pet} Pet(s)` : ''}`}</SmallText>
            </Wrapper>
          </DetailsContainer>
          <CopyContainer>
            <CopyButton
              onClick={() => navigator.clipboard.writeText(reservationCopyInformation)}
              type="button"
            >
              <img src={CopyIcon} alt="icone de copiar" />
              Copiar informações da reserva
            </CopyButton>
          </CopyContainer>
          <NotesContainer>
            <BarLabel>
              Notas
            </BarLabel>
            {
                (reservationData?.is_blocking && commentSplitted[0]?.length >= 1) && (
                  <>
                    <Space />
                    <SmallTextBold className="notes">Imóvel bloqueado</SmallTextBold>
                    <SmallTextNote
                      disabled
                      rows={commentSplitted[0]?.length > 300 ? 10 : 5}
                      cols={40}
                    >
                      {dataReservation.comment}
                    </SmallTextNote>
                  </>
                )
             }
            {
               (reservationData?.is_blocking
               && reservationData?.status === 'Canceled') && (
               <Separator />
               )
             }
            {
                (reservationData?.status === 'Canceled') && (
                  <>
                    {
                      commentSplitted[1]?.length > 1 && (
                        <>
                          <Space />
                          <SmallTextBold className="notes">
                            {reservationData?.is_blocking ? 'Bloqueio cancelado' : 'Reserva cancelada'}
                          </SmallTextBold>
                          <SmallTextNote
                            disabled
                            rows={commentSplitted[1]?.length > 300 ? 10 : 3}
                            cols={40}
                          >
                            {commentSplitted[1]}
                          </SmallTextNote>
                        </>
                      )
                    }
                  </>
                )
             }
          </NotesContainer>

          {!reservationData?.is_blocking && !roles.includes('Host')
              && (
                <FinanceContainer>
                  <BarLabel>Financeiro</BarLabel>
                  <FinanceContent>
                    <div>
                      <Wrapper>
                        <SmallText>Status pagamento</SmallText>
                        <SmallText>
                          {translatePaymentStatus(dataReservation.paymentStatus)}
                        </SmallText>
                      </Wrapper>
                      <Wrapper>
                        <SmallText>Valor caução</SmallText>
                        <SmallText>{dataReservation.paid_amount}</SmallText>
                      </Wrapper>
                    </div>
                    <button type="button" onClick={() => handleModalActive(2)}>Mais informações</button>
                  </FinanceContent>
                </FinanceContainer>
              )}
        </FormContent>

      </FormContainer>
    </Container>
  );
};

export default ReservationDataModal;
