import styled from 'styled-components';
import { Grid } from '@mui/material';

export const ContainerGrid = styled(Grid).attrs({
  container: true,
  spacing: 2,
})`
  && {
    margin-bottom: ${({ theme }) => theme.spacing.form} !important;
  }
`;

export const Container = styled.div`
   margin-bottom: ${({ theme }) => theme.spacing.form};
`;
