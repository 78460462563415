import React, { useState } from 'react';

import calendarIcon from '../../../../../../assets/icons/generals/redCalendar.svg';
import arrowBlackIcon from '../../../../../../assets/icons/generals/arrowBlack.svg';

import { AddressProps } from '../../../../../../context/PaymentVoucher/types/paymentStatus.types';

import {
  Days,
  Image,
  Header,
  SubInfo,
  BoldText,
  Container,
  ImageShimmer,
  LeftContainer,
  SideContainer,
  RightContainer,
  ContentContainer,
  BottomContainer,
} from './styles';

interface CardInfoProps{
  code: string;
  staff: string;
  address: AddressProps;
  checkin: string;
  reservationType: 'completes' | 'pendings';
}

const CardInfo = ({
  code,
  staff,
  checkin,
  address,
  reservationType,
}: CardInfoProps) => {
  const [loading, setLoading] = useState(true);

  const onImageLoaded = () => setLoading(true);

  const addressComplete = ` ${address.street}, ${address.number}, ${address.neighborhood}.`;
  const cityAndState = `${address.city} - ${address.state}`;

  const todayDate = new Date().toLocaleDateString();

  const missingDaysCheckinToday = 10;

  return (
    <Container reservationType={reservationType}>
      <ContentContainer>
        <SideContainer>
          <LeftContainer>
            <Header>
              <h1>
                <BoldText>{code?.substring(0, 8)}</BoldText>
                {addressComplete}
                <br />
                {cityAndState}
                <br />
                <br />
                {staff}
              </h1>
            </Header>
            <SubInfo>
              <img src={calendarIcon} alt="icone de calendário" />
              <div>
                <h1>{checkin}</h1>
                <img src={arrowBlackIcon} alt="seta de separação" />
                <h1>{todayDate}</h1>
              </div>
            </SubInfo>
            <Days />
          </LeftContainer>
          <RightContainer>
            <Image>
              {
              loading ? (
                <ImageShimmer />
              ) : (
                <img
                  onLoad={() => onImageLoaded()}
                  src={'https://source.unsplash.com/random/1280x720'}
                  alt="miniatura de imagem de imóvel"
                />
              )
            }

            </Image>
          </RightContainer>
        </SideContainer>

        {
          (missingDaysCheckinToday > 0 && missingDaysCheckinToday < 10) && (
            <BottomContainer>
              <h1>
                {missingDaysCheckinToday}
                {' '}
                dias restantes
              </h1>
            </BottomContainer>
          )
        }

      </ContentContainer>
    </Container>
  );
};

export default CardInfo;
