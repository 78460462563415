import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  max-height: 130px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background: ${({ theme }) => theme.palette.yellow._300.hex()};
  border: 1px solid ${({ theme }) => theme.palette.yellow._900.hex()};
  border-radius: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
`;

export const TitleContent = styled.div`
  margin-left: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.palette.yellow._900.hex()};
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  text-align: start;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 22px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  text-align: start;
`;
