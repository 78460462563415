import { IData } from '../../../Popups/type';
import { Icon1, Icon2 } from './icons';

export const data: IData[] = [
  {
    step: 0,
    image: <Icon1 />,
    title: 'Bem-vindo(a) !',
    useImageTemplate: true,
    card: {
      title: 'Nova funcionalidade!',
      description: (
        <>
          <p>
            Temos uma novidade para você! Agora você pode escolher se deseja
            transferir suas receitas para sua conta bancária ou se prefere
            deixá-las guardadas aqui na Seazone. E o melhor de tudo é que ao
            optar por deixar o dinheiro guardado, você terá acesso a condições
            especiais na compra de Spots futuramente!
          </p>
          <p>
            Clique no botão abaixo e selecione sua preferência quanto ao destino
            de suas receitas!
          </p>
        </>
      ),
    },
  },
  {
    step: 1,
    image: <Icon2 />,
    useImageTemplate: true,
    card: {
      title: 'Resumo financeiro',
      description: (
        <>
          <p>
            Sabemos que ter um controle financeiro claro é essencial para o
            sucesso da sua propriedade. Agora, você terá acesso ao Resumo
            Financeiro com os valores das suas receitas, despesas e repasses
            realizados!
          </p>
          <p>
            Caso você opte por deixar o dinheiro guardado, você também poderá
            acompanhar o seu Saldo acumulado. Tudo isso foi implementado com o
            objetivo de promover transparência e fortalecer nossa relação com
            você.
          </p>
        </>
      ),
    },
  },
];
