import styled from 'styled-components';
import {
  Tabs,
  Tab,
} from '@mui/material';

export const Header = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey._400.hex()}`};
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${({ theme }) => `${theme.palette.white.main.hex()}`};
`;

export const HeaderText = styled.p`
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  align-self: center;
  margin-left: 16px;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: right;
  color: ${({ theme }) => theme.palette.blue._950.hex()};

  &:hover{
    color: ${({ theme }) => theme.palette.red._400.hex()};
  }
`;

export const Content = styled.div`
  width: 100%;
  min-width: 270px;
`;

export const Container = styled.div<{
  height?: string;
}>`
  width: 100%;
  height: ${({ height }) => height || '100%'};
  max-width: 600px;
`;
export const VerticalTabs = styled(Tabs)`
  && {
    position: fixed;
    right: 0;
    top: 40px;
    z-index: 5;
    border: 1px solid ${({ theme }) => theme.palette.grey._340.hex()};
    align-self: end;
    height: 100vh;
    background: ${({ theme }) => theme.palette.white._400.hex()};
    width: 60px;
    margin: auto;
    margin-right: 0px;
    &.MuiTab-wrapper {
      width: 20px !important;
    }
    &.MuiTab-root {
      width: 20px !important;
    }

    @media (max-width: 900px) {
      width: 40px;
    }
  }
`;
export const SelectableTab = styled(Tab)`
  && {
    padding: 0px;

    &.active {
      background: ${({ theme }) => theme.palette.white.main.hex()};
    }

    &.MuiTab-root {
      width: 20px !important;
      min-width: 60px;
      :hover{
        background: ${({ theme }) => theme.palette.white.main.hex()};
      }

      @media (max-width: 900px) {
        min-width: 40px;
      }
    }
    &.MuiTab-textColorInherit.Mui-selected {
      background: ${({ theme }) => theme.palette.white.main.hex()};
    }
    &.MuiTab-wrapper {
      width: 20px;
    }
  }
`;

export const PropertyNameContainer = styled.span`
  color: ${({ theme }) => theme.palette.blue._920.hex()};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  margin-left: 0.3rem;
`;
