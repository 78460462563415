/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-indent */
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { StyledOverlay, StyledModal } from './styles';

interface IPortal {
  onClose: () => void;
}

const Portal: React.FC<IPortal> = ({ onClose, children }) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  return ReactDOM.createPortal(
    <StyledOverlay ref={overlayRef} onClick={onClose}>
      <StyledModal onClick={(e) => e.stopPropagation()}>{children}</StyledModal>
    </StyledOverlay>,
    document.body);
};

export default Portal;
