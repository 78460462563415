import styled, { keyframes } from 'styled-components';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

function verifyCollor(day: number) {
  if (day <= 4) {
    return 'red';
  } if (day > 4 && day <= 9) {
    return '#FF9900';
  }
  return '#62656F';
}

export const Container = styled.div`
min-width: 1300px;
`;

export const Title = styled.h1`
  font-size: 1.1rem;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;

export const TitleReservationCode = styled.h1`
  font-size: 1.1rem;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s;
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const ItemContainer = styled.div<{
  index: number;
  color: 'normal' | 'green';
}>`
  background: ${({ color }) => (color === 'normal' ? '#ebebf5' : '#DFEEEB')};
  border-radius: 7px;
  padding: 15px 10px;
  border: 1px solid transparent;
  position: relative;
  transition: 0.2s;
  opacity: 0;
  animation: ${showItems} 0.6s ${({ index }) => index * 0.1 + 0.5}s ease
    forwards;
  :hover {
    border: 1px solid #00000029;
  }
`;

export const EditButton = styled.button`
  background: #f1f1f6;
  border: none;
  height: 100%;
  width: 60px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  transition: 0.3s;
  @media (max-width: 1400px) {
    width: 5%;
  }
  img {
    transition: 0.2s;
  }
  :hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    img {
      transform: translateY(-3px);
    }
  }
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1.2fr 1.3fr 1fr 0.8fr 1.2fr 1.2fr;
  align-items: center;
`;

export const ReservationCode = styled.div`

`;

export const ImmobileCode = styled.div`

`;

export const ReservationDate = styled.div`

`;

export const ReservationStaff = styled.div`

`;

export const WhatsApp = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  img {
    width: 15px;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;
    :hover {
      transform: translateY(-3px);
    }
  }
`;

export const CheckIn = styled.div`

`;

export const Email = styled.div`
  margin-left: 1.4rem;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    transform: translateY(-3px);
  }

`;

export const SvgContainer = styled.div<{ isSend?: boolean }>`
  color: ${({ isSend, theme }) => (isSend === true ? `${theme.palette.grey._550.hex()}` : `${theme.palette.red._200.hex()}`)};
`;

export const RemainingDate = styled.div<{ day: number }>`
  p {
      font-size: 1rem;
      font-family: ${({ theme }) => theme.fonts.familys._1};
      color: ${({ day }) => verifyCollor(day)};
    }
`;
