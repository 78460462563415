import styled from 'styled-components';

import { MAX_SIZE_MOBILE } from '../utils';

export const NoDataFoundGeneralContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 48px 0 30px 0;
  align-items: center;
  justify-content: center;
`;

export const NoDataFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  h3 {
    text-align: center;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 26px;
    color: #969696;
  }

  @media screen and (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 60%;
  }
`;

export const Container = styled.div``;

export const Title = styled.h5`
  width: 100%;
  user-select: none;
  font-size: 1rem;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
`;

export const ScrollArea = styled.div<{ containerHeight: string }>`
  width: 100%;
  height: ${({ containerHeight }) => containerHeight};
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.palette.grey._425.hex()};
    border-radius: 17px;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    border-radius: 17px;
  }

  @media screen and (max-width: ${MAX_SIZE_MOBILE}px) {
    scrollbar-color: transparent transparent !important;

    &::-webkit-scrollbar {
      visibility: hidden !important;
      width: 0px !important;
      height: 0px !important;
      background: transparent !important;
    }
    &::-webkit-scrollbar-track {
      visibility: hidden !important;
      width: 0px !important;
      height: 0px !important;
      background: transparent !important;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden !important;
      width: 0px !important;
      height: 0px !important;
      background: transparent !important;

      &:hover {
        visibility: hidden !important;
        width: 0px !important;
        height: 0px !important;
      }
    }
  }
`;
