import styled, { keyframes } from 'styled-components';

const fadeImagePreview = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px) scale(0);
    transform-origin: bottom center;
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
    transform-origin: bottom center;
  }
`;

export type ColorProps = 'green' | 'red';

export const ValueContainer = styled.div`
  align-items: center;
  width: 100%;

  gap: 20px;

  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
`;

export const LineContainer = styled.div`
  width: 100%;
`;

export const TextValue = styled.h1<{
  color: ColorProps;
}>`
  color: ${({ theme, color }) => theme.palette[color]._250.hex()};
  font-size: 1rem;
  font-weight: bold;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  border: 1px dashed #00000018;
`;

export const StartContainer = styled.div<{
  textIsBig: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  h1 {
    width: 100%;
    font-weight: normal;
    font-size: 1.3rem;
    cursor: ${({ textIsBig }) => (textIsBig ? 'pointer' : 'default')};
  }

  svg {
    font-size: 1.3rem;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey._600.hex()};
  }
`;

export const DeleteIconContainer = styled.div`
  cursor: pointer;
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardPreviewContainer = styled.div``;

export const CardPreview = styled.div<{
  index: number;
}>`
  transition: 0.3s ease all;
  animation: ${fadeImagePreview} 0.3s ease forwards;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  top: calc(23% + ${({ index }) => index * 40}px);
  left: 2%;
  width: 100px;
  height: 100px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }
`;
