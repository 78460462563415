import styled, { keyframes } from 'styled-components';

const fadeLine = keyframes`
  from {
    opacity: 0;
    width: -0%;
  }
  to {
    opacity: 1;
    width: 100%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  button {
    text-decoration: none;
    border: none;
    background: transparent;

    transition: 0.3s;

    transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};

    &:hover {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
    }
  }

  h1 {
    color: ${({ isOpen, theme }) => (isOpen ? theme.palette.grey._900.hex() : '#0000005a')};
    transition: 0.3s;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 2rem;

    opacity: 0;
    animation: ${fadeIn} 0.5s 0.4s ease forwards;
  }

  img {
    cursor: pointer;
    width: 15px;
    transition: 0.4s;
    opacity: 0;
    animation: ${fadeIn} 0.5s 0.6s ease forwards;
  }
`;

export const Line = styled.div`
  width: 100%;
  background: #00000045;
  height: 1px;
  margin-top: 5px;

  opacity: 0;
  animation: ${fadeLine} 1s 0.4s ease forwards;
`;
