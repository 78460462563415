/* eslint-disable react/jsx-no-bind */
import { useMemo } from 'react';

import { useOwnerServicesTerms } from '../../../../hooks/OwnerPage/OwnerServicesTerms/useOwnerServicesTerms';
import { useGeolocation } from '../../../../hooks/useGeolocation/useGeolocation';
import { getCoordinates } from '../../../../context/GeolocationContext/utils';

import Backdrop from '../../../Backdrop';
import Button from '../../../FormButton';
import Checkbox from './Checkbox';

import {
  Container,
  Content,
  Header,
  Title,
  TermDescription,
  Text,
  TextRegular,
  TextBold,
  TextSmall,
  Link,
  Footer,
  TermByProperty,
  OneSpace,
  TwoSpaces,
  ButtonContainer,
  PropertiesList,
} from './styles';

const ServicesTermsPopup = () => {
  const {
    properties,
    openServicesTerms,
    setOpenServicesTerms,
    readAndAgreedTerms,
    setReadAndAgreedTerms,
    readAndNoAgreedTerms,
    setReadAndNoAgreedTerms,
  } = useOwnerServicesTerms();

  const { setCoordinates } = useGeolocation();

  const fileUrl = useMemo(() => properties?.[0]?.file_url || 'https://thread-wave-20a.notion.site/Termos-de-servi-o-510303e2ee784c938f89f9cd03e8e249', [properties]);

  const handleClickOnContinue = async () => {
    const { latitude, longitude } = await getCoordinates();

    setCoordinates({
      latitude,
      longitude,
    });

    setOpenServicesTerms({
      servicesTermsPopup: false,
      confirmationModal: true,
    });
  };

  function handleCheckReadAndAgreeWithTerms() {
    setReadAndAgreedTerms((state: boolean) => {
      if (!state === true) {
        setReadAndNoAgreedTerms(false);
      }
      return !state;
    });
  }

  function handleCheckReadAndNoAgreeWithTerms() {
    setReadAndNoAgreedTerms((state: boolean) => {
      if (!state === true) {
        setReadAndAgreedTerms(false);
      }
      return !state;
    });
  }

  if (!openServicesTerms.servicesTermsPopup) return null;

  return (
    <Backdrop onClose={() => {}}>
      <Container
        onClick={(e) => e.stopPropagation()}
      >
        <Content>
          <Header>
            <Title data-testid="title">Atualização Importante dos Termos de Serviços</Title>
          </Header>
          <TwoSpaces />

          <TermDescription>
            <Text data-testid="text-first-paragraph">{'Informamos que os Termos de Serviços prestados pela Seazone foram alterados no ANEXO II - Diretrizes Referentes à Implantação do Imóvel, especificamente nos itens 6, 7 e 8. '}</Text>
            <OneSpace />
            <Text data-testid="text-second-paragraph">{'Para continuar usufruindo dos nossos serviços, é necessário que você leia os termos e aceite as alterações que passam a valer para os seguites imóveis: '}</Text>
            <TwoSpaces />

            <PropertiesList>
              <ul>
                {properties.map((item) => (
                  <li data-testid="property-code" key={item.property.id}>{item.property.code}</li>
                ))}
              </ul>
            </PropertiesList>

            <TextRegular data-testid="text-hiperlink">
              {'Clique aqui para abrir os '}
              <Link data-testid="services-terms-link" href={fileUrl} target="_blank">Termos de Serviços.</Link>
            </TextRegular>
            <TwoSpaces />
            <TextBold data-testid="text-consent">{'Por favor, confirme seu consentimento clicando na caixa que afirma “Eu li e concordo com os termos de serviços” dos  imóvel(eis) que são de minha propriedade.  Se você não selecionar a caixa, entenderemos como uma recusa aos Termos de Serviços.'}</TextBold>
          </TermDescription>
          <TwoSpaces />

          <Footer>
            <TermByProperty>
              <TwoSpaces />
              <Checkbox
                id="readAndAgreedTerms"
                checked={readAndAgreedTerms}
                setChecked={handleCheckReadAndAgreeWithTerms}
                label={(
                  <TextSmall>
                    &nbsp; Eu li e concordo com os
                    {' '}
                    <Link href={fileUrl} target="_blank">Termos de Serviços</Link>
                  </TextSmall>
                )}
              />
              <TwoSpaces />
              <Checkbox
                id="readAndNoAgreedTerms"
                checked={readAndNoAgreedTerms}
                setChecked={handleCheckReadAndNoAgreeWithTerms}
                label={(
                  <TextSmall>
                    &nbsp; Eu li e
                    {' '}
                    <span style={{ color: '#FF2732', fontWeight: 500 }}>não</span>
                    {' '}
                    concordo com os
                    {' '}
                    <Link href={fileUrl} target="_blank">Termos de Serviços</Link>
                  </TextSmall>
                )}
              />
              <TwoSpaces />
            </TermByProperty>

            <TwoSpaces />
            <TextSmall data-testid="primer-text">
              Clique para ler o
              {' '}
              <Link data-testid="primer-link" href="https://drive.google.com/file/d/12fAXPG3vPcEGnnxRg93ZsVX6BBK_MiDS/view" target="_blank">resumo</Link>
              {' '}
              do novo contrato
            </TextSmall>
            <TwoSpaces />
            <TextSmall data-testid="whatsapp-support-text">
              Se você tem dúvidas ou não concorda com os termos,
              {' '}
              <Link data-testid="whatsapp-support-link" href="https://api.whatsapp.com/send?phone=5548991020350" target="_blank">entre em contato com o time de atendimento.</Link>
            </TextSmall>
            <ButtonContainer>
              <Button dataCy="btn-continue" disable={!readAndAgreedTerms && !readAndNoAgreedTerms} onClick={() => handleClickOnContinue()}>
                Prosseguir
              </Button>
            </ButtonContainer>
          </Footer>
        </Content>
      </Container>
    </Backdrop>
  );
};

export default ServicesTermsPopup;
