import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

export interface ModalType {
  children: ReactElement | string,
  anchor: string,
  open: boolean,
  modalActive?: Number,
  handleOpen: Function,
  handleAnchor: Function,
  handleChildren: Function,
  handleModalActive: Function,
}

export const ModalContext = createContext<ModalType>({
  children: '',
  anchor: 'right',
  open: false,
  modalActive: 0,
  handleOpen: () => {},
  handleAnchor: () => {},
  handleChildren: () => {},
  handleModalActive: () => {},
});

export const ModalProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const [openState, setOpenState] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<string>('right');
  const [childrenValue, setChildrenValue] = useState<ReactElement | string>('');
  const [active, setModalActive] = useState<Number>(0);

  const handleOpen = useCallback((value: boolean) => {
    setOpenState(value);
  }, []);

  const handleAnchor = useCallback((value: string) => {
    setModalPosition(value);
  }, []);

  const handleChildren = useCallback((value: ReactElement) => {
    setChildrenValue(value);
  }, []);

  const handleModalActive = useCallback((value: Number) => {
    setModalActive(value);
  }, []);

  const value = useMemo(() => ({
    anchor: modalPosition,
    children: childrenValue,
    open: openState,
    modalActive: active,
    handleOpen,
    handleAnchor,
    handleChildren,
    handleModalActive,
  }), [
    active,
    childrenValue,
    handleAnchor,
    handleChildren,
    handleModalActive,
    handleOpen,
    modalPosition,
    openState,
  ]);

  return (
    <ModalContext.Provider
      value={value}
    >
      {children}
    </ModalContext.Provider>

  );
};
