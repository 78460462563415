import React from 'react';
import { useFinancialFeedback } from '../../../../hooks/FinancialFeedbackContext/FinancialFeedbackContext';
import { IFilterOptions } from '../../types';
import { Container, ItemContainer } from './styles';

interface ItemProps {
  option: IFilterOptions;
  children: string;
}

const Item = ({
  option,
  children,
}: ItemProps) => {
  const { handleFilterOption, filterOption } = useFinancialFeedback();

  const handleClick = (filter: IFilterOptions) => {
    handleFilterOption(filter);

    if (filterOption === filter) {
      handleFilterOption('all');
    }
  };

  const validateActiveFunction = () => {
    if (filterOption === option) {
      return true;
    } return false;
  };

  const validateActive = validateActiveFunction();

  return (
    <ItemContainer active={validateActive} onClick={() => handleClick(option)} type="button">
      <li>
        <p>{children}</p>
      </li>
    </ItemContainer>
  );
};

const Navigate = () => (
  <Container>
    <ul>
      <Item option="closing">Fechamento</Item>
      <Item option="nf">Notas Fiscais</Item>
      <Item option="host">Anfitrião</Item>
      <Item option="expenses">Despesas</Item>
      <Item option="manualFit">Ajustes Manuais</Item>
    </ul>
  </Container>
);

export default Navigate;
