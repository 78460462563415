import React from 'react';
import { UseFormik } from '../../../../../../../../utils/Formik/types';
import { Container } from './style';

interface ICheckBox {
  formik: UseFormik<any>;
  id: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

const Checkbox = ({
  id,
  formik,
  value,
  onChange,
}: ICheckBox) => {
  const initialChecked = value || (formik.values[id] || false);
  const [checked, setChecked] = React.useState(initialChecked);

  const handleChange = () => {
    if (onChange) {
      onChange(!checked);
    } else {
      setChecked(!checked);
    }
  };

  React.useEffect(() => {
    if (!onChange) {
      formik.setFieldValue(id, checked);
    }
  }, [checked]);

  return (
    <Container
      id={id}
      checked={value || checked}
      onClick={handleChange}
    />
  );
};

Checkbox.defaultProps = {
  value: undefined,
  onChange: undefined,
};

export default Checkbox;
