import React, {
  createContext, useState, ReactNode, useEffect,
} from 'react';

interface HeaderConfigProps {
  children: ReactNode;
}

interface HeaderConfigContextProps {
  showCheckin: boolean;
  showCheckout: boolean;
  showClearning: boolean;
  showConcluded: boolean;

  handleShowConcluded: Function;
  handleShowCheckin: Function;
  handleShowCheckout: Function;
  handleShowClearning: Function;
}

export const HeaderConfigContext = createContext<HeaderConfigContextProps>({
  showConcluded: true,
  showCheckin: true,
  showCheckout: true,
  showClearning: true,

  handleShowConcluded: () => Boolean,
  handleShowCheckin: () => Boolean,
  handleShowCheckout: () => Boolean,
  handleShowClearning: () => Boolean,
});

export const HeaderConfigProvider = ({
  children,
}: HeaderConfigProps) => {
  const [showCheckin, setShowCheckin] = useState(true);
  const [showConcluded, setShowConcluded] = useState(false);
  const [showCheckout, setShowCheckout] = useState(true);
  const [showClearning, setShowClearning] = useState(true);

  const preventState = async () => {
    const isShowingChecking = await localStorage.getItem('showCheckin');
    const isShowingCheckout = await localStorage.getItem('showCheckout');
    const isShowingClearning = await localStorage.getItem('showClearning');

    if (isShowingChecking) {
      setShowCheckin(JSON.parse(isShowingChecking));
    }
    if (isShowingCheckout) {
      setShowCheckout(JSON.parse(isShowingCheckout));
    }
    if (isShowingClearning) {
      setShowClearning(JSON.parse(isShowingClearning));
    }
  };

  useEffect(() => {
    preventState();
  }, []);

  const handleShowCheckin = async () => {
    await localStorage.setItem('showCheckin', JSON.stringify(!showCheckin));
    setShowCheckin(!showCheckin);
  };

  const handleShowCheckout = async () => {
    await localStorage.setItem('showCheckout', JSON.stringify(!showCheckout));
    setShowCheckout(!showCheckout);
  };

  const handleShowConcluded = async () => {
    await localStorage.setItem('showConcluded', JSON.stringify(!showConcluded));
    setShowConcluded(!showConcluded);
  };

  const handleShowClearning = async () => {
    await localStorage.setItem('showClearning', JSON.stringify(!showClearning));
    setShowClearning(!showClearning);
  };

  return (
    <HeaderConfigContext.Provider value={{
      showConcluded,
      showCheckin,
      showCheckout,
      showClearning,
      handleShowConcluded,
      handleShowCheckin,
      handleShowCheckout,
      handleShowClearning,
    }}
    >
      {children}
    </HeaderConfigContext.Provider>
  );
};
