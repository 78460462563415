import React from 'react';

import { Container, Content } from './style';
import { LogoSVGIcon } from './svg';

const Header = () => (
  <Container
    initial={{
      width: 0,
    }}
    animate={{
      width: 'calc(100% - 20px)',
    }}
    exit={{
      opacity: 0,
      left: '50%',
      top: '-10px',
      transition: {
        duration: 0.2,
      },
    }}
  >
    <Content>
      <LogoSVGIcon />
    </Content>
  </Container>
);

export default Header;
