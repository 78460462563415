import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
`;

export const Content = styled(motion.div)`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ItemsContainer = styled.div`
  display: flex;
  gap: 7px;
  flex-direction: row;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    height: 0;
    width: 0px;
  }

  overflow-x: scroll;
`;

export const Item = styled(motion.div)<{
  inDevelopment?: boolean;
}>`
  gap: 0.5rem;
  width: 85px;

  cursor: pointer;

  padding: 10px 20px;
  display: flex;
  border-radius: 7px;
  background: #e7eaf1;

  border: 1px solid transparent;

  flex-direction: column;

  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 0.8rem;
    font-weight: normal;
    color: #3a4760;
  }

  ${({ inDevelopment }) => inDevelopment
    && css`
      filter: grayscale(1);

      h1 {
        opacity: 0.6;
      }
    `}


    svg {
      path {
        fill: #042675;
      }
    }
`;
