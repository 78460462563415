import styled from 'styled-components';
import { Typography } from '@mui/material';
import { PlusCircle, MinusCircle } from 'react-feather';
import {
  ReportProblemOutlined,
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

type Props = {
  border: boolean,
  justify: boolean,
};

export const Wrapper = styled.div<{
  border: boolean,
}>`
  display: block;
  width: 100%;
  margin-bottom: ${({ border }) => (border ? '15px' : '0')};
`;

export const WrapperQuantity = styled.div`
  max-width: 100%;
  max-height: 35px;
  width: 100%;
  height: 100%;
  display: flex;
  border-bottom: solid ${({ border }:Props) => (border ? 1 : 0)}px ${({ theme }) => theme.palette.grey._420.hex()};
  justify-content: ${({ justify }:Props) => (justify ? 'space-between' : 'center')};
`;
export const Title = styled(Typography)<{ isOnboardingPage?: boolean }>`
  && {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    color: ${({ theme, color }) => (color ? theme.palette.white._250.hex() : theme.palette.blue._950.hex())};
  }
  border: ${({ isOnboardingPage }) => isOnboardingPage && '1px solid #BEC6CC'};
  border-radius: 5px;
`;
export const IconWrapper = styled.button.attrs({
  type: 'button',
})`
  margin: 5px 15px;
  border: none;
  padding: 0;
  overflow: visible;
  background: transparent;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
export const IconValueWrapper = styled.div`
  display: flex;
`;

export const GreyMinusCircle = styled(MinusCircle)`
  :disabled {
    color: ${({ theme }) => theme.palette.grey._350.hex()};
  }
`;
export const GreyPlusCircle = styled(PlusCircle)`
  :disabled {
    color: ${({ theme }) => theme.palette.grey._350.hex()};
  }
`;
export const ErrorIcon = styled(ReportProblemOutlined)`
&& {
  margin-right: 3px;
  height: 16px;
}
`;

export const AddCircleIconStyled = styled(AddCircleIcon)<{ disable: boolean }>`
  && {
    color:${({ disable }) => (!disable ? '#0D4BD0' : '#C0C0C0')};
    width: 20px;
    height: 35px;
    padding-bottom: 10px;
  }
`;

export const RemoveCircleIconStyled = styled(RemoveCircleIcon)<{ disable: boolean }>`
  && {
    color:${({ disable }) => (!disable ? '#0D4BD0' : '#C0C0C0')};
    width: 20px;
    height: 35px;
    padding-bottom: 10px;
  }
`;

export const ArrowBackIosIconStyled = styled(ArrowBackIosIcon)`
  && {
    color: white;
    width: 70%;
    height: 70%;
    margin-left: 6px;
  }
`;

export const ArrowForwardIosIconStyled = styled(ArrowForwardIosIcon)`
  && {
    color: white;
    width: 70%;
    height: 70%;
    margin-left: 5px;
  }
`;

export const ArrowDiv = styled.div<{ disable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  width: 17px;
  height: 17px;
  background-color:${({ disable }) => (!disable ? '#0D4BD0' : '#C0C0C0')};
`;
