import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    background-color: transparent;
  }
`;

export const LeftItems = styled.ul`
  gap: 3px;
  width: 100%;
  display: flex;
  list-style: none;
  flex-direction: row;
  position: relative;
  text-align: center;

  @media (max-width: 320px){
    gap: 1px;
  }
`;

export const FilterButton = styled(motion.button)<{
  active: boolean;
}>`
  transition: 0.4s;
  cursor: pointer;

  ${({ active }) => active
    && css`
      svg {
        path {
          fill: blue;
        }
      }
    `}
`;

const handleBorderOption = (option: string) => {
  switch (option) {
    case 'todos':
      return `
        left: -5px;
        width: 30%;
      `;
    case 'terreno':
      return `
        left: 48px;
        width: 38%;
      `;
    case 'locacao':
      return `
        left: 109px;
        width: 45%;
      `;
    default:
      return '';
  }
};

export const Item = styled(Link)<{
  active: boolean;
}>`
  color: #ccc;
  text-decoration: none;
  transition: 0.3s;
  width: 100%;

  border: 2px solid black;
  padding: 10px 5px;

  border-radius: 5px 5px 0px 0px;

  color: var(--darkk-blue-900, #969696);
  text-align: center;

  /* fonte/corpo/paragrafo/1 */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;

  border: 2px solid var(--neuter-500, #969696);

  ${({ active }) => active
    && css`
      color: #001840;
      border-color: #001840;
    `};
`;

export const Border = styled(motion.div)<{
  option: string | undefined;
}>`
  height: 2px;
  bottom: -4px;
  transition: 0.2s;
  position: absolute;
  background-color: #0e0e0e;
  ${({ option }) => option && handleBorderOption(option)};
`;

export const FilterContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 20px 0 5px 0;

  transform: rotate(180deg);
`;
