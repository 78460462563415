import React, { useEffect } from 'react';
import { FormButton } from '../../..';
import { ButtonsContainer, Container } from './style';
import useKeys from '../../../../hooks/GlobalHook/useKeys';

interface Props {
  close: any;
  currentPage: number;
  setCurrentPage: Function;
  finish: boolean;
  setStarted: Function;
  started: boolean;
  page: {
    id: number;
    title: string;
    questions: {
      id: number;
      name: string;
      checked: boolean;
    }[];
  }[];
  mobile: boolean | undefined;
  concluded: boolean;
  handlePostChecklist: Function;
  handlePutChecklist: Function;
}

export const Footer = ({
  page,
  close,
  mobile,
  finish,
  started,
  concluded,
  setStarted,
  currentPage,
  setCurrentPage,
  handlePostChecklist,
  handlePutChecklist,
}: Props) => {
  const lastPage = page.map((item) => item.questions);

  const handleStarted = () => {
    setStarted(true);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    if (currentPage === 0) {
      close();
    }
  };

  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');
  const topArrow = useKeys('ArrowUp');
  const bottomArrow = useKeys('ArrowDown');

  useEffect(() => {
    if (rightArrow && (currentPage !== lastPage.length)) {
      if (started === false) {
        setStarted(true);
      } else {
        handleNextPage();
      }
    }

    if (leftArrow) {
      handlePrevPage();
    }

    if (topArrow) {
      setStarted(true);
      setCurrentPage(lastPage.length);
    }

    if (bottomArrow) {
      setCurrentPage(0);
    }
  }, [leftArrow, rightArrow, topArrow, bottomArrow]);

  return (
    <Container>
      <ButtonsContainer mobile={mobile}>
        <FormButton dataCy="btn-prev-checklist" onClick={handlePrevPage} variant="outlined" type="button">
          Voltar
        </FormButton>
        {!finish && !!started && page[currentPage] && (
          <FormButton dataCy="btn-next-checklist" type="button" onClick={handleNextPage}>
            Avançar
          </FormButton>
        )}
        {!finish && !!started && !page[currentPage] && (
          <FormButton
            dataCy="btn-next-checklist"
            customColor="grey"
            variant="outlined"
            disable
            type="button"
          >
            Avançar
          </FormButton>
        )}
        {!started && !finish && (
          <FormButton dataCy="btn-init-checklist" type="button" onClick={handleStarted}>
            Iniciar
          </FormButton>
        )}
        {finish && started && concluded && (
          <FormButton dataCy="btn-finish-checklist" type="button" onClick={() => handlePutChecklist()}>
            Atualizar
          </FormButton>
        )}
        {finish && started && !concluded && (
          <FormButton dataCy="btn-finish-checklist" type="button" onClick={() => handlePostChecklist()}>
            Finalizar
          </FormButton>
        )}
      </ButtonsContainer>
    </Container>
  );
};
