import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  padding: 2rem;
  max-width: 79vw;

  display: flex;
  gap: 16px;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  @media (max-width: 900px) {
    padding: 24px 16px;
    padding-bottom: 400px;
  }
`;

export const NoDataFound = styled.div`
  z-index: 1;
  height: 30vh;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    opacity: 0.5;
    font-size: 2rem;
    font-weight: bolder;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;
