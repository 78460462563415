import { useContextSelector } from 'use-context-selector';
import { GuestDamageStepContext } from '../../../context/GuestDamage/GuestDamageStepContext';

export function useGuestDamageStep() {
  const guestDamageStep = useContextSelector(GuestDamageStepContext, (state) => state
    .guestDamageStep);
  const shouldDisplayStepCard = useContextSelector(GuestDamageStepContext, (state) => state
    .shouldDisplayStepCard);
  const handleViewRefundDamageStep = useContextSelector(GuestDamageStepContext, (state) => state
    .handleViewRefundDamageStep);
  const handleViewDamageDetailsStep = useContextSelector(GuestDamageStepContext, (state) => state
    .handleViewDamageDetailsStep);
  const handleReconcileDamageWithReservationStep = useContextSelector(GuestDamageStepContext,
    (state) => state.handleReconcileDamageWithReservationStep);
  const handleInsertRefundDamageStep = useContextSelector(GuestDamageStepContext,
    (state) => state.handleInsertRefundDamageStep);
  const handleInsertDamageDetailsStep = useContextSelector(GuestDamageStepContext,
    (state) => state.handleInsertDamageDetailsStep);
  const handleViewBillingHistoryNegotiationsStep = useContextSelector(GuestDamageStepContext,
    (state) => state.handleViewBillingHistoryNegotiationsStep);
  const handleInsertBillingHistoryNegotiationsStep = useContextSelector(GuestDamageStepContext,
    (state) => state.handleInsertBillingHistoryNegotiationsStep);
  const handleDamageSearchStep = useContextSelector(GuestDamageStepContext,
    (state) => state.handleDamageSearchStep);

  return {
    guestDamageStep,
    shouldDisplayStepCard,
    handleInsertDamageDetailsStep,
    handleInsertRefundDamageStep,
    handleReconcileDamageWithReservationStep,
    handleViewDamageDetailsStep,
    handleViewRefundDamageStep,
    handleViewBillingHistoryNegotiationsStep,
    handleInsertBillingHistoryNegotiationsStep,
    handleDamageSearchStep,
  };
}
