import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  min-height: 80%;
`;

export const Form = styled.form`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  border-radius: 0 0 10px 10px;
  h1 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 0 2rem;
    margin-top: 1rem;
  }
  @media (max-width: 1150px){
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px){
    grid-template-columns: 1fr;
  }
  .two-columns {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin-bottom: 1rem;
  }
  .one-column {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;
  }
  .dropdown-property {
    margin-top: 8px;

    @media (max-width: 600px){
      margin-top: 25px;
    }
  }
  .switch {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};;
    border-radius: 5px;
    padding: 1rem;
    div {
      justify-self: flex-end;
    }
  }
  .footer-buttons {
    margin-top: 2rem;
    grid-column-start: 1;
    grid-column-end: 3;
    background: rgba(0, 24, 64, 0.05);
    padding: 2rem 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      width: 30%;
      margin-right: 2rem;

      @media (max-width: 600px){
        width: 100%;
        margin: 0 2rem;
      }
    }
  }
`;
