import React, {
  useState, ReactNode, forwardRef, useImperativeHandle,
} from 'react';
import { v4 as uuid } from 'uuid';
import {
  CloseButton, LateralModalWrapper, ModalContent, ModalHeader,
  Overlay, Tab, TabList, TabPanel, TabsContainer,
} from './styles';
import { Text } from '../../../Text';

import RingBell from '../../../../assets/icons/multicalendar/ring-bell.svg';
import Guest from '../../../../assets/icons/multicalendar/guest.svg';
import Finance from '../../../../assets/icons/multicalendar/finance.svg';

interface ModalProps {
  title: string;
  children: ReactNode | ReactNode[];
  onClose: () => void;
  onChangeTab?: (value: any) => void;
  hasTab?: boolean;
}

export interface LateralModalHandle {
  setState: (value: number) => void;
}

const LateralModal = forwardRef<LateralModalHandle, ModalProps>(({
  title, onClose, hasTab, children, onChangeTab,
}, ref) => {
  const iconsTab = [
    <img src={RingBell} alt="RingBell" />,
    <img src={Guest} alt="Guest" />,
    <img src={Finance} alt="Finance" />,
  ];

  const handleClose = () => {
    onClose();
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (onChangeTab) onChangeTab(index);
  };

  useImperativeHandle(ref, () => ({
    setState(value: any) {
      handleTabClick(value);
    },
  }));

  const renderTabs = () => {
    if (!hasTab) {
      if (Array.isArray(children)) return children[0];
      return children;
    }

    if (Array.isArray(children)) {
      return children
        .map((child, index) => (
          <TabPanel key={`tabPanel-${uuid()}`} hidden={activeTab !== index}>
            {child}
          </TabPanel>
        ));
    }

    return (
      <TabPanel hidden={activeTab !== 0}>
        {children}
      </TabPanel>
    );
  };

  const renderTabList = () => {
    if (!hasTab) {
      return null;
    }

    if (!Array.isArray(children)) {
      return (
        <TabList>
          <Tab
            onClick={() => handleTabClick(0)}
            active={activeTab === 0}
          >
            {iconsTab[0]}
          </Tab>
        </TabList>
      );
    }

    return (
      <TabList>
        {children.map((_, index) => (
          <Tab
            key={`tab-${uuid()}`}
            onClick={() => handleTabClick(index)}
            active={activeTab === index}
          >
            {iconsTab[index]}
          </Tab>
        ))}
      </TabList>
    );
  };

  return (
    <>
      <Overlay onClick={handleClose} />
      <LateralModalWrapper>
        <ModalHeader>
          <Text $color="#000" $fontSize="20">{title}</Text>
          <CloseButton onClick={handleClose}>&times;</CloseButton>
        </ModalHeader>
        <TabsContainer>
          <ModalContent>
            {renderTabs()}
          </ModalContent>
          {renderTabList()}
        </TabsContainer>
      </LateralModalWrapper>
    </>
  );
});

export default LateralModal;
