import {
  FC,
  useMemo,
  useState,
  useCallback,
  ReactElement,
} from 'react';

import { createContext } from 'use-context-selector';

import { IReports } from '../../../services/Owner/OwnerReport/types';

export type ExpensesModalProps = {
  open: boolean,
  expense: IReports,
};

export interface IExpensesReport {
  ownerExpenses: IReports[];
  setOwnerExpenses: Function;
  loading: boolean;
  setLoading: Function;
  startDate: Date;
  setStartDate: Function;
  endDate: Date;
  setEndDate: Function;
  propertyId: number | undefined;
  setPropertyId: Function;
  noDataFound: boolean;
  setNoDataFound: Function;
  expensesModal: ExpensesModalProps;
  setExpensesModal: Function;
}

const today = new Date();
today.setDate(1);
const tomorrow = new Date(today);
tomorrow.setMonth(tomorrow.getMonth() + 1);
tomorrow.setDate(0);

export const ExpensesReportContext = createContext<IExpensesReport>({
  loading: false,
  setLoading: () => {},
  startDate: today,
  setStartDate: () => {},
  endDate: tomorrow,
  setEndDate: () => {},
  propertyId: undefined,
  setPropertyId: () => {},
  ownerExpenses: [],
  setOwnerExpenses: () => {},
  noDataFound: false,
  setNoDataFound: () => {},
  expensesModal: {
    open: false,
    expense: {} as IReports,
  },
  setExpensesModal: () => {},
});

export const ExpensesReportProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(tomorrow);
  const [propertyId, setPropertyId] = useState<number | undefined>(undefined);
  const [ownerExpenses, setOwnerExpenses] = useState<IReports[]>([]);
  const [expensesModal, setExpensesModal] = useState<ExpensesModalProps>({
    open: false,
    expense: {} as IReports,
  });

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleStartDate = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  const handleEndDate = useCallback((date: Date) => {
    setEndDate(date);
  }, []);

  const handlePropertyId = useCallback((property: number | undefined) => {
    setPropertyId(property);
  }, []);

  const handleExpensesReport = useCallback((newReport: IReports[]) => {
    setOwnerExpenses(newReport);
  }, []);

  const handleNoDataFound = useCallback((newNoDataFound: boolean) => {
    setNoDataFound(newNoDataFound);
  }, []);

  const handleExpensesModal = useCallback((expense: ExpensesModalProps) => {
    setExpensesModal(expense);
  }, []);

  const value = useMemo(() => ({
    loading,
    setLoading: handleLoading,
    ownerExpenses,
    setOwnerExpenses: handleExpensesReport,
    startDate,
    setStartDate: handleStartDate,
    endDate,
    setEndDate: handleEndDate,
    propertyId,
    setPropertyId: handlePropertyId,
    noDataFound,
    setNoDataFound: handleNoDataFound,
    expensesModal,
    setExpensesModal: handleExpensesModal,
  }),
  [
    loading,
    handleLoading,
    ownerExpenses,
    handleExpensesReport,
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    propertyId,
    handlePropertyId,
    noDataFound,
    handleNoDataFound,
    expensesModal,
    handleExpensesModal,
  ]);

  return (
    <ExpensesReportContext.Provider value={value}>
      {children}
    </ExpensesReportContext.Provider>
  );
};
