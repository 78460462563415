import styled from 'styled-components';

export const BillingHistoryNegotiationsCardContainer = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin-top: 2rem;
`;

export const ValidationForm = styled.form`

`;

export const ReceiptForm = styled.form`
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
