import { AccountTypeOptionsProps, AccountTypeLabel, AccountTypeValue } from './types';

export const accountTypeOptions: AccountTypeOptionsProps[] = [
  {
    label: AccountTypeLabel.SAVING_ACCOUNT,
    value: AccountTypeValue.SAVING_ACCOUNT,
  },
  {
    label: AccountTypeLabel.INDIVIDUAL_CHECKING_ACCOUNT,
    value: AccountTypeValue.INDIVIDUAL_CHECKING_ACCOUNT,
  },
  {
    label: AccountTypeLabel.JOINT_CHECKING_ACCOUNT,
    value: AccountTypeValue.JOINT_CHECKING_ACCOUNT,
  },
];
