/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react';
import { SimpleSelect } from '../../components';
import CardProperty from '../../components/CardProperty/CardProperty';
import FilterBar from '../../components/FilterBar/index';
import { SelectOption } from '../../components/SimpleSelect/SimpleSelect';
import { useFilterBar } from '../../context/FilterBar';
import { getPropertiesDetails } from '../../services/Property/request';
import { PropertyDetails } from '../../services/Property/types';
import { removeParams } from '../../utils/FilterBar';

import {
  AllProperties,
  Container,
  Order,
  SecondBar,
  Wrapper,
  WrapperContainer,
  FilterBarContainer,
  InitialText,
} from './styles';

const order = [
  {
    id: '1',
    name: 'Maior preço',
  },
  {
    id: '2',
    name: 'Menor preço',
  },
];

export default function PropertySearch() {
  const { filterBar } = useFilterBar();
  const [propertiesList, setPropertiesList] = useState<Array<PropertyDetails>>();

  /* const getProperties = async () => {
    const values = await getPropertiesDetails();
    setPropertiesList(values);
  }; */
  useEffect(() => {
    document.title = 'Sapron | Busca de Imóveis';
  }, []);

  useEffect(() => {
    const getPropertiesFilter = async () => {
      const typeProperty: Array<string> = [];
      if (filterBar.hotel) {
        typeProperty.push('Hotel');
      }
      if (filterBar.house) {
        typeProperty.push('House');
      }
      if (filterBar.apartment) {
        typeProperty.push('Apartment');
      }

      const locationSplit: Array<string> = [];
      filterBar.location?.forEach((name) => {
        locationSplit.push(name.split('-')[0]);
      });

      const params: PropertyDetails = {
        bedroom_quantity: filterBar.bedroom,
        bathroom_quantity: filterBar.bathroom,
        property_type: typeProperty.join(','),
        sea_view: filterBar.seaView,
        barbecue: filterBar.barbecue,
        allow_pet: filterBar.allowPet,
        host: filterBar.host,
        categories: filterBar.categories?.join(','),
        guest_capacity__gte: filterBar.guestsCapacity,
        start_date: typeof filterBar.checkInDate !== 'string' ? filterBar.checkInDate.format('YYYY-MM-DD') : '',
        end_date: typeof filterBar.checkOutDate !== 'string' ? filterBar.checkOutDate.format('YYYY-MM-DD') : '',
        location: locationSplit.join(','),
      };

      const paramsAux = removeParams(filterBar, params);
      const values = await getPropertiesDetails(paramsAux);
      return values;
    };

    const getFilter = async () => {
      if (filterBar.moreSearch || filterBar.search) {
        const newProperties = await (await getPropertiesFilter());
        setPropertiesList(newProperties);
      }
    };

    getFilter();
  }, [filterBar]);

  const [mobile, setMobile] = useState<boolean>(false);

  function useWindowSize() {
    const { innerWidth: width } = window;
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  function orderBySmaller(a: PropertyDetails, b: PropertyDetails) {
    if ((a.daily_prices || 0) > (b.daily_prices || 0)) {
      return 1;
    }
    if ((a.daily_prices || 0) < (b.daily_prices || 0)) {
      return -1;
    }
    return 0;
  }

  function orderByLarger(a: PropertyDetails, b: PropertyDetails) {
    if ((a.daily_prices || 0) < (b.daily_prices || 0)) {
      return 1;
    }
    if ((a.daily_prices || 0) > (b.daily_prices || 0)) {
      return -1;
    }
    return 0;
  }

  function sortByPrice(e: any) {
    const option = e.target.value;

    const arrayAux = propertiesList;
    if (option === '1') {
      arrayAux?.sort(orderByLarger);
    } else {
      arrayAux?.sort(orderBySmaller);
    }

    setPropertiesList(arrayAux?.map((item) => item));
  }

  useEffect(() => {
    window.addEventListener('resize', useWindowSize);
  }, [mobile]);

  useEffect(() => {
    useWindowSize();
  }, []);

  return (
    <>
      <WrapperContainer>
        <Wrapper>
          <FilterBarContainer>
            <FilterBar />
          </FilterBarContainer>
          <SecondBar>
            {!propertiesList && (
            <InitialText>
              Selecione os filtros e clique em 'Buscar' para carregar os imóveis.
            </InitialText>
            )}
            {propertiesList && propertiesList?.length >= 1 && (
            <>
              <AllProperties>
                <p data-cy="txt-total-properties">
                  {propertiesList && propertiesList.length}
                  {' '}
                  imóveis
                </p>
              </AllProperties>
              <Order>
                <SimpleSelect
                  id="ordenar"
                  placeholder="Ordenar"
                  onChange={sortByPrice}
                  options={
                  (order || []).map<SelectOption>(({
                    id,
                    name,
                  }) => ({ value: id, valueLabel: name }))
                }
                />
              </Order>
            </>
            )}
          </SecondBar>

          <Container>
            {propertiesList && propertiesList.map((property) => (
              <CardProperty filterBar={filterBar} property={property} key={property.id} />
            ))}
          </Container>
        </Wrapper>
      </WrapperContainer>
    </>
  );
}
