import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

  gap: 15px;
  display: flex;
  flex-direction: column;

  p,
  h1,
  h2,
  a,
  span {
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const DateContent = styled(motion.div)`
  gap: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Section = styled(motion.div)`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-weight: normal;
    font-size: 1.1rem;
  }

  a {
    color: #1035f1b0;
    font-size: 1.1rem;
  }
`;

export const DateContainer = styled(motion.div)`
  gap: 10px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Date = styled(motion.p)`
  font-size: 1.1rem;
`;

function infoTextStyle() {
  return css`
    color: #000000b0;
    font-size: 1.2rem;
    font-weight: normal;
  `;
}

export const Title = styled(motion.h1)`
  ${infoTextStyle()}
`;

export const Code = styled(motion.span)`
  ${infoTextStyle()}
  font-weight: bold;
`;
export const Address = styled(motion.p)`
  ${infoTextStyle()}
`;
export const Cep = styled(motion.p)`
  ${infoTextStyle()}
`;
