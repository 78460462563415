import styled from 'styled-components';

export const ContainerBox = styled.div`
  width: 470px;
  height: 211px;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;

  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;

    &.highlight {
      color: ${({ theme }) => theme.palette.red._600.hex()};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0 0.5rem;
  }
`;
