import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 19px;

  color: #001840;
`;

export const StyledSection = styled(motion.div)`
  gap: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    opacity: 0.9;
    font-size: 1.1rem;
  }

  input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 7px;
    border: 1px solid #D9D9D9;
  }
`;
