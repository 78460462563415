/* eslint-disable no-console */
import { Coordinates } from './types';

const handleGeolocationError = async (error: GeolocationPositionError,
  resolve: (value: Coordinates) => void) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      console.warn('Permission to get location denied by the user.');
      break;
    case error.POSITION_UNAVAILABLE:
      console.warn('Location information is unavailable.');
      break;
    case error.TIMEOUT:
      console.warn('Timed out when trying to get the location.');
      break;
    default:
      console.warn('An unknown error occurred while trying to obtain the location.');
      break;
  }
  const latitude = '';
  const longitude = '';
  resolve({ latitude, longitude });
};

export const getCoordinates = (): Promise<Coordinates> => new Promise((resolve) => {
  const getLocation = async () => {
    let latitude = '';
    let longitude = '';

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        latitude = `${position.coords.latitude}`;
        longitude = `${position.coords.longitude}`;
        resolve({ latitude, longitude });
      },
      async (error: GeolocationPositionError) => {
        await handleGeolocationError(error, resolve);
      });
    } else {
      console.warn('Your browser does not support the Geolocation API.');
      resolve({ latitude, longitude });
    }
  };

  getLocation();
});

export const getBrowser = () => {
  const test = (regexp: RegExp) => (navigator?.userAgent ? regexp.test(navigator?.userAgent) : 'unknown');

  if (test(/opr\//i)) {
    return 'Opera';
  } if (test(/edg/i)) {
    return 'Microsoft Edge';
  } if (test(/chrome|chromium|crios/i)) {
    return 'Google Chrome';
  } if (test(/firefox|fxios/i)) {
    return 'Mozilla Firefox';
  } if (test(/safari/i)) {
    return 'Apple Safari';
  } if (test(/trident/i)) {
    return 'Microsoft Internet Explorer';
  } if (test(/ucbrowser/i)) {
    return 'UC Browser';
  } if (test(/samsungbrowser/i)) {
    return 'Samsung Browser';
  }
  return 'Unknown Browser';
};
