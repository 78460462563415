import React from 'react';
import RadioButton, { IRadioButtonProps, RadioButtonOptions } from './RadioButton';

type Props = Omit<IRadioButtonProps, 'options'>;

const RadioYesOrNo: React.FC<Props> = ({
  formLabel,
  id,
  labelPlacement,
  formik,
}) => {
  const options: RadioButtonOptions[] = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ];

  return (
    <RadioButton
      formLabel={formLabel}
      id={id}
      labelPlacement={labelPlacement}
      options={options}
      formik={formik}
    />
  );
};

export default RadioYesOrNo;
