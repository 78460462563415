import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { RequestLocation } from '../../services/location/types';
import { getLocations } from '../../services/location/request';

import { currencyToNumber } from '../../utils/Formatter';

import {
  FormButton, LateralModal, QuantityField, TextField,
} from '..';
import SelectCheckbox, { CheckboxOptions } from '../SelectCheckbox/SelectCheckbox';
import { ErrorIcon } from '../TextField/styles';
import FormMoreFilters from '../FormMoreFilters/FormMoreFilters';

import { BoldText } from '../Calendar/Reservation/Modal/styles';
import DatePicker from '../DatePicker/DatePicker';
import FormGridRow from '../FormGridRow';
import SelectModal from '../SelectModal/SelectModal';
import { useFilterBar } from '../../context/FilterBar';
import { useModal } from '../../hooks/ModalHook/useModal';
import {
  FormContainer, FormContent, DataGrid,
  SearchButton, InputModal, InputHideModal,
  InputHideSelect, Buttons, ButtonClear,
  DateContainer, Line, Localization, Price, Period, QuantityGuest, RowMobile, MoreFilters,
} from './styles';
import { currency } from '../../utils/InputMask/Number';

const FilterBar = () => {
  const { filterBar, setFilterBar } = useFilterBar();
  const [openDates, setOpenDates] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [priceMin, setPriceMin] = useState('R$ 300,00');
  const [priceMax, setPriceMax] = useState('R$ 1000,00');
  const { handleOpen } = useModal();
  const [openLateralModal, setOpenLateralModal] = useState(false);
  const [locations, setLocations] = useState<Array<RequestLocation>>();
  const [showErrorPrice, setShowErrorPrice] = useState<Boolean>(false);
  const [showErrorDate, setShowErrorDate] = useState<Boolean>(false);
  const [inputDate, setInputDate] = useState<string>('Selecione');

  const validation = Yup.object().shape({});
  const formik = useFormik({
    initialValues: {
      priceMin: '0',
      priceMax: '0',
      checkInDate: '',
      checkOutDate: '',
      guestsCapacity: 0,
      location: ['Selecione'],
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      setFilterBar({
        ...filterBar,
        ...values,
        checkInDate: moment(values.checkInDate),
        checkOutDate: moment(values.checkOutDate),
        search: true,
      });
    },
  });

  const getLocationList = async () => {
    const values = await getLocations();
    setLocations(values);
  };

  useEffect(() => {
    getLocationList();
  }, []);

  const handleOpenlLateral = () => {
    handleOpen(false);
    setOpenLateralModal(true);
  };

  const handleCloseAll = () => {
    setOpenLateralModal(false);
  };

  const handleOutClick = (event: any) => {
    if (event.target.id === 'inputModal') setOpen(false);
  };

  const handleOutMultipleSelect = (event: any) => {
    if (event.target.id === 'MultipleSelect') setOpenDialog(false);
  };

  const handleOutDates = (event: any) => {
    if (event.target.id === 'inputDates') setOpenDates(false);
  };

  const handleClickOpenDates = () => {
    setOpenDates(true);
  };

  function handlePrice() {
    if (currencyToNumber(formik.values.priceMax) <= currencyToNumber(formik.values.priceMin)) {
      setShowErrorPrice(true);
    } else {
      setPriceMin(formik.values.priceMin);
      setPriceMax(formik.values.priceMax);
      setShowErrorPrice(false);
      setOpen(!open);
    }
  }

  function checkDate() {
    return moment(formik.values.checkInDate).format('DD/MM/YYYY').toString() === 'Data inválida' || moment(formik.values.checkOutDate).format('DD/MM/YYYY').toString() === 'Data inválida' || moment(formik.values.checkOutDate) <= moment(formik.values.checkInDate);
  }

  function handleModal() {
    setOpen(!open);
  }

  function handleClearDate() {
    if (formik) {
      formik.setFieldValue('checkInDate', '');
      formik.setFieldTouched(
        'checkInDate', true, false,
      );
      formik.setFieldValue('checkOutDate', '');
      formik.setFieldTouched(
        'checkOutDate', true, false,
      );
    }
    setInputDate('Selecione');
    setOpenDates(false);
    setShowErrorDate(false);
  }

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleAppliedDateFilter = () => {
    const isInvalidDate = checkDate();
    if (isInvalidDate) {
      setOpenDates(true);
      setShowErrorDate(true);
    } else {
      setOpenDates(false);
      setShowErrorDate(false);
      setInputDate(`${moment(formik.values.checkInDate).format('DD/MM/YYYY')} à ${moment(formik.values.checkOutDate).format('DD/MM/YYYY')}`);
    }
  };

  return (
    <>
      <FormContainer>
        <FormContent onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
          <FormGridRow>
            <DataGrid>
              <RowMobile>
                <Localization>
                  <BoldText>Localização</BoldText>
                  <SelectModal
                    id="locations"
                    dataCy="locations"
                    onClick={handleClickOpen}
                    value={formik.values.location}
                  />
                  { openDialog && (
                  <>
                    <InputHideSelect id="MultipleSelect" onClick={handleOutMultipleSelect} />
                    <InputModal className="input-modal-style">
                      <SelectCheckbox
                        id="location"
                        formik={formik}
                        defaultValue={formik.values.location}
                        handleClose={handleClose}
                        options={
                      (locations || []).map<CheckboxOptions>(({
                        location,
                      }) => ({ value: location, valueLabel: location, checked: false }))
                    }
                      />
                    </InputModal>
                  </>
                  )}
                </Localization>
                <Price>
                  <BoldText>Preço da diária</BoldText>
                  <SelectModal
                    id="price"
                    dataCy="price"
                    onClick={handleModal}
                    value={`${currency(priceMin)} - ${currency(priceMax)}`}
                  />
                  { open && (
                  <>
                    <InputHideModal id="inputModal" onClick={handleOutClick} />
                    <InputModal>
                      <div className="group-price">
                        <div className="min">
                          <TextField
                            label="Valor min."
                            placeholder="R$ 300"
                            id="priceMin"
                            dataCy="priceMin"
                            mask="money"
                            formik={formik}
                          />
                        </div>
                        <span> - </span>
                        <div className="max">
                          <TextField
                            label="Valor max."
                            placeholder="R$ 1000"
                            id="priceMax"
                            dataCy="priceMax"
                            mask="money"
                            formik={formik}
                          />
                        </div>
                      </div>
                      { showErrorPrice && (
                        <>
                          <p className="messageError">
                            <ErrorIcon />
                            O preço máximo deve ser maior que o mínimo!
                          </p>
                        </>
                      )}
                      <div className="buttonApply">
                        <FormButton type="button" variant="outlined" onClick={handlePrice}>Aplicar</FormButton>
                      </div>
                    </InputModal>
                  </>
                  )}
                </Price>
              </RowMobile>
              <RowMobile>
                <Period>
                  <BoldText>Período</BoldText>
                  <SelectModal
                    id="dates"
                    dataCy="dates"
                    onClick={handleClickOpenDates}
                    value={inputDate}
                  />
                  { openDates && (
                  <>
                    <InputHideModal id="inputDates" onClick={handleOutDates} />
                    <InputModal className="input-modal-style">
                      <DateContainer>
                        <BoldText className="style-date">Check-in</BoldText>
                        <DatePicker
                          formik={formik}
                          id="checkInDate"
                          dataCy="checkInDate"
                          minDate={new Date('2000-01-02')}
                          className="filter"
                        />
                      </DateContainer>
                      <DateContainer>
                        <BoldText className="style-date">Check-out</BoldText>
                        <DatePicker
                          formik={formik}
                          id="checkOutDate"
                          dataCy="checkOutDate"
                          minDate={new Date('2000-01-02')}
                        />
                      </DateContainer>
                      <Line />
                      { showErrorDate && (
                        <p className="messageError">
                          <ErrorIcon />
                          A data de check-out deve ser maior do que a de check-in!
                        </p>
                      )}
                      <Buttons>
                        <ButtonClear onClick={handleClearDate}>Limpar</ButtonClear>
                        <FormButton type="button" variant="outlined" onClick={handleAppliedDateFilter}>Aplicar</FormButton>
                      </Buttons>
                    </InputModal>
                  </>
                  )}
                </Period>
                <QuantityGuest>
                  <BoldText>Nº de hóspedes</BoldText>
                  <div className="qtdGuests">
                    <QuantityField formik={formik} id="guestsCapacity" border={false} justify={false} dataCy="add-guests" />
                  </div>
                </QuantityGuest>
              </RowMobile>
              <SearchButton>
                <FormButton
                  type="submit"
                >
                  Buscar
                </FormButton>
              </SearchButton>
              <MoreFilters>
                <span
                  role="button"
                  onKeyDown={handleOpenlLateral}
                  onClick={handleOpenlLateral}
                  tabIndex={0}
                  data-cy="more-filter"
                >
                  + Filtros

                </span>
              </MoreFilters>
            </DataGrid>
            <LateralModal
              open={openLateralModal}
              setOpen={setOpenLateralModal}
              onClose={handleCloseAll}
              headerTitle="Filtros"
            >
              <FormMoreFilters isReloadShimmer={false} onClose={handleCloseAll} />
            </LateralModal>
          </FormGridRow>
        </FormContent>
      </FormContainer>
    </>
  );
};

export default FilterBar;
