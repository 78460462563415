import styled from 'styled-components';

export const Container = styled.div`
  --dark-color: ${({ theme }) => theme.palette.blue._950.hex()};
  --light-color: ${({ theme }) => theme.palette.white.main.hex()};
  text-align: center;
  justify-content:center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Date = styled.div<{ borderActive: boolean, weekend: boolean, showBackgroudColor: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({ showBackgroudColor }) => (showBackgroudColor ? '#E4EAEF' : '#F7FAFC')};
  padding: 0 8px;

  & > div {
    & > div {
      transform: scale(1.1);
      display: flex;
    }
  }
`;

export const DateMonth = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--dark-color);
  `;

export const DateNumber = styled.div<{ active: boolean }>`
  margin-right: 2px;
  background: ${({ active }) => (active ? 'linear-gradient(180deg, #0D4BD0 0%, #0038B9 100%)' : 'none')};
  border-radius: 50%;
  width: ${({ active }) => (active ? '23px' : 'auto')};
  height: ${({ active }) => (active ? '23px' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ active }) => (active ? '15px' : '4px')};;

  & > p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    letter-spacing: -0.02em;
    color: ${({ active }) => (active ? 'var(--light-color)' : 'var(--dark-color)')};
  }
`;

export const DateWeekDay = styled.p<{ weekend: boolean }>`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  display: flex;
  line-height: 16px;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.blue._700.hex()};
  justify-content: center;
`;

export const Items = styled.div``;

export const MobileContainer = styled.div<{ showBackgroundColor: boolean }>`
  display: flex; 
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  background-color: ${({ showBackgroundColor }) => (showBackgroundColor ? '#E4EAEF' : '#F7FAFC')};
`;

export const NextWeek = styled.button`
  background-color: #FFFFFF;
  border-radius: 9999999px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #0D4BD0;
  padding: 0.5rem;
  box-shadow: 0px 0px 3.2px 0px #00000026;


  svg {
    width: 24;
    height: 24;
  }
`;

export const LastWeek = styled.button`
  background-color: #FFFFFF;
  border-radius: 9999999px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #0D4BD0;
  padding: 0.5rem;
  box-shadow: 0px 0px 3.2px 0px #00000026;

  svg {
    width: 24;
    height: 24;
  }
`;

export const CardCounter = styled.div`
  width: 100%;
  max-width: 250px;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #FFFF;
  border-radius: 7px;
  padding: 4px 4px;
`;

export const CardCounterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1) !important;
  color: rgba(98, 101, 111, 1);

  span {
    white-space: nowrap;
  }
`;

export const Image = styled.img`
  height: 20px;
  width: auto;
`;
