import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import { GetGuestDamage, RefundHolder } from '../../../../services/GuestDamage/types';
import SimpleSelect from '../../../SimpleSelect';
import { patchGuestDamage } from '../../../../services/GuestDamage/request';
import { useToast } from '../../../../context/ToastContext';

interface TableSelectProps {
  guestDamage: GetGuestDamage;
}

export const TableSelect = ({ guestDamage }: TableSelectProps) => {
  const { setReservationGuestDamages } = useGuestDamage();
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      refundHolder: guestDamage.refund_holder as RefundHolder | undefined,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    async function handleChangeRefundHolder(newGuestDamage: GetGuestDamage,
      refundHolder?: RefundHolder) {
      try {
        await patchGuestDamage(guestDamage.id as number, {
          refund_holder: formik.values.refundHolder,
        });
        setReservationGuestDamages((oldValues) => {
          const newReservationGestDamages = oldValues.map((reservationGuestDamage) => {
            if (reservationGuestDamage.id as number === newGuestDamage.id) {
              return {
                ...reservationGuestDamage,
                refund_holder: refundHolder,
              };
            }
            return reservationGuestDamage;
          });
          return newReservationGestDamages;
        });
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Não foi possível alterar o dono do dano!');
        }
      }
    }
    handleChangeRefundHolder(guestDamage, formik.values.refundHolder);
  }, [formik.values.refundHolder]);
  return (
    <SimpleSelect
      id="refundHolder"
      placeholder="Selecione"
      formik={formik}
      options={[
        { value: 'Seazone', valueLabel: 'Seazone' },
        { value: 'Proprietário', valueLabel: 'Proprietário' },
      ]}
    />
  );
};
