import React from 'react';

import { Outlet } from 'react-router-dom';
import { IndicateProvider } from '../../../../context/Partners/IndicateContext/IndicateContext';

import {
  Content,
  Container,
} from './style';

const Indicate = () => (
  <Container>
    <Content>
      <Outlet />
    </Content>
  </Container>
);

const IndicateWithProvider = () => (
  <IndicateProvider>
    <Indicate />
  </IndicateProvider>
);

export default IndicateWithProvider;
