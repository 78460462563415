import styled from 'styled-components';

export const Container = styled.div<{ opacity: number }>`
  width: 100%;
  height: 100px;
  border-radius: 11px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  opacity: ${({ opacity }) => opacity};

  &:hover {
    cursor: pointer;

    box-shadow:
    0px 0.2px 0.5px rgba(0, 0, 0, 0.029),
    0px 0.4px 1.1px rgba(0, 0, 0, 0.043),
    0px 0.8px 2.1px rgba(0, 0, 0, 0.053),
    0px 1.3px 3.8px rgba(0, 0, 0, 0.06),
    0px 2.5px 7.1px rgba(0, 0, 0, 0.065),
    0px 6px 17px rgba(0, 0, 0, 0.07)
  ;
  }

`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
  padding: 15px 0;
`;

export const GuestName = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
`;

export const TextInfo = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
`;

export const Date = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 300;
  font-size: 0.625rem;
  letter-spacing: -0.02em;
`;

export const TotalValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;
export const AmountPaid = styled.div`
  width: 28%;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.green._120.hex()};
  border-radius: 0px 5px 5px 0px;
`;
export const Text = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: center;
`;
export const Amount = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
`;
