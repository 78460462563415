import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import moment from 'moment';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import Tooltip from '../../Tooltip';
import CardReservation from '../CardReservation';
import { CardAreaSelected } from '../ContentConciliation/styles';
import ModalConfirm from '../ModalConcililiation/ModalConfirm';
import {
  ButtonDisconnectConciliation,
  Container,
  Content,
  ContentConciledPayments,
  Dates, HeaderInformations,
  ImmobileOta,
  NameGuest,
  RemoveSelection, Row, Value,
} from './styles';
import arrow from '../../../assets/icons/conciliation/arrow.svg';
import CardPayment from '../CardPayment';
import { numberToCurrency } from '../../../utils/Formatter';

const DisconnectConciliation: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const {
    reservation, removeReservation, setPaymentMove, addNewPayment, reservationMove,
  } = useConciliation();
  const dropArray = useDrop({
    accept: 'Card',
    drop: () => ({ name: 'payment-area' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (reservationMove?.payment && reservationMove?.payment.id) {
      setPaymentMove(reservationMove?.payment);
      addNewPayment();
    }
  }, [reservationMove.id]);
  return (
    <Container>
      {!reservation.id && (
      <Content>
        <CardAreaSelected ref={dropArray[1]}>
          {reservation.id && (
          <CardReservation reservation={reservation} />
          )}
          {!reservation.id && (
          <>
            <img src={arrow} alt="Seta" />
            <h2>Selecione uma reserva</h2>
            <p>Selecione ou arraste</p>
          </>
          )}
        </CardAreaSelected>
      </Content>
      )}
      {reservation.id && (
        <>
          <HeaderInformations>
            <div>
              <NameGuest>
                {reservation.guest.user.first_name}
                {' '}
                {reservation.guest.user.last_name}
              </NameGuest>
              <ImmobileOta>
                {reservation.property.code}
                {' '}
                |
                {' '}
                {reservation.ota.name}
              </ImmobileOta>
            </div>
            <Dates>
              Check-in:
              {' '}
              {moment(reservation.check_in_date).format('DD/MM/YYYY')}
              {' '}
            </Dates>
            <Dates>
              Check-out:
              {' '}
              {moment(reservation.check_out_date).format('DD/MM/YYYY')}
            </Dates>
            <Value>
              {reservation.payment?.amount_paid
                ? numberToCurrency(reservation.total_price) : 0}

            </Value>
            <Tooltip text="Remover reserva selecionada">
              <RemoveSelection type="button" onClick={removeReservation}>Remover seleção</RemoveSelection>
            </Tooltip>
          </HeaderInformations>
          <ContentConciledPayments>
            <h1>Com o que está conciliado</h1>
            <Row>
              {reservation?.payment && (
                <CardPayment payment={reservation.payment} />
              )}
              <ButtonDisconnectConciliation onClick={() => setOpenModal(true)}>
                Desconciliar
              </ButtonDisconnectConciliation>
            </Row>
          </ContentConciledPayments>
          <ModalConfirm
            guestReservation={`${reservation.guest?.user?.first_name} ${reservation.guest?.user?.last_name}`}
            type="DESCONCILIAR"
            open={openModal}
            setOpen={setOpenModal}
            value={reservation.payment?.amount_paid ? reservation.payment?.amount_paid : '0'}
          />
        </>
      )}
    </Container>
  );
};

export default DisconnectConciliation;
