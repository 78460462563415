import { FinancialCloseHostResponse, FormattedFinancialCloseResponseData, TotalData } from '../../services/HostDashboard/types';
import { currencyToNumber, numberToCurrency } from '../Formatter';

const options = [
  'Receita de reservas',
  'Comissão de reservas',
  'Repasse da taxa de limpeza',
  'Número de reservas',
  'Número de limpezas',
  'Reembolso de despesas',
  'Reembolso de onboarding',
  'Repasse',
];

const initialValues = {
  title: '',
  jan: '0',
  fev: '0',
  mar: '0',
  abr: '0',
  mai: '0',
  jun: '0',
  jul: '0',
  ago: '0',
  set: '0',
  out: '0',
  nov: '0',
  dez: '0',
};

const months: string[] = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

export const formatFinancialCloseHost = (data: FinancialCloseHostResponse, year: string) => {
  const financialData: FormattedFinancialCloseResponseData[] = [];

  options.forEach((option) => {
    const dataFormatted: FormattedFinancialCloseResponseData = { ...initialValues };
    dataFormatted.title = option;

    months.forEach((month, index) => {
      let total = 0;

      Object.keys(data).forEach((propertyId: string) => {
        const item = data?.[propertyId]?.[year];
        const monthId = index + 1;

        switch (option) {
          case 'Receita de reservas':
            total += Number(item?.[monthId]?.incomes?.reservations_incomes || 0);
            dataFormatted[month] = total === 0 ? '-' : numberToCurrency(total);
            break;

          case 'Comissão de reservas':
            total += Number(item?.[monthId]?.incomes?.reservations_comission || 0);
            dataFormatted[month] = total === 0 ? '-' : numberToCurrency(total);
            break;

          case 'Repasse da taxa de limpeza':
            total += Number(item?.[monthId]?.incomes?.cleaning_incomes || 0);
            dataFormatted[month] = total === 0 ? '-' : numberToCurrency(total);
            break;

          case 'Número de reservas':
            total += Number(item?.[monthId]?.performed?.reservations_amount || 0);
            dataFormatted[month] = total === 0 ? '-' : `${total}`;
            break;

          case 'Número de limpezas':
            total += Number(item?.[monthId]?.performed?.cleaning_amount || 0);
            dataFormatted[month] = total === 0 ? '-' : `${total}`;
            break;

          case 'Reembolso de despesas':
            total += Number(item?.[monthId]?.refund?.refund_expenses || 0);
            dataFormatted[month] = total === 0 ? '-' : numberToCurrency(total);
            break;

          case 'Reembolso de onboarding':
            total += Number(item?.[monthId]?.refund?.refund_onboarding || 0);
            dataFormatted[month] = total === 0 ? '-' : numberToCurrency(total);
            break;

          case 'Repasse':
            total += Number(item?.[monthId]?.transfer || 0);
            dataFormatted[month] = total === 0 ? '-' : numberToCurrency(total);
            break;

          default:
            break;
        }
      });
    });

    financialData.push(dataFormatted);
  });

  return financialData;
};

const calculateTotal = (item: FormattedFinancialCloseResponseData) => {
  const jan = item.jan === '-' ? 0 : currencyToNumber(item.jan);
  const fev = item.fev === '-' ? 0 : currencyToNumber(item.fev);
  const mar = item.mar === '-' ? 0 : currencyToNumber(item.mar);
  const abr = item.abr === '-' ? 0 : currencyToNumber(item.abr);
  const mai = item.mai === '-' ? 0 : currencyToNumber(item.mai);
  const jun = item.jun === '-' ? 0 : currencyToNumber(item.jun);
  const jul = item.jul === '-' ? 0 : currencyToNumber(item.jul);
  const ago = item.ago === '-' ? 0 : currencyToNumber(item.ago);
  const set = item.set === '-' ? 0 : currencyToNumber(item.set);
  const out = item.out === '-' ? 0 : currencyToNumber(item.out);
  const nov = item.nov === '-' ? 0 : currencyToNumber(item.nov);
  const dez = item.dez === '-' ? 0 : currencyToNumber(item.dez);

  const total = (jan + fev + mar + abr + mai + jun + jul + ago + set + out + nov + dez);

  if (total === 0) {
    return '-';
  }

  if (!item.title.includes('Número')) {
    return numberToCurrency(total);
  }

  return `${total}`;
};

export const getFinancialDataTotal = (financialData: FormattedFinancialCloseResponseData[]):
TotalData => {
  const total: TotalData = {
    reservationsAmount: '0',
    cleaningAmount: '0',
    reservationsCommission: '0',
    reservationsIncomes: '0',
    cleaningIncomes: '0',
    refundExpenses: '0',
    onboardingExpenses: '0',
    transfer: '0',
  };

  financialData.forEach((item) => {
    switch (item.title) {
      case 'Receita de reservas':
        total.reservationsIncomes = calculateTotal(item);
        break;

      case 'Comissão de reservas':
        total.reservationsCommission = calculateTotal(item);
        break;

      case 'Repasse da taxa de limpeza':
        total.cleaningIncomes = calculateTotal(item);
        break;

      case 'Número de reservas':
        total.reservationsAmount = calculateTotal(item);
        break;

      case 'Número de limpezas':
        total.cleaningAmount = calculateTotal(item);
        break;

      case 'Reembolso de despesas':
        total.refundExpenses = calculateTotal(item);
        break;

      case 'Reembolso de onboarding':
        total.onboardingExpenses = calculateTotal(item);
        break;

      case 'Repasse':
        total.transfer = calculateTotal(item);
        break;

      default:
        break;
    }
  });

  return total;
};
