import React from 'react';
import { UseFormik } from '../../utils/Formik/types';
import { AirbnbSlider, ContentLabel, LabelText } from './style';

interface PropsCustomSlider {
  valueMin: number;
  valueMax: number;
  formik: UseFormik<any>;
  id: string,
}

const CustomSlider = ({
  valueMax, valueMin, id, formik,
}: PropsCustomSlider): JSX.Element => {
  function onChange(e: number[]) {
    if (formik) {
      formik.setFieldValue(`${id}Min`, e[0]);
      formik.setFieldTouched(id);

      formik.setFieldValue(`${id}Max`, e[1]);
      formik.setFieldTouched(id);
    }
  }

  return (
    <>
      <ContentLabel>
        <LabelText>
          R$
          {valueMin}
        </LabelText>
        <LabelText>
          R$
          {valueMax}
        </LabelText>
      </ContentLabel>
      <AirbnbSlider
        getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
        defaultValue={[valueMin, valueMax]}
        valueLabelDisplay="auto"
        min={300}
        max={5000}
      // @ts-ignore
        onChange={(e) => onChange(e.target.value)}
      />
      <ContentLabel>
        <LabelText>Min.</LabelText>
        <LabelText>Máx.</LabelText>
      </ContentLabel>
    </>
  );
};

export default CustomSlider;
