import {
  StatusProps,
  EnumStatus,
  IListItems,
  OrderBy,
  Order,
} from '../types';

import { compareList } from '../../../utils/Sorting';

export const NFLISTPAGEMARGIN = 50;

export const GRIDMAXITEMS = 10;

export const nfListDefaultLoadingTime = 2000;

export const gridTemplateColumnsDesktop = '350px 350px 300px 300px 200px 200px 200px 380px 200px 200px 150px 200px 100px 150px 250px ';

export const GRIDITEMS = [
  'Status',
  'Nome',
  'Nome Nota Fiscal',
  'Anfitrião',
  'Comissão',
  'CPF',
  'CNPJ',
  'Rua',
  'Número',
  'Complemento',
  'Bairro',
  'Cidade',
  'Estado',
  'CEP',
  'E-mail',
];

export const convertStringToStatusProps = (status: string): StatusProps => {
  switch (status) {
    case EnumStatus.notDone:
      return 'Not_Concluded';
    case EnumStatus.done:
      return 'Concluded';
    case EnumStatus.withAdjustments:
      return 'Pending';
    default:
      return EnumStatus.notDone;
  }
};

export const convertStatusPropsToStatusToShow = (status: StatusProps): string => {
  switch (status) {
    case EnumStatus.notDone:
      return 'Não concluído';
    case EnumStatus.done:
      return 'Concluido';
    case EnumStatus.withAdjustments:
      return 'Com ajustes';
    default:
      return 'Não concluído';
  }
};

export const translateNfLabel: Record<string, string> = {
  Nome: 'name',
  'Nome Nota Fiscal': 'nameOfNf',
  Anfitrião: 'host',
  Comissão: 'commission',
  CPF: 'cpf',
  CNPJ: 'cnpj',
  Rua: 'address',
  Número: 'address_number',
  Complemento: 'complement',
  Bairro: 'district',
  Estado: 'state',
  CEP: 'postal_code',
  'E-mail': 'email',
  Status: 'status',
};

export const sortNfListItems = (
  listItems: IListItems[],
  orderBy: OrderBy,
  order: Order,
): IListItems[] => {
  let sorted: IListItems[] = listItems;

  switch (orderBy) {
    case 'name':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.name, b.name, order,
      ));
      break;

    case 'nameOfNf':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.nameOfNf, b.nameOfNf, order,
      ));
      break;

    case 'host':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.host, b.host, order,
      ));
      break;

    case 'commission':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.commission, b.commission, order,
      ));
      break;

    case 'cpf':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.cpf, b.cpf, order,
      ));
      break;

    case 'cnpj':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.cnpj, b.cnpj, order,
      ));
      break;

    case 'address':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.address, b.address, order,
      ));
      break;

    case 'email':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.email, b.email, order,
      ));
      break;

    case 'status':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.status, b.status, order,
      ));
      break;

    default:
      return sorted;
  }

  return sorted;
};
