import React from 'react';
import ConciliationCompenents from '../../components/ConciliationCompenents';

import { Container } from './styles';

const Conciliation: React.FC = () => (
  <Container>
    <ConciliationCompenents />
  </Container>
);

export default Conciliation;
