/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { TrashAnimation } from '../../../../../../../assets/animations/components/Trash';

import arrowBottomGrayIcon from '../../../../../../../assets/icons/generals/arrowBottomGrey.svg';
import bashRedIcon from '../../../../../../../assets/icons/generals/bashRed.svg';

import {
  Container, LeftSide, RightSide, Title,
} from './styles';

interface HeaderProps {
  open: boolean;
  onClose: Function;
  index?: number;
  onBashClick: Function;
}

const Header = ({
  open, onClose, index, onBashClick,
}: HeaderProps) => {
  const [startAnimation, setStartAnimation] = React.useState(false);

  const handleStartAnimation = () => {
    setStartAnimation(true);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setStartAnimation(false);
    }, 8000);
  }, [handleStartAnimation]);

  return (
    <Container>
      <LeftSide isOpen={open} onClick={() => onClose()}>
        <div>
          <Title isOpen={open}>Hóspede</Title>
          <span>
            {index === 1 ? 'principal' : index}
          </span>
        </div>
        <button type="button" onClick={() => onClose()}>
          <img src={arrowBottomGrayIcon} alt="Mostrar mais" />
        </button>
      </LeftSide>
      {index !== 1 && (
      <RightSide
        onMouseOver={handleStartAnimation}
        onClick={() => onBashClick()}
      >
        <TrashAnimation startPlay={startAnimation} />
      </RightSide>
      )}
    </Container>
  );
};

Header.defaultProps = {
  index: 1,
};

export default Header;
