import { FC } from 'react';

import Tag from '../Tag';

import { useOwnerExtract } from '../../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';

import {
  Container,
  Content,
  TitleContainer,
  Divider,
} from './styles';

const WalletSection: FC = () => {
  const { extractList } = useOwnerExtract();

  return (
    <Container>
      <Content>
        <Tag
          label="Carteira"
          disableDropIcon
          description="Valor a ser transferido para a conta do proprietário no dia 10 do mês seguinte"
          stickTag
        />
        {/* <TitleContainer>
          <span>Saldo devedor</span>
          <Divider />
          <span>{`${numberToCurrency(extractList?.[0]?.wallet?.debit_balance)}`}</span>
        </TitleContainer> */}
        <TitleContainer>
          <span>Repasse</span>
          <Divider />
          <span>{`${extractList?.[0]?.wallet?.transfer}`}</span>
        </TitleContainer>
      </Content>
    </Container>
  );
};

export default WalletSection;
