import React from 'react';
import { CircularProgress } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { Close } from '@mui/icons-material';

import {
  Button,
  DeleteButton,
  DeleteButtonContainer,
} from './style';

import { BasePreCheckinProps } from '../../types/props';
import { useToast } from '../../../../../../../../../context/ToastContext';
import { deleteGuests } from '../../../../../../../../../services/PreCheckin';
import { usePreCheckin } from '../../../../../../../../../context/PreCheckin/PreCheckin';
import { TrashAnimation } from '../../../../../../../../../assets/animations/components/Trash';

interface IFadeAnimation {
  loading?: boolean;
  children: React.ReactNode;
}

const FadeAnimation = ({
  loading,
  children,
}: IFadeAnimation) => (
  <Button
    initial={{
      y: 10,
      opacity: 0,
    }}
    animate={{
      y: 0,
      opacity: 1,
    }}
  >
    {loading
      ? (<CircularProgress className="loading" size={30} style={{ color: '#fdfdfdaf' }} />)
      : children}
  </Button>
);

FadeAnimation.defaultProps = {
  loading: false,
};

interface IDelete {
  confirmDelete: boolean;
  setConfirmDelete: Function;
  guest: BasePreCheckinProps['guest'];
  disabled: boolean;
}

const Delete = ({
  guest,
  confirmDelete,
  setConfirmDelete,
  disabled,
}: IDelete) => {
  const toast = useToast();

  const {
    token,
    guests,
    setGuests,
  } = usePreCheckin();

  const [loading, setLoading] = React.useState(false);

  const handleConfirmDeletion = () => {
    const handleFilter = () => {
      const guestFiltered = guests.list.filter((item) => item.id !== guest.id);
      setGuests((old) => ({ ...old, list: guestFiltered }));
    };

    if (guest?.isPut?.is && token) {
      const handleDeleteGuest = async () => {
        try {
          setLoading(true);
          await deleteGuests({
            token,
            id: guest.id,
          });
          handleFilter();
        } catch (e) {
          toast.error('Ocorreu um erro ao deletar hóspede !');
        } finally {
          setLoading(false);
        }
      };

      handleDeleteGuest();
    } else {
      handleFilter();
    }
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
  };

  const handleClickDelete = () => {
    if (disabled) {
      return;
    }

    setConfirmDelete(true);
  };

  if (confirmDelete) {
    return (
      <>
        <DeleteButtonContainer>
          <DeleteButton
            loading={loading}
            onClick={handleConfirmDeletion}
            whileTap={{ scale: 0.8 }}
            style={{ background: '#21bf49af' }}
          >
            <FadeAnimation loading={loading}>
              <DoneIcon
                scale={10}
                className="confirm_icons"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                }}
              />
            </FadeAnimation>
          </DeleteButton>

          <DeleteButton
            disabled={loading}
            whileTap={{ scale: 0.8 }}
            onClick={handleCancelDelete}
            style={{ background: '#ed2b2bb0' }}
          >
            <FadeAnimation>
              <Close
                scale={3}
                style={{ color: 'white' }}
                className="confirm_icons"
              />
            </FadeAnimation>
          </DeleteButton>
        </DeleteButtonContainer>
      </>
    );
  }

  return (
    <DeleteButtonContainer>
      <DeleteButton
        loading={loading}
        whileTap={{ scale: 0.8 }}
        onClick={handleClickDelete}
      >
        <FadeAnimation loading={loading}>
          <TrashAnimation width={50} loop={false} />
        </FadeAnimation>
      </DeleteButton>
    </DeleteButtonContainer>
  );
};
export default Delete;
