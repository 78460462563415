import styled from 'styled-components';
import { animationTime, shimmerAnimation } from '../../../Calendar/LoadingShimmer/animationShimmer';

export const Container = styled.div`
  div {
    border-left: 1px solid #d3d3d3;

    &:first-child {
      border-left: none;
    }
  }
`;

export const Content = styled.div`
  border: 1px solid transparent;
  display: grid;
  margin-top: 5px;
  width: max-content;
  grid-template-columns: 200px 200px 120px 150px 300px 300px 220px 150px 200px 200px 150px 200px;
`;

export const ItemShimmer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => (theme.palette.grey._340.hex())};
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
  padding: 20px;
`;

export const ItemContentShimmer = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
`;
