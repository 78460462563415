import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1``;

export const Container = styled.form`
  width: 100vw;
  max-width: 600px;
  height: auto;
  padding: 2rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 1.875rem;
  }
`;

export const Content = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  .labelClass {
    font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
    font-family: ${({ theme }) => theme.fonts.familys._3};
    font-size: 1rem;
    }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: flex-end;
  border: none;
  cursor: pointer;
  margin-top: 2rem;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  color: ${({ theme }) => theme.palette.red._450.hex()};
  img {
    fill: ${({ theme }) => theme.palette.red._450.hex()};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  width: 100%;
`;

export const ButtonNewCodeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.9rem;
`;
