import styled from 'styled-components';
import { MAX_SIZE_MOBILE } from '../../utils';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  
  strong {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.blue._400.hex()};
    font-weight: 500;
    font-size: 12px;
    line-height: 5px;
    margin-right: 0.5rem;
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    strong {
      display: none;
    }
  }
`;
