import * as Yup from 'yup';

export const validation = Yup.object().shape({
  wantToReceiveByPix: Yup.string().required('Campo obrigatório'),
  bankAgency: Yup.string()
    .when('wantToReceiveByPix', {
      is: 'no',
      then: Yup.string().required('A agência é obrigatória, pois você selecionou que não deseja receber a transferencia via PIX'),
    }),
  bankName: Yup.string()
    .when('wantToReceiveByPix', {
      is: 'no',
      then: Yup.string().required('O nome do banco é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX'),
    }),
  bankNumber: Yup.string()
    .when('wantToReceiveByPix', {
      is: 'no',
      then: Yup.string().required('O número do banco é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX'),
    }),
  cpfOrCnpjOfFavored: Yup.string()
    .when('wantToReceiveByPix', {
      is: 'no',
      then: Yup.string().required('O CPF ou CNPJ do favorecido é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX'),
    }),
  bankAccountType: Yup.string()
    .when('wantToReceiveByPix', {
      is: 'no',
      then: Yup.string().required('O tipo da conta é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX'),
    })
    .notOneOf(['Selecione'],
      'O tipo da conta é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX"'),
  bankAccountNumber: Yup.string()
    .when('wantToReceiveByPix', {
      is: 'no',
      then: Yup.string().required('O número da conta é obrigatório, pois você selecionou que não deseja receber a transferencia via PIX'),
    }),
});
