import { useNavigate } from 'react-router-dom';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {
  Container, IconContainer,
} from './styles';

type CardProps = {
  name: string,
  route: string };

const Card = ({ name, route }:CardProps) => {
  const navigate = useNavigate();

  function handleRedirect(optionName: string) {
    navigate(optionName);
  }

  return (
    <Container onClick={() => handleRedirect(route)}>
      {name}
      <IconContainer>
        <ArrowForwardIosIcon />
      </IconContainer>
    </Container>
  );
};

export default Card;
