import styled, { keyframes } from 'styled-components';

const shakeAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;
    border: 2px dashed #d1d1d1;
    transition: 0.3s;
    font-size: 1.3rem;
    font-weight: bold;
    background: transparent;
    border-radius: 10px;
    font-family: ${({ theme }) => theme.fonts.familys._1};

    padding-right: 40px;
    img {
      width: 26px;
      transition: 0.4s;
    }
  }

  button:hover {
    img {
      animation: ${shakeAnimation} 0.9s infinite ease-in-out;
    }
    box-shadow: 0px 3px 1px #910b0b30, 0 10px 20px #b65c5c49;
  }
`;
