import Tooltip from '../../../../../../../../../components/Tooltip';
import { usePartnerIndicate } from '../../../../../../../../../context/Partners/IndicateContext/IndicateContext';

import { Container } from './styles';

interface Props {
  activeBorder?: boolean,
  fileId: string,
}

const DeleteFile = ({ activeBorder = false, fileId }: Props) => {
  const {
    openModalWithFiles,
    propertyImages,
    setPropertyImages,
    propertyDocuments,
    setPropertyDocuments,
  } = usePartnerIndicate();

  const handleDeleteFile = () => {
    const files = openModalWithFiles.uploadType === 'photos' ? propertyImages : propertyDocuments;
    const filtereds = files.filter((item) => item.id !== fileId);
    if (openModalWithFiles.uploadType === 'photos') {
      setPropertyImages(filtereds);
    } else {
      setPropertyDocuments(filtereds);
    }
  };

  return (
    <Tooltip text="Excluir arquivo">
      <Container activeBorder={activeBorder} onClick={() => handleDeleteFile()}>
        <svg
          width="20"
          height="15"
          fill="none"
          viewBox="0 0 6 7"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="black"
            fillOpacity="0.7"
            d="M5.59836 0.817474H4.38349L4.03639 0.470367H2.30085L1.95375 0.817474H0.738876V1.51169H5.59836M1.08598 6.02407C1.08598 6.20818 1.15912 6.38476 1.28931 6.51495C1.4195 6.64514 1.59608 6.71828 1.7802 6.71828H4.55705C4.74116 6.71828 4.91774 6.64514 5.04793 6.51495C5.17812 6.38476 5.25126 6.20818 5.25126 6.02407V1.85879H1.08598V6.02407Z"
          />
        </svg>
      </Container>
    </Tooltip>
  );
};

DeleteFile.defaultProps = {
  activeBorder: false,
};

export default DeleteFile;
