/* eslint-disable @typescript-eslint/no-unused-vars */
import { Edit } from '@mui/icons-material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useToast } from '../../context/ToastContext';
import { useUser } from '../../context/UserContext';
import { getViaCeps, patchAddress } from '../../services/Address/request';
import { ViaCepResponse } from '../../services/Address/types';
import { getHostBank } from '../../services/Host/request';
import { BankDetails } from '../../services/Owner/types';
import { getUserInformation, patchUser } from '../../services/User/request';
import { useToastErrorMessage } from '../../utils/Messages';
import BankAccountComponent from './BankAccount';
import ButtonAdd from '../ButtonAdd';
import TextField from '../TextField/TextField';
import ModalNewBankAccount from './ModalNewBankAccount';
import {
  ButtonCancell,
  ButtonEdit,
  ButtonSave,
  Container, ContentAccountBank, ContentButons, Divider, Row,
} from './styles';

const PersonalDataHost: React.FC = () => {
  const [mobile, setMobile] = useState<boolean>(false);
  const [openMOdal, setOpenModal] = useState<boolean>(false);
  const [listAccountBank, setListAccountBank] = useState<Array<BankDetails>>([]);
  const [address, setAddress] = useState<ViaCepResponse>({} as ViaCepResponse);
  const [activeButtons, setActiveButtons] = useState<boolean>(false);
  const [isEditBank, setIsEditBank] = useState(false);
  const { userInformation, user, setUserInformation } = useUser();
  const toastErrorRequest = useToastErrorMessage();
  const [dataAccountBank, setDataAccountBank] = useState<BankDetails>();
  const toast = useToast();

  async function loadUser() {
    const userDetails = await getUserInformation();
    if (userDetails) {
      setUserInformation(userDetails);
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: userInformation?.first_name || '',
      lastName: userInformation?.last_name || '',
      nickname: userInformation?.nickname || '',
      cpf: userInformation?.cpf || '',
      cnpj: userInformation?.cnpj || '',
      phone: userInformation?.phone_number1 || '',
      email: user?.email || '',
      cep: userInformation?.main_address?.postal_code || '',
      street: userInformation?.main_address?.street || '',
      number: userInformation?.main_address?.number || '',
      city: userInformation?.main_address?.city || '',
      neighborhood: userInformation?.main_address?.neighborhood || '',
      complement: userInformation?.main_address?.complement || '',
      state: userInformation?.main_address?.state || '',
    },
    onSubmit: async (values) => {
      try {
        patchUser(parseInt(userInformation?.user_id || '0', 10), {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          cpf: userInformation?.is_individual ? (values.cpf.split('.').join('')).split('-').join('') : '',
          cnpj: userInformation?.is_individual ? '' : (((values.cnpj.split('.').join('')).split('.').join(''))
            .split('/')
            .join(''))
            .split('-')
            .join(''),
          gender: userInformation?.gender || '',
          trading_name: userInformation?.is_individual ? values.nickname : '',
          corporate_name: userInformation?.is_individual ? values.firstName : '',
          phone_number1: values.phone,
          is_individual: userInformation?.is_individual || false,
          main_role: 'Host',
        });
        try {
          patchAddress(userInformation?.main_address?.id || 0, {
            city: values.city,
            neighborhood: values.neighborhood,
            number: values.number,
            postal_code: values.cep,
            state: values.state,
            street: values.street,
            country: 'Brasil',
            complement: values.complement,
          });
          toast.success('Informações atualizadas com sucesso!');
          loadUser();
          setActiveButtons(false);
        } catch (e: unknown) {
          if (e instanceof Error) {
            toastErrorRequest(e);
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  const getAddressByCep = async (cep: string) => {
    const cepAux = cep.split('-').join('');
    if (cepAux.length === 8) {
      const result = await getViaCeps(cepAux);
      setAddress(result);
      formik.setFieldValue('city', result.localidade);
      formik.setFieldTouched(
        'city', true, false,
      );
      formik.setFieldValue('state', result.uf);
      formik.setFieldTouched(
        'state', true, false,
      );
      formik.setFieldValue('cep', result.cep);
      formik.setFieldTouched(
        'cep', true, false,
      );
    }
  };

  function useWindowSize() {
    const { innerWidth: width } = window;
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  function handleOpenEditModal(accountBank: BankDetails) {
    setOpenModal(true);
    setDataAccountBank(accountBank);
  }

  async function getListBankAccountHost() {
    const result = await getHostBank();
    setListAccountBank(result);
  }

  useEffect(() => {
    window.addEventListener('resize', useWindowSize);
  }, [mobile]);

  useEffect(() => {
    useWindowSize();
  }, []);

  useEffect(() => {
    getListBankAccountHost();
  }, []);

  const isHostOps = userInformation?.roles?.includes('Host') && userInformation?.host?.is_host_ops === true;

  return (
    <>
      <Container>
        {!isHostOps && (
          <ContentAccountBank>
            <h1>Gerenciamento de contas bancárias</h1>
            <span>Olá, anfitrião(ã).</span>
            {listAccountBank && (
            <p>
              Esta é a sua conta bancária cadastrada, é para ela que faremos todas as
              transferências.
              Caso você precise alterar clique no lápis.
            </p>
            )}
            {!listAccountBank && (
            <p>
              Você ainda não possui conta bancária cadastrada, clique no botão abaixo para cadastrar
              sua conta bancária. Para ela que faremos todas as
              transferências.
            </p>
            )}
            <Divider />
            <div className="card-account">
              {listAccountBank.length === 0 && (
              <ButtonAdd
                onClick={() => { setOpenModal(true); }}
                text="Adicionar conta bancária"
                testid="add-account-bank"
              />
              )}
              {listAccountBank?.map((item) => (
                <BankAccountComponent
                  accountMain={false}
                  dataAccountBank={item}
                  handleOpenEditModal={() => handleOpenEditModal(item)}
                  updateListBank={() => {}}
                  setIsEditBank={setIsEditBank}
                />
              ))}
            </div>
          </ContentAccountBank>
        )}
        <form onSubmit={formik.handleSubmit}>
          <ContentAccountBank className="my-data">
            <h1>Meus dados</h1>
            <Divider />
            <div className="fields">
              <Row>
                <TextField
                  label="*Nome"
                  id="firstName"
                  pattern="[a-z\s]+$"
                  placeholder="Digite aqui..."
                  value={userInformation?.first_name || ''}
                  disabled={!activeButtons}
                  formik={formik}
                />
              </Row>
              <Row>
                <TextField
                  label="*Sobrenome"
                  id="lastName"
                  pattern="[a-z\s]+$"
                  placeholder="Digite aqui..."
                  value={userInformation?.last_name || ''}
                  disabled={!activeButtons}
                  formik={formik}
                />
              </Row>
              <Row>
                <TextField
                  label="*Apelido"
                  id="nickname"
                  placeholder="Digite aqui..."
                  value={userInformation?.nickname || ''}
                  disabled={!activeButtons}
                  formik={formik}
                />
              </Row>
              {userInformation?.is_individual ? (
                <Row>
                  <TextField
                    label="*CPF"
                    id="cpf"
                    placeholder="Digite aqui..."
                    disabled={!activeButtons}
                    value={userInformation?.cpf || ''}
                    formik={formik}
                    mask="cpf"
                  />
                </Row>
              ) : (
                <Row>
                  <TextField
                    label="*CNPJ"
                    id="cnpj"
                    placeholder="Digite aqui..."
                    disabled={!activeButtons}
                    value={userInformation?.cnpj || ''}
                    formik={formik}
                    mask="cnpj"
                  />
                </Row>
              )}
              <Row>
                <TextField
                  label="*Telefone"
                  id="phone"
                  placeholder="Digite aqui..."
                  value={userInformation?.phone_number1 || ''}
                  disabled={!activeButtons}
                  formik={formik}
                  mask="phone"
                />
              </Row>
              <Row>
                <TextField
                  label="*Email"
                  id="email"
                  placeholder="Digite aqui..."
                  value={user?.email || ''}
                  disabled={!activeButtons}
                  formik={formik}
                />
              </Row>
            </div>
          </ContentAccountBank>
          <ContentAccountBank>
            <h1>Gerenciamento endereço</h1>
            <p>
              Todos os materiais serão enviados para este endereço,
              então mantenha-o sempre atualizado.
            </p>
            <Divider />
            <div className="fields">
              <Row>
                <TextField
                  label="*CEP"
                  id="cep"
                  placeholder="Digite aqui..."
                  mask="cep"
                  onChange={(event) => getAddressByCep(event.target.value)}
                  formik={formik}
                  value={userInformation?.main_address?.postal_code}
                  disabled={!activeButtons}
                />
              </Row>
              <Row>
                <TextField
                  label="*Endereço"
                  id="street"
                  placeholder="Digite aqui..."
                  formik={formik}
                  value={address.logradouro || userInformation?.main_address?.street}
                  disabled={!activeButtons}
                />
              </Row>
              <Row>
                <div className="line-field">
                  <div>
                    <TextField
                      label="*Número"
                      id="number"
                      placeholder="Digite aqui..."
                      formik={formik}
                      value={userInformation?.main_address?.number}
                      disabled={!activeButtons}
                    />
                  </div>
                  <div>
                    <TextField
                      label="*Complemento"
                      id="complement"
                      placeholder="Digite aqui..."
                      formik={formik}
                      value={userInformation?.main_address?.complement}
                      disabled={!activeButtons}
                    />
                  </div>
                  <div>
                    <TextField
                      label="*Bairro"
                      id="neighborhood"
                      placeholder="Digite aqui..."
                      formik={formik}
                      value={address.bairro || userInformation?.main_address?.neighborhood}
                      disabled={!activeButtons}
                    />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="line-field">
                  <TextField
                    label="*Cidade"
                    id="city"
                    placeholder="Digite aqui..."
                    value={address.localidade || userInformation?.main_address?.city}
                    formik={formik}
                    disabled={!activeButtons}
                  />
                  <TextField
                    label="*Estado"
                    id="state"
                    placeholder="Digite aqui..."
                    value={address.uf || userInformation?.main_address?.state}
                    formik={formik}
                    disabled={!activeButtons}
                  />
                </div>
              </Row>
            </div>
          </ContentAccountBank>
          {/*
          <ContentButons>
            {!activeButtons && (
            <ButtonEdit onClick={() => (setActiveButtons(true))}>
              <Edit />
              Editar informações
            </ButtonEdit>
            )}
            {activeButtons && (
            <>
              <ButtonCancell onClick={() => (setActiveButtons(false))}>
                Descartar
              </ButtonCancell>
              <ButtonSave type="submit">
                Salvar ajustes
              </ButtonSave>
            </>
            )}
          </ContentButons>
          */}
        </form>
      </Container>
      <ModalNewBankAccount
        openModal={openMOdal}
        setOpenModal={setOpenModal}
        dataAccountBank={dataAccountBank}
        setDataAccountBank={setDataAccountBank}
        getListBankAccountHost={getListBankAccountHost}
        isEdit={isEditBank}
      />
    </>
  );
};

export default PersonalDataHost;
