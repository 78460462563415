import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { CustomInputMask } from '../../../../components/Inputs';

export const Error = styled(motion.p)`
  color: #f15151;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-family: "Inter";
  margin-top: -5px;
`;

export const Section = styled(motion.div)<{
  direction?: 'column' | 'row';
  disabled?: boolean;
}>`
  gap: 10px;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  transition: 0.2s;

  ${({ direction }) => direction === 'row'
    && css`
      align-items: center;
      justify-content: space-between;
    `}

  ${({ disabled }) => disabled
    && css`
      opacity: 0.5;
    `}
`;

export const InputMaskStyled = styled(CustomInputMask)<{
  error?: boolean;
}>`
  &&& {
    width: 100%;
    padding: 13px 17px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid #bec6cc;

    ${({ error }) => error
      && css`
        border: 1px solid #f15151;
      `}
  }
`;

export const InputStyled = styled.input<{
  error?: boolean;
}>`
  width: 100%;
  padding: 13px 17px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #bec6cc;
  transition: 0.2s;

  ${({ error }) => error
    && css`
      border: 1px solid #f15151;
    `}
`;

export const SelectStyled = styled.select<{
  error?: boolean;
}>`
  width: 100%;
  padding: 13px 17px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #bec6cc;

  transition: 0.2s;

  ${({ error }) => error
    && css`
      border: 1px solid #f15151;
    `}

  &:disabled {
    opacity: 0.5;
  }
`;

export const FormButton = styled.button<{
  variants?: 'primary' | 'outline';
}>`
  width: 100%;
  color: white;
  border: none;
  cursor: pointer;
  background: #0d4bd0;
  border-radius: 8px;
  padding: 10px 15px;
  font-weight: 650;
  border: 2px solid transparent;

  ${({ variants }) => {
    switch (variants) {
      case 'primary':
        return css`
          background: #0d4bd0;
        `;
      case 'outline':
        return css`
          background: transparent;
          border: 2px solid #0d4bd0;
          color: #0d4bd0;
        `;
      default:
        return css`
          background: #0d4bd0;
        `;
    }
  }}

  &:disabled {
    background: #bec6cc;
    cursor: not-allowed;
  }
`;

export const FormContent = styled.form`
  gap: 25px;
  display: flex;
  transition: 0.2s;
  flex-direction: column;
  width: 100%;
`;

export const SubText = styled(motion.p)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #394760;
`;

export const Label = styled(motion.p)`
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-family: "Inter";

  display: flex;
  color: #394760;
  align-items: center;
`;
