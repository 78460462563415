import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  h1 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 0 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .two-columns {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
    @media (max-width: 780px){
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .one-column {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;

    .labelClassCheckBox {
      margin: 0;
      .StyledDivLabel {
        font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
        font-family: ${({ theme }) => theme.fonts.familys._3};
      }
    }

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
  }

  @media (max-width: 1100px){
    grid-template-columns: 1fr;
  }
`;
