import {
  FC,
  useEffect,
  useMemo,
  memo,
  useState,
} from 'react';

import { X } from 'react-feather';

import { FinancialCloseParams, FranchseFeeProps } from '../../../../services/FinancialClose/Host/types';

import { getHostFranchiseFee } from '../../../../services/FinancialClose/Host/request';

import { useFinancialClose } from '../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseHost } from '../../../../hooks/FinancialCloseHook/useFinancialCloseHost';

import { formatDate } from '../../../../context/FinancialClosePage/FinancialCloseContext/utils';

import {
  Form,
  Container,
  Header,
  Body,
  Title,
  CloseButton,
  TableContainer,
  TableHeader,
  TableBody,
  Backdrop,
} from './styles';

const FranchseFeeHost: FC = () => {
  const {
    dateSelected,
  } = useFinancialClose();

  const {
    financialDataHost,
    openModalFranchiseFeeHost,
    handleOpenModalFranchseFeeHost,
  } = useFinancialCloseHost();

  const [hostFranchseFee, setHostFranchseFee] = useState<FranchseFeeProps>({});

  const params: FinancialCloseParams = {
    start_date: formatDate(dateSelected).start_date,
  };

  const hostsSelected = useMemo(() => {
    const hosts = financialDataHost.filter((item) => item.checked) || [];
    return hosts;
  }, [openModalFranchiseFeeHost, financialDataHost]);

  const getFranchseFee = async () => {
    let allResponses: FranchseFeeProps = {};

    await Promise.all(hostsSelected.map(async (item) => {
      const response: FranchseFeeProps = await getHostFranchiseFee({
        ...params,
        host_id: item.host.id,
      });

      allResponses = {
        ...allResponses,
        ...response,
      };
    }));

    setHostFranchseFee(allResponses);
  };

  useEffect(() => {
    if (openModalFranchiseFeeHost && hostsSelected.length !== 0) {
      getFranchseFee();
    }
  }, [openModalFranchiseFeeHost, hostsSelected]);

  return (
    <>
      <Form>
        <Container isOpen={openModalFranchiseFeeHost}>
          <Header>
            <Title>{`Taxa de franquia - Anfitrião - ${hostsSelected?.[0]?.host?.name || ''}`}</Title>
            <CloseButton type="button" onClick={() => handleOpenModalFranchseFeeHost(false)}>
              <X size={22} />
            </CloseButton>
          </Header>

          <Body>
            {hostsSelected.length > 0 && hostsSelected.map((item) => (
              <TableContainer key={item.id}>
                {Object.keys(hostFranchseFee)?.length === 0
                  ? <p>Não há taxa de franquia para o anfitriao!</p> : (
                    <>
                      <TableHeader>
                        <strong>Valor total da franquia</strong>
                        <strong>Data final do período de carência</strong>
                        <strong>Porcentagem do abatimento</strong>
                      </TableHeader>

                      <TableBody>
                        <span data-cy={`input-franchse-fee-amount-${hostFranchseFee.id}`}>
                          {hostFranchseFee.franchise_fee_amount}
                        </span>

                        <span data-cy={`input-final-date-franchse-fee-${hostFranchseFee.id}`}>
                          {hostFranchseFee?.have_grace_period === true ? hostFranchseFee.final_date_franchise_fee : '-'}
                        </span>

                        <span data-cy={`input-rebate-percentage-${hostFranchseFee.id}`}>
                          {hostFranchseFee.rebate_percentage}
                        </span>
                      </TableBody>
                    </>
                  )}
              </TableContainer>
            ))}
          </Body>
        </Container>
      </Form>

      { openModalFranchiseFeeHost && (
        <Backdrop onClick={() => handleOpenModalFranchseFeeHost(false)} />
      )}
    </>
  );
};

export default memo(FranchseFeeHost);
