import React, { useContext, useEffect } from 'react';
import moment, { Moment } from 'moment';

import InputCheckBox from '../../InputCheckBox';

import useKeys from '../../../hooks/GlobalHook/useKeys';

import { HeaderConfigContext } from '../../../context/ControllerPage/HeaderConfigContext';

import nextWeek from '../../../assets/icons/controll/nextWeek.svg';
import lastWeek from '../../../assets/icons/controll/lastWeek.svg';
import checkinIcon from '../../../assets/icons/controll/checkin.svg';
import checkoutIcon from '../../../assets/icons/controll/checkout.svg';

import {
  InputContainer,
  LastWeek,
  NextWeek,
  HeadConfig,
  HeadConfigContainer,
  HeadTitle,
} from './style';

interface HeaderProps {
  dates: Moment[];
  mobile: boolean;
  setDates: Function;
  checkList: boolean;
  modalIsOpened: boolean;
}

const NextWeekArrow = () => <img src={nextWeek} alt="next week arrow" />;

const LastWeekArrow = () => <img src={lastWeek} alt="last week arrow" />;

const HeaderConfig = ({
  dates,
  mobile,
  setDates,
  checkList,
  modalIsOpened,
}: HeaderProps) => {
  const {
    showCheckin,
    showCheckout,
    showConcluded,
    handleShowCheckin,
    handleShowCheckout,
    handleShowConcluded,
  } = useContext(HeaderConfigContext);

  const handleNextWeek = () => {
    const nextDates = Array.from({ length: mobile ? 2 : 8 },
      (_, days: number) => moment(dates[dates.length - 1]).add(days, 'day'));
    nextDates.shift();
    setDates(nextDates);
  };

  const handlePrevWeek = () => {
    const nextDates = Array.from({ length: mobile ? 2 : 8 },
      (_, days: number) => moment(dates[0]).add(-days, 'day'));
    nextDates.shift();
    setDates(nextDates.reverse());
  };

  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');

  useEffect(() => {
    if (leftArrow && !checkList && !modalIsOpened) handlePrevWeek();
    if (rightArrow && !checkList && !modalIsOpened) handleNextWeek();
  }, [leftArrow, rightArrow]);

  return (
    <HeadConfigContainer mobile={mobile}>
      {!mobile && (
        <div className="container-buttons">
          <LastWeek
            onClick={handlePrevWeek}
            className="lastWeek"
            data-cy="btn-previous-week"
          >
            <LastWeekArrow />
            Semana anterior
          </LastWeek>
        </div>
      )}
      <HeadConfig>
        {!mobile && <HeadTitle>Exibir: </HeadTitle>}
        <InputContainer>
          <InputCheckBox
            dataCy="checkbox-checkin"
            size="1rem"
            checked={showCheckin}
            onChange={() => handleShowCheckin()}
            labelName="Check-in"
            icon={checkinIcon}
          />
          <InputCheckBox
            dataCy="checkbox-checkout"
            size="1rem"
            checked={showCheckout}
            onChange={() => handleShowCheckout()}
            labelName="Check-out"
            icon={checkoutIcon}

          />
          <InputCheckBox
            dataCy="checkbox-concluded"
            size="1rem"
            checked={showConcluded}
            onChange={() => handleShowConcluded()}
            labelName="Concluído"
          />
        </InputContainer>
      </HeadConfig>
      {!mobile && (
        <div className="container-buttons">
          <NextWeek
            onClick={handleNextWeek}
            className="nextWeek"
            data-cy="btn-next-week"
          >
            Próxima semana
            <NextWeekArrow />
          </NextWeek>
        </div>
      )}
    </HeadConfigContainer>
  );
};

export default HeaderConfig;
