import React from 'react';
import { Container } from './style';
import { PageTitle } from '../utils/style';
import CardsList from './CardsList';

const Questions = () => (
  <Container
    transition={{ delay: 0.2 }}
    animate={{ y: 0, opacity: 1 }}
    initial={{ y: 10, opacity: 0 }}
  >
    <PageTitle>Dúvidas</PageTitle>
    <CardsList />
  </Container>
);

export default Questions;
