import styled from 'styled-components';

import {
  MAX_SIZE_MOBILE,
  inputsGap,
  buttonsWidthDesktop,
  expensesFiltersWidthDesktop,
} from '../../utils';

export const Form = styled.form<{ expensesExists: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${({ expensesExists }) => (expensesExists ? '24px' : '0px')};
  border-bottom: ${({ expensesExists, theme }) => (expensesExists ? `1px solid ${theme.palette.grey._425.hex()}` : 'none')};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: fit-content;
  gap: 0.7rem;

  &&.content-expenses-filters {
    width: ${expensesFiltersWidthDesktop}px !important;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: ${MAX_SIZE_MOBILE + buttonsWidthDesktop + inputsGap}px) {
    &.button-download-expenses {
      display: none;
    }
  }

  @media (min-width: ${MAX_SIZE_MOBILE + buttonsWidthDesktop + inputsGap + 1}px) {
    &.button-download-expenses {
      display: block;
    }
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 282px;
 
  .MuiOutlinedInput-root {
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()} !important;
  }
   
  &:hover {
    border-color: ${({ theme }) => theme.palette.green.main.hex()};
    transition: 0.7s;
  }

  select {
    width: 170px !important;
  }
  
  fieldset {
    border: 0 !important;
    width: 100% !important;
  }

  .MuiSvgIcon-root {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    fill: ${({ theme }) => theme.palette.blue._970.hex()} !important;
    font-size: 2.5rem !important;
    position: relative !important;
    right: 0px !important;
    top: 2px !important;
  }

  .MuiFormControl-root {
    gap: 0 !important;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    max-width: 100%;
    
    select, fieldset {
      width: 100% !important;
    }
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding-top: 7px;
  gap: ${inputsGap}px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    justify-content: space-between;
  }

  @media (min-width: ${MAX_SIZE_MOBILE}px) { 
    .MuiOutlinedInput-root  {
      width: 265px !important;
    }
  }
`;

export const Label = styled.p`
 font-family: Inter;
 font-size: 14px;
 font-weight: 400;
 line-height: 24px;
 padding-bottom: 8px;
 color: ${({ theme }) => theme.palette.blue._250.hex()};
`;
