import { ProperPayHostDashboard } from '../../../../services/HostDashboard/types';

import { useGridFinances } from '../../../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';
import { useTag } from '../../../../hooks/HostDashboardHook/HostGridFinances/useTag';

import Tag from '../Grid/Tag';
import Item from '../Grid/Item';

const FinancialGridContent = () => {
  const { financesData, propertyId } = useGridFinances();
  const {
    isExpandedDailyExecuted,
    handleChangeIsExpandedDailyExecuted,
    isExpandedCleaningExecuted,
    handleChangeIsExpandedCleaningExecuted,
    isExpandedDailyPaid,
    handleChangeIsExpandedDailyPaid,
    isExpandedCleaningPaid,
    handleChangeIsExpandedCleaningPaid,
    isExpandedOthersReceipts,
    handleChangeIsExpandedOthersReceipts,
    isExpandedWallet,
    handleChangeIsExpandedWallet,
  } = useTag();
  return (
    <>

      <Tag
        label="Diárias executadas"
        variant="0"
        isExpanded={isExpandedDailyExecuted}
        setIsExpanded={handleChangeIsExpandedDailyExecuted}
        data={financesData.executed_dailys}
        totalValue={financesData.executed_dailys.total}
      />

      <Item
        title="Diárias do mês atual"
        isVisible={isExpandedDailyExecuted}
        data={financesData.executed_dailys_to_receive}
        totalValue={financesData.executed_dailys_to_receive.total}
      />

      <Item
        title="Diárias do mês seguinte"
        isVisible={isExpandedDailyExecuted}
        data={financesData.executed_dailys_to_receive_following_month}
        totalValue={financesData.executed_dailys_to_receive_following_month.total}
      />

      <Tag
        label="Limpezas executadas"
        variant="5"
        isExpanded={isExpandedCleaningExecuted}
        setIsExpanded={handleChangeIsExpandedCleaningExecuted}
        data={financesData.executed_cleaning}
        totalValue={financesData.executed_cleaning.total}
      />
      <Item
        title="Limpezas do mês atual"
        isVisible={isExpandedCleaningExecuted}
        data={financesData.executed_cleaning_to_receive}
        totalValue={financesData.executed_cleaning_to_receive.total}
      />
      <Item
        title="Limpezas do mês seguinte"
        isVisible={isExpandedCleaningExecuted}
        data={financesData.executed_cleaning_to_receive_following_month}
        totalValue={financesData.executed_cleaning_to_receive_following_month.total}
      />

      <Tag
        label="Diárias pagas"
        variant="1"
        isExpanded={isExpandedDailyPaid}
        setIsExpanded={handleChangeIsExpandedDailyPaid}
        data={financesData.paid_dailys}
        totalValue={financesData.paid_dailys.total}
      />
      <Item
        title="Diárias do mês atual "
        isVisible={isExpandedDailyPaid}
        data={financesData.paid_dailys_revenue}
        totalValue={financesData.paid_dailys_revenue.total}
      />
      <Item
        title="Diárias do mês anterior"
        isVisible={isExpandedDailyPaid}
        data={financesData.paid_dailys_revenue_prior_month}
        totalValue={financesData.paid_dailys_revenue_prior_month.total}
      />

      <Tag
        label="Limpezas pagas"
        variant="1"
        isExpanded={isExpandedCleaningPaid}
        setIsExpanded={handleChangeIsExpandedCleaningPaid}
        data={financesData.paid_cleaning}
        totalValue={financesData.paid_cleaning.total}
      />
      <Item
        title="Limpezas do mês atual "
        isVisible={isExpandedCleaningPaid}
        data={financesData.paid_cleaning_revenue}
        totalValue={financesData.paid_cleaning_revenue.total}
      />
      <Item
        title="Limpezas do mês anterior"
        isVisible={isExpandedCleaningPaid}
        data={financesData.paid_cleaning_revenue_prior_month}
        totalValue={financesData.paid_cleaning_revenue_prior_month.total}
      />

      <Tag
        label="Outros recebimentos"
        variant="1"
        isExpanded={isExpandedOthersReceipts}
        setIsExpanded={handleChangeIsExpandedOthersReceipts}
        data={financesData.others_receipts}
        totalValue={financesData.others_receipts.total}
      />
      <Item
        title="Reembolsos pagos"
        isVisible={isExpandedOthersReceipts}
        data={financesData.others_receipts_refunds_paid}
        totalValue={financesData.others_receipts_refunds_paid.total}
      />

      <Item
        title="Ajustes"
        isVisible={isExpandedOthersReceipts}
        data={financesData.others_receipts_manual_fit}
        totalValue={financesData.others_receipts_manual_fit.total}
      />

      <Tag
        label="Saídas"
        variant="2"
        isExpanded={false}
        setIsExpanded={() => {}}
        hideExpandIcon
        data={financesData.cash_out}
        totalValue={financesData.cash_out.total}
      />

      <Tag
        label="Resultado"
        variant="4"
        isExpanded={isExpandedWallet}
        setIsExpanded={handleChangeIsExpandedWallet}
        data={financesData.results}
        totalValue={financesData.results.total}
      />

      <Item
        title="Taxa de franquia"
        isVisible={isExpandedWallet}
        data={financesData.franchise_fee}
        totalValue={financesData.franchise_fee.total}
      />

      {propertyId === undefined && (
      <Item
        title="Repasse (TED)"
        isVisible={isExpandedWallet}
        data={financesData.transfer}
        totalValue={financesData.transfer.total}
      />
      )}

      <Item
        title="Saldo"
        isVisible={isExpandedWallet}
        data={financesData.balance}
        totalValue={financesData.balance.total}
      />
    </>
  );
};

export default FinancialGridContent;
