import React from 'react';

import { Container, ContainerGrid } from './styles';

interface Props {
  grid?: boolean,
}

const FormGridRow: React.FC<Props> = ({
  children,
  grid = false,
}) => {
  if (grid) {
    return (
      <ContainerGrid>
        { children }
      </ContainerGrid>
    );
  }

  return (
    <Container>
      { children }
    </Container>
  );
};

export default FormGridRow;
