import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Wrapper = styled.div<{
  openModal: boolean;
}>`
  position: fixed;
  z-index: 10;
  left: 0;
  display: ${({ openModal }) => (openModal ? 'flex' : 'none')};
  justify-content: flex-end;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  align-items: center;
  justify-content: center;

  transition: 0.2s;
`;

export const Container = styled.form``;

export const ContentInputs = styled.div`
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  
  .MuiFormLabel-root, .MuiFormControl-root, .styledTextFieldLabel, label {
    font-family: ${({ theme }) => theme.fonts.familys._6} !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    padding-bottom: 80px;
    width: 100%;
    &.type-person {
      align-items: flex-start;
    }
  }
`;

export const RowInput = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .fullRow {
    width: 100%;
  }

  .selector {
    width: 100%;
    .MuiOutlinedInput-root {
      height: 43px !important;
      fieldset {
        border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()} !important;
      }
    }
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: column;
  }
`;

export const AlertMessageContainer = styled.div`
  margin-bottom: 20px;
`;
