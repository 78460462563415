import React, { useState, useEffect } from 'react';

import { DateRange } from '@mui/icons-material';

import { Container, CalendarModalContainer } from './styles';
import FastDataForwardModal from '../../../Calendar/FastDataForwardButton/FastDataForwardModal';
import { useDate } from '../../../../hooks/DateHook/useDate';

type SelectorDateFormatProps = 'month-year' | 'month year';

interface MonthProps {
  showMonth?: boolean;
  showYear?: boolean;
  customMonth?: number;
  customYear?: number;
  handleSetDate?: Function;
  isFinancialStatementCalendar?: boolean;
  withShortMonths?: boolean;
  selectorDateFormat?: SelectorDateFormatProps;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const MonthViewer = ({
  showMonth,
  showYear,
  customMonth,
  customYear,
  handleSetDate,
  isFinancialStatementCalendar,
  withShortMonths = false,
  selectorDateFormat = 'month-year',
  isModalVisible,
  setIsModalVisible,
}: MonthProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const {
    dateSelected,
    setGridMonth,
    setGridYear,
    setSelectInModal,
  } = useDate();

  const months = withShortMonths
    ? ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    : [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const verifyCustomYear = () => {
    if (customYear && customYear > 0) {
      return customYear;
    }
    return false;
  };

  const verifyCustomMonth = () => {
    if (customMonth && customMonth > 0 && customMonth < 13) {
      return customMonth;
    }
    return false;
  };

  const handleClick = (date: Date) => {
    setSelectedDate(date);
    setGridMonth(date.getMonth());
    setGridYear(date.getFullYear());
    setSelectInModal(true);

    if (handleSetDate) handleSetDate(date);
  };

  const handleSetIsModalVisible = (value?: boolean) => {
    if (value) {
      setIsModalVisible(value);
    }
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    handleSetIsModalVisible();
    const monthAux = verifyCustomMonth();
    const yearAux = verifyCustomYear();
    if (monthAux) {
      setMonth(monthAux);
    } else {
      setMonth(selectedDate.getMonth());
    }
    if (yearAux) {
      setYear(yearAux);
    } else {
      setYear(selectedDate.getFullYear());
    }
  }, [selectedDate]);

  useEffect(() => {
    setMonth(dateSelected.getMonth());
    setYear(dateSelected.getFullYear());
  }, [dateSelected]);

  const CustomSelectorDate = () => {
    if (selectorDateFormat === 'month year') {
      return (
        <h1 data-cy={`txt-minicalendar-date_${months[month]}-${year}`}>
          {`${months[month]} ${year}`}
        </h1>
      );
    }

    return (
      <h1 data-cy={`txt-minicalendar-date_${months[month]}-${year}`}>
        {`${months[month]}-${year}`}
      </h1>
    );
  };

  return (
    <>
      {isModalVisible && (
        <CalendarModalContainer>
          <FastDataForwardModal
            minDate={new Date('2019-01-02')}
            id="monthModalViewer"
            defaultValue={selectedDate}
            setDates={() => {}}
            onClose={() => handleSetIsModalVisible(false)}
            setSelectedMonth={(date: Date) => handleClick(date)}
            setSelectedYear={(date: Date) => handleClick(date)}
            showTodayButton={false}
            defaultView={'year'}
            isOwnerPage
            isFinancialStatementCalendar={isFinancialStatementCalendar}
          />
        </CalendarModalContainer>
      )}

      <Container id="calendarSelector" onClick={() => handleSetIsModalVisible(!isModalVisible)}>
        <div className="calendarContainer">
          <DateRange
            fontSize="medium"
            htmlColor="#EB6B57"
            className="calendarIcon"
          />
        </div>

        {showMonth && showYear && <CustomSelectorDate />}
        {showMonth && !showYear && <h1>{`${months[month]}`}</h1>}
        {showYear && !showMonth && <h1>{`${year}`}</h1>}

      </Container>
    </>

  );
};

MonthViewer.defaultProps = {
  showMonth: true,
  showYear: true,
  customMonth: 0,
  customYear: 0,
  handleSetDate: () => {},
  isFinancialStatementCalendar: false,
  withShortMonths: false,
  selectorDateFormat: 'month-year',
};

export default MonthViewer;
