import React from 'react';
import { usePartnersPortfolio } from '../../../../../../../../hooks/usePartners';

import {
  Text, Title, LeftContainer, RightContainer,
} from '../style';

import { ImageSVGIcon } from './icons';

import {
  Container,
  DotOptions,
  FavoriteButton,
  SubTitleContainer,
} from './style';

const Header = () => {
  const { handleOpenModal } = usePartnersPortfolio();
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClick = (event: any) => {
    const offsetTop = ref?.current?.offsetTop;
    const offsetLeft = ref?.current?.offsetLeft;

    if (offsetTop && offsetLeft) {
      handleOpenModal({
        x: event.clientX - offsetLeft,
        y: event.clientY - offsetTop,
      });
    }
  };

  return (
    <Container>
      <LeftContainer>
        <Title>INGLESES SPOT</Title>
        <SubTitleContainer>
          <Text>
            <span>Barra da lagoa</span>
            Florianópolis
          </Text>
        </SubTitleContainer>
      </LeftContainer>
      <RightContainer>
        <FavoriteButton
          whileTap={{
            scale: 0.9,
          }}
        >
          <ImageSVGIcon />
        </FavoriteButton>

        <DotOptions ref={ref} onClick={handleClick}>
          <svg
            width="3"
            height="12"
            viewBox="0 0 3 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1.5" cy="1.5" r="1.5" fill="white" />
            <circle cx="1.5" cy="5.69995" r="1.5" fill="white" />
            <circle cx="1.5" cy="9.90002" r="1.5" fill="white" />
          </svg>
        </DotOptions>
      </RightContainer>
    </Container>
  );
};

export default Header;
