/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import {
  useEffect,
  useState,
  useMemo,
  ReactElement,
} from 'react';
import { useParams } from 'react-router-dom';
import { Lock } from 'react-feather';

import iconFile from '../../../assets/icons/ownerPage/iconFile.svg';
import iconFileGrey from '../../../assets/icons/ownerPage/iconFileGrey.svg';

import airbnbLogo from '../../../assets/icons/otas/airbnbLogo.svg';
import bookingLogo from '../../../assets/icons/otas/bookingLogo.svg';
import expediaLogo from '../../../assets/icons/otas/expediaLogo.svg';
import homeawayLogo from '../../../assets/icons/otas/homeawayLogo.svg';
import temporadalivreLogo from '../../../assets/icons/otas/temporadalivreLogo.svg';
import testadorLogo from '../../../assets/icons/otas/testadorLogo.svg';
import staysLogo from '../../../assets/icons/otas/staysLogo.svg';
import websiteLogo from '../../../assets/icons/otas/websiteLogo.svg';
import seazoneLogo from '../../../assets/icons/otas/seazoneLogo.svg';
import decolarLogo from '../../../assets/icons/otas/decolarLogo.svg';

import { PropertyDetails } from '../../../services/Property/types';
import { ProperPayOwnerDashboardResponse } from '../../../services/Owner/Dashboard/types';

import { getPropertyId } from '../../../services/Property/request';
import { getProperPayOwnerDashboard } from '../../../services/Owner/Dashboard/request';

import { initialValuesOwnerDashboard } from '../../../services/Owner/Dashboard/utils';

import { GridCalendarReloadProvider } from '../../../context/OwnerPage/GridCalendarReload';
import { useUser } from '../../../context/UserContext';
import { useDate } from '../../../hooks/DateHook/useDate';
import { useViewMode } from '../../../context/ViewMode/ViewMode';

import MonthViewer from './MonthViewer/MonthViewer';
import GridCalendar from '../GridCalendar';

import {
  ButtonGrey,
  ButtonBlack,
  Line,
  CalendarWrapper,
  LinksOtas,
  Ota,
  GeneralContainer,
  ContainerCalendar,
  ContentTransfer,
  CardFinances,
  Divider,
  CardProperty,
  ContainerButtons,
  ContentBody,
  ContentDetails,
  PropertyCodeShimmer,
  AddressShimmer,
  LinksOtasShimmer,
  FinancialValueShimmer,
  ButtonChange,
  ButtonContactHost,
} from './styles';

const ImageOta: Record<string, ReactElement> = {
  BLK: <Lock className="lockLogo" />,
  AIR: <img src={airbnbLogo} alt="Airbnb Logo" />,
  BOO: <img src={bookingLogo} alt="Booking Logo" />,
  EXP: <img src={expediaLogo} alt="Expedia Logo" />,
  HOM: <img src={homeawayLogo} alt="HomeAway Logo" />,
  STA: <img src={staysLogo} alt="Stays Logo" />,
  TEM: <img src={temporadalivreLogo} alt="TemporadaLivre Logo" />,
  CON: <img src={seazoneLogo} alt="Seazone Logo" />,
  WEB: <img src={websiteLogo} alt="Website Logo" />,
  TES: <img src={testadorLogo} alt="Testador Logo" />,
  DEC: <img src={decolarLogo} alt="Decolar Logo" />,
};

const CardPropertyDetails = () => {
  const { propertiesIdsOwnerLogged, userInformation } = useUser();
  const { mode } = useViewMode();
  const { dateSelected } = useDate();
  const { id } = useParams();

  const [property, setProperty] = useState<PropertyDetails>();
  const [propertyAddress, setPropertyAddress] = useState<string>('');
  const [propertyCode, setPropertyCode] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  const [loadingPropertyInfos, setLoadingPropertyInfos] = useState<boolean>(true);
  const [loadingFinancialData, setLoadingFinancialData] = useState<boolean>(true);

  const [
    financialClosingData,
    setFinancialClosingData,
  ] = useState<ProperPayOwnerDashboardResponse>(initialValuesOwnerDashboard);

  const ownerId = useMemo(() => (userInformation?.owner?.id || undefined), [userInformation]);
  const viewModeOwnerId = useMemo(() => mode?.user_info?.owner?.id || undefined, [mode]);
  const month = useMemo(() => dateSelected.getMonth() + 1, [dateSelected]);
  const year = useMemo(() => dateSelected.getFullYear(), [dateSelected]);
  const propertyId = useMemo(() => (id ? Number(id) : undefined), [id]);

  const getProperty = async () => {
    setLoadingPropertyInfos(true);
    try {
      if (propertyId) {
        const values: PropertyDetails = await getPropertyId(`${propertyId}`);
        setProperty(values);

        if (values?.address) {
          const {
            street, number, neighborhood, complement, city, state, country,
          } = values.address;

          setPropertyAddress(`${street} ${number}, ${neighborhood} - ${complement} ${city} - ${state} - ${country}, CEP: ${values.address?.postal_code}`);
        } else {
          setPropertyAddress('');
        }
      }
    } finally {
      setLoadingPropertyInfos(false);
    }
  };

  const getFinancialClosing = async () => {
    try {
      setLoadingFinancialData(true);

      if (year && viewModeOwnerId && propertyId) {
        const response = await getProperPayOwnerDashboard(
          year, propertyId, viewModeOwnerId,
        );
        setFinancialClosingData(response);
      } else if (year && ownerId && propertyId) {
        const response = await getProperPayOwnerDashboard(
          year, propertyId, ownerId,
        );
        setFinancialClosingData(response);
      }

      setTimeout(() => {
        setLoadingFinancialData(false);
      }, 1000);
    } catch {
      setLoadingFinancialData(false);
    }
  };

  useEffect(() => {
    if (viewModeOwnerId) {
      getProperty();
    } else {
      const propertiesOwner: number[] = propertiesIdsOwnerLogged || [];
      if (propertyId && propertiesOwner.includes(propertyId)) {
        getProperty();
      }
    }
  }, [propertyId, propertiesIdsOwnerLogged]);

  useEffect(() => {
    if (viewModeOwnerId) {
      getFinancialClosing();
    } else {
      const propertiesOwner: number[] = propertiesIdsOwnerLogged || [];
      if (propertyId && propertiesOwner.includes(propertyId)) {
        getFinancialClosing();
      }
    }
  }, [propertyId, year, propertiesIdsOwnerLogged, viewModeOwnerId]);

  useEffect(() => {
    if (property?.code) {
      setPropertyCode(property.code);
    }
  }, [property]);

  const verifyLink = (link: string): boolean => {
    const dividerLink = `${link}`.split('/br/');
    if (dividerLink?.[1]?.length >= 3) {
      return true;
    }
    return false;
  };

  const handleRedirectToPage = (link: string) => {
    window.open(link, '_blank');
  };

  const phoneNumberWhats = property?.host?.phone_number_1?.replace('+055', '55')
    .replace(/\s+/g, '')
    .replace('(', '')
    .replace(')', '');

  return (
    <GeneralContainer>
      <GridCalendarReloadProvider>
        <ContainerCalendar>
          <GridCalendar propertyId={id !== undefined ? id : ''} propertyCode={propertyCode} />
        </ContainerCalendar>
      </GridCalendarReloadProvider>

      <ContentDetails>
        {loadingPropertyInfos ? (
          <CardProperty>
            <PropertyCodeShimmer />
            <AddressShimmer />
            <LinksOtas loading={loadingPropertyInfos}>
              <h2>Links:</h2>
              <LinksOtasShimmer />
            </LinksOtas>
            <ContainerButtons>
              <ButtonBlack type="button">
                <img src={iconFile} alt="ícone de arquivo" />
                Extrato Maio
              </ButtonBlack>
              <ButtonGrey type="button">
                <img src={iconFileGrey} alt="ícone de arquivo" />
                Relatórios
              </ButtonGrey>
            </ContainerButtons>
          </CardProperty>
        ) : (
          <CardProperty>
            <h1 data-cy="property-code">{property?.code}</h1>
            <p>{propertyAddress}</p>
            <LinksOtas loading={loadingPropertyInfos}>
              <h2>Links:</h2>
              {property?.ota_links && property.ota_links.map((itemOta) => itemOta.link && (
              <>
                {itemOta.ota === 'BOO' ? (
                  <>
                    {verifyLink(itemOta.link) && (
                    <Ota
                      key={itemOta.ota}
                      onClick={() => handleRedirectToPage(itemOta.link)}
                    >
                      {ImageOta[itemOta.ota.toUpperCase().trim()]}
                    </Ota>
                    )}
                  </>
                ) : (
                  <Ota
                    key={itemOta.ota}
                    onClick={() => handleRedirectToPage(itemOta.link)}
                  >
                    {ImageOta[itemOta.ota.toUpperCase().trim()]}
                  </Ota>
                )}
              </>
              ))}
            </LinksOtas>

            <ContainerButtons>
              <ButtonChange
                href={`https://app.pipefy.com/public/form/3aELjYRf?motivo_padronizado_1=${property && property.code}&nome_do_solicitante_1=${userInformation?.trading_name
                  ? userInformation.trading_name : `${userInformation?.first_name}  ${userInformation?.last_name}`}`}
                target="_blank"
              >
                Solicitar alteração no anúncio
              </ButtonChange>
              <ButtonContactHost
                href={`https://wa.me/${phoneNumberWhats}`}
                target="_blank"
              >
                Falar com o anfitrião

              </ButtonContactHost>
              <ButtonBlack type="button">
                <img src={iconFile} alt="ícone de arquivo" />
                Extrato Maio
              </ButtonBlack>
              <ButtonGrey type="button">
                <img src={iconFileGrey} alt="ícone de arquivo" />
                Relatórios
              </ButtonGrey>
            </ContainerButtons>
          </CardProperty>
        )}

        <CardFinances>
          <ContentBody>
            <CalendarWrapper>
              <MonthViewer
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            </CalendarWrapper>
            <Line loading={loadingFinancialData}>
              <p data-cy="txt-income">Diárias Executadas</p>
              {loadingFinancialData ? <FinancialValueShimmer />
                : <p>{financialClosingData.executed_dailys[`${month}`]}</p>}
            </Line>
            <Divider />
            <Line loading={loadingFinancialData}>
              <p data-cy="txt-expenses">Saídas</p>
              {loadingFinancialData ? <FinancialValueShimmer />
                : <p>{financialClosingData.outgoing[`${month}`]}</p>}
            </Line>
            <Divider />
            <Line loading={loadingFinancialData}>
              <p data-cy="txt-commission">Comissão</p>
              {loadingFinancialData ? <FinancialValueShimmer />
                : <p>{property?.comission_fee ? `${Number(property?.comission_fee) * 100}%` : '-'}</p>}
            </Line>
            <Divider />
          </ContentBody>
          <ContentTransfer loading={loadingFinancialData}>
            <p data-cy="txt-transfer">Repasse</p>
            {loadingFinancialData ? <FinancialValueShimmer />
              : <span>{financialClosingData.transfer[`${month}`]}</span> }
          </ContentTransfer>
        </CardFinances>

      </ContentDetails>
    </GeneralContainer>
  );
};

export default CardPropertyDetails;
