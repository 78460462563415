import {
  FC,
  useState,
  useEffect,
  useMemo,
  memo,
  useContext,
} from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { X } from 'react-feather';

import {
  Container,
  Content,
  FormContainer,
  Header,
  Title,
  CardContainer,
  CardPropertyContainer,
  CardPropertyContent,
  CardAddRemoveValue,
  ContainerQuestion,
  ContainerAddRemove,
  SwitchButton,
  SwitchCircle,
  ContainerValue,
  ContainerDescription,
  ButtonsContainer,
  CloseButton,
  Backdrop,
  ContentLoadMoreDatas,
  ArrowDownIcon,
  StarSymbol,
} from './styles';

import FormButton from '../../../../FormButton';
import TextField from '../../../../TextField';
import DatePicker from '../../../../DatePicker';

import {
  GridPropertyProps,
} from '../../../../../context/FinancialClosePage/FinancialClosePropertyContext/types';

import { PropertyCloseContext } from '../../../PropertyClose/PropertyClose';

import { FinancialCloseParams, ManualFitProps } from '../../../../../services/FinancialClose/types';

import { postManualFitProperty } from '../../../../../services/FinancialClose/Property/request';

import { currencyToNumber, numberToCurrency } from '../../../../../utils/Formatter';
import { currency } from '../../../../../utils/InputMask/Number';

import { useFinancialClose } from '../../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseProperty } from '../../../../../hooks/FinancialCloseHook/useFinancialCloseProperty';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../../utils/Messages';

import { formatDate } from '../../../../../context/FinancialClosePage/FinancialCloseContext/utils';

const AddManualFitProperty: FC = () => {
  const {
    handleOpenActions,
    dateSelected,
    handleActiveLinearProgress,
  } = useFinancialClose();

  const {
    financialDataProperty,
    openModalAddManualFitProperty,
    handleOpenModalAddManualFitProperty,
  } = useFinancialCloseProperty();

  const {
    refetchSelectedProperties,
  } = useContext(PropertyCloseContext);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  type SwitchProps = {
    id: number;
    active: boolean;
  };

  const DEFAULT_LIMIT = 3;
  const [limitFinancialData, setLimitFinancialData] = useState<number>(financialDataProperty.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : financialDataProperty.length);

  const params: FinancialCloseParams = {
    start_date: formatDate(dateSelected).start_date,
  };

  const [switchs, setSwitchs] = useState<SwitchProps[]>([]);

  const [propertiesSelected,
    setPropertiesSelected] = useState<GridPropertyProps[]>(financialDataProperty
    .filter((item) => item.checked));

  useEffect(() => {
    if (openModalAddManualFitProperty) {
      const properties = financialDataProperty.filter((item) => item.checked);

      setSwitchs(properties.length > 0
        ? properties.map((item: GridPropertyProps) => ({
          id: item.id,
          active: false,
        })) : []);

      setPropertiesSelected(() => [...properties]);
    }
  }, [openModalAddManualFitProperty, financialDataProperty]);

  const handleUpdateSwitch = (id: number) => {
    setSwitchs((prevSwitchs) => prevSwitchs.map((item) => ({
      ...item,
      active: item.id === id ? !item.active : item.active,
    })));
  };

  const findIndexOfItemSelected = (id: number) => propertiesSelected
    .findIndex((item) => item.id === id);

  const handlePostManualFit = async (manualFitData: ManualFitProps) => {
    try {
      const response = await postManualFitProperty(manualFitData);
      return response.data;
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const initialValuesFormik = useMemo(() => {
    let initial: any = {};
    const properties = financialDataProperty.filter((item) => item.checked);

    if (properties.length > 0) {
      properties.forEach((item) => {
        initial = {
          ...initial,
          [`is_adding_${item.id}`]: false,
          [`description_${item.id}`]: '',
          [`value_${item.id}`]: numberToCurrency(0),
          [`date_${item.id}`]: dateSelected,
        };
      });
    }

    return { ...initial };
  }, [financialDataProperty, dateSelected]);

  const validation = useMemo(() => {
    let schema = {};

    financialDataProperty.filter((item) => item.checked).forEach((item) => {
      schema = {
        ...schema,
        [`is_adding_${item.id}`]: Yup.boolean().required('Campo obrigatório'),
        [`description_${item.id}`]: Yup.string().required('Campo obrigatório'),
        [`value_${item.id}`]: Yup.string().required('Campo obrigatório'),
        [`date_${item.id}`]: Yup.date().required('Campo obrigatório'),
      };
    });

    return Yup.object().shape(schema);
  }, [financialDataProperty]);

  const formik = useFormik({
    initialValues: initialValuesFormik,
    validationSchema: validation,
    onSubmit: async (values) => {
      handleActiveLinearProgress(true);

      await Promise.all(propertiesSelected.map(async (item) => {
        const value = values?.[`value_${item.id}`];
        const description = values?.[`description_${item.id}`];
        const isAdding = switchs?.[findIndexOfItemSelected(item.id)]?.active || false;
        const dateRef = values?.[`date_${item.id}`];

        await handlePostManualFit({
          date_ref: dateRef ? dateRef.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }) : new Date(),
          value: typeof value === 'string' ? currencyToNumber(currency(value)) : value,
          is_adding: isAdding,
          property: item.property.id,
          description,
        });
      }));

      await refetchSelectedProperties({
        ...params,
        properties: propertiesSelected.map((item) => `${item.property.id}`),
      });

      formik.resetForm();

      setTimeout(() => {
        handleOpenActions(false);
        handleOpenModalAddManualFitProperty(false);
        handleActiveLinearProgress(false);
        toast.success('Ajustes realizados com sucesso!');
      }, 1000);
    },
  });

  const handleLoadNext = () => {
    setLimitFinancialData(financialDataProperty.length > 0
      && limitFinancialData >= financialDataProperty.length
      ? financialDataProperty.length : limitFinancialData + DEFAULT_LIMIT);
  };

  return (
    <>
      <FormContainer onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Container isOpen={openModalAddManualFitProperty}>
          <Header>
            <Title>Ajuste imóvel proprietário</Title>
            <CloseButton type="button" onClick={() => handleOpenModalAddManualFitProperty(false)}>
              <X size={22} />
            </CloseButton>
          </Header>
          <Content>
            {propertiesSelected.length > 0
            && propertiesSelected.slice(0, limitFinancialData).map((item) => (
              <CardContainer key={item.id}>
                <CardPropertyContainer>
                  <CardPropertyContent>
                    <div>
                      <p>
                        Imóvel
                      </p>
                      <strong data-cy={`title-property-code-${item.id}`}>
                        {`- ${item.property.code}`}
                      </strong>
                    </div>
                  </CardPropertyContent>
                </CardPropertyContainer>

                <CardAddRemoveValue>
                  <ContainerQuestion>
                    <strong>É um ajuste positivo ou negativo?</strong>
                    <StarSymbol>*</StarSymbol>
                  </ContainerQuestion>
                  <ContainerAddRemove switchIsActive={switchs?.[findIndexOfItemSelected(item.id)]
                    ?.active || false}
                  >
                    <p>Negativo</p>
                    <SwitchButton data-cy={`btn-add-remove-${item.id}`} type="button" onClick={() => handleUpdateSwitch(item.id)}>
                      <SwitchCircle switchIsActive={switchs?.[findIndexOfItemSelected(item.id)]
                        ?.active || false}
                      />
                    </SwitchButton>
                    <p>Positivo</p>
                  </ContainerAddRemove>
                </CardAddRemoveValue>

                <ContainerValue>
                  <TextField
                    dataCy={`input-value-${item.id}`}
                    id={`value_${item.id}`}
                    formik={formik}
                    label="Valor"
                    mask="money"
                    placeholder="R$ 0,00"
                    required
                    requireSymbolPosition="right"
                  />
                </ContainerValue>

                <ContainerValue>
                  <DatePicker
                    dataCy={`input-date-${item.id}`}
                    id={`date_${item.id}`}
                    formik={formik}
                    label="Data de referência"
                    hasInitialDates
                    required
                    requireSymbolPosition="right"
                    showDaysOutsideCurrentMonth={false}
                    showOnlyMonth={dateSelected}
                  />
                </ContainerValue>

                <ContainerDescription>
                  <TextField
                    dataCy={`textarea-description-${item.id}`}
                    formik={formik}
                    id={`description_${item.id}`}
                    label="Motivos"
                    type="textarea"
                    placeholder="Motivos"
                    required
                    requireSymbolPosition="right"
                  />
                </ContainerDescription>
              </CardContainer>
            ))}

            {propertiesSelected.length > DEFAULT_LIMIT && (
              <ContentLoadMoreDatas
                onClick={() => handleLoadNext()}
              >
                <p>Mostrar mais</p>
                <ArrowDownIcon />
              </ContentLoadMoreDatas>
            )}

          </Content>

          <ButtonsContainer className="btn-submit">
            <FormButton dataCy="btn-cancel" type="button" variant="outlined" onClick={() => handleOpenModalAddManualFitProperty(false)}>Cancelar</FormButton>
            <FormButton dataCy="btn-confirm" type="submit">Salvar</FormButton>
          </ButtonsContainer>
        </Container>
      </FormContainer>

      { openModalAddManualFitProperty && (
        <Backdrop onClick={() => handleOpenModalAddManualFitProperty(false)} />
      )}
    </>
  );
};

export default memo(AddManualFitProperty);
