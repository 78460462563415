/* eslint-disable @typescript-eslint/naming-convention */
import request from '../request';
import {
  GetGuestDamageNegotiation,
  GuestDamageEvidence,
  GetGuestDamageNegotiationParams,
  PostGuestDamageNegotiation,
  PropertiesList,
  ReservationDetails,
  ReservationDetailsParams,
  PostGuestDamage,
  GetGuestDamage,
  PatchGuestDamage,
  PatchGuestDamageNegotiation,
  PostDamageNegotiationGuestPayment,
  PostGuestDamageNegotiationHistory,
  BankDetails,
  PostDamageNegotiationOwnerPayment,
  HouseHoldLinen,
} from './types';

export const getPropertiesList = async ():
Promise<PropertiesList[]> => {
  const { data } = await request.get('/properties/properties_list');
  return data;
};

export const getGuestDamageNegotiation = async (params?: GetGuestDamageNegotiationParams):
Promise<GetGuestDamageNegotiation[]> => {
  if (params) {
    const { data } = await request.get<GetGuestDamageNegotiation[]>('/guest_damage_negotiation/', {
      params,
    });
    return data;
  }

  const { data } = await request.get<GetGuestDamageNegotiation[]>('/guest_damage_negotiation/');
  return data;
};

export const postGuestDamageNegotiation = async (guestDamageNegotiation:
PostGuestDamageNegotiation) => {
  const { data } = await request.post<GetGuestDamageNegotiation>('/guest_damage_negotiation/', {
    ...guestDamageNegotiation,
  } as PostGuestDamageNegotiation);
  return data;
};

export const getReservation = async (params?: ReservationDetailsParams):
Promise<ReservationDetails[]> => {
  if (params) {
    const {
      check_in_date, check_out_date, guest_name, ota_name, property_code,
    } = params;

    const { data } = await request.get<ReservationDetails[]>('/reservation/', {
      params: {
        check_in_date,
        check_out_date,
        search: guest_name,
        ota_name,
        property_code,
      },
    });
    return data;
  }

  const { data } = await request.get<ReservationDetails[]>('/reservation/');
  return data;
};

export const postGuestDamage = async (guestDamage?: PostGuestDamage):
Promise<PostGuestDamage> => {
  const { data } = await request.post<PostGuestDamage>('/guest_damage/', {
    ...guestDamage,
  });
  return data;
};

export const postGuestDamageEvidences = async (guestDamageId: number, evidence?: string) => {
  await request.post<GuestDamageEvidence>('/guest_damage_evidence/', {
    evidence,
    guest_damage: guestDamageId,
  });
};

export const getReservationGuestDamages = async (reservationId: number) => {
  const { data } = await request.get<GetGuestDamage[]>(`/reservation/${reservationId}/guest_damages/`);
  return data;
};

export const patchGuestDamage = async (id: number, guestDamage?: PatchGuestDamage):
Promise<GetGuestDamage> => {
  const { data } = await request.patch<GetGuestDamage>(`/guest_damage/${id}/`, {
    ...guestDamage,
  });
  return data;
};

export const patchGuestDamageNegotiation = async (id: number,
  guestDamageNegotiation?: PatchGuestDamageNegotiation):
Promise<PatchGuestDamageNegotiation> => {
  const { data } = await request.patch<PatchGuestDamageNegotiation>(`/guest_damage_negotiation/${id}/`, guestDamageNegotiation);
  return data;
};

export const postDamageNegotiationGuestPayment = async (guestDamageNegotiationId: number,
  guestPaymentReceipt: string) => {
  const { data } = await request.post<PostDamageNegotiationGuestPayment>('/damage_negotiation_guest_payment/', {
    guest_damage_negotiation: guestDamageNegotiationId,
    guest_payment_receipt: guestPaymentReceipt,
  } as PostDamageNegotiationGuestPayment);
  return data;
};

export const getGuestDamageNegotiationById = async (id: number):
Promise<GetGuestDamageNegotiation> => {
  const { data } = await request.get<GetGuestDamageNegotiation>(`/guest_damage_negotiation/${id}`);
  return data;
};

export const getGuestDamageById = async (id: number):
Promise<GetGuestDamage> => {
  const { data } = await request.get<GetGuestDamage>(`/guest_damage/${id}`);
  return data;
};

export const postGuestDamageNegotiationHistory = async (guestDamageNegotiationId: number,
  guestDamageNegotiationHistory: PostGuestDamageNegotiationHistory) => {
  const { data } = await request.post<PostGuestDamageNegotiationHistory>('/guest_damage_negotiation_history/', {
    guest_damage_negotiation: guestDamageNegotiationId,
    contact_date: guestDamageNegotiationHistory.contact_date,
    history: guestDamageNegotiationHistory.history,
  } as PostGuestDamageNegotiationHistory);
  return data;
};

export const getOwnerBankDetailsById = async (id: number):
Promise<BankDetails[]> => {
  const { data } = await request.get<BankDetails[]>('/financial/owner/bank_details/', {
    params: {
      user_id: id,
    },
  });
  return data;
};

export const postDamageNegotiationOwnerPayment = async (ownerDamageNegotiationId: number,
  ownerPaymentReceipt: string) => {
  const { data } = await request.post<PostDamageNegotiationOwnerPayment>('/damage_negotiation_owner_payment/', {
    guest_damage_negotiation: ownerDamageNegotiationId,
    owner_payment_receipt: ownerPaymentReceipt,
  } as PostDamageNegotiationOwnerPayment);
  return data;
};

export const getReservationGuestDamageNegotiation = async (reservationId: number) => {
  const { data } = await request.get<GetGuestDamageNegotiation>(`/reservation/${reservationId}/guest_damage_negotiation/`);
  return data;
};

export const getReservationById = async (id: number):
Promise<BankDetails[]> => {
  const { data } = await request.get<BankDetails[]>(`/reservation/${id}/`);
  return data;
};

export const deleteGuestDamageEvidence = async (id: number) => {
  await request.delete(`/guest_damage_evidence/${id}`);
};

export const getHouseHoldLinen = async () => {
  const { data } = await request.get<HouseHoldLinen[]>('/household_linen/');
  return data;
};
