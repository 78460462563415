import { useContextSelector } from 'use-context-selector';

import { OwnerExtractContext } from '../../../context/OwnerPage/OwnerExtractContext/OwnerExtractContext';

export function useOwnerExtract() {
  const openModalFinancialExtract = useContextSelector(OwnerExtractContext,
    (state) => state.openModalFinancialExtract);

  const setOpenModalFinancialExtract = useContextSelector(OwnerExtractContext,
    (state) => state.setOpenModalFinancialExtract);

  const extractList = useContextSelector(OwnerExtractContext,
    (state) => state.extractList);

  const setExtractList = useContextSelector(OwnerExtractContext,
    (state) => state.setExtractList);

  const showExtractDate = useContextSelector(OwnerExtractContext,
    (state) => state.showExtractDate);

  const setShowExtractDate = useContextSelector(OwnerExtractContext,
    (state) => state.setShowExtractDate);

  const extractGeneratedIn = useContextSelector(OwnerExtractContext,
    (state) => state.extractGeneratedIn);

  const setExtractGeneratedIn = useContextSelector(OwnerExtractContext,
    (state) => state.setExtractGeneratedIn);

  const period = useContextSelector(OwnerExtractContext,
    (state) => state.period);

  const setPeriod = useContextSelector(OwnerExtractContext,
    (state) => state.setPeriod);

  const property = useContextSelector(OwnerExtractContext,
    (state) => state.property);

  const setProperty = useContextSelector(OwnerExtractContext,
    (state) => state.setProperty);

  return {
    openModalFinancialExtract,
    setOpenModalFinancialExtract,
    extractList,
    setExtractList,
    showExtractDate,
    setShowExtractDate,
    extractGeneratedIn,
    setExtractGeneratedIn,
    period,
    setPeriod,
    property,
    setProperty,
  };
}
