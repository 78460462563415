import React from 'react';

import { Close } from '@mui/icons-material';
import { Backdrop } from '@mui/material';

import {
  DesktopContainer,
  DesktopContent,
  MobileContainer,
  CloseContainer,
} from './style';

import Card from './Card';
import StepsBall from './StepsBall';

import { IOptions, StepsProvider, useSteps } from './context';
import { useMobile } from '../../hooks/useMobile/useMobile';

import {
  IChangeRequiredActions,
} from '../../services/User/request';
import { IData } from './type';
import IconTemplate from './Icons/Icon';

interface Callback {
  currentStep: number;
}

type MyCallback = ({ currentStep }: Callback) => { currentStep: number };

export interface IWelcome {
  data: IData[];
  options?: IOptions;
  newBody: {};
  onFinish: {
    success?: (res: IChangeRequiredActions) => void;
    error?: () => void;
  };
  callback?: MyCallback;
  onClose?: Function;
}

interface ITemplateContainer {
  children: React.ReactNode;
}

interface IPopup {
  onClose?: Function;
}

const TemplateContainer = ({ children }: ITemplateContainer) => {
  const { isDesktop } = useMobile();

  if (isDesktop) {
    return (
      <>
        <DesktopContainer>
          <DesktopContent>{children}</DesktopContent>
        </DesktopContainer>
        <Backdrop
          open
          style={{
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 9999,
            height: '100%',
            position: 'fixed',
          }}
        />
      </>
    );
  }

  return <MobileContainer>{children}</MobileContainer>;
};

const Popup = ({ onClose }: IPopup) => {
  const { isMobile } = useMobile();
  const { step, data } = useSteps();

  const defineImage = React.useMemo(() => {
    if (data[step].useImageTemplate) {
      return <IconTemplate>{data[step].image}</IconTemplate>;
    }

    return data[step].image;
  }, [data, step]);

  return (
    <TemplateContainer>
      {onClose && (
        <CloseContainer
          isMobile={isMobile}
          onClick={() => onClose()}
        >
          <Close />
        </CloseContainer>
      )}

      <Card
        key={data[step].step}
        card={data[step].card}
        image={defineImage}
        title={data[step].title}
      />

      {isMobile && <StepsBall />}
    </TemplateContainer>
  );
};

Popup.defaultProps = {
  onClose: undefined,
};

/**
 * Este componente é um template para o popup de boas vindas
 */
const ProvidedWelcome = ({
  newBody,
  onFinish,
  callback,
  data,
  options,
  onClose,
}: IWelcome) => (
  <StepsProvider
    data={data}
    options={options}
    newBody={newBody}
    onFinish={onFinish}
    callback={callback}
  >
    <Popup onClose={onClose} />
  </StepsProvider>
);

export default ProvidedWelcome;
