import { UseFormik } from '../../../../utils/Formik/types';

import TextField from '../../../TextField';

import {
  Container,
  Content,
} from './styles';

interface FormAddSpotProps {
  formik: UseFormik<any>
}

export const FormAddSpot = ({ formik }:FormAddSpotProps) => (
  <Container>
    <Content>
      <TextField
        id="enterpriseName"
        formik={formik}
        label="Empreendimento"
        labelClassName="labelClass"
        required
        requireSymbolPosition="right"
        placeholder="Nome do empreendimento"
      />
    </Content>
    <Content>
      <TextField
        id="enterpriseUnits"
        formik={formik}
        label="Unidade"
        labelClassName="labelClass"
        required
        requireSymbolPosition="right"
        placeholder="Insira as unidades em intervalos de números separados por vírgulas (ex: 1-5,202-204,403-405)"
      />
    </Content>
  </Container>
);
