import axios from 'axios';
import request from '../request';

import {
  IGuest,
  IPreCheckinInfo,
  IGuestPostFront,
  IPatchPreCheckinInfo,
  IPreCheckinBedOrganization,
  PreCheckinGenerateLinkResponseProps,
} from './types';

export type ID = number | string;

// Cria uma nova instancia do axios
// Pois essa request usa o header e para autorização
// É preciso que a autenticação seja desabilitada
export const preCheckinRequest = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    Authorization: 'No Auth',
    'Content-Type': 'application/json',
  },
});

// Precheckin
type IPreCheckinGenerateLink = ({ reservation }: {
  reservation: number;
}) => Promise<PreCheckinGenerateLinkResponseProps>;
export const preCheckinGenerateLink: IPreCheckinGenerateLink = async ({
  reservation,
}) => {
  const { data } = await request.post('/precheckin/generatelink/', {
    reservation,
  });
  return data;
};

type IGetPreCheckin = ({ token }: {
  token: string;
}) => Promise<IPreCheckinInfo>;
export const getPreCheckin: IGetPreCheckin = async ({ token }) => {
  const { data } = await preCheckinRequest.get('/precheckin', {
    headers: {
      'Pre-Checkin-Token': token,
    },
  });

  return data;
};

type IPatchPreCheckin = ({
  data,
  token,
}: {
  token: string;
  data?: IPatchPreCheckinInfo;
  reservation: number;
}) => Promise<IPreCheckinInfo>;
export const patchPreCheckin: IPatchPreCheckin = async ({ token, data, reservation }) => {
  preCheckinRequest.defaults.headers.common['Pre-Checkin-Token'] = token;
  const response = await preCheckinRequest.patch('/precheckin/', { ...data, reservation_id: reservation });

  return response.data;
};

// Organização de cama
type IGetPreCheckinBedOrganization = ({ token }: {
  property: number;
  token: string;
}) => Promise<IPreCheckinBedOrganization[] | undefined>;
export const getPreCheckinBedOrganization: IGetPreCheckinBedOrganization = async ({
  token,
  property,
}) => {
  preCheckinRequest.defaults.headers.common['Pre-Checkin-Token'] = token;
  const { data } = await preCheckinRequest.get('/property/bedarrangement/', {
    params: {
      property__id: property,
    },
  });

  return data as IPreCheckinBedOrganization[] | undefined;
};

// Requests do hóspede
type IDeleteGuestPreCheckin = ({ id, token }: {
  id: ID;
  token: string;
}) => Promise<IGuest>;
export const deleteGuests: IDeleteGuestPreCheckin = async ({ token, id }) => {
  const response = await preCheckinRequest.delete(`/reservation_guests/${id}`, {
    headers: {
      'Pre-Checkin-Token': token,
    },
  });

  return response.data;
};

type IPatchGuestPreCheckin = ({ id, data, token }: {
  id: ID;
  data: IGuestPostFront;
  token: string;
}) => Promise<IGuest>;
export const patchGuests: IPatchGuestPreCheckin = async ({ id, data, token }) => {
  preCheckinRequest.defaults.headers.common['Pre-Checkin-Token'] = token;
  const response = await preCheckinRequest.patch(`/reservation_guests/${id}/`, { ...data });

  return response.data;
};

type IPostGuestPreCheckin = ({ data, token }: {
  data: IGuestPostFront;
  token: string;
}) => Promise<IPreCheckinInfo>;
export const postGuests: IPostGuestPreCheckin = async ({ token, data }) => {
  preCheckinRequest.defaults.headers.common['Pre-Checkin-Token'] = token;
  const response = await preCheckinRequest.post('/reservation_guests/', { ...data });

  return response.data;
};
