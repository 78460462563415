import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline';
import { Link } from 'react-router-dom';
import { OpenInNew, Person as PersonIcon } from '@mui/icons-material';
import { GroupContainer, RightTitleContainer } from './styles';
import { Text } from '../../Text';
import Tooltip from '../../Tooltip';

const GroupRenderer = ({ group }: ReactCalendarGroupRendererProps) => (
  <GroupContainer>
    <Text $color="#000" fontSize="14">
      <div>
        <Tooltip text="Ir para imóvel" placement="top-start">
          <Link
            to={`/buscadepropriedades/${group.id}`}
            target="_blank"
            state={{
              title: 'Imóveis',
              path: '/buscadepropriedades',
            }}
          >
            {group.title}
          </Link>
        </Tooltip>
      </div>
    </Text>
    <RightTitleContainer>
      <PersonIcon fontSize="small" />
      <Text $color="#686868" fontSize="10" $ellipsis>
        {group.rightTitle}
      </Text>
    </RightTitleContainer>
  </GroupContainer>
);

export default GroupRenderer;
