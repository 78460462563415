import React, {
  Children, cloneElement, ReactElement, useMemo,
} from 'react';
import { Divider } from '@mui/material';
import { Label, StyledSection } from './style';
import { itemVariants } from '../../../../../../../utils';
import { ErrorMessage } from '../../../../../../../components';
import { UseFormik } from '../../../../../../../../../utils/Formik/types';
import { IFormik } from '../../types';
import { usePreCheckin } from '../../../../../../../../../context/PreCheckin/PreCheckin';

export interface ISection {
  label?: string;
  divider?: boolean;
  className?: string;
  formik?: UseFormik<IFormik>;
  style?: React.CSSProperties;
  children: ReactElement | ReactElement[];
  error?: {
    is: boolean;
    id: string;
  };
}

const Section = ({
  children,
  divider = false,
  error = undefined,
  label = undefined,
  style = undefined,
  formik = undefined,
  className = undefined,
}: ISection) => {
  const { mode } = usePreCheckin();

  const validateError = useMemo(() => formik && error, [formik, error]);

  // Usar useMemo para evitar recálculo desnecessário de modifiedChildren
  const modifiedChildren = useMemo(() => Children.map(children, (child) => cloneElement(child, {
    ...child.props,
    disabled: mode.is === 'view',
  })), [children]);

  return (
    <StyledSection style={style} className={className} variants={itemVariants}>
      {label && <Label>{label}</Label>}
      {divider && <Divider style={{ marginBottom: '20px', opacity: '0.5' }} />}

      {modifiedChildren}

      {validateError && (
        <ErrorMessage
          id={error?.id as string}
          formik={formik as UseFormik<IFormik>}
        />
      )}
    </StyledSection>
  );
};

Section.defaultProps = {
  divider: false,
  style: undefined,
  error: undefined,
  label: undefined,
  formik: undefined,
  className: undefined,
};

export default React.memo(Section);
