import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { motion, Variants } from 'framer-motion';
import moment from 'moment';
import {
  ArrowsButton,
  ArrowsContainer,
  ButtonContainer,
  LineShimmer,
  ReconcileDamageReservationTableContainer,
} from './styles';
import FormButton from '../../../../FormButton/FormButton';
import { ReservationDetails } from '../../../../../services/GuestDamage/types';
import { useReservations } from '../../../../../hooks/GuestDamage/useReservations';
import { useGuestDamageStep } from '../../../../../hooks/GuestDamage/useGuestDamageStep';

const Arrows = () => (
  <ArrowsContainer>
    <KeyboardArrowUp style={{ marginBottom: '-6px' }} />
    <KeyboardArrowDown style={{ marginTop: '-6px' }} />
  </ArrowsContainer>
);
type ReservationSortedBy = 'code' | 'guest_name' | 'platform' | 'check-in_date' | 'check-out_date';

interface ReconcileDamageReservationTableProps {
  reservations: ReservationDetails[];
  isLoading: boolean;
  haveNextPage: boolean;
  handleNextPage: () => void;
  handleSortReservations: (sortedBy: ReservationSortedBy) => void;
}

const tableVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 120, transition: { duration: 0.2 } },
};
const theadVariants: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};
const tbodyVariants: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.2,
    },
  },
};

const animationDiv: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const animationDivWithDelay: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
export const ReconcileDamageReservationTable = ({
  isLoading,
  reservations,
  handleNextPage,
  haveNextPage,
  handleSortReservations,
}: ReconcileDamageReservationTableProps) => {
  const { setSelectedReservation } = useReservations();
  const { handleInsertDamageDetailsStep } = useGuestDamageStep();
  const isLoadingData = isLoading === true;
  const isLoadedData = reservations.length > 0 && isLoading === false;

  function handleSelectReservation(reservation: ReservationDetails) {
    handleInsertDamageDetailsStep();
    setSelectedReservation(reservation);
  }

  return (
    <>
      {(isLoadingData || isLoadedData) && (
        <>
          <ReconcileDamageReservationTableContainer
            variants={tableVariants}
            initial="closed"
            animate="open"
            key="table"
          >
            <table>
              <motion.thead variants={theadVariants} initial="hidden" animate="visible">
                <tr>
                  <th>
                    <motion.div variants={animationDiv} key="1">
                      Imóvel
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortReservations('code')}>
                        <Arrows />
                      </ArrowsButton>
                    </motion.div>
                  </th>
                  <th>
                    <motion.div variants={animationDiv} key="1">
                      Nome do hóspede
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortReservations('guest_name')}>
                        <Arrows />
                      </ArrowsButton>
                    </motion.div>
                  </th>
                  <th>
                    <motion.div variants={animationDiv} key="1">
                      Plataforma
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortReservations('platform')}>
                        <Arrows />
                      </ArrowsButton>
                    </motion.div>
                  </th>
                  <th>
                    <motion.div variants={animationDiv} key="1">
                      Check-in
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortReservations('check-in_date')}>
                        <Arrows />
                      </ArrowsButton>
                    </motion.div>
                  </th>
                  <th>
                    <motion.div variants={animationDiv} key="1">
                      Check-out
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortReservations('check-out_date')}>
                        <Arrows />
                      </ArrowsButton>
                    </motion.div>
                  </th>
                  <th> </th>
                </tr>
              </motion.thead>
              <motion.tbody
                variants={tbodyVariants}
                initial="hidden"
                animate="visible"
              >
                {isLoading && (
                <>
                  <tr>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                  </tr>
                  <tr>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                  </tr>
                  <tr>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                  </tr>
                  <tr>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                    <td><LineShimmer /></td>
                  </tr>
                </>
                )}
                {(reservations.length > 0 && isLoading === false)
                && reservations.map((reservation) => (
                  <motion.tr key={reservation.id} variants={animationDivWithDelay}>
                    <td>
                      <div>
                        {reservation.property_code || '-'}
                      </div>
                    </td>
                    <td>
                      {reservation.guest_name || '-'}
                    </td>
                    <td>
                      {reservation.ota_name || '-'}
                    </td>
                    <td>
                      {moment(reservation.check_in_date).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {moment(reservation.check_out_date).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      <FormButton
                        isFull
                        variant="outlined"
                        customColor="blue-dark"
                        type="button"
                        onClick={() => handleSelectReservation(reservation)}
                      >
                        Selecionar
                      </FormButton>
                    </td>
                  </motion.tr>
                ))}
              </motion.tbody>
            </table>
          </ReconcileDamageReservationTableContainer>
            {haveNextPage && (
              <ButtonContainer>
                <FormButton
                  onClick={handleNextPage}
                >
                  Carregar mais dados
                </FormButton>
              </ButtonContainer>
            )}
        </>
      )}
    </>
  );
};
