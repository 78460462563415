import { RevenuesHostProps, Status } from '../../../services/FinancialClose/types';
import { compareList } from '../../../utils/Sorting';
import { Order } from '../FinancialCloseContext/types';
import { GridHostProps, OrderBy } from './types';

export const initialValuesFinancialCloseHost: GridHostProps = {
  id: 0,
  host: {
    id: 0,
    name: 'Não informado',
    reservation_commission_fee: '0%',
    franchise_fee: '0',
  },
  reserves: {
    number: 0,
    income: 'R$ 0,00',
  },
  cleanings: {
    number: 0,
    income: 'R$ 0,00',
  },
  commission: 'R$ 0,00',
  onboarding: 'R$ 0,00',
  expense_value: 'R$ 0,00',
  refund_value: 'R$ 0,00',
  royalties: 'R$ 0,00',
  host_manual_fit: {
    value: 'R$ 0,00',
    customColor: 'default',
  },
  transfer: 'R$ 0,00',
  reservations: [
    {
      id: 0,
      property: {
        id: 0,
        code: 'Não associado',
      },
      reserves: {
        number: 0,
        income: 'R$ 0,00',
      },
      cleanings: {
        number: 0,
        income: 'R$ 0,00',
        manual_fit_total: {
          value: 'R$ 0,00',
          customColor: 'default'
        },
      },
      commission: 'R$ 0,00',
      onboarding: 'R$ 0,00',
      expense_value: 'R$ 0,00',
      property_manual_fit: {
        value: 'R$ 0,00',
        customColor: 'default',
      },
      transfer: {
        transfer_value: 'R$ 0,00',
        transfer_future: 'R$ 0,00',
      },
    },
  ],
  expenses: [
    {
      id: 0,
      code: '',
      property_code: '',
      register_date: '',
      expense_date: '',
      category: '',
      description: '',
      value: 'R$ 0,00',
      owner_approved: false,
      status: '',
      paid_by: '',
    },
  ],
  property_status: '',
  status: 'Open',
  revenue_ids: [],
  checked: false,
  expanded: false,
};

export const getFinancialCloseHostUpdated = (data: GridHostProps) => ({
  ...data,
  host: {
    ...data.host,
  },
  property_status: data.property_status,
  reserves: {
    ...data.reserves,
  },
  cleanings: {
    ...data.cleanings,
  },
  host_manual_fit: {
    ...data.host_manual_fit,
  },
  manual_fit: {
    ...data.manual_fit,
  },
  reservations: [...data.reservations],
  expenses: [...data.expenses],
});

export const formatRevenuesHost = (hosts: GridHostProps[], status: Status) => {
  let revenuesIds: number[] = [];

  hosts.forEach((host) => {
    const hostRevenues = host?.revenue_ids || [];
    revenuesIds = [...revenuesIds, ...hostRevenues];
  });

  const revenues: RevenuesHostProps = {
    fields: {
      status,
    },
    revenue_ids: revenuesIds,
  };

  return revenues;
};

export const sortFinancialDataOfHost = (
  financialData: GridHostProps[],
  orderBy: OrderBy,
  order: Order,
  financialDataIds?: number[], // ids of financial data selected in grid
): GridHostProps[] => {
  let sorted: GridHostProps[] = financialData;

  switch (orderBy) {
    case 'host':
      sorted = financialData.map((item) => item).sort((a, b) => compareList(
        a.host.name, b.host.name, order,
      ));
      break;

    case 'property_status':
      sorted = financialData.map((item) => item).sort((a, b) => compareList(
        a.property_status, b.property_status, order,
      ));
      break;

    case 'reservation_property_code':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.property.code, b.property.code, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_number':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.reserves.number, b.reserves.number, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_incomes':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.reserves.income, b.reserves.income, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_cleanings_number':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.cleanings.number, b.cleanings.number, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_cleanings_incomes':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.cleanings.income, b.cleanings.income, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_cleanings_manual_fit_total':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.cleanings.manual_fit_total.value, b.cleanings.manual_fit_total.value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_commission':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.commission, b.commission, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_expense_value':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.expense_value, b.expense_value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_manualfit_property':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.property_manual_fit?.value ?? '', b.property_manual_fit?.value || 0, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_transfer_value':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.transfer.transfer_value, b.transfer.transfer_value, order,
          )) : [...property.reservations],
      }));
      break;

    case 'reservation_transfer_future':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.reservations.sort((a, b) => compareList(
            a.transfer.transfer_future, b.transfer.transfer_future, order,
          )) : [...property.reservations],
      }));
      break;

    case 'expense_code':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.code, b.code, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_property_code':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.property_code, b.property_code, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_register_date':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.register_date, b.register_date, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_date':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.expense_date, b.expense_date, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_category':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.category, b.category, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_description':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.description, b.description, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_value':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.value, b.value, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_owner_approved':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            `${a.owner_approved}`, `${b.owner_approved}`, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_status':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.status, b.status, order,
          )) : [...property.expenses],
      }));
      break;

    case 'expense_paid_by':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseHostUpdated(property),
        expenses: financialDataIds && financialDataIds.includes(property.id)
          ? property.expenses.sort((a, b) => compareList(
            a.paid_by, b.paid_by, order,
          )) : [...property.expenses],
      }));
      break;

    default:
      return sorted;
  }
  return sorted;
};

export const hostIsSelected = (id: number) => {
  const response = localStorage.getItem('@saprom-pms-web/financialclose_host_selected');
  if (response) {
    const data = JSON.parse(response);
    return data?.[id] || false;
  }
  return false;
};
