import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import { PreCheckinLoadingPage } from './pages';
import { PreCheckinContextProvider, usePreCheckin } from '../../context/PreCheckin/PreCheckin';

const PreCheckinPage: React.FC = () => {
  const { loading } = usePreCheckin();

  useEffect(() => {
    document.title = 'Sapron | Pré check-in';
  }, []);

  if (loading.main) {
    return <PreCheckinLoadingPage />;
  }

  return (
    <Outlet />
  );
};

const PreCheckinPageProvided = () => (
  <PreCheckinContextProvider>
    <PreCheckinPage />
  </PreCheckinContextProvider>
);

export default PreCheckinPageProvided;
