import React from 'react';
import RadioButton, { IRadioButtonProps, RadioButtonOptions } from './RadioButton';

type Props = Omit<IRadioButtonProps, 'options'|'formLabel'> & {
  formLabel?: string,
};

const RadioGender: React.FC<Props> = ({
  id,
  formLabel = '*Sexo',
  labelPlacement,
  formik,
}) => {
  const options: RadioButtonOptions[] = [
    { value: 'Female', label: 'Feminino' },
    { value: 'Male', label: 'Masculino' },
    { value: 'Not informed', label: 'Outro' },
  ];

  return (
    <RadioButton
      formLabel={formLabel}
      id={id}
      labelPlacement={labelPlacement}
      options={options}
      formik={formik}
    />
  );
};

export default RadioGender;
