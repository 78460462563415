import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 94vh;

  @media (max-width: 600px) {
    width: 100vw;
  }
`;

export const BoldText = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 5px 0;
`;

export const InformationDiarias = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MidlleText = styled(BoldText)`
  font-weight: 500;
`;

export const ReasonsContainer = styled.div`
  margin-top: 2rem;
`;

export const NotesContainer = styled.div`
  margin-top: 1rem;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  width: 95%;
  position: absolute;
  bottom: 1rem;
`;
