import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ContentConciledReservations from './ContentConciledReservations';
import ContentConciliation from './ContentConciliation';
import ContentPayments from './ContentPayments';
import ContentReservations from './ContentReservations';
import DisconnectConciliation from './DisconnectConciliation';
import HeaderConciliation from './HeaderConciliation';

import {
  Container,
} from './styles';

const ConciliacaoCompenents: React.FC = () => {
  const [openDisconnectConciliation, setOpenDisconnectConciliation] = useState<boolean>(false);

  return (
    <Container>
      <HeaderConciliation
        setOpenDisconnectConciliation={setOpenDisconnectConciliation}
        openDisconnectConciliation={openDisconnectConciliation}
      />
      {!openDisconnectConciliation && (
      <div>
        <DndProvider backend={HTML5Backend}>
          <ContentReservations />
          <ContentConciliation />
          <ContentPayments />
        </DndProvider>
      </div>
      )}
      {openDisconnectConciliation && (
      <div>
        <DndProvider backend={HTML5Backend}>
          <ContentConciledReservations />
          <DisconnectConciliation />
        </DndProvider>
      </div>
      )}
    </Container>
  );
};

export default ConciliacaoCompenents;
