/* eslint-disable no-restricted-globals */
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useManagementPanel } from '../../../hooks/useManagementPanel/useManagementPanel';
import { compareList } from '../../../utils/Sorting';
import SimpleSelect from '../../SimpleSelect';
import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';

import {
  CircularProgressContainer,
  FormButtonContainer,
  ExportCSVModalBackdrop,
  ExportCSVModalContainer,
  ExportCSVModalContent,
  TitleContainer,
} from './styles';
import FormButton from '../../FormButton';
import { getExportedListingCSV, getExportedOwnerCSV, getExportedPropertyCSV } from '../../../services/ManagementPanel/request';

type CSVTypes = 'listing' | 'property' | 'owner' | '';
interface ExportCSVModalFormData {
  csvType: CSVTypes;
}

const ExportCSVModal = () => {
  const { handleCloseExportCSVModal } = useManagementPanel();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isFormButtonDisabled, setIsFormButtonDisabled] = useState(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const formik = useFormik<ExportCSVModalFormData>({
    initialValues: {
      csvType: '',
    },
    onSubmit: async (values) => {
      event?.preventDefault();
      setIsLoadingSubmit(true);

      try {
        switch (values.csvType) {
          case 'owner':
            await getExportedOwnerCSV();
            break;
          case 'listing':
            await getExportedListingCSV();
            break;
          case 'property':
            await getExportedPropertyCSV();
            break;
          default:
            break;
        }
        toast.success('CSV exportado com sucesso!');
        formik.resetForm();
        handleCloseExportCSVModal();
      } catch (err) {
        if (err instanceof Error) {
          toastErrorRequest('Não foi possível exportar o CSV!');
          setIsLoadingSubmit(false);
        }
      } finally {
        setIsLoadingSubmit(false);
      }
    },
  });

  function isFormDisable() {
    if (formik.values.csvType === '') {
      setIsFormButtonDisabled(true);
    } else {
      setIsFormButtonDisabled(false);
    }
  }

  useEffect(() => {
    isFormDisable();
  }, [formik.values.csvType]);

  return (
    <>
      <ExportCSVModalContainer onClick={handleCloseExportCSVModal}>
        <ExportCSVModalContent onClick={(e) => e.stopPropagation()}>
          <TitleContainer>
            <h1>Exportar CSVs</h1>
            <CloseIcon onClick={handleCloseExportCSVModal} />
          </TitleContainer>
          {isLoadingSubmit
            ? (
              <CircularProgressContainer>
                <CircularProgress size={80} />
              </CircularProgressContainer>
            )
            : (
              <form onSubmit={formik.handleSubmit}>
                <SimpleSelect
                  id="csvType"
                  formik={formik}
                  label="Escolha o CSV que deseja exportar"
                  options={[
                    { value: 'owner', valueLabel: 'Proprietário' },
                    { value: 'listing', valueLabel: 'Listing' },
                    { value: 'property', valueLabel: 'Imóvel' },
                  ].sort((firstType,
                    nextType) => compareList(firstType.valueLabel, nextType.valueLabel))}
                />
                <FormButtonContainer>
                  <FormButton disable={isLoadingSubmit || isFormButtonDisabled}>
                    Exportar
                  </FormButton>
                </FormButtonContainer>
              </form>
            )}
        </ExportCSVModalContent>
      </ExportCSVModalContainer>
      <ExportCSVModalBackdrop onClick={handleCloseExportCSVModal} />
    </>
  );
};

export default ExportCSVModal;
