import { BankDetails } from '../Owner/types';
import request from '../request';
import { RequestHost } from './types';

export const getHosts = async (): Promise<Array<RequestHost>> => {
  const { data } = await request.get('/account/host/');
  return data as RequestHost[];
};

export const getHostBank = async () => {
  const { data } = await request.get('/financial/host/bank_details/');
  return data as BankDetails[];
};

export const postHostBank = async (params: BankDetails) => {
  const { data } = await request.post('/financial/host/bank_details/', params);
  return data;
};

export const patchHostBank = async (params: BankDetails, id: number) => {
  const { data } = await request.patch(`/financial/host/bank_details/${id}/`, params);
  return data;
};

export const DeleteHostBank = async (id: number) => {
  const { data } = await request.delete(`/financial/host/bank_details/${id}/`);
  return data;
};
