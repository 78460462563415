import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;
export const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 30px 30px 20px 30px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  box-shadow: rgba(0, 0, 0, 0.096) 0px 25px 20px -20px;
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.6s ease forwards;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    line-height: 25px;
    font-size: 1.3rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: bold;
    color: #151B26;
  }
  img {
    width: 17px;
    height: 21px;
    margin-right: 15px;
  }
  div {
    &.left {
    width: 70%;
    display: flex;
    }
    &.right {
    width: 30%;
    display: flex;
    }
  }
`;
