import React, { createContext, useContext, useState } from 'react';
import { MonthlyBudgetPropertyDetailsModal } from '../services/MonthlyBudget/types';

interface Props {
  children: JSX.Element;
}

interface EstimateCartContextData {
  estimateCart: EstimateCart,
  setEstimateCart: (arg0: EstimateCart) => void;
  count: number;
  setCount: (arg0: number) => void;
  addToCart: (arg0: EstimateCartCategory) => void;
  removePropertyToCart: (arg0: number) => void;
  idRemove: number;
}

export type EstimateCartCategory = {
  name: string;
  properties: Array<MonthlyBudgetPropertyDetailsModal>;
};

export type EstimateCart = {
  categories: Array<EstimateCartCategory>;

};

export const EstimateCartContext = createContext<EstimateCartContextData>({
} as EstimateCartContextData);

export default function EstimateCartProvider({ children }:Props) {
  const [count, setCount] = useState<number>(0);
  const [idRemove, setIdRemove] = useState<number>(0);
  const [estimateCart, setEstimateCart] = useState<EstimateCart>({
    categories: [],
  });

  function countProperties(categories: Array<EstimateCartCategory>) {
    let countAux: number = 0;
    categories.forEach((item) => {
      countAux += item.properties.length;
    });
    setCount(countAux);
  }

  function addToCart(category: EstimateCartCategory) {
    if (estimateCart.categories.filter((item) => category.name === item.name).length === 0) {
      setEstimateCart({ categories: [...estimateCart.categories, category] });
      countProperties([...estimateCart.categories, category]);
    } else {
      const categories = estimateCart.categories.map((item) => {
        const itemAux = item;
        if (item.name === category.name) {
          itemAux.properties = [...item.properties, ...category.properties];
        }
        return itemAux;
      });
      setEstimateCart({ categories });
      countProperties(categories);
    }
  }

  function removePropertyToCart(idProperty: number) {
    setIdRemove(idProperty);
    const categories = estimateCart.categories.map((item) => {
      const itemAux = item;
      itemAux.properties = item.properties.filter((property) => property.id !== idProperty);
      return itemAux;
    });
    const categoriesAux = categories.filter((category) => category.properties.length > 0);
    setEstimateCart({ categories: categoriesAux });
    countProperties(categoriesAux);
  }

  return (
    <EstimateCartContext.Provider
      value={{
        estimateCart,
        setEstimateCart,
        addToCart,
        removePropertyToCart,
        count,
        setCount,
        idRemove,
      }}
    >
      {children}
    </EstimateCartContext.Provider>
  );
}

export function useEstimateCart() {
  const context = useContext(EstimateCartContext);
  const defaultProps = {
    categories: [],
  };

  const {
    estimateCart, setEstimateCart, addToCart, removePropertyToCart,
    count, setCount, idRemove,
  } = context;
  return {
    estimateCart,
    setEstimateCart,
    addToCart,
    removePropertyToCart,
    count,
    setCount,
    idRemove,
    defaultProps,
  };
}
