import request from '../request';
import { RequestHostProperties } from './types';

export const getHostProperties = async (
  page: number,
  code: string | undefined,
  status:string | undefined,
  bedroom_quantity?: number,
  location__in?: string,
  hostNameFilter?: string | undefined,
)
: Promise<RequestHostProperties> => {
  const { data } = await request.get('/properties/host/', {
    params: {
      ordering: 'code',
      search: code,
      status,
      bedroom_quantity: bedroom_quantity === 0 ? undefined : bedroom_quantity,
      location__in,
      host_name: hostNameFilter,
      page: page || 1,
      page_size: 15,
    },
  });
  return data as RequestHostProperties;
};
