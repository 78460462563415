import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const SwitcherOption = styled.p<{
  active: boolean;
}>`
  color: var(--gray-800, #394760);
  opacity: ${({ active }) => (active ? '1' : '0.5')};

  /* fonte/corpo/paragrafo/2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;
