import React, { FC } from 'react';

import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
  Background,
  ModalBox,
} from './styles';

interface Props {
  open?: boolean;
  handleClose?: any;
  children: React.ReactElement;
  newStyles?: boolean;
}

const Transition = React.forwardRef((props: TransitionProps & {
  children?: React.ReactElement<any, any>
}| any,
ref: React.Ref<unknown>) => <Slide direction="up" ref={ref} {...props} />);

const ModalTemplate: FC<Props> = ({
  children,
  open = false,
  handleClose = () => {},
  newStyles,
}) => (
  <Background
    newStyles={newStyles}
    TransitionComponent={Transition}
    keepMounted
    open={open}
    onClose={() => handleClose(false)}
    disableScrollLock
  >
    <ModalBox>
      {children}
    </ModalBox>
  </Background>
);

export default ModalTemplate;
