import styled, { keyframes } from 'styled-components';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 0 10px 0 0;
`;

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.2fr 1.1fr 1.1fr 1.1fr 1.2fr;
  margin-top: 20px;
  padding: 0 10px;
  width: 100%;
  justify-content: center;
  user-select: none;
  
  div {
    display: flex;
  }

  &:nth-child(n) {
    opacity: 0;
    animation: ${showItems} 0.3s 0.1s ease forwards;
  }

   &:nth-child(2n) {
    opacity: 0;
    animation: ${showItems} 0.3s 0.3s ease forwards;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 0.3fr 1fr 1.5fr 1fr 1fr 1fr;
  }
`;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;

  h1 {
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.1rem;
    font-weight: normal;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2px;

    img {
      width: 7px;
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
  }
`;

export const ItemLine = styled.div``;

export const ReservationCode = styled.div`
  flex-direction: column;
  gap: 20px;
`;

export const ImmobileCode = styled.div`
`;

export const ReservationDate = styled.div`
`;

export const ReservationStaff = styled.div`
`;

export const WhatsApp = styled.div`
`;

export const CheckIn = styled.div`
`;

export const PendingValue = styled.div``;

export const TotalValue = styled.div``;
