import styled from 'styled-components';

export const DamageResumeContainer = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin: 0.5rem;
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #E4EAEF;
  padding: 1rem;
  border-radius: 0 0 10px 10px;
`;

export const TotalText = styled.p`
  color: #394760;
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;

  span {
    margin-right: 0.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    color: #394760;
    text-align: center;
    font-family: Inter;
  }
`;
