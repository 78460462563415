import styled from 'styled-components';

const MAX_SIZE_MOBILE = 500; // 500px

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 16px;
  min-height: 90px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InitialName = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-weight: 700;
  font-size: 20px;

  font-family: ${({ theme }) => theme.fonts.familys._1};

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    max-width: 40px;
    max-height: 40px;
    font-size: 14px;
  }
`;

export const InputComment = styled.textarea`
  height: auto;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  color: ${({ theme }) => theme.palette.grey._600.hex()};
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  padding-left: 15px;
  padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
`;

export const EditComment = styled.div<{
  active: boolean;
}>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const Options = styled.img`
  cursor: pointer;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    position: absolute;
    z-index: 14;
  }
`;

export const Content = styled.div`
  margin-left: 8px;
  width: 95%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

`;

export const Informations = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: unset;
  }
`;

export const NameAuthor = styled.h2`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 700;
  margin-right: 10px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 14px;
  }
`;

export const Separator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.blue._850.hex()};
  height: 20px;
  margin-right: 10px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    display: none;
  }
`;

export const Date = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey._450.hex()};
  margin-right: 10px;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    margin: 5px 0;
    font-size: 12px;
  }
`;

export const Comment = styled.div<{
  active: boolean;
}>`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 400;
  display: ${({ active }) => (active ? 'none' : 'flex')};
`;

export const IconImage = styled.img`
  margin-right: 10px;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
`;
