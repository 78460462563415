import styled from 'styled-components';
import { MAX_SIZE_MOBILE } from '../utils';

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  padding: 16px 0;
`;

export const Title = styled.h4`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  font-size: 1.6rem !important;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  margin-left: 16px;
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 1.375rem !important;
  }
`;
