import styled from 'styled-components';

import { animationTime, shimmerAnimation } from '../animationShimmer';
import { MAX_SIZE_MOBILE } from '../../utils';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 10px;
  position: relative;

  width: 100%;
  overflow: hidden;
`;

export const Table = styled.div`
  width: 100%;
  background: #fff;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(10, 40px);
  gap: 0.5rem;
  border-radius: 10px;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    width: calc(100% - 32px);
    margin-left: 16px;
  }
`;

export const Item = styled.div`
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
`;

export const Modal = styled.div`
  width: 150px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: red;
  border-radius: 10px;
  margin: 20px;
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;

`;
