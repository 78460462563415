import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.form`
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 0px;
  margin-bottom: 24px;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 8px;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    padding: 0;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 8px;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    padding: 24px 16px 0px 16px;
    border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;  
`;

export const Title = styled.h4`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  padding-left: 0px;

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
`;

export const AlertMessageContainer = styled.div`
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    margin-right: 16px;
  }
`;

export const Label = styled.h6`
 font-family: Inter;
 font-size: 14px;
 font-weight: 600;
 line-height: 16px;
 padding-bottom: 8px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 282px;
 
  .inputLabel {
    font-family: ${({ theme }) => theme.fonts.familys._6} !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #969696 !important;
  }

  .MuiOutlinedInput-root {
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()} !important;
  }
   
  &:hover {
    border-color: ${({ theme }) => theme.palette.green.main.hex()};
    transition: 0.7s;
  }

  select {
    width: 170px !important;
  }
  
  fieldset {
    border: 0 !important;
    width: 100% !important;
  }

  .MuiSvgIcon-root {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    fill: ${({ theme }) => theme.palette.blue._970.hex()} !important;
    font-size: 2.5rem !important;
    position: relative !important;
    right: 0px !important;
    top: 2px !important;
  }

  .MuiFormControl-root {
    gap: 0 !important;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    max-width: 100%;
    margin-top: 16px;
    
    select, fieldset {
      width: 100% !important;
    }
  }
`;

export const ContentMobile = styled.div`
 .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin: 0 8px !important;
  }
`;
