import {
  FC,
  ReactElement,
  useState,
  useEffect,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';

import { createContext } from 'use-context-selector';

import { IAccountSimpleHostsResponse } from '../../services/AccountSimpleHosts/types';
import { compareList } from '../../utils/Sorting';
import { SelectProps } from '../../components/DropdownAutocomplete/DropdownAutocomplete';

import {
  ChangePropertyHostProps,
  OrderGridOptions,
  PropertyChangeHostProps,
} from './types';

import {
  getExchangedHostsUpdated,
  initialValues,
  sortAllExchangedHostsList,
  sortPartialExchangedHostsList,
} from './utils';

type ModalProps = {
  open: boolean,
  infos: ChangePropertyHostProps | null,
};

interface IProps {
  hosts: IAccountSimpleHostsResponse[],
  setHosts: (allHosts: IAccountSimpleHostsResponse[]) => void,
  hostsListSorted: SelectProps[],
  exchangedHosts: ChangePropertyHostProps[],
  setExchangedHosts: (hosts: ChangePropertyHostProps[]) => void,
  updateExchangedHosts: (currentData: ChangePropertyHostProps[],
    updatedData: ChangePropertyHostProps[]) => void,
  openModalConfirmExchangeHost: boolean,
  setOpenModalConfirmExchangeHost: (open: boolean) => void,
  modalEditExchangeHost: ModalProps,
  setModalEditExchangeHost: (payload: ModalProps) => void,
  modalDeleteExchangeHost: ModalProps,
  setModalDeleteExchangeHost: (payload: ModalProps) => void,
  payloadExchangedHost: PropertyChangeHostProps,
  setPayloadExchangedHost: (payloadChangeHost: PropertyChangeHostProps) => void,
  exchangeIsConfirmed: boolean,
  setExchangeIsConfirmed: (isConfirmed: boolean) => void,
  isSubmitting: boolean,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  orderGridBy: OrderGridOptions,
  setOrderGridBy: Function,
}

export const ChangePropertyHost = createContext<IProps>({
  hosts: [],
  setHosts: (allHosts: IAccountSimpleHostsResponse[]) => allHosts,
  hostsListSorted: [],
  exchangedHosts: initialValues,
  setExchangedHosts: (hosts: ChangePropertyHostProps[]) => hosts,
  updateExchangedHosts: () => {},
  openModalConfirmExchangeHost: false,
  setOpenModalConfirmExchangeHost: (open: boolean) => open,
  modalEditExchangeHost: {
    open: false,
    infos: null,
  },
  setModalEditExchangeHost: (payload: ModalProps) => payload,
  modalDeleteExchangeHost: {
    open: false,
    infos: null,
  },
  setModalDeleteExchangeHost: (payload: ModalProps) => payload,
  payloadExchangedHost: {} as PropertyChangeHostProps,
  setPayloadExchangedHost: (payloadChangeHost: PropertyChangeHostProps) => payloadChangeHost,
  exchangeIsConfirmed: false,
  setExchangeIsConfirmed: (isConfirmed: boolean) => isConfirmed,
  orderGridBy: {
    order_by: 'register_date',
    order_option: 'all',
    order: 'asc',
    order_start_position: 0,
    order_end_position: 0,
  },
  setOrderGridBy: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {},
});

export const ChangePropertyHostProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [hosts, setHosts] = useState<IAccountSimpleHostsResponse[]>([]);
  const [exchangedHosts, setExchangedHosts] = useState<ChangePropertyHostProps[]>(initialValues);
  const [openModalConfirmExchangeHost, setOpenModalConfirmExchangeHost] = useState<boolean>(false);
  const [exchangeIsConfirmed, setExchangeIsConfirmed] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [
    payloadExchangedHost,
    setPayloadExchangedHost,
  ] = useState<PropertyChangeHostProps>({} as PropertyChangeHostProps);

  const [modalEditExchangeHost, setModalEditExchangeHost] = useState<ModalProps>({
    open: false,
    infos: null,
  });

  const [modalDeleteExchangeHost, setModalDeleteExchangeHost] = useState<ModalProps>({
    open: false,
    infos: null,
  });

  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'register_date',
    order_option: 'all',
    order: 'asc',
    order_start_position: 0,
    order_end_position: 0,
  });

  const handleSetHosts = useCallback((allHosts: IAccountSimpleHostsResponse[]) => {
    setHosts(allHosts);
  }, []);

  const hostsListSorted: SelectProps[] = useMemo(() => hosts.map((host) => (
    {
      optionText: `${host.full_name}`,
      optionValue: `${host.id}`,
    }
  )).sort((a, b) => compareList(a.optionText, b.optionText)), [hosts]);

  const handleSetExchangedHosts = useCallback((allHosts: ChangePropertyHostProps[]) => {
    setExchangedHosts(allHosts);
  }, []);

  const handleUpdateExchangedHosts = useCallback((currentData: ChangePropertyHostProps[],
    dataUpdated: ChangePropertyHostProps[]) => {
    const newData: ChangePropertyHostProps[] = [...currentData];

    dataUpdated.forEach((itemUpdated) => {
      const indexItem = currentData.findIndex((currentItem) => currentItem.id === itemUpdated.id);
      newData[indexItem] = { ...getExchangedHostsUpdated(itemUpdated) };
    });

    setExchangedHosts(newData);
  }, []);

  const handleopenModalConfirmExchangeHost = useCallback((open: boolean) => {
    setOpenModalConfirmExchangeHost(open);
  }, []);

  const handleSetPayloadExchangedHost = useCallback((payload: PropertyChangeHostProps) => {
    setPayloadExchangedHost(payload);
  }, []);

  const handleSetExchangeIsConfirmed = useCallback((isConfirmed: boolean) => {
    setExchangeIsConfirmed(isConfirmed);
  }, []);

  const handleSetOrderGridBy = useCallback((orderBy: OrderGridOptions) => {
    setOrderGridBy(orderBy);
  }, []);

  const handleSetModalEditExchangeHost = useCallback((payload: ModalProps) => {
    setModalEditExchangeHost(payload);
  }, []);

  const handleSetModalDeleteExchangeHost = useCallback((payload: ModalProps) => {
    setModalDeleteExchangeHost(payload);
  }, []);

  useEffect(() => {
    const response: ChangePropertyHostProps[] = orderGridBy.order_option === 'all'
      ? sortAllExchangedHostsList(
        exchangedHosts,
        orderGridBy.order_by,
        orderGridBy.order,
      )
      : sortPartialExchangedHostsList(
        exchangedHosts,
        orderGridBy.order_by,
        orderGridBy.order,
        orderGridBy?.order_start_position || 0,
        orderGridBy?.order_end_position || 0,
      );

    handleSetExchangedHosts([...response]);
  }, [orderGridBy]);

  const value = useMemo(() => ({
    hosts,
    setHosts: handleSetHosts,
    hostsListSorted,
    exchangedHosts,
    setExchangedHosts: handleSetExchangedHosts,
    updateExchangedHosts: handleUpdateExchangedHosts,
    openModalConfirmExchangeHost,
    setOpenModalConfirmExchangeHost: handleopenModalConfirmExchangeHost,
    modalEditExchangeHost,
    setModalEditExchangeHost: handleSetModalEditExchangeHost,
    modalDeleteExchangeHost,
    setModalDeleteExchangeHost: handleSetModalDeleteExchangeHost,
    payloadExchangedHost,
    setPayloadExchangedHost: handleSetPayloadExchangedHost,
    exchangeIsConfirmed,
    setExchangeIsConfirmed: handleSetExchangeIsConfirmed,
    orderGridBy,
    setOrderGridBy: handleSetOrderGridBy,
    isSubmitting,
    setIsSubmitting,
  }), [
    hosts,
    setHosts,
    hostsListSorted,
    exchangedHosts,
    setExchangedHosts,
    openModalConfirmExchangeHost,
    setOpenModalConfirmExchangeHost,
    modalEditExchangeHost,
    setModalEditExchangeHost,
    modalDeleteExchangeHost,
    setModalDeleteExchangeHost,
    payloadExchangedHost,
    setPayloadExchangedHost,
    exchangeIsConfirmed,
    setExchangeIsConfirmed,
    orderGridBy,
    setOrderGridBy,
    isSubmitting,
    setIsSubmitting,
  ]);

  return (
    <ChangePropertyHost.Provider
      value={value}
    >
      {children}
    </ChangePropertyHost.Provider>
  );
};
