import React, { ReactNode } from 'react';

import {
  Zoom,
  styled,
  TooltipProps,
  tooltipClasses,
  Tooltip as MuiTooltip,
} from '@mui/material';

import {
  Content,
  Description,
  CloseOutlinedIcon,
  Backdrop,
  Button,
} from './styles';

import InfoButton from '../../IconsComponents/InfoButton';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f9f9f9',
    minWidth: 220,
    maxWidth: 300,
    width: 'calc(100% - 30px)',
    borderRadius: '16px',
    border: '1px solid #969696',
    padding: '16px',
    marginLeft: '12px',
  },

  '& p, strong': {
    color: '#394760',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '0.8rem',
    width: '100%',
    textAlign: 'start',
  },
}));

interface ITooltip {
  text: ReactNode;
  isOpen?: boolean,
}

const Tooltip = ({ text, isOpen = false }: ITooltip) => {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <HtmlTooltip
        arrow
        open={open}
        enterTouchDelay={0}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleClose}
        TransitionComponent={Zoom}
        style={{ opacity: 0.8, fontSize: 20 }}
        PopperProps={{
          disablePortal: false,
        }}
        title={(
          <Content>
            <Description>
              {text}
            </Description>
            <CloseOutlinedIcon onClick={handleClose} />
          </Content>
        )}
      >
        <Button>
          <InfoButton color="#222" onClick={() => handleOpen()} />
        </Button>
      </HtmlTooltip>
      {open && <Backdrop onClick={handleClose} />}
    </div>
  );
};

Tooltip.defaultProps = {
  isOpen: false,
};

export default Tooltip;
