import request from '../../request';

import {
  ManualFitParams,
  ManualFitProps,
} from '../types';

import { formatedDateOfRequest } from '../../../utils/Formatter';

export const getManualFitCleaning = async (params?: ManualFitParams) => {
  const { data } = await request.get<ManualFitProps[]>('/financial/cleaning_fee/manual_fit/', {
    params: {
      reservation_id: params?.reservation_id || undefined,
    },
  });

  const dataFormatted = data.map((item) => ({
    ...item,
    value: Number(item.value),
  }));

  return dataFormatted;
};

export const deleteManualFitCleaning = async (id: number) => request.delete(`/financial/cleaning_fee/manual_fit/${id}/`);

export const postManualFitCleaning = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    is_adding: data.is_adding,
    reservation: data.reservation,
    description: data.description,
    problem_type: data.problem_type,
    problem_description: data.problem_description,
    guest_departure_date: data?.guest_departure_date ? formatedDateOfRequest(data.guest_departure_date, 'DD/MM/YYYY') : null,
  };

  return request.post<ManualFitProps>('/financial/cleaning_fee/manual_fit/', {
    ...body,
  });
};

export const patchManualFitCleaning = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    description: data.description,
  };

  return request.patch<ManualFitProps>(`/financial/cleaning_fee/manual_fit/${data.id}/`, {
    ...body,
  });
};
