/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import { PaymentArbnbConciliation } from '../../../services/Conciliation/types';
import { numberToCurrency } from '../../../utils/Formatter';
import {
  AmountPaid,
  Date, Text,
  Amount,
  Container,
  ContentInfo,
  GuestName,
  TextInfo,
} from './styles';

interface Props {
  payment: PaymentArbnbConciliation,
}

const CardPayment = ({ payment }: Props): JSX.Element => {
  const { setPaymentMove, addNewPayment } = useConciliation();

  const [{ isDragging }, drag] = useDrag({
    type: 'Card',
    item: {
      id: 1,
      name: 'Fernanda Cruz',
    },
    end: (draggedItem, monitor) => {
      addNewPayment();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  useEffect(() => {
    if (isDragging) {
      setPaymentMove(payment);
    }
  }, [isDragging]);

  return (
    <Container ref={drag} opacity={opacity}>
      <ContentInfo>
        <TextInfo>
          ID:
          {' '}
          {payment.id}
        </TextInfo>
        <GuestName>
          {payment.guest?.user?.first_name}
          {' '}
          {payment.guest?.user?.last_name}
        </GuestName>
        <TextInfo>
          {payment.ota?.name}
          {' '}
          |
          {' '}
          {payment.property?.code}
          {' '}
          |
          {' '}
          {payment.confirmation_code}
        </TextInfo>
        <Date>
          Data pagamento:
          {' '}
          {moment(payment.payment_date).format('DD/MM/YYYY')}
        </Date>
      </ContentInfo>
      <AmountPaid>
        <Text>Valor pago:</Text>
        <Amount>
          {numberToCurrency(parseFloat(payment.amount_paid))}
        </Amount>
      </AmountPaid>
    </Container>
  );
};

export default CardPayment;
