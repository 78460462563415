import styled from 'styled-components';
import { PopupTemplateModels } from '../../context/CustomPopupsContext/types';

const MAX_SIZE_MOBILE = 900; // 900px

export const Backdrop = styled.div<{ isLogged: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({ isLogged }) => (isLogged ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #00000050;
  backdrop-filter: blur(1px);
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  z-index: 999999999999999;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 2rem;
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const Container = styled.div<{ type: PopupTemplateModels }>`
  width: 100%;
  height: 100%;

  max-width: ${({ type }) => (type === 'Default' ? '550px' : '900px')};
  max-height: 550px;
  border-radius: 16px;

  background: ${({ theme }) => theme.palette.white.main.hex()};
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    max-height: 100%;
  }
`;

export const Content = styled.div<{ type: PopupTemplateModels }>`
  width: ${({ type }) => (type === 'Default' ? '95%' : '100%')};
  height: ${({ type }) => (type === 'Default' ? '95%' : '100%')};
  position: absolute;
`;
