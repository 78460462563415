import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { createContext } from 'use-context-selector';
import { ColumnFiltersState } from '@tanstack/react-table';
import {
  ExpenseParams, ExpensePropsPaginationResults,
  ExpenseResponseProps as ExpenseResponsePropsType, SimpleProperty,
} from '../../services/Expenses/types';
import { getExpenses } from '../../services/Expenses/request';

interface IFilteredValues {
  property_id: number;
  host_id: number;
  expense_status: string;

  startDateRegister: Date;
  endDateRegister: Date;
  startDateTransfer: Date;
  endDateTransfer: Date;
}

const today = new Date();
const tomorrow = new Date(today);
const initialValuesFilter = {
  property_id: 0,
  host_id: 0,
  startDateRegister: today,
  endDateRegister: tomorrow,
  startDateTransfer: today,
  endDateTransfer: tomorrow,
  expense_status: 'Selecione',
};

const initialValues = {
  property_id: 0,
  host_id: 0,
  expense_status: undefined,
  refund_date_start: undefined,
  refund_date_end: undefined,
  register_date_start: undefined,
  register_date_end: undefined,
};

type SimplePropertyProps = {
  actives: SimpleProperty[],
  inactives: SimpleProperty[],
};

interface ExpenseResponseProps {
  openModalExpenses: boolean,
  setOpenModalExpenses: Function,
  isEditingModal: boolean,
  setIsEditingModal: Function,
  openExpensesInformationModal: boolean,
  setOpenExpensesInformationModal: Function,
  indexExpenseSelected: number,
  setIndexExpenseSelected: Function,
  expenseData: ExpenseResponsePropsType,
  setExpenseData: Dispatch<SetStateAction<ExpenseResponsePropsType>>,
  filteredValues: IFilteredValues,
  setFilteredValues: Function;
  hasFiltered: boolean;
  setHasFiltered: Function;
  filterParams: ExpenseParams;
  setFilterParams: Function;
  nextPage: number;
  setNextPage: Function;
  page: number;
  setPage: Function;
  hasMoreExpenses: boolean;
  setHasMoreExpenses: Function;
  globalFilter: ColumnFiltersState;
  setGlobalFilter: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  openExportExpensesModal: boolean;
  setOpenExportExpensesModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModalSelectWhoPays: boolean;
  setModalSelectWhoPays: React.Dispatch<React.SetStateAction<boolean>>;
  expensesList: ExpensePropsPaginationResults;
  setExpensesList: React.Dispatch<React.SetStateAction<ExpensePropsPaginationResults>>;
  fetchExpenseData: (expensePage: number, expensePageFilterParams?: ExpenseParams) => Promise<void>;
  expensesPage: number | null;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  properties: SimplePropertyProps,
  setProperties: React.Dispatch<React.SetStateAction<SimplePropertyProps>>
}

export const ExpenseContext = createContext<ExpenseResponseProps>({
  openModalExpenses: false,
  setOpenModalExpenses: () => {},
  isEditingModal: false,
  setIsEditingModal: () => {},
  openExpensesInformationModal: false,
  setOpenExpensesInformationModal: () => {},
  indexExpenseSelected: 0,
  setIndexExpenseSelected: () => {},
  expenseData: {} as ExpenseResponsePropsType,
  setExpenseData: () => {},
  filteredValues: initialValuesFilter,
  setFilteredValues: () => {},
  properties: {
    actives: [],
    inactives: [],
  },
  setProperties: () => {},
  hasFiltered: false,
  setHasFiltered: () => {},
  filterParams: initialValues,
  setFilterParams: () => {},
  nextPage: 2,
  setNextPage: () => {},
  page: 1,
  setPage: () => {},
  hasMoreExpenses: false,
  setHasMoreExpenses: () => {},
  globalFilter: [],
  setGlobalFilter: () => {},
  openExportExpensesModal: false,
  setOpenExportExpensesModal: () => {},
  openModalSelectWhoPays: false,
  setModalSelectWhoPays: () => {},
  expensesList: {} as ExpensePropsPaginationResults,
  setExpensesList: () => {},
  fetchExpenseData: async () => {},
  expensesPage: null,
  loading: false,
  setLoading: () => {},
});

export const ExpenseProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [openModalExpenses, setOpenModalExpenses] = useState<boolean>(false);
  const [isEditingModal, setIsEditingModal] = useState<boolean>(false);
  const [openExpensesInformationModal, setOpenExpensesInformationModal] = useState<boolean>(false);
  const [indexExpenseSelected, setIndexExpenseSelected] = useState<number>(0);
  const [expenseData, setExpenseData] = useState<ExpenseResponsePropsType>({
  } as ExpenseResponsePropsType);

  const [loading, setLoading] = useState<boolean>(false);
  const [properties, setProperties] = useState<SimplePropertyProps>({
    actives: [],
    inactives: [],
  });

  const [expensesList,
    setExpensesList] = useState<ExpensePropsPaginationResults>({} as ExpensePropsPaginationResults);

  const [globalFilter, setGlobalFilter] = React.useState<ColumnFiltersState>([]);

  const [page, setPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(2);

  const [hasMoreExpenses, setHasMoreExpenses] = useState<boolean>(false);

  const [hasFiltered, setHasFiltered] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<ExpenseParams>(initialValues);
  const [filteredValues, setFilteredValues] = useState<IFilteredValues>(initialValuesFilter);

  const [openModalSelectWhoPays, setModalSelectWhoPays] = useState<boolean>(false);
  const [openExportExpensesModal, setOpenExportExpensesModal] = useState<boolean>(false);

  const expensesPage = expensesList.total_pages > expensesList.current_page
    ? expensesList.current_page + 1
    : null;

  async function fetchExpenseData(expensePage: number = 1,
    expensePageFilterParams?: ExpenseParams) {
    try {
      setLoading(true);
      const data = await getExpenses(expensePage, expensePageFilterParams);

      setExpensesList((prev) => {
        if (prev?.hasMoreExpenses) {
          if (data?.current_page !== prev?.current_page) {
            return {
              ...prev,
              ...data,
              results: [
                ...prev?.results,
                ...data?.results,
              ],
            };
          }
        }

        return data;
      });
    } finally {
      setLoading(false);
    }
  }

  const handleNextPage = useCallback((newNextPage: number) => {
    setNextPage(newNextPage);
  }, []);

  const handlePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleFilterParams = useCallback((newFilterParam: ExpenseParams) => {
    setFilterParams(newFilterParam);
  }, []);

  const handleHasFiltered = useCallback((newHasFiltered: boolean) => {
    setHasFiltered(newHasFiltered);
  }, []);

  const handleFilteredValues = useCallback((newFilteredValues: IFilteredValues) => {
    setFilteredValues(newFilteredValues);
  }, []);

  const handleOpenModalExpenses = useCallback((open: boolean) => {
    setOpenModalExpenses(open);
  }, []);

  const handleIsEditingModal = useCallback((isEditing: boolean) => {
    setIsEditingModal(isEditing);
  }, []);

  const handleOpenExpensesInformationModal = useCallback((open: boolean) => {
    setOpenExpensesInformationModal(open);
  }, []);

  const handleIndexExpenseSelected = useCallback((index: number) => {
    setIndexExpenseSelected(index);
  }, []);

  const handleHasMoreExpenses = useCallback((newHasMoreExpense: boolean) => {
    setHasMoreExpenses(newHasMoreExpense);
  }, []);

  const value = useMemo(() => ({
    openModalExpenses,
    setOpenModalExpenses: handleOpenModalExpenses,
    isEditingModal,
    setIsEditingModal: handleIsEditingModal,
    openExpensesInformationModal,
    setOpenExpensesInformationModal: handleOpenExpensesInformationModal,
    indexExpenseSelected,
    setIndexExpenseSelected: handleIndexExpenseSelected,
    expenseData,
    setExpenseData,
    filteredValues,
    setFilteredValues: handleFilteredValues,
    hasFiltered,
    setHasFiltered: handleHasFiltered,
    filterParams,
    setFilterParams: handleFilterParams,
    nextPage,
    setNextPage: handleNextPage,
    page,
    setPage: handlePage,
    hasMoreExpenses,
    setHasMoreExpenses: handleHasMoreExpenses,
    globalFilter,
    setGlobalFilter,
    openExportExpensesModal,
    setOpenExportExpensesModal,
    openModalSelectWhoPays,
    setModalSelectWhoPays,
    expensesList,
    setExpensesList,
    fetchExpenseData,
    expensesPage,
    loading,
    setLoading,
    properties,
    setProperties,
  }), [
    openModalExpenses,
    setOpenModalExpenses,
    isEditingModal,
    setIsEditingModal,
    openExpensesInformationModal,
    setOpenExpensesInformationModal,
    indexExpenseSelected,
    setIndexExpenseSelected,
    expenseData,
    setExpenseData,
    filteredValues,
    handleFilteredValues,
    hasFiltered,
    handleHasFiltered,
    filterParams,
    handleFilterParams,
    nextPage,
    handleNextPage,
    page,
    handlePage,
    hasMoreExpenses,
    handleHasMoreExpenses,
    globalFilter,
    setGlobalFilter,
    openExportExpensesModal,
    setOpenExportExpensesModal,
    openModalSelectWhoPays,
    setModalSelectWhoPays,
    expensesList,
    setExpensesList,
    fetchExpenseData,
    expensesPage,
    loading,
    setLoading,
    properties,
    setProperties,
  ]);

  return (
    <ExpenseContext.Provider
      value={value}
    >
      {children}
    </ExpenseContext.Provider>
  );
};
