import React from 'react';
import { Circle, Container, DesktopContainer } from './style';
import { useMobile } from '../../../hooks/useMobile/useMobile';
import { useSteps } from '../context';

const StepsBall = () => {
  const { step, setStep, data } = useSteps();
  const { isDesktop } = useMobile();

  if (isDesktop) {
    return (
      <DesktopContainer>
        {data.map((item) => (
          <Circle
            key={item.step}
            active={step === item.step}
            onClick={() => setStep(item.step)}
          />
        ))}
      </DesktopContainer>
    );
  }

  return (
    <Container>
      {data.map((item) => (
        <Circle
          key={item.step}
          active={step === item.step}
          onClick={() => setStep(item.step)}
        />
      ))}
    </Container>
  );
};

export default StepsBall;
