import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  svg {
    min-width: 230px;
    width: 100%;
  }

  h1 {
    text-transform: uppercase;
    color: white;
  }
`;
