import { useState, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { getAccountSimpleHosts } from '../../../services/AccountSimpleHosts/request';

import { formatPropertiesDropdown } from '../utils';

import { useChangePropertyHost } from '../../../hooks/useChangePropertyHost/useChangePropertyHost';
import usePropertyManager from '../../../hooks/usePropertyManager';

import FormChangePropertyHost from './FormChangePropertyHost/FormChangePropertyHost';
import ModalEditChangeHost from './Modal/ModalEditChangeHost';
import ModalDeleteChangeHost from './Modal/ModalDeleteChangeHost';
import RulesMessage from './RulesMessage';
import Grid from './Grid/Grid';
import TabsNavigator from './TabsNavigator';

import {
  Container,
  Content,
  Header,
  TitleH1,
  TitleH2,
  Subtitle,
  TitleContainer,
} from './styles';

const InsertChangePropertyHostData = () => {
  const [actived, setActived] = useState<number>(0);
  const queryClient = useQueryClient();
  const { setHosts } = useChangePropertyHost();

  const {
    data: properties,
    loading: isLoadingProperties,
    refetch: refetchPropertiesData,
  } = usePropertyManager();

  const results = useMemo(() => {
    const filtereds = properties?.filter((property) => property.status === 'Active' || property.status === 'Onboarding') || [];
    return {
      simpleProperties: formatPropertiesDropdown(filtereds),
      propertiesAllInfos: filtereds,
    };
  }, [properties]);

  const getAllHosts = async () => {
    const allHosts = await getAccountSimpleHosts({
      host_active: true,
      user_active: true,
    });
    setHosts(allHosts);
  };

  useEffect(() => {
    getAllHosts();
  }, []);

  const refetchProperties = async () => {
    await queryClient.invalidateQueries('property-manager');
    refetchPropertiesData();
  };

  return (
    <Container>
      <Header>
        <TitleH1>Mudar Anfitrião</TitleH1>
      </Header>

      <Content>
        <RulesMessage />
        <FormChangePropertyHost
          isLoadingProperties={isLoadingProperties}
          refetchProperties={refetchProperties}
          results={results}
        />

        <TitleContainer>
          <TitleH2>Anfitriões em Onboarding</TitleH2>
          <Subtitle>{`Verifique os processos de troca ${actived === 0 ? 'em andamento' : 'finalizados'}`}</Subtitle>
        </TitleContainer>

        <TabsNavigator
          labels={['Em andamento', 'Histórico']}
          indexActivedLabel={actived}
          setIndexActivedLabel={setActived}
        />

        <Grid indexActivedGrid={actived} />
      </Content>

      <ModalEditChangeHost />
      <ModalDeleteChangeHost />
    </Container>
  );
};

export default InsertChangePropertyHostData;
