import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body, * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Inter, Helvetica, sans-serif;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  label,
  p,
  span,
  h1, h2, h3, h4,
  input,
  option,
  button,
  textarea {
    font-family: ${({ theme }) => theme.fonts.familys._6}, Arial, Helvetica, sans-serif;
  }

  .no-scroll {
    overflow: hidden;
  }

  .overflow-auto {
    overflow-y: auto;
    overflow-x: auto;
  }

  .MuiPickersPopper-root {
    .MuiPickersPopper-paper {
      margin-top: 10px;
      border-radius: 10px;
      box-shadow: none;
      border: solid 1px ${({ theme }) => theme.palette.grey._450.hex()};
    }
  }

  .MuiDrawer-paperAnchorLeft {
    @media (max-width: 900px) {
      right: 0 !important;
      width: 100vw;
    }
  }

  .MuiTooltip-popper {
    z-index: 8888;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: ${({ theme }) => theme.palette.orange.main.hex()} !important;
  }

  .MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background-color: rgba(255,100,100,0.35) !important;
  }

  .MuiDateRangePickerViewDesktop-root, .PrivatePickersSlideTransition-root {
    .MuiButtonBase-root, .MuiTypography-root {
      font-family: ${({ theme }) => theme.fonts.familys._1} !important;
      font-weight: bold !important;
    }
  }

  .MuiDateRangePickerViewDesktop-rangeCalendarContainer, .PrivatePickersSlideTransition-root {
    padding: 0 1rem !important;
  }

  .MuiDateRangePickerViewDesktop-calendar, .PrivatePickersSlideTransition-root {
    min-height: 220px !important;
    border-top: 1px solid ${({ theme }) => theme.palette.grey._420.hex()} !important;
  }


.my-timeline-container {
  height: 100%;
  min-width: 1440px;
}

.rct-header-root {
  z-index: 888;
  height: 60px !important;
  background-color: white !important;
  position: sticky !important;
  top: 0 !important;
}

.rct-hl-even .rct-hl-odd {
  height: 60px !important;
  background-color: white !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background-color: transparent !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background: transparent !important;
}

.react-calendar-timeline .rct-item .item-weekend {
  background-color: red !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 { 
  background-color: transparent !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-5 { 
  background-color: #F3F6F9 !important;
  z-index: -1;
 
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6 { 
  background-color: #F3F6F9 !important;
  z-index: -1;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px dashed #C9C9C9 !important;
}

.react-calendar-timeline .rct-sidebar {
  border-right: 1px solid #C9C9C9 !important;
}

.rct-items .rct-item {
  display: block !important;
  border-color: transparent !important;
}

.calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #C9C9C9 !important;
}

.react-calendar-timeline .rct-calendar-header {
  border-top: 1px solid #C9C9C9 !important;
  border-right: 1px solid #C9C9C9 !important;
  border-left: 1px solid #C9C9C9 !important;
  border-bottom: 0px solid #C9C9C9 !important;
}

.css-1d3ml05-MuiPopper-root-MuiTooltip-popper {
 z-index: 9999 !important;
}

.rmsc .dropdown-content {
  z-index: 900 !important;
}

.react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    touch-action: auto !important;
}

.dropdown-container {
  border-radius: 10px !important;
  border: 1px solid #949BA0 !important
}

.css-1s5lphu-MuiPopper-root-MuiAutocomplete-popper {
  z-index: 9999 !important;
}
`;
