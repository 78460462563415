import React from 'react';

import { MenuItem, Menu, IconButton } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import InputCheckBox from '../../InputCheckBox/InputCheckBox';
import { useEstimateCart } from '../../../context/EstimateCart';
import { AddressDetail } from '../../../services/Address/types';

import {
  ButtonAddEstimate,
  Container,
  ContentValueTotal,
  Description,
  HeaderCard,
  IconImage,
  ImageProperty,
  Price,
  PropertyTitle,
  RowInformations,
  Separator,
  Total,
} from './styles';

import propertyImage from '../../../assets/icons/generals/property-not-found.svg';
import copyImg from '../../../assets/icons/generals/copy.svg';
import mapImg from '../../../assets/icons/propertySearch/map-pin.svg';
import { MonthlyPrice } from '../../../services/MonthlyBudget/types';

const options = [
  {
    icon: copyImg,
    description: 'Copiar descrição',
  },
  {
    icon: mapImg,
    description: 'Mapa localização',
  },
];

const ITEM_HEIGHT = 48;

interface Props {
  code: string;
  address?: AddressDetail;
  guest: number;
  bedroom: string;
  category: string;
  id: number;
  intoCart: boolean;
  totalPrice: number;
  monthlyPrice: MonthlyPrice[];
  handleCheckProperty: (a: number, b: boolean) => void;
}

const CardProperty = ({
  code,
  guest,
  bedroom,
  address = {
    id: 0, city: '', complement: '', country: '', neighborhood: '', number: '', street: '', postal_code: '', state: '',
  },
  category,
  id,
  intoCart,
  totalPrice,
  monthlyPrice,
  handleCheckProperty,
}: Props): JSX.Element => {
  const { addToCart, removePropertyToCart } = useEstimateCart();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickToCheck = () => {
    if (!intoCart) {
      addToCart({
        name: category,
        properties: [{
          id, code, address, totalPrice, monthlyPrice,
        }],
      });
    } else {
      removePropertyToCart(id);
    }
    handleCheckProperty(id, intoCart);
  };

  return (
    <Container isChecked={intoCart}>
      <ImageProperty>
        <img src={propertyImage} alt="" />
      </ImageProperty>
      <HeaderCard>
        <PropertyTitle>{code}</PropertyTitle>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '22ch',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.description} selected={option.description === 'Pyxis'} onClick={handleClose}>
              <IconImage src={option.icon} alt={option.description} />
              <Description>
                {option.description}
              </Description>
            </MenuItem>
          ))}
        </Menu>
      </HeaderCard>
      <RowInformations>
        <p>
          {address.neighborhood}
          {' '}
          .
          {' '}
          {guest}
          {' '}
          hóspedes .
          {' '}
          {bedroom}
          {' '}
          quartos
        </p>
      </RowInformations>
      <Separator />
      <ContentValueTotal>
        <Total>Total</Total>
        <Price>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalPrice)}</Price>
      </ContentValueTotal>
      <ButtonAddEstimate>
        <p>Adicionar orçamento</p>
        <InputCheckBox data-cy={`btn-add-budget-${code}`} size="20px" labelName="" checked={intoCart} onChange={handleClickToCheck} />
      </ButtonAddEstimate>
    </Container>
  );
};

CardProperty.defaultProps = {
  address: {
    id: 0,
    city: '',
    complement: '',
    country: '',
    neighborhood: '',
    number: '',
    street: '',
    postal_code: '',
    state: '',
  },
};
export default CardProperty;
