import React from 'react';
import { Container } from './style';
import { usePreCheckin } from '../../../../../../context/PreCheckin/PreCheckin';

const MainGuest = () => {
  const { guests } = usePreCheckin();
  const findMainGuest = guests.main.name.length > 0 ? guests.main : false;

  if (findMainGuest) {
    return (
      <Container>
        <h1>
          Hóspede principal:
          {'   '}
          {findMainGuest.name}
        </h1>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Você ainda não finalizou o formulário para o hóspede principal</h1>
    </Container>
  );
};

export default MainGuest;
