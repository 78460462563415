import React, { useEffect, useState } from 'react';
import SimpleSelect from '../../SimpleSelect';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import CardReservation from '../CardReservation';
import iconFilter from '../../../assets/icons/multicalendar/filter.svg';

import {
  Container,
  Divider, Header, SearchButton, Title, ReservationsContent,
} from './styles';
import LateralModal from '../../LateralModal';
import { useModal } from '../../../hooks/ModalHook/useModal';
import FormReservationsFilters from '../FormReservationsFilters/FormReservationsFilters';
import Tooltip from '../../Tooltip';
import { getReservationConciliation } from '../../../services/Conciliation/request';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import CardSkeleton from '../LoadingShimmer/CardSkeleton';

const statusPayment = [
  {
    name: 'Parcialmente pago',
  },
  {
    name: 'Não Pago',
  },
];

const ContentReservations = (): JSX.Element => {
  const [openLateralModal, setOpenLateralModal] = useState(false);
  const { handleOpen } = useModal();
  const { listReservation, setListReservation } = useConciliation();

  const handleOpenlLateral = () => {
    handleOpen(false);
    setOpenLateralModal(true);
  };

  const handleCloseAll = () => {
    setOpenLateralModal(false);
  };

  useEffect(() => {
    async function listReservationsConciliation() {
      const response = await getReservationConciliation();
      setListReservation(response);
    }
    listReservationsConciliation();
  }, []);

  return (
    <Container>
      <Header>
        <Title>Reservas</Title>
        <div className="filters">
          <Tooltip text="Mais filtros">
            <SearchButton type="button" onClick={handleOpenlLateral}><img src={iconFilter} alt="" /></SearchButton>
          </Tooltip>
          <SimpleSelect
            id="status"
            options={(statusPayment || []).map<SelectOption>(({ name }) => ({
              value: name,
              valueLabel: name,
            }))}
            placeholder="Status pagamento"
          />
        </div>
      </Header>
      <ReservationsContent>
        {listReservation.length > 0 ? (
          listReservation.map((reservation) => (
            <>
              <CardReservation reservation={reservation} />
              <Divider />
            </>
          ))
        ) : (<CardSkeleton />)}
      </ReservationsContent>
      <LateralModal
        open={openLateralModal}
        setOpen={setOpenLateralModal}
        onClose={handleCloseAll}
        headerTitle="Filtros"
        anchor="left"
      >
        <FormReservationsFilters conciliation onClose={handleCloseAll} />
      </LateralModal>
    </Container>
  );
};

export default ContentReservations;
