import request from '../../../request';
import { GetPartnersBankDetailsBase, PostPartnersBankDetailsBase, PostPartnersBankDetailsResponseBase } from './types';

type GetPartnersBankDetails = (id?: number) => Promise<GetPartnersBankDetailsBase[]>;
export const getPartnersBankDetails: GetPartnersBankDetails = async (id) => {
  const validateId = id || '';

  const { data } = await request.get(`/financial/partner/bank_details/${validateId}`);
  return data;
};

type OptionalPostPartnersBankDetails = Partial<PostPartnersBankDetailsBase>;
type PostPartnersBankDetails = (data: OptionalPostPartnersBankDetails) =>
Promise<PostPartnersBankDetailsResponseBase>;
export const postPartnersBankDetails: PostPartnersBankDetails = async (data) => {
  const response = await request.post('/financial/partner/bank_details/', {
    ...data,
  });
  return response.data;
};

type PathPartnersBankDetails = (id: number) =>
Promise<PostPartnersBankDetailsBase>;
export const pathPartnersBankDetails: PathPartnersBankDetails = async (id) => {
  const { data } = await request.patch(`/financial/partner/bank_details/${id}`);
  return data;
};
