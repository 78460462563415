import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Button = styled(motion.button)<{
  variant?: 'outline';
}>`
  margin: 0;
  width: 100%;
  color: white;
  padding: 10px;
  cursor: pointer;
  background: #0d4bd0;
  font-weight: medium;
  /* border-radius: 10px; */
  border: 2px solid #ccc;

  ${({ variant }) => variant === 'outline'
    && css`
      border: 2px solid #0d4bd0;
      background: #fff;
      color: #0d4bd0;
    `}

  &:disabled {
    background: #c2c8f1;
  }
`;

export const MobileContainer = styled(motion.div)`
  bottom: -1px;
  left: -1px;
  width: 101%;
  position: fixed;
  transform: translateX(-50%);
`;
