import { useNavigate } from 'react-router-dom';
import FormButton from '../../FormButton';
import {
  ButtonContainer,
  Close,
  PendencyIcon,
  PendencyModalContainer,
  PendencyModalHeader,
  PendencyModalOverlay,
  PendencyModalText,
} from './styles';

interface PendencyModalProps {
  handleClosePendencyModal: () => void;
}

export function PendencyModal({ handleClosePendencyModal }: PendencyModalProps) {
  const navigate = useNavigate();
  const handleRedirectInSapron = (path: string) => {
    navigate(path);
  };
  return (
    <PendencyModalOverlay onClick={handleClosePendencyModal}>
      <PendencyModalContainer onClick={(e) => e.stopPropagation()}>
        <PendencyModalHeader>
          <PendencyIcon />
          <Close onClick={handleClosePendencyModal} />
        </PendencyModalHeader>
        <PendencyModalText>
          Existem evidências ou notas fiscais/orçamentos pendentes no processo de Danos de hóspede.
        </PendencyModalText>
        <ButtonContainer>
          <FormButton
            onClick={() => handleRedirectInSapron('/danosdehospede/pendency')}
          >
            Visualizar pendências

          </FormButton>
        </ButtonContainer>
      </PendencyModalContainer>
    </PendencyModalOverlay>
  );
}
