import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UseFormik } from '../../../../../utils/Formik/types';
import { IFormValues } from './types';
import { EnumType } from '../../../types';

import {
  initialText,
  ITexts,
  validateText,
} from './utils';

import ModalFiles from './Content/lib/Modal/ModalFiles/ModalFiles';
import AllotmentIcon from './Content/LandIndicationForm/icon/AllotmentIcon';
import InvestmentIcon from './Content/SpotIndicationForm/icon/InvestmentIcon';
import WelcomeMessage from './Content/WelcomeMessage';

import { usePartnerIndicate } from '../../../../../context/Partners/IndicateContext/IndicateContext';
import { useUser } from '../../../../../context/UserContext';

import {
  Container,
  TitleContainer,
  Title,
  Header,
  Subtitle,
} from './Content/style';

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 10 },
};

interface IFormTemplate {
  children: React.ReactNode;
  formik: UseFormik<IFormValues>;
}

const FormTemplate = ({ children, formik }: IFormTemplate) => {
  const { type } = useParams();
  const [texts, setTexts] = React.useState<ITexts>(initialText);
  const { openModalWithFiles } = usePartnerIndicate();
  const { userInformation } = useUser();
  const partnerActions: string[] = userInformation?.required_actions?.Partner || [];

  useEffect(() => {
    const validate = validateText(type);
    setTexts(validate);
  }, []);

  return (
    <>
      {type === EnumType.location && partnerActions.includes('PARTNER_INDICATION_POPUP') && (
        <WelcomeMessage />
      )}

      <ModalFiles open={openModalWithFiles.is} />

      <Container
        translate="yes"
        variants={list}
        animate="visible"
        initial="hidden"
        onSubmit={formik.handleSubmit}
      >
        <Header variants={item}>
          <TitleContainer>
            {EnumType.terrain === type && <AllotmentIcon />}
            {EnumType.spot === type && <InvestmentIcon />}
            <Title>{texts.headerTitle}</Title>
          </TitleContainer>
          <Subtitle>{texts.headerDescription}</Subtitle>
        </Header>
        {children}
      </Container>
    </>
  );
};

export default FormTemplate;
