import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;

  /* height: 100%; */
  height: 80vh;
`;

export const Title = styled(motion.h1)`
  font-weight: medium;
  font-size: 2rem;
  color: #394760;
`;
