import React, { useMemo } from 'react';
import SimpleSelect, { ISelectProps, SelectOption } from './SimpleSelect';
import { useIBGE } from '../../hooks/useIBGE/useIBGE';
import { compareList } from '../../utils/Sorting';

type Props = Omit<ISelectProps, 'options' | 'label'> & {
  label?: string;
  labelClassName?: string;
};

const StateSelect: React.FC<Props> = ({
  id,
  label = 'UF',
  placeholder = 'Selecione',
  disabled = false,
  formik,
  required,
  disableRequireSymbol = false,
  labelClassName,
}) => {
  const { states } = useIBGE({});
  const allStates = useMemo(() => states?.data || [], [states]);

  const isStateAbbreviation = (state: string) => allStates.some(({ sigla }) => `${sigla}`.toLowerCase() === state.toLowerCase());

  const selectOptions = useMemo(() => {
    const allOptions = allStates.map<SelectOption>(({ nome, sigla }) => ({
      valueLabel: `${sigla}`.toUpperCase(),
      value: nome,
    }));

    const state = `${formik?.values?.state}`;
    if (isStateAbbreviation(state)) {
      return allStates.map<SelectOption>(({ sigla }) => ({
        valueLabel: `${sigla}`.toUpperCase(),
        value: `${sigla}`.toUpperCase(),
      }));
    }

    return allOptions;
  }, [allStates, formik?.values?.state]);

  return (
    <SimpleSelect
      label={label}
      id={id}
      placeholder={placeholder}
      options={selectOptions.sort((a, b) => compareList(`${a.value}`, `${b.value}`))}
      formik={formik}
      disabled={disabled}
      required={required}
      disableRequireSymbol={disableRequireSymbol}
      labelClassName={labelClassName}
    />
  );
};

export default StateSelect;
