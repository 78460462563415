import styled, { css } from 'styled-components';

export const Container = styled.div<{ open: boolean }>`
  ${({ open }) => (open
    ? css`
      transition: all ease 0.5s;
      transform: translateX(-100px);
      `
    : css`
      transition: all ease 0.5s;
      transform: translateX(20rem);
  `)}
`;
