import React, {
  FC, useMemo, useState, ReactElement, useRef,
} from 'react';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { createContext, useContextSelector } from 'use-context-selector';

import { getPreCheckin, getPreCheckinBedOrganization } from '../../services/PreCheckin';
import {
  IGuestFront, IGuests, IPreCheckinBedOrganization, IPreCheckinInfo,
} from '../../services/PreCheckin/types';
import { useToast } from '../ToastContext';
import { guestInitialValues } from '../../pages/PreCheckin/utils';
import { localStoragePreCheckin } from '../../pages/PreCheckin/utils/localStorageUrl';

const INITIAL_LOADING_TIME = 3400;

const splitTokenFromUrl = (url: string | undefined) => {
  // faz o filtro na url para pegar apenas o token
  const TOKEN_PREFIXES = ['https://', 'token=', 'sapron', '.com', '.br', '/'];

  if (url) {
    const matchingPrefix = TOKEN_PREFIXES.find((prefix) => url?.includes(prefix));
    if (matchingPrefix) {
      const token = url?.split(`${matchingPrefix}`)[1];
      return token;
    }

    return url;
  }

  return '';
};

type ISetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface ILoading {
  aux: boolean;
  main: boolean;
  message: string | undefined;
}

interface IHasIsPrincipal {
  is: boolean;
  guest?: IGuestFront;
}

type ModeVariants = 'view' | 'edit';

type Mode = {
  is: ModeVariants;
  _default: ModeVariants;
};

type Modal = {
  is: boolean;
  scroll?: 'off';
  render: JSX.Element;
};

interface IPreCheckinContext {
  token: string | undefined;

  mode: Mode;
  setMode: (mode: ModeVariants) => void;

  bedOrganization: IPreCheckinBedOrganization[] | undefined;

  showFinalPage: boolean;
  setShowFinalPage: React.Dispatch<React.SetStateAction<boolean>>;

  guests: IGuests;
  setGuests: React.Dispatch<React.SetStateAction<IGuests>>;

  loading: ILoading;
  setLoading: ISetState<ILoading>;

  modal: Modal;
  setModal: React.Dispatch<React.SetStateAction<Modal>>

  hasIsPrincipal: IHasIsPrincipal;
  setHasIsPrincipal: React.Dispatch<React.SetStateAction<IHasIsPrincipal>>;

  info: IPreCheckinInfo;
  setInfo: React.Dispatch<React.SetStateAction<IPreCheckinInfo>>;

  handleLoading: Function;
  handleStopLoading: Function;

  error: unknown;
}

export const PreCheckinContext = createContext<IPreCheckinContext>({} as IPreCheckinContext);

export const PreCheckinContextProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { token } = useParams();

  // Pega qual o modo dos parametros na url
  // Podem ser de edição ou visualização
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const initialModeValue: ModeVariants = React.useMemo(() => {
    const value = searchParams.get('mode');
    if (value) {
      return value as ModeVariants;
    }

    return 'edit';
  }, [searchParams]);

  const toast = useToast();
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  const timmer = useRef<NodeJS.Timeout>({} as NodeJS.Timeout);

  const [modal, setModal] = useState<Modal>({} as Modal);
  const [error, setError] = useState<unknown>();
  const [mode, setMode] = useState<Mode>({ _default: initialModeValue, is: initialModeValue });
  const [loading, setLoading] = useState<ILoading>({} as ILoading);
  const [guests, setGuests] = useState<IGuests>({
    list: [] as IGuestFront[],
    main: guestInitialValues,
  });

  const [showFinalPage, setShowFinalPage] = useState<boolean>(false);
  const [info, setInfo] = useState<IPreCheckinInfo>({} as IPreCheckinInfo);
  const [hasIsPrincipal, setHasIsPrincipal] = useState<IHasIsPrincipal>({} as IHasIsPrincipal);

  const [
    bedOrganization,
    setBedOrganization,
  ] = useState<IPreCheckinBedOrganization[] | undefined>(undefined);

  const handleChageMode = (newMode: ModeVariants) => {
    setMode((prev) => ({ ...prev, is: newMode }));
  };

  const handleLoading = () => {
    setLoading((prev) => ({ ...prev, main: true, aux: true }));
  };

  const handleMessage = (message: string) => {
    setLoading((prevState) => ({ ...prevState, message }));
  };

  const handleStopLoading = () => {
    timmer.current = setTimeout(() => {
      setLoading((prev) => ({
        ...prev,
        aux: false,
        main: true,
        message: undefined,
      }));
      clearTimeout(timmer.current);
    }, INITIAL_LOADING_TIME);
  };

  React.useEffect(() => {
    const handleGetBedOrganization = async (property: number) => {
      try {
        handleMessage('Buscando organização das camas');
        const response = await getPreCheckinBedOrganization({
          property,
          token: splitTokenFromUrl(token),
        });
        setBedOrganization(response);
      } catch {
        toast.error('Ocorreu um erro ao buscar a organização das camas');
      }
    };

    const handleGetInfoData = async () => {
      if (token) {
        localStoragePreCheckin({
          action: 'set',
          value: window.location.href,
        });

        handleMessage('Buscando dados do pré-checkin');
        try {
          // busca os dados do pré-checkin
          const response = await getPreCheckin({ token: splitTokenFromUrl(token) });
          setInfo(response);

          // remove de dentro do array de resposta o hóspede principal
          // seta ele dentro do objeto main em hóspedes
          // os outros são setados dentro do objeto list
          const mainGuest = response.guests.find((guest) => guest.is_principal);
          const guestsListWithoutMainGuest = response.guests.filter((guest) => !guest.is_principal);

          const isPutBase = {
            is: true,
            updated: {
              bedOrganization: false,
              documentPhoto: {
                front: false,
                back: false,
              },
            },
          };

          const newGuests = guestsListWithoutMainGuest.map((guest) => ({
            ...guest,
            isPut: isPutBase,
          }));

          const newGuestMain = () => {
            if (mainGuest) {
              return {
                ...mainGuest,
                isPut: isPutBase,
              };
            }

            return guestInitialValues;
          };

          if (mainGuest) {
            setHasIsPrincipal((old) => ({ ...old, is: true, guest: newGuestMain() }));
          }

          setGuests((old) => ({
            ...old,
            list: newGuests,
            main: newGuestMain(),
          }));

          await handleGetBedOrganization(response.property.location.id);
        } catch (e) {
          setError(e);
          navigate('pagina-nao-encontrada');
        }
      }
    };

    const mainQuery = async () => {
      try {
        handleLoading();
        if (token) {
          await handleGetInfoData();
          handleStopLoading();
        } else {
          navigate('token-nao-encontrado');
          handleStopLoading();
        }
      } catch {
        toast.error('Ocorreu um erro ao buscar os dados do pré-checkin');
      }
    };

    if (isFirstRender.current) {
      mainQuery();
      isFirstRender.current = false;
    }

    return () => {
      clearTimeout(timmer.current);
    };
  }, []);

  const value = useMemo(() => ({
    token: splitTokenFromUrl(token),
    showFinalPage,
    setShowFinalPage,
    guests,
    setGuests,
    loading,
    setLoading,
    handleLoading,
    handleStopLoading,
    info,
    setInfo,
    bedOrganization,
    hasIsPrincipal,
    setHasIsPrincipal,
    mode,
    setMode: handleChageMode,
    modal,
    setModal,
    error,
  }),
  [
    token,
    showFinalPage,
    setShowFinalPage,
    guests,
    setGuests,
    loading,
    setLoading,
    handleLoading,
    handleStopLoading,
    info,
    setInfo,
    bedOrganization,
    hasIsPrincipal,
    setHasIsPrincipal,
    setMode,
    handleChageMode,
    modal,
    setModal,
    error,
  ]);

  return (
    <PreCheckinContext.Provider value={value}>
      {children}
    </PreCheckinContext.Provider>
  );
};

export const usePreCheckin = () => {
  const token = useContextSelector(PreCheckinContext, (state) => state.token);

  const mode = useContextSelector(PreCheckinContext, (state) => state.mode);
  const setMode = useContextSelector(PreCheckinContext, (state) => state.setMode);

  const modal = useContextSelector(PreCheckinContext, (state) => state.modal);
  const setModal = useContextSelector(PreCheckinContext, (state) => state.setModal);

  const bedOrganization = useContextSelector(PreCheckinContext, (state) => state.bedOrganization);

  const loading = useContextSelector(PreCheckinContext,
    (state) => state.loading);
  const setLoading = useContextSelector(PreCheckinContext,
    (state) => state.setLoading);
  const handleLoading = useContextSelector(PreCheckinContext,
    (state) => state.handleLoading);
  const handleStopLoading = useContextSelector(PreCheckinContext,
    (state) => state.handleLoading);

  const showFinalPage = useContextSelector(PreCheckinContext,
    (state) => state.showFinalPage);
  const setShowFinalPage = useContextSelector(PreCheckinContext,
    (state) => state.setShowFinalPage);

  const guests = useContextSelector(PreCheckinContext, (state) => state.guests);
  const setGuests = useContextSelector(PreCheckinContext,
    (state) => state.setGuests);

  const info = useContextSelector(PreCheckinContext, (state) => state.info);
  const setInfo = useContextSelector(PreCheckinContext,
    (state) => state.setInfo);

  const hasIsPrincipal = useContextSelector(PreCheckinContext, (state) => state.hasIsPrincipal);
  const setHasIsPrincipal = useContextSelector(PreCheckinContext,
    (state) => state.setHasIsPrincipal);

  const error = useContextSelector(PreCheckinContext, (state) => state.error);

  return {
    token,
    mode,
    setMode,
    showFinalPage,
    setShowFinalPage,
    guests,
    setGuests,
    loading,
    setLoading,
    handleLoading,
    handleStopLoading,
    info,
    setInfo,
    bedOrganization,
    hasIsPrincipal,
    setHasIsPrincipal,
    modal,
    setModal,
    error,
  };
};
