import React from 'react';
import { Container } from './style';

import {
  Seazone,
  SeazoneTip,
  CriteriaIndication,
  HowIndicateProperties,
  EarningMoneyIndicating,
  WhichRegionsCanIndicate,
  WhatTypesReferralsCanYouMake,
} from './content';

const Footer = () => (
  <Container>
    <WhatTypesReferralsCanYouMake />
    <CriteriaIndication />
    <SeazoneTip />
    <EarningMoneyIndicating />
    <WhichRegionsCanIndicate />
    <HowIndicateProperties />
    <Seazone />
  </Container>
);

export default Footer;
