export const DEFAULT_WIDTH_TO_DESKTOP = 1000;
export const ACTUAL_WINDOW_WIDTH = window.innerWidth;

export type ActualSize = 'Desktop' | 'Mobile';
export const actualSize: ActualSize = ACTUAL_WINDOW_WIDTH > DEFAULT_WIDTH_TO_DESKTOP ? 'Desktop' : 'Mobile';

export function useMobile() {
  const isMobile = ACTUAL_WINDOW_WIDTH <= DEFAULT_WIDTH_TO_DESKTOP;
  const isDesktop = ACTUAL_WINDOW_WIDTH > DEFAULT_WIDTH_TO_DESKTOP;

  return {
    isMobile,
    isDesktop,
    actualSize,
    ACTUAL_WINDOW_WIDTH,
  };
}
