import React, { Fragment } from 'react';

import { AnimatePresence } from 'framer-motion';
import { usePartnersPortfolio } from '../../../../../../../../hooks/usePartners';

import {
  Content,
  Backdrop,
  Container,
  ItemContainer,
  IconContainer,
  TextContainer,
  Separator,
} from './style';
import { ArrowSVGIcon, BookSVGIcon, GridSVGIcon } from './Icons';

const Item = ({ text, icon }: { text: string, icon: JSX.Element }) => (
  <ItemContainer
    whileTap={{
      x: 10,
      opacity: 1,
    }}
  >
    <IconContainer>
      {icon}
    </IconContainer>
    <TextContainer>
      <h1>{text}</h1>
    </TextContainer>
  </ItemContainer>
);

const Modal = () => {
  const { modal, handleCloseModal } = usePartnersPortfolio();
  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current
        && !modalRef.current.contains(event.target as Node)
      ) {
        handleCloseModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <AnimatePresence>
      {modal.open && (
        <Fragment key="modal_partners_portfolio">
          <Container
            ref={modalRef}
            exit={{ scale: 0 }}
            offsetTop={modal.coors.y}
            offsetLeft={modal.coors.x}
            style={{ originY: 0, originX: 1 }}
            animate={{ scale: 1, opacity: 1, x: 0 }}
            initial={{ scale: 0, x: 10, opacity: 0 }}
          >
            <Content>
              <Item icon={<BookSVGIcon />} text="Material de venda" />
              <Separator />
              <Item icon={<GridSVGIcon />} text="Espelho de venda" />
              <Separator />
              <Item icon={<ArrowSVGIcon />} text="Compartilhar" />
            </Content>
          </Container>
          <Backdrop
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
          />
        </Fragment>
      )}
    </AnimatePresence>
  );
};
export default Modal;
