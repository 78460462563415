import {
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';

import BackButton from './BackButton/BackButton';
import { ProfileLogout } from '../../Header/Header';
import ReportButton from './ReportButton/ReportButton';
import RequestBlockProperty from './RequestBlockProperty';
import NewInvestButton from './NewInvestButton/NewInvestButton';

import {
  Container,
  Content,
  ContainerLogo,
  Left,
  Right,
  Mid,
  UserManualLink,
} from './styles';

import { AnimatedLogo } from '../../../assets/animations/components/AnimatedLogo';

interface HeaderProps {
  showBackButton?: boolean;
}

const LogoSeazone = () => {
  const navigate = useNavigate();
  const { isMobile } = useScreenResize();

  return (
    <ContainerLogo
      data-cy="img-logo-seazone-header"
      onClick={() => navigate('/proprietario')}
    >
      <AnimatedLogo useHouseOnly={isMobile} />
    </ContainerLogo>
  );
};

const Header = ({ showBackButton }: HeaderProps) => {
  const { isMobile } = useScreenResize();

  const location = useLocation();

  return (
    <>
      <Container>
        <Content>
          <Left>
            <LogoSeazone />
          </Left>

          <Mid>
            {showBackButton && (
              <BackButton linkTo="/proprietario" dataCy="btn-back" />
            )}
          </Mid>

          <Right>
            {location.pathname === '/proprietario' && (
            <UserManualLink>
              <a
                href="https://thread-wave-20a.notion.site/Manual-de-Usu-rio-Propriet-rios-ef5ee1f7de63439a950ffbeacd5b71f0"
                target="_blank"
              >
                Manual do usuário
              </a>
            </UserManualLink>
            )}

            {!isMobile && !['/proprietario', '/meusdados'].includes(`${location.pathname}`) && <RequestBlockProperty className="mobile-width" />}
            <NewInvestButton className="mobile-width" />
            <ReportButton />

            <ProfileLogout useNewDesign color="white" useOnlyArrow={false} />
          </Right>
        </Content>
      </Container>
    </>
  );
};

Header.defaultProps = {
  showBackButton: false,
};

export default Header;
