import { Divider } from '@mui/material';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';

import {
  Money, Title, Content, Section, Container,
} from './style';

const Card = () => {
  const { resume } = usePartners();

  const formatedMoney = {
    balance: numberToCurrency(resume?.total_balance.balance) || 'R$ 0,00',
    withdraws: numberToCurrency(resume?.total_balance.withdraw_received) || 'R$ 0,00',
    comissions: numberToCurrency(resume?.total_balance.commission_received) || 'R$ 0,00',
  };

  return (
    <Container
      initial={{
        y: 20,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
    >
      <Content>
        <Section>
          <Title>Ganhos totais</Title>
          <Money>{formatedMoney.comissions}</Money>
        </Section>
        <Divider />
        <Section>
          <Title>Resgates Totais</Title>
          <Money>{formatedMoney.withdraws}</Money>
        </Section>
        <Divider />
        <Section>
          <Title>Saldo Disponível</Title>
          <Money
            style={{
              color: '#0d4bd0',
              fontWeight: 'bold',
            }}
          >
            {formatedMoney.balance}
          </Money>
        </Section>
      </Content>
    </Container>
  );
};
export default Card;
