import styled from 'styled-components';

export const Container = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
`;
export const Circle = styled.div<{
  active: boolean;
}>`
  height: 20px;
  width: 20px;
  transition: 0.2s;
  border-radius: 100px;
  background-color: ${({ active }) => (active ? '#0D4BD0' : '#D9D9D9')};
`;

export const DesktopContainer = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;

  position: absolute;
  bottom: 10px;
`;
