import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 580px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const ContentNewPassword = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 561px;
  height: 350px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  padding-left: 92px;
  padding-right: 92px;

  h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    margin: 0 auto;
    color: ${({ theme }) => theme.palette.grey._900.hex()};
    margin-bottom: 25px;
  }

  @media (max-width: 580px) {
    width: 100%;
    height: 350px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  p, span {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.palette.grey._900.hex()};
  }

  span {
    margin-left: 5px;
    color: ${({ theme }) => theme.palette.orange.main.hex()}
  }
`;

export const InputNewPassword = styled.div`
  width: 100%;
  margin-bottom: 17px;
`;

export const ButtonCreateNewPassword = styled.button`
  width: 100%;
  height: 40px;
  background: linear-gradient(360deg, ${({ theme }) => theme.palette.blue._850.hex()} 0%, ${({ theme }) => theme.palette.blue._200.hex()} 100%);
  border-radius: 10px;
  border: none;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  box-shadow: 1px 9px 37px -14px ${({ theme }) => theme.palette.blue._850.hex()};
  -webkit-box-shadow: 1px 9px 37px -14px ${({ theme }) => theme.palette.blue._850.hex()} ;
  -moz-box-shadow: 1px 9px 37px -14px ${({ theme }) => theme.palette.blue._850.hex()} ;

  &:hover {
    filter: brightness(0.90);
  }
`;
