import React from 'react';
import { Container } from './style';
import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="#E7E9FA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CloseButton = () => {
  const { setConversion, conversion } = usePartners();

  const handleChange = () => {
    setConversion((old) => ({
      ...old,
      isMaxBar: !old.isMaxBar || false,
    }));
  };

  return (
    <Container isMaxBar={conversion.isMaxBar} type="button" whileTap={{ scale: 0.9 }} onClick={handleChange}>
      <Icon />
    </Container>
  );
};

export default CloseButton;
