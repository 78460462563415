import React from 'react';
import { ICard } from '../types';
import { Arrow } from '../icons';
import { motionVariation } from '../utils';
import { analyticsEvent } from '../../../../../../../../utils/analytics';
import { useUser } from '../../../../../../../../../../context/UserContext';

// @ts-ignore
import pdf from '../../data/pdf/ebook-rentabilize.pdf';

import { Text } from '../style';

interface IAccessButton {
  title: ICard['title'];
  access: ICard['access'];
}

const AccessButton = ({ title, access }: IAccessButton) => {
  const { userInformation } = useUser();

  const handleClick = () => {
    analyticsEvent({
      name: `Clicou no card: ${title} - na sessão: CMS`,
      userInformation,
    });

    if (title === 'E-book') {
      window.open(pdf, '_blank');
    } else {
      window.open(access, '_blank');
    }
  };

  return (
    <Text onClick={handleClick} {...motionVariation}>
      <h1>Acessar</h1>
      <Arrow />
    </Text>
  );
};

export default AccessButton;
