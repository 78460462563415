import React, { FC, useEffect } from 'react';

import {
  StepIconProps,
} from '@mui/material/StepIcon';

import {
  Stack,
  Step,
  Stepper,
  StepLabel,
  Typography,
} from '@mui/material';

import clsx from 'clsx';

import {
  QontoConnector,
  QontoStepIconRoot,
  TitleContainer,
  CheckIcon,
} from './styles';

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={clsx(className)}>
      {completed ? (
        <CheckIcon className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

interface ICustomStepper {
  progress: number;
  stepperOptions?: string[];
}

const CustomizedSteppers: FC<ICustomStepper> = ({
  progress,
  stepperOptions = [
    'Estamos processando seu pedido',
    'Preparando CSV',
    'CSV Baixado',
  ],
}) => {
  const [activeStep, setActiveStep] = React.useState(1);

  const handleNextByProgress = () => {
    if (progress === 0) {
      setActiveStep(1);
    }
    if (progress === 50) {
      setActiveStep(2);
    }
    if (progress === 100) {
      setActiveStep(3);
    }
  };

  useEffect(() => {
    handleNextByProgress();
  }, [progress]);

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <TitleContainer>
        <Typography>Aguarde enquanto fazemos o download...</Typography>
      </TitleContainer>
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {stepperOptions.map((label) => (
          <Step key={label}>
            <StepLabel
              data-cy={`${label}`}
              StepIconComponent={QontoStepIcon}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CustomizedSteppers;
