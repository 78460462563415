import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useToast } from '../../../../context/ToastContext';
import { postUploadCSVPaymments } from '../../../../services/Conciliation/request';
import { useToastErrorMessage } from '../../../../utils/Messages';
import ModalTemplate from './ModalTemplate';
import {
  Container, ButtonCancel, ButtonSave, ContentButtons, Title,
} from './styles';

interface Props {
  onClose: Function;
  open: boolean;
}

const ModalUploadCSV = ({
  open, onClose,
}: Props) => {
  const [file, setFile] = useState<File | null>();
  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
    },
    onSubmit: async () => {
      try {
        if (file) {
          await postUploadCSVPaymments(file);
          toast.success('Upload realizado com sucesso!');
          onClose(false);
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <ModalTemplate
      open={open}
      handleClose={onClose}
    >
      <Container onSubmit={formik.handleSubmit}>
        <Title>Faça upload do CSV</Title>
        <div className="button-upload">
          <input type="file" name="file" className="custom-file-input" placeholder="Upload CSV" onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)} />
        </div>

        <ContentButtons>
          <ButtonCancel type="button" onClick={() => onClose(false)}>Cancelar</ButtonCancel>
          <ButtonSave type="submit">Salvar</ButtonSave>
        </ContentButtons>
      </Container>
    </ModalTemplate>
  );
};

export default ModalUploadCSV;
