import {
  useMemo,
  useState,
  useEffect,
} from 'react';

import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';

import { getPropertyChangeHost, postPropertyChangeHost } from '../../../../services/InsertData/request';
import { PostPropertyChangeHostObject } from '../../../../services/InsertData/types';
import { IPropertyManagerAPI } from '../../../../services/Property/Manager/types';
import { requestErrorMessageTranslated } from '../../utils';

import { useChangePropertyHost } from '../../../../hooks/useChangePropertyHost/useChangePropertyHost';
import { useToast } from '../../../../context/ToastContext';

import FormButton from '../../../FormButton';
import TextField from '../../../TextField/TextField';
import DatePicker from '../../../DatePicker';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import { SelectProps } from '../../../DropdownAutocomplete/DropdownAutocomplete';

import {
  Form,
  FormContent,
  ButtonContainer,
  DatePickerContainer,
  DividerContainer,
  DividerContent,
} from './styles';
import { compareDates } from '../../../../utils/Sorting';
import { useLoader } from '../../../../context/LoaderContext';

interface ChangeHostPropertyFormikValuesType {
  property?: number;
  newHostId?: number;
  oldHostId?: number;
  oldHostName?: string;
  newHostName?: string;
  replacementDate?: string | null;
}

interface IFormChangePropertyHost {
  isLoadingProperties: boolean,
  results: {
    simpleProperties: Array<SelectProps>,
    propertiesAllInfos: IPropertyManagerAPI[],
  },
  refetchProperties: Function,
}

const FormChangePropertyHost = ({
  isLoadingProperties,
  results,
  refetchProperties,
}: IFormChangePropertyHost) => {
  const toast = useToast();
  const { setLoad } = useLoader();
  const {
    hosts,
    exchangedHosts,
    hostsListSorted,
    setExchangedHosts,
    setOpenModalConfirmExchangeHost,
  } = useChangePropertyHost();

  const [disabled, setDisabled] = useState<boolean>(true);

  const formikInitialValues: ChangeHostPropertyFormikValuesType = {
    oldHostName: '',
    newHostName: '',
    property: undefined,
    oldHostId: undefined,
    newHostId: undefined,
    replacementDate: null,
  };

  const validation = Yup.object().shape({
    property: Yup.number(),
    oldHostId: Yup.number(),
    newHostId: Yup.number(),
    replacementDate: Yup.date().nullable(),
  });

  const handleGetExchangedHosts = async () => {
    const response = await getPropertyChangeHost();
    const sorted = response.map((item) => item).sort((a, b) => compareDates(
      a.created_at, b.created_at, 'desc',
    ));
    setExchangedHosts([...sorted]);
  };

  const formik = useFormik<ChangeHostPropertyFormikValuesType>({
    initialValues: formikInitialValues,
    validationSchema: validation,
    onSubmit: async (values, { resetForm, setValues }) => {
      try {
        const payload: PostPropertyChangeHostObject = {
          old_host: Number(values.oldHostId),
          new_host: Number(values.newHostId),
          property: Number(values.property),
          replacement_date: moment(values.replacementDate).format('YYYY-MM-DD'),
        };

        setLoad(true);

        await postPropertyChangeHost(payload);

        await handleGetExchangedHosts();
        refetchProperties();
        toast.success('Mudança solicitada');

        setValues({
          ...formikInitialValues,
        });
        setLoad(false);
        resetForm();
      } catch (e: unknown) {
        if (e instanceof Error) {
          setLoad(false);
          const msg = requestErrorMessageTranslated(e.message);
          toast.error(msg);
        }
      }
    },
  });

  const oldHostSelected = useMemo(() => {
    let hostName = '';

    if (formik.values?.property) {
      const filteredProperty = results.propertiesAllInfos.filter((property) => `${property.id}` === `${formik.values?.property}`);
      const hostId = filteredProperty?.[0]?.host || undefined;
      const filteredHost = hosts.filter((host) => `${host.id}` === `${hostId}`);
      hostName = `${filteredHost?.[0]?.full_name || ''}`;

      formik?.setFieldValue('oldHostId', hostId);
      formik?.setFieldValue('oldHostName', hostName);
    }

    return hostName;
  }, [formik.values?.property]);

  useEffect(() => {
    if (formik.values?.newHostId) {
      const filteredHost = hosts.filter((host) => `${host.id}` === `${formik.values?.newHostId}`);
      const hostname = filteredHost?.[0]?.full_name || '';
      formik?.setFieldValue('newHostName', hostname);
    }
  }, [formik.values?.newHostId]);

  const isNullOrUndefined = (value: string) => ['null', 'undefined'].includes(`${value}`);

  useEffect(() => {
    const disable = isNullOrUndefined(`${formik.values?.property}`) || isNullOrUndefined(`${formik.values?.newHostId}`) || isNullOrUndefined(`${formik.values?.replacementDate}`);
    setDisabled(disable);
  }, [formik.values?.property, formik.values?.newHostId, formik.values?.replacementDate]);

  const Divider = () => (
    <DividerContainer>
      <DividerContent />
    </DividerContainer>
  );

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormContent>
        <DropdownAutocomplete
          id="property"
          label="Imóvel"
          formik={formik}
          placeholder="Pesquisar"
          loading={isLoadingProperties}
          labelClassName="inputLabel"
          options={results.simpleProperties}
          resetInput={`${formik.values?.property}` === 'undefined'}
        />
        <TextField
          id="oldHostId"
          label="Anfitrião Atual"
          formik={formik}
          value={oldHostSelected}
          labelClassName="inputLabel"
          disabled
        />
        <DropdownAutocomplete
          id="newHostId"
          formik={formik}
          label="Anfitrião Novo"
          placeholder="Pesquisar"
          options={hostsListSorted}
          labelClassName="inputLabel"
          resetInput={`${formik.values?.newHostId}` === 'undefined'}
        />
        <DatePickerContainer>
          <DatePicker
            id="replacementDate"
            dataCy="datepicker-replacement-date"
            formik={formik}
            label="Data de Entrega"
            labelClassName="inputLabel"
            viewsCustom={['day']}
            hasInitialDates
            disableInput
            disablePast
            showDaysOutsideCurrentMonth
          />
        </DatePickerContainer>

        <ButtonContainer>
          <FormButton type="submit" disable={disabled} onClick={() => setOpenModalConfirmExchangeHost(true)}>Nova mudança</FormButton>
        </ButtonContainer>
      </FormContent>
      <Divider />
    </Form>
  );
};

export default FormChangePropertyHost;
