import React from 'react';
import { FormControl, RadioGroup } from '@mui/material';

import { UseFormik } from '../../../utils/Formik/types';
import { Type } from '../../HelperText/types';

import HelperText from '../../HelperText/HelperText';
import QuantityField from '../../QuantityField';
import SimpleSelect from '../../SimpleSelect';

import { ErrorIcon } from '../../TextField/styles';

import {
  FormLabelStylized,
  FormControlLabelStylized,
  RadioStylized,
  Container,
  CardContainer,
  SimpleSelectContainer,
} from './styles';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';

export type Value = string | boolean | number;
export type Layout = '1' | '2' | '3';

export interface RadioButtonOptions {
  value: Value;
  label: string;
  layout: Layout;
}

export interface IRadioButtonProps {
  formLabel: string,
  id: string,
  labelPlacement: 'end' | 'start' | 'top' | 'bottom',
  options: RadioButtonOptions[],
  formik?: UseFormik<any>,
  helperText?: string,
  onClick?: () => void,
  dataCy?: string,
}

const OnboardingPaymentMethod: React.FC<IRadioButtonProps> = ({
  formLabel,
  labelPlacement,
  options,
  id,
  formik,
  helperText,
  onClick,
  dataCy = '',
}) => {
  const handleChange = (selected: Value) => {
    formik?.setFieldValue(id, selected);
    formik?.setFieldTouched(
      id, true, false,
    );
    if (onClick) {
      onClick();
    }
  };

  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;
  const isDiscountRateSelected = formik?.values[id] === 'Discount_Rate';
  const selectOptions: SelectOption[] = isDiscountRateSelected ? [
    { value: 100, valueLabel: '100%' },
    { value: 80, valueLabel: '80%' },
    { value: 50, valueLabel: '50%' },
  ] : [
    { value: 0, valueLabel: '%' },
  ];

  return (
    <Container>
      <FormControl data-cy={dataCy} component="fieldset">
        <FormLabelStylized focused={false}>{formLabel}</FormLabelStylized>
        <RadioGroup
          row
          aria-label={id}
          name={id}
          id={id}
        >
          {options.map(({ value, label, layout }) => (
            <CardContainer isChecked={formik?.values[id] === value}>
              <FormControlLabelStylized
                key={value.toString()}
                value={value}
                isDeploymentFeeValues
                control={(
                  <RadioStylized
                    checked={formik?.values[id] === value}
                    onClick={() => handleChange(value)}
                  />
                )}
                label={label}
                labelPlacement={labelPlacement}
              />
              {layout === '2' && (
                <QuantityField
                  id="qtdInstallments"
                  formik={formik}
                  dataCy={label}
                  border={false}
                  justify={false}
                  isOnboardingPage
                  isDeploymentFeeValues
                  radioChecked={formik?.values[id] === 'Installments'}
                  initialQuantity={1}
                />
              )}
              {layout === '3' && (
                <SimpleSelectContainer>
                  <SimpleSelect
                    id="valueBalanceDiscountRate"
                    formik={formik}
                    dataCy={label}
                    disabled={!isDiscountRateSelected}
                    placeholder="%"
                    options={selectOptions}
                  />
                </SimpleSelectContainer>
              )}
            </CardContainer>
          ))}
        </RadioGroup>
      </FormControl>
      { !!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
      )}
    </Container>
  );
};

export default OnboardingPaymentMethod;
