import React from 'react';

import Card from './Card';

import {
  Container,
  GeneralContainer,
} from './style';

import { cardsData } from './data/cardsData';
import ChoiseMobile from './template/ChoiseMobile';

const CardSection = () => {
  const cardsRef = React.useRef({} as HTMLDivElement);

  return (
    <GeneralContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <Container ref={cardsRef}>

        <ChoiseMobile cardsRef={cardsRef}>
          {cardsData.map((card) => (
            <Card
              key={card.id}
              title={card.title}
              image={card.image}
              description={card.description}
              withShareButton={card.withShareButton}
              access={card.access}
            />
          ))}
        </ChoiseMobile>

      </Container>
    </GeneralContainer>
  );
};

export default CardSection;
