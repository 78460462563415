import { useState, FC, createContext } from 'react';

import FastDataForwardIcon from '../../../assets/icons/multicalendar/FastDataForwardIcon.svg';
import FastDataForwardModal from './FastDataForwardModal';

import { FastDataForwardButtonContainer } from './styles';

interface FastDataForwardButtonProps {
  setDates: Function;
}

export const FastDataForwardContext = createContext<{
  dateSelected: Date, setDateSelected: Function }>({
  dateSelected: new Date(),
  setDateSelected: () => {},
});

const FastDataForwardButton:FC<FastDataForwardButtonProps> = ({ setDates }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<Date>(new Date());

  return (
    <FastDataForwardContext.Provider value={{ dateSelected, setDateSelected }}>
      <FastDataForwardButtonContainer onClick={() => setIsModalVisible(true)}>
        <img src={FastDataForwardIcon} alt="botão de configurações" />
      </FastDataForwardButtonContainer>
      { isModalVisible ? (
        <FastDataForwardModal
          id="fastdataforwardmodal"
          onClose={() => setIsModalVisible(false)}
          setDates={setDates}
        />
      ) : null }
    </FastDataForwardContext.Provider>
  );
};

export default FastDataForwardButton;
