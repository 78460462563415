import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 83vh;
  background: #D6DDEB;
  border-radius: 10px;
  padding: 9px 13px;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  padding: 20px;
`;

export const Content = styled.div`
  width: 100%;
  height: 90%;
  overflow-y: scroll;
`;
