import React from 'react';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import { Container } from './styles';
import { partnersRoutes } from '../../../../../types';
import { MapPoint } from '../../../../../../../assets/animations';

const route = `/${partnersRoutes.partners.main}/${partnersRoutes.indicate.main}`;

const NotFound = () => (
  <Container>

    <motion.div
      transition={{
        delay: 0.2,
      }}
      initial={{
        opacity: 0,
        y: 10,
      }}
      animate={{
        opacity: 0.9,
        y: 0,
      }}
      style={{ width: '300px' }}
    >
      <MapPoint />
    </motion.div>

    <motion.h1
      transition={{
        delay: 0.3,
      }}
      initial={{
        opacity: 0,
        y: 10,
      }}
      animate={{
        opacity: '',
        y: 0,
      }}
    >
      Nenhuma indicação foi encontrada
    </motion.h1>

    <motion.p
      transition={{
        delay: 0.4,
      }}
      initial={{
        opacity: 0,
        y: 10,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
    >
      <Link to={route}>
        <motion.button whileTap={{ scale: 0.9 }}>
          <p>Clique aqui para indicar uma locação ou um terreno</p>
        </motion.button>
      </Link>
    </motion.p>
  </Container>
);

export default NotFound;
