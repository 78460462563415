import React, {
  createContext, useState, ReactNode, useContext,
} from 'react';

interface ChecklistContextData {
  finishChecklist: boolean;
  setFinishChecklist: (a: boolean) => void;
}

interface Props {
  children: ReactNode;
}

const ChecklistContext = createContext<ChecklistContextData>({} as
  ChecklistContextData);

export const ChecklistProvider = ({
  children,
}: Props) => {
  const [finishChecklist, setFinishChecklist] = useState<boolean>(false);

  return (
    <ChecklistContext.Provider value={{
      finishChecklist,
      setFinishChecklist,
    }}
    >
      {children}
    </ChecklistContext.Provider>
  );
};

export function useChecklist(): ChecklistContextData {
  const { finishChecklist, setFinishChecklist } = useContext(ChecklistContext);
  return { finishChecklist, setFinishChecklist };
}
