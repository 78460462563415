import styled from 'styled-components';

import { TEDLISTPAGEMARGIN } from './utils';

export const Container = styled.div`
  border-radius: 10px;
  margin: ${TEDLISTPAGEMARGIN / 2}px;
  width: calc(100% - ${TEDLISTPAGEMARGIN}px);
  height: calc(100% - ${TEDLISTPAGEMARGIN}px);

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow: hidden;

  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  background-color: #fff;
  padding: 16px 25px;
`;

export const ContainerButton = styled.div`
  button {
    border-radius: 10px;
    min-width: fit-content;
    padding: 10px 20px;
    cursor: pointer;
    background: ${({ theme }) => theme.palette.blue._400.hex()};

    transition: all 0.5s ease;

    border: 2px solid transparent;

    color: ${({ theme }) => theme.palette.white._900.hex()};

    &:hover {
      border: 2px solid ${({ theme }) => theme.palette.blue._930.hex()};
      background: ${({ theme }) => theme.palette.blue._930.hex()};
    }

    h1 {
      font-size: 1rem;
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts.familys._1};
      color: ${({ theme }) => theme.palette.white.main.hex()};
    }

    @media (max-width: 1350px) {
      min-width: none;
      padding: 6px;
    }
  }
`;
