import React from 'react';
import { StyledButton, Container, StyledInput } from './style';
import { useOwnerPerspectiveDashboard } from '../../../context/OwnerPerspectiveDashboard/OwnerPerspectiveDashboard';

const Input = () => (
  <StyledInput
    id="property_id"
    placeholder="Busque por nome, código do imóvel ou person_id"
    autoFocus
    onFocus={(e) => e.target.select()}
  />
);

const SearchButton = () => (
  <StyledButton type="submit" whileTap={{ scale: 0.95 }}>
    <h1>
      Pesquisar
    </h1>
  </StyledButton>
);

const Search = () => {
  const { setSearchTerm } = useOwnerPerspectiveDashboard();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const [input] = e.target as unknown as HTMLInputElement[];

    setSearchTerm(input.value);
  };

  return (
    <Container onSubmit={(e) => handleSubmit(e)}>
      <Input />
      <SearchButton />
    </Container>
  );
};

export default Search;
