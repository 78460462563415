import { useContextSelector } from 'use-context-selector';
import { HostGridFinancesContext } from '../../../context/HostDashboard/HostGridFinances/HostGridFinancesContext';

export function useGridFinances() {
  const year = useContextSelector(HostGridFinancesContext,
    (state) => state.year);

  const setYear = useContextSelector(HostGridFinancesContext,
    (state) => state.setYear);

  const financesData = useContextSelector(HostGridFinancesContext,
    (state) => state.financesData);

  const setFinancesData = useContextSelector(HostGridFinancesContext,
    (state) => state.setFinancesData);

  const isLoadingFinances = useContextSelector(HostGridFinancesContext,
    (state) => state.isLoadingFinances);

  const setIsLoadingFinances = useContextSelector(HostGridFinancesContext,
    (state) => state.setIsLoadingFinances);

  const propertyId = useContextSelector(HostGridFinancesContext,
    (state) => state.propertyId);

  const setPropertyId = useContextSelector(HostGridFinancesContext,
    (state) => state.setPropertyId);

  return {
    year,
    setYear,
    financesData,
    setFinancesData,
    isLoadingFinances,
    setIsLoadingFinances,
    propertyId,
    setPropertyId,
  };
}
