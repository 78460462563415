/* eslint-disable arrow-body-style */

import React, {
  useEffect, useRef, useState,
} from 'react';

import { Container, LoadContent, Text } from './style';
import { SearchingAnimation } from '../../../../../../../../assets/animations/components/SearchingAnimation';

const WAIT_TIME = 1600;

interface IObserver {
  haveMoreAllotmentsData: boolean;
  haveMorePropretysData: boolean;
  haveMoreSpotsData: boolean;
  handleNextPageAllotments: () => void;
  handleNextPagePropretys: () => void;
  handleNextPageSpots: () => void;
}

function scrollToTop() {
  window.scrollBy({ top: -500, behavior: 'smooth' });
}

const Observer = ({
  haveMorePropretysData,
  haveMoreAllotmentsData,
  haveMoreSpotsData,
  handleNextPagePropretys,
  handleNextPageAllotments,
  handleNextPageSpots,
}: IObserver) => {
  const targetRef = useRef(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const timeout = useRef<NodeJS.Timeout>({} as NodeJS.Timeout);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const loadMoreContent = () => {
    try {
      setShow(false);
      setLoading(true);

      timeout.current = setTimeout(() => {
        if (haveMoreAllotmentsData) {
          handleNextPageAllotments();
        }

        if (haveMorePropretysData) {
          handleNextPagePropretys();
        }

        if (haveMoreSpotsData) {
          handleNextPageSpots();
        }
      }, WAIT_TIME);
    } finally {
      timeout.current = setTimeout(() => {
        setLoading(false);
        scrollToTop();
      }, WAIT_TIME);

      timeout.current = setTimeout(() => {
        setShow(true);
      }, WAIT_TIME + 500);
    }
  };

  const onIntersect: IntersectionObserverCallback = (entries) => {
    if (entries[0].isIntersecting) {
      loadMoreContent();
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(onIntersect, {
      threshold: 1.0,
    });

    if (targetRef.current) {
      observerRef.current.observe(targetRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [loadMoreContent]);

  useEffect(() => {
    // remove listener on unmount

    return () => {
      clearTimeout(timeout.current);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      <Container>
        {show && (
          <>
            <div style={{ paddingTop: '100px' }} />
            <div ref={targetRef} />
          </>
        )}

        {loading && (
          <LoadContent>
            <SearchingAnimation />
            <Text>Carregando mais indicações ...</Text>
          </LoadContent>
        )}
      </Container>
    </>
  );
};

export default Observer;
