import { ICardsData, IGetFinancialPropertyAPI } from '../../components/FinancialFeedback/types';
import request from '../request';
import { IGetFinancialProperty } from './types';
import cardsJSON from '../../components/FinancialFeedback/mock/cards.json';

export const getFinancialProperty = async (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthString = month < 10 ? `0${month}` : month;
  const dateFormatted = `${year}-${monthString}`;

  const { data } = await request.get('/admin_financial_closing_kpi/', {
    params: {
      date: dateFormatted,
    },
  });

  // formatToICardsData
  const newData: IGetFinancialProperty = data;
  const formatTemplate: ICardsData[] = cardsJSON;

  const newTable = [
    {
      ...formatTemplate[0].table[0],
      value: newData.closing.amount_active_property,
    },
    {
      ...formatTemplate[0].table[1],
      value: newData.closing.amount_closed_revenues,
    },
  ];

  const dataFormatted = formatTemplate.map((card) => ({
    ...card,
    status: newData.closing.progress * 100,
    table: newTable,
  }));

  const dataAPI = data as IGetFinancialPropertyAPI;

  return { dataAPI, dataFormatted };
};
