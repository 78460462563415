import React, { useState } from 'react';

import CardsList from './CardsList';
import cardsJSON from './cards.json';

import { Container } from './style';
import { ICategoryes } from '../types';

import { getPropertyDocuments, getPropertyImages } from '../../../../services/InsertData/uploadFile/request';
import { IGetDocuments, IGetImages } from '../../../../services/InsertData/uploadFile/type';

interface IPropert {
  propertyId: number | undefined;
}

const UploadButton = ({ propertyId }: IPropert) => {
  const [documentsArray, setDocumentsArray] = useState<IGetDocuments[]>([] as IGetDocuments[]);
  const [imagesArray, setImagesArray] = useState<IGetImages[]>([] as IGetImages[]);

  React.useEffect(() => {
    const handleGetDocuments = async () => {
      if (propertyId) {
        const images = await getPropertyImages('Image');
        const documents = await getPropertyDocuments('Document');
        setImagesArray([...images]);
        setDocumentsArray([...documents]);
      }
    };

    handleGetDocuments();
  }, []);

  const isResponsePropertyIdIqualDocs = React.useCallback((category: ICategoryes) => {
    const filterByPropertyId = documentsArray
      .filter((item) => item.property.id === propertyId);

    const filterByCategoryes = filterByPropertyId
      .filter((item) => item.category === category);

    return filterByCategoryes;
  }, [propertyId]);

  const isResponsePropertyIdIqualImg = React.useCallback((category: ICategoryes) => {
    const filterByPropertyIdImg = imagesArray
      .filter((item) => item.property === propertyId);

    const filterByCategoryesImg = filterByPropertyIdImg
      .filter((item) => item.image.category === category);

    return filterByCategoryesImg;
  }, [propertyId]);

  return (
    <Container>
      {cardsJSON.map((item) => (
        <CardsList
          key={item.title}
          title={item.title}
          propertyId={propertyId}
          category={item.category as ICategoryes}
          filesLoaded={isResponsePropertyIdIqualDocs(item.category as ICategoryes)}
          imagesLoaded={isResponsePropertyIdIqualImg(item.category as ICategoryes)}
        />
      ))}
    </Container>
  );
};

export default UploadButton;
