import React from 'react';
import Lottie from 'lottie-react';

import animationSource from '../source/errorAnimationIcon.json';

export const ErrorAnimationIcon = () => (
  <Lottie
    animationData={animationSource}
  />
);
