import React, { useEffect } from 'react';

const FinishCheckin: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Pré check-in';
  }, []);
  return (
    <div />
  );
};

export default FinishCheckin;
