import styled, { keyframes } from 'styled-components';

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const FadeAnimation = styled.div` // essa ta dando problema com os botões de baixo :>
  animation-delay: .4s;
  animation-name: ${FadeInOpacity};
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;
`;

export const FormContent = styled.form`
  width: 100%;
  height: 90vh;
  padding-right: 2rem;
  `;

export const Space = styled.div`
  height: 20px;
  `;

export const FormContainer = styled.div`
  width: 100%;
  grid-area: 'form';
  margin: 1rem;
`;

export const ButtonsContainer = styled.div`
  grid-area: 'buttons';
  width: 100%;
  max-width: 500px;
  transition: 1s;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:  ${({ theme }) => theme.palette.white._400.hex()};
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  position: fixed;
  bottom: 0;
  top: 100;
  right: 0;
  padding-right: 4rem;

  button, svg{
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 900px){
      margin: 0;
      margin-right: 3px;
    }

    @media (max-width: 320px){
      margin: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 320px) {
    padding-right: 3rem;
  }

`;

export const ConfirmationButtonContainer = styled.div`
  display: grid;
  transition: 1s;
  text-align: center;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
`;

export const ConfirmationContainer = styled.div`
  display: grid;
  text-align: center;
  width: 100%;
  grid-template-columns: 1fr;
  gap: .5rem;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100vw;
  max-width: 500px;
  height: calc(100vh - 120px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: 10fr 1fr;
  grid-template-areas:
    'form nav'
    'buttons nav'
    ;

`;
