import styled, { css, keyframes } from 'styled-components';

const fadeInModal = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 500px;
  }
`;

const fadeOutModal = keyframes`
  from {
    opacity: 1;
    width: 500px;
  }
  to {
    opacity: 0;
    width: 0;
  }
`;

export const Form = styled.form`
  height: 100%;
  border-radius: 10px;
  gap: 0.3rem;

  p, strong {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-family: Inter;
  }
`;

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 8%;
  left: 35%;
  z-index: 20;
  
  height: 100%;
  width: 100%;
  min-width: 550px;
  max-width: 550px;
  max-height: 600px;

  opacity: 0;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  ${(props) => (props.isOpen ? css`
    animation: ${fadeInModal} 0.5s ease forwards;
    .btn-submit {
      animation: ${fadeInModal} 0.5s ease forwards;
    }
    ` : css`
      animation: ${fadeOutModal} 0.5s ease forwards;
      .btn-submit {
        animation: ${fadeOutModal} 0.5s ease forwards;
      }
  `)}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._410.hex()};
  padding: 30px;
  gap: 0.5rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;

  span {
    max-width: 95%;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  content-visibility: auto;
  padding: 30px 30px;
  gap: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.8rem;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.grey._360.hex()};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const Title = styled.strong`
  font-weight: 400;
  font-size: 1.375rem !important;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._970.hex()};
`;

export const SwitchContainer = styled.div`
  margin-top: 0.8rem;
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  strong {
    font-size: 1rem;
    font-weight: 700;
    font-family: Inter;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }
`;

export const SwitchContent = styled.div<{ switchIsActive: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0.5rem 0;

  ${(props) => (props.switchIsActive ? css`
    p {
      :first-child {
        color: ${({ theme }) => theme.palette.grey._670.hex()};
      }
      :last-child {
        color: ${({ theme }) => theme.palette.blue._900.hex()};
      }
    }
    ` : css`
    p {
      :first-child {
        color: ${({ theme }) => theme.palette.blue._900.hex()};
      }
      :last-child {
        color: ${({ theme }) => theme.palette.grey._670.hex()};
      }
    }
  `)}
`;

export const SwitchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 25px;
  border: 1px solid ${({ theme }) => theme.palette.grey._410.hex()};
  border-radius: 57px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  position: relative;
  cursor: pointer;
  margin: 0 0.7rem;
`;

export const SwitchCircle = styled.div<{ switchIsActive: boolean }>`
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: ${({ theme, switchIsActive }) => (switchIsActive ? theme.palette.green.main.hex()
    : theme.palette.red._350.hex())};
  position: absolute;

  ${(props) => (props.switchIsActive ? css`
      right: 0;
    ` : css`
      left: 0;
  `)}
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  min-width: 550px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._350.hex()};
  position: relative;
  bottom: 0;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
  background:  ${({ theme }) => theme.palette.white.main.hex()};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  button {
    margin: 1rem;
    cursor: pointer;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background: transparent;

  svg {
    color: ${({ theme }) => theme.palette.grey._450.hex()};
  }
`;

export const Backdrop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.295);
  backdrop-filter: blur(1px);
  z-index: 10;
`;

export const Spacing = styled.div`
  margin: 0.5rem 0;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;

export const SelectContainer = styled.div`
  width: 100%;

  .MuiOutlinedInput-root {
    max-height: 100% !important;
  }

  .MuiNativeSelect-select {
    padding: 0.8rem 0.5rem !important;
  }
`;
