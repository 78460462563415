import React from 'react';

import { Container, Title } from './style';
import CardSection from './CardSection/CardSection';

const About = () => (
  <Container>
    <Title
      initial={{ opacity: 0, y: 20 }}
      animate={{ y: 0, opacity: 1 }}
    >
      Conheça mais sobre a Seazone
    </Title>
    <CardSection />
  </Container>
);

export default About;
