import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface Props {
  title: string;
  children: JSX.Element;
  open?: boolean;
}

const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFCD80',
    color: '#001840',
    padding: '1rem',
    borderRadius: '10px',
    maxWidth: '550px',
  },
}));

const StyledTooltip: React.FC<Props> = ({
  title,
  children,
  open,
}) => {
  if (title === '') {
    return <>{children}</>;
  }

  return (
    <TooltipComponent
      arrow
      open={open}
      placement="bottom-start"
      followCursor
      enterDelay={200}
      leaveDelay={200}
      title={(
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '266px',
          maxHeight: '142px',
          padding: '16px',
        }}
        >
          <p style={{
            fontSize: '1rem',
            fontWeight: 400,
            fontFamily: 'Inter',
          }}
          >
            {title}
          </p>
        </div>
      )}

    >
      {children}
    </TooltipComponent>
  );
};

export default StyledTooltip;
