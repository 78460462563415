import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.button)`
  width: 100%;
  padding: 10px;
  max-height: 40px;
  border-radius: 10px;
  background: #0d4bd0;
  color: #fff;
  border: transparent;
  font-size: 1.1rem;
  cursor: pointer;
`;
