/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { keyframes } from 'styled-components';

const animationTime = '0.25s';
const screenAdaptive = '2000px';
const notebook = '1400px';

export const loadAnimation = keyframes`
  from{
    opacity: 0;
    width: -0%;
    height: -220px;
  }
  to{
    height: auto;
    opacity: 1;
    width: 100%;
  }
`;

export const FadeInAnimation = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const laodButton = keyframes`
  from{
    opacity: 0;
    transform: translateY(10px);
  }
  to{
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Container = styled.div<{
  mobile: boolean | undefined;
  animation?: boolean;
}>`
  padding-top: 0;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #fff;
  opacity: 1;

  scrollbar-width: none;

  flex-direction: column;
  justify-content: space-between;
  animation-name: ${FadeInAnimation};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 800;
  font-style: bold;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  color: ${({ theme }) => theme.palette.blue._950.hex()};

  @media (max-width: ${screenAdaptive}) {
    font-size: 2rem;
  }
`;

export const Header = styled.div`
  padding: 1rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.white._650.hex()};
  display: flex;
  align-items: center;
  justify-content: space-between;

  opacity: 0;
  animation-name: ${laodButton};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
`;

export const Content = styled.div<{
  mobile: boolean | undefined;
}>`
  display: ${({ mobile }) => (mobile ? 'flex' : 'grid')};
  grid-template-columns: ${({ mobile }) => !mobile && ' 1fr 250px'};
  height: ${({ mobile }) => (mobile && 'calc(100vh - 22vh)')};
  height: ${({ mobile }) => (!mobile && 'calc(100vh - 20%)')};

  @media (max-height: 600px){
    height: 82vh;
  }
  @media (max-width: 320px){
    height: calc(100vh - 10%);
  }
`;

export const CloseButton = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  z-index: 10;
  transform: scale(1.3, 1.2);
  font-weight: bolder;
  font-stretch: semi-condensed;
  cursor: pointer;
  font-size: 1.8rem;
  margin-top: -5px;
  transition: ${animationTime};
  color: ${({ theme }) => theme.palette.grey._720.hex()};

  &:hover {
    color: ${({ theme }) => theme.palette.pink.main.hex()};
  }

  @media (max-width: ${screenAdaptive}) {
    font-size: 1.4rem;
  }

  @media (max-width: 900px) {
    font-size: 2rem;
    transform: scale(1.4, 1.3);
  }

  opacity: 0;
  animation-name: ${laodButton};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: ${animationTime};
`;

export const LateralContainer = styled.div<{
  mobile: boolean | undefined;
}>`
  scrollbar-width: none;
  opacity: 0;
  animation-name: ${laodButton};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  display: ${({ mobile }) => (mobile ? 'none' : 'auto')};
`;

export const MainContainer = styled.div<{
  mobile: boolean | undefined;
}>`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 0;
  animation-name: ${laodButton};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  animation-timing-function: ease-in-out;
`;

export const ProgressBar = styled.div<{
  progress?: number;
}>`
  background: ${({ theme }) => theme.palette.red._400.hex()};
  height: 10px;
  width: 100%;
  transition: ${animationTime};
  animation-timing-function: ease;
  max-width: ${({ progress }) => `${progress}%`};
  display: flex;

  opacity: 0;
  animation-name: ${laodButton};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 0.3s;
`;

export const ProgressBarContainer = styled.div``;
