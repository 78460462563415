import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 123px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  background-image: linear-gradient(to bottom, #E8EAF3, #FFFFFF);

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const SelectLocation = styled.div`
  width: 130px;
  margin-right: 10px;
`;

export const Divider = styled.div`
  display: flex;
  button {
    margin-top: 33px;
  }
`;

export const BoldText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 700;
  font-size: 14px;
`;

export const SelectCategory = styled.div`
  width: 130px;
  margin-right: 10px;
`;

export const SelectBathroom = styled.div`
  width: 130px;
  margin-right: 10px;
`;

export const InputHideSelect = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`;

export const InputModal = styled.div`
  position: absolute;
  box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23);
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey._450.hex()};
  width: 287px;
  background-color:${({ theme }) => theme.palette.white.main.hex()};
  margin-top: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  z-index: 6;

  &.input-modal-style {
    height: auto;
  }

  .messageError {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-top: 5px;
  }

  .css-1qz17ui-MuiPaper-root {
    z-index: 10010 !important;
  }

  .group-price {
    width: 100%;
    display: flex;
    flex-direction: row;

    span {
      margin-top: 35px;
      margin-left: 5px;
      margin-right: 6px;

      @media (max-width: 600px) {
        margin-top: 5px;
      }
    }

    .min {
      width: 127.5px;
    }

    .max {
      width: 127.5px;
    }

    @media(max-width: 600px) {
      flex-direction: column;
    }
  }

  .buttonApply {
    margin-right: 0;
    margin-left: auto;
    margin-top: 5px;

    @media (max-width: 600px) {
      width: 100%;
    }

  }

  @media(max-width: 650px) {
    width: 200px;
  }

  @media(max-width: 600px) {
    width: 180px;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 400px) {
    width: 145px;
    padding: 10px;
  }
`;

export const ButtonSearch = styled.button`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  background: linear-gradient(
    360deg,
    ${({ theme }) => theme.palette.orange.main.hex()} 0%,
    ${({ theme }) => theme.palette.orange._600.hex()} 100%
  );
  border: none;
  border-radius: 10px;
  width: 120px;
  height: 40px;
  margin-top: 22px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.orange._700.hex()};
    -webkit-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    -moz-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
  }
`;

export const SelectDate = styled.div`
  margin-top: 10px;
  width: 300px;
  margin-right: 10px;
`;
