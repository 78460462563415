import {
  Email, MapsHomeWorkOutlined, Person, Phone,
} from '@mui/icons-material';
import { RequestHost } from '../../../services/Host/types';
import ModalTemplate from './ModalTemplate';
import {
  Card,
  Container, Content, ContentLeft, ContentRight, HeaderCard, Title, Wrapper,
} from './styles';

import cleaningIcon from '../../../assets/icons/propertySearch/cleaning.svg';
import comissionIcon from '../../../assets/icons/propertySearch/bondAmount.svg';

interface Props {
  onClose: Function;
  open: boolean;
  host: RequestHost;
}

const ModalMoreInformation = ({
  open, onClose, host,
}: Props) => (
  <ModalTemplate
    open={open}
    handleClose={onClose}
  >
    <Wrapper>
      <Title>Informações do Anfitrião</Title>
      <Container>
        <ContentLeft>
          <Content>
            <div className="row">
              <Person />
              {' '}
              <p>{host.name}</p>
            </div>
            <div className="row">
              <Phone />
              {' '}
              <p>{host.user?.phone_number1 || host.user?.phone_number2 || 'Não informado'}</p>

            </div>
            <div className="row">
              <Email />
              {' '}
              <p>{host.user?.email || 'Não informado'}</p>
            </div>
          </Content>
          <Content>
            <div className="row">
              <span>Endereço:</span>
              {' '}
              <p>{`${host.user?.main_address.street}, ${host.user?.main_address.number}, ${host.user?.main_address.neighborhood}, ${host.user?.main_address.country} - ${host.user?.main_address.state}` }</p>
            </div>
            <div className="row">
              <span>Apelido:</span>
              {' '}
              <p />
              {host.user?.nickname || 'Não informado'}
            </div>
            <div className="row">
              <span>Nome Corporativo:</span>
              {' '}
              <p>{host.user?.corporate_name || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Nome comercial:</span>
              {' '}
              <p>{host.user?.trading_name || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>CPF:</span>
              {' '}
              <p>{host.user?.cpf || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>CNPJ:</span>
              {' '}
              <p>{host.user?.cnpj || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Número de identidade:</span>
              {' '}
              <p>{host.user?.id_number || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Data de nascimento:</span>
              {' '}
              <p>{host.user?.birth_date || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Gênero:</span>
              {' '}
              <p>{host.user?.gender === 'Female' ? 'Feminino' : 'Masculino'}</p>
            </div>
            <div className="row">
              <span>É um anfitrião de operação:</span>
              {' '}
              <p>{host.is_host_ops ? 'Sim' : 'Não'}</p>
            </div>
          </Content>
        </ContentLeft>
        <ContentRight>
          <div className="row">
            <Card>
              <HeaderCard>
                <MapsHomeWorkOutlined />
                <span>Propriedades:</span>
              </HeaderCard>
              <p>
                {host.properties}
              </p>
            </Card>
          </div>
          <div className="row">
            <Card>
              <HeaderCard>
                <img src={comissionIcon} alt="ícone de casa" />
                <span>Taxa de comissão de reserva:</span>
              </HeaderCard>
              <p>
                {host.reservation_commission_fee ? `${host.reservation_commission_fee * 100}%` : 'Não informado'}
              </p>
            </Card>
          </div>
          <div className="row">
            <Card>
              <HeaderCard>
                <img src={cleaningIcon} alt="ícone de casa" />
                <span>Taxa de comissão de limpeza:</span>
              </HeaderCard>
              <p>
                {host.cleaning_commission_fee ? `${host.cleaning_commission_fee * 100}%` : 'Não informado'}
              </p>
            </Card>
          </div>
          <div className="row">
            <Card>
              <HeaderCard>
                <img src={cleaningIcon} alt="ícone de casa" />
                <span>Royalties de limpeza:</span>
              </HeaderCard>
              <p>
                {host.legacy_cleaning_royalties ? `${host.legacy_cleaning_royalties * 100}%` : 'Não informado'}
              </p>
            </Card>
          </div>
          <div className="row">
            <Card>
              <HeaderCard>
                <img src={comissionIcon} alt="ícone de casa" />
                <span>Royalties de reserva:</span>
              </HeaderCard>
              <p>
                {host.legacy_reservation_royalties ? `${host.legacy_reservation_royalties * 100}%` : 'Não informado'}
              </p>
            </Card>
          </div>
        </ContentRight>
      </Container>
    </Wrapper>
  </ModalTemplate>
);

export default ModalMoreInformation;
