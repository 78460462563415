import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 20px 20px;
  background: #EBEBF5;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  color: rgba(0,0,0,1);
  border-bottom: 2px solid #ccc;
  font-family: ${({ theme }) => theme.fonts.familys._6};

  h1 {
    font-size: 1.3rem;

    span {
      margin-left: 3px;
      font-size: 1.3rem;
      color: #0D4BD0;
      text-transform: capitalize;
    }
  }

  p{
    font-size: 1.1rem;
    color: rgba(0,0,0,0.6);
  }

  button {
    width: 100%;
    color: white;
    padding: 7px;
    border: none;
    font-size: 1.1rem;
    background: #0D4BD0;
    border-radius: 6px;
    cursor: pointer;
  }
`;
