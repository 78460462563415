import styled from 'styled-components';
import { CloseOutlined } from '@mui/icons-material';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  svg {
    fill: #394760;
  }
`;

export const Description = styled.p`
  && {
    text-align: start;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 22px;
    width: 90%;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }

  strong {
    font-weight: 700;
  }
`;

export const CloseOutlinedIcon = styled(CloseOutlined)`
  cursor: pointer;
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
`;
