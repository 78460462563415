import React from 'react';

import {
  Container, HotBedContainer, Text, Title, ToReciveContainer,
} from './style';

const HotBed = () => (
  <Container className="hotBed pin">
    <HotBedContainer>
      <Text>Cama quente</Text>
    </HotBedContainer>
  </Container>
);

const ToRecive = () => (
  <Container className="toRecive pin">
    <ToReciveContainer>
      <Title>A receber</Title>
    </ToReciveContainer>
  </Container>
);

export { ToRecive, HotBed };
