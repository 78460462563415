import { numberToCurrency } from '../../utils/Formatter';
import { ProperPayHostDashboard } from './types';

export const formatMonetaryValue = (value: number) => (value === 0 ? '-' : numberToCurrency(value));

export const sumExecuteds = (toReceive: number, toReceiveFollowingMonth: number) => {
  const total = toReceive + toReceiveFollowingMonth;
  return total;
};

export const sumPaids = (revenue: number, revenuePriorMonth: number) => {
  const total = revenue + revenuePriorMonth;
  return total;
};

export const sumOthersReceipts = (refundsPaid: number, manualFit: number) => {
  const total = refundsPaid + manualFit;
  return total;
};

export const getFormattedFinancialData = (data: any, formatToCurrency: boolean = true) => ({
  1: formatToCurrency ? formatMonetaryValue(data?.[1] || 0) : data?.[1] || '-',
  2: formatToCurrency ? formatMonetaryValue(data?.[2] || 0) : data?.[2] || '-',
  3: formatToCurrency ? formatMonetaryValue(data?.[3] || 0) : data?.[3] || '-',
  4: formatToCurrency ? formatMonetaryValue(data?.[4] || 0) : data?.[4] || '-',
  5: formatToCurrency ? formatMonetaryValue(data?.[5] || 0) : data?.[5] || '-',
  6: formatToCurrency ? formatMonetaryValue(data?.[6] || 0) : data?.[6] || '-',
  7: formatToCurrency ? formatMonetaryValue(data?.[7] || 0) : data?.[7] || '-',
  8: formatToCurrency ? formatMonetaryValue(data?.[8] || 0) : data?.[8] || '-',
  9: formatToCurrency ? formatMonetaryValue(data?.[9] || 0) : data?.[9] || '-',
  10: formatToCurrency ? formatMonetaryValue(data?.[10] || 0) : data?.[10] || '-',
  11: formatToCurrency ? formatMonetaryValue(data?.[11] || 0) : data?.[11] || '-',
  12: formatToCurrency ? formatMonetaryValue(data?.[12] || 0) : data?.[12] || '-',
  total: formatToCurrency ? formatMonetaryValue(data?.total || 0) : data?.total || '-',
});

export const getMonthlyFormattedFinancialData = (data: any, formatToCurrency?: boolean) => ({
  ...getFormattedFinancialData(data, formatToCurrency),
});

export const initialValuesHostDashboard: ProperPayHostDashboard = {
  executed_dailys: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  executed_dailys_to_receive: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  executed_dailys_to_receive_following_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  executed_cleaning: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  executed_cleaning_to_receive: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  executed_cleaning_to_receive_following_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  paid_dailys: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  paid_dailys_revenue: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  paid_dailys_revenue_prior_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  paid_cleaning: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  paid_cleaning_revenue: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  paid_cleaning_revenue_prior_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  others_receipts: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  others_receipts_refunds_paid: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  others_receipts_manual_fit: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  cash_out: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  number_of_cleanings: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  number_of_cleanings_to_receive: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  number_of_cleanings_next_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  number_of_dailys: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  number_of_dailys_to_receive: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  number_of_dailys_next_month: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  results: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  franchise_fee: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  transfer: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
  balance: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    total: '',
  },
};
