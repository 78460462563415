import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import moment from 'moment';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { useEffect, useState } from 'react';

import {
  ArrowsContainer,
  LineShimmer,
  SearchTableContainer,
  AnimationDiv,
  Table,
  ButtonContainer,
  ArrowsButton,
} from './styles';

import FormButton from '../../../../FormButton/FormButton';
import { GetGuestDamageNegotiation } from '../../../../../services/GuestDamage/types';
import { useGuestDamageStep } from '../../../../../hooks/GuestDamage/useGuestDamageStep';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { compareBoolean, compareDates, compareList } from '../../../../../utils/Sorting';
import { useLoadMoreData } from '../../../../../hooks/useLoadMoreData';

const Arrows = () => (
  <ArrowsContainer>
    <KeyboardArrowUp style={{ marginBottom: '-6px' }} />
    <KeyboardArrowDown style={{ marginTop: '-6px' }} />
  </ArrowsContainer>
);

interface SearchTableProps {
  isLoading: boolean;
}

const tableVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 120, transition: { duration: 0.2 } },
};
const trVariants: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};
const tbodyVariants: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.2,
    },
  },
};
const animationDiv: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
const animationDivWithDelay: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

type GuestDamageNegotiationSortedBy = 'code' | 'guest_name' | 'status' | 'stage' | 'refund' | 'check-out_date';
type Order = 'asc' | 'desc';
const ITEMS_PER_PAGE = 10;

export const SearchTable = ({
  isLoading,
}: SearchTableProps) => {
  const {
    setSelectedGuestDamageNegotiation,
    guestDamageNegotiations,
  } = useGuestDamageNegotiation();
  const isLoadingData = isLoading === true;
  const isLoadedData = guestDamageNegotiations.length > 0 && isLoading === false;
  const [sortedBy, setSortedBy] = useState<GuestDamageNegotiationSortedBy>('code');
  const [order, setOrder] = useState<Order>('asc');
  const { handleViewDamageDetailsStep } = useGuestDamageStep();
  const {
    actualPageData: guestDamageNegotiationsPageData,
    haveANextPage: haveMoreGuestDamageNegotiationsData,
    handleNextPage: handleNextPageGuestDamageNegotiations,
  } = useLoadMoreData<GetGuestDamageNegotiation[]>({
    pageLength: ITEMS_PER_PAGE,
    data: guestDamageNegotiations || [],
  });

  const [sortedGuestDamageNegotiations,
    setSortedGuestDamageNegotiations] = useState<GetGuestDamageNegotiation[
  ]>(guestDamageNegotiationsPageData);

  function handleSelectGuestDamageNegotiation(guestDamageNegotiation
  : GetGuestDamageNegotiation) {
    setSelectedGuestDamageNegotiation(guestDamageNegotiation);
    handleViewDamageDetailsStep();
  }

  function handleSortGuestDamageNegotiations(sortBy: GuestDamageNegotiationSortedBy) {
    switch (sortBy) {
      case 'code': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.reservation?.property_code || '', b.reservation?.property_code || '', 'desc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.reservation?.property_code || '', b.reservation?.property_code || '', 'asc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('code');
          const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
            a.reservation?.property_code || '', b.reservation?.property_code || '', 'asc',
          ));
          setSortedGuestDamageNegotiations(sortedNegotiations);
        }
        break;
      }
      case 'guest_name': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.reservation?.guest_name || '', b.reservation?.guest_name || '', 'desc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.reservation?.guest_name || '', b.reservation?.guest_name || '', 'asc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('guest_name');
          const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
            a.reservation?.guest_name || '', b.reservation?.guest_name || '', 'asc',
          ));
          setSortedGuestDamageNegotiations(sortedNegotiations);
        }
        break;
      }
      case 'status': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.status || '', b.status || '', 'desc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.status || '', b.status || '', 'asc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('status');
          const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
            a.status || '', b.status || '', 'asc',
          ));
          setSortedGuestDamageNegotiations(sortedNegotiations);
        }
        break;
      }
      case 'stage': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.stage || '', b.stage || '', 'desc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
              a.stage || '', b.stage || '', 'asc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('stage');
          const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
            a.stage || '', b.stage || '', 'asc',
          ));
          setSortedGuestDamageNegotiations(sortedNegotiations);
        }
        break;
      }
      case 'refund': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a,
              b) => compareBoolean(
              a.is_refunded || false, b.is_refunded || false, 'desc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a,
              b) => compareBoolean(
              a.is_refunded || false, b.is_refunded || false, 'asc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('refund');
          const sortedNegotiations = guestDamageNegotiationsPageData.sort((a,
            b) => compareBoolean(
            a.is_refunded || false, b.is_refunded || false, 'asc',
          ));
          setSortedGuestDamageNegotiations(sortedNegotiations);
        }
        break;
      }
      case 'check-out_date': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a,
              b) => compareDates(
              moment(a.reservation?.check_out_date || '').format('DD/MM/YYYY'), moment(b.reservation?.check_out_date || '').format('DD/MM/YYYY'), 'desc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = guestDamageNegotiationsPageData.sort((a,
              b) => compareDates(
              moment(a.reservation?.check_out_date || '').format('DD/MM/YYYY'), moment(b.reservation?.check_out_date || '').format('DD/MM/YYYY'), 'asc',
            ));
            setSortedGuestDamageNegotiations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('check-out_date');
          const sortedNegotiations = guestDamageNegotiationsPageData.sort((a,
            b) => compareDates(
            moment(a.reservation?.check_out_date || '').format('DD/MM/YYYY'), moment(b.reservation?.check_out_date || '').format('DD/MM/YYYY'), 'asc',
          ));
          setSortedGuestDamageNegotiations(sortedNegotiations);
        }
        break;
      }
      default: {
        const sortedNegotiations = guestDamageNegotiationsPageData.sort((a, b) => compareList(
          a.reservation?.property_code || '', b.reservation?.property_code || '', 'asc',
        ));
        setSortedGuestDamageNegotiations(sortedNegotiations);
        break;
      }
    }
  }

  useEffect(() => {
    handleSortGuestDamageNegotiations('code');
  }, [guestDamageNegotiationsPageData]);

  return (
    <>
      {(isLoadingData || isLoadedData) && (
        <>
          <SearchTableContainer
            variants={tableVariants}
            initial="closed"
            animate="open"
            key="table"
          >
            <Table>
              <thead>
                <motion.tr variants={trVariants} initial="hidden" animate="visible">
                  <th>
                    <AnimationDiv variants={animationDiv} key="1">
                      Imóvel
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortGuestDamageNegotiations('code')}>
                        <Arrows />
                      </ArrowsButton>
                    </AnimationDiv>
                  </th>
                  <th>
                    <AnimationDiv variants={animationDiv} key="2">
                      Nome do hóspede
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortGuestDamageNegotiations('guest_name')}>
                        <Arrows />
                      </ArrowsButton>
                    </AnimationDiv>
                  </th>
                  <th>
                    <AnimationDiv variants={animationDiv} key="3">
                      Status
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortGuestDamageNegotiations('status')}>
                        <Arrows />
                      </ArrowsButton>
                    </AnimationDiv>
                  </th>
                  <th>
                    <AnimationDiv variants={animationDiv} key="4">
                      Etapa
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortGuestDamageNegotiations('stage')}>
                        <Arrows />
                      </ArrowsButton>
                    </AnimationDiv>
                  </th>
                  <th>
                    <AnimationDiv variants={animationDiv} key="5">
                      Reembolso
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortGuestDamageNegotiations('refund')}>
                        <Arrows />
                      </ArrowsButton>
                    </AnimationDiv>
                  </th>
                  <th>
                    <AnimationDiv variants={animationDiv} key="6">
                      Data de check-out
                      {' '}
                      <ArrowsButton type="button" onClick={() => handleSortGuestDamageNegotiations('check-out_date')}>
                        <Arrows />
                      </ArrowsButton>
                    </AnimationDiv>
                  </th>
                  <th> </th>
                </motion.tr>
              </thead>
              <motion.tbody
                variants={tbodyVariants}
                initial="hidden"
                animate="visible"
              >
                <AnimatePresence>
                  {
              (sortedGuestDamageNegotiations.length > 0 && isLoading === false)
              && sortedGuestDamageNegotiations.map((guestDamageNegotiation) => {
                const formattedCheckoutDate = moment(guestDamageNegotiation.reservation?.check_out_date).format('DD/MM/YYYY');
                return (
                  <motion.tr key={guestDamageNegotiation.id} variants={animationDivWithDelay}>
                    <td>
                      <div>
                        {guestDamageNegotiation?.reservation?.property_code || '-'}
                      </div>
                    </td>
                    <td>{guestDamageNegotiation?.reservation?.guest_name}</td>
                    <td>
                      <span>{guestDamageNegotiation?.status || '-'}</span>
                    </td>
                    <td>{guestDamageNegotiation?.stage || '-'}</td>
                    <td>{guestDamageNegotiation?.is_refunded === true ? 'Sim' : 'Não'}</td>
                    <td>{formattedCheckoutDate}</td>
                    <td>
                      <FormButton
                        isFull
                        variant="outlined"
                        customColor="blue-dark"
                        type="button"
                        onClick={() => handleSelectGuestDamageNegotiation(guestDamageNegotiation)}
                      >
                        Selecionar
                      </FormButton>
                    </td>
                  </motion.tr>
                );
              })
              }
                  {isLoading && (
                  <>
                    <tr>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                    </tr>
                    <tr>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                    </tr>
                    <tr>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                      <td><LineShimmer /></td>
                    </tr>
                  </>
                  )}
                </AnimatePresence>
              </motion.tbody>
            </Table>
          </SearchTableContainer>
            {haveMoreGuestDamageNegotiationsData && (
              <ButtonContainer>
                <FormButton
                  onClick={handleNextPageGuestDamageNegotiations}
                >
                  Carregar mais dados
                </FormButton>
              </ButtonContainer>
            )}
        </>
      )}
    </>
  );
};
