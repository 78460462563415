import styled from 'styled-components';

export const Container = styled.div`
  button {
    border-radius: 10px;
    min-width: fit-content;
    padding: 10px 20px;
    cursor: pointer;
    background: ${({ theme }) => theme.palette.blue._400.hex()};

    transition: all 0.5s ease;

    border: 2px solid transparent;

    color: ${({ theme }) => theme.palette.white._900.hex()};

    &:hover {
      border: 2px solid ${({ theme }) => theme.palette.blue._930.hex()};
      background: ${({ theme }) => theme.palette.blue._930.hex()};
    }

    h1 {
      font-size: 1rem;
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts.familys._1};
      color: ${({ theme }) => theme.palette.white.main.hex()};
    }

    @media (max-width: 1350px) {
      min-width: none;
      padding: 6px;
    }
  }
`;
