import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { ArrowDown, ArrowUp } from 'react-feather';
import { ArrowContent, ArrowsContainer } from './style';

const Arrows = ({ open }: { open: boolean }) => (
  <ArrowsContainer>
    <AnimatePresence>
      {open ? (
        <ArrowContent
          key="up"
          initial={{ y: 10, opacity: 0 }}
          animate={{
            y: -10,
            opacity: 1,
            transition: {
              duration: 0.7,
              ease: [1, -0.05, 0.66, 1],
            },
          }}
          exit={{
            y: -100,
            opacity: 0,
            transition: {
              duration: 0.7,
              ease: [1, -0.05, 0.66, 1],
            },
          }}
        >
          <ArrowUp style={{ color: 'rgba(0,0,0,0.7)' }} />
        </ArrowContent>
      ) : (
        <ArrowContent
          key="down"
          initial={{ y: -30, opacity: 0 }}
          animate={{
            y: -10,
            opacity: 1,
            transition: {
              duration: 0.7,
              ease: [1, -0.05, 0.66, 1],
            },
          }}
          exit={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.7,
              ease: [1, -0.05, 0.66, 1],
            },
          }}
        >
          <ArrowDown style={{ color: 'rgba(0,0,0,0.7)' }} />
        </ArrowContent>
      )}
    </AnimatePresence>
  </ArrowsContainer>
);

export default Arrows;
