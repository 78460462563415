import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Form,
  Title,
  Container,
  SendButton,
  InlineForm,
  CloseButton,
  CancelButton,
  ButtonContainer,
} from './style';

import Success from './Success';

const CloseSVGIcon = () => {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (
    <CloseButton onClick={() => goBack()}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.1621 14.4653L2.50276 0.805985C2.05763 0.360852 1.33593 0.360852 0.890793 0.805985L0.805952 0.890826C0.360819 1.33596 0.360819 2.05766 0.805952 2.5028L14.4653 16.1621C14.9104 16.6073 15.6321 16.6073 16.0772 16.1621L16.1621 16.0773C16.6072 15.6321 16.6072 14.9104 16.1621 14.4653Z" fill="#394760" />
        <path d="M14.584 0.874411L0.924637 14.5337C0.479504 14.9789 0.479504 15.7006 0.924637 16.1457L1.00948 16.2305C1.45461 16.6757 2.17631 16.6757 2.62145 16.2305L16.2808 2.57122C16.7259 2.12609 16.7259 1.40438 16.2808 0.959251L16.1959 0.874411C15.7508 0.429277 15.0291 0.429278 14.584 0.874411Z" fill="#394760" />
      </svg>
    </CloseButton>
  );
};

const Withdraw = () => {
  const [successPage, setSuccessPage] = React.useState<boolean>(false);
  const navigate = useNavigate();

  function finish() {
    setSuccessPage(true);
  }

  // returns
  if (successPage) {
    return (
      <Success />
    );
  }

  return (
    <Container>
      <CloseSVGIcon />
      <Title>Informe sua conta bancária</Title>
      <Form>
        <label htmlFor="bank">
          <p>Banco</p>
          <input
            required
            type="text"
            name="bank"
            placeholder="Digite aqui..."
          />
        </label>
        <InlineForm>
          <label htmlFor="agency">
            <p>Agencia</p>
            <input
              required
              type="text"
              name="agency"
              placeholder="Digite aqui..."
            />
          </label>
          <label htmlFor="account">
            <p>Conta</p>
            <input
              required
              type="text"
              name="account"
              placeholder="Digite aqui..."
            />
          </label>
        </InlineForm>
        <label htmlFor="pix">
          <p>Pix (não obrigatório)</p>
          <input type="text" name="pix" placeholder="Digite aqui..." />
        </label>
        <label htmlFor="pix">
          <p>Nome destinatário</p>
          <input
            required
            type="text"
            name="name"
            placeholder="Digite aqui..."
          />
        </label>
        <label htmlFor="cpf">
          <p>CPF</p>
          <input required type="text" name="cpf" placeholder="Digite aqui..." />
        </label>
      </Form>
      <ButtonContainer>
        <SendButton
          whileTap={{
            scale: 0.9,
          }}
          onClick={() => finish()}
          type="button"
        >
          Enviar

        </SendButton>
        <CancelButton
          whileTap={{
            scale: 0.9,
          }}
          type="button"
          onClick={() => navigate(-1)}
        >
          Cancelar
        </CancelButton>
      </ButtonContainer>
    </Container>
  );
};

export default Withdraw;
