import request from '../../request';
import { IPropertyManagerAPI } from './types';

type IGetPropertyManager = (id?: number, order?: string) => Promise<IPropertyManagerAPI[]>;
export const getPropertyManager: IGetPropertyManager = async (id, order) => {
  const endpoint = id ? `/property/manager/${id}` : '/property/manager/';

  const { data } = await request.get(endpoint, {
    params: {
      ordering: order,
    },
  });

  return data;
};
