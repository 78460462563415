import styled from 'styled-components';

export const Navbar = styled.nav``;

export const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Item = styled.li<{ active: boolean }>`
  width: 153px;
  padding: 8px 16px;
  cursor: pointer;
  list-style: none;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  border-bottom: 1px solid ${({ active, theme }) => (active
    ? theme.palette.blue._400.hex() : theme.palette.grey._425.hex())};
`;
