import React from 'react';

import {
  ItemContainer,
  MainTitle,
  OwnerName,
  TextContainer,
  PinsContainer,
  AnimationLoaded,
  Hour,
  Text,
} from './style';

import check from '../../../../assets/icons/controll/pin-check.svg';
import warmBed from '../../../../assets/icons/controll/pin-warmBed.svg';
import greenPin from '../../../../assets/icons/controll/pin1-green.svg';
import redPin from '../../../../assets/icons/controll/pin1-red.svg';

import { removeSecondsHourToShow } from '../../../../utils/Formatter';

interface Props {
  className?: string;
  dataCy?: string;
  checkin?: boolean;
  checkout?: boolean;
  // clearning?: boolean;
  checked?: boolean;
  disabled?: boolean;
  hot?: boolean;
  setOpen?: Function | any;
  variant?: string;
  talked?: boolean;
  hidden?: boolean;
  property: string;
  guest: string;
  hour: string;
  haveDataInCache?: boolean;
}

const GreenPin: React.FC<{ dataCy?: string }> = ({ dataCy }) => (
  <img
    data-cy={`${dataCy}_icon-msg-green`}
    src={greenPin}
    className="pin greenpin"
    alt="green pin"
  />
);

const RedPin: React.FC<{ dataCy?: string }> = ({ dataCy }) => (
  <img
    data-cy={`${dataCy}_icon-msg-red`}
    src={redPin}
    className="pin redpin"
    alt="red pin"
  />
);

const Check: React.FC<{ dataCy?: string }> = ({ dataCy }) => <img data-cy={`${dataCy}_icon-check-green`} src={check} className="pin check" alt="pin check" />;

const WarmBed = () => (
  <img src={warmBed} className="pin warmbed" alt="red pin" />
);

const GridCards = ({
  className,
  dataCy,
  // clearning,
  checkout,
  checkin,
  checked,
  hot,
  talked,
  disabled,
  setOpen,
  variant,
  hidden,
  property,
  guest,
  hour,
  haveDataInCache,
}: Props) => {
  const hourSecondsRemoved = removeSecondsHourToShow(hour);

  return (
    <>
      <ItemContainer
        className={className}
        data-cy={dataCy}
        // clearning={clearning || false}
        checkout={checkout || false}
        checkin={checkin || false}
        variant={variant || ''}
        disabled={disabled}
        onClick={setOpen}
        hidden={hidden}
      >
        <AnimationLoaded
          // clearning={clearning || false}
          checkout={checkout || false}
          checkin={checkin || false}
          variant={variant || ''}
          disabled={disabled}
          onClick={setOpen}
          hidden={hidden}
          haveDataInCache={haveDataInCache}
        />
        <AnimationLoaded
          // clearning={clearning || false}
          checkout={checkout || false}
          checkin={checkin || false}
          haveDataInCache={haveDataInCache}
        />
        <TextContainer haveDataInCache={haveDataInCache}>
          <MainTitle haveDataInCache={haveDataInCache}>{property}</MainTitle>
          <OwnerName>{guest}</OwnerName>
          {hour && <Hour>{hourSecondsRemoved}</Hour>}
          {/* {clearning && <Text>Limpeza</Text>} */}
          {checkin && <Text>Check-in</Text>}
          {checkout && <Text>Check-out</Text>}

        </TextContainer>
        <PinsContainer checked={checked} data-cy="pins" haveDataInCache={haveDataInCache}>
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'end', gap: '8px', width: '100%',
          }}
          >
            {checked ? (
              <Check dataCy={dataCy} />
            ) : (
              <>
                {talked && <GreenPin dataCy={dataCy} />}

                {!talked
              // && !clearning
              && <RedPin dataCy={dataCy} />}
                {hot && <WarmBed />}
              </>
            )}
          </div>
        </PinsContainer>
      </ItemContainer>
    </>
  );
};

GridCards.defaultProps = {
  className: '',
  dataCy: '',
  checkin: false,
  checkout: false,
  // clearning: false,
  checked: false,
  hot: false,
  disabled: false,
  setOpen: () => {},
  variant: '',
  talked: false,
  hidden: false,
  haveDataInCache: false,
};

export default GridCards;
