import styled from 'styled-components';
import Slider from '@mui/material/Slider';

export const AirbnbSlider = styled(Slider)`
  span {

    &.MuiSlider-rail{
    color: #62656F;
    height: 0.880989px;
    }
  }

  span {
    &.MuiSlider-thumbColorPrimary {
      height: 15px !important;
      width: 15px !important;
      background-color: #fff !important;
      border: 4px solid ${({ theme }) => theme.palette.blue._400.hex()} !important;
      box-shadow: #ebebeb 0 2px 2px !important;

      &:focus &:hover &:active {
        box-shadow: inherit;
      }
  }
  &.MuiSlider-track{
      // display: inline-block !important;
      height: 1;
      width: 1;
      background-color: transparent;
      margin-left: 1px;
      margin-right: 1px;
    }
}

  .rail {
    height: 8px;
    border-radius: 4px;
  }
`;

export const ContentLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LabelText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #151B26;
`;
