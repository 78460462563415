import React from 'react';
import { motion } from 'framer-motion';

import { Container, Content } from './style';
import { callWhatsAppNumber } from '../utils';

import { analyticsEvent } from '../utils/analytics';
import { useUser } from '../../../context/UserContext';
import { usePartners } from '../../../context/Partners/Partner/PartnerContext';

const Icon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0735 13.2533C15.8026 13.1185 14.4742 12.4688 14.2269 12.3783C13.9795 12.2887 13.7995 12.2443 13.6186 12.514C13.4385 12.7818 12.9212 13.3881 12.7639 13.5673C12.6057 13.7474 12.4484 13.7691 12.1783 13.6352C11.9083 13.4994 11.0372 13.2162 10.0052 12.3004C9.20236 11.5874 8.65954 10.7069 8.50224 10.4373C8.34494 10.1685 8.48497 10.0228 8.62044 9.88892C8.74228 9.76857 8.89049 9.57492 9.02597 9.41838C9.16144 9.26092 9.206 9.14872 9.29601 8.96865C9.38694 8.78948 9.34147 8.63293 9.27328 8.4981C9.206 8.36327 8.66591 7.03942 8.44041 6.50101C8.22128 5.97708 7.99852 6.04857 7.83304 6.03952C7.67483 6.03228 7.4948 6.03047 7.31477 6.03047C7.13474 6.03047 6.84196 6.09743 6.59464 6.36709C6.34642 6.63584 5.64903 7.28646 5.64903 8.61031C5.64903 9.93326 6.61647 11.2119 6.75194 11.3919C6.88742 11.5711 8.65681 14.2876 11.3673 15.4522C12.0128 15.7291 12.5157 15.8947 12.9075 16.0177C13.5549 16.2231 14.1441 16.1942 14.6096 16.1245C15.1279 16.0476 16.2081 15.4739 16.4336 14.8459C16.6582 14.2179 16.6582 13.6795 16.5909 13.5673C16.5236 13.4551 16.3436 13.3881 16.0726 13.2533H16.0735ZM11.1436 19.9522H11.14C9.53008 19.9525 7.94976 19.5218 6.56464 18.7053L6.23731 18.5116L2.83491 19.4002L3.74325 16.0992L3.52957 15.7607C2.62955 14.335 2.15324 12.6848 2.1557 11.001C2.15752 6.06938 6.18912 2.0571 11.1472 2.0571C13.5476 2.0571 15.8044 2.98913 17.501 4.67947C18.3379 5.50887 19.0012 6.49519 19.4525 7.58136C19.9039 8.66752 20.1344 9.83198 20.1306 11.0074C20.1288 15.939 16.0972 19.9522 11.1436 19.9522ZM18.7922 3.39543C17.7904 2.39184 16.5984 1.59609 15.2853 1.0543C13.9722 0.512507 12.5641 0.235447 11.1427 0.239173C5.18349 0.239173 0.331757 5.06676 0.329938 11.0001C0.327177 12.8884 0.824909 14.744 1.77291 16.3797L0.239014 21.9565L5.9709 20.4598C7.55651 21.3196 9.33338 21.7701 11.1391 21.7701H11.1436C17.1028 21.7701 21.9545 16.9425 21.9564 11.0083C21.9607 9.59417 21.6834 8.19324 21.1402 6.88652C20.5971 5.5798 19.799 4.39322 18.7922 3.39543Z"
      fill="white"
    />
  </svg>
);

interface IWhatsAppProps {
  onClick?: () => void;
}

const WhatsApp = ({ onClick }: IWhatsAppProps) => {
  const { userInformation } = useUser();
  const { resume, hideElements } = usePartners();

  const name = resume?.partner.attendant_name;
  const phone = resume?.partner.attendant_phone_number;

  const handleClick = () => {
    analyticsEvent({
      userInformation,
      name: 'Clicou no botão para envio de mensagens no whatsapp',
    });

    if (onClick) {
      onClick();
    }

    callWhatsAppNumber(phone, name);
  };

  if (hideElements?.whatsapp?.is) {
    return null;
  }

  return (
    <Container
      onClick={handleClick}
      initial={{
        x: 200,
      }}
      animate={{
        x: 150,
      }}
      transition={{
        delay: 0.5,
      }}
    >
      <Content
        whileTap={{
          x: -30,
        }}
      >
        <motion.div
          initial={{
            y: 100,
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            delay: 1,
          }}
        >
          <Icon />
        </motion.div>
      </Content>
    </Container>
  );
};

WhatsApp.defaultProps = {
  onClick: undefined,
};

export default WhatsApp;
