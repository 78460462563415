import React, { FC, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';
import {
  FormButton,
} from '../../..';

import iconClose from '../../../../assets/icons/generals/iconClose.svg';
import newExpense from '../../../../assets/icons/expense/newExpense.svg';

import {
  Container,
  Content,
  Backdrop,
  CloseButton,
  HeaderContainer,
  BodyContainer,
  ButtonsContainer,
  InputModal,
  ButtonClear,
  Buttons,
  DateContainer,
  ErrorIcon,
  InputHideModal,
  Line,
} from './styles';

import SelectModal from '../../../SelectModal/SelectModal';
import DatePickerRange from '../../../DatePickerRange';

import { useToast } from '../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../utils/Messages';
import { useLoader } from '../../../../context/LoaderContext';

import { getReservationsReport } from '../../../../services/FinancialClose/request';
import { ReservationReportProps } from '../../../../services/FinancialClose/types';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import { SelectProps } from '../../../DropdownAutocomplete/DropdownAutocomplete';
import { compareList } from '../../../../utils/Sorting';
import { getPropertiesDetails } from '../../../../services/Property/request';
import { PropertyDetails as PropertyDetailsType } from '../../../../services/Property/types';

interface ModalProps {
  close: () => void;
}

interface DateRangeProps {
  startDate: Date;
  endDate: Date;
}

const initialDateRangeValues = {
  startDate: new Date(),
  endDate: new Date(),
};

const ExportReservationsModal: FC<ModalProps> = ({ close }) => {
  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();
  const { setLoad } = useLoader();

  const [dateRange, setDateRange] = useState<DateRangeProps>(initialDateRangeValues);
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [inputDate, setInputDate] = useState<string>('Clique para selecionar o período de datas');
  const [showError, setShowError] = useState<Boolean>(false);
  const [properties, setProperties] = useState<PropertyDetailsType[]>([]);

  const today = new Date();
  const tomorrow = new Date(today);

  const handleClickOpenDateTransfer = () => {
    setOpenDate(true);
  };

  const handleOutDateTransfer = (event: any) => {
    if (event.target.id === 'inputDates') setOpenDate(false);
  };

  const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');

  const formik = useFormik({
    initialValues: {
      startDateTransfer: today,
      endDateTransfer: tomorrow,
      property_id: 0,
    },
    onSubmit: () => {},
  });

  const handleGetExportReservations = async () => {
    try {
      setLoad(true);
      const params: ReservationReportProps = {
        start_date: formatDate(dateRange.startDate),
        end_date: formatDate(dateRange.endDate),
        property: formik.values.property_id,
      };
      if (formik.values.property_id === 0) {
        delete params.property;
      }
      await getReservationsReport(params);
      setLoad(false);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoad(false);
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  };

  const handleClearDateFilter = (startDate: string, endDate: string) => {
    if (formik) {
      formik.setFieldValue(startDate, today);
      formik.setFieldTouched(
        startDate, true, false,
      );
      formik.setFieldValue(endDate, tomorrow);
      formik.setFieldTouched(
        endDate, true, false,
      );
    }
    setInputDate('Clique para selecionar o período de datas');
    setShowError(false);
  };

  const checkDateFilter = (startDate: Date, endDate: Date) => moment(startDate).format('DD/MM/YYYY').toString() === 'Data inválida' || moment(endDate).format('DD/MM/YYYY').toString() === 'Data inválida' || moment(endDate) <= moment(startDate);
  const handleAppliedDateFilter = () => {
    const isInvalidDate = checkDateFilter(formik.values.startDateTransfer,
      formik.values.endDateTransfer);
    if (isInvalidDate) {
      setOpenDate(true);
      setShowError(true);
      return false;
    }
    setDateRange({
      startDate: formik.values.startDateTransfer,
      endDate: formik.values.endDateTransfer,
    });
    setInputDate(`${moment(formik.values.startDateTransfer).format('DD/MM/YYYY')} à ${moment(formik.values.endDateTransfer).format('DD/MM/YYYY')}`);
    setOpenDate(false);
    setShowError(false);
    return true;
  };

  useEffect(() => {
    async function getProperty() {
      const propertiesResult = await getPropertiesDetails();
      setProperties(propertiesResult);
    }
    getProperty();
  }, []);

  const handleGetProperty = (): SelectProps[] => properties.map((property) => (
    { optionText: `${property.code}`, optionValue: `${property.id}` }
  )).sort((a, b) => compareList(a.optionText, b.optionText));

  return (
    <>
      <Backdrop
        onClick={() => {
          close();
        }}
      >
        <Container
          openDateSelector={!openDate}
          onClick={(e) => e.stopPropagation()}
        >
          <Content>
            <CloseButton onClick={close}>
              <img src={iconClose} alt="botão de fechar o modal" />
            </CloseButton>
            <HeaderContainer>
              <img src={newExpense} alt="Ícone de download da reserva" />
              <h1 data-cy="title-export-reservations-modal">Selecione o intervalo de referência que deseja exportar as reservas</h1>
            </HeaderContainer>
            <BodyContainer>
              <form>

                <SelectModal
                  id="dates"
                  dataCy="export-reservations-modal-dates"
                  onClick={handleClickOpenDateTransfer}
                  value={inputDate}
                />

                { openDate && (
                  <>
                    <InputHideModal id="inputDates" onClick={handleOutDateTransfer} />
                    <InputModal className="input-modal-style">
                      <DateContainer onClick={(e) => e.stopPropagation()}>
                        <DatePickerRange
                          formik={formik}
                          calendars={2}
                          data-ci="date-picker-range-export-reservations"
                          id1="startDateTransfer"
                          disableCloseOnSelect={false}
                          id2="endDateTransfer"
                          minDate={new Date('2000-01-02')}
                          hasInitialDates
                        />
                      </DateContainer>
                      <Line />
                      <Buttons>
                        <ButtonClear onClick={() => handleClearDateFilter('startDateTransfer', 'endDateTransfer')}>
                          Limpar
                        </ButtonClear>
                        <FormButton type="button" variant="outlined" onClick={() => handleAppliedDateFilter()}>Aplicar</FormButton>
                      </Buttons>
                      { showError && (
                      <p className="messageError">
                        <ErrorIcon />
                        A data final deve ser maior do que a inicial!
                      </p>
                      )}
                    </InputModal>
                  </>
                )}
                <div className="property">
                  <DropdownAutocomplete
                    dataCy="select-expense-properties-list"
                    label="Imóvel: (Pesquise pelo código do imóvel)"
                    id="property_id"
                    options={handleGetProperty()}
                    formik={formik}
                    placeholder="Digite aqui"
                  />
                </div>
                {
                  !openDate && (
                    <ButtonsContainer>
                      <FormButton dataCy="btn-cancel-reservations" variant="outlined" onClick={close}>
                        Cancelar
                      </FormButton>
                      <FormButton dataCy="btn-download-reservations" type="button" onClick={() => handleGetExportReservations()}>Baixar</FormButton>
                    </ButtonsContainer>
                  )
                }
              </form>
            </BodyContainer>
          </Content>
        </Container>
      </Backdrop>
    </>
  );
};

export default ExportReservationsModal;
