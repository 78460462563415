import styled from 'styled-components';
import { MAX_SIZE_MOBILE, buttonsWidthDesktop, inputsGap } from './utils';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid  ${({ theme }) => theme.palette.grey._425.hex()};
  margin-bottom: 140px;
  border-radius: 16px;
  h1,
  h2,
  p,
  span,
  strong,
  button {
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
  button {
    cursor: pointer;
  }
  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: 700;
    font-size: 2rem;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    margin-bottom: 100px;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  padding: 0 16px 24px 16px;
  
  &.guest-section {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()} !important;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  display: flex;  
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 0 0 0;

  &.header-guestlist {
    padding: 24px 0 16px 0;
  }
  
  @media (min-width: ${MAX_SIZE_MOBILE + buttonsWidthDesktop + inputsGap + 1}px) {
    button {
      display: none;
    }
  }
`;

export const Title = styled.h5`
  width: 100%;
  user-select: none;
  font-size: 16px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
`;
