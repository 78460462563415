import styled from 'styled-components';

export const WrapperContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  background: #ebebf5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 900px) {
    height: calc(100% - 100px);
  }
`;

export const InitialText = styled.h1`
  width: 300%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.grey._550.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-size: 50px;
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
 `;

export const Container = styled.div`
  grid-area: properties;

  width: calc(100% - 100px);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 5px;

  align-items: start;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  scrollbar-color: ${({ theme }) => theme.palette.orange.main.hex()} ${({ theme }) => theme.palette.grey._400.hex()};

  @media(max-width: 500px) {
    width: 100%;
  }

  @media(min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const FilterBarContainer = styled.div`
  grid-area: filterbar;
  width: 100%;
`;

export const SecondBar = styled.div`
  grid-area: header;

  width: calc(100% - 100px);

  display: flex;
  justify-content: space-between;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    height: 30px;
    padding-top: 13px;
  }

  p {
    color: ${({ theme }) => theme.palette.grey._720.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    height: 30px;
    padding-top: 13px;
  }
`;

export const AllProperties = styled.div`
    width: 50%;
    padding-bottom: 27px;

    @media (max-width: 500px) {

      h1 {
        font-size: 1.2rem;
      }
    }
`;

export const Order = styled.div`
    width: 100px;
`;
