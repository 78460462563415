import React, { useEffect } from 'react';
import { Container } from './styles';
import GuestDamage from '../../components/GuestDamage/GuestDamage';
import { GuestDamageNegotiationProvider } from '../../context/GuestDamage/GuestDamageNegotiationsContext';
import { ReservationsProvider } from '../../context/GuestDamage/ReservationsContext';
import { GuestDamageProvider } from '../../context/GuestDamage/GuestDamageContext';
import { GuestDamageStepProvider } from '../../context/GuestDamage/GuestDamageStepContext';

const GuestDamagePage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Danos de hóspede';
  }, []);

  return (
    <Container>
      <GuestDamageNegotiationProvider>
        <ReservationsProvider>
          <GuestDamageProvider>
            <GuestDamageStepProvider>
              <GuestDamage />
            </GuestDamageStepProvider>
          </GuestDamageProvider>
        </ReservationsProvider>
      </GuestDamageNegotiationProvider>
    </Container>
  );
};
export default GuestDamagePage;
