import styled from 'styled-components';
import { ArrowDropDown } from '@mui/icons-material';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 30px;

  strong {
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 30px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 2rem
`;

export const SecundaryTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 700;
  font-size: 20px;
  margin-top: 26px;
  margin-bottom: 12px;
`;

export const QuantityProperties = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Ordination = styled.div`
  width: 120px;
`;

export const ContentProperties = styled.div`
  max-height: 50vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  overflow: auto;
`;

export const Cart = styled.div`
  width: 64px;
  height: 61px;
  position: fixed;
  z-index: 10;
  right: 0;
  top: 0;
  margin-top: 2rem;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.green.main.hex()};
  cursor: pointer;

  -webkit-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
  -moz-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
  box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);

  svg {
    fill: ${({ theme }) => theme.palette.white.main.hex()};
  }

  :hover {
    background-color: ${({ theme }) => theme.palette.green._250.hex()};
  }
`;

export const Quantity = styled.p`
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 400;
  font-size: 14px;
`;

export const NotFound = styled.div`
  width: 300%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.grey._550.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  p {
    font-size: 50px;
  }
`;

export const InicialText = styled.h1`
  width: 300%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.grey._550.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-size: 50px;
  text-align: center;
`;

export const SecondaryTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircularDiv = styled.div`
  margin-top: 20px;
  margin-left: 10px;
`;

export const ContainerLoadMoreDatas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentLoadMoreDatas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 20px;
  p {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
    font-weight: bold;
    font-size: 14px;
  }
`;

export const ArrowUpIcon = styled(ArrowDropDown)`
  fill:  ${({ theme }) => theme.palette.blue._400.hex()} !important;
  width: 30px !important;
  height: 30px !important;
  transform: rotate(180deg);
`;

export const ArrowDownIcon = styled(ArrowDropDown)`
  fill:  ${({ theme }) => theme.palette.blue._400.hex()} !important;
  width: 30px !important;
  height: 30px !important;
  transform: rotate(0deg);
`;
