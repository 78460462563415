import styled, { css } from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../hooks/useMobile/useMobile';

export const Container = styled.div`
  padding: 34px 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    padding: 34px 10rem;
  }
`;

export const Title = styled.div`
  color: var(--rosa, #fc605b);
  text-align: center;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Text = styled.h1<{
  desktopMaxWidth?: string;
}>`
  text-align: center;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    ${({ desktopMaxWidth }) => !desktopMaxWidth
      && css`
        max-width: 300px;
      `}

      ${({ desktopMaxWidth }) => desktopMaxWidth
      && css`
        max-width: ${desktopMaxWidth};
      `}
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Description = styled.p`
  color: #fff;
  text-align: center;
  font-family: Asap;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
