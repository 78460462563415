import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.palette.white._250.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 2rem;
  
  @media (max-width: 900px) {
    height: calc(100vh - 160px);
  }
`;
