import request from '../../request';

import {
  OwnerExtractParams,
  OwnerExtractResponseProps,
} from './types';

import { formatOwnerExtractData } from './utils';

export const getOwnerExtract = async (params: OwnerExtractParams) => {
  const { data } = await request.get<OwnerExtractResponseProps>('/proper_pay/owner/financial/statement/monthly', {
    params: {
      ...params,
    },
  });

  const financialData = formatOwnerExtractData(data);

  return financialData;
};
