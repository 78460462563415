import React from 'react';

import { X } from 'react-feather';
import {
  HeaderBox,
  Title,
  Subtitle,
  HeaderContainer,
  TitleContainer,
  HorizontalRow,
  CloseButtonContainer,
  DateContainer,
  DateGrid,
} from './styles';

import DatePickerRange from '../../../../DatePickerRange';
import FormButton from '../../../../FormButton';
import { Color } from '../../../../FormButton/types';

interface Props {
  onClose?: Function;
  handleClick?: Function;
  title?: string;
  subtitle?: string;
  titleButton?: string;
  customColorButton?: Color;
  formik?: any;
  calendars?: 1 | 2 | undefined;
  propertyId?: number;
  calendarHasMarkers?: boolean;
  dataCy?: string;
}

const ModalHeader: React.FC<Props> = ({
  onClose = () => {},
  handleClick = () => {},
  title = '',
  subtitle = '',
  titleButton = '',
  customColorButton = 'black',
  calendars = 2,
  formik,
  propertyId,
  calendarHasMarkers = false,
  dataCy = 'modal-1',
}) => {
  const handle = () => {
    handleClick();
  };

  return (
    <HeaderBox>
      <HeaderContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TitleContainer>
        <CloseButtonContainer
          data-cy={`${dataCy}-btn-close-modal`}
          onClick={() => onClose()}
        >
          <X
            size={20}
          />
          <strong>Fechar</strong>
        </CloseButtonContainer>
      </HeaderContainer>
      <HorizontalRow />

      <DateContainer>
        <DatePickerRange
          calendars={calendars}
          formik={formik}
          id1="startDate"
          id2="endDate"
          minDate={new Date('2000-01-02')}
          propertyId={propertyId}
          calendarHasMarkers={calendarHasMarkers}
          disableInput
          hasInitialDates
          disableAutoMonthSwitching
          dataCyTextField1={`${dataCy}-datepicker-1`}
          dataCyTextField2={`${dataCy}-datepicker-2`}
        />

        {formik?.values?.endDate === null
          ? (
            <DateGrid buttonIsBlocked>
              <FormButton
                type="button"
                customColor="grey"
                variant="outlined"
                onClick={() => {}}
              >
                {titleButton}
              </FormButton>
            </DateGrid>
          ) : (
            <DateGrid>
              <FormButton
                dataCy={`${dataCy}-btn-${titleButton === 'Buscar' ? 'search' : 'block'}`}
                type="submit"
                customColor={customColorButton}
                onClick={() => handle()}
              >
                {titleButton}
              </FormButton>
            </DateGrid>
          )}

      </DateContainer>
    </HeaderBox>
  );
};

export default ModalHeader;
