import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import { getConciliations, getReservationConciliation } from '../../../services/Conciliation/request';
import { FiltersType } from '../../../services/Conciliation/types';
import { getOtas, getPropertiesDetails } from '../../../services/Property/request';
import { PropertyDetails, RequestOta } from '../../../services/Property/types';
import { formatedDateOfRequest } from '../../../utils/Formatter';
import AutoComplete, { SelectProps } from '../../Autocomplete/Autocomplete';
import CustomSlider from '../../CustomSlider';
import FormButton from '../../FormButton';
import RadioButton from '../../RadioButton';
import SimpleSelect from '../../SimpleSelect';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import TextField from '../../TextField';
import SelectPeriod from './SelectPeriod';
import {
  FormContent, TitleFilter, Text, Row, ContainerButtons,
} from './styles';

/*
const options = [
  {
    id: '1',
    description: 'Airbnb',
  },
];
*/
interface Props {
  onClose: () => void;
  conciliation: boolean;
}

const FormReservationsFilters = ({ onClose, conciliation }: Props) : JSX.Element => {
  const [propertiesList, setPropertiesList] = useState<Array<PropertyDetails>>([]);
  const [otas, setOtas] = useState<Array<RequestOta>>([]);
  const { setListReservation, setListConciliations } = useConciliation();

  const formik = useFormik({
    initialValues: {
      propertyId: '',
      ota: '',
      startDate: '',
      endDate: '',
      dateOption: false,
      GuestName: '',
      reservationValueMin: 0,
      reservationValueMax: 0,
      paymentValueMin: 0,
      paymentValueMax: 0,
    },
    onSubmit: async (values) => {
      const filterArray = [];
      if (values.propertyId) {
        filterArray.push({ name: 'property_id', value: values.propertyId } as FiltersType);
      }
      if (values.ota) {
        filterArray.push({ name: 'ota_id', value: values.ota } as FiltersType);
      }

      if (values.GuestName) {
        filterArray.push({ name: 'guest_name', value: values.GuestName } as FiltersType);
      }
      if (values.reservationValueMin) {
        filterArray.push({ name: 'reservation_initial_value', value: values.reservationValueMin } as unknown as FiltersType);
      }
      if (values.reservationValueMax) {
        filterArray.push({ name: 'reservation_final_value', value: values.reservationValueMax } as unknown as FiltersType);
      }
      if (values.dateOption.toString() === 'true') {
        if (values.startDate && values.endDate) {
          filterArray.push({ name: 'checkin_initial_date', value: formatedDateOfRequest(values.startDate) } as FiltersType);
          filterArray.push({ name: 'checkin_final_date', value: formatedDateOfRequest(values.endDate) } as FiltersType);
        }
      }
      if (values.dateOption.toString() !== 'true') {
        if (values.startDate && values.endDate) {
          filterArray.push({ name: 'checkout_initial_date', value: formatedDateOfRequest(values.startDate) } as FiltersType);
          filterArray.push({ name: 'checkout_final_date', value: formatedDateOfRequest(values.endDate) } as FiltersType);
        }
      }

      if (!conciliation) {
        if (values.paymentValueMin) {
          filterArray.push({ name: 'payment_initial_value', value: values.paymentValueMin } as unknown as FiltersType);
        }
        if (values.paymentValueMax) {
          filterArray.push({ name: 'Payment Final Value', value: values.paymentValueMax } as unknown as FiltersType);
        }
      }

      if (conciliation) {
        const results = await getReservationConciliation(filterArray);
        setListReservation(results);
      } else {
        const results = await getConciliations(filterArray);
        setListConciliations(results);
      }
      onClose();
    },
  });

  useEffect(() => {
    async function getallProperties() {
      const allProperties = await getPropertiesDetails();
      setPropertiesList(allProperties);
    }
    getallProperties();
  }, []);

  useEffect(() => {
    async function getAllOtas() {
      const result = await getOtas();
      setOtas(result);
    }
    getAllOtas();
  }, []);

  const handleGetProperty = async ():
  Promise<SelectProps[]> => propertiesList.map((propertyMap) => (
    {
      optionText: `${propertyMap.code}`,
      optionValue: propertyMap?.id?.toString() || '',
    }
  ));

  return (
    <FormContent onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <TitleFilter>
        <Text>Hóspede</Text>
      </TitleFilter>
      <Row>
        <TextField
          id="GuestName"
          label="Nome"
          placeholder="Digite o nome do hóspede"
          formik={formik}
        />
      </Row>
      <TitleFilter>
        <Text>Datas check-in/Check-out</Text>
      </TitleFilter>
      <Row>
        <RadioButton
          id="dateOption"
          labelPlacement="end"
          formLabel=""
          formik={formik}
          options={[
            { value: true, label: 'Check-in' },
            { value: false, label: 'Check-out' },
          ]}
        />
        <SelectPeriod formik={formik} />
      </Row>
      <TitleFilter>
        <Text>Imóvel</Text>
      </TitleFilter>
      <Row>
        <AutoComplete
          id="propertyId"
          asyncOptions={handleGetProperty}
          formik={formik}
          placeholder="Buscar imóvel"
        />
      </Row>
      <TitleFilter>
        <Text>OTA</Text>
      </TitleFilter>
      <Row>
        <SimpleSelect
          id="ota"
          placeholder="Selecione"
          formik={formik}
          options={
          (otas || []).map<SelectOption>(({
            id,
            name,
          }) => ({ value: id, valueLabel: name }))
        }
        />
      </Row>
      {/*
      <TitleFilter>
        <Text>Plataforma de pagamento</Text>
      </TitleFilter>
      <Row>
        <SimpleSelect
          id="indication"
          dataCy="indication"
          placeholder="Selecione"
          options={
          (options || []).map<SelectOption>(({
            id,
            description,
          }) => ({ value: id, valueLabel: description }))
        }
        />
      </Row>
      */}
      <TitleFilter>
        <Text>Valor da reserva</Text>
      </TitleFilter>
      <Row>
        <CustomSlider id="reservationValue" formik={formik} valueMax={5000} valueMin={300} />
      </Row>
      {!conciliation && (
        <>
          <TitleFilter>
            <Text>Valor pago</Text>
          </TitleFilter>
          <Row>
            <CustomSlider id="paymentValue" formik={formik} valueMax={5000} valueMin={300} />
          </Row>
        </>
      )}
      <ContainerButtons>
        <FormButton variant="outlined" type="button" onClick={onClose}>Cancelar</FormButton>
        <FormButton type="submit">Aplicar</FormButton>
      </ContainerButtons>
    </FormContent>
  );
};

export default FormReservationsFilters;
