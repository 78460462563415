import {
  EnumType, IIndicationsBase, IIndicationsStatus, IInvestmentIndication,
} from '../../../../../../types';

export const handleMapIndicate = (
  filter: string | undefined,
  activeFilter: IIndicationsStatus | undefined,
  array: IIndicationsBase[],
) => array.filter((item) => {
  function translateFilter() {
    switch (filter) {
      case 'todos':
        return 'all';
      case 'locacao':
        return EnumType.location;
      case 'terreno':
        return EnumType.terrain;
      case 'spot':
        return EnumType.spot;
      default:
        return 'all';
    }
  }
  if (activeFilter) {
    if (item?.status?.toLowerCase() !== activeFilter.toLowerCase()) {
      return false;
    }
  }
  if (translateFilter() === 'all') {
    return item;
  }
  return item.type === translateFilter();
});

export const handleMapIndicateInvestment = (
  filter: string | undefined,
  activeFilter: IIndicationsStatus | undefined,
  array: IInvestmentIndication[],
) => array.filter((item) => {
  function translateFilter() {
    switch (filter) {
      case 'todos':
        return 'all';
      case 'locacao':
        return EnumType.location;
      case 'terreno':
        return EnumType.terrain;
      case 'spot':
        return EnumType.spot;
      default:
        return 'all';
    }
  }

  if (activeFilter) {
    if (item?.status?.toLowerCase() !== activeFilter?.toLowerCase()) {
      return false;
    }
  }
  if (translateFilter() === 'all') {
    return item;
  }

  return item.type === translateFilter();
});
