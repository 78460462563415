import React from 'react';

import {
  Content,
  WaveText,
  WaveTexts,
  Container,
  Description,
  WaveContent,
  WaveContainer,
  HeadContainer,
} from './style';

import WaveIcon from './WaveIcon';
import { Title } from '../../common/style';

const AdvantagesBeingPartner = () => (
  <Container>
    <Content>
      <HeadContainer>
        <Title>Vantagens de ser um parceiro Seazone</Title>
        <Description>Indique imóveis e garanta uma renda extra.</Description>
      </HeadContainer>

      <WaveContainer>
        <WaveContent
          initial={{ x: 500, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.7,
              ease: [1, -0.05, 0.66, 1],
            },
          }}
        >
          <WaveIcon />
        </WaveContent>

        <WaveTexts>
          <WaveText>
            <span>+ de 500 mil</span>
            {' '}
            pagos em comissão
          </WaveText>

          <WaveText>
            <span>+ de 500</span>
            {' '}
            parceiros
          </WaveText>

          <WaveText>
            <span>+ de 1900</span>
            {' '}
            imóveis indicados
          </WaveText>

          <WaveText>
            <span>+ de 200</span>
            {' '}
            vendas realizadas por indicação
          </WaveText>
        </WaveTexts>
      </WaveContainer>

    </Content>
  </Container>
);

export default AdvantagesBeingPartner;
