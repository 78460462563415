import React from 'react';

import { FilePreviewShimmer } from '../style';
import { FileProps } from '../../../../../../../../context/FileContext/types';

import {
  FileNameShimmer,
  FileSeparatorShimmer,
  FilesContainerShimmer,
  FileDeleteIconContainerShimmer,
} from './style';

interface IShimmer {
  documentsArray: FileProps[];
}

const Shimmer = ({ documentsArray }: IShimmer) => {
  if (documentsArray.length > 0) {
    return (
      <>
        {documentsArray.map(() => (
          <FilesContainerShimmer>
            <FilePreviewShimmer />
            <FileSeparatorShimmer />
            <FileNameShimmer />
            <FileSeparatorShimmer />
            <FileDeleteIconContainerShimmer />
          </FilesContainerShimmer>
        ))}
      </>
    );
  }

  return (
    <FilesContainerShimmer>
      <FilePreviewShimmer />
      <FileSeparatorShimmer />
      <FileNameShimmer />
      <FileSeparatorShimmer />
      <FileDeleteIconContainerShimmer />
    </FilesContainerShimmer>
  );
};

export default Shimmer;
