import { LandFormProps, LocationFormProps, SpotFormProps } from './types';

export const initialValuesLandForm: LandFormProps = {
  state: '',
  city: '',
  neighborhood: '',
  address: '',
  coordinates: '',
  allotmentArea: '',
  allotmentDimensions: '',
  allotmentWidth: '',
  allotmentLenght: '',
  allotmentValue: '',
  documentLink: '',
  photoLink: '',
  comment: '',
  allowBarter: false,
};

export const initialValuesLocationForm: LocationFormProps = {
  name: '',
  phone: '',
  state: '',
  city: '',
  street: '',
  number: '',
  neighborhood: '',
  complement: '',
  propertyType: '',
  comment: '',
  b2bCondominium: '',
  b2bCondominiumID: 0,
};

export const initialValuesSpotForm: SpotFormProps = {
  enterprise: '',
  unit: '',
  name: '',
  email: '',
  phone: '',
  comment: '',
};
