import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { createContext, useContextSelector } from 'use-context-selector';
import {
  Mode, IsMode, GetMode, IViewMode,
} from './type';
import { getUserDetailsById } from '../../services/InsertData/request';

import Message from './Message';

const KEY = 'g_mode';

export const ViewModeContext = createContext<IViewMode>({} as IViewMode);

export const ViewModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [mode, setMode] = useState<Mode>({} as Mode);

  const { search } = window.location;
  const params = new URLSearchParams(search);

  const getUserInfo = async (id: Mode['user_id']) => {
    const response = await getUserDetailsById(id);
    setMode((prev) => ({
      ...prev,
      is: prev.is,
      user_info: response,
      user_id: prev.user_id,
    }));
  };

  const getMode = useCallback(async () => {
    const isModeExistInQueryParams = params.get('mode');
    const userIdExistInQueryParams = params.get('user_id');

    if (isModeExistInQueryParams && userIdExistInQueryParams) {
      const formatUserId = parseInt(userIdExistInQueryParams, 10);
      const response = getUserInfo(formatUserId);

      const newMode = {
        is: isModeExistInQueryParams as IsMode,
        user_id: formatUserId,
        user_info: response,
      };

      return newMode;
    }
    return null;
  }, []);

  const exitViewMode = useCallback(() => {
    localStorage.removeItem(KEY);
    params.delete('mode');
    params.delete('user_id');
    window.history.replaceState(
      {}, '', '/painel-perspectiva-do-proprietario',
    );
    window.location.reload();
  }, []);

  useEffect(() => {
    const handleGetMe = async () => {
      const validateMode: any = await getMode();
      setMode(validateMode);

      if (validateMode?.user_id) {
        getUserInfo(validateMode.user_id);
      }
    };

    handleGetMe();
  }, []);

  const value = {
    mode,
    exitViewMode,
  };

  return (
    <ViewModeContext.Provider value={value}>
      <Message />

      {children}
    </ViewModeContext.Provider>
  );
};

export const useViewMode = () => {
  const mode = useContextSelector(ViewModeContext, (context) => context.mode);
  const exitViewMode = useContextSelector(ViewModeContext,
    (context) => context.exitViewMode);

  const viewModeInfo = useContextSelector(ViewModeContext,
    (context) => context.mode?.user_info);

  const isViewMode = useMemo(() => mode?.is === 'view', [mode]);

  return {
    mode,
    isViewMode,
    exitViewMode,
    viewModeInfo,
  };
};
