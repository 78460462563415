import React from 'react';
import { usePartners } from '../../../../context/Partners/Partner/PartnerContext';
import Icon from './Icon';

const Burger = () => {
  const { setShowBurgerMenu } = usePartners();

  const handleOpenMenu = () => {
    setShowBurgerMenu(true);
  };

  return (
    <Icon onClick={() => handleOpenMenu()} />
  );
};
export default Burger;
