/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { EditOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import deleteIcon from '../../../assets/icons/generals/bashRed.svg';
// import WarningIcon from '../../assets/icons/generals/alert.svg';
import { Bank } from '../../../services/Bank/types';
import { BankDetails } from '../../../services/Owner/types';
import { cnpj, cpf } from '../../../utils/InputMask/Number';
import ModalConfirmDeleteBankAccount from '../ModalConfirmDeleteBankAccount';
import { useViewMode } from '../../../context/ViewMode/ViewMode';

import {
  Container,
  Divider,
  Line,
  Options,
  Row,
  TagMain,
  Warning,
} from './styles';

interface PropsBankAccount {
  accountMain: boolean;
  dataAccountBank: BankDetails;
  handleOpenEditModal: (a: BankDetails) => void;
  updateListBank: () => void;
  // eslint-disable-next-line react/require-default-props
  setIsEditBank?: (a: boolean) => void;
}

const BankAccountComponent = ({
  accountMain,
  dataAccountBank,
  handleOpenEditModal,
  updateListBank,
  setIsEditBank,
}: PropsBankAccount): JSX.Element => {
  const { isViewMode } = useViewMode();

  const [openModalDeleteBankAccount, setOpenModalDeleteBankAccount] = useState<boolean>(false);

  function handleEdit() {
    handleOpenEditModal(dataAccountBank);
    if (setIsEditBank) {
      setIsEditBank(true);
    }
  }
  return (
    <>
      <Container data-cy="list-banks" isMain={accountMain}>
        <Options isMain={accountMain}>
          {accountMain && <TagMain>Principal</TagMain>}
          {!isViewMode && (
            <>
              <EditOutlined onClick={() => handleEdit()} />
              {!dataAccountBank.is_default && (
                <img
                  src={deleteIcon}
                  alt="icone de deletar"
                  onKeyDown={() => setOpenModalDeleteBankAccount(true)}
                  className="delete"
                  onClick={() => setOpenModalDeleteBankAccount(true)}
                />
              )}
            </>
          )}
        </Options>
        <Row>
          <div>
            <h2>Banco</h2>
            <p>
              {`${(dataAccountBank.bank as Bank)?.bank_number}-${
                (dataAccountBank.bank as Bank)?.short_name
              }`}

            </p>
          </div>
          <Divider />
          {dataAccountBank.cpf.length > 1 && (
            <div>
              <h2>CPF</h2>
              <p>{cpf(dataAccountBank.cpf)}</p>
            </div>
          )}
          {dataAccountBank.cnpj.length > 1 && (
            <div>
              <h2>CNPJ</h2>
              <p>{cnpj(dataAccountBank.cnpj)}</p>
            </div>
          )}
        </Row>
        <Line />
        <Row className="secondaryContent">
          <div className="new-styled">
            <h2>Agência</h2>
            <p>{dataAccountBank.branch_number}</p>
          </div>
          <Divider />
          <div className="new-styled">
            <h2>Conta</h2>
            <p>{dataAccountBank.account_number}</p>
          </div>
          <Divider />
          <div className="new-styled">
            <h2>Destinatário</h2>
            <p>{dataAccountBank.entity_name}</p>
          </div>
          <Divider />
          <div className="new-styled">
            <h2>Pix</h2>
            <p>{dataAccountBank.pix_key || 'Não Informado'}</p>
          </div>
        </Row>
        <ModalConfirmDeleteBankAccount
          openModal={openModalDeleteBankAccount}
          setOpenModal={setOpenModalDeleteBankAccount}
          idAccount={dataAccountBank.id || 0}
          updateListBank={updateListBank}
        />
      </Container>
      <Warning>
        {/* <img src={WarningIcon} alt="warning" color={'#FF2732'} /> */}
        <h2>
          *As mudanças de conta bancária realizadas após o dia 30 do mês só
          serão utilizadas para repasses dos meses posteriores
        </h2>
      </Warning>
    </>
  );
};

export default BankAccountComponent;
