import { useContextSelector } from 'use-context-selector';
import { StarRatingContext } from '../../context/StarRatingContext/StarRatingContext';

export function useStarRating() {
  const rating = useContextSelector(StarRatingContext, (state) => state.rating);
  const handleChangeRating = useContextSelector(StarRatingContext,
    (state) => state.setRating);

  const hover = useContextSelector(StarRatingContext, (state) => state.hover);
  const handleChangeHover = useContextSelector(StarRatingContext,
    (state) => state.setHover);

  return {
    rating,
    handleChangeRating,
    hover,
    handleChangeHover,
  };
}
