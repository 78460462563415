import styled from 'styled-components';

export const SeazoneResumeContainer = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #394760 !important;
    font-family: 'Inter' !important;
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  color: #394760;
  font-family: 'Inter';
`;

export const SeazoneResumeItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-family: 'Inter';
  color: #394760;

  > p {
    font-size: 1rem;
    font-size: 0.75;
    span {
      font-weight: 600;
    }
  }
`;

export const SeazoneResumeInformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: row;
  gap: 1rem;
  margin: 1rem 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #E4EAEF;
  padding: 1rem;
  border-radius: 0 0 10px 10px;
`;

export const TotalText = styled.p`
  color: #394760;
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;

  @media (max-width: 900px) {
    font-size: 1.25rem;
  }

  span {
    margin-right: 0.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    color: #394760;
    text-align: center;
    font-family: Inter;
    @media (max-width: 900px) {
      font-size: 1.25rem;
    }
  }
`;
