import { useEffect, useState } from 'react';

import moment from 'moment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Tooltip from '../../../../Tooltip';

import copyIcon from '../../../../../assets/icons/generals/copy.svg';
import editIcon from '../../../../../assets/icons/generals/edit.svg';
import RingBell from '../../../../../assets/icons/multicalendar/ring-bell.svg';
import Guest from '../../../../../assets/icons/multicalendar/guest.svg';
import Finance from '../../../../../assets/icons/multicalendar/finance.svg';

import {
  Container,
  ItemContainer,
  Item,
  ReservationCode,
  ImmobileCode,
  ReservationDate,
  ReservationStaff,
  WhatsApp,
  CheckIn,
  Title,
  EditButton,
  TitleReservationCode,
  RemainingDate,
  Email,
  SvgContainer,
} from './styles';
import { LateralModal } from '../../../..';
import { useModal } from '../../../../../hooks/ModalHook/useModal';
import ReservationDataModal from '../../../LateralModal/ReservationDataModal';
import GuestForm from '../../../LateralModal/GuestModal';
import FinanceForm from '../../../../Calendar/Reservation/ModalFinance';
import PreCheckin from './PreCheckin/PreCheckin';
import { Guest as GuesType } from '../../../../../services/CompReservations/types';

interface BodyProps {
  reservationCode?: string;
  immobileCode?: string;
  reservationDate?: string;
  reservationStaff?: string;
  whatsApp: string;
  checkIn?: string;
  index?: number;
  color: 'normal' | 'green';
  onClickEditButton: Function;
  reservationId: number;
  emailSent: boolean;
  isPrecheckinLinkSent: boolean;
  isHotel: boolean;
  isPreCheckinFullFiled: boolean;
  mainGuest: GuesType;
}

const Body = ({
  color,
  index,
  checkIn,
  whatsApp,
  emailSent,
  immobileCode,
  reservationId,
  reservationCode,
  reservationDate,
  reservationStaff,
  onClickEditButton,
  isPrecheckinLinkSent,
  isPreCheckinFullFiled,
  isHotel = false,
  mainGuest,
}: BodyProps) => {
  const { modalActive } = useModal();
  const [openReservatioDataModal, setOpenReservationDataModal] = useState(false);

  const options = [
    { icon: Guest, alt: 'Guest', title: 'Hóspede' },
    { icon: Finance, alt: 'Finance', title: 'Financeiro' },
    { icon: RingBell, alt: 'Ring Bell', title: 'Dados da reserva' },
  ];

  async function handleCopyWhatsAppNumber() {
    navigator.clipboard.writeText(whatsApp);
  }

  const [day, setDay] = useState<number>();

  useEffect(() => {
    function calculateDays() {
      const resultDays = moment(checkIn).diff(moment(), 'day');
      setDay(resultDays + 1);
    }
    calculateDays();
  }, []);

  return (
    <Container>
      <ItemContainer color={color} index={index || 2}>
        <Item>
          <ReservationCode>
            <TitleReservationCode
              onClick={() => setOpenReservationDataModal(true)}
            >
              {reservationCode}
            </TitleReservationCode>
          </ReservationCode>

          <ImmobileCode>
            <Title>{immobileCode}</Title>
          </ImmobileCode>

          <ReservationDate>
            <Title>{moment(reservationDate).format('DD/MM/YYYY')}</Title>
          </ReservationDate>

          <ReservationStaff>
            <Title>{reservationStaff}</Title>
          </ReservationStaff>

          <WhatsApp onClick={handleCopyWhatsAppNumber}>
            <Title>{whatsApp}</Title>

            <Tooltip text="Copiar Número">
              <img src={copyIcon} alt="icone de cópia" />
            </Tooltip>
          </WhatsApp>

          <CheckIn>
            <Title>{moment(checkIn).format('DD/MM/YYYY')}</Title>
          </CheckIn>

          <Email>
            {!emailSent ? (
              <SvgContainer>
                <Tooltip text="Email não enviado">
                  <MailOutlineIcon />
                </Tooltip>
              </SvgContainer>
            ) : (
              <SvgContainer isSend>
                <Tooltip text="Email enviado">
                  <MailOutlineIcon />
                </Tooltip>
              </SvgContainer>
            )}
          </Email>

          <RemainingDate day={day || 0}>
            {day && day > 1 && (
            <p>
              Faltam
              {' '}
              {day}
              d
            </p>
            )}
            {day && day === 1 && (
            <p>
              Falta
              {' '}
              {day}
              d
            </p>
            )}
          </RemainingDate>

          <PreCheckin
            immobileCode={immobileCode}
            reservationId={reservationId}
            reservationCode={reservationCode}
            reservationDate={checkIn}
            reservationStaff={reservationStaff}
            isPrecheckinLinkSent={isPrecheckinLinkSent}
            isPreCheckinFullFiled={isPreCheckinFullFiled}
            isHotel={isHotel}
          />

          <EditButton onClick={() => onClickEditButton()}>
            <Tooltip text="Editar">
              <img src={editIcon} alt="icone de edição" />
            </Tooltip>
          </EditButton>
        </Item>
      </ItemContainer>
      <LateralModal
        open={openReservatioDataModal}
        setOpen={setOpenReservationDataModal}
        activeTabs
        options={options}
        headerTitle="Dados da reserva"
      >
        <>
          {modalActive === 0 && (
            <ReservationDataModal comment="" idReservation={reservationId} />
          )}
          {modalActive === 1 && <GuestForm mainGuest={mainGuest} idReservation={reservationId} />}
          {modalActive === 2 && <FinanceForm />}
        </>
      </LateralModal>
    </Container>
  );
};

Body.defaultProps = {
  index: 2,
  reservationCode: '00425583483983',
  immobileCode: 'INGSUI3Q',
  reservationDate: '12 ⁄ 12 ⁄ 2021',
  reservationStaff: 'Gabriel Fischer',
  checkIn: '12 ⁄ 12 ⁄ 2021',
};

export default Body;
