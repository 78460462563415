import { Button } from './styles';

interface IInfoButton {
  onClick: Function;
  color?: string,
}

const InfoButton = ({
  onClick = () => {},
  color = '#0D4BD0',
}: IInfoButton) => (
  <Button
    data-testid="info-icon"
    type="button"
    onClick={() => onClick()}
  >
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.49939 0.613281C3.36388 0.613281 0 3.94488 0 8.03991C0 12.1349 3.36388 15.4664 7.49939 15.4664C11.6351 15.4664 14.9995 12.1348 14.9995 8.03991C14.9995 3.94488 11.635 0.613281 7.49939 0.613281ZM7.49939 13.8941C4.23962 13.8941 1.5879 11.2678 1.5879 8.03991C1.5879 4.81207 4.23965 2.1856 7.49939 2.1856C10.7593 2.1856 13.4116 4.81207 13.4116 8.03991C13.4116 11.2678 10.7593 13.8941 7.49939 13.8941Z" fill={color} />
      <path d="M8.2609 7.32031H6.73828C6.48281 7.32031 6.27539 7.5257 6.27539 7.77828V12.2485C6.27539 12.5015 6.48281 12.7065 6.73828 12.7065H8.2609C8.51638 12.7065 8.72379 12.5014 8.72379 12.2485V7.77831C8.72379 7.52573 8.51638 7.32031 8.2609 7.32031Z" fill={color} />
      <path d="M7.49892 3.375C6.75002 3.375 6.14258 3.97646 6.14258 4.71806C6.14258 5.45975 6.74999 6.06124 7.49892 6.06124C8.24798 6.06124 8.85545 5.45978 8.85545 4.71806C8.85542 3.97646 8.24801 3.375 7.49892 3.375Z" fill="#0D4BD0" />
    </svg>
  </Button>
);

InfoButton.defaultProps = {
  color: '#0D4BD0',
};

export default InfoButton;
