import styled from 'styled-components';

export const ValidationAndHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .label {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #394760 !important;
    font-family: 'Inter' !important;
  }
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin-top: 2rem;
`;
