import React, { createContext, ReactNode, useState } from 'react';

interface ManagementPanelProviderProps {
  children: ReactNode
}

interface ManagementPanelContextProps {
  isImportStaysReservationModalOpen: boolean
  handleOpenImportStaysReservationModal: () => void
  handleCloseImportStaysReservationModal: () => void
  isExportCSVModalOpen: boolean
  handleOpenExportCSVModal: () => void
  handleCloseExportCSVModal: () => void
}

export const ManagementPanelContext = createContext({} as
  ManagementPanelContextProps);

export const ManagementPanelProvider = ({
  children,
}: ManagementPanelProviderProps) => {
  const [isImportStaysReservationModalOpen, setIsImportStaysReservationModalOpen] = useState(false);
  const [isExportCSVModalOpen, setIsExportCSVModalOpen] = useState(false);

  const handleOpenImportStaysReservationModal = () => {
    setIsImportStaysReservationModalOpen(true);
  };

  const handleCloseImportStaysReservationModal = () => {
    setIsImportStaysReservationModalOpen(false);
  };

  const handleOpenExportCSVModal = () => {
    setIsExportCSVModalOpen(true);
  };

  const handleCloseExportCSVModal = () => {
    setIsExportCSVModalOpen(false);
  };

  return (
    <ManagementPanelContext.Provider
      value={{
        isImportStaysReservationModalOpen,
        isExportCSVModalOpen,
        handleOpenImportStaysReservationModal,
        handleCloseImportStaysReservationModal,
        handleOpenExportCSVModal,
        handleCloseExportCSVModal,
      }}
    >
      {children}
    </ManagementPanelContext.Provider>
  );
};
