import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white._300.hex()};
  width: 100%;
  height: 100vh;
  padding: 16px 18px;

  @media screen and (max-width: 1000px) {
    padding: 0;
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  padding: 1rem 1.13rem;

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1000px) {
    border-radius: 0;
    padding: 1rem 1.13rem 100px 1.13rem;
  }
`;
