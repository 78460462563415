import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  transition: 0.3s;
  gap: 30px;
`;
