import { useState, useEffect } from 'react';

export function useScreenResize(defaultWidthToDesktop: number = 900) {
  const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  return {
    isMobile: widthWindow <= defaultWidthToDesktop,
    widthWindow,
  };
}
