import styled, { css } from 'styled-components';
import { ExpandMoreRounded } from '@mui/icons-material/';

const getBackgroundColor = (label: string) => {
  let color = 'linear-gradient(90deg, #EAFFBE 11.87%, #D7FFF3 72.55%)';

  if (label === 'Saídas') {
    color = 'linear-gradient(90deg, #FFDAE0 19.21%, #FFACBB 97%)';
  } else if (label === 'Carteira') {
    color = 'linear-gradient(90deg, #BCE3FF 28.29%, #C2B8FF 99.41%)';
  }

  return color;
};

export const Container = styled.div <{ customColor: string, stickTag: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 27px;
  background: ${({ customColor }) => getBackgroundColor(customColor)};
  padding: 0 1.125rem;
  
  @media(max-width: 768px) {
    ${({ stickTag }) => stickTag && css`
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 70px;
    `}
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: sticky;
  position: -webkit-sticky;
  left: 0;
`;

export const Title = styled.strong`
  margin-left: 0.5rem;
  font-size: 1rem;

  @media(max-width: 768px) {
    margin-left: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 0.8rem;
  
  @media(max-width: 768px) {
    font-size: 0.9rem;
    margin-top: 0.3rem;
    margin-left: 2.25rem;
  }
`;

export const Icon = styled.img`
  @media(max-width: 768px) {
    margin-top: 0.2rem;
  }
`;

export const ExpandMoreRoundedContainer = styled.div`
  cursor: pointer;
`;

export const ExpandMoreRoundedIcon = styled(ExpandMoreRounded)`
  width: 9.6px;
  height: 16.8px;
`;
