import { motion, AnimatePresence } from 'framer-motion';
import { useGuestDamageStep } from '../../../../hooks/GuestDamage/useGuestDamageStep';
import FormButton from '../../../FormButton/FormButton';
import { BillingHistoryNegotiationsHeader } from './BillingHistoryNegotiationsHeader';
import { DamageResume } from './DamageResume';
import { ValidationAndHistory } from './ValidationAndHistory';
import {
  BillingHistoryNegotiationsCardContainer, ButtonContainer, Title, ValidationAndHistoryItem,
} from './styles';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import { useUser } from '../../../../context/UserContext';

export const ViewBillingHistoryNegotiationsCard = () => {
  const { handleInsertBillingHistoryNegotiationsStep } = useGuestDamageStep();
  const { selectedGuestDamage } = useGuestDamage();
  const { userInformation } = useUser();
  const roles = userInformation?.roles;

  return (
    <BillingHistoryNegotiationsCardContainer>
      <BillingHistoryNegotiationsHeader />
      <DamageResume />
      <AnimatePresence custom="wait">
        {selectedGuestDamage.id !== undefined && (
          <>
            <motion.div
              initial={{
                opacity: 0,
                y: -30,
              }}
              transition={{ ease: 'easeIn', duration: 0.4 }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              exit={{
                opacity: 0,
                y: -30,
              }}
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <Title>Validação de evidências e orçamento</Title>
              <ValidationAndHistoryItem>
                <h3>Evidências e orçamentos validados</h3>
                <p>{selectedGuestDamage.are_evidences_and_quotation_validated ? 'Sim' : 'Não'}</p>
              </ValidationAndHistoryItem>
              <ValidationAndHistoryItem>
                <h3>Quais informações estão faltando</h3>
                <p>{selectedGuestDamage.missing_information || '-'}</p>
              </ValidationAndHistoryItem>
            </motion.div>

          </>
        )}
      </AnimatePresence>
      <ValidationAndHistory />
      {(roles?.includes('Attendant')
        || roles?.includes('Admin'))
        && (
          <ButtonContainer>
            <FormButton
              onClick={handleInsertBillingHistoryNegotiationsStep}
            >
              Editar dados
            </FormButton>
          </ButtonContainer>
        )}
    </BillingHistoryNegotiationsCardContainer>
  );
};
