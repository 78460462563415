import React, {
  useEffect, useRef, useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Image,
  ImageResized,
  ItemNav,
  NavBar,
  SVGContainer,
  Title,
} from './styles';

import linksIcon from '../../../assets/icons/generals/linksIcon.svg';
import NFicon from '../../../assets/icons/generals/NFicon.svg';
import ChartBar from '../../../assets/icons/generals/chartbar.svg';
import editDataIcon from '../../../assets/icons/generals/edit.svg';
import FinancialFeedbackIMG from '../../../assets/icons/generals/financialFeedback.svg';
import iconController from '../../../assets/icons/generals/iconControl.svg';
import iconDashboard from '../../../assets/icons/dashboard/dashboard.svg';
import iconExpenditure from '../../../assets/icons/generals/iconExpenditure.svg';
import iconProperties from '../../../assets/icons/generals/iconProperties.svg';
import insertDataIcon from '../../../assets/icons/generals/insertDataIcon.svg';
import { useUser } from '../../../context/UserContext';
import iconEstimate from '../../../assets/icons/generals/iconEstimate.svg';
import reservationVoucher from '../../../assets/icons/generals/reservationVoucher.svg';
import { partnersIconSVG } from '../../../assets/icons/partners';
import paymentVoucherIcon from '../../../assets/icons/paymentVoucher/paymentVoucher.svg';
import guestDamageIcon from '../../../assets/icons/guestDamage/guestDamageIcon.svg';
import iconCalendar from '../../../assets/icons/generals/iconCalendar.svg';

const BottomHeader: React.FC = () => {
  const [active, setActive] = useState('');
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuItemsRef = useRef<HTMLDivElement[] | HTMLAnchorElement[]>([]);

  const shouldBeVisibleTo = (permissions: string[], verify_host_ops = false) => {
    const permission = permissions
      .find((role) => roles.includes(role));

    if (permission === 'Host' && verify_host_ops) {
      if (!userInformation?.host?.is_host_ops) {
        return true;
      }
      return false;
    }

    return !!permission;
  };

  const handleClick = (index: number) => {
    const container = containerRef.current;
    const containerContainer = menuContainerRef.current;
    if (!container || !containerContainer) return;

    const containerRect = containerContainer.getBoundingClientRect();
    const elementRect = menuItemsRef.current[index].getBoundingClientRect();

    const scrollLeft = elementRect.left - containerRect.left
      - (containerRect.width - elementRect.width) / 2 + container.scrollLeft;

    container.scrollTo({ left: scrollLeft, behavior: 'smooth' });
  };

  const menuItems = [
    {
      id: 1,
      to: '/controle',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/controle');
      },
      className: active === '/controle' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Host']),
      icon: <Image src={iconController} alt="menu controle" />,
      title: 'Controle',
    },
    {
      id: 2,
      to: '/dashboard',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/dashboard');
      },
      className: active === '/dashboard' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Host']),
      icon: <Image src={iconDashboard} alt="menu dashboard" />,
      title: 'Dashboard',
    },
    {
      id: 3,
      to: '/multicalendar',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/multicalendar');
      },
      className: active === '/multicalendar' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneAdministrative', 'Attendant', 'Host']),
      icon: <Image src={iconCalendar} alt="menu dashboard" />,
      title: 'Multicalendar',
    },
    {
      id: 4,
      to: '/buscadepropriedades',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/buscadepropriedades');
      },
      className: active === '/buscadepropriedades' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Attendant']),
      icon: (
        <SVGContainer>
          <SearchIcon />
        </SVGContainer>
      ),
      title: 'Busca de propriedades',
    },
    {
      id: 5,
      to: '/propriedades',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/propriedades');
      },
      className: active === '/propriedades' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Host', 'Attendant']),
      icon: <Image src={iconProperties} alt="menu propriedades" />,
      title: 'Propriedades',
    },
    {
      id: 6,
      to: '/despesas',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/despesas');
      },
      className: active === '/despesas' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding'], true),
      icon: <Image src={iconExpenditure} alt="menu despesas" />,
      title: 'Despesas',
    },
    {
      id: 7,
      to: '/reembolso',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/reembolso');
      },
      className: active === '/reembolso' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Host', 'SeazoneAdministrative', 'SeazoneOnboarding'], true),
      icon: <Image src={iconExpenditure} alt="menu reembolso" />,
      title: 'Pedido de Reembolso',
    },
    {
      id: 8,
      to: '/listadenfs',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/listadenfs');
      },
      className: active === '/listadenfs' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneAdministrative']),
      icon: <ImageResized src={NFicon} alt="menu lista de notas fiscais" />,
      title: 'Lista de NFs',
    },
    {
      id: 9,
      to: '/listaderepassesproprietario',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/listaderepassesproprietario');
      },
      className: active === '/listaderepassesproprietario' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneAdministrative']),
      icon: <ImageResized src={NFicon} alt="menu lista de ted" />,
      title: 'Lista de repasses p/ Proprietários',
    },
    {
      id: 10,
      to: '/listaderepassesanfitriao',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/listaderepassesanfitriao');
      },
      className: active === '/listaderepassesanfitriao' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneAdministrative']),
      icon: <ImageResized src={NFicon} alt="menu lista de ted" />,
      title: 'Lista de repasses p/ Anfitrião',
    },
    {
      id: 11,
      to: '/links',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/links');
      },
      className: active === '/links' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Host']),
      icon: <Image src={linksIcon} alt="links" />,
      title: 'Links',
    },
    {
      id: 12,
      to: '/painelgerenciamento',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/painelgerenciamento');
      },
      className: active === '/painelgerenciamento' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative', 'Attendant']),
      icon: (
        <SVGContainer>
          <SystemUpdateAltIcon />
        </SVGContainer>
      ),
      title: 'Painel de Gerenciamento',
    },
    {
      id: 13,
      to: '/orcamento',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/estimate');
      },
      className: active === '/estimate' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Attendant']),
      icon: <Image src={iconEstimate} alt="menu orcamento" />,
      title: 'Orçamento Mensal',
    },
    {
      id: 14,
      to: '/conciliacao',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/conciliacao');
      },
      className: active === '/conciliacao' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Attendant']),
      icon: <Image src={iconProperties} alt="menu conciliação" />,
      title: 'Conciliação',
    },
    {
      id: 15,
      to: '/compreservas',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/compreservas');
      },
      className: active === '/compreservas' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Attendant']),
      icon: <Image src={reservationVoucher} alt="menu despesas" />,
      title: 'Comp.Reservas',
    },
    {
      id: 16,
      to: '/parceiros/painel',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/parceiros/painel');
      },
      className: active === '/parceiros/painel' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Partner']),
      icon: <Image src={partnersIconSVG} alt="menu de parceiros" />,
      title: 'Parceiros',
    },
    {
      id: 17,
      to: '/comprovantespagamento',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/comprovantespagamento');
      },
      className: active === '/comprovantespagamento' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Attendant']),
      icon: <Image src={paymentVoucherIcon} alt="menu despesas" />,
      title: 'Comprovantes de Pagamento',
    },
    {
      id: 18,
      to: '/vouchersenviados',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/vouchersenviados');
      },
      className: active === '/vouchersenviados' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Attendant']),
      icon: <Image src={paymentVoucherIcon} alt="menu despesas" />,
      title: 'Vouchers enviados',
    },
    {
      id: 19,
      to: '/gabaritofinanceiro',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/gabaritofinanceiro');
      },
      className: active === '/gabaritofinanceiro' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneAdministrative']),
      icon: <Image src={FinancialFeedbackIMG} alt="menu gabarito" />,
      title: 'Gabarito',
    },
    {
      id: 20,
      to: '/onboarding',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/onboarding');
      },
      className: active === '/onboarding' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneOnboarding']),
      icon: <Image src={ChartBar} alt="menu onboarding" />,
      title: 'Onboarding',
    },
    {
      id: 21,
      to: '/inserirdados',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/inserirdados');
      },
      className: active === '/inserirdados' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneOnboarding']),
      icon: <Image src={insertDataIcon} alt="menu onboarding" />,
      title: 'Inserir Dados',
    },
    {
      id: 22,
      to: '/editardados',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/editardados');
      },
      className: active === '/editardados' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'SeazoneOnboarding']),
      icon: <Image src={editDataIcon} alt="menu onboarding" />,
      title: 'Editar Dados',
    },
    {
      id: 23,
      to: '/danosdehospede',
      onClick: (index:number) => {
        handleClick(index);
        setActive('/danosdehospede');
      },
      className: active === '/danosdehospede' ? 'active' : '',
      shouldBeVisible: shouldBeVisibleTo(['Admin', 'Attendant', 'Host', 'SeazoneAdministrative', 'SeazoneOnboarding']),
      icon: <Image src={guestDamageIcon} alt="menu danos de hospede" />,
      title: 'Danos de hóspede',
    },
  ];

  const handleGetActualRoute = () => {
    const match = menuItems.find((route) => location.pathname.includes(route.to));
    if (match) {
      setActive(match.to);
    } else {
      setActive('');
    }
  };

  useEffect(() => {
    handleGetActualRoute();
  }, [location]);

  useEffect(() => {
    const adjustItems = () => {
      const container = menuContainerRef.current;
      if (!container) return;

      const containerWidth = container.offsetWidth - 40;

      let closestDifference = Infinity;
      let visibleItemCount = 0;

      for (let index = 0; index < menuItemsRef.current.length; index += 1) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = menuItemsRef.current[index].getBoundingClientRect();
        const isVisible = (elementRect.left >= containerRect.left
        && elementRect.right <= containerRect.right);
        if (!isVisible) {
          const itemWidth = menuItemsRef.current[index - 1]?.offsetLeft || 0;
          const difference = Math.abs(containerWidth - itemWidth);
          closestDifference = difference;
          break;
        } else {
          visibleItemCount += 1;
        }
      }

      const margin = (closestDifference / visibleItemCount);
      menuItemsRef.current.forEach((item) => {
        const itemObj = item;
        itemObj.style.marginLeft = `${margin}px`;
      });
    };

    adjustItems();
  }, []);

  return (
    <Container ref={menuContainerRef}>
      <NavBar ref={containerRef}>
        {menuItems
          .filter((item) => item.shouldBeVisible)
          .map((item, index) => (
            <ItemNav
              key={item.id}
              to={item.to}
              onClick={() => item.onClick(index)}
              className={item.className}
              ref={(el) => {
                if (el) menuItemsRef.current[index] = el;
              }}
            >
              {item.icon}
              <Title>{item.title}</Title>
            </ItemNav>
          ))}
      </NavBar>
    </Container>
  );
};

export default BottomHeader;
