import React, { useState } from 'react';

import { Menu } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import {
  Container,
  ContentHeader,
  CloseOutlinedIcon,
  ContentBody,
  ContentTitle,
  Title,
  Subtitle,
  InfoIconContainer,
} from './styles';

interface Props {
  title?: string;
  subtitle: string | JSX.Element;
  open?: boolean;
  tooltipColor?: string;
  size?: 'sm' | 'md' | 'lg'
  marginRight?: string;
}

const TooltipInfo: React.FC<Props> = ({
  title = '',
  subtitle = '',
  open = false,
  tooltipColor = '#ACACAC',
  size = 'sm',
  marginRight = '0px',
}) => {
  const [openTooltip, setOpenTooltip] = useState(open);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenTootip = (event: React.MouseEvent<HTMLElement>) => {
    setOpenTooltip((prevState) => !prevState);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTootip = () => {
    setOpenTooltip(false);
    setAnchorEl(null);
  };

  const customStyles = {
    minHeight: 'auto',
    minWidth: '40ch',
    maxHeight: '180px',
    maxWidth: '225px',
    transition: '0.3s',
    borderRadius: '5px',
    padding: '0.5rem 1rem',
    boxShadow: '0px 2px 8px 5px rgba(0, 0, 0, 0.18)',
  };

  return (
    <>
      <Menu
        open={openTooltip}
        onClose={() => handleCloseTootip()}
        anchorEl={anchorEl}
        PaperProps={{
          style: customStyles,
        }}
      >
        <Container>
          <ContentHeader>
            <ContentTitle>
              <InfoOutlined />
              {title && (
                <Title>
                  {title}
                </Title>
              )}
            </ContentTitle>
            <CloseOutlinedIcon onClick={() => handleCloseTootip()} />
          </ContentHeader>
          <ContentBody>
            <Subtitle>
              {subtitle}
            </Subtitle>
          </ContentBody>
        </Container>
      </Menu>

      <InfoIconContainer
        size={size}
        color={tooltipColor}
        marginRight={marginRight}
        onClick={handleOpenTootip}
      >
        <InfoOutlined />
      </InfoIconContainer>
    </>
  );
};

export default TooltipInfo;
