import { actualSize } from '../../../../../../../../../../hooks/useMobile/useMobile';

export const motionVariation = actualSize === 'Desktop'
  ? {
    initial: { x: 0 },
    animate: { x: '50%' },
    whileTap: { x: '70%' },
  }
  : {
    whileTap: {
      opacity: 0.6,
    },
  };
