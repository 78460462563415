import React, { useState } from 'react';

import { Tooltip } from '@mui/material';

import {
  FileProps,
  FileAWSFormat,
  FileReference,
  FileReferenceResponse,
} from '../../../../../../../context/FileContext/types';

import { ICardContent } from '../../types';
import { initialValues } from '../../utils';

import {
  Content,
  FileName,
  AddButton,
  FilePreview,
  ImageTooltip,
  FileSeparator,
  FilesContainer,
  FileDeleteIconSVG,
  FilePreviewShimmer,
} from './style';

import Shimmer from './Shimmer';

import { useUpload } from '../../../../../../../hooks/useUpload';
import { useToast } from '../../../../../../../context/ToastContext';
import { useFile } from '../../../../../../../hooks/FileHook/useFile';

import {
  deletePropertyDocuments,
  postPropertyCocuments,
} from '../../../../../../../services/InsertData/uploadFile/request';

type IFileResponse = FileReferenceResponse | { uid: null };

const TrashIconSVG = () => (
  <svg
    width="20"
    height="15"
    fill="none"
    viewBox="0 0 6 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="black"
      fillOpacity="0.7"
      d="M5.59836 0.817474H4.38349L4.03639 0.470367H2.30085L1.95375 0.817474H0.738876V1.51169H5.59836M1.08598 6.02407C1.08598 6.20818 1.15912 6.38476 1.28931 6.51495C1.4195 6.64514 1.59608 6.71828 1.7802 6.71828H4.55705C4.74116 6.71828 4.91774 6.64514 5.04793 6.51495C5.17812 6.38476 5.25126 6.20818 5.25126 6.02407V1.85879H1.08598V6.02407Z"
    />
  </svg>
);

function fixPdfContentType(type: string) {
  if (type.includes('pdf')) {
    return 'application/pdf';
  } return type;
}

const handleLimitFileName = (name: string) => {
  if (name.length > 20) {
    return `${name.substring(0, 20)}...`;
  }
  return name;
};

const handleOpenPreview = (link: string | null) => {
  if (link) {
    window.open(link);
  }
};

function CardContent({
  isOpen,
  category,
  propertyId,
  filesLoaded,
  imagesLoaded,
}: ICardContent) {
  const toast = useToast();

  const {
    uploadFileToS3,
    createFileReference,
  } = useFile();

  const [loading, setLoading] = useState(false);
  const [documentClicked, setDocumentClicked] = useState(false);
  const [documentPhoto, setDocumentPhoto] = useState<FileProps>(initialValues);

  const fileLoadedAsDocumentsArray: FileProps[] = React
    .useMemo(() => filesLoaded?.map((item: any) => ({
      ...item.document,
      id: item.id,
    })) as FileProps[],
    [filesLoaded]);

  const fileLoadedAsImagesArray: FileProps[] = React
    .useMemo(() => imagesLoaded?.map((item: any) => ({
      ...item.image,
      id: item.id,
    })) as FileProps[],
    [imagesLoaded]);

  const [
    documentsArray,
    setDocumentsArray,
  ] = useState<FileProps[]>(([] as FileProps[]));

  const [
    imagesArray,
    setImagesArray,
  ] = useState<FileProps[]>(([] as FileProps[]));

  const handleUploadFile = async (file: FileProps) => {
    setLoading(true);
    let fileResponse: IFileResponse = { uid: null };

    try {
      const fileReference: FileReference = {
        category,
        name: file.name,
        content_type: fixPdfContentType(file.MIMEtype) || '',
      };

      const responseFile: FileReferenceResponse = await createFileReference(fileReference);

      const params: FileAWSFormat = {
        file: '',
        fileId: responseFile.uid,
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        key: responseFile.storage.fields.key,
        content_type: fixPdfContentType(fileReference.content_type),
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
      };

      await uploadFileToS3(file, params);

      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
    } catch (e: unknown) {
      toast.error('Não foi possível fazer o upload do documento!');
    }

    return fileResponse;
  };

  async function handleSelectFile(file: FileProps) {
    setDocumentPhoto(initialValues);
    if (!documentClicked) {
      setDocumentClicked(true);

      let responseDocumentFile: FileReferenceResponse | { uid: null } = {
        uid: null,
      };

      responseDocumentFile = await handleUploadFile(file);

      if (!responseDocumentFile.uid) {
        toast.error('Não foi possível realizar o upload do arquivo do documento');
      } else if (propertyId) {
        try {
          const responseDocument = await postPropertyCocuments({
            category,
            propertyID: propertyId,
            type: responseDocumentFile.content_type.includes('pdf') ? 'Document' : 'Image',
            documentUUID: responseDocumentFile.uid,
          });
          setDocumentsArray((oldData) => [
            ...oldData,
            {
              ...file,
              id: String(responseDocument.id),
              uid: responseDocumentFile?.uid || '',
            },
          ]);
        } catch (e) {
          toast.error('Erro ao tentar enviar a referência da imagem');
        } finally {
          setLoading(false);
        }
      }
    }
  }

  const {
    open,
    getRootProps,
    getInputProps,
  } = useUpload({
    multiple: true,
    file: documentPhoto,
    accept: ['image/*', 'application/*', '.csv'],
    setFile: (file: FileProps) => handleSelectFile(file),
  });

  const handleDeleteFileDoc = async (id: string, type: string) => {
    const selectType = type.includes('pdf') ? 'Document' : 'Image';

    try {
      await deletePropertyDocuments(selectType, id);
      const filterdDocumentsArray = documentsArray.filter((doc) => doc.id !== id);
      setDocumentsArray(filterdDocumentsArray);
    } catch (e) {
      toast.error('Não foi possível deletar o arquivo');
    }
  };

  const handleDeleteFileImage = async (id: string, type: string) => {
    const selectType = type.includes('pdf') ? 'Document' : 'Image';

    try {
      await deletePropertyDocuments(selectType, id);
      const filterdImageArray = imagesArray.filter((doc) => doc.id !== id);
      setImagesArray(filterdImageArray);
    } catch (e) {
      toast.error('Não foi possível deletar o arquivo');
    }
  };

  React.useEffect(() => {
    setDocumentsArray(fileLoadedAsDocumentsArray as FileProps[]);
  }, [filesLoaded]);

  React.useEffect(() => {
    setImagesArray(fileLoadedAsImagesArray as FileProps[]);
  }, [filesLoaded]);

  if (!isOpen) {
    return null;
  }

  return (
    <Content
      layout
      exit={{ opacity: 0, y: 0 }}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
    >
      <div>
        <AddButton onClick={() => open()} type="button">
          Clique ou arraste para carregar uma imagem ou documento
          <div {...getRootProps()}>
            <input id={String(documentPhoto.id)} {...getInputProps()} type="file" />
          </div>
        </AddButton>
      </div>

      {loading ? (
        <Shimmer documentsArray={documentsArray} />
      ) : (
        documentsArray.map((file) => (
          <FilesContainer key={file.id}>
            {file.previewURL || file.url ? (
              <Tooltip
                arrow
                placement={'top'}
                title={(
                  <ImageTooltip
                    alt={file.name}
                    src={file.previewURL || file.url || ''}
                  />
                )}
              >
                <FilePreview
                  src={file.previewURL || file.url || ''}
                  onClick={() => handleOpenPreview(file.previewURL || file.url)}
                />
              </Tooltip>
            ) : (
              <FilePreviewShimmer />
            )}

            <FileSeparator />

            <Tooltip title={<h1>{file.name}</h1>}>
              <FileName>{handleLimitFileName(file.name)}</FileName>
            </Tooltip>

            <FileSeparator />

            <Tooltip title={<h1>Deletar arquivo</h1>}>
              <FileDeleteIconSVG
                onClick={() => handleDeleteFileDoc(file.id, (file.MIMEtype || file.content_type || ''))}
              >
                <TrashIconSVG />
              </FileDeleteIconSVG>
            </Tooltip>
          </FilesContainer>
        ))
      )}
      {loading ? (
        <Shimmer documentsArray={imagesArray} />
      ) : (
        imagesArray.map((file) => (
          <FilesContainer key={file.id}>
            {file.previewURL || file.url ? (
              <Tooltip
                arrow
                placement={'top'}
                title={(
                  <ImageTooltip
                    alt={file.name}
                    src={file.previewURL || file.url || ''}
                  />
                )}
              >
                <FilePreview
                  src={file.previewURL || file.url || ''}
                  onClick={() => handleOpenPreview(file.previewURL || file.url)}
                />
              </Tooltip>
            ) : (
              <FilePreviewShimmer />
            )}

            <FileSeparator />

            <Tooltip title={<h1>{file.name}</h1>}>
              <FileName>{handleLimitFileName(file.name)}</FileName>
            </Tooltip>

            <FileSeparator />

            <Tooltip title={<h1>Deletar arquivo</h1>}>
              <FileDeleteIconSVG
                onClick={() => handleDeleteFileImage(file.id, (file.MIMEtype || file.content_type || ''))}
              >
                <TrashIconSVG />
              </FileDeleteIconSVG>
            </Tooltip>
          </FilesContainer>
        ))
      )}
    </Content>
  );
}

export default CardContent;
