import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  // Contact,
  Container,
  Logo,
} from './styles';
import logo from '../../../assets/icons/generals/logoFullWhite.svg';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Logo src={logo} onClick={() => { navigate('/proprietario'); }} alt="logo seazone" data-cy="img-logo-seazone-footer" />
      {/* <Contact>
      <p />
    </Contact> */}
    </Container>
  );
};

export default Footer;
