/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
} from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import { translateMonth } from '../../../utils/Translator';
import { getPaymentStatus, getReservations } from '../../../services/Reservation/request';
import { Reservation } from '../../../services/Reservation/types';

import { useReload } from '../../../context/OwnerPage/GridCalendarReload';
import { useDate } from '../../../hooks/DateHook/useDate';
import { useUser } from '../../../context/UserContext';
import { changeTimeZoneOfDate } from '../../../utils/Date';
import { useViewMode } from '../../../context/ViewMode/ViewMode';

import FormButton from '../../FormButton';
import ReservationMarker from './ReservationMarker';
import ModalReservation from '../Modal/ModalReservation';
import ModalPropertyLock from '../Modal/ModalPropertyLock';
import TooltipInfo from '../TooltipInfo';
import PropertiesSelector from '../Header/PropertiesSelector/PropertiesSelector';

import {
  Container,
  ContainerTitle,
  WrapperTitle,
  Title,
  WrapperReservation,
  ContentReservation,
  Circle,
  WrapperMonth,
  ButtonMonth,
  WrapperButton,
  ContainerCalendar,
  DaysOfWeek,
  DateGrid,
  DayContainer,
  DateContainer,
  DayContent,
  DayNumber,
  DailyPrice,
  SelectorContainer,
} from './styles';

type ExistingReservationProps = {
  checkin: Moment;
  checkout: Moment;
};

type ReservationsProperty = Record<string, Reservation>;

export interface CalendarType {
  getReservation: Function;
}

export const CalendarContext = createContext<CalendarType>({
  getReservation: () => {},
});

interface Props {
  propertyId: string;
  propertyCode?: string;
}

const GridCalendar: React.FC<Props> = ({
  propertyId,
  propertyCode = '',
}) => {
  const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);
  const [widthMarker, setWidthMarker] = useState<number>(0);
  const [openModalReservation, setOpenModalReservation] = useState<boolean>(true);
  const [openModalPropertyLock, setOpenModalPropertyLock] = useState<boolean>(false);
  const [reservations, setReservations] = useState<ReservationsProperty>();
  const [propertyInfos, setPropertyInfos] = useState<{
    existingBookings: ExistingReservationProps[],
    hasExtraDayPreparation: boolean,
  }>({
    existingBookings: [],
    hasExtraDayPreparation: false,
  });
  const [checkinsDates, setCheckinsDates] = useState<string[]>([]);
  const [reservationClicked, setReservationClicked] = useState<Reservation>();
  const [counterDailysBookings, setCounterDailysBookings] = useState<number>(0);
  const [counterDailysLocks, setCounterDailysLocks] = useState<number>(0);
  const [occupancyRate, setOccupancyRate] = useState<number>(0);

  const { reload, setReload } = useReload();
  const { propertiesIdsOwnerLogged } = useUser();
  const { mode, isViewMode } = useViewMode();
  const { id } = useParams();
  const {
    handleDateSelected,
    handleDirection,
    gridMonth,
    gridYear,
    setGridMonth,
    setGridYear,
    selectInModal,
    setSelectInModal,
  } = useDate();

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  const refDiv = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    if (refDiv.current) {
      setWidthMarker(refDiv.current ? refDiv.current.offsetWidth : 0);
    } else {
      windowResize();
    }
  }, [refDiv, widthWindow]);

  const days: string[] = widthWindow >= 600 ? ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'] : ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'];
  const months: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  const convertDayNameToNumber = (day: string): number => {
    const dayName = day.toUpperCase().trim();
    let indexDay = 0;
    if (['MONDAY', 'MON', 'SEGUNDA', 'SEGUNDA-FEIRA', 'SEG'].includes(dayName)) {
      indexDay = 0;
    } else if (['TUESDAY', 'TUE', 'TERÇA', 'TERÇA-FEIRA', 'TER'].includes(dayName)) {
      indexDay = 1;
    } else if (['WEDNESDAY', 'WED', 'QUARTA', 'QUARTA-FEIRA', 'QUA'].includes(dayName)) {
      indexDay = 2;
    } else if (['THURSDAY', 'THU', 'QUINTA', 'QUINTA-FEIRA', 'QUI'].includes(dayName)) {
      indexDay = 3;
    } else if (['FRIDAY', 'FRI', 'SEXTA', 'SEXTA-FEIRA', 'SEX'].includes(dayName)) {
      indexDay = 4;
    } else if (['SATURDAY', 'SAT', 'SÁBADO', 'SAB'].includes(dayName)) {
      indexDay = 5;
    } else if (['SUNDAY', 'SUN', 'DOMINGO', 'DOM'].includes(dayName)) {
      indexDay = 6;
    }
    return indexDay;
  };

  const getDayStartMonth = (date: string) => {
    const dayName = moment(date).startOf('month').format('dddd');
    return dayName;
  };

  const getDayEndMonth = (date: string) => {
    const dayName = moment(date).endOf('month').format('dddd');
    return dayName;
  };

  const getDaysInMonth = (date: string) => {
    const numberDays: number = moment(date, 'YYYY-MM').daysInMonth();
    return numberDays;
  };

  const [numberOfPastDates, setNumberOfPastDates] = useState<number>(convertDayNameToNumber(getDayStartMonth(`${gridYear}-${months[gridMonth]}`)));
  const [numberOfFutureDates, setNumberOfFutureDates] = useState<number>(6 - convertDayNameToNumber(getDayEndMonth(`${gridYear}-${months[gridMonth]}`)));
  const [daysInMonth, setDaysInMonth] = useState<number>(getDaysInMonth(`${gridYear}-${months[gridMonth]}`));

  const generateArrayDates = (currentMonth: string | number, currentYear: string | number) => {
    const totalDays = getDaysInMonth(`${currentYear}-${currentMonth}`);
    const firstDateInMonth = changeTimeZoneOfDate(`${currentYear}-${currentMonth}-01`, 'America/Sao_Paulo');
    const datesPast = Array
      .from({ length: numberOfPastDates }, (_, day: number) => moment(moment(firstDateInMonth).add(-(day), 'days').toDate()));
    const datesCurrent = Array
      .from({ length: totalDays }, (_, day: number) => moment(moment(firstDateInMonth).add(day + 1, 'days').toDate()));
    const lastDateInMonth = datesCurrent[datesCurrent.length - 1];
    const datesFuture = Array
      .from({ length: numberOfFutureDates }, (_, day: number) => moment(moment(lastDateInMonth).add(day + 1, 'days').toDate()));
    const arrayDates: Moment[] = [];
    datesPast.forEach((item) => arrayDates.push(item));
    datesCurrent.forEach((item) => arrayDates.push(item));
    datesFuture.forEach((item) => arrayDates.push(item));
    const arrayDatesSort = arrayDates
      .sort((a, b) => (moment(a, 'YYYY-MM-DD').isBefore(moment(b, 'YYYY-MM-DD')) ? -1 : 1));
    return arrayDatesSort;
  };

  const generateCountArrayDates = (currentMonth: string | number, currentYear: string | number) => {
    const totalDays = getDaysInMonth(`${currentYear}-${currentMonth}`);
    const firstDateInMonth = changeTimeZoneOfDate(`${currentYear}-${currentMonth}-01`, 'America/Sao_Paulo');
    const datesCurrent = Array
      .from({ length: totalDays }, (_, day: number) => moment(moment(firstDateInMonth).add(day + 1, 'days').toDate()));
    const arrayDates: Moment[] = [];
    datesCurrent.forEach((item) => arrayDates.push(item));
    const arrayDatesSort = arrayDates
      .sort((a, b) => (moment(a, 'YYYY-MM-DD').isBefore(moment(b, 'YYYY-MM-DD')) ? -1 : 1));
    return arrayDatesSort;
  };

  const reduceReservations = (array: Reservation[]) => array.reduce<ReservationsProperty>((acc,
    item) => ({
    ...acc,
    [item.check_in_date]: {
      ...item,
      paymentStatus: getPaymentStatus(item.status),
    },
  }), {});

  const [dates, setDates] = useState<Moment[]>(generateArrayDates(months[gridMonth], gridYear));

  const getPropertyReservations = async () => {
    const data: Reservation[] = await getReservations(
      dates,
      [propertyId],
      {
        mode,
      },
    );

    const dataFilteredForStatus = data.filter((item) => item.status !== 'Canceled');
    const dataReduce: ReservationsProperty = reduceReservations(dataFilteredForStatus);

    const bookings: ExistingReservationProps[] = [];
    dataFilteredForStatus.forEach((item) => {
      bookings.push({
        checkin: moment(item.check_in_date),
        checkout: moment(item.check_out_date),
      });
    });

    setReservations(dataReduce);
    setCheckinsDates(Object.keys(dataReduce));
    setPropertyInfos({
      existingBookings: bookings,
      hasExtraDayPreparation: dataFilteredForStatus?.[0]?.listing?.property?.extra_day_preparation !== 0,
    });
  };

  const handlePrevMonth = () => {
    handleDirection('preview');
    setGridYear((prevYear: number) => ((gridMonth - 1) < 0 ? prevYear - 1 : prevYear));
    setGridMonth((prevMonth: number) => ((prevMonth - 1) < 0 ? 11 : prevMonth - 1));
    const prevMonth = (gridMonth - 1) < 0 ? 11 : gridMonth - 1;
    const prevYear = (gridMonth - 1) < 0 ? gridYear - 1 : gridYear;
    setDaysInMonth(getDaysInMonth(`${prevYear}-${months[prevMonth]}`));
    const indexDayPast = convertDayNameToNumber(getDayStartMonth(`${prevYear}-${months[prevMonth]}`));
    setNumberOfPastDates(indexDayPast);
    const indexDayFuture = convertDayNameToNumber(getDayEndMonth(`${prevYear}-${months[prevMonth]}`));
    setNumberOfFutureDates(6 - indexDayFuture);
  };

  const handleNextMonth = () => {
    handleDirection('next');
    setGridYear((prevYear: number) => ((gridMonth + 1) > 11 ? prevYear + 1 : prevYear));
    setGridMonth((prevMonth: number) => ((prevMonth + 1) > 11 ? 0 : prevMonth + 1));
    const nextMonth = (gridMonth + 1) > 11 ? 0 : gridMonth + 1;
    const nextYear = (gridMonth + 1) > 11 ? gridYear + 1 : gridYear;
    setDaysInMonth(getDaysInMonth(`${nextYear}-${months[nextMonth]}`));
    const indexDayPast = convertDayNameToNumber(getDayStartMonth(`${nextYear}-${months[nextMonth]}`));
    setNumberOfPastDates(indexDayPast);
    const indexDayFuture = convertDayNameToNumber(getDayEndMonth(`${nextYear}-${months[nextMonth]}`));
    setNumberOfFutureDates(6 - indexDayFuture);
  };

  type InclusivityOptions = '[]' | '()' | '[)' | '(]';
  const checkIfCurrentDateIsBeetweenCheckinAndCheckoutDates = (
    currentDate: Moment, date1: Moment, date2: Moment, inclusivity: InclusivityOptions = '[]',
  ) => {
    const isBetween = moment(currentDate, 'DD/MM/YYYY').isBetween(
      moment(date1, 'DD/MM/YYYY'), moment(date2, 'DD/MM/YYYY'), 'days', inclusivity,
    );

    return isBetween;
  };

  const checkIfIsSameDate = (date1: Moment, date2: Moment) => {
    const test = moment(date1.format('DD/MM/YYYY'), 'DD/MM/YYYY').isSame(moment(date2.format('DD/MM/YYYY'), 'DD/MM/YYYY'));
    return test;
  };

  const checkIfIsPastDate = (date: Moment) => {
    const firstDateInMonth = changeTimeZoneOfDate(`${gridYear}-${months[gridMonth]}-01`, 'America/Sao_Paulo');
    const test = moment(date.format('DD/MM/YYYY'), 'DD/MM/YYYY').isBefore(moment(moment(firstDateInMonth), 'DD/MM/YYYY'));
    return test;
  };

  const checkIfIsFutureDate = (date: Moment) => {
    const totalDays = getDaysInMonth(`${gridYear}-${months[gridMonth]}`);
    const lastDateInMonth = changeTimeZoneOfDate(`${gridYear}-${months[gridMonth]}-${totalDays}`, 'America/Sao_Paulo');
    const lastDate = changeTimeZoneOfDate(lastDateInMonth, 'America/Sao_Paulo');
    lastDate.setDate(lastDate.getDate() + 1);
    const test = moment(date.format('DD/MM/YYYY'), 'DD/MM/YYYY').isAfter(moment(moment(lastDate), 'DD/MM/YYYY'));
    return test;
  };

  const calculateSizeReservation = (reservation: Record<string, Reservation>, date: Moment) => {
    let size: number = 0;
    size = moment(reservation[date.format('DD/MM/YYYY')]?.check_out_date, 'DD/MM/YYYY')
      .diff(moment(reservation[date.format('DD/MM/YYYY')]?.check_in_date, 'DD/MM/YYYY'),
        'day') + 1;
    const checkinIsBefore = moment(reservation[date.format('DD/MM/YYYY')]?.check_in_date, 'DD/MM/YYYY')
      .isBefore(moment(dates[0], 'DD/MM/YYYY'));
    size = checkinIsBefore
      ? (moment(reservation[date.format('DD/MM/YYYY')]?.check_out_date, 'DD/MM/YYYY')
        .diff(moment(dates[0], 'DD/MM/YYYY'), 'day') + 1) : size;
    return Math.abs(size);
  };

  const disableSelect = (bookings: Record<string, Reservation>, date: Moment) => {
    if (bookings) {
      const filterbookings = Object.values(bookings).filter((booking: Reservation) => (
        moment(date, 'DD/MM/YYYY')
          .isBetween(moment(booking.check_in_date, 'DD/MM/YYYY'),
            moment(booking.check_out_date, 'DD/MM/YYYY'))
        && moment(date).format('DD/MM/YYYY') !== booking.check_in_date
      ));
      return filterbookings.length > 0;
    }
    return false;
  };

  const handleOpenReservation = (reservationSelected: Reservation) => {
    setOpenModalReservation(true);
    setReservationClicked(reservationSelected);
  };

  const getDiffBetweenDatesInDays = (
    date: string, checkInDate: string, checkOutDate: string, isLast: boolean,
  ) => {
    let totalDays;
    if (moment(date, 'DD/MM/YYYY') > moment(checkInDate, 'DD/MM/YYYY')) {
      totalDays = moment(checkOutDate, 'DD/MM/YYYY').diff(moment(date, 'DD/MM/YYYY'), 'day');
    } else {
      totalDays = moment(checkOutDate, 'DD/MM/YYYY').diff(moment(checkInDate, 'DD/MM/YYYY'), 'day');
    }
    if ((checkOutDate.split('/')[0] === '31' || checkOutDate.split('/')[0] === '30' || checkOutDate.split('/')[0] === '29' || checkOutDate.split('/')[0] === '28') && isLast) {
      totalDays += 1;
    }
    return totalDays;
  };

  const leapYear = (y: number) => {
    if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) {
      return true;
    }
    return false;
  };

  const chooseMonths31 = (month: string) => {
    switch (month) {
      case '01':
        return true;
      case '03':
        return true;
      case '05':
        return true;
      case '07':
        return true;
      case '08':
        return true;
      case '10':
        return true;
      case '12':
        return true;
      default:
        return false;
    }
  };

  const chooseMonths30 = (month: string) => {
    switch (month) {
      case '04':
        return true;
      case '06':
        return true;
      case '09':
        return true;
      case '11':
        return true;
      default:
        return false;
    }
  };

  const handleCounterReservations = () => {
    let counterLocks = 0;
    let counterBookings = 0;
    let calcDays = 0;
    const newDates: Moment[] = generateCountArrayDates(months[gridMonth], gridYear);
    const oldCheckin: Array<string> = [];
    newDates.forEach((date: Moment) => {
      checkinsDates.forEach((checkinDate: string) => {
        if (checkIfCurrentDateIsBeetweenCheckinAndCheckoutDates(
          moment(date, 'YYYY-MM-DD'), moment(checkinDate, 'YYYY-MM-DD'), moment(reservations && reservations[checkinDate]?.check_out_date, 'YYYY-MM-DD'), '[)',
        ) && reservations && reservations[checkinDate] && reservations[checkinDate].status !== 'Canceled' && oldCheckin.filter((item) => item === reservations[checkinDate].check_in_date).length === 0) {
          const splittedReservationCheckin = reservations[checkinDate].check_in_date.split('-');
          const splittedReservationCheckout = reservations[checkinDate].check_out_date.split('-');

          if (splittedReservationCheckin[1] === splittedReservationCheckout[1]) {
            calcDays = getDiffBetweenDatesInDays(
              date.format('DD/MM/YYYY'),
              `${splittedReservationCheckin[2]}/${splittedReservationCheckin[1]}/${splittedReservationCheckin[0]}`,
              `${splittedReservationCheckout[2]}/${splittedReservationCheckout[1]}/${splittedReservationCheckout[0]}`,
              false,
            );

            if (reservations[checkinDate].is_blocking) {
              counterLocks += calcDays;
            } else {
              counterBookings += calcDays;
            }
          } else if (splittedReservationCheckin[1] !== splittedReservationCheckout[1] && splittedReservationCheckin[1] === months[gridMonth]) {
            let lastDay = '';
            if (chooseMonths31(splittedReservationCheckin[1])) {
              lastDay = `31/${splittedReservationCheckin[1]}/${splittedReservationCheckin[0]}`;
            } else if (chooseMonths30(splittedReservationCheckin[1])) {
              lastDay = `30/${splittedReservationCheckin[1]}/${splittedReservationCheckin[0]}`;
            } else if (splittedReservationCheckin[1] === '02') {
              if (leapYear(Number(splittedReservationCheckin[0])) === false) {
                lastDay = `28/${splittedReservationCheckin[1]}/${splittedReservationCheckin[0]}`;
              } else {
                lastDay = `29/${splittedReservationCheckin[1]}/${splittedReservationCheckin[0]}`;
              }
            }
            calcDays = getDiffBetweenDatesInDays(
              date.format('DD/MM/YYYY'),
              `${splittedReservationCheckin[2]}/${splittedReservationCheckin[1]}/${splittedReservationCheckin[0]}`,
              lastDay,
              true,
            );
            if (reservations[checkinDate].is_blocking) {
              counterLocks += calcDays;
            } else {
              counterBookings += calcDays;
            }
          } else if (splittedReservationCheckin[1] !== splittedReservationCheckout[1] && splittedReservationCheckout[1] === months[gridMonth]) {
            calcDays = getDiffBetweenDatesInDays(
              date.format('DD/MM/YYYY'),
              `${splittedReservationCheckin[2]}/${splittedReservationCheckin[1]}/${splittedReservationCheckin[0]}`,
              `${splittedReservationCheckout[2]}/${splittedReservationCheckout[1]}/${splittedReservationCheckout[0]}`,
              false,
            );
            if (reservations[checkinDate].is_blocking) {
              counterLocks = calcDays;
            } else {
              counterBookings = calcDays;
            }
            if (reservations[checkinDate].is_blocking) {
              counterLocks = calcDays;
            } else {
              counterBookings = calcDays;
            }
          } else if (reservations[checkinDate].is_blocking) {
            counterLocks = getDaysInMonth(`${gridYear}-${months[gridMonth]}`);
          } else {
            counterBookings = getDaysInMonth(`${gridYear}-${months[gridMonth]}`);
          }

          oldCheckin.push(reservations[checkinDate].check_in_date);
        }
      });
    });
    setCounterDailysLocks(counterLocks);
    setCounterDailysBookings(counterBookings);
    const rate = (daysInMonth - counterLocks) === 0
      ? 0 : (counterBookings / (daysInMonth - counterLocks));
    setOccupancyRate(rate > 1 ? 100 : Number((rate * 100).toFixed(0)));
  };

  useEffect(() => {
    if (selectInModal) {
      const indexDayPast = convertDayNameToNumber(getDayStartMonth(`${gridYear}-${months[gridMonth]}`));
      setNumberOfPastDates(indexDayPast);
      const indexDayFuture = convertDayNameToNumber(getDayEndMonth(`${gridYear}-${months[gridMonth]}`));
      setNumberOfFutureDates(6 - indexDayFuture);
      setSelectInModal(false);
    }
    const arrayDates = generateArrayDates(months[gridMonth], gridYear);
    setDates(arrayDates);
    const newDateSelected = `${gridMonth + 1 > 11 ? gridYear + 1 : gridYear}-${months[gridMonth + 1 > 11 ? 0 : gridMonth + 1]}-01`;
    const newDate = changeTimeZoneOfDate(newDateSelected, 'America/Sao_Paulo');
    handleDateSelected(newDate);
  }, [gridMonth, gridYear, selectInModal]);

  useEffect(() => {
    getPropertyReservations();
    setReload(false);
  }, [dates, id, reload, propertiesIdsOwnerLogged]);

  useEffect(() => {
    handleCounterReservations();
  }, [checkinsDates]);

  useEffect(() => {
    if (openModalReservation) {
      setOpenModalPropertyLock(false);
    }
  }, [openModalReservation]);

  return (
    <>
      <CalendarContext.Provider
        value={{
          getReservation: getPropertyReservations,
        }}
      >
        <Container>
          <ContainerTitle>
            <WrapperTitle>
              <Title>Imóvel</Title>
              <SelectorContainer>
                <PropertiesSelector dataCy="select-properties-list" />
              </SelectorContainer>
            </WrapperTitle>
            <WrapperMonth>
              <ArrowBackIosIcon data-cy="btn-arrow-left" onClick={() => handlePrevMonth()} />
              <ButtonMonth data-cy={`txt-current-month_${translateMonth(months[gridMonth])}-${gridYear}`} type="button">{`${translateMonth(months[gridMonth])}/${gridYear}`}</ButtonMonth>
              <ArrowForwardIosIcon data-cy="btn-arrow-right" onClick={() => handleNextMonth()} />
            </WrapperMonth>
            <WrapperButton>
              {!isViewMode && (
                <FormButton
                  dataCy="btn-request-block"
                  type="button"
                  onClick={() => setOpenModalPropertyLock(true)}
                >
                  Solicitar bloqueio
                </FormButton>
              )}
            </WrapperButton>
          </ContainerTitle>
          <ModalPropertyLock
            openModalPropertyLock={openModalPropertyLock}
            onClose={setOpenModalPropertyLock}
            showOnlyHeader
            propertyCode={propertyCode}
            propertyId={Number(propertyId)}
            dataCy="modal-2"
            propertyInfos={propertyInfos}
          />
          <ContainerCalendar>
            <DaysOfWeek className="days-of-week">
              {days.map((day, indexDay) => (
                <DayContainer key={`${day}-${uuid()}`}>
                  <DayContent
                    isWeekend={indexDay === 5 || indexDay === 6}
                    mobile={widthWindow <= 780}
                  >
                    {days[indexDay]}
                  </DayContent>
                </DayContainer>
              ))}
            </DaysOfWeek>
            <DateGrid>
              {dates.map((date: Moment, indexDate: number) => (
                <DateContainer
                  key={date.format('YYYY-MM-DD')}
                  ref={refDiv}
                  isLastDay={indexDate === (dates.length - 1)}
                  isPast={checkIfIsPastDate(date)}
                  isFuture={checkIfIsFutureDate(date)}
                  disableSelect={
                    (checkIfIsPastDate(date) || checkIfIsFutureDate(date))
                      ? true : reservations && disableSelect(reservations, date)
                  }
                  onClick={
                    (reservations && disableSelect(reservations, date)) || openModalReservation
                      || (checkIfIsPastDate(date) || checkIfIsFutureDate(date))
                      ? () => { } : () => setOpenModalPropertyLock(true)
                  }
                >
                  <div className="day-number">
                    <DayNumber mobile={widthWindow <= 780}>{date.format('DD')}</DayNumber>
                  </div>
                  <div className="daily-price">
                    <DailyPrice mobile={widthWindow <= 780}>R$300,00</DailyPrice>
                  </div>
                  {checkinsDates
                    .map((checkinDate
                    : string) => checkIfCurrentDateIsBeetweenCheckinAndCheckoutDates(
                      moment(date, 'YYYY-MM-DD'), moment(checkinDate, 'YYYY-MM-DD'), moment(reservations && reservations[checkinDate]?.check_out_date, 'YYYY-MM-DD'),
                    ) && reservations && reservations[checkinDate] && reservations[checkinDate].status !== 'Canceled' && (
                    <div
                      key={checkinDate}
                      className="reservation-marker"
                      data-cy={reservations[checkinDate]?.is_blocking ? `blocking_${moment(checkinDate).format('YYYY-MM-DD')}` : `reservation_${checkinDate?.split('/')[2]}-${checkinDate?.split('/')[1]}-${checkinDate?.split('/')[0]}`}
                    >
                      <ReservationMarker
                        dataCy={reservations[checkinDate]?.is_blocking ? `marker_blocking_${moment(checkinDate).format('YYYY-MM-DD')}` : `marker_reservation_${checkinDate?.split('/')[2]}-${checkinDate?.split('/')[1]}-${checkinDate?.split('/')[0]}`}
                        ota={reservations[checkinDate].listing.ota}
                        blocked={reservations[checkinDate].is_blocking}
                        reason={reservations[checkinDate].blocking_reason}
                        paymentStatus={reservations[checkinDate].paymentStatus}
                        reservationStatus={reservations[checkinDate].status}
                        size={calculateSizeReservation(reservations, date) > 7
                          ? 7 : calculateSizeReservation(reservations, date)}
                        defaultSize={checkIfIsSameDate(date, moment(reservations[checkinDate]?.check_out_date, 'DD/MM/YYYY')) ? 1.5 : 2}
                        widthMarker={widthMarker}
                        id={propertyId.toString()}
                        client={`
                            ${reservations[checkinDate].guest.user.first_name !== 'Dummy'
                          ? reservations[checkinDate].guest.user.first_name : 'Bloqueio'}
                            ${reservations[checkinDate].guest.user.last_name !== 'Dummy'
                            ? reservations[checkinDate].guest.user.last_name : reservations[checkinDate].blocking_reason}
                        `}
                        price={reservations[checkinDate].daily_net_value || 0}
                        reservationData={reservations[checkinDate]}
                        isInitMarker={checkIfIsSameDate(date, moment(checkinDate, 'YYYY-MM-DD'))}
                        isEndMarker={checkIfIsSameDate(date, moment(reservations[checkinDate]?.check_out_date, 'YYYY-MM-DD'))}
                        handleClick={() => handleOpenReservation(reservations[checkinDate])}
                        totalDays={moment(reservations[checkinDate]?.check_out_date, 'YYYY-MM-DD')
                          .diff(moment(reservations[checkinDate]?.check_in_date, 'YYYY-MM-DD'),
                            'day') + 1}
                        isShowingMarker
                        mobile={widthWindow <= 780}
                        isBookingOrBlockAutomatic={
                          reservations[checkinDate].is_last_minute
                          || reservations[checkinDate].is_block_for_pricing
                        }
                        indexDate={indexDate}
                      />
                    </div>
                    ))}
                </DateContainer>
              ))}
            </DateGrid>
          </ContainerCalendar>
          <WrapperReservation>
            <ContentReservation>
              <Circle status="available" />
              <p>Reserva</p>
              <p>
                <strong>{counterDailysBookings}</strong>
                noites
              </p>
            </ContentReservation>
            <ContentReservation>
              <Circle status="unavailable" />
              <p>Bloqueado</p>
              <p>
                <strong>{counterDailysLocks}</strong>
                noites
              </p>
            </ContentReservation>
            <ContentReservation>
              <Circle status="rate" />
              <p>Taxa de ocupação</p>
              <strong>
                {occupancyRate}
                %
              </strong>
              <TooltipInfo
                title="Taxa de ocupação"
                subtitle="O percentil apresentado é calculado com base nas noites disponíveis para locação. Em situações de bloqueio, é relevante destacar que essas noites não são consideradas como disponíveis no cálculo do percentil."
                tooltipColor="#000"
              />
            </ContentReservation>
          </WrapperReservation>
        </Container>

        {reservationClicked && (
          <ModalReservation
            open={openModalReservation}
            onClose={setOpenModalReservation}
            guestName={`
          ${reservationClicked.guest.user.first_name !== 'Dummy'
              ? reservationClicked.guest.user.first_name : 'Bloqueio'}
          ${reservationClicked.guest.user.last_name !== 'Dummy'
                ? reservationClicked.guest.user.last_name : reservationClicked.blocking_reason}
          `}
            adultGuests={reservationClicked?.adult_guest_quantity || 1}
            childrenGuests={reservationClicked?.child_guest_quantity || 0}
            petIndicator={reservationClicked?.has_pet > 0 || false}
            blocked={reservationClicked?.is_blocking || false}
            reason={reservationClicked?.blocking_reason || ''}
            startDate={reservationClicked?.check_in_date || ''}
            endDate={reservationClicked?.check_out_date || ''}
            totalPrice={reservationClicked?.total_price || 0}
            ota={reservationClicked?.listing?.ota || null}
            notes={reservationClicked?.comment || ''}
            id={reservationClicked.id}
            reservation={reservationClicked}
            dailyNetValue={reservationClicked?.daily_net_value || 0}
          />
        )}
      </CalendarContext.Provider>
    </>
  );
};
export default GridCalendar;
