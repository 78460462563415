import {
  Menu as MuiMenu,
} from '@mui/material';

import {
  sx,
  Item,
  Title,
  Container,
} from './style';

import { IIndicationsStatus } from '../../../../..';
import { usePartnerIndicate } from '../../../../../../../context/Partners/IndicateContext/IndicateContext';
import { findStatus } from '../../../../../utils/indication';

interface IMenu {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

interface IFilterOption {
  text: string;
  onClick: IIndicationsStatus | undefined;
}

const filterOptions: IFilterOption[] = [
  { onClick: 'Lost', text: 'Perda' },
  { onClick: 'Won', text: 'Aprovado' },
  { onClick: 'Canceled', text: 'Cancelado' },
  { onClick: 'In_Progress', text: 'Em andamento' },
];

const Menu = ({ anchorEl, open, handleClose }: IMenu) => {
  const { activeFilter, setActiveFilter } = usePartnerIndicate();

  const handleDefineActiveItem = (text: string) => {
    if (text === 'Todos' && !activeFilter) return true;
    if (text === (activeFilter && findStatus(activeFilter).translated)) return true;

    return false;
  };

  const handleSelectFilter = (text: IIndicationsStatus | undefined) => {
    setActiveFilter(text);
    handleClose({}, 'backdropClick');
  };

  return (
    <MuiMenu
      open={open}
      disablePortal
      id="account-menu"
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorReference="anchorEl"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      PaperProps={{
        sx,
        elevation: 2,
      }}
    >
      <Container>
        <Title>Filtrar por:</Title>
        <Item
          onClick={() => handleSelectFilter(undefined)}
          active={handleDefineActiveItem('Todos')}
        >
          Todos
        </Item>

        {filterOptions.map((option) => (
          <Item
            key={option.text}
            onClick={() => handleSelectFilter(option.onClick)}
            active={handleDefineActiveItem(option.text)}
          >
            {option.text}
          </Item>
        ))}
      </Container>
    </MuiMenu>
  );
};

export default Menu;
