import React, {
  useState, useEffect, createContext, memo, useCallback,
} from 'react';

import {
  Container,
  Content,
  ContainerInformation,
  Information,
} from './styles';

import Header from '../Header';
import AddManualFitHost from '../ManualFit/ManualFitHost/AddManualFitHost';
import EditManualFitHost from '../ManualFit/ManualFitHost/EditManualFitHost';
import GridHost from '../Grid/GridHost';
import GridSkeleton from '../LoadingShimmer/Grid/GridSkeleton';

import { postConsolidationTask } from '../../../services/FinancialClose/request';

import {
  getFinancialCloseHost,
  patchRevenuesHost,
} from '../../../services/FinancialClose/Host/request';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../utils/Messages';
import { useFinancialClose } from '../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseHost } from '../../../hooks/FinancialCloseHook/useFinancialCloseHost';
import { useAbortRequest } from '../../../hooks/AbortRequestHook/useAbortRequest';

import { FinancialCloseParams, RevenuesHostProps, Status } from '../../../services/FinancialClose/Host/types';
import { formatDate } from '../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { GridHostProps } from '../../../context/FinancialClosePage/FinancialCloseHostContext/types';
import FranchiseFeeHost from '../FranchiseFee/FranchiseFeeHost';

export interface HostCloseContextProps {
  addFinancialCloseHost: Function;
  refetchSelectedHosts: Function;
  validationRevenuesHost: Function;
}

export const HostCloseContext = createContext<HostCloseContextProps>({
  addFinancialCloseHost: (params: FinancialCloseParams) => params,
  refetchSelectedHosts: (params: FinancialCloseParams) => params,
  validationRevenuesHost: (revenues: RevenuesHostProps) => revenues,
});

const HostClose: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();
  const { dateSelected, search } = useFinancialClose();

  const {
    financialDataHost,
    handleSetFinancialDataHost,
    handleUpdateFinancialDataHost,
    openModalAddManualFitHost,
    openModalEditManualFitHost,
    openModalFranchiseFeeHost,
  } = useFinancialCloseHost();

  const { abortController, setAbortController } = useAbortRequest();

  const getFinancialClose = useCallback(async (params: FinancialCloseParams) => {
    try {
      setLoading(true);
      const response: GridHostProps[] | any = await getFinancialCloseHost(params, abortController);
      handleSetFinancialDataHost([...response]);
      setLoading(false);
    } catch (e: unknown) {
      if (e instanceof Error && !abortController?.signal?.aborted) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
      setLoading(false);
    }
  }, [financialDataHost]);

  useEffect(() => {
    setAbortController(() => new AbortController());
    return () => {
      abortController.abort();
    };
  }, [getFinancialClose]);

  const refetchSelectedHosts = useCallback(async (params: FinancialCloseParams,
    consolidateTask: boolean = false) => {
    try {
      if (consolidateTask) {
        await postConsolidationTask({
          task: 'host',
          date: params.start_date,
          hosts: params.hosts?.map((host) => Number(host)),
        });
      }

      const response: GridHostProps[] | any = await getFinancialCloseHost(params);
      handleUpdateFinancialDataHost(financialDataHost, response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoading(false);
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  }, [financialDataHost]);

  const validationRevenuesHost = useCallback(async (
    revenues: RevenuesHostProps,
    params: FinancialCloseParams,
    status: Status,
  ) => {
    try {
      await patchRevenuesHost(revenues);
      const response: GridHostProps[] | any = await getFinancialCloseHost(params);
      handleUpdateFinancialDataHost(financialDataHost, response);
      toast.success(`Dados ${status === 'Closed' ? 'validados' : 'desvalidados'} com sucesso!`);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  }, [financialDataHost]);

  useEffect(() => {
    let params = formatDate(dateSelected);
    if (search !== '') {
      params = {
        ...params,
        search,
      };
    }
    getFinancialClose(params);
  }, [dateSelected]);

  useEffect(() => {
    if (!loading && financialDataHost.length === 0) {
      toast.alert('Não há registros para o mês selecionado');
    }
  }, [loading]);

  return (
    <HostCloseContext.Provider
      value={{
        addFinancialCloseHost: getFinancialClose,
        refetchSelectedHosts,
        validationRevenuesHost,
      }}
    >
      <Container>
        <Content>
          <Header />
          {loading ? (
            <GridSkeleton type="host" />
          ) : (
            <>
              <GridHost />
            </>
          )}
          {!loading && financialDataHost.length === 0 && (
          <ContainerInformation>
            <Information>
              Fechamento financeiro não realizado
            </Information>
          </ContainerInformation>
          )}
        </Content>
      </Container>

      {openModalAddManualFitHost && <AddManualFitHost />}
      {openModalEditManualFitHost && <EditManualFitHost />}
      {openModalFranchiseFeeHost && <FranchiseFeeHost />}

    </HostCloseContext.Provider>
  );
};

export default memo(HostClose);
