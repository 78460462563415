import React, {
  FC,
  ReactElement,
  useState,
  useMemo,
  useCallback,
} from 'react';

import { createContext } from 'use-context-selector';
import { RequestCategory } from '../../services/Category/types';
import { GetCategoryLocationData } from '../../services/InsertData/types';
import { RequestLocation } from '../../services/location/types';

type InsertPropertyCategoryLocationProps = {
  openModalCategoryLocation: boolean,
  setOpenModalCategoryLocation: (open: boolean) => void,
  categoriesLocations: GetCategoryLocationData[],
  setCategoriesLocations: (categoriesLocations: GetCategoryLocationData[]) => void,

  categories: RequestCategory[],
  setCategories: (newCategories: RequestCategory[]) => void,
  openModalNewCategoryCode: boolean,
  setOpenModalNewCategoryCode: (open: boolean) => void,
  handleAddCategory: (newCategory: RequestCategory) => void,

  locations: RequestLocation[],
  setLocations: (newCategories: RequestLocation[]) => void,
  openModalNewLocationCode: boolean,
  setOpenModalNewLocationCode: (open: boolean) => void,
  handleAddLocation: (newLocation: RequestLocation) => void,

  isNewCategoryLocation: boolean,
  setIsNewCategoryLocation: (isNewCategory: boolean) => void,
};

export const InsertPropertyCategoryLocationContext = createContext<
InsertPropertyCategoryLocationProps>({
  openModalCategoryLocation: false,
  setOpenModalCategoryLocation: (open: boolean) => open,

  categoriesLocations: [],
  setCategoriesLocations: (categoriesLocations: GetCategoryLocationData[]) => categoriesLocations,

  categories: [],
  setCategories: (newCategories: RequestCategory[]) => newCategories,
  openModalNewCategoryCode: false,
  setOpenModalNewCategoryCode: (open: boolean) => open,
  handleAddCategory: (newCategory: RequestCategory) => newCategory,

  locations: [],
  setLocations: (newLocations: RequestLocation[]) => newLocations,
  openModalNewLocationCode: false,
  setOpenModalNewLocationCode: (open: boolean) => open,
  handleAddLocation: (newLocation: RequestLocation) => newLocation,

  isNewCategoryLocation: false,
  setIsNewCategoryLocation: (isNewCategory: boolean) => isNewCategory,
});

export const InsertPropertyCategoryLocationProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [openModalCategoryLocation, setOpenModalCategoryLocation] = useState<boolean>(false);
  const [categoriesLocations, setCategoriesLocations] = useState<GetCategoryLocationData[]>([]);

  const [categories, setCategories] = useState<RequestCategory[]>([]);
  const [openModalNewCategoryCode, setOpenModalNewCategoryCode] = useState<boolean>(false);
  const [locations, setLocations] = useState<RequestLocation[]>([]);
  const [openModalNewLocationCode, setOpenModalNewLocationCode] = useState<boolean>(false);
  const [isNewCategoryLocation, setIsNewCategoryLocation] = useState<boolean>(false);

  const handleOpenModalCategoryLocation = useCallback((open: boolean) => {
    setOpenModalCategoryLocation(open);
  }, []);

  const handleSetCategoriesLocations = useCallback((newCategoriesLocations:
  GetCategoryLocationData[]) => {
    setCategoriesLocations(newCategoriesLocations);
  }, []);

  const handleSetCategories = useCallback((newCategories: RequestCategory[]) => {
    setCategories(newCategories);
  }, []);

  const handleOpenModalNewCategoryCode = useCallback((open: boolean) => {
    setOpenModalNewCategoryCode(open);
  }, []);

  const handleAddCategory = useCallback((newCategory: RequestCategory) => {
    setCategories((prevState) => [...prevState, { ...newCategory }]);
  }, []);

  const handleSetLocations = useCallback((newLocations: RequestLocation[]) => {
    setLocations(newLocations);
  }, []);

  const handleOpenModalNewLocationCode = useCallback((open: boolean) => {
    setOpenModalNewLocationCode(open);
  }, []);

  const handleAddLocation = useCallback((newLocation: RequestLocation) => {
    setLocations((prevState) => [...prevState, { ...newLocation }]);
  }, []);

  const handleSetIsNewCategoryLocation = useCallback((isNewCategory: boolean) => {
    setIsNewCategoryLocation(isNewCategory);
  }, []);

  const value = useMemo(() => ({
    openModalCategoryLocation,
    setOpenModalCategoryLocation: handleOpenModalCategoryLocation,
    categoriesLocations,
    setCategoriesLocations: handleSetCategoriesLocations,
    categories,
    setCategories: handleSetCategories,
    openModalNewCategoryCode,
    setOpenModalNewCategoryCode: handleOpenModalNewCategoryCode,
    handleAddCategory,
    locations,
    setLocations: handleSetLocations,
    openModalNewLocationCode,
    setOpenModalNewLocationCode: handleOpenModalNewLocationCode,
    handleAddLocation,
    isNewCategoryLocation,
    setIsNewCategoryLocation: handleSetIsNewCategoryLocation,
  }), [
    openModalCategoryLocation,
    setOpenModalCategoryLocation,
    categoriesLocations,
    setCategoriesLocations,
    categories,
    setCategories,
    openModalNewCategoryCode,
    setOpenModalNewCategoryCode,
    handleAddCategory,
    locations,
    setLocations,
    openModalNewLocationCode,
    setOpenModalNewLocationCode,
    handleAddLocation,
    isNewCategoryLocation,
    setIsNewCategoryLocation,
  ]);

  return (
    <InsertPropertyCategoryLocationContext.Provider
      value={value}
    >
      {children}
    </InsertPropertyCategoryLocationContext.Provider>
  );
};
