import styled from 'styled-components';

const gap = '30px';

export const FormContainer = styled.div`
  margin-top: 50px;
  border-top: 2px dashed ${({ theme }) => theme.palette.grey._400.hex()};
  padding-top: ${gap};
  padding-bottom: 12vh;

  label{
    font-weight: bolder;
  }
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-gap: ${gap};
  grid-template-columns: repeat(2, 1fr);

  width: 100%;

  .MuiInputBase-formControl {
    background: #fff;
    border-radius: 10px;
  }

  .date-container-picker {
    min-width: 300px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gap};
`;

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gap};


  h1{
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;

export const Container = styled.form`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.h1`
  font-size: 1.3rem;
`;

export const ButtonsContainer = styled.div`
  right: 0;
  top: 100;
  bottom: 0;
  width: 100%;
  height: 12vh;
  display: flex;
  transition: 1s;
  position: fixed;
  max-width: 500px;
  background: #ebebf5;
  align-items: center;
  grid-area: "buttons";
  justify-content: space-between;
  border-top: 1px solid #00000018;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);

  button,
  svg {
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 900px) {
      margin: 0;
      margin-right: 3px;
    }

    @media (max-width: 320px) {
      margin: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 320px) {
    padding-right: 3rem;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  right: 0;
  bottom: 117px;
  position: absolute;
`;
