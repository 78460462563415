import {
  Pending,
  Complete,
  PaymentDataPropsItems,
  ResponsePaymentDataProps,
  ResponsePaymentDataStatus,
} from './types';

import {
  PaymentProps,
  PaymentItemsProps,
} from '../../context/PaymentVoucher/types/paymentStatus.types';

import {
  paymentDataInitialValues,
  paymentVoucherInitialValues,
  propertyAddressInitialValues,
  paymentDataInitialValuesItems,
  paymentVoucherItemsInitialValues,
} from '../../context/PaymentVoucher/utils';

import { checkEmptyString } from '../../utils/Formatter';
import { PaymentDataProps } from '../../context/PaymentVoucher/types/paymentData.types';

const handleFormattingPayValue = (value: number) => {
  const valueFormatted = value.toFixed(2);
  return Number(valueFormatted);
};

export const formatteMoneyVoucher = (value: number) => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format(value);

// Reservation Data Status

export const formatPaymentDataStatus = (data: ResponsePaymentDataStatus) => {
  const dataFormatted: PaymentProps = paymentVoucherInitialValues;

  let pendings: PaymentItemsProps[] = [];
  let completes: PaymentItemsProps[] = [];

  data.complete.forEach((item: Complete, index) => {
    let auxComplete: PaymentItemsProps = paymentVoucherItemsInitialValues;

    const date = item?.reservation?.check_in_date;
    const dateFormatted = new Date(date).toLocaleDateString();

    auxComplete = {
      id: index + 1,
      reservationId: item?.reservation?.id || 0,
      checkin: dateFormatted || 'Não informado',
      reservationCode: item?.reservation?.code || 'Não informado',
      reservationStaff: checkEmptyString(`${item?.reservation?.guest?.guest_user?.first_name} ${item?.reservation?.guest?.guest_user?.last_name}`) || 'Não informado',
      totalPaid: handleFormattingPayValue(item?.total_paid) || 0,
      totalValue: handleFormattingPayValue(item?.total_value) || 0,
      pendingValue: handleFormattingPayValue(item?.pending_value) || 0,
      propertyId: item?.property?.id || 0,
      propertyCode: item?.property?.code || 'Não informado',
      propertyAddress: item?.property?.address || propertyAddressInitialValues,
      checked: false,
    };

    completes = [...completes, { ...auxComplete }];
  });

  data.pending.forEach((item: Pending, index) => {
    let auxPending: PaymentItemsProps = paymentVoucherItemsInitialValues;

    const date = item?.reservation?.check_in_date;
    const dateFormatted = new Date(date).toLocaleDateString();

    auxPending = {
      id: index + 1,
      reservationId: item?.reservation?.id || 0,
      checkin: dateFormatted || 'Não informado',
      reservationCode: item?.reservation?.code || 'Não informado',
      reservationStaff: checkEmptyString(`${item?.reservation?.guest?.guest_user?.first_name} ${item?.reservation?.guest?.guest_user?.last_name}`) || 'Não informado',
      totalPaid: handleFormattingPayValue(item?.total_paid) || 0,
      totalValue: handleFormattingPayValue(item?.total_value) || 0,
      pendingValue: handleFormattingPayValue(item?.pending_value) || 0,
      checked: false,
      propertyId: item?.property?.id || 0,
      propertyCode: item?.property?.code || 'Não informado',
      propertyAddress: item?.property?.address || propertyAddressInitialValues,
    };

    pendings = [...pendings, { ...auxPending }];
  });

  dataFormatted.pendings = pendings;
  dataFormatted.completes = completes;

  return dataFormatted;
};

// Reservation Data

export const formatPaymentData = (data: ResponsePaymentDataProps) => {
  let dataFormatted: PaymentDataProps[] = paymentDataInitialValues;
  let auxFormatted: PaymentDataProps[] = [];

  data.forEach((item: PaymentDataPropsItems) => {
    let auxData: PaymentDataProps = paymentDataInitialValuesItems;

    const formatedDate = new Date(item?.date).toLocaleDateString();
    const customReservationStaff = checkEmptyString(`${item?.guest?.user.first_name} ${item?.guest?.user?.last_name}`) || 'Não informado';

    auxData = {
      id: item?.id || 0,
      amount: item?.value || 0,
      image: item.receipt_image || null,
      date: formatedDate || 'Não informado',
      depositedBy: item?.depositor || 'Não informado',
      reservationStaff: customReservationStaff,
    };

    auxFormatted = [...auxFormatted, { ...auxData }];
  });

  dataFormatted = auxFormatted;

  return dataFormatted;
};
