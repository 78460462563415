import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 1.4rem;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    opacity: 0.6;
  }

  a {
    display: flex;
    width: 100%;
    text-decoration: none;
  }

  button {
    border: none;
    width: 100%;
    padding: 10px 15px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    cursor: pointer;

    p {
      gap: 5px;
      opacity: 0.9;
      display: flex;
      flex-direction: column;
      font-size: 1.1rem;
      font-family: ${({ theme }) => theme.fonts.familys._6};
    }
  }
`;
