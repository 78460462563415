import styled, { css, keyframes } from 'styled-components';

const left = keyframes`
  from{
    opacity: 0;
    transform:  translateX(1rem);
  }
  to{
    opacity: 1;
  }
  `;

const right = keyframes`
  from{
    opacity: 0;
    transform:  translateX(-1rem);
  }
  to{
    opacity: 1;
  }
`;

const down = keyframes`
  from{
    opacity: 0;
    transform:  translateY(1rem);
  }
  to{
    opacity: 1;
  }
  `;

const up = keyframes`
  from{
    opacity: 0;
    transform:  translateY(-1rem);
  }
  to{
    opacity: 1;
  }
`;

export const WrapperContainer = styled.div`
  display: grid;
  grid-template-areas: 'menu calendar';
  overflow: hidden;

  @media (max-width: 900px) {
    grid-template-areas: 'calendar';
  }

`;

export const MenuContainer = styled.div`
  grid-area: menu;
  z-index: 10;

  @media (max-width: 900px) {
    grid-area: calendar;
  }
`;

export const CalendarContainer = styled.div`
  grid-area: calendar;

  overflow: auto;
  width: 100%;
  height: 100vh;

  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 15px;
    width: 15px;
    border: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
    background-color: ${({ theme }) => theme.palette.grey._400.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  .ReactVirtualized__List {
    width: max-content !important;
    height: max-content !important;
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    max-height: max-content !important;
    max-width: max-content !important;
    height: auto !important;
    width: 100% !important;
  }
`;

export const Container = styled.div<{ role: string }>`
  width: max-content;
  .calendar-navigation, .calendar-navigation-dates {
    height: auto;
    border-bottom: solid 1px ${({ theme }) => theme.palette.grey._400.hex()};

    &.calendar-navigation {
      width: 185px;
      z-index: 100;

      @media (max-width: 900px) {
        width: 130px;
      }

      @media (max-width: 415px) {
        width: 165px;
      }

      @media (max-width: 375px) {
        width: 145px;
      }
      @media (max-width: 320px) {
        width: 120px;
      }
    }
  }

  .calendar-property {
    width: 185px;
    min-width: 185px;
    z-index: 100;

    @media (max-width: 900px) {
      width: 130px;
      min-width: 130px;
    }

    @media (max-width: 415px) {
      width: 165px;
      min-width: 165px;
    }

    @media (max-width: 375px) {
      width: 145px;
      min-width: 145px;
    }

    @media (max-width: 320px) {
      width: 120px;
      min-width: 120px;
    }
  }
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 250;
  width: max-content;
  display: flex;

  @media (max-width: 500px) {
    position: fixed;
    margin-top: 65px;
  }
`;

export const Years = styled.div<{ center: boolean }>`
  margin-left: 185px;
  width: calc(100% - 200px);
  position: absolute;
  z-index: 15;
  height: 25px;
  display: flex;
  top: 0;
  align-items: center;
  padding: 0 10px;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  border-bottom: solid 1px ${({ theme }) => theme.palette.grey._400.hex()};
  background: ${({ theme }) => theme.palette.white.main.hex()};

  @media (max-width: 900px) {
    margin-left: 125px;
    width: calc(100% - 125px);
    justify-content: center;
  }

  @media (max-width: 500px) {
    margin-left: 165px;
    width: calc(100% - 165px);
  }

  @media (max-width: 375px) {
    margin-left: 145px;
    width: calc(100% - 145px);
  }

  @media (max-width: 320px) {
    margin-left: 120px;
    width: calc(100% - 120px);
  }
`;

export const Year = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.14em;
  color: ${({ theme }) => theme.palette.blue._950.hex()};
`;

export const Main = styled.div`
  display: flex;
  position: static;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  @media(max-width: 500px) {
    position: absolute;
    margin-top: 145px;
    .resize-triggers {
      display: none;
    }
  }
`;

export const PaginationContainer = styled.div`
  position: fixed;
  bottom: 20px;
  height: 30px;
  margin-left: 67px;
  width: 50px;
  z-index: 100;
  border-radius: 50px;
  background: ${({ theme }) => theme.palette.orange.main.hex()};
  opacity: 0.8;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const PaginationButton = styled.button
  .attrs({
    type: 'button',
  })<{ borderLeft?: boolean }>`
  cursor: pointer;
  height: 100%;
  padding: 0;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  border: none;
  background: transparent;
  width: 50%;
  transition: all .3s linear;
  ${({ borderLeft }) => (borderLeft ? 'border-radius: 50px 0 0 50px;' : 'border-radius: 0 50px 50px 0;')};

  svg {
    width: 25px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.green.main.hex()};
  }

  &:disabled {
    background: transparent;
    opacity: 0.5;
    cursor: default;
  }
`;

export const HorizontalArrow = styled.div<{ anchor?: string, isShowingArrow?: boolean, sidebarIsClosed?: boolean }>`
  width: 100px;
  height: calc(100vh - 80px);
  display: ${({ isShowingArrow }) => (isShowingArrow ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 15;
  cursor: pointer;
  svg{fill:${({ theme }) => theme.palette.orange.main.hex()}};

  ${({ anchor, sidebarIsClosed }) => (anchor === 'right'
    ? css`
      right: 1rem;
      background: linear-gradient(to left,#c2c2c285, transparent);
      animation: ${left} 0.4s;
    `
    : css`
      left: ${sidebarIsClosed ? 19 : 35}rem;
      background: linear-gradient(to right,#c2c2c285, transparent);
      animation: ${right} 0.4s;

      @media (max-width: 900px) {
        left: 12rem;
        display: block;
        background: none;
      }

      .icon{
        svg{margin-left: 8px}
      }
  `)}

  .icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;

  }

  &:hover {
    .icon{
      border: 2px solid ${({ theme }) => theme.palette.orange.main.hex()};
      background: linear-gradient(180deg, #EB6B57 0%, #CF4F44 100%);
      svg{fill:${({ theme }) => theme.palette.white.main.hex()}};
    }
  }

  @media (max-width: 500px) {
    width: 30px;
    display: flex;
    background: none;
  }
`;

export const VerticalArrow = styled.div<{ anchor?: string, isShowingArrow?: boolean }>`
  width: calc(100vw - 18px);
  height: 50px;
  display: ${({ isShowingArrow }) => (isShowingArrow ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 15;
  cursor: pointer;
  svg{fill:${({ theme }) => theme.palette.orange.main.hex()}};

  ${({ anchor }) => (anchor === 'up'
    ? css`
      top: 5.5rem;
      background: linear-gradient(to bottom,#c2c2c285, transparent);
      animation: ${up} 0.4s;

      @media (max-width: 580px) {
        top: 10.2rem;
        display: flex;
        background: none;
        padding-left: 165px;
      }

      .icon {
        svg{transform: rotate(-90deg);}
      }
      `
    : css`
      bottom: 1.2rem;
      background: linear-gradient(to top,#c2c2c285, transparent);
      animation: ${down} 0.4s;
      .icon {
        svg {transform: rotate(90deg);}
      }

      @media (max-width: 580px) {
        bottom: 7.25rem;
        display: flex;
        background: none;
        padding-left: 165px;
      }
  `)}

  .icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .icon{
      border: 2px solid ${({ theme }) => theme.palette.orange.main.hex()};
      background: linear-gradient(180deg, #EB6B57 0%, #CF4F44 100%);
      svg{fill:${({ theme }) => theme.palette.white.main.hex()}};
    }
  }
`;
