import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
  font-style: normal;
  font-family: Inter;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
`;
