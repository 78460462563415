import styled from 'styled-components';
import { ReportProblemOutlined } from '@mui/icons-material';

const MAX_SIZE_MOBILE = 500; // 500px

export const ContainerBox = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 600px;
  
  .messageError {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0;
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
  }
`;

export const ContentBox = styled.div`
  width: 100%;

  p, strong, span, h1, h2 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }

  .MuiFormControl-root > label {
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
`;

export const ButtonClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.orange._350.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 1.375rem;
  font-weight: 700;
  padding-bottom: 1rem;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 1.2rem;
  }
`;

export const DatesSelected = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    padding: 1rem 0;

    .time, .date {
      width: 100%;
    }
  }
`;

export const DateGrid = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;

  strong, span {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    line-height: 24px;
  }

  strong {
    font-weight: 700 !important;
    margin-right: 0.5rem;
  }
  
  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    font-weight: 400 !important;

    span {
      font-weight: 400 !important;
    }
    
    svg {
      fill: ${({ theme }) => theme.palette.blue._250.hex()};
      width: 16px;
      height: auto;
      margin-right: 0.5rem;
    }
  }

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: column;
    margin-left: 1.2rem;

    .time, .date {
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: column;

    button {
      width: 100%;
      padding: 1rem;
    }
  }
`;

export const ErrorIcon = styled(ReportProblemOutlined)`
  && {
    margin-right: 3px;
    height: 16px;
  }
`;

export const PhoneLabel = styled.div`
  margin: 0.25rem 0;
  div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    
    span {
      color: ${({ theme }) => theme.palette.orange.main.hex()} !important;
      padding: 0 0.3rem 0.3rem 0;
      font-size: 0.8rem;
    }

    label {
      font-size: 1rem;
      font-weight: 700;
      font-family: ${({ theme }) => theme.fonts.familys._6} !important;
    }
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    padding: 0.25rem 0;
  }
`;
