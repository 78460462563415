import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  grid-template-rows: 12% 45% 35%;
  grid-template-areas: 'header' 'body' 'footer';

  @media (max-width: 320px) {
    grid-template-rows: 10% 25% 35%;
  }

  @media (min-width: 321px) and (max-width: 400px) {
    grid-template-rows: 12% 40% 35%;
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  grid-area: 'body';
  margin-top: 1rem;
  
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 11px;
    width: 11px;
    border: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
    background-color: ${({ theme }) => theme.palette.grey._425.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const Content = styled.div`
  width: 98%;
  height: 100%;
`;

export const Header = styled.header`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  grid-area: 'header';
`;

export const Footer = styled.footer`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  grid-area: 'footer';
`;

export const Text = styled.p`
  width: 100%;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const TextBold = styled.p`
  width: 100%;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  margin: 1rem 0 1rem 0;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
`;

export const Title = styled.h2`
  width: 100%;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  font-family: Inter;
  font-size: 1.375rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  button {
    width: 100%;
  }
`;
