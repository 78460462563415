import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  margin: 50px 0;
  padding: 30px 30px;
  border-radius: 10px;
  background: transparent;
  justify-content: center;

  user-select: none;

  h1 {
    font-size: 1.3rem;
    color: ${({ theme }) => theme.palette.grey._550.hex()};
  }

  .cancel-button {
    background: transparent;
    text-align: left;
    justify-content: left;
    border: transparent;
    box-shadow: 0px 0px 0px transparent;
    &:hover {
      color: #cc7b7b;
      background: transparent;
      border: transparent;
    }
  }

  button {
    text-align: center;
    transition: 0.2s;
    gap: 10px;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    border-radius: 7px;
    border: 2px solid ${({ theme }) => theme.palette.grey._550.hex()};
    align-items: center;
    color: ${({ theme }) => theme.palette.grey._550.hex()};

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background: transparent;
      }
    }

    &:hover {
      background: #ffffff;
    }
  }

  border: 3px dashed ${({ theme }) => theme.palette.grey._550.hex()};
`;

export const Content = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;
