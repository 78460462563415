import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 2rem;
  }

  img {
    cursor: pointer;
    width: 15px;
  }
`;

export const Line = styled.div`
  width: 100%;
  background: #00000045;
  height: 1px;
  margin-top: 5px;
`;

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  user-select: none;
`;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  h1 {
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.1rem;
    font-weight: normal;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    img {
      width: 7px;
    }
  }
`;

export const ItemLine = styled.div``;

export const ReservationCode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ImmobileCode = styled.div``;

export const ReservationDate = styled.div``;

export const ReservationStaff = styled.div``;

export const WhatsApp = styled.div``;

export const CheckIn = styled.div``;

export const EditButton = styled.div``;
