import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;
