import styled from 'styled-components';

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonCanceled = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.orange.main.hex()};
  background: linear-gradient(
    360deg,
    ${({ theme }) => theme.palette.orange.main.hex()} 0%,
    ${({ theme }) => theme.palette.orange._600.hex()} 100%
  );
  border: none;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 600;
  cursor: pointer;
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 2rem;
  line-height: 1.8rem;

  p, strong, span, h2 , textarea {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  }

  && strong {
    font-weight: bold;
  }

  && p, span {
    color: ${({ theme }) => theme.palette.grey._700.hex()};
    font-size: 1.3rem;
  }

  .notes {
    word-wrap: break-word;
    p{
      font-size: 1.1rem;
      color: ${({ theme }) => theme.palette.grey._900.hex()};
      margin-bottom: -1.7rem;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => (theme.palette.red._200.hex())};
  padding: 1rem;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
`;

export const CloseButtonContainer = styled.div`
  max-width: 7rem;
  height: auto;

  background: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.palette.grey._550.hex()};
  border-radius: 0.7rem;
  cursor: pointer;

  padding: 0.8rem;


  svg {
    margin-right: 0.2rem;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  @media(min-width: 320px)  and (max-width: 500px) {
    margin-bottom: 5rem;

    strong {
      display: none;
    }
  }
`;
