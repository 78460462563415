import React from 'react';

import SimpleSelect from '../SimpleSelect';
import { UseFormik } from '../../utils/Formik/types';

export interface GenderSelectProps {
  id?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  formik?: UseFormik<any>;
}

const GenderSelect: React.FC<GenderSelectProps> = ({
  id = 'gender',
  placeholder = 'Selecione',
  label = '*Gênero',
  disabled = false,
  formik,
}) => (
  <SimpleSelect
    id={id}
    formik={formik}
    label={label}
    disabled={disabled}
    placeholder={placeholder}
    options={[
      {
        value: 'Female',
        valueLabel: 'Feminino',
      },
      {
        value: 'Male',
        valueLabel: 'Masculino',
      },
      {
        value: 'Not informed',
        valueLabel: 'Não informado',
      },
    ]}
  />
);

export default GenderSelect;
