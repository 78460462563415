import styled from 'styled-components';

export const RefundDamageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .label {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #394760 !important;
    font-family: 'Inter' !important;
  }
`;

export const RefundDamageHeaderContentContainer = styled.div`
  display: flex;
  gap: 5rem;
  width: 100%;
`;

export const RefundDamageHeaderItem = styled.div`
  display: flex;
  gap: 0.75rem;
  font-family: 'Inter';
  color: #394760;

  > p {
    font-size: 1rem;
    font-size: 0.75;
    span {
      font-weight: 600;
    }
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const RefundDamageHeaderColumnItem = styled.div`
  display: flex;
  gap: 0.75rem;
  font-family: 'Inter';
  color: #394760;
  flex-direction: column;

  > p {
    font-size: 1rem;
    font-size: 0.75;
    span {
      font-weight: 600;
    }
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin-top: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;
