import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 1.5rem;
  border-radius: 10px;
  margin: 40px 0;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};

  @media (max-width: 500px) {
    padding: 10px 0 0 0;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  line-height: 32px;

  @media (max-width: 900px) {
    font-size: 1.375rem;
    margin: 0.5rem 0 1rem 0.5rem;
  }
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0px;
    padding: 0 0.5rem;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  width: 100%;
  gap: 0.8rem;

  @media (max-width: 900px) {
    margin-bottom: 1rem;
    /* margin-left: 0.4rem; */
    width: calc(100% - 0.8rem);
  }
`;

export const ContainerBottom = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;

  button {
    border-radius: 10px;
    font-size: 1rem;
    border: 1px solid var(--Blue-500, #0d4bd0);
    background: var(--Blue-500, #0d4bd0);
    padding: 12px 16px;
    cursor: pointer;
    font-family: Inter;
    color: ${({ theme }) => theme.palette.white.main.hex()};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    white-space: nowrap;

    svg {
      width: 24px;
    }
  }

  @media (max-width: 1000px){
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      height: 35px;
      padding: 10px;
      color: var(--branco-100, #FFF);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export const ContentSelectProperty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 282px;

  background: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  border-radius: 10px;
  padding: 0.8rem 0.8rem 0.2rem 0.8rem;

  &:hover {
    border-color: ${({ theme }) => theme.palette.green.main.hex()};
    transition: 0.7s;
  }

  strong {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: start;
    margin-left: 0.8rem;
    color: ${({ theme }) => theme.palette.blue._970.hex()};
  }

  select {
    width: 160px !important;
  }

  fieldset {
    border: 0 !important;
    width: 100% !important;
  }

  .MuiSvgIcon-root {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    fill: ${({ theme }) => theme.palette.blue._970.hex()} !important;
    font-size: 2.5rem !important;

    @media (min-width: 900px) {
      position: relative !important;
      right: 0px !important;
      bottom: 2px !important;
    }
  }

  @media (max-width: 900px) {
    padding: 0 0.5rem;

    strong {
      display: none;
    }
    select,
    fieldset {
      width: 100% !important;
    }
    .MuiFormControl-root {
      min-width: 80px !important;
      max-width: 160px !important;
      gap: 0 !important;
    }
  }

  @media (min-width: 900px) {
    img {
      margin-top: 1.2rem;
    }
  }
`;

export const ContentSelectYear = styled(ContentSelectProperty)``;

export const ButtonDownload = styled.button`
  display: none;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  background: rgb(21, 27, 38);
  background: linear-gradient(360deg, rgba(21, 27, 38, 1) 0%, rgba(89, 93, 102, 1) 100%);
  color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  border: none;
  height: 38px;
  width: 163px;

  @media (max-width: 370px) {
    width: 120px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.palette.grey._340.hex()};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.grey._320.hex()};
    border-radius: 10px;
  }

  @media (min-width: 1500px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const GridContent = styled.div`
  width: max-content;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GridHeaderContainer = styled.div`
  width: max-content;
  height: 51px;
  background-color: ${({ theme }) => theme.palette.blue._900.hex()};

  display: grid;
  grid-template-columns: 210px 0vw repeat(13, 100px);

  @media (min-width: 1500px) and (max-width: 1706px) {
    grid-template-columns: 230px 8vw repeat(13, 100px);
  }

  align-items: center;

  border: 2px solid ${({ theme }) => theme.palette.grey._340.hex()};
  border-radius: 10px 10px 0px 0px;

  :first-child {
    padding: 1.125rem;
  }

  .highLigth {
    color: ${({ theme }) => theme.palette.grey._465.hex()};

    svg {
      color: ${({ theme }) => theme.palette.grey._465.hex()};
    }
  }
`;

export const GridHeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.palette.grey._580.hex()};

  :first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    background-color: ${({ theme }) => theme.palette.blue._900.hex()};
    border-right: 0;
  }

  :last-child {
    border-right: 0;
  }

  span,
  .txt-total {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: 700;
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 0.925rem;

    &:last-child {
      border-right: none;
    }
  }

  span,
  p {
    color: ${({ theme }) => theme.palette.white.main.hex()};
  }

  svg {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    width: 15px;
    height: 15px;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    cursor: pointer;
  }
`;

export const ExtractButtonContainer = styled.div<{ isVisible: boolean }>`
  width: 10px;
  height: auto;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;
