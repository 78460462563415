import React, { useEffect } from 'react';

import { Container } from './styles';
import EditDataComponent from '../../components/InsertData/EditData';

const EditData: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Edição de Dados';
  }, []);

  return (
    <Container>
      <EditDataComponent />
    </Container>
  );
};
export default EditData;
