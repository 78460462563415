import { useEffect, useState } from 'react';
import { UseFormik } from '../../../../../utils/Formik/types';
import RadioButton from '../../../../RadioButton';
import SimpleSelect from '../../../../SimpleSelect';
import TextField from '../../../../TextField';
import { Container } from './styles';
import { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import { compareList } from '../../../../../utils/Sorting';
import { getPartnerExecutivesRequest } from '../../../../../services/InsertData/request';
import { PartnerExecutive } from '../../../../../services/InsertData/types';

type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed' | undefined;

const maritalStatus = [
  {
    value: 'Single',
    valueLabel: 'Solteiro(a)',
  },
  {
    value: 'Married',
    valueLabel: 'Casado(a)',
  },
  {
    value: 'Divorced',
    valueLabel: 'Divorciado(a)',
  },
  {
    value: 'Widowed',
    valueLabel: 'Viúvo(a)',
  },
];

type FormState = 'user' | 'partner';
interface AddOwnerFormikValuesType {
  formType: FormState;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber1: string;
  phoneNumber2: string;
  gender: string;
  birthDate: Date | undefined;
  cpf?: string;
  cnpj?: string;
  corporateName: string;
  tradingName: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  nationality: string;
  maritalStatus: MaritalStatus;
  profession: string;
  emailForOperation: string;
  zipCode: string;
  partnerId?: number;
  isIndividual: boolean;
  signedContract: boolean;
  link: string,
  phoneNumberAttendent: string,
  attendent: string,
  executive: number,
  pipedrivePartnerId?: string,
  pipedrivePersonId?: string,
}
interface FormAddOwnerProps {
  formik?: UseFormik<AddOwnerFormikValuesType>;

}

const FormAddPartner = ({
  formik,

}: FormAddOwnerProps) => {
  const [executives, setExecutives] = useState<PartnerExecutive[]>([]);

  useEffect(() => {
    async function getPartnerExecutives() {
      const response = await getPartnerExecutivesRequest();
      setExecutives(response);
    }
    getPartnerExecutives();
  }, []);

  const formattedExecutiveNameOptions: SelectOption[] = executives
    .map((executive) => ({
      value: executive.id,
      valueLabel: executive.full_name,
    }))
    .sort((a, b) => compareList((a?.valueLabel || ''), (b?.valueLabel || '')));

  const formattedAttendantNameOptions: SelectOption[] = executives
    .map((executive) => ({
      value: executive.full_name,
      valueLabel: executive.full_name,
    }))
    .sort((a, b) => compareList((a?.valueLabel || ''), (b?.valueLabel || '')));

  return (
    <Container>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          value={formik?.values.nationality}
          id="nationality"
          label="Nacionalidade"
          placeholder="Digite aqui..."
          formik={formik}
        />
        <SimpleSelect
          id="maritalStatus"
          label="Estado civil"
          className="selector"
          options={maritalStatus}
          formik={formik}
          placeholder="Selecione..."
          labelClassName="labelClass"
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          id="profession"
          value={formik?.values.profession}
          label="Profissão"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="one-column">
        <RadioButton
          formLabel="Contrato assinado"
          id="signedContract"
          formik={formik}
          labelPlacement="end"
          options={[
            { value: 'true', label: 'Sim' },
            { value: 'false', label: 'Não' },
          ]}
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          id="link"
          label="Link para planilha de acompanhamento"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.link}
        />
      </div>
      <div className="one-column">
        <SimpleSelect
          required
          id="executive"
          label="Nome do executivo"
          labelClassName="labelClass"
          className="selector"
          options={formattedExecutiveNameOptions}
          formik={formik}
          placeholder="Selecione..."
        />
      </div>
      <div className="two-columns">
        <SimpleSelect
          required
          id="attendent"
          label="Atendente responsável"
          labelClassName="labelClass"
          className="selector"
          options={formattedAttendantNameOptions}
          formik={formik}
          placeholder="Selecione"
        />
        <TextField
          required
          labelClassName="labelClass"
          id="phoneNumberAttendent"
          label="Telefone do atendente"
          placeholder="Digite aqui..."
          formik={formik}
          mask="phone"
          value={formik?.values.phoneNumberAttendent}
        />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="pipedrivePersonId"
          label="ID da pessoa no pipedrive"
          placeholder="Digite aqui..."
          formik={formik}
          value={formik?.values.pipedrivePersonId}
        />
      </div>

    </Container>

  );
};
FormAddPartner.defaultProps = {
  formik: undefined,
};

export default FormAddPartner;
