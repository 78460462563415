import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  &:last-child {
    &:last-child {
      && .state-label {
        font-weight: normal !important;
      }
    }

    &&& label {
      font-weight: normal;
    }
  }
`;

export const TextBold = styled.div<{ isOnboardingPage?: boolean }>`
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  padding-bottom: 8px;
`;

export const StateAdjust = styled.div`
  margin-top: -4px;
`;
