import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Content = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100vh);
  flex-direction: column;
  justify-content: center;

  p {
    opacity: 0;
    margin-top: 24px;
    font-size: 1.1rem;
    font-weight: bold;
    animation: ${fadeIn} 0.4s 0.2s ease forwards;
    color: ${({ theme }) => theme.palette.grey._700.hex()};
  }
`;

export const General = styled.div<{
  exit: boolean;
}>`
  transform-origin: center center;

  ${({ exit }) => exit && 'overflow: hidden;'}
`;

export const Container = styled(motion.div)`
  gap: 20px;
  display: flex;
  flex-direction: column;
  transform-origin: center center;
`;
