import styled from 'styled-components';

export const PhotoModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 1000;

`;

export const PhotoModalContainer = styled.div`
  padding: 1rem;
  img{
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

`;
