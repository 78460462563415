import * as Yup from 'yup';
import { IListingDropDown, IListings } from '../types';

export const formattListDropdown = ({
  otas,
  listings,
  properties,
}: IListingDropDown) => {
  const formattedList = listings?.map((listing) => {
    const foundOta = otas.find((ota) => ota.id === listing.ota);
    const foundProperty = properties?.find((property) => property.id === listing.property);

    let formattedText = `${listing.id}.`;
    if (foundProperty) {
      formattedText = `${formattedText} ${foundProperty.code}`;
    }

    if (foundOta) {
      formattedText = `${formattedText} - ${foundOta.name}`;
    }
    formattedText = `${formattedText} - ${listing?.title_in_ota}`;

    return {
      optionText: formattedText,
      optionValue: `${listing.id}`,
      status: foundProperty?.status,
    };
  });

  const sortedList = formattedList?.sort(((a, b) => {
    if (a.status === 'Active' && b.status !== 'Active') {
      return -1;
    }
    if (a.status !== 'Active' && b.status === 'Active') {
      return 1;
    }
    return 0;
  }));
  return sortedList;
};

export const isEditingForm = [
  'editardados/listing',
  'editardados/listing/',
  '/editardados/listing',
  '/editardados/listing/',
].includes(window.location.pathname);

export const validation = Yup.object().shape({
  ota: Yup.number().required('Selecione a OTA').min(0, 'Campo obrigatório'),
  property: Yup.number()
    .required('Selecione o imóvel'),
  feeOTA: Yup.number()
    .typeError('A taxa de OTA precisa ser um número')
    .required('Campo obrigatório')
    .min(0, 'Digite um valor entre 0 e 100')
    .max(100, 'Digite um valor entre 0 e 100'),
});

export const getListingSelected = (listings: IListings, listingId: number) => {
  const hostFiltered = listings.filter((listing) => listing.id === listingId);
  return hostFiltered?.[0]?.title_in_ota || '';
};
