import { CopyItem, CopyItemContainer } from './styles';
import copyIcon from '../../../../../assets/icons/generals/copy.svg';
import { useToast } from '../../../../../context/ToastContext';

interface Option {
  message: string;
  text: string;
}

interface CopyItemButtonProps {
  options: Option[];
}
const Copy = () => (
  <img src={copyIcon} alt="icone de copiar" className="copyIcon" />
);

function CopyItems({ options }: CopyItemButtonProps) {
  const toast = useToast();
  function handleClick(text: string) {
    navigator.clipboard.writeText(text);
    toast.alert('Copiado!');
  }
  return (
    <CopyItemContainer>
      {options.map((option) => (
        <CopyItem onClick={() => handleClick(option.message)}>
          <h3>{option.text}</h3>
          <Copy />
        </CopyItem>
      ))}
    </CopyItemContainer>
  );
}

export default CopyItems;
