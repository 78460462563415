import { useEffect } from 'react';

import { ConfirmationAnimation } from '../../../../assets/animations';

import { useOnboardingPage } from '../../../../hooks/useOnboarding/useOnboarding';
import { useOnboardingHandover } from '../../../../context/OnboardingContext/OnboardingHandoverContext';

import { Container } from './styles';

const CardFinish = () => {
  const { handleClickPhase } = useOnboardingPage();
  const { setResetFormik } = useOnboardingHandover();

  useEffect(() => {
    setTimeout(() => {
      setResetFormik(true);
      handleClickPhase(0);
    }, 3000);
  }, []);

  return (
    <Container>
      <ConfirmationAnimation />
      <p>Cadastro realizado com sucesso!</p>
    </Container>
  );
};

export default CardFinish;
