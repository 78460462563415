/* eslint-disable no-nested-ternary */
import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { RequestQuote as DailyValueIcon } from '@mui/icons-material';

import airbnbLogo from '../../../../assets/icons/otas/airbnbLogo.svg';
import bookingLogo from '../../../../assets/icons/otas/bookingLogo.svg';
import expediaLogo from '../../../../assets/icons/otas/expediaLogo.svg';
import homeawayLogo from '../../../../assets/icons/otas/homeawayLogo.svg';
import temporadalivreLogo from '../../../../assets/icons/otas/temporadalivreLogo.svg';
import testadorLogo from '../../../../assets/icons/otas/testadorLogo.svg';
import staysLogo from '../../../../assets/icons/otas/staysLogo.svg';
import websiteLogo from '../../../../assets/icons/otas/websiteLogo.svg';
import seazoneLogo from '../../../../assets/icons/otas/seazoneLogo.svg';
import decolarLogo from '../../../../assets/icons/otas/decolarLogo.svg';

import blockCleaning from '../../../../assets/icons/multicalendar/blockCleaning.svg';
import blockHost from '../../../../assets/icons/multicalendar/blockHost.svg';
import blockMaintenance from '../../../../assets/icons/multicalendar/blockMaintenance.svg';
import blockOwner from '../../../../assets/icons/multicalendar/blockOwner.svg';
import blockPreparation from '../../../../assets/icons/multicalendar/blockPreparation.svg';

import { PaymentStatus, ReservationStatus, Reservation } from '../../../../services/Reservation/types';
import { RequestOta } from '../../../../services/Property/types';

import { numberToCurrency } from '../../../../utils/Formatter';
import { translateBlockingReason } from '../../../../utils/Translator';

import { useCheckBox } from '../../../../context/CheckBoxContext';

import Tooltip from '../../../Tooltip';

import {
  Marker,
  MarkerContainerIcon,
  MarkerContainer,
  Indicators,
} from './styles';

interface PropsMaker {
  id?: string;
  size: number;
  defaultSize?: number;
  ota: RequestOta;
  client?: string;
  price?: number;
  reservationStatus: ReservationStatus;
  paymentStatus?: PaymentStatus;
  blocked?: boolean,
  extension?: boolean,
  reason?: string | null,
  reservationData?: Reservation | any,
  isShowingCanceledReservations?: boolean,
  widthMarker: number;
  showIndicators?: boolean;
  handleClick?: Function | any;
  isInitMarker?: boolean;
  isEndMarker?: boolean;
  totalDays?: number;
  isShowingMarker?: boolean;
  mobile?: boolean;
  dataCy?: string;
  isBookingOrBlockAutomatic?: boolean,
  indexDate: number,
}

const ReservationMarker: React.FC<PropsMaker> = ({
  id,
  size,
  defaultSize = 2,
  ota,
  client,
  price,
  reservationStatus,
  paymentStatus = 'unpaid',
  reservationData,
  blocked = false,
  widthMarker = 107,
  showIndicators = true,
  isInitMarker = false,
  isEndMarker = false,
  handleClick,
  totalDays = 0,
  isShowingMarker = true,
  mobile = false,
  dataCy = '',
  isBookingOrBlockAutomatic = false,
  indexDate,
}) => {
  const [extension, setExtension] = useState<boolean>(false);
  const [guestName, setGuestName] = useState<string>(!client ? '' : client.trim());
  const [fullGuestName, setFullGuestName] = useState<string>(!client ? '' : client.trim());
  const { isShowingCanceledReservations } = useCheckBox();

  const ImageOta: Record<string, ReactElement> = {
    airbnb: <img src={airbnbLogo} alt={ota.name} />,
    booking: <img src={bookingLogo} alt={ota.name} />,
    expedia: <img src={expediaLogo} alt={ota.name} />,
    homeaway: <img src={homeawayLogo} alt={ota.name} />,
    stays: <img src={staysLogo} alt={ota.name} />,
    temporadalivre: <img src={temporadalivreLogo} alt={ota.name} />,
    contrato: <img src={seazoneLogo} alt={ota.name} />,
    website: <img src={websiteLogo} alt={ota.name} />,
    testador: <img src={testadorLogo} alt={ota.name} />,
    decolar: <img src={decolarLogo} alt={ota.name} />,
  };

  const ImageBlock: Record<string, ReactElement> = {
    Cleaning: <img src={blockCleaning} alt="imagem do bloqueio de limpeza" />,
    Host: <img src={blockHost} alt="imagem do bloqueio de anfitrião" />,
    Maintenance: <img src={blockMaintenance} alt="imagem do bloqueio de manutenção" />,
    'Owner use': <img src={blockOwner} alt="imagem do bloqueio de proprietário" />,
    Preparation: <img src={blockPreparation} alt="imagem do bloqueio por tempo de preparação" />,
  };

  const [reasonTranslate, setReasonTranslate] = useState<string>('');
  useEffect(() => {
    const blockingReason = translateBlockingReason(reservationData?.blocking_reason);
    setReasonTranslate(blockingReason);
  }, [reservationData?.blocking_reason]);

  useEffect(() => {
    if (reservationData?.is_early_extension
      || reservationData?.is_late_extension) {
      setExtension(true);
    }
  }, [reservationData?.is_early_extension, reservationData?.is_late_extension]);

  const getGuestName = () => {
    const name = !client ? '' : client.trim();
    const nameWithoutBlankSpaces = name.replace(/\s/g, '-');
    const nameSplitted = nameWithoutBlankSpaces.split('-');

    let nameFormatted = '';
    if (nameSplitted.length > 0) {
      nameSplitted.forEach((item: string) => {
        if (item !== '') {
          nameFormatted += ` ${item}`;
        }
      });
    } else {
      nameFormatted = name;
    }

    setFullGuestName(nameFormatted);

    if (size < 3 && nameFormatted.length >= 10) {
      nameFormatted = `${nameFormatted.substring(0, 5)}...`;
    }
    return mobile && size <= 3 && nameFormatted.length >= 10 ? `${nameFormatted.substring(0, 8)}...` : nameFormatted;
  };

  useEffect(() => {
    setGuestName(getGuestName());
  }, [client, size]);

  return (
    <Marker
      key={id}
      data-cy={dataCy}
      size={defaultSize}
      isInitMarker={isInitMarker}
      isEndMarker={(totalDays < 3 && !mobile) || isEndMarker}
      widthMarker={widthMarker}
      reservationStatus={reservationStatus}
      blocked={blocked}
      blockingReason={reservationData?.blocking_reason}
      extension={extension}
      onClick={() => handleClick()}
      isShowingCanceledReservations={isShowingCanceledReservations}
      isShowingMarker={isShowingMarker}
      mobile={mobile}
      isBookingOrBlockAutomatic={isBookingOrBlockAutomatic}
      customZindex={indexDate}
    >
      <MarkerContainerIcon
        reservationStatus={reservationStatus}
        paymentStatus={paymentStatus}
        showIcon={isInitMarker}
        data-cy="marker"
        mobile={mobile}
      >
        {!reservationData?.blocking_reason ? ImageOta[ota.name.toLowerCase().trim()]
          : ImageBlock[reservationData?.blocking_reason]}
      </MarkerContainerIcon>

      <Tooltip text={reservationData?.blocking_reason === 'Preparation' ? 'Tempo de Preparo' : blocked ? 'Bloqueio' : fullGuestName}>
        <MarkerContainer
          size={size}
          isInitMarker={isInitMarker}
          isEndMarker={isEndMarker}
          widthMarker={widthMarker}
          reservationStatus={reservationStatus}
          blocked={blocked}
          blockingReason={reservationData?.blocking_reason}
          mobile={mobile}
        >

          {!reservationData?.is_blocking ? (
            <>
              <div className="wrapper-name">
                <div className="name">
                  {!mobile && <span>Reserva</span>}
                  <span>{guestName}</span>
                </div>
                <Indicators
                  className="indicators"
                  showIndicators={size < 3 ? false : showIndicators}
                  mobile={mobile}
                >
                  <DailyValueIcon />
                  <div className="dailyValueIcon">
                    <span>{reservationData?.daily_net_value}</span>
                  </div>
                </Indicators>
              </div>
              <div className="totalPrice">
                <span className="currency">R$ </span>
                <span className="price">{numberToCurrency(price, false)}</span>
              </div>
            </>
          ) : (
            <div className="name">
              {reservationData?.blocking_reason === 'Preparation' ? 'Tempo de Preparo' : `Bloqueio ${size > 2 ? `- ${reasonTranslate}` : ''}`}
            </div>
          )}
        </MarkerContainer>
      </Tooltip>
    </Marker>
  );
};

export default ReservationMarker;
