import React from 'react';

import { useFormik } from 'formik';

import TedListDatePicker from './DatePicker';
import ExportListButton from './Button/ExportListButton';
// import TedListTextField from './TextField/TedListTextField';
// import GenerateOrLoadListButton from './Button/GenerateOrLoadListButton';

import {
  LeftSide,
  RightSide,
  Container,
} from './styles';

const Header = () => {
  const formik = useFormik({
    initialValues: {
      search: '',
      period: new Date(),
    },
    onSubmit: () => {},
  });

  return (
    <Container>
      <LeftSide>
        {/* <GenerateOrLoadListButton formik={formik}>
          Gerar / Carregar lista
        </GenerateOrLoadListButton> */}
        <TedListDatePicker formik={formik} />
      </LeftSide>
      <RightSide>
        <ExportListButton formik={formik}>Exportar lista</ExportListButton>
        {/* <TedListTextField id="search" formik={formik} /> */}
      </RightSide>
    </Container>
  );
};

export default Header;
