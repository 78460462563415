import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';
import { Actions } from './types';

interface IFinancialClose {
  dateSelected: Date,
  setDateSelected: Function,
  openModalDate: boolean,
  setOpenModalDate: Function,
  openActions: boolean,
  setOpenActions: Function,
  openModalManualFit: boolean,
  setOpenModalManualFit: Function,
  actionSelected: Actions,
  setActionSelected: Function,
  loading: boolean,
  setLoading: Function,
  search: string,
  setSearch: Function,
  activeLinearProgress: boolean,
  setActiveLinearProgress: Function
  openFinancialUpdateModal: boolean,
  setOpenFinancialUpdateModal: Function,
}

export const FinancialCloseContext = createContext<IFinancialClose>({
  dateSelected: new Date(),
  setDateSelected: () => {},
  openModalDate: false,
  setOpenModalDate: () => {},
  openActions: false,
  setOpenActions: () => {},
  openModalManualFit: false,
  setOpenModalManualFit: () => {},
  actionSelected: 'none',
  setActionSelected: () => {},
  loading: false,
  setLoading: () => {},
  search: '',
  setSearch: () => {},
  activeLinearProgress: false,
  setActiveLinearProgress: () => {},
  openFinancialUpdateModal: false,
  setOpenFinancialUpdateModal: () => {},
});

export const FinancialCloseProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const [openModalDate, setOpenModalDate] = useState<boolean>(false);
  const [openActions, setOpenActions] = useState<boolean>(false);
  const [openModalManualFit, setOpenModalManualFit] = useState<boolean>(false);
  const [openFinancialUpdateModal, setOpenFinancialUpdateModal] = useState<boolean>(false);
  const [actionSelected, setActionSelected] = useState<Actions>('none');
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [activeLinearProgress, setActiveLinearProgress] = useState<boolean>(false);

  const handleChangeDate = useCallback((date: Date) => {
    setDateSelected(date);
  }, []);

  const handleChangeOpenModalDate = useCallback((isOpen: boolean) => {
    setOpenModalDate(isOpen);
  }, []);

  const handleChangeOpenActions = useCallback((isOpen: boolean) => {
    setOpenActions(isOpen);
  }, []);

  const handleChangeOpenModalManualFit = useCallback((isOpen: boolean) => {
    setOpenModalManualFit(isOpen);
  }, []);

  const handleOpenFinancialUpdateModal = useCallback((isOpen: boolean) => {
    setOpenFinancialUpdateModal(isOpen);
  }, []);

  const handleChangeAction = useCallback((action: Actions) => {
    setActionSelected(action);
  }, []);

  const handleChangeLoading = useCallback((isLoading: boolean) => {
    setLoading(isLoading);
  }, []);

  const handleChangeSearch = useCallback((searchTerm: string) => {
    setSearch(searchTerm);
  }, []);

  const handleActiveLinearProgress = useCallback((isActive: boolean) => {
    setActiveLinearProgress(isActive);
  }, []);

  useEffect(() => {
    if (openModalDate) {
      setOpenModalDate(!openModalDate);
    }
  }, [dateSelected]);

  useEffect(() => {
    if (actionSelected === 'update' && loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [actionSelected, loading]);

  const value = useMemo(() => ({
    dateSelected,
    setDateSelected: handleChangeDate,
    openModalDate,
    setOpenModalDate: handleChangeOpenModalDate,
    openActions,
    setOpenActions: handleChangeOpenActions,
    openModalManualFit,
    setOpenModalManualFit: handleChangeOpenModalManualFit,
    actionSelected,
    setActionSelected: handleChangeAction,
    loading,
    setLoading: handleChangeLoading,
    search,
    setSearch: handleChangeSearch,
    activeLinearProgress,
    setActiveLinearProgress: handleActiveLinearProgress,
    openFinancialUpdateModal,
    setOpenFinancialUpdateModal: handleOpenFinancialUpdateModal,
  }), [
    dateSelected,
    setDateSelected,
    openModalDate,
    setOpenModalDate,
    openActions,
    setOpenActions,
    openModalManualFit,
    setOpenModalManualFit,
    actionSelected,
    setActionSelected,
    loading,
    setLoading,
    search,
    setSearch,
    activeLinearProgress,
    setActiveLinearProgress,
    openFinancialUpdateModal,
    setOpenFinancialUpdateModal,
  ]);

  return (
    <FinancialCloseContext.Provider
      value={value}
    >
      {children}
    </FinancialCloseContext.Provider>
  );
};
