import React, { useEffect } from 'react';
import Main from '../../components/SendingVouchers';
import { Container } from './styles';

const SendingVouchers = () => {
  useEffect(() => {
    document.title = 'Sapron | Voucher';
  }, []);
  return (
    <Container>
      <Main />
    </Container>
  );
};

export default SendingVouchers;
