import request from '../../request';

import {
  OwnerServicesTermsProps,
  OwnerServicesTermsParams,
  OwnerServicesTermsPatchProps,
} from '../../../context/OwnerPage/OwnerServicesTermsContext/types';

export const getPropertyAgreements = async (params: OwnerServicesTermsParams) => {
  const { data } = await request.get<Array<OwnerServicesTermsProps>>('/property/agreements/', {
    params: {
      ...params,
    },
  });

  return data;
};

export const patchPropertyAgreements = async (id: number, payload: OwnerServicesTermsPatchProps) => request.patch(`/property/agreements/${id}/`, {
  ...payload,
  owner_latitude: payload?.owner_latitude !== '' ? payload.owner_latitude : undefined,
  owner_longitude: payload?.owner_longitude !== '' ? payload.owner_longitude : undefined,
  registered_at: new Date(),
});
