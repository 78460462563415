import styled, { css } from 'styled-components';

// Estilizando os componentes
export const HeaderContainer = styled.div<{ isWeekend: boolean }>`
  background: ${({ isWeekend }) => (isWeekend ? 'rgba(233, 235, 236, 0.9)' : 'white')};
  border-right: 1px dashed #bbb;
  text-align: center;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const DayCircle = styled.div<{ isToday: boolean }>`
  background-color: ${({ isToday }) => (isToday ? '#E05845' : 'transparent')};
  border-radius: ${({ isToday }) => (isToday ? '50%' : '0%')};
  padding: ${({ isToday }) => (isToday ? '4px' : '0')};

  ${(props) => props.isToday
    && css`
      width: 24px;
    `}
`;
