import React from 'react';
import { ITableCardsData } from '../../../types';

import {
  Body,
  Title,
  Status,
  Header,
  LeftSide,
  RightSide,
  ValueBody,
  Container,
  Description,
  CardInfo,
} from './styles';

interface ICard {
  table: ITableCardsData[];
}

const Card = ({
  table,
}: ICard) => (
  <Container>
    <LeftSide>
      <Title />
      <Description />
    </LeftSide>
    <RightSide>
      <Header>
        <h1>Indicadores</h1>
        <h1>Valor</h1>
        <Status />
      </Header>
      <Body>
        {table?.map((card) => (
          <ValueBody key={card.id}>
            <CardInfo />
            <CardInfo />
          </ValueBody>
        ))}
      </Body>
    </RightSide>
  </Container>
);

export default Card;
