import styled, { keyframes } from 'styled-components';

const mobile = '1000px';

const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
    }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: #00000050;
  backdrop-filter: blur(1px);

  justify-content: center;
  align-items: center;
  transition: 0.4s;
  z-index: 998 !important;

  @media (max-width: ${mobile}) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 2rem;
    background: #fff;
  }
`;

export const Container = styled.div`
  display: flex;

  width: 100%;
  min-width: 50vw;
  height: auto;
  max-height: 720px;
  z-index: 999 !important;

  z-index: 3;
  background: #fff;
  border-radius: 15px;
  padding: 2rem;

  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  user-select: none;

  @media (max-width: ${mobile}) {
    background: #fff;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 2rem;
  cursor: pointer;

  img {
    width: 20px;
    opacity: 0.5;
    filter: invert(1);
    transition: 0.5s;
    &:hover {
      opacity: 1;
      transform: rotate(90deg);
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  img {
    width: 45px;
  }

  h1 {
    font-size: 2.3rem;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    margin-left: 1rem;
  }

  @media (max-width: ${mobile}) {
    h1 {
      font-size: 1.5rem;
      font-family: ${({ theme }) => theme.fonts.familys._1};
    }
    img {
      width: 30px;
    }
  }
`;

export const ContentContainer = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.8rem;
  height: 100%;
  width: 100%;

  @media (max-width: ${mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const FormsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  .messageError {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .autocomplete-host {
    margin-top: 0.7rem;
    text-align: start;
  }

  @media (max-width: ${mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const LeftSideContainer = styled.div`
  width: 100%;
  border-right: 1px solid #00000024;
  padding-right: 1rem;

  div {
    label {
      display: flex;
    }
  }
  .styledTextFieldContainer {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 1rem;
    .styledTextFieldLabel {
      text-align: left;
    }

    .styledMoney {
      width: 200px;
    }
  }

  .simpleSelectContainer {
    margin-top: 2rem;
    .simpleSelectLabel {
      text-align: left;
    }
  }

  .datePickerContainer {
    margin-top: 2rem;
    text-align: left;
    justify-content: left;

    .datePickerLabel {
      text-align: left;
    }

    .customStyledTextField {
      width: 200px;
    }
  }

  @media (max-width: ${mobile}) {
    border-right: 0;
    padding: 0;
  }
`;

export const RightSideContainer = styled.div`
  padding-left: 1rem;
  text-align: left;
  justify-content: left;

  @media (max-width: ${mobile}) {
    border-right: 0;
    padding: 0;
  }
`;

export const PhotoContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${mobile}) {
    margin-top: 0.5rem;
  }
`;
export const FieldCodeProperty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 3px;

  h1 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  p {
    margin-left: 5px;
    font-size: 12px;
  }

  h1, p {
    font-family: 'Quicksand', sans-serif;
  }
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const ReceiptContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  flex-direction: column;
  padding: 0 10px;
  h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
  }
  p {
    font-size: 12px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

export const MaintenanceContainer = styled(ReceiptContainer)`

`;

export const DescriptionContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  h1, p {
    font-family: 'Quicksand', sans-serif;
  }

  h1 {
    font-weight: 700;
    font-size: 14px;
  }

  p {
    text-align: justify;
    width: 100%;
    margin: 15px 0;
    margin-left: 15px;
    font-size: 12px;
  }
`;

export const AuthorizationContainer = styled(DescriptionContainer)`
  p {
    text-align: start;
    margin: 10px 0;
    padding: 0;
    margin-left: 10px;
  }
`;

export const FieldStatusContainer = styled(FieldCodeProperty)`
  margin-left: 10px;
`;

export const FilesContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;
