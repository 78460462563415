export type StatusProps = 'Active' | 'Inactive' | 'Closed' | 'Onboarding' | 'Default';

export enum Color {
  Ativo = '#008169',
  Inativo = '#CF4F44',
  Fechado = '#9494A4',
  Onboarding = '#349CBD',
}

export enum Background {
  Ativo = '#BAECDD',
  Inativo = '#F6E1E1',
  Fechado = '#FFFFFF',
  Onboarding = '#E3F8FF',
}

export const objStatus = [
  'Active',
  'Inactive',
  'Closed',
  'Onboarding',
];

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
  Closed = 'Closed',
  Onboarding = 'Onboarding',
  Default = 'Active',
}
