import React from 'react';

import { Alert } from '@mui/material';

import {
  FormContainer,
  InputMaskStyled,
} from './style';

import { IBody } from './types';
import { bodyVariants } from '../../../../../../utils';

import {
  Input,
  ErrorMessage,
  Button,
} from '../../../../../../components';

import Phone from './Phone';
import Section from './Section';
import IsPrincipal from './IsPrincipal';
import DocumentUpload from './DocumentUpload';
import GuestOptions from './GuestOptions/GuestOptions';
import { usePreCheckin } from '../../../../../../../../context/PreCheckin/PreCheckin';
import { SimpleSelect } from '../../../../../../../../components';
import { SelectOption } from '../../../../../../../../components/SimpleSelect/SimpleSelect';

const nationalityOptions: SelectOption[] = [
  {
    value: 'brazilian',
    valueLabel: 'Brasileira',
  },
  {
    value: 'other',
    valueLabel: 'Outra',
  },
];

const Body = ({
  guest,
  formik,
  isMain,
  loading,
  expanded,
}: IBody) => {
  const { mode, info } = usePreCheckin();

  const { isPrincipal } = formik.values;

  const requiredView = isPrincipal ? '*' : '';
  const isBrazilian = formik.values.nationality === 'brazilian';

  return (
    <FormContainer
      disabled={loading}
      expanded={expanded}
      variants={bodyVariants}
      onSubmit={formik.handleSubmit}
      animate={expanded ? 'open' : 'closed'}
    >
      {isMain && (
        <Section style={{ marginTop: '-22px' }} className="isMain">
          <IsPrincipal />
        </Section>
      )}

      <Section label="Informações pessoais: " divider>
        <Input
          disabled
          id="name"
          label="Nome Completo *"
          formik={formik}
          placeholder="Digite aqui..."
        />
      </Section>
      <Section>
        <p>Nacionalidade *</p>
        <SimpleSelect
          id="nationality"
          formik={formik}
          placeholder="Selecione"
          options={nationalityOptions}
          className="select"
        />
      </Section>
      <Section>
        <p>
          CPF
          {' '}
          {isBrazilian && requiredView}
        </p>

        <InputMaskStyled
          id="cpf"
          option="cpf"
          formik={formik}
          initial={formik.values.cpf}
          placeholder="Digite aqui..."
        />

        <ErrorMessage id="cpf" formik={formik} />
      </Section>

      <Section divider label="Informações de contato: ">
        <Input
          id="email"
          formik={formik}
          placeholder="Digite aqui..."
          label={`Email ${requiredView}`}
        />
      </Section>

      <Section>
        <p>
          Telefone
          {' '}
          {requiredView}
        </p>
        <p style={{ fontSize: '0.95rem', opacity: 0.8, marginBottom: '5px' }}>
          Clique sobre a bandeira, ou digite o código do país para alterar a
          origem do telefone
        </p>
        <Phone formik={formik} />
        <ErrorMessage id="phone" formik={formik} />
      </Section>

      <Section divider label="Enviar foto do seu documento">
        <p>
          Envie-nos uma foto do seu documento frente e verso (RG, CNH ou Passaporte)
          {' '}
          {requiredView}
        </p>
        <DocumentUpload guest={guest} formik={formik} />
        <ErrorMessage id="front_document_photo" formik={formik} />
        <ErrorMessage id="back_document_photo" formik={formik} />
      </Section>

      {info?.property?.property_type !== 'Hotel' && (
        <Section>
          <Alert severity="warning">
            <h1>Atenção: </h1>
            <p>O anfitrião entrará em contato caso necessite da placa do veículo</p>
          </Alert>
        </Section>
      )}

      {isMain && (
        <GuestOptions />
      )}

      {mode.is === 'edit' && (
        <Section divider>
          <Button guest={guest} loading={loading} />
        </Section>
      )}
    </FormContainer>
  );
};

export default Body;
