import { useManagementPanel } from '../../hooks/useManagementPanel/useManagementPanel';
import ExportCSVModal from './ExportCSVModal';
import ImportStaysReservationModal from './ImportStaysReservationModal';
import ManagementPanel from './ManagementPanel/ManagementPanel';
import { ManagementPanelComponentContainer, ManagementPanelComponentContent } from './styles';

const ManagementPanelComponent = () => {
  const { isImportStaysReservationModalOpen, isExportCSVModalOpen } = useManagementPanel();

  return (
    <>
      <ManagementPanelComponentContainer>
        <ManagementPanelComponentContent>
          <ManagementPanel />
        </ManagementPanelComponentContent>
      </ManagementPanelComponentContainer>
      {isImportStaysReservationModalOpen && (<ImportStaysReservationModal />)}
      {isExportCSVModalOpen && (<ExportCSVModal />)}
    </>
  );
};

export default ManagementPanelComponent;
