import styled, { keyframes } from 'styled-components';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-1rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
`;

export const FirstLoginOwnerModalBackDrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  background-color: rgba(0,0,0, 0.7);
`;

export const ButtonClose = styled.button`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  align-self: baseline;
`;

export const FirstLoginOwnerModalCard = styled.div`
  position: absolute;
  width: 600px;
  height: 85%;
  z-index: 1000 !important;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 2rem;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${({ theme }) => theme.fonts.familys._6};


  @media (max-width: 480px) {
    width: 90%;
    height: 90%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.blue._910.hex()};
  line-height: 125%;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  animation-name: ${FadeAnimation};
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  opacity: 0;
`;

export const SimpleSelectContainer = styled.div`
  margin-top: -1rem;
`;

export const TwoUnevenColumns = styled.div`
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 1rem;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    button {
      width: 100%;
      margin-top: 0.75rem;
      margin-bottom: 0.125rem;
    }
  }
`;

export const DatePickerContainer = styled.div`
  width: 40%;

  @media (max-width: 480px) {
    width: 100%;
  }
`;
