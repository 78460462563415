import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 500px;
  height: 350px;
  background: #fff;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 10px;

  position: fixed;
  border-radius: 10px;
  padding: 20px 40px;

  h1{
    font-size: 2rem;
  }

  h2{
    font-weight: 500;
    font-size: 1.4rem;
  }

  img {
    width: 72px;
  }

  z-index: 25;
`;

export const BackDrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: 20;

  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;

`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 90%;
  gap: 10px;
  margin-top: 20px;
`;
