import request from '../request';
import { RequestReservationVoucher } from './types';

export const getReservationVoucher = async (): Promise<Array<RequestReservationVoucher>> => {
  const { data } = await request.get('/reservation_voucher/');
  return data as RequestReservationVoucher[];
};

export const postReservationVoucher = async (idReservation: number, voucherSent: boolean) => {
  await request.post('/reservation_voucher/', {
    reservation: idReservation,
    voucher_sent: voucherSent,
  });
};

export const patchReservartionVoucher = async (values: boolean, id: number) => {
  await request.patch(`/reservation_voucher/${id}/`, {
    voucher_sent: values,
  });
};
