import request from '../request';
import {
  RequestOta, Property, PropertyDetails,
} from './types';
import { PagedResult } from '../types';
import { UserInformation } from '../User/types';
import { Mode } from '../../context/ViewMode/type';

export const getOtas = async (): Promise<RequestOta[]> => {
  const { data } = await request.get('/otas/');
  return data as RequestOta[];
};

export const getProperties = async (page: number = 1, pageSize: number = 25):
Promise<PagedResult<Property>> => {
  const { data } = await request.get(`/calendar/properties/?page=${page}&page_size=${pageSize}`);
  return data;
};

export const getPropertiesUserId = async (userId: string, pageSize: number = 25):
Promise<PagedResult<Property>> => {
  const { data } = await request.get(`/calendar/properties/?host__user=${userId}&page_size=${pageSize}`);
  return data;
};

export const getPropertiesHost = async (pageSize: number = 999999999, hostId?: number):
Promise<PagedResult<Property>> => {
  const { data } = await request.get('/properties/host/', {
    params: {
      page_size: pageSize,
      host_id: hostId,
    },
  });
  return data;
};

export const getPropertyId = async (id: string): Promise<PropertyDetails> => {
  const { data } = await request.get(`/properties/details/${id}/`);
  return data;
};

export const getPropertiesDetails = async (filter?: PropertyDetails):
Promise<Array<PropertyDetails>> => {
  if (filter) {
    const { data } = await request.get('/properties/details/', {
      params: {
        bedroom_quantity: filter.bedroom_quantity,
        bathroom_quantity: filter.bathroom_quantity,
        property_type__in: filter.property_type,
        sea_view: filter.sea_view,
        barbecue: filter.barbecue,
        allow_pet: filter.allow_pet,
        categories: filter.categories,
        guest_capacity__gte: filter.guest_capacity__gte,
        start_date: filter.start_date,
        end_date: filter.end_date,
        location__in: filter.location,
        host: filter.host,
      },
    });
    return data;
  }
  const { data } = await request.get('/properties/details/');
  return data;
};

export const getOnboardingPropertyById = async (id: string, mode?: Mode | null) => {
  const userID = mode?.user_id || null;

  const { data } = await request.get('/property/onboarding/', {
    params: {
      property_id: id,
      user_id: userID,
    },
  });
  return data;
};

export const searchProperties = async (filter?: PropertyDetails):
Promise<PagedResult<Property>> => {
  if (filter) {
    const params = {
      search: filter.search,
      bedroom_quantity: filter.bedroom_quantity,
      bathroom_quantity: filter.bathroom_quantity,
      property_type__in: filter.property_type,
      sea_view: filter.sea_view,
      barbecue: filter.barbecue,
      allow_pet: filter.allow_pet,
      category__in: filter.categories,
      guest_capacity__gte: filter.guest_capacity__gte,
      location__in: filter.location,
      host: Object.keys(filter?.host || {}).length > 0 ? filter.host : undefined,
      page: filter.page,
      page_size: filter.page_size,
    };
    const dataUser = localStorage.getItem('sapron-pms-user-role-id');
    let userInformation: UserInformation = {
      main_role: '',
      roles: [],
      user_id: '',
    };
    let allParams;
    if (dataUser) {
      userInformation = JSON.parse(dataUser);
      allParams = {
        host__user: userInformation.user_id,
        ...params,
      };
    }
    const { roles } = userInformation;
    const { data } = await request.get('calendar/properties/', {
      params: roles.includes('Host') ? allParams : params,
    });
    return data;
  }
  const { data } = await request.get('calendar/properties/');
  return data;
};
