import {
  FC,
  useState,
  useEffect,
  useMemo,
  memo,
  useContext,
} from 'react';

import { useFormik } from 'formik';
import moment from 'moment';

import { X } from 'react-feather';
import { Check } from '@mui/icons-material';

import TrashIcon from '../../../../../assets/icons/generals/bashRed.svg';
import EditIcon from '../../../../../assets/icons/generals/editIcon.svg';

import { HostCloseContext } from '../../../HostClose/HostClose';

import { FinancialCloseParams, ManualFitProps } from '../../../../../services/FinancialClose/Host/types';

import { deleteManualFitHost, getManualFitHost, patchManualFitHost } from '../../../../../services/FinancialClose/Host/request';

import { currencyToNumber, numberToCurrency } from '../../../../../utils/Formatter';
import { currency } from '../../../../../utils/InputMask/Number';

import { useFinancialClose } from '../../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseHost } from '../../../../../hooks/FinancialCloseHook/useFinancialCloseHost';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../../utils/Messages';

import { formatDate, sortManualFitList } from '../../../../../context/FinancialClosePage/FinancialCloseContext/utils';

import FormButton from '../../../../FormButton';
import TextField from '../../../../TextField';
import DatePicker from '../../../../DatePicker';
import DeleteManualFitHost from '../DeleteManualFitHost';

import {
  Form,
  Container,
  Header,
  Body,
  Title,
  CloseButton,
  Card,
  TableContainer,
  TableHeader,
  TableBody,
  Field,
  FieldDescription,
  ButtonsContainer,
  Button,
  Backdrop,
} from './styles';

const EditManualFitHost: FC = () => {
  const {
    dateSelected,
    handleActiveLinearProgress,
  } = useFinancialClose();

  const {
    financialDataHost,
    openModalEditManualFitHost,
    handleOpenModalEditManualFitHost,
  } = useFinancialCloseHost();

  const {
    refetchSelectedHosts,
  } = useContext(HostCloseContext);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  const params: FinancialCloseParams = {
    start_date: formatDate(dateSelected).start_date,
  };

  type DeleteManualFitProps = {
    id: string,
    isOpen: boolean,
  };

  const [openModalDelete, setOpenModalDelete] = useState<DeleteManualFitProps>({
    id: '',
    isOpen: false,
  });

  const [editedManualFits, setEditedManualFits] = useState<ManualFitProps[]>([]);

  type ManualFitRecordProps = Record<string, ManualFitProps[]>;
  const [manualFitList, setManualFitList] = useState<ManualFitRecordProps>({});

  type EditModeProps = Record<string, boolean>;
  const [editMode, setEditMode] = useState<EditModeProps>({});

  const [initialValuesFormik, setInitialValuesFormik] = useState<any>({});

  const hostsSelected = useMemo(() => {
    const hosts = financialDataHost.filter((item) => item.checked) || [];
    return hosts;
  }, [openModalEditManualFitHost, financialDataHost]);

  const getManualFits = async () => {
    let allManualFits: ManualFitRecordProps = {};

    await Promise.all(hostsSelected.map(async (item) => {
      const response: ManualFitProps[] = await getManualFitHost({
        ...params,
        host_id: item.host.id,
      });

      allManualFits = {
        ...allManualFits,
        [item.host.id]: sortManualFitList(response),
      };
    }));

    setManualFitList(allManualFits);
  };

  useEffect(() => {
    if (openModalEditManualFitHost && hostsSelected.length !== 0) {
      getManualFits();
    }
  }, [openModalEditManualFitHost, hostsSelected]);

  useEffect(() => {
    let initialValues: any = {};
    const wasEdited: EditModeProps = {};
    const hostId = hostsSelected?.[0]?.id || null;

    if (hostId) {
      manualFitList?.[hostId]?.forEach((item) => {
        wasEdited[`${item.id}`] = false;

        initialValues = {
          ...initialValues,
          [`description_${item.id}`]: item?.description || '',
          [`value_${item.id}`]: item?.value ? numberToCurrency(Number(item?.value)) : 'R$ 0,00',
          [`date_${item.id}`]: item?.date_ref ? moment(item?.date_ref).toDate() : new Date(),
        };
      });
    }

    setInitialValuesFormik(initialValues);
    setEditMode(wasEdited);
  }, [manualFitList]);

  const handlePatchManualFit = async (manualFitData: ManualFitProps) => {
    try {
      const response = await patchManualFitHost(manualFitData);
      return response.data;
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: initialValuesFormik,
    onSubmit: async () => {
      handleActiveLinearProgress(true);

      try {
        await Promise.all(editedManualFits.map(async (item) => {
          await handlePatchManualFit({
            ...item,
            date_ref: item.date_ref,
            description: item.description,
            value: typeof item.value === 'string' ? currencyToNumber(currency(item.value)) : item.value,
          });
        }));

        await refetchSelectedHosts({
          ...params,
          hosts: hostsSelected.map((item) => `${item.host.id}`),
        });

        formik.resetForm();

        setTimeout(() => {
          handleOpenModalEditManualFitHost(false);
          handleActiveLinearProgress(false);
          toast.success('Ajuste editado com sucesso!');
        }, 1000);
      } catch (e: unknown) {
        handleOpenModalEditManualFitHost(true);
        handleActiveLinearProgress(false);
        toast.error('Não foi possível atualizar o ajuste!');
      }
    },
  });

  const handleDeleteManualFit = async (id?: number, host_id?: number) => {
    handleActiveLinearProgress(true);

    if (!id || !host_id) return null;

    try {
      await deleteManualFitHost(id);

      const newManualFitList = manualFitList?.[host_id]?.filter((item) => `${item.id}` !== `${id}`);
      setManualFitList({
        ...manualFitList,
        [host_id]: newManualFitList,
      });

      const newEditedManualFitList = editedManualFits.filter((item) => `${item.id}` !== `${id}`);
      setEditedManualFits(newEditedManualFitList);

      await refetchSelectedHosts({
        ...params,
        hosts: hostsSelected.map((item) => `${item.host.id}`),
      });

      handleActiveLinearProgress(false);
      toast.success('Ajuste deletado com sucesso!');

      if (newManualFitList.length === 0) {
        setTimeout(() => {
          handleOpenModalEditManualFitHost(false);
        }, 2000);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const activeManualFitToEdit = (id: string) => {
    setEditMode((state) => ({
      ...state,
      [id]: true,
    }));
  };

  const storeManualFitEdited = (manualFit: ManualFitProps) => {
    const filtereds = editedManualFits.filter((item) => `${item.id}` !== `${manualFit.id}`);

    const manualFitUpdated: ManualFitProps = {
      ...manualFit,
      date_ref: formik.values?.[`date_${manualFit.id}`],
      description: formik.values?.[`description_${manualFit.id}`],
      value: formik.values?.[`value_${manualFit.id}`],
    };

    const newArray = [...filtereds, { ...manualFitUpdated }];
    setEditedManualFits(newArray);

    setEditMode((state) => ({
      ...state,
      [`${manualFit.id}`]: false,
    }));
  };

  useEffect(() => {
    if (Object.values(initialValuesFormik).length !== 0) {
      formik.setValues({
        ...initialValuesFormik,
      });
    }
  }, [initialValuesFormik]);

  return (
    <>
      <Form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Container isOpen={openModalEditManualFitHost}>
          <Header>
            <Title>{`Ajuste direto anfitrião - ${hostsSelected?.[0]?.host?.name || ''}`}</Title>
            <CloseButton type="button" onClick={() => handleOpenModalEditManualFitHost(false)}>
              <X size={22} />
            </CloseButton>
          </Header>

          <Body>
            {hostsSelected.length > 0 && hostsSelected.map((item) => (
              <TableContainer key={item.id}>
                {manualFitList?.[item.host.id]?.length === 0
                  ? <p>Nenhum ajuste foi realizado ainda!</p> : (
                    <>
                      <TableHeader>
                        <strong>Data de referência</strong>
                        <strong>Data de inserção</strong>
                        <strong>Imóvel</strong>
                        <strong>Motivo</strong>
                        <strong>Valor</strong>
                      </TableHeader>

                      {manualFitList?.[item.host.id]?.map((manualFit) => (
                        <Card key={manualFit.id}>
                          <TableBody>
                            {editMode[`${manualFit.id}`] ? (
                              <Field>
                                <DatePicker
                                  dataCy={`input-dateref-${manualFit.id}`}
                                  id={`date_${manualFit.id}`}
                                  formik={formik}
                                  hasInitialDates
                                  showDaysOutsideCurrentMonth={false}
                                  showOnlyMonth={dateSelected}
                                />
                              </Field>
                            ) : (
                              <span data-cy={`input-dateref-${manualFit.id}`}>
                                {`${moment(formik.values[`date_${manualFit.id}`] || new Date()).format('DD/MM/YYYY')}`}
                              </span>
                            )}

                            <span data-cy={`input-dateinsert-${manualFit.id}`}>
                              {`${moment(manualFit?.created_at || new Date()).format('DD/MM/YYYY')} às ${moment(manualFit?.created_at || new Date()).format('HH:mm:ss')}`}
                            </span>

                            <span>
                              {`${manualFit.is_related_property
                                ? manualFit.code : '-'}`}
                            </span>

                            {editMode[`${manualFit.id}`] ? (
                              <FieldDescription>
                                <TextField
                                  dataCy={`textarea-description-${manualFit.id}`}
                                  formik={formik}
                                  id={`description_${manualFit.id}`}
                                  type="textarea"
                                  placeholder="Motivos"
                                  value={formik.values[`description_${manualFit.id}`]}
                                />
                              </FieldDescription>
                            ) : (
                              <span data-cy={`textarea-description-${manualFit.id}`}>
                                {formik.values[`description_${manualFit.id}`]}
                              </span>
                            )}

                            {editMode[`${manualFit.id}`] ? (
                              <Field>
                                <TextField
                                  dataCy={`input-value-${manualFit.id}`}
                                  id={`value_${manualFit.id}`}
                                  formik={formik}
                                  mask="money"
                                  placeholder="R$ 0,00"
                                  value={currency(formik.values[`value_${manualFit.id}`])}
                                />
                              </Field>
                            ) : (
                              <span data-cy={`input-value-${manualFit.id}`} className={`${manualFit.is_adding ? 'isAdding' : 'isRemoving'}`}>
                                {currency(formik.values[`value_${manualFit.id}`])}
                              </span>
                            )}

                            {!editMode[`${manualFit.id}`] ? (
                              <Button
                                type="button"
                                data-cy={`btn-edit-manualfit-host-${manualFit.id}`}
                                onClick={() => activeManualFitToEdit(`${manualFit.id}`)}
                              >
                                <img src={EditIcon} alt="editar" />
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                data-cy={`btn-edit-confirmation-manualfit-host-${manualFit.id}`}
                                onClick={() => storeManualFitEdited(manualFit)}
                              >
                                <Check />
                              </Button>
                            )}

                            <Button
                              type="button"
                              data-cy={`btn-delete-manualfit-host-${manualFit.id}`}
                              onClick={() => setOpenModalDelete({
                                id: `${manualFit?.id || ''}`,
                                isOpen: true,
                              })}
                            >
                              <img src={TrashIcon} alt="deletar" />
                            </Button>
                          </TableBody>

                          {openModalDelete.isOpen && `${manualFit.id}` === `${openModalDelete.id}` && (
                          <DeleteManualFitHost
                            openModal={openModalDelete.isOpen}
                            onClose={() => setOpenModalDelete({
                              id: '',
                              isOpen: false,
                            })}
                            handleDelete={
                              () => handleDeleteManualFit(manualFit.id, item.host.id)
                            }
                          />
                          )}
                        </Card>
                      ))}
                    </>
                  )}
              </TableContainer>
            ))}
          </Body>

          <ButtonsContainer className="btn-submit">
            <FormButton dataCy="btn-cancel-edit-manualfit" type="button" variant="outlined" onClick={() => handleOpenModalEditManualFitHost(false)}>Cancelar</FormButton>
            <FormButton
              dataCy="btn-save-edit-manualfit"
              type="submit"
              disable={editedManualFits.length === 0}
              customColor={editedManualFits.length !== 0 ? 'blue' : 'grey'}
            >
              Salvar
            </FormButton>
          </ButtonsContainer>
        </Container>

      </Form>

      { openModalEditManualFitHost && (
        <Backdrop onClick={() => handleOpenModalEditManualFitHost(false)} />
      )}
    </>
  );
};

export default memo(EditManualFitHost);
