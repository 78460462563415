import React from 'react';

import { LeftContainer, RightContainer, Text } from '../style';
import { Container, TagContainer, ReserveButton } from './style';

const Footer = () => (
  <Container>
    <LeftContainer gap="10px">
      <TagContainer active>
        <Text>Disponíveis 34</Text>
      </TagContainer>
      <TagContainer>
        <Text>Reservados 10</Text>
      </TagContainer>
    </LeftContainer>
    <RightContainer>
      <ReserveButton>
        Reservar
        <span>
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.50062 5L0 1.66745L1.74969 0L7 5L1.74969 10L0 8.33255L3.50062 5Z"
              fill="white"
            />
          </svg>
        </span>
      </ReserveButton>
    </RightContainer>
  </Container>
);

export default Footer;
