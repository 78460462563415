import React from 'react';

import {
  Zoom,
  styled,
  TooltipProps,
  tooltipClasses,
  Tooltip as MuiTooltip,
} from '@mui/material';

import { Backdrop, TooltipText } from './style';

const Icon = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...rest}>
    <g clipPath="url(#clip0_137_650)">
      <path d="M7.99963 0C3.58826 0 0 3.58885 0 8.0001C0 12.4113 3.58826 16 7.99963 16C12.4112 16 16 12.4112 16 8.0001C16 3.58885 12.4111 0 7.99963 0ZM7.99963 14.3063C4.52241 14.3063 1.69382 11.4772 1.69382 8.0001C1.69382 4.52301 4.52245 1.69373 7.99963 1.69373C11.477 1.69373 14.3062 4.52301 14.3062 8.0001C14.3062 11.4772 11.4769 14.3063 7.99963 14.3063Z" fill="#0D4BD0" />
      <path d="M8.80984 7.22461H7.18566C6.91315 7.22461 6.69189 7.44585 6.69189 7.71794V12.5333C6.69189 12.8058 6.91315 13.0267 7.18566 13.0267H8.80984C9.08236 13.0267 9.30361 12.8057 9.30361 12.5333V7.71797C9.30361 7.44589 9.08236 7.22461 8.80984 7.22461Z" fill="#0D4BD0" />
      <path d="M7.99711 2.9751C7.19825 2.9751 6.55029 3.623 6.55029 4.42187C6.55029 5.22082 7.19822 5.86876 7.99711 5.86876C8.79613 5.86876 9.44412 5.22086 9.44412 4.42187C9.44409 3.623 8.79616 2.9751 7.99711 2.9751Z" fill="#0D4BD0" />
    </g>
    <defs>
      <clipPath id="clip0_137_650">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: '#394760',
    maxWidth: 240,
    lineHeight: 1.3,
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: '10px',
    border: '1px solid #969696',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '& p': {
    color: '#394760',
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
}));

interface ITooltip {
  text: JSX.Element;
}

const MobileTooltip = ({ text }: ITooltip) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <HtmlTooltip
        arrow
        open={open}
        enterTouchDelay={0}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleClose}
        TransitionComponent={Zoom}
        style={{ opacity: 0.8, fontSize: 20, padding: 0 }}
        PopperProps={{
          disablePortal: false,
        }}
        title={<TooltipText>{text}</TooltipText>}
      >
        <button style={{ border: 'none', background: 'none' }} type="button" onClick={() => handleOpen()}>
          <Icon style={{ cursor: 'pointer' }} />
        </button>
      </HtmlTooltip>
      {open && <Backdrop onClick={handleClose} />}
    </div>
  );
};

export default MobileTooltip;
