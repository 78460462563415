/* eslint-disable react/no-unescaped-entities */
import {
  Icon0, Icon1, Icon2, Icon3, Icon4,
} from './Icons';

// @ts-ignore
import pdf from './pdf/ebook-rentabilize.pdf';

export const data = [
  {
    step: 0,
    title: 'Bem vindo (a)',
    image: <Icon0 />,
    card: {
      title: 'O que há de novo?',
      description: (
        <>
          Conheça a Seazone! Descubra informações institucionais, vantagens de
          ser nosso parceiro e um eBook sobre rentabilidade de imóveis. Seja um
          parceiro de sucesso.
        </>
      ),
    },
  },
  {
    step: 1,
    image: <Icon1 />,
    card: {
      title: 'Conheça mais sobre a Seazone',
      description: (
        <>
          Acesse nosso
          {' '}
          <a target="_blank" href="https://seazone.com.br/sobre-a-empresa/">
            conteúdo institucional
          </a>
          {' '}
          e descubra nossa sólida reputação no mercado imobiliário, construída
          com confiança e resultados!
        </>
      ),
    },
  },
  {
    step: 2,
    image: <Icon2 />,
    card: {
      title: 'Blog',
      description: (
        <>
          Explore o
          {' '}
          <a target="_blank" href="https://seazone.com.br/blog/">
            blog da Seazone
          </a>
          {' '}
          e descubra vantagens de ser nosso parceiro, imóveis de alta conversão,
          histórias de sucesso e dicas de investimento!
        </>
      ),
    },
  },
  {
    step: 3,
    image: <Icon3 />,
    card: {
      title: 'E-book',
      description: (
        <>
          Baixe o
          {' '}
          <a
            target="_blank"
            href={pdf}
          >
            "Guia definitivo para rentabilizar imóveis"
          </a>
          {' '}
          da Seazone agora! Fortaleça parcerias compartilhando esse conteúdo
          exclusivo. Juntos, podemos ter sucesso e conquistar resultados
          incríveis.
        </>
      ),
    },
  },
  {
    step: 4,
    image: <Icon4 />,
    card: {
      title: 'Mapa de área de atuação',
      description: (
        <>
          Você conhece as áreas de atuação da Seazone? Agora você pode
          {' '}
          <a
            target="_blank"
            href="https://file.notion.so/f/s/1380db03-93e2-4127-b391-7f8e3aae985c/EBOOK_RENTABILIZE_Final.pdf?id=374aa5cb-3b81-4f04-85f4-b3110b87f78b&table=block&spaceId=65c5e3f6-e1b5-4d64-885d-dd47960fa782&expirationTimestamp=1689897600000&signature=eNoVS3bHznz4jPzpXjVrf6-r0NgqvpMOmEMy2VSQDkM&downloadName=EBOOK+RENTABILIZE_Final.pdf"
          >
            visualizar o mapa
          </a>
          {' '}
          e conferir quais as áreas de abrangência da Sezone. Acesse agora e
          pontecialize os resultados de suas indicações!
        </>
      ),
    },
  },
];
