import { motion } from 'framer-motion';
import styled from 'styled-components';

export const PropertyCardContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
`;

export const PropertyInformationCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  border: 1px solid #D9D9D9;
  border-radius: 6px 0 0 6px;
  padding: 0.75rem 1rem;
  width: 65%;
  min-height: 100px;

  @media (max-width: 900px) {
    gap: 0.5rem;
  }
`;

export const CardLink = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  background-color: transparent;
  color: #0D4BD0;
  cursor: pointer;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  width: 100%;

  @media (max-width: 900px) {
    font-size: 1rem;
    width: initial;
  }
`;

export const FinancialText = styled.p`
  font-weight: bold;
  color: #001840;
  line-height: 24px;
  font-family: 'Inter';
  font-size: 1.5rem;

  @media (max-width: 900px) {
    font-size: 1rem;
  }
`;

export const FinancialMonth = styled.p`
  font-weight: 500;
  color: #969696;
  line-height: 16px;
  font-family: 'Inter';
`;

export const Divider = styled.div<{ isToHide: boolean }>`
  height: 40px;
  width: 1px;
  background-color: #D9D9D9;

  @media (max-width: 900px) {
    display: ${({ isToHide }) => isToHide && 'none'};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 35%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ExtractButton = styled.button`
  padding: 0.75rem 1rem;
  background: #001840;
  color: #FFFFFF;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 0;
  gap: 0.5rem;
  width: 100%;

  @media (max-width: 900px) {
    padding: 0.75rem 1.5rem;
    justify-content: initial;
    border-radius: 0 6px 0 0;
  }
`;

export const BlockButton = styled.button`
  padding: 0.75rem 1rem;
  background: #425987;
  color: #FFFFFF;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 0 6px 6px 0;
  gap: 0.5rem;
  width: 100%;

  @media (max-width: 900px) {
    padding: 0.75rem 1.5rem;
    justify-content: initial;
    border-radius: 0 0 6px 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 900px) {
    display: block;
    align-items: initial;
    justify-content: initial;
    width: initial;
  }
`;

export const PropertyInformationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 0;
    align-items: initial;
  }
`;

export const SmallText = styled.p`
  margin-top: -0.5rem;
  color: #969696;
  @media (max-width: 900px) {
    display: none;
  }
`;
