import React from 'react';
import { Container } from './style';

const UserInfo = () => (
  <Container>
    <h1>
      <span>Faça seu pré check-in</span>
      Bem-vindo(a)! O pré check-in é o primeiro passo para uma experiência excepcional!
    </h1>
    <p>
      Confirme os dados abaixo para facilitar o seu check-in.
      Estamos ansiosos para recebê-lo(a)!
    </p>
    <p style={{ color: '#0d4bd0' }}>
      Pode ficar tranquilo(a), valorizamos sua privacidade.
    </p>
  </Container>
);

export default UserInfo;
