import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Error as StyledMessage } from '../../style';

interface IError {
  validate: boolean;
  children: React.ReactNode;
}

const Error = ({ children, validate }: IError) => (
  <>
    <AnimatePresence>
      {validate && (
        <StyledMessage
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: 10, opacity: 0 }}
          exit={{
            y: 10,
            opacity: 0,
            transition: { duration: 0.2 },
          }}
        >
          {children}
        </StyledMessage>
      )}
    </AnimatePresence>
  </>
);

export default Error;
