import styled, { css, keyframes } from 'styled-components';
import { ArrowDropDown } from '@mui/icons-material';

const fadeInModal = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 500px;
  }
`;

const fadeOutModal = keyframes`
  from {
    opacity: 1;
    width: 500px;
  }
  to {
    opacity: 0;
    width: 0;
  }
`;

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 8%;
  left: 35%;
  z-index: 20;
  
  width: 100vw;
  height: 100%;
  min-width: fit-content;
  max-height: 600px;

  opacity: 0;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  ${(props) => (props.isOpen ? css`
    animation: ${fadeInModal} 0.5s ease forwards;
    .btn-submit {
      animation: ${fadeInModal} 0.5s ease forwards;
    }
    ` : css`
      animation: ${fadeOutModal} 0.5s ease forwards;
      .btn-submit {
        animation: ${fadeOutModal} 0.5s ease forwards;
      }
  `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  

  content-visibility: auto;

  padding: 30px 30px;
  gap: 0.5rem;


  &::-webkit-scrollbar {
    width: 0.8rem;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #D9D9D9;
  }
  &::-webkit-scrollbar-thumb {
    background: #0D4BD0;
  }
`;

export const FormContainer = styled.form`

  height: 100%;
  border-radius: 10px;
  gap: 0.3rem;

  p, strong {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._410.hex()};
  padding: 30px 30px;
  gap: 0.5rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const Title = styled.strong`
  font-weight: bold;
  font-size: 1.375rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  color: ${({ theme }) => theme.palette.blue._970.hex()};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px dashed ${({ theme }) => theme.palette.grey._410.hex()};
  width: 100%;
  padding: 1rem 0;
`;

export const CardHostContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;

  div {
    display: flex;
  }

  strong {
    padding-left: 0.2rem;
  }
`;

export const CardHostContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  button {
    background: transparent !important;
    border: 0;
    cursor: pointer;
  }
`;

export const CardAddRemoveValue = styled.div`
  margin-top: 0.8rem;
  width: 100%;
  height: 45px;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const ContainerQuestion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ContainerAddRemove = styled.div<{ switchIsActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0;

  ${(props) => (props.switchIsActive ? css`
    p {
      :first-child {
        color: ${({ theme }) => theme.palette.grey._670.hex()};
      }
      :last-child {
        color: ${({ theme }) => theme.palette.blue._900.hex()};
      }
    }
    ` : css`
    p {
      :first-child {
        color: ${({ theme }) => theme.palette.blue._900.hex()};
      }
      :last-child {
        color: ${({ theme }) => theme.palette.grey._670.hex()};
      }
    }
  `)}
`;

export const SwitchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 25px;
  border: 1px solid ${({ theme }) => theme.palette.grey._410.hex()};
  border-radius: 57px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  position: relative;
  cursor: pointer;
  margin: 0 0.7rem;
`;

export const SwitchCircle = styled.div<{ switchIsActive: boolean }>`
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: ${({ theme, switchIsActive }) => (switchIsActive ? theme.palette.green.main.hex()
    : theme.palette.red._350.hex())};
  position: absolute;

  ${(props) => (props.switchIsActive ? css`
      right: 0;
    ` : css`
      left: 0;
  `)}
`;

export const ContainerValue = styled.div`
  margin-top: 1rem;

  fieldset {
    border: solid 1px ${({ theme }) => theme.palette.white._430.hex()} !important;
  }
`;

export const ContainerDescription = styled.div`
  margin-top: 1rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._350.hex()};
  position: relative;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
  background:  ${({ theme }) => theme.palette.white.main.hex()};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  button {
    margin: 1rem;
    cursor: pointer;

    @media (max-width: 900px) {
      margin: 0;
      margin-right: 3px;
    }

    @media (max-width: 320px) {
      margin: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 320px) {
    padding-right: 3rem;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background: transparent;

  svg {
    color: #969696;
  }
`;

export const Backdrop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.295);
  backdrop-filter: blur(1px);
  z-index: 10;
`;

export const ContentLoadMoreDatas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 20px;
  p {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
    font-weight: bold;
    font-size: 14px;
  }
`;

export const ArrowDownIcon = styled(ArrowDropDown)`
  fill:  ${({ theme }) => theme.palette.blue._400.hex()} !important;
  width: 30px !important;
  height: 30px !important;
  transform: rotate(0deg);
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;

export const SelectContainer = styled.div`
  width: 100%;

  .MuiOutlinedInput-root {
    max-height: 100% !important;
  }

  .MuiNativeSelect-select {
    padding: 0.8rem 0.5rem !important;
  }
`;

export const Spacing = styled.div`
  margin: 0.5rem 0;
`;
