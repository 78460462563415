import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { createContext } from 'use-context-selector';
import { ReservationDetails } from '../../../services/GuestDamage/types';

type ReservationContextProps = {
  reservations: ReservationDetails[];
  setReservations: Dispatch<SetStateAction<ReservationDetails[]>>;
  selectedReservation: ReservationDetails;
  setSelectedReservation: Dispatch<SetStateAction<ReservationDetails>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const ReservationsContext = createContext<ReservationContextProps>({
} as ReservationContextProps);

export const ReservationsProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [reservations,
    setReservations] = useState<ReservationDetails[]>([]);
  const [selectedReservation,
    setSelectedReservation] = useState<ReservationDetails>({} as ReservationDetails);
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(() => ({
    reservations,
    setReservations,
    selectedReservation,
    setSelectedReservation,
    isLoading,
    setIsLoading,
  }), [
    reservations,
    setReservations,
    selectedReservation,
    setSelectedReservation,
    isLoading,
    setIsLoading,
  ]);

  return (
    <ReservationsContext.Provider
      value={value}
    >
      {children}
    </ReservationsContext.Provider>
  );
};
