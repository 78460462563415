import React from 'react';

import {
  Container, Finish, Initial, TextContainer, TitlePhase,
} from './style';

interface Props {
  content: {
    id: number;
    title: string;
    questions: {
      name: string;
      checked: boolean;
    }[];
  }[];
  page: {
    id: number;
    title: string;
    questions: {
      name: string;
      checked: boolean;
    }[];
  };
  finish: boolean;
  started: boolean;
  setCurrentPage: Function;
  setStarted: Function;
}

export const LateralContent = ({
  content,
  page,
  finish,
  started,
  setCurrentPage,
  setStarted,
}: Props) => {
  const handleMoveToNotes = () => {
    setCurrentPage(content.length);
    setStarted(true);
  };
  const handleMoveToInitial = () => {
    setCurrentPage(0);
    setStarted(true);
  };

  return (
    <Container>
      <Finish finish={finish}>Finalizado</Finish>
      <TextContainer>
        <TitlePhase active={!started}>
          Introdução
        </TitlePhase>
        <TitlePhase className="checklists" active={page && started} onClick={handleMoveToInitial}>Checklists</TitlePhase>
        <TitlePhase className="observations" active={!page} onClick={handleMoveToNotes}>
          Observações
        </TitlePhase>
      </TextContainer>
      <Initial finish={finish}>Progresso</Initial>
    </Container>
  );
};
