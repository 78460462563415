import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.grey._240.hex()};
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  @media (min-width: 1000px){
    height: 100vh;
    display: flex;
    overflow-y: scroll;
    justify-content: center;
  }
`;

export const Content = styled(motion.div)`
  gap: 1rem;
  width: 100%;
  display: flex;
  max-width: 900px;
  flex-direction: column;
  height: max-content;
  padding-bottom: 8rem;
`;
