import {
  FC,
  useState,
  useMemo,
  useCallback,
  ReactElement,
} from 'react';

import { createContext } from 'use-context-selector';
import { OwnerServicesTermsProps, IsOpenProps } from './types';

export interface IOwnerServicesTerms {
  openServicesTerms: IsOpenProps,
  setOpenServicesTerms: Function,
  properties: OwnerServicesTermsProps[];
  setProperties: Function;
  readAndAgreedTerms: boolean,
  setReadAndAgreedTerms: Function,
  readAndNoAgreedTerms: boolean,
  setReadAndNoAgreedTerms: Function,
}

export const OwnerServicesTermsContext = createContext<IOwnerServicesTerms>({
  openServicesTerms: {
    servicesTermsPopup: false,
    confirmationModal: false,
  },
  setOpenServicesTerms: () => {},
  properties: [],
  setProperties: () => {},
  readAndAgreedTerms: false,
  setReadAndAgreedTerms: () => {},
  readAndNoAgreedTerms: false,
  setReadAndNoAgreedTerms: () => {},
});

export const OwnerServicesTermsProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [properties, setProperties] = useState<Array<OwnerServicesTermsProps>>([]);
  const [openServicesTerms, setOpenServicesTerms] = useState<IsOpenProps>({
    servicesTermsPopup: false,
    confirmationModal: false,
  });
  const [readAndAgreedTerms, setReadAndAgreedTerms] = useState<boolean>(false);
  const [readAndNoAgreedTerms, setReadAndNoAgreedTerms] = useState<boolean>(false);

  const handleSetPropertiesList = useCallback((data: OwnerServicesTermsProps[]) => {
    setProperties(data);
  }, []);

  const handleOpenServicesTerms = useCallback((isOpen: IsOpenProps) => {
    setOpenServicesTerms({
      servicesTermsPopup: isOpen.servicesTermsPopup,
      confirmationModal: isOpen.confirmationModal,
    });
  }, []);

  const handleSetReadAndAgreedTerms = useCallback((agreed: boolean) => {
    setReadAndAgreedTerms(agreed);
  }, []);

  const handleSetReadAndNoAgreedTerms = useCallback((agreed: boolean) => {
    setReadAndNoAgreedTerms(agreed);
  }, []);

  const value = useMemo(() => ({
    properties,
    setProperties: handleSetPropertiesList,
    openServicesTerms,
    setOpenServicesTerms: handleOpenServicesTerms,
    readAndAgreedTerms,
    setReadAndAgreedTerms: handleSetReadAndAgreedTerms,
    readAndNoAgreedTerms,
    setReadAndNoAgreedTerms: handleSetReadAndNoAgreedTerms,
  }), [
    properties,
    setProperties,
    openServicesTerms,
    setOpenServicesTerms,
    readAndAgreedTerms,
    handleSetReadAndAgreedTerms,
    readAndNoAgreedTerms,
    handleSetReadAndNoAgreedTerms,
  ]);

  return (
    <OwnerServicesTermsContext.Provider value={value}>
      {children}
    </OwnerServicesTermsContext.Provider>
  );
};
