export const Icon1 = () => (
  <svg width="419" height="295" viewBox="0 0 419 295" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M82.2274 158.463L81.9215 171.025L79.168 292.938H73.7168V158.463H82.2274Z" fill="#D6E3F8" />
    <path d="M82.2274 158.463L81.9215 171.025L73.7168 169.937V158.463H82.2274Z" fill="#96B1CC" />
    <path d="M147.337 158.631V293.105H141.886L139.299 178.508L138.826 158.631H147.337Z" fill="#D6E3F8" />
    <path d="M147.337 158.631V180.183L139.299 178.508L138.826 158.631H147.337Z" fill="#96B1CC" />
    <path d="M247.825 158.463L247.547 171.025L244.766 292.938H239.314V158.463H247.825Z" fill="#D6E3F8" />
    <path d="M312.932 158.631V293.105H307.509L304.894 178.508L304.449 158.631H312.932Z" fill="#D6E3F8" />
    <path d="M247.825 158.463L247.547 171.025L239.314 169.937V158.463H247.825Z" fill="#96B1CC" />
    <path d="M312.932 158.631V180.183L304.894 178.508L304.449 158.631H312.932Z" fill="#96B1CC" />
    <path d="M320.943 155.727H65.9023V164.604H320.943V155.727Z" fill="#D6E3F8" />
    <path d="M331.791 134.65C331.791 134.65 336.352 150.814 345.892 156.621C345.892 156.621 343.222 143.472 331.791 134.65Z" fill="#00CAB4" />
    <path d="M345.112 158.965L341.107 122.701H342.442L347.671 158.965H345.112Z" fill="#00CAB4" />
    <path d="M349.785 120.051C349.618 119.632 345.001 135.991 348.45 150.061C348.45 150.033 354.68 132.53 349.785 120.051Z" fill="#00CAB4" />
    <path d="M343.017 123.775L342.191 123.895L347.027 157.464L347.853 157.344L343.017 123.775Z" fill="black" />
    <path d="M349.451 157.403C349.451 157.403 355.792 140.905 370.895 129.18C370.895 129.18 366.333 144.897 353.234 157.403H349.451Z" fill="#00CAB4" />
    <path d="M353.261 157.487L352.732 156.845C352.871 156.734 366.166 145.344 370.366 129.264L371.172 129.487C366.889 145.818 353.4 157.376 353.261 157.487Z" fill="black" />
    <path d="M359.102 113.572L349.451 160.64H352.232L361.105 114.019L359.102 113.572Z" fill="#00CAB4" />
    <path d="M360.694 113.452L353.92 149.469L354.74 149.624L361.514 113.607L360.694 113.452Z" fill="black" />
    <path d="M357.962 155.393L357.49 159.943L355.737 176.693H343.416L341.191 155.393H357.962Z" fill="#ACC5EA" />
    <path d="M348.115 118.738C348.004 117.202 346.669 116.058 345.14 116.17L344.11 116.253L344.027 115.22C343.916 113.685 342.581 112.54 341.051 112.652C339.521 112.764 338.381 114.104 338.492 115.639L338.576 116.672L337.547 116.756C336.017 116.867 334.877 118.207 334.988 119.743C335.099 121.278 336.434 122.423 337.964 122.311L338.993 122.227L339.076 123.26C339.188 124.796 340.523 125.94 342.052 125.829C343.582 125.717 344.722 124.377 344.611 122.842L344.528 121.809L345.557 121.725C347.086 121.613 348.227 120.273 348.115 118.738Z" fill="#FFAA3F" />
    <path d="M365.165 111.619L364.358 110.977L364.998 110.167C365.944 108.967 365.749 107.208 364.553 106.259C363.357 105.31 361.605 105.505 360.659 106.706L360.019 107.515L359.213 106.873C358.017 105.924 356.265 106.119 355.319 107.32C354.374 108.52 354.568 110.279 355.764 111.228L356.571 111.87L355.931 112.68C354.985 113.88 355.18 115.639 356.376 116.588C357.572 117.537 359.324 117.342 360.27 116.141L360.909 115.332L361.716 115.974C362.912 116.923 364.664 116.728 365.61 115.527C366.555 114.355 366.361 112.596 365.165 111.619Z" fill="#FFAA3F" />
    <path d="M350.173 115.694C351.31 115.694 352.231 114.769 352.231 113.628C352.231 112.487 351.31 111.562 350.173 111.562C349.037 111.562 348.115 112.487 348.115 113.628C348.115 114.769 349.037 115.694 350.173 115.694Z" fill="#FFAA3F" />
    <path d="M362.661 132.529C363.889 132.529 364.886 131.529 364.886 130.296C364.886 129.062 363.889 128.062 362.661 128.062C361.432 128.062 360.436 129.062 360.436 130.296C360.436 131.529 361.432 132.529 362.661 132.529Z" fill="#FFAA3F" />
    <path d="M338.104 137.219C339.394 137.219 340.44 136.169 340.44 134.874C340.44 133.579 339.394 132.529 338.104 132.529C336.814 132.529 335.768 133.579 335.768 134.874C335.768 136.169 336.814 137.219 338.104 137.219Z" fill="#FFAA3F" />
    <path d="M343.916 151.791C339.661 141.601 331.595 134.985 331.512 134.929L332.04 134.287C332.124 134.343 340.356 141.099 344.695 151.484L343.916 151.791Z" fill="black" />
    <path d="M357.489 159.943L355.737 176.693H348.395L357.489 159.943Z" fill="#96B1CC" />
    <path d="M349.173 150.229L348.395 149.95C348.45 149.782 354.708 131.692 349.479 120.414L350.23 120.051C355.598 131.636 349.451 149.475 349.173 150.229Z" fill="black" />
    <path d="M357.961 154.832H341.051V155.67H357.961V154.832Z" fill="black" />
    <path d="M57.2783 176.217C55.9989 188.026 53.2177 202.431 47.9889 219.795L44.0117 219.292C44.0117 219.292 44.1508 213.737 44.5402 205.39C45.5136 183.308 48.0445 141.656 53.468 131.857C53.468 131.857 60.6992 144.699 57.2783 176.217Z" fill="#00CAB4" />
    <path d="M46.4066 225.044H45.5723C46.0729 171.918 52.9704 131.942 53.0538 131.551L53.8882 131.69C53.8048 132.081 46.9073 171.974 46.4066 225.044Z" fill="black" />
    <path d="M57.2783 176.217C55.9989 188.026 53.2177 202.431 47.9889 219.795L44.0117 219.292C44.0117 219.292 44.1508 213.737 44.5402 205.39C48.6842 193.078 53.8017 182.666 57.2783 176.217Z" fill="black" />
    <path d="M44.7637 218.038C44.7637 218.038 58.6977 179.82 67.403 170.328C67.403 170.328 73.1324 193.806 47.7396 219.294L44.7637 218.038Z" fill="#00CAB4" />
    <path d="M21.6242 145.844C21.6242 145.844 43.0121 177.055 45.0146 219.292H44.2636C44.2636 219.292 17.1464 173.816 21.6242 145.844Z" fill="#00CAB4" />
    <path d="M43.8479 219.348C37.451 177.194 21.4032 146.346 21.2363 146.039L21.9873 145.648C22.1541 145.956 38.2576 176.915 44.6823 219.209L43.8479 219.348Z" fill="black" />
    <path d="M44.5405 223.787L43.7617 223.508C54.8311 191.879 66.9295 170.327 67.0686 170.104L67.7917 170.522C67.6527 170.746 55.582 192.241 44.5405 223.787Z" fill="black" />
    <path d="M59.951 216.527L58.3101 236.655L53.721 292.963H36.0601L29.8301 216.527H59.951Z" fill="#ACC5EA" />
    <path d="M58.308 236.656L53.7189 292.964H41.4258L58.308 236.656Z" fill="#96B1CC" />
    <path d="M363.915 283.475H360.021V292.966H363.915V283.475Z" fill="#BAD0F7" />
    <path d="M390.337 283.475H386.443V292.966H390.337V283.475Z" fill="#BAD0F7" />
    <path d="M326.923 283.475H323.029V292.966H326.923V283.475Z" fill="#BAD0F7" />
    <path d="M353.345 283.475H349.451V292.966H353.345V283.475Z" fill="#BAD0F7" />
    <path d="M394.509 176.275H365.473V285.402H394.509V176.275Z" fill="#BAD0F7" />
    <path d="M366.557 285.401H319.832V176.387H366.585L366.557 285.401Z" fill="#D6E3F8" />
    <path d="M322.197 184.371V282.414H363.249V184.371H322.197ZM334.741 185.795V188.81H349.537V185.795H361.802V203.466H323.644V185.795H334.741ZM323.644 261.561V242.494H334.741V245.648H349.537V242.494H361.802V261.561H323.644ZM323.644 241.042V223.65H334.741V226.721H349.537V223.65H361.802V241.042H323.644ZM323.644 222.198V204.89H334.741V208.044H349.537V204.918H361.802V222.226H323.644V222.198ZM323.644 280.963V262.984H334.741V266.139H349.537V262.984H361.802V280.963H323.644Z" fill="#ACC5EA" />
    <path d="M215.006 8.99805H178.488V54.2231H215.006V8.99805Z" fill="#D6E3F8" />
    <path d="M195.122 14.666H183.135V16.4527H195.122V14.666Z" fill="white" />
    <path d="M210.864 20.9746H183.135V22.7613H210.864V20.9746Z" fill="white" />
    <path d="M210.864 27.2852H183.135V29.0718H210.864V27.2852Z" fill="white" />
    <path d="M210.864 33.5645H183.135V35.3511H210.864V33.5645Z" fill="white" />
    <path d="M210.864 39.873H183.135V41.6597H210.864V39.873Z" fill="white" />
    <path d="M192.508 46.1816H183.135V47.9683H192.508V46.1816Z" fill="white" />
    <path d="M214.949 63.0182C219.88 63.0182 223.877 59.0061 223.877 54.057C223.877 49.1078 219.88 45.0957 214.949 45.0957C210.019 45.0957 206.021 49.1078 206.021 54.057C206.021 59.0061 210.019 63.0182 214.949 63.0182Z" fill="#00CAB4" />
    <path d="M213.866 57.6839L210.167 53.971C209.861 53.6639 209.861 53.1614 210.167 52.8544C210.473 52.5473 210.974 52.5473 211.279 52.8544L213.894 55.4785L218.733 50.8164C219.039 50.5094 219.54 50.5373 219.846 50.8444C220.152 51.1514 220.124 51.6539 219.818 51.961L213.866 57.6839Z" fill="white" />
    <path d="M179.517 81.3576C184.049 81.3576 187.722 77.6704 187.722 73.1221C187.722 68.5738 184.049 64.8867 179.517 64.8867C174.986 64.8867 171.312 68.5738 171.312 73.1221C171.312 77.6704 174.986 81.3576 179.517 81.3576Z" fill="#FFAA3F" />
    <path d="M178.85 78.1756V77.0031C178.015 76.9752 177.236 76.7518 176.764 76.4727L177.125 75.021C177.654 75.3002 178.377 75.5514 179.156 75.5514C179.851 75.5514 180.324 75.2722 180.324 74.7977C180.324 74.3231 179.934 74.0439 179.016 73.7368C177.709 73.2902 176.791 72.676 176.791 71.4756C176.791 70.3868 177.542 69.5493 178.877 69.2702V68.0977H180.073V69.1864C180.908 69.2143 181.464 69.4097 181.853 69.6052L181.492 71.001C181.158 70.8614 180.602 70.5822 179.712 70.5822C178.905 70.5822 178.655 70.9172 178.655 71.2802C178.655 71.6989 179.072 71.9502 180.129 72.341C181.603 72.8714 182.187 73.5414 182.187 74.6581C182.187 75.7468 181.408 76.696 179.99 76.9472V78.2035H178.85V78.1756Z" fill="white" />
    <path d="M326.453 3.46875H285.763C284.567 3.46875 283.594 4.44583 283.594 5.64625V23.4571C283.594 24.6575 284.567 25.6346 285.763 25.6346H288.489V32.7812L295.025 25.6346H326.425C327.621 25.6346 328.594 24.6575 328.594 23.4571V5.67417C328.622 4.44583 327.649 3.46875 326.453 3.46875Z" fill="#D6E3F8" />
    <path d="M303.87 8.4082H288.045V10.2228H303.87V8.4082Z" fill="white" />
    <path d="M324.146 13.5195H288.045V15.3341H324.146V13.5195Z" fill="white" />
    <path d="M324.146 18.6543H288.045V20.4689H324.146V18.6543Z" fill="white" />
    <path d="M66.4038 0.957031H107.093C108.289 0.957031 109.263 1.93411 109.263 3.13453V20.9174C109.263 22.1179 108.289 23.0949 107.093 23.0949H104.368V30.2416L97.8041 23.1229H66.4038C65.2078 23.1229 64.2344 22.1458 64.2344 20.9454V3.13453C64.2344 1.93411 65.2078 0.957031 66.4038 0.957031Z" fill="#D6E3F8" />
    <path d="M77.2778 14.1613C78.4452 14.1613 79.3916 13.2114 79.3916 12.0396C79.3916 10.8679 78.4452 9.91797 77.2778 9.91797C76.1104 9.91797 75.1641 10.8679 75.1641 12.0396C75.1641 13.2114 76.1104 14.1613 77.2778 14.1613Z" fill="white" />
    <path d="M86.4009 14.1613C87.5683 14.1613 88.5146 13.2114 88.5146 12.0396C88.5146 10.8679 87.5683 9.91797 86.4009 9.91797C85.2335 9.91797 84.2871 10.8679 84.2871 12.0396C84.2871 13.2114 85.2335 14.1613 86.4009 14.1613Z" fill="white" />
    <path d="M95.772 14.1613C96.9393 14.1613 97.8857 13.2114 97.8857 12.0396C97.8857 10.8679 96.9393 9.91797 95.772 9.91797C94.6046 9.91797 93.6582 10.8679 93.6582 12.0396C93.6582 13.2114 94.6046 14.1613 95.772 14.1613Z" fill="white" />
    <path d="M126.73 55.3105L128.01 60.6426C128.01 60.6426 128.955 62.0105 129.985 62.2618L129.4 56.511L126.73 55.3105Z" fill="black" />
    <path d="M142.082 65.9743L142.805 69.7989L144.056 76.5269L128.175 76.9456L129.038 61.731L129.093 58.046C128.259 57.5156 127.591 57.0131 127.035 56.5385C125.144 54.9193 124.699 53.5514 124.838 52.4906C124.838 52.4906 124.838 52.4627 124.838 52.4348C125.06 50.8435 126.674 49.9502 126.674 49.9502L127.675 40.291L146.81 41.8823C146.893 42.2731 147.005 42.6639 147.088 43.0268V43.0827C147.533 45.0648 147.867 46.8514 148.089 48.5264V48.5823C150.676 67.2027 142.082 65.9743 142.082 65.9743Z" fill="#A55C5C" />
    <path d="M130.681 42.7754L131.738 53.2162L129.708 52.6858C129.708 52.6858 128.651 50.1175 126.704 50.3129C125.869 50.3966 124.673 51.8762 124.785 53.1046C124.785 53.1046 117.359 40.1791 124.034 39.4812C124.034 39.4812 134.575 22.5079 139.637 33.5071C139.637 33.5071 147.535 29.515 145.867 36.4383C145.867 36.4383 151.958 34.2329 149.733 43.1941L148.203 48.9729L147.09 43.0546C147.09 43.0546 142.418 45.1483 135.048 42.7196C135.048 42.7196 136.855 44.925 140.109 45.7625C140.082 45.7625 133.685 45.2321 130.681 42.7754Z" fill="black" />
    <path d="M142.805 69.8004C135.713 68.8513 133.794 61.7604 133.738 61.5371C135.185 63.6029 139.412 65.1384 142.082 66.0038L142.805 69.8004Z" fill="black" />
    <path d="M276.443 57.7368C277.917 56.6201 279.53 54.3868 279.002 52.2093C278.724 51.0647 278.14 50.5901 277.472 50.5064C277.556 45.8164 276.193 41.0147 270.741 39.898C259.338 37.553 254.443 46.0118 255.528 54.9451C256.613 63.8785 255.89 67.6751 263.399 69.2385L263.037 80.9914L278.473 80.126L275.636 60.5564C275.609 60.6122 275.998 59.4676 276.443 57.7368Z" fill="#FFAD94" />
    <path d="M274.527 58.3821C273.693 57.8238 274.082 54.753 274.527 52.5196C274.722 51.5705 276.78 49.9234 278.087 50.7609C279.172 46.1267 279.255 40.7667 274.527 39.5663C273.136 35.7417 269.604 36.6071 267.602 36.998C261.372 38.2542 259.425 38.31 256.866 36.7746C255.003 35.658 253.223 36.0767 252.917 37.333C252.361 39.5942 253.584 40.8225 253.584 40.8225C251.248 41.4646 251.165 43.0559 251.248 44.033C251.498 46.6013 252.972 47.69 254.947 47.9134C255.142 49.4767 255.587 50.9842 255.531 51.4588C255.531 51.4588 255.698 49.7559 256.338 47.9413C261.149 47.718 267.63 44.005 267.63 44.005C266.879 46.0988 270.995 47.1038 272.58 47.4109C272.942 50.0909 273.415 56.735 270.105 61.5646C270.105 61.5646 261.205 57.8517 255.976 60.2525C255.976 60.2525 255.364 69.8559 264.57 69.4092C264.57 69.4092 267.824 69.0742 269.771 67.12C269.771 67.12 272.942 64.3284 275.501 58.2425C275.083 58.4938 274.722 58.5217 274.527 58.3821Z" fill="black" />
    <path d="M114.854 270.044L112.796 278.558L112.212 280.987L110.877 286.459H101.143L102.033 279.536L102.339 277.079L103.284 269.904L114.854 270.044Z" fill="#A55C5C" />
    <path d="M114.855 270.044L112.797 278.558L112.213 280.987L102.033 279.536L102.339 277.079L103.285 269.904L114.855 270.044Z" fill="black" />
    <path d="M101.559 283.138C102.421 284.031 105.87 286.962 111.627 282.97C111.683 282.942 111.738 282.942 111.794 282.97L127.647 291.485C128.398 291.876 128.12 293.02 127.258 293.02H101.392C100.196 293.02 99.3063 291.904 99.5844 290.731L101.281 283.249C101.309 283.082 101.476 283.026 101.559 283.138Z" fill="black" />
    <path d="M152.873 269.933L151.649 280.904L151.01 286.46H141.275L141.303 279.341L141.331 269.766L152.873 269.933Z" fill="#A55C5C" />
    <path d="M152.873 269.933L151.649 280.904L141.303 279.341L141.331 269.766L152.873 269.933Z" fill="black" />
    <path d="M141.137 283.138C141.999 284.031 145.448 286.962 151.205 282.97C151.261 282.942 151.317 282.942 151.372 282.97L167.225 291.485C167.976 291.876 167.698 293.02 166.836 293.02H140.97C139.774 293.02 138.884 291.904 139.163 290.731L140.859 283.249C140.887 283.082 141.054 283.026 141.137 283.138Z" fill="black" />
    <path d="M259.869 269.933L261.121 280.904L261.733 286.46H271.495L271.467 279.341L271.439 269.766L259.869 269.933Z" fill="#FFAD94" />
    <path d="M259.869 269.933L261.121 280.904L271.467 279.341L271.439 269.766L259.869 269.933Z" fill="black" />
    <path d="M271.604 283.138C270.742 284.031 267.293 286.962 261.536 282.97C261.48 282.942 261.424 282.942 261.369 282.97L245.516 291.485C244.765 291.876 245.043 293.02 245.905 293.02H271.771C272.967 293.02 273.857 291.904 273.579 290.731L271.882 283.249C271.854 283.082 271.715 283.026 271.604 283.138Z" fill="black" />
    <path d="M310.708 286.46H300.946L299.611 281.044L296.857 269.933L308.427 269.766L309.735 279.481L310.708 286.46Z" fill="#FFAD94" />
    <path d="M309.735 279.481L299.611 281.044L296.857 269.933L308.427 269.766L309.735 279.481Z" fill="black" />
    <path d="M310.266 283.138C309.404 284.031 305.955 286.962 300.198 282.97C300.142 282.942 300.087 282.942 300.031 282.97L284.178 291.485C283.427 291.876 283.705 293.02 284.567 293.02H310.433C311.629 293.02 312.519 291.904 312.241 290.731L310.544 283.249C310.516 283.082 310.377 283.026 310.266 283.138Z" fill="black" />
    <path d="M254.61 171.947C253.776 172.784 252.941 171.947 252.941 171.947C252.385 172.784 251.273 171.947 251.273 171.947C250.16 172.784 249.604 171.667 249.604 171.667C247.657 173.063 247.935 167.759 247.935 167.759C247.657 164.688 249.048 162.734 249.048 162.734V157.988L253.776 159.384L253.915 161.645L254.61 171.947Z" fill="#FFAD94" />
    <path d="M258.729 168.484C259.591 168.484 260.259 169.182 260.259 170.02V170.913V177.194H258.729V172.309C258.729 171.527 258.117 170.913 257.339 170.913H243.293C242.515 170.913 241.903 171.527 241.903 172.309V177.194H240.373V170.913V170.02C240.373 169.154 241.068 168.484 241.903 168.484H258.729Z" fill="#E54E13" />
    <path d="M227.217 178.589V209.102C227.217 210.274 228.162 211.224 229.331 211.224H272.635C273.803 211.224 274.748 210.274 274.748 209.102V178.589C274.748 177.417 273.803 176.467 272.635 176.467H229.331C228.162 176.439 227.217 177.417 227.217 178.589Z" fill="#FFAA3F" />
    <path d="M232.029 188.641L253.806 196.486L274.777 188.948V187.078L253.806 194.615L232.613 186.994L232.029 188.641Z" fill="#E54E13" />
    <path d="M227.217 178.589V209.102C227.217 210.274 228.162 211.224 229.331 211.224H230.749C231.917 211.224 232.863 210.274 232.863 209.102V178.589C232.863 177.417 231.917 176.467 230.749 176.467H229.331C228.162 176.439 227.217 177.417 227.217 178.589Z" fill="#E54E13" />
    <path d="M158.101 151.01L162.551 205.447L156.989 278.449H140.023L140.301 208.239L132.514 151.01H158.101Z" fill="black" />
    <path d="M147.673 150.868L136.826 205.026L118.887 278.447H100.809L112.212 208.515L111.377 149.611L147.673 150.868Z" fill="#273237" />
    <path d="M230.416 113.042L229.582 120.301L226.244 120.022C226.161 120.077 226.077 120.133 225.994 120.189C223.129 122.032 213.45 127.28 213.45 127.28C207.332 128.397 203.994 125.605 203.994 125.605L209.557 116.951C209.557 116.951 206.497 115.276 208.166 113.601C209.835 111.926 221.516 112.205 221.516 112.205C223.991 112.205 225.938 112.345 227.412 112.512C229.387 112.763 230.416 113.042 230.416 113.042Z" fill="#FFAD94" />
    <path d="M218.732 115.554C218.732 115.554 217.62 117.787 212.892 117.787L204.27 125.883H197.873V116.671L200.905 116.419L207.886 115.833L213.17 114.158L218.732 115.554Z" fill="#A55C5C" />
    <path d="M202.462 125.882H197.873V116.669L200.905 116.418L202.462 125.882Z" fill="black" />
    <path d="M199.265 115.275V128.675L176.208 127.754C172.815 127.614 169.617 126.134 167.308 123.594L156.851 109.245L159.465 158.434L159.493 158.797C133.627 168.568 109.43 158.518 109.43 158.518L111.906 109.161L111.934 108.547L104.98 116.364L104.869 122.561L104.424 151.818H91.0742V115.582C91.0742 113.349 91.5748 111.171 92.5205 109.161L104.675 83.7849C106.983 78.9553 111.238 75.3261 116.384 73.8465L127.926 70.4686C140.441 81.3561 143.223 70.1895 143.223 70.1895L148.785 71.1386C152.929 71.8645 156.6 74.349 158.798 77.9503L181.187 114.437L199.265 115.275Z" fill="#FFAA3F" />
    <path d="M253.916 161.645L249.049 160.557V157.988L253.777 159.384L253.916 161.645Z" fill="black" />
    <path d="M254.751 160.639L244.738 158.406L249.466 109.273L251.691 84.427L256.976 78.5645L254.751 160.639Z" fill="#00A388" />
    <path d="M276.026 161.058L273.245 202.375L274.357 277.052H255.167L250.717 200.979L252.386 138.167L281.032 135.096L276.026 161.058Z" fill="black" />
    <path d="M230.416 113.042L229.582 120.3L226.244 120.021C226.161 120.077 226.078 120.132 225.994 120.188L227.385 112.539C229.387 112.762 230.416 113.042 230.416 113.042Z" fill="black" />
    <path d="M298.387 136.406L300.64 194.417L310.653 277.05H293.131L275.331 201.955L264.623 140.957L298.387 136.406Z" fill="#273237" />
    <path d="M300.641 152.543C278.948 166.501 250.023 153.101 250.023 153.101V152.794L250.996 129.512L227.355 126.162L229.024 110.808L251.552 113.041V86.4364C251.552 83.9798 252.498 81.6348 254.222 79.876L262.955 74.7952C268.379 84.7056 277.696 71.166 277.696 71.166L290.879 75.2698C294.411 76.861 296.748 80.2948 296.97 84.1752L297.916 101.539L300.641 152.543Z" fill="#00CAB4" />
    <path d="M212.893 128.674C212.893 128.674 214.84 130.349 215.952 127.837C215.952 127.837 218.177 128.395 218.734 126.162C218.734 126.162 220.68 126.441 220.959 124.487C220.959 124.487 222.905 124.766 223.184 122.812L222.071 118.066C222.071 118.066 219.846 117.787 219.568 120.02C219.568 120.02 217.065 119.183 216.787 121.695C216.787 121.695 214.562 121.695 214.562 123.649C214.562 123.649 212.337 123.091 212.059 125.603C212.059 125.603 212.059 127.278 212.893 128.674Z" fill="#A55C5C" />
    <path d="M91.6292 162.091C90.6836 162.035 89.8492 162.761 89.7936 163.71L89.738 164.715L89.293 171.778L91.0173 171.89L91.3789 166.223C91.4345 165.441 92.0742 164.883 92.853 164.911L108.929 165.915C109.707 165.971 110.264 166.613 110.236 167.395L109.874 173.062L111.599 173.174L112.044 166.111L112.099 165.134C112.155 164.185 111.432 163.347 110.486 163.291L91.6292 162.091Z" fill="#E54E13" />
    <path d="M126.285 175.632L124.143 209.858C124.06 211.17 122.919 212.175 121.612 212.091L73.0795 209.02C71.7723 208.936 70.771 207.792 70.8545 206.48L72.996 172.254C73.0795 170.942 74.2198 169.937 75.527 170.021L124.06 173.091C125.367 173.175 126.368 174.32 126.285 175.632Z" fill="#FFAA3F" />
    <path d="M120.167 186.573L95.1914 193.804L72.2461 183.865L72.3852 181.771L95.3305 191.71L119.639 184.675L120.167 186.573Z" fill="#E54E13" />
    <path d="M113.465 165.665C110.155 167.312 107.847 164.325 107.847 164.325C107.68 166.809 106.651 168.568 106.651 168.568C104.954 170.075 103.23 167.73 103.23 167.73C101.199 168.707 99.7532 166.027 99.7532 166.027C97.0832 167.228 96.6938 163.459 96.6938 163.459C96.7216 157.122 96.7216 155.028 96.7216 155.028V154.414L96.666 151.846L104.12 151.65L104.259 153.13L104.287 153.186L106.067 155.977C107.819 157.82 111.101 162.51 111.101 162.51L113.465 165.665Z" fill="#A55C5C" />
    <path d="M126.285 175.632L124.143 209.857C124.06 211.17 122.919 212.175 121.612 212.091L120.027 211.979C118.72 211.895 117.718 210.751 117.802 209.439L119.943 175.213C120.027 173.901 121.167 172.896 122.474 172.979L124.06 173.091C125.367 173.175 126.368 174.319 126.285 175.632Z" fill="#E54E13" />
    <path d="M115.344 102.587L104.777 116.264L105.437 116.777L116.004 103.1L115.344 102.587Z" fill="black" />
    <g opacity="0.15">
      <path d="M159.494 158.826C133.629 168.597 109.432 158.547 109.432 158.547L111.907 109.191L111.935 109.135C111.796 169.574 151.317 159.971 159.466 158.464L159.494 158.826Z" fill="black" />
    </g>
    <path d="M155.707 98.1983L154.877 98.2793L156.976 120.064L157.806 119.983L155.707 98.1983Z" fill="black" />
    <path d="M104.314 153.187L96.7216 154.416L96.666 151.875L104.148 151.68L104.259 153.131L104.314 153.187Z" fill="black" />
    <path d="M273.497 134.006C272.968 134.006 272.44 133.95 271.884 133.866L242.291 128.813L242.43 127.976L272.023 133.029C275.61 133.643 279.254 131.94 281.117 128.785L289.127 115.162L289.85 115.581L281.84 129.204C280.088 132.219 276.862 134.006 273.497 134.006Z" fill="black" />
    <g opacity="0.15">
      <path d="M300.64 152.545C278.947 166.503 250.021 153.103 250.021 153.103V152.796C300.64 166.224 295.717 118.877 297.887 101.541L300.64 152.545Z" fill="black" />
    </g>
    <g opacity="0.15">
      <path d="M251.553 113.042L268.24 114.996L279.087 99.084L267.128 112.763L251.553 113.042Z" fill="black" />
    </g>
    <path d="M268.906 163.021L268.084 163.166L273.773 195.579L274.595 195.434L268.906 163.021Z" fill="white" />
    <path d="M144.263 166.779L137.811 197.908L138.627 198.079L145.08 166.949L144.263 166.779Z" fill="white" />
    <path d="M141.275 58.7422L146.838 59.3005C146.838 59.3005 144.891 63.488 141.275 58.7422Z" fill="white" />
    <path d="M262.538 62.0938H257.254C257.254 62.0938 258.088 66.0021 262.538 62.0938Z" fill="white" />
    <path d="M418.288 292.826H0.822266V294.501H418.288V292.826Z" fill="black" />
  </svg>
);
