import styled from 'styled-components';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Check } from '@mui/icons-material';

export const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0D4BD0',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0D4BD0',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean } }>(({ ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#0D4BD0',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#0D4BD0',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

export const CheckIcon = styled(Check)`
  color: ${({ theme }) => theme.palette.white.main.hex()};
  z-index: 1;
  font-size: 18px;
  border-radius: 50%;
  background: #0D4BD0;
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 30px;

  span {
    color: black;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;
