import React, { useState, memo } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Container, Content, Header, NameAuthor, Separator, Date,
  Comment, InitialName, Wrapper, Informations, IconImage, Description, EditComment,
} from './styles';

import iconCalendar from '../../assets/icons/generals/calendar.svg';
import iconCopy from '../../assets/icons/generals/copy.svg';
import iconEdit from '../../assets/icons/generals/edit.svg';
// import iconDelete from '../../assets/icons/propertySearch/trash.svg';
import { useToast } from '../../context/ToastContext';
import TextField from '../TextField/TextField';
import { useToastErrorMessage } from '../../utils/Messages';
import { editComment } from '../../services/Comments/request';

interface Props {
  nameAuthor: string,
  comment: string,
  date: string,
  property: number,
  idComment: number,
  setReloadComment: (a: boolean) => void;
}

const options = [
  {
    id: '1',
    icon: iconEdit,
    description: 'Editar',
  },
  {
    id: '2',
    icon: iconCopy,
    description: 'Copiar',
  },
  // {
  // id: '3',
  // icon: iconDelete,
  // description: 'Excluir',
  // },
];

const ITEM_HEIGHT = 48;

const CommentProperty = ({
  nameAuthor, comment, date, property, idComment, setReloadComment,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activEdit, setActiveEdit] = useState(false);
  const open = Boolean(anchorEl);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const validation = Yup.object().shape({
    comment: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      if (property) {
        try {
          await editComment({ id: idComment, property, comment: values.comment });
          toast.success('Comentário salvo com sucesso!');
          setActiveEdit(false);
          setReloadComment(true);
        } catch (error) {
          toastErrorRequest(error);
        }
      }
    },
  });

  async function copyComment(text: string) {
    if ('clipboard' in navigator) {
      toast.success('Comentário copiado com sucesso!');
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand(
      'copy', true, text,
    );
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (id: string, commented: string) => {
    if (id === '1') {
      setActiveEdit(true);
      handleClose();
    } else if (id === '2') {
      copyComment(commented);
      handleClose();
    }
    return null;
  };

  const initialsNameSeparator = (name: string): string => {
    const resultadoEspaco = name.split(' ', 2);
    const first = resultadoEspaco[0].substring(0, 1);
    const second = resultadoEspaco[1].substring(0, 1);
    const abrev: string = (first + second);
    return abrev;
  };
  return (
    <Container>
      <Wrapper>
        <InitialName>
          {initialsNameSeparator(nameAuthor)}
        </InitialName>
        <Content>
          <Header>
            <Informations>
              <NameAuthor>{nameAuthor}</NameAuthor>
              <Separator />
              <Date>
                <img src={iconCalendar} alt="calendário" />
                {`${moment(date).format('DD MMM YYYY')} às ${moment(date).format('HH:mm')}`}
              </Date>
            </Informations>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '15ch',
                },
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.description} selected={option.description === 'Pyxis'} onClick={() => handleAction(option.id, comment)}>
                  <IconImage src={option.icon} alt={option.description} />
                  <Description>
                    {option.description}
                  </Description>
                </MenuItem>
              ))}
            </Menu>
          </Header>
          <Comment active={activEdit}>
            {comment}
          </Comment>
          <EditComment active={activEdit}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="comment"
                placeholder="Adicionar comentário"
                activeButton
                formik={formik}
                value={comment}
              />
            </form>
          </EditComment>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default memo(CommentProperty);
