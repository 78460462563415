import styled from 'styled-components';

export const Container = styled.div`
  label {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.5px;
    padding-bottom: 8px;
  }
`;

export const NumberInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
  width: 100%;

  &:has(input:focus) {
    border-color: #41B592;
  }

  &:has(input:hover) {
    border-color: #41B592;
  }
`;

export const Input = styled.input`
  font-size: 1rem;
  font-weight: regular;
  border: 0;
  text-align: center;
  width: 100%;
  height: 100%;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  svg {
    color: $gray800;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: $neutralGray300;
  }
`;

export const CircularButton = styled.button`
  border-radius: 99999px;
  border: none;
  all: unset;
  border-radius: 999999px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > svg {
    width: 14px;
    height: 14px;
  }

  &:disabled {
    cursor: not-allowed;
  };
`;

export const ContainerLabel = styled.div`
  display: flex;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;
