import React from 'react';
import { motion } from 'framer-motion';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Text from './Text';

import { Container } from './style';
import { useMobile } from '../../../../../../../../hooks/useMobile/useMobile';
import { useUser } from '../../../../../../../../context/UserContext';
import { analyticsEvent } from '../../../../../../utils/analytics';

interface IScrollDialog {
  open: boolean;
  setCheckbox: Function;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUserTerms {
  checkbox: boolean;
  setCheckbox: Function;
}

function ScrollDialog({
  open,
  setOpen,
  setCheckbox,
}: IScrollDialog) {
  const { isMobile } = useMobile();
  const { userInformation } = useUser();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setCheckbox(true);
    analyticsEvent({
      userInformation,
      name: 'Aceitou os termos de indicação estando dentro do modal para leitura',
    });
    handleClose();
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }

      analyticsEvent({
        userInformation,
        name: 'Abriu para leitura dos termos de indicação',
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      maxWidth={false}
      onClose={handleClose}
      fullScreen={isMobile}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Termos e Condições</DialogTitle>
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          },
        }}
        dividers
      >
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Text />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept}>Aceitar</Button>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}

const UserTerms = ({ checkbox, setCheckbox }: IUserTerms) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ScrollDialog
        open={open}
        setOpen={setOpen}
        setCheckbox={setCheckbox}
      />

      <Container>
        <motion.input
          id="checkbox"
          type="checkbox"
          checked={checkbox}
          whileTap={{ scale: 0.9 }}
          onChange={() => setCheckbox(!checkbox)}
        />

        <motion.p>
          Li e aceito os
          <motion.button
            type="button"
            whileTap={{ scale: 0.9 }}
            onClick={() => setOpen(true)}
          >
            Termos
          </motion.button>
        </motion.p>
      </Container>
    </>
  );
};

export default UserTerms;
