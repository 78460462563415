import { useContextSelector } from 'use-context-selector';
import { PropertyRulesContext } from '../../context/InsertPropertyRulesContext/InsertPropertyRulesContext';

export function usePropertyRules() {
  const allRules = useContextSelector(PropertyRulesContext, (state) => state.allRules);
  const handleSetAllRules = useContextSelector(PropertyRulesContext,
    (state) => state.setAllRules);
  const handleUpdateRule = useContextSelector(PropertyRulesContext,
    (state) => state.handleUpdateRule);

  return {
    allRules,
    handleSetAllRules,
    handleUpdateRule,
  };
}
