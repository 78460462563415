import React from 'react';
import { usePartners } from '../../../../../../../../context/Partners/Partner/PartnerContext';
import { SelectStyled } from '../../style';
import { UseFormik } from '../../../../../../../../utils/Formik/types';
import { validateError } from '../Input/Input';
import { usePartnersEarning } from '../../../../../../../../context/Partners/Earning/Earning';

type IBanks = {
  formik: UseFormik<any>
};

const Banks = ({ formik }: IBanks) => {
  const { banks } = usePartners();
  const { setFormValues } = usePartnersEarning();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = Number(e.target.value);

    const bankSelected = banks.find((bank) => bank.id === id);
    if (bankSelected) {
      setFormValues((old) => ({
        ...old,
        bankId: bankSelected.id,
        bankName: bankSelected.long_name,
        bankNumber: bankSelected.bank_number,
      }));
    }

    formik.handleChange(e);
  };

  return (
    <SelectStyled
      id="bankId"
      placeholder="Selecione um banco"
      onChange={(e) => handleChange(e)}
      value={formik.values.bankId}
      error={validateError(formik, 'bankId')}
    >
      <option defaultChecked value="Selecione um banco">Selecione um banco</option>
      {banks.map((bank) => (
        <option key={bank.id} value={bank.id}>{`${bank.bank_number} - ${bank.long_name}`}</option>
      ))}
    </SelectStyled>
  );
};

export default Banks;
