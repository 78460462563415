import request from '../request';

import { PostListingProps } from './types';

export const postListing = async (listing: PostListingProps) => {
  const { data } = await request.post('/listings/', listing);
  return data;
};

export const getListings = async (): Promise<Array<PostListingProps>> => {
  const { data } = await request.get('/listings/');
  return data as PostListingProps[];
};

export const getListingsById = async (id: number): Promise<PostListingProps> => {
  const { data } = await request.get(`/listings/${id}/`);
  return data as PostListingProps;
};

export const patchListings = async (id: number, data: PostListingProps) => {
  await request.patch(`/listings/${id}/`, {
    ...data,
  });
};
