import { Container, Content } from './styles';

import copyIcon from '../../../assets/icons/generals/copy.svg';
import Tooltip from '../../Tooltip';
import { useToast } from '../../../context/ToastContext';

interface ICopyText {
  text: string,
  toastMessage?: string,
  tooltipText?: string,
}

const CopyIcon = () => (
  <img src={copyIcon} alt="icone de copiar" className="copyIcon" />
);

const CopyText = ({
  text,
  toastMessage = 'Texto copiado com sucesso!',
  tooltipText = 'Copiar texto',
}: ICopyText) => {
  const toast = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    toast.success(toastMessage);
  };

  return (
    <Container onClick={() => copyToClipboard()}>
      <p>{text}</p>
      <Tooltip text={tooltipText}>
        <Content>
          <CopyIcon />
        </Content>
      </Tooltip>
    </Container>
  );
};

CopyText.defaultProps = {
  toastMessage: 'Texto copiado com sucesso!',
  tooltipText: 'Copiar texto',
};

export default CopyText;
