import { useEffect, useState } from 'react';
import {
  Divider,
  ItemContainer,
  StepIcon, StepItem, StepItemContainer, StepText, StepsContainer,
} from './styles';

import damageDetailsIcon from '../../../assets/icons/guestDamage/damageDetailsIcon.svg';
import billingHistoryHandlingIcon from '../../../assets/icons/guestDamage/billingHistoryHandlingIcon.svg';
import damageRefundIcon from '../../../assets/icons/guestDamage/damageRefundIcon.svg';
import damageDetailsIconFinished from '../../../assets/icons/guestDamage/damageDetailsIconFinished.svg';
import billingHistoryHandlingIconFinished from '../../../assets/icons/guestDamage/billingHistoryHandlingIconFinished.svg';
import damageRefundIconFinished from '../../../assets/icons/guestDamage/damageRefundIconFinished.svg';
import statusIconFinished from '../../../assets/icons/guestDamage/statusIconFinished.svg';
import statusIcon from '../../../assets/icons/guestDamage/statusIcon.svg';
import { useGuestDamageStep } from '../../../hooks/GuestDamage/useGuestDamageStep';
import { useGuestDamageNegotiation } from '../../../hooks/GuestDamage/useGuestDamageNegotiation';

interface StepProps {
  name: string;
  icon: string;
  finishedIcon: string;
  alt: string;
  isActive: boolean;
  onClick: () => void;
}

const StepsCard = () => {
  const {
    handleViewBillingHistoryNegotiationsStep,
    handleViewDamageDetailsStep,
    handleViewRefundDamageStep,
  } = useGuestDamageStep();
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const initialSteps: StepProps[] = [
    {
      name: 'Detalhes do dano',
      icon: damageDetailsIcon,
      finishedIcon: damageDetailsIconFinished,
      alt: 'ícone detalhes do dano',
      isActive: false,
      onClick: handleViewDamageDetailsStep,
    },
    {
      name: 'Histórico e Tratativa de Cobrança',
      icon: billingHistoryHandlingIcon,
      finishedIcon: billingHistoryHandlingIconFinished,
      alt: 'ícone histórico e tratativa de cobrança',
      isActive: false,
      onClick: handleViewBillingHistoryNegotiationsStep,
    },
    {
      name: 'Reembolso do dano',
      icon: damageRefundIcon,
      finishedIcon: damageRefundIconFinished,
      alt: 'ícone reembolso do dano',
      isActive: false,
      onClick: handleViewRefundDamageStep,
    },
  ];
  const [steps, setSteps] = useState<StepProps[]>(initialSteps);
  const stepsLength = steps.length;

  useEffect(() => {
    switch (selectedGuestDamageNegotiation.stage) {
      case 'Detalhes do dano': {
        setSteps((oldValue) => {
          const updatedSteps = oldValue.map((step) => {
            if (step.name === selectedGuestDamageNegotiation.stage) {
              return { ...step, isActive: true };
            }
            return step;
          });

          return updatedSteps;
        });
        break;
      }
      case 'Histórico e Tratativa de Cobrança': {
        setSteps((oldValue) => {
          const updatedSteps = oldValue.map((step) => {
            if (step.name === selectedGuestDamageNegotiation.stage || step.name === 'Detalhes do dano') {
              return { ...step, isActive: true };
            }
            return step;
          });

          return updatedSteps;
        });
        break;
      }
      case 'Reembolso do dano': {
        setSteps((oldValue) => {
          const updatedSteps = oldValue.map((step) => ({ ...step, isActive: true }));

          return updatedSteps;
        });
        break;
      }
      default: {
        break;
      }
    }
  }, [selectedGuestDamageNegotiation.stage]);

  return (
    <StepsContainer
      initial={{ x: 50, opacity: 0 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        duration: 0.5,
      }}
      exit={{ x: 50, opacity: 0 }}
      key="stepContainer"
    >
      <h2 style={{ padding: '0 2rem' }}>Etapas do dano</h2>
      <StepItemContainer>
        {steps.map((item) => (
          <ItemContainer key={item.alt}>
            <StepItem onClick={item.onClick}>
              <StepText isActive={item.isActive}>{item.name}</StepText>
              <StepIcon src={item.isActive ? item.finishedIcon : item.icon} alt={item.alt} />
            </StepItem>
            <Divider stepsLength={stepsLength} isActive={item.isActive} />
          </ItemContainer>
        ))}
        <div>
          <StepItem style={{ cursor: 'unset' }} isLastCard>
            <StepText
              isActive={selectedGuestDamageNegotiation.status === 'Dano cobrado. Repasse realizado ao dono do reembolso'}
              isLastCard
            >
              {selectedGuestDamageNegotiation.status || '-'}
            </StepText>
            <StepIcon
              isLastCard
              src={selectedGuestDamageNegotiation.status === 'Dano cobrado. Repasse realizado ao dono do reembolso'
                ? statusIconFinished : statusIcon}
              alt="status icon"
            />
          </StepItem>
        </div>
      </StepItemContainer>
    </StepsContainer>
  );
};

export default StepsCard;
