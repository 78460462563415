import React, { useEffect } from 'react';
import { Container } from './styles';
import InsertDataComponent from '../../components/InsertData/InsertData';

const InsertData: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <InsertDataComponent />
    </Container>
  );
};
export default InsertData;
