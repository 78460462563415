export type StatusProps = 'Not_Concluded' | 'Concluded' | 'Pending';

export type OrderGridBy =
  'name'
  | 'nameOfNf'
  | 'host'
  | 'commission'
  | 'cpf'
  | 'cnpj'
  | 'address'
  | 'address_number'
  | 'complement'
  | 'district'
  | 'city'
  | 'state'
  | 'postal_code'
  | 'email'
  | 'status';

export type OrderBy = OrderGridBy;
export type Order = 'asc' | 'desc';

export type OrderGridOptions = {
  order_by: OrderBy,
  order: Order,
};

export enum EnumStatus {
  'notDone' = 'Not_Concluded',
  'done' = 'Concluded',
  'withAdjustments' = 'Pending',
}

export enum EnumStatusColors {
  'notDone' = '#FF2732',
  'done' = '#41B592',
  'withAdjustments' = '#FF9900',
}

export interface IListItems {
  id: number;
  name: string;
  nameOfNf: string;
  host: string;
  commission: number;
  cpf: string;
  cnpj: string;
  address: string;
  address_number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  postal_code: string;
  email: string;
  status: string;
}
