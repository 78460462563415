import styled, { css } from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { UrlLink } from '../../../services/types';
import defaultImage from '../../../assets/icons/generals/property-not-found.svg';
import { shimmerAnimation, animationTime } from '../LoadingShimmer/animationShimmer';

export const Container = styled.div<{ loading?: boolean }>`
  --dark-color: ${({ theme }) => theme.palette.blue._950.hex()};
  --light-color: ${({ theme }) => theme.palette.white.main.hex()};

  position: sticky;
  left: 0;
  min-height: 185px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  filter: drop-shadow(5px 25px 15px rgba(57,74,107,0.4));
  z-index: 10;

  ${({ loading }) => loading && css`
    .property-image, .property-code, .person-infos > img, p {
      background: ${({ theme }) => theme.palette.grey._440.hex()};
      color: transparent;
      animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
    }

    .person-infos > img, .category-grouped {
      display: none;
    }
  `}
`;

export const ContainerGrouped = styled(Accordion)`
  && {
    &.Mui-expanded, &.MuiAccordion-rounded:last-child {
      margin: 0;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: none !important;
    }
  }
`;

export const Grouped = styled(AccordionSummary)`
  && {
    display: flex;
    border: none;
    max-width: 178px;
    width: 100%;
    height: 30px !important;
    min-height: 30px !important;
    flex-direction: row !important;
    align-items: center;
    font-weight: bold;
    background: ${({ theme }) => theme.palette.grey._340.hex()};
    padding: 7px;
    cursor: pointer;
    text-transform: uppercase;
    flex-direction: row-reverse;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    color: ${({ theme }) => theme.palette.grey._900.hex()};

    &, &.Mui-expanded {
      height: 40px;
      max-height: 40px;
      min-height: 40px;
      margin: 0;
    }

    .MuiIconButton-root {
      margin-right: ${({ theme }) => theme.spacing.small};
      padding: 0;
      color: ${({ theme }) => theme.palette.grey._900.hex()};
    }

    p {
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--dark-color);
    }
  }
`;

export const ContainerProperty = styled(AccordionDetails)`
  && {
    padding: 0;
    display: block;
  }

  a {
    text-decoration: none;
  }



`;

export const Property = styled.div<{ thisLastProperty: boolean }>`
  display: flex;
  align-items: center;
  padding: 7px;
  height: 61px;
  min-height: 61px;
  border-bottom: ${({ thisLastProperty, theme }) => (thisLastProperty ? 'none' : `solid 1px ${theme.palette.grey._400.hex()}`)};
  transition: all .3s ease-in-out;

  & > div > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.blue.main.hex()};
  }
`;

export const ModalProperty = styled.div<{
  openModal: boolean;
}>`
  display: ${({ openModal }) => (openModal ? 'flex' : 'none')};
  flex-direction: column;
  width: 150px;
  height: 160px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  z-index: 999;
  left: 180px;
  border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  padding: 15px;
  justify-content: center;
`;

export const ContentRow = styled.div`
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 5px;
  p, span {
    margin-left: 5px;
    font-family: ${({ theme }) => theme.fonts.familys._1} !important;
    font-weight: 500;
  }

  &:last-child{
    margin-top: 10px;
  }

  span {
    color: ${({ theme }) => theme.palette.grey._700.hex()};
  }
  svg {
    fill: ${({ theme }) => theme.palette.blue._800.hex()};
  }
`;

export const PropertyImage = styled.div<{ backgroundImage?: UrlLink }>`
  display: flex;
  justify-content: flex-end;
  min-width: 52px;
  width: 52px;
  height: 44px;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : `url(${defaultImage})`)};
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  position: relative;

  &:after {
    content: '';
    left: 45px;
    top: -7px;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
    background-color: ${({ theme }) => theme.palette.green.main.hex()};
    border-radius: 50%;
    position: absolute;
    width: 15px;
    height: 15px;
  }
`;

export const PropertyDesc = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > div:last-child {
    display: flex;
    align-items: center;
  }
`;

export const PropertyTitle = styled.p`
  display: block;
  margin-bottom: 5px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: var(--dark-color);
`;

export const PropertyUser = styled.p`
  margin-left: 5px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
`;

export const LastElementReference = styled.div<{ disabled: boolean }>`
  display: ${({ disabled }) => (disabled ? 'block' : 'flex')};
  position: relative;
  top: -60px;
`;
