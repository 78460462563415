import styled from 'styled-components';

const transitionTime = '0.3s';

export const Container = styled.div<{
  showLabel?: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-between;
  background: #f1f1f6;
  text-align: center;
  position: absolute;
  padding: 20px;
  border-radius: 7px;

  align-items: center;
  height: 100%;
  top: 0;
  right: 0;

  .Component-root-162 .MuiSwitch-track {
    transition: all ${transitionTime} ease;
  }

  .Component-root-162 .MuiSwitch-switchBase {
    transition: all ${transitionTime} ease;
  }

  .Component-root-162 .MuiSwitch-switchBase {
    transform: all ${transitionTime} ease;
  }

  div {
    transition: all ${transitionTime} ease;
    margin-right: ${({ showLabel }) => showLabel && 'calc(-50% + 35%)'};
  }
`;

export const Label = styled.h1<{
  green?: boolean;
  selected?: boolean;
}>`
  transition: ${transitionTime};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  color: ${({ selected }) => (selected ? '#283143' : '#C8C8C8')};
  color: ${({ theme, green }) => green && theme.palette.green.main.hex()};
`;
