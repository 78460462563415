import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;

    max-width: 10rem;

    path {
      fill: #fefefe;
    }
  }
`;
