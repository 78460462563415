import React from 'react';
import { motion } from 'framer-motion';

import { Container } from './style';
import { copyGuestsToClipboard } from './utils';
import { useToast } from '../../../../../../context/ToastContext';
import { usePreCheckin } from '../../../../../../context/PreCheckin/PreCheckin';

const CopyGuests = () => {
  const toast = useToast();
  const { guests, mode, info } = usePreCheckin();

  const copyAllDataFromClipboard = React.useCallback(() => {
    function callbacks() {
      return {
        sucess: () => {
          toast.success('Dados copiados com sucesso!');
        },
        error: (err: any) => {
          toast.error(`Erro ao copiar dados!, tente novamente mais tarde. ${err}`);
        },
      };
    }

    copyGuestsToClipboard(
      guests,
      info.has_pet > 0,
      callbacks(),
    );
  }, [guests]);

  if (mode._default === 'edit') {
    return null;
  }

  return (
    <Container>
      <motion.button
        whileTap={{ scale: 0.9 }}
        onClick={copyAllDataFromClipboard}
      >
        <h1>
          Clique aqui para copiar todos os dados para a área de transferência
        </h1>
      </motion.button>
    </Container>
  );
};

export default CopyGuests;
