import React, { useEffect } from 'react';
import PersonalDataHost from '../../components/PersonalDataHost';
import { Container } from './styles';
import { useViewMode } from '../../context/ViewMode/ViewMode';

const PersonalDataHostPage: React.FC = () => {
  const { mode } = useViewMode();

  useEffect(() => {
    document.title = 'Sapron | Meus Dados';
  }, []);

  return (
    <Container mode={mode}>
      <PersonalDataHost />
    </Container>
  );
};

export default PersonalDataHostPage;
