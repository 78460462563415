import { Alert, Snackbar } from '@mui/material';
import { AlertColor as Color } from '@mui/material/Alert';
import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  
  width: 100%;
  height: 100%;

  opacity: 0;
  z-index: 9999999;
  background: black;
`;

type Props = {
  show: boolean,
  message: string | string[],
  autoHideDuration: number,
  type: Color,
  handleClose(): void,
};

const Toast: React.FC<Props> = ({
  message,
  show,
  handleClose,
  autoHideDuration,
  type,
}) => {
  const getMessage = () => {
    if (typeof message === 'string') {
      return message;
    }

    return message.map((msg, index) => (
      <div key={`${msg}-${index.toString()}`}>{msg}</div>
    ));
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={show}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        style={{ zIndex: 99999999999999999999 }}
      >
        <Alert
          data-cy="toast-alert"
          data-testid="toast-alert"
          onClose={handleClose}
          severity={type}
        >
          {getMessage()}
        </Alert>
      </Snackbar>
      {show && (<Backdrop onClick={() => handleClose()} />)}
    </>
  );
};

export default Toast;
