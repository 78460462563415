import styled from 'styled-components';

export const Container = styled.div`
  grid-template-columns: 1fr 1fr;
  max-width: 100%;

  h1 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 0 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .two-columns {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
    @media (max-width: 780px){
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .one-column {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
  }
`;

export const CardsContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  row-gap: 1rem;
  column-gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  @media (max-width: 1100px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 780px){
    grid-template-columns: 1fr;
  }
`;

export const BoxQuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border: #b5bac8 1px solid;
  border-radius: 10px;
  padding: 18px 0;
`;

export const QuatitySwitchText = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-self: center;
  margin-bottom: 16px;
  padding: 0 4px;
  text-align: center;
`;

export const Form = styled.form`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  border-radius: 0 0 10px 10px;

  .one-column {
    display: grid;
    padding: 0 2rem;
    grid-template-columns: repeat(4,1fr);
    grid-column-end: 4;
    margin-bottom: 1rem;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 2rem;
    grid-column-start: 1;
    grid-column-end: 5;

    @media (max-width: 1450px){
      grid-template-columns: repeat(4,1fr);
    }

    @media (max-width: 1150px){
      grid-template-columns: repeat(3,1fr);
    }

    @media (max-width: 600px){
      grid-template-columns: repeat(2,1fr);
    }

  }

  .footer-buttons {
    margin-top: 2rem;
    grid-column-start: 1;
    grid-column-end: 5;
    background: rgba(0, 24, 64, 0.05);
    padding: 2rem 0;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: flex-end;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      width: 30%;
      margin-right: 2rem;
    }
  }
`;
