import { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { BankAccountStateProps } from '../../AccountBankManager';
import { Bank } from '../../../../services/Bank/types';
import { PropertyInvoiceBank } from '../../../../services/Owner/types';
import { patchOwnerBank, postOwnerBank } from '../../../../services/Owner/request';
import { getBanks } from '../../../../services/Bank/request';
import { accountTypeOptions } from '../../../../services/Bank/utils';
import { cpf, cnpj } from '../../../../utils/InputMask/Number';

import { useToastErrorMessage } from '../../../../utils/Messages';
import { useToast } from '../../../../context/ToastContext';
import { useCustomToast } from '../../../../context/CustomToastContext';

import SimpleSelect, { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import TextField from '../../../TextField';
import { Mask } from '../../../TextField/TextField';
import RadioTypePerson from '../../../RadioButton/RadioTypePerson';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import type { SelectProps } from '../../../DropdownAutocomplete/DropdownAutocomplete';
import RadioYesOrNo from '../../../RadioButton/RadioYesOrNo';
import DragDrawerModal from '../../../Modal/DragDrawerModal';
import AlertMessage from '../../AccountBankManager/AlertMessage';

import {
  Container,
  ContentInputs,
  RowInput,
  AlertMessageContainer,
} from './styles';

const listTypePix = [
  {
    id: 'CPF',
    type: 'CPF',
  },
  {
    id: 'CNPJ',
    type: 'CNPJ',
  },
  {
    id: 'Email',
    type: 'E-mail',
  },
  {
    id: 'Phone_Number',
    type: 'Número de telefone celular',
  },
  {
    id: 'Random',
    type: 'Chave aleatória',
  },
];

const pixMasks: Record<string, Mask> = {
  CPF: 'cpf',
  CNPJ: 'cnpj',
  Phone_Number: 'phone',
  'E-mail': 'none',
  Random: 'none',
};

const addLeftZeroInAgency = (agencyNumber: number): string => {
  const agencyNumberFormated = agencyNumber.toString().padStart(4, '0');
  return agencyNumberFormated;
};

interface Props {
  bankAccountState: BankAccountStateProps;
  setBankAccountState: (item: BankAccountStateProps) => void;
  getPropertyBankByID: (id: number) => PropertyInvoiceBank | undefined;
  updateListBank: () => void;
}

const ModalNewBankAccount = ({
  bankAccountState,
  setBankAccountState,
  getPropertyBankByID,
  updateListBank,

}: Props) : JSX.Element => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const [isValidationWithCpf, setIsValidationWithCpf] = useState(false);
  const [listBank, setListBank] = useState<Array<Bank>>([]);
  const [hasPixKey, setHasPixKey] = useState(false);

  const bankAccountData = useMemo(() => bankAccountState?.bank || null, [bankAccountState]);

  function getProperty() {
    if (bankAccountData?.id) {
      return getPropertyBankByID(bankAccountData.id);
    }
    return undefined;
  }

  const property = getProperty();

  const ownerToast = useCustomToast();

  const handleCloseModal = () => {
    setBankAccountState({
      state: 'viewing',
      bank: null,
    });
  };

  const requiredBankField = Yup.string().nullable().test(
    'required',
    'Campo obrigatório',
    (value) => !['null', 'undefined', ''].includes(`${value}`),
  );

  const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
  const phoneRegex = /^\+\d{2} \(\d{2}\) \d{5}-\d{4}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validationPixKey = Yup.string().when(['pixKeyType', 'hasPix'], {
    is: (pixKeyType: string, hasPix: boolean) => hasPix,
    then: Yup.string().test(
      'pix', 'Formato inválido para o tipo de chave PIX', (value, context) => {
        if (!value) return false;

        switch (context.parent.pixKeyType) {
          case 'CPF':
            return cpfRegex.test(value);
          case 'CNPJ':
            return cnpjRegex.test(value);
          case 'Phone_Number':
            return phoneRegex.test(value);
          case 'Email':
            return emailRegex.test(value);
          default:
            return true;
        }
      },
    ).nullable(),
    otherwise: Yup.string().nullable(),
  });

  const validationWithCPF = Yup.object().shape({
    bank: requiredBankField,
    agency: Yup.string().required(),
    account: Yup.string().required(),
    type: Yup.string().required(),
    name: Yup.string().required(),
    cpf: Yup.string()
      .when('typePerson', (isIndividual: string, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (isIndividual !== 'individual') return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )).required('Campo obrigatório'),
    pixKeyType: Yup.string().when('hasPix', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    pix: validationPixKey,
  });

  const validationWithCNPJ = Yup.object().shape({
    bank: requiredBankField,
    agency: Yup.string().required().max(4, 'Máximo de 4 números'),
    account: Yup.string().required(),
    type: Yup.string().required(),
    name: Yup.string().required(),
    cnpj: Yup.string()
      .when('typePerson', (isIndividual: string, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual === 'individual') return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )).required('Campo obrigatório'),
    pixKeyType: Yup.string().when('hasPix', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    pix: validationPixKey,
  });

  const getSelectedBankId = (bank: string) => {
    const bankId = listBank?.find((item) => `${item.bank_number} - ${item.short_name}`.trim().toLowerCase() === `${bank}`.trim().toLowerCase())?.id;
    return bankId || undefined;
  };

  const initialValues = {
    typePerson: bankAccountData?.cnpj ? 'legal' : 'individual',
    bank: bankAccountData && (bankAccountData?.bank as Bank)?.id ? `${(bankAccountData?.bank as Bank)?.bank_number} - ${(bankAccountData?.bank as Bank)?.short_name || ''}` : null,
    agency: bankAccountData?.branch_number ? bankAccountData.branch_number : '',
    account: bankAccountData?.account_number ? bankAccountData.account_number : '',
    type: bankAccountData?.account_type ? bankAccountData.account_type : '',
    cnpj: bankAccountData?.cnpj ? cnpj(bankAccountData.cnpj) : '',
    cpf: bankAccountData?.cpf ? cpf(bankAccountData.cpf) : '',
    name: bankAccountData?.entity_name ? bankAccountData.entity_name : '',
    hasPix: !['não informado', 'null', 'undefined'].includes(`${bankAccountData?.pix_key}`.toLowerCase().trim()),
    pixKeyType: bankAccountData?.pix_key_type || undefined,
    pix: bankAccountData?.pix_key || undefined,
    isMainAccount: bankAccountData?.is_default || false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: isValidationWithCpf ? validationWithCPF : validationWithCNPJ,
    onSubmit: async (values) => {
      try {
        const bankAccount = Number(`${values?.bank}`?.split('-')?.[0]) as any || undefined;
        const bankId = getSelectedBankId(`${values?.bank}`) || bankAccount;

        if (!bankId || Number.isNaN(bankAccount)) {
          formik.setFieldError('bank', 'Selecione uma das opções de Banco disponíveis');
          return;
        }

        if (bankAccountData?.id) {
          await patchOwnerBank({
            bank: bankId,
            account_type: values.type,
            account_number: values.account,
            branch_number: addLeftZeroInAgency(Number(values.agency)),
            is_default: values.isMainAccount,
            entity_name: values.name,
            cnpj: formik.values.typePerson === 'legal' ? (((values.cnpj.split('.').join('')).split('.').join(''))
              .split('/')
              .join(''))
              .split('-')
              .join('') : '',
            cpf: formik.values.typePerson === 'individual' ? (values.cpf.split('.').join('')).split('-').join('') : '',
            pix_key_type: values.hasPix ? values.pixKeyType : 'Random',
            pix_key: values.hasPix ? values.pix : 'Não informado',
          }, bankAccountData.id);

          const verifyIfIsDefaultChange = bankAccountData?.is_default !== values.isMainAccount;

          if (verifyIfIsDefaultChange) {
            ownerToast.addToast({
              title: 'Sua conta principal foi alterada com sucesso!',
              type: 'success',
              description: 'Todos seus imóveis que estavam com conta principal antiga foram remanejados automaticamente para a nova conta principal. As mudanças de conta bancária realizadas após o dia 30 do mês só serão utilizadas para repasses dos meses posteriores',
            });
          }

          ownerToast.addToast({
            title: `As modificações da conta do imóvel: ${property ? property?.code : values.name}, foram enviadas!`,
            type: 'success',
            description: 'O nosso time entrará em contato contigo via email para confirmar a alteração. As mudanças de conta bancária realizadas após o dia 30 do mês só serão utilizadas para repasses dos meses posteriores',
          });
        } else {
          await postOwnerBank({
            bank: bankId,
            account_type: values.type,
            account_number: values.account,
            branch_number: addLeftZeroInAgency(Number(values.agency)),
            is_default: values.isMainAccount,
            entity_name: values.name,
            cnpj: formik.values.typePerson !== 'individual' ? (((values.cnpj.split('.').join('')).split('.').join(''))
              .split('/')
              .join(''))
              .split('-')
              .join('') : '',
            cpf: formik.values.typePerson === 'individual' ? (values.cpf.split('.').join('')).split('-').join('') : '',
            pix_key_type: values.hasPix ? values.pixKeyType : 'Random',
            pix_key: values.hasPix ? values.pix : 'Não informado',
          });

          setTimeout(() => {
            toast.success('Conta bancária cadastrada com sucesso!');
          }, 1000);
        }

        handleCloseModal();
        updateListBank();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  function handleGetBankOptions() {
    const options = listBank?.map<SelectProps>((item: any) => {
      const id = item?.id ? `${item?.id}` : null;
      const shortName = `${item?.bank_number || ''}`;
      const bankNumber = `${item?.short_name || ''}`;

      return {
        optionText: `${shortName} - ${bankNumber}`,
        optionValue: `${id}`,
      };
    });

    return options;
  }

  useEffect(() => {
    setIsValidationWithCpf(formik.values.typePerson === 'individual');
  }, [formik.values.typePerson]);

  useEffect(() => {
    async function getListBank() {
      const result = await getBanks();
      setListBank(result);
    }
    getListBank();
  }, []);

  useEffect(() => {
    setHasPixKey(formik.values.hasPix);
  }, [formik.values.hasPix]);

  return (
    <DragDrawerModal
      variant="withCancelAndAppyButton"
      title={bankAccountData?.id ? 'Editar conta bancária' : 'Adicione uma conta bancária'}
      cancelButtonText={bankAccountData?.id ? 'Descartar' : 'Cancelar'}
      applyButtonText={bankAccountData?.id ? 'Salvar ajustes' : 'Salvar'}
      open={['adding', 'editing'].includes(bankAccountState.state)}
      onClose={() => handleCloseModal()}
      handleClickApplyButton={() => formik.handleSubmit()}
      handleClickCancelButton={() => handleCloseModal()}
      maxWidthForModalOnDesk={'600px'}
      maxHeightForModalOnDesk={'100vh'}
      activeDragHandAnimation={false}
    >
      <Container onSubmit={formik.handleSubmit}>
        <ContentInputs>
          <AlertMessageContainer>
            <AlertMessage variant="2" />
          </AlertMessageContainer>

          <RowInput className="type-person">
            <div className="fullRow">
              <RadioTypePerson
                formLabel="Tipo da conta"
                labelPlacement="end"
                id="typePerson"
                formik={formik}
              />
            </div>
          </RowInput>
          {formik.values.typePerson === 'individual' && (
            <RowInput>
              <div className="fullRow">
                <TextField
                  label="*CPF"
                  id="cpf"
                  placeholder="Digite aqui..."
                  mask="cpf"
                  formik={formik}
                  value={formik.values.cpf}
                  dataCy="cpf-field"
                />
              </div>
            </RowInput>
          )}
          {formik.values.typePerson === 'legal' && (
            <RowInput>
              <div className="fullRow">
                <TextField
                  label="*CNPJ"
                  id="cnpj"
                  placeholder="Digite aqui..."
                  mask="cnpj"
                  formik={formik}
                  value={formik.values.cnpj}
                  dataCy="cnpj-field"
                />
              </div>
            </RowInput>
          )}

          <RowInput>
            <div className="selector">
              <DropdownAutocomplete
                id="bank"
                label="*Banco"
                placeholder={formik.values?.bank || ''}
                options={handleGetBankOptions()}
                formik={formik}
              />
            </div>
            <div className="fullRow">
              <TextField
                label="*Agência (sem dígito)"
                id="agency"
                placeholder="Digite aqui..."
                formik={formik}
                mask="agency"
                value={bankAccountData?.branch_number}
                dataCy="agency-field"
              />
            </div>
          </RowInput>
          <RowInput>
            <div className="fullRow">
              <TextField
                label="*Conta (com o dígito)"
                id="account"
                placeholder="Digite aqui..."
                formik={formik}
                mask="account"
                value={bankAccountData?.account_number}
                dataCy="account-field"
              />
            </div>
            <div className="selector">
              <SimpleSelect
                id="type"
                placeholder="Selecione"
                label="*Tipo da conta"
                formik={formik}
                options={
                  (accountTypeOptions || []).map<SelectOption>(({
                    label,
                    value,
                  }) => ({ valueLabel: label, value }))
                }
                dataCy="type-field"
              />
            </div>
          </RowInput>

          <RowInput>
            <div className="fullRow">
              <TextField
                label="*Titular da conta"
                id="name"
                placeholder="Digite aqui..."
                formik={formik}
                value={bankAccountData?.entity_name}
                dataCy="name-field"
              />
            </div>
          </RowInput>

          <RowInput>
            <div className="fullRow">
              <RadioYesOrNo
                formLabel="Essa conta vai ser sua conta principal?"
                id="isMainAccount"
                labelPlacement="end"
                formik={formik}
              />
            </div>
          </RowInput>

          <RowInput>
            <div className="fullRow">
              <RadioYesOrNo
                formLabel="Essa conta bancária possui uma chave pix?"
                id="hasPix"
                labelPlacement="end"
                formik={formik}
              />
            </div>
          </RowInput>

          {hasPixKey && (
            <RowInput>
              <div className="fullRow">
                <SimpleSelect
                  id="pixKeyType"
                  placeholder="Selecione"
                  label="*Tipo da chave PIX"
                  formik={formik}
                  options={
                      (listTypePix || []).map<SelectOption>(({
                        id,
                        type,
                      }) => ({ value: id, valueLabel: type }))
                    }
                  dataCy="pix-type-field"
                />
              </div>
              <div className="fullRow">
                <TextField
                  label="*Pix"
                  id="pix"
                  placeholder="Digite aqui..."
                  formik={formik}
                  value={formik.values.pix}
                  dataCy="pix-field"
                  type={formik.values?.pixKeyType === 'E-mail' as any ? 'email' : 'text'}
                  mask={formik.values?.pixKeyType ? pixMasks[`${formik.values.pixKeyType}`] : 'none'}
                />
              </div>
            </RowInput>
          )}
        </ContentInputs>
      </Container>
    </DragDrawerModal>
  );
};

export default ModalNewBankAccount;
