import { Data } from '../types';

const data: Data[] = [
  {
    id: 1,
    title: 'O imóvel indicado está dentro da área de atuação da Seazone?',
    option: 'IsIndicatedWithinSeazoneOperationArea',
    tooltip: 'Um dos principais fatores que influenciam na probabilidade de conversão da indicação é se o imóvel está dentro da área de abrangência da Seazone.',
  },
  {
    id: 2,
    title: 'O imóvel possui mobília?',
    option: 'doesThePropertyHaveFurniture',
    tooltip: 'Um dos fatores que influenciam na probabilidade de conversão da indicação é se o imóvel possui mobília: móveis, camas, eletrodomésticos e utensílios de cozinha',
  },
  {
    id: 3,
    title: 'O imóvel está em obra?',
    option: 'isThePropertyUnderConstruction',
    tooltip: 'Um dos fatores que afetam a probabilidade de conversão é o status da propriedade: se ela está concluída ou ainda em construção. Caso você sinalize que a propriedade está em obras, complemente essa informação no campo de observações',
  },
  {
    id: 4,
    option: 'isTheOwnerAwareOfTheIndication',
    title: 'O proprietário está ciente da indicação?',
    tooltip: 'Um dos fatores que afetam a probabilidade de conversão de indicações é se o proprietário já tem conhecimento que a Seazone entrará em contato.',
  },
  {
    id: 5,
    option: 'hasTheOwnerAlreadyReceivedTheEBook',
    title: 'O proprietário já recebeu o E-book?',
    tooltip: 'Um dos fatores que afetam a probabilidade de conversão de indicações é se o proprietário já tem conhecimento prévio das vantagens de trazer o seu imóvel para a Seazone.',
  },
];

export default data;
