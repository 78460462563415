import styled, { keyframes } from 'styled-components';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 0 10px 0 0;
  min-width: 1300px;
`;

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1.2fr 1.3fr 1fr 0.8fr 1.2fr 1.2fr;
  margin-top: 20px;

  justify-content: center;
  user-select: none;
`;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.1rem;
    font-weight: normal;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2px;

    img {
      width: 7px;
    }
  }

  .titleContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
    cursor: pointer;
  }


`;

export const ItemLine = styled.div``;

export const ReservationCode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 0;
  animation: ${showItems} 0.6s 0.4s ease forwards;
`;

export const ImmobileCode = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.5s ease forwards;
`;

export const ReservationDate = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.6s ease forwards;
`;

export const ReservationStaff = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.7s ease forwards;
`;

export const WhatsApp = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.8s ease forwards;
`;

export const CheckIn = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.9s ease forwards;
`;

export const Email = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.9s ease forwards;
`;

export const RemainingDate = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.9s ease forwards;
`;

export const PreCheckinButton = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.9s ease forwards;
`;
