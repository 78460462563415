import request from '../request';
import {
  Bank,
  BankDetail,
} from './types';

export const getBankDetailByIdUser = async (id: number): Promise<BankDetail[]> => {
  const { data } = await request.get(`/account/user/${id}/bank_details/`);
  return data as BankDetail[];
};

export const putBankDetail = async (id: number, data: BankDetail) => {
  await request.put(`/bank_details/${id}/`, {
    ...data,
  });
};

export const postBankDetail = async (data: BankDetail) => {
  await request.post('/bank_details/', {
    ...data,
  });
};

export const getBanks = async (): Promise<Array<Bank>> => {
  const { data } = await request.get('/financial/banks');
  return data as Bank[];
};
