import styled, { keyframes } from 'styled-components';

const fadeInModal = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 85%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const buttonFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 500px;
  background: #ebebf5;
  position: fixed;
  right: 0;
  border-top-left-radius: 20px;
  top: 0;
  z-index: 20;
  opacity: 0;
  animation: ${fadeInModal} 0.5s ease forwards;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 12vh;

  &::-webkit-scrollbar {
    width: 0.3rem;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #c0c0c0;
  }
`;

export const CloseButton = styled.button`
  border: none;
  position: absolute;
  padding: 20px 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 40px;
  left: -13%;
  cursor: pointer;
  background: #fff;

  opacity: 0;
  animation: ${buttonFade} 0.3s 0.4s ease forwards;
  transition: 0.4s;

  &:hover {
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
  }

  h1 {
    color: #e96956;
    font-size: 1rem;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};

    opacity: 0;
    animation: ${fadeIn} 0.3s 0.4s ease forwards;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.295);
  z-index: 10;
`;

export const X = styled.h1`
  &:after {
    transition: 0.4s;
    font-size: 1.5rem;
    opacity: 0;
    animation: ${fadeIn} 0.4s 0.4s ease forwards;

    color: #e96956;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};

    opacity: 0;
    animation: ${fadeIn} 0.3s 0.5s ease forwards;
    content: "X";

  }
`;
