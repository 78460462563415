import React, { useEffect } from 'react';

import { Container } from './styles';
import InsertHostDataComponent from '../../../components/InsertData/InsertHostData/InsertHostData';

const InsertHostDataPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <InsertHostDataComponent />
    </Container>
  );
};
export default InsertHostDataPage;
