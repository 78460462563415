import { Close } from '@mui/icons-material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { getBanks } from '../../services/Bank/request';
import { Bank } from '../../services/Bank/types';
import { accountTypeOptions } from '../../services/Bank/utils';
import FormButton from '../FormButton';

import {
  BankDetails,
  // PixType,
} from '../../services/Owner/types';
import SimpleSelect, { SelectOption } from '../SimpleSelect/SimpleSelect';

import TextField from '../TextField';
import {
  Title,
  Header,
  ButtonClose,
  // ButtonSave,
  Container,
  ContentInputs,
  RowButtons,
  RowInput,
  Wrapper,
  TwoColumns,
} from './styles';
import { useToastErrorMessage } from '../../utils/Messages';
import { useToast } from '../../context/ToastContext';
import RadioTypePerson from '../RadioButton/RadioTypePerson';
import { patchOwnerBank, postOwnerBank } from '../../services/Owner/request';

// const pixKeyTypeOptions = [
//   {
//     value: 'Phone_Number',
//     valueLabel: 'Número de telefone',
//   },
//   {
//     value: 'CPF',
//     valueLabel: 'CPF',
//   },
//   {
//     value: 'CNPJ',
//     valueLabel: 'CNPJ',
//   },
//   {
//     value: 'Email',
//     valueLabel: 'E-mail',
//   },
//   {
//     value: 'Random',
//     valueLabel: 'Chave aleatória',
//   },
//   {
//     value: 'Dont_Have',
//     valueLabel: 'Não possui chave PIX',
//   },
// ];

interface Props {
  openModal: boolean;
  setOpenModal: (a: boolean) => void;
  // eslint-disable-next-line react/require-default-props
  dataAccountBank?: BankDetails;
  setDataAccountBank: (a: BankDetails) => void;

}

const FirstLoginBankModal = ({
  openModal,
  setOpenModal,
  dataAccountBank,
  setDataAccountBank,
}: Props) : JSX.Element => {
  const [selectedSwitcher, setSelectedSwitcher] = React.useState(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const [isValidationWithCpf, setIsValidationWithCpf] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(true);

  const handleCloseModal = () => {
    setDataAccountBank({} as BankDetails);
    setOpenModal(false);
  };

  const validationWithCPF = Yup.object().shape({
    bank: Yup.string().required(),
    agency: Yup.string().required(),
    account: Yup.string().required(),
    type: Yup.string().required(),
    // pix: Yup.string().required(),
    // pixKeyType: Yup.string().required(),
    name: Yup.string().required(),
    cpf: Yup.string().required(),
  });

  const validationWithCNPJ = Yup.object().shape({
    bank: Yup.string().required(),
    agency: Yup.string().required(),
    account: Yup.string().required(),
    type: Yup.string().required(),
    // pix: Yup.string().required(),
    // pixKeyType: Yup.string().required(),
    name: Yup.string().required(),
    cnpj: Yup.string().required(),
  });

  // const getPixMask = (type?: PixType) => {
  //   switch (type) {
  //     case 'CNPJ': {
  //       return 'cnpj';
  //     }
  //     case 'CPF': {
  //       return 'cpf';
  //     }
  //     case 'Phone_Number': {
  //       return 'phone';
  //     }
  //     default: {
  //       return undefined;
  //     }
  //   }
  // };

  const initialValues = {
    typePerson: dataAccountBank?.cnpj ? 'legal' : 'individual',
    bank: dataAccountBank ? `${(dataAccountBank.bank as Bank)?.id}|${(dataAccountBank.bank as Bank)?.short_name}` : '',
    agency: dataAccountBank?.branch_number ? dataAccountBank.branch_number : '',
    account: dataAccountBank ? dataAccountBank.account_number : '',
    type: dataAccountBank ? dataAccountBank.account_type : '',
    // pix: dataAccountBank ? dataAccountBank.pix_key : '',
    // pixKeyType: dataAccountBank ? dataAccountBank.pix_key : 'Selecione' as PixType,
    cnpj: dataAccountBank ? dataAccountBank.cnpj : '',
    cpf: dataAccountBank ? dataAccountBank.cpf : '',
    name: dataAccountBank ? dataAccountBank.entity_name : '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: isValidationWithCpf ? validationWithCPF : validationWithCNPJ,
    onSubmit: async (values) => {
      try {
        setIsLoadingSubmit(true);
        const bankAccount = { id: parseInt(values.bank.split('|')[0], 10), short_name: values.bank.split('|')[1] } as Bank;
        if (dataAccountBank?.id) {
          await patchOwnerBank({
            bank: bankAccount.id || 0,
            account_type: values.type,
            account_number: values.account,
            branch_number: values.agency,
            // pix_key: values.pix,
            // pix_key_type: values.pixKeyType as PixType,
            is_default: !!selectedSwitcher,
            entity_name: values.name,
            cnpj: formik.values.typePerson === 'legal' ? (((values.cnpj.split('.').join('')).split('.').join(''))
              .split('/')
              .join(''))
              .split('-')
              .join('') : '',
            cpf: formik.values.typePerson === 'individual' ? (values.cpf.split('.').join('')).split('-').join('') : '',
          }, dataAccountBank.id);
          toast.success('Conta bancária atualizada com sucesso!');
        } else {
          await postOwnerBank({
            bank: bankAccount.id || 0,
            account_type: values.type,
            account_number: values.account,
            branch_number: values.agency,
            // pix_key: values.pix,
            // pix_key_type: values.pixKeyType as PixType,
            is_default: !!selectedSwitcher,
            entity_name: values.name,
            cnpj: (((values.cnpj.split('.').join('')).split('.').join(''))
              .split('/')
              .join(''))
              .split('-')
              .join(''),
            cpf: (values.cpf.split('.').join('')).split('-').join(''),
          });
          toast.success('Conta bancária cadastrada com sucesso!');
        }
        handleCloseModal();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  const [listBank, setListBank] = useState<Array<Bank>>();

  useEffect(() => {
    setIsValidationWithCpf(formik.values.typePerson === 'individual');
  }, [formik.values.typePerson]);

  useEffect(() => {
    async function getListBank() {
      const result = await getBanks();
      setListBank(result);
    }

    if (dataAccountBank) {
      setSelectedSwitcher(dataAccountBank.is_default);
    }

    getListBank();
  }, []);

  useEffect(() => {
    const {
      typePerson,
      bank,
      agency,
      account,
      type,
      // pix,
      // pixKeyType,
      cnpj,
      cpf,
      name,
    } = formik.values;

    if (name !== ''
    && (cpf || cnpj) !== ''
    // && pixKeyType !== ''
    // && pix !== ''
    && type !== ''
    && account !== ''
    && agency !== ''
    && bank !== ''
    && typePerson !== ''
    ) {
      setIsLoadingSubmit(false);
    } else {
      setIsLoadingSubmit(true);
    }
  }, [formik.values]);

  function handleWrapperClick(e: React.MouseEvent<HTMLElement>) {
    const classList = (e.target as Element).classList.value.split(' ');
    if (classList.includes('area-close')) {
      handleCloseModal();
    }
  }

  return (
    <Wrapper data-testid="modal-bank-account" className="area-close" onClick={(e) => handleWrapperClick(e)} openModal={openModal}>
      <Container onSubmit={formik.handleSubmit}>
        <ContentInputs>
          <Header>
            <Title>
              Dados Bancários
            </Title>
            <ButtonClose id="close-modal">
              <Close onClick={() => handleCloseModal()} />
            </ButtonClose>
          </Header>
          <RowInput>
            <div className="column">
              <SimpleSelect
                labelClassName="labelClass"
                required
                label="Banco"
                id="bank"
                placeholder="Selecione"
                formik={formik}
                options={
                    (listBank || []).map<SelectOption>(({
                      id,
                      bank_number,
                      short_name,
                    }) => ({ value: `${id}|${short_name}`, valueLabel: `${bank_number}-${short_name}` }))
                  }
              />
            </div>
          </RowInput>
          <TwoColumns>

            <RowInput>
              <TextField
                labelClassName="labelClass"
                required
                label="Agência"
                id="agency"
                placeholder="Digite aqui..."
                formik={formik}
                mask="agency"
                value={dataAccountBank?.branch_number}
              />
            </RowInput>
            <RowInput>
              <TextField
                required
                labelClassName="labelClass"
                label="Conta"
                id="account"
                placeholder="Digite aqui..."
                formik={formik}
                mask="account"
                value={dataAccountBank?.account_number}
              />
            </RowInput>
          </TwoColumns>
          <TwoColumns>

            <SimpleSelect
              labelClassName="labelClass"
              required
              id="type"
              placeholder="Selecione"
              label="Tipo da conta"
              formik={formik}
              options={
                    (accountTypeOptions || []).map<SelectOption>(({
                      label,
                      value,
                    }) => ({ valueLabel: label, value }))
                  }
            />
            {/* <SimpleSelect
              labelClassName="labelClass"
              required
              id="pixKeyType"
              placeholder="Selecione"
              label="Tipo da chave PIX"
              formik={formik}
              options={pixKeyTypeOptions}
            /> */}
          </TwoColumns>
          {/* <RowInput>
            <TextField
              required
              labelClassName="labelClass"
              label="Pix"
              id="pix"
              placeholder="Digite aqui..."
              formik={formik}
              value={dataAccountBank?.pix_key}
              dataCy="pix-field"
              mask={getPixMask(dataAccountBank?.pix_key_type)}
            />
          </RowInput> */}
          <RowInput>
            <TextField
              required
              labelClassName="labelClass"
              label="Nome do destinatário"
              id="name"
              placeholder="Digite aqui..."
              formik={formik}
              value={dataAccountBank?.entity_name}
              dataCy="name-field"
            />
          </RowInput>
          <RowInput>
            <RadioTypePerson
              required
              labelPlacement="end"
              id="typePerson"
              formik={formik}
            />
          </RowInput>
          {formik.values.typePerson === 'individual' && (
          <RowInput>
            <TextField
              required
              label="CPF"
              id="cpf"
              placeholder="Digite aqui..."
              mask="cpf"
              formik={formik}
              value={formik.values.typePerson === 'individual' ? dataAccountBank?.cpf : ''}
              dataCy="cpf-field"
            />
          </RowInput>
          )}
          {formik.values.typePerson === 'legal' && (
          <RowInput>
            <TextField
              required
              label="CNPJ"
              id="cnpj"
              placeholder="Digite aqui..."
              mask="cnpj"
              formik={formik}
              value={formik.values.typePerson === 'legal' ? dataAccountBank?.cnpj : ''}
              dataCy="cnpj-field"
            />
          </RowInput>
          )}

        </ContentInputs>
        <RowButtons>
          {/* <ButtonSave data-cy="btn-save" type="submit">
            {dataAccountBank?.id ? 'Salvar Ajustes' : 'Salvar'}
            {isLoadingSubmit}
          </ButtonSave> */}

          <FormButton type="submit" disable={isLoadingSubmit}>
            Salvar
            {isLoadingSubmit}
          </FormButton>
        </RowButtons>
        <h5>2/2</h5>
      </Container>
    </Wrapper>
  );
};

export default FirstLoginBankModal;
