import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 16px 0;
  p {
    font-weight: 500;
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
    color: #969696;
    text-align: start;
    text-justify: distribute;
    text-align-last: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
  }
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    padding: 8px 0;

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px;
  background: #FAFAFA;

  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 6px;
  p {
    font-weight: 400;
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
    color: #7D7D80;
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    padding-left: 8px;
  }
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    width: calc(100% - 0px);

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
