import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  h1 {
    width: 55%;
    padding: 0 2rem;
    margin-top: 1rem;
    @media (max-width: 1100px){
      width: 100%;
    }
  }

  .two-columns {
    padding: 0 2rem;
    width: 55%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    h1 {
      padding: 0;
      padding-bottom: 8px;
      margin-top: 0;
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
      font-family: ${({ theme }) => theme.fonts.familys._3};
      font-size: 1rem;
    }

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._3};
      margin-right: 8px;
    }

    @media (max-width: 1100px){
      width: 100%;
    }
    @media (max-width: 780px){
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  .one-column {
    padding: 0 2rem;
    width: 55%;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
    @media (max-width: 1100px){
      width: 100%;
    }
  }
  .three-columns {
    padding: 0 2rem;
    width: 55%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
      font-family: ${({ theme }) => theme.fonts.familys._2};
      color: ${({ theme }) => theme.palette.blue._900.hex()};
      line-height: 1.4;
      font-size: 0.93rem;
    }
    @media (max-width: 1100px){
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
  .three-columns.nowrap {
    .labelClass {
     white-space: nowrap;
    }
  }

  .switch {
    padding: 0 2rem;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;

    h2 {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
    }

    div {
      justify-self: flex-end;
    }
    @media (max-width: 1100px){
      width: 100%;
    }
  }
`;

export const Label = styled.span`
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-weight: 400;
    font-size: 14px;
    line-height: 17.5px;
`;
