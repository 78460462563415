import styled from 'styled-components';

export const Container = styled.div`
  grid-template-columns: 1fr 1fr;
  h1 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 0 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .two-columns {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }

    @media (max-width: 780px){
      grid-template-columns: 1fr !important;
      gap: 1rem;
    }
  }

  .one-column {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;

    .labelClass {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
  }

  .switch {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
    border-radius: 10px;
    h2 {
      font-weight: ${({ theme }) => theme.fonts.weights.normal};
    }
  }
  @media (max-width: 1100px){
    grid-template-columns: 1fr;
  }
`;

export const Form = styled.form`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  border-radius: 0 0 10px 10px;
  h1 {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 0 2rem;
    margin-top: 1rem;
  }

  @media (max-width: 1150px){
    grid-template-columns: 1fr;
  }

  @media (max-width: 600px){
    grid-template-columns: 1fr;
  }

  .two-columns {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    margin-bottom: 1rem;
  }

  .one-column {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-bottom: 1rem;
  }

  .switch {
    padding: 0 2rem;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;

    div {
      justify-self: flex-end;
    }

  }

  .footer-buttons {
    align-self: flex-end;
    margin-top: 2rem;
    grid-column-start: 1;
    grid-column-end: 3;
    background: rgba(0, 24, 64, 0.05);
    padding: 2rem 0;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: flex-end;
    max-height: 10rem;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      width: 30%;
      margin-right: 2rem;
    }
  }
`;
