import styled, { css, keyframes } from 'styled-components';

export const animationTime = '0.5s';
export const FadeLoadTime = '0.7s';

export const FadeIn = keyframes`
  from {
    transform: translateX(-15px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const FadeLoad = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0;
    display: none;
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.grey._250.hex()};
`;

export const AnimationLoaded = styled.div<{
  checkin?: boolean;
  clearning?: boolean;
  checkout?: boolean;
  disabled?: boolean;
  variant?: string;
  haveDataInCache?: boolean;
}>`
  height: 100%;
  position: absolute;
  margin: 0px;
  padding: 0px;
  background: ${({ checkin, theme }) => checkin && `${theme.palette.blue._200.hex()}`};
  background: ${({ checkout, theme }) => checkout && `${theme.palette.pink.main.hex()}`};
  background: ${({ clearning, theme }) => clearning && `${theme.palette.green._200.hex()}`};
  margin-top: -10px;
  margin-left: -10px;
  width: 0;
  display: none;

  ${({
    haveDataInCache, checkin, clearning, checkout,
  }) => !haveDataInCache
    && css`
      width: 110%;
      display: flex;
      animation-name: ${FadeLoad};
      animation-duration: ${FadeLoadTime};
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-delay: ${checkin && '0.2s'};
      animation-delay: ${clearning && '0.4s'};
      animation-delay: ${checkout && '0.3s'};
    `}
`;

export const ItemContainer = styled.div<{
  checkin?: boolean;
  clearning?: boolean;
  checkout?: boolean;
  disabled?: boolean;
  variant?: string;
  hidden?: boolean;
}>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  overflow: hidden;
  margin-bottom: 10px;
  justify-content: space-between;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-left: ${({ checkin, theme }) => checkin && `4px solid ${theme.palette.blue._200.hex()}`};
  border-left: ${({ checkout, theme }) => checkout && `4px solid ${theme.palette.pink.main.hex()}`};
  border-left: ${({ clearning, theme }) => clearning && `4px solid ${theme.palette.green._200.hex()}`};
  padding: 0.7rem;
  margin-right: 0.5rem;
  transition: ${animationTime};

  h3 {
    display: ${({ clearning }) => clearning && 'none'};
  }
  cursor: ${({ disabled }) => disabled === false && 'pointer'};
  filter: ${({ disabled }) => disabled && 'grayscale(50%)'};
  opacity: ${({ disabled }) => disabled && '0.5'};
  transform: translateX(0px);

  /* :last-child {
    margin-bottom: 300px;
    background: #000;
  } */
`;

export const TextContainer = styled.div<{ haveDataInCache?: boolean }>`
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;

  z-index: -1;
  opacity: 1;

  ${({ haveDataInCache }) => !haveDataInCache
    && css`
      opacity: 0;
      animation-name: ${FadeIn};
      animation-duration: ${animationTime};
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-delay: 0.6s;
      will-change: opacity;
    `}
`;

export const MainTitle = styled.h1<{ haveDataInCache?: boolean }>`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  margin-bottom: 0.125rem;
  @media (max-width: 1700px) {
    font-size: 1.2rem;
  }

  z-index: -1;
  opacity: 1;
  ${({ haveDataInCache }) => !haveDataInCache
    && css`
      opacity: 0;
      animation-name: ${FadeIn};
      animation-duration: ${animationTime};
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-delay: 0.6s;
      will-change: opacity;
    `}
`;

export const Hour = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
  margin-bottom: 0.125rem;
`;

export const Text = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
  @media (max-width: 1700px) {
    font-size: 1rem;
  }
`;

export const OwnerName = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue._950.hex()};
  line-height: 15px;
  margin-bottom: 0.125rem;
  @media (max-width: 1700px) {
    font-size: 1rem;
  }
`;

export const PinsContainer = styled.div<{
  checked?: boolean;
  haveDataInCache?: boolean;
}>`
  display: flex;
  width: 100%;
  max-width: 12%;
  align-items: end;
  justify-self: end;


  @media (max-width: 1700px) {
    max-width: 16%;
  }

  @media (max-width: 900px) {
    img {
      width: 50%;
    }
  }

  z-index: -1;
  opacity: 1;
  ${({ haveDataInCache }) => !haveDataInCache
    && css`
      opacity: 0;
      animation-name: ${FadeIn};
      animation-duration: ${animationTime};
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-delay: 0.6s;
      will-change: opacity;
      img:last-child {
        z-index: -1;
        opacity: 0;
        animation-name: ${FadeIn};
        animation-duration: ${animationTime};
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-delay: 0.72s;
        will-change: opacity;
      }
    `}
`;
