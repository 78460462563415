import React, { useEffect } from 'react';
import { Expenses as Main } from '../../components/Expenses/Expenses';
import { Container } from './styles';
import { ExpenseProvider } from '../../context/ExpensesPage/ExpenseContext';
import { StarRatingProvider } from '../../context/StarRatingContext/StarRatingContext';

const Expenses = () => {
  useEffect(() => {
    document.title = 'Sapron | Despesas';
  }, []);

  return (
    <ExpenseProvider>
      <StarRatingProvider>
        <Container>
          <Main />
        </Container>
      </StarRatingProvider>
    </ExpenseProvider>
  );
};

export default Expenses;
