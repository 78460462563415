import React, { useEffect, useState } from 'react';
import SimpleSelect from '../../SimpleSelect';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import iconFilter from '../../../assets/icons/multicalendar/filter.svg';

import {
  Container,
  Divider, Header, SearchButton, Title, PaymentsContent,
} from './styles';
import CardPayment from '../CardPayment';
import LateralModal from '../../LateralModal';
import FormPaymentsFilters from '../FormPaymentsFilters/FormPaymentsFilters';
import { useModal } from '../../../hooks/ModalHook/useModal';
import Tooltip from '../../Tooltip';
import { getPaymentAirbnbConciliation } from '../../../services/Conciliation/request';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import { getOtas } from '../../../services/Property/request';
import { RequestOta } from '../../../services/Property/types';
import CardSkeleton from '../LoadingShimmer/CardSkeleton';

const ContentPayments = (): JSX.Element => {
  const [openLateralModal, setOpenLateralModal] = useState(false);
  const [otas, setOtas] = useState<Array<RequestOta>>([]);
  const { handleOpen } = useModal();
  const { setListPayments, listPayments } = useConciliation();

  const handleOpenlLateral = () => {
    handleOpen(false);
    setOpenLateralModal(true);
  };

  const handleCloseAll = () => {
    setOpenLateralModal(false);
  };

  useEffect(() => {
    async function listPaymentsConciliation() {
      const response = await getPaymentAirbnbConciliation();
      setListPayments(response);
    }
    listPaymentsConciliation();
  }, []);

  useEffect(() => {
    async function getAllOtas() {
      const result = await getOtas();
      setOtas(result);
    }
    getAllOtas();
  }, []);

  return (
    <Container>
      <Header>
        <Title>Pagamentos</Title>
        <div className="filters">
          <Tooltip text="Mais filtros">
            <SearchButton type="button" onClick={handleOpenlLateral}><img src={iconFilter} alt="" /></SearchButton>
          </Tooltip>
          <SimpleSelect
            id="status"
            options={(otas || []).map<SelectOption>(({ name }) => ({
              value: name,
              valueLabel: name,
            }))}
            placeholder="OTA"
          />
        </div>
      </Header>
      <PaymentsContent>
        {listPayments.length > 0 ? (
          listPayments.map((payment) => (
            <>
              <CardPayment payment={payment} />
              <Divider />
            </>
          ))) : (
            <CardSkeleton />
        )}
      </PaymentsContent>
      <LateralModal
        open={openLateralModal}
        setOpen={setOpenLateralModal}
        onClose={handleCloseAll}
        headerTitle="Filtros"
        anchor="right"
      >
        <FormPaymentsFilters onClose={handleCloseAll} />
      </LateralModal>
    </Container>
  );
};

export default ContentPayments;
