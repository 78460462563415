export const variantList = (delay?: number) => ({
  visible: {
    opacity: 1,
    height: '100%',
    transition: {
      when: 'beforeChildren',
      staggerChildren: delay || 0.2,
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      when: 'afterChildren',
    },
  },
});

export const variantItemsX = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -100 },
};

export const variantItemsY = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 50 },
};
