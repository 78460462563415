import request from '../request';
import { PutImportStaysReservationData, PutImportStaysReservationBody } from './types';

export const putImportStaysReservations = async (importStaysReservationData:
PutImportStaysReservationData) => {
  await request.put('/channel_manager/import_stays_reservations/',
    {
      date_type: importStaysReservationData.type,
      start_date: importStaysReservationData.date,
    } as PutImportStaysReservationBody);
};

export const putImportStaysReservation = async (stays_id: string) => {
  await request.put('/channel_manager/import_stays_reservation/',
    {
      stays_id,
    });
};

export const getExportedOwnerCSV = async () => {
  const response = await request.get('/reports/owners_csv/', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'proprietarios.csv');
  document.body.appendChild(link);
  link.click();
};

export const getExportedListingCSV = async () => {
  const response = await request.get('/reports/listings_csv/', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'listings.csv');
  document.body.appendChild(link);
  link.click();
};

export const getExportedPropertyCSV = async () => {
  const response = await request.get('/reports/properties_csv/', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'imoveis.csv');
  document.body.appendChild(link);
  link.click();
};
