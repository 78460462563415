import styled from 'styled-components';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.familys._3} !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 41px !important;
  letter-spacing: -0.02em !important;
  text-align: center;
  margin-bottom: 18px;
  
  @media(max-width: 900px) {
    font-size: 20px !important;
    line-height: 24px !important;
    margin-bottom: 30px;
  }
`;

export const Container = styled.form`
  width: 453px;
  height: 349px;
  padding: 26px 42px;
  background: #FFFFFF;
  border-radius: 10px;
  label {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 700 !important;
  }

  @media(max-width: 900px) {
    width: 100%;
    height: auto;
    padding: 24px 16px;
  }
`;

export const ButtonSave = styled.button`
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};
  width: 50%;
  height: 40px;
  border-radius: 10px;
  border: none;
  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  :hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
`;

export const ButtonCancel = styled.button`
  background: #EBEBF5;
  width: 50%;
  height: 40px;
  margin-right: 15px;
  border-radius: 10px;
  border: 1px solid #D9DCDF;
  color: #B5B5B5;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  :hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;

export const ContentButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 90px;
`;
