import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 19px 25px;

  >div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;
  }
`;
