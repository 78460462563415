import request from '../request';
import { RequestLocation, CreateLocationProps } from './types';

export const getLocations = async (): Promise<Array<RequestLocation>> => {
  const { data } = await request.get('/locations/');
  return data as RequestLocation[];
};

export const postLocation = async (payload: CreateLocationProps): Promise<RequestLocation> => {
  const { data } = await request.post<RequestLocation>('/locations/', payload);
  return data as RequestLocation;
};
