import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.button)<{
  isMaxBar: boolean;
}>`
  background: #0d4bd0;
  position: absolute;
  top: -28px;
  z-index: 69;
  right: 10px;
  border-radius: 10px 10px 0 0;
  padding: 2px 10px;

  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: 0.3s ease;
    transform: ${({ isMaxBar }) => (isMaxBar ? 'none' : 'rotate(180deg)')};
  }
`;
