import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  gap: 10px;
  display: flex;
  flex-direction: column;

  h1 {
    gap: 2px;
    display: flex;
    flex-direction: column;
  };

  h1,span,h2,p {
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }

  h1,span {
    color: #000000b0;
    font-size: 1.5rem;
    font-weight: medium;
  }

  span {
    color: #0d4bd0;
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
    color: #000000b0;
  }

  h2{
    font-size: 1.2rem;
    color: #000000b0;
  }
`;
