import {
  FC,
  useState,
  useMemo,
  useCallback,
  ReactElement,
} from 'react';

import { createContext } from 'use-context-selector';
import { ProperPayHostDashboard } from '../../../services/HostDashboard/types';

interface IHostGridFinancesContext {
  year: number,
  setYear: (selectedYear: number) => void,
  financesData: ProperPayHostDashboard,
  setFinancesData: (data: ProperPayHostDashboard) => void,
  isLoadingFinances: boolean,
  setIsLoadingFinances: (isLoading: boolean) => void,
  propertyId: string | undefined;
  setPropertyId: Function;
}

export const HostGridFinancesContext = createContext<IHostGridFinancesContext>({
  isLoadingFinances: true,
  setIsLoadingFinances: (isLoading: boolean) => isLoading,
  financesData: {} as ProperPayHostDashboard,
  setFinancesData: (data: ProperPayHostDashboard) => data,
  year: new Date().getFullYear(),
  setYear: (selectedYear: number) => selectedYear,
  propertyId: undefined,
  setPropertyId: () => {},
});

export const HostGridFinancesProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [propertyId, setPropertyId] = useState<string | undefined>(undefined);
  const [financesData, setFinancesData] = useState<any>();
  const [isLoadingFinances, setIsLoadingFinances] = useState<boolean>(true);

  const handleSetYear = useCallback((selectedYear: number) => {
    setYear(selectedYear);
  }, []);

  const handleSetFinancesData = useCallback((data: any) => {
    setFinancesData(data);
  }, []);

  const handleSetIsLoadingFinances = useCallback((isLoading: boolean) => {
    setIsLoadingFinances(isLoading);
  }, []);

  const handleSetPropertyId = useCallback((property: string | undefined) => {
    setPropertyId(property);
  }, []);

  const value = useMemo(() => ({
    financesData,
    setFinancesData: handleSetFinancesData,
    isLoadingFinances,
    setIsLoadingFinances: handleSetIsLoadingFinances,
    year,
    setYear: handleSetYear,
    propertyId,
    setPropertyId: handleSetPropertyId,
  }), [
    financesData,
    setFinancesData,
    isLoadingFinances,
    setIsLoadingFinances,
    year,
    setYear,
    propertyId,
    setPropertyId,
  ]);

  return (
    <HostGridFinancesContext.Provider value={value}>
      {children}
    </HostGridFinancesContext.Provider>
  );
};
