/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';

import entranceIcon from '../../../../assets/icons/ownerPage/entranceIcon.svg';
import payoutIcon from '../../../../assets/icons/ownerPage/payoutIcon.svg';
import walletIcon from '../../../../assets/icons/ownerPage/walletIcon.svg';

import TooltipInfo from '../../TooltipInfo';

import {
  Container,
  TitleContainer,
  Icon,
  Title,
  ExpandMoreRoundedContainer,
  ExpandIcon,
  CollapseIcon,
  Description,
} from './styles';

interface ITag {
  label?: string,
  variant?: '1' | '2' | '3',
  disableDropIcon?: boolean,
  description?: string | JSX.Element,
  isExpanded?: boolean,
  setIsExpanded?: Function,
  stickTag?: boolean,
  valueJan?: string;
  valueFeb?: string;
  valueMar?: string;
  valueApr?: string;
  valueMay?: string;
  valueJun?: string;
  valueJul?: string;
  valueAug?: string;
  valueSep?: string;
  valueOut?: string;
  valueNov?: string;
  valueDec?: string;
  transfer?: boolean;
  totalValue?: string;
  hideTotal?: boolean;
  isSelectedOptionTODOS?: boolean;
}

const Tag: React.FC <ITag> = ({
  label = '',
  variant = '1',
  disableDropIcon = false,
  description = '',
  isExpanded = false,
  setIsExpanded = () => {},
  stickTag = false,
  valueJan = '',
  valueFeb = '',
  valueMar = '',
  valueApr = '',
  valueMay = '',
  valueJun = '',
  valueJul = '',
  valueAug = '',
  valueSep = '',
  valueOut = '',
  valueNov = '',
  valueDec = '',
  totalValue = '',
  hideTotal = false,
  isSelectedOptionTODOS = true,
}) => {
  const getDescription: Record<string, string | JSX.Element> = {
    'Diárias Executadas': isSelectedOptionTODOS ? <p>Total das diárias <strong>de todos os seus imóveis</strong>, com estadia no mês somado aos ajustes financeiros positivos. Não é o valor do repasse.</p> : 'Valor total das diárias com estadia no mês somado aos ajustes financeiros positivos. Não é o valor do repasse.',
    'Diárias Pagas': isSelectedOptionTODOS ? <p>Valor total das diárias <strong>de todos os seus imóveis</strong>, com recebimento no mês atual.</p> : 'Valor total das diárias com recebimento para o mês atual, independente da data da estadia.',
    Saídas: isSelectedOptionTODOS ? <p>Valor total das <strong>despesas, ajustes financeiros negativos, comissão, ou taxa de adesão</strong> do mês de referência, <strong>de todos os seus imóveis</strong>. Para detalhes acesse seu extrato financeiro.</p> : <p>Valor total das <strong>despesas, ajustes financeiros negativos, comissão, ou taxa de adesão</strong> do mês de referência. Para detalhes acesse seu extrato financeiro.</p>,
    Resultado: isSelectedOptionTODOS ? <p>Somatório do valor das Diárias pagas menos as Saídas do mês de referência, <strong>de todos os seus imóveis</strong>. <strong>Não é o valor do repasse (TED).</strong></p> : <p>Valor das Diárias pagas menos as Saídas do mês de referência. <strong>Não é o valor do repasse (TED).</strong></p>,
    Reembolsos: 'Valores recebidos relacionados a danos causados por hóspedes nos imóveis.',
  };

  const TagIcon: Record <string, string> = {
    1: entranceIcon,
    2: payoutIcon,
    3: walletIcon,
  };

  const ExpansiveButton = () => (
    <>
      {!disableDropIcon ? (
        <ExpandMoreRoundedContainer onClick={() => setIsExpanded((prevState:boolean) => !prevState)}>
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </ExpandMoreRoundedContainer>
      ) : (
        <ExpandMoreRoundedContainer onClick={() => {}}>
          <Description>{description}</Description>
        </ExpandMoreRoundedContainer>
      )}
    </>
  );

  return (
    <Container customColor={variant} stickTag={stickTag}>
      <TitleContainer customColor={variant}>
        <div>
          <ExpansiveButton />
          <Icon src={TagIcon[variant]} />
          <Title>{label}</Title>
        </div>
        <TooltipInfo
          title={label}
          subtitle={getDescription[label]}
          marginRight="1.2rem"
        />
      </TitleContainer>

      <p className="spacing" />
      <p>{valueJan}</p>
      <p>{valueFeb}</p>
      <p>{valueMar}</p>
      <p>{valueApr}</p>
      <p>{valueMay}</p>
      <p>{valueJun}</p>
      <p>{valueJul}</p>
      <p>{valueAug}</p>
      <p>{valueSep}</p>
      <p>{valueOut}</p>
      <p>{valueNov}</p>
      <p>{valueDec}</p>
      {!hideTotal && <p style={{ fontWeight: 700 }}>{totalValue}</p>}
    </Container>
  );
};

export default Tag;
