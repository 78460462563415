import {
  FC,
} from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  FormContainer,
  FormContent,
  ButtonsContainer,
} from './styles';

import FormGridRow from '../../../../FormGridRow';

import {
  TextField,
  FormButton,
  FormAddress,
} from '../../../../index';

import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../../utils/Messages';
import { useEditForm } from '../../../../../context/EditFormContext';
import { putGuest } from '../../../../../services/Guest/request';
import { putAddress } from '../../../../../services/Address/request';
import { useLoader } from '../../../../../context/LoaderContext';
import { useReservationDetails } from '../../../../../context/ReservationDetailsContext';
import { Roles } from '../../../../../services/types';

interface Props {
  email: string;
  phone1: string;
  street: string,
  zipCode: string,
  number: string,
  neighborhood: string,
  city: string,
  state: string,
  country: string,
  addressExists: boolean,
  activeButton: (a: boolean) => void;
}

const ContactDataForm: FC<Props> = ({
  email,
  phone1,
  street,
  zipCode,
  number,
  neighborhood,
  city,
  state,
  country,
  addressExists,
  activeButton,
}) => {
  const { reservationDetails, setReservationDetails } = useReservationDetails();

  const toast = useToast();
  const { setLoad } = useLoader();
  const toastErrorRequest = useToastErrorMessage();
  const {
    setOpenForm,
    setFormActive,
  } = useEditForm();

  const {
    cnpj, cpf, address, id,
  } = reservationDetails.guest;

  const handleCloseEditForm = () => {
    setOpenForm(false);
    setFormActive(0);
    activeButton(false);
  };
  const validation = Yup.object().shape({
    email: Yup.string().required().email('E-mail inválido'),
    phone1: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      email,
      phone1,
      street,
      zipCode,
      number,
      neighborhood,
      city,
      state,
      country,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      setLoad(true);
      try {
        const mainAddress: any = {
          ...address,
          street: values.street,
          number: values.number,
          neighborhood: values.neighborhood,
          city: values.city,
          state: values.state,
          postal_code: values.zipCode,
          country: values.country,
        };

        const guest = {
          id,
          user: {
            id: reservationDetails?.guest.id,
            first_name: reservationDetails?.guest.guestFirstName,
            last_name: reservationDetails?.guest.guestLastName,
            cpf: reservationDetails?.guest.cpf,
            cnpj: reservationDetails?.guest.cnpj,
            main_role: 'Host' as Roles,
            gender: 'Male',
            email: values.email,
            phone_number1: values.phone1,
            phone_number2: null,
            main_address: mainAddress,
            corporate_name: reservationDetails?.guest.corporate_name,
            trading_name: reservationDetails?.guest.trading_name,
            is_individual: reservationDetails?.guest.is_individual || null,
            id_number: reservationDetails?.guest.idNumber,
            birth_date: reservationDetails?.guest.birthDate || null,
          },
        };
        if (guest.user.cpf === cpf) {
          delete guest.user.cpf;
        }
        if (cnpj === guest.user.cnpj) {
          delete guest.user.cnpj;
        }

        await putGuest(guest.id, guest);

        if (addressExists) {
          await putAddress(mainAddress.id, mainAddress);
        }

        guest.user.cpf = cpf;

        toast.success('Os dados de contato foram salvos');

        const newReservation = {
          ...reservationDetails,
          guest: {
            ...reservationDetails.guest,
            main_role: 'Host' as Roles,
            gender: 'Male',
            email: values.email,
            phoneNumber1: values.phone1,
            phoneNumber2: null,
            mainAddress,
          },
        };

        setReservationDetails(newReservation);
        handleCloseEditForm();
        setLoad(false);
      } catch (e: unknown) {
        if (e instanceof Error) {
          setLoad(false);
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <FormContainer
      onContextMenu={(event) : void => {
        event.stopPropagation();
      }}
    >
      <FormContent onSubmit={formik.handleSubmit}>
        <FormGridRow>
          <TextField
            label="E-mail"
            formik={formik}
            id="email"
            value={email}
          />
        </FormGridRow>
        <FormGridRow>
          <TextField
            label="Telefone/Celular"
            formik={formik}
            placeholder="+00 (00) 00000-0000"
            id="phone1"
            mask="phone"
            value={phone1}
          />
        </FormGridRow>
        <FormAddress
          addressProp={{
            city,
            number,
            street,
            state,
            neighborhood,
            country,
            postal_code: zipCode,
            id: 0,
            complement: '',
          }}
          formik={formik}
        />
        <ButtonsContainer>
          <FormButton type="button" customColor="grey" onClick={() => handleCloseEditForm()}>Cancelar</FormButton>
          <FormButton onClick={() => formik.handleSubmit}>Salvar</FormButton>
        </ButtonsContainer>
      </FormContent>
    </FormContainer>
  );
};

export default ContactDataForm;
