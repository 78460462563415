import { motion } from 'framer-motion';
import styled from 'styled-components';

export const CopyContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-left: 0.5rem;

  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
