import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)``;

export const Alert = styled.div`
  background: #001840;
  color: #ff9900;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 20px;

  p,
  strong,
  a {
    color: #ff9900;
    font-size: 1.2rem;
  }
`;

export const Header = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  h2 {
    color: #001840;
    font-weight: 700;
  }
`;

export const Item = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  justify-content: space-between;

  h2 {
    flex-wrap: wrap;
    color: #001840;
  }

  p {
    color: #394760;
  }
`;

export const Footer = styled(motion.div)`
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;

  &&&& button {
    &&&&&:disabled {
      opacity: 0.2;
      color: #000;
      background: #ccc;
    }
  }
`;

export const Content = styled(motion.div)`
  width: 550px;
  min-width: 300px;
  background: white;
  border-radius: 10px;
  justify-content: center;

  display: flex;
  flex-direction: column;

  padding: 1.25rem 0;

  > *:not(.alert) {
    padding: 0 30px;
  }

  h1,
  p {
    font-size: 1.2rem;
  }
`;

export const ButtonsWrapper = styled(motion.div)`
  gap: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;

  p {
    color: #394760;
  }

  button {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 5px;
    border: 1px solid #394760;
    background: transparent;
    color: #394760;

    &:hover {
      background: #eee;
    }
  }
`;

export const Section = styled(motion.div)<{
  gap?: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ gap }) => gap || '10px'};
`;
