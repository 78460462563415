import { motion } from 'framer-motion';
import styled from 'styled-components';

export const DEFAULTPADDING = '24px';

export const Container = styled(motion.div)`
  padding: 24px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  @media (max-width: 320px) {
    padding: 14px;
  }
`;
