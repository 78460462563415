import styled from 'styled-components';

import { shimmerAnimation } from '../animationShimmer';

export const Container = styled.div<{
  animationTime: number;
}>`
  margin: 0px;
  height: 100px;
  overflow-y: hidden;
  padding: 1rem;
  padding-left: 0px;
  border-radius: 1rem;
  margin-bottom: 1rem;
  animation: ${shimmerAnimation} linear infinite;
  animation-duration: ${({ animationTime }) => animationTime}s;
  
`;
