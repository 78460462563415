import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

interface IStarRating {
  rating: number,
  setRating: Function,
  hover: number,
  setHover: Function,
}

export const StarRatingContext = createContext<IStarRating>({
  rating: 0,
  setRating: () => {},
  hover: 0,
  setHover: () => {},
});

export const StarRatingProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState<number>(0);

  const handleChangeRating = useCallback((ratingValue: number) => {
    setRating(ratingValue);
  }, []);

  const handleChangeHover = useCallback((hoverValue: number) => {
    setHover(hoverValue);
  }, []);

  const value = useMemo(() => ({
    rating,
    setRating: handleChangeRating,
    hover,
    setHover: handleChangeHover,
  }), [
    rating,
    setRating,
    hover,
    setHover,
  ]);

  return (
    <StarRatingContext.Provider
      value={value}
    >
      {children}
    </StarRatingContext.Provider>
  );
};
