import React from 'react';

import Navigate from './Navigate';
import CardList from './CardList';

import {
  Container,
} from './styles';

import {
  Container as ContainerCardList,
} from './CardList/styles';

import {
  useFinancialFeedback,
} from '../../../hooks/FinancialFeedbackContext/FinancialFeedbackContext';

import cardDataJSON from '../mock/cards.json';
import CardShimmer from './CardList/CardShimmer';

const Body = () => {
  const { loading } = useFinancialFeedback();

  if (loading) {
    return (
      <Container>
        <Navigate />
        <ContainerCardList>
          {cardDataJSON?.map((card) => (
            <CardShimmer key={card.id} table={card.table} />
          ))}
        </ContainerCardList>
      </Container>
    );
  }

  return (
    <Container>
      <Navigate />
      <CardList />
    </Container>
  );
};

export default Body;
