import {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  MouseEvent,
  createContext,
} from 'react';
import moment, { Moment } from 'moment';

import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import {
  Property,
  PropertyGroup,
  PropertyGrouped,
  PropertyGroupedClosed,
} from '../../services/Property/types';
import Navigation from './Navigation';
import NavigationDates from './NavigationDates';
import Properties from './Properties';
import PropertiesDates from './PropertiesDates';
import ReservationModal from '../ReservationModal';
import SettingsHideButton from './SettingsHideButton';
import {
  CalendarContainer,
  Container,
  Header,
  Main,
  Year,
  Years,
  HorizontalArrow,
  VerticalArrow,
} from './styles';
import {
  getProperties,
} from '../../services/Property/request';
import { useToast } from '../../context/ToastContext';
import { ErrorMessage } from '../../utils/Messages';
import {
  getPaymentStatus,
  getReservations,
} from '../../services/Reservation/request';
import { Reservation } from '../../services/Reservation/types';
import { useModal } from '../../hooks/ModalHook/useModal';
import { useReservationForm } from '../../hooks/ReservationHook/useReservationForm';
import { CheckBoxProvider } from '../../context/CheckBoxContext';
import { useSearch } from '../../hooks/SearchHook/useSearch';
import PropertiesDatesSkeleton from './LoadingShimmer/PropertiesDatesSkeleton';
import NavigationSkeleton from './LoadingShimmer/NavigationSkeleton';
import NavigationDatesSkeleton from './LoadingShimmer/NavigationDatesSkeleton';
import PropertiesSkeleton from './LoadingShimmer/PropertiesSkeleton';
import FastDataForwardButton from './FastDataForwardButton';
import { checkIfTokenExpired } from '../../utils/Auth';
import { useUser } from '../../context/UserContext';
import useLocalStorage from '../../hooks/GlobalHook/useLocalStorage';
import { useUpdateReservation } from '../../context/UpdateReservationContext';

type DateDiff = {
  date: null | Moment;
  diff: number;
  index: number;
};

type ReservationsProperties = Record<string, Record<string, Reservation>>;

export interface CalendarType {
  getReservation: Function;
}

export const CalendarContext = createContext<CalendarType>({
  getReservation: () => {},
});

let INITIAL_DATES_PER_PAGE = 40;
if (window.innerWidth > 2000) {
  INITIAL_DATES_PER_PAGE = 40;
} else if (window.innerWidth < 500 && window.innerWidth >= 385) {
  INITIAL_DATES_PER_PAGE = 4;
} else if (window.innerWidth < 385) {
  INITIAL_DATES_PER_PAGE = 2;
} else {
  INITIAL_DATES_PER_PAGE = 30;
}

const Calendar: FC<{}> = () => {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const [sidebarIsClosed] = useLocalStorage('sidebarFull', false);

  const wrapperRef = useRef<HTMLHeadingElement>(null);
  const { dataGrouped, setDataGrouped, resetDataGrouped } = useReservationForm();
  const { filteredResults } = useSearch();
  const toast = useToast();
  const [loadInitialDate, setLoadInitialDate] = useState<boolean>(false);

  const [dates, setDates] = useState<Moment[]>([]);
  const [closeGrouped, setCloseGrouped] = useState<PropertyGroupedClosed>({});
  const [data, setData] = useState<PropertyGroup | null>(null);
  const [endDate, setEndDate] = useState<Moment>(moment().add(INITIAL_DATES_PER_PAGE === 2 ? -1 : -10, 'days'));
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<boolean>(false);
  const [lastPage, setLastPage] = useState<number>(2);

  const { handleOpen } = useModal();
  const [years, setYears] = useState({
    previous: moment().format('YYYY'),
    next: '',
  });
  const [loadDate, setLoadDate] = useState<boolean>(false);
  const [loadProperty, setLoadProperty] = useState<boolean>(false);
  const [loadReservation, setLoadReservation] = useState<boolean>(false);
  const {
    setUpdateReservation, updateReservation,
    reloadLoadingShimmer, setReloadLoadingShimmer,
  } = useUpdateReservation();

  const [propertyList, setPropertyList] = useState<Property[]>([]);
  const [totalPropertiesExists, setTotalPropertiesExists] = useState<number>(0);
  const [lastProperty, setLastProperty] = useState<Property>();
  const [lastDateVisible, setLastDateVisible] = useState<Moment>();

  const [loadShimmerInitial, setLoadShimmerInitial] = useState<boolean>(true);
  const [loadShimmerDailyPrices, setLoadShimmerDailyPrices] = useState<boolean>(true);

  const [isShowingArrowLeft, setIsShowingArrowLeft] = useState<boolean>(false);
  const [isShowingArrowRight, setIsShowingArrowRight] = useState<boolean>(false);

  const [isShowingArrowDown, setIsShowingArrowDown] = useState<boolean>(false);
  const [isHiddenArrowDownFilter, setIsHiddenArrowDownFilter] = useState<boolean>(true);

  type Orientation = 'horizontal' | 'vertical';
  type Direction = 'up' | 'down' | 'left' | 'right' | 'center';
  const [orientationScroll, setOrientationScroll] = useState<Orientation>('horizontal');
  const [directionScroll, setDirectionScroll] = useState<Direction>('center');
  const [isLastPage, setIsLastPage] = useState(false);
  const NEXT_DATES_PER_PAGE = Math.ceil(INITIAL_DATES_PER_PAGE / 2);

  const INITIAL_PROPERTIES_PER_PAGE = 25;
  const NEXT_PROPERTIES_PER_PAGE = Math.ceil(INITIAL_PROPERTIES_PER_PAGE / 2);
  const MAX_PROPERTIES_PER_PAGE = INITIAL_PROPERTIES_PER_PAGE;

  const navigate = useNavigate();

  const getPositionHorizontalScroll = () => {
    const position = localStorage.getItem('last-position:horizontal-scroll');
    return Number(position);
  };

  const getPositionVerticalScroll = () => {
    const position = localStorage.getItem('last-position:vertical-scroll');
    return Number(position);
  };

  const clearPositionScroll = () => {
    localStorage.removeItem('last-position:horizontal-scroll');
    localStorage.removeItem('last-position:vertical-scroll');
    localStorage.removeItem('scrolling');
  };

  useEffect(() => {
    clearPositionScroll();
    return () => clearPositionScroll();
  }, []);

  const movementScroll = () => {
    if (orientationScroll === 'horizontal') {
      if (wrapperRef.current) {
        wrapperRef.current.scrollTop = getPositionVerticalScroll();
        const dateToday: HTMLElement | null = document.getElementById(moment().format('YYYY-MM-DD'));
        if (dateToday && !loadInitialDate) {
          wrapperRef.current?.scrollTo(dateToday.offsetLeft - 185, 0);
          setLoadInitialDate(true);
        } else if (lastDateVisible) {
          const element: HTMLElement | null = document.getElementById(lastDateVisible.format('YYYY-MM-DD'));
          element?.scrollIntoView({
            inline: directionScroll === 'right' ? 'end' : 'start',
          });
          if (directionScroll === 'right') {
            wrapperRef.current.scrollLeft -= 110;
          } else {
            wrapperRef.current.scrollLeft -= 90;
          }
        }
      }
    } else if (orientationScroll === 'vertical') {
      if (wrapperRef.current) {
        wrapperRef.current.scrollLeft = getPositionHorizontalScroll();
        if (lastProperty) {
          const element = document.querySelector(`#property-${lastProperty.code}`);
          element?.scrollIntoView({
            block: directionScroll === 'down' ? 'end' : 'start',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!loadShimmerDailyPrices) {
      movementScroll();
    }
  }, [loadShimmerDailyPrices]);

  useEffect(() => {
    if (reloadLoadingShimmer) {
      setLoadShimmerDailyPrices(true);
      setTimeout(() => {
        setLoadShimmerDailyPrices(false);
        setReloadLoadingShimmer(false);
      }, 3000);
    }
  }, [reloadLoadingShimmer]);

  const reduceReservations = (bookings: Reservation[]) => bookings
    .reduce<ReservationsProperties>((acc, item) => {
    const checkinsInProperty: string[] = Object.keys(acc[item.listing.property.code] || {});

    let keyCheckin = '';
    if (!checkinsInProperty.includes(item.check_in_date) || (checkinsInProperty.includes(item.check_in_date) && item.status !== 'Canceled')) {
      keyCheckin = item.check_in_date;
    }

    return {
      ...acc,
      [item.listing.property.code]: {
        ...(acc[item.listing.property.code] || {}),
        [keyCheckin]: {
          ...item,
          paymentStatus: getPaymentStatus(item.status),
        },
      },
    };
  }, {});

  const reduceProperties = (array: Property[]) => array
    .reduce((acc: PropertyGroup, cur: Property) => ({
      ...acc,
      [cur.category_location]: {
        name: cur.category_location,
        id: cur.category_location,
        guaranteeValue: cur.bond_amount,
        data: [...(acc[cur.category_location]?.data || []), cur],
      },
    }),
    {});

  const reducePropertiesHost = (array: Property[]) => array
    .reduce((acc: PropertyGroup, cur: Property) => ({
      ...acc,
      [cur.location]: {
        name: cur.location,
        id: cur.location,
        guaranteeValue: cur.bond_amount,
        data: [...(acc[cur.location]?.data || []), cur],
      },
    }),
    {});

  const addPastDates = (): void => {
    setLoadDate(true);
    setLoadShimmerDailyPrices(true);
    const firstDate = dates[0];

    const pastDates: Moment[] = Array.from({ length: NEXT_DATES_PER_PAGE },
      (_, index: number) => moment(firstDate).add(-(index + 1), 'days'));

    const arrayDates = Array
      .from(new Set([...pastDates.reverse(), ...dates.slice(0, NEXT_DATES_PER_PAGE)]));

    setDates(arrayDates);
    setLastDateVisible(firstDate);
    setLoadReservation(true);
  };

  const addNextDates = (): void => {
    setLoadDate(true);
    setLoadShimmerDailyPrices(true);
    const lastDate = dates[dates.length - 1];

    const nextDates: Moment[] = Array.from({ length: NEXT_DATES_PER_PAGE },
      (_, index: number) => moment(lastDate).add(index + 1, 'days'));

    const arrayDates = Array.from(new Set([
      ...dates.slice(dates.length - NEXT_DATES_PER_PAGE, dates.length),
      ...nextDates,
    ]));

    setDates(arrayDates);
    setLastDateVisible(lastDate);
    setLoadReservation(true);
  };

  const setPropertiesVisible = (
    currentPage: number,
    values: Property[],
    direction: Direction = 'center',
  ) => {
    const totalProperties = propertyList.length;

    let properties: Property[] = totalProperties > 0 ? [...propertyList] : [...values];
    if (direction === 'down') {
      const nextProperties = values;

      properties = [...nextProperties];

      setLastProperty(propertyList[propertyList.length - 1]);
    } else if (direction === 'up') {
      const prevProperties = values;
      const nextProperties = propertyList;

      if (currentPage === 1) {
        properties = [...prevProperties];
      } else {
        properties = [...prevProperties, ...nextProperties].length
          > MAX_PROPERTIES_PER_PAGE
          ? [
            ...prevProperties,
            ...propertyList.slice(0, NEXT_PROPERTIES_PER_PAGE),
          ]
          : [...prevProperties, ...nextProperties];
        setLastProperty(propertyList[0]);
      }
    }
    return Array.from(new Set([...properties]));
  };

  const removeDuplicateProperties = (properties: Property[]) => properties.filter((
    value, index, array,
  ) => array.findIndex((property) => property.id === value.id) === index);

  const getPageProperties = async (currentPage: number,
    direction: Direction = 'center') => {
    if (loadDate) {
      return;
    }

    setLoadProperty(true);
    try {
      if (userInformation) {
        const values = await getProperties(currentPage);
        let properties;
        properties = setPropertiesVisible(
          currentPage,
          values.results,
          direction,
        );
        properties = removeDuplicateProperties(properties);
        const propertiesData = roles.includes('Host') ? reducePropertiesHost(properties) : reduceProperties(properties);
        if (!values.next) {
          setIsLastPage(true);
        }
        setPage(currentPage);
        setData(propertiesData);
        setLastPage(Math.ceil(values.count / INITIAL_PROPERTIES_PER_PAGE));
        setPropertyList(properties);
        setTotalPropertiesExists(values.count);
        setLoadReservation(true);
        setLoadProperty(false);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const tokenExpired = checkIfTokenExpired();
        if (tokenExpired) {
          toast.error(ErrorMessage.expiredToken());
          window.localStorage.removeItem('sapron-pms-user');
          navigate('/login');
        }

        toast.error(e.message || ErrorMessage.default());
        setEndPage(true);
        setLoadProperty(false);
      }
    }
  };

  const addNextProperties = () => {
    if (
      (dataGrouped && dataGrouped.length) === 0
      || loadProperty
      || (lastPage && page + 1 > lastPage)
    ) {
      return;
    }
    setLoadShimmerDailyPrices(true);
    getPageProperties(page + 1, 'down');
  };

  const addPrevProperties = () => {
    if (
      (dataGrouped && dataGrouped.length) === 0
      || loadProperty
      || (lastPage && page - 1 < 1)
    ) {
      return;
    }
    setLoadShimmerDailyPrices(true);
    getPageProperties(page - 1, 'up');
    setIsLastPage(false);
  };

  const getPropertiesReservations = async (rangeProperties: string[],
    rangeDates: Moment[]): Promise<Reservation[]> => {
    let dataReservation: Reservation[] = [];

    try {
      dataReservation = await getReservations(rangeDates, rangeProperties);
      setDates(rangeDates);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
      }
    }
    return dataReservation;
  };
  function sortProperty(a: Property, b: Property) {
    if (a.code > b.code) {
      return 1;
    }
    if (a.code < b.code) {
      return -1;
    }
    return 0;
  }
  const addReservation = async (filteredProperties?: Property[],
    filteredsDates?: Moment[]) => {
    if (!data) {
      return;
    }

    if (filteredProperties && (filteredProperties.length < INITIAL_PROPERTIES_PER_PAGE)) {
      setIsShowingArrowDown(false);
      setIsHiddenArrowDownFilter(false);
    } else {
      setIsHiddenArrowDownFilter(true);
    }

    try {
      const propertiesGrouped:
      PropertyGrouped[] = (filteredProperties && filteredProperties.length > 0)
        || filteredResults.length > 0
        ? Object.values(reduceProperties(filteredProperties || filteredResults))
        : Object.values(data);

      const rangeProperties = propertiesGrouped
        .map((item) => item.data.map((property: Property) => property.id.toString()))
        .flat();
      const rangeDates = filteredsDates || dates;
      const dataReservation = await getPropertiesReservations(rangeProperties,
        rangeDates);
      const reservationsProperties: ReservationsProperties = reduceReservations(dataReservation);

      setDataGrouped(propertiesGrouped.map((item) => ({
        ...item,
        data: item.data.sort(sortProperty).map<Property>((property: Property) => ({
          ...property,
          price: 100,
          reservation: reservationsProperties[property.code] || null,
        })),
      })));

      setLoadReservation(false);
      setLoadShimmerDailyPrices(false);
      setLoadShimmerInitial(false);
      setLoadDate(false);
      setTimeout(() => {
        setUpdateReservation(updateReservation + 1);
        setLoadReservation(false);
      }, 500);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        setLoadReservation(false);
        setLoadShimmerDailyPrices(false);
        setLoadShimmerInitial(false);
        setLoadDate(false);
      }
    }
  };

  const getDataInit = () => {
    if (endPage) {
      return;
    }
    getPageProperties(page);
  };

  useEffect(() => {
    getDataInit();
    setDates(Array.from({ length: INITIAL_DATES_PER_PAGE }, (_, days: number) => moment(endDate).add(days + 1, 'day')));
    setEndDate(endDate.add(10, 'days'));
  }, []);

  useEffect(() => {
    const left: HTMLElement | null = document.getElementById(moment().format('YYYY-MM-DD'));

    if (wrapperRef && left && !loadInitialDate) {
      wrapperRef.current?.scrollTo(left.offsetLeft - 185, 0);
      setLoadInitialDate(true);
    }
  }, [wrapperRef, dates, loadInitialDate]);

  useEffect(() => {
    if (loadReservation) {
      addReservation();
    }
  }, [loadReservation]);

  useEffect(() => {
    async function getReservationsUpdate() {
      setLoadShimmerDailyPrices(true);
      await addReservation();
      setLoadShimmerDailyPrices(false);
    }

    getReservationsUpdate();
  }, [resetDataGrouped]);

  const handleScroll = ({
    currentTarget: {
      clientWidth,
      scrollWidth,
      scrollLeft,
      scrollHeight,
      scrollTop,
      clientHeight,
    },
  }: MouseEvent<HTMLDivElement>) => {
    const item = scrollWidth / dates.length;
    const itemPerPage = Math.round((clientWidth - 180) / item);

    const viewDate: DateDiff = dates.reduce<DateDiff>((
      acc: DateDiff, cur, index,
    ) => {
      const diff = Math.abs((index + 1) * item - (scrollLeft + 220));

      if (!acc.date || diff < acc.diff) {
        return {
          date: cur,
          diff,
          index,
        };
      }

      return acc;
    },
    {
      date: null,
      diff: 0,
      index: 0,
    });

    const selectedDate = dates.slice(viewDate.index,
      viewDate.index + itemPerPage);
    const lastItem = selectedDate.length - 1;

    if (
      years.next !== selectedDate[lastItem].format('YYYY')
      || years.previous !== selectedDate[0].format('YYYY')
    ) {
      setYears({
        previous: selectedDate[0].format('YYYY'),
        next: selectedDate[lastItem].format('YYYY'),
      });
    }

    if (loadDate || loadShimmerDailyPrices || loadProperty) {
      return;
    }

    localStorage.setItem('scrolling', 'false');
    localStorage.setItem('last-position:vertical-scroll', scrollTop.toString());
    localStorage.setItem('last-position:horizontal-scroll',
      scrollLeft.toString());
    setIsShowingArrowRight(false);
    setIsShowingArrowLeft(false);
    setIsShowingArrowDown(false);

    if (scrollWidth - scrollLeft <= clientWidth) {
      setOrientationScroll('horizontal');
      setDirectionScroll('right');
      localStorage.setItem('scrolling', 'true');
      setIsShowingArrowRight(true);
    }
    if (scrollLeft < 5) {
      setOrientationScroll('horizontal');
      setDirectionScroll('left');
      localStorage.setItem('scrolling', 'true');
      setIsShowingArrowLeft(true);
    }
    if (scrollTop < 5 && page > 1 && scrollHeight > clientHeight) {
      setOrientationScroll('vertical');
      setDirectionScroll('up');
      localStorage.setItem('scrolling', 'true');
    }
    if (scrollHeight - scrollTop <= clientHeight) {
      setOrientationScroll('vertical');
      setDirectionScroll('down');
      localStorage.setItem('scrolling', 'true');
      if (scrollHeight !== clientHeight && !isLastPage) {
        setIsShowingArrowDown(true);
      }
    }
  };

  const onOpen = useCallback(() => handleOpen(true), [handleOpen]);

  return (
    <CalendarContainer
      id="calendarData"
      ref={wrapperRef}
      onScroll={handleScroll}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {!loadShimmerDailyPrices ? (
        <CalendarContext.Provider
          value={{
            getReservation: addReservation,
          }}
        >
          <Container role={userInformation?.main_role || ''}>
            <HorizontalArrow
              sidebarIsClosed={sidebarIsClosed}
              isShowingArrow={isShowingArrowLeft}
              onClick={() => addPastDates()}
            >
              <div className="icon">
                <ArrowBackIosIcon />
              </div>
            </HorizontalArrow>
            <HorizontalArrow
              anchor="right"
              isShowingArrow={isShowingArrowRight}
              onClick={() => addNextDates()}
            >
              <div className="icon">
                <ArrowForwardIosIcon />
              </div>
            </HorizontalArrow>
            <VerticalArrow
              anchor="up"
              onClick={() => addPrevProperties()}
              isShowingArrow={page !== 1}
            >
              <div className="icon">
                <ArrowForwardIosIcon />
              </div>
            </VerticalArrow>
            <VerticalArrow
              isShowingArrow={isShowingArrowDown && isHiddenArrowDownFilter}
              onClick={() => addNextProperties()}
            >
              <div className="icon">
                <ArrowForwardIosIcon />
              </div>
            </VerticalArrow>
            <Header>
              <Navigation
                page={page}
                setPage={setPage}
                className="calendar-navigation"
                INITIAL_PROPERTIES_PER_PAGE={INITIAL_PROPERTIES_PER_PAGE}
              />
              <Years center={years.next === years.previous}>
                <Year>{years.previous}</Year>
                {years.previous !== years.next && <Year>{years.next}</Year>}
              </Years>
              <NavigationDates
                className="calendar-navigation-dates"
                dates={dates}
              />
            </Header>
            <CheckBoxProvider>
              <Main className="selection">
                <Properties
                  className="calendar-property"
                  groupedData={dataGrouped || []}
                  propertyGroupedClosed={closeGrouped}
                  setPropertyGroupedClosed={setCloseGrouped}
                  totalPropertiesLoaded={propertyList.length}
                  totalPropertiesExists={totalPropertiesExists}
                  lastPropertyCode={lastProperty?.code}
                />
                <PropertiesDates
                  dates={dates}
                  groupedData={dataGrouped || []}
                  propertyGroupedClosed={closeGrouped}
                  onClick={onOpen}
                />
                <ReservationModal />
              </Main>
              {!roles.includes('Host') && (
                <SettingsHideButton />
              )}
              <FastDataForwardButton setDates={setDates} />
            </CheckBoxProvider>
          </Container>
        </CalendarContext.Provider>
      ) : (
        <Container role={userInformation?.main_role || ''}>
          {loadShimmerInitial ? (
            <>
              <Header>
                <NavigationSkeleton />
                <Years center={years.next === years.previous}>
                  <Year>{''}</Year>
                  {years.previous !== years.next && <Year>{years.next}</Year>}
                </Years>
                <NavigationDatesSkeleton />
              </Header>
              <Main>
                <PropertiesSkeleton />
                <PropertiesDatesSkeleton />
              </Main>
            </>
          ) : (
            <>
              <Header>
                <Navigation
                  page={page}
                  setPage={setPage}
                  className="calendar-navigation"
                  INITIAL_PROPERTIES_PER_PAGE={INITIAL_PROPERTIES_PER_PAGE}
                />
                <Years center={years.next === years.previous}>
                  <Year>{years.previous}</Year>
                  {years.previous !== years.next && <Year>{years.next}</Year>}
                </Years>
                <NavigationDates
                  className="calendar-navigation-dates"
                  dates={dates}
                />
              </Header>
              <Main>
                <Properties
                  className="calendar-property"
                  groupedData={dataGrouped || []}
                  propertyGroupedClosed={closeGrouped}
                  setPropertyGroupedClosed={setCloseGrouped}
                  totalPropertiesLoaded={propertyList.length}
                  totalPropertiesExists={totalPropertiesExists}
                  lastPropertyCode={lastProperty?.code}
                />
                <PropertiesDatesSkeleton />
              </Main>
            </>
          )}
        </Container>
      )}
    </CalendarContainer>
  );
};

export default Calendar;
