import { Moment } from 'moment';
import { ReservationTypeLocal } from '../../components/Calendar/BudgetModal/types';

import request from '../request';
import { Budget } from './types';

export interface GetDaillyBudgetProps {
  startDate: Moment;
  endDate: Moment;
  propertiesIds: string;
  qtdGuest?: number;
}

type FormatProps = 'YYYY-MM-DD' | 'DD/MM/YYYY';

export const getDaillyBudget = async ({
  startDate,
  propertiesIds,
  endDate,
}: GetDaillyBudgetProps) => {
  const formatDate = (date: Moment, format: FormatProps) => (date.format(format));
  const { data } = await request.get(`properties/daily_budget/?start_date=${formatDate(startDate, 'YYYY-MM-DD')}&property_id=${propertiesIds}&end_date=${formatDate(endDate, 'YYYY-MM-DD')}&guests=2`);

  let formatArrayData: ReservationTypeLocal[] = [];

  Object.keys(data).forEach((key) => {
    const formatData: ReservationTypeLocal = {
      address: {
        id: data[key]?.address?.id,
        street: data[key]?.address?.street,
        number: data[key]?.address?.number,
        complement: data[key]?.address?.complement,
        neighborhood: data[key]?.address?.neighborhood,
        city: data[key]?.address?.city,
        state: data[key]?.address?.state,
        postal_code: '',
        country: data[key]?.address?.country,
      },
      quantityGuest: data[key]?.guest_capacity,
      checkinDate: formatDate(startDate, 'DD/MM/YYYY'),
      checkoutDate: formatDate(endDate, 'DD/MM/YYYY'),
      totalNigth: 0,
      valuePerNigth: data[key]?.daily_price,
      cleaningFee: data[key]?.cleaning_fee,
      propertyId: Number(key),
      totalValue: data[key]?.total_value,
      idIndex: Number(key),
      code: data[key]?.code,
      price: data[key]?.daily_price,
      category: '',
      newProperty: true,
      rent_price: {
        total_price: data[key].total_price,
      },
    };

    formatArrayData = [...formatArrayData, { ...formatData }];
  });

  return formatArrayData;
};

export const getDailyBudget = async ({
  startDate,
  propertiesIds,
  endDate,
  qtdGuest,
}: GetDaillyBudgetProps): Promise<Budget> => {
  const formatDate = (date: Moment, format: FormatProps) => (date.format(format));
  const { data } = await request.get(`properties/daily_budget/?start_date=${formatDate(startDate, 'YYYY-MM-DD')}&property_id=${propertiesIds}&end_date=${formatDate(endDate, 'YYYY-MM-DD')}&guests=${qtdGuest || 2}`);
  return data as Budget;
};
