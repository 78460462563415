import React, {
  FC,
  useState,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { createContext } from 'use-context-selector';
import { v4 as uuid } from 'uuid';
import { Moment } from 'moment';
import { Property, PropertyGrouped } from '../services/Property/types';

export interface ReservationData {
  price: number,
  guaranteeValue: number,
  start: Moment,
  end: Moment,
  properties: Property[],
}

export interface ReservationProps {
  data?: ReservationData,
  setData: (data: ReservationData) => void,
  resetSelection: string,
  handleResetSelection: () => void,
  resetDataGrouped: string,
  handleResetDataGrouped: () => void,
  dataGrouped?: PropertyGrouped[],
  setDataGrouped: (dataGrouped: PropertyGrouped[]) => void,
}

export const RerservatioFormContext = createContext<ReservationProps>({
  data: undefined,
  setData: (data: ReservationData) => data,
  resetSelection: '',
  handleResetSelection: () => {},
  resetDataGrouped: '',
  handleResetDataGrouped: () => {},
  dataGrouped: [],
  setDataGrouped: (dataGrouped: PropertyGrouped[]) => dataGrouped,
});

export const ReservationFormProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [data, setData] = useState<ReservationData>();
  const [resetSelection, setResetSelection] = useState<string>(uuid());
  const [resetDataGrouped, setResetDataGrouped] = useState<string>(uuid());
  const [dataGrouped, setDataGrouped] = useState<PropertyGrouped[]>([]);

  const handleResetSelection = useCallback(() => {
    setResetSelection(uuid());
  }, []);

  const handleResetDataGrouped = useCallback(() => {
    setResetDataGrouped(uuid());
  }, []);

  const value = useMemo(() => ({
    data,
    setData,
    resetSelection,
    handleResetSelection,
    resetDataGrouped,
    handleResetDataGrouped,
    dataGrouped,
    setDataGrouped,
  }), [data, dataGrouped, handleResetSelection,
    resetSelection, resetDataGrouped, setResetDataGrouped]);

  return (
    <RerservatioFormContext.Provider
      value={value}
    >
      {children}
    </RerservatioFormContext.Provider>
  );
};
