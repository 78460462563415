import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  span,
  p,
  strong {
    font-size: 1.1rem;
  }

  h1 {
    color: #394760;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 27px;
    font-style: normal;
  }
  p {
    color: #394760;
  }
`;
