import styled, { css } from 'styled-components';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const getBackgroundWithGradient = (variant: string) => {
  let color = '#FFFFFF';

  if (variant === '1') {
    color = 'linear-gradient(90deg, #EAFFBE 11.87%, #D7FFF3 72.55%)';
  } else if (variant === '2') {
    color = 'linear-gradient(90deg, #FFDAE0 19.21%, #FFACBB 97%)';
  } else if (variant === '3') {
    color = 'linear-gradient(90deg, #FFC1F5 11.87%, #FFE296 98.03%)';
  } else if (variant === '4') {
    color = 'linear-gradient(90deg, #BCE3FF 28.29%, #C2B8FF 99.41%)';
  }

  return color;
};

const getBackgroundWithoutGradient = (variant: string) => {
  let color = '#FFFFFF';

  if (variant === '1') {
    color = '#EAFFBE';
  } else if (variant === '2') {
    color = '#FFDAE0';
  } else if (variant === '3') {
    color = '#FFC1F5';
  } else if (variant === '4') {
    color = '#BCE3FF';
  }

  return color;
};

export const Container = styled.div <{ customColor: string, stickTag: boolean }>`
  width: max-content;
  display: grid;
  grid-template-columns: 235px 0vw repeat(13, 100px);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 27px;
  background: ${({ customColor }) => getBackgroundWithGradient(customColor)};
  padding: 0 1.125rem;
  
  p{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 0.875rem;
    width: 100%;
  }

  ${({ customColor }) => ['0', '5'].includes(customColor) && css`
    border-top: 1px solid  ${({ theme }) => theme.palette.grey._425.hex()};
    border-bottom: 1px solid  ${({ theme }) => theme.palette.grey._425.hex()};
    height: 28px;
  `}

  @media(max-width: 768px) {
    ${({ stickTag }) => stickTag && css`
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 70px;
    `}

    p {
      font-size: 1rem;
    }
  }
`;

export const TitleContainer = styled.div<{ customColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ customColor }) => getBackgroundWithoutGradient(customColor)};
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  height: 27px;
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    strong {
      hyphens: auto;
      text-align: justify;
      word-spacing: -0.5px;
    }
  }
  
  ${({ customColor }) => ['0', '5'].includes(customColor) && css`
    border-bottom: 1px solid  ${({ theme }) => theme.palette.grey._425.hex()};
  `}

  @media(min-width: 1500px) and (max-width: 1706px){
    padding-left: 1rem;
  }
`;

export const Title = styled.strong`
  margin: 0 0.5rem;
  font-size: 0.9rem;
  text-align: start;
`;

export const Description = styled.p`
  font-size: 0.8rem;
  
  @media(max-width: 768px) {
    font-size: 0.9rem;
    margin-top: 0.3rem;
    margin-left: 2.25rem;
  }
`;

export const Icon = styled.img`
  @media(max-width: 768px) {
    margin-top: 0.2rem;
  }
`;

export const ExpandMoreRoundedContainer = styled.div<{ hideExpandIcon?: boolean }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  visibility: ${({ hideExpandIcon }) => (hideExpandIcon ? 'hidden' : 'visible')};
`;

export const ExpandIcon = styled(AddCircleOutline)`
  margin: 4px !important;
  width: 14px !important;
  height: 14px !important;
  fill: ${({ theme }) => theme.palette.blue._400.hex()} !important;
  cursor: pointer;

  @media(min-width: 768px) {
    margin: 4px !important;
    width: 16px !important;
    height: 16px !important;
  }
`;

export const CollapseIcon = styled(RemoveCircleOutline)`
  margin: 4px !important;
  width: 14px !important;
  height: 14px !important;
  fill: ${({ theme }) => theme.palette.blue._400.hex()} !important;
  cursor: pointer;

  @media(min-width: 768px) {
    margin: 4px !important;
    width: 16px !important;
    height: 16px !important;
  }
`;
