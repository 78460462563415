import { css } from 'styled-components';
import { gridTemplateColumns } from '.';

export const baseStyle = css`
  display: grid;
  grid-template-columns: ${gridTemplateColumns};

  padding: 13px 16px;

  min-width: 100%;
  width: fit-content;
`;
