import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

interface ITagContext {
  isExpandedDailyExecuted: boolean,
  setIsExpandedDailyExecuted: (isExpanded: boolean) => void,
  isExpandedDailyPaid: boolean,
  setIsExpandedDailyPaid: (isExpanded: boolean) => void,
  isExpandedPayout: boolean,
  setIsExpandedPayout: (isExpanded: boolean) => void,
  isExpandedWallet: boolean,
  setIsExpandedWallet: (isExpanded: boolean) => void,
}

export const TagContext = createContext<ITagContext>({
  isExpandedDailyExecuted: false,
  setIsExpandedDailyExecuted: () => {},
  isExpandedDailyPaid: false,
  setIsExpandedDailyPaid: () => {},
  isExpandedPayout: false,
  setIsExpandedPayout: () => {},
  isExpandedWallet: false,
  setIsExpandedWallet: () => {},
});

export const TagProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [isExpandedDailyExecuted, setIsExpandedDailyExecuted] = useState<boolean>(false);
  const [isExpandedDailyPaid, setIsExpandedDailyPaid] = useState<boolean>(false);
  const [isExpandedPayout, setIsExpandedPayout] = useState<boolean>(false);
  const [isExpandedWallet, setIsExpandedWallet] = useState<boolean>(false);

  const handleChangeIsExpandedDailyExecuted = useCallback((isExpanded: boolean) => {
    setIsExpandedDailyExecuted(isExpanded);
  }, []);

  const handleChangeIsExpandedDailyPaid = useCallback((isExpanded: boolean) => {
    setIsExpandedDailyPaid(isExpanded);
  }, []);

  const handleChangeIsExpandedPayout = useCallback((isExpanded: boolean) => {
    setIsExpandedPayout(isExpanded);
  }, []);

  const handleChangeIsExpandedWallet = useCallback((isExpanded: boolean) => {
    setIsExpandedWallet(isExpanded);
  }, []);

  const value = useMemo(() => ({
    isExpandedDailyExecuted,
    setIsExpandedDailyExecuted: handleChangeIsExpandedDailyExecuted,
    isExpandedDailyPaid,
    setIsExpandedDailyPaid: handleChangeIsExpandedDailyPaid,
    isExpandedPayout,
    setIsExpandedPayout: handleChangeIsExpandedPayout,
    isExpandedWallet,
    setIsExpandedWallet: handleChangeIsExpandedWallet,
  }), [
    isExpandedDailyExecuted,
    setIsExpandedDailyExecuted,
    isExpandedDailyPaid,
    setIsExpandedDailyPaid,
    isExpandedPayout,
    setIsExpandedPayout,
    isExpandedWallet,
    setIsExpandedWallet,
  ]);

  return (
    <TagContext.Provider
      value={value}
    >
      {children}
    </TagContext.Provider>
  );
};
