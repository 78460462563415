import * as Yup from 'yup';

import { IValidateSecGuest, IValidation } from './types';
import { secGuestMessage, validateSecGuestFields } from './secondaryGuest';
import { FormikFields } from '../types';

export const validationSchema = ({
  info,
  guests,
  singleGuest,
  hasBedOrganization,
}: IValidation) => {
  const validateIfIsCpfIsDuplicate = (cpf?: string) => {
    const isDuplicate = guests?.list?.some((guest) => {
      const uniqueCPF = guest?.document === cpf;
      const uniqueID = guest?.id === singleGuest?.id;

      const uniqueDocMain = guest?.document === guests?.main?.document;
      const validateCpfWithMain = cpf === guests?.main?.document;

      if (uniqueID && validateCpfWithMain) {
        return true;
      }

      if (uniqueCPF && uniqueDocMain) {
        return true;
      }

      return uniqueCPF && !uniqueID;
    });
    return !isDuplicate;
  };

  const handleValidateSecGuest: IValidateSecGuest = (value, config) => {
    const propertyType = info?.property?.property_type;
    if (validateSecGuestFields(propertyType, config?.path as FormikFields)) {
      return true; // Campo opcional
    }

    return !!value; // Campo obrigatório
  };

  // retorno do yup
  return Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),

    bedOrganization: Yup.string().when('isPrincipal', {
      is: true,
      then: Yup.string().test(
        'isRequired',
        'A organização de camas é obrigatória, pois esse é o hóspede principal',
        (value) => {
          if (hasBedOrganization) {
            return Yup.string().required().isValidSync(value);
          }
          return true;
        },
      ),
    }),

    nationality: Yup.string().required('Nacionalidade Obrigatória'),

    cpf: Yup.string()
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
      .test(
        'cpf', 'CPF já cadastrado', (value) => validateIfIsCpfIsDuplicate(value),
      )
      .when(['isPrincipal', 'nationality'], {
        is: (isPrincipal: boolean, nationality: string) => isPrincipal && nationality === 'brazilian',
        then: Yup.string().required('O CPF é obrigatório, pois esse é o hóspede principal'),
      })
      // valida para o hóspede secundário
      .when('isPrincipal', {
        is: false,
        then: Yup
          .string()
          .test(
            'cpf',
            `O cpf${secGuestMessage}`,
            handleValidateSecGuest,
          ),
      }),

    email: Yup.string()
      .email('Digite um e-mail válido')
      .when('isPrincipal', {
        is: true,
        then: Yup.string()
          .email('Digite um e-mail válido')
          .required('O e-mail é obrigatório, pois esse é o hóspede principal'),
      })
      .when('isPrincipal', {
        is: false,
        then: Yup.string()
          .test(
            'email',
            `O email${secGuestMessage}`,
            handleValidateSecGuest,
          ),
      }),

    phone: Yup.string()
      // valida para não enviar vazio
      .test(
        'phone', 'O telefone é obrigatório', (value) => value !== '',
      )

      // valida para o hóspede principal
      .when('isPrincipal', {
        is: true,
        then: Yup.string()
          .test(
            'phone', 'O telefone é obrigatório', (value) => value !== '',
          )
          .required('O telefone é obrigatório, pois esse é o hóspede principal'),
      })

      // valida para o hóspede secundário
      .when('isPrincipal', {
        is: false,
        then: Yup.string()
          .test(
            'phone',
            `O telefone ${secGuestMessage}`,
            handleValidateSecGuest,
          ),
      }),

    front_document_photo: Yup.object()
      .when('isPrincipal', {
        is: true,
        then: Yup.object()
          .test(
            'hasName',
            'A foto da frente do documento é obrigatória, pois você é o hóspede principal',
            (value) => {
              const { name } = value || {};
              return Yup.string().required().isValidSync(name);
            },
          ),
      })
      // valida para o hóspede secundário
      .when('isPrincipal', {
        is: false,
        then: Yup.object()
          .test(
            'document-property-type',
            `A parte da frente do seu documento${secGuestMessage}`,
            (value) => {
              const propertyType = info?.property?.property_type;

              if (validateSecGuestFields(propertyType, 'front_document_photo' as FormikFields)) {
                return true; // Campo opcional
              }

              const { name } = value || {};
              return Yup.string().required().isValidSync(name);
            },
          ),
      }),

    back_document_photo: Yup.object()
      .when('isPrincipal', {
        is: true,
        then: Yup.object()
          .test(
            'hasName',
            'A foto da parte de trás do seu documento, pois você é o hóspede principal',
            (value) => {
              const { name } = value || {};
              return Yup.string().required().isValidSync(name);
            },
          ),
      })
      // valida para o hóspede secundário
      .when('isPrincipal', {
        is: false,
        then: Yup.object()
          .test(
            'document-property-type',
            `A foto da parte de trás do seu documento${secGuestMessage}`,
            (value) => {
              const propertyType = info?.property?.property_type;

              if (validateSecGuestFields(propertyType, 'back_document_photo' as FormikFields)) {
                return true; // Campo opcional
              }

              const { name } = value || {};
              return Yup.string().required().isValidSync(name);
            },
          ),
      }),
  });
};
