import styled from 'styled-components';

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 1000;

  input[type="checkbox"] {
    background: transparent;
    border-radius: 2px;
    border: 1px solid var(--blue-100, #c2c8f1);
  }

  input[type="checkbox"]::before {
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  p {
    color: var(--gray-800, #394760);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;
