import styled from 'styled-components';
import { animationTime, shimmerAnimation } from './animationShimmer';

export const Container = styled.div`
  width: 29%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  `;

export const Content = styled.div`
  width: 97%;
  height: 93px;
  background: ${({ theme }) => theme.palette.grey._150.hex()};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;
  padding: 6px 0;
  padding-left: 5px;
`;

export const Text2 = styled.div`
  width: 80%;
  height: 30px;
  animation: ${animationTime}s ${shimmerAnimation} ease-in-out infinite;
`;

export const Text = styled.div`
  width: 40%;
  height: 10px;
  animation: ${animationTime}s ${shimmerAnimation} ease-in-out infinite;
`;

export const AmountPaid = styled.div`
  width: 25%;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${animationTime}s ${shimmerAnimation} ease-in-out infinite;
  border-radius: 0 8px 8px 0;
`;

export const MissingValues = styled.div`
  width: 25%;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${animationTime}s ${shimmerAnimation} ease-in-out infinite;
  border-radius: 0 12px 12px 0;
`;
