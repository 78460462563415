import styled from 'styled-components';
import { shimmerAnimation, animationTime } from '../animationShimmer';

export const Container = styled.div`
  position: fixed;
  left: 20;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100vw;
  height: 100vh;
`;

export const ContainerGrouped = styled.div`
  width: 185px;
  height: 100vh;
  `;

export const ContentGrouped = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  flex-direction: row;
`;

export const Grouped = styled.div`
  display: flex;
  height: 3rem;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  `;

export const ContainerProperty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px dashed ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const PropertyDailyPrices = styled.div`
  width: 80px;
  height: 30px;
  position: relative;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  margin: 1.2rem;
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
`;
