import styled from 'styled-components';

type OwnerPropertyStatus =
  | 'Active'
  | 'Inactive'
  | 'Onboarding'
  | 'Closed'
  | 'Signed_Contract';
type OwnerPropertyCondition = 'Available' | 'Blocked' | 'Rented';

export const PropertyStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const PropertyStatusCircle = styled.span<{ status: OwnerPropertyStatus, condition: OwnerPropertyCondition }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999999999px;
  background-color: ${({ status, condition }) => (status === 'Active' && condition === 'Rented') && '#0D4BD0'};
  background-color: ${({ status, condition }) => (status === 'Active' && condition === 'Blocked') && '#ED6A71'};
  background-color: ${({ status, condition }) => (status === 'Active' && condition === 'Available') && '#41B592'};
  background-color: ${({ status }) => (status === 'Onboarding') && '#FF9900'};
  background-color: ${({ status }) => (status === 'Inactive') && '#93A4C2'};

  @media (max-width: 900px) {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const PropertyStatusText = styled.p`
  color: #394760;
  font-weight: 500;
  line-height: 20px;
`;

export const PropertyStatusDate = styled.p`
  line-height: 20px;
  color: #6D6D6D;
`;
