import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import star from '../../../assets/icons/generals/star.svg';
import { ArrowDropDown } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import NFicon from '../../../assets/icons/generals/NFicon.svg';
import iconBack from '../../../assets/icons/generals/arrowBack.svg';
import ChartBar from '../../../assets/icons/generals/chartbar.svg';
import editDataIcon from '../../../assets/icons/generals/edit.svg';
import guestDamageIcon from '../../../assets/icons/guestDamage/guestDamageIcon.svg';
import FinancialFeedbackIMG from '../../../assets/icons/generals/financialFeedback.svg';
import iconCalendar from '../../../assets/icons/generals/iconCalendar.svg';
import iconClose from '../../../assets/icons/generals/iconClose.svg';
import iconController from '../../../assets/icons/generals/iconControl.svg';
import iconExpenditure from '../../../assets/icons/generals/iconExpenditure.svg';
import iconPainel from '../../../assets/icons/generals/iconPainel.svg';
import iconProperties from '../../../assets/icons/generals/iconProperties.svg';
import insertDataIcon from '../../../assets/icons/generals/insertDataIcon.svg';
import linksIcon from '../../../assets/icons/generals/linksIcon.svg';
import seazoneHouseLogo from '../../../assets/icons/generals/logo-house-white.svg';
import logoImg from '../../../assets/icons/generals/logoFullWhite.svg';
import menuOpenButton from '../../../assets/icons/generals/menuOpenButton.svg';
import iconProfile from '../../../assets/icons/generals/person.svg';
import iconProfileMenu from '../../../assets/icons/generals/person-menu.svg';
import addGuest from '../../../assets/icons/generals/addGuest-menu.svg';
import reservationVoucher from '../../../assets/icons/generals/reservationVoucher.svg';
import paymentVoucherIcon from '../../../assets/icons/paymentVoucher/paymentVoucher.svg';
import { ProfileLogout } from '../../Header/Header';
import {
  CloseButton,
  Container,
  ContainerLogoSeazone,
  ContainerLogoSeazoneHouse,
  Content,
  ContentHeader,
  ContentInfo,
  Divider,
  EndContainer,
  FinanceOptionsContainer,
  Image,
  ImageContainer,
  ImageResized,
  NameContainer,
  NavBar,
  NavItem,
  Profile,
  ProfileContainer,
  ProfileLogoutRetractContainer,
  ProfileRetract,
  RecoverButton,
  ResetMaxSizeButton,
  SVGContainer,
  Title,
  TitleContainer,
} from './styles';

import iconEstimate from '../../../assets/icons/generals/iconEstimate.svg';
import { partnersIconSVG } from '../../../assets/icons/partners';
import { useMenuLateral } from '../../../context/MenuLateral';
import { useUser } from '../../../context/UserContext';
import { translateRole } from '../../../utils/Translator';
import Tooltip from '../../Tooltip';
import { getMainRoute } from '../../../utils/Routes';

interface Props {
  openMenu?: boolean;
  setOpenMenu?: (a: boolean) => void;
  setIsOpen?: (a: boolean) => void;
}

const MenuLateral = ({ openMenu, setOpenMenu, setIsOpen }: Props) => {
  const [openMenuLateral, setOpenMenuLateral] = useState(false);
  const { setOpen } = useMenuLateral();
  const [active, setActive] = useState('');
  const [retractMenu, setRetractMenu] = useState(true);
  const [financialCloseOption, setFinancialCloseOption] = useState<string>('Fechamento');
  const [openFinancialCloseOptions, setOpenFinancialCloseOptions] = useState<boolean>(['/fechamentoanfitriao',
    '/fechamentoproprietario', '/fechamentoimovel'].includes(window.location.pathname));

  const navigate = useNavigate();

  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  const femaleRole = (role: string) => {
    if (role === 'Host') {
      return 'Anfitriã';
    } if (role === 'Admin') {
      return 'Administradora';
    }
    return 'Atendente';
  };

  const mainRole = userInformation?.gender === 'Male' || userInformation?.gender === 'Not informed' ? `${translateRole(userInformation?.main_role || '')}` : femaleRole(userInformation?.main_role || '');

  const handleRetractMenu = () => {
    setRetractMenu(true);
    setOpen(true);
    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  const handleResetMenuSize = () => {
    setRetractMenu(false);
    setOpen(false);
    if (setIsOpen) {
      setIsOpen(true);
    }
  };

  const handleGetActualRoute = () => {
    const actualRoute = window.location.pathname;
    setActive(actualRoute);
  };

  useEffect(() => {
    handleGetActualRoute();
    setOpen(retractMenu);
  }, []);

  const shouldBeVisibleTo = (permissions: string[], verify_host_ops = false) => {
    const permission = permissions
      .find((role) => roles.includes(role));

    if (permission === 'Host' && verify_host_ops) {
      if (!userInformation?.host?.is_host_ops) {
        return true;
      }
      return false;
    }

    return !!permission;
  };

  const getFinancialCloseRoute: Record<string, string> = {
    Anfitrião: '/fechamentoanfitriao',
    Imóvel: '/fechamentoimovel',
    Proprietário: '/fechamentoproprietario',
  };

  useEffect(() => {
    if (financialCloseOption !== 'Fechamento') {
      navigate(getFinancialCloseRoute[financialCloseOption]);
    }
  }, [financialCloseOption]);

  useEffect(() => {
    setRetractMenu(true);
  }, [active]);

  const handleClickFinancialCloseOption = () => {
    setActive(getFinancialCloseRoute[financialCloseOption]);
    setOpenFinancialCloseOptions(!openFinancialCloseOptions);
  };

  const handleRedirectToRole = () => {
    if (userInformation?.main_role) {
      setActive(getMainRoute[userInformation?.main_role]);
      return getMainRoute[userInformation?.main_role];
    }
    setActive(getMainRoute[userInformation?.roles[0] || '']);
    return getMainRoute[userInformation?.roles[0] || ''];
  };

  return (
    <>
      <Container
        shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative', 'Attendant', 'Host'])}
        retractMenu={retractMenu}
        openMenu={openMenu || openMenuLateral}
      >
        <Content>
          <ContentHeader>
            {retractMenu && (
            <ContainerLogoSeazoneHouse>
              <Image className="house" src={seazoneHouseLogo} onClick={() => navigate(handleRedirectToRole())} alt="Logo Seazone" />
            </ContainerLogoSeazoneHouse>
            )}

            {!retractMenu && (
            <ContainerLogoSeazone>
              <Image src={logoImg} onClick={() => navigate(handleRedirectToRole())} alt="Logo Seazone" />
            </ContainerLogoSeazone>
            )}

            <CloseButton
              onClick={() => (setOpenMenu ? setOpenMenu(false) : setOpenMenuLateral(false))}
              src={iconClose}
              alt="botão fechar"
            />
          </ContentHeader>

          <Divider isRetract={retractMenu} />

          {!retractMenu && (
          <RecoverButton onClick={() => setRetractMenu(true)}>
            <img src={iconBack} alt="botão voltar" />
          </RecoverButton>
          )}

          {retractMenu && (
          <ResetMaxSizeButton onClick={() => setRetractMenu(false)}>
            <img src={menuOpenButton} alt="botão voltar" />
          </ResetMaxSizeButton>
          )}

          <NavBar>
            <NavItem
              to="/controle"
              onClick={() => setActive('/controle')}
              className={active === '/controle' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Host'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Controle">
                    <Image src={iconController} alt="menu controle" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconController} alt="menu controle" />
                    <Title>Controle</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/dashboard"
              onClick={() => setActive('/dashboard')}
              className={active === '/dashboard' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Host'], true)}
            >
              {retractMenu

                ? (
                  <Tooltip text="Dashboard">
                    <Image src={iconPainel} alt="menu dashboard" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconPainel} alt="menu dashboard" />
                    <Title>Dashboard</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/multicalendar"
              onClick={() => setActive('/multicalendar')}
              className={active === '/multicalendar' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative', 'Attendant', 'Host'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Multicalendar">
                    <Image src={iconCalendar} alt="menu calendário" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconCalendar} alt="menu calendário" />
                    <Title>Multicalendar</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/buscadepropriedades"
              onClick={() => setActive('/buscadepropriedades')}
              className={active === '/buscadepropriedades' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Attendant'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Busca de propriedades">
                    <SVGContainer>
                      <SearchIcon />
                    </SVGContainer>
                  </Tooltip>
                )
                : (
                  <>
                    <SVGContainer>
                      <SearchIcon />
                    </SVGContainer>
                    <Title>Busca de Propriedades</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/propriedades"
              onClick={() => setActive('/propriedades')}
              className={active === '/propriedades' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Host', 'SeazoneOnboarding', 'Attendant'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Propriedades">
                    <Image src={iconProperties} alt="menu propriedades" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconProperties} alt="menu propriedades" />
                    <Title>Propriedades</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/despesas"
              onClick={() => setActive('/despesas')}
              className={active === '/despesas' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding'], true)}
            >
              {retractMenu

                ? (
                  <Tooltip text="Despesas">
                    <Image src={iconExpenditure} alt="menu despesas" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconExpenditure} alt="menu despesas" />
                    <Title>Despesas</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/parceiros/painel"
              onClick={() => setActive('/parceiros/painel')}
              className={active === '/parceiros/painel' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Partner'], true)}
            >
              {retractMenu ? (
                <>
                  <Tooltip text="Parceiros">
                    <Image src={partnersIconSVG} alt="menu dos parceiros" />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Image src={partnersIconSVG} alt="menu dos parceiros" />
                  <Title>Parceiros</Title>
                </>
              )}
            </NavItem>

            <NavItem
              to="/reembolso"
              onClick={() => setActive('/reembolso')}
              className={active === '/reembolso' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Host'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Pedido de Reembolso">
                    <Image src={iconExpenditure} alt="menu reembolso" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconExpenditure} alt="menu reembolso" />
                    <Title>Pedido de Reembolso</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/listadenfs"
              onClick={() => setActive('/listadenfs')}
              className={active === '/listadenfs' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Lista de Notas Fiscais">
                    <ImageResized src={NFicon} alt="menu lista de notas fiscais" />
                  </Tooltip>
                )
                : (
                  <>
                    <ImageResized src={NFicon} alt="menu lista de notas fiscais" />
                    <Title>Lista de NFs</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/listaderepassesproprietario"
              onClick={() => setActive('/listaderepassesproprietario')}
              className={active === '/listaderepassesproprietario' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
            >
              <ImageResized src={iconProfileMenu} alt="menu lista de ted" />
              {!retractMenu && (
              <Title>
                Lista de repasses p/
                <br />
                Proprietário
              </Title>
              )}
            </NavItem>

            <NavItem
              to="/listaderepassesanfitriao"
              onClick={() => setActive('/listaderepassesanfitriao')}
              className={active === '/listaderepassesanfitriao' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
            >
              <ImageResized src={iconProfileMenu} alt="menu lista de ted" />
              {!retractMenu && (
              <Title>
                Lista de repasses p/
                <br />
                Anfitrião
              </Title>
              )}
            </NavItem>

            <NavItem
              to="/links"
              onClick={() => setActive('/links')}
              className={active === '/links' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Host'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Links">
                    <Image src={linksIcon} alt="links" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={linksIcon} alt="links" />
                    <Title>Links</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/orcamento"
              onClick={() => setActive('/estimate')}
              className={active === '/estimate' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Attendant'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Orçamento Mensal">
                    <Image src={iconEstimate} alt="menu orcamento" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconEstimate} alt="menu orcamento" />
                    <Title>Orçamento Mensal</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/conciliacao"
              onClick={() => setActive('/conciliacao')}
              className={active === '/conciliacao' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Conciliação">
                    <Image src={iconProperties} alt="menu conciliação" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconProperties} alt="menu conciliação" />
                    <Title>Conciliação</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/compreservas"
              onClick={() => setActive('/compreservas')}
              className={active === '/compreservas' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Attendant'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Completar Reservas">
                    <Image src={reservationVoucher} alt="menu despesas" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={reservationVoucher} alt="menu despesas" />
                    <Title>Completar Reservas</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/comprovantespagamento"
              onClick={() => setActive('/comprovantespagamento')}
              className={active === '/comprovantespagamento' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Attendant'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Comprovantes de Pagamento">
                    <Image src={paymentVoucherIcon} alt="menu despesas" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={paymentVoucherIcon} alt="menu despesas" />
                    <Title>Comprovantes de Pagamento</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/vouchersenviados"
              onClick={() => setActive('/vouchersenviados')}
              className={active === '/vouchersenviados' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'Attendant'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Vouchers enviados">
                    <Image src={paymentVoucherIcon} alt="menu despesas" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={paymentVoucherIcon} alt="menu despesas" />
                    <Title>Vouchers enviados</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to={window.location.pathname}
              onClick={() => handleClickFinancialCloseOption()}
              className={active === getFinancialCloseRoute[financialCloseOption] ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Fechamento">
                    <Image src={iconExpenditure} alt="menu fechamento" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconExpenditure} alt="menu fechamento" />
                    <TitleContainer>
                      <Title>Fechamento</Title>
                      <ArrowDropDown />
                    </TitleContainer>
                  </>
                )}
            </NavItem>

            {openFinancialCloseOptions && (
            <FinanceOptionsContainer>
              <NavItem
                to="/fechamentoanfitriao"
                onClick={() => setActive('/fechamentoanfitriao')}
                className={active === '/fechamentoanfitriao' ? 'active' : ''}
                shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
              >
                <div />
                {retractMenu

                  ? (
                    <Tooltip text="Anfitrião">
                      <Title onClick={() => setFinancialCloseOption('Anfitrião')} retractMenu={retractMenu}>
                        Anf...
                      </Title>

                    </Tooltip>
                  )
                  : (
                    <Title onClick={() => setFinancialCloseOption('Anfitrião')} retractMenu={retractMenu}>
                      Anfitrião
                    </Title>
                  )}
              </NavItem>

              <NavItem
                to="/fechamentoproprietario"
                onClick={() => setActive('/fechamentoproprietario')}
                className={active === '/fechamentoproprietario' ? 'active' : ''}
                shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
              >
                <div />
                {retractMenu

                  ? (
                    <Tooltip text="Proprietário">
                      <Title onClick={() => setFinancialCloseOption('Proprietário')} retractMenu={retractMenu}>
                        Prop...
                      </Title>

                    </Tooltip>
                  )
                  : (
                    <Title onClick={() => setFinancialCloseOption('Proprietário')} retractMenu={retractMenu}>
                      Proprietário
                    </Title>
                  )}
              </NavItem>

              <NavItem
                to="/fechamentoimovel"
                onClick={() => setActive('/fechamentoimovel')}
                className={active === '/fechamentoimovel' ? 'active' : ''}
                shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
              >
                <div />
                {retractMenu

                  ? (
                    <Tooltip text="Imóvel">
                      <Title onClick={() => setFinancialCloseOption('Imóvel')} retractMenu={retractMenu}>
                        Imó...
                      </Title>

                    </Tooltip>
                  )
                  : (
                    <Title onClick={() => setFinancialCloseOption('Imóvel')} retractMenu={retractMenu}>
                      Imóvel
                    </Title>
                  )}
              </NavItem>
            </FinanceOptionsContainer>

            )}

            <NavItem
              to="/gabaritofinanceiro"
              onClick={() => setActive('/gabaritofinanceiro')}
              className={active === '/gabaritofinanceiro' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Gabarito">
                    <Image src={FinancialFeedbackIMG} alt="menu gabarito" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={FinancialFeedbackIMG} alt="menu gabarito" />
                    <Title>Gabarito</Title>
                  </>
                )}
            </NavItem>

            <NavItem
              to="/onboarding"
              onClick={() => setActive('/onboarding')}
              className={active === '/onboarding' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneOnboarding'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Onboarding">
                    <Image src={ChartBar} alt="menu onboarding" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={ChartBar} alt="menu onboarding" />
                    <Title>Onboarding</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/inserirdados"
              onClick={() => setActive('/inserirdados')}
              className={active === '/inserirdados' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneOnboarding'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Inserir Dados">
                    <Image src={insertDataIcon} alt="menu onboarding" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={insertDataIcon} alt="menu onboarding" />
                    <Title>Inserir Dados</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/painel-perspectiva-do-proprietario"
              onClick={() => setActive('/painel-perspectiva-do-proprietario')}
              className={active === '/painel-perspectiva-do-proprietario' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="painel-perspectiva-do-proprietario">
                    <Image src={iconPainel} alt="menu onboarding" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconPainel} alt="menu onboarding" />
                    <Title>Perspectiva do proprietario</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/editardados"
              onClick={() => setActive('/editardados')}
              className={active === '/editardados' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneOnboarding'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Editar Dados">
                    <Image src={editDataIcon} alt="menu onboarding" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={editDataIcon} alt="menu onboarding" />
                    <Title>Editar Dados</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/anfitrioes"
              onClick={() => setActive('/anfitrioes')}
              className={active === '/anfitrioes' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneOnboarding'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Anfitriões">
                    <Image src={iconProfileMenu} alt="menu anfitriões" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={iconProfileMenu} alt="menu anfitriões" />
                    <Title>Anfitriões</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/proprietarios"
              onClick={() => setActive('/proprietarios')}
              className={active === '/proprietarios' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneOnboarding'])}
            >
              {retractMenu

                ? (
                  <Tooltip text="Proprietários">
                    <Image src={addGuest} alt="menu proprietários" />
                  </Tooltip>
                )
                : (
                  <>
                    <Image src={addGuest} alt="menu proprietários" />
                    <Title>Proprietários</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/danosdehospede"
              onClick={() => setActive('/danosdehospede')}
              className={active === '/danosdehospede' ? 'active' : ''}
              shouldBeVisible={
                shouldBeVisibleTo(['Admin', 'Attendant', 'Host', 'SeazoneAdministrative', 'SeazoneOnboarding'])
              }
            >
              {retractMenu

                ? (
                  <Tooltip text="Danos de hóspede">
                    <ImageContainer>
                      <Image src={guestDamageIcon} alt="menu danos de hóspede" />
                    </ImageContainer>
                  </Tooltip>
                )
                : (
                  <>
                    <ImageContainer>
                      <Image src={guestDamageIcon} alt="menu danos de hóspede" />
                    </ImageContainer>
                    <Title>Danos de hóspede</Title>
                  </>
                )}
            </NavItem>
            <NavItem
              to="/painelgerenciamento"
              onClick={() => setActive('/painelgerenciamento')}
              className={active === '/painelgerenciamento' ? 'active' : ''}
              shouldBeVisible={shouldBeVisibleTo(['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative', 'Attendant'])}
            >
              {retractMenu
                ? (
                  <Tooltip text="Painel de gerenciamento">
                    <Image src={editDataIcon} alt="menu onboarding" />
                  </Tooltip>
                )
                : (
                  <>
                    <SVGContainer>
                      <SystemUpdateAltIcon />
                    </SVGContainer>
                    <Title>
                      Painel de
                      <br />
                      gerenciamento

                    </Title>
                  </>
                )}
            </NavItem>
          </NavBar>

          {!retractMenu && (
          <EndContainer>
            <Profile>
              <ContentInfo>
                <ProfileContainer>
                  <img src={iconProfile} alt="imagem de perfil" />
                  <NameContainer>
                    <p>
                      {userInformation?.nickname}
                    </p>
                    <span>{mainRole}</span>
                  </NameContainer>
                  <ProfileLogout color="white" useOnlyArrow />
                  {/* <img src={star} alt="estrela de avaliação" />
              <span>6.67</span> */}
                </ProfileContainer>
              </ContentInfo>
            </Profile>
          </EndContainer>
          )}

          {retractMenu && (
          <ProfileRetract>
            <ProfileLogoutRetractContainer>
              <img src={iconProfile} alt="imagem de perfil" />
              <ProfileLogout className="logoutRetractMenu" color="white" useOnlyArrow />
            </ProfileLogoutRetractContainer>
            <ContentInfo>
              <p>
                {userInformation?.nickname}
              </p>
              <span>{mainRole}</span>
              {/* <img src={star} alt="estrela de avaliação" />
          <span>6.67</span> */}
            </ContentInfo>
          </ProfileRetract>
          )}
        </Content>
      </Container>

    </>
  );
};

MenuLateral.defaultProps = {
  openMenu: false,
  setOpenMenu: () => {},
  setIsOpen: () => {},
};

export default MenuLateral;
