import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fadeIn } from '../style';

export const Container = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const CardContent = styled.div`
  border-radius: 30px;
  width: 100%;
  padding: 1rem;
  background: var(--blue-50, #e7e9fa);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardTitle = styled.div`
  opacity: 0;
  animation: 0.5s 0.6s ease ${fadeIn} forwards;

  color: var(--darkk-blue-900, #001840);
  font-family: Inter;
  text-align: center;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 145.455% */
`;

export const Description = styled.div`
  opacity: 0;
  animation: 0.5s 0.8s ease ${fadeIn} forwards;

  color: var(--darkk-blue-900, #001840);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const Title = styled.h1`
  opacity: 0;
  animation: 0.5s 0.2s ${fadeIn} forwards;

  color: var(--darkk-blue-900, #001840);
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    max-height: 300px;
  }

  svg {
    width: 100%;
    height: 100%;
    max-height: 250px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  button {
    width: 100%;
  }
`;

type IB = {
  variant?: string;
};
export const Button = styled(motion.button)<IB>`
  display: flex;
  padding: 13px 50px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  color: var(--branco, #fff);
  font-family: Inter;
  font-weight: 700;
  cursor: pointer;
  background: #0d4bd0;
  border: 2px solid transparent;


  ${({ variant }) => (variant === 'outline' ? `
      background: transparent;
      border: 2px solid #042675;
      color: #042675;
    ` : `
      background: #0d4bd0;
      border: 2px solid transparent;
    `)
}

`;
