import { useUser } from '../../../../../context/UserContext';
import {
  PropertyBody,
  PropertyItem,
  PropertyCode,
  PropertyTransfer,
} from '../style';

import Switcher from '../Switcher/Switcher';

interface ItemProps {
  code: string;
  active: boolean;
  hideSwitch: boolean,
  onChange: Function;
}

const Item = ({
  code,
  active,
  hideSwitch,
  onChange,
}: ItemProps) => (
  <PropertyItem>
    <PropertyCode>{code}</PropertyCode>
    <PropertyTransfer>
      {hideSwitch ? (
        <span>{active ? 'Sim' : 'Não'}</span>
      ) : (
        <Switcher onChange={onChange} active={active} />
      )}
    </PropertyTransfer>
  </PropertyItem>
);

interface IPropertyTable {
  onChange: Function;
  hideSwitch: boolean;
}

const PropertyTable = ({ onChange, hideSwitch = false }: IPropertyTable) => {
  const { propertiesOwnerLogged } = useUser();

  return (
    <PropertyBody>
      {propertiesOwnerLogged?.map((item) => (
        <Item
          key={item.id}
          code={item.code}
          onChange={() => onChange(item.id, item.is_to_keep_funds_in_seazone)}
          active={item.is_to_keep_funds_in_seazone}
          hideSwitch={hideSwitch}
        />
      ))}
    </PropertyBody>
  );
};

export default PropertyTable;
