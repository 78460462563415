import { useContextSelector } from 'use-context-selector';
import { ReservationsContext } from '../../../context/GuestDamage/ReservationsContext';

export function useReservations() {
  const reservations = useContextSelector(ReservationsContext, (state) => state
    .reservations);
  const setReservations = useContextSelector(ReservationsContext,
    (state) => state.setReservations);
  const selectedReservation = useContextSelector(ReservationsContext, (state) => state
    .selectedReservation);
  const setSelectedReservation = useContextSelector(ReservationsContext,
    (state) => state.setSelectedReservation);
  const isLoading = useContextSelector(ReservationsContext, (state) => state
    .isLoading);
  const setIsLoading = useContextSelector(ReservationsContext,
    (state) => state.setIsLoading);

  return {
    reservations,
    setReservations,
    selectedReservation,
    setSelectedReservation,
    isLoading,
    setIsLoading,
  };
}
