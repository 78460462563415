import React from 'react';
import Lottie from 'lottie-react';

import confirmEmailAnimation from '../source/confirmationEmailAnimation.json';

export const ConfirmationEmailAnimation = () => (
  <Lottie
    animationData={confirmEmailAnimation}
  />
);
