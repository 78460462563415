/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { Text } from '../../Text';
import { IconWrapper, ItemContainer, TextContainer } from './styles';

import airbnbLogo from '../../../assets/icons/otas/airbnbLogo.svg';
import bookingLogo from '../../../assets/icons/otas/bookingLogo.svg';
import expediaLogo from '../../../assets/icons/otas/expediaLogo.svg';
import homeawayLogo from '../../../assets/icons/otas/homeawayLogo.svg';
import temporadalivreLogo from '../../../assets/icons/otas/temporadalivreLogo.svg';
import testadorLogo from '../../../assets/icons/otas/testadorLogo.svg';
import staysLogo from '../../../assets/icons/otas/staysLogo.svg';
import websiteLogo from '../../../assets/icons/otas/websiteLogo.svg';
import seazoneLogo from '../../../assets/icons/otas/seazoneLogo.svg';
import decolarLogo from '../../../assets/icons/otas/decolarLogo.svg';

import blockCleaning from '../../../assets/icons/multicalendar/blockCleaning.svg';
import blockHost from '../../../assets/icons/multicalendar/blockHost.svg';
import blockMaintenance from '../../../assets/icons/multicalendar/blockMaintenance.svg';
import blockOwner from '../../../assets/icons/multicalendar/blockOwner.svg';
import blockPreparation from '../../../assets/icons/multicalendar/blockPreparation.svg';
import { numberToCurrency } from '../../../utils/Formatter';
import { translateBlockingReason } from '../../../utils/Translator';

const ImageOta: any = {
  airbnb: <img src={airbnbLogo} alt={''} />,
  booking: <img src={bookingLogo} alt={''} />,
  expedia: <img src={expediaLogo} alt={''} />,
  homeaway: <img src={homeawayLogo} alt={''} />,
  stays: <img src={staysLogo} alt={''} />,
  temporadalivre: <img src={temporadalivreLogo} alt={''} />,
  contrato: <img src={seazoneLogo} alt={''} />,
  website: <img src={websiteLogo} alt={''} />,
  testador: <img src={testadorLogo} alt={''} />,
  decolar: <img src={decolarLogo} alt={''} />,
};

const ImageBlock: any = {
  Cleaning: <img src={blockCleaning} alt="imagem do bloqueio de limpeza" />,
  Host: <img src={blockHost} alt="imagem do bloqueio de anfitrião" />,
  Maintenance: <img src={blockMaintenance} alt="imagem do bloqueio de manutenção" />,
  'Owner use': <img src={blockOwner} alt="imagem do bloqueio de proprietário" />,
  Preparation: <img src={blockPreparation} alt="imagem do bloqueio por tempo de preparação" />,
};

const ItemRenderer = ({
  item, getItemProps, onClick,
}: any) => {
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);

  const handleTouchStart = (e: any) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e: any) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchEndY = e.changedTouches[0].clientY;
    const deltaX = Math.abs(touchEndX - touchStartX);
    const deltaY = Math.abs(touchEndY - touchStartY);

    if (deltaX === 0 && deltaY === 0) {
      onClick();
    }
  };

  return (
    <ItemContainer
      onClick={onClick}
      $status={item.status}
      $blocked={item.is_blocking}
      $reason={item.blocking_reason}
      $isBookingOrBlockAutomatic={
      item.is_last_minute
      || item.is_block_for_pricing
}
      {...getItemProps()}
      onTouchStart={(e: any) => handleTouchStart(e)}
      onTouchEnd={(e: any) => handleTouchEnd(e)}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconWrapper>
          {!item.is_blocking
            ? ImageOta[item.icon?.toLowerCase()] : ImageBlock[item.blocking_reason]}
        </IconWrapper>

        <TextContainer height={42} style={{ flex: 1, overflow: 'hidden' }}>
          <Text
            $color="#FBFBFB"
            fontSize="12"
            $ellipsis
          >
            {item.blocking_reason === 'Preparation' ? 'Tempo de Preparo'
              : item.is_blocking && !item.is_last_minute && !item.is_block_for_pricing ? `Bloqueio - ${translateBlockingReason(item.blocking_reason)}` : item.title}
          </Text>
          <Text $color="#FBFBFB" fontSize="12" $ellipsis>
            {item.is_blocking && !item.is_last_minute
      && !item.is_block_for_pricing ? '' : numberToCurrency(item.price)}

          </Text>
        </TextContainer>
      </div>
    </ItemContainer>
  );
};

export default ItemRenderer;
