/* eslint-disable no-nested-ternary */
import React, { FC, ReactElement, useState } from 'react';

import { Lock } from 'react-feather';

import moment from 'moment';
import { RequestOta } from '../../../../../services/Property/types';
import { BlockingReason, Reservation } from '../../../../../services/Reservation/types';
import { translateBlockingReason } from '../../../../../utils/Translator';
import { formatedDateToShow } from '../../../../../utils/Formatter';

import {
  ContainerBox,
  HeaderContainer,
  Title,
  ButtonCanceled,
} from '../styles';

import ModalTemplate from '../../ModalTemplate';

import airbnbLogo from '../../../../../assets/icons/otas/airbnbLogo.svg';
import bookingLogo from '../../../../../assets/icons/otas/bookingLogo.svg';
import expediaLogo from '../../../../../assets/icons/otas/expediaLogo.svg';
import homeawayLogo from '../../../../../assets/icons/otas/homeawayLogo.svg';
import temporadalivreLogo from '../../../../../assets/icons/otas/temporadalivreLogo.svg';
import testadorLogo from '../../../../../assets/icons/otas/testadorLogo.svg';
import staysLogo from '../../../../../assets/icons/otas/staysLogo.svg';
import websiteLogo from '../../../../../assets/icons/otas/websiteLogo.svg';
import seazoneLogo from '../../../../../assets/icons/otas/seazoneLogo.svg';
import decolarLogo from '../../../../../assets/icons/otas/decolarLogo.svg';

import ModalCancelLock from '../../ModalCancelLock';
import { useViewMode } from '../../../../../context/ViewMode/ViewMode';
import CloseSVG from '../../../../IconsComponents/Close';
import {
  NewItem,
  NewItemTitle,
  NewContainer,
  NewContentBox,
  NewItemWithLine,
  NewHorizontalRow,
  NewItemDescription,
} from '../newStyle';

interface Props {
  id: number;
  onClose: Function;
  open?: boolean;
  guestName?: string;
  petIndicator?: boolean;
  adultGuests?: number;
  childrenGuests?: number;
  blocked?: boolean;
  startDate?: string;
  endDate?: string;
  reason: BlockingReason | string;
  ota: RequestOta;
  totalPrice?: number;
  notes?: string;
  reservation: Reservation;
  dailyNetValue: number;
}

const ImageOta: Record<string, ReactElement> = {
  BLK: <Lock className="lockLogo" />,
  AIR: <img src={airbnbLogo} alt="Airbnb Logo" />,
  BOO: <img src={bookingLogo} alt="Booking Logo" />,
  EXP: <img src={expediaLogo} alt="Expedia Logo" />,
  HOM: <img src={homeawayLogo} alt="HomeAway Logo" />,
  STA: <img src={staysLogo} alt="Stays Logo" />,
  CON: <img src={seazoneLogo} alt="Seazone Logo" />,
  WEB: <img src={websiteLogo} alt="Website Logo" />,
  TES: <img src={testadorLogo} alt="Testador Logo" />,
  DEC: <img src={decolarLogo} alt="Decolar Logo" />,
  TEM: <img src={temporadalivreLogo} alt="TemporadaLivre Logo" />,
};

const ModalReservationBlocked: FC<Props> = ({
  id,
  ota,
  notes,
  reason,
  onClose,
  reservation,
  open = false,
  blocked = false,
  endDate = 'dd/mm/yyyy',
  startDate = 'dd/mm/yyyy',
}) => {
  const { isViewMode } = useViewMode();
  const [openModalLock, setOpenModalLock] = useState<boolean>(false);

  function numberOfDaily(checkin: string, checkout: string) {
    const total = moment(checkout, 'DD/MM/YYYY').diff(moment(checkin, 'DD/MM/YYYY'), 'day');
    return total;
  }

  return (
    <>
      <ModalTemplate
        open={open}
        handleClose={onClose}
      >
        <ContainerBox>
          <HeaderContainer isBlocked={blocked}>
            <Title blocked={blocked}>
              {reason === 'Preparation' ? 'Tempo de Preparo' : 'Bloqueado'}
            </Title>
            <CloseSVG
              size={20}
              id="close-btn"
              onClick={() => onClose(false)}
            />
          </HeaderContainer>

          <NewContainer>
            <NewContentBox blocked>
              <NewItemWithLine>
                <NewItem>
                  <NewItemTitle>{reason === 'Preparation' ? 'Motivo' : 'Motivo do bloqueio'}</NewItemTitle>
                  <NewItemDescription>
                    {reason === 'Preparation' ? 'Preparação da reserva' : translateBlockingReason(reason)}
                  </NewItemDescription>
                </NewItem>

                <NewHorizontalRow />

                <NewItem>
                  <NewItemTitle>
                    {reason === 'Preparation' ? 'Data' : 'Data do bloqueio'}
                  </NewItemTitle>
                  <NewItemDescription>
                    {formatedDateToShow(startDate)}
                    {' '}
                    -
                    {' '}
                    {formatedDateToShow(endDate)}
                  </NewItemDescription>
                </NewItem>

                <NewHorizontalRow />

                {reason !== 'Preparation' && (
                  <>
                    <NewItem>
                      <NewItemTitle>
                        Quantidade de diárias
                      </NewItemTitle>
                      <NewItemDescription>
                        {numberOfDaily(moment(startDate).format('DD/MM/YYYY'), moment(endDate).format('DD/MM/YYYY'))}
                      </NewItemDescription>
                    </NewItem>

                    <NewHorizontalRow />

                    <NewItem>
                      <NewItemTitle>Plataforma</NewItemTitle>
                      <NewItemDescription>
                        {ImageOta[ota.initials.toUpperCase().trim()]}
                      </NewItemDescription>
                    </NewItem>
                  </>
                )}
              </NewItemWithLine>

              {reason !== 'Preparation' && (
                <>
                  <NewHorizontalRow />

                  <NewItem>
                    <NewItemTitle>Observação</NewItemTitle>
                    <NewItemDescription>{notes && notes?.length > 2 ? notes : 'Nenhuma observação'}</NewItemDescription>
                  </NewItem>
                </>
              )}
            </NewContentBox>

            {!isViewMode && reason !== 'Preparation' && (
              <NewItem>
                <ButtonCanceled data-cy="btn-cancel-block" type="button" onClick={() => { onClose(); setOpenModalLock(true); }}>Cancelar bloqueio</ButtonCanceled>
              </NewItem>
            )}
          </NewContainer>
        </ContainerBox>
      </ModalTemplate>

      {openModalLock && (
        <ModalCancelLock
          id={id}
          reservation={reservation}
          onClose={() => setOpenModalLock(false)}
          open={openModalLock}
        />
      )}
    </>
  );
};

export default ModalReservationBlocked;
