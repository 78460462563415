import { useState, useEffect, useMemo } from 'react';

import moment from 'moment';

import { PropertyOwner } from '../../../../services/Owner/types';
import { downloadOwnerExpensesCSV } from '../../../../services/Owner/OwnerReport/request';

import {
  itemHeightMobile,
  itemHeightDesktop,
  itemsVisibleInMobileBeforeScroll,
  itemsVisibleInDesktopBeforeScroll,
  MAX_SIZE_MOBILE,
} from '../utils';

import { useExpensesReport } from '../../../../hooks/OwnerPage/OwnerReport/useExpensesReport';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { useToast } from '../../../../context/ToastContext';
import { useUser } from '../../../../context/UserContext';
import { useViewMode } from '../../../../context/ViewMode/ViewMode';
import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';

import GridItem from './Grid/GridItem';
import GridItemShimmer from './Shimmer/GridItemShimmer';
import Filters from './Filters';
import DownloadButton from '../Buttons/DownloadButton';
import LoadingStepper from '../LoadingStepper';
import ExpensesModal from './Modal';

import { Header, Title } from '../styles';

import {
  Container,
  ScrollArea,
  NoDataFoundContainer,
  NoDataFoundGeneralContainer,
} from './styles';

interface IExpenses {
  propertiesList: PropertyOwner[];
}

const Expenses = ({ propertiesList }: IExpenses) => {
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { isMobile } = useScreenResize(MAX_SIZE_MOBILE);

  const {
    loading,
    noDataFound,
    ownerExpenses,
    startDate,
    endDate,
    expensesModal,
    propertyId,
  } = useExpensesReport();

  const { dispatchEvent } = useAnalytics();
  const toast = useToast();
  const { userInformation } = useUser();
  const { mode } = useViewMode();

  const ownerId = useMemo(() => (userInformation?.owner?.id || undefined), [userInformation]);
  const viewModeOwnerId = useMemo(() => mode?.user_info?.owner?.id || undefined, [mode]);

  const containerHeight = useMemo(() => {
    if (isMobile && ownerExpenses.length >= itemsVisibleInMobileBeforeScroll) {
      return `${(itemHeightMobile * itemsVisibleInMobileBeforeScroll) + (itemHeightMobile / 2)}px`;
    }

    if (!isMobile && ownerExpenses.length >= itemsVisibleInDesktopBeforeScroll) {
      return `${(itemHeightDesktop * itemsVisibleInDesktopBeforeScroll) + (itemHeightDesktop / 2)}px`;
    }

    return 'auto';
  }, [ownerExpenses]);

  const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');
  const formatPropertyId = (propId: string) => (!['', 'null', 'undefined'].includes(`${propId}`)
    ? propId : undefined);

  const handleDownloadExpenses = async () => {
    try {
      setIsDownloading(true);

      const params = {
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        owner_id: ownerId || viewModeOwnerId,
        property_id: formatPropertyId(`${propertyId}`),
      };

      const args = {
        params,
        setProgress: setDownloadProgress,
      };

      await downloadOwnerExpensesCSV(args);

      if (downloadProgress >= 100) {
        setTimeout(() => {
          setIsDownloading(false);
        }, 1500);
      }

      dispatchEvent({
        action: 'Baixou as despesas com sucesso',
      });
    } catch {
      dispatchEvent({
        action: 'Falhou em baixar as despesas',
      });
      setIsDownloading(false);
      toast.error('Algo deu errado, por favor tente novamente');
    }
  };

  const Grid = () => (
    <>
      {ownerExpenses.map((item) => (
        <GridItem key={item.id} expense={item} />
      ))}
    </>
  );

  const LoadingShimmer = () => {
    const shimmerArray = Array(4).fill(0);
    return (
      <>
        {shimmerArray.map(() => (
          <GridItemShimmer />
        ))}
      </>
    );
  };

  const NoDataFoundComponent = () => (
    <NoDataFoundGeneralContainer>
      <NoDataFoundContainer>
        <h3>Nenhuma despesa encontrada</h3>
      </NoDataFoundContainer>
    </NoDataFoundGeneralContainer>
  );

  useEffect(() => {
    if (downloadProgress >= 100) {
      setTimeout(() => {
        setIsDownloading(false);
      }, 1500);
    }
  }, [downloadProgress]);

  return (
    <Container>
      <Header className="header-expenses">
        <Title>Despesas</Title>
        <DownloadButton
          type="button"
          label="Baixar despesas"
          onClick={() => handleDownloadExpenses()}
          variantForMobile="secondary"
          variantForDesktop="secondary"
        />
      </Header>

      <Filters propertiesList={propertiesList} handleDownloadExpenses={handleDownloadExpenses} />

      {noDataFound ? <NoDataFoundComponent /> : (
        <ScrollArea containerHeight={containerHeight}>
          {loading ? <LoadingShimmer /> : <Grid />}
        </ScrollArea>
      )}

      {isDownloading && <LoadingStepper progress={downloadProgress} />}
      {expensesModal.open && <ExpensesModal />}
    </Container>
  );
};

export default Expenses;
