import { Country } from './types';

export const countries: Country[] = [
  {
    name: 'Brasil',
    acronym: 'BR',
  },
  {
    name: 'Afeganistão',
    acronym: 'AF',
  },
  {
    name: 'África do Sul',
    acronym: 'ZA',
  },
  {
    name: 'Albânia',
    acronym: 'AL',
  },
  {
    name: 'Alemanha',
    acronym: 'DE',
  },
  {
    name: 'Andorra',
    acronym: 'AD',
  },
  {
    name: 'Angola',
    acronym: 'AO',
  },
  {
    name: 'Anguilla',
    acronym: 'AI',
  },
  {
    name: 'Antártida',
    acronym: 'AQ',
  },
  {
    name: 'Antígua e Barbuda',
    acronym: 'AG',
  },
  {
    name: 'Arábia Saudita',
    acronym: 'SA',
  },
  {
    name: 'Argélia',
    acronym: 'DZ',
  },
  {
    name: 'Argentina',
    acronym: 'AR',
  },
  {
    name: 'Armênia',
    acronym: 'AM',
  },
  {
    name: 'Aruba',
    acronym: 'AW',
  },
  {
    name: 'Austrália',
    acronym: 'AU',
  },
  {
    name: 'Áustria',
    acronym: 'AT',
  },
  {
    name: 'Azerbaijão',
    acronym: 'AZ',
  },
  {
    name: 'Bahamas',
    acronym: 'BS',
  },
  {
    name: 'Bahrein',
    acronym: 'BH',
  },
  {
    name: 'Bangladesh',
    acronym: 'BD',
  },
  {
    name: 'Barbados',
    acronym: 'BB',
  },
  {
    name: 'Belarus',
    acronym: 'BY',
  },
  {
    name: 'Bélgica',
    acronym: 'BE',
  },
  {
    name: 'Belize',
    acronym: 'BZ',
  },
  {
    name: 'Benin',
    acronym: 'BJ',
  },
  {
    name: 'Bermudas',
    acronym: 'BM',
  },
  {
    name: 'Bolívia',
    acronym: 'BO',
  },
  {
    name: 'Bósnia-Herzegóvina',
    acronym: 'BA',
  },
  {
    name: 'Botsuana',
    acronym: 'BW',
  },
  {
    name: 'Brunei',
    acronym: 'BN',
  },
  {
    name: 'Bulgária',
    acronym: 'BG',
  },
  {
    name: 'Burkina Fasso',
    acronym: 'BF',
  },
  {
    name: 'Burundi',
    acronym: 'BI',
  },
  {
    name: 'Butão',
    acronym: 'BT',
  },
  {
    name: 'Cabo Verde',
    acronym: 'CV',
  },
  {
    name: 'Camarões',
    acronym: 'CM',
  },
  {
    name: 'Camboja',
    acronym: 'KH',
  },
  {
    name: 'Canadá',
    acronym: 'CA',
  },
  {
    name: 'Canárias',
    acronym: 'IC',
  },
  {
    name: 'Cazaquistão',
    acronym: 'KZ',
  },
  {
    name: 'Ceuta e Melilla',
    acronym: 'EA',
  },
  {
    name: 'Chade',
    acronym: 'TD',
  },
  {
    name: 'Chile',
    acronym: 'CL',
  },
  {
    name: 'China',
    acronym: 'CN',
  },
  {
    name: 'Chipre',
    acronym: 'CY',
  },
  {
    name: 'Cingapura',
    acronym: 'SG',
  },
  {
    name: 'Colômbia',
    acronym: 'CO',
  },
  {
    name: 'Comores',
    acronym: 'KM',
  },
  {
    name: 'Congo',
    acronym: 'CG',
  },
  {
    name: 'Coréia do Norte',
    acronym: 'KP',
  },
  {
    name: 'Coréia do Sul',
    acronym: 'KR',
  },
  {
    name: 'Costa do Marfim',
    acronym: 'CI',
  },
  {
    name: 'Costa Rica',
    acronym: 'CR',
  },
  {
    name: 'Croácia',
    acronym: 'HR',
  },
  {
    name: 'Cuba',
    acronym: 'CU',
  },
  {
    name: 'Curaçao',
    acronym: 'CW',
  },
  {
    name: 'Diego Garcia',
    acronym: 'DG',
  },
  {
    name: 'Dinamarca',
    acronym: 'DK',
  },
  {
    name: 'Djibuti',
    acronym: 'DJ',
  },
  {
    name: 'Dominica',
    acronym: 'DM',
  },
  {
    name: 'Egito',
    acronym: 'EG',
  },
  {
    name: 'El Salvador',
    acronym: 'SV',
  },
  {
    name: 'Emirados Árabes Unidos',
    acronym: 'AE',
  },
  {
    name: 'Equador',
    acronym: 'EC',
  },
  {
    name: 'Eritréia',
    acronym: 'ER',
  },
  {
    name: 'Eslováquia',
    acronym: 'SK',
  },
  {
    name: 'Eslovênia',
    acronym: 'SI',
  },
  {
    name: 'Espanha',
    acronym: 'ES',
  },
  {
    name: 'Estados Unidos',
    acronym: 'US',
  },
  {
    name: 'Estônia',
    acronym: 'EE',
  },
  {
    name: 'Etiópia',
    acronym: 'ET',
  },
  {
    name: 'Fiji',
    acronym: 'FJ',
  },
  {
    name: 'Filipinas',
    acronym: 'PH',
  },
  {
    name: 'Finlândia',
    acronym: 'FI',
  },
  {
    name: 'França',
    acronym: 'FR',
  },
  {
    name: 'Gabão',
    acronym: 'GA',
  },
  {
    name: 'Gâmbia',
    acronym: 'GM',
  },
  {
    name: 'Gana',
    acronym: 'GH',
  },
  {
    name: 'Geórgia',
    acronym: 'GE',
  },
  {
    name: 'Gibraltar',
    acronym: 'GI',
  },
  {
    name: 'Grã-Bretanha (Reino Unido, UK)',
    acronym: 'GB',
  },
  {
    name: 'Granada',
    acronym: 'GD',
  },
  {
    name: 'Grécia',
    acronym: 'GR',
  },
  {
    name: 'Groelândia',
    acronym: 'GL',
  },
  {
    name: 'Guadalupe',
    acronym: 'GP',
  },
  {
    name: 'Guam (Território dos Estados Unidos)',
    acronym: 'GU',
  },
  {
    name: 'Guatemala',
    acronym: 'GT',
  },
  {
    name: 'Guernsey',
    acronym: 'GG',
  },
  {
    name: 'Guiana',
    acronym: 'GY',
  },
  {
    name: 'Guiana Francesa',
    acronym: 'GF',
  },
  {
    name: 'Guiné',
    acronym: 'GN',
  },
  {
    name: 'Guiné Equatorial',
    acronym: 'GQ',
  },
  {
    name: 'Guiné-Bissau',
    acronym: 'GW',
  },
  {
    name: 'Haiti',
    acronym: 'HT',
  },
  {
    name: 'Holanda',
    acronym: 'NL',
  },
  {
    name: 'Honduras',
    acronym: 'HN',
  },
  {
    name: 'Hong Kong',
    acronym: 'HK',
  },
  {
    name: 'Hungria',
    acronym: 'HU',
  },
  {
    name: 'Iêmen',
    acronym: 'YE',
  },
  {
    name: 'Ilha Bouvet',
    acronym: 'BV',
  },
  {
    name: 'Ilha de Ascensão',
    acronym: 'AC',
  },
  {
    name: 'Ilha de Clipperton',
    acronym: 'CP',
  },
  {
    name: 'Ilha de Man',
    acronym: 'IM',
  },
  {
    name: 'Ilha Natal',
    acronym: 'CX',
  },
  {
    name: 'Ilha Pitcairn',
    acronym: 'PN',
  },
  {
    name: 'Ilha Reunião',
    acronym: 'RE',
  },
  {
    name: 'Ilhas Aland',
    acronym: 'AX',
  },
  {
    name: 'Ilhas Cayman',
    acronym: 'KY',
  },
  {
    name: 'Ilhas Cocos',
    acronym: 'CC',
  },
  {
    name: 'Ilhas Cook',
    acronym: 'CK',
  },
  {
    name: 'Ilhas Faroes',
    acronym: 'FO',
  },
  {
    name: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    acronym: 'GS',
  },
  {
    name: 'Ilhas Heard e McDonald (Território da Austrália)',
    acronym: 'HM',
  },
  {
    name: 'Ilhas Malvinas',
    acronym: 'FK',
  },
  {
    name: 'Ilhas Marianas do Norte',
    acronym: 'MP',
  },
  {
    name: 'Ilhas Marshall',
    acronym: 'MH',
  },
  {
    name: 'Ilhas Menores dos Estados Unidos',
    acronym: 'UM',
  },
  {
    name: 'Ilhas Norfolk',
    acronym: 'NF',
  },
  {
    name: 'Ilhas Salomão',
    acronym: 'SB',
  },
  {
    name: 'Ilhas Seychelles',
    acronym: 'SC',
  },
  {
    name: 'Ilhas Tokelau',
    acronym: 'TK',
  },
  {
    name: 'Ilhas Turks e Caicos',
    acronym: 'TC',
  },
  {
    name: 'Ilhas Virgens (Estados Unidos)',
    acronym: 'VI',
  },
  {
    name: 'Ilhas Virgens (Inglaterra)',
    acronym: 'VG',
  },
  {
    name: 'Índia',
    acronym: 'IN',
  },
  {
    name: 'Indonésia',
    acronym: 'ID',
  },
  {
    name: 'Irã',
    acronym: 'IR',
  },
  {
    name: 'Iraque',
    acronym: 'IQ',
  },
  {
    name: 'Irlanda',
    acronym: 'IE',
  },
  {
    name: 'Islândia',
    acronym: 'IS',
  },
  {
    name: 'Israel',
    acronym: 'IL',
  },
  {
    name: 'Itália',
    acronym: 'IT',
  },
  {
    name: 'Jamaica',
    acronym: 'JM',
  },
  {
    name: 'Japão',
    acronym: 'JP',
  },
  {
    name: 'Jersey',
    acronym: 'JE',
  },
  {
    name: 'Jordânia',
    acronym: 'JO',
  },
  {
    name: 'Kiribati',
    acronym: 'KI',
  },
  {
    name: 'Kosovo',
    acronym: 'XK',
  },
  {
    name: 'Kuait',
    acronym: 'KW',
  },
  {
    name: 'Laos',
    acronym: 'LA',
  },
  {
    name: 'Lesoto',
    acronym: 'LS',
  },
  {
    name: 'Letônia',
    acronym: 'LV',
  },
  {
    name: 'Líbano',
    acronym: 'LB',
  },
  {
    name: 'Libéria',
    acronym: 'LR',
  },
  {
    name: 'Líbia',
    acronym: 'LY',
  },
  {
    name: 'Liechtenstein',
    acronym: 'LI',
  },
  {
    name: 'Lituânia',
    acronym: 'LT',
  },
  {
    name: 'Luxemburgo',
    acronym: 'LU',
  },
  {
    name: 'Macau',
    acronym: 'MO',
  },
  {
    name: 'Macedônia (República Yugoslava)',
    acronym: 'MK',
  },
  {
    name: 'Madagascar',
    acronym: 'MG',
  },
  {
    name: 'Malásia',
    acronym: 'MY',
  },
  {
    name: 'Malaui',
    acronym: 'MW',
  },
  {
    name: 'Maldivas',
    acronym: 'MV',
  },
  {
    name: 'Mali',
    acronym: 'ML',
  },
  {
    name: 'Malta',
    acronym: 'MT',
  },
  {
    name: 'Marrocos',
    acronym: 'MA',
  },
  {
    name: 'Martinica',
    acronym: 'MQ',
  },
  {
    name: 'Maurício',
    acronym: 'MU',
  },
  {
    name: 'Mauritânia',
    acronym: 'MR',
  },
  {
    name: 'Mayotte',
    acronym: 'YT',
  },
  {
    name: 'México',
    acronym: 'MX',
  },
  {
    name: 'Micronésia',
    acronym: 'FM',
  },
  {
    name: 'Moçambique',
    acronym: 'MZ',
  },
  {
    name: 'Moldova',
    acronym: 'MD',
  },
  {
    name: 'Mônaco',
    acronym: 'MC',
  },
  {
    name: 'Mongólia',
    acronym: 'MN',
  },
  {
    name: 'Montenegro',
    acronym: 'ME',
  },
  {
    name: 'Montserrat',
    acronym: 'MS',
  },
  {
    name: 'Myanma',
    acronym: 'MM',
  },
  {
    name: 'Namíbia',
    acronym: 'NA',
  },
  {
    name: 'Nauru',
    acronym: 'NR',
  },
  {
    name: 'Nepal',
    acronym: 'NP',
  },
  {
    name: 'Nicarágua',
    acronym: 'NI',
  },
  {
    name: 'Níger',
    acronym: 'NE',
  },
  {
    name: 'Nigéria',
    acronym: 'NG',
  },
  {
    name: 'Niue',
    acronym: 'NU',
  },
  {
    name: 'Noruega',
    acronym: 'NO',
  },
  {
    name: 'Nova Caledônia',
    acronym: 'NC',
  },
  {
    name: 'Nova Zelândia',
    acronym: 'NZ',
  },
  {
    name: 'Omã',
    acronym: 'OM',
  },
  {
    name: 'Países Baixos Caribenhos',
    acronym: 'BQ',
  },
  {
    name: 'Palau',
    acronym: 'PW',
  },
  {
    name: 'Palestina',
    acronym: 'PS',
  },
  {
    name: 'Panamá',
    acronym: 'PA',
  },
  {
    name: 'Papua-Nova Guiné',
    acronym: 'PG',
  },
  {
    name: 'Paquistão',
    acronym: 'PK',
  },
  {
    name: 'Paraguai',
    acronym: 'PY',
  },
  {
    name: 'Peru',
    acronym: 'PE',
  },
  {
    name: 'Polinésia Francesa',
    acronym: 'PF',
  },
  {
    name: 'Polônia',
    acronym: 'PL',
  },
  {
    name: 'Porto Rico',
    acronym: 'PR',
  },
  {
    name: 'Portugal',
    acronym: 'PT',
  },
  {
    name: 'Qatar',
    acronym: 'QA',
  },
  {
    name: 'Quênia',
    acronym: 'KE',
  },
  {
    name: 'Quirguistão',
    acronym: 'KG',
  },
  {
    name: 'República Centro-Africana',
    acronym: 'CF',
  },
  {
    name: 'República Democrática do Congo',
    acronym: 'CD',
  },
  {
    name: 'República Dominicana',
    acronym: 'DO',
  },
  {
    name: 'República Tcheca',
    acronym: 'CZ',
  },
  {
    name: 'Romênia',
    acronym: 'RO',
  },
  {
    name: 'Ruanda',
    acronym: 'RW',
  },
  {
    name: 'Rússia (antiga URSS) - Federação Russa',
    acronym: 'RU',
  },
  {
    name: 'Saara Ocidental',
    acronym: 'EH',
  },
  {
    name: 'Saint-Pierre e Miquelon',
    acronym: 'PM',
  },
  {
    name: 'Samoa Americana',
    acronym: 'AS',
  },
  {
    name: 'Samoa Ocidental',
    acronym: 'WS',
  },
  {
    name: 'San Marino',
    acronym: 'SM',
  },
  {
    name: 'Santa Helena',
    acronym: 'SH',
  },
  {
    name: 'Santa Lúcia',
    acronym: 'LC',
  },
  {
    name: 'São Bartolomeu',
    acronym: 'BL',
  },
  {
    name: 'São Cristóvão e Névis',
    acronym: 'KN',
  },
  {
    name: 'São Martim',
    acronym: 'MF',
  },
  {
    name: 'São Martinho',
    acronym: 'SX',
  },
  {
    name: 'São Tomé e Príncipe',
    acronym: 'ST',
  },
  {
    name: 'São Vicente e Granadinas',
    acronym: 'VC',
  },
  {
    name: 'Senegal',
    acronym: 'SN',
  },
  {
    name: 'Serra Leoa',
    acronym: 'SL',
  },
  {
    name: 'Sérvia',
    acronym: 'RS',
  },
  {
    name: 'Síria',
    acronym: 'SY',
  },
  {
    name: 'Somália',
    acronym: 'SO',
  },
  {
    name: 'Sri Lanka',
    acronym: 'LK',
  },
  {
    name: 'Suazilândia',
    acronym: 'SZ',
  },
  {
    name: 'Sudão',
    acronym: 'SD',
  },
  {
    name: 'Sudão do Sul',
    acronym: 'SS',
  },
  {
    name: 'Suécia',
    acronym: 'SE',
  },
  {
    name: 'Suíça',
    acronym: 'CH',
  },
  {
    name: 'Suriname',
    acronym: 'SR',
  },
  {
    name: 'Svalbard',
    acronym: 'SJ',
  },
  {
    name: 'Tadjiquistão',
    acronym: 'TJ',
  },
  {
    name: 'Tailândia',
    acronym: 'TH',
  },
  {
    name: 'Taiwan',
    acronym: 'TW',
  },
  {
    name: 'Tanzânia',
    acronym: 'TZ',
  },
  {
    name: 'Território Britânico do Oceano índico',
    acronym: 'IO',
  },
  {
    name: 'Territórios do Sul da França',
    acronym: 'TF',
  },
  {
    name: 'Timor-Leste',
    acronym: 'TL',
  },
  {
    name: 'Togo',
    acronym: 'TG',
  },
  {
    name: 'Tonga',
    acronym: 'TO',
  },
  {
    name: 'Trinidad e Tobago',
    acronym: 'TT',
  },
  {
    name: 'Tristão da Cunha',
    acronym: 'TA',
  },
  {
    name: 'Tunísia',
    acronym: 'TN',
  },
  {
    name: 'Turcomenistão',
    acronym: 'TM',
  },
  {
    name: 'Turquia',
    acronym: 'TR',
  },
  {
    name: 'Tuvalu',
    acronym: 'TV',
  },
  {
    name: 'Ucrânia',
    acronym: 'UA',
  },
  {
    name: 'Uganda',
    acronym: 'UG',
  },
  {
    name: 'Uruguai',
    acronym: 'UY',
  },
  {
    name: 'Uzbequistão',
    acronym: 'UZ',
  },
  {
    name: 'Vanuatu',
    acronym: 'VU',
  },
  {
    name: 'Vaticano',
    acronym: 'VA',
  },
  {
    name: 'Venezuela',
    acronym: 'VE',
  },
  {
    name: 'Vietnã',
    acronym: 'VN',
  },
  {
    name: 'Wallis e Futuna',
    acronym: 'WF',
  },
  {
    name: 'Zâmbia',
    acronym: 'ZM',
  },
  {
    name: 'Zimbábue',
    acronym: 'ZW',
  },
];

export const normalizeCountry = (country: string) => {
  const foundCountry = countries.find((item) => {
    const countryName = `${item.name}`.trim().toLowerCase();
    const countryAcronym = `${item.acronym}`.trim().toLowerCase();

    return [countryName, countryAcronym].includes(`${country}`.trim().toLowerCase());
  });

  return foundCountry ? foundCountry.name : country;
};
