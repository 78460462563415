export type ResponsiblesForExpenseProps ={
  main_role: string,
  roles: string[],
  paid_by: string,
  paid_by_user_id: number,
  received_by: string,
  received_by_user_id: number,
  logged_user_id: number,
};

type ResponsiblesForExpenseResponse = {
  paid_by?: string,
  paid_by_user?: number,
  received_by: string,
  received_by_user: number,
  registered_by: number,
};

const isSeazoneUser = (data: ResponsiblesForExpenseProps) => ['Seazone', 'Admin'].includes(`${data?.main_role}`) && 
  (data.roles.includes('SeazoneAdministrative') || data.roles.includes('SeazoneOnboarding') || data.roles.includes('Admin'));

export const getParamsResponsibleUser = (data: ResponsiblesForExpenseProps): ResponsiblesForExpenseResponse => {
  if (isSeazoneUser(data)) {
    return {
      paid_by: data.paid_by,
      paid_by_user: data.paid_by_user_id,
      received_by: data.received_by,
      received_by_user: data.received_by_user_id,
      registered_by: data.logged_user_id,
    };
  }

  return {
    paid_by: undefined, //  quando um anfitrião lança uma despesa o paid by não deve ser preenchido, pois é o administrativo que infere estes valores.
    paid_by_user: undefined, //  quando um anfitrião lança uma despesa o paid by não deve ser preenchido, pois é o administrativo que infere estes valores. 
    received_by: 'Host',
    received_by_user: data.logged_user_id,
    registered_by: data.logged_user_id,
  };
};
