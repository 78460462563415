import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const BodyContainer = styled.div<{
  isTwoCardOpen?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  transition: 0.4s;
  margin-top: 1rem;
  max-height: ${({ isTwoCardOpen }) => (isTwoCardOpen ? '25vh' : '57vh')};
  overflow-y: scroll;
  padding: 0 10px 0 0;

  @media (max-width: 1400px) {
    max-height: ${({ isTwoCardOpen }) => (isTwoCardOpen ? '19vh' : '46vh')};
  }

  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;
