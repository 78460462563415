import React, { useState } from 'react';
import { motion } from 'framer-motion';

import {
  Alert,
  AlertTitle,
  CircularProgress,
} from '@mui/material';

import Template from '../Template';

import { IModal } from '../types';
import { SuccessModal } from '..';
import { ContainerButton } from './style';

import { IGuests } from '../../../../../../../../services/PreCheckin/types';
import { patchPreCheckin } from '../../../../../../../../services/PreCheckin';
import { usePreCheckin } from '../../../../../../../../context/PreCheckin/PreCheckin';

interface IButton {
  color: string;
  loading: boolean;
  onClick: Function;
  children: React.ReactNode;
}

const Button = ({
  color,
  onClick,
  children,
  loading = false,
}: IButton) => {
  if (loading) {
    return (
      <button style={{ background: color }} type="button">
        <CircularProgress size={20} style={{ color: 'white' }} />
      </button>
    );
  }

  return (
    <motion.button
      type="button"
      whileTap={{ scale: 0.9 }}
      whileHover={{ opacity: 1 }}
      onClick={() => onClick()}
      style={{ background: color }}
    >
      {children}
    </motion.button>
  );
};

const checkIfHaveNonFinishGuest = (guests: IGuests) => {
  const noIsPut = guests.list.filter((item) => item.isPut?.is === false);

  return {
    result: noIsPut,
    is: noIsPut.length > 0,
    quanty: noIsPut.length,
  };
};

const Confirm = ({ open, setOpen }: IModal) => {
  const [loading, setLoading] = useState({ is: false });
  const [success, setSuccess] = useState({ show: false });
  const { token, info, guests } = usePreCheckin();

  const validateGuestNonFinish = checkIfHaveNonFinishGuest(guests);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendData = async () => {
    const actualDate = new Date();
    const reservationId = info?.reservation_id;

    try {
      setLoading((old) => ({ ...old, is: true }));

      if (token && reservationId) {
        await patchPreCheckin({
          token,
          reservation: reservationId,
          data: {
            has_pet: info.has_pet,
            is_pre_checkin_completed: true,
            pre_checkin_fullfilled_at: actualDate,
          },
        });
      }
      setSuccess((old) => ({ ...old, show: true }));
    } finally {
      setLoading((old) => ({ ...old, is: false }));
    }
  };

  if (success.show) {
    return (
      <SuccessModal open={open} />
    );
  }

  return (
    <Template open={open} setOpen={setOpen}>
      <h1>Tem certeza que deseja concluir o pré-checkin ?</h1>
      {validateGuestNonFinish.is && (
        <Alert severity="warning">
          <AlertTitle>
            <strong>
              Você tem
              {' '}
              {`( ${validateGuestNonFinish.quanty} )`}
              {' '}
              {validateGuestNonFinish.quanty === 1 ? 'Hóspede não finalizado.' : 'Hóspedes não finalizados.'}
            </strong>
          </AlertTitle>
          <p>
            Certifique-se de ter finalizado todos os hóspdedes antes de concluir o pré-checkin.
          </p>
          <br />
          <p>
            Hóspedes que não foram finalizados não serão enviados para o anfitrião.
          </p>
        </Alert>
      )}
      <ContainerButton>
        <Button
          color="#45cc48"
          loading={loading.is}
          onClick={handleSendData}
        >
          Sim, concluir
        </Button>

        <Button
          loading={false}
          color="rgb(232 82 82)"
          onClick={handleClose}
        >
          Não, voltar
        </Button>
      </ContainerButton>
    </Template>
  );
};
export default Confirm;
