import { useContextSelector } from 'use-context-selector';
import { FinancialCloseContext } from '../../context/FinancialClosePage/FinancialCloseContext/FinancialCloseContext';

export function useFinancialClose() {
  const dateSelected = useContextSelector(FinancialCloseContext, (state) => state.dateSelected);
  const handleDateSelected = useContextSelector(FinancialCloseContext,
    (state) => state.setDateSelected);

  const openModalDate = useContextSelector(FinancialCloseContext,
    (state) => state.openModalDate);
  const handleOpenModalDate = useContextSelector(FinancialCloseContext,
    (state) => state.setOpenModalDate);

  const openActions = useContextSelector(FinancialCloseContext,
    (state) => state.openActions);
  const handleOpenActions = useContextSelector(FinancialCloseContext,
    (state) => state.setOpenActions);

  const openModalManualFit = useContextSelector(FinancialCloseContext,
    (state) => state.openModalManualFit);
  const handleOpenModalManualFit = useContextSelector(FinancialCloseContext,
    (state) => state.setOpenModalManualFit);

  const actionSelected = useContextSelector(FinancialCloseContext, (state) => state.actionSelected);
  const handleActionSelected = useContextSelector(FinancialCloseContext,
    (state) => state.setActionSelected);

  const loading = useContextSelector(FinancialCloseContext, (state) => state.loading);
  const handleLoading = useContextSelector(FinancialCloseContext,
    (state) => state.setLoading);

  const search = useContextSelector(FinancialCloseContext, (state) => state.search);
  const handleChangeSearch = useContextSelector(FinancialCloseContext,
    (state) => state.setSearch);

  const activeLinearProgress = useContextSelector(FinancialCloseContext, (state) => state
    .activeLinearProgress);
  const handleActiveLinearProgress = useContextSelector(FinancialCloseContext,
    (state) => state.setActiveLinearProgress);

  const openFinancialUpdateModal = useContextSelector(FinancialCloseContext,
    (state) => state.openFinancialUpdateModal);
  const handleOpenFinancialUpdateModal = useContextSelector(FinancialCloseContext,
    (state) => state.setOpenFinancialUpdateModal);

  return {
    dateSelected,
    handleDateSelected,
    openModalDate,
    handleOpenModalDate,
    openActions,
    handleOpenActions,
    openModalManualFit,
    handleOpenModalManualFit,
    actionSelected,
    handleActionSelected,
    loading,
    handleLoading,
    search,
    handleChangeSearch,
    activeLinearProgress,
    handleActiveLinearProgress,
    openFinancialUpdateModal,
    handleOpenFinancialUpdateModal,
  };
}
