import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    cursor: pointer;
  }

  p {
    gap: 5px;
    display: flex;
    flex-direction: row;

    a {
      color: #394760;
      text-decoration: underline;
    }

    button {
      color: #389be4;
      border: none;
      font-weight: bold;
      padding: 7px 10px;
      border-radius: 5px;
      background: transparent;
      border: 2px solid #389be4;
    }
  }
`;

export const TextContainer = styled.div``;
