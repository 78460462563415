import * as Yup from 'yup';

import { useFormik } from 'formik';
import { Grid } from '@mui/material';

import Backdrop from '../../../../../Backdrop';
import TextField from '../../../../../TextField';
import FormButton from '../../../../../FormButton';
import FormGridRow from '../../../../../FormGridRow';

import {
  ErrorMessage,
  useToastErrorMessage,
} from '../../../../../../utils/Messages';

import {
  usePropertyCategoryLocation,
} from '../../../../../../hooks/usePropertyCategoryLocation/usePropertyCategoryLocation';

import { useToast } from '../../../../../../context/ToastContext';
import { postCategory } from '../../../../../../services/Category/request';
import { CreateCategoryProps } from '../../../../../../services/Category/types';

import {
  Content,
  Container,
  ButtonsContainer,
} from './styles';

const NewCategoryCodeModal = () => {
  const {
    categories,
    handleAddCategory,
    setOpenModalNewCategoryCode,
  } = usePropertyCategoryLocation();

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const handleCloseModal = () => {
    setOpenModalNewCategoryCode(false);
  };

  const validation = Yup.object().shape({
    category: Yup.string().min(3, 'Campo obrigatório').required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      category: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const categoryAlreadyExists = categories.some((item) => item.code.toUpperCase().trim()
        === values.category.toUpperCase().trim());

        if (categoryAlreadyExists) {
          toast.alert('O código da categoria informada já existe!');
        } else {
          const payload: CreateCategoryProps = {
            code: values.category.toUpperCase().trim(),
          };

          const response = await postCategory(payload);
          handleAddCategory(response);
          handleCloseModal();
          toast.success('Código da categoria criado com sucesso!');
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error(e.message || ErrorMessage.default());
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <Backdrop onClose={handleCloseModal}>
      <Container
        onSubmit={formik.handleSubmit}
        onContextMenu={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      >
        <Content>
          <FormGridRow grid>
            <Grid item sm={12} xs={12}>
              <TextField
                formik={formik}
                id="category"
                required
                label="Código da nova categoria"
                placeholder="Digite aqui..."
                labelClassName="labelClass"
                value={formik.values.category.toUpperCase()}
              />
            </Grid>
          </FormGridRow>
        </Content>
        <ButtonsContainer>
          <FormButton type="button" variant="outlined" onClick={() => handleCloseModal()}>Cancelar</FormButton>
          <FormButton type="submit">Salvar</FormButton>
        </ButtonsContainer>
      </Container>
    </Backdrop>
  );
};

export default NewCategoryCodeModal;
