import { useMemo } from 'react';

import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';
import { Container, Cluster } from './styles';

const PartnerCluster = () => {
  const { resume } = usePartners();

  const cluster = useMemo(() => resume?.partner?.partnership_tier || 'New', [resume]);

  if (cluster === 'New') return null;

  return (
    <Container>
      <p>
        Atualmente você é
        <strong>Parceiro</strong>
        <Cluster cluster={resume?.partner?.partnership_tier || 'New'}>{resume?.partner?.partnership_tier || 'New'}</Cluster>
      </p>
    </Container>
  );
};

export default PartnerCluster;
