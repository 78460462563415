import {
  FC, useMemo,
} from 'react';

import usePropertyManager from '../../../../hooks/usePropertyManager';
import { Status } from '../../../../services/InsertData/types';
import { UseFormik } from '../../../../utils/Formik/types';
import { compareList } from '../../../../utils/Sorting';
import { SelectProps } from '../../../Autocomplete/Autocomplete';
import DatePicker from '../../../DatePicker';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import { formatPropertiesDropdown } from '../../utils';
import { Container, Form } from './styles';

interface PropertyChangeStatusFormikProps {
  property: number;
  status: Status;
  exchangeDate: Date | null;
}

type FormChangePropertyStatusProps = {
  formik?: UseFormik<PropertyChangeStatusFormikProps>,
};

const statusList = [
  { label: 'Ativo', value: 'Active' },
  { label: 'Inativo', value: 'Inactive' },
  { label: 'Onboarding', value: 'Onboarding' },
  { label: 'Contrato assinado', value: 'Signed_Contract' },
];

const FormChangePropertyStatus:FC<FormChangePropertyStatusProps> = ({ formik }) => {
  const {
    data: properties,
    loading: propertiesLoad,
  } = usePropertyManager();

  const formattPropertiesToShow = useMemo(() => {
    const resolver = formatPropertiesDropdown(properties);
    return resolver;
  }, [properties]);

  const handleGetStatus = (): SelectProps[] => statusList.map((status) => ({
    optionText: `${status.label}`,
    optionValue: `${status.value}`,
  })).sort((a, b) => compareList(a.optionText, b.optionText));

  return (
    <Container>
      <Form>
        <div className="two-columns">
          <DropdownAutocomplete
            required
            id="property"
            formik={formik}
            label="Propriedade"
            loading={propertiesLoad}
            labelClassName="labelClass"
            options={formattPropertiesToShow}
          />
          <DropdownAutocomplete
            labelClassName="labelClass"
            required
            label="Status"
            id="status"
            options={handleGetStatus()}
            formik={formik}
          />
        </div>
        <div className="two-columns">
          <DatePicker
            labelClassName="labelClass"
            required
            viewsCustom={['day', 'month', 'year']}
            label="Data de alteração do status"
            id="exchangeDate"
            formik={formik}
          />
        </div>
      </Form>
    </Container>
  );
};

export default FormChangePropertyStatus;
