import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  min-height: 180px;
  width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
`;

export const Content = styled.div`
  z-index: 10;

  padding: 10px;

  width: 100%;
  height: 100%;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1,
  p {
    font-family: "Inter";
    color: white;
  }
`;

export const TagContainer = styled.div<{
  active?: boolean;
}>`
  border: 0.5px solid ${({ active }) => (active ? '#7BD1B7' : '#cccc')};
  padding: 5px;
  border-radius: 10px;

  p{
    font-weight: bold;
    font-size: 0.9rem;
  }
`;

export const Text = styled.p`
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
  }
`;
export const RightContainer = styled.div`
  gap: 30px;
  display: flex;
  align-items: baseline;
  flex-direction: row;
`;

export const LeftContainer = styled.div<{
  gap?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

export const Title = styled.h1`
  font-size: 1.2rem;
`;
