import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 10px;

  button {
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background: #00173e;
    color: #ffffff;
    cursor: pointer;
  }
`;
