/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import deleteIcon from '../../../../assets/icons/generals/bashRed.svg';
import { BankAccountStateProps } from '..';
import { Bank } from '../../../../services/Bank/types';
import { BankDetails } from '../../../../services/Owner/types';
import { cnpj, cpf } from '../../../../utils/InputMask/Number';
import { useViewMode } from '../../../../context/ViewMode/ViewMode';

import {
  CardContainer,
  CardContent,
  Header,
  BankContainer,
  ContainerButtons,
  Body,
  TagMain,
  ButtonEdit,
} from './styles';
import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';

interface IAccountBankCard {
  bankAccountState: BankDetails;
  setBankAccountState: (item: BankAccountStateProps) => void;
}

const AccountBankCard = ({
  bankAccountState,
  setBankAccountState,
}: IAccountBankCard): JSX.Element => {
  const { isMobile } = useScreenResize();
  const { isViewMode } = useViewMode();

  const handleEditBankAccount = () => {
    if (bankAccountState) {
      setBankAccountState({
        bank: bankAccountState,
        state: 'editing',
      });
    }
  };

  const handleDeleteBankAccount = () => {
    if (bankAccountState?.id) {
      setBankAccountState({
        bank: bankAccountState,
        state: 'deleting',
      });
    }
  };

  const IconEdit = () => (
    <ButtonEdit type="button" onClick={() => handleEditBankAccount()}>
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3103 4.87915L14.1216 0.689461C13.9823 0.550137 13.8169 0.439617 13.6349 0.364213C13.4529 0.28881 13.2578 0.25 13.0608 0.25C12.8638 0.25 12.6687 0.28881 12.4867 0.364213C12.3047 0.439617 12.1393 0.550137 12 0.689461L0.439695 12.2507C0.299801 12.3895 0.188889 12.5547 0.113407 12.7367C0.0379245 12.9188 -0.000621974 13.114 7.58902e-06 13.311V17.5007C7.58902e-06 17.8985 0.158043 18.2801 0.439347 18.5614C0.720652 18.8427 1.10218 19.0007 1.50001 19.0007H5.6897C5.88675 19.0013 6.08197 18.9628 6.26399 18.8873C6.44602 18.8118 6.61122 18.7009 6.75001 18.561L18.3103 7.00071C18.4496 6.86142 18.5602 6.69604 18.6356 6.51403C18.711 6.33202 18.7498 6.13694 18.7498 5.93993C18.7498 5.74292 18.711 5.54784 18.6356 5.36582C18.5602 5.18381 18.4496 5.01844 18.3103 4.87915ZM5.6897 17.5007H1.50001V13.311L9.75001 5.06102L13.9397 9.25071L5.6897 17.5007ZM15 8.18946L10.8103 4.00071L13.0603 1.75071L17.25 5.93946L15 8.18946Z" fill="#0D4BD0" />
      </svg>
    </ButtonEdit>
  );

  const CardHeader = () => (
    <Header>
      <BankContainer>
        <div>
          <h3>Banco</h3>
          {bankAccountState?.is_default && <TagMain>Principal</TagMain>}
        </div>
        <h3>
          {`${(bankAccountState?.bank as Bank)?.bank_number}-${
            (bankAccountState?.bank as Bank)?.short_name
          }`}
        </h3>
      </BankContainer>
      {!isViewMode && (
        <ContainerButtons isMainAccount={bankAccountState?.is_default || false}>
          <IconEdit />
          {!bankAccountState?.is_default && (
            <img
              src={deleteIcon}
              alt="icone de deletar"
              onKeyDown={() => handleDeleteBankAccount()}
              className="delete"
              onClick={() => handleDeleteBankAccount()}
            />
          )}
        </ContainerButtons>
      )}
    </Header>
  );

  const CardBody = () => (
    <>
      <Body className="firstContent">
        {bankAccountState?.cpf?.length > 1 && (
          <div>
            <p>CPF</p>
            <span>{cpf(bankAccountState.cpf)}</span>
          </div>
        )}
        {bankAccountState?.cnpj?.length > 1 && (
          <div>
            <p>CNPJ</p>
            <span>{cnpj(bankAccountState.cnpj)}</span>
          </div>
        )}
        <div>
          <p>Pix</p>
          <span>{bankAccountState?.pix_key || 'Não Informado'}</span>
        </div>
      </Body>

      <Body className="secondaryContent">
        <div>
          <p>Agência</p>
          <span>{bankAccountState?.branch_number || ''}</span>
        </div>
        <div>
          <p>Conta</p>
          <span>{bankAccountState?.account_number || ''}</span>
        </div>
        {!isMobile && (
          <div>
            <p>Destinatário</p>
            <span>{bankAccountState?.entity_name || ''}</span>
          </div>
        )}
      </Body>
      {isMobile && (
        <Body className="secondaryContent">
          <div>
            <p>Destinatário</p>
            <span>{bankAccountState?.entity_name || ''}</span>
          </div>
        </Body>
      )}
    </>
  );

  return (
    <CardContainer isMain={bankAccountState?.is_default || false}>
      <CardContent>
        <CardHeader />
        <CardBody />
      </CardContent>
    </CardContainer>
  );
};

export default AccountBankCard;
