import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,

} from 'react';

import { createContext } from 'use-context-selector';

type Steps = 'damageSearch'
| 'reconcileDamageWithReservation'
| 'insertDamageDetails'
| 'insertBillingHistoryNegotiations'
| 'viewDamageDetails'
| 'viewBillingHistoryNegotiations'
| 'insertRefundDamage'
| 'viewRefundDamage'
;

type GuestDamageStepContextProps = {
  guestDamageStep: Steps;
  setGuestDamageStep: Dispatch<SetStateAction<Steps>>;
  shouldDisplayStepCard: boolean;
  handleInsertDamageDetailsStep: () => void;
  handleViewDamageDetailsStep: () => void;
  handleReconcileDamageWithReservationStep: () => void;
  handleViewBillingHistoryNegotiationsStep: () => void;
  handleInsertRefundDamageStep: () => void;
  handleViewRefundDamageStep: () => void;
  handleInsertBillingHistoryNegotiationsStep: () => void;
  handleDamageSearchStep: () => void;
};

export const GuestDamageStepContext = createContext<GuestDamageStepContextProps>({
} as GuestDamageStepContextProps);

export const GuestDamageStepProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [guestDamageStep, setGuestDamageStep] = useState<Steps>('damageSearch');

  const shouldDisplayStepCard = guestDamageStep === 'viewDamageDetails'
  || guestDamageStep === 'insertBillingHistoryNegotiations'
  || guestDamageStep === 'viewBillingHistoryNegotiations'
  || guestDamageStep === 'insertRefundDamage'
  || guestDamageStep === 'viewRefundDamage';

  function handleInsertDamageDetailsStep() {
    setGuestDamageStep('insertDamageDetails');
  }

  function handleViewDamageDetailsStep() {
    setGuestDamageStep('viewDamageDetails');
  }

  function handleReconcileDamageWithReservationStep() {
    setGuestDamageStep('reconcileDamageWithReservation');
  }

  function handleInsertBillingHistoryNegotiationsStep() {
    setGuestDamageStep('insertBillingHistoryNegotiations');
  }

  function handleViewBillingHistoryNegotiationsStep() {
    setGuestDamageStep('viewBillingHistoryNegotiations');
  }

  function handleInsertRefundDamageStep() {
    setGuestDamageStep('insertRefundDamage');
  }

  function handleViewRefundDamageStep() {
    setGuestDamageStep('viewRefundDamage');
  }
  function handleDamageSearchStep() {
    setGuestDamageStep('damageSearch');
  }

  const value = useMemo(() => ({
    guestDamageStep,
    setGuestDamageStep,
    shouldDisplayStepCard,
    handleInsertDamageDetailsStep,
    handleViewDamageDetailsStep,
    handleReconcileDamageWithReservationStep,
    handleInsertBillingHistoryNegotiationsStep,
    handleViewBillingHistoryNegotiationsStep,
    handleInsertRefundDamageStep,
    handleViewRefundDamageStep,
    handleDamageSearchStep,
  }), [
    guestDamageStep,
    setGuestDamageStep,
    shouldDisplayStepCard,
    handleInsertDamageDetailsStep,
    handleViewDamageDetailsStep,
    handleReconcileDamageWithReservationStep,
    handleInsertBillingHistoryNegotiationsStep,
    handleViewBillingHistoryNegotiationsStep,
    handleInsertRefundDamageStep,
    handleViewRefundDamageStep,
    handleDamageSearchStep,
  ]);

  return (
    <GuestDamageStepContext.Provider
      value={value}
    >
      {children}
    </GuestDamageStepContext.Provider>
  );
};
