import React from 'react';

import { SearchRounded } from '@mui/icons-material';
import { UseFormik } from '../../../../utils/Formik/types';
import { useToast } from '../../../../context/ToastContext';
import { getNFList } from '../../../../services/NFlist/request';
import { useNfList } from '../../../../hooks/NfListHook/useNfList';

import {
  Container,
  StyledTextField,
} from './styles';

interface INFListTextField {
  id: string;
  onClick?: Function;
  formik: UseFormik<any>;
}

type IEventCustomType = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const NFListTextField = ({
  id,
  formik,
  onClick,
}: INFListTextField) => {
  const toast = useToast();
  const { handleListItems, handleLoading } = useNfList();

  const handleGetNfs = async () => {
    handleLoading(true);
    const formattedPeriod = `${formik.values.period.getFullYear()}-${
      formik.values.period.getMonth() + 1
    }`;

    const params = {
      period: formattedPeriod,
      q: formik.values[id],
    };

    try {
      const response = await getNFList(params);
      handleListItems(response.formatDataToListItem);

      if (response.formatDataToListItem.length === 0) {
        toast.alert('Nenhum dado foi encontrado');
        handleLoading(false);
      }

      handleLoading(false);
      return response;
    } catch (err) {
      toast.error('Erro ao buscar os repasses');
      handleLoading(false);
      return false;
    }
  };

  const handleSetDataInFormikIdValue = (e: IEventCustomType) => {
    const customValue: string = e.currentTarget.value;
    formik.setFieldValue(id, customValue);
  };

  const handleKeyPress = (e: { key: string; }) => {
    if (e.key === 'Enter' && onClick) {
      onClick();
      handleGetNfs();
    }
  };

  return (
    <Container>
      <StyledTextField>
        <SearchRounded
          className="icon"
          onClick={() => handleGetNfs()}
          style={{ fontSize: 22, color: '#2860D8' }}
        />
        <input
          id={id}
          type="text"
          onKeyUp={(e) => handleKeyPress(e)}
          placeholder="Busque por proprietário ou anfitrião"
          onChange={(e) => handleSetDataInFormikIdValue(e)}
        />
      </StyledTextField>
    </Container>
  );
};

NFListTextField.defaultProps = {
  onClick: () => {},
};

export default NFListTextField;
