import React, { useEffect } from 'react';
import { UseFormik } from '../../../../../../../../utils/Formik/types';

import {
  Label,
  Section,
  SubText,
  InputStyled,
} from '../../style';
import Error from '../Error';
import { Number } from '../../../../../../../../utils/InputMask';

type Mask = 'money' | 'cpf' | 'cnpj'| 'cpfOrCnpj' | 'cep' | 'phone' | 'hour' | 'agency' | 'account' | 'integerNumber';

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  title: string;
  formik: UseFormik<any>;
  subtext?: string | undefined;
  mask?: Mask,
}

export const validateError = (
  formik: UseFormik<any>,
  id: string,
  ignoreTouch?: boolean,
) => {
  const { touched, errors } = formik;

  if (touched[id] && errors[id]) {
    return true;
  }

  if (ignoreTouch && errors[id]) {
    return true;
  }

  return false;
};

const Input = ({
  id,
  title,
  formik,
  subtext,
  disabled,
  mask,
  onChange,
  ...rest
}: IInput) => {
  const { touched, errors } = formik;
  const masks: Record<Mask, (value: string) => string> = {
    money: Number.currency,
    cpf: Number.cpf,
    cnpj: Number.cnpj,
    cpfOrCnpj: Number.cpfOrCnpj,
    cep: Number.cep,
    phone: Number.phone,
    hour: Number.hour,
    agency: Number.agency,
    account: Number.account,
    integerNumber: Number.integerNumber,
  };
  const formatValue = (str: string) => (mask ? masks[mask](str) : str);

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(id, formatValue((formik?.values[id] || '').toString()));
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const customValue: string = formatValue(e.currentTarget.value);
    if (formik) {
      formik.setFieldValue(id, customValue);
      formik.setFieldTouched(
        id, true, false,
      );
    }
  };

  return (
    <Section disabled={disabled}>
      <Section>
        <Label>{title}</Label>
        {subtext && <SubText>{subtext}</SubText>}

        <InputStyled
          id={id}
          disabled={disabled}
          error={validateError(formik, id)}
          {...rest}
          {...formik.getFieldProps(id)}
          onChange={handleChange}
        />
      </Section>
      <Error validate={!!((touched[id] && errors[id]))}>
        {errors[id]}
      </Error>
    </Section>
  );
};

Input.defaultProps = {
  subtext: undefined,
  mask: '',
};

export default Input;
