import { useContextSelector } from 'use-context-selector';
import { GuestDamageContext } from '../../../context/GuestDamage/GuestDamageContext';

export function useGuestDamage() {
  const guestDamagesToInsert = useContextSelector(GuestDamageContext, (state) => state
    .guestDamagesToInsert);
  const setGuestDamagesToInsert = useContextSelector(GuestDamageContext,
    (state) => state.setGuestDamagesToInsert);
  const quotationFile = useContextSelector(GuestDamageContext, (state) => state
    .quotationFile);
  const setQuotationFile = useContextSelector(GuestDamageContext,
    (state) => state.setQuotationFile);
  const evidences = useContextSelector(GuestDamageContext, (state) => state
    .evidences);
  const setEvidences = useContextSelector(GuestDamageContext,
    (state) => state.setEvidences);
  const selectedPhoto = useContextSelector(GuestDamageContext, (state) => state
    .selectedPhoto);
  const handleOpenPhotoModal = useContextSelector(GuestDamageContext,
    (state) => state.handleOpenPhotoModal);
  const handleClosePhotoModal = useContextSelector(GuestDamageContext,
    (state) => state.handleClosePhotoModal);
  const handleOpenFile = useContextSelector(GuestDamageContext,
    (state) => state.handleOpenFile);
  const reservationGuestDamages = useContextSelector(GuestDamageContext, (state) => state
    .reservationGuestDamages);
  const setReservationGuestDamages = useContextSelector(GuestDamageContext,
    (state) => state.setReservationGuestDamages);
  const isLoading = useContextSelector(GuestDamageContext, (state) => state
    .isLoading);
  const setIsLoading = useContextSelector(GuestDamageContext,
    (state) => state.setIsLoading);
  const selectedGuestDamage = useContextSelector(GuestDamageContext, (state) => state
    .selectedGuestDamage);
  const setSelectedGuestDamage = useContextSelector(GuestDamageContext,
    (state) => state.setSelectedGuestDamage);
  const houseHoldLinenItens = useContextSelector(GuestDamageContext, (state) => state
    .houseHoldLinenItens);
  const setHouseHoldLinenItens = useContextSelector(GuestDamageContext,
    (state) => state.setHouseHoldLinenItens);

  const handleOpenLink = useContextSelector(GuestDamageContext, (state) => state.handleOpenLink);

  return {
    guestDamagesToInsert,
    setGuestDamagesToInsert,
    quotationFile,
    setQuotationFile,
    evidences,
    setEvidences,
    selectedPhoto,
    handleOpenPhotoModal,
    handleClosePhotoModal,
    handleOpenFile,
    reservationGuestDamages,
    setReservationGuestDamages,
    isLoading,
    setIsLoading,
    selectedGuestDamage,
    setSelectedGuestDamage,
    houseHoldLinenItens,
    setHouseHoldLinenItens,
    handleOpenLink,
  };
}
