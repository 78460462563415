import { MenuItem } from '@mui/material';
import styled, { css } from 'styled-components';

export const sx = {
  padding: 0,
  borderRadius: 2,
  overflow: 'visible',
  filter:
    'box-shadow: rgba(50, 52, 57, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
  background: 'white',
  position: 'absolute',
  // border: '1px solid #cccccc7e',
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '& .MuiMenu-list': {
    '& .MuiMenuItem-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '&:first-child': {
        mt: 0,
      },

      mt: 0.5,
      paddingTop: 1,
      paddingBottom: 1,
      '&:hover': {
        borderRadius: 2,
      },
    },
  },
};

export const Item = styled(MenuItem)<{
  active?: boolean;
}>`
  &&& {
    display: flex;
    color: rgba(0, 0, 0, 0.8);

    ${({ active }) => active
      && css`
        display: flex;
        color: "blue";
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 8px;
      `}
  }
`;

export const Container = styled.div`
  padding: 10px;
`;

export const Title = styled.h1`
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
`;
