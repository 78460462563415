import React, { FC, useState } from 'react';
import { Close } from '@mui/icons-material';

import ModalTemplate from '../ModalTemplate';
import FormButton from '../../../FormButton';
import MonthViewer from '../../CardPropertyDetails/MonthViewer/MonthViewer';

import { useOwnerExtract } from '../../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';
import { getOwnerExtract } from '../../../../services/Owner/OwnerExtract/request';
import { getMonthNumber } from '../../../../utils/Date';

import {
  ContainerBox,
  Header,
  ButtonClose,
  Text,
  ButtonContainer,
  CalendarWrapper,
} from './styles';

interface Props {
  dataCy?: string;
  open?: boolean;
  handleOpen?: Function;
}

const ModalFinancialStatement: FC<Props> = ({
  open = false,
  handleOpen = () => {},
}) => {
  const {
    property,
    period,
    setPeriod,
    setOpenModalFinancialExtract,
    setExtractList,
  } = useOwnerExtract();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  const handleGetFinancialStatementData = async () => {
    const date = `${period.getFullYear()}-${getMonthNumber[period.getMonth()]}`;

    if (property.id !== -1) {
      const response = await getOwnerExtract({
        date,
        property_id: property.id,
      });

      setExtractList(response);
      setOpenModalFinancialExtract(true);
    }
  };

  return (
    <ModalTemplate
      open={open}
      handleClose={handleOpen}
    >
      <ContainerBox isMonthModalOpen={isModalVisible}>
        <Header>
          <Text>{`Selecione o período para ver o relatório financeiro do ${property.code}`}</Text>
          <ButtonClose type="button" onClick={() => handleOpen(false)}>
            <Close />
          </ButtonClose>
        </Header>
        <CalendarWrapper>
          <MonthViewer
            isModalVisible={isModalVisible}
            isFinancialStatementCalendar
            handleSetDate={setPeriod}
            setIsModalVisible={setIsModalVisible}
          />
        </CalendarWrapper>
        <ButtonContainer>
          <FormButton
            type="button"
            onClick={() => handleGetFinancialStatementData()}
          >
            Visualizar extrato
          </FormButton>
        </ButtonContainer>
      </ContainerBox>
    </ModalTemplate>

  );
};

export default ModalFinancialStatement;
