import styled from 'styled-components';
import { shimmerAnimation } from '../../../../../../../Calendar/LoadingShimmer/animationShimmer';
import { fadeInAnimation } from '../../../utils';

export const FilesContainerShimmer = styled.div`
  gap: 10px;
  opacity: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;

  animation: ${fadeInAnimation} ease 0.3s forwards;
`;
export const AddButtonShimmer = styled.div`
  gap: 5px;
  display: flex;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 10px;
  transition: 0.3s ease;
  background: transparent;
  border: 1px solid #c4c4c4;

  gap: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  width: 120px;
  height: 105px;

  position: relative;

  &::after{
    content: '';
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    background: red;
    position: absolute;
    border-radius: 10px;
    border: 1px solid #cccc;
    transform: translate(-50%, -50%);
    animation: ${shimmerAnimation} 2s ease infinite;
  }
`;

export const AddButtonShimmerContainer = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1{
    font-weight: 500;
    color: rgba(0,0,0,0.5);
  }
`;

export const FileSeparatorShimmer = styled.div`
  height: 3px;
  width: 100%;
  border-radius: 5px;
  background: #d9d9d9;
  border: 1px solid #cccc;
  animation: ${shimmerAnimation} 2s ease infinite;
`;

export const FileNameShimmer = styled.div`
  width: 90%;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #cccc;
  animation: ${shimmerAnimation} 2s ease infinite;
`;

export const FileDeleteIconContainerShimmer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #cccc;
  animation: ${shimmerAnimation} 2s ease infinite;
`;
