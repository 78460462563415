import styled from 'styled-components';

import { CARDGRIDTEMPLATECOLUM } from '../../../utils';

const radiusBorder = '10px';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 1.7rem;
`;

export const Description = styled.p`
  font-size: 1.3rem;
`;

export const LeftSide = styled.div`
  gap: 20px;
  display: flex;
  max-width: 300px;
  padding: 20px 15px;
  flex-direction: column;

  border-top-left-radius: ${radiusBorder};
  border-bottom-left-radius: ${radiusBorder};

  background-color: ${({ theme }) => theme.palette.white._700.hex()};

  h1 {
    font-size: 1.56rem;
  }
  p {
    font-size: 1.2rem;
  }
`;

export const RightSide = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: grid;
  padding: 20px 15px;
  align-items: center;

  border-top-right-radius: ${radiusBorder};
  grid-template-columns: ${CARDGRIDTEMPLATECOLUM};
  background-color: ${({ theme }) => theme.palette.white._750.hex()};

  h1 {
    opacity: 0.6;
    font-size: 1.23rem;
    font-weight: normal;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom-right-radius: ${radiusBorder};
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
`;

export const ValueBody = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  padding: 20px 15px;
  grid-template-rows: 1fr;
  grid-template-columns: ${CARDGRIDTEMPLATECOLUM};
  
  gap: 30px;

  &:first-child {
    border-top: none;
  }

  h2 {
    font-size: 1.1rem;
    font-weight: normal;
  }

  border-top: 1px solid #d2d4d8;
`;
