import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
