import {
  FC,
  createContext,
  useContext,
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';

import moment from 'moment';
import ReactGA from 'react-ga4';
import { UserLogged, UserInformation } from '../services/User/types';
import { getUserInformation } from '../services/User/request';
import { getPropertiesOwner } from '../services/Owner/request';
import { PropertyOwner } from '../services/Owner/types';
import { useViewMode } from './ViewMode/ViewMode';
import { queryClient } from '../utils/QueryClient/QueryClient';
import { compareList } from '../utils/Sorting';

export interface UserContextType {
  user?: UserLogged | null;
  setUser(user: UserLogged): void;
  userInformation?: UserInformation | null;
  setUserInformation(user: UserInformation): void;
  propertiesIdsOwnerLogged?: number[];
  setPropertiesIdsOwnerLogged: Function;
  propertiesOwnerLogged?: PropertyOwner[];
  setPropertiesOwnerLogged: Function;
  propertiesOwnerLoggedCopy?: PropertyOwner[];
  setPropertiesOwnerLoggedCopy: Function;
  logout(): void;
  translateMainRole: Function;
  isLoggedIn: boolean;
}

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: (user: UserLogged) => user,
  userInformation: null,
  setUserInformation: (user: UserInformation) => user,
  logout: () => null,
  propertiesIdsOwnerLogged: [],
  setPropertiesIdsOwnerLogged: () => {},
  propertiesOwnerLogged: [],
  setPropertiesOwnerLogged: () => {},
  propertiesOwnerLoggedCopy: [],
  setPropertiesOwnerLoggedCopy: () => {},
  translateMainRole: () => {},
  isLoggedIn: false,
});

export const UserProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const { mode, isViewMode } = useViewMode();

  const [user, setUser] = useState<UserLogged | null>(null);
  const [userInformation, setUserInformation] = useState<UserInformation | null>(null);
  const [propertiesIdsOwnerLogged, setPropertiesIdsOwnerLogged] = useState<number[]>([]);
  const [propertiesOwnerLogged, setPropertiesOwnerLogged] = useState<PropertyOwner[]>([]);
  const [propertiesOwnerLoggedCopy, setPropertiesOwnerLoggedCopy] = useState<PropertyOwner[]>([]);
  const [init, setInit] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      const start = localStorage.getItem('startUse');
      const duration = moment.duration(moment().diff(moment(start)));
      const seconds = duration.asSeconds();
      const hours = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds / 60) % 60);
      const secs = Math.floor(seconds % 60);
      ReactGA.event(localStorage.getItem('userDetail') || 'Indefinido', {
        category: localStorage.getItem('userDetail') || 'Indefinido',
        action: `Tempo de acesso ${hours}:${mins}:${secs}`,
      });
      localStorage.removeItem('startUse');
    });
  }, []);

  const loadUser = async () => {
    const userData = localStorage.getItem('sapron-pms-user');
    if (userData) {
      const userDetails = await getUserInformation();

      if (userDetails) {
        setUser(JSON.parse(userData));
        setUserInformation(userDetails);
        setIsLoggedIn(true);
      }
    }
    setInit(true);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const getPropertiesIdsOwnerLogged = useCallback(async () => {
    if (userInformation) {
      const roles = userInformation?.roles || [];
      if (roles.includes('Owner')) {
        const validateMode = (mode && mode) || undefined;
        const response = isViewMode ? await getPropertiesOwner({}, validateMode)
          : await getPropertiesOwner();

        const properties = response.sort((a, b) => compareList(a.code, b.code));
        const ids = properties.map((prop) => Number(prop.id));

        setPropertiesOwnerLogged(properties);
        setPropertiesOwnerLoggedCopy(properties);
        setPropertiesIdsOwnerLogged(ids);
      }
    }
  }, [userInformation]);

  useEffect(() => {
    getPropertiesIdsOwnerLogged();
  }, [getPropertiesIdsOwnerLogged]);

  const handleSetUser = (userData: UserLogged) => {
    localStorage.setItem('sapron-pms-user', JSON.stringify(userData));
    setUser(userData);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('sapron-pms-user');
    localStorage.removeItem('startUse');
    queryClient.removeQueries();
    setIsLoggedIn(false);
  };

  const translateMainRole = (role: string) => {
    switch (role) {
      case 'Admin':
        return 'Administrador';
      case 'Partner':
        return 'Parceiro';
      case 'Host':
        return 'Anfitrião';
      case 'Owner':
        return 'Proprietário';
      case 'SeazoneOnboarding':
        return 'Seazone Integração';
      case 'SeazoneAdministrative':
        return 'Seazone Administrativo';
      case 'Attendant':
        return 'Atendente';
      default:
        return 'Sem role traduzida';
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        translateMainRole,
        setUser: handleSetUser,
        userInformation,
        setUserInformation,
        logout: handleLogout,
        propertiesIdsOwnerLogged,
        setPropertiesIdsOwnerLogged,
        propertiesOwnerLogged,
        setPropertiesOwnerLogged,
        propertiesOwnerLoggedCopy,
        setPropertiesOwnerLoggedCopy,
        isLoggedIn,
      }}
    >
      {init && children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
