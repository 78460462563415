import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { variantItemsX } from '../../../../../../utils/animations';

import {
  EnumType,
  IAllotmentIndication,
  IPropertyIndication,
  IInvestmentIndication,
} from '../../../../../../types';

import Card from './Card';
import { numberToCurrency } from '../../../../../../../../utils/Formatter';

interface ICardsView {
  indicationsLocation: IPropertyIndication[],
  indicationsTerrain: IAllotmentIndication[],
  indicationsSpots: IInvestmentIndication[],
}

const CardsView = ({
  indicationsLocation,
  indicationsTerrain,
  indicationsSpots,
}: ICardsView) => (
  <>
    <AnimatePresence>
      {indicationsLocation?.map((item) => (
        <motion.div
          key={item.id}
          exit={{ x: 10, opacity: 0 }}
          variants={variantItemsX}
        >
          <Card
            key={item.id}
            status={item.status}
            comment={item.comment}
            name={item.owner_name}
            city={item.property_city}
            state={item.property_state}
            number={item.property_number}
            street={item.property_street}
            pipedriveStage={item.pipedrive_stage}
            changedDate={item.updated_at}
            createdDate={item.created_at}
            phone={item.owner_phone_number}
            type={item.type || EnumType.location}
            neighborhood={item.property_neighborhood}
            lostReason={item.lost_reason}
          />
        </motion.div>
      ))}

      {indicationsTerrain?.map((item) => (
        <motion.div
          key={item.id}
          variants={variantItemsX}
          exit={{ x: 10, opacity: 0 }}
        >
          <Card
            key={item.id}
            status={item.status}
            name={item.owner_name}
            comment={item.comment}
            city={item.allotment_city}
            state={item.allotment_state}
            neighborhood={item.allotment_neighborhood}
            street={item.allotment_street}
            number={item.allotment_address_number}
            phone={item.owner_phone_number}
            changedDate={item.updated_at}
            type={item.type || EnumType.location}
            createdDate={item.created_at}
            allotmentValue={numberToCurrency(item.allotment_value)}
          />
        </motion.div>
      ))}

      {indicationsSpots?.map((item) => (
        <motion.div
          key={item.id}
          exit={{ x: 10, opacity: 0 }}
          variants={variantItemsX}
        >
          <Card
            key={item.id}
            status={item.status || 'In_Progress'}
            comment={item.comment}
            name={item.investor_name}
            pipedriveStage={item.pipedrive_stage}
            createdDate={item.created_at || new Date()}
            phone={item.investor_phone}
            changedDate={item?.updated_at || new Date()}
            type={item.type || EnumType.spot}
            lostReason={item.lost_reason}
          />
        </motion.div>
      ))}
    </AnimatePresence>
  </>
);

export default CardsView;
