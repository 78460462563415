import styled, { keyframes } from 'styled-components';

const fadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px)
  } to {
    opacity: 1;
    transform: translateY(0px)
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: 0;
  animation: ${fadeAnimation} 0.8s ease forwards;

  h1 {
    font-weight: bolder;
    font-size: 2rem;
    color: #3333336c;
    opacity: 0.6;
    font-family: ${({ theme }) => theme.fonts.familys._1};

    opacity: 0;
    animation: ${fadeAnimation} 0.8s 0.1s ease forwards;

    span {
      font-weight: bolder;
      font-size: 2rem;
      color: #0078e9d6;
      opacity: 0.6;
      font-family: ${({ theme }) => theme.fonts.familys._1};
    }
  }
`;
