import { useQuery } from 'react-query';
import { PropertiesList } from '../../../services/GuestDamage/types';
import { getPropertiesList } from '../../../services/GuestDamage/request';

type PropertyListHook = () => {
  loading: boolean;
  data: PropertiesList[] | undefined,
};

const usePropertiesList: PropertyListHook = () => {
  const {
    data,
    isFetching: loading,
  } = useQuery(
    'property-manager', async () => getPropertiesList(), {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  return { data, loading };
};

export default usePropertiesList;
