import styled, { css } from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const Container = styled.form`
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  padding: 16px;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    padding: 16px 16px 0px 16px;
  }
`;

export const Content = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  
  ${({ border }) => border && css`
    padding: 16px 0 32px 0;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  `};

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    padding: 8px 0;
    border-bottom: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h4`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  padding-left: 0px;

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 16px;
  }
`;

export const Text = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.03em;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 12px;
    text-align: start;
    line-height: 16px;
  }
`;

export const TextLight = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #969696;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.03em;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 12px;
    text-align: start;
    line-height: 16px;
  }
`;

export const TextBold = styled.h6`
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 14px;
  }
`;

export const FaqContent = styled.div<{ isAdding?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isAdding }) => (isAdding ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)')};
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin-top: 16px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
`;

export const FaqCardContainer = styled.div`
  width: 100%;
`;

export const FaqButton = styled.button<{ expand: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  outline: none;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  min-height: ${({ expand }) => (expand ? '172px' : 'auto')};
  
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    min-height: ${({ expand }) => (expand ? '100px' : 'auto')};
  }
`;

export const ContainerSlider = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 10px;
  padding: 16px 0;

 .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin: 0 8px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.palette.blue._970.hex()} !important;
  }
`;

export const CardPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  border-radius: 10px;
  width: 100%;
`;

export const CardPropertyContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  border-right: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  width: 100%;
  gap: 8px;

  .property-code-content {
    min-width: 70px;
  }
  .keep-funds-content {
    padding: 0 8px 0 0;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    display: flex;
    justify-content: space-between;
    border-right: 0px;
    padding: 16px 0 16px 8px;
    gap: 16px;
    width: 100%;

    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
    }

    p {
      padding: 2px 0;
    }
  }
`;

export const SettingsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px 0;
`;

export const ButtonAddSettings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  
  button {
    max-width: 300px;
    padding: 15px 25px;
    border-radius: 10px;

    width: 100%;

    cursor: pointer;

    border: none;
    background: ${({ theme }) => theme.palette.blue._400.hex()};

    color: ${({ theme }) => theme.palette.white.main.hex()};
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.003px;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    margin-top: 16px; 
    button {
      max-width: 100%;
    }
  }
`;

export const ButtonChangeSettings = styled(ButtonAddSettings)`
  margin-top: 16px;
  justify-content: flex-end;
  align-items: flex-end;
  
  button {
    max-width: 32vw;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    button {
      max-width: 100%;
    }
  }
`;

export const Spacing = styled.div<{ spacing?: number }>`
  margin-top: ${({ spacing }) => (spacing ? `${spacing}px` : '8px')};
`;
