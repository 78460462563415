import * as Yup from 'yup';

import { useFormik } from 'formik';
import { useToastErrorMessage } from '../../../../utils/Messages';
import { useOwnerTedList } from '../../../../hooks/OwnerTedListHook/useOwnerTedList';

import FormButton from '../../../FormButton';
import SimpleSelect from '../../../SimpleSelect';
import TextField from '../../../TextField';

import { IGetOwnerTedsParams } from '../../../../services/OwnerTedList/types';
import { getOwnerTeds } from '../../../../services/OwnerTedList/request';
import { currencyToNumber } from '../../../../utils/Formatter';
import { currency } from '../../../../utils/InputMask/Number';

import {
  FormContent,
  TitleFilter,
  Text,
  Row,
  ContainerButtons,
} from './styles';

const options = [
  {
    id: 'Not_Concluded',
    name: 'Não concluído',
  },
  {
    id: 'Concluded',
    name: 'Concluído',
  },
  {
    id: 'Pending',
    name: 'Pendente',
  },
  {
    id: 'Exception',
    name: 'Exceção',
  },
];

interface Props {
  onClose: () => void;
}

const FormTedFilters = ({ onClose }: Props) : JSX.Element => {
  const toastErrorRequest = useToastErrorMessage();
  const { handleListItems, filters } = useOwnerTedList();

  const validation = Yup.object().shape({
    transferDay: Yup.number().min(1, 'Valor mínimo = 1').max(31, 'Valor máximo = 31'),
  });

  const formik = useFormik({
    initialValues: {
      tedStatus: undefined,
      transferValue: undefined,
      transferDay: undefined,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const params: IGetOwnerTedsParams = {
          period: filters.period !== '' ? filters.period : undefined,
          termToFilter: filters.termToFilter !== '' ? filters.termToFilter : undefined,
          status: values.tedStatus !== '' ? values.tedStatus : undefined,
          transfer_day: values.transferDay !== '' ? values.transferDay : undefined,
          transfer: values.transferValue !== '' ? currencyToNumber(currency(`${values.transferValue}`)) : undefined,
        };

        const response = await getOwnerTeds(params);
        handleListItems(response.formatDataToListItem);
        onClose();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <FormContent onSubmit={formik.handleSubmit}>
      <TitleFilter>
        <Text>Valor do repasse</Text>
      </TitleFilter>
      <Row>
        <TextField
          id="transferValue"
          placeholder="Insira o valor EXATO"
          formik={formik}
          mask="money"
        />
      </Row>

      <TitleFilter>
        <Text>Dia do repasse</Text>
      </TitleFilter>
      <Row>
        <TextField
          id="transferDay"
          placeholder="Digite aqui..."
          formik={formik}
          type="number"
          mask="integerNumber"
        />
      </Row>

      <TitleFilter>
        <Text>Status do repasse</Text>
      </TitleFilter>
      <Row>
        <SimpleSelect
          id="tedStatus"
          placeholder="Selecione"
          formik={formik}
          options={(options).map(({ id, name }) => ({
            value: id,
            valueLabel: name,
          }))}
        />
      </Row>

      <ContainerButtons>
        <FormButton variant="outlined" type="button" onClick={onClose}>Cancelar</FormButton>
        <FormButton type="submit">Aplicar</FormButton>
      </ContainerButtons>
    </FormContent>
  );
};

export default FormTedFilters;
