import React from 'react';
import { StyledPhone } from './style';
import { IFormik } from '../../types';
import { UseFormik } from '../../../../../../../../../utils/Formik/types';
import { useMobile } from '../../../../../../../../../hooks/useMobile/useMobile';
import { usePreCheckin } from '../../../../../../../../../context/PreCheckin/PreCheckin';

interface IPhone {
  formik: UseFormik<IFormik>
}

const Phone = ({ formik }: IPhone) => {
  const { mode } = usePreCheckin();
  const { isMobile } = useMobile();

  return (
    <StyledPhone
      disabled={mode.is === 'view'}
      country={'br'}
      autoFormat={false}
      enableLongNumbers
      value={formik.values.phone}
      enableClickOutside={!isMobile}
      onChange={(value) => formik.setFieldValue('phone', value)}
    />
  );
};

export default Phone;
