import React, { useEffect } from 'react';

// import {Checkbox} from '@mui/material';

import Tooltip from '../../../../Tooltip';

import editIcon from '../../../../../assets/icons/generals/edit.svg';

import {
  Item,
  Title,
  Container,
  EditButton,
  TotalValue,
  PendingValue,
  ItemContainer,
  ReservationCode,
  ReservationStaff,
  CheckBoxContainer,
  TitleReservationCode,
  ContainerWithRedText,
} from './styles';

import { usePaymentVoucher } from '../../../../../hooks/PaymentVoucherHook/usePaymentVoucher';

import { ColorProps, CardDataTypeProps } from '../../types';

import Guest from '../../../../../assets/icons/multicalendar/guest.svg';
import Finance from '../../../../../assets/icons/multicalendar/finance.svg';
import RingBell from '../../../../../assets/icons/multicalendar/ring-bell.svg';
import GuestForm from '../../../ReservationDataModal/GuestModal';
import FinanceForm from '../../../../Calendar/Reservation/ModalFinance';
import { useModal } from '../../../../../hooks/ModalHook/useModal';
import ReservationDataModal from '../../../ReservationDataModal';
import { LateralModal } from '../../../..';

interface BodyProps {
  id: number;
  index: number;
  checkIn: string;
  color: ColorProps;
  reservationCode?: string;
  reservationStaff?: string;
  totalValue?: number | string;
  pendingValue?: number | string;
  cardDataType?: CardDataTypeProps;
  reservationId: number;
}

const options = [
  { icon: RingBell, alt: 'Ring Bell', title: 'Dados da reserva' },
  { icon: Guest, alt: 'Guest', title: 'Hóspede' },
  { icon: Finance, alt: 'Finance', title: 'Financeiro' },
];

const Body = ({
  id,
  color,
  index,
  checkIn,
  totalValue,
  pendingValue,
  reservationId,
  cardDataType,
  reservationCode,
  reservationStaff,
}: BodyProps) => {
  const {
    checkedList,
    paymentDataStatus,
    handleChangeChecked,
    handleChangeModalOpen,
    handleChangeCheckedList,
    handleChangeCheckedCounter,
    handleChangePaymentDataStatus,
    handleChangeReservationIdEditedButton,
  } = usePaymentVoucher();

  const { modalActive } = useModal();

  const [openReservatioDataModal, setOpenReservationDataModal] = React.useState(false);

  const handleOpenModalReservationData = () => {
    setOpenReservationDataModal(true);
  };

  const getPaymentDataStatusCompletesOrPendings = () => (cardDataType === 'completes' ? paymentDataStatus.completes : paymentDataStatus.pendings);

  const handleAddingCheckedItemsToCheckedList = () => {
    let newCheckedList = checkedList;
    newCheckedList = {
      pendings: paymentDataStatus.pendings.filter((item) => item.checked === true),
      completes: paymentDataStatus.completes.filter((item) => item.checked === true),
    };
    handleChangeCheckedList(newCheckedList);
  };

  // const handleChangeCheckedBox = () => {
  //   const newPaymentDataStatusCardType = getPaymentDataStatusCompletesOrPendings();

  //   const newPaymentDataStatusChecked = newPaymentDataStatusCardType.map((item) => {
  //     if (item.id === id) {
  //       return {
  //         ...item,
  //         checked: !item.checked,
  //       };
  //     }
  //     return item;
  //   });

  //   if (cardDataType === 'pending') {
  //     handleChangePaymentDataStatus({
  //       ...paymentDataStatus,
  //       pendings: newPaymentDataStatusChecked,
  //     });
  //   } else {
  //     handleChangePaymentDataStatus({
  //       ...paymentDataStatus,
  //       completes: newPaymentDataStatusChecked,
  //     });
  //   }
  // };

  // const handleClick = () => {
  //   handleChangeCheckedBox();
  //   handleChangeCheckedValueByCheckedListLenght();
  // };

  const handleChangeCheckedValueByCheckedListLenght = () => {
    const totalCheckedCompletes = paymentDataStatus.completes
      .filter((item) => item.checked === true).length;
    const totalCheckedPendings = paymentDataStatus.pendings
      .filter((item) => item.checked === true).length;

    const totalChecked = totalCheckedCompletes + totalCheckedPendings;
    handleChangeCheckedCounter(totalChecked);
    handleChangeChecked(totalChecked > 0);
  };

  const handleOpenOnlyReservation = (reservationIdItem: number) => {
    let newCheckedList;

    handleChangeModalOpen(true);
    handleChangeCheckedCounter(0);
    handleChangeReservationIdEditedButton(reservationId);

    if (cardDataType === 'pending') {
      newCheckedList = ({
        ...paymentDataStatus,
        pendings: paymentDataStatus.pendings.map((item) => {
          if (item.id === reservationIdItem) {
            return {
              ...item,
              checked: true,
            };
          }
          return item;
        }),
      });
    } else {
      newCheckedList = ({
        ...paymentDataStatus,
        completes: paymentDataStatus.completes.map((item) => {
          if (item.id === reservationIdItem) {
            return {
              ...item,
              checked: true,
            };
          }
          return item;
        }),
      });
    }
    handleChangePaymentDataStatus(newCheckedList);
    handleChangeCheckedList(newCheckedList);
  };

  function calcIfMissing4Days() {
    const daysMissing = new Date(checkIn).getDate() - new Date().getDate();
    if (daysMissing < 0 && daysMissing > -4) {
      return Math.abs(daysMissing - 2);
    }
    return false;
  }

  useEffect(() => {
    handleChangeCheckedValueByCheckedListLenght();
    handleAddingCheckedItemsToCheckedList();
  }, [paymentDataStatus]);

  return (
    <>
      <Container>
        <ItemContainer
          color={color}
          index={index < 20 ? index : 0}
          checked={getPaymentDataStatusCompletesOrPendings()?.[index]?.checked}
        >
          <Item>
            <CheckBoxContainer>
              {/* <Checkbox
                color="primary"
                checked={getPaymentDataStatusCompletesOrPendings()?.[index]?.checked}
                onClick={() => handleClick()}
              /> */}
            </CheckBoxContainer>

            <ReservationCode>
              <Tooltip text="Ir para detalhes do imóvel">
                <TitleReservationCode onClick={() => handleOpenModalReservationData()}>
                  {reservationCode}
                  ...
                </TitleReservationCode>
              </Tooltip>
            </ReservationCode>

            <ContainerWithRedText>
              <Title>{checkIn}</Title>
              {calcIfMissing4Days() && (
              <p>
                Faltam
                {' '}
                {calcIfMissing4Days()}
                d
              </p>
              )}
            </ContainerWithRedText>

            <ReservationStaff>
              <Title>{reservationStaff}</Title>
            </ReservationStaff>

            <TotalValue>
              <Title>{`${totalValue}`}</Title>
            </TotalValue>

            <PendingValue>
              <Title>{`${pendingValue}`}</Title>
            </PendingValue>

            <EditButton onClick={() => id && handleOpenOnlyReservation(id)}>
              <Tooltip text="Editar reserva">
                <img src={editIcon} alt="icone de edição" />
              </Tooltip>
            </EditButton>
          </Item>
        </ItemContainer>
      </Container>

      <LateralModal
        open={openReservatioDataModal}
        setOpen={setOpenReservationDataModal}
        activeTabs
        options={options}
        headerTitle="Dados da reserva"
      >
        <>
          {modalActive === 0 && (
          <ReservationDataModal
            comment={''}
            idReservation={reservationId}
          />
          )}
          {modalActive === 1 && (
            <GuestForm idReservation={reservationId} />
          )}
          {modalActive === 2 && (
            <FinanceForm />
          )}
        </>
      </LateralModal>
    </>
  );
};

Body.defaultProps = {
  totalValue: '1280,00',
  cardDataType: 'default',
  pendingValue: '2500,00',
  reservationCode: '00425583483983',
  reservationStaff: 'Gabriel Fischer',
};

export default Body;
