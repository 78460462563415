import React from 'react';
import { OwnerTedList as TedListMainComponent } from '../../components/OwnerTedList';
import { CustomToastProvider } from '../../context/CustomToastContext';
import { OwnerTedListProvider } from '../../context/OwnerTedList/OwnerTedListContext';

import { Container } from './styles';

const OwnerTedList = () => (
  <OwnerTedListProvider>
    <Container>
      <CustomToastProvider>
        <TedListMainComponent />
      </CustomToastProvider>
    </Container>
  </OwnerTedListProvider>
);

export default OwnerTedList;
