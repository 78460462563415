import {
  useEffect,
} from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import { UseFormik } from '../../../../../../utils/Formik/types';

import { compareList } from '../../../../../../utils/Sorting';
import { useToast } from '../../../../../../context/ToastContext';
import { SelectProps } from '../../../../../Autocomplete/Autocomplete';
import { getLocations } from '../../../../../../services/location/request';
import { getCategories } from '../../../../../../services/Category/request';

import Backdrop from '../../../../../Backdrop';
import FormButton from '../../../../../FormButton';
import FormGridRow from '../../../../../FormGridRow';
import DropdownAutocomplete from '../../../../../DropdownAutocomplete';

import { usePropertyCategoryLocation } from '../../../../../../hooks/usePropertyCategoryLocation/usePropertyCategoryLocation';

import {
  Title,
  Content,
  Container,
  CloseButton,
  TitleContainer,
  HeaderContainer,
  ButtonsContainer,
  ButtonNewCodeContainer,
} from './styles';
import NewCategoryCodeModal from '../Code';
import NewLocationCodeModal from '../LocationCode';
import { postCategoryLocation } from '../../../../../../services/InsertData/request';

type PropertyType = 'House' | 'Apartment' | 'Hotel';
type PropertyStatus = 'Active' | 'Inactive' | 'Onboarding' | 'Closed';

interface InsertDataPropertyFormik {
  code: string;
  singleBedQuantity?: number;
  doubleBedQuantity?: number;
  queenBedQuantity?: number;
  kingBedQuantity?: number;
  singleSofaBedQuantity?: number;
  doubleSofaBedQuantity?: number;
  pillowQuantity?: number;
  bedroomQuantity?: number;
  bathroomQuantity?: number;
  lavatoryQuantity?: number;
  boundAmount?: string;
  guestCapacity?: number;
  propertyType?: PropertyType;
  status?: PropertyStatus;
  activationDate?: Date;
  inactivationDate?: Date;
  contractStartDate?: Date;
  contractEndDate?: Date;
  street: string;
  number?: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  host?: number;
  owners?: number;
  cleaningFee: string;
  comissionFee: string;
  categoryLocation?: string;
  category: number;
  location: number;
}

interface NewCategoryLocationModalProps {
  closeModal: () => void;
  formik?: UseFormik<InsertDataPropertyFormik>;
}

const NewCategoryLocationModal = ({
  closeModal,
  formik,
}: NewCategoryLocationModalProps) => {
  const {
    categoriesLocations,
    categories,
    setCategories,
    setOpenModalNewCategoryCode,
    locations,
    setLocations,
    setOpenModalNewLocationCode,
    setIsNewCategoryLocation,
    openModalNewLocationCode,
    openModalNewCategoryCode,
  } = usePropertyCategoryLocation();

  const toast = useToast();

  const categoryIds: string[] = [];
  const categoryNames: string[] = [];
  const locationIds: string[] = [];
  const locationNames: string[] = [];

  const getLabelCategoryLocation = (categoryId: number, locationId: number) => {
    const indexCategory = categoryIds.findIndex((id) => Number(id) === categoryId);
    const indexLocation = locationIds.findIndex((id) => Number(id) === locationId);

    const categoryLabel = (categoryNames?.[indexCategory] || '');
    const locationLabel = (locationNames?.[indexLocation] || '');

    return `${locationLabel}${categoryLabel}`.toUpperCase().trim();
  };

  const getSelectedCategory = (): SelectProps[] => {
    categories.forEach(async (category) => {
      categoryIds.push(`${category.id}`);
      categoryNames.push(`${category.code}`);
    });

    return categoryNames
      .map((name, index) => ({
        optionText: name,
        optionValue: categoryIds[index],
      }))
      .sort((a, b) => compareList(a.optionText, b.optionText));
  };

  const getSelectLocation = (): SelectProps[] => {
    locations.forEach(async (location) => {
      locationIds.push(`${location.id}`);
      locationNames.push(`${location.code}`);
    });

    return locationNames
      .map((name, index) => ({
        optionText: name,
        optionValue: locationIds[index],
      }))
      .sort((a, b) => compareList(a.optionText, b.optionText));
  };

  const getAllCategories = async () => {
    const results = await getCategories();
    setCategories(results);
  };

  const getAllLocations = async () => {
    const results = await getLocations();
    setLocations(results);
  };

  useEffect(() => {
    getAllCategories();
    getAllLocations();
  }, []);

  const validation = Yup.object().shape({
    category: Yup.number().required('Campo obrigatório'),
    location: Yup.number().required('Campo obrigatório'),
  });

  const formikAux = useFormik({
    initialValues: {
      category: -1,
      location: -1,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      const category = Number(values.category);
      const location = Number(values.location);

      const alreadyExists = categoriesLocations.some((item) => item.code.toUpperCase()
        .trim() === getLabelCategoryLocation(category, location));

      if (alreadyExists) {
        toast.alert('A categoria informada já existe!');
      } else {
        setIsNewCategoryLocation(true);
        try {
          const response = await postCategoryLocation({
            category,
            location,
          });

          formik?.setFieldValue('categoryLocationId', response);
          formik?.setFieldValue('categoryLocationCode', getLabelCategoryLocation(category, location));
          formik?.setFieldValue('category', category);
          formik?.setFieldValue('location', location);

          closeModal();

          toast.success('Categoria criada com sucesso');
        } catch {
          toast.error('Erro ao enviar categoria');
        }
      }
    },
  });

  return (
    <Backdrop onClose={closeModal}>
      {openModalNewCategoryCode && <NewCategoryCodeModal />}
      {openModalNewLocationCode && <NewLocationCodeModal />}

      <Container
        onSubmit={formikAux.handleSubmit}
        onContextMenu={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderContainer>
          <TitleContainer>
            <Title>Nova Categoria</Title>
          </TitleContainer>
          <CloseButton onClick={closeModal}>
            <CloseIcon />
            Fechar
          </CloseButton>
        </HeaderContainer>
        <Content>
          <FormGridRow grid>
            <Grid item sm={6} xs={12}>
              <DropdownAutocomplete
                label="Código da categoria"
                id="category"
                options={getSelectedCategory()}
                placeholder="Digite aqui..."
                labelClassName="labelClass"
                formik={formikAux}
                required
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ButtonNewCodeContainer>
                <FormButton
                  type="button"
                  onClick={() => setOpenModalNewCategoryCode(true)}
                  variant="outlined"
                >
                  Novo código
                </FormButton>
              </ButtonNewCodeContainer>
            </Grid>
          </FormGridRow>

          <FormGridRow grid>
            <Grid item sm={6} xs={12}>
              <DropdownAutocomplete
                label="Código da localização"
                options={getSelectLocation()}
                formik={formikAux}
                id="location"
                placeholder="Digite aqui..."
                labelClassName="labelClass"
                required
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ButtonNewCodeContainer>
                <FormButton
                  type="button"
                  onClick={() => setOpenModalNewLocationCode(true)}
                  variant="outlined"
                >
                  Novo código
                </FormButton>
              </ButtonNewCodeContainer>
            </Grid>
          </FormGridRow>
        </Content>
        <ButtonsContainer>
          <FormButton type="button" variant="outlined" onClick={closeModal}>Cancelar</FormButton>
          <FormButton type="submit">Salvar</FormButton>
        </ButtonsContainer>
      </Container>
    </Backdrop>
  );
};

NewCategoryLocationModal.defaultProps = {
  formik: undefined,
};

export default NewCategoryLocationModal;
