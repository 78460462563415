import React, {
  createContext, useState, ReactNode, useContext,
} from 'react';
import { Conciliation, PaymentArbnbConciliation, ReservationConciliation } from '../../services/Conciliation/types';

interface ConciliationContextData {
  paymentMove: PaymentArbnbConciliation;
  setPaymentMove: (a: PaymentArbnbConciliation) => void;
  paymentSelected: PaymentArbnbConciliation;
  addNewPayment: () => void;
  removePayment: () => void;
  addNewReservation: () => void;
  reservation: ReservationConciliation;
  setReservation: (a: ReservationConciliation) => void;
  removeReservation: () => void;
  reservationMove: ReservationConciliation,
  setReservationMove: (a: ReservationConciliation) => void;
  listReservation: ReservationConciliation[],
  setListReservation:(a: ReservationConciliation[]) => void;
  listPayments: PaymentArbnbConciliation[],
  setListPayments:(a: PaymentArbnbConciliation[]) => void;
  listConciliations: Conciliation[];
  setListConciliations: (a: Conciliation[]) => void;
}

interface Props {
  children: ReactNode;
}

const ConciliationContext = createContext<ConciliationContextData>({} as
  ConciliationContextData);

export const ConciliationProvider = ({
  children,
}: Props) => {
  const [paymentMove,
    setPaymentMove] = useState<PaymentArbnbConciliation>({} as PaymentArbnbConciliation);
  const [paymentSelected,
    setPaymentSelected] = useState<PaymentArbnbConciliation>({} as PaymentArbnbConciliation);
  const [reservationMove,
    setReservationMove] = useState<ReservationConciliation>({} as ReservationConciliation);
  const [reservation,
    setReservation] = useState<ReservationConciliation>({} as ReservationConciliation);
  const [listReservation,
    setListReservation] = useState<ReservationConciliation[]>([]);
  const [listPayments,
    setListPayments] = useState<PaymentArbnbConciliation[]>([]);
  const [listConciliations, setListConciliations] = useState<Conciliation[]>([]);

  function addNewPayment() {
    const paymentsAux = paymentMove;
    setPaymentSelected(paymentsAux);
  }

  function addNewReservation() {
    const reservationAux = reservationMove;
    setReservation(reservationAux);
  }

  function removePayment() {
    setPaymentSelected({} as PaymentArbnbConciliation);
  }

  function removeReservation() {
    setReservation({} as ReservationConciliation);
  }

  return (
    <ConciliationContext.Provider value={{
      paymentMove,
      setPaymentMove,
      paymentSelected,
      addNewPayment,
      addNewReservation,
      removePayment,
      setReservation,
      reservation,
      reservationMove,
      setReservationMove,
      removeReservation,
      listReservation,
      setListReservation,
      listPayments,
      setListPayments,
      listConciliations,
      setListConciliations,

    }}
    >
      {children}
    </ConciliationContext.Provider>
  );
};

export function useConciliation(): ConciliationContextData {
  const {
    paymentMove, setPaymentMove, paymentSelected,
    addNewPayment, addNewReservation, removePayment, setReservation, removeReservation,
    reservation,
    reservationMove,
    setReservationMove,
    listReservation,
    setListReservation,
    listPayments,
    setListPayments,
    listConciliations,
    setListConciliations,
  } = useContext(ConciliationContext);
  return {
    paymentMove,
    setPaymentMove,
    paymentSelected,
    addNewPayment,
    addNewReservation,
    removePayment,
    setReservation,
    reservation,
    reservationMove,
    setReservationMove,
    removeReservation,
    listReservation,
    setListReservation,
    listPayments,
    setListPayments,
    listConciliations,
    setListConciliations,
  };
}
