import { FiltersType } from '../Conciliation/types';
import request from '../request';
import {
  BedArrangementType,
  CompReservationsType,
  Guest,
  GuestReservation,
  PreCheckinAdminUpdateType,
  SendEmailType,
} from './types';

export const getCompReservations = async (params?: Array<FiltersType>):
Promise<Array<CompReservationsType>> => {
  let response;
  let urlParamsEncode = '?';
  if (params && params?.length > 0) {
    params.forEach((item) => {
      urlParamsEncode += `${item.name}=${item.value}&`;
    });
  }
  if (params) {
    response = await request.get<CompReservationsType[]>(`/precheckin/admin/${urlParamsEncode}`);
  } else {
    response = await request.get<CompReservationsType[]>('/precheckin/admin/');
  }

  const { data } = response;

  const dataFormatted = data.map((item) => ({
    ...item,
    bed_arrangement: item?.bed_arrangement || '',
    bed_arrangement_list: item?.bed_arrangement?.replace(' ', '')?.trim()?.split(',') || [''],
    guest_owner: item?.guest_owner || {},
    property: item?.property || {},
    address: item?.address || {},
    guests: item?.guests || [],
    is_pre_checkin_link_sent: item.is_pre_checkin_link_sent,
  }));

  return dataFormatted as CompReservationsType[];
};

export const getBedArrangement = async (id: number): Promise<Array<BedArrangementType>> => {
  const { data } = await request.get('/location/bedarrangement', {
    params: {
      location: id,
    },
  });
  return data as BedArrangementType[];
};

export const patchCompReservations = async (params: PreCheckinAdminUpdateType, id: number) => {
  const { data } = await request.patch(`/precheckin/admin/${id}/`, params);
  return data as CompReservationsType;
};

export const postGuestReservations = async (params: GuestReservation) => {
  const payload = {
    ...params,
    document: params.document === '' ? null : params.document,
    front_document_photo: params.front_document_photo === '' ? null : params.front_document_photo,
    back_document_photo: params.back_document_photo === '' ? null : params.back_document_photo,
    email: params.email === '' ? null : params.email,
  };

  const { data } = await request.post('/reservation_guests/', payload);
  return data as Guest;
};

export const patchGuestReservations = async (id: number, params: GuestReservation) => {
  const payload = {
    ...params,
    document: params.document === '' ? null : params.document,
    front_document_photo: params.front_document_photo === '' ? null : params.front_document_photo,
    back_document_photo: params.back_document_photo === '' ? null : params.back_document_photo,
    email: params.email === '' ? null : params.email,
  };

  const { data } = await request.patch(`/reservation_guests/${id}/`, payload);
  return data as Guest;
};

export const deleteGuestReservations = async (id: number) => {
  const { data } = await request.delete(`/reservation_guests/${id}/`);
  return data;
};

export const emailNotification = async (params: SendEmailType) => {
  const { data } = await request.put('/property/notify/', params);
  return data;
};
