import { useOwnerPerspectiveDashboard } from '../../../../../context/OwnerPerspectiveDashboard/OwnerPerspectiveDashboard';
import LoadingMoreData from '../LoadingMoreData';
import Row from '../Row/Row';

const SelectView = () => {
  const {
    viewOwners,
  } = useOwnerPerspectiveDashboard();

  return (
    <>
      {viewOwners?.owners?.map((row) => (
        <>
          <Row key={row.id} row={row} />
        </>
      ))}
      <LoadingMoreData />
    </>
  );
};

export default SelectView;
