/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';

import {
  Close,
} from '@mui/icons-material';
// @ts-ignore

import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import {
  Container,
  AreaClose,
  ButtonCloseModal,
  ContentButtons,
  ButtonCancel,
  ButtonConfirm,
  BoldText,
  DataGrid,
  TitleModal,
  BoldTextMargin,
  ContainerName,
  MidlleText,
  Row,
  Value,
  Line,
  SimpleText,
  Notes,
} from './styles';
import DatePickerRange from '../../DatePickerRange';
import FormButton from '../../FormButton/FormButton';
import { GuestFormData, RequestGuest } from '../../../services/Guest/types';
import { numberToCurrency, numberToPhone } from '../../../utils/Formatter';
import FormGridRow from '../../FormGridRow';
import TextField from '../../TextField/TextField';

import {
  GenderSelect, RadioTypePerson, FormAddress, DatePicker, QuantityField, RadioYesOrNo,
} from '../..';
import AutoComplete from '../../Autocomplete';
import { getGuest, postGuest, putGuestReservation } from '../../../services/Guest/request';
import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import { useLoader } from '../../../context/LoaderContext';
import { SelectProps } from '../../Autocomplete/Autocomplete';
import CardResumeProperty from '../CardResumeProperty';
import { useEstimateCart } from '../../../context/EstimateCart';
import { postMonthlyReservation } from '../../../services/MonthlyBudget/request';
import { MonthlyReservationPost } from '../../../services/MonthlyBudget/types';
import { EditGuest } from '../../Calendar/Reservation/Modal/styles';

interface Props {
  handleCloseModalLateral: () => void;
  checkin: string;
  checkout: string;

}

const ModalGenerateContract = ({
  handleCloseModalLateral,
  checkin,
  checkout,
}: Props): JSX.Element => {
  const [guests, setGuests] = useState<RequestGuest[]>([]);
  const [isNewGuest, setIsNewGuest] = useState<boolean>(false);
  const [isEditingGuest, setIsEditingGuest] = useState<boolean>(false);
  const { estimateCart } = useEstimateCart();
  const toast = useToast();
  const { setLoad } = useLoader();
  const toastErrorRequest = useToastErrorMessage();

  function replaceAll(
    string: string, search: string, replace: string,
  ) {
    return string.split(search).join(replace);
  }

  function formatCNPJToPost(cnpj: string) {
    return replaceAll(
      replaceAll(
        replaceAll(
          cnpj, '.', '',
        ), '/', '',
      ), '-', '',
    );
  }

  const validation = !isNewGuest
    ? Yup.object().shape({
      checkInDate: Yup.date().required(),
      checkOutDate: Yup
        .date()
        .when('checkInDate',
          (checkInDate: any, schema: Yup.DateSchema) => (
            checkInDate && schema.min(checkInDate, 'A data deve ser maior que data de check-in')))
        .required(),
      adults: Yup.number().moreThan(0, 'O valor deve ser maior que 0').required(),
      notes: Yup.string().required(),
    })
    : Yup.object().shape({
      email: Yup.string().required().email('E-mail inválido'),
      adults: Yup.number().moreThan(0, 'O valor deve ser maior que 0').required(),
      phone1: Yup.string().required(),
      phone2: Yup.string(),
      corporate_name: Yup.string(),
      trading_name: Yup.string(),
      checkInDate: Yup.date().required(),
      checkOutDate: Yup.date().required(),
      lastName: Yup.string().required(),
      gender: Yup.string().required(),
      born: Yup.string().required(),
      typePerson: Yup.string().required(),
      cpf: Yup.string(),
      cnpj: Yup.string(),
      rg: Yup.string().required(),
      street: Yup.string().required(),
      zipCode: Yup.string().required(),
      neighborhood: Yup.string().required(),
      city: Yup.string().required(),
      state: Yup.string().required(),
      country: Yup.string().required(),
      number: Yup.string(),
      notes: Yup.string().required(),
    });

  const initialValues = {
    checkInDate: checkin,
    checkOutDate: checkout,
    corporateName: '',
    tradingName: '',
    phone1: '',
    phone2: '',
    guest: '',
    firstName: '',
    lastName: '',
    email: '',
    adults: 0,
    children: 0,
    notes: '',
    hasPet: false,
    petsNumber: 0,
    typePerson: 'individual',
    cpf: '',
    cnpj: '',
    rg: '',
    born: new Date(),
    contractDate: new Date(),
    gender: '',
    street: '',
    zipCode: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    country: 'BR',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        let idGuestReservation = values.guest;
        if (isEditingGuest === true) {
          const guestSelected = guests.find((item) => item.id === Number(values.guest));
          if (guestSelected) {
            try {
              const editingGuest : GuestFormData = {
                ...guestSelected,
                id: Number(values.guest),
                user: {
                  ...guestSelected.user,
                  main_role: 'Guest',
                  gender: values.gender,
                  birth_date: values.born.toString(),
                  is_individual: values.typePerson === 'individual',
                  cpf: replaceAll(
                    replaceAll(
                      values.cpf, '.', '',
                    ), '-', '',
                  ),
                  cnpj: formatCNPJToPost(values.cnpj).length > 14
                    ? formatCNPJToPost(values.cnpj).slice(0, -1) : formatCNPJToPost(values.cnpj),
                  corporate_name: values.corporateName,
                  trading_name: values.tradingName,
                  id: guestSelected.user.id,
                  first_name: values.firstName,
                  last_name: values.lastName,
                  email: values.email,
                  phone_number1: values.phone1,
                  phone_number2: values.phone2,
                },
              };
              if (editingGuest.user.cpf === guestSelected.user.cpf || values.typePerson !== 'individual') {
                delete editingGuest.user.cpf;
              }
              if (values.typePerson === 'individual' || guestSelected.user.cnpj === editingGuest.user.cnpj) {
                delete editingGuest.user.cnpj;
              }
              await putGuestReservation(Number(values.guest), editingGuest);

              try {
                const contractData = await postMonthlyReservation({
                  guest: parseInt(idGuestReservation, 10),
                  property: estimateCart?.categories[0]?.properties[0]?.id,
                  start_date: moment(values.checkInDate).format('YYYY-MM-DD'),
                  end_date: moment(values.checkOutDate).format('YYYY-MM-DD'),
                  total_price: estimateCart?.categories[0].properties[0].totalPrice,
                  signature_date: moment(values.contractDate).format('YYYY-MM-DD'),
                  adult_guest_quantity: values.adults,
                  child_guest_quantity: values.children,
                  has_pet: values.petsNumber,
                  comment: values.notes,
                } as MonthlyReservationPost);

                window.open(contractData.monthly_contract.url, '_blank');

                toast.success('Reserva efetuada com sucesso!');
              } catch (e: unknown) {
                if (e instanceof Error) {
                  toastErrorRequest(e);
                }
              }
            } catch (e: unknown) {
              if (e instanceof Error) {
                toastErrorRequest(e);
              }
            }
          }
        } else if (isNewGuest) {
          try {
            const id = await postGuest({
              main_role: 'Guest',
              gender: values.gender,
              birth_date: values.born.toString(),
              is_individual: values.typePerson === 'individual',
              cpf: replaceAll(
                replaceAll(
                  values.cpf.length > 11 ? values.cpf.slice(0, -1) : values.cpf, '.', '',
                ), '-', '',
              ),
              cnpj: formatCNPJToPost(values.cnpj).length > 14
                ? formatCNPJToPost(values.cnpj).slice(0, -1) : formatCNPJToPost(values.cnpj),
              corporate_name: values.corporateName,
              trading_name: values.tradingName,
              first_name: values.guest,
              last_name: values.lastName,
              email: values.email,
              phone_number1: values.phone1,
              phone_number2: values.phone2,
              main_address: {
                city: values.city,
                country: values.country,
                state: values.state,
                neighborhood: values.neighborhood,
                number: values.number,
                postal_code: values.zipCode,
                street: values.street,
              },
            });
            idGuestReservation = id;

            try {
              const contractData = await postMonthlyReservation({
                guest: parseInt(idGuestReservation, 10),
                property: estimateCart?.categories[0]?.properties[0]?.id,
                start_date: moment(values.checkInDate).format('YYYY-MM-DD'),
                end_date: moment(values.checkOutDate).format('YYYY-MM-DD'),
                total_price: estimateCart?.categories[0].properties[0].totalPrice,
                signature_date: moment(values.contractDate).format('YYYY-MM-DD'),
                adult_guest_quantity: values.adults,
                child_guest_quantity: values.children,
                has_pet: values.petsNumber,
                comment: values.notes,
              } as MonthlyReservationPost);

              window.open(contractData.monthly_contract.url, '_blank');

              toast.success('Reserva efetuada com sucesso!');
            } catch (e: unknown) {
              if (e instanceof Error) {
                toastErrorRequest(e);
              }
            }
          } catch (e: unknown) {
            if (e instanceof Error) {
              toastErrorRequest(e);
            }
          }
        } else {
          try {
            const contractData = await postMonthlyReservation({
              guest: parseInt(idGuestReservation, 10),
              property: estimateCart?.categories[0]?.properties[0]?.id,
              start_date: moment(values.checkInDate).format('YYYY-MM-DD'),
              end_date: moment(values.checkOutDate).format('YYYY-MM-DD'),
              total_price: estimateCart?.categories[0].properties[0].totalPrice,
              signature_date: moment(values.contractDate).format('YYYY-MM-DD'),
              adult_guest_quantity: values.adults,
              child_guest_quantity: values.children,
              has_pet: values.petsNumber,
              comment: values.notes,
            } as MonthlyReservationPost);

            window.open(contractData.monthly_contract.url, '_blank');

            toast.success('Reserva efetuada com sucesso!');
          } catch (e: unknown) {
            if (e instanceof Error) {
              toastErrorRequest(e);
            }
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
      setLoad(false);
      return values;
    },
  });

  const selectedGuest = (): null | RequestGuest & { phone: string } => {
    const guest = guests.find((item) => item.id === Number(formik.values.guest));
    if (!guest || isNewGuest) {
      return null;
    }

    const phone = guest.user.phone_number1 || guest.user.phone_number2;

    return {
      ...guest,
      phone: numberToPhone(phone, true),
    };
  };

  useEffect(() => {
    const stringDate = selectedGuest()?.user?.birth_date || '02/01/2010';
    const dateSplitted = stringDate.split('/');
    const dateFormatted = dateSplitted.length === 3 ? `${dateSplitted[2]}-${dateSplitted[1]}-${dateSplitted[0]}` : '1850-01-02';

    formik.setValues({
      ...initialValues,
      guest: formik.values.guest,
      firstName: selectedGuest()?.user?.first_name || '',
      lastName: selectedGuest()?.user?.last_name || '',
      email: selectedGuest()?.user?.email || '',
      born: new Date(dateFormatted),
      phone1: selectedGuest()?.user?.phone_number1 || '',
      phone2: selectedGuest()?.user?.phone_number2 || '',
      cnpj: selectedGuest()?.user?.cnpj || '',
      cpf: selectedGuest()?.user?.cpf || '',
      rg: selectedGuest()?.user?.rg || '',
      gender: selectedGuest()?.user?.gender || '',
    });
  }, [isEditingGuest]);

  const handleGetGuest = async (value: string): Promise<SelectProps[]> => {
    const guestsData = await getGuest(value);
    setGuests(guestsData);
    return guestsData.map((guest) => ({
      optionText: `${guest.user.first_name} ${guest.user.last_name}`,
      optionValue: guest.id.toString(),
      cpf: guest.user.cpf,
      cnpj: guest.user.cnpj,
    }));
  };

  const removeGuest = () => {
    formik.setFieldValue('guest', '');
    setIsNewGuest(false);
  };

  return (
    <>
      <AreaClose onClick={() => handleCloseModalLateral()} />
      <Container
        onSubmit={formik.handleSubmit}
        onChange={formik.handleChange}
      >
        <ButtonCloseModal onClick={() => handleCloseModalLateral()}>
          <Close />
          <p>Fechar</p>
        </ButtonCloseModal>
        <TitleModal>Gerar contrato de reserva mensal</TitleModal>
        <CardResumeProperty
          code={estimateCart?.categories[0]?.properties[0]?.code || ''}
          idProperty={estimateCart?.categories[0]?.properties[0]?.id || 0}
          address={estimateCart?.categories[0]?.properties[0]?.address || {}}
        />
        <div className="content-dates">
          <BoldText>*Datas selecionadas</BoldText>
          <DataGrid>
            <DatePickerRange
              formik={formik}
              hasInitialDates
              id1="checkInDate"
              id2="checkOutDate"
              minDate={new Date('1910-01-02')}
              showingLabel={false}
            />
          </DataGrid>
        </div>
        <BoldTextMargin>Hóspede</BoldTextMargin>
        <ContainerName>
          {(selectedGuest() && !isEditingGuest) && (
          <EditGuest>
            <BoldText>
              {`${selectedGuest()?.user?.first_name} ${selectedGuest()?.user?.last_name}`}
            </BoldText>
            <MidlleText>
              {`${selectedGuest()?.phone} - ${selectedGuest()?.user?.email}`}
            </MidlleText>
            <FormButton
              type="button"
              link
              onClick={() => setIsEditingGuest(true)}
            >
              Editar Informações
            </FormButton>
            <FormButton
              type="button"
              link
              onClick={removeGuest}
            >
              Trocar de hóspede
            </FormButton>
          </EditGuest>
          )}
          {selectedGuest() && isEditingGuest && (
          <>
            <FormGridRow>
              <TextField
                label="*Nome"
                formik={formik}
                id="firstName"
                dataCy="firstName"
                value={formik.values.firstName}
                pattern="[a-z\s]+$"
              />
            </FormGridRow>
            <FormGridRow>
              <TextField
                label="*Sobrenome"
                formik={formik}
                id="lastName"
                dataCy="lastName"
                value={formik.values.lastName}
              />
            </FormGridRow>

            <FormGridRow>
              <TextField
                label="*E-mail"
                formik={formik}
                id="email"
                dataCy="email"
                value={formik.values.email}
              />
            </FormGridRow>

            <FormGridRow grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="*Telefone Principal"
                  placeholder="+00 (00) 00000-0000"
                  formik={formik}
                  id="phone1"
                  dataCy="phone1"
                  mask="phone"
                  value={formik.values.phone1}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Telefone Secundário"
                  placeholder="+00 (00) 00000-0000"
                  formik={formik}
                  id="phone2"
                  dataCy="phone2"
                  mask="phone"
                  value={formik.values.phone2}
                />
              </Grid>
            </FormGridRow>

            <FormGridRow grid>
              <Grid item sm={6} xs={12}>
                <GenderSelect formik={formik} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <DatePicker
                  formik={formik}
                  label="*Data de nasc."
                  id="born"
                  dataCy="born"
                  minDate={new Date('902-01-02')}
                  viewsCustom={['day', 'month', 'year']}
                  disableCloseOnSelect={false}
                />
              </Grid>
            </FormGridRow>
            <FormGridRow>
              <RadioTypePerson
                labelPlacement="end"
                id="typePerson"
                formik={formik}
              />
            </FormGridRow>

            {formik.values.typePerson === 'legal' && (
            <>
              <FormGridRow grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="*CNPJ"
                    formik={formik}
                    id="cnpj"
                    dataCy="cnpj"
                    mask="cnpj"
                    value={formik.values.cnpj}
                  />
                </Grid>
              </FormGridRow>

              <FormGridRow grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Razão Social"
                    formik={formik}
                    id="corporateName"
                    dataCy="corporateName"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Nome Fantasia"
                    formik={formik}
                    id="tradingName"
                    dataCy="tradingName"
                  />
                </Grid>
              </FormGridRow>
            </>
            )}

            <FormGridRow grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="CPF"
                  formik={formik}
                  id="cpf"
                  dataCy="cpf"
                  mask="cpf"
                  value={formik.values.cpf}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="*Nº de identidade"
                  formik={formik}
                  id="rg"
                  dataCy="rg"
                  value={selectedGuest()?.user?.rg}
                />
              </Grid>
            </FormGridRow>
            <FormAddress formik={formik} />
          </>
          )}
          {!selectedGuest() && (
          <FormGridRow>
            <AutoComplete
              label="*Pesquise por: Nome, CPF ou CNPJ"
              formik={formik}
              id="guest"
              dataCy="guest"
              addOption="+ Novo hóspede"
              asyncOptions={handleGetGuest}
              setNewValue={setIsNewGuest}
            />
          </FormGridRow>
          )}
          { formik.values.guest && formik.values.guest !== '' && isNewGuest && (
          <>
            <FormGridRow>
              <TextField
                label="*Sobrenome"
                formik={formik}
                id="lastName"
                dataCy="lastName"
              />
            </FormGridRow>

            <FormGridRow>
              <TextField
                label="*E-mail"
                formik={formik}
                id="email"
                dataCy="email"
              />
            </FormGridRow>

            <FormGridRow grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="*Telefone Principal"
                  placeholder="+00 (00) 00000-0000"
                  formik={formik}
                  id="phone1"
                  dataCy="phone1"
                  mask="phone"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Telefone Secundário"
                  placeholder="+00 (00) 00000-0000"
                  formik={formik}
                  id="phone2"
                  dataCy="phone2"
                  mask="phone"
                />
              </Grid>
            </FormGridRow>

            <FormGridRow grid>
              <Grid item sm={6} xs={12}>
                <GenderSelect formik={formik} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <DatePicker
                  formik={formik}
                  label="*Data de nasc."
                  id="born"
                  dataCy="born"
                  minDate={new Date('1902-01-02')}
                  viewsCustom={['day', 'month', 'year']}
                  disableCloseOnSelect={false}
                />
              </Grid>
            </FormGridRow>
            <FormGridRow>
              <RadioTypePerson
                labelPlacement="end"
                id="typePerson"
                formik={formik}
              />
            </FormGridRow>

            {formik.values.typePerson === 'legal' && (
            <>
              <FormGridRow grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="*CNPJ"
                    formik={formik}
                    id="cnpj"
                    dataCy="cnpj"
                    mask="cnpj"
                  />
                </Grid>
              </FormGridRow>

              <FormGridRow grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Razão Social"
                    formik={formik}
                    id="corporateName"
                    dataCy="corporateName"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Nome Fantasia"
                    formik={formik}
                    id="tradingName"
                    dataCy="tradingName"
                  />
                </Grid>
              </FormGridRow>
            </>
            )}

            <FormGridRow grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="CPF"
                  formik={formik}
                  id="cpf"
                  dataCy="cpf"
                  mask="cpf"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="*Nº de identidade"
                  formik={formik}
                  id="rg"
                  dataCy="rg"
                />
              </Grid>
            </FormGridRow>
            <FormAddress formik={formik} />
          </>
          )}
        </ContainerName>
        <div className="content-date">
          <BoldText>*Data de assinatura do contrato</BoldText>
          <DatePicker
            formik={formik}
            id="contractDate"
            minDate={new Date('2000-01-02')}
          />
        </div>
        <QuantityField id="adults" title="*Adultos" formik={formik} dataCy="btn-add-adults" />
        <QuantityField id="children" title="Crianças" formik={formik} dataCy="btn-add-children" />

        <RadioYesOrNo
          formLabel="*Vai levar pet?"
          id="hasPet"
          labelPlacement="end"
          formik={formik}
        />

        {formik.values.hasPet.toString() === 'true' && (
        <QuantityField
          id="petsNumber"
          title="Pets"
          initialQuantity={1}
          formik={formik}
          dataCy="btn-add-pets"
        />
        )}
        <BoldText>Resumo</BoldText>
        <Row>
          <SimpleText>Taxa de limpeza</SimpleText>
          <Line />
          <Value>
            {numberToCurrency(estimateCart?.categories[0]?.properties[0]?.monthlyPrice[0].cleaning_fee)}

          </Value>
        </Row>
        <Row>
          <SimpleText>Valor da reserva</SimpleText>
          <Line />
          <Value>
            {numberToCurrency(estimateCart?.categories[0]?.properties[0]?.monthlyPrice[0].subtotal)}
          </Value>
        </Row>
        <Row>
          <SimpleText>Valor total</SimpleText>
          <Line />
          <Value>
            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(estimateCart?.categories[0]?.properties[0]?.totalPrice)}
          </Value>
        </Row>
        <Notes>
          <FormGridRow>
            <TextField
              label="Notas"
              formik={formik}
              id="notes"
              type="textarea"
              placeholder=" "
              dataCy="notes"
            />
          </FormGridRow>
        </Notes>
        <ContentButtons>
          <ButtonCancel onClick={() => handleCloseModalLateral()}>Cancelar</ButtonCancel>
          <ButtonConfirm data-cy="btn-contract-generate" type="submit">
            Gerar contrato e inserir reserva
          </ButtonConfirm>
        </ContentButtons>
      </Container>
    </>
  );
};

export default ModalGenerateContract;
