import { useEffect } from 'react';
import { Modal } from '@mui/material';
import TextField from '../../../TextField';
import AutoComplete from '../../../Autocomplete';

import { NewCategoryLocationModal } from '.';
import { compareList } from '../../../../utils/Sorting';
import { UseFormik } from '../../../../utils/Formik/types';
import { SelectProps } from '../../../Autocomplete/Autocomplete';
import { getCategoryLocationForSelect } from '../../../../services/InsertData/request';
import { usePropertyCategoryLocation } from '../../../../hooks/usePropertyCategoryLocation/usePropertyCategoryLocation';
import { Container } from './style';

interface ICategory {
  formik: UseFormik<any>;
  labelClassName?: string;
}

const Category = ({ formik, labelClassName = 'labelClass' }: ICategory) => {
  const {
    categoriesLocations,
    isNewCategoryLocation,
    setCategoriesLocations,
    setIsNewCategoryLocation,
    openModalCategoryLocation,
    setOpenModalCategoryLocation,
  } = usePropertyCategoryLocation();

  const categoryLocationIds: string[] = [];
  const categoryLocationNames: string[] = [];

  const getSelectCategoryLocation = async () => {
    const results = await getCategoryLocationForSelect();
    setCategoriesLocations(results);
  };

  const handleGetCategoryLocation = (): SelectProps[] => {
    categoriesLocations.forEach(async (categoryLocation) => {
      categoryLocationIds.push(`${categoryLocation.id}`);
      categoryLocationNames.push(`${categoryLocation.code}`);
    });

    return categoryLocationNames
      .map((name, index) => ({
        optionText: name,
        optionValue: categoryLocationIds[index],
      }))
      .sort((a, b) => compareList(a.optionText, b.optionText));
  };

  const handleChangeCategory = () => {
    if (formik?.values?.categoryLocationId !== '' && isNewCategoryLocation) {
      setIsNewCategoryLocation(false);
      formik?.setFieldValue('categoryLocationId', '');
    }
  };

  const getCategorySelected = (categoryId: number) => {
    const index = categoryLocationIds.findIndex((id) => Number(id) === categoryId);
    return categoryLocationNames?.[index] || '';
  };

  const handleCloseModal = () => {
    setOpenModalCategoryLocation(false);
  };

  useEffect(() => {
    getSelectCategoryLocation();
  }, []);

  useEffect(() => {
    const adefinirIndex = categoriesLocations.findIndex((category) => ['', 'undefined', 'null', 'adefinir'].includes(`${category.code}`.toLowerCase().trim()));

    if (adefinirIndex !== -1 && ['', 'undefined', 'null', 'adefinir'].includes(`${formik?.values?.categoryLocation}`.toLowerCase().trim())) {
      const adefinirId = categoriesLocations[adefinirIndex].id;
      formik?.setFieldValue('categoryLocationId', adefinirId);
    }
  }, [formik?.values?.categoryLocationId, categoriesLocations]);

  return (
    <>
      {openModalCategoryLocation && (
        <Modal open>
          <NewCategoryLocationModal
            formik={formik}
            closeModal={handleCloseModal}
          />
        </Modal>
      )}

      <Container>
        {isNewCategoryLocation ? (
          <TextField
            id="not"
            required
            label="Categoria"
            labelClassName={labelClassName}
            onChange={() => handleChangeCategory()}
            placeholder={
            formik?.values?.categoryLocationCode
              ? formik?.values?.categoryLocationCode
              : 'Digite aqui...'
            }
            value={formik?.values?.categoryLocationCode}
          />
        ) : (
          <AutoComplete
            required
            isNormalWeight
            labelClassName={labelClassName}
            formik={formik}
            label="Categoria"
            id="categoryLocationId"
            addOption="+ Nova categoria"
            options={handleGetCategoryLocation()}
            setNewValue={setOpenModalCategoryLocation}
            placeholder={
            formik?.values?.categoryLocationId
              ? `${getCategorySelected(Number(formik?.values?.categoryLocationId))}`
              : 'Digite aqui...'
          }
          />
        )}
      </Container>
    </>
  );
};

Category.defaultProps = {
  labelClassName: 'labelClassName',
};

export default Category;
