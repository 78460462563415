import { useContextSelector } from 'use-context-selector';
import { PropertyAmenitiesContext } from '../../context/InsertPropertyAmenitiesContext/InsertPropertyAmenitiesContext';

export function usePropertyAmenities() {
  const allAmenities = useContextSelector(PropertyAmenitiesContext, (state) => state.allAmenities);
  const handleSetAllAmenities = useContextSelector(PropertyAmenitiesContext,
    (state) => state.setAllAmenities);
  const handleUpdateAmenitie = useContextSelector(PropertyAmenitiesContext,
    (state) => state.handleUpdateAmenitie);

  return {
    allAmenities,
    handleSetAllAmenities,
    handleUpdateAmenitie,
  };
}
