import phone from './img/phone.jpg';
import worker from './img/worker.jpg';
import seazone from './img/seazone.jpg';
import contract from './img/contract.jpg';

export const cardsData = [
  {
    id: 0,
    title: 'Institucional',
    description: 'Aprenda sobre a história da Seazone e como se tornou referência no mercado imobiliário!',
    image: {
      source: seazone,
      alt: 'seazone',
    },
    access: 'https://seazone.com.br/sobre-a-empresa/',
    withShareButton: {
      is: true,
      link: 'https://seazone.com.br/sobre-a-empresa/',
    },
  },
  {
    id: 1,
    title: 'E-book',
    description: 'Baixe o "Guia definitivo para rentabilizar imóveis" da Seazone, um conteúdo exclusivo para compartilhar com proprietários e alcançar resultados juntos',
    image: {
      source: phone,
      alt: 'Telefone',
    },
    access: 'open pdf',
    withShareButton: {
      is: true,
      link: 'https://marketing.seazone.com.br/guia-definitivo-rentabilizar-imovel#rd-box-l8byke6o',
    },
  },
  {
    id: 2,
    title: 'Mapa de área de atuação',
    description: 'Descubra as áreas de atuação da Seazone! Explore o mapa para conferir a área de cobertura e potencialize o resultado de suas indicações. ',
    image: {
      source: worker,
      alt: 'trabalhador',
    },
    access: 'https://www.google.com/maps/d/viewer?mid=1W7ivGvlo6hGKx-fa9mWIPyg8OFncixw&ll=-22.388727970158094%2C-42.84507501927432&z=10',
    withShareButton: {
      is: false,
      link: '',
    },
  },
  {
    id: 3,
    title: 'Blog',
    description: 'Encontre no nosso blog: vantagens de ser parceiro, indicações de imóveis de sucesso, histórias inspiradoras e dicas de investimento.',
    image: {
      source: contract,
      alt: 'aperto de mão',
    },
    access: 'https://seazone.com.br/blog/',
    withShareButton: {
      is: false,
      link: '',
    },
  },
] as const;
