import React from 'react';
import ViewOwnersComponent from '../../components/ViewOwnersComponent';

// import { Container } from './styles';

const ViewOwners: React.FC = () => (
  <ViewOwnersComponent />
);

export default ViewOwners;
