import { useContextSelector } from 'use-context-selector';
import { CustomPopupsContext } from '../../context/CustomPopupsContext/CustomPopupsContext';

export function useCustomPopups() {
  const popupsList = useContextSelector(CustomPopupsContext, (state) => state.popupsList);
  const setPopupsList = useContextSelector(CustomPopupsContext, (state) => state
    .setPopupsList);

  return {
    popupsList,
    setPopupsList,
  };
}
