import * as Yup from 'yup';

export const LandSchema = Yup.object().shape({
  state: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  neighborhood: Yup.string().required('Campo obrigatório'),
  address: Yup.string().required('Campo obrigatório'),
  coordinates: Yup.string().when('address', {
    is: (address: string) => `${address}`.split(',').length < 2,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string(),
  }),
  allotmentArea: Yup.string().required('Campo obrigatório'),
  allotmentDimensions: Yup.string(),
  allotmentValue: Yup.string().required('Campo obrigatório'),
  documentLink: Yup.string(),
  photoLink: Yup.string(),
  comment: Yup.string(),
});

export const LocationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  street: Yup.string(),
  number: Yup.string(),
  neighborhood: Yup.string(),
  complement: Yup.string(),
  propertyType: Yup.string().required('Campo obrigatório'),
  comment: Yup.string(),
});

export const SpotSchema = Yup.object().shape({
  enterprise: Yup.string().required('Campo obrigatório'),
  unit: Yup.string(),
  email: Yup.string()
    .nullable()
    .notRequired()
    .test('is-valid-email', 'E-mail inválido', (value) => {
      if (value && value.length > 0) {
        return Yup.string().email().isValidSync(value);
      }
      return true;
    }),
  phone: Yup.string().required('Campo obrigatório'),
  comment: Yup.string(),
});
