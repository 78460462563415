import moment from 'moment';
import { useToast } from '../../context/ToastContext';

export const ErrorMessage: Record<string, Function> = {
  default: () => 'Ocorreu um erro inesperado, tente novamente mais tarde!',
  expiredToken: () => 'Sua sessão expirou!',
  invalidDate: () => 'Data inválida',
  minDate: (minDate: Date): string => `A data minima aceita é ${moment(minDate).format('DD/MM/YYYY')}`,
  required: () => 'Campo obrigatório',
  invalid: () => 'Valor inválido',
  addressDefaultErro: () => 'Endereço não encontrado',
};

type ToastFunction = (errorFields: any, defaultMessage?: string) => void;

export const useToastErrorMessage = (): ToastFunction => {
  const toast = useToast();

  return (errorFields: any, defaultMessage?: string) => {
    if (typeof errorFields.detail === 'object') {
      const flatError = (error: any): string[] => {
        const errors = Object.keys(error).map((key) => {
          if (Array.isArray(error[key])) {
            let message = error[key];
            error[key].forEach((element: string) => {
              if (element === 'user with this email address already exists.') {
                message = 'Já existe um usuário com este endereço de e-mail.';
              } else if (element === 'The phone number entered is not valid.') {
                message = 'O número de telefone inserido não é válido.';
              } else if (element === 'check out should occur after check in') {
                message = 'A data de check-out deve ser maior que a  data de check-in.';
              } else if (element === 'Listing not found') {
                message = 'Não foi encontrado um anúncio para este imóvel.';
              } else if (element === 'Reservation already exists within this date') {
                message = 'Já existe uma reserva nesta data.';
              } else if (element === 'Ensure this field has no more than 11 characters.') {
                message = 'O campo CPF possui mais de 11 caracteres.';
              } else if (element === 'User age must be over 12 and under 123 years.') {
                message = 'A idade do usuário deve estar entre 12 e 123 anos.';
              } else if (element === 'The fields reservation, checklist must make a unique set.') {
                message = 'Nenhum campo foi alterado';
              } else if (element === 'This field may not be blank.') {
                message = 'O campo não pode estar em branco.';
              } else if (element === 'This field may not be null.') {
                message = 'O campo de hóspede não foi preenchido.';
              } else if (element === 'This field must be unique.') {
                message = 'O e-mail deve ser diferente dos demais cadastrados.';
              } else if (element === 'Ensure that there are no more than 2 decimal places') {
                message = 'Certifique-se de que não haja mais de 2 casas decimais';
              } else if (element === 'The fields property, ota must make a unique set.') {
                message = 'Já existe listing para este imóvel nesta OTA';
              } else if (element === 'File is not CSV type') {
                message = 'O arquivo selecionado não é um CSV';
              } else if (element === 'This property code already exists and is active') {
                message = 'Esse código de imóvel já existe e está ativo!';
              } else if (element === 'This password is too common.') {
                message = 'Esta senha é muito comum.';
              } else if (element === 'This password is too short. It must contain at least 8 characters.') {
                message = 'Esta senha é muito curta. Deve conter pelo menos 8 caracteres';
              } else if (element === 'Password fields didn\'t match.') {
                message = 'Os campos de senha não correspondem.';
              } else if (element === 'user with this pipedrive person id already exists.') {
                message = 'Esse Person ID já está cadastrado.';
              } else if (element === 'property has extra day preparation') {
                message = 'Não é possível inserir um bloqueio, pois o imóvel necessita de tempo de preparo antes e depois do período selecionado. Tente selecionar um novo período que respeite esse intervalo.';
              }
            });
            return message;
          }
          return flatError(error[key]).flat();
        });
        return errors.flat();
      };
      toast.error(flatError(errorFields?.detail));
      return;
    }

    toast.error(defaultMessage || ErrorMessage.default());
  };
};
