import { useFormik } from 'formik';
import { useMemo } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { compareList } from '../../../../../utils/Sorting';
import DatePicker from '../../../../DatePicker/DatePicker';
import DropdownAutocomplete from '../../../../DropdownAutocomplete/DropdownAutocomplete';
import FormButton from '../../../../FormButton/FormButton';
import SimpleSelect, { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import { ReconcileDamageReservationFormContainer } from './styles';
import TextField from '../../../../TextField/TextField';
import usePropertiesList from '../../../../../hooks/GuestDamage/usePropertiesList';
import { formatPropertiesDropdown } from '../../../utils/format-properies-to-dropdown';
import { GetGuestDamageNegotiation, ReservationDetails, ReservationDetailsParams } from '../../../../../services/GuestDamage/types';
import { getGuestDamageNegotiation, getReservation } from '../../../../../services/GuestDamage/request';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../../utils/Messages';
import { useReservations } from '../../../../../hooks/GuestDamage/useReservations';

const platformOptions: SelectOption[] = [
  { value: 'Airbnb', valueLabel: 'Airbnb' },
  { value: 'Stays', valueLabel: 'Stays' },
  { value: 'Booking', valueLabel: 'Booking' },
  { value: 'Temporada Livre', valueLabel: 'Temporada Livre' },
  { value: 'Expedia', valueLabel: 'Expedia' },
  { value: 'Seazone', valueLabel: 'Seazone' },
  { value: 'Decolar', valueLabel: 'Decolar' },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

interface ReconcileDamageReservationFormData {
  propertyCode?: string;
  guestName?: string;
  platform?: string;
  checkInDate?: Date | null;
  checkOutDate?: Date | null;
}

const validationSchema = Yup.object().shape({
  checkInDate: Yup.date().nullable(),
  checkOutDate: Yup.date().nullable(),
  propertyCode: Yup.string(),
  guestName: Yup.string(),
  platform: Yup.string(),
}).test(
  'checkin-or-checkout', 'Pelo menos um dos campos (check-in ou check-out) deve ser preenchido',
  function error(values) {
    const {
      checkInDate, checkOutDate, guestName, platform, propertyCode,
    } = values;
    if (guestName) {
      return false;
    }
    if (platform) {
      return false;
    }
    if (propertyCode) {
      return false;
    }
    if ((checkInDate === null && checkOutDate === null)) {
      return this.createError({
        path: 'checkInDate',
        message: 'Pelo menos uma das datas (check-in ou check-out) deve ser preenchida',
      });
    }
    return true;
  },
);

function removeExistReservations(reservations: ReservationDetails[],
  guestDamagesNegotiation: GetGuestDamageNegotiation[]): ReservationDetails[] {
  const guestDamagesReservationIds = guestDamagesNegotiation
    .map((negotiation) => negotiation.reservation?.id!);
  return reservations
    .filter((reservation) => !guestDamagesReservationIds.includes(reservation?.id!));
}

export const ReconcileDamageReservationForm = () => {
  const { setIsLoading, setReservations } = useReservations();
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const initialValues: ReconcileDamageReservationFormData = {
    propertyCode: undefined,
    guestName: undefined,
    platform: undefined,
    checkInDate: null,
    checkOutDate: null,
  };
  const formik = useFormik<ReconcileDamageReservationFormData>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const filters: ReservationDetailsParams = {
          check_in_date: values.checkInDate === null ? undefined : moment(values.checkInDate).format('YYYY-MM-DD'),
          check_out_date: values.checkOutDate === null ? undefined : moment(values.checkOutDate).format('YYYY-MM-DD'),
          ota_name: values.platform,
          guest_name: values.guestName,
          property_code: values.propertyCode,
        };
        const data = await getReservation(filters);
        const guestDamageNegotiations = await getGuestDamageNegotiation();
        const otherReservations = removeExistReservations(data, guestDamageNegotiations);
        const filteredData = otherReservations.filter((reservation) => reservation.ota_name !== 'Blocking');
        if (filteredData.length === 0) {
          setIsLoading(false);
          toast.alert('Nenhuma reserva encontrada!');
          setReservations([]);
        } else {
          setIsLoading(false);
          setReservations(filteredData);
        }
      } catch (e) {
        if (e instanceof Error) {
          setIsLoading(false);
          toastErrorRequest(e);
        }
      }
    },
  });

  const {
    data: properties,
  } = usePropertiesList();

  const filteredProperties = useMemo(() => {
    const resolver = properties?.filter((property) => property.status === 'Active' || property.status === 'Onboarding');
    return resolver;
  }, [properties]);

  const formattedPropertiesToShow = useMemo(() => {
    const resolver = formatPropertiesDropdown(filteredProperties);
    return resolver;
  }, [filteredProperties]);

  return (
    <ReconcileDamageReservationFormContainer onSubmit={formik.handleSubmit}>
      <DropdownAutocomplete
        label="Imóvel"
        id="propertyCode"
        options={formattedPropertiesToShow}
        placeholder="Digite aqui..."
        formik={formik}
      />
      <TextField
        label="Nome do hóspede"
        id="guestName"
        placeholder="Digite aqui..."
        formik={formik}
        value={formik.values.guestName}
      />
      <SimpleSelect
        options={platformOptions}
        label="Plataforma"
        id="platform"
        placeholder="Selecione"
        formik={formik}
      />
      <DatePicker
        formik={formik}
        id="checkInDate"
        label="Data de check-in"
        disableFuture
      />
      <DatePicker
        formik={formik}
        id="checkOutDate"
        label="Data de check-out"
        disableFuture
      />
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        width: '100%',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
      >
        <div style={{ padding: '3px' }} />
        <FormButton type="submit">Buscar</FormButton>
      </div>
    </ReconcileDamageReservationFormContainer>
  );
};
