import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import MuiModal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FormButton from '../../FormButton/FormButton';

import {
  Item,
  Footer,
  Header,
  Section,
  Content,
  Alert,
  ButtonsWrapper,
} from './style';

import Tooltip from '../../Tooltip';
import CheckLink from './CheckLink/CheckLink';
import { useToast } from '../../../context/ToastContext';

export type IModal = {
  open: boolean;
  token: string;
  isHotel: boolean;
  reservation: {
    code: string | undefined;
    owner: string | undefined;
    check_in: string | undefined;
    id: string | undefined;
    reservationID: number | undefined;
  };
};

interface IPreCheckinModal {
  modal: IModal;
  setModal: React.Dispatch<React.SetStateAction<IModal>>;
  callback: Function;
  is_pre_checkin_link_sent: boolean;
}

const ModalPreCheckinConfirmation = ({
  modal,
  setModal,
  callback,
  is_pre_checkin_link_sent,
}: IPreCheckinModal) => {
  const [enableSave, setEnableSave] = useState(is_pre_checkin_link_sent || false);
  const toast = useToast();

  const handleModal = (open: boolean) => {
    setModal((prev) => ({
      ...prev,
      open,
    }));
  };

  const handleClickLink = () => {
    setModal((old) => ({ ...old, open: false }));
  };

  const handleMessage = () => {
    if (modal.token) {
      navigator.clipboard.writeText(`Olá esse é o link para efetuar o pré checkin: \n ${modal.token}`);
      toast.alert('Copiado para a àrea de transferencia');
    }
  };

  const handleToken = () => {
    if (modal.token) {
      navigator.clipboard.writeText(modal.token);
      toast.alert('Copiado para a àrea de transferencia');
    }
  };

  const handleClose = () => {
    setModal((old) => ({ ...old, open: false }));
  };

  useEffect(() => {
    if (is_pre_checkin_link_sent) {
      setEnableSave(is_pre_checkin_link_sent);
    }
  }, [is_pre_checkin_link_sent]);

  return (
    <MuiModal
      open={modal.open}
      onClose={() => handleModal(false)}
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

      }}
    >
      <Content>
        <Header>
          <h2>Pré Checkin</h2>
          <Close
            style={{ cursor: 'pointer', alignSelf: 'baseline' }}
            onClick={() => handleModal(false)}
          />
        </Header>
        <Section style={{ marginBottom: '1rem' }}>
          <Item>
            <h2>Imóvel</h2>
            <p>{modal.reservation?.code || 'Não informado'}</p>
          </Item>
          <Item>
            <h2>Dono da reserva</h2>
            <p>{modal.reservation?.owner || 'Não informado'}</p>
          </Item>
          <Item>
            <h2>Check-in</h2>
            <p>
              {moment(modal.reservation?.check_in)?.format('DD/MM/YYYY')
                || 'Não informado'}
            </p>
          </Item>
        </Section>
        {modal.isHotel === true && (
          <Alert className="alert">
            <p>
              <strong>Atenção:</strong>
              {' '}
              O arranjo de camas desta reserva deve ser inserido manualmente na
              tela de
              {' '}
              <Tooltip text="Clique para ir ate a tela">
                <Link to="/compreservas" onClick={handleClickLink}>Completar dados da reserva.</Link>
              </Tooltip>
            </p>
          </Alert>
        )}
        <Section style={{ flexDirection: 'column', marginBottom: '2rem' }}>
          <p>Copiar token para enviar ao hóspede</p>
          <ButtonsWrapper>
            <motion.button
              type="button"
              onClick={handleToken}
              whileTap={{ scale: 0.95 }}
            >
              Copiar apenas o link
            </motion.button>
            <motion.button
              type="button"
              onClick={handleMessage}
              whileTap={{ scale: 0.95 }}
            >
              Copiar o link e a mensagem
            </motion.button>
          </ButtonsWrapper>
        </Section>
        <CheckLink
          callback={callback}
          id={modal?.reservation?.reservationID}
          setEnableSave={setEnableSave}
          is_pre_checkin_link_sent={is_pre_checkin_link_sent}
        />
        <Footer>
          <FormButton
            variant={!enableSave ? 'outlined' : undefined}
            disable={!enableSave}
            type="button"
            onClick={handleClose}
          >
            Salvar
          </FormButton>
          <FormButton variant="outlined" type="button" onClick={handleClose}>
            Cancelar
          </FormButton>
        </Footer>
      </Content>
    </MuiModal>
  );
};

export default ModalPreCheckinConfirmation;
