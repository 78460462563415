import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { InputAdornment } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Eye, EyeOff } from 'react-feather';

import { handleRequestsCreateHostOpsModal } from '../../services/RequestHostOpsModal/request';
import { onlyNumbers } from '../../utils/Formatter';

import { useToast } from '../../context/ToastContext';
import { useToastErrorMessage } from '../../utils/Messages';
import { useUser } from '../../context/UserContext';

import FormButton from '../FormButton/FormButton';
import TextField from '../TextField/TextField';
import SimpleSelect from '../SimpleSelect/SimpleSelect';

import {
  RequestHostOpsModalBackdrop,
  RequestHostOpsModalButtonsContainer,
  RequestHostOpsModalContent,
  RequestHostOpsModalForm,
  RequestHostOpsModalTitle,
  ButtonClose,
} from './styles';

interface RequestHostOpsModalProps{
  handleCloseModal: () => void;
}

const genderOptions = [
  {
    value: 'Not informed',
    valueLabel: 'Não informado',
  },
  {
    value: 'Male',
    valueLabel: 'Masculino',
  },
  {
    value: 'Female',
    valueLabel: 'Feminino',
  },
];

export default function RequestHostOpsModal({ handleCloseModal }: RequestHostOpsModalProps) {
  const [passwordType, setPasswordType] = useState<string>('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState<string>('password');
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const numberRegex = /.*[0-9].*/;
  const lowercaseRegex = /.*[a-z].*/;
  const uppercaseRegex = /.*[A-Z].*/;
  const specialCharRegex = /.*[!@#$%^&*()_\-+={}[\]|:;"'<>,.?/].*/;
  const commonPasswords = [
    'user',
    'email',
    'teste',
    'senha',
    'admin',
    '123456',
    'password',
    'username',
    'teste1234',
    'teste123!',
    'Teste123!',
  ];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email('Digite um e-mail valido').required(),
    password: Yup.string()
      .required('A senha é obrigatória')
      .matches(specialCharRegex, 'A senha deve conter pelo menos um carácter especial')
      .matches(uppercaseRegex, 'A senha deve conter pelo menos uma letra maiúscula')
      .matches(lowercaseRegex, 'A senha deve conter pelo menos uma letra minúscula')
      .matches(numberRegex, 'A senha deve conter pelo menos um número')
      .notOneOf(commonPasswords, 'A senha não pode ser uma senha comum')
      // .not([Yup.ref('email')], () => 'Seu email não pode ser igual à senha')
      // .not([Yup.ref('firstName')], () => 'Seu nome não pode ser igual à senha')
      // .not([Yup.ref('lastName')], () => 'Seu sobrenome não pode ser igual à senha')
      // @ts-ignore
      .min(8, 'A senha deve ter pelo menos 8 caracteres'),

    passwordConfirmation: Yup.string().required('É necessário confirmar a senha')
      .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais'),
  });

  const { userInformation } = useUser();
  const hostId = userInformation?.host?.id || 0;

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      nickName: '',
      email: '',
      gender: 'Not informed',
      password: '',
      passwordConfirmation: '',
      cpf: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const channel = process.env.NODE_ENV !== 'production' ? '#sapron' : '#suporte-sapron';

        await handleRequestsCreateHostOpsModal(
          {
            channel,
            fallback: '',
            color: 'good',
            username: 'Sapron Notify',
            icon_emoji: ':seazone:',
            fields: [{
              title: 'Criar perfil Host Ops',
              value: `Criar novo Host Ops com as credenciais:\n\n - Primeiro nome: ${values.firstName}\n - Último nome:${values.lastName}\n - Email: ${values.email}\n - Senha sugerida: ${values.password}`,
            }],
          },
          {
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            password: values.password,
            password_confirmation: values.passwordConfirmation,
            gender: values.gender,
            main_role: 'Host',
            nickname: values.nickName,
            cnpj: '',
            cpf: onlyNumbers(values.cpf),
          }, hostId,
        );

        toast.success('Solicitação feita com sucesso!');
        handleCloseModal();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });
  const { handleSubmit } = formik;

  return (
    <RequestHostOpsModalBackdrop onClick={handleCloseModal}>
      <RequestHostOpsModalContent onClick={(e) => e.stopPropagation()}>
        <RequestHostOpsModalTitle>
          <h2>Solicitar perfil de usuário operacional</h2>
          <ButtonClose type="button" onClick={handleCloseModal}>
            <Close fontSize="large" />
          </ButtonClose>
        </RequestHostOpsModalTitle>
        <RequestHostOpsModalForm onSubmit={handleSubmit}>
          <div className="two-itens">
            <TextField required formik={formik} id="firstName" label="Primeiro nome" placeholder="Digite..." />
            <TextField required formik={formik} id="lastName" label="Último nome" placeholder="Digite..." />
          </div>
          <TextField required formik={formik} id="nickName" label="Apelido" placeholder="Digite..." />
          <TextField required formik={formik} id="email" label="E-mail" type="email" placeholder="Digite..." />
          <div className="two-itens">
            <TextField required formik={formik} id="cpf" label="CPF" placeholder="Digite..." mask="cpf" />
            <SimpleSelect
              required
              id="gender"
              label="Gênero"
              className="selector"
              options={genderOptions}
              placeholder="Selecione..."
              firstValue="not_informed"
              formik={formik}
            />
          </div>

          <TextField
            required
            formik={formik}
            id="password"
            label="Senha"
            type={passwordType}
            endAdornment={(
              <InputAdornment position="start">
                {passwordType === 'password'
                  ? <EyeOff onClick={() => setPasswordType('text')} strokeWidth={'1'} cursor="pointer" size={20} color="#62656E" />
                  : <Eye onClick={() => setPasswordType('password')} strokeWidth={'1'} cursor="pointer" size={20} color="#62656E" />}
              </InputAdornment>
                )}
          />
          <TextField
            required
            formik={formik}
            id="passwordConfirmation"
            label="Confirme sua senha"
            type={confirmPasswordType}
            endAdornment={(
              <InputAdornment position="start">
                {confirmPasswordType === 'password'
                  ? <EyeOff onClick={() => setConfirmPasswordType('text')} strokeWidth={'1'} cursor="pointer" size={20} color="#62656E" />
                  : <Eye onClick={() => setConfirmPasswordType('password')} strokeWidth={'1'} cursor="pointer" size={20} color="#62656E" />}
              </InputAdornment>
                )}
          />
          <RequestHostOpsModalButtonsContainer>
            <FormButton variant="outlined" type="button" onClick={handleCloseModal}>Cancelar</FormButton>
            <FormButton type="submit">Solicitar</FormButton>
          </RequestHostOpsModalButtonsContainer>
        </RequestHostOpsModalForm>
      </RequestHostOpsModalContent>
    </RequestHostOpsModalBackdrop>
  );
}
