import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { createContext } from 'use-context-selector';

import { GridOwnerProps } from './types';
import { getFinancialCloseOwnerUpdated } from './utils';

interface IFinancialCloseOwner {
  financialDataOwner: GridOwnerProps[],
  setFinancialDataOwner: Function,
  updateFinancialDataOwner: Function,
  updateCheckedOfOwnerSelected: Function,
  updateAllCheckedsOfOwnerSelected: Function,
  updateExpandedOfOwnerSelected: Function,
}

export const FinancialCloseOwnerContext = createContext<IFinancialCloseOwner>({
  financialDataOwner: [],
  setFinancialDataOwner: () => {},
  updateFinancialDataOwner: () => {},
  updateCheckedOfOwnerSelected: () => {},
  updateAllCheckedsOfOwnerSelected: () => {},
  updateExpandedOfOwnerSelected: () => {},
});

export const FinancialCloseOwnerProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [financialDataOwner, setFinancialDataOwner] = useState<GridOwnerProps[]>([]);
  const [ownersSelected, setOwnersSelected] = useState<GridOwnerProps[]>([]);

  const handleSetFinancialDataOwner = useCallback((data: GridOwnerProps[]) => {
    setFinancialDataOwner([...data]);
  }, []);

  const handleUpdateFinancialDataOwner = useCallback((currentData: GridOwnerProps[],
    dataUpdated: GridOwnerProps[]) => {
    const newData: GridOwnerProps[] = [...currentData];

    dataUpdated.forEach((itemUpdated) => {
      const indexItem = currentData.findIndex((currentItem) => currentItem.id === itemUpdated.id);
      newData[indexItem] = { ...getFinancialCloseOwnerUpdated(itemUpdated) };
    });

    setFinancialDataOwner(newData);
  }, []);

  const handleUpdateCheckedOfOwnerSelected = useCallback((id: number, checkedUpdated?: boolean) => {
    if (checkedUpdated) {
      setFinancialDataOwner((state) => state.map((item) => ({
        ...getFinancialCloseOwnerUpdated(item),
        checked: item.id === id ? checkedUpdated : item.checked,
      })));
    } else {
      setFinancialDataOwner((state) => state.map((item) => ({
        ...getFinancialCloseOwnerUpdated(item),
        checked: item.id === id ? !item.checked : item.checked,
      })));
    }
  }, []);

  const handleUpdateAllCheckedsOfOwnerSelected = useCallback((checkedUpdated: boolean) => {
    setFinancialDataOwner((state) => state.map((item) => ({
      ...getFinancialCloseOwnerUpdated(item),
      checked: checkedUpdated,
    })));
  }, []);

  const handleUpdateExpandedOfOwnerSelected = useCallback((id: number) => {
    setFinancialDataOwner((state) => state.map((item) => ({
      ...getFinancialCloseOwnerUpdated(item),
      expanded: item.id === id ? !item.expanded : item.expanded,
    })));
  }, []);

  type CheckProps = Record<number, boolean>;
  useEffect(() => {
    const checkeds: CheckProps = financialDataOwner.reduce((acc, item) => {
      acc[item.id] = item.checked;
      return acc;
    }, {} as CheckProps);

    localStorage.setItem('@saprom-pms-web/financialclose_owner_selected', JSON.stringify(checkeds));
  }, [financialDataOwner]);

  const value = useMemo(() => ({
    financialDataOwner,
    setFinancialDataOwner: handleSetFinancialDataOwner,
    updateFinancialDataOwner: handleUpdateFinancialDataOwner,
    updateCheckedOfOwnerSelected: handleUpdateCheckedOfOwnerSelected,
    updateAllCheckedsOfOwnerSelected: handleUpdateAllCheckedsOfOwnerSelected,
    updateExpandedOfOwnerSelected: handleUpdateExpandedOfOwnerSelected,
  }), [
    financialDataOwner,
    setFinancialDataOwner,
    ownersSelected,
    setOwnersSelected,
  ]);

  return (
    <FinancialCloseOwnerContext.Provider
      value={value}
    >
      {children}
    </FinancialCloseOwnerContext.Provider>
  );
};
