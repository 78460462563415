import { motion } from 'framer-motion';
import styled, { DefaultTheme } from 'styled-components';
import { Type } from './types';

const color = (theme: DefaultTheme, type: Type): string => {
  const colors = {
    primary: theme.palette.blue._950.hex().toString(),
    error: theme.palette.red._400.hex().toString(),
    success: theme.palette.green.main.hex().toString(),
  };

  return colors[type];
};

export const Container = styled(motion.div)<{ type: Type }>`
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    padding-top: 5px;
    color: ${({ theme, type }) => color(theme, type)};
    display: flex;
`;
