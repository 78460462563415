import React from 'react';

import {
  Line,
  TextValue,
  ColorProps,
  ValueContainer,
} from './styles';

interface ValueTextProps {
  value: number | string;
  text: string;
  color: ColorProps;
}

const ValueText = ({ value, color, text }: ValueTextProps) => (
  <ValueContainer>
    <h1>{text}</h1>

    <div>
      <Line />
    </div>

    <TextValue color={color}>{`${value}`}</TextValue>
  </ValueContainer>
);

export default ValueText;
