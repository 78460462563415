import styled from 'styled-components';

export const Container = styled.div``;

export const ValueContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PaymentDoContainer = styled.div`
  margin: 30px 0;
`;

export const PaymentTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-size: 1.3rem;
`;

export const ReservationNotFound = styled.div`
  display: flex;

  h1 {
    margin-top: 20px;
    color: ${({ theme }) => theme.palette.grey._450.hex()};;
    font-size: 2rem;
  }
`;
