import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface BoxProps {
  border?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  children?: ReactNode;
}

const BoxComponent = styled.div<BoxProps>`
  border: ${(props) => props.border || '1px solid rgb(227, 231, 235)'};
  display: ${(props) => props.display || 'flex'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  padding: ${(props) => props.padding || '16px'};
  border-radius: 0.8rem;
`;

const Box: React.FC<BoxProps> = ({ children, ...rest }) => (
  <BoxComponent {...rest}>
    {children}
  </BoxComponent>
);

export default Box;
