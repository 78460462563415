import React from 'react';
import { Outlet } from 'react-router-dom';
import { IndicateProvider } from '../../../../../context/Partners/IndicateContext/IndicateContext';

import Header from './Header';

import { Container } from './style';

const IndicateViews = () => (
  <Container>
    <Header />
    <Outlet />
  </Container>
);

const IndicateViewsWithProvider = () => (
  <IndicateProvider>
    <IndicateViews />
  </IndicateProvider>
);

export default IndicateViewsWithProvider;
