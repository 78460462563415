import React from 'react';
import { Container } from '../../../../common/style';
import { LogoSVGIcon } from '../../../../../../../Header/svg';
import { Content } from './style';

const sub = [
  { id: 0, value: 'S' },
  { id: 1, value: 'e' },
  { id: 2, value: 'u' },
  { id: 3, value: ' ' },
  { id: 4, value: 'l' },
  { id: 5, value: 'u' },
  { id: 6, value: 'g' },
  { id: 7, value: 'a' },
  { id: 8, value: 'r' },
  { id: 9, value: ' ' },
  { id: 10, value: 'f' },
  { id: 11, value: 'o' },
  { id: 12, value: 'r' },
  { id: 13, value: 'a' },
  { id: 14, value: ' ' },
  { id: 15, value: 'd' },
  { id: 16, value: 'e' },
  { id: 17, value: ' ' },
  { id: 18, value: 'c' },
  { id: 19, value: 'a' },
  { id: 20, value: 's' },
  { id: 21, value: 'a' },
];

const Seazone = () => (
  <Container style={{ paddingTop: '150px' }}>
    <Content>
      <LogoSVGIcon />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
      }}
      >
        {sub.map((item) => {
          if (item.value === ' ') {
            return <div key={item.id} style={{ marginLeft: '5px' }} />;
          }

          return <h1 key={item.id}>{item.value}</h1>;
        })}
      </div>
    </Content>
  </Container>
);

export default Seazone;
