import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import moment from 'moment';
import { createContext } from 'use-context-selector';

import { IListItems, IStatementImage, OrderGridOptions } from '../../components/OwnerTedList/types/listItems';
import { sortTedListItems, getTedUpdated } from '../../components/OwnerTedList/utils';
import { IGetOwnerTedsParams } from '../../services/OwnerTedList/types';

export interface IOwnerTedListContext {
  listItems: IListItems[];
  setListItems: Function;
  filteredItems: IListItems[];
  setFilteredItems: Function;
  loading: boolean;
  setLoading: Function;
  openModalID: number;
  setOpenModalID: Function;
  openModalConfirmDeleteVoucher: boolean;
  setOpenModalConfirmDeleteVoucher: Function;
  deleteVoucherIsConfirmed: boolean,
  setDeleteVoucherIsConfirmed: Function,
  modalImage: IStatementImage;
  setModalImage: Function;
  orderGridBy: OrderGridOptions;
  setOrderGridBy: Function;
  updateCheckedOfTedSelected: Function;
  updateAllCheckedsOfTedSelected: Function;
  buttonUpdateStatusIsVisible: boolean;
  openModalUpdateTedStatus: boolean;
  setOpenModalUpdateTedStatus: Function;
  openModalFilters: boolean;
  setOpenModalFilters: Function;
  filters: IGetOwnerTedsParams;
  setFilters: Function;
}

export const OwnerTedListContext = createContext<IOwnerTedListContext>({} as IOwnerTedListContext);

export const OwnerTedListProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [listItems, setListItems] = useState<IListItems[]>([]);
  const [filteredItems, setFilteredItems] = useState<IListItems[]>([]);
  const [openModalID, setOpenModalID] = useState<number>(0);
  const [modalImage, setModalImage] = useState<IStatementImage>({} as IStatementImage);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalFilters, setOpenModalFilters] = useState<boolean>(false);

  const [
    openModalConfirmDeleteVoucher,
    setOpenModalConfirmDeleteVoucher,
  ] = useState<boolean>(false);

  const [deleteVoucherIsConfirmed, setDeleteVoucherIsConfirmed] = useState<boolean>(false);
  const [openModalUpdateTedStatus, setOpenModalUpdateTedStatus] = useState<boolean>(false);

  const [filters, setFilters] = useState<IGetOwnerTedsParams>({
    period: moment(new Date()).format('YYYY-MM'),
    status: '',
    termToFilter: '',
    transfer_day: undefined,
    transfer: undefined,
  });

  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'name',
    order: 'asc',
  });

  const handleModalImage = useCallback((image: IStatementImage) => {
    setModalImage(image);
  }, []);

  const handleListItems = useCallback((newListItems: IListItems[]) => {
    setListItems(newListItems);
  }, []);

  const handleFilteredItems = useCallback((newFilteredItems: IListItems[]) => {
    setFilteredItems(newFilteredItems);
  }, []);

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleOpenModalID = useCallback((newOpenModalID: number) => {
    setOpenModalID(newOpenModalID);
  }, []);

  const handleOpenModalConfirmDeleteVoucher = useCallback((open: boolean) => {
    setOpenModalConfirmDeleteVoucher(open);
  }, []);

  const handleSetOpenModalUpdateTedStatus = useCallback((open: boolean) => {
    setOpenModalUpdateTedStatus(open);
  }, []);

  const handleSetDeleteVoucherIsConfirmed = useCallback((isConfirmed: boolean) => {
    setDeleteVoucherIsConfirmed(isConfirmed);
  }, []);

  const handleSetOrderGridBy = useCallback((orderBy: OrderGridOptions) => {
    setOrderGridBy(orderBy);
  }, []);

  const handleUpdateCheckedOfTedSelected = useCallback((id: number,
    checkedUpdated?: boolean) => {
    if (checkedUpdated) {
      setListItems((state) => state.map((item) => ({
        ...getTedUpdated(item),
        checked: item.id === id ? checkedUpdated : item.checked,
      })));
    } else {
      setListItems((state) => state.map((item) => ({
        ...getTedUpdated(item),
        checked: item.id === id ? !item.checked : item.checked,
      })));
    }
  }, []);

  const handleUpdateAllCheckedsOfTedSelected = useCallback((checkedUpdated: boolean) => {
    setListItems((state) => state.map((item) => ({
      ...getTedUpdated(item),
      checked: checkedUpdated,
    })));
  }, []);

  const handleOpenModalFilters = useCallback((open: boolean) => {
    setOpenModalFilters(open);
  }, []);

  const handleSetFilters = useCallback((filterOptions: IGetOwnerTedsParams) => {
    setFilters(filterOptions);
  }, []);

  useEffect(() => {
    const response: IListItems[] = sortTedListItems(
      listItems,
      orderGridBy.order_by,
      orderGridBy.order,
    );

    handleListItems([...response]);
  }, [orderGridBy]);

  useEffect(() => {
    if (deleteVoucherIsConfirmed) {
      handleOpenModalConfirmDeleteVoucher(false);
    }
  }, [deleteVoucherIsConfirmed]);

  const buttonUpdateStatusIsVisible = useMemo(() => listItems
    .some((item) => item.checked), [listItems]);

  const value = useMemo(() => ({
    listItems,
    setListItems: handleListItems,
    filteredItems,
    setFilteredItems: handleFilteredItems,
    loading,
    setLoading: handleLoading,
    openModalID,
    setOpenModalID: handleOpenModalID,
    openModalConfirmDeleteVoucher,
    setOpenModalConfirmDeleteVoucher: handleOpenModalConfirmDeleteVoucher,
    deleteVoucherIsConfirmed,
    setDeleteVoucherIsConfirmed: handleSetDeleteVoucherIsConfirmed,
    openModalUpdateTedStatus,
    setOpenModalUpdateTedStatus: handleSetOpenModalUpdateTedStatus,
    modalImage,
    setModalImage: handleModalImage,
    orderGridBy,
    setOrderGridBy: handleSetOrderGridBy,
    updateCheckedOfTedSelected: handleUpdateCheckedOfTedSelected,
    updateAllCheckedsOfTedSelected: handleUpdateAllCheckedsOfTedSelected,
    buttonUpdateStatusIsVisible,
    openModalFilters,
    setOpenModalFilters: handleOpenModalFilters,
    filters,
    setFilters: handleSetFilters,
  }),
  [
    listItems,
    handleListItems,
    filteredItems,
    handleFilteredItems,
    loading,
    handleLoading,
    openModalID,
    handleOpenModalID,
    openModalConfirmDeleteVoucher,
    setOpenModalConfirmDeleteVoucher,
    deleteVoucherIsConfirmed,
    setDeleteVoucherIsConfirmed,
    openModalUpdateTedStatus,
    setOpenModalUpdateTedStatus,
    modalImage,
    handleModalImage,
    orderGridBy,
    setOrderGridBy,
    openModalFilters,
    setOpenModalFilters,
    filters,
    setFilters,
  ]);

  return (
    <OwnerTedListContext.Provider value={value}>{children}</OwnerTedListContext.Provider>
  );
};
