import styled, { css } from 'styled-components';

import {
  genericBorderRoundedMinWidth,
} from '../utils/var.utils';

export const Container = styled.div`
  div {
    border-left: 1px solid #d3d3d3;

    &:first-child {
      border-left: none;
    }
  }
`;

export const Content = styled.div<{
  isBedExpanded: boolean;
  isBathroomsExpanded: boolean;
  validateHostSearch: boolean;
}>`
  border: 1px solid transparent;
  display: grid;
  margin-top: 5px;
  width: max-content;
  background: #ebf0f5;
  transition: 0.3s;
  grid-template-columns: ${({ isBedExpanded, isBathroomsExpanded }) => `200px 200px 200px 120px 150px 300px 300px 300px 220px ${isBedExpanded ? '700px' : '200px'} 150px ${isBathroomsExpanded ? '400px' : '200px'}`};

  ${({ validateHostSearch, isBedExpanded, isBathroomsExpanded }) => validateHostSearch && css`
    grid-template-columns: ${`200px 200px 200px 120px 150px 300px 300px 300px 220px 200px ${isBedExpanded ? '700px' : '200px'} 150px ${isBathroomsExpanded ? '400px' : '200px'}`};
  `};

  div {
    display: flex;
    padding: 0.15rem;
    align-items: center;
    justify-content: center;
  }
`;

export const TaxCleaningContainer = styled.div`
  background: #c0e8ff;
`;

export const BedsContainer = styled.div`
  background: #e0f1fb;
  border-right: 1px solid #cccccc;
  min-width: ${genericBorderRoundedMinWidth};
  display: flex;
  justify-content: space-around;

  div{
    border: none;
  }
`;

export const BathroomsContainer = styled.div`
  background: #e0f1fb;
  border-right: 1px solid #cccccc;
  display: flex;
  justify-content: center;
`;

export const BedroomsContainer = styled.div`
  display: flex;
  gap: 10px;
  background: #e0f1fb;
  margin: 0 10px;
  justify-content: center;
  border-right: 1px solid #cccccc;
`;

export const Value = styled.h1`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const OwnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommissionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightContainer = styled.div`
  display: flex;
  margin-right: 91px;
`;

export const StartOfContractContainer = styled.div``;
