import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PopupTemplate } from '../../../../components/Popups';
import { data } from './data';
import { usePartners } from '../../../../context/Partners/Partner/PartnerContext';
import { partnersRoutes } from '../../types';
import { useMobile } from '../../../../hooks/useMobile/useMobile';

const Welcome2 = () => {
  const { isMobile } = useMobile();
  const { setWelcome } = usePartners();

  const navigate = useNavigate();

  return (
    <PopupTemplate
      data={data}
      options={{
        buttons: [
          {
            is: true,
            onClick: ({ nextStep }) => {
              nextStep();
            },
            step: 0,
            text: 'Próximo',
          },
          {
            is: true,
            onClick: ({ nextStep }) => {
              nextStep();
            },
            step: 1,
            text: 'Próximo',
          },
          {
            is: true,
            onClick: ({ nextStep }) => {
              nextStep();
            },
            step: 2,
            text: 'Próximo',
          },
          {
            is: true,
            onClick: ({ nextStep }) => {
              nextStep();
            },
            step: 3,
            text: 'Próximo',
          },
          {
            is: true,
            onClick: ({ finish }) => {
              finish();
            },
            step: 4,
            text: 'Finalizar',
          },
        ],
        showNotShowAgainCheckbox: {
          is: true,
          onTrue: () => {},
          onFalse: () => {},
        },
      }}
      newBody={{
        Partner: [],
      }}
      onFinish={{
        success: () => {
          setWelcome((old) => ({ ...old, is: false }));
          if (isMobile) {
            navigate(`/${partnersRoutes.partners.main}/${partnersRoutes.panel.main}`);
          }
        },
      }}
    />
  );
};
export default Welcome2;
