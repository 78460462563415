import { useHostPropertiesFilter } from '../../../../hooks/useHostPropertiesFilter/useHostPropertiesFilter';

import arrowTop from '../../../../assets/icons/generals/arrowTop.svg';
import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';

import {
  Title,
  Content,
  SubTitle,
  Container,
  OwnerContainer,
  OrderContainer,
  StatusContainer,
  AddressContainer,
  ImmobileContainer,
  BedroomsContainer,
  TaxCleaningContainer,
  // CommissionContainer,
  StartOfContractContainer,
  ProprietaryPhoneContainer,
  ImmobileCategoryContainer,
} from './styles';

import ExpansiveItem from './ExpansiveItem';
import { useUser } from '../../../../context/UserContext';

const Order = () => {
  const {
    order,
  } = useHostPropertiesFilter();

  if (order === 'asc') {
    return (
      <OrderContainer>
        <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
      </OrderContainer>
    );
  }

  if (order === 'desc') {
    return (
      <OrderContainer>
        <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
      </OrderContainer>
    );
  }

  return (
    <OrderContainer>
      <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
      <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
    </OrderContainer>
  );
};

const Header = () => {
  const {
    bedsGridExpanded,
    handleSortingByCode,
    handleSortingByPhone,
    handleSortingByStatus,
    bathroomsGridExpanded,
    handleSortingByAddress,
    handleSortingByHostName,
    handleSortingByCategory,
    handleSortingByOwnersName,
    handleSortingByImmobileId,
    handleSortingByCleaningFee,
    // handleSortingByCommission,
    handleChangeBedsGridExpanded,
    handleSortingByBedroomQuantity,
    handleSortingByContractStartDate,
    handleChangeBathroomsGridExpanded,
  } = useHostPropertiesFilter();

  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const validateHostSearch: boolean = roles.includes('SeazoneOnboarding') || roles.includes('Admin') || roles.includes('Attendant');

  return (
    <Container
      isBedExpanded={bedsGridExpanded}
      isBathroomsExpanded={bathroomsGridExpanded}
      validateHostSearch={validateHostSearch}
    >
      <TaxCleaningContainer>
        <Content onClick={() => handleSortingByImmobileId()}>
          <Title>ID do imóvel</Title>
          <Order />
        </Content>
      </TaxCleaningContainer>

      <ImmobileContainer>
        <Content onClick={() => handleSortingByCode()}>
          <Title>Imóvel</Title>
          <Order />
        </Content>
      </ImmobileContainer>

      <ImmobileCategoryContainer onClick={() => handleSortingByCategory()}>
        <Content>
          <Title>
            Categoria
            <br />
            do imóvel
          </Title>
          <Order />
        </Content>
      </ImmobileCategoryContainer>

      <StatusContainer>
        <Content onClick={() => handleSortingByStatus()}>
          <Title>Status</Title>
          <Order />
        </Content>
      </StatusContainer>

      <StartOfContractContainer>
        <Content onClick={() => handleSortingByContractStartDate()}>
          <Title>
            Início
            <br />
            do contrato
          </Title>
          <Order />
        </Content>
      </StartOfContractContainer>

      <OwnerContainer>
        <Content onClick={() => handleSortingByOwnersName()}>
          <Title>Proprietario</Title>
          <Order />
        </Content>
      </OwnerContainer>

      <ProprietaryPhoneContainer>
        <Content onClick={() => handleSortingByPhone()}>
          <Title>
            Telefone
            <br />
            do proprietario
          </Title>
          <Order />
        </Content>
      </ProprietaryPhoneContainer>

      {validateHostSearch && (
        <OwnerContainer>
          <Content onClick={() => handleSortingByHostName()}>
            <Title>Anfitrião</Title>
            <Order />
          </Content>
        </OwnerContainer>
      )}

      <AddressContainer>
        <Content onClick={() => handleSortingByAddress()}>
          <Title>Endereço</Title>
          <Order />
        </Content>
      </AddressContainer>

      {/* <CommissionContainer>
        <Content onClick={() => handleSortingByCommission()}>
          <Title>% Comissão</Title>
          <Order />
        </Content>
      </CommissionContainer> */}

      <TaxCleaningContainer>
        <Content onClick={() => handleSortingByCleaningFee()}>
          <Title>Tax. Limpeza</Title>
          <Order />
        </Content>
      </TaxCleaningContainer>

      <ExpansiveItem
        title="Camas"
        subTitle="Todas"
        open={bedsGridExpanded}
        onClick={handleChangeBedsGridExpanded}
      >
        <SubTitle>Camas de casal</SubTitle>
        <SubTitle>Camas de solteiro</SubTitle>
        <SubTitle>Camas king</SubTitle>
        <SubTitle>Camas queen</SubTitle>
        <SubTitle>Sofás-cama individual</SubTitle>
        <SubTitle>Sofás-cama duplo</SubTitle>
      </ExpansiveItem>

      <BedroomsContainer>
        <Content onClick={() => handleSortingByBedroomQuantity()}>
          <Title>Quartos</Title>
          <Order />
        </Content>
      </BedroomsContainer>

      <ExpansiveItem
        title="Banheiros"
        subTitle="Todos"
        open={bathroomsGridExpanded}
        onClick={handleChangeBathroomsGridExpanded}
      >
        <SubTitle>Banheiros</SubTitle>
        <SubTitle>Lavatórios</SubTitle>
      </ExpansiveItem>

    </Container>
  );
};
export default Header;
