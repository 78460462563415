import styled from 'styled-components';

export const ContantInfoReservation = styled.div`
  padding-left: 15px;
  padding-right: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  min-height: 90px;
  border-radius: 10px;
  margin-bottom: 6px;

  @media(max-width: 350px) {
    height: 190px;
  }
`;

export const ButtonDelete = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 12;
  right: 0;
  margin-right: 22px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.orange.main.hex()};
    color: ${({ theme }) => theme.palette.white.main.hex()};

  }
`;

export const CodeProperty = styled.p`
  font-weight: 700;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  line-height: 25.5px;
`;

export const Address = styled.p`
  width: 219px;
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._1};

`;

export const ImageProperty = styled.img`
  width: 100px;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 52px;
    height: 44px;
    border-radius: 4px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Guest = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  svg{fill:${({ theme }) => theme.palette.orange.main.hex()}};
  p {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;
