import styled from 'styled-components';
import CountrySelector from '../../../../../../../../../components/CountrySelector/CountrySelector';

export const StyledPhone = styled(CountrySelector)`
  &&.react-tel-input .form-control {
    height: 40px;
    border: '1px solid #D9D9D9';
  }

  &&.react-tel-input .country-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;
