import styled from 'styled-components';

export const ProprietaryPhoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;

  div {
    border: none;
  }

  img {
    width: 20px;
  }
`;

export const ProprietaryPhoneIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  border: none;

  button{
    border: none;
    background: transparent;
  }

  .ProprietaryPhoneWhatsAppIcon{
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: translateY(-5px);
    }
  }

  .ProprietaryPhoneCopyIcon{
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: translateY(-5px);
    }
  }
`;
