import styled, { css } from 'styled-components';

export const Container = styled.div<{ isRow?: boolean }>`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  gap: 1rem;
`;

export const Section = styled.div`
  gap: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;

  label {
    cursor: pointer;
  }
`;

export const Checkbox = styled.div<{
  checked: boolean;
}>`
  height: 24px;
  width: 24px;
  display: flex;
  transition: 0.2s;
  border: 1px solid #D9D9D9;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  position: relative;

  ${({ checked }) => checked
    && css`
      ::before {
        content: "";
        width: 16px;
        height: 16px;
        background-color: #0d4bd0;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}

  label {
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: #394760;
  }
`;
