import React from 'react';
import {
  Title, Container, Description, Header,
} from '../../../../common/style';
import { ButtonBackDrop, ButtonContainer, SeeRegionsButton } from './style';
import { actualSize } from '../../../../../../../../../hooks/useMobile/useMobile';

const link = 'https://www.google.com/maps/d/u/1/viewer?ll=-27.421353807013315%2C-48.74695275606552&z=8&mid=190CNFeJxptJvE9wPLrJveFXtYY-dBAPy';

const WhichRegionsCanIndicate = () => {
  const handleRedirect = () => {
    window.open(link, '_blank');
  };

  return (
    <Container>
      <Header>
        <Title>Quais regiões você pode indicar imóveis?</Title>
        <Description>
          A Seazone já está presente em diversos estados do Brasil. Para
          auxiliar nossos parceiros com suas indicações, desenvolvemos um mapa
          atualizado de todas as regiões em que atuamos.
        </Description>
      </Header>

      <div style={{ maxWidth: actualSize === 'Desktop' ? '50%' : 'auto' }}>
        <ButtonContainer onClick={handleRedirect} whileTap={{ scale: 0.95 }}>
          <ButtonBackDrop />
          <SeeRegionsButton>VER REGIÕES DE ATUAÇÃO</SeeRegionsButton>
        </ButtonContainer>
      </div>
    </Container>
  );
};

export default WhichRegionsCanIndicate;
