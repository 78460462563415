import { useState } from 'react';
import CentralizedModal from '../Modal/CentralizedModal';
import LateralModal from '../Modal/LateralModal';
import BlockReservation from './BlockReservation';
import { Text } from '../../Text';
import {
  Container,
  ModalContent, Wrapper,
} from './styles';
import ReserveLogo from '../../../assets/icons/multicalendar/create-reserve.svg';
import BlockLogo from '../../../assets/icons/multicalendar/create-block.svg';
import { useUser } from '../../../context/UserContext';
import CreateReservation from './CreateReservation';

const ControlReservation = ({ onClose }: any) => {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const [selectedComponent, setSelectedComponent] = useState<any>(null);

  const datas = [
    {
      id: 0,
      title: 'Criar reserva',
      description:
        'Você precisa saber as informações do hóspede e escolher uma acomodação para completar o processo',
      imgSrc: ReserveLogo,
      condition: () => roles.includes('Admin') || roles.includes('SeazoneAdministrative') || roles.includes('Attendant'),
      component:
  <CreateReservation onClose={onClose} isReservation />,
    },
    {
      id: 1,
      title: 'Criar bloqueio',
      description:
        'Impede que a acomodação receba reservas para o período selecionado.',
      imgSrc: BlockLogo,
      condition: () => roles.includes('Admin') || roles.includes('SeazoneAdministrative') || roles.includes('Attendant') || roles.includes('Host'),
      component:
  <BlockReservation onClose={onClose} />,
    },
  ];

  return (
    <>
      {!selectedComponent && (
        <CentralizedModal
          onClose={onClose}
        >
          <ModalContent>
            {
              datas.filter((item) => item.condition()).map((item) => (
                <Container key={item.id} onClick={() => setSelectedComponent(item)}>
                  <img src={item.imgSrc} alt={item.title} />
                  <Wrapper>
                    <Text $color="#151B26" $fontSize="16">{item.title}</Text>
                    <Text $color="#62656F" $fontSize="12">{item.description}</Text>
                  </Wrapper>
                </Container>
              ))
          }
          </ModalContent>
        </CentralizedModal>
      )}

      {selectedComponent && (
        <LateralModal
          title={selectedComponent?.title}
          onClose={() => {
            onClose();
          }}
        >
          {selectedComponent?.component}
        </LateralModal>
      )}

    </>
  );
};

export default ControlReservation;
