import { useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import iconHouse from '../../../../../assets/icons/generals/house.svg';

import { PropertyOwner } from '../../../../../services/Owner/types';

import { getOwnerExpenses } from '../../../../../services/Owner/OwnerReport/request';
import { MAX_SIZE_MOBILE } from '../../utils';
import { compareList } from '../../../../../utils/Sorting';

import { useExpensesReport } from '../../../../../hooks/OwnerPage/OwnerReport/useExpensesReport';
import { useViewMode } from '../../../../../context/ViewMode/ViewMode';
import { useUser } from '../../../../../context/UserContext';
import { useScreenResize } from '../../../../../hooks/useSceenResize/useScreenResize';

import SimpleSelect from '../../../../SimpleSelect';
import SearchButton from '../../Buttons/SearchButton';
import CustomDatePickerRange from '../../CustomDatePickerRange';
import DownloadButton from '../../Buttons/DownloadButton';

import {
  Form,
  DatePickerContainer,
  Container,
  Content,
  ContainerSelect,
  Label,
} from './styles';

interface IExpenses {
  propertiesList: PropertyOwner[];
  handleDownloadExpenses: Function;
}

const Filters = ({ propertiesList, handleDownloadExpenses }: IExpenses) => {
  const {
    handleNoDataFound,
    handleLoading,
    ownerExpenses,
    handleOwnerExpenses,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setPropertyId,
  } = useExpensesReport();

  const { isMobile } = useScreenResize(MAX_SIZE_MOBILE);
  const { userInformation } = useUser();
  const { mode } = useViewMode();
  const ownerId = useMemo(() => (userInformation?.owner?.id || undefined), [userInformation]);
  const viewModeOwnerId = useMemo(() => mode?.user_info?.owner?.id || undefined, [mode]);
  const expensesExists = useMemo(() => (ownerExpenses.length !== 0), [ownerExpenses]);

  const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');
  const formatPropertyId = (propId: string) => (!['', 'null', 'undefined'].includes(`${propId}`)
    ? propId : undefined);

  const formik = useFormik({
    initialValues: {
      startDate,
      endDate,
      property: undefined,
    },
    onSubmit: async (values) => {
      try {
        handleLoading(true);

        const initialDate = formatDate(values.startDate);
        const finalDate = formatDate(values.endDate);
        const propertyId = formatPropertyId(`${formik.values.property}`);

        const params = {
          mode,
          start_date: initialDate,
          end_date: finalDate,
          page: 1,
          owner_id: ownerId || viewModeOwnerId,
          property_id: propertyId as any,
        };

        const apiResponse = await getOwnerExpenses(params);
        const response = apiResponse.results;
        setStartDate(values.startDate);
        setEndDate(values.endDate);
        setPropertyId(propertyId);
        handleOwnerExpenses(response);

        if (response.length === 0) {
          handleNoDataFound(true);
        } else {
          handleNoDataFound(false);
        }

        setTimeout(() => {
          handleLoading(false);
        }, 600);
      } catch {
        handleNoDataFound(true);
        handleLoading(false);
      }
    },
  });

  const propertiesCodes = useMemo(() => {
    const codes = propertiesList.map((prop) => ({
      id: prop.id,
      value: prop.id,
      valueLabel: prop.code,
    }));

    codes.sort((a, b) => compareList((a?.valueLabel || ''), (b?.valueLabel || '')));

    if (codes.length === 1) {
      formik.setFieldValue('property', codes[0].id);
    }

    return codes;
  }, [propertiesList]);

  return (
    <Form onSubmit={formik.handleSubmit} expensesExists={expensesExists}>
      <Container>
        <Content className="content-expenses-filters">
          <ContainerSelect>
            <Label>Selecione o imóvel</Label>
            <SimpleSelect
              dataCy="select-properties-list"
              id="property"
              placeholder="Todos"
              formik={formik}
              icon={iconHouse}
              showIcon
              options={propertiesCodes || []}
            />
          </ContainerSelect>
          <DatePickerContainer>
            <CustomDatePickerRange
              id1="startDate"
              id2="endDate"
              calendars={1}
              formik={formik}
              hasInitialDates
              disableCloseOnSelect={false}
              minDate={new Date('2000-01-02')}
              dataCyTextField1="owner-report-expenses-textfield-1"
              dataCyTextField2="owner-report-expenses-textfield-2"
              labelVariant="secondary"
              inputVariant="secondary"
            />
            <SearchButton type="submit" label={isMobile ? undefined : 'Buscar'} />
          </DatePickerContainer>
        </Content>

        <Content className="button-download-expenses">
          <DownloadButton
            type="button"
            label="Baixar despesas"
            onClick={() => handleDownloadExpenses()}
            variantForMobile="secondary"
            variantForDesktop="secondary"
          />
        </Content>

      </Container>
    </Form>
  );
};

export default Filters;
