import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { createContext } from 'use-context-selector';
import { GetGuestDamageNegotiation } from '../../../services/GuestDamage/types';

type GuestNegotiationContextProps = {
  guestDamageNegotiations: GetGuestDamageNegotiation[];
  setGuestDamageNegotiations: Dispatch<SetStateAction<GetGuestDamageNegotiation[]>>;
  selectedGuestDamageNegotiation: GetGuestDamageNegotiation;
  setSelectedGuestDamageNegotiation: Dispatch<SetStateAction<GetGuestDamageNegotiation>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const GuestDamageNegotiationContext = createContext<GuestNegotiationContextProps>({
} as GuestNegotiationContextProps);

export const GuestDamageNegotiationProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [guestDamageNegotiations,
    setGuestDamageNegotiations] = useState<GetGuestDamageNegotiation[]>([]);
  const [selectedGuestDamageNegotiation,
    setSelectedGuestDamageNegotiation] = useState<GetGuestDamageNegotiation>({
  } as GetGuestDamageNegotiation);
  const [isLoading,
    setIsLoading] = useState(false);

  const value = useMemo(() => ({
    guestDamageNegotiations,
    setGuestDamageNegotiations,
    isLoading,
    setIsLoading,
    selectedGuestDamageNegotiation,
    setSelectedGuestDamageNegotiation,
  }), [
    guestDamageNegotiations,
    setGuestDamageNegotiations,
    isLoading,
    setIsLoading,
    selectedGuestDamageNegotiation,
    setSelectedGuestDamageNegotiation,
  ]);

  return (
    <GuestDamageNegotiationContext.Provider
      value={value}
    >
      {children}
    </GuestDamageNegotiationContext.Provider>
  );
};
