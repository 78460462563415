import addGuest from '../../../../../../assets/icons/generals/addGuest.svg';

import { Container } from './styles';

interface AddGuestButtonProps {
  guestsSecondary?: any;
  setGuestsSecondary?: any;
}

const AddGuestButton = ({
  guestsSecondary,
  setGuestsSecondary,
}: AddGuestButtonProps) => {
  const handleAddGuest = () => {
    const newGuest = {
      id: guestsSecondary.length + 2,
      isNewGuest: true,
    };

    setGuestsSecondary((prevState: any) => [...prevState, { ...newGuest }]);
  };

  return (
    <Container>
      <button type="button" onClick={handleAddGuest}>
        Adicionar Hóspede
        <img src={addGuest} alt="Adicionar Hóspede" />
      </button>
    </Container>
  );
};

AddGuestButton.defaultProps = {
  guestsSecondary: [],
  setGuestsSecondary: () => {},
};

export default AddGuestButton;
