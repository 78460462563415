import React from 'react';

const Spot = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="tag">
      <path
        d="M9.74817 11.9977C10.9906 11.9977 11.9977 10.9906 11.9977 9.74817C11.9977 8.50576 10.9906 7.49859 9.74817 7.49859C8.50576 7.49859 7.49859 8.50576 7.49859 9.74817C7.49859 10.9906 8.50576 11.9977 9.74817 11.9977Z"
        fill="#D66A9E"
      />
      <path
        d="M29.1095 14.1124L15.867 0.869837C15.3121 0.314941 14.5473 0 13.7524 0H2.99944C1.34975 0 0 1.34975 0 2.99944V13.7524C0 14.5473 0.314941 15.3121 0.884834 15.867L14.1273 29.1095C15.2971 30.2793 17.2018 30.2793 18.3716 29.1095L29.1245 18.3566C30.2943 17.1868 30.2943 15.2971 29.1095 14.1124ZM16.242 26.9949L2.99944 13.7524V2.99944H13.7524L26.9949 16.242L16.242 26.9949Z"
        fill="#D66A9E"
      />
    </g>
  </svg>
);

export default Spot;
