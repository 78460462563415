/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useMemo } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import { AccountOwnerOnboardingProps, PipedriveOwnerInfo } from '../../../../services/Onboarding/types';
import { PropertyInformation } from '../../../../context/OnboardingContext/types';

import { translateTypeProperty } from '../../../../utils/Translator';
import { checkActive, handleValidation } from '../../../../utils/Onboarding';

import { useOnboardingHandover } from '../../../../context/OnboardingContext/OnboardingHandoverContext';
import { useOnboardingPage } from '../../../../hooks/useOnboarding/useOnboarding';
import { useToast } from '../../../../context/ToastContext';
import { useIBGE } from '../../../../hooks/useIBGE/useIBGE';
import { normalizeState } from '../../../../hooks/useIBGE/utils';

import QuantityField from '../../../QuantityField';
import SimpleSelect from '../../../SimpleSelect';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import OnboardingSwitch from '../../OnboardingSwitch';
import OnboardingTextField from '../../OnboardingTextArea';
import FormAddressProperty from '../../FormAddressProperty';
import FormButton from '../../../FormButton';
import FormGridRow from '../../../FormGridRow';
import TextField from '../../../TextField';
import Tooltip from '../../../Tooltip';
import Category from '../../../InsertData/InsertPropertyData/Category';

import { StyledCountrySelector } from '../../NewOwnerModal/styles';

import {
  Form,
  PropertyInformationContainer,
  TitleContainer,
  Title,
  Border,
  ContentContainer,
  BoxContainer,
  BoxContent,
  BoxHeader,
  BoxTitle,
  DoubleBox,
  QuantityContainer,
  BoxQuantityContainer,
  QuatityTextContainer,
  QuatityText,
  InfoIconContainer,
  SwitchContainer,
  End,
  ButtonContainer,
  CategoryField,
  Label,
} from './styles';

const phoneRegExp = /^\+55 \([1-9]{2}\) [2-9][0-9]{3,4}-[0-9]{4}$/;

const CardPropertyInformation = () => {
  const { states } = useIBGE({});
  const { stateMappings } = states;
  const toast = useToast();
  const {
    propertyDetails,
    setPropertyDetails,
    setInfosPipeDrive,
    infosPipeDrive,
    resetFormik,
    setResetFormik,
    setOwner,
  } = useOnboardingHandover();

  const { handleClickPhase, phases } = useOnboardingPage();

  function formatPhoneToShow(phone: string, isOwnerContact: boolean) {
    if (isOwnerContact) return phone;
    if (['null', 'undefined'].includes(`${phone}`)) return '';

    const phoneNumber = `${phone}`.replace(/\D/g, '');

    if (!phoneNumber.startsWith('+55') && !phoneNumber.startsWith('55')) {
      return `+55 ${phoneNumber}`;
    }

    return phoneNumber;
  }

  function formatPhoneToSubmit(phone: string) {
    if (['null', 'undefined'].includes(`${phone}`)) return '';

    const phoneNumber = `${phone}`.replace(/\D/g, '');

    if (!phoneNumber.startsWith('+')) {
      return `+${phoneNumber}`;
    }

    return phoneNumber;
  }

  const propertyTypes = [
    {
      id: 'Apartment',
      type: 'Apartamento',
    },
    {
      id: 'Hotel',
      type: 'Hotel',
    },
    {
      id: 'House',
      type: 'Casa',
    },
  ];

  const validation = Yup.object().shape({
    propertycode: Yup.string().required(),
    postal_code: Yup.string().required(),
    city: Yup.string().required(),
    neighborhood: Yup.string().required(),
    number: Yup.string().required(),
    state: Yup.string().required(),
    street: Yup.string().required(),
    categoryLocationId: Yup.string().required(),
    bedroomsAmount: Yup.number().min(1, 'Informe um valor maior do que zero').required(),
    extraDayPreparation: Yup.number().min(0, 'Informe um valor maior ou igual a zero').required(),
    propertyType: Yup.string().required(),
    comment: Yup.string(),
    singleBeds: Yup.number().required(),
    doubleBeds: Yup.number().required(),
    queenBeds: Yup.number().required(),
    kingBeds: Yup.number().required(),
    singleSofaBeds: Yup.number().required(),
    sofaDoubleBeds: Yup.number().required(),
    bathrooms: Yup.number().required(),
    onboardingPhone: Yup.string().when('isOwnerContact', {
      is: false,
      then: Yup.string()
        .test(
          'is-valid-phone',
          'O número de telefone deve estar no formato +55 (00) 00000-0000',
          (value) => {
            if (!value) return true;

            if (value.startsWith('55')) {
              const formattedValue = `+55 (${value.slice(2, 4)}) ${value.slice(4, 9)}-${value.slice(
                9,
              )}`;
              return phoneRegExp.test(formattedValue);
            }

            return false;
          },
        )
        .required('O telefone é obrigatório'),
    }),
  });

  type FormikProps = {
    propertycode: string;

    inCondominium: boolean;
    condominium: string;

    city: string;
    neighborhood: string;
    number: string;
    state: string;
    street: string;
    postal_code: string;
    complement: string;
    country: string;

    categoryLocationId: string;
    bedroomsAmount: number;
    propertyType: string;
    propertyAreaSizeM2: number;
    extraDayPreparation: number;

    comment: string;

    isOwnerContact: boolean;
    onboardingName: string;
    onboardingPhone: string;

    singleBeds: number;
    doubleBeds: number;
    queenBeds: number;
    kingBeds: number;
    singleSofaBeds: number;
    sofaDoubleBeds: number;
    bathrooms: number;
    lavabo: number;
    guestCapacity: number;
    totalImplantationFee?: string;
  };

  const initialValues: FormikProps = {
    propertycode: infosPipeDrive?.property_infos?.property_code
      ? infosPipeDrive?.property_infos?.property_code
      : propertyDetails?.propertycode || '',

    inCondominium: infosPipeDrive?.property_infos?.property_address?.condominium_name
    ? !["", 'null', 'undefined'].includes(`${infosPipeDrive?.property_infos?.property_address?.condominium_name}`)
    : propertyDetails?.inCondominium || false,
    condominium: infosPipeDrive?.property_infos?.property_address?.condominium_name 
    ? infosPipeDrive?.property_infos?.property_address?.condominium_name 
    : propertyDetails?.condominium || '',

    city: infosPipeDrive?.property_infos?.property_address?.property_city
      ? infosPipeDrive?.property_infos?.property_address?.property_city
      : propertyDetails?.city || '',
    neighborhood: infosPipeDrive?.property_infos?.property_address?.property_neighborhood
      ? infosPipeDrive?.property_infos?.property_address?.property_neighborhood
      : propertyDetails?.neighborhood || '',
    number: infosPipeDrive?.property_infos?.property_address?.property_number
      ? infosPipeDrive?.property_infos?.property_address?.property_number
      : propertyDetails?.number || '',
    state: normalizeState(stateMappings, infosPipeDrive?.property_infos?.property_address?.property_state
      ? infosPipeDrive?.property_infos?.property_address?.property_state || ''
      : propertyDetails?.state || ''),
    street: infosPipeDrive?.property_infos?.property_address?.property_street
      ? infosPipeDrive?.property_infos?.property_address?.property_street
      : propertyDetails?.street || '',
    postal_code: infosPipeDrive?.property_infos?.property_address?.property_postal_code
      ? infosPipeDrive?.property_infos?.property_address?.property_postal_code
      : propertyDetails?.postal_code || '',
    complement: infosPipeDrive?.property_infos?.property_address?.property_complement
      ? infosPipeDrive?.property_infos?.property_address?.property_complement
      : propertyDetails?.complement || '',
    country: 'BR', // nao integrado com pipedrive

    categoryLocationId: infosPipeDrive?.property_infos?.property_category
      ? infosPipeDrive?.property_infos?.property_category
      : propertyDetails?.category || 'ADEFINIR',
    bedroomsAmount: infosPipeDrive?.property_infos?.bedroom_quantity
      ? infosPipeDrive?.property_infos?.bedroom_quantity
      : propertyDetails?.bedroomsAmount || 0,
    propertyAreaSizeM2: infosPipeDrive?.property_infos?.property_size
      ? infosPipeDrive?.property_infos?.property_size
      : propertyDetails?.propertyAreaSizeM2 || 0,
    extraDayPreparation: Number(infosPipeDrive?.preparation_days 
      ? infosPipeDrive?.preparation_days 
      : propertyDetails?.extraDayPreparation || 0),
    propertyType: infosPipeDrive?.property_infos?.property_type
      ? translateTypeProperty(infosPipeDrive?.property_infos?.property_type)
      : propertyDetails?.propertyType || '',

    comment: infosPipeDrive?.property_infos?.property_comment || propertyDetails?.comment || '',

    isOwnerContact: infosPipeDrive?.property_infos?.is_owner_contact || propertyDetails?.isOwnerContact || false,
    onboardingName: infosPipeDrive?.property_infos?.onboarding_name || propertyDetails?.onboardingName || '',
    onboardingPhone: formatPhoneToShow(
      infosPipeDrive?.property_infos?.onboarding_phone
      ? infosPipeDrive?.property_infos?.onboarding_phone
      : propertyDetails?.onboardingPhone || '', 
      infosPipeDrive?.property_infos?.is_owner_contact || propertyDetails?.isOwnerContact || false
    ),

    singleBeds: infosPipeDrive?.property_infos?.amenities.single_bed_quantity
      ? infosPipeDrive?.property_infos?.amenities.single_bed_quantity
      : propertyDetails?.singleBeds || 0,
    doubleBeds: infosPipeDrive?.property_infos?.amenities.double_bed_quantity
      ? infosPipeDrive?.property_infos?.amenities.double_bed_quantity
      : propertyDetails?.doubleBeds || 0,
    queenBeds: infosPipeDrive?.property_infos?.amenities.queen_bed_quantity
      ? infosPipeDrive?.property_infos?.amenities.queen_bed_quantity
      : propertyDetails?.queenBeds || 0,
    kingBeds: infosPipeDrive?.property_infos?.amenities.king_bed_quantity
      ? infosPipeDrive?.property_infos?.amenities.king_bed_quantity
      : propertyDetails?.kingBeds || 0,
    singleSofaBeds: infosPipeDrive?.property_infos?.amenities.single_sofa_bed_quantity
      ? infosPipeDrive?.property_infos?.amenities.single_sofa_bed_quantity
      : propertyDetails?.singleSofaBeds || 0,
    sofaDoubleBeds: infosPipeDrive?.property_infos?.amenities.double_sofa_bed_quantity
      ? infosPipeDrive?.property_infos?.amenities.double_sofa_bed_quantity
      : propertyDetails?.sofaDoubleBeds || 0,
    bathrooms: infosPipeDrive?.property_infos?.amenities.bathroom_quantity
      ? infosPipeDrive?.property_infos?.amenities.bathroom_quantity
      : propertyDetails?.bathrooms || 0,
    lavabo: propertyDetails?.lavabo || 0,
    guestCapacity: infosPipeDrive?.property_infos?.amenities.capacity
      ? infosPipeDrive?.property_infos?.amenities.capacity
      : propertyDetails?.guestCapacity || 0,
    totalImplantationFee: infosPipeDrive?.property_infos?.implantation_fee_total_value,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (formValues) => {
      const data = {
        propertycode: formValues.propertycode,

        inCondominium: formValues.inCondominium,
        condominium: formValues.inCondominium ? formValues.condominium : '',

        city: formValues.city,
        neighborhood: formValues.neighborhood,
        number: formValues.number,
        postal_code: formValues.postal_code,
        state: normalizeState(stateMappings, formValues.state || ''),
        street: formValues.street,
        complement: formValues.complement,

        category: formValues.categoryLocationId,
        bedroomsAmount: formValues.bedroomsAmount,
        propertyAreaSizeM2: formValues.propertyAreaSizeM2,
        extraDayPreparation: Number(formValues.extraDayPreparation),
        propertyType: formValues.propertyType,

        comment: formValues.comment,

        isOwnerContact: formValues.isOwnerContact,
        onboardingName: formValues.isOwnerContact ? '' : formValues.onboardingName,
        onboardingPhone: formValues.isOwnerContact ? '' : formatPhoneToSubmit(formValues.onboardingPhone),

        queenBeds: formValues.queenBeds,
        doubleBeds: formValues.doubleBeds,
        kingBeds: formValues.kingBeds,
        singleBeds: formValues.singleBeds,
        singleSofaBeds: formValues.singleSofaBeds,
        sofaDoubleBeds: formValues.sofaDoubleBeds,
        bathrooms: formValues.bathrooms,
        lavabo: formValues.lavabo,
        guestCapacity: `${formValues.guestCapacity}` !== '' ? formValues.guestCapacity : 0,
        totalImplantationFee: formValues.totalImplantationFee,
      };

      setPropertyDetails(data);
      handleClickPhase(2);
    },
  });

  const handleSubmit = () => {
    handleValidation(formik, toast);
    formik.handleSubmit();
  }

  const guestCapacity = useMemo(() => {
    const { singleBeds, singleSofaBeds, kingBeds, doubleBeds, queenBeds, sofaDoubleBeds } =
      formik.values;

    const capacity =
      1 * (singleBeds + singleSofaBeds) + 2 * (kingBeds + doubleBeds + queenBeds + sofaDoubleBeds);

    formik.setFieldValue('guestCapacity', capacity);
    return capacity;
  }, [
    formik.values.singleBeds,
    formik.values.singleSofaBeds,
    formik.values.kingBeds,
    formik.values.doubleBeds,
    formik.values.queenBeds,
    formik.values.sofaDoubleBeds,
  ]);

  useEffect(() => {
    if (checkActive(phases, 'Informações do imóvel')) {
      handleValidation(formik, toast);
    }
  }, [phases]);

  useEffect(() => {
    if (resetFormik) {
      setInfosPipeDrive({} as PipedriveOwnerInfo);
      setPropertyDetails({} as PropertyInformation);
      setOwner({} as AccountOwnerOnboardingProps);

      formik.resetForm();
      formik.setValues({
        ...initialValues,
        propertycode: '',
        inCondominium: false,
        condominium: '',
        city: '',
        neighborhood: '',
        number: '',
        state: '',
        street: '',
        postal_code: '',
        complement: '',
        country: 'BR',
        categoryLocationId: 'ADEFINIR',
        bedroomsAmount: 0,
        propertyAreaSizeM2: 0,
        extraDayPreparation: 0,
        propertyType: '',
        comment: '',
        isOwnerContact: false,
        onboardingName: '',
        onboardingPhone: '',
        singleBeds: 0,
        doubleBeds: 0,
        queenBeds: 0,
        kingBeds: 0,
        singleSofaBeds: 0,
        sofaDoubleBeds: 0,
        bathrooms: 0,
        lavabo: 0,
        guestCapacity: 0,
        totalImplantationFee: '',
      });
      setResetFormik(false);
    }
  }, [resetFormik]);

  useEffect(() => {
    if (infosPipeDrive?.property_infos && propertyDetails) {
      formik.setValues({
        ...initialValues,
        propertycode: infosPipeDrive?.property_infos?.property_code
          ? infosPipeDrive?.property_infos?.property_code
          : propertyDetails?.propertycode || '',

        inCondominium: infosPipeDrive?.property_infos?.property_address?.condominium_name
          ? !["", 'null', 'undefined'].includes(`${infosPipeDrive?.property_infos?.property_address?.condominium_name}`)
          : propertyDetails?.inCondominium || false,
        condominium: infosPipeDrive?.property_infos?.property_address?.condominium_name 
          ? infosPipeDrive?.property_infos?.property_address?.condominium_name 
          : propertyDetails?.condominium || '',

        city: infosPipeDrive?.property_infos?.property_address?.property_city
          ? infosPipeDrive?.property_infos?.property_address?.property_city
          : propertyDetails?.city || '',
        neighborhood: infosPipeDrive?.property_infos?.property_address?.property_neighborhood
          ? infosPipeDrive?.property_infos?.property_address?.property_neighborhood
          : propertyDetails?.neighborhood || '',
        number: infosPipeDrive?.property_infos?.property_address?.property_number
          ? infosPipeDrive?.property_infos?.property_address?.property_number
          : propertyDetails?.number || '',
        state: normalizeState(stateMappings, infosPipeDrive?.property_infos?.property_address?.property_state
          ? infosPipeDrive?.property_infos?.property_address?.property_state || ''
          : propertyDetails?.state || ''),
        street: infosPipeDrive?.property_infos?.property_address?.property_street
          ? infosPipeDrive?.property_infos?.property_address?.property_street
          : propertyDetails?.street || '',
        postal_code: infosPipeDrive?.property_infos?.property_address?.property_postal_code
          ? infosPipeDrive?.property_infos?.property_address?.property_postal_code
          : propertyDetails?.postal_code || '',
        complement: infosPipeDrive?.property_infos?.property_address?.property_complement
          ? infosPipeDrive?.property_infos?.property_address?.property_complement
          : propertyDetails?.complement || '',
        country: 'BR',

        categoryLocationId: infosPipeDrive?.property_infos?.property_category
          ? infosPipeDrive?.property_infos?.property_category
          : propertyDetails?.category || 'ADEFINIR',
        bedroomsAmount: infosPipeDrive?.property_infos?.bedroom_quantity
          ? infosPipeDrive?.property_infos?.bedroom_quantity
          : propertyDetails?.bedroomsAmount || 0,
        propertyAreaSizeM2: infosPipeDrive?.property_infos?.property_size
          ? infosPipeDrive?.property_infos?.property_size
          : propertyDetails?.propertyAreaSizeM2 || 0,
        extraDayPreparation: Number(infosPipeDrive?.preparation_days 
          ? infosPipeDrive?.preparation_days
          : propertyDetails?.extraDayPreparation || 0),
        propertyType: infosPipeDrive?.property_infos?.property_type
          ? translateTypeProperty(infosPipeDrive?.property_infos?.property_type)
          : propertyDetails?.propertyType || '',

        comment: infosPipeDrive?.property_infos?.property_comment || propertyDetails?.comment || '',

        isOwnerContact: infosPipeDrive?.property_infos?.is_owner_contact || propertyDetails?.isOwnerContact || false,
        onboardingName: infosPipeDrive?.property_infos?.onboarding_name || propertyDetails?.onboardingName || '',
        onboardingPhone: formatPhoneToShow(
          infosPipeDrive?.property_infos?.onboarding_phone
          ? infosPipeDrive?.property_infos?.onboarding_phone
          : propertyDetails?.onboardingPhone || '', 
          infosPipeDrive?.property_infos?.is_owner_contact || propertyDetails?.isOwnerContact || false
        ),

        singleBeds: infosPipeDrive?.property_infos?.amenities.single_bed_quantity
          ? infosPipeDrive?.property_infos?.amenities.single_bed_quantity
          : propertyDetails?.singleBeds || 0,
        doubleBeds: infosPipeDrive?.property_infos?.amenities.double_bed_quantity
          ? infosPipeDrive?.property_infos?.amenities.double_bed_quantity
          : propertyDetails?.doubleBeds || 0,
        queenBeds: infosPipeDrive?.property_infos?.amenities.queen_bed_quantity
          ? infosPipeDrive?.property_infos?.amenities.queen_bed_quantity
          : propertyDetails?.queenBeds || 0,
        kingBeds: infosPipeDrive?.property_infos?.amenities.king_bed_quantity
          ? infosPipeDrive?.property_infos?.amenities.king_bed_quantity
          : propertyDetails?.kingBeds || 0,
        singleSofaBeds: infosPipeDrive?.property_infos?.amenities.single_sofa_bed_quantity
          ? infosPipeDrive?.property_infos?.amenities.single_sofa_bed_quantity
          : propertyDetails?.singleSofaBeds || 0,
        sofaDoubleBeds: infosPipeDrive?.property_infos?.amenities.double_sofa_bed_quantity
          ? infosPipeDrive?.property_infos?.amenities.double_sofa_bed_quantity
          : propertyDetails?.sofaDoubleBeds || 0,
        bathrooms: infosPipeDrive?.property_infos?.amenities.bathroom_quantity
          ? infosPipeDrive?.property_infos?.amenities.bathroom_quantity
          : propertyDetails?.bathrooms || 0,
        lavabo: propertyDetails?.lavabo || 0,
        guestCapacity: infosPipeDrive?.property_infos?.amenities.capacity
          ? infosPipeDrive?.property_infos?.amenities.capacity
          : propertyDetails?.guestCapacity || 0,
        totalImplantationFee: infosPipeDrive?.property_infos?.implantation_fee_total_value,
      });
    }
  }, []);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <PropertyInformationContainer>
        <TitleContainer>
          <Title>Informações do imóvel</Title>
          <Border />
        </TitleContainer>
        <ContentContainer>
          <DoubleBox>
            <BoxContainer>
              <OnboardingTextField
                id="propertycode"
                label="Código do Imóvel"
                required
                generateCodeButton
                formik={formik}
                value={formik.values.propertycode}
              />
            </BoxContainer>
            <BoxContainer>
              <BoxContent>
                <SwitchContainer>
                  <label htmlFor="inCondominium">Em condomínio ?</label>
                  <OnboardingSwitch
                    id="inCondominium"
                    formik={formik}
                    value={formik.values.inCondominium}
                    alignment="flex-end"
                  />
                </SwitchContainer>

                <OnboardingTextField
                  id="condominium"
                  placeholder="Preencha o nome do condomínio"
                  formik={formik}
                  value={formik.values.condominium}
                  disabled={!formik.values.inCondominium}
                  required={formik.values.inCondominium}
                />
              </BoxContent>
            </BoxContainer>
          </DoubleBox>

          <BoxContainer>
            <FormAddressProperty formik={formik} />
          </BoxContainer>

          <BoxContainer>
            <BoxContent>
              <BoxHeader>
                <BoxTitle>Categoria do imóvel *</BoxTitle>
              </BoxHeader>

              <CategoryField>
                <FormGridRow grid>
                  <Grid item xs={12} sm={6}>
                    <Category labelClassName="subTitle" formik={formik} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="bedroomsAmount"
                      placeholder="Insira um valor numérico"
                      formik={formik}
                      type="number"
                      required
                      label="Número de quartos"
                      value={formik.values.bedroomsAmount}
                      labelClassName="subTitle"
                    />
                  </Grid>
                </FormGridRow>

                <FormGridRow grid>
                  <Grid item alignItems="center" xs={12} sm={6}>
                    <TextField
                      id="extraDayPreparation"
                      placeholder="Insira um valor numérico"
                      formik={formik}
                      type="number"
                      label="Quantos dias são necessários para Tempo de preparo?"
                      required
                      value={formik.values.extraDayPreparation ? Number(formik.values.extraDayPreparation) : 0}
                      labelClassName="subTitle"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SimpleSelect
                      formik={formik}
                      id="propertyType"
                      placeholder="Selecione..."
                      label="Tipo do imóvel"
                      required
                      disabled={false}
                      options={(propertyTypes || []).map<SelectOption>(({ id, type }) => ({
                        value: id,
                        valueLabel: type,
                      }))}
                      labelClassName="subTitle"
                    />
                  </Grid>
                </FormGridRow>
              </CategoryField>
            </BoxContent>
          </BoxContainer>

          <BoxContainer>
            <OnboardingTextField
              id="comment"
              label="Comentários gerais sobre o imóvel"
              placeholder="Digite aqui"
              type="textarea"
              formik={formik}
              value={formik.values.comment}
            />
          </BoxContainer>

          <BoxContainer>
            <BoxContent>
              <BoxHeader>
                <BoxTitle>Contato Para Onboarding *</BoxTitle>
              </BoxHeader>

              <FormGridRow grid>
                <Grid item xs={12} sm={6}>
                  <BoxContent>
                    <SwitchContainer flexDirection="column">
                      <label htmlFor="isOwnerContact">O contato é o proprietário ?</label>
                      <OnboardingSwitch
                        id="isOwnerContact"
                        formik={formik}
                        value={formik.values.isOwnerContact}
                      />
                    </SwitchContainer>
                  </BoxContent>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <OnboardingTextField
                    id="onboardingName"
                    formik={formik}
                    label="Nome:"
                    placeholder="Digite aqui"
                    value={formik.values.onboardingName}
                    disabled={formik.values.isOwnerContact}
                  />
                  <div>
                    <Label className="styledTextFieldLabel">
                      Telefone: (Exemplo: +55 (48) 91234-1234)
                    </Label>
                    <div style={{ margin: '6px 0' }} />
                    <StyledCountrySelector
                      id="onboardingPhone"
                      formik={formik}
                      country={'br'}
                      autoFormat
                      enableTerritories
                      value={formik.values.onboardingPhone}
                      onChange={(value: any) => formik.setFieldValue('onboardingPhone', value)}
                      disableDropdown
                      disabled={formik.values.isOwnerContact}
                      placeholder="+xx (xx) xxxxx-xxxx"
                      masks={{
                        br: '(..) .....-....',
                      }}
                    />
                  </div>
                </Grid>
              </FormGridRow>
            </BoxContent>
          </BoxContainer>

          <QuantityContainer>
            <BoxQuantityContainer>
              <QuatityText>Camas Solteiro</QuatityText>
              <QuantityField
                id="singleBeds"
                dataCy="singleBeds"
                border={false}
                justify={false}
                isOnboardingPage
                initialQuantity={
                  infosPipeDrive?.property_infos?.amenities.single_bed_quantity || 0
                }
                formik={formik}
              />
            </BoxQuantityContainer>
            <BoxQuantityContainer>
              <QuatityText>Camas Casal</QuatityText>
              <QuantityField
                id="doubleBeds"
                dataCy="doubleBeds"
                border={false}
                justify={false}
                isOnboardingPage
                formik={formik}
                initialQuantity={
                  infosPipeDrive?.property_infos?.amenities?.double_bed_quantity || 0
                }
              />
            </BoxQuantityContainer>
            <BoxQuantityContainer>
              <QuatityText>Camas Queen</QuatityText>
              <QuantityField
                id="queenBeds"
                dataCy="queenBeds"
                border={false}
                justify={false}
                isOnboardingPage
                formik={formik}
                initialQuantity={
                  infosPipeDrive?.property_infos?.amenities?.queen_bed_quantity || 0
                }
              />
            </BoxQuantityContainer>
            <BoxQuantityContainer>
              <QuatityText>Camas King</QuatityText>
              <QuantityField
                id="kingBeds"
                dataCy="kingBeds"
                border={false}
                justify={false}
                isOnboardingPage
                formik={formik}
                initialQuantity={
                  infosPipeDrive?.property_infos?.amenities?.king_bed_quantity || 0
                }
              />
            </BoxQuantityContainer>
          </QuantityContainer>

          <QuantityContainer>
            <BoxQuantityContainer>
              <QuatityTextContainer>
                <QuatityText>Sofás Cama Solteiro</QuatityText>
                <InfoIconContainer>
                  <Tooltip text="Camas adicionais e bi-camas devem ser lançadas aqui em sofá-cama solteiro">
                    <InfoOutlined />
                  </Tooltip>
                </InfoIconContainer>
              </QuatityTextContainer>
              <QuantityField
                id="singleSofaBeds"
                dataCy="singleSofaBeds"
                border={false}
                justify={false}
                isOnboardingPage
                formik={formik}
                initialQuantity={infosPipeDrive?.property_infos?.amenities?.single_sofa_bed_quantity || 0}
              />
            </BoxQuantityContainer>
            <BoxQuantityContainer>
              <QuatityText>Sofás Camas Casal</QuatityText>
              <QuantityField
                id="sofaDoubleBeds"
                dataCy="sofaDoubleBeds"
                border={false}
                justify={false}
                isOnboardingPage
                formik={formik}
                initialQuantity={infosPipeDrive?.property_infos?.amenities?.double_sofa_bed_quantity || 0}
              />
            </BoxQuantityContainer>
            <BoxQuantityContainer>
              <QuatityText>Banheiros</QuatityText>
              <QuantityField
                id="bathrooms"
                dataCy="bathrooms"
                border={false}
                justify={false}
                isOnboardingPage
                formik={formik}
                initialQuantity={infosPipeDrive?.property_infos?.amenities?.bathroom_quantity || 0}
              />
            </BoxQuantityContainer>
            <BoxQuantityContainer>
              <QuatityText>Lavabo</QuatityText>
              <QuantityField
                id="lavabo"
                dataCy="lavabo"
                border={false}
                justify={false}
                isOnboardingPage
                formik={formik}
              />
            </BoxQuantityContainer>
          </QuantityContainer>

          <QuantityContainer>
            <BoxContainer>
              <OnboardingTextField
                id="guestCapacity"
                formik={formik}
                label="Capacidade"
                disabled
                value={
                  infosPipeDrive?.property_infos?.amenities?.capacity || guestCapacity
                }
              />
            </BoxContainer>
          </QuantityContainer>
          <End />
        </ContentContainer>
      </PropertyInformationContainer>

      <ButtonContainer>
        <FormButton
          type="button"
          customColor="blue"
          variant="outlined"
          onClick={() => handleClickPhase(0)}
        >
          Voltar
        </FormButton>
        <FormButton type="button" onClick={() => handleSubmit()}>
          Avançar
        </FormButton>
      </ButtonContainer>
    </Form>
  );
};

export default CardPropertyInformation;
