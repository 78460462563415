import { Dispatch, SetStateAction } from 'react';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { UseFormik } from '../../../../../utils/Formik/types';
import FormButton from '../../../../FormButton/FormButton';
import {
  ButtonContainer, GridContainer, LinkText, PendencyFormContainer, PendencyFormItem,
} from './styles';
import PendencyForm from '../../../components/Forms/PendencyForm';
import { CopyLink } from '../../../components/CopyLink';

interface PendencyFormViewProps {
  formik: UseFormik<any>;
  isEditingForm: boolean;
  setIsEditingForm: Dispatch<SetStateAction<boolean>>
}

const PendencyFormView = ({
  formik,
  isEditingForm,
  setIsEditingForm,
}: PendencyFormViewProps) => {
  const { selectedGuestDamage, handleOpenFile } = useGuestDamage();

  return (
    <>
      {isEditingForm ? (
        <PendencyForm
          formik={formik}
          isEditingForm={isEditingForm}
        />
      ) : (
        <PendencyFormContainer
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          key="PendencyForm"
          transition={{
            duration: 0.4,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <GridContainer>
            <PendencyFormItem>
              <h3>Classificação do dano</h3>
              <p>{selectedGuestDamage.item_type || '-'}</p>
            </PendencyFormItem>
            <PendencyFormItem>
              <h3>O que precisa ser feito?</h3>
              <p>{selectedGuestDamage.resolution || '-'}</p>
            </PendencyFormItem>
            <PendencyFormItem>
              <h3>Evidências</h3>
              {selectedGuestDamage.evidences
              && selectedGuestDamage?.evidences?.length > 0
                ? selectedGuestDamage.evidences?.map((evidence) => (
                  <LinkText
                    onClick={() => handleOpenFile(evidence.evidence?.url!,
                      evidence.evidence?.name!)}
                  >
                    {evidence.evidence?.name}
                  </LinkText>
                ))
                : ('-')}
            </PendencyFormItem>
            <PendencyFormItem>
              <h3>Valor</h3>
              <p>{numberToCurrency(selectedGuestDamage.item_price) || '-'}</p>
            </PendencyFormItem>
            <PendencyFormItem>
              <h3>Link do orçamento</h3>
              <CopyLink link={selectedGuestDamage.quotation_link} />
            </PendencyFormItem>
            <PendencyFormItem>
              <h3>Orçamento / Nota fiscal</h3>
              <LinkText
                onClick={() => handleOpenFile(selectedGuestDamage.quotation_file?.url!,
                  selectedGuestDamage.quotation_file?.name!)}
              >
                {selectedGuestDamage.quotation_file?.name || '-'}
              </LinkText>
            </PendencyFormItem>
            <PendencyFormItem>
              <h3>Observações</h3>
              <p>{selectedGuestDamage.observation || '-'}</p>
            </PendencyFormItem>
          </GridContainer>
          <ButtonContainer>
            <FormButton onClick={() => setIsEditingForm(true)}>Editar item</FormButton>
          </ButtonContainer>
        </PendencyFormContainer>
      )}
    </>
  );
};

export default PendencyFormView;
