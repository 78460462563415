import React from 'react';

import { Value } from '../styles';
import { ImmobileContainer } from './styles';

interface ImmobileIDProps {
  dataCy: string;
  immobile: number;
}

const ImmobileID = ({ dataCy, immobile }: ImmobileIDProps) => (
  <ImmobileContainer>
    <Value data-cy={dataCy}>{immobile}</Value>
  </ImmobileContainer>
);

export default ImmobileID;
