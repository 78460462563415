import styled, { keyframes } from 'styled-components';

export const pulse = keyframes`
  to {
    box-shadow: 0 0 0 12px rgba(255, 255, 255, 0);
  }
`;

export const Container = styled.div`
  backdrop-filter: blur(10px);
  background: var(--darkk-blue-900, #001840);
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  font-family: ${({ theme }) => theme.fonts.familys._1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 56px;
  padding: 2rem;

  color: ${({ theme }) => theme.palette.white.main.hex()};

  button {
    background: none;
    border: none;
  }

  .logo {
    width: 150px;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .menu-mobile {
    display: none;

    @media (max-width: 900px) {
      display: flex;
    }
  }
`;

export const ContainerLogo = styled.div`
  cursor: pointer;
  max-width: 150px;

  @media (max-width: 1000px) {
    max-width: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsContainer = styled.div``;

export const Mid = styled.div``;
export const Left = styled.div``;
export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserManualLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 150px;
  height: 20px;
  margin-right: 4rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-size: 1rem;
    font-weight: 700;
  }

  :hover {
    padding: 1rem 0;
    border-bottom: 4px solid ${({ theme }) => theme.palette.white.main.hex()};
    transition: 0.2s;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;
