import React from 'react';

import { Container, Content } from './styles';

interface PaymentRegisterProps {
  handleOpenShowAddVoucherScreen: Function;
}

const PaymentRegister = ({ handleOpenShowAddVoucherScreen }: PaymentRegisterProps) => (
  <Container onClick={() => handleOpenShowAddVoucherScreen()}>
    <Content>
      <h1>+</h1>
      <h2>Adicionar comprovante</h2>
    </Content>
  </Container>
);

export default PaymentRegister;
