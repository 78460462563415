import {
  FC,
  useState,
  useEffect,
  useMemo,
  memo,
  useContext,
} from 'react';

import { useFormik } from 'formik';
import moment from 'moment';

import { X } from 'react-feather';
import { Check } from '@mui/icons-material';

import TrashIcon from '../../../../../assets/icons/generals/bashRed.svg';
import EditIcon from '../../../../../assets/icons/generals/editIcon.svg';

import { PropertyCloseContext } from '../../../PropertyClose/PropertyClose';

import { FinancialCloseParams, ManualFitProps } from '../../../../../services/FinancialClose/types';

import { deleteManualFitDaily, getManualFitDaily, patchManualFitDaily } from '../../../../../services/FinancialClose/Daily/request';

import { currencyToNumber, numberToCurrency } from '../../../../../utils/Formatter';
import { currency } from '../../../../../utils/InputMask/Number';

import { useFinancialClose } from '../../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseProperty } from '../../../../../hooks/FinancialCloseHook/useFinancialCloseProperty';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../../utils/Messages';

import { formatDate, sortManualFitList } from '../../../../../context/FinancialClosePage/FinancialCloseContext/utils';

import FormButton from '../../../../FormButton';
import TextField from '../../../../TextField';
import DatePicker from '../../../../DatePicker';
import DeleteManualFitDaily from '../DeleteManualFitDaily';
import CopyText from '../../../CopyText';

import {
  Form,
  Container,
  Header,
  ContainerHeader,
  ContentHeader,
  Body,
  Title,
  CloseButton,
  Card,
  TableContainer,
  TableHeader,
  TableBody,
  Field,
  FieldDescription,
  ButtonsContainer,
  Button,
  Backdrop,
} from './styles';

const EditManualFitDaily: FC = () => {
  const {
    dateSelected,
    handleActiveLinearProgress,
  } = useFinancialClose();

  const {
    openModalEditManualFitDaily,
    handleOpenModalEditManualFitDaily,
  } = useFinancialCloseProperty();

  const {
    refetchSelectedProperties,
  } = useContext(PropertyCloseContext);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  const params: FinancialCloseParams = {
    start_date: formatDate(dateSelected).start_date,
  };

  type DeleteManualFitProps = {
    id: string,
    isOpen: boolean,
  };

  const [openModalDelete, setOpenModalDelete] = useState<DeleteManualFitProps>({
    id: '',
    isOpen: false,
  });

  const [editedManualFits, setEditedManualFits] = useState<ManualFitProps[]>([]);

  const [manualFitList, setManualFitList] = useState<ManualFitProps[]>([]);

  type EditModeProps = Record<string, boolean>;
  const [editMode, setEditMode] = useState<EditModeProps>({});

  const [initialValuesFormik, setInitialValuesFormik] = useState<any>({});

  const reservation = useMemo(() => (Object
    .keys(openModalEditManualFitDaily.reservation).length !== 0
    ? openModalEditManualFitDaily.reservation : null), [openModalEditManualFitDaily]);

  const getManualFits = async () => {
    if (reservation?.id) {
      const paramsManualFits: FinancialCloseParams = {
        start_date: moment(reservation?.check_in_date, 'DD/MM/YYYY').format('YYYY-MM'),
        end_date: moment(reservation?.check_out_date, 'DD/MM/YYYY').format('YYYY-MM'),
      };
      const response: ManualFitProps[] = await getManualFitDaily({
        ...paramsManualFits,
        reservation_id: reservation.id,
      });

      const responseSorted = sortManualFitList(response);
      setManualFitList(responseSorted);
    }
  };

  useEffect(() => {
    if (openModalEditManualFitDaily.open) {
      getManualFits();
    }
  }, [openModalEditManualFitDaily]);

  useEffect(() => {
    let initialValues: any = {};
    const wasEdited: EditModeProps = {};
    const propertyId = reservation?.property?.id || null;

    if (propertyId) {
      manualFitList.forEach((item) => {
        wasEdited[`${item.id}`] = false;

        initialValues = {
          ...initialValues,
          [`description_${item.id}`]: item?.description || '',
          [`value_${item.id}`]: item?.value ? numberToCurrency(Number(item?.value)) : 'R$ 0,00',
          [`date_${item.id}`]: item?.date_ref ? moment(item?.date_ref).toDate() : new Date(),
        };
      });
    }

    setInitialValuesFormik(initialValues);
    setEditMode(wasEdited);
  }, [manualFitList]);

  const handlePatchManualFit = async (manualFitData: ManualFitProps) => {
    try {
      const response = await patchManualFitDaily(manualFitData);
      return response.data;
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: initialValuesFormik,
    onSubmit: async () => {
      try {
        if (reservation?.property?.id) {
          handleActiveLinearProgress(true);

          await Promise.all(editedManualFits.map(async (item) => {
            await handlePatchManualFit({
              ...item,
              date_ref: item.date_ref,
              description: item.description,
              value: typeof item.value === 'string' ? currencyToNumber(currency(item.value)) : item.value,
            });
          }));

          await refetchSelectedProperties({
            ...params,
            search: reservation.property.code,
          });

          formik.resetForm();

          setTimeout(() => {
            handleOpenModalEditManualFitDaily(false);
            handleActiveLinearProgress(false);
            toast.success('Ajuste editado com sucesso!');
          }, 1000);
        }
      } catch (e: unknown) {
        handleOpenModalEditManualFitDaily(true);
        handleActiveLinearProgress(false);
        toast.error('Não foi possível atualizar o ajuste!');
      }
    },
  });

  const handleDeleteManualFit = async (id?: number) => {
    if (!id || !reservation?.property?.id) return null;

    handleActiveLinearProgress(true);

    try {
      await deleteManualFitDaily(id);

      const newManualFitList = manualFitList?.filter((item) => `${item.id}` !== `${id}`);
      setManualFitList(newManualFitList);

      const newEditedManualFitList = editedManualFits.filter((item) => `${item.id}` !== `${id}`);
      setEditedManualFits(newEditedManualFitList);

      await refetchSelectedProperties({
        ...params,
        search: reservation.property.code,
      });

      handleActiveLinearProgress(false);
      toast.success('Ajuste deletado com sucesso!');

      if (newManualFitList.length === 0) {
        setTimeout(() => {
          handleOpenModalEditManualFitDaily(false);
        }, 2000);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const activeManualFitToEdit = (id: string) => {
    setEditMode((state) => ({
      ...state,
      [id]: true,
    }));
  };

  const storeManualFitEdited = (manualFit: ManualFitProps) => {
    const filtereds = editedManualFits.filter((item) => `${item.id}` !== `${manualFit.id}`);

    const manualFitUpdated: ManualFitProps = {
      ...manualFit,
      date_ref: formik.values?.[`date_${manualFit.id}`],
      description: formik.values?.[`description_${manualFit.id}`],
      value: formik.values?.[`value_${manualFit.id}`],
    };

    const newArray = [...filtereds, { ...manualFitUpdated }];
    setEditedManualFits(newArray);

    setEditMode((state) => ({
      ...state,
      [`${manualFit.id}`]: false,
    }));
  };

  const translateProblemType: Record<string, string> = {
    Discount: 'Desconto',
    Cancellation_Fee: 'Taxa de cancelamento',
    Adjustment: 'Ajuste',
    Early_Check_Out: 'Check-out antecipado',
    Other: 'Outros',
  };

  useEffect(() => {
    if (Object.values(initialValuesFormik).length !== 0) {
      formik.setValues({
        ...initialValuesFormik,
      });
    }
  }, [initialValuesFormik]);

  if (!reservation) return null;

  return (
    <>
      <Form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Container isOpen={openModalEditManualFitDaily.open}>
          <Header>
            <ContainerHeader>
              <Title>Ajuste de diária imóvel</Title>

              <CloseButton
                type="button"
                onClick={() => handleOpenModalEditManualFitDaily({
                  open: false,
                  reservation: {},
                })}
              >
                <X size={22} />
              </CloseButton>
            </ContainerHeader>
            <ContainerHeader>
              <ContentHeader>
                <strong>Código do imóvel</strong>
                <span>{`${reservation.property.code}`}</span>
              </ContentHeader>
              <ContentHeader>
                <strong>Código da reserva</strong>
                <CopyText
                  text={`${reservation.code}`}
                  toastMessage="Código da reserva copiado para área de transferência"
                  tooltipText="Copiar código"
                />
              </ContentHeader>
              <ContentHeader>
                <strong>Hóspede</strong>
                <span>{`${reservation.guest}`}</span>
              </ContentHeader>
              <ContentHeader>
                <strong>Check-in</strong>
                <span>{reservation.check_in_date}</span>
              </ContentHeader>
              <ContentHeader>
                <strong>Check-out</strong>
                <span>{reservation.check_out_date}</span>
              </ContentHeader>
            </ContainerHeader>
          </Header>

          <Body>
            <TableContainer>
              {manualFitList?.length === 0
                ? <p>Nenhum ajuste foi realizado ainda!</p> : (
                  <>
                    <TableHeader>
                      <strong>Data de referência</strong>
                      <strong>Data de inserção</strong>
                      <strong>Saída hóspede</strong>
                      <strong>Tipo de problema</strong>
                      <strong>Motivo</strong>
                      <strong>Valor</strong>
                    </TableHeader>

                    {manualFitList?.map((manualFit) => (
                      <Card key={manualFit.id}>
                        <TableBody>
                          {editMode[`${manualFit.id}`] ? (
                            <Field>
                              <DatePicker
                                dataCy={`input-dateref-${manualFit.id}`}
                                id={`date_${manualFit.id}`}
                                formik={formik}
                                hasInitialDates
                                showDaysOutsideCurrentMonth={false}
                                minDate={moment(reservation?.check_in_date,
                                  'DD/MM/YYYY').toDate()}
                                maxDate={moment(reservation?.check_out_date,
                                  'DD/MM/YYYY').subtract(1, 'day').toDate()}
                              />
                            </Field>
                          ) : (
                            <span data-cy={`input-dateref-${manualFit.id}`}>
                              {`${moment(formik.values[`date_${manualFit.id}`] || new Date()).format('DD/MM/YYYY')}`}
                            </span>
                          )}

                          <span data-cy={`input-dateinsert-${manualFit.id}`}>
                            {`${moment(manualFit?.created_at || new Date()).format('DD/MM/YYYY')} às ${moment(manualFit?.created_at || new Date()).format('HH:mm:ss')}`}
                          </span>

                          <span data-cy={`input-guestDepartureDate-${manualFit.id}`}>
                            {`${manualFit?.guest_departure_date ? moment(manualFit.guest_departure_date).format('DD/MM/YYYY') : ''}`}
                          </span>

                          <span data-cy={`input-problemtype-${manualFit.id}`}>
                            {translateProblemType[`${manualFit.problem_type}`]}
                          </span>

                          {editMode[`${manualFit.id}`] ? (
                            <FieldDescription>
                              <TextField
                                dataCy={`textarea-description-${manualFit.id}`}
                                formik={formik}
                                id={`description_${manualFit.id}`}
                                type="textarea"
                                placeholder="Motivos"
                                value={formik.values[`description_${manualFit.id}`]}
                              />
                            </FieldDescription>
                          ) : (
                            <span data-cy={`textarea-description-${manualFit.id}`}>
                              {formik.values[`description_${manualFit.id}`]}
                            </span>
                          )}

                          {editMode[`${manualFit.id}`] ? (
                            <Field>
                              <TextField
                                dataCy={`input-value-${manualFit.id}`}
                                id={`value_${manualFit.id}`}
                                formik={formik}
                                mask="money"
                                placeholder="R$ 0,00"
                                value={currency(formik.values[`value_${manualFit.id}`])}
                              />
                            </Field>
                          ) : (
                            <span data-cy={`input-value-${manualFit.id}`} className={`${manualFit.is_adding ? 'isAdding' : 'isRemoving'}`}>
                              {currency(formik.values[`value_${manualFit.id}`])}
                            </span>
                          )}

                          {!editMode[`${manualFit.id}`] ? (
                            <Button
                              type="button"
                              data-cy={`btn-edit-manualfit-daily-${manualFit.id}`}
                              onClick={() => activeManualFitToEdit(`${manualFit.id}`)}
                            >
                              <img src={EditIcon} alt="editar" />
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              data-cy={`btn-edit-confimartion-manualfit-daily-${manualFit.id}`}
                              onClick={() => storeManualFitEdited(manualFit)}
                            >
                              <Check />
                            </Button>
                          )}

                          <Button
                            type="button"
                            data-cy={`btn-delete-manualfit-daily-${manualFit.id}`}
                            onClick={() => setOpenModalDelete({
                              id: `${manualFit?.id || ''}`,
                              isOpen: true,
                            })}
                          >
                            <img src={TrashIcon} alt="deletar" />
                          </Button>
                        </TableBody>

                        {openModalDelete.isOpen && `${manualFit.id}` === `${openModalDelete.id}` && (
                          <DeleteManualFitDaily
                            openModal={openModalDelete.isOpen}
                            onClose={() => setOpenModalDelete({
                              id: '',
                              isOpen: false,
                            })}
                            handleDelete={
                              () => handleDeleteManualFit(manualFit.id)
                            }
                          />
                        )}
                      </Card>
                    ))}
                  </>
                )}
            </TableContainer>
          </Body>

          <ButtonsContainer className="btn-submit">
            <FormButton dataCy="btn-cancel-edit-manualfit" type="button" variant="outlined" onClick={() => handleOpenModalEditManualFitDaily(false)}>Cancelar</FormButton>
            <FormButton
              dataCy="btn-save-edit-manualfit"
              type="submit"
              disable={editedManualFits.length === 0}
              customColor={editedManualFits.length !== 0 ? 'blue' : 'grey'}
            >
              Salvar
            </FormButton>
          </ButtonsContainer>
        </Container>
      </Form>

      { openModalEditManualFitDaily && (
        <Backdrop onClick={() => handleOpenModalEditManualFitDaily(false)} />
      )}
    </>
  );
};

export default memo(EditManualFitDaily);
