import { motion } from 'framer-motion';
import { useLottie } from 'lottie-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmationAnimationJSON } from '../../../../../assets/animations';
import { Container } from './style';

const options = {
  animationData: confirmationAnimationJSON,
  loop: false,
  autoplay: true,
};

const style = {
  height: 200,
};

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 },
};

const ConfirmationAnimation = () => {
  const { View } = useLottie(options, style);
  return View;
};

const Success = () => {
  const navigate = useNavigate();

  return (
    <Container
      variants={list}
      initial="hidden"
      animate="visible"
    >
      <ConfirmationAnimation />
      <motion.h1 variants={item}>Dados enviados com sucesso!</motion.h1>
      <motion.p variants={item}>
        Envie sua
        <motion.span variants={item}> nota fiscal ou recibo </motion.span>
        para o e-mail: administrativo@seazone.com.br
      </motion.p>
      <motion.button onClick={() => navigate(-1)} variants={item} type="button">
        Fechar
      </motion.button>
    </Container>
  );
};
export default Success;
