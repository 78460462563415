import { motion } from 'framer-motion';
import styled from 'styled-components';

export const PageTitle = styled(motion.h1)`
  display: flex;
  font-size: 2rem;
  font-weight: 600;
  line-height: 31px;
  font-style: normal;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.familys._6};

  color: var(--gray-800, #394760);

  /* Heading 3 */
  opacity: 1;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem; /* 145.455% */
`;
