/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useParams } from 'react-router-dom';
import { usePartnerIndicate } from '../../../../../../context/Partners/IndicateContext/IndicateContext';

import Menu from './Menu';

import {
  Item, Border, Container, LeftItems, FilterButton, FilterContainer,
} from './style';

const FilterSVGIcon = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" fill="#151B26" />
  </svg>
);

const Header = () => {
  const { filter } = useParams();

  const { activeFilter } = usePartnerIndicate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function validateActive(choice: string) {
    if (filter === choice) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Menu open={open} anchorEl={anchorEl} handleClose={handleClose} />

      <Container>
        <FilterContainer>
          <FilterButton
            type="button"
            active={open || activeFilter !== undefined}
            whileTap={{ scale: 0.9 }}
            onClick={handleOpen}
          >
            <FilterSVGIcon />
          </FilterButton>
        </FilterContainer>

        <LeftItems>
          <Item active={validateActive('todos')} to="todos">
            Todos
          </Item>
          <Item active={validateActive('terreno')} to="terreno">
            Terreno
          </Item>
          <Item active={validateActive('locacao')} to="locacao">
            Locação
          </Item>
          <Item active={validateActive('spot')} to="spot">
            Spot
          </Item>
        </LeftItems>
      </Container>
    </>
  );
};

export default Header;
