export type ButtonTypeProps = 'button' | 'submit' | 'reset';

export type Color = 'new-blue' | 'new-blue-outline' | 'new-black-outline' | 'blue' | 'blue-dark' | 'red' | 'red-dark' | 'grey' | 'grey-dark' | 'blue-light' | 'black' | undefined;
export type Variant = 'outlined' | undefined;

export type ButtonProps = {
  customcolorbg?: Color;
  variant?: Variant;
  linkto?: number;
  invisible?: boolean | undefined;
  isFull?: boolean | undefined;
  newStyle?: boolean | undefined;
};

export enum VariantEnum {
  OUTLINED = 'outlined',
}

export enum ButtonTypeEnum {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ColorsEnum {
  BLUE = 'blue',
  RED = 'red',
  GREY = 'grey',
  BLACK = 'black',
}
