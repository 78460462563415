import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const SwitcherOption = styled.h1<{
  active: boolean;
}>`
  color: var(--gray-800, #394760);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  opacity: ${({ active }) => (active ? '1' : '0.5')};
`;
