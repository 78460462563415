import { useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';
import { Roles } from '../../services/types';
import { getMainRoute } from '../../utils/Routes';
import { translateRole } from '../../utils/Translator';

interface IUseSelectRoles {
  only?: string;
  disabled?: [string];
}

export interface IRoutesArray {
  id: string;
  link: string;
  name: string;
  disablePrefix?: boolean | undefined;
}

const DEFAULT_DISABLED = ['Guest'];

type ICustomName = {
  role: Roles;
  name: string;
};

function validateCustomName(role: Roles) {
  const customNames: ICustomName[] = [
    {
      role: 'Partner',
      name: 'Programa de indicações',
    },
  ];

  return customNames.find((customName) => {
    if (customName.role === role) {
      return customName;
    }
    return null;
  });
}

function resolveNavigateRouteWithPrefix(route: IRoutesArray | null) {
  if (route) {
    if (route.disablePrefix) {
      return route.name;
    }

    return `Página de ${route.name}`;
  }

  return null;
}

const useSelectRoles = (args?: IUseSelectRoles) => {
  const { pathname } = useLocation();
  const { userInformation } = useUser();

  const pathnameSplit = pathname.split('/');

  const validateRole = (role: string) => {
    const roleFound = userInformation?.roles.find((item) => item === role);
    if (roleFound) {
      return true;
    }
    return false;
  };

  const redirectToRole = (role: string) => {
    const roleFound = userInformation?.roles.find((item) => item === role);
    if (roleFound) {
      return getMainRoute[roleFound];
    }
    return '';
  };

  const routesArray = userInformation?.roles.map((role) => {
    const typedRole = role as Roles;
    const validRole = validateRole(role);
    const customName = validateCustomName(typedRole);

    const validateIfActualPathIsDifferent = getMainRoute[role];
    const validatePath = validateIfActualPathIsDifferent.includes(pathnameSplit[1]);

    if (DEFAULT_DISABLED.includes(role)) {
      return null;
    }

    if (args?.disabled?.includes(role)) {
      return null;
    }

    if (args?.only) {
      return {
        id: args?.only,
        name: translateRole(args?.only),
        link: redirectToRole(args?.only),
      };
    }

    if (customName && !validatePath) {
      return {
        id: role,
        name: customName.name,
        link: redirectToRole(role),
        disablePrefix: true,
      };
    }

    if (validRole && !validatePath) {
      return {
        id: role,
        name: translateRole(role),
        link: redirectToRole(role),
      };
    }

    return null;
  });

  const filterRoutes = routesArray?.filter((item) => item !== null);

  return {
    userInformation,
    redirectToRole,
    validateRole,
    routesArray: filterRoutes,
    resolveNavigateRouteWithPrefix,
  };
};

useSelectRoles.defaultProps = {
  args: {
    disabled: [],
    only: undefined,
  },
};

export default useSelectRoles;
