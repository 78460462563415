import styled, { css } from 'styled-components';
import { shimmerAnimation } from '../LoadingShimmer/animationShimmer';

export const GeneralContainer = styled.div`
  width: 100%;
`;

export const ContainerCalendar = styled.div`
  margin: 1rem;

  @media (max-width: 795px) {
    margin: 0px;
  }
`;

export const ContentDetails = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.grey._240.hex()};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  margin: 24px 0 42px 16px;
  width: calc(100% - 32px);

  @media (max-width: 795px) {
    flex-direction: column;
    padding: 1rem;
    margin: 24px 0 42px 0;
    width: 100%;
  }
`;

export const Divider = styled.span`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
`;

export const Image = styled.img`
  width: 203px;
  height: 232px;
  margin-left: 9px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.blue._700.hex()};
  object-fit: contain;

  visibility: hidden;
  @media (max-width: 795px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const ImagePropertyShimmer = styled.div`
  width: 203px;
  height: 232px;
  margin-left: 9px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  animation: ${shimmerAnimation} 1s ease-in-out infinite;
  visibility: hidden;

  @media (max-width: 795px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const CardProperty = styled.div`
  margin-left: 24px;
  padding-top: 27px;
  width: 300px;
  height: 100%;
  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-weight: 700;
    font-size: 2rem;
    line-height: 25px;
    margin-bottom: 5px;
  }
  span {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 400;
    font-size: 1rem;
    line-height: 16px;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 400;
    font-size: 1rem;
    margin-top: 10px;
    max-width: 90%;
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 15px;
    line-height: 16px;
    margin-top: 10px;
  }

  @media (max-width: 795px) {
    width: 100%;
  }
`;

export const PropertyCodeShimmer = styled.div`
  width: 25%;
  height: 22px;
  background-color: ${({ theme }) => theme.palette.grey._340.hex()};
  animation: ${shimmerAnimation} 1s ease-in-out infinite;
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  border-radius: 8px;
`;

export const AddressShimmer = styled(PropertyCodeShimmer)`
  width: 100%;
  height: 25px;
  margin: 0.2rem 0;
`;

export const LinksOtasShimmer = styled(PropertyCodeShimmer)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

export const ContainerButtons = styled.div`
  display: block;
  align-items: center;
  width: calc(100% - 25px) !important;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 795px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }
`;

export const ButtonChange = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 35px;
  border-radius: 10px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  cursor: pointer;
  -webkit-box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);
  box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);
  margin-top: 13px;

  &:hover {
   filter: brightness(0.9);
  }

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
`;

export const ButtonContactHost = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 35px;
  border-radius: 10px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  background-color: ${({ theme }) => theme.palette.green.main.hex()};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  cursor: pointer;
  -webkit-box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);
  box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);
  margin-top: 6px;

  &:hover {
   filter: brightness(0.9);
  }

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
`;

export const ButtonBlack = styled.button`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  background: ${({ theme }) => theme.palette.blue._950.hex()};
  background: linear-gradient(
    360deg,
    ${({ theme }) => theme.palette.blue._950.hex()} 0%,
    ${({ theme }) => theme.palette.grey._720.hex()} 100%
  );
  color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  border: none;
  height: 38px;
  width: 125px;
  margin-top: 28px;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: none;
  align-items: center;
  cursor: pointer;

  @media (max-width: 900px) {
    margin-top: 5px;
    margin-right: 0;
  }

  @media (max-width: 795px) {
    margin-right: 5px;
    width: 40%;
  }
`;

export const Ota = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    object-fit: contain;
    width: 15px;
    height: 15px;
  }
`;

export const LinksOtas = styled.div<{ loading?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 10px;
  h2 {
    margin-top: 0 !important;
    width: 50px;
  }

  ${(props) => (props.loading ? css`
    img {
      visibility: hidden;
    }
    ` : css`
      visibility: visible;
  `)}
`;

export const ButtonGrey = styled.button`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
  height: 38px;
  width: 125px;
  margin-top: 28px;
  padding-left: 15px;
  padding-right: 15px;
  display: none;
  align-items: center;
  cursor: pointer;

  @media (max-width: 900px) {
    margin-top: 5px;
  }

  @media (max-width: 795px) {
    width: 40%;
  }
`;

export const Line = styled.div<{ loading?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 59px;
  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  ${(props) => (props.loading ? css`
    p:last-child {
      visibility: hidden;
    }
    ` : css`
      visibility: visible;
  `)}
`;

export const FinancialValueShimmer = styled(PropertyCodeShimmer)`
  width: 25px;
  height: 25px;
`;

export const CardFinances = styled.div`
  width: 350px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.grey._340.hex()};
  border-radius: 10px;

  @media (max-width: 795px) {
    width: 100%;
  }
`;

export const ContentTransfer = styled.div<{ loading?: boolean }>`
  width: 100%;
  height: 63px;
  background-color: ${({ theme }) => theme.palette.green.main.hex()};
  color: ${({ theme }) => theme.palette.white.main.hex()};
  padding-left: 24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  p,
  span {
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
  p {
    width: 100px;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 16px;
  }
  span {
    font-weight: 500;
    font-size: 1.4rem;
  }

  ${(props) => (props.loading ? css`
    span {
      visibility: hidden;
    }
    ` : css`
      visibility: visible;
  `)}
`;

export const ContentBody = styled.div`
  width: 100%;
  height: 187px;
  padding: 27px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
  }
`;

export const CalendarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TitleShimmer = styled.div`
  width: 30%;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.grey._340.hex()};
  animation: ${shimmerAnimation} 1s ease-in-out infinite;
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  border-radius: 8px;
  margin-bottom: 2rem;
`;
