import React from 'react';
import Input from '../Input/Input';
import { UseFormik } from '../../../../../../../../utils/Formik/types';

type Mask =
  | 'money'
  | 'cpf'
  | 'cnpj'
  | 'cpfOrCnpj'
  | 'cep'
  | 'phone'
  | 'hour'
  | 'agency'
  | 'account'
  | 'integerNumber';

interface IPixKeyInput {
  formik: UseFormik<any>
}

const PixKeyInput = ({
  formik,
}: IPixKeyInput) => {
  const [mask, setMask] = React.useState<Mask | undefined>(undefined);
  const [placeholder, setPlaceholder] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const handleMask = () => {
      switch (formik.values.typePixKey) {
        case 'CPF': {
          setMask('cpf');
          setPlaceholder('Ex: 000.000.000-00');
          break;
        }
        case 'CNPJ': {
          setMask('cnpj');
          setPlaceholder('Ex: 00.000.000/0000-00');
          break;
        }
        case 'Phone_Number': {
          setMask('phone');
          setPlaceholder('Ex: +00 (00) 00000-0000');
          break;
        }
        case 'Email': {
          setPlaceholder('Ex: joao@seazone.com.br');
          setMask(undefined);
          break;
        }
        default: {
          setPlaceholder('Ex: 000000000');
          setMask(undefined);
          break;
        }
      }
    };
    handleMask();
  }, [formik.values.typePixKey]);

  return (
    <Input
      formik={formik}
      id="pixKey"
      title="Chave PIX"
      placeholder={placeholder}
      disabled={formik.values.wantToReceiveByPix === 'no'}
      subtext="Se você não possuir chave PIX, deixar em branco"
      type={formik.values.typePixKey === 'Email' ? 'email' : undefined}
      mask={mask}
    />
  );
};

export default PixKeyInput;
