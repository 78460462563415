import styled, { css, keyframes } from 'styled-components';
import {
  AddCircleOutline,
  RemoveCircleOutline,
  MoreVert,
  ArrowDropDown,
  KeyboardArrowUp,
  BorderColor,
  CheckOutlined,
  Cancel,
} from '@mui/icons-material';

import { shimmerAnimation } from '../../Calendar/LoadingShimmer/animationShimmer';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

type GridProps = {
  loading?: boolean;
  expanded?: boolean;
  disableMarginHorizontal?: boolean;
  disableMarginVertical?: boolean;
  disableBorder?: boolean;
  disableBorderTop?: boolean;
  disableBorderRight?: boolean;
  disableBorderBottom?: boolean;
  disableBorderLeft?: boolean;
  disableBorderRadius?: boolean;
  disableBorderTopLeftRadius?: boolean;
  disableBorderTopRightRadius?: boolean;
  customColor?: string;
  customBorderColor?: string;
  borderWidth?: number;
  customWidth?: number;
  customHeight?: number;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  contentEditable?: boolean;
  whiteSpace?: string;
  isVisible?: boolean;
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: calc(92% - 65px);
  content-visibility: auto;

  p, th, tr, td {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 0.95rem;
    color: ${({ theme }) => theme.palette.blue._900.hex()};
  }
`;

export const Content = styled.form`
  width: 100%;
  height: 100%;
`;

export const ContainerTable = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 2rem;

  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.white._650.hex()};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    border-radius: 10px;
  }
`;

export const Table = styled.table`
  width: 100%;
  height: auto;
  margin-top: 1rem;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  .th-main-grid-fixed-top, .th-secondary-grid-fixed-top {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }

  .th-main-grid-fixed-left, .td-main-grid-fixed-left,
  .th-secondary-grid-fixed-left, .td-secondary-grid-fixed-left {
    position: sticky;
    position: -webkit-sticky;
    left: 0
  }

  .th-main-grid-fixed-top, .th-main-grid-fixed-left, .td-main-grid-fixed-left {
    z-index: 2;
  }

  .th-secondary-grid-fixed-top, .th-secondary-grid-fixed-left, .td-secondary-grid-fixed-left {
    z-index: 1;
  }
`;

export const TableBody = styled.tbody<GridProps>`
  background: ${({ customColor, theme }) => (customColor || theme.palette.white._350.hex())} !important;
  font-size: 0.5rem;
  text-align: center;
  content-visibility: ${({ isVisible }) => (isVisible ? 'auto' : 'hidden')};
  `;

export const Tr = styled.tr<GridProps>`
  background: ${({ customColor }) => (customColor || 'transparent')} !important;
`;

export const Th = styled.th<GridProps>`
  width: ${({ customWidth }) => (customWidth || 150)}px !important;
  height: ${({ customHeight }) => (customHeight || 55)}px !important;
  background: ${({ customColor }) => (customColor || 'transparent')} !important;
  border: 0 !important;
  font-weight: normal;
  display: ${({ hidden }) => (hidden ? 'none' : 'visible')};
`;

export const Td = styled.td<GridProps>`
  width: ${({ customWidth }) => (customWidth || 120)}px !important;
  height: ${({ customHeight }) => (customHeight || 43)}px !important;

  background: ${({ customColor, theme }) => (
    customColor === 'Closed'
      ? theme.palette.green._100.hex()
      : theme.palette.white._350.hex() || 'transparent')} !important;

  border-bottom: ${({ customColor, theme }) => (
    customColor === 'Closed'
      ? `5px solid ${theme.palette.white._350.hex()}`
      : '0 !important'
  )};

  white-space: nowrap !important;
  display: ${({ hidden }) => (hidden ? 'none' : 'visible')};
`;

export const ThContainer = styled.div<GridProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${({ disableMarginHorizontal }) => (disableMarginHorizontal ? 100 : 95)}%;
  height: ${({ disableMarginVertical }) => (disableMarginVertical ? 100 : 95)}%;

  border-top-left-radius: ${({ disableBorderTopLeftRadius }) => (disableBorderTopLeftRadius ? 0 : 10)}px;
  border-top-right-radius: ${({ disableBorderTopRightRadius }) => (disableBorderTopRightRadius ? 0 : 10)}px;
  border: ${({ borderWidth }) => (borderWidth || 1)}px solid ${({ theme, customBorderColor }) => (customBorderColor || theme.palette.white._650.hex())};

  padding: 0.25rem;
  margin: 0.125rem 0.25rem;

  ${(props) => props.disableBorder && css`
    border: 0;
  `}

  ${(props) => props.disableBorderRadius && css`
    border-radius: 0;
  `}

  ${(props) => props.disableBorderTop && css`
    border-top: 0;
  `}

  ${(props) => props.disableBorderRight && css`
    border-right: 0;
  `}

  ${(props) => props.disableBorderBottom && css`
    border-bottom: 0;
  `}

  ${(props) => props.disableBorderLeft && css`
    border-left: 0;
  `}

  ${(props) => props.disableMarginHorizontal && css`
    margin: 0.125rem 0;
  `}

  ${(props) => props.disableMarginVertical && css`
    margin: 0 0.25rem;
  `}

  ${(props) => props.customColor && css`
    background: ${props.customColor};
  `}

  ${(props) => props.minWidth && css`
    min-width: ${props.minWidth}px;
  `}

  ${(props) => props.maxWidth && css`
    max-width: ${props.maxWidth}px;
  `}

  ${(props) => props.minHeight && css`
    min-height: ${props.minHeight}px;
  `}

  ${(props) => props.maxHeight && css`
    max-height: ${props.maxHeight}px;
  `}
`;

export const TdContainer = styled(ThContainer)`
  background: ${({ theme }) => theme.palette.white._350.hex()};

  padding-right: 5px;
  margin-left: -0.5px;

  ${(props) => props.loading && css`
    animation: ${shimmerAnimation} 1s ease-in-out infinite;
    div {
      visibility: hidden;
    }
  `}

  ${(props) => props.customColor && css`
    background: ${props.customColor};
  `}

  ${(props) => props.contentEditable && css`
    background: ${({ theme }) => theme.palette.white.main.hex()};

    p {
      font-weight: bold;
      color: ${({ theme }) => theme.palette.blue._900.hex()};
    }
  `}
`;

export const TitleContainer = styled.div<{ expanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 0.2rem;

  svg {
    position: relative;
    right:  ${({ expanded }) => (expanded ? -85 : 0)}%;
  }
`;

export const Title = styled.p<{ disableWidth?: boolean, textAlign?: string, customColor?: string, isInputData?: boolean }>`
  width: ${({ disableWidth }) => (disableWidth ? 'auto' : '100%')};
  color: ${({ customColor, theme }) => (customColor ? `${customColor}` : theme.palette.blue._900.hex())} !important;
  text-align: ${({ textAlign }) => (textAlign || 'center')};

  ${({ isInputData }) => isInputData && css`
    font-weight: bold;
  `}
`;

export const Subtitle = styled.span`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.palette.grey._445.hex()};
`;

export const ContainerArrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  img {
    padding: 0.1rem 0 0.1rem 0.5rem;
    cursor: pointer;
  }
`;

export const Image = styled.img``;

export const ArrowDownIcon = styled(ArrowDropDown)`
  fill:  ${({ theme }) => theme.palette.blue._400.hex()} !important;
  width: 30px !important;
  height: 30px !important;
  transform: rotate(0deg);
`;

export const ArrowUpIcon = styled(ArrowDropDown)`
  fill:  ${({ theme }) => theme.palette.blue._400.hex()} !important;
  width: 30px !important;
  height: 30px !important;
  transform: rotate(180deg);
`;

export const KeyboardArrowUpIcon = styled(KeyboardArrowUp)`
  fill:  ${({ theme }) => theme.palette.white.main.hex()} !important;
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
`;

export const MoreVertButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MoreVertButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 50px;

  border: 1px solid ${({ theme }) => theme.palette.white._650.hex()};
  background: ${({ theme }) => theme.palette.grey._610.hex()};
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const MoreVertIcon = styled(MoreVert)``;

export const PencilIcon = styled(BorderColor)`
  width: 12px !important;
  height: 12px !important;
  cursor: pointer;
`;

export const CheckIcon = styled(CheckOutlined)`
  width: 12px !important;
  height: 12px !important;
  cursor: pointer;
`;

export const CancelIcon = styled(Cancel)`
  width: 12px !important;
  height: 12px !important;
  cursor: pointer;
`;

export const RadioButtonContainer = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 43px;
  background: ${({ theme }) => theme.palette.white._350.hex()};
`;

export const RadioButton = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
`;

export const ExpandIcon = styled(AddCircleOutline)`
  margin: 0.15rem !important;
  width: 14px !important;
  height: 14px !important;
  fill: ${({ theme }) => theme.palette.blue._400.hex()} !important;
  cursor: pointer;
`;

export const CollapseIcon = styled(RemoveCircleOutline)`
  margin: 0.15rem !important;
  width: 14px !important;
  height: 14px !important;
  fill: ${({ theme }) => theme.palette.red._450.hex()} !important;
  cursor: pointer;
`;

export const ContainerLoadMoreDatas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentLoadMoreDatas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 20px;

  p {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
    font-weight: bold;
  }
`;

export const WrapperGridRow = styled.div<{ customColor?: string }>`
  display: grid;
  grid-template-columns: 6fr;
  flex-direction: column;
`;

export const ContainerGridRow = styled.div<{ customColor?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  resize: vertical;
  overflow: auto;
  padding: 1rem;

  background: ${({ theme, customColor }) => (customColor || theme.palette.grey._330.hex())};
  border: 1px dashed ${({ theme }) => theme.palette.white._650.hex()};
  padding-bottom: 0.8rem;

  .reservation-table, .expense-table {
    width: 100%;
  }

  .reservation-list,
  .expense-list {
    width: 100%;
    max-width: 140px;
  }
`;

export const CollapseRow = styled.div`
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;

  svg {
    position: relative;
    left: 0;
    bottom: 5px;
    z-index: 100;
    cursor: pointer;
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  left: 25px;
  top: 5px;
`;

export const manualFitColor: Record<string, string> = {
  true: '#219653',
  green: '#219653',
  false: '#E35B4C',
  red: '#E35B4C',
  default: '#283143',
};

export const ContainerInformation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ContentInformation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100px;

  p {
    position: relative;
    top: 40px;
    left: 22%;
    font-weight: bold;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.palette.grey._550.hex()} !important;
  }
`;

export const TextFieldContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;

  .MuiOutlinedInput-root {
    max-width: 100px !important;
    max-height: 30px !important;
    border: 0 !important;
    margin: 0 2px 8px 3px;
  }

  .MuiInputBase-input{
    width: 100%;
    max-width: 100px !important;
    max-height: 30px !important;
    border: 0 !important;
  };
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin: 0.2rem;


  .datePickerContainer {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd, fieldset {
      border: 0 !important;
    }

    .MuiInputBase-input{
      border: 0 !important;
    }

    &:hover fieldset, .Mui-focused fieldset, fieldset:focus, fieldset:active {
      border: 0 !important;
    }
  }

  input {
    width: 100% !important;
    min-width: 75px !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
`;

export const PencilButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 0;
  background: ${({ theme }) => theme.palette.grey._310.hex()} !important;

  svg {
    fill: ${({ theme }) => theme.palette.grey._700.hex()};
  }
`;

export const CheckButton = styled(PencilButton)`
  width: 22px;
  height: 22px;
`;

export const CancelButton = styled(CheckButton)``;

export const ContainerSpinner = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const Spinner = styled.div<{ isLoading: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.grey._440.hex()};
  border-left-color: ${({ theme }) => theme.palette.blue._850.hex()};

  ${({ isLoading }) => isLoading
    && css`
      animation: ${rotate} 0.5s linear infinite;
  `}
`;

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: -2.6px;
`;

export const ReservationCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 130px;

  p {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    position: relative;
    left: 0.5rem;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
`;
