import { useContextSelector } from 'use-context-selector';
import { OwnerServicesTermsContext } from '../../../context/OwnerPage/OwnerServicesTermsContext/OwnerServicesTermsContext';

export function useOwnerServicesTerms() {
  const openServicesTerms = useContextSelector(OwnerServicesTermsContext,
    (state) => state.openServicesTerms);

  const setOpenServicesTerms = useContextSelector(OwnerServicesTermsContext,
    (state) => state.setOpenServicesTerms);

  const properties = useContextSelector(OwnerServicesTermsContext,
    (state) => state.properties);

  const setProperties = useContextSelector(OwnerServicesTermsContext,
    (state) => state.setProperties);

  const readAndAgreedTerms = useContextSelector(OwnerServicesTermsContext,
    (state) => state.readAndAgreedTerms);

  const setReadAndAgreedTerms = useContextSelector(OwnerServicesTermsContext,
    (state) => state.setReadAndAgreedTerms);

  const readAndNoAgreedTerms = useContextSelector(OwnerServicesTermsContext,
    (state) => state.readAndNoAgreedTerms);

  const setReadAndNoAgreedTerms = useContextSelector(OwnerServicesTermsContext,
    (state) => state.setReadAndNoAgreedTerms);

  return {
    properties,
    setProperties,
    openServicesTerms,
    setOpenServicesTerms,
    readAndAgreedTerms,
    setReadAndAgreedTerms,
    readAndNoAgreedTerms,
    setReadAndNoAgreedTerms,
  };
}
