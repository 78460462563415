import NoPropertyIcon from '../../../assets/icons/estimatePage/noneProperty.svg';

import { useHostPropertiesFilter } from '../../../hooks/useHostPropertiesFilter/useHostPropertiesFilter';
import { useMobile } from '../../../hooks/useMobile/useMobile';
import { useCreateBlockPropertyHostPageData } from '../../../context/CreateBlockPropertyInHostPage/CreateBlockPropertyInHostpage';

import HostBlockPropertyForm from '../HostBlockPropertyForm';
import ModalSuccessLock from '../../OwnerPage/Modal/ModalSuccessLock';
import LateralModal from '../../LateralModal';
import Header from './Header';
import Items from './Items';
import ItemsShimmer from './ItemShimmer/ItemShimmer';

import {
  Container,
  Content,
  MoreButtonContainer,
  TitleContainer,
} from './styles';

type GridProps = {
  isSuccess: boolean;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage?: Function;
};

const Grid = ({
  isSuccess,
  isLoading,
  hasNextPage,
  fetchNextPage = () => {},
}: GridProps) => {
  const { isMobile } = useMobile();
  const { allItemsFormatted } = useHostPropertiesFilter();

  const {
    isLateralModalOpen,
    handleCloseModal,
    handleOpenModal,
    isSuccessModalOpen,
    checkInDate,
    checkOutDate,
    blockPropertyCode,
    handleCloseSuccessModal,
  } = useCreateBlockPropertyHostPageData();

  return (
    <>
      <Container>
        <Content>
          <Header />
          {isLoading && (
            <>
              <ItemsShimmer />
              <ItemsShimmer />
              <ItemsShimmer />
              <ItemsShimmer />
              <ItemsShimmer />
              <ItemsShimmer />
              <ItemsShimmer />
            </>
          )}
          {isSuccess && allItemsFormatted.length !== 0 && (
            <>
              {allItemsFormatted.map((property) => (
                <Items
                  key={property?.id}
                  immobile={property?.code}
                  status={property?.status}
                  startOfContract={property?.contract_start_date}
                  owner={
                    property?.owners[0]?.user?.first_name === ''
                      ? property?.owners[0]?.user?.trading_name
                      : `${property?.owners[0]?.user?.first_name}
                 ${property?.owners[0]?.user?.last_name}`
                  }
                  proprietaryPhone={property?.owners[0]?.user?.phone_number1}
                  address={property?.address}
                  // commission={property?.comission_fee}
                  taxCleaning={property?.cleaning_fee}
                  beds={property?.beds_quantity}
                  bedrooms={property?.bedroom_quantity}
                  bathrooms={property?.bathroom_quantity}
                  id={property?.id}
                  host={`${property.host.user.first_name} ${property.host.user.last_name}`}
                  category={property.category_location}
                />
              ))}
            </>
          )}
          {!isSuccess && (
            <TitleContainer>
              <img src={NoPropertyIcon} alt="Icone de nenhuma propriedade" />
              <h1>Nada foi encontrado!</h1>
            </TitleContainer>
          )}
          {allItemsFormatted.length === 0 && (
            <TitleContainer>
              <img src={NoPropertyIcon} alt="Icone de nenhuma propriedade" />
              <h1>Nada foi encontrado!</h1>
            </TitleContainer>
          )}
        </Content>
        {hasNextPage && (
          <MoreButtonContainer>
            <button type="button" onClick={() => fetchNextPage()}>
              Carregar mais imóveis
            </button>
          </MoreButtonContainer>
        )}
      </Container>
      <LateralModal
        customStyle={isMobile ? {
          height: 'fit-content',
        } : undefined}
        open={isLateralModalOpen}
        setOpen={handleOpenModal}
        onClose={handleCloseModal}
        headerTitle={'Criar bloqueio para o Imóvel'}
        propertyName={blockPropertyCode}
      >
        <HostBlockPropertyForm />
      </LateralModal>
      <ModalSuccessLock
        propertyCode={blockPropertyCode}
        handleCloseModalSuccessLock={handleCloseSuccessModal}
        open={isSuccessModalOpen}
        startDate={checkInDate}
        endDate={checkOutDate}
        variant="blue"
      />
    </>
  );
};

Grid.defaultProps = {
  fetchNextPage: () => {},
};

export default Grid;
