import { useEffect } from 'react';

import {
  Content,
  Container,
} from './styles';

import { useHostProperties } from '../../hooks/useHostProperties/useHostProperties';
import { useHostPropertiesFilter } from '../../hooks/useHostPropertiesFilter/useHostPropertiesFilter';

import Grid from './Grid';
import Header from './Header';

const HostProperties = () => {
  const {
    codeFilter,
    statusFilter,
    bedroomsFilter,
    locationFilter,
    handleChangeAllItemsFormatted,
    hostNameFilter,
  } = useHostPropertiesFilter();

  const {
    data,
    isSuccess,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useHostProperties(
    codeFilter,
    statusFilter,
    bedroomsFilter,
    locationFilter,
    hostNameFilter,
  );

  useEffect(() => {
    if (data !== undefined) {
      handleChangeAllItemsFormatted(data?.pages.flatMap((page) => page.results));
    }
  }, [data]);

  return (
    <Container>
      <Content>
        <Header />
        <Grid
          isSuccess={isSuccess}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </Content>
    </Container>
  );
};

export default HostProperties;
