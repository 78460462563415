import React from 'react';

import ValueText from './ValueText';
import PaymentDo from './PaymentDo';
import PaymentRegister from './PaymentRegister';

import {
  Container,
  PaymentTitle,
  ValueContainer,
  PaymentDoContainer,
  ReservationNotFound,
} from './styles';

import { usePaymentVoucher } from '../../../../../../hooks/PaymentVoucherHook/usePaymentVoucher';
import { paymentDataInitialValues } from '../../../../../../context/PaymentVoucher/utils';
import { formatteMoneyVoucher } from '../../../../../../services/PaymentVoucher/utils';

interface ValueTableProps {
  totalValue: number | string;
  pendingValue: number | string;
  handleOpenShowAddVoucherScreen: Function;
}

const ValueTable = ({
  handleOpenShowAddVoucherScreen,
  totalValue,
  pendingValue,
}: ValueTableProps) => {
  const { paymentData } = usePaymentVoucher();

  const verifyIfDataIsEmpty = () => {
    if (paymentData === paymentDataInitialValues) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <ValueContainer>
        <ValueText text="Valor Total" color="green" value={totalValue} />
        <ValueText text="Valor Pendente" color="red" value={pendingValue} />
      </ValueContainer>

      <PaymentRegister
        handleOpenShowAddVoucherScreen={handleOpenShowAddVoucherScreen}
      />

      <PaymentDoContainer>
        <PaymentTitle>Pagamentos</PaymentTitle>

        {verifyIfDataIsEmpty() && (
          <ReservationNotFound>
            <h1>Nenhum pagamento foi registrado</h1>
          </ReservationNotFound>
        )}

        {verifyIfDataIsEmpty() === false && (
          paymentData.map((payment, index) => (
            <PaymentDo
              id={payment.id}
              key={payment.id}
              index={index + 1}
              date={payment.date}
              amount={formatteMoneyVoucher(payment.amount)}
            />
          ))
        )}
      </PaymentDoContainer>
    </Container>
  );
};

export default ValueTable;
