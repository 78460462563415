import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Divider } from '@mui/material';

import TextField from '../../../../../../../components/TextField/TextField';
import FormGridRow from '../../../../../../../components/FormGridRow/FormGridRow';
import { UseFormik } from '../../../../../../../utils/Formik/types';

import {
  SubmitButton,
  FormContainer,
  FooterContainer,
  PropertyFormTitle,
  Spacing,
  SelectContainer,
} from '../style';

import { SimpleSelect } from '../../../../../../../components';
import { SelectOption } from '../../../../../../../components/SimpleSelect/SimpleSelect';
import { getEnterprises } from '../../../../../../../services/Enterprise/request';
import { EnterpriseResponseProps } from '../../../../../../../services/Enterprise/types';
import Phone from '../lib/Phone';

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 10 },
};

interface ISpotIndicationForm {
  formik: UseFormik<any>;
}

const SpotIndicationForm = ({ formik }: ISpotIndicationForm) => {
  const [enterprisesInfos, setEnterprisesInfos] = useState<EnterpriseResponseProps[]>([]);

  const handleGetEnterprises = async () => {
    try {
      const response = await getEnterprises();
      setEnterprisesInfos(response);
    } catch {
      setEnterprisesInfos([]);
    }
  };

  useEffect(() => {
    handleGetEnterprises();
  }, []);

  const enterprises: SelectOption[] = useMemo(() => enterprisesInfos.map((enterprise) => ({
    value: enterprise.id,
    valueLabel: enterprise.name,
  })), [enterprisesInfos]);

  const enterpriseUnits: SelectOption[] = useMemo(() => {
    const enterpriseId = formik.values?.enterprise;

    if (enterpriseId) {
      const enterprise = enterprisesInfos
        .filter((item) => `${item.id}` === `${enterpriseId}`)?.[0] || [];

      return enterprise.enterprise_unit.map((unit) => ({
        value: unit.id,
        valueLabel: unit.number,
      })) as SelectOption[];
    }
    return [];
  }, [formik.values?.enterprise]);

  const validateForm = () => {
    const noError = Object.keys(formik.errors).length === 0;
    if (noError) {
      return false;
    }
    return true;
  };

  return (
    <>
      <FormContainer
        translate="yes"
        transition={{ duration: 0.5 }}
        variants={variants}
      >
        <Divider />
        <PropertyFormTitle>Dados do SPOT</PropertyFormTitle>

        <FormGridRow grid>
          <Grid item sm={6} xs={12}>
            <SelectContainer>
              <SimpleSelect
                id="enterprise"
                formik={formik}
                dataCy="select-enterprise"
                placeholder="Selecione"
                label="Empreendimento"
                options={enterprises}
                required
                requireSymbolPosition="right"
              />
            </SelectContainer>
          </Grid>

          <Grid item sm={6} xs={12}>
            <SelectContainer>
              <SimpleSelect
                id="unit"
                formik={formik}
                dataCy="select-unit"
                placeholder="Selecione"
                label="Unidade"
                options={enterpriseUnits}
              />
            </SelectContainer>
          </Grid>
        </FormGridRow>
        <Divider />
        <Spacing />

        <PropertyFormTitle>Dados do investidor</PropertyFormTitle>
        <FormGridRow grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="name"
              dataCy="input-investor-name"
              formik={formik}
              label="Nome do investidor"
              placeholder="Ex: Paulo Machado"
              required
              requireSymbolPosition="right"
            />
            <Spacing />

            <TextField
              id="email"
              dataCy="input-investor-email"
              formik={formik}
              label="E-mail do investidor"
              placeholder="Ex: paulomachado@seazone.com.br"
              requireSymbolPosition="right"
              type="email"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Phone
              label="Telefone do investidor"
              formik={formik}
              required
            />
            <Spacing />

            <TextField
              id="comment"
              dataCy="input-comment"
              formik={formik}
              label="Observações"
              placeholder="Digite aqui..."
              type="textarea"
            />
          </Grid>
        </FormGridRow>
      </FormContainer>

      <FooterContainer variants={variants}>
        <SubmitButton
          disabled={validateForm()}
          type="submit"
          whileTap={{ scale: 0.9 }}
        >
          <h1>Enviar</h1>
        </SubmitButton>
      </FooterContainer>
    </>
  );
};

export default SpotIndicationForm;
