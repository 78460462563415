import React, { FC, useEffect } from 'react';
import { HeaderConfigProvider } from '../../context/ControllerPage/HeaderConfigContext';
import ControllerComponent from '../../components/ControllerPage/ControllerComponent';
import { Container } from './styles';

const Controller: FC<{}> = () => {
  useEffect(() => {
    document.title = 'Sapron | Controle';
  }, []);

  return (
    <Container>
      <HeaderConfigProvider>
        <ControllerComponent />
      </HeaderConfigProvider>
    </Container>
  );
};

export default Controller;
