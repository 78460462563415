import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 190px;
  width: 100%;
  text-align: left;
  justify-content: left;
`;

export const TitleContainer = styled.div`
  display: flex;
  min-width: 190px;
  width: 100%;
  text-align: left;
  flex-direction: row;
  justify-content: left;

  h1 {
    opacity: 0.7;
    display: flex;
    font-size: 2rem;
    font-weight: 600;
    line-height: 31px;
    font-style: normal;
    align-items: left;
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const ClusterContainer = styled.div``;

export const Title = styled(motion.h1)<{
  capitalize?: boolean;
}>`
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
`;

export const HappyFace = styled(motion.h1)``;
