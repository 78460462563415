/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import {
  FC,
  useEffect,
  useState,
  useMemo,
} from 'react';

import { Grid } from '@mui/material';

import { useFormik } from 'formik';
import useKeys from '../../../../hooks/GlobalHook/useKeys';

import {
  DatePicker,
  FormButton,
  RadioButton,
  SimpleSelect,
  TextField,
} from '../../..';

import StarRating from '../../../StarRating';

import newExpense from '../../../../assets/icons/expense/newExpense.svg';
import iconClose from '../../../../assets/icons/generals/iconClose.svg';

import {
  Backdrop,
  Container,
  Content,
  CloseButton,
  HeaderContainer,
  FormContainer,
  FormContent,
  LeftSideContainer,
  RightSideContainer,
  ButtonsContainer,
  PhotoContainer,
  NoteContainer,
  RadiosButtonContainer,
  ErrorIcon,
  FieldCodeProperty,
  PendingReasonContainer,
  ContainerSupplier,
  ValueContainer,
} from './styles';

import {
  currencyToNumber,
  formatedDateOfRequest,
  numberToCurrency,
} from '../../../../utils/Formatter';

import {
  FileProps,
} from '../../../../context/FileContext/types';

import {
  AllInformationExpenseProps,
  ExpenseProps,
  ExpenseReason,
  ExpenseReasonLabel,
  ExpenseReasonValue,
  ExpenseResponseProps,
} from '../../../../services/Expenses/types';

import { IAccountSimpleHostsResponse } from '../../../../services/AccountSimpleHosts/types';
import { IAccountSimpleOwners } from '../../../../services/AccountSimpleOwners/types';

import { patchExpenses, postExpenses } from '../../../../services/Expenses/request';
import { getAccountSimpleOwners } from '../../../../services/AccountSimpleOwners/AccountSimpleOwners';
import { getAccountSimpleHosts } from '../../../../services/AccountSimpleHosts/request';

import { initialValuesFile } from '../../../../context/FileContext/FileContext';
import { useLoader } from '../../../../context/LoaderContext';
import { useToast } from '../../../../context/ToastContext';
import { useUser } from '../../../../context/UserContext';
import { useExpense } from '../../../../hooks/ExpenseHook/useExpense';
import { useStarRating } from '../../../../hooks/StarRatingHook/useStarRating';

import { ResponsiblesForExpenseProps, getParamsResponsibleUser } from '../../../../services/Expenses/utils';
import { currency } from '../../../../utils/InputMask/Number';
import { ErrorMessage, useToastErrorMessage } from '../../../../utils/Messages';
import { compareList } from '../../../../utils/Sorting';
import { translatedReceivedBy } from '../../../../utils/Translator';

import DropdownAutocomplete, { SelectProps } from '../../../DropdownAutocomplete/DropdownAutocomplete';
import FormGridRow from '../../../FormGridRow';
import UploadButton from './UploadButtons/UploadButton';
import DuplicateAlert from './DuplicateAlert';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';


type ValuesFormikProps = {
  supplier: string,
  supplier_phonenumber: string,
  supplier_rating: number,
  value: string,
  reason: string,
  description: string,
  expense_status: string,
  expense_date: Date,
  owner_approval: boolean,
  owner_approval_status: string,
  property_id: number,
  paid_by: string | undefined,
  paid_by_user: number | undefined,
  received_by: string | undefined,
  received_by_user: number | undefined,
};

type AllFormikType = {
  supplier: string,
  supplier_phonenumber: string,
  supplier_rating: number,
  value: string,
  reason: string,
  description: string,
  expense_status: string,
  expense_date: Date,
  owner_approval: boolean,
  owner_approval_status: string,
  property_id: number,
  paid_by: string,
  paid_by_user: number | undefined,
  received_by: string,
  received_by_user: number | undefined,
};

type FileReferenceResponse = {
  id?: string;
  uid: string,
  name: string,
  category?: 'Maintenance' | 'Statement',
  content_type: string,
  storage: {
    url: string,
    fields: {
      acl: string,
      content_type?: string,
      key: string,
      AWSAccessKeyId: string,
      policy: string,
      signature: string
    }
  }
};

const NewExpensesModal: FC = () => {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const NAME_PAGE = userInformation?.main_role === 'Host' ? 'reembolso' : 'despesa';

  const {
    openModalExpenses,
    handleOpenModalExpenses,
    isEditingModal,
    indexExpenseSelected,
    expensesList,
    properties,
    page,
    filterParams,
    fetchExpenseData,
  } = useExpense();

  const { rating, handleChangeRating } = useStarRating();

  const esc = useKeys('Escape');
  if (esc) handleOpenModalExpenses(false);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();
  const { setLoad } = useLoader();

  const [hosts, setHosts] = useState<IAccountSimpleHostsResponse[]>([]);
  const [owners, setOwners] = useState<IAccountSimpleOwners[]>([]);
  const [isNotExpenseDuplicate, setIsNotExpenseDuplicate] = useState<boolean>(false);
  const [showErrorOwnerApprovalNotFound, setShowErrorOwnerApprovalNotFound] = useState<boolean>(false);
  const [showErrorReason, setShowErrorReason] = useState<boolean>(false);
  const [showErrorPaidBy, setShowErrorPaidBy] = useState<boolean>(false);
  const [showErrorReceivedBy, setShowErrorReceivedBy] = useState<boolean>(false);
  const [showErrorReceivedByUser, setShowErrorReceivedByUser] = useState<boolean>(false);
  const [defaultReceivedByUser, setDefaultReceivedByUser] = useState<SelectProps>({ optionText: '', optionValue: '' });
  const [loading, setLoading] = useState(false);
  const [showErrorPropertyNotFound, setShowErrorPropertyNotFound] = useState<boolean>(false);
  const [showErrorRatingNotFound, setShowErrorRatingNotFound] = useState<boolean>(false);
  const [showErrorDescrptionVoid, setShowErrorDescrptionVoid] = useState<boolean>(false);
  const [showErrorSupplierPhoneNumberNotFound, setShowErrorSupplierPhoneNumberNotFound] = useState<boolean>(false);
  const [showErrorMinSupplierPhoneNumber, setShowErrorMinSupplierPhoneNumber] = useState<boolean>(false);
  const [showErrorFilesRequired, setShowErrorFilesRequired] = useState<boolean>(false);
  const [sendingFileArray, setSendingFileArray] = useState<FileReferenceResponse[]>([]);
  const [statementFiles, setStatementFiles] = useState<FileProps[]>([]);
  const [maintenanceFiles, setMaintenanceFiles] = useState<FileProps[]>([]);
  const [uploadStatementFile, setUploadStatementFile] = useState<FileProps>(initialValuesFile);
  const [uploadMaintenanceFile, setUploadMaintenanceFile] = useState<FileProps>(initialValuesFile);
  const [allInformationExpenseFiles, setAllInformationExpenseFiles] = useState<AllInformationExpenseProps>({} as AllInformationExpenseProps);

  const expense: ExpenseResponseProps = useMemo(() => (expensesList?.results?.[indexExpenseSelected] || null), [expensesList]);

  const getOwnerApprovalStatus: Record<string, string> = {
    true: 'approved',
    false: 'notApproved',
    default: '',
  };

  const isSeazoneUser = ['Seazone', 'Admin'].includes(`${userInformation?.main_role}`) && (roles.includes('SeazoneAdministrative') || roles.includes('SeazoneOnboarding') || roles.includes('Admin'));

  const optionIsSelected = (option: string) => !['Selecione', 'undefined', 'null', ''].includes(`${option}`);

  const getInitialExpenseDate = () => {
    if (!isEditingModal) return new Date();

    const today = new Date().toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const dateString = expense?.expense_date || today;
    const expenseDate = new Date(dateString);
    expenseDate.setDate(expenseDate.getDate() + 1);

    return expenseDate;
  };

  const handleValidationsForm = (values: ValuesFormikProps) => {
    const hasError = false;

    if (values.property_id === -1) {
      setShowErrorPropertyNotFound(true);
      return true;
    }

    if (values.supplier_rating === 0) {
      setShowErrorRatingNotFound(true);
      return true;
    }

    if (values.supplier_phonenumber === '') {
      setShowErrorSupplierPhoneNumberNotFound(true);
      return true;
    }

    if (values.supplier_phonenumber.length < 19) {
      setShowErrorMinSupplierPhoneNumber(true);
      return true;
    }

    if (['Selecione', ''].includes(values.reason)) {
      setShowErrorReason(true);
      return true;
    }

    if (isSeazoneUser) {
      if (!optionIsSelected(`${values.paid_by}`)) {
        setShowErrorPaidBy(true);
        return true;
      }
      if (!optionIsSelected(`${values.received_by}`)) {
        setShowErrorReceivedBy(true);
        return true;
      }
      if (['Owner', 'Host'].includes(`${values.received_by}`) && !optionIsSelected(`${values.received_by_user}`)) {
        setShowErrorReceivedByUser(true);
        return true;
      }
    }

    if (values.owner_approval_status === '') {
      setShowErrorOwnerApprovalNotFound(true);
      return true;
    }

    if (values.description.length <= 0) {
      setShowErrorDescrptionVoid(true);
      return true;
    }

    if (statementFiles.length === 0) {
      setShowErrorFilesRequired(true);
      return true;
    }

    return hasError;
  };

  const handleResetStates = () => {
    setLoad(false);
    handleOpenModalExpenses(false);
    setSendingFileArray([]);
    setMaintenanceFiles([]);
    setStatementFiles([]);
  };

  const formik = useFormik<AllFormikType>({
    initialValues: {
      supplier: '',
      supplier_phonenumber: '',
      supplier_rating: 0,
      value: 'R$ 0,00',
      reason: '',
      description: '',
      expense_status: 'Analyzing',
      expense_date: new Date(getInitialExpenseDate()),
      owner_approval: false,
      owner_approval_status: '',
      property_id: -1,
      paid_by: 'Selecione',
      paid_by_user: undefined,
      received_by: 'Selecione',
      received_by_user: undefined,
    },
    onSubmit: async (values) => {
      try {
        const isInvalid = handleValidationsForm(values as ValuesFormikProps);
        if (isInvalid) return;
        setLoad(true);

        let filesUids = sendingFileArray.map((item) => item.uid);

        const responsibles: ResponsiblesForExpenseProps = {
          main_role: userInformation?.main_role || '',
          roles: userInformation?.roles || [],
          paid_by: values.paid_by,
          paid_by_user_id: values.paid_by === 'Seazone' ? 237 : Number(values.paid_by_user),
          received_by: values.received_by,
          received_by_user_id: values.received_by === 'Seazone' ? 237 : Number(values.received_by_user),
          logged_user_id: Number(userInformation?.user_id || -1),
        };

        if (!isEditingModal) {
          const expenseParamsPost: ExpenseProps = {
            expense_date: formatedDateOfRequest(values.expense_date.toString()),
            expense_status: values.expense_status,
            reason: values.reason as ExpenseReason,
            description: values.description,
            supplier: values.supplier,
            supplier_phonenumber: values.supplier_phonenumber,
            supplier_rating: values.supplier_rating,
            value: currencyToNumber(currency(formik.values.value)),
            owner_approval: values.owner_approval,
            property: Number(values.property_id),
            file_uids: filesUids,
            ...getParamsResponsibleUser(responsibles),
          };

          await postExpenses(expenseParamsPost);
          await fetchExpenseData(page, filterParams);
          toast.success(userInformation?.main_role !== 'Host' ? 'Despesa inserida com sucesso!' : 'Reembolso inserido com sucesso!');
          handleResetStates();
        } else {
          const uidsMaintenanceFiles = allInformationExpenseFiles.maintenanceExpenseFiles.map((item: any) => `${item.file.uid}`).filter((uid) => !['undefined', 'null'].includes(uid));
          const uidsStatementFiles = allInformationExpenseFiles.statementExpenseFiles.map((item: any) => `${item.file.uid}`).filter((uid) => !['undefined', 'null'].includes(uid));
          filesUids = [...filesUids, ...uidsMaintenanceFiles, ...uidsStatementFiles];

          const expenseParamsPatch: ExpenseProps = {
            expense_date: formatedDateOfRequest(values.expense_date.toString()),
            expense_status: 'Analyzing',
            reason: values.reason as ExpenseReason,
            description: values.description,
            supplier: values.supplier,
            supplier_phonenumber: values.supplier_phonenumber,
            supplier_rating: values.supplier_rating,
            value: currencyToNumber(currency(formik.values.value)),
            owner_approval: values.owner_approval,
            file_uids: filesUids,
            ...getParamsResponsibleUser(responsibles),
          };

          if (expense?.id) {
            await patchExpenses(expense.id, expenseParamsPatch);
            await fetchExpenseData(page, filterParams);
            toast.success(userInformation?.main_role !== 'Host' ? 'Despesa editada com sucesso!' : 'Reembolso editado com sucesso!');
            handleResetStates();
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error(e.message || ErrorMessage.default());
          toastErrorRequest(e);
        }
        setLoad(false);
      }
    },
  });

  useEffect(() => {
    if (!isSeazoneUser) return;

    const fetchData = async () => {
      setLoading(true);
      const [allHosts, allOwners] = await Promise.all([getAccountSimpleHosts(), getAccountSimpleOwners()]);
      setHosts(allHosts);
      setOwners(allOwners);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (openModalExpenses && isEditingModal) {
      if (expense) {
        formik.setValues({
          ...formik.initialValues,
          supplier: expense?.supplier || '',
          supplier_phonenumber: expense?.supplier_phonenumber || '',
          supplier_rating: expense?.supplier_rating || 0,
          value: expense?.value?.toString() || 'R$ 0,00',
          reason: expense?.reason || '',
          description: expense?.description || '',
          expense_status: expense?.expense_status || 'Analyzing',
          expense_date: getInitialExpenseDate(),
          owner_approval: expense?.owner_approval || false,
          owner_approval_status: getOwnerApprovalStatus[expense?.owner_approval?.toString() || 'default'],
          property_id: expense?.property?.id as any,
          paid_by: expense?.paid_by || 'Selecione',
          received_by: expense?.received_by || 'Selecione',
          received_by_user: expense?.received_by_user as any,
        });
        handleChangeRating(expense?.supplier_rating);
      }
    }

    return () => handleResetStates();
  }, [openModalExpenses, isEditingModal, indexExpenseSelected]);

  useEffect(() => {
    if (optionIsSelected(formik.values.reason)) {
      setShowErrorReason(false);
    }
  }, [formik.values.reason]);

  useEffect(() => {
    if (optionIsSelected(formik.values.paid_by)) {
      setShowErrorPaidBy(false);
    }
  }, [formik.values.paid_by]);

  useEffect(() => {
    if (optionIsSelected(formik.values.received_by)) {
      setShowErrorReceivedBy(false);
    }

    formik.setFieldValue('received_by_user', undefined);
    setShowErrorReceivedByUser(true);
  }, [formik.values.received_by]);

  useEffect(() => {
    if (optionIsSelected(`${formik.values.received_by_user}`)) {
      setShowErrorReceivedByUser(false);
    }
  }, [formik.values.received_by_user]);

  useEffect(() => {
    if (formik.values.owner_approval_status === 'approved') {
      formik?.setFieldValue('owner_approval', true);
      formik?.setFieldTouched(
        'owner_approval', true, false,
      );
      setShowErrorOwnerApprovalNotFound(false);
    } else if (formik.values.owner_approval_status === 'notApproved') {
      formik?.setFieldValue('owner_approval', false);
      formik?.setFieldTouched(
        'owner_approval', true, false,
      );
      setShowErrorOwnerApprovalNotFound(false);
    }
  }, [formik.values.owner_approval_status]);

  useEffect(() => {
    formik?.setFieldValue('supplier_rating', rating);
    formik?.setFieldTouched(
      'supplier_rating', true, false,
    );
  }, [rating]);

  useEffect(() => {
    handleChangeRating(isEditingModal ? Number(expense?.supplier_rating || 0) : 0);
  }, [expense]);

  useEffect(() => {
    if (formik.values.supplier_rating !== 0) {
      setShowErrorRatingNotFound(false);
    }
  }, [formik.values.supplier_rating]);

  useEffect(() => {
    if (formik.values.property_id !== -1) {
      setShowErrorPropertyNotFound(false);
    }
  }, [formik.values.supplier_rating]);

  useEffect(() => {
    if (formik.values.supplier_phonenumber.length === 19) {
      setShowErrorMinSupplierPhoneNumber(false);
    }
  }, [formik.values.supplier_phonenumber]);

  useEffect(() => {
    if (statementFiles.length === 0) {
      setShowErrorFilesRequired(false);
    }
  }, [statementFiles]);

  const handleGetProperty = (): SelectProps[] => properties.actives.map((property) => (
    { optionText: `${property.code}`, optionValue: `${property.id}` }
  )).sort((a, b) => compareList(a.optionText, b.optionText));

  const handleGetHost = (): SelectProps[] => (hosts || []).map((item: any) => ({
    optionText: `${item?.full_name}`,
    optionValue: item?.user_id,
  })).sort((a: SelectProps, b: SelectProps) => compareList(a.optionText || '', b.optionText || ''));

  const handleGetOwner = (): SelectProps[] => (owners || []).map((item: any) => ({
    optionText: `${item?.name}`,
    optionValue: item?.user_id,
  })).sort((a: SelectProps, b: SelectProps) => compareList(a.optionText || '', b.optionText || ''));

  useEffect(() => {
    const filteredProperty = properties.actives.filter((item) => `${item.id}` === `${formik.values.property_id}`);
    if (filteredProperty.length === 0) return;

    if (!isSeazoneUser) {
      const hostName = `${filteredProperty[0].host.user.first_name} ${filteredProperty[0].host.user.last_name}`;
      const hostUserId = `${filteredProperty[0].host.user.id}`;
      formik.setFieldValue('received_by_user', hostUserId);
      setDefaultReceivedByUser({
        optionText: hostName,
        optionValue: hostUserId,
      });
      return;
    }

    switch (formik.values.received_by) {
      case "Host":
        const hostName = `${filteredProperty[0].host.user.first_name} ${filteredProperty[0].host.user.last_name}`;
        const hostUserId = `${filteredProperty[0].host.user.id}`;
        formik.setFieldValue('received_by_user', hostUserId);
        setDefaultReceivedByUser({
          optionText: hostName,
          optionValue: hostUserId,
        });
        break;

      case "Owner":
        const ownerName = `${filteredProperty[0].owners[0].user.first_name} ${filteredProperty[0].owners[0].user.last_name}`;
        const ownerUserId = `${filteredProperty[0].owners[0].user.id}`;
        formik.setFieldValue('received_by_user', ownerUserId);
        setDefaultReceivedByUser({
          optionText: ownerName,
          optionValue: ownerUserId,
        });
        break;

      case "Seazone":
        formik.setFieldValue('received_by_user', `${userInformation?.user_id}`);
        setDefaultReceivedByUser({
          optionText: 'Seazone',
          optionValue: `${userInformation?.user_id}`,
        });
        break;

      default:
        break;
    }
  }, [formik.values.received_by, formik.values.property_id, properties]);

  useEffect(() => {
    const filteredProperty = properties.actives.filter((item) => `${item.id}` === `${formik.values.property_id}`);
    if (filteredProperty.length === 0) return;

    if (!isSeazoneUser) {
      formik.setFieldValue('paid_by_user', filteredProperty?.[0]?.host?.user?.id);
      return;
    }

    switch (formik.values.paid_by) {
      case "Host":
        formik.setFieldValue('paid_by_user', filteredProperty?.[0]?.host?.user?.id);
        break;
      case "Owner":
        formik.setFieldValue('paid_by_user', filteredProperty?.[0]?.owners[0]?.user?.id);
        break;
      case "Seazone":
        formik.setFieldValue('paid_by_user', Number(userInformation?.user_id || -1));
        break;
      default:
        break;
    }
  }, [formik.values.paid_by, formik.values.property_id, properties]);

  const handleGetReceivedBy: Record<string, SelectProps[]> = {
    'Host': handleGetHost(),
    'Owner': handleGetOwner(),
    'Seazone': [],
  };

  let options: SelectOption[] = [
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_CONDOMINIUM,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_CONDOMINIUM,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_ENERGY,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_ENERGY,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_GARDEN,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_GARDEN,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_INTERNET,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_INTERNET,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_IPTU,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_IPTU,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_POOL,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_POOL,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_TV,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_TV,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_WATER,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_WATER,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_SUBSCRIPTION,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_SUBSCRIPTION,
    },
    {
      value: ExpenseReasonValue.MAINTENANCE,
      valueLabel: ExpenseReasonLabel.MAINTENANCE,
    },
    {
      value: ExpenseReasonValue.PURCHASE_REQUIRED_ITEMS,
      valueLabel: ExpenseReasonLabel.PURCHASE_REQUIRED_ITEMS,
    },
    {
      value: ExpenseReasonValue.THIRD_PARTY_SERVICES,
      valueLabel: ExpenseReasonLabel.THIRD_PARTY_SERVICES,
    },
    {
      value: ExpenseReasonValue.ONBOARDING_KEYS_CLEANING,
      valueLabel: ExpenseReasonLabel.ONBOARDING_KEYS_CLEANING,
    },
    {
      value: ExpenseReasonValue.ONBOARDING_LAUNDRY,
      valueLabel: ExpenseReasonLabel.ONBOARDING_LAUNDRY,
    },
    {
      value: ExpenseReasonValue.RECCURENT_EXPENSE,
      valueLabel: ExpenseReasonLabel.RECCURENT_EXPENSE,
    },
    {
      value: ExpenseReasonValue.GUEST_DAMAGE,
      valueLabel: ExpenseReasonLabel.GUEST_DAMAGE,
    },
    {
      value: ExpenseReasonValue.SEAZONE_CHARGES,
      valueLabel: ExpenseReasonLabel.SEAZONE_CHARGES,
    },
    // {
    //   value: ExpenseReasonValue.RESERVATION_COMMISSION,
    //   valueLabel: ExpenseReasonLabel.RESERVATION_COMMISSION,
    // },
    // {
    //   value: ExpenseReasonValue.OWNER_CLEANING,
    //   valueLabel: ExpenseReasonLabel.OWNER_CLEANING,
    // },
    {
      value: ExpenseReasonValue.OWNER_RESERVATION,
      valueLabel: ExpenseReasonLabel.OWNER_RESERVATION,
    },
    {
      value: ExpenseReasonValue.ACCOUNT_MANAGEMENT_GAS,
      valueLabel: ExpenseReasonLabel.ACCOUNT_MANAGEMENT_GAS,
    },
    {
      value: ExpenseReasonValue.MEMBERSHIP_FEE_MADEGO,
      valueLabel: ExpenseReasonLabel.MEMBERSHIP_FEE_MADEGO,
    },
    {
      value: ExpenseReasonValue.LAYETTE_PARCEL_MADEGO,
      valueLabel: ExpenseReasonLabel.LAYETTE_PARCEL_MADEGO,
    },
    {
      value: ExpenseReasonValue.BUYING_LAYETTE,
      valueLabel: ExpenseReasonLabel.BUYING_LAYETTE,
    },
    {
      value: ExpenseReasonValue.LAYETTE_DAMAGE,
      valueLabel: ExpenseReasonLabel.LAYETTE_DAMAGE,
    },
    {
      value: ExpenseReasonValue.PRO_PHOTO_REVIEW,
      valueLabel: ExpenseReasonLabel.PRO_PHOTO_REVIEW,
    },
    {
      value: ExpenseReasonValue.MATERIAL_PURCHASE,
      valueLabel: ExpenseReasonLabel.MATERIAL_PURCHASE,
    },
    {
      value: ExpenseReasonValue.REFUND_EXPENSE,
      valueLabel: ExpenseReasonLabel.REFUND_EXPENSE,
    },
  ].sort((firstOption,
    nextOption) => compareList(firstOption.valueLabel, nextOption.valueLabel));

  if (roles.includes('Host')) {
    options = [
      {
        value: ExpenseReasonValue.MAINTENANCE,
        valueLabel: ExpenseReasonLabel.MAINTENANCE,
      },
      {
        value: ExpenseReasonValue.PURCHASE_REQUIRED_ITEMS,
        valueLabel: ExpenseReasonLabel.PURCHASE_REQUIRED_ITEMS,
      },
      // {
      //   value: ExpenseReasonValue.THIRD_PARTY_SERVICES,
      //   valueLabel: ExpenseReasonLabel.THIRD_PARTY_SERVICES,
      // },
      {
        value: ExpenseReasonValue.ONBOARDING_KEYS_CLEANING,
        valueLabel: ExpenseReasonLabel.ONBOARDING_KEYS_CLEANING,
      },
      {
        value: ExpenseReasonValue.ONBOARDING_LAUNDRY,
        valueLabel: ExpenseReasonLabel.ONBOARDING_LAUNDRY,
      },
      {
        value: ExpenseReasonValue.RECCURENT_EXPENSE,
        valueLabel: ExpenseReasonLabel.RECCURENT_EXPENSE,
      },
      {
        value: ExpenseReasonValue.GUEST_DAMAGE,
        valueLabel: ExpenseReasonLabel.GUEST_DAMAGE,
      },
      // {
      //   value: ExpenseReasonValue.SEAZONE_CHARGES,
      //   valueLabel: ExpenseReasonLabel.SEAZONE_CHARGES,
      // },
      // {
      //   value: ExpenseReasonValue.RESERVATION_COMMISSION,
      //   valueLabel: ExpenseReasonLabel.RESERVATION_COMMISSION,
      // },
      // {
      //   value: ExpenseReasonValue.OWNER_CLEANING,
      //   valueLabel: ExpenseReasonLabel.OWNER_CLEANING,
      // },
      {
        value: ExpenseReasonValue.OWNER_RESERVATION,
        valueLabel: ExpenseReasonLabel.OWNER_RESERVATION,
      },
      // {
      //   value: ExpenseReasonValue.MEMBERSHIP_FEE_MADEGO,
      //   valueLabel: ExpenseReasonLabel.MEMBERSHIP_FEE_MADEGO,
      // },
      // {
      //   value: ExpenseReasonValue.LAYETTE_PARCEL_MADEGO,
      //   valueLabel: ExpenseReasonLabel.LAYETTE_PARCEL_MADEGO,
      // },
      {
        value: ExpenseReasonValue.BUYING_LAYETTE,
        valueLabel: ExpenseReasonLabel.BUYING_LAYETTE,
      },
      {
        value: ExpenseReasonValue.LAYETTE_DAMAGE,
        valueLabel: ExpenseReasonLabel.LAYETTE_DAMAGE,
      },
      {
        value: ExpenseReasonValue.PRO_PHOTO_REVIEW,
        valueLabel: ExpenseReasonLabel.PRO_PHOTO_REVIEW,
      },
    ].sort((firstOption,
      nextOption) => compareList(firstOption.valueLabel, nextOption.valueLabel));
  }

  if (roles.includes('Host') === false) {
    options = [
      ...options,
      {
        value: ExpenseReasonValue.ONBOARDING_ADMINISTRATIVE,
        valueLabel: ExpenseReasonLabel.ONBOARDING_ADMINISTRATIVE,
      },
    ];

    options = options.sort((firstOption: SelectOption, nextOption: SelectOption) => {
      if (firstOption?.valueLabel && nextOption?.valueLabel) {
        return compareList(`${firstOption.valueLabel}`, `${nextOption.valueLabel}`);
      }
      return 0;
    });
  }

  return (
    <>
      <Backdrop
        onClick={() => handleOpenModalExpenses(false)}
      >
        <Container onClick={(e) => e.stopPropagation()}>
          <Content>
            <HeaderContainer>
              <div>
                <img src={newExpense} alt="newExpenseIcon" />
                {userInformation?.main_role === 'Host' ? (
                  <h1>{`${isEditingModal ? `Editar  ${NAME_PAGE}` : `Novo  ${NAME_PAGE}`}`}</h1>
                ) : (
                  <h1>{`${isEditingModal ? `Editar  ${NAME_PAGE}` : `Nova  ${NAME_PAGE}`}`}</h1>
                )}

              </div>
              <CloseButton onClick={() => handleOpenModalExpenses(false)}>
                <img src={iconClose} alt="close" />
              </CloseButton>
            </HeaderContainer>
            <FormContainer onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
              <FormContent>
                <LeftSideContainer>
                  <div className="row">
                    {!isEditingModal ? (
                      <>
                        <DropdownAutocomplete
                          dataCy="select-expense-properties-list"
                          label="Imóvel: (Pesquise pelo código do imóvel)"
                          id="property_id"
                          required
                          options={handleGetProperty()}
                          formik={formik}
                        />
                        {showErrorPropertyNotFound && (
                          <p data-cy="msg-supplier-rating-required" className="messageError">
                            <ErrorIcon />
                            Avalie o fornecedor
                          </p>
                        )}
                      </>
                    ) : (
                      <FieldCodeProperty>
                        <h1 id="property_id" data-cy="input-property-selected">
                          {expensesList?.results?.[indexExpenseSelected]?.property?.code
                            ? `Imóvel - ${expensesList?.results?.[indexExpenseSelected]?.property?.code}`
                            : 'Não pertence a um imóvel'}
                        </h1>
                      </FieldCodeProperty>
                    )}
                  </div>

                  <ContainerSupplier>
                    <FormGridRow grid>
                      <Grid item sm={6} xs={12}>
                        {!isEditingModal ? (
                          <TextField
                            dataCy="input-supplier"
                            className="styledTextFieldLabel"
                            id="supplier"
                            required
                            label="Fornecedor"
                            placeholder="Nome do fornecedor"
                            formik={formik}
                          />
                        ) : (
                          <TextField
                            dataCy="input-supplier"
                            className="styledTextFieldLabel"
                            id="supplier"
                            required
                            label="Fornecedor"
                            placeholder="Nome do fornecedor"
                            value={formik.values.supplier}
                          />
                        )}
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <StarRating
                          label="Avaliação do fornecedor"
                          required
                        />
                        {showErrorRatingNotFound && (
                          <p data-cy="msg-supplier-rating-required" className="messageError">
                            <ErrorIcon />
                            Avalie o fornecedor
                          </p>
                        )}
                      </Grid>
                    </FormGridRow>
                  </ContainerSupplier>
                  {!isEditingModal ? (
                    <>
                      <TextField
                        labelClassName='labelClass'
                        required
                        label="Telefone do fornecedor"
                        id="supplier_phonenumber"
                        mask="phone"
                        placeholder="+55 (48) 99999-9999"
                      />
                      {showErrorSupplierPhoneNumberNotFound && (
                        <p data-cy="msg-supplier-phone-number-required" className="messageError">
                          <ErrorIcon />
                          Digite o numero do fornecedor.
                        </p>
                      )}
                      {showErrorMinSupplierPhoneNumber && (
                        <p data-cy="msg-supplier-min-phone-number-required" className="messageError">
                          <ErrorIcon />
                          Telefone inválido. Ex. +55 (48) 99999-9999
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <TextField
                        labelClassName='labelClass'
                        required
                        label="Telefone do fornecedor"
                        id="supplier_phonenumber"
                        value={formik.values.supplier_phonenumber}
                        mask="phone"
                        placeholder={formik.values.supplier_phonenumber || '+00 (00) 00000-0000'}
                        formik={formik}
                      />

                      {showErrorSupplierPhoneNumberNotFound && (
                        <p data-cy="msg-supplier-phone-number-required" className="messageError">
                          <ErrorIcon />
                          Digite o numero do fornecedor.
                        </p>
                      )}
                      {showErrorMinSupplierPhoneNumber && (
                        <p data-cy="msg-supplier-min-phone-number-required" className="messageError">
                          <ErrorIcon />
                          Telefone inválido. Ex. +55(48)99999-9999
                        </p>
                      )}
                    </>
                  )}

                  <FormGridRow grid>
                    <Grid item sm={6} xs={12}>
                      <ValueContainer>
                        {!isEditingModal ? (
                          <TextField
                            dataCy="input-expense-value"
                            labelClassName='labelClass'
                            required
                            label="Valor"
                            id="value"
                            mask="money"
                            placeholder="R$ 0,00"
                          />
                        ) : (
                          <TextField
                            dataCy="input-expense-value"
                            labelClassName='labelClass'
                            required
                            label="Valor"
                            id="value"
                            mask="money"
                            placeholder="R$ 0,00"
                            value={numberToCurrency(Number(formik.values.value))}
                            formik={formik}
                          />
                        )}
                      </ValueContainer>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <SimpleSelect
                        dataCy="input-expense-reason"
                        labelClassName='labelClass'
                        id="reason"
                        placeholder="Selecione"
                        required
                        label="Motivo"
                        formik={formik}
                        options={options}
                      />
                      {showErrorReason && (
                        <p data-cy="msg-reason-required" className="messageError">
                          <ErrorIcon />
                          {userInformation?.main_role === 'Host' ? 'Selecione o motivo do reembolso' : 'Selecione o motivo da despesa'}
                        </p>
                      )}
                    </Grid>
                  </FormGridRow>

                  <FormGridRow grid>
                    <Grid item sm={6} xs={12}>
                      <DatePicker
                        dataCy="datepicker-expense"
                        formik={formik}
                        label="Data de execução"
                        required
                        id="expense_date"
                        minDate={new Date('1850-01-02')}
                        hasInitialDates
                        disableFuture
                        disableInput
                      />
                    </Grid>
                    {isSeazoneUser && (
                      <Grid item sm={6} xs={12}>
                        <SimpleSelect
                          dataCy="input-paid_by"
                          labelClassName='labelClass'
                          id="paid_by"
                          placeholder="Selecione"
                          required
                          disabled={!isSeazoneUser}
                          label="Quem paga"
                          formik={formik}
                          options={[
                            { value: 'Host', valueLabel: 'Anfitrião' },
                            { value: 'Owner', valueLabel: 'Proprietário' },
                            { value: 'Seazone', valueLabel: 'Seazone' },
                          ]}
                        />
                        {showErrorPaidBy && (
                          <p data-cy="msg-reason-required" className="messageError">
                            <ErrorIcon />
                            {userInformation?.main_role === 'Host' ? 'Selecione o pagador do reembolso' : 'Selecione o pagador da despesa'}
                          </p>
                        )}
                    </Grid>
                    )}

                  </FormGridRow>

                  {isSeazoneUser && (
                    <FormGridRow grid>
                      <>
                        <Grid item sm={6} xs={12}>
                          <SimpleSelect
                            dataCy="input-received_by"
                            labelClassName='labelClass'
                            id="received_by"
                            placeholder="Selecione"
                            required
                            label="Quem recebe"
                            formik={formik}
                            options={[
                              { value: 'Host', valueLabel: 'Anfitrião' },
                              { value: 'Owner', valueLabel: 'Proprietário' },
                              { value: 'Seazone', valueLabel: 'Seazone' },
                            ]}
                          />
                          {showErrorReceivedBy && (
                            <p data-cy="msg-reason-required" className="messageError">
                              <ErrorIcon />
                              {'Selecione quem receberá o reembolso'}
                            </p>
                          )}
                        </Grid>

                        {['Host', 'Owner'].includes(formik.values.received_by) && (
                          <Grid item sm={6} xs={12}>
                            <div className="autocomplete-host">
                              <DropdownAutocomplete
                                dataCy="select-host-list"
                                labelClassName='labelClass'
                                label={`Nome do ${translatedReceivedBy[formik.values.received_by]}`}
                                id="received_by_user"
                                required
                                options={handleGetReceivedBy[formik.values.received_by]}
                                formik={formik}
                                loading={loading}
                                defaultSelected={defaultReceivedByUser}
                                placeholder={defaultReceivedByUser.optionText}
                              />
                            </div>
                            {showErrorReceivedByUser && (
                              <p data-cy="msg-reason-required" className="messageError">
                                <ErrorIcon />
                                {`Informe o ${translatedReceivedBy[formik.values.received_by]} responsável`}
                              </p>
                            )}
                          </Grid>
                        )}
                      </>
                    </FormGridRow>
                  )}
                </LeftSideContainer>

                <RightSideContainer>
                  <PhotoContainer>
                    <UploadButton
                      isEditing={isEditingModal}
                      maintenanceFiles={maintenanceFiles}
                      setMaintenanceFiles={setMaintenanceFiles}
                      statementFiles={statementFiles}
                      setStatementFiles={setStatementFiles}
                      allInformationExpenseFiles={allInformationExpenseFiles}
                      setAllInformationExpenseFiles={setAllInformationExpenseFiles}
                      uploadStatementFile={uploadStatementFile}
                      setUploadStatementFile={setUploadStatementFile}
                      uploadMaintenanceFile={uploadMaintenanceFile}
                      setUploadMaintenanceFile={setUploadMaintenanceFile}
                      setSendingFileArray={setSendingFileArray}
                    />
                  </PhotoContainer>
                  {showErrorFilesRequired && (
                    <p data-cy="msg-files-required" className="messageError">
                      <ErrorIcon />
                      Upload obrigatório de arquivos do recibo
                    </p>
                  )}
                  <NoteContainer>
                    <div className="row-description">
                      <TextField
                        dataCy="textarea-expense-description"
                        label={userInformation?.main_role === 'Host' ? 'Descrição do reembolso' : 'Descrição da despesa'}
                        id="description"
                        type="textarea"
                        placeholder={userInformation?.main_role === 'Host' ? 'Descrição do reembolso' : 'Descrição da despesa'}
                        value={isEditingModal ? formik.values.description : undefined}
                        required
                      />
                      {showErrorDescrptionVoid && (
                        <p data-cy="msg-supplier-rating-required" className="messageError">
                          <ErrorIcon />
                          {userInformation?.main_role === 'Host' ? 'Descreva o reembolso' : 'Descreva a despesa'}
                        </p>
                      )}
                    </div>
                  </NoteContainer>

                  <RadiosButtonContainer>
                    <RadioButton
                      dataCy="radio-button-owner-approval"
                      id="owner_approval_status"
                      required
                      labelPlacement="end"
                      formik={formik}
                      formLabel="Foi pedido a autorização do proprietário ?"
                      options={[
                        { value: 'approved', label: 'Sim' },
                        { value: 'notApproved', label: 'Não' },
                      ]}
                    />
                  </RadiosButtonContainer>
                  {showErrorOwnerApprovalNotFound && (
                    <p data-cy="msg-reason-required" className="messageError">
                      <ErrorIcon />
                      {'Selecione se foi realizado o pedido de autorização'}
                    </p>
                  )}

                  {isEditingModal && expense?.expense_status === 'Pending' && (
                    <PendingReasonContainer>
                      <h1 data-cy="txt-pending-reason">
                        Motivo da Pendência:
                      </h1>
                      <p>
                        {expense?.pending_reason || ''}
                      </p>
                    </PendingReasonContainer>
                  )}

                </RightSideContainer>
              </FormContent>

              {!isEditingModal && (
                <DuplicateAlert
                  checked={isNotExpenseDuplicate}
                  setChecked={() => setIsNotExpenseDuplicate((state: boolean) => !state)}
                />
              )}

              <ButtonsContainer>
                <FormButton dataCy="btn-cancel" variant="outlined" onClick={() => handleOpenModalExpenses(false)}>
                  Cancelar
                </FormButton>
                <FormButton
                  type="submit"
                  dataCy="btn-save"
                  disable={!isEditingModal && !isNotExpenseDuplicate}
                >
                  Salvar
                </FormButton>
              </ButtonsContainer>
            </FormContainer>
          </Content>
        </Container>

      </Backdrop>
    </>
  );
};

export default NewExpensesModal;
