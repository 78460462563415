import styled from 'styled-components';

import {
  Background, Color, Status, StatusProps,
} from './status';

export const StatusContainer = styled.div<{
  status: StatusProps;
  selectedStatus: StatusProps;
}>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  user-select: none;
  h1{
    border: ${({ status, selectedStatus }) => (status === selectedStatus ? '1px solid #00000060' : 'none')};
    border: ${({ selectedStatus }) => selectedStatus === 'Default' && '1px solid transparent'};

    background: ${({ status }) => status === Status.Active && Background.Ativo};
    color: ${({ status }) => status === Status.Active && Color.Ativo};

    background: ${({ status }) => status === Status.Inactive && Background.Inativo};
    color: ${({ status }) => status === Status.Inactive && Color.Inativo};

    background: ${({ status }) => status === Status.Closed && Background.Fechado};
    color: ${({ status }) => status === Status.Closed && Color.Fechado};

    background: ${({ status }) => status === Status.Onboarding && Background.Onboarding};
    color: ${({ status }) => status === Status.Onboarding && Color.Onboarding};

    display: flex;
    font-size: 1.1rem;
    padding: 10px 20px;
    align-items: center;
    border-radius: 100px;
    transform: scale(0.9);
    transition: 0.4s;
    justify-content: center;
  }

  button{
    cursor: pointer;
    background: none;
    border: none;
  }

`;
