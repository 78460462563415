import styled from 'styled-components';

export const RadioGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const RadioLabel = styled.label`
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  font-weight: 800;
`;

export const RadioInput = styled.div<{ checked: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ checked }) => (checked ? '#0D4BD0' : '#D9DCDF')} ;
`;

export const ContainerLabel = styled.div`
  display: flex;
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;
