import styled, { css } from 'styled-components';
import { CheckCircle, Error } from '@mui/icons-material';

const dragActive = css`
  border-color: ${({ theme }) => theme.palette.green.main.hex()};
`;

const dragReject = css`
  border-color: ${({ theme }) => theme.palette.red._500.hex()};
`;

type IDropContainer = {
  isDragActive?: boolean;
  isDragReject?: boolean;
  disableDragAndDrop?: boolean;
  dropzoneWidth?: string;
  dropzoneHeight?: string;
};

export const DropContainer = styled.div<IDropContainer>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ dropzoneWidth }) => (dropzoneWidth)};
  height: ${({ dropzoneHeight }) => (dropzoneHeight)};

  cursor: ${({ disableDragAndDrop }) => (disableDragAndDrop ? 'default' : 'pointer')};

  border-radius: 4px;
  border-radius: 10px;
  border: 2px dashed ${(props) => (props.isDragActive ? dragActive : '#ddd')};
  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;

const messageColors = {
  default: '#999',
  error: '#CF4F44',
  success: '#41B592',
};

interface ITypeMessageColor {
  type?: 'default' | 'error' | 'success';
}

export const UploadMessage = styled.p<ITypeMessageColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${(props) => messageColors[props.type || 'default']};
  padding: 15px 0;
  font-weight: bold;
`;

export const ContainerFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;

  span {
    padding-top: 0.5rem;
    font-size: 0.7rem;
    color: ${({ theme }) => theme.palette.grey._450.hex()};
  }
`;

type IPreviewImage = {
  previewImageWidth?: string;
  previewImageHeight?: string;
};

export const PreviewImage = styled.img<IPreviewImage>`
  object-fit: cover;
  width: ${({ previewImageWidth }) => (previewImageWidth)};
  height: ${({ previewImageHeight }) => (previewImageHeight)};
  border-radius: 10px;
  background-size: cover;
  background-position: 50% 50%;
  margin: 10px;
`;

export const ContainerProgressbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
`;

export const MdCheckCircle = styled(CheckCircle)`
  color: ${({ theme }) => theme.palette.green.main.hex()};
`;

export const MdError = styled(Error)`
  color: ${({ theme }) => theme.palette.red._500.hex()};
`;
