import { Button } from './styles';

interface ICloseButton {
  onClose: Function;
}

const CloseButton = ({ onClose }: ICloseButton) => (
  <Button onClick={() => onClose()}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2807 14.2194C15.3504 14.2891 15.4056 14.3718 15.4433 14.4628C15.4811 14.5539 15.5005 14.6515 15.5005 14.75C15.5005 14.8486 15.4811 14.9461 15.4433 15.0372C15.4056 15.1282 15.3504 15.2109 15.2807 15.2806C15.211 15.3503 15.1283 15.4056 15.0372 15.4433C14.9462 15.481 14.8486 15.5004 14.7501 15.5004C14.6515 15.5004 14.5539 15.481 14.4629 15.4433C14.3718 15.4056 14.2891 15.3503 14.2194 15.2806L8.00005 9.06032L1.78068 15.2806C1.63995 15.4214 1.44907 15.5004 1.25005 15.5004C1.05103 15.5004 0.860156 15.4214 0.719426 15.2806C0.578695 15.1399 0.499634 14.949 0.499634 14.75C0.499634 14.551 0.578695 14.3601 0.719426 14.2194L6.93974 8L0.719426 1.78063C0.578695 1.6399 0.499634 1.44903 0.499634 1.25001C0.499634 1.05098 0.578695 0.860111 0.719426 0.71938C0.860156 0.57865 1.05103 0.499588 1.25005 0.499588C1.44907 0.499588 1.63995 0.57865 1.78068 0.71938L8.00005 6.93969L14.2194 0.71938C14.3602 0.57865 14.551 0.499588 14.7501 0.499588C14.9491 0.499588 15.1399 0.57865 15.2807 0.71938C15.4214 0.860111 15.5005 1.05098 15.5005 1.25001C15.5005 1.44903 15.4214 1.6399 15.2807 1.78063L9.06036 8L15.2807 14.2194Z" fill="#001840" />
    </svg>
  </Button>
);

export default CloseButton;
