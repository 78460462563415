import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Section } from '../style';
import { patchCompReservations } from '../../../../services/CompReservations/request';

interface ICheckLink {
  callback: Function;
  id: number | undefined;
  setEnableSave: Function;
  is_pre_checkin_link_sent: boolean;
}

const CheckLink = ({
  id,
  callback,
  setEnableSave,
  is_pre_checkin_link_sent,
}: ICheckLink) => {
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(is_pre_checkin_link_sent || false);

  const hanldleClickSentLink = async () => {
    const value = !check;
    const actualDate = new Date();

    if (id) {
      try {
        setLoading(true);
        if (value === false) {
          await patchCompReservations({
            is_pre_checkin_link_sent: value,
          },
          id);
        } else {
          await patchCompReservations({
            is_pre_checkin_link_sent: value,
            link_sent_at: actualDate,
          },
          id);
        }

        setCheck(value);
        setEnableSave(true);

        callback(value);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (is_pre_checkin_link_sent) {
      setCheck(is_pre_checkin_link_sent);
    }
  }, [is_pre_checkin_link_sent]);

  return (
    <Section
      style={{
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {loading ? (
        <CircularProgress size={15} />
      ) : (
        <Checkbox checked={check} onClick={hanldleClickSentLink} />
      )}
      <p>Link de pré checkin-enviado</p>
    </Section>
  );
};

export default CheckLink;
