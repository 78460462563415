import React from 'react';
import { Container } from './styles';
import person from '../../../../../../assets/icons/generals/personRed.svg';
import QuantityField from '../../../../../QuantityField';
import { UseFormik } from '../../../../../../utils/Formik/types';

interface Props {
  formik: UseFormik<any>;
  text: string;
  idName: string;
}

const GuestQuantity = ({ formik, text, idName }: Props) => (
  <Container>
    <div className="left">
      <img src={person} alt="icone pessoa" />
      <p>{text}</p>
    </div>
    <div className="rigth">
      <QuantityField formik={formik} id={idName} border={false} justify={false} />
    </div>
  </Container>
);

export default GuestQuantity;
