import React, { useEffect, createContext } from 'react';

import Card from './Cards/Cards';
import Header from './Header';

import {
  Content,
  Dividers,
  Container,
} from './styles';

import { getPaymentStatus } from '../../services/PaymentVoucher/request';

import { usePaymentVoucher } from '../../hooks/PaymentVoucherHook/usePaymentVoucher';

import { PaymentProps } from '../../context/PaymentVoucher/types/paymentStatus.types';
import { useToast } from '../../context/ToastContext';

export interface CompReservationProps {
  addPaymentVoucher: Function;
}

export const CompReservationContext = createContext<CompReservationProps>({
  addPaymentVoucher: (params: PaymentProps) => params,
});

const CompReservation = () => {
  const {
    isFinishOpen,
    isIncompleteOpen,
    paymentDataStatus,
    handleChangeLoading,
    handleChangeIsFinishOpen,
    handleChangeIsIncompleteOpen,
    handleChangePaymentDataStatus,
  } = usePaymentVoucher();

  const toast = useToast();

  const hadleGetPaymentDataStatus = async (search?: string) => {
    try {
      handleChangeLoading(true);
      const response = search
        ? await getPaymentStatus(search)
        : await getPaymentStatus();

      handleChangePaymentDataStatus(response);
      handleChangeLoading(false);

      if (search && (response.pendings.length === 0 && response.completes.length === 0)) {
        toast.alert('Não há nenhum resultado para esta pesquisa');
      } else if (search && response.completes.length === 0) {
        toast.alert('Não há resultados para comprovantes de pagamentos completos');
      } else if (search && response.pendings.length === 0) {
        toast.alert('Não há resultados para comprovantes de pagamentos pendentes');
      }

      return response;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    hadleGetPaymentDataStatus();
  }, []);

  return (
    <CompReservationContext.Provider
      value={{
        addPaymentVoucher: hadleGetPaymentDataStatus,
      }}
    >
      <Container>
        <Content>
          <Header />
          <Dividers>
            <Card
              title="Pendentes"
              cardDataType="pending"
              isOpenCard={isIncompleteOpen}
              data={paymentDataStatus.pendings}
              setIsOpenCard={handleChangeIsIncompleteOpen}
            />
            <Card
              color="green"
              title="Completos"
              cardDataType="completes"
              isOpenCard={isFinishOpen}
              data={paymentDataStatus.completes}
              setIsOpenCard={handleChangeIsFinishOpen}
            />
          </Dividers>
        </Content>
      </Container>
    </CompReservationContext.Provider>
  );
};
export default CompReservation;
