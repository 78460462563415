import { ProperPayHostDashboard } from '../../../../services/HostDashboard/types';

import { useTag } from '../../../../hooks/HostDashboardHook/HostGridFinances/useTag';

import Tag from '../Grid/Tag';
import Item from '../Grid/Item';
import { useGridFinances } from '../../../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';

const CountingGridContent = () => {
  const { financesData } = useGridFinances();
  const {
    isExpandedDailyExecuted,
    handleChangeIsExpandedDailyExecuted,
    isExpandedCleaningExecuted,
    handleChangeIsExpandedCleaningExecuted,
  } = useTag();
  return (
    <>

      <Tag
        label="Diárias executadas"
        variant="0"
        isExpanded={isExpandedDailyExecuted}
        setIsExpanded={handleChangeIsExpandedDailyExecuted}
        data={financesData.number_of_dailys}
        totalValue={financesData.number_of_dailys.total}
        isCountGrid
      />

      <Item
        title="Diárias do mês atual"
        isVisible={isExpandedDailyExecuted}
        data={financesData.number_of_dailys_to_receive}
        totalValue={financesData.number_of_dailys_to_receive.total}
        isCountGrid
      />

      <Item
        title="Diárias do mês seguinte"
        isVisible={isExpandedDailyExecuted}
        data={financesData.number_of_dailys_next_month}
        totalValue={financesData.number_of_dailys_next_month.total}
        isCountGrid
      />

      <Tag
        label="Limpezas executadas"
        variant="5"
        isExpanded={isExpandedCleaningExecuted}
        setIsExpanded={handleChangeIsExpandedCleaningExecuted}
        data={financesData.number_of_cleanings}
        totalValue={financesData.number_of_cleanings.total}
        isCountGrid
      />
      <Item
        title="Limpezas do mês atual"
        isVisible={isExpandedCleaningExecuted}
        data={financesData.number_of_cleanings_to_receive}
        totalValue={financesData.number_of_cleanings_to_receive.total}
        isCountGrid
      />
      <Item
        title="Limpezas do mês seguinte"
        isVisible={isExpandedCleaningExecuted}
        data={financesData.number_of_cleanings_next_month}
        totalValue={financesData.number_of_cleanings_next_month.total}
        isCountGrid
      />
    </>
  );
};

export default CountingGridContent;
