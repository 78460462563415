import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey._410.hex()};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;

  h1{
    font-size: 1.1rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 300;
  }
`;

export const BashIconContainer = styled.div`
  margin: 0 20px;
  cursor: pointer;
`;
