import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { useGuestDamageStep } from '../../../../hooks/GuestDamage/useGuestDamageStep';
import FormButton from '../../../FormButton/FormButton';
import { BillingHistoryNegotiationsHeader } from './BillingHistoryNegotiationsHeader';
import { DamageResume } from './DamageResume';
import { ValidationAndHistory } from './ValidationAndHistory';
import {
  BillingHistoryNegotiationsCardContainer,
  ButtonContainer,
  GridContainer,
  ReceiptForm,
  Title,
  ValidationForm,
} from './styles';
import {
  GetGuestDamage,
  GuestDamageNegotiationPaymentStatus,
  GuestDamageNegotiationStage,
  GuestDamageNegotiationStatus,
  RefundHolder,
} from '../../../../services/GuestDamage/types';
import RadioButton from '../../components/RadioButton';
import TextField from '../../../TextField';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import {
  getGuestDamageNegotiationById,
  patchGuestDamage,
  patchGuestDamageNegotiation,
  postDamageNegotiationGuestPayment,
  postGuestDamageNegotiationHistory,
} from '../../../../services/GuestDamage/request';
import { useToast } from '../../../../context/ToastContext';
import { FileProps } from '../../../../context/FileContext/types';
import { useGuestDamageNegotiation } from '../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { currencyToNumber, numberToCurrency } from '../../../../utils/Formatter';
import DatePicker from '../../../DatePicker';

const validateOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export const InsertBillingHistoryNegotiationsCard = () => {
  const {
    selectedGuestDamage,
    setReservationGuestDamages,
    setSelectedGuestDamage,
  } = useGuestDamage();
  const {
    selectedGuestDamageNegotiation,
    setSelectedGuestDamageNegotiation,
  } = useGuestDamageNegotiation();
  const [isToInformOwner, setIsToInformOwner] = useState(selectedGuestDamageNegotiation
    .is_to_inform_owner || false);
  const [guestPaymentReceipts, setGuestPaymentReceipts] = useState<FileProps[]>([]);
  const [validateSelectedOption, setValidateSelectedOption] = useState(selectedGuestDamage
    .are_evidences_and_quotation_validated || false);
  const { handleViewBillingHistoryNegotiationsStep } = useGuestDamageStep();
  const toast = useToast();

  const initialValidateValues = {
    refundHolder: selectedGuestDamage?.refund_holder || undefined as RefundHolder | undefined,
    missingInformation: selectedGuestDamage.missing_information as string | undefined,
  };
  async function handleFailedValidadeEvidences() {
    const patchResponse = await patchGuestDamageNegotiation(selectedGuestDamageNegotiation.id!,
      {
        status: 'Atendimento verificou pendências na validação de informações',
      });
    if (patchResponse.id) {
      const response = await getGuestDamageNegotiationById(patchResponse.id);
      setSelectedGuestDamageNegotiation(response);
      toast.success('Dados enviados com sucesso!');
    }
  }
  const validateFormik = useFormik({
    initialValues: initialValidateValues,
    onSubmit: async (values) => {
      try {
        const response = await patchGuestDamage(selectedGuestDamage.id as number, {
          are_evidences_and_quotation_validated: validateSelectedOption,
          missing_information: values.missingInformation,
        });
        setReservationGuestDamages((oldValues) => {
          const newGuestDamages = oldValues.map((reservationGuestDamage) => {
            if (reservationGuestDamage.id === response.id) {
              return {
                ...reservationGuestDamage,
                are_evidences_and_quotation_validated: response
                  .are_evidences_and_quotation_validated,
                missing_information: response.missing_information,
              };
            }
            return reservationGuestDamage;
          });
          const validateNextStep = newGuestDamages
            .reduce((validator, currentGuestDamage) => {
              if (currentGuestDamage.are_evidences_and_quotation_validated === false) {
                return false;
              } if (currentGuestDamage.are_evidences_and_quotation_validated === undefined) {
                return undefined;
              }
              return validator && currentGuestDamage.are_evidences_and_quotation_validated;
            }, true as boolean | undefined);

          if (validateNextStep === false) {
            handleFailedValidadeEvidences();
          }
          if (validateNextStep === true) {
            patchGuestDamageNegotiation(selectedGuestDamageNegotiation.id as number, {
              stage: 'Histórico e Tratativa de Cobrança',
            });
            setSelectedGuestDamageNegotiation((oldSelectedValues) => ({
              ...oldSelectedValues,
              stage: 'Histórico e Tratativa de Cobrança',
            }));
          }
          return newGuestDamages;
        });
        setSelectedGuestDamage({} as GetGuestDamage);
        toast.success('Validação feita com sucesso!');
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Erro ao validar este dano!');
        }
      }
    },
  });

  useEffect(() => {
    validateFormik.setValues({
      ...initialValidateValues,
    });
    setValidateSelectedOption(selectedGuestDamage.are_evidences_and_quotation_validated || false);
  }, [selectedGuestDamage]);

  const initialTransactionsValue = {
    aircoverCode: selectedGuestDamageNegotiation?.aircover_code || '',
    amountReceived: selectedGuestDamageNegotiation?.amount_received ? numberToCurrency(selectedGuestDamageNegotiation?.amount_received) : '',
    guestPaymentStatus: selectedGuestDamageNegotiation?.guest_payment_status || '' as GuestDamageNegotiationPaymentStatus,
    history: '',
    contactDate: new Date(),
  };

  function handleFinalStatus(guestPaymentStatus: GuestDamageNegotiationPaymentStatus)
    : GuestDamageNegotiationStatus | undefined {
    switch (guestPaymentStatus) {
      case 'Disputa': {
        return 'Cobrança em disputa com o hóspede';
      }
      case 'Solicitado': {
        return 'Cobrança solicitada pelo atendimento';
      }
      case 'Pendente': {
        return 'Cobrança pendente, ainda em tratativa';
      }
      case 'Finalizado sem sucesso': {
        return 'Atendimento realizou todas as tratativas, porém não obteve sucesso na cobrança';
      }
      case 'Pago': {
        return 'Pagamento recebido, encaminhado pelo financeiro';
      }
      case 'À pagar': {
        return 'Tratativa finalizada. Aguardando pagamento';
      }
      default: {
        return undefined;
      }
    }
  }

  function handleStage(guestPaymentStatus: GuestDamageNegotiationPaymentStatus)
    : GuestDamageNegotiationStage | undefined {
    switch (guestPaymentStatus) {
      case 'Pago': {
        return 'Reembolso do dano';
      }
      default: {
        if (selectedGuestDamageNegotiation.stage === 'Histórico e Tratativa de Cobrança') {
          return undefined;
        }
        return 'Histórico e Tratativa de Cobrança';
      }
    }
  }

  const transactionHistoryFormik = useFormik({
    initialValues: initialTransactionsValue,
    onSubmit: async (values) => {
      try {
        if (guestPaymentReceipts.length > 0) {
          guestPaymentReceipts.forEach(async (receipt) => {
            await postDamageNegotiationGuestPayment(selectedGuestDamageNegotiation.id!,
              receipt.uid!);
          });
        }
        if (values.history !== initialTransactionsValue.history) {
          await postGuestDamageNegotiationHistory(selectedGuestDamageNegotiation.id!, {
            contact_date: moment(values.contactDate).format('YYYY-MM-DD'),
            history: values.history,
          });
        }

        const patchResponse = await patchGuestDamageNegotiation(selectedGuestDamageNegotiation.id!,
          {
            aircover_code: values?.aircoverCode === initialTransactionsValue.aircoverCode
              ? undefined : values.aircoverCode,
            amount_received: values.amountReceived === initialTransactionsValue.amountReceived
              ? undefined : currencyToNumber(values.amountReceived),
            guest_payment_status: values.guestPaymentStatus === initialTransactionsValue
              .guestPaymentStatus ? undefined : values.guestPaymentStatus,
            is_to_inform_owner: isToInformOwner,
            status: handleFinalStatus(values.guestPaymentStatus),
            stage: handleStage(values.guestPaymentStatus),
          });
        if (patchResponse.id) {
          const response = await getGuestDamageNegotiationById(patchResponse.id);
          setSelectedGuestDamageNegotiation(response);
          toast.success('Dados enviados com sucesso!');
        } else {
          setSelectedGuestDamageNegotiation((oldValues) => ({
            ...oldValues,
            ...patchResponse,
            guest_payment_status: values.guestPaymentStatus!,
          }));
        }
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Falha ao enviar os dados!');
        }
      }
    },
  });

  return (
    <BillingHistoryNegotiationsCardContainer>
      <BillingHistoryNegotiationsHeader />
      <ValidationForm onSubmit={validateFormik.handleSubmit}>
        <DamageResume />
        <AnimatePresence custom="wait">
          {selectedGuestDamage.id !== undefined && (
          <>
            <motion.div
              initial={{
                opacity: 0,
                y: -30,
              }}
              transition={{ ease: 'easeIn', duration: 0.4 }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              exit={{
                opacity: 0,
                y: -30,
              }}
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <Title>Validação de evidências e orçamento</Title>
              <RadioButton
                css={{ marginTop: '1rem' }}
                label="Evidências e orçamentos são válidos?"
                labelClassName="label"
                options={validateOptions}
                selectedOption={validateSelectedOption}
                setSelectedOption={setValidateSelectedOption}
              />
              <TextField
                css={{ marginTop: '1rem' }}
                id="missingInformation"
                label="Quais informações estão faltando"
                labelClassName="label"
                placeholder="Digite aqui..."
                formik={validateFormik}
                value={validateFormik.values.missingInformation}
              />
            </motion.div>
            <ButtonContainer>
              <FormButton>
                Finalizar validação
              </FormButton>
            </ButtonContainer>
          </>
          )}
        </AnimatePresence>
      </ValidationForm>
      <ReceiptForm onSubmit={transactionHistoryFormik.handleSubmit}>
        <ValidationAndHistory
          formik={transactionHistoryFormik}
          isToInformOwner={isToInformOwner}
          setIsToInformOwner={setIsToInformOwner}
          guestPaymentReceipts={guestPaymentReceipts}
          setGuestPaymentReceipts={setGuestPaymentReceipts}
        />
        <GridContainer>
          <h2>Histórico da data de contato</h2>
          <h2>História</h2>
          {selectedGuestDamageNegotiation.histories
          && selectedGuestDamageNegotiation?.histories?.length > 0
          && selectedGuestDamageNegotiation?.histories.map((history) => (
            <>
              <p>{moment(history.contact_date).format('DD/MM/YYYY')}</p>
              <p>{history.history}</p>
            </>
          ))}
          <DatePicker
            id="contactDate"
            labelClassName="label"
            formik={transactionHistoryFormik}
          />
          <TextField
            id="history"
            labelClassName="label"
            placeholder="Digite aqui..."
            formik={transactionHistoryFormik}
            value={transactionHistoryFormik.values.history}
          />
        </GridContainer>
        <ButtonContainer>
          <FormButton
            onClick={handleViewBillingHistoryNegotiationsStep}
          >
            Finalizar Inserção
          </FormButton>
        </ButtonContainer>
      </ReceiptForm>
    </BillingHistoryNegotiationsCardContainer>
  );
};
