import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;

  gap: 30px;

  width: 100%;

  padding-bottom: 15px;
  overflow-x: scroll;

  user-select: none;

  ::-webkit-scrollbar {
    height: 3px;
  }
`;

export const RadioContainer = styled.div`
  position: absolute;
  right: -3px;
  top: -5px;
`;

export const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow: hidden;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
  }
`;

export const Card = styled.div<{
  active: boolean;
}>`
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background: white;
  min-width: 50px;
  width: 210px;
  height: 170px;
  overflow: hidden;

  cursor: pointer;

  position: relative;

  transition: 0.2s;

  display: flex;
  flex-direction: column;
  gap: 15px;

  ${({ active }) => active
    && `
    border: 2px solid #0D4BD0;
  `}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  span {
    font-size: 12px;
    color: #333;
  }

  p {
    font-size: 14px;
    color: #394760;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    font-style: normal;
    font-family: "Inter";
  }
`;
