/* eslint-disable @typescript-eslint/no-unused-vars */
import request from '../request';
import {
  ExpenseProps,
  ExpenseParams,
  ExpenseReportProps,
  ExpensePropsPaginationResults,
  PostExpenseFiles,
  SimpleProperty,
  SimpleHost,
} from './types';

interface ExpenseApprove {
  ids: Array<number>,
  type: string,
  status: string
}

export const getExpenses = async (page: number = 1,
  filters?: ExpenseParams) => {
  if (filters) {
    let params = {};
    if (filters.property_id) {
      params = { ...params, property_id: filters.property_id };
    }
    if (filters.host_id) {
      params = { ...params, host_id: filters.host_id };
    }
    if (filters.expense_status) {
      params = { ...params, expense_status: filters.expense_status };
    }
    if (filters.refund_date_start) {
      params = {
        ...params,
        refund_date_start: filters.refund_date_start,
        refund_date_end: filters.refund_date_end,
      };
    }
    if (filters.register_date_start) {
      params = {
        ...params,
        register_date_start: filters.register_date_start,
        register_date_end: filters.register_date_end,
      };
    }
    if (filters.approval_date_start) {
      params = {
        ...params,
        approval_date_start: filters.approval_date_start,
        approval_date_end: filters.approval_date_end,
      };
    }

    const { data } = await request.get(`/expenses/?page=${page}`, { params });
    return {
      ...data,
      hasMoreExpenses: data?.next !== null,
      nextPage: data?.next?.split('page=')?.[1] || 1,
    } as ExpensePropsPaginationResults;
  }

  const { data } = await request.get(`/expenses/?page=${page}`);
  return {
    ...data,
    hasMoreExpenses: data?.next !== null,
    nextPage: data?.next?.split('page=')?.[1] || 1,
  } as ExpensePropsPaginationResults;
};

export const postExpenses = async (params: ExpenseProps) => {
  const { data } = await request.post('/expenses/', params);
  return data;
};

export const patchExpenses = async (id: number, params: ExpenseProps) => {
  const { data } = await request.patch(`/expenses/${id}/`, params);
  return data;
};

export const getExpensesReport = async (params: ExpenseReportProps) => {
  await request
    .get('/expense_report/', { params, responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',
        `despesas_${params.start_date}_${params.end_date}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};

export const postExpenseFile = async (postExpenseFileData: PostExpenseFiles) => {
  const { data } = await request.post('/expenses_files/', postExpenseFileData);
  return data;
};

export const deleteExpenseFile = async (id: number) => {
  await request.delete(`/expenses_files/${id}`);
};

export const getProperties = async (status = '') => {
  const { data } = await request.get(`/property/simple/details?status=${status}`);
  return data as SimpleProperty[];
};

export const getHosts = async () => {
  const { data } = await request.get('/account/host_simple/');
  return data as SimpleHost[];
};
export const postExpensesApprove = async (expenseApprove: ExpenseApprove) => {
  const { data } = await request.post('/expenses/actions/', expenseApprove);
  return data;
};
