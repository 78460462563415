import styled from 'styled-components';

export const Container = styled.div`
  width: 30%;
  height: 80vh;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 12px;
`;

export const ReservationsContent = styled.div`
  width: 100%;
  height: 90%;
  overflow-y: scroll;
`;

export const Header = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._370.hex()};
  margin-bottom: 6px;
  padding-bottom: 10px;

  .filters {
    display: flex;
    align-items: center;

    label {
      padding-bottom: 0;
    }
  }

`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
`;

export const SearchButton = styled.button`
  background-color: transparent;
  border: none;
  margin-right: 5px;

  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  margin: 6px 0;

  border-bottom: 0.5px solid ${({ theme }) => theme.palette.grey._370.hex()};
`;
