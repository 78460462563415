import styled from 'styled-components';
import { baseStyle } from '../config/base';

export const Container = styled.div`
  ${baseStyle}
  border-radius: 10px 10px 0px 0px;
  background: var(--dark-blue-900, #042675);
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const Item = styled.div`
  color: var(--blue-50, #e7e9fa);

  /* fonte/corpo/paragrafo/2 */
  font-size: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
