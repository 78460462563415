import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { createContext } from 'use-context-selector';
import { GetGuestDamage, HouseHoldLinen } from '../../../services/GuestDamage/types';
import { FileProps } from '../../FileContext/types';

interface PhotoProps {
  src?: string;
  alt?: string;
}

type GuestDamageContextProps = {
  guestDamagesToInsert: GetGuestDamage[];
  setGuestDamagesToInsert: Dispatch<SetStateAction<GetGuestDamage[]>>;
  reservationGuestDamages: GetGuestDamage[];
  setReservationGuestDamages: Dispatch<SetStateAction<GetGuestDamage[]>>;
  selectedGuestDamage: GetGuestDamage;
  setSelectedGuestDamage: Dispatch<SetStateAction<GetGuestDamage>>;
  quotationFile: FileProps;
  setQuotationFile: Dispatch<SetStateAction<FileProps>>;
  evidences: FileProps[];
  setEvidences: Dispatch<SetStateAction<FileProps[]>>;
  selectedPhoto: PhotoProps;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  houseHoldLinenItens: HouseHoldLinen[];
  setHouseHoldLinenItens: Dispatch<SetStateAction<HouseHoldLinen[]>>
  handleOpenPhotoModal: (src: string, alt: string) => void;
  handleClosePhotoModal: () => void;
  handleOpenFile: (fileUrl: string, fileName: string) => void;
  handleOpenLink: (url:string) => void
};

export const GuestDamageContext = createContext<GuestDamageContextProps>({
} as GuestDamageContextProps);

export const GuestDamageProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [guestDamagesToInsert,
    setGuestDamagesToInsert] = useState<GetGuestDamage[]>([]);
  const [reservationGuestDamages,
    setReservationGuestDamages] = useState<GetGuestDamage[]>([]);
  const [selectedGuestDamage,
    setSelectedGuestDamage] = useState<GetGuestDamage>({} as GetGuestDamage);
  const [isLoading,
    setIsLoading] = useState(false);
  const [quotationFile, setQuotationFile] = useState<FileProps>({} as FileProps);
  const [evidences, setEvidences] = useState<FileProps[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<PhotoProps>({} as PhotoProps);
  const [houseHoldLinenItens, setHouseHoldLinenItens] = useState<HouseHoldLinen[]>([]);

  function handleOpenPhotoModal(src: string, alt: string) {
    setSelectedPhoto({
      src,
      alt,
    });
  }

  function handleClosePhotoModal() {
    setSelectedPhoto({
      src: undefined,
      alt: undefined,
    });
  }

  const handleOpenFile = (fileUrl: string, fileName: string) => {
    const url = `${fileUrl}`;
    const name = `${fileName}`;
    const isPdfFormat = name.endsWith('.pdf');

    if (isPdfFormat) {
      window.open(url, '_blank');
    } else {
      handleOpenPhotoModal(url, name);
    }
  };

  function handleOpenLink(url: string) {
    window.open(url, '_blank');
  }

  const value = useMemo(() => ({
    guestDamagesToInsert,
    setGuestDamagesToInsert,
    isLoading,
    setIsLoading,
    quotationFile,
    setQuotationFile,
    evidences,
    setEvidences,
    selectedPhoto,
    handleOpenPhotoModal,
    handleClosePhotoModal,
    handleOpenFile,
    reservationGuestDamages,
    setReservationGuestDamages,
    selectedGuestDamage,
    setSelectedGuestDamage,
    houseHoldLinenItens,
    setHouseHoldLinenItens,
    handleOpenLink,
  }), [
    guestDamagesToInsert,
    setGuestDamagesToInsert,
    isLoading,
    setIsLoading,
    quotationFile,
    setQuotationFile,
    evidences,
    setEvidences,
    selectedPhoto,
    handleOpenPhotoModal,
    handleClosePhotoModal,
    handleOpenFile,
    reservationGuestDamages,
    setReservationGuestDamages,
    selectedGuestDamage,
    setSelectedGuestDamage,
    houseHoldLinenItens,
    setHouseHoldLinenItens,
    handleOpenLink,
  ]);

  return (
    <GuestDamageContext.Provider
      value={value}
    >
      {children}
    </GuestDamageContext.Provider>
  );
};
