import { useNavigate } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';

import {
  Container,
  BackButtonStyled,
  Content,
} from './styles';

interface BackButtonsProps {
  linkTo?: string;
  mobile?: boolean;
  dataCy?: string;
}

const BackButton = ({
  linkTo = '',
  mobile = false,
  dataCy = '',
}: BackButtonsProps) => {
  const navigate = useNavigate();

  const handleLink = () => {
    navigate(linkTo);
  };

  return (
    <Container>
      <BackButtonStyled onClick={handleLink} isMobile={mobile}>
        <Content data-cy={dataCy}>
          {!mobile ? (
            <>
              <ArrowBack htmlColor="#e35b4c" fontSize="small" />
              <p>Voltar</p>
            </>
          ) : <ArrowBack htmlColor="#e35b4c" fontSize="small" />}
        </Content>
      </BackButtonStyled>
    </Container>
  );
};

BackButton.defaultProps = {
  linkTo: '/proprietario',
  mobile: false,
  dataCy: '',
};

export default BackButton;
