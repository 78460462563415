import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white._300.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    border-radius: 10px 10px 0 0;
    padding: 2rem;
    gap: 1rem;

    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    }
    @media (max-width: 650px) {
      padding: 0.5rem;
    }
  }

  .radio {
    span {
      font-size: 1.5rem;
      font-family: ${({ theme }) => theme.fonts.familys._3};
    }
    @media (max-width: 330px) {
      padding: 0 5rem;
    }
  }
  .divider {
    width: 95%;
    padding: 0 2rem;
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }
  .formContainer {
    overflow-y: scroll;
    display: grid;
    width: 97%;
    max-height: calc(100vh - 35vh);
    padding: 1rem 0;

    ::-webkit-scrollbar {
      width: 0.3rem;
      height: 8px;
      background: ${({ theme }) => theme.palette.grey._320.hex()};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      border: 0.1px solid ${({ theme }) => theme.palette.grey._320.hex()};
    }
  }
`;

export const Title = styled.h1`
  font-size: 1.875rem;
  line-height: 2.125rem;
  font-family: ${({ theme }) => theme.fonts.familys._3};
  @media (max-width: 650px) {
    padding-top: 1rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.palette.grey._750.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._3};
  padding-bottom: 2rem;
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.grey._750.hex()};
`;

export const Form = styled.form`
  width: 95%;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 0 0 10px 10px;
`;

export const ButtonsContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 100%;
  background: rgba(0, 24, 64, 0.05);
  div {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 30%;
    @media (max-width: 1100px) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;
