import styled from 'styled-components';
import { CloseOutlined } from '@mui/icons-material';

export const Container = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  justify-items: center;
  
  @media(max-width: 768px) {
    margin: 1.5rem 0;
  }
`;

export const HeaderContent = styled.section`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: flex-start;
  align-items: center;
  margin: 1.5rem 0;
  
  @media(max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._305.hex()};
  padding-bottom: 1.125rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.strong`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const TitleInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  :last-child {
    margin-left: 3rem;
  }

  span {
    margin-left: 0.5rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1rem;
  }

  h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    margin-left: 0.5rem;
  }

  @media(max-width: 768px) {
    margin: 0.5rem 0;
    
    :last-child {
      margin-left: 0;
    }

    span {
      margin-left: 0.5rem;
    }

    h1 {
      margin-left: 0.5rem;
    }
  }
`;

export const CloseOutlinedIcon = styled(CloseOutlined)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.black.main.hex()};

  position: absolute;
  right: 0;
  top: 0;

  margin-right: 15px;
  margin-top: 10px;

  background: transparent;
  border: none;

  font-size: 10px;

  :hover {
    cursor: pointer;
    filter: brightness(0.9);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
`;

export const ButtonDownload = styled.button`
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};

  width: 182px;
  height: 40px;

  border-radius: 10px;
  border: none;

  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  transition: filter(0.2s);

  :hover {
    filter: brightness(0.8);
    cursor: pointer;
  }

  @media(max-width: 768px) {
    max-width: 85px;
    margin-right: 1rem;
  }
`;

export const Divider = styled.div<{ isDashed?: boolean }>`
  border-top: 0.5px ${({ isDashed }) => (isDashed ? 'dashed' : 'solid')} ${({ theme }) => theme.palette.grey._305.hex()};
  margin: 0 1rem;
  width: 100%;
`;

export const DateHourContainer = styled.div<{ showExtractDate: boolean }>`
  visibility: ${({ showExtractDate }) => (showExtractDate ? 'visible' : 'hidden')};
  padding: 1rem 0;
  border-top: 1px dashed ${({ theme }) => theme.palette.grey._350.hex()};
`;

export const DateHourContent = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  font-family: 'Inter', sans-serif;

  color: ${({ theme }) => theme.palette.grey._900.hex()};
  
  strong{
    font-size: 0.875rem;
  }
`;
