import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const padding = css`
  padding: 30px;
`;

export const Title = styled(motion.h1)`
  ${padding}

  color: var(--neuter-50, #f9f9f9);

  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

export const Container = styled.div`
  background: var(--darkk-blue-900, #001840);
  width: 100%;
  height: 100%;
`;
