import { useContextSelector } from 'use-context-selector';
import { NfListContext } from '../../context/NfList/NfListContext';

export function useNfList() {
  const listItems = useContextSelector(NfListContext,
    (state) => state.listItems);

  const handleListItems = useContextSelector(NfListContext,
    (state) => state.setListItems);

  const filteredItems = useContextSelector(NfListContext,
    (state) => state.filteredItems);

  const handleFilteredItems = useContextSelector(NfListContext,
    (state) => state.setFilteredItems);

  const loading = useContextSelector(NfListContext,
    (state) => state.loading);

  const handleLoading = useContextSelector(NfListContext,
    (state) => state.setLoading);

  const handleLoadingFiles = useContextSelector(NfListContext,
    (state) => state.setLoadingFiles);

  const loadingFiles = useContextSelector(NfListContext,
    (state) => state.loadingFiles);

  const orderGridBy = useContextSelector(NfListContext,
    (state) => state.orderGridBy);
  const setOrderGridBy = useContextSelector(NfListContext,
    (state) => state.setOrderGridBy);

  const abort = useContextSelector(NfListContext,
    (state) => state.abort);
  const setAbort = useContextSelector(NfListContext,
    (state) => state.setAbort);

  return {
    loadingFiles,
    handleLoadingFiles,
    listItems,
    handleListItems,
    filteredItems,
    handleFilteredItems,
    loading,
    handleLoading,
    orderGridBy,
    setOrderGridBy,
    abort,
    setAbort,
  };
}
