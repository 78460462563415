import React, { Fragment } from 'react';
import { AnimatePresence } from 'framer-motion';
import { IScrollButtons } from '../../types';
import { LeftButton, RightButton } from '../../style';
import { Icon } from './Icon';

const ScrollButtons = ({ children, cardsRef }: IScrollButtons) => {
  const [isRightScrollable, setIsRightScrollable] = React.useState({
    left: false,
    right: false,
  });

  const handleScrollToRight = () => {
    cardsRef.current.scrollTo({
      behavior: 'smooth',
      left: cardsRef.current.scrollLeft + 1000,
    });
  };

  const handleScrollToLeft = () => {
    cardsRef.current.scrollTo({
      behavior: 'smooth',
      left: cardsRef.current.scrollLeft - 1000,
    });
  };

  React.useEffect(() => {
    const cards = cardsRef.current;

    const handleScroll = () => {
      const { scrollWidth, scrollLeft, clientWidth } = cards;
      setIsRightScrollable({
        left: !(scrollWidth - scrollLeft > clientWidth),
        right: scrollWidth - scrollLeft > clientWidth,
      });
    };

    cards.addEventListener('scroll', handleScroll);

    return () => {
      cards.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AnimatePresence>
      {isRightScrollable.left && (
        <LeftButton
          initial={{ opacity: 0, x: -100 }}
          exit={{ opacity: 0, x: -100 }}
          animate={{ opacity: 0.7, x: 0 }}
          // change animate key mode
          transition={{
            ease: 'easeIn',
          }}
          whileTap={{ scale: 1.2 }}
          key="left-button-show-more"
          onClick={handleScrollToLeft}
          type="button"
        >
          <Icon />
        </LeftButton>
      )}

      <Fragment key="children-fragment">{children}</Fragment>

      {isRightScrollable.right && (
        <RightButton
          type="button"
          key="right-button-show-more"
          whileTap={{ scale: 1.2 }}
          onClick={handleScrollToRight}
          animate={{
            opacity: 0.7,
            x: 0,
            transition: {
              ease: 'easeIn',
            },
          }}
          initial={{ opacity: 0, x: -100 }}
          exit={{ opacity: 0, x: 100 }}
        >
          <Icon />
        </RightButton>
      )}
    </AnimatePresence>
  );
};

export default ScrollButtons;
