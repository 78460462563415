import {
  DamageResumeContainer,
  Title,
  TotalContainer,
  TotalText,
} from './styles';

import { DamageResumeTable } from './DamageResumeTable';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { numberToCurrency } from '../../../../../utils/Formatter';

export const DamageResume = () => {
  const { reservationGuestDamages } = useGuestDamage();
  const calculateTotalValue = reservationGuestDamages.reduce((total, item) => {
    const itemPrice = item.item_price || 0;
    const itemQuantity = item.item_quantity || 1;
    return total + itemPrice * itemQuantity;
  }, 0);

  return (
    <>
      {reservationGuestDamages.length > 0 && (
        <DamageResumeContainer>
          <Title>Resumo de danos a serem lançados</Title>
          <DamageResumeTable />
          <TotalContainer>
            <TotalText>
              <span>Total:</span>
              {numberToCurrency(calculateTotalValue)}
            </TotalText>
          </TotalContainer>
        </DamageResumeContainer>
      )}
    </>
  );
};
