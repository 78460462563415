import { UserInformation } from '../../services/User/types';

export const checkKeyMainRoute = (user: UserInformation) => {
  if (user.main_role === '') return 'default';
  if (user.main_role !== 'Seazone') return user.main_role;
  if (user.seazone?.departmanet === 'Onboarding') return 'SeazoneOnboarding';
  if (user.seazone?.departmanet === 'Administrative') return 'SeazoneAdministrative';
  return 'default';
};

export const getMainRoute: Record<string, string> = {
  Admin: '/buscadepropriedades',
  SeazoneOnboarding: '/despesas',
  SeazoneAdministrative: '/fechamentoimovel',
  Attendant: '/buscadepropriedades',
  Owner: '/proprietario',
  Host: '/controle',
  Partner: '/parceiros/painel',
  Guest: '/login',
  default: '/login',
};
