import styled, { keyframes } from 'styled-components';

const OpenSlide = keyframes`
  from {
    right: -457px;
    opacity: 0.2;
  }
  to {
    opacity: 1;
    right: 0;

  }
`;

export const AreaClose = styled.div`
  position: fixed;
  z-index: 40;
  left: 0;
  display: flex;
  justify-content: flex-end;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
`;

export const ButtonCloseModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 43px;
  margin-left: -60px;
  top: 0;
  margin-top: 10px;
  position: fixed;
  z-index: 42;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  p {
    font-size: 10px;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  svg {
    fill: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  :hover  {
    background-color: ${({ theme }) => theme.palette.grey._260.hex()};
    color: ${({ theme }) => theme.palette.orange._600.hex()};
  }
`;

export const Container = styled.div`
  animation-name: ${OpenSlide};
  animation-duration: 0.3s;
  animation-fill-mode: both;
  width: 457px;
  height: 100vh;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.white._300.hex()};
  border-radius: 10px 0 0 10px;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 41;
  overflow-x: scroll;
  padding-bottom: 100px;

  h2 {
    font-size: 14px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    margin-top: 15px;
    margin-bottom: 10px;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;

export const Cart = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  svg {
    fill: ${({ theme }) => theme.palette.green.main.hex()};
    margin-right: 10px;
  }
`;

export const TotalValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 700;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.green.main.hex()};
  }
`;

export const SmallPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey._420.hex()};

  p {
    font-weight: 400;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;

export const ContentButtons = styled.div`
  animation-name: ${OpenSlide};
  animation-duration: 0.3s;
  width: 457px;
  height: 74px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 43;
  bottom: 0;
  right: 0;
`;

export const ButtonCancel = styled.div`
  width: 45%;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.grey._410.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey._440.hex()};
    -webkit-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    -moz-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
  }
`;

export const ButtonConfirm = styled.div`
  width: 45%;
  height: 40px;
  background: linear-gradient(
    360deg,
    ${({ theme }) => theme.palette.orange.main.hex()} 0%,
    ${({ theme }) => theme.palette.orange._600.hex()} 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  font-weight: 700;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.orange._700.hex()};
    -webkit-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    -moz-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
  }
`;

export const ButtonCopy = styled.div`
  width: 100%;
  height: 43px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  font-weight: 500;
  margin-top: 21px;
  cursor: pointer;

  img {
    margin-left: 17px;
  }

  &:hover {
    -webkit-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    -moz-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
  }
`;
