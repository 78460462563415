import styled from 'styled-components';

export type Position = {
  top: number;
  left: number;
  side: string;
  width: number;
  height: number;
  vertical: string;
};

export const animationTime = '0.25s';
export const secondaryTime = '0.5s';
export const screenAdaptive = '2000px';
export const commumScreen = '1800px';
export const notebook = '1500px';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.096);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div<{
  checkList: boolean;
}>`
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  z-index: 999;
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 3px 2px 20px rgba(0, 0, 0, 0.178);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  max-width: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 100px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  @media (max-width: 900px) {
    position: absolute;
    width: 100%;
    border-radius: 10px;
    max-width: 500px;
    max-height: 80%;
    margin-left: 0;
  }
`;

export const CloseButton = styled.p`
  z-index: 10;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const FirstInfoContainer = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CheckColor = styled.div<{
  checkin?: boolean;
  clearning?: boolean;
  checkout?: boolean;
}>`
  width: 30px;
  height: 30px;
  background: ${({ checkin, theme }) => checkin && theme.palette.blue._200.hex()};
  background: ${({ checkout, theme }) => checkout && theme.palette.pink.main.hex()};
  background: ${({ clearning, theme }) => clearning && theme.palette.green._200.hex()};
  border-radius: 12%;
  @media (max-width: ${screenAdaptive}) {
    width: 25px;
    height: 25px;
  }
`;

export const CardName = styled.h2`
  font-size: 1.5rem;
  color: #001840;
  transform-origin: center, center;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.familys._6};
`;

export const ReserveDate = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: #394760;
  font-size: 1.25rem;

  @media (max-width: ${screenAdaptive}) {
    font-size: 1rem;
  }
`;

export const ReserveTime = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 1.25rem;
  align-self: flex-end;
  color: #394760;

  @media (max-width: ${screenAdaptive}) {
    font-size: 1rem;
  }
`;

export const RowPins = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 1rem;
  @media (max-width: 345px) {
    justify-content: flex-start;
  }
`;

export const ReservationInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  
  > div {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  p {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: #394760;

    @media (max-width: ${screenAdaptive}) {
      font-size: 1rem;
    }
  }

  .dot {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #394760;
    display: flex;
  }
`;

export const CardsPinsContainer = styled.div`
  right: 0;
  display: flex;
  align-content: center;
  padding-top: -50px;
  margin-left: -59px;
  @media (max-width: ${screenAdaptive}) {
    transform: scale(0.7);
    transform-origin: right;
  }

  @media (max-width: 900px) {
    width: 100%;
    margin: 0;
    transform-origin: left;
  }

  @media (max-width: 345px) {
    width: 90%;
    transform-origin: left;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 1rem;
`;

export const PropertyContainer = styled.div``;

export const PropertyTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 600;
  color: #001840;
  font-size: 1.25rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const PropertyAddress = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
  color: #394760;
  font-size: 1rem;
`;

export const StyledImage = styled.img`
  height: 20px;
  width: 20px;
  color: #394760;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
  } 
`;

export const PropertyState = styled.h3`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
`;

export const InfoBottomContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  &.blocking {
    flex-direction: row-reverse !important;
  }

  @media (max-width: 700px) {
    margin-top: 0rem;
    flex-direction: column;
  }
`;

export const LeftContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const CopyContainer = styled.h2`
  align-items: center;
  display: flex;
  img {
    margin-left: 1rem;
    width: 100%;
    cursor: pointer;
  }
  &.button-copy {
    img {
      margin-left: 1rem;
      width: 60%;
      cursor: pointer;
    }
  }
  .copyIcon {
    width: 100%;
    min-width: 20px;
    transition: 0.2s;
    &:hover {
      transform: translateY(-5px);
    }
  }
  @media (min-width: ${screenAdaptive}) {
    .whatsappIcon {
      width: 100%;
      min-width: 30px;
      transition: 0.2s;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .copyIcon {
      width: 100%;
      min-width: 25px;
      transition: 0.2s;
      &:hover {
        transform: translateY(-5px);
      }
    }
  }
`;

export const ClientNameTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: #001840;
  font-weight: 600;
  font-size: 1.25rem;
`;

export const ClientPhone = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  cursor: pointer;

  > p {
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    color: #394760;
    font-size: 1.25rem;
  }

  > div {
    display: flex;
    gap: 0.3rem;
  }

  button {
    border: none;
    background: transparent;
  }

  &:hover {
    > p {
      filter: contrast(300%);
    }
  }
`;

export const BottomContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const PencilButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.palette.grey._700.hex()};
  }

  .btn-saveCheckinPhone {
    color: ${({ theme }) => theme.palette.grey._700.hex()};
    margin-left: 1rem;
    margin-top: 8px;

    &:hover {
      transition: color 400ms;
      color: ${({ theme }) => theme.palette.grey._900.hex()};
    }
  }
`;

export const TextFieldContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 40%;
  align-items: center;
  max-width: 450px;
  font-size: 1rem !important;

  > div {
    min-width: 190px;
  }

  .btn-saveCheckinPhone, .btn-saveCheckOutPhone {
    margin-top: -0.4rem;
    text-align: center;
  };
`;

export const BlockingAlertContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey._445.hex()};
  margin-bottom: 1rem;
  width: 100%;
  text-align: start;
  text-justify: justify;

  p {
    font-size: 1.2rem;
  }
  p, strong {
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }

  strong {
    font-size: 1.4rem;
  }
`;

export const PreCheckinContainer = styled.div`
  display: flex;
  border-radius: 10px;
  padding: '1rem';
`;

export const ModalButton = styled.button<{ isFull?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #042675;
  color: #042675;
  font-weight: bold;
  gap: 1rem;
  cursor: pointer;
  min-height: 40px;
  grid-column-start: ${({ isFull }) => isFull && 1};
  grid-column-end: ${({ isFull }) => isFull && 3};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (max-width: 500px) {
    grid-column-start: ${({ isFull }) => isFull && 1};
    grid-column-end: ${({ isFull }) => isFull && 2};
  }
  
  p {
    text-align: start;
    width: 100%;
  }
`;

export const StyledIcon = styled.img`
  width: 20px;
  height: 20px;
  fill: #042675;
  justify-self: start;
`;
