import {
  FC,
  useState,
  useMemo,
  useEffect,
  ReactElement,
  useCallback,
} from 'react';

import { createContext } from 'use-context-selector';
import { Coordinates } from './types';
import { getUserIPAddress } from '../../services/AddressIP/request';

export interface IGeolocation {
  IPAddress: string,
  coordinates: Coordinates,
  setCoordinates: Function,
}

export const GeolocationContext = createContext<IGeolocation>({
  IPAddress: '',
  coordinates: {
    latitude: '',
    longitude: '',
  },
  setCoordinates: () => {},
});

export const GeolocationProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [IPAddress, setIPAddress] = useState<string>('');

  const getIPAddress = async () => {
    const ip = await getUserIPAddress();
    setIPAddress(ip);
  };

  const [coordinates, setCoordinates] = useState<Coordinates>({
    latitude: '',
    longitude: '',
  });

  const handleSetCoordinates = useCallback((coords: Coordinates) => {
    setCoordinates({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  }, []);

  useEffect(() => {
    getIPAddress();
  }, []);

  const value = useMemo(() => ({
    coordinates,
    setCoordinates: handleSetCoordinates,
    IPAddress,
  }), [
    coordinates,
    setCoordinates,
    IPAddress,
  ]);

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  );
};
