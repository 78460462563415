import React, { useEffect, useState } from 'react';
import { UseFormik } from '../../utils/Formik/types';

import HelperText from '../HelperText';
import { Type } from '../HelperText/types';

import {
  StyledFormControl, StyledTextField, StyledTextArea, ErrorIcon, Wrapper, Arrow,
} from './styles';

interface ISelectProps {
  label?: string;
  id: string;
  dataCy?: string;
  value?: any;
  pattern?: string;
  error?: boolean;
  helperText?: string; // To use with error
  placeholder?: string;
  required?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  formik?: UseFormik<any>;
  onClick?: () => void;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement
  | HTMLTextAreaElement>, formatedValue: string) => void) | undefined;
}

const SelectModal: React.FC<ISelectProps> = ({
  label, value, id, dataCy = '', error, helperText, placeholder, required, type = 'text', formik, onClick, onChange,
}) => {
  const [stateValue, setStateValue] = useState<any>();

  useEffect(() => {
    if (formik) {
      formik.setFieldValue(id, (formik?.values[id] || '').toString());
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const customValue: string = e.currentTarget.value;

    if (onChange) {
      onChange(e, customValue);
      return;
    }

    if (formik) {
      formik.setFieldValue(id, customValue);
      formik.setFieldTouched(
        id, true, false,
      );
      return;
    }

    setStateValue(customValue);
  };

  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const getValue = () => {
    if (onChange) {
      return value;
    }

    return formik ? formik?.values[id] : stateValue;
  };

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  return (
    <>
      <StyledFormControl>
        <label htmlFor={label}>{label}</label>
        {type !== 'textarea' ? (
          <Wrapper
            id="select-wrapper"
            onClick={onClick}
          >
            <StyledTextField
              name={id}
              id={id}
              data-cy={dataCy}
              variant="outlined"
              type={type}
              error={error}
              placeholder={placeholder}
              required={required}
              value={value}
              onChange={handleChange}
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <Arrow />
          </Wrapper>
        ) : (
          <StyledTextArea
            name={id}
            id={id}
            placeholder={placeholder}
            required={required}
            value={getValue()}
            onChange={handleChange}
          />
        )}
        {!!helperTextValue() && <HelperText type={helperTextType}>{helperTextValue()}</HelperText>}
      </StyledFormControl>
    </>
  );
};

export default SelectModal;
