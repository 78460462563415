import { fieldLabels } from '../Translator';

export const checkActive = (phases: Array<any>, label: string) =>
  phases.find((item) => item.name.includes(label))?.isActive;

export const handleValidation = async (formik: any, toast: any) => {
  const errors = await formik.validateForm();

  const invalidFields = Object.keys(formik.values).filter(
    (key) => `${formik.values[key]}`.toLowerCase().trim() === 'invalid'
  );

  if (Object.keys(errors).length > 0) {
    formik.setTouched(
      Object.keys(errors).reduce((acc: any, key: any) => {
        acc[key] = true;
        return acc;
      }, {}),
    );

    const missingFields = Object.keys(errors)
      .concat(invalidFields)
      .map((key) => fieldLabels[key] || key)
      .join(', ');

    toast.error(
      `Os seguintes campos não foram preenchidos: ${missingFields}. Preencha estes campos para finalizar.`,
    );
  } else {
    toast.success('Todos os campos estão corretos!');
  }
};
