import styled, { keyframes } from 'styled-components';

interface TabProps {
  active: boolean;
}

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

export const LateralModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 512px;
  background-color: white;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2);
  animation: ${slideIn} 0.3s ease-out;
  z-index: 1001;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(231, 234, 241);
  width: 100%;
  height: 40px;
  padding-inline: 16px;
`;

export const CloseButton = styled.span`
  font-size: 24px;
  color: #333;

  &:hover {
    color: #E05845;
  }

  cursor: pointer;
`;

export const ModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* overflow-y: auto; */
  padding-bottom: 32px;
`;

export const TabsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const TabList = styled.div`
  background-color: #F3F6F9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid rgb(231, 234, 241);
`;

export const Tab = styled.button<TabProps>`
  width: 60px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({ active }) => (active ? '#fff' : '#F3F6F9')};
  border-right: ${({ active }) => (active ? '3px solid rgb(25, 118, 210)' : '3px solid transparent')};
  cursor: pointer;

  &:hover {
    background-color: ${({ active }) => (active ? '' : '#fff')}; 
  }
`;

export const TabPanel = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  width: 100%;
  height: 100%;
`;
