import styled from 'styled-components';

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  z-index: 99999;

  > *:not(.popup-card) {
    padding: 20px;
  }

  padding-bottom: 50px;
`;

export const DesktopContainer = styled.div`
  top: 50%;
  left: 50%;
  width: 70vw;
  max-height: 520px;
  height: 100%;
  z-index: 99999;
  position: fixed;
  transform: translate(-50%, -50%);
`;

export const DesktopContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  background: white;
  height: 100%;
  width: 100%;
  border-radius: 30px;

  padding: 40px;
`;

export const CloseContainer = styled.div<{
  isMobile: boolean,
}>`
  position: absolute;
  right: ${({ isMobile }) => (isMobile ? '10px' : '35px')};
  top: ${({ isMobile }) => (isMobile ? '10px' : '25px')};
  cursor: pointer;
  z-index: 9999999;
`;
