import React from 'react';

import { useLocation } from 'react-router-dom';
import { Container } from './style';
import { usePartners } from '../../../context/Partners/Partner/PartnerContext';

import Bar from './Bar';
import Body from './Body';

const onlyShowOn = [
  '/parceiros/indicar/formulario/locacao',
];

const ConversionProbability = () => {
  const { conversion } = usePartners();
  const { pathname } = useLocation();

  if (!onlyShowOn.includes(pathname)) {
    return null;
  }

  return (
    <Container
      isMaxBar={conversion.isMaxBar}
    >
      <Bar progress={conversion.progress} />
      <Body />
    </Container>
  );
};

export default ConversionProbability;
