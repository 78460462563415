import { v4 as uuidv4 } from 'uuid';
import { Variants } from 'framer-motion';
import { IGuestFront } from '../../../services/PreCheckin/types';

export const guestInitialValues: IGuestFront = {
  id: uuidv4(),
  reservation: 0,
  name: '',
  document: '',
  email: '',
  phone_number: '',
  front_document_photo: null,
  back_document_photo: null,
  is_principal: false,
  isPut: {
    is: false,
    updated: {
      bedOrganization: false,
      documentPhoto: {
        front: false,
        back: false,
      },
    },
  },
  expanded: {
    is: true,
  },
};

export const bodyVariants = {
  open: {
    height: 'auto',
    marginTop: '0px',
    clipPath: 'inset(0% 0% 0% 0% round 10px)',
    transition: {
      bounce: 0,
      duration: 0.7,
      type: 'spring',
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
  closed: {
    height: '0px',
    marginTop: '-45px',
    clipPath: 'inset(10% 50% 90% 50% round 10px)',
    transition: {
      bounce: 0,
      duration: 0.3,
      type: 'spring',
    },
  },
};

export const itemVariants: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      damping: 24,
      type: 'spring',
      stiffness: 300,
    },
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};
