import styled, { css } from 'styled-components';
import { FormControl, TextField, TextareaAutosize } from '@mui/material';
import { ReportProblemOutlined } from '@mui/icons-material';

export const StyledFormControl = styled(FormControl)<{ isLargeText: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing.form};
  width: 100%;
  
  && label {
    font-weight: 400;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    font-size: ${({ isLargeText }) => (isLargeText ? '13px' : '14px')};
    line-height: 17.5px;
    padding-bottom: 8px;
  }
`;

export const ActiveButton = styled.button`
  position: absolute;
  z-index: 12;
  margin-left: -100px;
  right: 0;
  margin-right: 35px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._850.hex()};
  font-size: 12px;
  font-weight: 700;
  border: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    transform: translateX(10px) scale(1.2);
  }
  @media (max-width: 580px) {
    margin-right: 25px;
  }
`;

export const ContainerLabel = styled.div`
  display: flex;
  label {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const StarSymbol = styled.p`
  margin-left: 0.3rem;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const Content = styled.div<{
  generateButton: boolean;
}>`
${({ generateButton }) => (generateButton
    ? css`
        display: grid;
        grid-template-columns: 1.8fr 1.2fr 1fr;
        gap: 16px;
      `
    : css`
        display: flex;
        align-items: center;
      `
  )}
  display: ${({ generateButton }) => (generateButton ? 'grid' : 'flex')};
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledTextField = styled(TextField)<{
  activeButton: boolean;
}>`
  && {
    .MuiOutlinedInput-root {
      height: 40px;
      background: ${({ theme }) => theme.palette.white.main.hex()};
      box-sizing: border-box;
      border-radius: 10px;
      padding-right: ${({ activeButton }) => (activeButton ? '75px' : '0')};
      fieldset {
        border: solid 1px ${({ theme }) => theme.palette.grey._450.hex()};
      }
      &:hover fieldset {
        border: solid 1px ${({ theme }) => theme.palette.green.main.hex()};
      }
      &.Mui-focused fieldset {
        border: solid 1px ${({ theme }) => theme.palette.green.main.hex()};
      }
    }
    .MuiInputBase-root {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .MuiInputBase-input {
      ::placeholder {
        color: ${({ theme }) => theme.palette.grey._760.hex()};
        font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
        opacity: 1;
      }
      :focus {
        ::placeholder {
          color: ${({ theme }) => theme.palette.grey._550.hex()};
        }
      }
    }
    .MuiInputAdornment-root {
      color: ${({ theme }) => theme.palette.grey._760.hex()};
    }
  }
`;

export const StyledTextArea = styled(TextareaAutosize)`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border: none;
    display: block;
    background-color: transparent;
    overflow: hidden;
  }
  && {
    background: ${({ theme }) => theme.palette.white.main.hex()};
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
    padding: 10px 13px 10px 13px;
    resize: none;
    min-height: 120px !important;
    max-height: 120px;
    overflow-y: scroll !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.5px;
    letter-spacing: 0.00938em;

    ::placeholder {
      color: ${({ theme }) => theme.palette.grey._760.hex()};
      font-family: ${({ theme }) => theme.fonts.familys._6}, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px !important;
      line-height: 12px;
      letter-spacing: -0.02em;
    }
    :focus {
      ::placeholder {
        color: ${({ theme }) => theme.palette.grey._760.hex()};
      }
    }
    :focus,
    :active,
    :hover {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.palette.green._700.hex()};
    }
  }
`;

export const ErrorIcon = styled(ReportProblemOutlined)`
  && {
    margin-right: 3px;
    height: 16px;
  }
`;
