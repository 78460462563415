import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { createContext } from 'use-context-selector';

import { GridPropertyProps, ReservationProps, ExpenseProps } from './types';
import { getFinancialClosePropertyUpdated } from './utils';

type ReservationInfosProps = ReservationProps & {
  property: {
    id: number,
    code: string,
  },
  host: {
    id: number,
    name: string,
  },

};

type ModalDailyProps = {
  open: boolean,
  reservation: ReservationInfosProps,
};

type ModalCleaningProps = ModalDailyProps;

interface IFinancialCloseProperty {
  financialDataProperty: GridPropertyProps[],
  setFinancialDataProperty: Function,
  updateFinancialDataProperty: Function,
  updateReservationProperty: Function,
  updateExpenseProperty: Function,
  updateCheckedOfPropertySelected: Function,
  updateAllCheckedsOfPropertySelected: Function,
  updateExpandedOfPropertySelected: Function,
  openModalAddManualFitProperty: boolean,
  setOpenModalAddManualFitProperty: Function,
  openModalEditManualFitProperty: boolean,
  setOpenModalEditManualFitProperty: Function,
  openModalAddManualFitDaily: ModalDailyProps,
  setOpenModalAddManualFitDaily: Function,
  openModalEditManualFitDaily: ModalDailyProps,
  setOpenModalEditManualFitDaily: Function,
  openModalAddManualFitCleaning: ModalCleaningProps,
  setOpenModalAddManualFitCleaning: Function,
  openModalEditManualFitCleaning: ModalCleaningProps,
  setOpenModalEditManualFitCleaning: Function,
}

export const FinancialClosePropertyContext = createContext<IFinancialCloseProperty>({
  financialDataProperty: [],
  setFinancialDataProperty: () => {},
  updateFinancialDataProperty: () => {},
  updateReservationProperty: () => {},
  updateExpenseProperty: () => {},
  updateCheckedOfPropertySelected: () => {},
  updateAllCheckedsOfPropertySelected: () => {},
  updateExpandedOfPropertySelected: () => {},
  openModalAddManualFitProperty: false,
  setOpenModalAddManualFitProperty: () => {},
  openModalEditManualFitProperty: false,
  setOpenModalEditManualFitProperty: () => {},
  openModalAddManualFitDaily: {
    open: false,
    reservation: {} as ReservationInfosProps,
  },
  setOpenModalAddManualFitDaily: () => {},
  openModalEditManualFitDaily: {
    open: false,
    reservation: {} as ReservationInfosProps,
  },
  setOpenModalEditManualFitDaily: () => {},
  openModalAddManualFitCleaning: {
    open: false,
    reservation: {} as ReservationInfosProps,
  },
  setOpenModalAddManualFitCleaning: () => {},
  openModalEditManualFitCleaning: {
    open: false,
    reservation: {} as ReservationInfosProps,
  },
  setOpenModalEditManualFitCleaning: () => {},
});

export const FinancialClosePropertyProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [financialDataProperty, setFinancialDataProperty] = useState<GridPropertyProps[]>([]);
  const [propertiesSelected, setPropertiesSelected] = useState<GridPropertyProps[]>([]);
  const [openModalAddManualFitProperty,
    setOpenModalAddManualFitProperty] = useState<boolean>(false);
  const [openModalEditManualFitProperty,
    setOpenModalEditManualFitProperty] = useState<boolean>(false);
  const [openModalAddManualFitDaily,
    setOpenModalAddManualFitDaily] = useState<ModalDailyProps>({
    open: false,
    reservation: {} as ReservationInfosProps,
  });
  const [openModalEditManualFitDaily,
    setOpenModalEditManualFitDaily] = useState<ModalDailyProps>({
    open: false,
    reservation: {} as ReservationInfosProps,
  });

  const [openModalAddManualFitCleaning,
    setOpenModalAddManualFitCleaning] = useState<ModalCleaningProps>({
    open: false,
    reservation: {} as ReservationInfosProps,
  });
  const [openModalEditManualFitCleaning,
    setOpenModalEditManualFitCleaning] = useState<ModalCleaningProps>({
    open: false,
    reservation: {} as ReservationInfosProps,
  });

  const handleSetFinancialDataProperty = useCallback((data: GridPropertyProps[]) => {
    setFinancialDataProperty([...data]);
  }, []);

  const handleUpdateFinancialDataProperty = useCallback((currentData: GridPropertyProps[],
    dataUpdated: GridPropertyProps[]) => {
    const newData: GridPropertyProps[] = [...currentData];

    dataUpdated.forEach((itemUpdated) => {
      const indexItem = currentData.findIndex((currentItem) => currentItem.id === itemUpdated.id);
      newData[indexItem] = { ...getFinancialClosePropertyUpdated(itemUpdated) };
    });

    setFinancialDataProperty(newData);
  }, []);

  const handleUpdateReservationProperty = useCallback((data: ReservationProps) => {
    setFinancialDataProperty((state) => state.map((property) => ({
      ...property,
      reservations: property.reservations
        .map((reservation: ReservationProps) => (reservation.id === data.id
          ? { ...data } : { ...reservation })),
    })));
  }, []);

  const handleUpdateExpenseProperty = useCallback((data: ExpenseProps) => {
    setFinancialDataProperty((state) => state.map((property) => ({
      ...property,
      expenses: property.expenses
        .map((expense: ExpenseProps) => (expense.id === data.id
          ? { ...data } : { ...expense })),
    })));
  }, []);

  const handleUpdateCheckedOfPropertySelected = useCallback((id: number,
    checkedUpdated?: boolean) => {
    if (checkedUpdated) {
      setFinancialDataProperty((state) => state.map((item) => ({
        ...getFinancialClosePropertyUpdated(item),
        checked: item.id === id ? checkedUpdated : item.checked,
      })));
    } else {
      setFinancialDataProperty((state) => state.map((item) => ({
        ...getFinancialClosePropertyUpdated(item),
        checked: item.id === id ? !item.checked : item.checked,
      })));
    }
  }, []);

  const handleUpdateAllCheckedsOfPropertySelected = useCallback((checkedUpdated: boolean) => {
    setFinancialDataProperty((state) => state.map((item) => ({
      ...getFinancialClosePropertyUpdated(item),
      checked: checkedUpdated,
    })));
  }, []);

  const handleUpdateExpandedOfPropertySelected = useCallback((id: number) => {
    setFinancialDataProperty((state) => state.map((item) => ({
      ...getFinancialClosePropertyUpdated(item),
      expanded: item.id === id ? !item.expanded : item.expanded,
    })));
  }, []);

  const handleChangeOpenModalAddManualFit = useCallback((isOpen: boolean) => {
    setOpenModalAddManualFitProperty(isOpen);
  }, []);

  const handleChangeOpenModalEditManualFit = useCallback((isOpen: boolean) => {
    setOpenModalEditManualFitProperty(isOpen);
  }, []);

  const handleChangeOpenModalAddManualFitDaily = useCallback((data: ModalDailyProps) => {
    setOpenModalAddManualFitDaily(data);
  }, []);

  const handleChangeOpenModalEditManualFitDaily = useCallback((data: ModalDailyProps) => {
    setOpenModalEditManualFitDaily(data);
  }, []);

  const handleChangeOpenModalAddManualFitCleaning = useCallback((data: ModalCleaningProps) => {
    setOpenModalAddManualFitCleaning(data);
  }, []);

  const handleChangeOpenModalEditManualFitCleaning = useCallback((data: ModalCleaningProps) => {
    setOpenModalEditManualFitCleaning(data);
  }, []);

  type CheckProps = Record<number, boolean>;
  useEffect(() => {
    const checkeds: CheckProps = financialDataProperty.reduce((acc, item) => {
      acc[item.id] = item.checked;
      return acc;
    }, {} as CheckProps);

    localStorage.setItem('@saprom-pms-web/financialclose_property_selected', JSON.stringify(checkeds));
  }, [financialDataProperty]);

  useEffect(() => {
    const expanded: CheckProps = financialDataProperty.reduce((acc, item) => {
      acc[item.id] = item.expanded;
      return acc;
    }, {} as CheckProps);

    localStorage.setItem('@saprom-pms-web/financialclose_property_expanded', JSON.stringify(expanded));
  }, [financialDataProperty]);

  const value = useMemo(() => ({
    financialDataProperty,
    setFinancialDataProperty: handleSetFinancialDataProperty,
    updateFinancialDataProperty: handleUpdateFinancialDataProperty,
    updateReservationProperty: handleUpdateReservationProperty,
    updateExpenseProperty: handleUpdateExpenseProperty,
    updateCheckedOfPropertySelected: handleUpdateCheckedOfPropertySelected,
    updateAllCheckedsOfPropertySelected: handleUpdateAllCheckedsOfPropertySelected,
    updateExpandedOfPropertySelected: handleUpdateExpandedOfPropertySelected,
    openModalAddManualFitProperty,
    setOpenModalAddManualFitProperty: handleChangeOpenModalAddManualFit,
    openModalEditManualFitProperty,
    setOpenModalEditManualFitProperty: handleChangeOpenModalEditManualFit,
    openModalAddManualFitDaily,
    setOpenModalAddManualFitDaily: handleChangeOpenModalAddManualFitDaily,
    openModalEditManualFitDaily,
    setOpenModalEditManualFitDaily: handleChangeOpenModalEditManualFitDaily,
    openModalAddManualFitCleaning,
    setOpenModalAddManualFitCleaning: handleChangeOpenModalAddManualFitCleaning,
    openModalEditManualFitCleaning,
    setOpenModalEditManualFitCleaning: handleChangeOpenModalEditManualFitCleaning,
  }), [
    financialDataProperty,
    setFinancialDataProperty,
    propertiesSelected,
    setPropertiesSelected,
    openModalAddManualFitProperty,
    setOpenModalAddManualFitProperty,
    openModalEditManualFitProperty,
    setOpenModalEditManualFitProperty,
    openModalAddManualFitDaily,
    setOpenModalAddManualFitDaily,
    openModalEditManualFitDaily,
    setOpenModalEditManualFitDaily,
    openModalAddManualFitCleaning,
    setOpenModalAddManualFitCleaning,
    openModalEditManualFitCleaning,
    setOpenModalEditManualFitCleaning,
  ]);

  return (
    <FinancialClosePropertyContext.Provider
      value={value}
    >
      {children}
    </FinancialClosePropertyContext.Provider>
  );
};
