import styled from 'styled-components';

export const ValidationAndHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin-top: 2rem;
`;

export const ValidationAndHistoryItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-family: 'Inter';
  color: #394760;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  > p {
    font-size: 0.75;
    font-weight: 400;
  }
`;
export const LinkText = styled.div`
  text-decoration: underline;
  color: #0D4BD0;
  z-index: 2;
  cursor: pointer;
`;
