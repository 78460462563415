/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react';
import { Grid, Divider } from '@mui/material';

import { IValidateList, validateErrorMessage } from '../../utils/validateErrorMessage';

import { UseFormik } from '../../../../../../../utils/Formik/types';
import { useMobile } from '../../../../../../../hooks/useMobile/useMobile';

import TextField from '../../../../../../../components/TextField/TextField';
import FormGridRow from '../../../../../../../components/FormGridRow/FormGridRow';
import Switcher from '../../../../../components/Switcher';

import {
  StateSelector,
} from '../lib';

import {
  Error,
  PropertyForm,
  ErrorWrapper,
  SubmitButton,
  FormContainer,
  FooterContainer,
  PropertyFormTitle,
  Spacing,
  RowContent,
  Box,
} from '../style';

const LINK_ALLOTMENTS_LOCATION = 'https://www.google.com/maps/d/u/0/viewer?ll=-27.671288092857136%2C-48.482269975&z=15&mid=1bYshgFCNZXIyVmglLF2B2FC8rb7XnUI';
const LINK_COORDINATES = 'https://drive.google.com/file/d/18c8Npp8P3hKjX2mU5GsP28XSjAUY4C90/view';
const LINK_GOOGLE_DRIVE_SUPPORT = 'https://support.google.com/drive/answer/2494822?hl=pt-BR&co=GENIE.Platform%3DDesktop';

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 10 },
};

interface ILandIndicationForm {
  formik: UseFormik<any>;
}

const LandIndicationForm = ({ formik }: ILandIndicationForm) => {
  const { isMobile } = useMobile();

  const coordinatesIsRequired = useMemo(() => {
    const splitted = `${formik.values?.address}`?.split(','); // ["Logradouro", "número"]
    return splitted.length < 2;
  }, [formik.values?.address]);

  const documentsIsRequired = useMemo(() => {
    const allotmentWidth = `${formik.values?.allotmentWidth}`.trim();
    const allotmentLenght = `${formik.values?.allotmentLenght}`.trim();

    const docsRequired = (allotmentWidth === '0' && allotmentLenght === '0')
    || (allotmentWidth === '0' && allotmentLenght === '')
    || (allotmentWidth === '' && allotmentLenght === '0');

    return docsRequired;
  }, [formik.values?.allotmentWidth, formik.values?.allotmentLenght]);

  const documentsIsValidate = useMemo(() => {
    const documentLink = `${formik.values?.documentLink}`.trim();
    const photoLink = `${formik.values?.photoLink}`.trim();

    if ((documentsIsRequired && (documentLink === '' || photoLink === ''))) {
      return false;
    }
    return true;
  }, [documentsIsRequired, formik.values?.documentLink, formik.values?.photoLink]);

  const validateForm = () => {
    const noError = Object.keys(formik.errors).length === 0;
    if (noError && documentsIsValidate) {
      return false;
    }
    return true;
  };

  const validateErrorMsgLocal = (obj: IValidateList) => validateErrorMessage({
    checkbox: true,
    formik,
    obj,
  });

  return (
    <>
      <FormContainer
        translate="yes"
        transition={{ duration: 0.5 }}
        variants={item}
      >
        <Divider />
        <PropertyFormTitle>Dados do terreno</PropertyFormTitle>

        <FormGridRow grid>
          <Grid item sm={6} xs={12}>
            <PropertyForm>
              <ErrorWrapper>
                <StateSelector
                  formik={formik}
                  labelState="Localização do Terreno"
                  labelCity="Cidade"
                  required
                  requireSymbolPosition="right"
                  labelStateDescription={(
                    <p>
                      <a href={LINK_ALLOTMENTS_LOCATION} target="blank">{'Clique aqui'}</a>
                      &nbsp;
                      {'e verifique quais as áreas de atuação da Seazone'}
                    </p>
                  )}
                />
                {validateErrorMsgLocal('state/city') && (
                  <Error>{formik.errors.state}</Error>
                )}
              </ErrorWrapper>
            </PropertyForm>
            <Spacing />

            <TextField
              id="neighborhood"
              dataCy="input-neighborhood"
              formik={formik}
              label="Bairro"
              placeholder="Ex: Vila Mariana"
              required
              requireSymbolPosition="right"
              labelDescription={(
                <p>
                  {'Somente o nome do bairro, sem abreviações'}
                </p>
              )}
            />
            <Spacing />

            <TextField
              id="address"
              dataCy="input-address"
              formik={formik}
              label="Endereço"
              placeholder="Ex: Avenida Brasil, 241, Rua Dom Bosco"
              required
              requireSymbolPosition="right"
              labelDescription={(
                <p>
                  {`Inserir o logradouro e número, sem abreviações e separados por vírgula.
                  Se o número não existir, preencher somente o nome do logradouro`}
                </p>
              )}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              id="coordinates"
              dataCy="input-coordinates"
              formik={formik}
              label="Coordenadas geográficas"
              placeholder="Ex: -27.429413, -48.460755"
              required={coordinatesIsRequired}
              requireSymbolPosition="right"
              labelDescription={(
                <p>
                  <a href={LINK_COORDINATES} target="blank">{'Como obter'}</a>
                  &nbsp;
                  {' as coordenadas geográficas do terreno'}
                </p>
              )}
            />
            <Spacing />

            <TextField
              id="allotmentArea"
              dataCy="input-allotmentArea"
              formik={formik}
              label="Área em m²"
              placeholder=" Ex: 10000m²"
              required
              requireSymbolPosition="right"
              labelDescription={(
                <p>
                  {'Preencher a área do terreno em metros quadrados. Inserir somente números'}
                </p>
              )}
            />
            <Spacing />

            <label className="label" htmlFor="allotmentDimensions">Dimensões do terreno (dimensões em metros)</label>
            <p>{'Preencher somente se o terreno for retangular. Caso o terreno possua outro formato, preencha com "0" e envie um croqui na seção "Documentos"'}</p>
            <RowContent>
              <TextField
                id="allotmentWidth"
                dataCy="input-allotment-width"
                formik={formik}
                placeholder="Ex: L = 20m"
                type="number"
              />
              <strong>{' X '}</strong>
              <TextField
                id="allotmentLenght"
                dataCy="input-allotment-lenght"
                formik={formik}
                placeholder="Ex: C = 40m"
                type="number"
              />
            </RowContent>

            <Spacing />

            <TextField
              id="allotmentValue"
              dataCy="input-allotment-value"
              formik={formik}
              mask="money"
              label="Valor do terreno"
              placeholder="Ex: 1500000"
              required
              requireSymbolPosition="right"
              labelDescription={(
                <p>
                  {'Preencher o valor completo, utilizando apenas números, não inserir pontos como separador de milhar'}
                </p>
              )}
            />
            <Spacing />

            <Box className="box-allow-barter">
              <label className="label" htmlFor="allowBarter">Permite permuta?</label>
              <Switcher
                active={formik.values?.allowBarter}
                onChange={() => formik.setFieldValue('allowBarter', !formik.values?.allowBarter)}
              />
            </Box>
            <Spacing />
          </Grid>
        </FormGridRow>
        <Spacing />

        <Divider />

        <PropertyFormTitle>
          Documentos&nbsp;
          {documentsIsRequired && (
            <strong>
              {'(Obrigatório enviar um croqui do terreno)'}
            </strong>
          )}
        </PropertyFormTitle>
        <FormGridRow grid>
          <Grid item sm={12} xs={12}>
            <RowContent isColumn={isMobile}>
              <TextField
                id="documentLink"
                dataCy="input-documents-link"
                formik={formik}
                label="Link de documentos&nbsp;"
                placeholder="Digite aqui..."
                required={documentsIsRequired}
                requireSymbolPosition="right"
                activeTooltip
                tooltipTitle="Você deve anexar o arquivo no Google drive e compartilhar o link em modo público"
                labelDescription={(
                  <p>
                    <a href={LINK_GOOGLE_DRIVE_SUPPORT} target="blank">{'Como deixar'}</a>
                    &nbsp;
                    {' a pasta do Google drive pública?'}
                  </p>
                )}
              />

              <TextField
                id="photoLink"
                dataCy="input-photos-link"
                formik={formik}
                label="Link de fotos&nbsp;"
                placeholder="Digite aqui..."
                required={documentsIsRequired}
                requireSymbolPosition="right"
                activeTooltip
                tooltipTitle="Você deve anexar o arquivo no Google drive e compartilhar o link em modo público"
                labelDescription={(
                  <p>
                    <a href={LINK_GOOGLE_DRIVE_SUPPORT} target="blank">{'Como deixar'}</a>
                    &nbsp;
                    {' a pasta do Google drive pública?'}
                  </p>
                )}
              />
            </RowContent>
            <Spacing />

            <TextField
              formik={formik}
              id="comment"
              dataCy="input-comment"
              label="Observação"
              placeholder="Digite aqui..."
              type="textarea"
            />
          </Grid>
        </FormGridRow>
      </FormContainer>

      <FooterContainer variants={item}>
        <SubmitButton
          disabled={validateForm()}
          type="submit"
          whileTap={{ scale: 0.9 }}
        >
          <h1>Enviar</h1>
        </SubmitButton>
      </FooterContainer>
    </>
  );
};

export default LandIndicationForm;
