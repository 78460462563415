import styled from 'styled-components';
import { FormControlLabel, FormGroup, FormLabel } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 3rem 0 13rem 0;
  @media(max-width: 320px) {
    margin: 3rem 0 18rem 0;
  }
`;

export const OptionFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  
  .labelSelect {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.grey._900.hex()}
  }
  .style-multi-select, .css-q8hpuo-MuiFormControl-root {
    margin-left: 10px !important;
    margin-bottom: 5px !important;
    width: 230px !important;
  }
`;

export const Selectors = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.space {
    margin-bottom: 11px;
  }

  label{
    padding-bottom: 0px  !important;
  }

  span {
    width: 30%;
    margin-right: 5px;
    margin-left: 20px;
  }
`;

export const MutiSelectors = styled.div`
  margin-left: 15px;
`;

export const BarLabel = styled.div`
  height: 30px;
  width: 255px;
  padding-left: 17px;
  background-color: ${({ theme }) => theme.palette.grey._420.hex()};
  display: flex;
  align-items: center;
  border-radius: 0px 15px 15px 0px;

  &.barLabelStyle {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &.details{
    margin-top: 15px;
  }

  @media (max-width: 900px) {
    width: 95vw;
  }
`;

export const StyledFormLabel = styled(FormLabel)`
&& {
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.grey._900.hex()};

  &.css-28p64e-MuiFormLabel-root.Mui-focused{
    color: ${({ theme }) => theme.palette.grey._900.hex()};
  }

}
`;

export const StyledFormGroup = styled(FormGroup)`
  padding-left: 5px;

  .styleCheckbox {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .styleCheckbox div{
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.grey._900.hex()}
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    &.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
      font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  position: fixed;
  bottom: 0;
  padding-right: 1rem;
  padding-bottom: 1rem;

  @media(min-width: 900px) {
    max-width: 260px;
    padding-right: 0rem;
  }
`;

export const ButtonStyle = styled.div`
  padding-left: 5px;

  button {
    flex: 0 !important;
    width: 120px;
  }
`;

export const FormContent = styled.form<{ disableScroll?: boolean }>`
  width: 100%;
  overflow: ${({ disableScroll }) => (disableScroll ? 'hidden' : 'auto')};

  @media(min-width: 900px) {
    max-width: 260px;
  }
`;
