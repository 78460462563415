import { motion } from 'framer-motion';
import { useLottie } from 'lottie-react';
import React from 'react';
import { confirmationAnimationJSON } from '../../../../assets/animations';
import { Container } from './style';

const options = {
  animationData: confirmationAnimationJSON,
  loop: false,
  autoplay: true,
};

interface ISuccess {
  children?: React.ReactNode | undefined;
  onClose?: Function | undefined;
}

const style = {
  height: 200,
};

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 },
};

const ConfirmationAnimation = () => {
  const { View } = useLottie(options, style);
  return View;
};

const Success = ({ children, onClose }: ISuccess) => (
  <Container
    variants={list}
    initial="hidden"
    animate="visible"
  >
    <ConfirmationAnimation />
    <motion.h1 variants={item}>Dados enviados com sucesso!</motion.h1>
    {children || <></>}
    <motion.button onClick={() => onClose && onClose()} variants={item} type="button">
      Fechar
    </motion.button>
  </Container>
);

Success.defaultProps = {
  children: undefined,
  onClose: undefined,
};

export default Success;
