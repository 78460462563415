import { PropertyOwner } from '../../../services/Owner/types';

import { GuestListReportProvider } from '../../../context/OwnerPage/OwnerReport/GuestListReportContext';
import { ExpensesReportProvider } from '../../../context/OwnerPage/OwnerReport/ExpensesReportContext';

import Header from './Header';
import GuestList from './GuestList';
import Expenses from './Expenses';

import {
  Container,
  CardContainer,
} from './styles';

interface IOwnerReport {
  propertiesList: PropertyOwner[];
}

const OwnerReport = ({ propertiesList }: IOwnerReport) => (
  <Container>
    <Header />
    <GuestListReportProvider>
      <CardContainer className="guest-section">
        <GuestList />
      </CardContainer>
    </GuestListReportProvider>

    <ExpensesReportProvider>
      <CardContainer className="expenses-section">
        <Expenses propertiesList={propertiesList} />
      </CardContainer>
    </ExpensesReportProvider>
  </Container>
);

export default OwnerReport;
