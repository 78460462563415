import React, { useState } from 'react';

import sendEmailIcon from '../../../../../assets/icons/generals/sendEmail.svg';

import { ConfirmationEmailAnimation } from '../../../../../assets/animations';

import {
  Container,
  ButtonsContainer,
  IconContainer,
  ConfirmationTitle,
  IconContainerConfirmation,
} from './styles';

interface SendEmailProps {
  onCloseScreen: Function;
  onCloseModal: Function;
}

const ConfirmPay = ({ onCloseScreen, onCloseModal }: SendEmailProps) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  function closeModalOnTimeOut() {
    setTimeout(() => {
      onCloseModal();
    }, 8200);
  }

  const handleShowConfirmationEmailScreen = () => {
    setIsConfirmed(true);
    closeModalOnTimeOut();
  };

  return isConfirmed ? (
    <>
      <Container>
        <IconContainerConfirmation>
          <ConfirmationEmailAnimation />
          <ConfirmationTitle>
            Pagamento Anexado
            {' '}
            <br />
            {' '}
            com sucesso !
          </ConfirmationTitle>
        </IconContainerConfirmation>
      </Container>
    </>
  ) : (
    <>
      <Container>
        <IconContainer>
          <img src={sendEmailIcon} alt="Enviar email icone" />
          <h1>Enviar comprovantes ?</h1>
        </IconContainer>
        <ButtonsContainer>
          <button type="button" onClick={() => onCloseScreen()}>
            Voltar
          </button>
          <button type="button" onClick={() => handleShowConfirmationEmailScreen()}>
            Sim
          </button>
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default ConfirmPay;
