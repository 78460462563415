import React, { useEffect, useState } from 'react';

import moment from 'moment';
import calendarIcon from '../../../../../../assets/icons/generals/redCalendar.svg';
import arrowBlackIcon from '../../../../../../assets/icons/generals/arrowBlack.svg';

import {
  Container,
  Days,
  Header,
  LeftContainer,
  RightContainer,
  SubInfo,
  Image,
  SideContainer,
  BoldText,
  BottomContainer,
  ImageShimmer,
} from './styles';
import { getPropertyId } from '../../../../../../services/Property/request';
import { PropertyDetails } from '../../../../../../services/Property/types';

interface Props {
  idProperty: number;
  checkin: string;
  checkout: string;
  daysLeft: number;
}

const CardInfo = ({
  idProperty, checkin, checkout, daysLeft,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState<PropertyDetails>();

  const handleWaitLoading = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  };

  useEffect(() => {
    handleWaitLoading();
  }, []);

  useEffect(() => {
    async function getPropertyInformation() {
      const result = await getPropertyId(idProperty.toString());
      setProperty(result);
    }

    getPropertyInformation();
  }, []);

  return (
    <Container>
      <SideContainer>
        <LeftContainer>
          <Header>
            <h1>
              <BoldText>{property?.code}</BoldText>
              .
              {' '}
              {property?.address?.street}
              {' '}
              {property?.address?.number}
              ,
              {' '}
              {property?.address?.neighborhood}
              ,
              {property?.address?.city}
              {' '}
              -
              {' '}
              {property?.address?.state}
              {' '}
              . CEP
              {' '}
              {property?.address?.postal_code}
            </h1>
          </Header>
          <SubInfo>
            <img src={calendarIcon} alt="icone de calendário" />
            <div>
              <h1>{moment(checkin).format('DD/MM/YYYY')}</h1>
              <img src={arrowBlackIcon} alt="seta de separação" />
              <h1>{moment(checkout).format('DD/MM/YYYY')}</h1>
            </div>
          </SubInfo>
          <Days />
        </LeftContainer>
        <RightContainer>
          <Image>
            {
              loading ? (
                <ImageShimmer />
              ) : (
                <img
                  src="https://source.unsplash.com/random/1280x720"
                  alt="miniatura de imagem de imóvel"
                />
              )
            }

          </Image>
        </RightContainer>
      </SideContainer>
      <BottomContainer>
        <h1>
          {daysLeft + 1}
          {' '}
          dias restantes
        </h1>
      </BottomContainer>
    </Container>
  );
};

export default CardInfo;
