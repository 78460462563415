import React, { FC } from 'react';
import theme from '../../styles/themes';

import {
  Container,
  ContainerLabel,
  Label,
  StarContainer,
  StarContent,
  Input,
  StarIconContainer,
  StarIcon,
  RatingCounter,
  StarSymbol,
} from './styles';

import { useStarRating } from '../../hooks/StarRatingHook/useStarRating';

interface IStar {
  label?: string,
  required?: boolean,
  disableChangeStar?: boolean,
}

const StarRating: FC<IStar> = ({
  label = '',
  required = false,
  disableChangeStar = false,
}) => {
  const {
    rating,
    handleChangeRating,
    hover,
    handleChangeHover,
  } = useStarRating();

  return (
    <Container>
      {label && (
        <ContainerLabel>
          {required && <StarSymbol>*</StarSymbol>}
          <Label htmlFor={label}>{label}</Label>
        </ContainerLabel>
      )}

      <StarContainer>
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;

          return (
            <StarContent key={star} data-cy={`star-${index + 1}`}>
              <Input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={disableChangeStar ? () => {
                }
                  : () => handleChangeRating(ratingValue)}
              />
              <StarIconContainer
                disableChangeStar={disableChangeStar}
                starColor={ratingValue <= (hover || rating)
                  ? theme.palette.blue._920.hex() : theme.palette.grey._340.hex()}
                onMouseEnter={disableChangeStar ? () => {
                }
                  : () => handleChangeHover(ratingValue)}
                onMouseLeave={disableChangeStar ? () => {
                }
                  : () => handleChangeHover(0)}
              >
                <StarIcon />
              </StarIconContainer>
            </StarContent>
          );
        })}

        {rating !== 0 && <RatingCounter>{rating.toFixed(1)}</RatingCounter>}
      </StarContainer>
    </Container>
  );
};

export default StarRating;
