import { Divider } from '@mui/material';
import { useManagementPanel } from '../../../hooks/useManagementPanel/useManagementPanel';
import { compareList } from '../../../utils/Sorting';

import ManagementPanelCard from './ManagementPanelCard';
import { CardContainer, ManagementPanelContainer } from './styles';

type CardType = 'IMPORTSTAYSRESERVATION' | 'EXPORTCSV';

interface CardData {
  id: number;
  name: string;
  cardType: CardType;
}

const cardList: CardData[] = [
  {
    id: 1,
    name: 'Importar reservas da Stays para o Sapron',
    cardType: 'IMPORTSTAYSRESERVATION' as CardType,
  },
  {
    id: 2,
    name: 'Exportar CSVs',
    cardType: 'EXPORTCSV' as CardType,
  },
].sort((firstCard, nextCard) => compareList(firstCard.name, nextCard.name));

const ManagementPanel = () => {
  const { handleOpenImportStaysReservationModal, handleOpenExportCSVModal } = useManagementPanel();

  const handleCardClick = (type: CardType) => {
    switch (type) {
      case 'IMPORTSTAYSRESERVATION':
        return handleOpenImportStaysReservationModal;
      case 'EXPORTCSV':
        return handleOpenExportCSVModal;
      default:
        return () => {};
    }
  };

  return (
    <ManagementPanelContainer>
      <h1>Painel de gerenciamento</h1>
      <Divider />
      <CardContainer>
        {cardList.map((card, index) => (
          <ManagementPanelCard
            key={card.id}
            name={card.name}
            time={((index + 1) * 500) + 400}
            cardType={card.cardType}
            onClick={handleCardClick(card.cardType)}
          />
        ))}
      </CardContainer>
    </ManagementPanelContainer>
  );
};

export default ManagementPanel;
