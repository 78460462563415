import styled, { keyframes } from 'styled-components';

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const FormGridContainer = styled.div`
  margin-top: 1rem;
`;

export const Space = styled.div`
  height: 20px;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  grid-area: 'form';
  margin: 1rem;
`;

export const FadeAnimation = styled.div`
  animation-delay: .4s;
  animation-name: ${FadeInOpacity};
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;
`;

export const FormContent = styled.form`
  width: 100%;
  margin: 1rem;
  height: 100vh;
`;
