import { motion } from 'framer-motion';
import styled from 'styled-components';
import { animationTime, shimmerAnimation } from '../../../../../../../../components/Calendar/LoadingShimmer/animationShimmer';

export const Container = styled(motion.img)`
  object-fit: cover;
  width: 100%;
  height: 100%;

  transition: 0.4s;

  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
  background: ${({ theme }) => theme.palette.grey._400.hex()};

  transform: scale(1);

  top: 0;
  left: 0;
  position: absolute;

  z-index: 2;
`;

export const ShadowImage = styled.div`
  opacity: 0.5;

  z-index: 3;
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #000;
`;
