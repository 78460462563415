const TOKEN = 'pre-checkin';

interface LocalStoragePrecheckin {
  value?: string;
  action: 'set' | 'get';
}

export function localStoragePreCheckin({
  value,
  action,
}: LocalStoragePrecheckin) {
  switch (action) {
    case 'set':
      if (value) {
        localStorage.setItem(TOKEN, value);
        return null;
      }
      return null;

    case 'get':
      return localStorage.getItem(TOKEN);

    default:
      return localStorage.getItem(TOKEN);
  }
}
