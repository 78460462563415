/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { gridTemplateColumns } from './config';

export const Container = styled.div`
  width: 100%;
  height: inherit;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 10px;
  border-radius: 10px;
  background: var(--blue-50, #E7E9FA);

  @media screen and (max-width: 1000px) {
    min-height: 250px;
  }

  &::-webkit-scrollbar{
    height: 3px;
  }
`;
