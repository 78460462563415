import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { MenuItem, Menu, IconButton } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { numberToCurrency } from '../../utils/Formatter';
import { PropertyDetails } from '../../services/Property/types';

import {
  ContainerCard, Description, IconImage, Image, Price, ResumeInformation,
  Sep, TitleProperty, Total, ValorTotal, ValueTotal,
} from './styles';

import copyImg from '../../assets/icons/generals/copy.svg';
import mapImg from '../../assets/icons/propertySearch/map-pin.svg';
import propertyImage from '../../assets/icons/generals/property-not-found.svg';
import { FilterBar } from '../../context/FilterBar';

const options = [
  {
    icon: copyImg,
    description: 'Copiar descrição',
  },
  {
    icon: mapImg,
    description: 'Mapa localização',
  },
];

const ITEM_HEIGHT = 48;

type Props = {
  property: PropertyDetails,
  filterBar: FilterBar;
};

const CardProperty = ({ property, filterBar }: Props): JSX.Element => {
  function numberDaily() {
    if (typeof filterBar.checkInDate !== 'string' && typeof filterBar.checkOutDate !== 'string') {
      const duration = moment.duration(filterBar.checkOutDate.diff(filterBar.checkInDate));
      return Math.round(duration.asDays());
    }

    return 0;
  }

  function valueDaily() {
    const daysTotal = numberDaily();
    const valueTotal = daysTotal * parseInt(property.daily_prices || '0', 10);
    return valueTotal;
  }

  function totalValue() {
    const daysTotal = valueDaily();
    const valueTotal = parseFloat(daysTotal.toString()) + parseFloat(property.cleaning_fee?.toString() || '0');
    return valueTotal;
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ContainerCard>
        <Link
          to={`/buscadepropriedades/${property.id}`}
          state={{
            title: 'Imóveis',
            path: '/buscadepropriedades',
          }}
        >
          <Image src={propertyImage} />

        </Link>
        <TitleProperty>
          <Link
            to={
              `/buscadepropriedades/${property.id}`
            }
            state={{
              title: 'Imóveis',
              path: '/buscadepropriedades',
            }}
          >
            {property.code}
          </Link>
        </TitleProperty>
        <ResumeInformation>
          <div>
            <p>
              {property?.address?.neighborhood}
              .
              {' '}
              {property.guest_capacity}
              {' '}
              hóspedes
              {' '}
              .
              {' '}
              {property.bedroom_quantity}
              {' '}
              quartos
            </p>
          </div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '22ch',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.description} selected={option.description === 'Pyxis'} onClick={handleClose}>
                <IconImage src={option.icon} alt={option.description} />
                <Description>
                  {option.description}
                </Description>
              </MenuItem>
            ))}
          </Menu>
        </ResumeInformation>
        <Price>
          <p>Valor diária</p>
          <p>
            {numberToCurrency(parseInt(property.daily_prices || '0', 10))}
            {' '}
            / noite
          </p>
        </Price>
        <Price>
          {numberDaily() > 0 && (
            <>
              <p>
                Total
                {' '}
                {numberDaily()}
                {' '}
                diárias
              </p>
              <p>{numberToCurrency(valueDaily())}</p>
            </>
          )}
          {!numberDaily() && (
            <p>Selecione um período nos filtros para ver o total de diária</p>
          )}
        </Price>
        <Price>
          <p>Taxa de limpeza</p>
          <p>{numberToCurrency(property.cleaning_fee)}</p>
        </Price>
        <Price>
          <p>Caução</p>
          <p>{numberToCurrency(property.bond_amount)}</p>
        </Price>
        <ValorTotal>
          {!numberDaily() && (
          <Total>Selecione um período nos filtros para ver o total</Total>
          )}
          {numberDaily() > 0 && (
            <>
              <Total>Total:</Total>
              <Sep>
                <ValueTotal>{numberToCurrency(totalValue())}</ValueTotal>
              </Sep>
            </>
          )}
        </ValorTotal>
      </ContainerCard>
    </>

  );
};

export default memo(CardProperty);
