import { useMemo, useEffect } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';

import { deletePropertyChangeHost } from '../../../../../services/InsertData/request';

import { useChangePropertyHost } from '../../../../../hooks/useChangePropertyHost/useChangePropertyHost';
import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../../utils/Messages';

import DragDrawerModal from '../../../../Modal/DragDrawerModal';
import TextField from '../../../../TextField';
import DatePicker from '../../../../DatePicker';
import FormGridRow from '../../../../FormGridRow';
import Spinner from '../../Spinner';

import {
  Form,
  FormContent,
  DatePickerContainer,
} from './styles';

interface ChangeHostPropertyFormikValuesType {
  property?: number;
  newHostId?: number;
  oldHostId?: number;
  oldHostName?: string;
  newHostName?: string;
  replacementDate?: string | null | Date;
}

const ModalDeleteChangeHost = () => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const {
    exchangedHosts,
    modalDeleteExchangeHost,
    setModalDeleteExchangeHost,
    setExchangedHosts,
  } = useChangePropertyHost();

  const handleClose = () => {
    setModalDeleteExchangeHost({
      open: false,
      infos: null,
    });
  };

  const infos = useMemo(() => modalDeleteExchangeHost.infos, [modalDeleteExchangeHost]);

  const formikInitialValues: ChangeHostPropertyFormikValuesType = {
    oldHostName: infos?.old_host?.full_name || '',
    newHostName: infos?.new_host?.full_name || '',
    property: infos?.property?.id || undefined,
    oldHostId: infos?.old_host?.id || undefined,
    newHostId: infos?.new_host?.id || undefined,
    replacementDate: infos?.replacement_date
      ? moment(infos?.replacement_date, 'DD-MM-YYYY').toDate() : null,
  };

  const formik = useFormik<ChangeHostPropertyFormikValuesType>({
    initialValues: formikInitialValues,
    onSubmit: async () => {
      try {
        if (infos?.id) {
          await deletePropertyChangeHost(infos?.id);
          const response = exchangedHosts.filter((item) => `${item.id}` !== `${infos?.id}`);
          setExchangedHosts(response);

          toast.success('Exclusão Realizada');
          formik.resetForm();
          handleClose();
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  useEffect(() => {
    const date = infos?.replacement_date
      ? moment(infos?.replacement_date, 'DD-MM-YYYY').toDate() : null;
    formik.setFieldValue('replacementDate', date);
  }, [infos]);

  return (
    <DragDrawerModal
      variant="withCancelAndAppyButton"
      title="Excluir mudança"
      cancelButtonText="Cancelar"
      applyButtonText="Excluir"
      applyButtonColor="red"
      open={modalDeleteExchangeHost.open}
      onClose={handleClose}
      handleClickApplyButton={() => formik.handleSubmit()}
      handleClickCancelButton={handleClose}
      maxWidthForModalOnDesk={'693px'}
      maxHeightForModalOnDesk={'auto'}
      activeDragHandAnimation={false}
      disableApplyButton={formik.isSubmitting}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Spinner loading={formik.isSubmitting} />
        <FormContent isVisible={!formik.isSubmitting}>
          <FormGridRow grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="property"
                label="Imóvel"
                formik={formik}
                value={infos?.property?.code}
                labelClassName="inputLabel"
                disabled
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="oldHostId"
                label="Anfitrião Atual"
                formik={formik}
                value={infos?.old_host?.full_name}
                labelClassName="inputLabel"
                disabled
              />
            </Grid>
          </FormGridRow>

          <FormGridRow grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="newHostId"
                label="Anfitrião Novo"
                formik={formik}
                value={infos?.new_host?.full_name}
                labelClassName="inputLabel"
                disabled
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DatePickerContainer>
                <DatePicker
                  id="replacementDate"
                  dataCy="datepicker-replacement-date"
                  formik={formik}
                  label="Data de Entrega"
                  labelClassName="inputLabel"
                  viewsCustom={['day']}
                  hasInitialDates
                  disableInput
                  disableDate
                  showDaysOutsideCurrentMonth
                  onCustomClose={() => {}}
                />
              </DatePickerContainer>
            </Grid>
          </FormGridRow>
        </FormContent>
      </Form>
    </DragDrawerModal>
  );
};

export default ModalDeleteChangeHost;
