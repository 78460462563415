import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

import { IListItems, IStatementImage, OrderGridOptions } from '../../components/HostTedList/types/listItems';
import { listItemsJSON } from '../../components/HostTedList/mock';
import { sortTedListItems } from '../../components/HostTedList/utils';

export interface IHostTedListContext {
  listItems: IListItems[];
  setListItems: Function;
  filteredItems: IListItems[];
  setFilteredItems: Function;
  loading: boolean;
  setLoading: Function;
  openModalID: number;
  setOpenModalID: Function;
  openModalConfirmDeleteVoucher: boolean;
  setOpenModalConfirmDeleteVoucher: Function;
  deleteVoucherIsConfirmed: boolean,
  setDeleteVoucherIsConfirmed: Function,
  modalImage: IStatementImage;
  setModalImage: Function;
  orderGridBy: OrderGridOptions;
  setOrderGridBy: Function;
}

export const HostTedListContext = createContext<IHostTedListContext>({} as IHostTedListContext);

export const HostTedListProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [listItems, setListItems] = useState<IListItems[]>(listItemsJSON);
  const [filteredItems, setFilteredItems] = useState<IListItems[]>([]);
  const [openModalID, setOpenModalID] = useState<number>(0);
  const [modalImage, setModalImage] = useState<IStatementImage>({} as IStatementImage);
  const [loading, setLoading] = useState<boolean>(false);

  const [
    openModalConfirmDeleteVoucher,
    setOpenModalConfirmDeleteVoucher,
  ] = useState<boolean>(false);

  const [deleteVoucherIsConfirmed, setDeleteVoucherIsConfirmed] = useState<boolean>(false);

  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'name',
    order: 'asc',
  });

  const handleModalImage = useCallback((image: IStatementImage) => {
    setModalImage(image);
  }, []);

  const handleListItems = useCallback((newListItems: IListItems[]) => {
    setListItems(newListItems);
  }, []);

  const handleFilteredItems = useCallback((newFilteredItems: IListItems[]) => {
    setFilteredItems(newFilteredItems);
  }, []);

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleOpenModalID = useCallback((newOpenModalID: number) => {
    setOpenModalID(newOpenModalID);
  }, []);

  const handleOpenModalConfirmDeleteVoucher = useCallback((open: boolean) => {
    setOpenModalConfirmDeleteVoucher(open);
  }, []);

  const handleSetDeleteVoucherIsConfirmed = useCallback((isConfirmed: boolean) => {
    setDeleteVoucherIsConfirmed(isConfirmed);
  }, []);

  const handleSetOrderGridBy = useCallback((orderBy: OrderGridOptions) => {
    setOrderGridBy(orderBy);
  }, []);

  useEffect(() => {
    const response: IListItems[] = sortTedListItems(
      listItems,
      orderGridBy.order_by,
      orderGridBy.order,
    );

    handleListItems([...response]);
  }, [orderGridBy]);

  useEffect(() => {
    if (deleteVoucherIsConfirmed) {
      handleOpenModalConfirmDeleteVoucher(false);
    }
  }, [deleteVoucherIsConfirmed]);

  const value = useMemo(() => ({
    listItems,
    setListItems: handleListItems,
    filteredItems,
    setFilteredItems: handleFilteredItems,
    loading,
    setLoading: handleLoading,
    openModalID,
    setOpenModalID: handleOpenModalID,
    openModalConfirmDeleteVoucher,
    setOpenModalConfirmDeleteVoucher: handleOpenModalConfirmDeleteVoucher,
    deleteVoucherIsConfirmed,
    setDeleteVoucherIsConfirmed: handleSetDeleteVoucherIsConfirmed,
    modalImage,
    setModalImage: handleModalImage,
    orderGridBy,
    setOrderGridBy: handleSetOrderGridBy,
  }),
  [
    listItems,
    handleListItems,
    filteredItems,
    handleFilteredItems,
    loading,
    handleLoading,
    openModalID,
    handleOpenModalID,
    openModalConfirmDeleteVoucher,
    setOpenModalConfirmDeleteVoucher,
    deleteVoucherIsConfirmed,
    setDeleteVoucherIsConfirmed,
    modalImage,
    handleModalImage,
    orderGridBy,
    setOrderGridBy,
  ]);

  return (
    <HostTedListContext.Provider value={value}>{children}</HostTedListContext.Provider>
  );
};
