import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../../../../context/ToastContext';
import { EnumType, IType, partnersRoutes } from '../../../../types/paths';

import {
  Title, Container, ItemContainer, CardItemContainer,
} from './style';

import { TerrainCard, SpotCard, LocationCard } from './svgs';

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 20 },
};

interface IAnimatedCardTemplateProps {
  text: string;
  icon: JSX.Element;
  type?: IType;
  inDevelopment?: boolean;
}

const AnimatedCardTemplate = ({
  text,
  icon,
  type,
  inDevelopment,
}: IAnimatedCardTemplateProps) => {
  const toast = useToast();
  const navigate = useNavigate();

  const handleFormNavigation = () => {
    if (inDevelopment) {
      toast.alert('Em desenvolvimento ...');
    } else {
      navigate(`${partnersRoutes.indicate.form.main}/${type}`);
    }
  };

  return (
    <CardItemContainer
      inDevelopment={inDevelopment}
      onClick={() => handleFormNavigation()}
      whileTap={{
        scale: 0.9,
        border: '1px solid #fefefe',
      }}
      variants={item}
    >
      <ItemContainer>{icon}</ItemContainer>
      <Title>{text}</Title>
    </CardItemContainer>
  );
};

const Cards = () => (
  <Container initial="hidden" animate="visible" variants={list}>
    <AnimatedCardTemplate
      type={EnumType.terrain}
      icon={<TerrainCard />}
      text={'Indicar Terreno'}
    />

    <AnimatedCardTemplate
      type={EnumType.location}
      icon={<LocationCard />}
      text={'Indicar para Locação'}
    />

    <AnimatedCardTemplate
      type={EnumType.spot}
      icon={<SpotCard />}
      text={'Vender SPOTS'}
    />
  </Container>
);

AnimatedCardTemplate.defaultProps = {
  inDevelopment: false,
  type: undefined,
};

export default Cards;
