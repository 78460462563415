import React from 'react';

import arrowBottom from '../../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../../assets/icons/generals/arrowTop.svg';

import {
  CheckIn,
  Container,
  GridTitle,
  GridHeader,
  TotalValue,
  PendingValue,
  ReservationCode,
  ReservationStaff,
} from './styles';

const Header = () => (
  <Container>
    <GridHeader>
      <div />
      <ReservationCode>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Código
              {' '}
              <br />
              da reserva
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </ReservationCode>
      <CheckIn>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Checkin
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </CheckIn>

      <ReservationStaff>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Dono
              {' '}
              <br />
              {' '}
              da reserva
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </ReservationStaff>

      <TotalValue>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Valor
              {' '}
              <br />
              {' '}
              Total
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </TotalValue>

      <PendingValue>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Valor
              {' '}
              <br />
              {' '}
              pendente
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </PendingValue>
    </GridHeader>
  </Container>
);

export default Header;
