import React, { useState } from 'react';

import sendEmailIcon from '../../../../../../assets/icons/generals/sendEmail.svg';

import { ConfirmationAnimation } from '../../../../../../assets/animations';

import {
  Container,
  ButtonsContainer,
  IconContainer,
  ConfirmationTitle,
  IconContainerConfirmation,
} from './styles';

interface ConfirmScreenProps {
  onCancel: Function;
  onFinish: Function;
}

const ConfirmScreen = ({ onCancel, onFinish }: ConfirmScreenProps) => {
  const [isConfirmed, setIsConfirmed] = useState(true);

  function closeModalOnTime() {
    setTimeout(() => {
      onFinish();
    }, 2000);
  }

  const handleShowConfirmationEmailScreen = () => {
    setIsConfirmed(true);
    closeModalOnTime();
  };

  React.useEffect(() => {
    closeModalOnTime();
  }, []);

  if (isConfirmed) {
    return (
      <>
        <Container>
          <IconContainerConfirmation>
            <ConfirmationAnimation />
            <ConfirmationTitle>
              Pagamento anexado
              {' '}
              <br />
              {' '}
              com sucesso !
            </ConfirmationTitle>
          </IconContainerConfirmation>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <IconContainer>
          <img src={sendEmailIcon} alt="Enviar email icone" />
          <h1>Anexar pagamentos ?</h1>
        </IconContainer>
        <ButtonsContainer>
          <button type="button" onClick={() => onCancel()}>
            Voltar
          </button>
          <button type="button" onClick={() => handleShowConfirmationEmailScreen()}>
            Sim
          </button>
        </ButtonsContainer>
      </Container>
    </>
  );
};

export default ConfirmScreen;
