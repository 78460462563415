import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { SimpleSelect } from '../../../index';

import { Container } from './styles';
import { PropertyOwner } from '../../../../services/Owner/types';
import { getPropertiesOwner } from '../../../../services/Owner/request';
import { compareList } from '../../../../utils/Sorting';
import { useViewMode } from '../../../../context/ViewMode/ViewMode';

export interface PropertiesProps {
  id?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  dataCy?: string;
}

const PropertiesSelector: React.FC<PropertiesProps> = ({
  label = '',
  disabled = false,
  dataCy = '',
}) => {
  const [selectedOption, setSelectedOption] = useState<boolean>(false);
  const [actualProperty, setActualProperty] = useState<PropertyOwner>();
  const [propertiesList, setPropertiesList] = useState<Array<PropertyOwner>>([]);
  const { id } = useParams();
  const { mode } = useViewMode();
  const navigate = useNavigate();

  const getProperties = async () => {
    const validateMode = (mode && mode) || undefined;
    const values = await getPropertiesOwner({}, validateMode);

    const activeValues: PropertyOwner[] = [];
    const inactiveValues: PropertyOwner[] = [];

    values.forEach((value) => {
      if (value.status === 'Inactive') {
        inactiveValues.push({ ...value, code: `${value.code} - (Inativo)` });
      } else {
        activeValues.push(value);
      }
    });

    activeValues.sort((a, b) => a.code.localeCompare(b.code));
    inactiveValues.sort((a, b) => a.code.localeCompare(b.code));

    setPropertiesList([...activeValues, ...inactiveValues]);
  };

  const getActualProperty = () => {
    let property;
    if (id) {
      property = propertiesList.find((item) => item.id === parseInt(id, 10));
      setActualProperty(property);
    }
    return property;
  };

  const formik = useFormik({
    initialValues: {
      property: actualProperty?.code,
    },
    onSubmit: () => {},
  });

  const propertiesCodeList = propertiesList?.map((property) => ({
    key: property.code,
    id: property.id,
    value: property.code,
    valueLabel: property.code,
  }));

  const handleChangeRouteByOption = () => {
    if (propertiesCodeList && formik.values.property) {
      const property = propertiesCodeList.find((item) => item.value === formik.values.property);
      if (property) {
        navigate(`/proprietario/${property.id}`);
        setSelectedOption(false);
      }
    }
  };

  const handleSetTrueSelectedOption = () => {
    if (formik.values.property) {
      setSelectedOption(true);
    } else {
      setSelectedOption(false);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  useEffect(() => {
    if (propertiesList.length > 0) {
      getActualProperty();
    }
  }, [propertiesList]);

  useEffect(() => {
    handleSetTrueSelectedOption();
  }, [formik.values.property]);

  useEffect(() => {
    if (formik.values.property) {
      handleChangeRouteByOption();
    }
  }, [selectedOption]);

  return (
    <Container>
      <SimpleSelect
        dataCy={dataCy}
        id="property"
        formik={formik}
        label={label}
        disabled={disabled}
        placeholder="Selecione"
        firstValue={`${actualProperty?.code || ''}`}
        className="selector"
        options={propertiesCodeList}
        onChange={() => { handleSetTrueSelectedOption(); }}
      />
    </Container>
  );
};

export default PropertiesSelector;
