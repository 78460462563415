/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import { useFormik } from 'formik';
import {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import moment from 'moment';
import {
  GetPartnerDetailsById,
  PatchEditAddress,
  PatchEditHost,
  PatchEditUser,
  PatchPartner,
  PostAddressObject,
  PostHostObject,
  PostUserObject,
} from '../../../services/InsertData/types';
import { Partner } from '../../../services/Partner/types';
import {
  deleteHost,
  deleteUser,
  getHostDetailsById,
  getPartnerDetailsById,
  getUserDetailsById,
  patchEditAddress,
  patchEditHost,
  patchEditPartner,
  patchEditUser,
  postEditPartner,
  postHost,
  postUser,
} from '../../../services/InsertData/request';

import {
  postAddress,
  deleteAddress,
} from '../../../services/Address/request';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';

import FormButton from '../../FormButton';
import FormAddUser from './Forms/FormAddUser';

import { currencyToNumber, onlyNumbers } from '../../../utils/Formatter';
import { Number as Mask } from '../../../utils/InputMask';
import { useLoader } from '../../../context/LoaderContext';

import {
  ButtonsContainer,
  Container,
  Divider,
  Form,
  Title,
} from './styles';

import { deletePartner, postPartner } from '../../../services/Partner';
import { getHostFranchiseFee, postHostFranchiseFee } from '../../../services/FinancialClose/Host/request';
import { FranchseFeeProps } from '../../../services/FinancialClose/Host/types';

type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed' | undefined;
interface FormikValues {
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  franchiseCode: string;
  franchiseModel: string;
  phoneNumber1: string;
  phoneNumber2: string;
  gender: string;
  birthDate: Date | null;
  cpf?: string;
  cnpj?: string;
  corporateName: string;
  tradingName: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  reservationRoyalties: number;
  cleaningRoyalties: number;
  isHostOps: boolean;
  location: number;
  hostId?: number;
  isIndividual: boolean;
  pipedrivePersonId: string;
  nationality: string;
  maritalStatus: MaritalStatus;
  profession: string;
  emailForOperation: string;
  zipCode: string;
  partnerId?: number;
  signedContractHost: boolean;
  contractSigningJustification: string;
  signedContractPartner: boolean;
  link: string,
  phoneNumberAttendent: string,
  attendent: string,
  executive: number,
  pipedrivePartnerId?: string,

  haveGracePeriod: boolean,
  franchiseFeeAmount: string,
  rebatePercentage: number,
  newRebatePercentage: number,
  finalDateFranchiseFee: Date | null | string,
  updatedAtFranchiseFee: string | null,
  cleaningCommissionFee: number;
  reservationCommissionFee: number;
}

interface HostDetailsProps {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  mainRole: string;
  gender: string;
  birthDate?: string;
  mainAddress?: number;
  postalAddress?: number;
  isIndividual?: boolean;
  idNumber?: string;
  cpf?: string;
  cnpj?: string;
  corporateName?: string;
  tradingName?: string;
  nickname?: string;
  hostId?: number;
  reservationRoyalties: number;
  cleaningRoyalties: number;
  createdAt?: string;
  updatedAt?: string;
  isHostOps?: boolean;
  user: number;
  location?: number;
  neighborhood: string;
  country: string;
  street: string;
  number?: string;
  complement?: string;
  city: string;
  state: string;
  postalCode: string;
  condominium?: string;
  pipedrivePersonId?: string;

  haveGracePeriod?: boolean,
  franchiseFeeAmount?: string,
  rebatePercentage?: number,
  newRebatePercentage?: number,
  finalDateFranchiseFee?: Date | null | string,
  updatedAtFranchiseFee?: string | null,
  cleaningCommissionFee: number,
  reservationCommissionFee: number,

  signed_contract?: boolean;
  contract_signing_justification?: string;
  franchiseCode?: string;
  franchiseModel?: string;
}

interface PartnerDetailsProps {
  id: number;
  user?: {
    id?: number;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number1?: string;
    trading_name?: string;
    is_individual?: boolean;
  },
  signed_contract?: boolean;
  nationality?: string;
  marital_status: MaritalStatus;
  profession: string;
  attendant_name: string;
  attendant_phone_number: string
  spreadhsheet_link: string;
  executive: number;
}

const InsertHostData = () => {
  const isEditingForm = [
    '/editardados/anfitriao',
    'editardados/anfitriao',
    '/editardados/anfitriao/',
    'editardados/anfitriao/',
  ].includes(window.location.pathname);

  const [passwordFirstNameRegexp, setPasswordFirstNameRegexp] = useState<RegExp>();
  const [passwordLastNameRegexp, setPasswordLastNameRegexp] = useState<RegExp>();
  const [allHostDetails, setAllHostDetails] = useState<HostDetailsProps>({} as HostDetailsProps);
  const [allPartnerDetails,
    setAllPartnerDetails] = useState<PartnerDetailsProps>({} as PartnerDetailsProps);
  const { setLoad } = useLoader();

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const navigate = useNavigate();

  function padTo2Digits(number: number) {
    return number.toString().padStart(2, '0');
  }

  function formatDate(date?: Date | string) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return [
          current.getFullYear(),
          padTo2Digits(current.getMonth() + 1),
          padTo2Digits(current.getDate()),
        ].join('-');
      }
    }
    return undefined;
  }

  function handleAge(date: Date) {
    return new Date().getFullYear() - date.getFullYear();
  }

  const initialValues = {
    firstName: !isEditingForm ? '' : (allHostDetails.firstName || ''), // User
    lastName: !isEditingForm ? '' : (allHostDetails.lastName || ''), // User
    nickname: !isEditingForm ? '' : (allHostDetails.nickname || ''), //
    email: !isEditingForm ? '' : (allHostDetails.email || ''), // User
    password: '', // User
    passwordConfirmation: '', // User
    franchiseCode: !isEditingForm ? '' : (allHostDetails?.franchiseCode || ''), //
    franchiseModel: !isEditingForm ? '' : (allHostDetails?.franchiseModel || ''), //
    phoneNumber1: !isEditingForm ? '' : Mask.phone(allHostDetails.phoneNumber1 || ''), // User
    phoneNumber2: !isEditingForm ? '' : Mask.phone(allHostDetails.phoneNumber2 || ''), // User
    gender: !isEditingForm ? '' : (allHostDetails.gender || ''), // User
    birthDate: !isEditingForm ? null
      : moment(allHostDetails?.birthDate).toDate() || null,
    cpf: !isEditingForm ? '' : Mask.cpf(allHostDetails.cpf || ''), // User
    cnpj: !isEditingForm ? '' : Mask.cnpj(allHostDetails.cnpj || ''), // User
    corporateName: !isEditingForm ? '' : (allHostDetails.corporateName || ''), // User
    tradingName: !isEditingForm ? '' : (allHostDetails.tradingName || ''), // User
    street: !isEditingForm ? '' : (allHostDetails.street || ''), // Address
    number: !isEditingForm ? '' : (allHostDetails.number || ''), // Address
    complement: !isEditingForm ? '' : (allHostDetails.complement || ''), // Address
    neighborhood: !isEditingForm ? '' : (allHostDetails.neighborhood || ''), // Address
    city: !isEditingForm ? '' : (allHostDetails.city || ''), // Address
    state: !isEditingForm ? '' : (allHostDetails.state || ''), // Address
    country: !isEditingForm ? '' : (allHostDetails.country || ''), // Address
    postalCode: !isEditingForm ? '' : Mask.cep(allHostDetails.postalCode || ''), // Address
    reservationRoyalties: !isEditingForm ? 0
      : (allHostDetails.reservationRoyalties * 100 || 0), // Host
    cleaningRoyalties: !isEditingForm ? 0 : (allHostDetails.cleaningRoyalties * 100 || 0), // Host
    isHostOps: !isEditingForm ? false : (allHostDetails.isHostOps || false), // Host
    location: !isEditingForm ? -1 : (allHostDetails.location || -1), // Host
    hostId: !isEditingForm ? undefined : (allHostDetails.hostId || undefined),
    isIndividual: !isEditingForm ? true : (allHostDetails.isIndividual || true),
    pipedrivePersonId: !isEditingForm ? '' : (allHostDetails.pipedrivePersonId || ''),
    nationality: !isEditingForm ? '' : (allPartnerDetails.nationality || ''), // partner
    maritalStatus: !isEditingForm ? undefined
      : (allPartnerDetails.marital_status || undefined), // partner
    profession: !isEditingForm ? 'Não informado' : (allPartnerDetails.profession || 'Não informado'), // partner
    emailForOperation: !isEditingForm ? '' : ('allPartnerDetails.' || ''), // partner
    transferDay: !isEditingForm ? ''
      : (allHostDetails.cpf || 'Não informado'),
    zipCode: '',
    partnerId: !isEditingForm ? undefined : (allPartnerDetails.id || undefined),
    signedContractHost: !isEditingForm ? false : (allHostDetails.signed_contract || false),
    contractSigningJustification: !isEditingForm ? '' : (allHostDetails.contract_signing_justification || ''),
    signedContractPartner: !isEditingForm ? false : (allPartnerDetails.signed_contract || false),
    link: !isEditingForm ? '' : (allPartnerDetails.spreadhsheet_link || ''),
    phoneNumberAttendent: !isEditingForm ? '' : (allPartnerDetails.attendant_phone_number || ''),
    attendent: !isEditingForm ? '' : (allPartnerDetails.attendant_name || ''),
    executive: !isEditingForm ? 0 : (allPartnerDetails.executive || 0),
    pipedrivePartnerId: '',
    haveGracePeriod: !isEditingForm ? false : (allHostDetails?.haveGracePeriod || false),
    franchiseFeeAmount: !isEditingForm ? '' : `${allHostDetails?.franchiseFeeAmount || ''}`,
    rebatePercentage: !isEditingForm ? 0 : allHostDetails?.rebatePercentage || 0,
    newRebatePercentage: !isEditingForm ? 0 : allHostDetails?.rebatePercentage || 0,
    finalDateFranchiseFee: !isEditingForm ? null
      : moment(allHostDetails?.finalDateFranchiseFee).toDate(),
    updatedAtFranchiseFee: !isEditingForm ? null
      : allHostDetails.updatedAtFranchiseFee ? moment(allHostDetails.updatedAtFranchiseFee).format('DD/MM/YYYY') : null,
    cleaningCommissionFee: !isEditingForm ? 1 : allHostDetails.cleaningCommissionFee,
    reservationCommissionFee: !isEditingForm ? 0.08 : allHostDetails.reservationCommissionFee,
  };

  const validationInsert = Yup.object().shape({
    firstName: Yup.string().required(),
    pipedrivePersonId: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email('Digite um e-mail valido').required(),
    password: Yup.string().required().min(8, 'Mínimo 8 caracteres')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'A senha precisa ter no mínimo uma letra maiúscula ou uma letra minúscula, '
      + 'um número e um caracter especial')
      .matches(passwordFirstNameRegexp || /./, 'Senha fraca')
      .matches(passwordLastNameRegexp || /./, 'Senha fraca'),
    passwordConfirmation: Yup.string().required().min(8, 'Mínimo 8 caracteres').oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
    gender: Yup.string().required(),
    street: Yup.string().required(),
    number: Yup.string(),
    complement: Yup.string(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
    location: Yup.number().required('Campo Obrigatório').min(0, 'Selecione uma localização'),
    isIndividual: Yup.boolean().required(),

    corporateName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-corporate-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    tradingName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-trading-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    cpf: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (!isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )),
    cnpj: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )),
    phoneNumber1: Yup.string().required(),
    phoneNumberAttendent: Yup.string().required(),
    attendent: Yup.string().required(),
    executive: Yup.number().required(),
    franchiseFeeAmount: Yup.string().required('Campo obrigatório'),
    rebatePercentage: Yup.number().min(0, 'Insira um valor entre 0 e 100').max(100, 'Insira um valor entre 0 e 100').required('Campo obrigatório'),
    haveGracePeriod: Yup.boolean().required('Campo obrigatório'),
    finalDateFranchiseFee: Yup.date().when('haveGracePeriod', {
      is: true,
      then: Yup.date().nullable().required('Campo obrigatório'),
      otherwise: Yup.date().nullable(),
    }),
    franchiseCode: Yup.string().required('Campo obrigatório'),
    franchiseModel: Yup.string().required('Campo obrigatório'),
  });

  const validationEdit = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    gender: Yup.string().required(),
    neighborhood: Yup.string().required(),
    country: Yup.string().required(),
    street: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    postalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
    isIndividual: Yup.boolean().required(),

    corporateName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-corporate-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    tradingName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-trading-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    cpf: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (!isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )),
    cnpj: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )),
    newRebatePercentage: Yup.number().min(0, 'Insira um valor entre 0 e 100').max(100, 'Insira um valor entre 0 e 100'),
    haveGracePeriod: Yup.boolean().required('Campo obrigatório'),
    finalDateFranchiseFee: Yup.date().when('haveGracePeriod', {
      is: true,
      then: Yup.date().nullable().required('Campo obrigatório'),
      otherwise: Yup.date().nullable(),
    }),
    attendent: Yup.string().required(),
    executive: Yup.number().required(),
    nationality: Yup.string().required(),
    franchiseCode: Yup.string().required('Campo obrigatório'),
    franchiseModel: Yup.string().required('Campo obrigatório'),
  });

  const handleInsertSubmit = async (values: FormikValues) => {
    event?.preventDefault();

    // (CPF and CNPJ converted to number)
    const onlyNumbersCPFformikValue = onlyNumbers(`${values.cpf}`);
    const onlyNumbersCNPJformikValue = onlyNumbers(`${values.cnpj}`);

    // postal code (CEP converted to number)
    const onlyNumbersCEPformikValue = onlyNumbers(`${values.postalCode}`);

    // phone number (phone converted to number)
    const onlyNumbersPhoneNumber1formikValue = Mask.phoneToNumber(`${values.phoneNumber1}`);
    const onlyNumbersPhoneNumber2formikValue = Mask.phoneToNumber(`${values.phoneNumber2}`);
    const onlyNumbersPhoneNumberAttendentformikValue = Mask.phoneToNumber(`${values.phoneNumberAttendent}`);

    let createdAddressId = -1;
    let createdUserID = -1;
    let createdHostId: number = -1;
    let createdPartnerId: number = -1;

    try {
      const address: PostAddressObject = {
        city: values.city,
        street: values.street,
        complement: values.complement,
        country: values.country,
        neighborhood: values.neighborhood,
        number: values.number,
        postal_code: onlyNumbersCEPformikValue,
        state: values.state,
      }; // Objeto enviado a API /account/address

      const addressResponse = await postAddress(address);
      createdAddressId = addressResponse?.id || -1;

      const user: PostUserObject = {
        first_name: values.firstName,
        last_name: values.lastName,
        nickname: values.nickname,
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        phone_number1: values.phoneNumber1 !== '' ? onlyNumbersPhoneNumber1formikValue : '',
        phone_number2: values.phoneNumber2 !== '' ? onlyNumbersPhoneNumber2formikValue : '',
        gender: values.gender,
        birth_date: values.birthDate === null ? undefined : formatDate(values.birthDate),
        cpf: values.isIndividual ? onlyNumbersCPFformikValue : '',
        cnpj: !values.isIndividual ? onlyNumbersCNPJformikValue : '',
        corporate_name: values.corporateName,
        trading_name: values.tradingName,
        main_address: createdAddressId,
        main_role: 'Host',
        is_individual: values.isIndividual,
        pipedrive_person_id: values.pipedrivePersonId,
      }; // Objeto enviado a API /users

      createdUserID = await postUser(user);

      const host: PostHostObject = {
        reservation_royalties: values.reservationRoyalties / 100,
        cleaning_royalties: values.cleaningRoyalties / 100,
        is_host_ops: values.isHostOps,
        user: createdUserID,
        location: values.location,
        cleaning_commission_fee: values.cleaningCommissionFee,
        reservation_commission_fee: values.reservationCommissionFee,
        signed_contract: values.signedContractHost,
        contract_signing_justification: values.contractSigningJustification,
        franchise_code: values.franchiseCode,
        franchise_model: values.franchiseModel,
      }; // Objeto enviado a API /account/host/
      const partner: Partner = {
        marital_status: values.maritalStatus,
        nationality: values.nationality,
        profession: values.profession,
        signed_contract: values.signedContractPartner,
        user: createdUserID,
        attendant_name: values.attendent,
        attendant_phone_number: onlyNumbersPhoneNumberAttendentformikValue,
        executive: values.executive,
        spreadhsheet_link: values.link === '' ? 'Não informado' : values.link,
        pipedrive_partner_id: values.pipedrivePartnerId,
        pipedrive_person_id: values.pipedrivePersonId,
      }; // Objeto enviado a API /account/partner/

      createdHostId = await postHost(host);
      createdPartnerId = await postPartner(partner);

      if (createdHostId !== -1) {
        await postHostFranchiseFee({
          franchise_fee_amount: currencyToNumber(values.franchiseFeeAmount),
          rebate_percentage: values.rebatePercentage,
          have_grace_period: values.haveGracePeriod,
          final_date_franchise_fee: values.finalDateFranchiseFee === null
            ? undefined : formatDate(values.finalDateFranchiseFee as Date),
          month_ref: moment().format('YYYY-MM-DD'),
          host: createdHostId,
        });
      }

      navigate('/inserirdados');
      toast.success('Franqueado(a) criado com sucesso!');
    } catch (e: unknown) {
      if (createdAddressId !== -1) {
        await deleteAddress(createdAddressId);
      }
      if (createdUserID !== -1) {
        await deleteUser(createdUserID);
      }
      if (createdHostId !== -1) {
        await deleteHost(createdHostId);
      }
      if (createdPartnerId !== -1) {
        await deletePartner(createdPartnerId);
      }
      if (e instanceof Error) {
        toastErrorRequest(e);
      }
    }
  };

  function formatPhoneNumber(phone: string) {
    if (['null', 'undefined'].includes(`${phone}`)) return '';

    let phoneNumber = `${phone}`.replace(/\s+/g, '');

    if (!`${phone}`.startsWith('+')) {
      phoneNumber = `+55 ${phone}`;
    } else if (!phone.startsWith('+55')) {
      return phoneNumber;
    }

    return phoneNumber;
  }

  const handleEditSubmit = async (values: FormikValues) => {
    event?.preventDefault();
    // (CPF and CNPJ converted to number)
    const onlyNumbersCPFformikValue = onlyNumbers(`${values.cpf}`);
    const onlyNumbersCNPJformikValue = onlyNumbers(`${values.cnpj}`);
    const onlyNumbersCPFhostDetails = onlyNumbers(`${allHostDetails.cpf}`);
    const onlyNumbersCNPJhostDetails = onlyNumbers(`${allHostDetails.cnpj}`);

    // postal code (CEP converted to number)
    const onlyNumbersCEPformikValue = onlyNumbers(`${values.postalCode}`);
    const onlyNumbersCEPhostDetails = onlyNumbers(`${allHostDetails.postalCode}`);

    // phone number (phone converted to number)
    const onlyNumbersPhoneNumber1formikValue = Mask.phoneToNumber(`${values.phoneNumber1}`);
    const onlyNumbersPhoneNumber2formikValue = Mask.phoneToNumber(`${values.phoneNumber2}`);
    const onlyNumbersPhoneNumber1hostDetails = Mask.phoneToNumber(`${allHostDetails.phoneNumber1}`);
    const onlyNumbersPhoneNumber2hostDetails = Mask.phoneToNumber(`${allHostDetails.phoneNumber2}`);

    let patchedHost = false;
    let patchedUser = false;
    let patchedAddress = false;
    let patchedPartner = false;

    try {
      const host: PatchEditHost = {
        cleaning_royalties: values.cleaningRoyalties / 100,
        reservation_royalties: values.reservationRoyalties / 100,
        user: allHostDetails.user,
        is_host_ops: values.isHostOps === allHostDetails.isHostOps ? undefined : values.isHostOps,
        location: values.location === allHostDetails.location ? undefined : values.location,
        cleaning_commission_fee: values.cleaningCommissionFee,
        reservation_commission_fee: values.reservationCommissionFee,
        signed_contract: values.signedContractHost,
        contract_signing_justification: values.contractSigningJustification,
        franchise_code: values.franchiseCode,
        franchise_model: values.franchiseModel,
      };
      await patchEditHost(values.hostId || 0, host);
      patchedHost = true;

      const editedUser: PatchEditUser = {
        first_name: values.firstName,
        gender: values.gender,
        last_name: values.lastName,
        main_role: allHostDetails.mainRole,
        birth_date: (formatDate(values.birthDate || new Date()) === allHostDetails.birthDate)
            || values.birthDate?.getFullYear() === new Date().getFullYear()
            || handleAge(values.birthDate || new Date()) === 0
          ? undefined : formatDate(values.birthDate || new Date()),
        corporate_name: values.corporateName === allHostDetails.corporateName
          ? undefined : values.corporateName,
        cpf: values.isIndividual
          ? (([onlyNumbersCPFhostDetails, ''].includes(onlyNumbersCPFformikValue)) ? undefined : onlyNumbersCPFformikValue)
          : undefined,
        cnpj: !values.isIndividual
          ? (([onlyNumbersCNPJhostDetails, ''].includes(onlyNumbersCNPJformikValue)) ? undefined : onlyNumbersCNPJformikValue)
          : undefined,
        email: values.email === allHostDetails.email ? undefined : values.email,
        nickname: values.nickname === allHostDetails.nickname ? undefined : values.nickname,
        phone_number1: values.phoneNumber1 === allHostDetails.phoneNumber1
          ? undefined : onlyNumbersPhoneNumber1formikValue,
        phone_number2: values.phoneNumber2 === allHostDetails.phoneNumber2
          ? undefined : onlyNumbersPhoneNumber2formikValue,
        trading_name: values.tradingName === allHostDetails.tradingName
          ? undefined : values.tradingName,
        is_individual: values.isIndividual === allHostDetails.isIndividual
          ? undefined : values.isIndividual,
        pipedrive_person_id: values.pipedrivePersonId === allHostDetails.pipedrivePersonId
          ? undefined : values.pipedrivePersonId,
      };

      await patchEditUser(allHostDetails.user, editedUser);
      patchedUser = true;

      const address: PatchEditAddress = {
        neighborhood: values.neighborhood,
        country: values.country,
        street: values.street,
        number: values.number === allHostDetails.number ? undefined : values.number,
        complement: values.complement === allHostDetails.complement ? undefined : values.complement,
        city: values.city,
        state: values.state,
        postal_code: onlyNumbersCEPformikValue,
      }; // Objeto enviado a API /account/address

      await patchEditAddress(allHostDetails.mainAddress || 0, address);
      patchedAddress = true;

      const partner: PatchPartner = {
        attendant_name: values.attendent,
        executive: values.executive,
        attendant_phone_number: Mask.phoneToNumber(`${values.phoneNumberAttendent}`),
        marital_status: values.maritalStatus,
        nationality: values.nationality,
        profession: values.profession,
        signed_contract: values.signedContractPartner,
        spreadhsheet_link: values.link,
      };

      if (allPartnerDetails?.id) {
        await patchEditPartner(allPartnerDetails.id, partner);
      } else {
        await postEditPartner(partner);
      }
      patchedPartner = true;

      await postHostFranchiseFee({
        franchise_fee_amount: currencyToNumber(values.franchiseFeeAmount),
        rebate_percentage: `${values.newRebatePercentage}`.trim() === '' ? allHostDetails.rebatePercentage : values.newRebatePercentage,
        have_grace_period: values.haveGracePeriod,
        final_date_franchise_fee: values.finalDateFranchiseFee === null
          ? undefined : formatDate(values.finalDateFranchiseFee as Date),
        month_ref: moment().format('YYYY-MM-DD'),
        host: values.hostId,
      });

      navigate('/editardados');
      toast.success('Franqueado(a) editado com sucesso!');
    } catch (e: unknown) {
      if (patchedHost === true) {
        await patchEditHost(values.hostId || 0, {
          cleaning_royalties: allHostDetails.cleaningRoyalties,
          reservation_royalties: allHostDetails.reservationRoyalties,
          user: allHostDetails.user,
          is_host_ops: allHostDetails.isHostOps,
          location: allHostDetails.location,
          cleaning_commission_fee: allHostDetails.cleaningCommissionFee,
          reservation_commission_fee: allHostDetails.reservationCommissionFee,
          signed_contract: allHostDetails.signed_contract,
          contract_signing_justification: allHostDetails.contract_signing_justification,
          franchise_code: allHostDetails.franchiseCode,
          franchise_model: allHostDetails.franchiseModel,
        });
      }
      if (patchedUser === true) {
        await patchEditUser(allHostDetails.user, {
          first_name: allHostDetails.firstName,
          gender: allHostDetails.gender,
          last_name: allHostDetails.lastName,
          main_role: allHostDetails.mainRole,
          birth_date: allHostDetails.birthDate ? allHostDetails.birthDate : undefined,
          cnpj: onlyNumbersCNPJhostDetails,
          cpf: onlyNumbersCPFhostDetails,
          corporate_name: allHostDetails.corporateName,
          email: allHostDetails.email,
          id_number: allHostDetails.idNumber,
          is_individual: allHostDetails.isIndividual,
          main_address: allHostDetails.mainAddress,
          nickname: allHostDetails.nickname,
          phone_number1: onlyNumbersPhoneNumber1hostDetails,
          phone_number2: onlyNumbersPhoneNumber2hostDetails,
          postal_address: allHostDetails.postalAddress,
          trading_name: allHostDetails.tradingName,
          pipedrive_person_id: allHostDetails.pipedrivePersonId,
        });
      }
      if (patchedAddress === true) {
        await patchEditAddress(allHostDetails.mainAddress || 0, {
          neighborhood: allHostDetails.neighborhood,
          country: allHostDetails.country,
          street: allHostDetails.street,
          number: allHostDetails.number,
          complement: allHostDetails.complement,
          city: allHostDetails.city,
          state: values.state,
          postal_code: onlyNumbersCEPhostDetails,
        });
      }
      if (patchedPartner === true) {
        await patchEditPartner(allPartnerDetails.id, {
          attendant_name: allPartnerDetails.attendant_name,
          attendant_phone_number: allPartnerDetails.attendant_phone_number,
          executive: allPartnerDetails.executive,
          marital_status: allPartnerDetails.marital_status,
          nationality: allPartnerDetails.nationality,
          profession: allPartnerDetails.profession,
          signed_contract: allPartnerDetails.signed_contract,
          spreadhsheet_link: allPartnerDetails.spreadhsheet_link,
        });
      }

      if (e instanceof Error) {
        toastErrorRequest(e);
      }
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema: isEditingForm ? validationEdit : validationInsert,
    onSubmit: async (values) => {
      if (isEditingForm) {
        await handleEditSubmit(values);
      } else {
        await handleInsertSubmit(values);
      }
    },
  });

  const handleRegexFirstName = () => {
    const regexpAux = `^((?!${formik.values.firstName}).)*$`;
    const regexp = new RegExp(regexpAux, 'i');
    setPasswordFirstNameRegexp(regexp);
  };

  const handleRegexLastName = () => {
    const regexpAux = `^((?!${formik.values.lastName}).)*$`;
    const regexp = new RegExp(regexpAux, 'i');
    setPasswordLastNameRegexp(regexp);
  };

  const getFranchseFee = async (hostId: number) => {
    let franchiseFeeData = {} as FranchseFeeProps;

    const response: FranchseFeeProps = await getHostFranchiseFee({
      host_id: hostId,
    });

    if (Object.keys(response).length !== 0) {
      franchiseFeeData = {
        ...response,
        final_date_franchise_fee: `${response.final_date_franchise_fee}`.split('/').reverse()
          .join('-'),
        franchise_fee_amount: currencyToNumber(`${response.franchise_fee_amount}`),
        rebate_percentage: Number(`${response.rebate_percentage}`.replace('%', '')),
      };
    } else {
      franchiseFeeData = {
        ...response,
        host: hostId,
        have_grace_period: false,
        franchise_fee_amount: '',
        rebate_percentage: 0,
        month_ref: moment().format('YYYY-MM-DD'),
        created_at: null,
        updated_at: null,
      };
    }

    return franchiseFeeData;
  };

  const getHostDetails = useCallback(async (idHost: number) => {
    setLoad(true);
    try {
      let hostDetails: HostDetailsProps = {} as HostDetailsProps;
      const host = await getHostDetailsById(idHost);
      const user = await getUserDetailsById(host.user);
      let partner: GetPartnerDetailsById = {} as GetPartnerDetailsById;
      const franchiseFee = await getFranchseFee(idHost);

      if (user?.partner?.id) {
        partner = await getPartnerDetailsById(user?.partner?.id);
        partner.attendant_phone_number = formatPhoneNumber(partner.attendant_phone_number);
      }

      hostDetails = {
        cleaningRoyalties: host.cleaning_royalties,
        firstName: user.first_name,
        gender: user.gender,
        lastName: user.last_name,
        mainRole: user.main_role,
        reservationRoyalties: host.reservation_royalties,
        user: host.user,
        birthDate: user.birth_date,
        cnpj: Mask.cnpj(user.cnpj),
        cpf: Mask.cpf(user.cpf),
        corporateName: user.corporate_name,
        createdAt: host.created_at,
        email: user.email,
        hostId: host.id,
        idNumber: user.id_number,
        isHostOps: host.is_host_ops,
        isIndividual: user.is_individual,
        location: host.location,
        mainAddress: user.main_address.id,
        nickname: user.nickname,
        phoneNumber1: Mask.phone(user.phone_number1),
        phoneNumber2: Mask.phone(user.phone_number2),
        postalAddress: user.postal_address?.id || user.main_address.id,
        tradingName: user.trading_name,
        updatedAt: host.updated_at,
        city: user.main_address.city,
        country: user.main_address.country,
        neighborhood: user.main_address.neighborhood,
        postalCode: Mask.cep(user.main_address.postal_code),
        state: user.main_address.state,
        street: user.main_address.street,
        complement: user.main_address.complement,
        condominium: user.main_address.condominium,
        number: user.main_address.number,
        pipedrivePersonId: user.pipedrive_person_id,
        finalDateFranchiseFee: franchiseFee.final_date_franchise_fee === 'null'
          ? null
          : franchiseFee.final_date_franchise_fee as string,
        franchiseFeeAmount: franchiseFee.franchise_fee_amount as string,
        haveGracePeriod: franchiseFee.have_grace_period,
        newRebatePercentage: franchiseFee.rebate_percentage,
        rebatePercentage: franchiseFee.rebate_percentage,
        updatedAtFranchiseFee: franchiseFee.created_at as any,
        cleaningCommissionFee: host.cleaning_commission_fee,
        reservationCommissionFee: host.reservation_commission_fee,
        signed_contract: host.signed_contract,
        contract_signing_justification: host.contract_signing_justification,
        franchiseCode: host.franchise_code,
        franchiseModel: host.franchise_model,
      };

      setAllHostDetails(hostDetails);
      setAllPartnerDetails(partner);
    } catch (error) {
      toast.error("Erro ao buscar os detalhes do host. Por favor, tente novamente.");
    } finally {
      setLoad(false);
    }
  }, []);


  const handleChangePersonType = (manualType?: boolean) => {
    if (manualType) {
      formik.setFieldValue('isIndividual', manualType);
    } else {
      formik.setFieldValue('isIndividual', !formik.values.isIndividual);
    }
  };

  const checkSubmit = async () => {
    try {
      const validation = isEditingForm ? validationEdit : validationInsert;
      await validation.validate(formik.values, { abortEarly: false });
    } catch (errors) {
      formik.setSubmitting(false);

      Object.values(formik.errors).forEach((item) => {
        toast.error(`${item}`);
      });
    }
  };

  useEffect(() => {
    if (formik.isSubmitting) {
      checkSubmit();
    }
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (formik.values.firstName.length > 0) {
      handleRegexFirstName();
    }
  }, [formik.values.firstName]);

  useEffect(() => {
    if (formik.values.lastName.length > 0) {
      handleRegexLastName();
    }
  }, [formik.values.lastName]);

  const hostIdRef = useRef(formik.values.hostId);

  useEffect(() => {
    if (Number(hostIdRef.current) === Number(formik.values.hostId)) return;

    hostIdRef.current = formik.values.hostId;
    if (formik.values.hostId !== undefined) {
      getHostDetails(formik.values.hostId);
    } else {
      formik.resetForm();
    }
  }, [formik.values.hostId]);

  useEffect(() => {
    if (isEditingForm
      && (Object.keys(allHostDetails).length !== 0
      || Object.keys(allPartnerDetails).length !== 0)) {
      formik.setValues({
        ...initialValues,
        isIndividual: allHostDetails?.isIndividual !== undefined
          ? allHostDetails?.isIndividual : true,
        haveGracePeriod: allHostDetails?.haveGracePeriod || false,
        franchiseFeeAmount: `${allHostDetails?.franchiseFeeAmount || ''}`,
        rebatePercentage: allHostDetails?.rebatePercentage ? allHostDetails?.rebatePercentage : 0,
        newRebatePercentage: allHostDetails?.newRebatePercentage
          ? allHostDetails?.newRebatePercentage : 0,
        finalDateFranchiseFee: allHostDetails?.finalDateFranchiseFee
          ? moment(allHostDetails?.finalDateFranchiseFee).toDate() : null,
        updatedAtFranchiseFee: !isEditingForm ? null
          : allHostDetails.updatedAtFranchiseFee ? moment(allHostDetails.updatedAtFranchiseFee).format('DD/MM/YYYY') : null,
      });
    }
  }, [isEditingForm, allHostDetails, allPartnerDetails]);

  useEffect(() => {
    if (formik?.values?.birthDate
      && formatDate(formik?.values?.birthDate) !== allHostDetails.birthDate) {
      const age = handleAge(formik?.values?.birthDate);
      if ((age < 12 || age > 123) && age !== 0) {
        formik.setFieldError('birthDate', 'A idade não é válida!');
      }
    }
  }, [formik.values.birthDate]);

  useEffect(() => {
    if (!formik.values?.haveGracePeriod) {
      formik.setFieldValue('finalDateFranchiseFee', null);
    }
  }, [formik.values?.haveGracePeriod]);

  return (
    <Container>
      <div className="header">
        <div>
          <Title>{isEditingForm ? 'Editar anfitrião' : 'Novo anfitrião'}</Title>
        </div>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="formContainer">
          <FormAddUser
            isEditingForm={isEditingForm}
            formik={formik}
            handleChangePersonType={handleChangePersonType}
            hostDetailsPostalCode={allHostDetails.postalCode}
          />
        </div>
        <ButtonsContainer>
          <div>
            <FormButton
              type="button"
              variant="outlined"
              onClick={() => { navigate(isEditingForm ? '/editardados' : '/inserirdados'); }}
            >
              Cancelar
            </FormButton>
            <FormButton
              type="submit"
              disable={formik.isSubmitting}
            >
              Salvar
            </FormButton>

          </div>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default InsertHostData;
