import { Navbar, List, Item } from './styles';

interface ITabsNavigator {
  labels: Array<string>,
  indexActivedLabel?: number,
  setIndexActivedLabel?: Function,
}

const TabsNavigator = ({
  labels = [],
  indexActivedLabel = 0,
  setIndexActivedLabel = () => {},
}: ITabsNavigator) => (
  <Navbar>
    <List>
      {labels.map((label, index) => (
        <Item
          key={label}
          active={indexActivedLabel === index}
          onClick={() => setIndexActivedLabel(index)}
        >
          {label}
        </Item>
      ))}
    </List>
  </Navbar>
);

TabsNavigator.defaultProps = {
  indexActivedLabel: 0,
  setIndexActivedLabel: () => {},
};

export default TabsNavigator;
