import { onlyNumber } from '../Formatter';

export const currency = (value: string): string => {
  if (value === '') return '';

  return (new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })).format(Number(onlyNumber(value)) / 100);
};

export const currencyToNumber = (value: string): number => {
  const numberFormat = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  const options = numberFormat.resolvedOptions();

  if (options.currency) {
    const unformatted = value.replace(options.currency, '').replace(/[^\d,-]/g, '').replace(',', '.');
    return parseFloat(unformatted);
  }

  return 0;
};

export const cpf = (value: string) => onlyNumber(`${value}`)
  .replace(/(\d{11})(\d)/, '$1')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})$/, '$1-$2');

export const cnpj = (value: string) => (
  onlyNumber(`${value}`)
    .replace(/(\d{14})(\d)/, '$1')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d{1,2})$/, '$1-$2')
);

export const cpfOrCnpj = (value: string) => {
  if (`${value}`.length < 15) {
    return onlyNumber(`${value}`)
      .replace(/(\d{11})(\d)/, '$1')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  return onlyNumber(`${value}`)
    .replace(/(\d{14})(\d)/, '$1')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
};

export const cep = (value: string | number) => {
  const number = onlyNumber(`${value}`);
  if (number.length <= 3) {
    return number;
  }

  return number
    .substr(0, 8)
    .replace(/([0-9]{5})([0-9]{1,3})/, '$1-$2');
};

export const agency = (value: string) => {
  if (value.length > 0) {
    return onlyNumber(value).toString().slice(0, 4);
  }
  return value;
};

export const account = (value: string) => {
  const valueAux = value.split('-').join('');
  if (valueAux.length < 2) {
    return valueAux;
  }
  return `${valueAux.substr(0, valueAux.length - 1)}-${valueAux.substr(valueAux.length - 1)}`;
};

export const internationalPhone = (value: string | number) => onlyNumber(value)
  .replace(/^(\d{1,2})/, '+$1')
  .replace(/(\d{2})(\d{1})/, '$1 $2');

export const brPhone = (value: string) => onlyNumber(value)
  .replace(/^(\d{1,2})/, '($1')
  .replace(/(\d{2})(\d{1})/, '$1) $2')
  .replace(/(\+\d{2} \(\d{2})(\d)/, '$1) $2')
  .replace(/(\d{4})(\d{1,4})/, '$1-$2')
  .replace(/(\d{5})(\d{5})/, '$1-$2')
  .replace(/(-\d{5})\d+?$/, '$1')
  .replace(/(\d{4})-(\d{1})(\d{4})/, '$1$2-$3');

export const phone = (value: string | number) => onlyNumber(`${value}`)
  .replace(/^(\d{1,2})/, '+$1')
  .replace(/(\d{2})(\d{1})/, '$1 ($2')
  .replace(/(\+\d{2} \(\d{2})(\d)/, '$1) $2')
  .replace(/(\d{4})(\d{1,4})/, '$1-$2')
  .replace(/(\d{5})(\d{5})/, '$1-$2')
  .replace(/(-\d{5})\d+?$/, '$1')
  .replace(/(\d{4})-(\d{1})(\d{4})/, '$1$2-$3');

export const phoneToNumber = (value: string): string => `+${Number(onlyNumber(value))}`;

export const hour = (value: string | number) => onlyNumber(value)
  .replace(/(\d{4})(\d)/, '$1')
  .replace(/(\d{2})(\d)/, '$1:$2');

export const integerNumber = (value: string | number) => onlyNumber(value);
