import React from 'react';
import { UseFormik } from '../../../../../../../../utils/Formik/types';
import { Checkbox, Container, Section } from './style';

interface IRadioGroup {
  formik: UseFormik<any>;
  id: string;
  isRow?: boolean;
  options: {
    label: string;
    value: string;
  }[];
}

const RadioGroup = ({
  id,
  formik,
  options,
  isRow,
}: IRadioGroup) => (
  <Container isRow={isRow}>
    {options.map((option) => (
      <Section onClick={() => formik.setFieldValue(id, option.value)}>
        <Checkbox
          id={option.value}
          onChange={formik.handleChange}
          checked={formik.values[id] === option.value}
        />
        <label htmlFor={option.value}>{option.label}</label>
      </Section>
    ))}
  </Container>
);

RadioGroup.defaultProps = {
  isRow: false,
};

export default RadioGroup;
