import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 998;

  @media (max-width: 950px) {
    display: flex;
    width: 100%;
    height: 55px;
    justify-content: space-between;
    align-items: center;
    padding: 2px 15px;

    border: 1px solid #d9dcdf;

    /* -webkit-box-shadow: 2px 10px 39px -12px rgba(191,191,191,1);
    -moz-box-shadow: 2px 10px 39px -12px rgba(191,191,191,1);
    box-shadow: 2px 10px 39px -12px rgba(191,191,191,1); */

    background: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const Content = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Logo = styled.img<{
  isOwnerPropertyDetails?: boolean;
}>`
  width: ${({ isOwnerPropertyDetails }) => (isOwnerPropertyDetails ? '70%' : '40%')};
  margin-left: ${({ isOwnerPropertyDetails }) => (isOwnerPropertyDetails ? '-11rem' : '0')};
  cursor: pointer;
`;

export const Username = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-size: 1.1rem;
  font-weight: bold;
  text-align: flex-end;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
`;

export const Margin = styled.div`
  width: 100%;
  display: none;

  @media (max-width: 950px) {
    display: flex;
  }
  
  @media (max-width: 786px) {
    height: 1px;
    margin: 2rem 0;
  }
`;
