import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { IAccountSimpleHostsResponse } from '../../services/AccountSimpleHosts/types';
import { AccountOwnerOnboardingProps } from '../../services/Onboarding/types';

type PhasesData = {
  name: string;
  isActive: boolean;
  isConcluded: boolean;
};

interface OnboardingPageContextData {
  isOpenModal: boolean;
  setIsOpenModal: (arg0: boolean) => void;
  isOwnerCard: boolean;
  setIsOwnerCard: (arg0: boolean) => void;
  isPropertyInformationCard: boolean;
  setIsPropertyInformationCard: (arg0: boolean) => void;
  isDeploymentFeeValuesCard: boolean;
  setIsDeploymentFeeValuesCard: (arg0: boolean) => void;
  isCommissionsCard: boolean;
  setIsCommissionsCard: (arg0: boolean) => void;
  isDatesCard: boolean;
  setIsDatesCard: (arg0: boolean) => void;
  loadingOwner: boolean;
  setLoadingOwner: (arg0: boolean) => void;
  phases: PhasesData[];
  setPhases: (arg0: PhasesData[]) => void;
  handleClickPhase: (arg0: number) => void;
  isFinishCard: boolean;
  setIsFinishCard: (arg0: boolean) => void;
  listOwner: Array<AccountOwnerOnboardingProps>;
  setListOwner: (arg0: Array<AccountOwnerOnboardingProps>) => void;
  listOwnerFiltered: Array<AccountOwnerOnboardingProps>;
  setListOwnerFiltered: (arg0: Array<AccountOwnerOnboardingProps>) => void;
  listHost: Array<IAccountSimpleHostsResponse>;
  setListHost: (arg0: Array<IAccountSimpleHostsResponse>) => void;
}
interface OnboardingPageProps {
  children: ReactNode;
}

const OnboardingPageContext = createContext<OnboardingPageContextData>(
  {} as OnboardingPageContextData,
);

export function OnboardingPageProvider({ children }: OnboardingPageProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loadingOwner, setLoadingOwner] = useState<boolean>(false);
  const [isOwnerCard, setIsOwnerCard] = useState<boolean>(true);
  const [isFinishCard, setIsFinishCard] = useState<boolean>(true);
  const [isPropertyInformationCard, setIsPropertyInformationCard] = useState<boolean>(false);
  const [isDeploymentFeeValuesCard, setIsDeploymentFeeValuesCard] = useState<boolean>(false);
  const [isCommissionsCard, setIsCommissionsCard] = useState<boolean>(false);
  const [isDatesCard, setIsDatesCard] = useState<boolean>(false);
  const [listOwner, setListOwner] = useState<Array<AccountOwnerOnboardingProps>>([]);
  const [listOwnerFiltered, setListOwnerFiltered] = useState<Array<AccountOwnerOnboardingProps>>([]);
  const [listHost, setListHost] = useState<Array<IAccountSimpleHostsResponse>>([]);

  const [phases, setPhases] = useState<PhasesData[]>([
    {
      name: 'Proprietário PF/PJ',
      isActive: true,
      isConcluded: false,
    },
    {
      name: 'Informações do imóvel',
      isActive: false,
      isConcluded: false,
    },
    {
      name: 'Taxas e comissões',
      isActive: false,
      isConcluded: false,
    },
    {
      name: 'Final',
      isActive: false,
      isConcluded: false,
    },
  ]);

  function handleClickPhase(phasesId: number) {
    setPhases((existingPhases) => {
      let phasesAux = phases;
      const activeItemIndex = existingPhases.findIndex((phase) => phase.isActive === true);
      phasesAux = [
        ...existingPhases.slice(0, activeItemIndex),
        {
          ...existingPhases[activeItemIndex],
          isActive: false,
        },
        ...existingPhases.slice(activeItemIndex + 1),
      ];
      return [
        ...phasesAux.slice(0, phasesId),
        {
          ...phasesAux[phasesId],
          isActive: true,
        },
        ...phasesAux.slice(phasesId + 1),
      ];
    });
  }

  useEffect(() => {
    const activeItemIndex = phases.findIndex((phase) => phase.isActive === true);
    if (activeItemIndex === 0) {
      setIsPropertyInformationCard(false);
      setIsDeploymentFeeValuesCard(false);
      setIsCommissionsCard(false);
      setIsDatesCard(false);
      setIsOwnerCard(true);
      setIsFinishCard(false);
    } else if (activeItemIndex === 1) {
      setIsDeploymentFeeValuesCard(false);
      setIsCommissionsCard(false);
      setIsDatesCard(false);
      setIsOwnerCard(false);
      setIsPropertyInformationCard(true);
      setIsFinishCard(false);
    } else if (activeItemIndex === 2) {
      setIsCommissionsCard(false);
      setIsDatesCard(false);
      setIsOwnerCard(false);
      setIsPropertyInformationCard(false);
      setIsDeploymentFeeValuesCard(true);
      setIsFinishCard(false);
    } else if (activeItemIndex === 3) {
      setIsDeploymentFeeValuesCard(false);
      setIsDatesCard(false);
      setIsOwnerCard(false);
      setIsPropertyInformationCard(false);
      setIsCommissionsCard(true);
      setIsFinishCard(false);
    } else if (activeItemIndex === 4) {
      setIsCommissionsCard(false);
      setIsOwnerCard(false);
      setIsPropertyInformationCard(false);
      setIsDeploymentFeeValuesCard(false);
      setIsDatesCard(true);
      setIsFinishCard(false);
    } else if (activeItemIndex === 5) {
      setIsCommissionsCard(false);
      setIsOwnerCard(false);
      setIsPropertyInformationCard(false);
      setIsDeploymentFeeValuesCard(false);
      setIsDatesCard(false);
      setIsFinishCard(true);
    }
  }, [phases]);

  return (
    <OnboardingPageContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        isOwnerCard,
        setIsOwnerCard,
        isPropertyInformationCard,
        setIsPropertyInformationCard,
        setIsFinishCard,
        isFinishCard,
        isDeploymentFeeValuesCard,
        setIsDeploymentFeeValuesCard,
        isCommissionsCard,
        setIsCommissionsCard,
        isDatesCard,
        setIsDatesCard,
        loadingOwner,
        setLoadingOwner,
        phases,
        setPhases,
        handleClickPhase,
        listOwner,
        setListOwner,
        listOwnerFiltered,
        setListOwnerFiltered,
        listHost,
        setListHost,
      }}
    >
      {children}
    </OnboardingPageContext.Provider>
  );
}

export function useOnboardingPage() {
  const context = useContext(OnboardingPageContext);
  return context;
}
