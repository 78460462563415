import { actualSize } from '../../../../../../../../../hooks/useMobile/useMobile';

export const config = {
  height: actualSize === 'Desktop' ? '250px' : '320px',
  width: actualSize === 'Desktop' ? '470px' : '100%',

  image: {
    break: actualSize === 'Desktop' ? '151px' : '101px',
  },
};
