import { AnimatePresence } from 'framer-motion';

import Guest from './Guest';
import { Container } from './style';
import { usePreCheckin } from '../../../../../../context/PreCheckin/PreCheckin';

const GuestsList = () => {
  const { guests } = usePreCheckin();

  return (
    <Container>
      <AnimatePresence>
        {guests.main && <Guest isMain guest={guests.main} index={0} />}
        {guests?.list?.map((guest, index) => (
          <Guest key={guest.id} guest={guest} index={index} isMain={false} />
        ))}
      </AnimatePresence>
    </Container>
  );
};

export default GuestsList;
