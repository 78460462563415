import { FC, ReactNode } from 'react';
import Slider, { Settings } from 'react-slick';

interface ISimpleSlider {
  children: ReactNode,
  sliderRef: any,
  settings?: Settings
}

const SimpleSlider: FC<ISimpleSlider> = ({
  children,
  sliderRef = null,
  settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  },
}) => (
  <Slider ref={sliderRef} {...settings}>
    {children}
  </Slider>
);

export default SimpleSlider;
