import { motion } from 'framer-motion';
import React from 'react';

interface IEarningsProps {
  delay: number;
}

const Earnings = ({
  delay,
}: IEarningsProps) => (
  <motion.svg
    whileTap={{
      scale: 1.2,
    }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.g id="container">
      <motion.rect id="background" width="32" height="32" rx="10" fill="#E7EAF1" />
      <motion.path
        initial={{
          opacity: 0,
          scale: 0,
          y: 10,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          scale: 0,
          y: 10,
        }}
        transition={{ delay: 1.2 + delay * 0.1 }}
        id="icon"
        d="M20.4615 17.3846C20.2779 17.3846 20.1018 17.4576 19.972 17.5874C19.8422 17.7172 19.7692 17.8933 19.7692 18.0769C19.7692 18.2605 19.8422 18.4366 19.972 18.5665C20.1018 18.6963 20.2779 18.7692 20.4615 18.7692H22.5385C22.7221 18.7692 22.8982 18.6963 23.028 18.5665C23.1578 18.4366 23.2308 18.2605 23.2308 18.0769C23.2308 17.8933 23.1578 17.7172 23.028 17.5874C22.8982 17.4576 22.7221 17.3846 22.5385 17.3846H20.4615ZM8 9.07692C8 8.52609 8.21882 7.99782 8.60832 7.60832C8.99782 7.21882 9.52609 7 10.0769 7H21.6731C22.2698 7 22.8421 7.23705 23.2641 7.65901C23.686 8.08097 23.9231 8.65326 23.9231 9.25V9.94646C25.1332 10.3743 26 11.5284 26 12.8846V21.8846C26 22.7109 25.6718 23.5033 25.0875 24.0875C24.5033 24.6718 23.7109 25 22.8846 25H11.1154C10.2891 25 9.49672 24.6718 8.91248 24.0875C8.32823 23.5033 8 22.7109 8 21.8846V9.25H8.00692C8.00224 9.19243 7.99993 9.13469 8 9.07692ZM22.8846 11.1538H9.38462V21.8846C9.38462 22.3436 9.56697 22.7839 9.89155 23.1085C10.2161 23.433 10.6564 23.6154 11.1154 23.6154H22.8846C23.3436 23.6154 23.7839 23.433 24.1085 23.1085C24.433 22.7839 24.6154 22.3436 24.6154 21.8846V12.8846C24.6154 12.4256 24.433 11.9854 24.1085 11.6608C23.7839 11.3362 23.3436 11.1538 22.8846 11.1538ZM22.5385 9.25C22.5385 8.77231 22.1508 8.38462 21.6731 8.38462H10.0769C9.89331 8.38462 9.71722 8.45755 9.58739 8.58739C9.45756 8.71722 9.38462 8.89331 9.38462 9.07692C9.38462 9.26053 9.45756 9.43663 9.58739 9.56646C9.71722 9.69629 9.89331 9.76923 10.0769 9.76923H22.5385V9.25Z"
        fill="#B8C6DC"
      />
    </motion.g>
  </motion.svg>
);

export default Earnings;
