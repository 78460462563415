import { useContextSelector } from 'use-context-selector';
import { GeolocationContext } from '../../context/GeolocationContext/GeolocationContext';
import { getBrowser } from '../../context/GeolocationContext/utils';

export function useGeolocation() {
  const IPAddress = useContextSelector(GeolocationContext,
    (state) => state.IPAddress);

  const { latitude, longitude } = useContextSelector(GeolocationContext,
    (state) => state.coordinates);

  const setCoordinates = useContextSelector(GeolocationContext,
    (state) => state.setCoordinates);

  const browser = getBrowser();
  const os = navigator?.platform || 'Unknown OS';

  return {
    IPAddress,
    latitude,
    longitude,
    setCoordinates,
    browser,
    os,
  };
}
