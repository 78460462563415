import styled, { css, DefaultTheme } from 'styled-components';

const getCustomBackground = (
  theme: DefaultTheme,
  isToday: boolean,
  isOwner: boolean,
  isFinancialStatementCalendar: boolean,
) => {
  const color: string = `${theme.palette.grey._550.hex()}`;

  if ((isToday && !isOwner) || isFinancialStatementCalendar) {
    return 'linear-gradient(180deg, #0D4BD0 0%, #215789 100%)';
  }

  return color;
};

export const FastDataFowardModal = styled.div<{
  isToday: boolean,
  isOwner: boolean,
  isFinancialStatementCalendar: boolean,
}>`
  width: 100%;
  height: 100vh;
  z-index: 10000;
  position: absolute;
  ${(props) => !props.isOwner && css`
    top: 0;
    left: 0;
  `}

  .container {
    position: ${({ isOwner }) => (isOwner ? 'relative' : 'fixed')};
    width: ${({ isOwner }) => (isOwner ? '303px' : '303px')};
    height: auto;
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border: 1px solid #d9dcdf;
    border-radius: 10px;
    top: ${({ isOwner }) => (isOwner ? '22px' : '9vh')};
    right: ${({ isOwner }) => (isOwner ? '0' : '5.5vw')};
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    @media (max-width: 800px) {
      width: ${({ isOwner }) => isOwner && '510px'};
      left: ${({ isOwner }) => isOwner && '100px'};
      top: ${({ isOwner }) => isOwner && '-300px'};
    }
    @media (max-width: 650px) {
      width: ${({ isOwner }) => isOwner && '410px'};
      left: ${({ isOwner }) => isOwner && '40px'};
      top: ${({ isOwner }) => isOwner && '-300px'};
    }
    @media (max-width: 550px) {
      width: ${({ isOwner }) => isOwner && '310px'};
      left: ${({ isOwner }) => isOwner && '40px'};
      top: ${({ isOwner }) => isOwner && '-300px'};
    }
    @media (max-width: 415px) {
      width: ${({ isOwner }) => isOwner && '310px'};
      left: ${({ isOwner }) => isOwner && '10px'};
      top: ${({ isOwner }) => isOwner && '-300px'};
    }
    @media (max-width: 370px) {
      width: ${({ isOwner }) => isOwner && '290px'};
      left: ${({ isOwner }) => isOwner && '0'};
      top: ${({ isOwner }) => isOwner && '-300px'};
    }
    .content {
      padding: 1rem;

      .react-calendar {
        text-align: center;
        color: #949ba0;
        button {
          border: none;
          background-color: ${({ theme }) => theme.palette.white.main.hex()};
          cursor: pointer;
        }
        span {
          font-family: ${({ theme }) => theme.fonts.familys._6};
          font-weight: ${({ theme }) => theme.fonts.weights.bold};
          line-height: 20px;
        }
        abbr {
          font-family: ${({ theme }) => theme.fonts.familys._6};
        }
        .react-calendar__navigation {
          padding-bottom: 0.813rem;
          border-bottom: 1px solid
          ${({ theme }) => theme.palette.grey._400.hex()};
        }
        .react-calendar__navigation__next-button,
        .react-calendar__navigation__prev-button{
          padding: 0.5rem;
        }
        .react-calendar__navigation__label::first-letter {
          text-transform: uppercase;
        }
        .react-calendar__month-view__weekdays {
          margin: 0.5rem 0;
          font-family: ${({ theme }) => theme.fonts.familys._6};
          font-size: 14px;
          font-weight: ${({ theme }) => theme.fonts.weights.regular};

        }
        .tile-class {
          font-style: normal;
          font-size: 14px;
          font-weight: ${({ theme }) => theme.fonts.weights.regular};
          color: ${({ theme }) => theme.palette.blue._950.hex()};
          border: none;
          background-color: ${({ theme }) => theme.palette.white.main.hex()};
          margin: 0.4rem 0 0;
          cursor: pointer;
          height: 35px;
          width: 35px;
          transition: all 400ms;
          &:hover {
            color: ${({ theme }) => theme.palette.blue._950.hex()};
          }
        }
        .react-calendar__tile:enabled:hover {
          background: ${({ theme }) => theme.palette.grey._400.hex()};
          border-radius: 10px;
        }
        .react-calendar__tile--active {
          background: linear-gradient(180deg, #0D4BD0 0%, #215789 100%);
          color: white;
          border-radius: 10px;
          padding: 0.5rem;
          border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
        }

        .react-calendar__tile--now {
          background: ${({
    theme, isToday, isOwner, isFinancialStatementCalendar,
  }) => getCustomBackground(
    theme, isToday, isOwner, isFinancialStatementCalendar,
  )};
           
          color: white;
          border-radius: 10px;
        }
        .react-calendar__tile--hasActive{
          background: linear-gradient(180deg, #0D4BD0 0%, #215789 100%);
          color: white;
          border-radius: 10px;
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          color: ${({ theme }) => theme.palette.grey._450.hex()};
        }
      }
    }
    .today {
      margin: 5px 16px 0;
      padding-top: 1rem;
      border-top: 1px solid #d9dcdf;
      button {
        height: 30px;
        width: 77px;
        background: linear-gradient(180deg, #0D4BD0 0%, #215789 100%);
        border: 1px solid #d9dcdf;
        box-sizing: border-box;
        border-radius: 10px;
        font-family: ${({ theme }) => theme.fonts.familys._6};
        font-weight: ${({ theme }) => theme.fonts.weights.bold};
        cursor: pointer;
        margin-bottom: 1rem;
        transition: all 500ms;
        &:hover {
          border: 1px solid black;
        }
      }
    }
  }
`;
