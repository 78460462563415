import { compareList } from '../../utils/Sorting';
import { Amenities, PropertyAmenitiesValuesType } from './types';

export const initialValuesAmenities = [
  {
    name: 'Básico?',
    key: 'basic',
    value: false,
  },
  {
    name: 'Ar condicionado?',
    key: 'air_conditioning',
    value: false,
  },
  {
    name: 'Produtos de limpeza?',
    key: 'cleaning_products',
    value: false,
  },
  {
    name: 'Utensílios básicos de cozinha?',
    key: 'kitchen_basics',
    value: false,
  },
  {
    name: 'Espaço exclusivo para trabalho?',
    key: 'exclusive_work_space',
    value: false,
  },
  {
    name: 'Louça?',
    key: 'dishware',
    value: false,
  },
  {
    name: 'Secador de roupas?',
    key: 'clothes_dryer',
    value: false,
  },
  {
    name: 'Secador de cabelo?',
    key: 'hairdryer',
    value: false,
  },
  {
    name: 'Aquecimento central?',
    key: 'central_heating',
    value: false,
  },
  {
    name: 'Jacuzzi?',
    key: 'jacuzzi',
    value: false,
  },
  {
    name: 'Cozinha?',
    key: 'kitchen',
    value: false,
  },
  {
    name: 'Piscina?',
    key: 'swimming_pool',
    value: false,
  },
  {
    name: 'Televisão?',
    key: 'tv',
    value: false,
  },
  {
    name: 'Wi-Fi?',
    key: 'wifi',
    value: false,
  },
  {
    name: 'Banheira?',
    key: 'bathtube',
    value: false,
  },
  {
    name: 'Bidê?',
    key: 'bidet',
    value: false,
  },
  {
    name: 'Sabonete?',
    key: 'bath_soap',
    value: false,
  },
  {
    name: 'Condicionador?',
    key: 'conditioner',
    value: false,
  },
  {
    name: 'Água quente?',
    key: 'hot_water',
    value: false,
  },
  {
    name: 'Chuveiro externo?',
    key: 'outdoor_shower',
    value: false,
  },
  {
    name: 'Shampoo?',
    key: 'shampoo',
    value: false,
  },
  {
    name: 'Gel de banho?',
    key: 'shower_gel',
    value: false,
  },
  {
    name: 'Roupa de cama?',
    key: 'bedding',
    value: false,
  },
  {
    name: 'Lugar para guardar roupas?',
    key: 'place_to_store_clothes',
    value: false,
  },
  {
    name: 'Varal de roupas?',
    key: 'clothes_line',
    value: false,
  },
  {
    name: 'Cobertores e travesseiros extra?',
    key: 'extra_blankets_and_pillows',
    value: false,
  },
  {
    name: 'Cabides?',
    key: 'hangers',
    value: false,
  },
  {
    name: 'Ferro de passar roupa?',
    key: 'iron',
    value: false,
  },
  {
    name: 'Mosquiteiro?',
    key: 'mosquito_net',
    value: false,
  },
  {
    name: 'Cortinas blackout?',
    key: 'blackout_curtains',
    value: false,
  }, {
    name: 'Seguro?',
    key: 'insurance',
    value: false,
  },
  {
    name: 'TV por assiantura?',
    key: 'tv_subscription',
    value: false,
  },
  {
    name: 'Acesso à internet?',
    key: 'internet_access',
    value: false,
  },
  {
    name: 'Video game?',
    key: 'video_game',
    value: false,
  },
  {
    name: 'Piano?',
    key: 'piano',
    value: false,
  },
  {
    name: 'Tênis de mesa?',
    key: 'table_tennis_table',
    value: false,
  },
  {
    name: 'Mesa de sinuca?',
    key: 'snooker_table',
    value: false,
  },
  {
    name: 'Gravador?',
    key: 'recorder',
    value: false,
  },
  {
    name: 'Sistema de som?',
    key: 'sound_system',
    value: false,
  },
  {
    name: 'Banheira para bebê?',
    key: 'baby_bath',
    value: false,
  },
  {
    name: 'Babá eletrônica?',
    key: 'eletronic_sitter',
    value: false,
  },
  {
    name: 'Portão de segurança para bebês?',
    key: 'baby_security_gate',
    value: false,
  },
  {
    name: 'Recomendação de babá?',
    key: 'babysitter_recommendation',
    value: false,
  },
  {
    name: 'Jogos de tabuleiro?',
    key: 'board_games',
    value: false,
  },
  {
    name: 'Trocador?',
    key: 'changing_mat',
    value: false,
  },
  {
    name: 'Livros e brinquedos infantis?',
    key: 'children_books_and_toys',
    value: false,
  },
  {
    name: 'Louça infantil?',
    key: 'children_dishware',
    value: false,
  },
  {
    name: 'Berço?',
    key: 'baby_crib',
    value: false,
  },
  {
    name: 'Tela de fogo?',
    key: 'fire_screen',
    value: false,
  },
  {
    name: 'Cadeira alta para criança?',
    key: 'high_chair',
    value: false,
  },
  {
    name: 'Protetores de tomada?',
    key: 'socket_protectors',
    value: false,
  },
  {
    name: 'Cercadinho portátil?',
    key: 'portable_playpen',
    value: false,
  },
  {
    name: 'Protetor de canto de mesa?',
    key: 'table_croner_protector',
    value: false,
  },
  {
    name: 'Rede de proteção para janela?',
    key: 'window_net_protector',
    value: false,
  },
  {
    name: 'Ventilador de teto?',
    key: 'ceiling_fan',
    value: false,
  },
  {
    name: 'Lareira interna?',
    key: 'indoor_fireplace',
    value: false,
  },
  {
    name: 'Ventilador portátil?',
    key: 'portable_fan',
    value: false,
  },
  {
    name: 'Alarme de monóxido de carbono?',
    key: 'carbon_monoxide_alarm',
    value: false,
  },
  {
    name: 'Extintor de incêndio?',
    key: 'fire_extinguisher',
    value: false,
  },
  {
    name: 'Kit de primeiros socorros?',
    key: 'first_aid_kit',
    value: false,
  },
  {
    name: 'Alarme de incêndio?',
    key: 'fire_alarm',
    value: false,
  },
  {
    name: 'Wi-Fi portátil?',
    key: 'portable_wifi',
    value: false,
  },
  {
    name: 'Folha de confeitaria?',
    key: 'confectionery_sheet',
    value: false,
  },
  {
    name: 'Utensílios para churrasco?',
    key: 'barbecue_utensils',
    value: false,
  },
  {
    name: 'Máquina de pão?',
    key: 'breading_machine',
    value: false,
  },
  {
    name: 'Máquina de café?',
    key: 'coffee_machine',
    value: false,
  },
  {
    name: 'Mesa de jantar?',
    key: 'dining_table',
    value: false,
  },
  {
    name: 'Lava-louças?',
    key: 'dishwasher',
    value: false,
  },
  {
    name: 'Congelador?',
    key: 'freezer',
    value: false,
  },
  {
    name: 'Chaleira?',
    key: 'kettle',
    value: false,
  },
  {
    name: 'Máquina de café Keurig?',
    key: 'keurig_coffee_machine',
    value: false,
  },
  {
    name: 'Micro-ondas?',
    key: 'microwave',
    value: false,
  },
  {
    name: 'Frigobar?',
    key: 'minibar',
    value: false,
  },
  {
    name: 'Máquina de expresso?',
    key: 'espresso_machine',
    value: false,
  },
  {
    name: 'Forno?',
    key: 'oven',
    value: false,
  },
  {
    name: 'Cafeteira de filtro?',
    key: 'filter_coffee_maker',
    value: false,
  },
  {
    name: 'Refrigerador?',
    key: 'refrigerator',
    value: false,
  },
  {
    name: 'Panela elétrica?',
    key: 'eletric_pot',
    value: false,
  },
  {
    name: 'Fogão?',
    key: 'stove',
    value: false,
  },
  {
    name: 'Torradeira?',
    key: 'toaster',
    value: false,
  },
  {
    name: 'Compactador de lixo?',
    key: 'compactor_trash_can',
    value: false,
  },
  {
    name: 'Taças de vinho?',
    key: 'wine_glasses',
    value: false,
  },
  {
    name: 'Vista do mar?',
    key: 'sea_view',
    value: false,
  },
  {
    name: 'Acesso ao lago?',
    key: 'lake_access',
    value: false,
  },
  {
    name: 'Lavanderia ao redor?',
    key: 'washhouse_around',
    value: false,
  },
  {
    name: 'Entrada privada?',
    key: 'private_entrance',
    value: false,
  },
  {
    name: 'Entrada e saída de esqui?',
    key: 'ski_entrance_and_exit',
    value: false,
  },
  {
    name: 'Vista para águas?',
    key: 'waters_view',
    value: false,
  },
  {
    name: 'Churrasqueira?',
    key: 'barbecue',
    value: false,
  },
  {
    name: 'Utensílios básicos para praia?',
    key: 'beach_basics',
    value: false,
  },
  {
    name: 'Bicicletas?',
    key: 'bikes',
    value: false,
  },
  {
    name: 'Rampa para barcos?',
    key: 'boat_ramp',
    value: false,
  },
  {
    name: 'Fogueira?',
    key: 'fire_pit',
    value: false,
  },
  {
    name: 'Jardim ou quintal?',
    key: 'garden_or_yard',
    value: false,
  },
  {
    name: 'Caiaque?',
    key: 'kayak',
    value: false,
  },
  {
    name: 'Área de jantar ao ar livre?',
    key: 'outdoor_dining_area',
    value: false,
  },
  {
    name: 'Móveis no exterior?',
    key: 'outdoor_furniture',
    value: false,
  },
  {
    name: 'Pátio ou varanda?',
    key: 'courtyard_or_balcony',
    value: false,
  },
  {
    name: 'Carregador para carro elétrico?',
    key: 'car_eletric_charger',
    value: false,
  },
  {
    name: 'Estacionamento?',
    key: 'parking_lot',
    value: false,
  },
  {
    name: 'Estacionamento de rua gratuito?',
    key: 'free_street_parking_lot',
    value: false,
  },
  {
    name: 'Estacionamento de rua pago?',
    key: 'paid_street_parking_lot',
    value: false,
  },
  {
    name: 'Estacionamento interno pago?',
    key: 'paid_inside_parking_lot',
    value: false,
  },
  {
    name: 'Academia?',
    key: 'gym',
    value: false,
  },
  {
    name: 'Sauna?',
    key: 'steam_room',
    value: false,
  },
  {
    name: 'Térreo?',
    key: 'ground_floor',
    value: false,
  },
  {
    name: 'Café da manhã?',
    key: 'breakfest',
    value: false,
  },
  {
    name: 'Limpeza antes do checkout?',
    key: 'cleaning_before_checkout',
    value: false,
  },
  {
    name: 'Aceita deixar malas?',
    key: 'leave_bags_allowed',
    value: false,
  },
  {
    name: 'Máquina de lavar?',
    key: 'washing_machine',
    value: false,
  },
].sort((a, b) => compareList(a.name, b.name));

export const initialValuesForm = {
  basic: false,
  air_conditioning: false,
  cleaning_products: false,
  kitchen_basics: false,
  exclusive_work_space: false,
  dishware: false,
  clothes_dryer: false,
  hairdryer: false,
  central_heating: false,
  jacuzzi: false,
  kitchen: false,
  swimming_pool: false,
  tv: false,
  wifi: false,
  bathtube: false,
  bidet: false,
  bath_soap: false,
  conditioner: false,
  hot_water: false,
  outdoor_shower: false,
  shampoo: false,
  shower_gel: false,
  bedding: false,
  place_to_store_clothes: false,
  clothes_line: false,
  extra_blankets_and_pillows: false,
  hangers: false,
  iron: false,
  mosquito_net: false,
  blackout_curtains: false,
  insurance: false,
  tv_subscription: false,
  internet_access: false,
  video_game: false,
  piano: false,
  table_tennis_table: false,
  snooker_table: false,
  recorder: false,
  sound_system: false,
  baby_bath: false,
  eletronic_sitter: false,
  baby_security_gate: false,
  babysitter_recommendation: false,
  board_games: false,
  changing_mat: false,
  children_books_and_toys: false,
  children_dishware: false,
  baby_crib: false,
  fire_screen: false,
  high_chair: false,
  socket_protectors: false,
  portable_playpen: false,
  table_croner_protector: false,
  window_net_protector: false,
  ceiling_fan: false,
  indoor_fireplace: false,
  portable_fan: false,
  carbon_monoxide_alarm: false,
  fire_extinguisher: false,
  first_aid_kit: false,
  fire_alarm: false,
  portable_wifi: false,
  confectionery_sheet: false,
  barbecue_utensils: false,
  breading_machine: false,
  coffee_machine: false,
  dining_table: false,
  dishwasher: false,
  freezer: false,
  kettle: false,
  keurig_coffee_machine: false,
  microwave: false,
  minibar: false,
  espresso_machine: false,
  oven: false,
  filter_coffee_maker: false,
  refrigerator: false,
  eletric_pot: false,
  stove: false,
  toaster: false,
  compactor_trash_can: false,
  wine_glasses: false,
  sea_view: false,
  lake_access: false,
  washhouse_around: false,
  private_entrance: false,
  ski_entrance_and_exit: false,
  waters_view: false,
  barbecue: false,
  beach_basics: false,
  bikes: false,
  boat_ramp: false,
  fire_pit: false,
  garden_or_yard: false,
  kayak: false,
  outdoor_dining_area: false,
  outdoor_furniture: false,
  courtyard_or_balcony: false,
  car_eletric_charger: false,
  parking_lot: false,
  free_street_parking_lot: false,
  paid_street_parking_lot: false,
  paid_inside_parking_lot: false,
  gym: false,
  steam_room: false,
  ground_floor: false,
  breakfest: false,
  cleaning_before_checkout: false,
  leave_bags_allowed: false,
  washing_machine: false,
};

export const handleUpdateFormPropertyAmenities = (allAmenities: Amenities[]) => {
  const propertyAmenities: PropertyAmenitiesValuesType = {
    ...initialValuesForm,
  };
  allAmenities.forEach((amenitie) => {
    switch (amenitie.key) {
      case 'basic':
        propertyAmenities.basic = amenitie.value;
        break;
      case 'air_conditioning':
        propertyAmenities.air_conditioning = amenitie.value;
        break;
      case 'cleaning_products':
        propertyAmenities.cleaning_products = amenitie.value;
        break;
      case 'kitchen_basics':
        propertyAmenities.kitchen_basics = amenitie.value;
        break;
      case 'exclusive_work_space':
        propertyAmenities.exclusive_work_space = amenitie.value;
        break;
      case 'dishware':
        propertyAmenities.dishware = amenitie.value;
        break;
      case 'clothes_dryer':
        propertyAmenities.clothes_dryer = amenitie.value;
        break;
      case 'hairdryer':
        propertyAmenities.hairdryer = amenitie.value;
        break;
      case 'central_heating':
        propertyAmenities.central_heating = amenitie.value;
        break;
      case 'jacuzzi':
        propertyAmenities.jacuzzi = amenitie.value;
        break;
      case 'kitchen':
        propertyAmenities.kitchen = amenitie.value;
        break;
      case 'swimming_pool':
        propertyAmenities.swimming_pool = amenitie.value;
        break;
      case 'tv':
        propertyAmenities.tv = amenitie.value;
        break;
      case 'wifi':
        propertyAmenities.wifi = amenitie.value;
        break;
      case 'bathtube':
        propertyAmenities.bathtube = amenitie.value;
        break;
      case 'bidet':
        propertyAmenities.bidet = amenitie.value;
        break;
      case 'bath_soap':
        propertyAmenities.bath_soap = amenitie.value;
        break;
      case 'conditioner':
        propertyAmenities.conditioner = amenitie.value;
        break;
      case 'hot_water':
        propertyAmenities.hot_water = amenitie.value;
        break;
      case 'outdoor_shower':
        propertyAmenities.outdoor_shower = amenitie.value;
        break;
      case 'shampoo':
        propertyAmenities.shampoo = amenitie.value;
        break;
      case 'shower_gel':
        propertyAmenities.shower_gel = amenitie.value;
        break;
      case 'bedding':
        propertyAmenities.bedding = amenitie.value;
        break;
      case 'place_to_store_clothes':
        propertyAmenities.place_to_store_clothes = amenitie.value;
        break;
      case 'clothes_line':
        propertyAmenities.clothes_line = amenitie.value;
        break;
      case 'extra_blankets_and_pillows':
        propertyAmenities.extra_blankets_and_pillows = amenitie.value;
        break;
      case 'hangers':
        propertyAmenities.hangers = amenitie.value;
        break;
      case 'iron':
        propertyAmenities.iron = amenitie.value;
        break;
      case 'mosquito_net':
        propertyAmenities.mosquito_net = amenitie.value;
        break;
      case 'blackout_curtains':
        propertyAmenities.blackout_curtains = amenitie.value;
        break;
      case 'insurance':
        propertyAmenities.insurance = amenitie.value;
        break;
      case 'tv_subscription':
        propertyAmenities.tv_subscription = amenitie.value;
        break;
      case 'internet_access':
        propertyAmenities.internet_access = amenitie.value;
        break;
      case 'video_game':
        propertyAmenities.video_game = amenitie.value;
        break;
      case 'piano':
        propertyAmenities.piano = amenitie.value;
        break;
      case 'table_tennis_table':
        propertyAmenities.table_tennis_table = amenitie.value;
        break;
      case 'snooker_table':
        propertyAmenities.snooker_table = amenitie.value;
        break;
      case 'recorder':
        propertyAmenities.recorder = amenitie.value;
        break;
      case 'sound_system':
        propertyAmenities.sound_system = amenitie.value;
        break;
      case 'baby_bath':
        propertyAmenities.baby_bath = amenitie.value;
        break;
      case 'eletronic_sitter':
        propertyAmenities.eletronic_sitter = amenitie.value;
        break;
      case 'baby_security_gate':
        propertyAmenities.baby_security_gate = amenitie.value;
        break;
      case 'babysitter_recommendation':
        propertyAmenities.babysitter_recommendation = amenitie.value;
        break;
      case 'board_games':
        propertyAmenities.board_games = amenitie.value;
        break;
      case 'changing_mat':
        propertyAmenities.changing_mat = amenitie.value;
        break;
      case 'children_books_and_toys':
        propertyAmenities.children_books_and_toys = amenitie.value;
        break;
      case 'children_dishware':
        propertyAmenities.children_dishware = amenitie.value;
        break;
      case 'fire_screen':
        propertyAmenities.fire_screen = amenitie.value;
        break;
      case 'high_chair':
        propertyAmenities.high_chair = amenitie.value;
        break;
      case 'socket_protectors':
        propertyAmenities.socket_protectors = amenitie.value;
        break;
      case 'portable_playpen':
        propertyAmenities.portable_playpen = amenitie.value;
        break;
      case 'table_croner_protector':
        propertyAmenities.table_croner_protector = amenitie.value;
        break;
      case 'window_net_protector':
        propertyAmenities.window_net_protector = amenitie.value;
        break;
      case 'ceiling_fan':
        propertyAmenities.ceiling_fan = amenitie.value;
        break;
      case 'indoor_fireplace':
        propertyAmenities.indoor_fireplace = amenitie.value;
        break;
      case 'portable_fan':
        propertyAmenities.portable_fan = amenitie.value;
        break;
      case 'carbon_monoxide_alarm':
        propertyAmenities.carbon_monoxide_alarm = amenitie.value;
        break;
      case 'fire_extinguisher':
        propertyAmenities.fire_extinguisher = amenitie.value;
        break;
      case 'first_aid_kit':
        propertyAmenities.first_aid_kit = amenitie.value;
        break;
      case 'fire_alarm':
        propertyAmenities.fire_alarm = amenitie.value;
        break;
      case 'portable_wifi':
        propertyAmenities.portable_wifi = amenitie.value;
        break;
      case 'confectionery_sheet':
        propertyAmenities.confectionery_sheet = amenitie.value;
        break;
      case 'barbecue_utensils':
        propertyAmenities.barbecue_utensils = amenitie.value;
        break;
      case 'breading_machine':
        propertyAmenities.breading_machine = amenitie.value;
        break;
      case 'coffee_machine':
        propertyAmenities.coffee_machine = amenitie.value;
        break;
      case 'dining_table':
        propertyAmenities.dining_table = amenitie.value;
        break;
      case 'dishwasher':
        propertyAmenities.dishwasher = amenitie.value;
        break;
      case 'freezer':
        propertyAmenities.freezer = amenitie.value;
        break;
      case 'kettle':
        propertyAmenities.kettle = amenitie.value;
        break;
      case 'keurig_coffee_machine':
        propertyAmenities.keurig_coffee_machine = amenitie.value;
        break;
      case 'microwave':
        propertyAmenities.microwave = amenitie.value;
        break;
      case 'minibar':
        propertyAmenities.minibar = amenitie.value;
        break;
      case 'espresso_machine':
        propertyAmenities.espresso_machine = amenitie.value;
        break;
      case 'oven':
        propertyAmenities.oven = amenitie.value;
        break;
      case 'filter_coffee_maker':
        propertyAmenities.filter_coffee_maker = amenitie.value;
        break;
      case 'refrigerator':
        propertyAmenities.refrigerator = amenitie.value;
        break;
      case 'eletric_pot':
        propertyAmenities.eletric_pot = amenitie.value;
        break;
      case 'stove':
        propertyAmenities.stove = amenitie.value;
        break;
      case 'toaster':
        propertyAmenities.toaster = amenitie.value;
        break;
      case 'compactor_trash_can':
        propertyAmenities.compactor_trash_can = amenitie.value;
        break;
      case 'wine_glasses':
        propertyAmenities.wine_glasses = amenitie.value;
        break;
      case 'sea_view':
        propertyAmenities.sea_view = amenitie.value;
        break;
      case 'lake_access':
        propertyAmenities.lake_access = amenitie.value;
        break;
      case 'washhouse_around':
        propertyAmenities.washhouse_around = amenitie.value;
        break;
      case 'private_entrance':
        propertyAmenities.private_entrance = amenitie.value;
        break;
      case 'ski_entrance_and_exit':
        propertyAmenities.ski_entrance_and_exit = amenitie.value;
        break;
      case 'waters_view':
        propertyAmenities.waters_view = amenitie.value;
        break;
      case 'barbecue':
        propertyAmenities.barbecue = amenitie.value;
        break;
      case 'beach_basics':
        propertyAmenities.beach_basics = amenitie.value;
        break;
      case 'bikes':
        propertyAmenities.bikes = amenitie.value;
        break;
      case 'boat_ramp':
        propertyAmenities.boat_ramp = amenitie.value;
        break;
      case 'fire_pit':
        propertyAmenities.fire_pit = amenitie.value;
        break;
      case 'garden_or_yard':
        propertyAmenities.garden_or_yard = amenitie.value;
        break;
      case 'kayak':
        propertyAmenities.kayak = amenitie.value;
        break;
      case 'outdoor_dining_area':
        propertyAmenities.outdoor_dining_area = amenitie.value;
        break;
      case 'outdoor_furniture':
        propertyAmenities.outdoor_furniture = amenitie.value;
        break;
      case 'courtyard_or_balcony':
        propertyAmenities.courtyard_or_balcony = amenitie.value;
        break;
      case 'car_eletric_charger':
        propertyAmenities.car_eletric_charger = amenitie.value;
        break;
      case 'parking_lot':
        propertyAmenities.parking_lot = amenitie.value;
        break;
      case 'free_street_parking_lot':
        propertyAmenities.free_street_parking_lot = amenitie.value;
        break;
      case 'paid_street_parking_lot':
        propertyAmenities.paid_street_parking_lot = amenitie.value;
        break;
      case 'paid_inside_parking_lot':
        propertyAmenities.paid_inside_parking_lot = amenitie.value;
        break;
      case 'gym':
        propertyAmenities.gym = amenitie.value;
        break;
      case 'steam_room':
        propertyAmenities.steam_room = amenitie.value;
        break;
      case 'ground_floor':
        propertyAmenities.ground_floor = amenitie.value;
        break;
      case 'breakfest':
        propertyAmenities.breakfest = amenitie.value;
        break;
      case 'cleaning_before_checkout':
        propertyAmenities.cleaning_before_checkout = amenitie.value;
        break;
      case 'leave_bags_allowed':
        propertyAmenities.leave_bags_allowed = amenitie.value;
        break;
      case 'washing_machine':
        propertyAmenities.washing_machine = amenitie.value;
        break;
      case 'baby_crib':
        propertyAmenities.baby_crib = amenitie.value;
        break;
      default:
        break;
    }
  });
  return propertyAmenities;
};
