import request from '../request';
import { EnterpriseResponseProps, PostEnterprise } from './types';
import { parseEnterpriseUnits } from './utils';

export const getEnterprises = async () => {
  const response = await request.get<EnterpriseResponseProps[]>('/channel_manager/enterprise/');
  return response.data;
};

export const postEnterprise = async (spot: PostEnterprise) => {
  const response = await request.post('/channel_manager/enterprise/', {
    enterprise_name: spot.enterprise_name,
    unit_range: parseEnterpriseUnits(spot.enterprise_units),
  });

  return response.data;
};
