import { useContextSelector } from 'use-context-selector';
import { GuestDamageNegotiationContext } from '../../../context/GuestDamage/GuestDamageNegotiationsContext';

export function useGuestDamageNegotiation() {
  const guestDamageNegotiations = useContextSelector(GuestDamageNegotiationContext, (state) => state
    .guestDamageNegotiations);
  const setGuestDamageNegotiations = useContextSelector(GuestDamageNegotiationContext,
    (state) => state.setGuestDamageNegotiations);
  const selectedGuestDamageNegotiation = useContextSelector(GuestDamageNegotiationContext,
    (state) => state.selectedGuestDamageNegotiation);
  const setSelectedGuestDamageNegotiation = useContextSelector(GuestDamageNegotiationContext,
    (state) => state.setSelectedGuestDamageNegotiation);
  const isLoading = useContextSelector(GuestDamageNegotiationContext, (state) => state
    .isLoading);
  const setIsLoading = useContextSelector(GuestDamageNegotiationContext,
    (state) => state.setIsLoading);

  return {
    guestDamageNegotiations,
    setGuestDamageNegotiations,
    selectedGuestDamageNegotiation,
    setSelectedGuestDamageNegotiation,
    isLoading,
    setIsLoading,
  };
}
