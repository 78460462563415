import styled from 'styled-components';
import { MAX_SIZE_MOBILE } from '../../utils';

export const HeaderContainer = styled.div``;

export const HeaderContent = styled.div`
  width: 100%;
  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  @media (min-width: ${MAX_SIZE_MOBILE + 1}px) {    
    margin-bottom: 24px;
    button {
      display: none;
    }
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  line-height: 24px;
  margin: 8px 0 0 0px;
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 20px;
    line-height: 16px;
    margin: 16px 0 0 16px;
  }
`;

export const ButtonDownloadContainer = styled.div`
  padding: 16px 16px 0 0;
  width: 60px;

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    display: none;
  }
`;
