import styled from 'styled-components';
import {
  MAX_SIZE_MOBILE,
  buttonsWidthMobile,
  buttonsWidthDesktop,
  buttonsHeightDesktop,
} from '../../utils';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${buttonsWidthDesktop}px;
  height: ${buttonsHeightDesktop}px;
  max-height: ${buttonsHeightDesktop}px;
  border-radius: 6px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.blue._400.hex()};
  outline: none;
  border: none;

  p{
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    padding: 8px;
    width: 100%;
    max-width: ${buttonsWidthMobile}px;

    p {
      display: none;
    }
  }
`;
