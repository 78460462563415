// Payment Data

export const paymentDataInitialValuesItems = {
  id: 0,
  date: '',
  amount: 0,
  image: null,
  depositedBy: '',
  reservationId: 0,
  reservationStaff: '',
};

export const paymentDataInitialValues = [{ ...paymentDataInitialValuesItems }];

// Payment Status

export const propertyAddressInitialValues = {
  city: '',
  state: '',
  number: '',
  street: '',
  country: '',
  complement: '',
  neighborhood: '',
};

export const paymentVoucherItemsInitialValues = {
  id: 0,
  checkin: '',
  reservationCode: '',
  reservationStaff: '',
  reservationId: 0,
  propertyId: 0,
  propertyCode: '',
  propertyAddress: propertyAddressInitialValues,

  totalValue: 0,
  pendingValue: 0,
  totalPaid: 0,

  checked: false,
};

export const paymentVoucherInitialValues = {
  completes: [{ ...paymentVoucherItemsInitialValues }],
  pendings: [{ ...paymentVoucherItemsInitialValues }],
};

export const checkedListInitialValues = {
  completes: [...[]],
  pendings: [...[]],
};
