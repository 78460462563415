import styled, { css } from 'styled-components';

export const SelectPhoneOptionContainer = styled.div``;

const commonStyle = css`
  border-radius: 10px 0 0 10px;
`;

export const Container = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const OverWriteCountrySelector = styled.div`
  position: relative;
  z-index: 1000 !important;
  
  .css_overwrite_react-tel-input {
    .react-tel-input {
      input {
        height: 42px !important;
      }
    }
    .react-tel-input .country-list {
      border-radius: 10px;
    }

    .react-tel-input .flag-dropdown {
      ${commonStyle}
    }

    .react-tel-input .selected-flag {
      ${commonStyle}
    }

    .react-tel-input .selected-flag:hover,
    .react-tel-input .selected-flag:focus {
      ${commonStyle}
    }

    .react-tel-input .flag-dropdown.open .selected-flag {
      ${commonStyle}
    }
  }
`;

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    font-weight: 700;
    font-family: Inter;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }
`;

export const StarSymbol = styled.p`
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  padding-right: 0.3rem;
`;
