import hands from './img/hands.png';
import house from './img/house.png';
import map from './img/map.png';

export const data = [
  {
    id: 0,
    icon: <img src={hands} alt="aperto de mão" />,
    text: {
      is: 'Proprietários interessados em locação por temporada',
      style: {
        color: '#FC605B',
      },
    },
  },
  {
    id: 1,
    icon: <img src={map} alt="ponto no mapa" />,
    text: {
      is: 'Terrenos à venda',
      style: {
        color: '#FC605B',
      },
    },
  },
  {
    id: 2,
    icon: <img src={house} alt="casa com dinheiro" />,
    text: {
      is: 'Investidores para nossos lançamentos exclusivos!',
      style: {
        color: '#FC605B',
      },
    },
  },
];
