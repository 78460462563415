import { IReports } from '../../../../../../services/Owner/OwnerReport/types';

import { MAX_SIZE_MOBILE } from '../../../utils';

import { useScreenResize } from '../../../../../../hooks/useSceenResize/useScreenResize';
import { useExpensesReport } from '../../../../../../hooks/OwnerPage/OwnerReport/useExpensesReport';

import ArrowButton from '../../../Buttons/ArrowButton';

import {
  Item,
  ItemContainer,
  ValueContainer,
  Container,
} from './styles';

interface IGridItems {
  expense: IReports;
}

const GridItem = ({
  expense,
}: IGridItems) => {
  const { isMobile } = useScreenResize(MAX_SIZE_MOBILE);
  const { handleExpensesModal } = useExpensesReport();

  const handleOpenModal = () => {
    handleExpensesModal({
      open: true,
      expense,
    });
  };

  const ItemsMobile = () => (
    <Container onClick={() => handleOpenModal()}>
      <Item>
        <ItemContainer>
          <p className="property-code">{expense.property_code}</p>
          <p className="register-date">{expense.register_date}</p>
          <p className="description">{expense.description}</p>
        </ItemContainer>
      </Item>

      <Item>
        <ValueContainer>
          <p className="value">{expense.value}</p>
          <ArrowButton />
        </ValueContainer>
      </Item>
    </Container>
  );

  const ItemsDesktop = () => (
    <Container onClick={() => handleOpenModal()}>
      <Item>
        <p className="property-code">{expense.property_code}</p>
      </Item>

      <Item>
        <p className="register-date">{expense.register_date}</p>
      </Item>

      <Item>
        <p className="description">{expense.description}</p>
      </Item>

      <Item>
        <ValueContainer>
          <p className="value">{expense.value}</p>
          <ArrowButton />
        </ValueContainer>
      </Item>
    </Container>
  );

  return isMobile ? <ItemsMobile /> : <ItemsDesktop />;
};

export default GridItem;
