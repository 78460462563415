import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import IconTemplate from '../utils/IconTemplate';

import { partnersRoutes } from '../../types/paths';

import { Container } from './style';

import {
  PanelSVGIcon,
  EarningsSVGIcon,
  IndicateSVGIcon,
  PortfolioSVGIcon,
  IndicationsSVGIcon,
} from '../utils/SvgIcons';
import { usePartners } from '../../../../context/Partners/Partner/PartnerContext';

const Mobile = () => {
  const { hideElements } = usePartners();
  const location = useLocation();
  const pathname = location?.pathname?.split(`/${partnersRoutes.partners.main}/`)[1];

  return (
    <Container
      initial={{
        y: 100,
        height: 0,
      }}
      animate={{
        y: 0,
        height: '71px',
      }}
      transition={{
        velocity: hideElements.footer?.animate ? 0 : 5,
      }}
      exit={{
        y: 100,
        height: 0,
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      }}
    >
      <Link to={`${partnersRoutes.panel.main}`}>
        <PanelSVGIcon
          delay={3}
          active={pathname?.includes(partnersRoutes.panel.main)}
        />
      </Link>

      <button
        type="button"
        onClick={() => window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })}
      >
        <Link
          to={`${partnersRoutes.indications.main}/${partnersRoutes.indications.filter.all}`}
        >
          <IconTemplate
            delay={3}
            active={pathname?.includes(partnersRoutes.indications.main.split('/')[0])}
            text="Indicações"
          >
            <IndicationsSVGIcon delay={3} />
          </IconTemplate>
        </Link>
      </button>

      <Link to={`${partnersRoutes.indicate.main}`}>
        <IndicateSVGIcon delay={4} />
      </Link>

      <Link to={`${partnersRoutes.earnings.main}`}>
        <IconTemplate
          delay={5}
          active={pathname === partnersRoutes.earnings.main}
          text="Ganhos"
        >
          <EarningsSVGIcon delay={5} />
        </IconTemplate>
      </Link>

      <Link to={`${partnersRoutes.portfolio.main}`}>
        <IconTemplate
          delay={6}
          active={pathname?.includes(partnersRoutes.portfolio.main)}
          text="Portfólio"
        >
          <PortfolioSVGIcon delay={6} />
        </IconTemplate>
      </Link>
    </Container>
  );
};

const MobilePresence = () => {
  const { hideElements } = usePartners();

  return (
    <AnimatePresence>
      {!hideElements?.footer?.is && (
        <Mobile
          key={`footer_partners_page_open=${!hideElements?.footer?.is}`}
        />
      )}
    </AnimatePresence>
  );
};

export default MobilePresence;
