import styled from 'styled-components';
import { Typography, Dialog, DialogContent } from '@mui/material';

export const RoundedDialog = styled(Dialog)`
  && {
    .MuiPaper-rounded {
      border-radius: 10px;
    }
    .MuiPaper-elevation24 {
      box-shadow: none;
    }
  }
`;

export const ModalBox = styled(DialogContent)`
  && {
    padding: 0px !important;
    width: 434px;

    @media (max-width: 900px) {
      width: auto;
    }
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: 'Quicksand', sans-serif;
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  height: 40px;
  border-bottom: 1px solid  ${({ theme }) => theme.palette.grey._400.hex()};
  padding: 10px;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
`;

export const OptionsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  padding: 10px 15px;
  margin: 20px 0px;
  cursor: pointer;

  @media (max-width: 900px) {
    height: 85px;
  }
`;
export const Description = styled(Typography)`
  && {
    margin-top: 5px;
    font-family: 'Quicksand', sans-serif;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
  }
`;
export const TextOptionContainer = styled.div`
  padding: 0px 10px;
`;
export const LogoContainer = styled.div`
  height: 40px;
  width: 40px;
`;
export const CloseButtonContainer = styled.div`
  cursor: pointer;
  &:hover{
    color: ${({ theme }) => theme.palette.red._400.hex()};
  }
`;
