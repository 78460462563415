import { FinancialCloseParams, ManualFitProps } from '../../../services/FinancialClose/types';

export const formatDate = (date: Date): FinancialCloseParams => {
  const dateSplitted = date.toLocaleDateString('pt-BR', {
    month: '2-digit',
    year: 'numeric',
  }).split('/');

  return {
    start_date: `${dateSplitted[1]}-${dateSplitted[0]}`,
  } as FinancialCloseParams;
};

export const translatePath: Record<string, string> = {
  '/fechamentoimovel': 'imóvel',
  '/fechamentoanfitriao': 'anfitrião',
  '/fechamentoproprietario': 'proprietário',
};

export const sortManualFitList = (manualFitList: ManualFitProps[]) => {
  const manualFitListSorted = manualFitList.sort((a, b) => {
    const dateA = new Date(a?.created_at || new Date());
    const dateB = new Date(b?.created_at || new Date());
    return (dateB.getTime() - dateA.getTime());
  });

  return manualFitListSorted;
};
