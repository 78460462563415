import {
  FC,
  ReactElement,
  useState,
  useMemo,
  useCallback,
} from 'react';

import { createContext } from 'use-context-selector';
import { PopupsProps } from './types';

export interface IPopups {
  popupsList: Array<PopupsProps>,
  setPopupsList: Function,
}

export const CustomPopupsContext = createContext<IPopups>({
  popupsList: [],
  setPopupsList: () => [],
});

export const CustomPopupsProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [popupsList, setPopupsList] = useState<Array<PopupsProps>>([]);

  const handleSetPopupsList = useCallback((popups: Array<PopupsProps>) => {
    setPopupsList(popups);
  }, []);

  const value = useMemo(() => ({
    popupsList,
    setPopupsList: handleSetPopupsList,
  }), [
    popupsList,
    setPopupsList,
  ]);

  return (
    <CustomPopupsContext.Provider value={value}>
      {children}
    </CustomPopupsContext.Provider>
  );
};
