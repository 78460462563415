/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  DeleteForeverOutlined,
  PictureAsPdfOutlined,
  ImageOutlined,
} from '@mui/icons-material';

import {
  Line,
  CardPreview,
  LineContainer,
  StartContainer,
  ValueContainer,
  DeleteIconContainer,
} from './styles';

import Tooltip from '../../../../../Tooltip';

interface VoucherListProps {
  id: string;
  text: string;
  index: number;
  preview: string | null;
  onDelete: Function;
  MIMEtype: 'application/pdf' | string;
}

const TEXTLIMIT = 15;

const VoucherList = ({
  id, text, preview, onDelete, index, MIMEtype,
}: VoucherListProps) => {
  const verifyIfTextIsBig = text.length >= TEXTLIMIT;
  const limitTextToWords = verifyIfTextIsBig
    ? `${text.substring(0, TEXTLIMIT)}...`
    : text;
  const [isOpen, setIsOpen] = React.useState(false);

  const handlePreviewImage = () => {
    if (preview) {
      window.open(preview, '_blank');
    }
  };

  const handleHoverOpen = (newValue: boolean) => {
    setIsOpen(newValue);
  };

  return (
    <ValueContainer>

      {isOpen && preview && MIMEtype !== 'application/pdf' && (
      <CardPreview index={index}>
        <img src={preview} alt="preview" />
      </CardPreview>
      )}

      <StartContainer textIsBig={verifyIfTextIsBig}>
        {MIMEtype === 'application/pdf' && (
          <Tooltip text="Clique para visualizar o pdf">
            <PictureAsPdfOutlined
              onClick={handlePreviewImage}
            />
          </Tooltip>
        )}

        {MIMEtype !== 'application/pdf' && (
          <ImageOutlined
            onClick={handlePreviewImage}
            onMouseOver={() => handleHoverOpen(true)}
            onMouseOut={() => handleHoverOpen(false)}
          />
        )}

        <Tooltip text={verifyIfTextIsBig ? text : ''}>
          <h1>{limitTextToWords}</h1>
        </Tooltip>
      </StartContainer>

      <LineContainer>
        <Line />
      </LineContainer>

      <DeleteIconContainer onClick={() => onDelete(id)}>
        <Tooltip text="Deletar arquivo">
          <DeleteForeverOutlined style={{ color: '#E35B4C', fontSize: '25px' }} />
        </Tooltip>
      </DeleteIconContainer>
    </ValueContainer>
  );
};

export default VoucherList;
