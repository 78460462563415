import styled from 'styled-components';

export const Container = styled.div`
  --dark-color: ${({ theme }) => theme.palette.blue._950.hex()};
  --light-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  z-index: 10;
  padding-top: 20px;
  `;

export const Date = styled.div<{ borderActive: boolean, weekend: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  & > div {
    padding: 10px 0;
    width:  110px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme, weekend }) => (weekend ? theme.palette.white._400.hex() : `linear-gradient(179.93deg, ${theme.palette.white._250.hex()} 0.06%, ${theme.palette.white.main.hex()} 6.05%)`)};
    border-right: ${({ theme, borderActive }) => (borderActive ? 'unset' : `1px dashed ${theme.palette.grey._440.hex()}`)};
    & > div {
      display: flex;
    }

  }
  &::before {
    content: "";
    background-color:  ${({ theme, borderActive }) => (borderActive ? theme.palette.orange._200.hex() : 'unset')};
    position: absolute;
    width: 3px;
    height: 60px;
    margin: 0 108px;
    z-index: 110;
  }
  &:after {
    content: "";
    background-color:  ${({ theme, borderActive }) => (borderActive ? theme.palette.orange._200.hex() : 'unset')};
    position: absolute;
    width: 3px;
    height: 60px;
  }

  @media (max-width: 320px) {
    width: 98px;
  }
`;

export const DateMonth = styled.p`
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--dark-color);
`;

export const DateNumber = styled.div<{ active: boolean }>`
  margin-right: 2px;
  background: ${({ active, theme }) => (active ? `linear-gradient(180deg, #EB6957 0%, ${theme.palette.orange.main.hex()} 100%)` : 'none')};
  border-radius: 50%;
  width: ${({ active }) => (active ? '23px' : 'auto')};
  height: ${({ active }) => (active ? '23px' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: ${({ active }) => (active ? 'var(--light-color)' : 'var(--dark-color)')};
  }
`;

export const DateWeekDay = styled.p<{ weekend: boolean }>`
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme, weekend }) => (weekend ? theme.palette.orange.main.hex() : theme.palette.blue._700.hex())};
`;
