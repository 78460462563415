import React from 'react';
import FormButton from '../../FormButton';
import sendEmailIcon from '../../../assets/icons/generals/sendEmail.svg';
import {
  Container, BackDrop, Content, ButtonContainer,
} from './styles';
import { patchReservartionVoucher, postReservationVoucher } from '../../../services/SendingVouchers/request';
import { useSendingVoucher } from '../../../context/SendingVoucherContext';

interface ModalProps {
  onClose: (a: boolean) => void;
  guestName: string;
}

const Modal = ({
  onClose,
  guestName,
}: ModalProps) => {
  const {
    idReservation,
    idReservationVoucher,
    sendingVoucher,
    setReload,
    setReloadConfirm,
    setSendingVoucher,
  } = useSendingVoucher();

  async function handleSentVouchers() {
    if (idReservationVoucher) {
      await patchReservartionVoucher(sendingVoucher, idReservationVoucher);
    } else {
      await postReservationVoucher(idReservation, sendingVoucher);
    }
    setReloadConfirm(Math.random());
    onClose(true);
  }

  function handleCancell() {
    setSendingVoucher(!sendingVoucher);
    setReload(Math.random());
    onClose(true);
  }

  return (
    <>
      <Container>
        <Content>
          <p style={{ display: 'none' }} data-cy="value-toggle">{sendingVoucher.toString()}</p>
          <img src={sendEmailIcon} alt="icone de email" />
          <h1>Deseja realmente confirmar envio para:</h1>
          <h2>{guestName}</h2>
          <ButtonContainer>
            <FormButton data-cy="btn-confirm" onClick={handleCancell} variant="outlined">Cancelar</FormButton>
            <FormButton onClick={handleSentVouchers}>Confirmar</FormButton>
          </ButtonContainer>
        </Content>
      </Container>
      <BackDrop onClick={() => onClose(false)} />
    </>
  );
};

export default Modal;
