import React, {
  FC,
  createContext,
  useContext,
  ReactElement,
  useState,
} from 'react';
import Loader from '../components/Loader';

export interface LoaderType {
  load: boolean,
  setLoad: Function,
}

export const LoaderContext = createContext<LoaderType>({
  load: false,
  setLoad: () => {},
});

export const LoaderProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const [load, setLoad] = useState<boolean>(false);

  return (
    <LoaderContext.Provider
      value={{ load, setLoad }}
    >
      <Loader show={load} />
      {children}
    </LoaderContext.Provider>

  );
};

export const useLoader = () => useContext(LoaderContext);
