import React from 'react';
import Lottie from 'lottie-react';

import animationSource from '../source/minimalDog.json';

export const MinimalDogAnimation = () => (
  <Lottie
    animationData={animationSource}
  />
);
