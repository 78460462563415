import styled from 'styled-components';
import { motion } from 'framer-motion';
import fullImg from '../../../../assets/icons/ReservationButton/fullbackground.png';
import halfImg from '../../../../assets/icons/ReservationButton/halfbackground.png';
import miniImg from '../../../../assets/icons/ReservationButton/minibackground.png';

export const BookingContainer = styled(motion.button)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  padding: 1rem;
  background-image: url(${fullImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  min-height: 200px;
  cursor: pointer;
  background-position: right center;
  box-shadow: 0px 3px 5px 1px ${({ theme }) => theme.palette.grey._700.alpha(0.5).toString()};

  > div {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    color: #F9F9F9;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    background-image: url(${halfImg});
  }

  @media screen and (max-width: 630px) {
    background-image: url(${miniImg});
  }

  @media screen and (max-width: 350px) {
    > div {
        gap: 0;
        svg {
          width: 14px;
          height: 14px;
        }
    }
  }

`;

export const Title = styled.h2`
  font-weight: 700;
  color: #F9F9F9;
  font-family: Inter;
  font-size: 2rem;
  max-width: 70%;
  text-align: left;

  @media screen and (max-width: 900px) {
    max-width: 65%;
  }

  @media screen and (max-width: 700px) {
    font-size: 1.5rem;
    max-width: 55%;
  }

  @media screen and (max-width: 350px) {
    font-size: 1.25rem;
  }
`;

export const SubTitle = styled.h2`
  font-weight: 700;
  color: #F9F9F9;
  font-family: Inter;
  font-size: 1.75rem;
  max-width: 70%;
  text-align: left;

  @media screen and (max-width: 900px) {
    max-width: 65%;
  }

  @media screen and (max-width: 700px) {
    font-size: 1.25rem;
    max-width: 50%;
  }

  @media screen and (max-width: 350px) {
    font-size: 1rem;
  }
`;
