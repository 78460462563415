import { useContextSelector } from 'use-context-selector';
import { FinancialCloseOwnerContext } from '../../context/FinancialClosePage/FinancialCloseOwnerContext/FinancialCloseOwnerContext';

export function useFinancialCloseOwner() {
  const financialDataOwner = useContextSelector(FinancialCloseOwnerContext, (state) => state
    .financialDataOwner);
  const handleSetFinancialDataOwner = useContextSelector(FinancialCloseOwnerContext,
    (state) => state.setFinancialDataOwner);
  const handleUpdateFinancialDataOwner = useContextSelector(FinancialCloseOwnerContext,
    (state) => state.updateFinancialDataOwner);

  const handleUpdateCheckedOfOwnerSelected = useContextSelector(FinancialCloseOwnerContext,
    (state) => state.updateCheckedOfOwnerSelected);

  const handleUpdateAllCheckedsOfOwnerSelected = useContextSelector(FinancialCloseOwnerContext,
    (state) => state.updateAllCheckedsOfOwnerSelected);

  const handleUpdateExpandedOfOwnerSelected = useContextSelector(FinancialCloseOwnerContext,
    (state) => state.updateExpandedOfOwnerSelected);

  return {
    financialDataOwner,
    handleSetFinancialDataOwner,
    handleUpdateFinancialDataOwner,
    handleUpdateCheckedOfOwnerSelected,
    handleUpdateAllCheckedsOfOwnerSelected,
    handleUpdateExpandedOfOwnerSelected,
  };
}
