import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';

import { Container, DatePickerContainer } from './styles';
import { UseFormik } from '../../../../utils/Formik/types';
import DatePicker from '../../../DatePicker';

const getMonthByNumber = (month: number) => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Fev';
    case 3:
      return 'Mar';
    case 4:
      return 'Abr';
    case 5:
      return 'Mai';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Ago';
    case 9:
      return 'Set';
    case 10:
      return 'Out';
    case 11:
      return 'Nov';
    case 12:
      return 'Dez';
    default:
      return 'Mês inválido';
  }
};

interface ITedListDatePicker {
  formik: UseFormik<any>;
  customOpen?: boolean;
  setCustomOpen?: Function;
}

const TedListDatePicker = ({
  formik, customOpen, setCustomOpen, ...rest
}: ITedListDatePicker) => {
  const [open, setOpen] = useState<boolean>(customOpen || false);

  const date: Date = formik.values.period;
  const month = getMonthByNumber(date.getMonth() + 1);
  const year = date.getFullYear();

  useEffect(() => {
    setOpen(false);
  }, [formik.values.period]);

  if (customOpen && setCustomOpen) {
    return (
      <Container>
        <DatePickerContainer>
          <DatePicker
            {...rest}
            formik={formik}
            customOpen={open}
            showOnlyModalPicker
            onCustomClose={() => setCustomOpen(false)}
            className="customDatePicker-tedList"
            viewsCustom={['month', 'year']}
            id="period"
            hasInitialDates
            dataCy="period-date-ted-list"
            minDate={new Date('2000-01-02')}
            disableCloseOnSelect={false}
          />
        </DatePickerContainer>
      </Container>
    );
  }

  return (
    <Container>
      <h1 className="label">*Período</h1>
      <Button
        variant="outlined"
        startIcon={(
          <CalendarTodayOutlined
            style={{ fontSize: '16px', fontWeight: 'bold', color: '#0D4BD0' }}
          />
        )}
        onClick={() => setOpen(true)}
      >
        <h1>
          {month}
          {' '}
          {year}
        </h1>
      </Button>
      <DatePickerContainer>
        <DatePicker
          formik={formik}
          customOpen={open}
          showOnlyModalPicker
          onCustomClose={() => setOpen(false)}
          className="customDatePicker-tedList"
          viewsCustom={['month', 'year']}
          id="period"
          hasInitialDates
          dataCy="period-date-ted-list"
          minDate={new Date('2000-01-02')}
          disableCloseOnSelect={false}
        />
      </DatePickerContainer>
    </Container>
  );
};

TedListDatePicker.defaultProps = {
  customOpen: false,
  setCustomOpen: () => {},
};

export default TedListDatePicker;
