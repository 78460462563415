import styled from 'styled-components';

export const ReconcileDamageReservationFormContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  grid-auto-flow: row;
  gap: 1rem;
  margin-top: 1rem;
  font-family: 'Inter';
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  color: #394760;
  font-family: 'Inter';
`;
