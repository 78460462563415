import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  memo,
} from 'react';

import {
  Container,
  Content,
  ContainerInformation,
  Information,
} from './styles';

import Header from '../Header';
import AddManualFitProperty from '../ManualFit/ManualFitProperty/AddManualFitProperty/AddManualFitProperty';
import EditManualFitProperty from '../ManualFit/ManualFitProperty/EditManualFitProperty/EditManualFitProperty';
import AddManualFitDaily from '../ManualFit/ManualFitDaily/AddManualFitDaily';
import EditManualFitDaily from '../ManualFit/ManualFitDaily/EditManualFitDaily';
import AddManualFitCleaning from '../ManualFit/ManualFitCleaning/AddManualFitCleaning';
import EditManualFitCleaning from '../ManualFit/ManualFitCleaning/EditManualFitCleaning';
import ExportReservationsModal from '../Modal/ExportReservationsModal';
import GridProperty from '../Grid/GridProperty';
import GridSkeleton from '../LoadingShimmer/Grid/GridSkeleton';

import {
  getFinancialCloseProperty,
} from '../../../services/FinancialClose/Property/request';

import {
  postConsolidationTask,
} from '../../../services/FinancialClose/request';

import { putReservationsConciliate } from '../../../services/Reservation/request';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../utils/Messages';
import { useFinancialClose } from '../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseProperty } from '../../../hooks/FinancialCloseHook/useFinancialCloseProperty';
import { useAbortRequest } from '../../../hooks/AbortRequestHook/useAbortRequest';

import { FinancialCloseParams, RevenuesPropertyProps, Status } from '../../../services/FinancialClose/types';
import { formatDate } from '../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { GridPropertyProps } from '../../../context/FinancialClosePage/FinancialClosePropertyContext/types';

export interface PropertyCloseContextProps {
  addFinancialCloseProperty: Function;
  refetchSelectedProperties: Function;
  validationRevenuesProperty: Function;
}

export const PropertyCloseContext = createContext<PropertyCloseContextProps>({
  addFinancialCloseProperty: (params: FinancialCloseParams) => params,
  refetchSelectedProperties: (params: FinancialCloseParams) => params,
  validationRevenuesProperty: (revenues: RevenuesPropertyProps[]) => revenues,
});

const PropertyClose: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();
  const {
    dateSelected,
    search,
    actionSelected,
    handleActionSelected,
  } = useFinancialClose();

  const {
    financialDataProperty,
    handleSetFinancialDataProperty,
    handleUpdateFinancialDataProperty,
    openModalAddManualFitProperty,
    openModalEditManualFitProperty,
    openModalAddManualFitDaily,
    openModalEditManualFitDaily,
    openModalAddManualFitCleaning,
    openModalEditManualFitCleaning,
  } = useFinancialCloseProperty();

  const { abortController, setAbortController } = useAbortRequest();

  const getFinancialClose = useCallback(async (params: FinancialCloseParams) => {
    try {
      setLoading(true);
      const response: GridPropertyProps[] | any = await getFinancialCloseProperty(params,
        abortController);
      handleSetFinancialDataProperty([...response]);
      setLoading(false);
    } catch (e: unknown) {
      if (e instanceof Error && !abortController?.signal?.aborted) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
      setLoading(false);
    }
  }, [financialDataProperty]);

  useEffect(() => {
    setAbortController(() => new AbortController());
    return () => {
      abortController.abort();
    };
  }, [getFinancialClose]);

  const refetchSelectedProperties = useCallback(async (params: FinancialCloseParams,
    consolidateTask: boolean = false) => {
    try {
      if (consolidateTask) {
        await postConsolidationTask({
          task: 'property',
          date: params.start_date,
          properties: params.properties?.map((prop) => Number(prop)),
        });
      }

      const response: GridPropertyProps[] | any = await getFinancialCloseProperty(params);

      handleUpdateFinancialDataProperty(financialDataProperty, response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  }, [financialDataProperty]);

  const updateBookingsConciliationFlag = async (propertiesSelected: GridPropertyProps[],
    conciliada: boolean) => {
    const reservationIds: Array<number> = [];

    propertiesSelected.forEach((item) => {
      item.reservations.forEach((reservation) => {
        reservationIds.push(reservation.id);
      });
    });

    await putReservationsConciliate({
      reservation_ids: reservationIds,
      conciliada,
    });
  };

  const validationRevenuesProperty = useCallback(async (params: FinancialCloseParams,
    status: Status) => {
    try {
      const propertiesSelected = financialDataProperty.filter((item) => item.checked);
      await updateBookingsConciliationFlag(propertiesSelected, status === 'Closed');

      const response: GridPropertyProps[] | any = await getFinancialCloseProperty(params);
      handleUpdateFinancialDataProperty(financialDataProperty, response);

      toast.success('As reservas dos imóveis selecionados foram marcadas como conciliadas.');
      toast.success(`Dados ${status === 'Closed' ? 'validados' : 'desvalidados'} com sucesso!`);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  }, [financialDataProperty]);

  useEffect(() => {
    let params = formatDate(dateSelected);
    if (search !== '') {
      params = {
        ...params,
        search,
      };
    }
    getFinancialClose(params);
  }, [dateSelected]);

  useEffect(() => {
    if (!loading && financialDataProperty.length === 0) {
      toast.alert('Não há registros para o mês selecionado');
    }
  }, [loading]);

  return (
    <PropertyCloseContext.Provider
      value={{
        addFinancialCloseProperty: getFinancialClose,
        refetchSelectedProperties,
        validationRevenuesProperty,
      }}
    >
      <Container>
        <Content>
          <Header />
          {loading ? (
            <GridSkeleton type="property" />
          ) : (
            <>
              <GridProperty />
            </>
          )}
          {!loading && financialDataProperty.length === 0 && (
            <ContainerInformation>
              <Information>
                Fechamento financeiro não realizado
              </Information>
            </ContainerInformation>
          )}
        </Content>
      </Container>

      {openModalAddManualFitProperty && <AddManualFitProperty />}
      {openModalEditManualFitProperty && <EditManualFitProperty />}

      {openModalAddManualFitDaily.open && <AddManualFitDaily />}
      {openModalEditManualFitDaily.open && <EditManualFitDaily />}

      {openModalAddManualFitCleaning.open && <AddManualFitCleaning />}
      {openModalEditManualFitCleaning.open && <EditManualFitCleaning />}

      {actionSelected === 'export_reservations' && (
        <ExportReservationsModal
          close={() => handleActionSelected('none')}
        />
      )}
    </PropertyCloseContext.Provider>
  );
};

export default memo(PropertyClose);
