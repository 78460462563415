import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  button {
    width: 100%;
    opacity: 0.8;
    height: 40px;
    color: white;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
`;

export const Alert = styled.p`

`;
