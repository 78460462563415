import React, { Dispatch, SetStateAction, useState } from 'react';

import Header from './Header';
import Modal from './Modal';
import Grid from './Grid';

import { Container } from './styles';
import { CompReservationsType } from '../../../services/CompReservations/types';
import { useCompleteReservation } from '../../../hooks/CompleteReservationHook/useCompleteReservation';

interface CardsProps {
  title: string;
  isLoading: boolean;
  color?: 'normal' | 'green';
  setIsOpenCard: Function;
  isOpenCard: boolean;
  isTwoCardOpen?: boolean;
  setTypeSort: Dispatch<SetStateAction<string | undefined>>;
}

const Cards = ({
  title,
  color = 'normal',
  setIsOpenCard,
  isOpenCard,
  isTwoCardOpen,
  setTypeSort,
  isLoading,
}: CardsProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idProperty, setIdProperty] = useState<number>();
  const [checkin, setCheckin] = useState<string>();
  const [checkout, setCheckout] = useState<string>();
  const [daysLeft, setDaysLeft] = useState<number>();
  const [idLocation, setIdLocation] = useState<number>();

  const { reservationsList } = useCompleteReservation();
  const allReservations: CompReservationsType[] = title.toLowerCase() === 'incompletas'
    ? (reservationsList?.filter((item) => !item.is_pre_checkin_completed) || [])
    : (reservationsList?.filter((item) => item.is_pre_checkin_completed) || []);

  const [reservation, setReservation] = useState<CompReservationsType>();

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleChangeVisible = () => {
    setIsOpenCard(!isOpenCard);
  };

  return (
    <>
      {isOpenModal && (
        <Modal
          checkin={checkin || ''}
          checkout={checkout || ''}
          daysLeft={daysLeft || 0}
          idProperty={idProperty || 0}
          onCloseModal={handleCloseModal}
          idLocation={idLocation || 0}
          allReservations={allReservations}
          reservation={reservation || {} as CompReservationsType}
        />
      )}

      <Container>
        <Header
          title={title}
          isOpen={isOpenCard}
          changeVisible={handleChangeVisible}
        />
        {isOpenCard && (
          <Grid
            onClickEditButton={handleOpenModal}
            isTwoCardOpen={isTwoCardOpen}
            color={color}
            setIdProperty={setIdProperty}
            setCheckin={setCheckin}
            setCheckout={setCheckout}
            setDaysLeft={setDaysLeft}
            setIdLocation={setIdLocation}
            setReservation={setReservation}
            allReservations={allReservations}
            setTypeSort={setTypeSort}
            isLoading={isLoading}
          />
        )}
      </Container>
    </>
  );
};

Cards.defaultProps = {
  color: 'normal',
  isTwoCardOpen: true,
};

export default Cards;
