/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ReportProblemOutlined } from '@mui/icons-material';
import moment from 'moment';
import copy from '../../../assets/icons/generals/copy.svg';
import { useUser } from '../../../context/UserContext';
import BudgetCard from './BudgetCard';

import { initialValues } from './utils/initialValues';

import {
  Total,
  Alert,
  Footer,
  Header,
  Content,
  ModalBox,
  ButtonCopy,
  ButtonCancel,
  RoundedDialog,
  ButtonDiscount,
  AddReservation,
  ButtonConcluded,
} from './styles';

import { ReservationTypeLocal } from './types';
import {
  GetDaillyBudgetProps,
  getDailyBudget,
} from '../../../services/DaillyBudget/request';
import { useReservationForm } from '../../../hooks/ReservationHook/useReservationForm';
import { numberToCurrency } from '../../../utils/Formatter';
import { useToast } from '../../../context/ToastContext';
import { Budget, RequestDataProperty } from '../../../services/DaillyBudget/types';
import { PropertyDetails } from '../../../services/Property/types';
import { getPropertiesDetails } from '../../../services/Property/request';
import { Host, UserDetail } from '../../../services/User/types';
import CardSkeleton from './LoadingShimmer/CardSkeleton';

interface Props {
  openModal: boolean;
  setOpenModal: (a: boolean) => void;
  isTest?: boolean;
  setOpenLateralModal: (a: boolean) => void;
}

interface CategoriesType {
  title: string;
  properties: ReservationTypeLocal[];

}

const ModalEstimate = ({
  openModal,
  setOpenModal,
  isTest,
  setOpenLateralModal,
}: Props): JSX.Element => {
  // @ts-ignore
  const [listProperties, setListProperties] = useState<Array<RequestDataProperty>>([]);
  const [totalValue, setTotalValue] = useState<number | string>(0);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [propertiesList, setPropertiesList] = useState<Array<PropertyDetails>>([]);
  const [showLoading, setShowLoading] = useState(true);

  const toast = useToast();
  const { data, setData } = useReservationForm();

  function removeProperty(id: number) {
    const remove = listProperties.filter((item) => item.id !== id.toString());
    setListProperties(remove);
  }

  const getTotalBudgetValue = () => {
    let total = 0;
    listProperties?.forEach((item) => {
      total += item?.rent_price?.total_price || 0;
    });
    setTotalValue(numberToCurrency(total));
    return numberToCurrency(total);
  };

  const { userInformation } = useUser();
  const username = `${userInformation?.first_name || ''}`;

  const textCopy = (listPropertiesByCategories: CategoriesType[]) => {
    let text: string = `Olá hóspede tudo bem? Sou ${
      userInformation?.gender === 'Female' ? 'a' : 'o'
    } *${username}* e serei responsável pelo seu atendimento. Como solicitado, este é o orçamento e as possibilidades de locação para o período de *${moment(data?.start).format('DD/MM/YYYY')}* até *${moment(data?.end).format('DD/MM/YYYY')}*:`;
    listPropertiesByCategories.forEach((item) => {
      text += `*${item.title}*`;
      item.properties.forEach((propert) => {
        text += ` ${propert.code} - (${propert.address?.street},${propert.address?.number}, ${propert.address?.neighborhood}, ${propert.address?.city}) *Valor Total:* ${propert?.rent_price?.total_price} `;
      });
    });
    text
      += 'Estou à disposição para qualquer esclarecimento que se fizer necessário. Esperamos concretizar a sua reserva em breve 😁 ';
    return text;
  };

  const copyToClipboard = (text: string | EventTarget | any) => {
    navigator.clipboard.writeText(text);
  };

  async function handleCreateArray() {
    const categories = await listProperties.map((item) => ({
      title: item.code,
      properties: listProperties.filter((item2) => item2 === item),
    }));
    // @ts-ignore
    copyToClipboard(textCopy(categories));
  }

  function getRndInteger(min: number, max: number) {
    const numberSort = Math.floor(Math.random() * (max - min)) + min;
    if (
      listProperties.find((item) => parseInt(item.id, 10)
      === numberSort)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getRndInteger(min, max);
    }

    return numberSort;
  }

  const handleGetBudgetData = async () => {
    if (data) {
      const args: GetDaillyBudgetProps = {
        endDate: data?.end,
        startDate: data?.start,
        propertiesIds: data?.properties[data?.properties.length - 1].id.toString(),
      };

      const response = await getDailyBudget(args);

      if (response) {
        setListProperties([...listProperties,
          {
            ...response[Object.keys(response)[0] as keyof Budget],
            id: Object.keys(response)[0],
            newProperty: true,
            checkinDate: data.start,
            checkoutDate: data.end,
          }]);
        setShowLoading(false);
      } else {
        toast.alert('Nenhuma propriedade foi encontrada no período selecionado');
      }
    }
  };

  const handleApplyDiscount = () => {
    let total = 0;
    listProperties?.forEach((item) => {
      if (item.rent_price.total_price > 0) {
        total += item?.rent_price.total_price;
      }
    });

    const totalDiscount = total * 0.1;
    const finalValue = total - totalDiscount;

    setTotalValue(numberToCurrency(finalValue));
    setDiscountApplied(true);
    return finalValue;
  };

  const handleRemoveDiscount = () => {
    const total = getTotalBudgetValue();
    setTotalValue(total);
    setDiscountApplied(false);
  };

  useEffect(() => {
    handleGetBudgetData();
  }, []);

  useEffect(() => {
    if (discountApplied) {
      handleApplyDiscount();
    } else {
      getTotalBudgetValue();
    }
  }, [listProperties]);

  useEffect(() => {
    async function getallProperties() {
      const allProperties = await getPropertiesDetails();
      setPropertiesList(allProperties);
    }
    if (!isTest) {
      getallProperties();
    }
  }, []);

  function handleCreateReservation() {
    if (listProperties.length > 1) {
      toast.alert('Selecione apenas um imóvel para criar reserva!');
    } else if (listProperties.length !== 1) {
      toast.error('Selecione um imóvel para criar a reserva!');
    } else {
      const property = propertiesList.find((item) => item.id?.toString() === listProperties[0].id);
      setData({
        price: listProperties[0].rent_price.daily_price,
        guaranteeValue: 0,
        start: moment(listProperties[0].checkinDate) || moment(),
        end: moment(listProperties[0].checkoutDate) || moment(),
        properties: [{
          address: property?.address?.id || 0,
          allow_pet: property?.allow_pet || false,
          code: property?.code || '',
          id: property?.id || 0,
          owners: property?.owners || [],
          location: property?.location || '',
          category_location: property?.location || '',
          host: {} as unknown as Host,
        }],

      });
      setOpenLateralModal(true);
      setOpenModal(false);
    }
  }

  return (
    <>
      <RoundedDialog
        keepMounted
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <ModalBox>
          <Header>
            <div>
              <h1>Gerar novo orçamento</h1>
              <span>Calcule datas imóveis e negocie</span>
            </div>
            <ButtonCopy onClick={() => handleCreateArray()}>
              Copiar informações de orçamento
              <img src={copy} alt="" />
            </ButtonCopy>
          </Header>
          <Content data-testid="content">
            {showLoading && (
              <CardSkeleton />
            )}
            {listProperties.length > 0
            && listProperties.map((item) => (
              <div key={item.id}>
                <BudgetCard
                  isTest={isTest}
                  property={item}
                  key={item.id}
                  testid="card-estimate"
                  remove={removeProperty}
                  idProperty={parseInt(item.id, 10)}
                  isProperty={parseInt(item.id, 10) === 1}
                  listProperties={listProperties}
                  setListProperties={setListProperties}
                  propertiesListSearchField={propertiesList}
                />
              </div>
            ))}
            {listProperties.filter((item) => item.id === '').length === 0 && (
            <AddReservation
              data-testid="add-reservation-button"
              onClick={() => setListProperties((oldData) => [
                ...oldData,
                {
                  id: '',
                  code: '',
                  address: {
                    state: '',
                    street: '',
                    neighborhood: '',
                    city: '',
                    complement: '',
                    country: '',
                    number: '',
                  },
                  cover_image: '',
                  guest_capacity: 0,
                  rent_price: {
                    daily_price: 0,
                    fees: [],
                    number_of_stays: 0,
                    total_price: 0,
                    newProperty: false,
                  },
                },
              ])}
            >
              <p>+ Adicionar reserva</p>
            </AddReservation>
            )}
          </Content>
          <Footer>
            <Alert>
              <ReportProblemOutlined />
              <p>
                *Atenção! Você tem direito a usar 5 vezes o desconto para
                conseguir negociar com o cliente em cada 20 negociações.
              </p>
            </Alert>
            <div className="row">
              <Total>
                {
                discountApplied ? (
                  <ButtonDiscount
                    color="red"
                    onClick={handleRemoveDiscount}
                  >
                    Remover desconto*
                  </ButtonDiscount>
                ) : (
                  <ButtonDiscount
                    color="green"
                    disabled={discountApplied}
                    onClick={handleApplyDiscount}
                  >
                    Aplicar desconto*
                  </ButtonDiscount>
                )
              }

                <p>Total do orçamento:</p>
                <div>
                  <strong>{totalValue}</strong>
                </div>
              </Total>
              <div>
                <ButtonConcluded onClick={() => handleCreateReservation()}>
                  Criar reserva
                </ButtonConcluded>
                <ButtonCancel onClick={() => setOpenModal(false)}>
                  Cancelar
                </ButtonCancel>
              </div>
            </div>
          </Footer>
        </ModalBox>
      </RoundedDialog>
    </>
  );
};

ModalEstimate.defaultProps = {
  isTest: false,
};

export default ModalEstimate;
