import styled from 'styled-components';

export const Container = styled.div<{ isChecked: boolean }>`
  width: 300px;
  height: 310px;
  background-color: ${({ theme, isChecked }) => (!isChecked ? theme.palette.grey._260.hex() : theme.palette.green._100.hex())};
  border-radius: 10px;
  padding: 15px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.green._100.hex()};
    -webkit-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    -moz-box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
    box-shadow: 3px 3px 16px -4px rgba(92,94,93,1);
  }
`;

export const ImageProperty = styled.div`
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;

  img {
    width: 100%;
    height: 108px;
    border-radius: 10px;
    object-fit: cover;
  }
`;

export const PropertyTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.blue._950.hex()};
  margin-bottom: 5px;
`;

export const RowInformations = styled.div`
  height: 37px;
  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 9px;
    line-height: 18px;
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
`;

export const ContentValueTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const Total = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blue._950.hex()};
`;

export const Price = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.blue._950.hex()};
`;

export const ButtonAddEstimate = styled.div`
  width: 100%;
  height: 38px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  margin-top: 15px;

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
  }

  div {
    margin: 0!important;
  }

  &:hover {
    -webkit-box-shadow: 1px 1px 12px -4px rgba(92,94,93,1);
    -moz-box-shadow: 1px 1px 12px -4px rgba(92,94,93,1);
    box-shadow: 1px 1px 12px -4px rgba(92,94,93,1);
  }
`;

export const IconImage = styled.img`
  margin-right: 10px;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
`;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
`;

export const Ota = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  img {
    object-fit: contain;
  }

  &:hover {
    -webkit-box-shadow: 1px 1px 12px -4px rgba(92,94,93,1);
    -moz-box-shadow: 1px 1px 12px -4px rgba(92,94,93,1);
    box-shadow: 1px 1px 12px -4px rgba(92,94,93,1);
  }
`;

export const Images = styled.div`
  position: absolute;
  z-index: 10;
  margin-top: 70px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
