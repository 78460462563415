import React from 'react';

import { motion } from 'framer-motion';
import { Header } from '../../components';
import { Container, Content } from './style';
import { bodyVariants, itemVariants } from '../../utils';

import CardInfo from './CardInfo';
import AddGuest from './AddGuest';
import Presentation from './Presentation';
import Finish from './Finish/Finish';
import BookingButton from '../../components/BookingButton';

const Main = () => (
  <Container
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  >
    <Content
      variants={bodyVariants}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 20, opacity: 0 }}
      exit={{
        y: 20,
        opacity: 0,
        transition: {
          duration: 0.4,
          ease: [1, -0.05, 0.66, 1],
        },
      }}
    >
      <Header />
      <motion.div variants={itemVariants} transition={{ delay: 0.3 }}>
        <Presentation />
      </motion.div>
      <motion.div variants={itemVariants} transition={{ delay: 0.4 }}>
        <CardInfo />
      </motion.div>
      <motion.div variants={itemVariants} transition={{ delay: 0.5 }}>
        <AddGuest />
      </motion.div>
      <BookingButton />
    </Content>
    <Finish />
  </Container>
);

export default Main;
