import React, {
  FC,
  createContext,
  useContext,
  ReactElement,
  useState,
} from 'react';

import { Reservation } from '../services/Reservation/types';

export interface ReservationContextType {
  reservationData?: Reservation | any;
  reservationDetails?: any;
  setReservationData: (data: Reservation) => void;
  setReservationDetails: (data: any) => void;
}

export const ReservationContext = createContext<ReservationContextType>({
  reservationData: {},
  reservationDetails: {},
  setReservationData: (reservationData: Reservation) => reservationData,
  setReservationDetails: (reservation: any) => reservation,
});

export const ReservationProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const [reservationData, setReservationData] = useState<Reservation>();
  const [reservationDetails, setReservationDetails] = useState<any>();
  return (
    <ReservationContext.Provider
      value={{
        reservationData, setReservationData, reservationDetails, setReservationDetails,
      }}
    >
      {children}
    </ReservationContext.Provider>

  );
};

export const useReservation = () => useContext(ReservationContext);
