import { postPopupsAck } from '../../../services/Popups/request';

import { PopupsProps } from '../../../context/CustomPopupsContext/types';

import { useCustomPopups } from '../../../hooks/useCustomPopups/useCustomPopups';
import { useToast } from '../../../context/ToastContext';

import CloseButton from '../components/CloseButton';
import FormButton from '../../FormButton';

import {
  Container,
  MainContent,
  ImageContent,
  Header,
  Body,
  Content,
  Footer,
  Text,
  Title,
  ButtonContainer,
  ImageWrapper,
  Image,
  CloseButtonContainer,
} from './styles';

interface IPopupTemplate2 {
  id: number,
  headline: string,
  content: HTMLElement | string,
  confirmButtonText: string,
  imageUrl: string,
}

const PopupTemplate2 = ({
  id,
  headline,
  content,
  confirmButtonText,
  imageUrl,
}: IPopupTemplate2) => {
  const { setPopupsList } = useCustomPopups();
  const toast = useToast();

  const handleClosePopup = () => {
    setPopupsList((state: PopupsProps[]) => state.map((item) => ({
      ...item,
      popup_is_open: item.id === id ? false : item.popup_is_open,
    })));
  };

  const handlePopupConfirmation = async () => {
    try {
      await postPopupsAck(id);
      toast.success('Confirmação realizada com sucesso!');
      handleClosePopup();
    } catch {
      toast.error('Não foi possível realizar a confirmação!');
    }
  };

  const contentHTML = content instanceof HTMLElement ? content.outerHTML : content;

  return (
    <Container key={id}>
      <MainContent>
        <Header>
          <Title>{headline}</Title>
        </Header>
        <Body>
          <Content>
            <Text dangerouslySetInnerHTML={{ __html: contentHTML }} />
          </Content>
        </Body>
        <Footer>
          <ButtonContainer>
            <FormButton
              type="button"
              customColor="blue"
              onClick={() => handlePopupConfirmation()}
            >
              {confirmButtonText}
            </FormButton>
          </ButtonContainer>
        </Footer>
      </MainContent>

      <ImageContent>
        <ImageWrapper>
          <Image src={imageUrl} alt="Imagem de fundo do popup" />
          <CloseButtonContainer>
            <CloseButton onClose={() => handleClosePopup()} />
          </CloseButtonContainer>
        </ImageWrapper>
      </ImageContent>
    </Container>
  );
};

export default PopupTemplate2;
