import { Moment } from 'moment';
import React, { createContext, useContext, useState } from 'react';
import { HostDetails } from '../services/Property/types';

interface Props {
  children: JSX.Element;
}

interface FilterBarContextData {
  filterBar: FilterBar,
  setFilterBar: (arg0: FilterBar) => void;
}

export type FilterBar = {
  checkInDate: Moment | string,
  checkOutDate: Moment | string,
  priceMax: string,
  priceMin: string,
  guestsCapacity: number
  search?: boolean,
  moreSearch?: boolean,
  apartment?: boolean,
  hotel?: boolean,
  house?: boolean,
  seaView?: boolean,
  barbecue?: boolean,
  allowPet?: boolean,
  acceptMonthly?: boolean,
  bedroom?: string,
  bathroom?: string,
  host?: HostDetails,
  categories?: Array<string>,
  location?: Array<string>
};

export const FilterBarContext = createContext<FilterBarContextData>({} as FilterBarContextData);

export default function FilterBarProvider({ children }:Props) {
  const [filterBar, setFilterBar] = useState<FilterBar>({
    checkInDate: '',
    checkOutDate: '',
    priceMax: '',
    priceMin: '',
    guestsCapacity: 0,
    search: false,
    moreSearch: false,
  });

  return (
    <FilterBarContext.Provider
      value={{ filterBar, setFilterBar }}
    >
      {children}
    </FilterBarContext.Provider>
  );
}
export function useFilterBar() {
  const context = useContext(FilterBarContext);
  const defaultProps = {
    checkInDate: '',
    checkOutDate: '',
    priceMax: '',
    priceMin: '',
    guestsCapacity: 0,
    search: false,
    moreSearch: false,
    apartment: false,
    hotel: false,
    house: false,
    seaView: false,
    barbecue: false,
    allowPet: false,
    acceptMonthly: false,
    bedroom: '',
    bathroom: '',
    host: {} as HostDetails,
    categories: ['Selecione'],
    location: ['Selecione'],
  };

  const { filterBar, setFilterBar } = context;
  return { filterBar, setFilterBar, defaultProps };
}
