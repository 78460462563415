import React from 'react';

import { Content, Container } from './style';

import Table from './Table';
import Header from './Header';
import Search from './Search';

import { OwnerPerspectiveDashboardProvider } from '../../context/OwnerPerspectiveDashboard/OwnerPerspectiveDashboard';

const OwnerPerspectiveDashboard = () => (
  <Container>
    <Content>
      <Header />
      <Search />
      <Table />
    </Content>
  </Container>
);

const OwnerPerspectiveDashboardProvided = () => (
  <OwnerPerspectiveDashboardProvider>
    <OwnerPerspectiveDashboard />
  </OwnerPerspectiveDashboardProvider>
);

export default OwnerPerspectiveDashboardProvided;
