import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 8px 0;
`;

export const Title = styled.h2`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  padding: 16px 0;
`;

export const CardOwnerSelected = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: ${({ theme }) => theme.palette.white._350.hex()};
  border-radius: 3px;
  p, button {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.palette.grey._760.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    cursor: pointer
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${({ theme }) => theme.palette.grey._380.hex()};
    color: ${({ theme }) => theme.palette.white._350.hex()};
  }
`;

export const SearchContainer = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 17px;
  
  @media(max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  max-width: 466px;
  height: 45px;
  width: 100%;
  
  .MuiOutlinedInput-root {
    width: 100% !important;
    max-height: 35px !important;
    border: 0 !important;
    box-sizing: border-box !important;
    
    &:focus, &:active, &:hover  fieldset {
      border: solid 1px ${({ theme }) => theme.palette.grey._425.hex()} !important;
    }
  }
  
  .MuiInputBase-input {
    width: 100% !important;
    max-height: 35px !important;
    border: 0 !important;
    box-sizing: border-box !important;

    :focus, :active, :hover {
      border: none !important;
    }

    ::placeholder {
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      color: ${({ theme }) => theme.palette.blue._250.hex()};
    }
  }
`;

export const ButtonSection = styled.div`
  width: 100%;
  position: fixed;
  bottom: 30px;
  left: 68vw;
  right: 0;
  z-index: 10;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 16px;

  button {
    min-width: 100px;
    max-height: 35px;
  }

  @media(max-width: 900px) {
    bottom: 110px;
  }
`;

export const ButtonDiv = styled.div`
  width: 100%;
  height: 100%;

  button {
    min-width: 119px;
    max-height: 40px;
  }

  @media(max-width: 900px) {
    button {
      min-width: 100%;
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 16px;
  border: ${({ theme }) => theme.palette.grey._375.hex()} 1px solid;
  border-radius: 10px;
  h1 {
    margin-top: 10px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const ResultsText = styled.div<{ isLast?: boolean }>`
  margin-top: 18px;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.white._600.hex()};
  }
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.grey._765.hex()};
    margin-bottom: 18px;
  }
  border-bottom: ${({ isLast }) => !isLast && '0.5px solid #C1C1C1'} ;
`;

export const TitleContainer = styled.div`
  display: flex;
  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
`;
