import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.white._300.hex()};
  height: 100%;
  width: 100%;

  padding: 50px;

  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px){
    padding: 100px 50px;
  }

  h1,h2,p{
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;
