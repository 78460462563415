import React from 'react';
import { Container } from './style';

import data from './utils/data';
import Quest from './Quest/Quest';

const Questions = () => (
  <Container>
    {data.slice(1, data.length).map((item) => (
      <Quest
        key={item.id}
        title={item.title}
        option={item.option}
        tooltip={item.tooltip}
      />
    ))}
  </Container>
);

export default Questions;
