import styled, { keyframes } from 'styled-components';

import Button from '@mui/material/Button';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div<{ haveColor?: boolean }>`
  background-color: ${({ theme, haveColor }) => !haveColor && theme.palette.white.main.hex()};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  svg {
    fill: ${({ theme }) => theme.palette.blue._850.hex()};
  }
`;

export const BackButtonStyled = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.blue._850.hex()};
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border: 1px solid ${({ theme }) => theme.palette.blue._850.hex()};
    border-radius: 10px;
    padding: 7px 10px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    box-shadow: 0 0 0.5em ${({ theme }) => theme.palette.grey._650.hex()};
  }
  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    margin-left: 0.8rem;
    opacity: 0;
    font-size: 10pt;
    animation-name: ${FadeAnimation};
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
  &&:hover {
    border: 1px solid ${({ theme }) => theme.palette.blue._850.hex()};
    color: ${({ theme }) => theme.palette.blue._850.hex()};
    -webkit-box-shadow: 1px 3px 35px -9px ${({ theme }) => theme.palette.blue._850.hex()};
    -moz-box-shadow: 1px 3px 35px -9px ${({ theme }) => theme.palette.blue._850.hex()};
    box-shadow: 1px 3px 35px -9px ${({ theme }) => theme.palette.blue._850.hex()};
    background-color: ${({ theme }) => theme.palette.white.main.hex()} !important;
  }
`;
