import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const Logo = styled.img`
  width: 200px;
`;

export const LogoText = styled.p`
  font-weight: 600;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.orange.main.hex()};
`;

export const Title = styled.h2`
  margin-top: 2rem;
  text-align: justify;
  color: #002060;
  font-size: 2rem;
`;

export const Text = styled.p`
  margin-top: 1.5rem;
  color: #002060;
  font-size: 1.5rem;
  text-align: justify;

  span {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: justify;
  }

  a {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: justify;
  }
`;

export const ListContainer = styled.ul`
  list-style-type: lower-alpha;
  padding-left: 4rem;
  margin-top: 1.5rem;
`;

export const ItemList = styled.li`
  color: #002060;
  font-size: 1.5rem;
  text-align: justify;

  span {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: justify;
  }
`;
