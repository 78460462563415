import { Container, Input, Text } from './styles';

interface ICheckbox {
  label: HTMLElement | string,
  checked: boolean,
  setChecked: () => void,
}

const Checkbox = ({
  label,
  checked,
  setChecked,
}: ICheckbox) => {
  const contentHTML = label instanceof HTMLElement ? label.outerHTML : label;

  return (
    <Container>
      <Input
        type="checkbox"
        checked={checked}
        onClick={setChecked}
      />
      <Text dangerouslySetInnerHTML={{ __html: contentHTML }} />
    </Container>
  );
};

export default Checkbox;
