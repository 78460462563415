import styled, { css } from 'styled-components';

export const NewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NewItemTitle = styled.h3`
  color: ${({ theme }) => theme.palette.blue._250.hex()};

  & {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const NewItemDescription = styled.h4`
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
`;

export const NewItemPersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  & > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;

    span {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.blue._910.hex()};
    }
  }
`;

export const NewItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DefaultStyle = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.grey._425.hex()};
  opacity: 70%;
`;

export const NewVerticalLine = styled(DefaultStyle)`
  border-left: none;
  height: 100%;
  width: 1px;
`;

export const NewHorizontalRow = styled(DefaultStyle)`
  border-top: none;
  height: 1px;
  width: 100%;
`;

type Props = {
  blocked?: boolean;
};

export const NewContentBox = styled.div<Props>`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 16px;

  ${({ blocked }) => blocked && css`
    grid-template-columns: 1fr;
  `}

  @media (max-width: 1000px) {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
`;

export const NewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;
  padding: 24px 31px;
  gap: 16px;
`;

export const NewItemWithLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const NewItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;
