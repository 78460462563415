import React from 'react';

import {
  Header,
  Content,
  LateralModal,
  Footer,
  Container,
  Main,
} from './style';

export const ChecklistShimmer = () => (
  <Container>
    <Header />
    <Main>
      <Content />
      <LateralModal />
    </Main>
    <Footer />
  </Container>
);
