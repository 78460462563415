import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

import { OwnerFinancialSummaryProps } from './types';
import { initialValuesOwnerFinancialSummary } from './utils';

interface IOwnerFinancialSummary {
  financialSummaryData: OwnerFinancialSummaryProps;
  setFinancialSummaryData: Function;
  period: Date;
  setPeriod: Function;
  showBalance: boolean;
  setShowBalance: Function;
}

export const OwnerFinancialSummaryContext = createContext<IOwnerFinancialSummary>({
  financialSummaryData: initialValuesOwnerFinancialSummary,
  setFinancialSummaryData: () => {},
  period: new Date(),
  setPeriod: () => {},
  showBalance: false,
  setShowBalance: (isShow: boolean) => isShow,
});

export const OwnerFinancialSummaryProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [
    financialSummaryData,
    setFinancialSummaryData,
  ] = useState<OwnerFinancialSummaryProps>(initialValuesOwnerFinancialSummary);

  const [period, setPeriod] = useState<Date>(new Date());
  const [showBalance, setShowBalance] = useState<boolean>(false);

  const handleSetFinancialSummaryList = useCallback((data: OwnerFinancialSummaryProps) => {
    setFinancialSummaryData(data);
  }, []);

  const handleSetPeriod = useCallback((date: Date) => {
    setPeriod(date);
  }, []);

  const handleSetShowBalance = useCallback((isShow: boolean) => {
    setShowBalance(isShow);
  }, []);

  const value = useMemo(() => ({
    financialSummaryData,
    setFinancialSummaryData: handleSetFinancialSummaryList,
    period,
    setPeriod: handleSetPeriod,
    showBalance,
    setShowBalance: handleSetShowBalance,
  }), [
    financialSummaryData,
    setFinancialSummaryData,
    period,
    setPeriod,
    showBalance,
    setShowBalance,
  ]);

  return (
    <OwnerFinancialSummaryContext.Provider
      value={value}
    >
      {children}
    </OwnerFinancialSummaryContext.Provider>
  );
};
