import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;
export const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 18px 30px 30px 30px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  box-shadow: rgba(0, 0, 0, 0.096) 0px 25px 20px -20px;
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.6s ease forwards;
  display: flex;
  flex-direction: column;
  z-index: 100;
  
  h1 {
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: bold;
  }
`;

export const InputModal = styled.div`
  position: fixed;
  top: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23);
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey._450.hex()};
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  margin-top: 47px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  z-index: 6;

  &.input-modal-style {
    height: auto;
  }
`;

export const InputHideSelect = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`;

export const OptionFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  
  .labelSelect {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.grey._900.hex()}
  }
  .style-multi-select, .css-q8hpuo-MuiFormControl-root {
    margin-left: 10px !important;
    margin-bottom: 5px !important;
    width: 230px !important;
  }
`;

export const MutiSelectors = styled.div``;
