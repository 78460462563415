import React, { useCallback, useEffect, useMemo } from 'react';

import { IFormik } from '../../../types';
import { useUpload } from '../../../../../../../../../../hooks/useUpload';
import useSingleUpload from '../../../../../../../../../../hooks/useUpload/useSingleUpload';
import { usePreCheckin } from '../../../../../../../../../../context/PreCheckin/PreCheckin';

import { Text, View, Loading } from './components';
import { IUpload, IPreCheckinDocumentType } from './types';
import { Container, StyledUpload, UploadPreview } from './style';

const UploadComponent = ({
  text,
  type,
  formik,
  callbacks,
}: IUpload) => {
  const { token, mode } = usePreCheckin();

  const { file, loading, onUpload } = useSingleUpload<IFormik, IPreCheckinDocumentType>({
    type,
    formik,
    callbacks: { success: callbacks.success },
    config: {
      createFileReference: {
        customHeader: {
          is: true,
          token: token || '',
          value: 'Pre-Checkin-Token',
        },
      },
    },
  });

  const {
    open,
    getRootProps,
    getInputProps,
  } = useUpload({
    file,
    setFile: onUpload,
  });

  useEffect(() => {
    formik.setFieldValue(type, file);
  }, [file]);

  const isImageUploaded = useMemo(() => file.uid && file.previewURL, [file]);

  const openImageInNewTab = useCallback(() => {
    if (file.previewURL) {
      window.open(file.previewURL);
    }
  }, [file.previewURL]);

  const UploadContent = useMemo(() => (isImageUploaded ? (
    <UploadPreview alt={`imagem do documento-${type}`} loading="lazy" src={file.previewURL || ''} />
  ) : (
    <View text={text} />
  )), [isImageUploaded, type, file.previewURL, text]);

  if (mode.is === 'view') {
    return (
      <Container>
        <StyledUpload onClick={openImageInNewTab}>
          {UploadContent}
        </StyledUpload>

        {isImageUploaded && <Text>{text}</Text>}
      </Container>
    );
  }

  return (
    <Container>
      <StyledUpload onClick={open} {...getRootProps()}>
        <Loading show={loading} />
        {UploadContent}
      </StyledUpload>

      {isImageUploaded && <Text>{text}</Text>}

      <input id={file.id} {...getInputProps()} type="file" />
    </Container>
  );
};

const Upload = React.memo(UploadComponent);
export default Upload;
