import React from 'react';

import {
  CheckboxContainer,
  HiddenCheckBox,
  StyledCheckBox,
  Icon,
  StyledDivLabel,
  Image,
} from './styles';

interface IProps {
  id?: string;
  dataCy?: string;
  className?: string;
  classNameLabel?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelWrap?: boolean;
  labelName?: string;
  htmlForName?: string;
  size?: string;
  icon?: string
}

const InputCheckBox : React.FC<IProps> = ({
  id = '',
  dataCy,
  className,
  classNameLabel = 'StyledDivLabel',
  checked,
  labelWrap = true,
  labelName,
  htmlForName,
  size = '',
  icon,
  ...props
}) => {
  const content = (
    <CheckboxContainer className={className}>
      <HiddenCheckBox data-cy={dataCy} checked={checked} {...props} />
      <StyledCheckBox id={id} size={size} checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckBox>
      {icon && <Image src={icon} alt={icon} />}
      <StyledDivLabel data-testid="checkbox-label" className={classNameLabel} size={size} notMargin={!!icon}>{labelName}</StyledDivLabel>
    </CheckboxContainer>
  );
  return labelWrap ? (
    <label htmlFor={htmlForName}>
      {content}
    </label>
  ) : <>{content}</>;
};

export default InputCheckBox;
