import styled, { DefaultTheme, keyframes } from 'styled-components';
import { ReservationStatus } from '../../../../services/Reservation/types';

const getBackgroundStatus = (
  theme: DefaultTheme, status: ReservationStatus, blocked: boolean = false,
): string => {
  const color = {
    Concluded: theme.palette.green.main.hex(),
    Active: theme.palette.green.main.hex(),
    'In progress': theme.palette.green.main.hex(),
    'Pre-booking': theme.palette.yellow.main.hex(),
    Canceled: theme.palette.grey._550.hex(),
  };

  if (blocked) {
    return theme.palette.red._200.hex();
  }

  return color[status];
};

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  width: 500px;
  height: calc(100vh - 70px);
  overflow: hidden;
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-areas:
    'form nav'
    'buttons nav'
    ;
`;

export const FormContainer = styled.div`
  grid-area: 'form';
  animation-delay: .4s;
  animation-name: ${FadeInOpacity};
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  `;

export const CardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0;
  width: calc(100% - 1.5rem);
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  margin: 1rem;
`;

export const Wrapper = styled.div`
  line-height: 2rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  p {
    width: 80%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin: 0 0.5rem;
  }
`;

export const SmallText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
`;

export const SmallTextBold = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const MediumText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.regular}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
`;

export const MediumTextBold = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const ButtonStyled = styled.button<{ reservationStatus: ReservationStatus }>`
  width: 100%;
  background: ${({ theme, reservationStatus }) => getBackgroundStatus(theme, reservationStatus)};
  color: ${({ theme }) => theme.palette.white._400.hex()};
  border: 0;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
`;

export const PhotosContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  button: {
    width: 100%;
  }
`;

export const Photo = styled.img`
  margin-top: 0.5rem;
  width: 100px;
  height: 100px;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  border-radius: 10px;
  cursor: pointer;
`;
