import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  ReactElement,
} from 'react';

import { createContext } from 'use-context-selector';
import { PropertyOwner } from '../../../services/Owner/types';

export interface IOwnerCardProperties {
  propertiesList: PropertyOwner[];
  setPropertiesList: Function;
  handleUpdatePropertyCondition: Function;
}

export const OwnerCardPropertiesContext = createContext<IOwnerCardProperties>({
  propertiesList: [],
  setPropertiesList: () => {},
  handleUpdatePropertyCondition: () => {},
});

export const OwnerCardPropertiesProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [propertiesList, setPropertiesList] = useState<Array<PropertyOwner>>([]);

  const handleSetPropertiesList = useCallback((properties: PropertyOwner[]) => {
    setPropertiesList(properties);
  }, []);

  const handleUpdatePropertyCondition = useCallback((
    propertyId: number,
    propertyCondition: string,
    blockedUntil?: string,
  ) => {
    setPropertiesList((state) => state.map((item) => ({
      ...item,
      property_condition: item.id === propertyId ? propertyCondition : item.property_condition,
      rented_or_blocked: {
        ...item.rented_or_blocked,
        blocked_until: item.id === propertyId && blockedUntil
          ? blockedUntil : item.rented_or_blocked.blocked_until,
      },
    })));
  }, []);

  const value = useMemo(() => ({
    propertiesList,
    setPropertiesList: handleSetPropertiesList,
    handleUpdatePropertyCondition,
  }), [
    propertiesList,
    setPropertiesList,
    handleUpdatePropertyCondition,
  ]);

  return (
    <OwnerCardPropertiesContext.Provider value={value}>
      {children}
    </OwnerCardPropertiesContext.Provider>
  );
};
