import React, { useEffect } from 'react';

import { Container } from './styles';
import InsertPropertyAmenitiesDataComponent from '../../../components/InsertData/InsertPropertyAmenitiesData';
import { PropertyAmenitiesProvider } from '../../../context/InsertPropertyAmenitiesContext/InsertPropertyAmenitiesContext';

const InsertPropertyAmenitiesDataPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <PropertyAmenitiesProvider>
        <InsertPropertyAmenitiesDataComponent />
      </PropertyAmenitiesProvider>
    </Container>
  );
};

export default InsertPropertyAmenitiesDataPage;
