import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  height: 90px;

  background: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px dashed #CECECE;
  box-sizing: border-box;
  border-radius: 13px;

  :hover {
      cursor: pointer;
      filter: brightness(0.9);
      box-shadow:
                1px 1px #CECECE,
                2px 2px #CECECE,
                3px 3px #CECECE;
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
  }

  p{
    font-family: ${({ theme }) => theme.fonts.familys._1} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 113% !important;
    color: #5F5F5F;
  }

  @media(max-width: 660px){
    height: 50px;
  }
`;
