import request from '../request';
import { IAccountSimpleOwners } from './types';

export const getAccountSimpleOwners = async (search?: string) => {
  if (search) {
    const { data } = await request.get<IAccountSimpleOwners[]>(`/account/owner_simple?search=${search}`);
    return data;
  }

  const { data } = await request.get<IAccountSimpleOwners[]>('/account/owner_simple/');

  return data;
};
