import {
  FC,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import {
  postProperty,
  patchProperty,
  deleteCategoryLocation,
  getPropertyHandoverDetails,
  patchPropertyHandoverDetails,
  getPropertiesManager,
} from '../../../services/InsertData/request';

import {
  putAddress,
  postAddress,
  deleteAddress,
} from '../../../services/Address/request';

import {
  PostPropertyObject,
  PropertyDetailsResponse,
} from '../../../services/InsertData/types';

import { validation } from './utils';
import { formatPropertiesDropdown } from '../utils';
import { Number as Mask } from '../../../utils/InputMask';
import { currencyToNumber, onlyNumbers } from '../../../utils/Formatter';
import { AddressDetail } from '../../../services/Address/types';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import {
  usePropertyCategoryLocation,
} from '../../../hooks/usePropertyCategoryLocation/usePropertyCategoryLocation';

import FormButton from '../../FormButton';
import FormAddProperty from './FormAddProperty';
import UploadButton from './UploadButtons/UploadButton';
import usePropertyManager from '../../../hooks/usePropertyManager';
import DropdownAutocomplete from '../../DropdownAutocomplete/DropdownAutocomplete';

import {
  Form,
  Title,
  Divider,
  Container,
  ButtonsContainer,
} from './styles';

import {
  PropertyType,
  PropertyStatus,
  InsertDataPropertyFormik,
} from './types';
import TextField from '../../TextField';
import CopyItem from './CopyItem';
import SimpleSelect from '../../SimpleSelect';

const InsertPropertyData: FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const toastErrorRequest = useToastErrorMessage();
  const [oldId, setOldId] = useState(0);

  const isEditingForm = [
    'editardados/propriedade',
    '/editardados/propriedade',
    'editardados/propriedade/',
    '/editardados/propriedade/',
  ].includes(window.location.pathname);

  const {
    setIsNewCategoryLocation,
  } = usePropertyCategoryLocation();

  const {
    data: properties,
    loading: propertiesLoad,
  } = usePropertyManager();

  const [isLoadingProperty, setIsLoadingProperty] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(isEditingForm);
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetailsResponse>();

  const formattPropertiesToShow = useMemo(() => {
    const resolver = formatPropertiesDropdown(properties);
    return resolver;
  }, [properties]);
  const [isChurnChecked, setIsChurnChecked] = useState(false);
  const padTo2Digits = (number: number) => number.toString().padStart(2, '0');
  const formatDate = (date: Date) => [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-');

  const getContractEndDate = () => {
    if (!isEditingForm) {
      return undefined;
    } if (propertyDetails?.status === 'Inactive') {
      return moment(propertyDetails?.contract_end_date || formatDate(new Date())).toDate();
    }
    return undefined;
  };

  const initialValues: InsertDataPropertyFormik = {
    id: !isEditingForm ? undefined : (propertyDetails?.id || undefined),
    code: !isEditingForm ? '' : (propertyDetails?.code || ''),
    guestCapacity: !isEditingForm ? 0 : (propertyDetails?.guest_capacity || 0),
    property_id: !isEditingForm ? undefined : (propertyDetails?.id || undefined),
    pillowQuantity: !isEditingForm ? 0 : (propertyDetails?.pillow_quantity || 0),
    bedroomQuantity: !isEditingForm ? 0 : (propertyDetails?.bedroom_quantity || 0),
    kingBedQuantity: !isEditingForm ? 0 : (propertyDetails?.king_bed_quantity || 0),
    bathroomQuantity: !isEditingForm ? 0 : (propertyDetails?.bathroom_quantity || 0),
    lavatoryQuantity: !isEditingForm ? 0 : (propertyDetails?.lavatory_quantity || 0),
    queenBedQuantity: !isEditingForm ? 0 : (propertyDetails?.queen_bed_quantity || 0),
    status: (!isEditingForm ? '' : (propertyDetails?.status || '')) as PropertyStatus,
    singleBedQuantity: !isEditingForm ? 0 : (propertyDetails?.single_bed_quantity || 0),
    doubleBedQuantity: !isEditingForm ? 0 : (propertyDetails?.double_bed_quantity || 0),
    singleSofaBedQuantity: !isEditingForm ? 0 : (propertyDetails?.single_sofa_bed_quantity || 0),
    doubleSofaBedQuantity: !isEditingForm ? 0 : (propertyDetails?.double_sofa_bed_quantity || 0),
    propertyType: (!isEditingForm ? '' : (propertyDetails?.property_type || '')) as PropertyType,

    activationDate: !isEditingForm
      ? new Date()
      : moment(propertyDetails?.activation_date || formatDate(new Date())).toDate(),

    inactivationDate: !isEditingForm
      ? undefined
      : moment(propertyDetails?.inactivation_date || formatDate(new Date())).toDate(),

    contractStartDate: !isEditingForm
      ? new Date()
      : moment(propertyDetails?.contract_start_date || formatDate(new Date())).toDate(),

    newContractStartDate: moment(formatDate(new Date())).toDate(),
    contractEndDate: getContractEndDate(),
    categoryLocationId: !isEditingForm
      ? undefined
      : (`${propertyDetails?.category_location || undefined}`),

    city: !isEditingForm ? '' : (propertyDetails?.address?.city || ''),
    state: !isEditingForm ? '' : (propertyDetails?.address?.state || ''),
    number: !isEditingForm ? '' : (propertyDetails?.address?.number || ''),
    host: !isEditingForm ? undefined : (propertyDetails?.host || undefined),
    street: !isEditingForm ? '' : (propertyDetails?.address?.street || ''),
    country: !isEditingForm ? '' : (propertyDetails?.address?.country || ''),
    complement: !isEditingForm ? '' : (propertyDetails?.address?.complement || ''),
    postalCode: !isEditingForm ? '' : Mask.cep(propertyDetails?.address?.postal_code || ''),
    owners: !isEditingForm ? undefined : (propertyDetails?.owners?.[0] || undefined),
    hasNewOwner: !isEditingForm ? false
      : propertyDetails?.has_ownership_change_in_progress,
    neighborhood: !isEditingForm ? '' : (propertyDetails?.address?.neighborhood || ''),
    cleaningFee: !isEditingForm ? '' : Mask.currency(propertyDetails?.cleaning_fee || '0'),
    comissionFee: !isEditingForm
      ? ''
      : (Number(propertyDetails?.comission_fee || '0') * 100).toString(),

    category: !isEditingForm ? 0 : propertyDetails?.category_location || 0,
    location: !isEditingForm ? 0 : propertyDetails?.category_location || 0,
    boundAmount: !isEditingForm ? '0' : Mask.currency(propertyDetails?.bond_amount?.toString() || '0'),
    plan: !isEditingForm ? undefined : propertyDetails?.plan || undefined,
    hostReservationComissionFee: !isEditingForm ? undefined
      : (Number(propertyDetails?.host_reservation_comission_fee || 0) * 100),
    extraDayPreparation: !isEditingForm ? 0 : (propertyDetails?.extra_day_preparation || 0),
    changeContractStartDate: false,
  };

  const getContractDate = (values: InsertDataPropertyFormik) => {
    if (!values.changeContractStartDate
      && values.contractStartDate) return formatDate(values.contractStartDate);

    if (values.changeContractStartDate
      && values.newContractStartDate) return formatDate(values.newContractStartDate);

    return undefined;
  };

  const formik = useFormik<InsertDataPropertyFormik>({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      const onlyNumbersCEPformikValue = onlyNumbers(`${values.postalCode}`);

      let createdAddressId: number = -1;

      try {
        const address: AddressDetail = {
          city: values.city,
          state: values.state,
          street: values.street,
          country: values.country,
          number: values.number || '',
          postal_code: onlyNumbersCEPformikValue,
          neighborhood: values.neighborhood,
          complement: values.complement || '',
        };

        let addressResponse: AddressDetail;

        if (isEditingForm && propertyDetails?.address) {
          addressResponse = await putAddress(propertyDetails.address.id, address);
        } else {
          addressResponse = await postAddress(address);
        }

        createdAddressId = addressResponse?.id || -1;

        const property: PostPropertyObject = {
          owners: values.owners,
          code: values.code,
          category_location: Number(values?.categoryLocationId),
          address: createdAddressId,
          bathroom_quantity: values.bathroomQuantity,
          bedroom_quantity: values.bedroomQuantity,
          bond_amount: currencyToNumber(values.boundAmount),
          cleaning_fee: currencyToNumber(values.cleaningFee).toString(),
          comission_fee: (Number(values.comissionFee) / 100).toFixed(2).toString(),
          contract_start_date: getContractDate(values),
          contract_end_date: values.contractEndDate
            ? formatDate(values.contractEndDate)
            : undefined,
          double_bed_quantity: values.doubleBedQuantity,
          double_sofa_bed_quantity: values.doubleSofaBedQuantity,
          guest_capacity: values.guestCapacity,
          host: values.host,
          king_bed_quantity: values.kingBedQuantity,
          lavatory_quantity: values.lavatoryQuantity,
          pillow_quantity: values.pillowQuantity,
          property_type: values.propertyType,
          queen_bed_quantity: values.queenBedQuantity,
          single_bed_quantity: values.singleBedQuantity,
          single_sofa_bed_quantity: values.singleSofaBedQuantity,
          status: values.status,
          churn: isChurnChecked,
          host_reservation_comission_fee:
            Number((Number(values.hostReservationComissionFee) / 100).toFixed(2)),
          has_ownership_change_in_progress: values.hasNewOwner,
          extra_day_preparation: values.extraDayPreparation,
        };

        if (isEditingForm && propertyDetails?.id) {
          await patchProperty(propertyDetails.id, property);
          try {
            if (propertyDetails.handoverId) {
              await patchPropertyHandoverDetails(propertyDetails.handoverId, values.plan);
            }
          } catch (e: unknown) {
            toast.success('Não foi possível atualizar o plano!');
          }
          navigate('/editardados');
          toast.success('Propriedade atualizada com sucesso!');
        } else {
          await postProperty(property);
          navigate('/inserirdados');
          toast.success('Propriedade inserida com sucesso!');
        }

        formik.resetForm();
        setIsNewCategoryLocation(false);
      } catch (e: unknown) {
        if (createdAddressId !== -1 && !isEditingForm) {
          await deleteAddress(createdAddressId);
        }
        if (formik.values.categoryLocationId) {
          await deleteCategoryLocation(Number(formik.values.categoryLocationId));
        }
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  const filterPropertyDetails = async (id: number) => {
    setIsLoadingProperty(true);
    const allPropertiesDetails: PropertyDetailsResponse[] = await getPropertiesManager();
    const propertyHandoverDetails = await getPropertyHandoverDetails(id);
    const findedProperty = allPropertiesDetails.find((property) => property.id === id);

    if (findedProperty) {
      setPropertyDetails({
        ...findedProperty,
        plan: propertyHandoverDetails[0]?.plan || undefined,
        handoverId: propertyHandoverDetails[0]?.id,
      });
      setIsChurnChecked(findedProperty?.churn || false);
      if (findedProperty.id) {
        setOldId(findedProperty?.id);
      }
      setIsLoadingProperty(false);
    } else {
      toast.error('Não foi possível encontrar as informações da propriedade');
      setIsLoadingProperty(false);
    }
  };

  const checkSubmit = async () => {
    try {
      await validation.validate(formik.values, { abortEarly: false });
    } catch (errors) {
      formik.setSubmitting(false);

      Object.values(formik.errors).forEach((item) => {
        if (`${formik.values.postalCode}`.length >= 9 && `${item}` === 'Insira um CEP válido') {
          return;
        } 
        toast.error(`${item}`);
      });
    }
  };

  useEffect(() => {
    if (formik.isSubmitting) {
      checkSubmit();
    }
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (isEditingForm && formik.values?.property_id !== undefined) {
      if (oldId !== formik.values?.property_id) {
        filterPropertyDetails(Number(formik.values?.property_id));
      }
    }
    if (formik.values?.property_id === undefined) {
      setOldId(0);
      formik.resetForm();
    }
    setDisabled(isEditingForm && formik.values?.property_id === undefined);
  }, [formik.values.property_id]);

  useEffect(() => {
    if (isEditingForm && propertyDetails) {
      formik.setValues({
        ...initialValues,
      });
    }
  }, [propertyDetails]);

  useEffect(() => {
    const postalCode = `${formik.values.postalCode}`;
    if (postalCode.length >= 9 && `${formik.errors.postalCode}` === 'Insira um CEP válido') {
      formik.setFieldValue('postalCode', postalCode);
      formik.setFieldError('postalCode', '');
    }
  }, [formik.values.postalCode]);

  return (
    <>
      <Container>
        <div className="header">
          <div>
            <Title>{isEditingForm ? 'Editar propriedade' : 'Nova propriedade'}</Title>
          </div>
        </div>
        <div className="divider">
          <Divider />
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <div className="formContainer">
            {isEditingForm && (
              <>
                <div className="one-column">
                  <DropdownAutocomplete
                    required
                    label="Imóvel"
                    formik={formik}
                    id="property_id"
                    loading={propertiesLoad || isLoadingProperty}
                    options={formattPropertiesToShow}
                    labelClassName="labelClass"
                  />
                </div>
                <div className="two-column">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="three-column">
                      <TextField
                        required
                        label="Id"
                        formik={formik}
                        id="id"
                        value={formik.values.id}
                        labelClassName="labelClass"
                        disabled={isLoadingProperty}
                      />
                    </div>
                    <CopyItem copyItem={formik.values.id} />
                  </div>
                  <div style={{ width: '100%' }}>
                    <SimpleSelect
                      labelClassName="labelClass"
                      id="plan"
                      label="Plano"
                      placeholder="Selecione"
                      disabled={isLoadingProperty}
                      options={[
                        { value: 'Full', valueLabel: 'Full' },
                        { value: 'Mid', valueLabel: 'Mid' },
                        { value: 'Light', valueLabel: 'Light' },
                        { value: 'Digital_Management', valueLabel: 'Gestão Digital' },
                        { value: 'Essencial', valueLabel: 'Essencial' },
                        { value: 'Plus', valueLabel: 'Plus' },
                        { value: 'Pool', valueLabel: 'Pool' },
                      ].sort()}
                      formik={formik}
                    />
                  </div>
                </div>
              </>
            )}

            <FormAddProperty
              propertyDetails={propertyDetails}
              formik={formik}
              isChurnChecked={isChurnChecked}
              setIsChurnChecked={setIsChurnChecked}
              isLoadingProperty={isLoadingProperty}
              isEditingForm={isEditingForm}
            />
            {(isEditingForm && formik.values.property_id) && (
              <UploadButton propertyId={formik.values.property_id} />
            )}

          </div>
          <ButtonsContainer>
            <div>
              <FormButton
                type="button"
                variant="outlined"
                onClick={() => {
                  navigate(`${isEditingForm ? '/editardados' : '/inserirdados'}`);
                }}
              >
                Cancelar
              </FormButton>
              <FormButton type="submit" disable={disabled || formik.isSubmitting}>
                Salvar
              </FormButton>
            </div>
          </ButtonsContainer>
        </Form>
      </Container>
    </>
  );
};

export default InsertPropertyData;
