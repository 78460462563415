import React from 'react';
import { usePartners } from '../../../../../../context/Partners/Partner/PartnerContext';
import { PartnersInlineLink } from '../../../../components';
import { EnumRoutes } from '../../../../types/paths';

import {
  TitleCards,
  HeaderCards,
  CardContainerTemplate,
} from '../utils';

import {
  Value,
  Gains,
  Content,
  SubTitle,
  Container,
  Separator,
  InProgress,
  SideContent,
  GainsContainer,
  InProgressContainer,
} from './style';

const Indications = () => {
  const { resumeIndications } = usePartners();

  return (
    <CardContainerTemplate>
      <Container>

        <HeaderCards>
          <TitleCards>Minhas indicações</TitleCards>
          <PartnersInlineLink to={`/parceiros/${EnumRoutes.indications}`}>
            Detalhes
          </PartnersInlineLink>
        </HeaderCards>

        <Content>
          <InProgressContainer>
            <SideContent>
              <InProgress>
                <Value>{resumeIndications.inProgress}</Value>
              </InProgress>
              <SubTitle>Em andamento</SubTitle>
            </SideContent>
          </InProgressContainer>

          <Separator />

          <GainsContainer>
            <SideContent>
              <Gains>
                <Value>{resumeIndications.gains}</Value>
              </Gains>
              <SubTitle>Ganhos</SubTitle>
            </SideContent>
          </GainsContainer>
        </Content>
      </Container>
    </CardContainerTemplate>
  );
};

export default Indications;
