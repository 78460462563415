import React from 'react';
import { Container } from './style';

const CardContainerTemplate = ({ children }: { children: React.ReactNode }) => (
  <Container
    initial={{
      y: 10,
      opacity: 0,
    }}
    animate={{
      y: 0,
      opacity: 1,
    }}
    transition={{
      delay: 0.3,
    }}
  >
    {children}
  </Container>
);

export default CardContainerTemplate;
