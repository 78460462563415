import { useEffect, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { motion, Variants } from 'framer-motion';
import {
  ArrowsButton,
  ArrowsContainer,
  LinkText,
  TableContainer,
  Td,
} from './styles';

import { useGuestDamage } from '../../../../../../hooks/GuestDamage/useGuestDamage';
import { numberToCurrency } from '../../../../../../utils/Formatter';
import FormButton from '../../../../../FormButton';
import { GetGuestDamage } from '../../../../../../services/GuestDamage/types';
import { useUser } from '../../../../../../context/UserContext';
import { compareList } from '../../../../../../utils/Sorting';

const Arrows = () => (
  <ArrowsContainer>
    <KeyboardArrowUp style={{ marginBottom: '-6px' }} />
    <KeyboardArrowDown style={{ marginTop: '-6px' }} />
  </ArrowsContainer>
);

const tableVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 120, transition: { duration: 0.2 } },
};
const theadVariants: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};
const tbodyVariants: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.2,
    },
  },
};

const animationDiv: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const animationDivWithDelay: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';
type Order = 'asc' | 'desc';

export const DamageResumeTable = () => {
  const { handleOpenFile, handleOpenLink } = useGuestDamage();
  const { reservationGuestDamages, setSelectedGuestDamage } = useGuestDamage();
  const { userInformation } = useUser();
  const roles = userInformation?.roles!;
  const userCanEditDetails = roles.includes('Host') || roles.includes('Attendant');
  const [sortedBy, setSortedBy] = useState<GuestDamageSortedBy>('type');
  const [order, setOrder] = useState<Order>('asc');
  const [sortedGuestDamages,
    setSortedGuestDamages] = useState<GetGuestDamage[]>(reservationGuestDamages);

  function handleSortGuestDamage(sortBy: GuestDamageSortedBy) {
    switch (sortBy) {
      case 'type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.damage_type, b.damage_type, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_type || '', b.item_type || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_name': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name, b.item_name, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name, b.item_name, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_name');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_name, b.item_name, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'resolution': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution, b.resolution, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution, b.resolution, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('resolution');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.resolution, b.resolution, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'refund_holder': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('refund_holder');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.refund_holder || '', b.refund_holder || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_quantity': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_quantity');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_quantity, b.item_quantity, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'price': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('price');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            (a?.item_quantity * (a?.item_price || 0)) || '-',
            (b?.item_quantity * (b?.item_price || 0)) || '-',
            'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      default: {
        const sortedNegotiations = reservationGuestDamages.sort((a, b) => compareList(
          a.damage_type, b.damage_type, 'asc',
        ));
        setSortedGuestDamages(sortedNegotiations);
        break;
      }
    }
  }

  useEffect(() => {
    handleSortGuestDamage('type');
  }, [reservationGuestDamages]);

  function handleSelectedGuestDamage(guestDamage: GetGuestDamage) {
    setSelectedGuestDamage(guestDamage);
  }

  return (
    <TableContainer>
      <motion.table
        variants={tableVariants}
        initial="closed"
        animate="open"
        key="table"
      >
        <motion.thead variants={theadVariants} initial="hidden" animate="visible">
          <tr>
            <th>
              <motion.div variants={animationDiv} key="1">
                Tipo de dano
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('type')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="2">
                Classificação
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('item_type')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="3">
                Item
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('item_name')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="4">
                O que precisa ser feito
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('resolution')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="5">
                Evidencias
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="6">
                NF / Comprovante
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="8">
                Link de Cotação
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="7">
                Dono do Reembolso
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('refund_holder')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="9">
                Quantidade
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('item_quantity')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="8">
                Valor
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('price')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            {userCanEditDetails && (
              <th> </th>
            )}
          </tr>
        </motion.thead>
        <motion.tbody
          variants={tbodyVariants}
          initial="hidden"
          animate="visible"
        >
          {sortedGuestDamages.map((guestDamage) => (
            <motion.tr key={guestDamage.id} variants={animationDivWithDelay}>
              <td>
                <div>
                  {guestDamage?.damage_type || '-'}
                </div>
              </td>
              <td>
                {guestDamage?.item_type || '-'}
              </td>
              <td>
                {guestDamage?.item_name || '-'}
              </td>
              <td>
                {guestDamage?.resolution || '-'}
              </td>
              <td style={{ cursor: 'pointer' }}>
                <div style={{
                  display: 'flex', flexDirection: 'column', gap: '0.5rem',
                }}
                >
                  {guestDamage.evidences
                    && guestDamage?.evidences?.length > 0
                    ? guestDamage.evidences?.map((evidence) => (
                      <LinkText
                        onClick={() => handleOpenFile(evidence.evidence?.url!,
                          evidence.evidence?.name)}
                      >
                        {evidence.evidence?.name}
                      </LinkText>
                    ))
                    : ('-')}
                </div>
              </td>
              <Td
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenFile(guestDamage.quotation_file?.url!,
                  guestDamage.quotation_file?.name!)}
              >
                <>
                  {guestDamage.quotation_file?.name ? (
                    <LinkText>
                      {guestDamage.quotation_file.name}
                    </LinkText>
                  ) : '-'}
                </>
              </Td>
              {guestDamage?.quotation_link ? (
                <Td
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenLink(guestDamage?.quotation_link!)}
                >

                  <LinkText>Clique aqui para acessar</LinkText>
                </Td>
              ) : (<td>-</td>)}
              <td>
                {guestDamage?.refund_holder || '-'}
              </td>
              <td>
                {guestDamage?.item_quantity || '-'}
              </td>
              <td>
                {numberToCurrency((guestDamage?.item_quantity * (guestDamage?.item_price || 0))) || '-'}
              </td>
              {userCanEditDetails && (
                <td>
                  <FormButton
                    isFull
                    variant="outlined"
                    customColor="blue-dark"
                    type="button"
                    onClick={() => handleSelectedGuestDamage(guestDamage)}
                  >
                    Selecionar
                  </FormButton>
                </td>
              )}
            </motion.tr>
          ))}
        </motion.tbody>
      </motion.table>
    </TableContainer>

  );
};
