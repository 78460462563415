import { IListItems } from '../../components/OwnerTedList/types';
import { translatedAccountType } from '../../utils/Translator';
import request from '../request';

import {
  IGetOwnerTeds,
  ISyncOwnerTeds,
  IGetOwnerTedsParams,
  IVerifyTaskStatus,
  IConcludedCardsParams,
  IGenFileIdDownloadOwnerTedListCSV,
  IGetOwnerTedsResponse,
  ISendOwnerTedVouchers,
  IPutOwnerTeds,
} from './types';

export const syncOwnerTeds = async ({
  period,
}: IPutOwnerTeds): Promise<ISyncOwnerTeds> => {
  const { data } = await request.put('/financial_closing/property_owner/sync_teds/', {
    period,
  });
  return data;
};

export const verifyTaskStatus = async (taskID: string): Promise<IVerifyTaskStatus> => {
  const { data } = await request.get(`/tasks/${taskID}`);
  return data;
};

export const getOwnerTeds = async ({
  status,
  period,
  termToFilter,
  transfer_day,
  transfer,
}: IGetOwnerTedsParams): Promise<IGetOwnerTedsResponse> => {
  const { data } = await request.get('/financial_closing/owner/teds/', {
    params: {
      period,
      status,
      q: termToFilter,
      transfer_day,
      transfer,
    },
  });

  const formatDataToListItem: IListItems[] = data.map((item: IGetOwnerTeds, index: number) => ({
    id: item?.id || index + 1,
    name: item?.owner?.user?.name || item?.bank_details?.entity_name || 'Não informado',
    nameToTed: item?.owner?.user?.name || item?.bank_details?.entity_name || 'Não informado',
    transfer: item?.transfer || 'Não informado',
    cpf: item?.bank_details?.cpf || 'Não informado',
    bank: item?.bank_details?.bank.long_name || 'Não informado',
    agency: item?.bank_details?.branch_number || 'Não informado',
    account: item?.bank_details?.account_number || 'Não informado',
    pixKey: item?.bank_details?.pix_key || 'Não informado',
    pixKeyType: item?.bank_details?.pix_key_type || 'Não informado',
    status: item?.status || 'Não informado',
    data: item?.date_ref || new Date(),
    cnpj: item?.bank_details?.cnpj || 'Não informado',
    accountType: translatedAccountType[item?.bank_details?.account_type],
    bankNumber: item?.bank_details?.bank.bank_number || 'Não informado',
    statmentImage: item?.statement_image,
    transferDay: item?.owner.transfer_day || -1,
    checked: false,
  }));

  return { data, formatDataToListItem };
};

export const changeOwnerTedStatus = async ({
  ted_status,
  ted_ids,
}: IConcludedCardsParams) => {
  const { data } = await request.patch('/financial_closing/owner/teds/', {
    status: ted_status,
    ids: ted_ids,
  });

  return data;
};

export const genFileIdTodownloadTedListCSV = async ({
  status,
  period,
  // termToFilter,
}: IGetOwnerTedsParams): Promise<IGenFileIdDownloadOwnerTedListCSV> => {
  const { data } = await request.put('/financial_closing/owner/generate_teds_csv/', {
    status,
    period,
    // q: termToFilter,
  });

  return data;
};

export const sendTedVouchers = async ({
  tedID,
  fileUID,
}: ISendOwnerTedVouchers) => {
  const { data } = await request.patch('/financial_closing/owner/teds/', {
    ids: [tedID],
    statement_image: fileUID,
  });

  return data;
};
