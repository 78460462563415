import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const OptionsContainer = styled.div``;

export const ListOptions = styled.ul`
  padding: inherit;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Option = styled.p`
  font-weight: medium;
  font-size: 1.5rem;
  list-style: none;
  color: #ffff;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover{
    text-decoration: underline;
  }

  &:focus{
    transform: translateX(10px);
  }
`;
