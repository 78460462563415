import styled from 'styled-components';

export const CopyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;

export const Link = styled.p`
  font-family: 'Inter';
  color: #0D4BD0;
  text-decoration: underline;
  cursor: pointer;
`;

export const CopyContainer = styled.img``;
