/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { DeleteForeverOutlined } from '@mui/icons-material';

import {
  Content,
  Container,
  PaymentContainer,
  BashIconContainer,
} from './styles';

import { useToast } from '../../../../../../../context/ToastContext';
import { deletePaymentData } from '../../../../../../../services/PaymentVoucher/request';
import { PaymentDataProps } from '../../../../../../../context/PaymentVoucher/types/paymentData.types';
import { usePaymentVoucher } from '../../../../../../../hooks/PaymentVoucherHook/usePaymentVoucher';

interface PaymentDoProps {
  id: number;
  date?: string;
  index: number;
  amount?: number | string;
}

const PaymentDo = ({
  id,
  date,
  index,
  amount,
}: PaymentDoProps) => {
  const toast = useToast();

  const { paymentData, handleChangePaymentData } = usePaymentVoucher();

  const deleteIconStyle = {
    color: '#E35B4C',
  };

  const handleRemovePaymentFromState = () => {
    const newPaymentData = paymentData.filter((payment: PaymentDataProps) => payment.id !== id);

    handleChangePaymentData(newPaymentData);
  };

  const handleDeletePayment = async () => {
    try {
      await deletePaymentData(id);
      handleRemovePaymentFromState();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Container>
      <Content>
        <PaymentContainer>
          <h1>
            Parcela
            {' '}
            {index}
          </h1>
          <h1>{date}</h1>
          <h1>
            {amount}
          </h1>
          <BashIconContainer>
            <DeleteForeverOutlined
              style={deleteIconStyle}
              onClick={() => handleDeletePayment()}
            />
          </BashIconContainer>
        </PaymentContainer>
      </Content>
    </Container>
  );
};

PaymentDo.defaultProps = {
  amount: 'R$ 3.100.00',
  date: '02 / Jun / 2021',
};

export default PaymentDo;
