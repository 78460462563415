import { FileProps } from '../../../context/FileContext/types';

export type OrderGridBy =
  'name'
  | 'nameToTed'
  | 'transfer'
  | 'cpf'
  | 'bank'
  | 'cnpj'
  | 'accountType'
  | 'bankNumber'
  | 'agency'
  | 'account'
  | 'pixKey'
  | 'pixKeyType'
  | 'status'
  | 'transferDay';

export type OrderBy = OrderGridBy;
export type Order = 'asc' | 'desc';

export type OrderGridOptions = {
  order_by: OrderBy,
  order: Order,
};

export type StatusProps = 'Not_Concluded' | 'Concluded' | 'Pending' | 'Exception';
export type IStatementImage = FileProps | any;

export enum EnumStatus {
  'notDone' = 'Not_Concluded',
  'done' = 'Concluded',
  'withAdjustments' = 'Pending',
  'exception' = 'Exception',
}

export enum EnumStatusColors {
  'notDone' = '#FF2732',
  'done' = '#41B592',
  'withAdjustments' = '#FF9900',
  'exception' = '#4B68FF',
}

export interface IListItems {
  id: number;
  name: string;
  nameToTed: string;
  transfer: string;
  cpf: string;
  bank: string;
  cnpj: string;
  accountType: string;
  bankNumber: string;
  agency: string;
  account: string;
  pixKey: string;
  pixKeyType: string;
  status: string;
  data: Date | string;
  statementImage: IStatementImage;
  transferDay: number;
}
