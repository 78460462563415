import React, { useEffect, useState } from 'react';
import { getOwners } from '../../services/Owner/request';
import Header from './Header';
import LineGrid from './LineGrid';
import { GetOwnerRequest } from '../../services/Owner/types';

import { Container, Content, Wrapper } from './styles';
import HeaderPage from './HeaderPage';
import ModalMoreInformation from './ModalMoreInformation';

const ViewOwnersComponent: React.FC = () => {
  const [listOwners, setListOwner] = useState<Array<GetOwnerRequest>>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ownerSelected, setOwnerSelected] = useState<GetOwnerRequest>({} as GetOwnerRequest);
  const [typeSort, setTypeSort] = useState('a-z');

  useEffect(() => {
    async function getListHost() {
      const result = await getOwners();
      setListOwner(result);
    }
    getListHost();
  }, []);

  function handleSort() {
    if (listOwners && typeSort === 'a-z') {
      const strAscending = [...listOwners].sort((a, b) => (
        (a.user.first_name + a.user.last_name ?? a.user.corporate_name)
        > (b.user.first_name + b.user.last_name ?? b.user.corporate_name)
          ? 1 : -1));
      setListOwner(strAscending);
      setTypeSort('z-a');
    } else if (listOwners) {
      const strAscending = [...listOwners].sort((a, b) => (
        (a.user.first_name + a.user.last_name ?? a.user.corporate_name)
        < (b.user.first_name + b.user.last_name ?? b.user.corporate_name)
          ? 1 : -1));
      setListOwner(strAscending);
      setTypeSort('a-z');
    }
  }

  return (
    <Wrapper>
      <HeaderPage />
      <Container>
        <Header onClick={handleSort} />
        <Content>
          {listOwners?.map((item) => (
            <LineGrid
              owner={item}
              setOpenModal={() => setOpenModal(true)}
              setOwnerSelected={setOwnerSelected}
            />
          ))}
        </Content>
      </Container>
      <ModalMoreInformation ownerSelected={ownerSelected} onClose={setOpenModal} open={openModal} />
    </Wrapper>
  );
};

export default ViewOwnersComponent;
