import { compareList } from '../../../utils/Sorting';
import { GetPropertiesResponse } from '../../../services/InsertData/types';

export function translateStatus(status: string) {
  switch (status) {
    case 'Active': {
      return 'Ativo';
    }
    case 'Inactive': {
      return 'Inativo';
    }
    case 'Onboarding': {
      return 'Onboarding';
    }
    default: {
      return '';
    }
  }
}

export const formatPropertiesDropdown = (filteredProperties?: GetPropertiesResponse[]) => {
  if (!filteredProperties) return [];

  const formattedData = filteredProperties?.map((property) => ({
    optionText: `${property.code} ${translateStatus(property.status)}`,
    optionValue: `${property.id}`,
  }));

  const sortData = formattedData?.sort((a, b) => compareList(a.optionText, b.optionText));
  return sortData;
};

export const requestErrorMessageTranslated = (message: string) => {
  switch (`${message}`.toLowerCase().trim()) {
    case 'active reservation in date of replacement':
      return 'Não é possível realizar a troca dos anfitriões pois há uma reserva ativa no período selecionado para a entrega da chave.';
    default:
      return 'Ocorreu um erro inesperado, tente novamente mais tarde!';
  }
};
