/* eslint-disable max-len */
import { useState, useRef } from 'react';
import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';

import { BankDetails, PropertyInvoiceBank } from '../../../services/Owner/types';

import SimpleSlider from '../../Carousel/SimpleSlider';
import AccountBankCard from './AccountBankCard';
import ButtonAddAccount from './ButtonAddAccount';
import AlertMessage from './AlertMessage';
import ModalNewBankAccount from '../Modal/ModalNewBankAccount';
import ModalConfirmDeleteBankAccount from '../Modal/ModalConfirmDeleteBankAccount';

import {
  Container,
  Content,
  TitleContainer,
  Title,
  ContentMobile,
  AlertMessageContainer,
} from './styles';

export type BankAccountStateProps = {
  bank: BankDetails | null,
  state: 'viewing' | 'adding' | 'editing' | 'deleting'
};

interface IAccountManager {
  isViewMode: boolean,
  listAccountsBank: Array<BankDetails>,
  getListBankAccountOwner: () => void,
  getPropertyBankByID: (a: number) => PropertyInvoiceBank | undefined;
}

const AccountBankManager = ({
  isViewMode,
  listAccountsBank = [],
  getListBankAccountOwner,
  getPropertyBankByID,
}: IAccountManager) => {
  const { isMobile } = useScreenResize();
  const sliderRef = useRef<any>(null);

  const [bankAccountState, setBankAccountState] = useState<BankAccountStateProps>({
    bank: null,
    state: 'viewing',
  });

  return (
    <Container>
      <TitleContainer>
        <Title>Gerenciamento de contas bancárias</Title>
        {!isViewMode && !isMobile && (
          <ButtonAddAccount onClick={() => setBankAccountState({
            state: 'adding',
            bank: null,
          })}
          />
        )}
      </TitleContainer>

      <AlertMessageContainer>
        <AlertMessage />
      </AlertMessageContainer>

      {!isMobile && (
        <Content>
          <SimpleSlider
            sliderRef={sliderRef}
            settings={{
              dots: false,
              arrows: listAccountsBank.length > 2,
              infinite: false,
              speed: 500,
              slidesToScroll: 1,
              slidesToShow: 2.5,
              draggable: listAccountsBank.length > 2,
            }}
          >
            {listAccountsBank.map((item) => (
              <AccountBankCard
                key={item.id}
                bankAccountState={item}
                setBankAccountState={setBankAccountState}
              />
            ))}
          </SimpleSlider>
        </Content>
      )}

      {isMobile && (
        <ContentMobile>
          <SimpleSlider
            sliderRef={sliderRef}
            settings={{
              dots: false,
              arrows: false,
              infinite: false,
              speed: 500,
              slidesToScroll: 1,
              draggable: listAccountsBank.length > 2,
              slidesToShow: 1.2,
            }}
          >
            {listAccountsBank.map((item) => (
              <AccountBankCard
                key={item.id}
                bankAccountState={item}
                setBankAccountState={setBankAccountState}
              />
            ))}
          </SimpleSlider>
        </ContentMobile>
      )}

      {!isViewMode && isMobile && (
        <ButtonAddAccount onClick={() => setBankAccountState({
          state: 'adding',
          bank: null,
        })}
        />
      )}

      {['adding', 'editing'].includes(bankAccountState.state) && (
        <ModalNewBankAccount
          bankAccountState={bankAccountState}
          setBankAccountState={setBankAccountState}
          getPropertyBankByID={getPropertyBankByID}
          updateListBank={getListBankAccountOwner}
        />
      )}

      {bankAccountState.state === 'deleting' && (
        <ModalConfirmDeleteBankAccount
          bankAccountState={bankAccountState}
          setBankAccountState={setBankAccountState}
          updateListBank={getListBankAccountOwner}
          sliderRef={sliderRef}
        />
      )}
    </Container>
  );
};

export default AccountBankManager;
