import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  gap: 10px;

  p {
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;

    strong {
      font-family: Inter;
      font-weight: 700;
      font-size: 12px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 17.23px;
    color: #616161;
    padding: 0 8px;
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const Page = styled.div`
  width: 26px;
  height: 37px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.blue._20.hex()};
  font-size: 14px;
  font-weight: 700;
  line-height: 17.23px;
  color: #616161;
`;
