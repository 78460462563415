import React, {
  createContext, useState, ReactNode, useContext,
} from 'react';

interface SendingVoucherContextData {
  sendingVoucher: boolean;
  setSendingVoucher: (a: boolean) => void;
  idReservation: number;
  setIdReservation: (a: number) => void;
  idReservationVoucher: number;
  setIdReservationVoucher: (a: number) => void;
  reload: number;
  setReload: (a: number) => void;
  nameGuest: string;
  setNameGuest: (a: string) => void;
  reloadConfirm: number;
  setReloadConfirm: (a: number) => void;
}

interface Props {
  children: ReactNode;
}

const SendingVoucherContext = createContext<SendingVoucherContextData>({} as
  SendingVoucherContextData);

export const SendingVoucherProvider = ({
  children,
}: Props) => {
  const [sendingVoucher, setSendingVoucher] = useState<boolean>(false);
  const [idReservation, setIdReservation] = useState<number>(0);
  const [idReservationVoucher, setIdReservationVoucher] = useState<number>(0);
  const [reload, setReload] = useState<number>(0);
  const [nameGuest, setNameGuest] = useState<string>('');
  const [reloadConfirm, setReloadConfirm] = useState<number>(0);

  return (
    <SendingVoucherContext.Provider value={{
      sendingVoucher,
      setSendingVoucher,
      idReservation,
      setIdReservation,
      idReservationVoucher,
      setIdReservationVoucher,
      reload,
      setReload,
      nameGuest,
      setNameGuest,
      reloadConfirm,
      setReloadConfirm,
    }}
    >
      {children}
    </SendingVoucherContext.Provider>
  );
};

export function useSendingVoucher(): SendingVoucherContextData {
  const {
    sendingVoucher,
    setSendingVoucher,
    idReservation,
    setIdReservation,
    idReservationVoucher,
    setIdReservationVoucher,
    reload,
    setReload,
    nameGuest,
    setNameGuest,
    reloadConfirm,
    setReloadConfirm,
  } = useContext(SendingVoucherContext);
  return {
    sendingVoucher,
    setSendingVoucher,
    idReservation,
    setIdReservation,
    idReservationVoucher,
    setIdReservationVoucher,
    reload,
    setReload,
    nameGuest,
    setNameGuest,
    reloadConfirm,
    setReloadConfirm,
  };
}
