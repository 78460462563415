import { UseFormik } from '../../../../utils/Formik/types';
import {
  B2BContainerCondiminiumName,
  B2BFieldsContainer,
  B2BScroll,
} from './styles';

import TextField from '../../../TextField';
import { useIBGE } from '../../../../hooks/useIBGE/useIBGE';
import SimpleSelect from '../../../SimpleSelect';

interface FormAddB2BCondominiumProps {
  formik: UseFormik<any>
}

export const FormAddB2BCondominium = ({ formik }:FormAddB2BCondominiumProps) => {
  const { states } = useIBGE({
    uf: formik.values.state,
  });

  return (
    <B2BScroll>
      <B2BContainerCondiminiumName>
        <TextField
          id="condominiumName"
          formik={formik}
          label="Nome do Condomínio"
          labelClassName="labelClass"
          required
          requireSymbolPosition="right"
        />
      </B2BContainerCondiminiumName>
      <B2BFieldsContainer>
        <TextField
          id="street"
          formik={formik}
          label="Rua"
          labelClassName="labelClass"
          required
          requireSymbolPosition="right"
        />
        <TextField
          id="number"
          formik={formik}
          label="Número"
          labelClassName="labelClass"
          type="number"
          required
          requireSymbolPosition="right"
        />
        <TextField
          id="complement"
          formik={formik}
          label="Complemento"
          labelClassName="labelClass"
        />
        <TextField
          id="neighborhood"
          formik={formik}
          label="Bairro"
          labelClassName="labelClass"
          required
          requireSymbolPosition="right"
        />
        <TextField
          id="city"
          formik={formik}
          label="Cidade"
          labelClassName="labelClass"
          required
          requireSymbolPosition="right"
        />
        <SimpleSelect
          labelClassName="labelClass"
          required
          label="Estado"
          id="state"
          placeholder="Selecione"
          formik={formik}
          options={(states?.data || [])?.map((item) => ({
            value: item.sigla,
            valueLabel: item.nome,
          }))}
        />
        <TextField
          id="postal_code"
          mask="cep"
          formik={formik}
          label="CEP"
          labelClassName="labelClass"
          required
          requireSymbolPosition="right"
        />
        <TextField
          id="country"
          formik={formik}
          label="País"
          labelClassName="labelClass"
          required
          requireSymbolPosition="right"
        />
      </B2BFieldsContainer>
    </B2BScroll>
  );
};
