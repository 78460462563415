import {
  Divider,
  Zoom,
  styled,
  TooltipProps,
  tooltipClasses,
  Tooltip as MuiTooltip,
} from '@mui/material';
import React from 'react';

import { Info, OpenInNew } from '@mui/icons-material';

import {
  Content, Section, Backdrop,
} from './style';
import { BottomSideContainer, Link } from '../styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    margin: 10,
    maxWidth: 220,
    maxHeight: 300,
    lineHeight: 1.3,
    overflowY: 'scroll',
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #dadde9',

    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
  },
}));

interface ITooltip {
  open: boolean;
  setOpen: Function;
  address: string;
  handleGotToAddress: Function;
}

const Tooltip = ({
  open,
  setOpen,
  address,
  handleGotToAddress,
}: ITooltip) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <HtmlTooltip
        open={open}
        enterTouchDelay={0}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleClose}
        title={(
          <Content>
            <Section>
              <h1>Endereço completo:</h1>
              <h2>{address}</h2>
            </Section>
            <Divider />
            <Section>
              <h1>Abrir endereço no Google Maps</h1>
              <BottomSideContainer onClick={() => handleGotToAddress()}>
                <Link>Abrir mapa</Link>
                <OpenInNew style={{ color: '#1D9BE1', fontSize: '16px' }} />
              </BottomSideContainer>
            </Section>
          </Content>
        )}
        TransitionComponent={Zoom}
        PopperProps={{ disablePortal: false }}
        style={{ opacity: 0.8, fontSize: 20 }}
      >
        <Info
          id="info"
          style={{ fill: 'rgba(0,0,0,0.5)', cursor: 'pointer' }}
          onClick={handleOpen}
        />
      </HtmlTooltip>
      {open && <Backdrop onClick={handleClose} />}
    </>
  );
};

export default Tooltip;
