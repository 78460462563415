import Main from '../../components/HostProperties';
import { CreateBlockPropertyHostPageProvider } from '../../context/CreateBlockPropertyInHostPage/CreateBlockPropertyInHostpage';
import { HostPropertiesProvider } from '../../context/HostProperties/HostPropertiesContext';
import { ModalProvider } from '../../context/ModalContext';

import { Container } from './styles';

const HostProperties = () => (
  <Container>
    <HostPropertiesProvider>
      <ModalProvider>
        <CreateBlockPropertyHostPageProvider>
          <Main />
        </CreateBlockPropertyHostPageProvider>
      </ModalProvider>
    </HostPropertiesProvider>
  </Container>
);

export default HostProperties;
