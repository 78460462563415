import React from 'react';
import { Fab } from '@mui/material';
import Tooltip from '../Tooltip';
import { Container } from './styles';
import whatsappIcon from '../../assets/icons/controll/whatsapp.svg';
import { useAnalytics } from '../../hooks/useAnalytics';

interface IWhatsappFloatButton {
  phoneNumber: string;
}

const WhatsappFloatButton: React.FC<IWhatsappFloatButton> = ({
  phoneNumber,
}) => {
  const { dispatchEvent } = useAnalytics();

  const handleClick = () => {
    dispatchEvent({ action: 'Clicou em falar com o cs, no botão flutuante de whatsapp' });
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
  };

  return (
    <Container onClick={handleClick}>
      <Tooltip text="Fale com o time de atendimento">
        <Fab style={{ background: '#1a8d44' }}>
          <div>
            <img src={whatsappIcon} alt="" />
          </div>
        </Fab>
      </Tooltip>
    </Container>
  );
};

export default WhatsappFloatButton;
