import styled, { keyframes } from 'styled-components';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 0 10px 0 0;
`;

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1.1fr 1.1fr 1.1fr 1.2fr 2fr;
  margin-top: 20px;
  padding: 0 10px;
  width: 100%;
  justify-content: center;
  user-select: none;

  @media (max-width: 1400px) {
    grid-template-columns: 2fr 1.5fr 2fr 2fr 3fr 4fr;
  }
`;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;

  h1 {
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.1rem;
    font-weight: normal;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2px;

    img {
      width: 7px;
    }
  }

  .titleContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    justify-content: space-around;
  }


`;

export const ItemLine = styled.div``;

export const ReservationCode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  opacity: 0;
  animation: ${showItems} 0.6s 0.4s ease forwards;
`;

export const ImmobileCode = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.5s ease forwards;
`;

export const ReservationDate = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.6s ease forwards;
`;

export const ReservationStaff = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.7s ease forwards;
`;

export const WhatsApp = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.8s ease forwards;
`;

export const ReservationOta = styled.div`
  display: flex;
  opacity: 0;
  animation: ${showItems} 0.6s 0.7s ease forwards;
`;

export const CheckIn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: ${showItems} 0.6s 0.9s ease forwards;

  position: absolute;
  right: 100px;
  .titleContainer h1 {
    margin-top: 0.5rem;
  }
  .titleContainer .arrowContainer {
    margin-top: 0.5rem;
  }
`;
