import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey._340.hex()};
  padding: 3rem 5rem;
  margin-top: 1rem;
  border-radius: 10px;
  h1, h2, p, span, strong, button {
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
  button {
    cursor: pointer;
  }
  h1 {
    font-weight: 700;
    font-size: 1.5rem;
  }
  @media (max-width: 900px) {
    padding: 1rem;
  }
`;

export const WrapperCalendar = styled.div`
  position: fixed;
  overflow-x: scroll;
  right: 2rem;
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px){
    flex-direction: column;
    gap: 1rem;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.7rem;
  width: 55%;
  
  @media (max-width: 900px){
    gap: 0rem;
    width: calc(100vw - 102px);
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SelectorContainer = styled.div`
  @media (max-width: 900px) {
    .MuiOutlinedInput-root {
      width: calc(100vw - 102px);
    }
  }
`;

export const Title = styled.h1`
  text-align: start;
  padding-top: 0.5rem;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
`;

export const WrapperReservation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  @media (max-width: 900px){
    gap: 0.5rem;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const ContentReservation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    padding-left: 0.3rem;
    font-size: 1rem;
  }
  strong {
    padding: 0 0.3rem;
    font-size: 1rem;
  }
`;

export const Circle = styled.div<{ status: string }>`
  width: 15px;
  height: 15px;
  background: ${({ theme }) => theme.palette.orange._600.hex()};
  background: ${({ theme, status }) => (status === 'available' && theme.palette.green.main.hex())};
  background: ${({ theme, status }) => (status === 'rate' && theme.palette.blue._920.hex())};
  border-radius: 50%;
`;

export const WrapperMonth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin-right: 24px;
  
  svg {
    color: ${({ theme }) => theme.palette.red._450.hex()};
    margin: 0 0.5rem;
    cursor: pointer;
  }
  @media (max-width: 1150px){
    margin-right: 0px;
    width: 100%;
  }
`;

export const ButtonMonth = styled.button`
  width: 200px;
  height: 40px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 0.7rem;
  border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  font-size: 1.4rem;
  font-weight: bold;
  @media (max-width: 1150px){
    width: 100%;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
  }
  @media (max-width: 1150px){
    width: 100%;
  }
`;

export const ContainerCalendar = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  margin: 1rem 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  overflow-x: hidden;
`;

export const DaysOfWeek = styled.div`
  background: ${({ theme }) => theme.palette.blue._950.hex()};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-areas: 'days-grid';

  @media (max-width: 795px){
    height: 50px;
  }
`;

export const DayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  grid-area: 'days-grid';

  @media (max-width: 795px){
    height: 50px;
  }
`;

export const DateGrid = styled.div`
  width: 100%;
  display: grid;
  overflow: hidden;

  grid-template-columns: repeat(7, 1fr);
  grid-template-areas: 'date-grid';
`;

export const DateContainer = styled.div<{ isLastDay?: boolean, disableSelect?: boolean, isPast?: boolean, isFuture?: boolean }>`
  ${(props) => props.isLastDay && css`
    overflow: hidden;
  `}

  ${(props) => !props.disableSelect && !props.isPast && !props.isFuture && css`
    cursor: pointer;
  `}

  ${(props) => (props.isPast || props.isFuture) && css`
    strong {
      color: ${({ theme }) => theme.palette.grey._440.hex()};
    }

    span {
      color: ${({ theme }) => theme.palette.grey._420.hex()};
    }
  `}

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110px;
  grid-area: 'date-grid';
  position: relative;
  border: 0.3px solid ${({ theme }) => theme.palette.grey._400.hex()};
  background: ${({ theme, isPast, isFuture }) => ((isPast || isFuture) ? theme.palette.grey._300.hex() : theme.palette.white.main.hex())};
  
  && > .day-number {
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.5rem 0 0 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  && > .daily-price {
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0 0.5rem 0.5rem 0;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  && > .reservation-marker {
    justify-content: center;
    align-items: center;
    margin-top: 2.2rem;
    position: absolute;

    @media (max-width: 795px){
      margin-top: 0;
      padding-top: 18px;

      button {
        height: 25px;
        left: 5px !important;
      }
  }
  }

  @media (max-width: 795px){
    height: 100px;
  }
`;

export const DayContent = styled.strong<{ isWeekend?: boolean, mobile?: boolean }>`
  font-size: ${({ mobile }) => (!mobile ? 1.25 : 0.9)}rem;
  color: ${({ theme, isWeekend }) => (isWeekend ? theme.palette.red._500.hex() : theme.palette.white.main.hex())};
`;

export const DayNumber = styled.strong<{ mobile?: boolean }>`
  font-size: ${({ mobile }) => (!mobile ? 1.25 : 0.9)}rem;
  color: ${({ theme }) => theme.palette.blue._950.hex()};
  `;

export const DailyPrice = styled.span<{ mobile?: boolean }>`
  font-size: ${({ mobile }) => (!mobile ? 0.9 : 0.55)}rem;
  color: ${({ theme }) => theme.palette.grey._450.hex()};
  display: none;
`;
