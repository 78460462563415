import {
  SeazoneResumeContainer, TotalContainer, TotalText,
} from './styles';
import { SeazoneResumeTable } from './SeazoneResumeTable';
import { GetGuestDamage } from '../../../../../services/GuestDamage/types';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';

interface SeazoneResumeProps {
  seazoneReservationGuestDamages: GetGuestDamage[];
  handleSortGuestDamage: (sortBy: GuestDamageSortedBy) => void;
}

export const SeazoneResume = ({
  seazoneReservationGuestDamages,
  handleSortGuestDamage,
}:SeazoneResumeProps) => {
  const { reservationGuestDamages } = useGuestDamage();
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const seazoneTotalValue = reservationGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Seazone')
    .reduce((total, item) => {
      const itemPrice = item.item_price || 0;
      const itemQuantity = item.item_quantity || 1;
      return total + itemPrice * itemQuantity;
    }, 0);

  if (seazoneReservationGuestDamages.length <= 0) {
    return null;
  }
  return (
    <SeazoneResumeContainer>
      <SeazoneResumeTable
        handleSortGuestDamage={handleSortGuestDamage}
        filteredReservations={seazoneReservationGuestDamages}
      />
      <TotalContainer>
        <TotalText>
          <span>Total:</span>
          {numberToCurrency(seazoneTotalValue)}
        </TotalText>
        <TotalText>
          <span>Valor Recebido:</span>
          {numberToCurrency(selectedGuestDamageNegotiation.amount_received)}
        </TotalText>
        <TotalText>
          <span>Valor do Reembolso:</span>
          {numberToCurrency(selectedGuestDamageNegotiation.transfer_to_seazone)}
        </TotalText>
      </TotalContainer>
    </SeazoneResumeContainer>
  );
};
