import styled, { keyframes } from 'styled-components';

import { Button } from '@mui/material';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  display: block;
  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  @media (max-width: 1400px) {
    transform: scale(0.9) !important;
  }
`;

export const Content = styled.div`
  width: 100%;

  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
`;

export const NewInvestButtonStyled = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    background: linear-gradient(#5f9e48, #90eb6f);
    border-radius: 10px;
    text-shadow: 0px 0px 3px #00000060;
    padding: 9px 10px;
    font-weight: bold;
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-size: 10pt;
    opacity: 0;
    animation-name: ${FadeAnimation};
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  && {
    transition: 0.3s;
  }
`;

export const ButtonContainer = styled.div`
  display: none;
  .grey {
    background: linear-gradient(#5f9e48, #90eb6f);
    border: none;

    &:hover {
      border: none;
      cursor: pointer;
    }
  }

  &.mobile-width {
    button {
      width: 100% !important;
    }

  }

  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  @media (max-width: 1400px) {
    transform: scale(0.9) !important;
  }

  @media (max-width: 1276px) {
    transform: scale(0.8) !important;
  }
`;
