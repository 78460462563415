/* eslint-disable react/require-default-props */
import { useState, useEffect } from 'react';
import {
  FormControlLabel,
  styled,
  Switch,
  SwitchProps,
} from '@mui/material';

import { UseFormik } from '../../../utils/Formik/types';

import {
  Container,
  YesText,
  NoText,
  SwitchButton,
} from './styles';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 45,
  height: 28,
  padding: 0,
  marginLeft: 30,
  transition: 'all 0.3s',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    color: '#FB515D',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#41B592',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? '#D1D1D1' : '#D1D1D1',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],

    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 25,
    height: 25,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#D1D1D1' : '#D1D1D1',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface Props {
  id: string,
  formik: UseFormik<any>;
  value?: boolean;
  alignment?: 'flex-start' | 'flex-end' | 'center';
}

const OnboardingSwitch = ({
  id,
  formik,
  value = false,
  alignment = 'flex-start',
}: Props) : JSX.Element => {
  const [checked, setChecked] = useState<boolean>(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  function onChange() {
    setChecked((prevChecked) => !prevChecked);

    if (formik) {
      formik.setFieldValue(id, !checked);
      formik.setFieldTouched(id, true, false);
    }
  }

  return (
    <Container alignment={alignment}>
      <NoText checked={checked}>Não</NoText>
      <SwitchButton>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} />}
          value
          checked={checked}
          onChange={() => onChange()}
          label={false}
        />
      </SwitchButton>
      <YesText checked={checked}>Sim</YesText>
    </Container>
  );
};

export default OnboardingSwitch;
