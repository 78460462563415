import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container } from './style';
import { usePreCheckin } from '../../../../../../context/PreCheckin/PreCheckin';
import { guestInitialValues } from '../../../../utils';

const AddGuestButton = () => {
  const {
    setGuests, info, guests, mode,
  } = usePreCheckin();

  const handleClick = () => {
    setGuests((prev) => ({
      ...prev,
      list: [...prev.list, { ...guestInitialValues, id: uuidv4() }],
    }));
  };

  const validateMaxGuests = () => {
    const lenghtOfGuestList = guests?.list?.length;

    // remove 1 por conta do hóspede principal
    const guestCapacity = (info?.property?.guest_capacity) - 1;

    return (lenghtOfGuestList >= guestCapacity);
  };

  if (mode.is === 'view') {
    return null;
  }

  if (validateMaxGuests()) {
    return (
      <Container disabled>
        <h1>Você atingiu o limite de hóspedes para esse imóvel</h1>
      </Container>
    );
  }

  return (
    <Container onClick={handleClick} whileTap={{ scale: 0.95 }}>
      <h1>Adicionar hóspede</h1>
    </Container>
  );
};

export default AddGuestButton;
