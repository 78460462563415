import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const SideContent = styled.div`
  gap: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Value = styled.h1`
  font-size: 1.7rem;
  color: #151B26
`;

export const InProgress = styled.div``;
export const InProgressContainer = styled.div`
  width: 100%;
`;

export const Separator = styled.div`
  height: 28px;
  width: 2px;
  background: #e7eaf1;
`;

export const Gains = styled.div``;
export const GainsContainer = styled.div`
  width: 100%;
`;

export const SubTitle = styled.div`
  font-size: 1rem;
  color: #0d4bd0;
`;
