import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

import { IListItems, OrderGridOptions } from '../../components/NfList/types/listItems';
import { listItemsJSON } from '../../components/NfList/mock';

import { GRIDMAXITEMS, nfListDefaultLoadingTime, sortNfListItems } from '../../components/NfList/utils';

export interface INfListContext {
  listItems: IListItems[];
  setListItems: Function;
  filteredItems: IListItems[];
  setFilteredItems: Function;
  loading: boolean;
  setLoading: Function;
  loadingFiles: boolean;
  setLoadingFiles: Function;
  orderGridBy: OrderGridOptions;
  setOrderGridBy: Function;
  abort: 'Abort' | 'Idle';
  setAbort: React.Dispatch<React.SetStateAction<'Abort' | 'Idle'>>;
}

export const NfListContext = createContext<INfListContext>({
  listItems: listItemsJSON,
  setListItems: () => {},
  filteredItems: [],
  setFilteredItems: () => {},
  loading: false,
  setLoading: () => {},
  loadingFiles: false,
  setLoadingFiles: () => {},
  orderGridBy: {
    order_by: 'name',
    order: 'asc',
  },
  setOrderGridBy: () => {},
  abort: 'Idle',
  setAbort: () => {},
});

export const NfListProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [abort, setAbort] = useState<'Abort' | 'Idle'>('Idle');
  const [listItems, setListItems] = useState<IListItems[]>(listItemsJSON.slice(1, GRIDMAXITEMS));
  const [filteredItems, setFilteredItems] = useState<IListItems[]>([]);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'name',
    order: 'asc',
  });

  const timer = React.useRef<any>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const handleLoadingFiles = useCallback((newLoading: boolean) => {
    setLoadingFiles(newLoading);
  }, []);

  const handleListItems = useCallback((newListItems: IListItems[]) => {
    setListItems(newListItems);
  }, []);

  const handleFilteredItems = useCallback((newFilteredItems: IListItems[]) => {
    setFilteredItems(newFilteredItems);
  }, []);

  const handleLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleSetOrderGridBy = useCallback((orderBy: OrderGridOptions) => {
    setOrderGridBy(orderBy);
  }, []);

  useEffect(() => {
    const response: IListItems[] = sortNfListItems(
      listItems,
      orderGridBy.order_by,
      orderGridBy.order,
    );
    handleListItems([...response]);
  }, [orderGridBy]);

  useEffect(() => {
    function timeOut() {
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, nfListDefaultLoadingTime);
    }

    timeOut();

    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  const value = useMemo(() => ({
    listItems,
    setListItems: handleListItems,
    filteredItems,
    setFilteredItems: handleFilteredItems,
    loading,
    setLoading: handleLoading,
    loadingFiles,
    setLoadingFiles: handleLoadingFiles,
    orderGridBy,
    setOrderGridBy: handleSetOrderGridBy,
    abort,
    setAbort,
  }),
  [
    listItems,
    handleListItems,
    filteredItems,
    handleFilteredItems,
    loading,
    handleLoading,
    loadingFiles,
    handleLoadingFiles,
    orderGridBy,
    setOrderGridBy,
    abort,
    setAbort,
  ]);

  return (
    <NfListContext.Provider value={value}>{children}</NfListContext.Provider>
  );
};
