/* eslint-disable react/require-default-props */
import React from 'react';
import { Container } from './styles';

interface Props {
  text: string;
  onClick: () => void;
  testid?: string;
  dataCy?: string;
}

const ButtonAdd = ({
  text, onClick, testid = '', dataCy,
}: Props) : JSX.Element => (
  <Container data-cy={dataCy} data-testid={testid} onClick={onClick} type="button">
    <p>
      +
      {' '}
      {text}
    </p>
  </Container>
);

export default ButtonAdd;
