import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const DeleteButtonContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 10px;
  border-left: 1px dashed #eee;
`;

export const Button = styled(motion.div)`
  .confirm_icons {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .loading {
    padding: 5px;
  }
`;

export const DeleteButton = styled(motion.button)<{
  loading?: boolean;
}>`
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: transparent;
  border-radius: 5px;
  background: #f5f5f5;

  ${({ loading }) => loading
    && css`
      background: transparent;
    `};

  &:disabled {
    opacity: 0.7;
  }
`;
