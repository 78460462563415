import React, { useState } from 'react';
import { Fab } from '@mui/material';
import { useFormik } from 'formik';

import {
  Add, Search,
  FilterList as FilterListIcon,
} from '@mui/icons-material';

import {
  Container,
  Backdrop,
  MobileSearchModalContainer,
  MobileSearchModalBackdrop,
} from './styles';

import TedListDatePicker from '../DatePicker';
import TedListTextField from '../TextField/TedListTextField';
import GenerateOrLoadListButton from '../Button/GenerateOrLoadListButton';
import ExportListButton from '../Button/ExportListButton';

const TedListFab = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      period: new Date(),
      search: '',
    },
    onSubmit: () => {},
  });

  const handleCloseAll = () => {
    setOpen(false);
    setOpenSearchModal(false);
    setOpenDatePicker(false);
  };

  if (openSearchModal) {
    return (
      <>
        <MobileSearchModalContainer>
          <h1>Pesquise por algum proprieatário</h1>
          <TedListTextField id="search" formik={formik} />
        </MobileSearchModalContainer>
        <MobileSearchModalBackdrop onClick={() => handleCloseAll()} />
      </>
    );
  }

  return (
    <>
      {openDatePicker && (
        <TedListDatePicker
          formik={formik}
          setCustomOpen={setOpenDatePicker}
          customOpen={openDatePicker}
        />
      )}

      <Container>
        {open && (
          <>
            <div>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="search"
                onClick={() => setOpenSearchModal(true)}
              >
                <Search style={{ marginRight: '5px' }} />
                Pesquisar
              </Fab>
            </div>
            <div>
              <ExportListButton fab formik={formik}>
                Exportar csv
              </ExportListButton>
            </div>
            <div>
              <Fab
                onClick={() => setOpenDatePicker(true)}
                variant="extended"
                size="small"
                color="primary"
                aria-label="export-csv"
              >
                <FilterListIcon style={{ marginRight: '5px' }} />
                Filtrar por data
              </Fab>
            </div>
            <div>
              <GenerateOrLoadListButton fab formik={formik}>
                Gerar ou carregar listas
              </GenerateOrLoadListButton>
            </div>
          </>
        )}

        <Fab
          size="medium"
          onClick={() => setOpen(!open)}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Container>

      {open && <Backdrop onClick={() => setOpen(false)} />}
    </>
  );
};

export default TedListFab;
