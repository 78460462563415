import React, {
  FC,
  useState,
} from 'react';

import { MoreVert, MoreHoriz, GetApp } from '@mui/icons-material';

import momentLib, { Moment } from 'moment';
import { extendMoment } from 'moment-range';

import {
  Container,
  FormContainer,
  FormContent,
  Wrapper,
  DetailsContainer,
  DailysContainer,
  TransactionContainer,
  ButtonStyled,
  ButtonsContainer,
  SmallText,
  SmallTextBold,
} from './styles';

import {
  FormButton,
} from '../../../index';

import { numberToCurrency } from '../../../../utils/Formatter';
import { useReservation } from '../../../../context/ReservationContext';
import { useUser } from '../../../../context/UserContext';

interface Props {
  props?: object;
}

/* @ts-ignore */
const moment = extendMoment(momentLib);

const FinanceForm: FC<Props> = () => {
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  const { reservationData } = useReservation();
  const [showDailys, setShowDailys] = useState(false);
  const totalPrice = numberToCurrency(Number(reservationData?.total_price)) || 0;
  const [listDays, setListDays] = useState<Array<Moment>>();

  const ListDaysReservation = () => {
    const start = moment(moment(reservationData.check_in_date, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'YYYY-MM-DD');
    const end = moment(moment(reservationData.check_out_date, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'YYYY-MM-DD');
    const range = moment().range(start, end);
    const array = Array.from(range.by('days'));
    setListDays(array);
  };

  const handleClickViewDaily = () => {
    ListDaysReservation();
    setShowDailys(!showDailys);
  };
  return (
    <Container>
      <FormContainer
        onContextMenu={(event) : void => {
          event.stopPropagation();
        }}
      >
        <FormContent>
          <DetailsContainer>
            {roles.includes('Host') && (
              <h2>
                Esta parte ainda está em desenvolvimento, os valores podem não ser exatamente estes
              </h2>
            )}
            <Wrapper>
              <SmallText>Valor total das diárias</SmallText>
              <SmallText>{totalPrice}</SmallText>
            </Wrapper>
            { showDailys && (
              <DailysContainer>
                { listDays && listDays.map((m) => (
                  <Wrapper>
                    <SmallText>{m.format('ddd, DD/MM')}</SmallText>
                    <SmallText>R$450,00</SmallText>
                  </Wrapper>
                ))}
              </DailysContainer>
            )}
            <button type="button" onClick={handleClickViewDaily}>{`${showDailys ? 'Recolher diária' : 'Mostrar diária'}`}</button>
            {roles.includes('Host') && (
              <>
                <Wrapper>
                  <SmallText>Taxa de limpeza (líquida)</SmallText>
                  <SmallText>Em desenvolvimento</SmallText>
                </Wrapper>
                <Wrapper>
                  <SmallText>Preço líquido da diária</SmallText>
                  <SmallText>Em desenvolvimento</SmallText>
                </Wrapper>
                <Wrapper>
                  <SmallText>Receita do anfitrião</SmallText>
                  <SmallText>Em desenvolvimento</SmallText>
                </Wrapper>
              </>
            )}
            <Wrapper>
              <SmallText>Valor total da reserva</SmallText>
              <SmallText>R$1840,00</SmallText>
            </Wrapper>
            <SmallTextBold>Pagamento</SmallTextBold>
            <Wrapper>
              <SmallText>Total já recebido</SmallText>
              <SmallText>R$600,00</SmallText>
            </Wrapper>
            <SmallTextBold>Histórico de transações</SmallTextBold>
          </DetailsContainer>

          <TransactionContainer>
            <Wrapper className="date">
              <SmallTextBold>Data</SmallTextBold>
              <SmallText>10/01/2021</SmallText>
              <SmallText>04/01/2021</SmallText>
            </Wrapper>
            <Wrapper className="value">
              <SmallTextBold>Valor</SmallTextBold>
              <SmallText>R$300,00</SmallText>
              <SmallText>R$300,00</SmallText>
            </Wrapper>
            <Wrapper className="payment">
              <SmallTextBold>Forma de pagto.</SmallTextBold>
              <SmallText>Cartão de crédito</SmallText>
              <SmallText>Boleto</SmallText>
            </Wrapper>
            <Wrapper className="icon-download">
              <div />
              <div>
                <div className="tooltip">
                  <p>Baixar comprovante</p>
                </div>
                <GetApp />
              </div>
              <div>
                <div className="tooltip">
                  <p>Baixar comprovante</p>
                </div>
                <GetApp />
              </div>
            </Wrapper>
            <Wrapper className="icon-more-horiz">
              <div />
              <div>
                <div className="tooltip">
                  <p>Adicionado por João Paulo</p>
                </div>
                <MoreHoriz />
              </div>
              <div>
                <div className="tooltip">
                  <p>Adicionado por João Paulo</p>
                </div>
                <MoreHoriz />
              </div>
            </Wrapper>
          </TransactionContainer>
          <button type="button">Mais informações</button>
        </FormContent>
        {!roles.includes('Host') && (
        <ButtonsContainer>
          <FormButton type="button" variant="outlined">Declarar recebimento</FormButton>
          <ButtonStyled>
            <button type="button">Mais opções</button>
            <MoreVert />
          </ButtonStyled>
        </ButtonsContainer>
        )}

      </FormContainer>
    </Container>
  );
};

export default FinanceForm;
