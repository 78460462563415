import React, { useEffect } from 'react';

import { Container } from './styles';
import PropertyClose from '../../../components/FinancialClose/PropertyClose';
import { FinancialCloseProvider } from '../../../context/FinancialClosePage/FinancialCloseContext/FinancialCloseContext';
import { FinancialClosePropertyProvider } from '../../../context/FinancialClosePage/FinancialClosePropertyContext/FinancialClosePropertyContext';

const PropertyClosePage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Fechamento';
  }, []);

  return (
    <FinancialCloseProvider>
      <FinancialClosePropertyProvider>
        <Container>
          <PropertyClose />
        </Container>
      </FinancialClosePropertyProvider>
    </FinancialCloseProvider>
  );
};
export default PropertyClosePage;
