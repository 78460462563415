import React from 'react';
import { useParams } from 'react-router-dom';

import {
  EnumType,
  IPropertyIndication,
  IAllotmentIndication,
  IInvestmentIndication,
} from '../../../../../types';

import { useLoadMoreData } from '../../../../../../../hooks/useLoadMoreData';
import { usePartners } from '../../../../../../../context/Partners/Partner/PartnerContext';
import { usePartnerIndicate } from '../../../../../../../context/Partners/IndicateContext/IndicateContext';

import Observer from './Observer';
import CardsView from './CardsView';
import { handleMapIndicate, handleMapIndicateInvestment } from './utils';

const ITEMS_PER_PAGE = 5;

const Indications = () => {
  const { filter } = useParams();

  const { indications } = usePartners();
  const { activeFilter } = usePartnerIndicate();

  const {
    actualPageData: allotmentsPageData,
    haveANextPage: haveMoreAllotmentsData,
    handleNextPage: handleNextPageAllotments,
  } = useLoadMoreData({
    pageLength: ITEMS_PER_PAGE,
    data: indications?.allotments || [],
    key: 'status',
    filter: activeFilter,
  });

  const {
    actualPageData: propretysPageData,
    haveANextPage: haveMorePropretysData,
    handleNextPage: handleNextPagePropretys,
  } = useLoadMoreData({
    pageLength: ITEMS_PER_PAGE,
    data: indications?.propretys || [],
    key: 'status',
    filter: activeFilter,
  });

  const {
    actualPageData: spotsPageData,
    haveANextPage: haveMoreSpotsData,
    handleNextPage: handleNextPageSpots,
  } = useLoadMoreData({
    pageLength: ITEMS_PER_PAGE,
    data: indications?.spots || [],
    key: 'status',
    filter: activeFilter,
  });

  const indicationsTerrain = React.useMemo(() => handleMapIndicate(
    filter, activeFilter, allotmentsPageData,
  ),
  [allotmentsPageData, filter, activeFilter]);

  const indicationsLocation = React.useMemo(() => handleMapIndicate(
    filter, activeFilter, propretysPageData,
  ),
  [propretysPageData, filter, activeFilter]);

  const indicationsSpots = React.useMemo(() => handleMapIndicateInvestment(
    filter, activeFilter, spotsPageData,
  ),
  [spotsPageData, filter, activeFilter]);

  const validateRenderObserver = React.useCallback(() => {
    if (filter === 'todos') {
      if (haveMoreAllotmentsData || haveMorePropretysData || haveMoreSpotsData) {
        return true;
      }
    }

    if (filter === EnumType.terrain) {
      if (haveMoreAllotmentsData) {
        return true;
      }
    }

    if (filter === EnumType.location) {
      if (haveMorePropretysData) {
        return true;
      }
    }

    if (filter === EnumType.spot) {
      if (haveMoreSpotsData) {
        return true;
      }
    }

    return false;
  }, [filter, haveMoreAllotmentsData, haveMorePropretysData, haveMoreSpotsData]);

  return (
    <>
      <CardsView
        indicationsTerrain={indicationsTerrain as IAllotmentIndication[]}
        indicationsLocation={indicationsLocation as IPropertyIndication[]}
        indicationsSpots={indicationsSpots as IInvestmentIndication[]}
      />

      {(validateRenderObserver()) && (
        <Observer
          haveMoreAllotmentsData={haveMoreAllotmentsData}
          haveMorePropretysData={haveMorePropretysData}
          haveMoreSpotsData={haveMoreSpotsData}
          handleNextPageAllotments={handleNextPageAllotments}
          handleNextPagePropretys={handleNextPagePropretys}
          handleNextPageSpots={handleNextPageSpots}
        />
      )}
    </>
  );
};

export default Indications;
