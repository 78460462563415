import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonContainer, Container } from './style';

interface IDetailsProps {
  to: string;
  children: React.ReactNode;
}

interface IDetailsButton{
  children: React.ReactNode;
  onClick: Function;
}

const Icon = () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.50062 5L0 1.66745L1.74969 0L7 5L1.74969 10L0 8.33255L3.50062 5Z"
      fill="#0D4BD0"
    />
  </svg>
);

export const DetailsLink = ({
  to,
  children,
}: IDetailsProps) => (
  <Link style={{ textDecoration: 'none', color: '#0D4BD0' }} to={to}>
    <Container
      whileTap={{
        x: 10,
      }}
    >
      {children}
      <Icon />
    </Container>
  </Link>
);

export const DetailsButton = ({
  children,
  onClick,
  ...rest
}: IDetailsButton) => (
  <ButtonContainer onClick={() => onClick()} {...rest}>
    <Container
      whileTap={{
        x: 10,
      }}
    >
      {children}
      <Icon />
    </Container>
  </ButtonContainer>
);
