import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 90%;
  background: white;
  padding: 30px 20px;

  max-width: 400px;

  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);

  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  h1 {
    font-size: 1.4rem;
  }
`;
