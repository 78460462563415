import { useContextSelector } from 'use-context-selector';

import { OwnerTransferControlContext } from '../../../context/OwnerPage/OwnerTransferControlContext/OwnerTransferControlContext';

export function useOwnerTransferControl() {
  const transferControlAction = useContextSelector(OwnerTransferControlContext,
    (state) => state.transferControlAction);

  const setTransferControlAction = useContextSelector(OwnerTransferControlContext,
    (state) => state.setTransferControlAction);

  return {
    transferControlAction,
    setTransferControlAction,
  };
}
