import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 2rem 2.5rem;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.blue._950.hex()};
`;

export const Logo = styled.img`
  width: 180px;
  cursor: pointer;
`;

export const Contact = styled.div`
  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-weight: 700;
    font-size: 14px;
  }
`;
