import React from 'react';
import { useLocation } from 'react-router-dom';

import { partnersRoutes } from '../types';
import { analyticsEvent } from '../utils/analytics';
import { useUser } from '../../../context/UserContext';

export function useDefineTitle(cTitle?: string) {
  const { userInformation } = useUser();
  const { pathname } = useLocation();

  React.useEffect(() => {
    function getPageName() {
      const routes = Object.values(partnersRoutes);
      const filteredRoute: any = routes.find((item) => {
        const ignore = [
          'w',
          'parceiros',
        ];

        if (!ignore.includes(item.main)) {
          if (pathname.includes(item.main)) {
            return true;
          }
        }
        return false;
      });

      if (filteredRoute && filteredRoute !== '') {
        const text = `Parceiros - ${filteredRoute.name || ''}`;
        return {
          text,
          filteredRoute,
        };
      }

      const text = 'Parceiros - Seazone';
      return {
        text,
        filteredRoute,
      };
    }

    const pageName = getPageName();

    if (cTitle) {
      window.document.title = cTitle;
      analyticsEvent({
        userInformation,
        name: `Acessou a página de ${cTitle}`,
      });
    } else if (pageName.filteredRoute?.name) {
      window.document.title = pageName.text;
      analyticsEvent({
        userInformation,
        name: `Acessou a página de ${pageName.filteredRoute?.name}`,
      });
    }
  }, [pathname]);

  return pathname;
}
