import styled from 'styled-components';

export const MenuItemContainer = styled.div`
  overflow: hidden;
  display: flex;
  gap: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const DescriptionContainer = styled.div`
  display: flex;
`;

export const ContainerNotes = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 300px;

  textarea {
    margin-left: 1.5rem !important;
  }
`;
