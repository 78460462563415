import { useEffect, useState } from 'react';
import {
  ButtonContainer,
  LineShimmer,
  LinkText,
  OwnerResumeContainer,
  OwnerResumeInformationContainer,
  OwnerResumeItem,
  Title,
  TotalContainer,
  TotalText,
} from './styles';

import { OwnerResumeTable } from './OwnerResumeTable';
import FormButton from '../../../../FormButton/FormButton';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { BankDetails, GetGuestDamage } from '../../../../../services/GuestDamage/types';
import { getOwnerBankDetailsById } from '../../../../../services/GuestDamage/request';
import { useToast } from '../../../../../context/ToastContext';
import { translateAccountType } from '../../../../../utils/Translator';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { useGuestDamageStep } from '../../../../../hooks/GuestDamage/useGuestDamageStep';
import { useUser } from '../../../../../context/UserContext';
import { numberToCurrency } from '../../../../../utils/Formatter';

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';

interface OwnerResumeProps {
  ownerReservationGuestDamages: GetGuestDamage[];
  handleSortGuestDamage: (sortBy: GuestDamageSortedBy) => void;
}

export const OwnerResume = ({
  ownerReservationGuestDamages,
  handleSortGuestDamage,
}:OwnerResumeProps) => {
  const [ownerBankDetails, setOwnerBankDetails] = useState<BankDetails>({} as BankDetails);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const { handleInsertRefundDamageStep } = useGuestDamageStep();
  const { handleOpenFile, reservationGuestDamages } = useGuestDamage();
  const toast = useToast();
  const { userInformation } = useUser();
  const roles = userInformation?.roles;

  useEffect(() => {
    async function getOwnerBankDetails() {
      if (selectedGuestDamageNegotiation.reservation?.property_owner_user_id) {
        try {
          setIsLoading(true);
          const response = await getOwnerBankDetailsById(selectedGuestDamageNegotiation
            .reservation?.property_owner_user_id);
          setOwnerBankDetails(response[0]);
          setIsLoading(false);
        } catch (e) {
          if (e instanceof Error) {
            toast.alert('Falha ao recuperar os dados bancários!');
          }
          setIsLoading(false);
        }
      }
    }
    getOwnerBankDetails();
  }, [selectedGuestDamageNegotiation]);

  const ownerTotalValue = reservationGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Proprietário')
    .reduce((total, item) => {
      const itemPrice = item.item_price || 0;
      const itemQuantity = item.item_quantity || 1;
      return total + itemPrice * itemQuantity;
    }, 0);

  return (
    <OwnerResumeContainer>
      <OwnerResumeInformationContainer>
        <OwnerResumeItem>
          <h3>Dono do reembolso</h3>
          <p>Proprietário</p>
        </OwnerResumeItem>
        <OwnerResumeItem>
          {isLoading ? (<LineShimmer />) : (<h3>Nome</h3>)}
          <p>{selectedGuestDamageNegotiation.reservation?.property_owner_name || '-'}</p>
        </OwnerResumeItem>
      </OwnerResumeInformationContainer>
      <Title>Dados bancários</Title>
      <OwnerResumeInformationContainer>
        <OwnerResumeItem>
          <h3>Banco</h3>
          {isLoading ? (<LineShimmer />) : (<p>{ownerBankDetails?.bank?.long_name || ownerBankDetails?.bank?.short_name || '-'}</p>)}
        </OwnerResumeItem>
        <OwnerResumeItem>
          <h3>Número do banco</h3>
          {isLoading ? (<LineShimmer />) : (<p>{ownerBankDetails?.bank?.bank_number || '-'}</p>)}
        </OwnerResumeItem>
        <OwnerResumeItem>
          <h3>Agência</h3>
          {isLoading ? (<LineShimmer />) : (<p>{ownerBankDetails?.branch_number || '-'}</p>)}
        </OwnerResumeItem>
        <OwnerResumeItem>
          <h3>Conta</h3>
          {isLoading ? (<LineShimmer />) : (<p>{ownerBankDetails?.account_number || '-'}</p>)}
        </OwnerResumeItem>
        <OwnerResumeItem>
          <h3>Tipo de conta</h3>
          {isLoading ? (<LineShimmer />) : (<p>{translateAccountType(ownerBankDetails?.account_type) || '-'}</p>)}
        </OwnerResumeItem>
        <OwnerResumeItem>
          <h3>Nome do correntista</h3>
          {isLoading ? (<LineShimmer />) : (<p>{ownerBankDetails?.entity_name || '-'}</p>)}
        </OwnerResumeItem>
      </OwnerResumeInformationContainer>
      <OwnerResumeTable
        handleSortGuestDamage={handleSortGuestDamage}
        filteredReservations={ownerReservationGuestDamages}
      />
      <TotalContainer>
        <TotalText>
          <span>Total:</span>
          {numberToCurrency(ownerTotalValue)}
        </TotalText>
        <TotalText>
          <span>Valor Recebido:</span>
          {numberToCurrency(selectedGuestDamageNegotiation.amount_received)}
        </TotalText>
        <TotalText>
          <span>Valor do Reembolso:</span>
          {numberToCurrency(selectedGuestDamageNegotiation.transfer_to_owner)}
        </TotalText>
      </TotalContainer>
      <OwnerResumeItem>
        <h3>Reembolso realizado?</h3>
        {isLoading ? (<LineShimmer />) : (<p>{selectedGuestDamageNegotiation.is_refunded ? 'Sim' : 'Não'}</p>)}
      </OwnerResumeItem>
      <OwnerResumeItem>
        {selectedGuestDamageNegotiation?.owner_payment_receipts?.map((receipt) => (
          <>
            {receipt?.owner_payment_receipt !== undefined
              ? (
                <LinkText
                  onClick={
                    () => handleOpenFile(receipt.owner_payment_receipt?.url!,
                      receipt.owner_payment_receipt?.name!)
                  }
                >
                  {receipt.owner_payment_receipt.name}
                </LinkText>
              )
              : '-'}
          </>
        ))}
      </OwnerResumeItem>
      {(roles?.includes('SeazoneAdministrative')
        || roles?.includes('Admin'))
        && (
        <ButtonContainer>
          <FormButton onClick={handleInsertRefundDamageStep}>Editar</FormButton>
        </ButtonContainer>
        )}
    </OwnerResumeContainer>
  );
};
