import styled, { keyframes } from 'styled-components';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const FadeTransition = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: auto;
  }
`;

export const CalendarModalContainer = styled.div<{
  isOpen?: boolean;
}>`
  animation: ${FadeTransition} 0.2s ease-in-out;
  position: absolute;

  @media (max-width: 1200px) {
    #monthModalViewer {
      position: absolute;
      max-width: 80%;
      margin: 0 auto;
      .container {
        left: 20%;
        top: 30px;

        @media (max-width: 800px) {
          left: -40%;
          top: 30px;
        }
      }
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  padding: 10px 15px;
  border: 1px solid ${({ theme }) => theme.palette.white._900.hex()};
  min-width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.5s;

  @media (max-width: 1180px) {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .calendarContainer {
    margin-right: 2rem;
    justify-self: end;
    cursor: pointer;

    opacity: 0;
    animation-name: ${FadeAnimation};
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  h1 {
    opacity: 0;
    animation-name: ${FadeAnimation};
    animation-duration: 0.6s;
    animation-delay: 0.2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    text-align: center;
    font-size: 1.3rem;
    letter-spacing: 1.5px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.blue._950.hex()};
  }
`;
