import filesize from 'filesize';
import { v4 as uuid } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { useCallback, useEffect } from 'react';
import { useFile } from '../FileHook/useFile';
import { FileProps } from '../../context/FileContext/types';
import photoSketch from '../../assets/icons/expense/photoSketch.svg';
import { initialValuesFile } from '../../context/FileContext/FileContext';

interface UploadProps {
  dataCy?: string;
  file: FileProps;
  accept?: string[];
  setFile: Function;
  multiple?: boolean;
  disabled?: boolean;
  onCloseFile?: boolean;
  defaultPreview?: string;
}

export const useUpload = ({
  file,
  multiple,
  disabled,
  setFile = () => {},
  onCloseFile = false,
  defaultPreview = photoSketch,
  accept = ['image/*', 'application/*'],
}: UploadProps) => {
  const { uploadedFiles, setUploadedFiles, deleteFile } = useFile();

  const imgSketch: HTMLImageElement = new Image();
  imgSketch.src = defaultPreview;

  useEffect(() => {
    if (onCloseFile) {
      deleteFile(file.id);
      setFile(initialValuesFile);
    }
  }, [onCloseFile]);

  useEffect(() => {
    uploadedFiles.forEach((upload) => {
      if (upload.id === file.id) {
        setFile(upload);
      }
    });
  }, [uploadedFiles]);

  useEffect(() => () => {
    uploadedFiles.forEach((f) => f.previewURL && URL.revokeObjectURL(f.previewURL));
    setUploadedFiles([]);
  },
  []);

  const onUpload = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((f) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);

      reader.onload = () => {
        const newFile: FileProps = {
          url: null,
          id: uuid(),
          error: false,
          name: f.name,
          size: f.size,
          uploaded: false,
          MIMEtype: f.type,
          uploadProgress: 0,
          file: reader.result as string,
          readableSize: filesize(f.size),
          previewURL: URL.createObjectURL(f),
        };

        setFile(newFile);
        setUploadedFiles((uploaded: FileProps[]) => uploaded.concat(newFile));
      };
    });
  }, []);

  const onDragEnter = useCallback(() => {}, []);
  const onDragLeave = useCallback(() => {}, []);
  const onDragOver = useCallback(() => {}, []);

  const {
    getRootProps, getInputProps, isDragActive, isDragReject, open,
  } = useDropzone({
    accept,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop: onUpload,
    multiple: multiple || false,
    disabled: disabled || false,
  });

  return {
    file,
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  };
};
