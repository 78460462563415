import ReactGA from 'react-ga4';
import { UserInformation } from '../../../../services/User/types';

interface IAnalytics {
  name: string;
  action?: string;
  userInformation: UserInformation | null | undefined;
}

export function analyticsEvent({
  name,
  userInformation,
}: IAnalytics) {
  ReactGA.event(`Parceiros - ${userInformation?.nickname || userInformation?.first_name}`, {
    category: `${userInformation?.main_role}-${userInformation?.user_id}-`,
    action: name,
  });
}

analyticsEvent.defaultProps = {
  action: undefined,
};
