import styled from 'styled-components';
import { Modal } from '@mui/material';
import { motion } from 'framer-motion';

const MAX_SIZE_MOBILE = 900; // 900 px

export const Container = styled(Modal)`
  background: white;
  position: relative;
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const Content = styled(motion.div)`
  overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }
  
  background: white;

  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 662px;
  height: max-content;

  border-radius: 10px;

  padding: 30px;

  h4 {
    color: var(--Darkk-Blue-900, #001840);

    /* Heading 2 */
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 145.455% */
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    height: 90%;
    width: 90%;
  }

  @media (max-width: 320px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: scroll;

    padding: 40px 20px;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 10px;
`;

export const PropertyHeader = styled.div`
  padding: 15px;
  border-radius: 10px;
  background: var(--Dark-blue-900, #042675);

  display: flex;
  justify-items: space-between;
  align-items: center;

  h6 {
    color: var(--Neuter-50, #f9f9f9);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 183.333% */
    text-align: start;
    text-justify: distribute;
    text-align-last: left;
    hyphens: auto;
    -webkit-hyphens: auto;
  }

  .column-property {
    width: 55%;
    padding-left: 1rem;
  }
  
  .column-transfer {
    width: 40%;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    .column-property {
      width: 50%;
    }
    
    .column-transfer {
      width: 50%;
    }
  }
`;

export const StyledPropertyContent = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    padding: 15px 25px;
    border-radius: 10px;

    width: 100%;

    cursor: pointer;

    border: none;
    background: var(--Blue-500, #0d4bd0);

    color: var(--branco, #fff);
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 87.5% */
    letter-spacing: -0.003px;
  }

  p {
    font-weight: 400;
    font-family: Inter;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
  }
`;

export const PropertyBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 200px;
  overflow: auto;
  
  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    height: auto;
    max-height: 430px;
  }
`;

export const PropertyItem = styled.div`
  display: grid;
  flex-direction: space-between;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;

  border-radius: 10px;
  background: var(--Blue-50, #e7e9fa);
  padding: 15px;
  align-items: center;

    
  @media(min-width: 700px) {
    display: flex;
  }
`;

export const PropertyCode = styled.div`
  width: 40%; 

  @media(min-width: 500px) {
    width: 60%; 
  }
`;

export const PropertyTransfer = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;

 @media(min-width: 700px) {
    width: 40%; 
  }
`;
