import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';
import { TransferControlActionsProps } from './types';

interface ITransferControl {
  transferControlAction: TransferControlActionsProps;
  setTransferControlAction: (action: TransferControlActionsProps) => void;
}

export const OwnerTransferControlContext = createContext<ITransferControl>({
  transferControlAction: 'add_transfer',
  setTransferControlAction: (action: TransferControlActionsProps) => action,
});

export const OwnerTransferControlProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [
    transferControlAction,
    setTransferControlAction,
  ] = useState<TransferControlActionsProps>('add_transfer');

  const handleSetTransferControlAction = useCallback((data: TransferControlActionsProps) => {
    setTransferControlAction(data);
  }, []);

  const value = useMemo(() => ({
    transferControlAction,
    setTransferControlAction: handleSetTransferControlAction,
  }), [
    transferControlAction,
    setTransferControlAction,
  ]);

  return (
    <OwnerTransferControlContext.Provider
      value={value}
    >
      {children}
    </OwnerTransferControlContext.Provider>
  );
};
