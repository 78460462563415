import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 300px;
  padding: 30px;
  background-color: white;

  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);

  z-index: 1000000000;

  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
`;

export const Span = styled.span<{
  status?: 'low' | 'moderate' | 'medium' | 'hight' | 'excellent';
}>`
  ${({ status }) => {
    switch (status) {
      case 'low':
        return 'color : #0D4BD0';

      case 'moderate':
        return 'color : #FF8D00';

      case 'medium':
        return 'color : #FF5C00';

      case 'hight':
        return 'color : #F22200';

      case 'excellent':
        return 'color : #EA0000';

      default:
        return 'color: black';
    }
  }}
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  h1 {
    color: var(--darkk-blue-900, #001840);

    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 145.455% */
  }
`;
export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: #0d4bd0;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border: none;
    cursor: pointer;
  }
`;
export const RadioContainer = styled(motion.div)`
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  user-select: none;
  cursor: pointer;

  input {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
