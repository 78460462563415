import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 1.4rem;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  @media(min-width: 520px) {
    max-width: 550px;
    max-height: 626px;
    margin-top: 0px;
    border-radius: 16px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
`;

export const Header = styled.header`
  width: 100%;
  height: 18%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 700;
  font-family: Inter;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};
  width: 100%;
  padding: 0.5rem 0;
`;

export const TermDescription = styled.div`  
  @media(min-width: 520px) {
    overflow-y: auto;
    height: 60%;
    &::-webkit-scrollbar {
      background-color: transparent;
   }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }
`;

export const TermByProperty = styled.div``;

export const SelectAllContainer = styled.div`
  display: flex;
`;

export const Text = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  font-family: Inter;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  text-align: justify;
  word-wrap: break-word; 
  margin: 0;
`;

export const TextRegular = styled(Text)`
  font-weight: 500;
  line-height: 22px;
  
  a {
    font-weight: 500;
  }
`;

export const TextBold = styled(Text)`
  font-weight: 700;
  line-height: 24px;
`;

export const TextSmall = styled(Text)`
  font-size: 1rem;
`;

export const Link = styled.a`
  font-size: 1.1rem;
  font-weight: 500;
  font-family: Inter;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.blue._400.hex()};
  word-wrap: break-word; 
`;

export const Footer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
  margin-bottom: 1rem;
  
  @media(min-width: 520px) {
    height: 25%;
  }
`;

export const OneSpace = styled.div`
  margin: 0.5rem 0;
`;

export const TwoSpaces = styled.div`
  margin: 1rem 0;
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};

  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.palette.grey._300.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const GridCard = styled.div`
  display: grid;
  width: 100%;
  height: calc(100% + 100px);
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 2rem;
  padding: 1rem;

  @media(max-width: 520px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  button {
    width: 100% !important;
    margin: 1rem 0;
  } 
`;

export const PropertiesList = styled.div`
  ul {
    list-style: none;
  }
  
  li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 1.1rem;
    font-weight: 700;
  }
`;
