import {
  Container,
  Table,
  Item,
} from './styles';

export const Finances = () => (
  <Container>
    <Table>
      <Item />
      <Item />
      <Item />
      <Item />
    </Table>
  </Container>
);
