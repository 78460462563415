import styled from 'styled-components';

export const ShimmerContainer = styled.div`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 15px;

  height: calc(100vh - 71px);
  width: 100%;

  ::-webkit-scrollbar {
    background: transparent;
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  overflow-y: hidden;
  overflow-x: hidden;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: 900px) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 0 15px 15px 15px;

  height: calc(100vh - 71px);
  width: 100%;

  ::-webkit-scrollbar {
    background: transparent;
    width: 3px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  overflow-y: scroll;
  overflow-x: scroll;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: 900px) {
    padding-bottom: 100px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;
