import { Popover } from '@mui/material';
import React from 'react';
import SelectModal from '../../../SelectModal/SelectModal';
import {
  BoldText,
} from '../styles';

interface Props {
  title: string;
  value: string;
  children: React.ReactNode;
}

export function FilterPopoverRoot({
  value,
  children,
  title,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'popover-register-modal-expense-screen' : undefined;

  const ChildrenWithProps = React.useMemo(() => React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClose: handleClose } as React.Attributes);
    }
    return child;
  }), [children]);

  return (
    <>
      <div className="row">
        <button
          style={{
            // remove button styles
            backgroundColor: 'transparent',
            border: 'none',
          }}
          type="button"
          onClick={(e) => {
            handleClick(e);
          }}
          aria-describedby={popoverId}
          className="styledSimple"
        >
          <BoldText>{title}</BoldText>
          <SelectModal
            dataCy="select-register-date"
            id="dates"
            value={value}
          />
        </button>

        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPopover-paper': {
              marginTop: '5px',
              padding: '20px',
              borderRadius: '10px',
              border: '1px solid #cecece',
              boxShadow: '0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)',
            },
          }}
        >
          {ChildrenWithProps}
        </Popover>
      </div>
    </>
  );
}
