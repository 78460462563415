/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useFormik } from 'formik';

import iconHouse from '../../../assets/icons/generals/house.svg';

import {
  BankDetails,
  OwnerInvoiceDetails,
  PropertyInvoiceBank,
  PropertyOwner,
} from '../../../services/Owner/types';

import { compareList } from '../../../utils/Sorting';

import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';

import { SimpleSelect } from '../..';
import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import ModalNewDataFiscalNote from '../Modal/ModalNewDataFiscalNote';
import AlertMessage from '../AccountBankManager/AlertMessage';
import CardPropertyBankAccount from './CardPropertyBankAccount';
import SimpleSlider from '../../Carousel/SimpleSlider';

import {
  Container,
  Content,
  HeaderContainer,
  HeaderContent,
  TitleContainer,
  Title,
  AlertMessageContainer,
  ContainerSelect,
  Label,
  ContentMobile,
} from './styles';

interface IAccountPropertyManager {
  propertiesListMain: Array<PropertyOwner>,
  listAccountsBank: Array<BankDetails>,
  listOwnerInvoice: Array<OwnerInvoiceDetails>,
  propertyInvoiceBank: Array<PropertyInvoiceBank>,
  getListOwnerInvoices: () => void;
}

const AccountPropertyManager = ({
  propertiesListMain = [],
  listAccountsBank = [],
  listOwnerInvoice = [],
  propertyInvoiceBank = [],
  getListOwnerInvoices,
}: IAccountPropertyManager) => {
  const [openModalFiscalNote, setOpenModalFiscalNote] = useState<boolean>(false);
  const [dataFiscalNote, setDataFiscalNote] = useState<OwnerInvoiceDetails>({} as OwnerInvoiceDetails);
  const [propertiesFiltereds, setPropertiesFiltereds] = useState<Array<PropertyOwner>>(propertiesListMain);
  const { isMobile } = useScreenResize();
  const sliderRef = useRef<any>(null);

  const propertiesCodes: SelectOption[] = useMemo(() => {
    if (propertiesListMain) {
      const codes = propertiesListMain.map((prop) => ({
        id: `${prop.id}`,
        value: `${prop.id}`,
        valueLabel: `${prop.code}`,
      }));

      return codes.sort((a, b) => compareList((a?.valueLabel || ''), (b?.valueLabel || '')));
    }

    return [];
  }, [propertiesListMain]);

  const formik = useFormik({
    initialValues: {
      propertyId: undefined,
    },
    onSubmit: async () => {},
  });

  useEffect(() => {
    if (formik.values?.propertyId) {
      const filtered = propertiesListMain.filter((item) => `${item.id}` === `${formik.values.propertyId}`);
      setPropertiesFiltereds(filtered);
    } else {
      setPropertiesFiltereds(propertiesListMain);
    }
  }, [formik.values?.propertyId]);

  useEffect(() => {
    setPropertiesFiltereds(propertiesListMain);
  }, [propertiesListMain]);

  function handleOpenEditModalFiscalNote(fiscalNote: OwnerInvoiceDetails) {
    setOpenModalFiscalNote(true);
    setDataFiscalNote(fiscalNote);
  }

  function handleCloseModalDataFiscalNote() {
    setOpenModalFiscalNote(false);
    getListOwnerInvoices();
  }

  return (
    <Container onSubmit={formik.handleSubmit} onChange={formik.handleChange} data-testid="card-property-bank-account">
      <HeaderContainer>
        <TitleContainer>
          <Title>Gerenciamento de contas de cada imóvel</Title>
        </TitleContainer>

        <HeaderContent>
          <AlertMessageContainer>
            <AlertMessage variant="3" />
          </AlertMessageContainer>

          <ContainerSelect>
            <Label>Selecione o imóvel</Label>
            <SimpleSelect
              dataCy="select-properties-list"
              id="propertyId"
              placeholder="Todos"
              formik={formik}
              icon={iconHouse}
              showIcon
              options={propertiesCodes || []}
              className="inputLabel"
            />
          </ContainerSelect>
        </HeaderContent>
      </HeaderContainer>

      <Content>
        <section>
          {isMobile && (
            <ContentMobile>
              <SimpleSlider
                sliderRef={sliderRef}
                settings={{
                  dots: false,
                  arrows: false,
                  infinite: false,
                  speed: 500,
                  slidesToScroll: 1,
                  draggable: propertiesFiltereds.length >= 2,
                  slidesToShow: propertiesFiltereds.length >= 2 ? 1.2 : 1,
                }}
              >
                {propertiesFiltereds.map((item) => (
                  <CardPropertyBankAccount
                    key={item.id}
                    listBank={listAccountsBank || []}
                    testid="card-property-bank-account-item"
                    code={item.code}
                    neighborhood={item.address?.neighborhood || ''}
                    propertyInvoiceBank={
                    propertyInvoiceBank?.find((propInvoiceBank) => (
                      propInvoiceBank.code === item.code)) || {} as PropertyInvoiceBank
                  }
                  />
                ))}
              </SimpleSlider>
            </ContentMobile>
          )}

          {!isMobile && (
            propertiesFiltereds.map((item) => (
              <CardPropertyBankAccount
                key={item.id}
                listBank={listAccountsBank || []}
                testid="card-property-bank-account-item"
                code={item.code}
                neighborhood={item.address?.neighborhood || ''}
                propertyInvoiceBank={
                  propertyInvoiceBank?.find((propInvoiceBank) => (
                    propInvoiceBank.code === item.code)) || {} as PropertyInvoiceBank
                  }
              />
            ),
            ))}
        </section>

        {openModalFiscalNote && (
          <ModalNewDataFiscalNote
            setOpenModal={handleCloseModalDataFiscalNote}
            openModal={openModalFiscalNote || false}
            dataFiscalNote={dataFiscalNote}
            setDataFiscalNote={handleOpenEditModalFiscalNote}
          />
        )}
      </Content>
    </Container>
  );
};

export default AccountPropertyManager;
