import styled from 'styled-components';

const MAX_SIZE_MOBILE = 768; // 768px

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;

  grid-template-columns: 50% 50%;
  grid-template-areas: 'content image';
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'image' 'content'; 
  }
`;

export const MainContent = styled.div`
  grid-area: content;
  padding: 1rem;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    order: 2;
  }
`;

export const ImageContent = styled.div`
  grid-area: image;
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    order: 1;
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 68vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  margin-top: 2rem;
  
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 11px;
    width: 11px;
    border: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
    background-color: ${({ theme }) => theme.palette.grey._425.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.blue._400.hex()};
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    height: 30vh;
    position: relative;
    bottom: 30px;
    margin-top: 1rem;
  }
`;

export const Content = styled.div`
  width: 95%;
  height: 100%;
`;

export const Header = styled.header`
  width: 100%;
  height: auto;
  margin-top: 1rem;
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    position: relative;
    bottom: 30px;
    margin-top: 0;
  }
`;

export const Footer = styled.footer`
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  margin: 0 2rem;

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    margin: 0;
  }
`;

export const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-family: Inter;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.blue._970.hex()};

  @media (min-width: ${MAX_SIZE_MOBILE}px) {
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    font-size: 2rem;
    font-weight: 400;
  }
`;

export const ButtonContainer = styled.div`
  width: 95%;
  height: auto;
  margin-bottom: 1rem;
  margin-right: 1.2rem;

  button {
    width: 97%;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 550px;
  
  @media (max-width: 500px) {
    max-height: 220px;
  }

  @media (min-width: 501px) and (max-width: ${MAX_SIZE_MOBILE}px) {
    max-height: 240px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    border-radius: 0;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem 1rem 0 0;
`;
