import { RadioYesOrNo, QuantityField } from '../../../../..';

import {
  Container,
  Header,
  LeftSide,
  RightSide,
  FormContainer,
} from './styles';

import { MinimalDogAnimation } from '../../../../../../assets/animations';
import { UseFormik } from '../../../../../../utils/Formik/types';

interface Props {
  formik: UseFormik<any>;
}

const ComingPet = ({ formik }: Props) => (
  <Container>
    <Header>
      <LeftSide>
        <h1>
          Vai levar pet ?
        </h1>
        <RadioYesOrNo
          formLabel=""
          id="comingPet"
          labelPlacement="end"
          formik={formik}
        />
      </LeftSide>
      <RightSide>
        <MinimalDogAnimation />
      </RightSide>
    </Header>
    {formik.values.comingPet.toString() === 'true' && (
    <>
      <FormContainer className="form-1">
        <QuantityField
          formik={formik}
          border={false}
          title="Quantidade de pets"
          id="petsQuantity"
          initialQuantity={1}
        />
      </FormContainer>
    </>
    )}
  </Container>
);

export default ComingPet;
