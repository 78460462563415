import styled, { css, keyframes } from 'styled-components';

const fadeInModal = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 500px;
  }
`;

const fadeOutModal = keyframes`
  from {
    opacity: 1;
    width: 500px;
  }
  to {
    opacity: 0;
    width: 0;
  }
`;

export const Form = styled.form`
  border-radius: 10px;
  gap: 0.3rem;

  p, strong {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }
`;

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100%;
  min-width: fit-content;
  max-height: 600px;

  position: fixed;
  top: 8%;
  bottom: 10%;
  left: 15%;
  right: 10%;
  z-index: 20;
  opacity: 0;
  border-radius: 20px;

  background: ${({ theme }) => theme.palette.white.main.hex()};
  max-height: 600px;

  ${(props) => (props.isOpen ? css`
    animation: ${fadeInModal} 0.5s ease forwards;
    .btn-submit {
      animation: ${fadeInModal} 0.5s ease forwards;
    }
    ` : css`
      animation: ${fadeOutModal} 0.5s ease forwards;
      .btn-submit {
        animation: ${fadeOutModal} 0.5s ease forwards;
      }
  `)}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._410.hex()};
  padding: 20px 20px;
  gap: 0.5rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 20px;
  gap: 0.5rem;
  content-visibility: auto;

  &::-webkit-scrollbar {
    width: 0.8rem;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background:  ${({ theme }) => theme.palette.grey._360.hex()};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const Title = styled.strong`
  font-weight: bold;
  font-size: 1.375rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  color: ${({ theme }) => theme.palette.blue._970.hex()};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  min-height: 208px;
  height: fit-content;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._410.hex()};
  margin: 0.5rem;
  padding: 1rem;
  text-align: justify;
  word-break: break-all;

  p, span, strong {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    margin: 0.5rem 0;
  }

  .isAdding {
    color:  ${({ theme }) => theme.palette.green._700.hex()};
  }

  .isRemoving {
    color:  ${({ theme }) => theme.palette.red._500.hex()};
  }
`;

export const Field = styled.div`
  max-width: 150px !important;
  fieldset {
    border: solid 1px ${({ theme }) => theme.palette.white._430.hex()} !important;
  }
`;

export const FieldDescription = styled.div`
  max-width: 200px !important
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background: transparent;

  svg {
    color: ${({ theme }) => theme.palette.grey._450.hex()};;
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr 50px 50px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.blue._250.hex()};
  border-radius: 6px;
  padding: 1rem 0;

  strong {
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }

  strong:first-child {
    margin-left: 1rem; 
  }
`;

export const TableBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr 50px 50px;
  width: 100%;

  span {
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;  
  width: 22px;
  height: 22px;
  margin-top: 0.5rem;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  min-width: 70vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.grey._350.hex()};
  position: relative;
  bottom: 0;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
  background:  ${({ theme }) => theme.palette.white.main.hex()};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  button {
    margin: 1rem;
    cursor: pointer;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.295);
  backdrop-filter: blur(1px);
  z-index: 10;
`;
