import { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';

import { downloadGuestListCsvFile } from '../../../../../services/Owner/OwnerReport/request';

import { useExpensesReport } from '../../../../../hooks/OwnerPage/OwnerReport/useExpensesReport';
import { useViewMode } from '../../../../../context/ViewMode/ViewMode';
import { useUser } from '../../../../../context/UserContext';
import { useToast } from '../../../../../context/ToastContext';
import { useAnalytics } from '../../../../../hooks/useAnalytics';

import CustomDatePickerRange from '../../CustomDatePickerRange';
import DownloadButton from '../../Buttons/DownloadButton';
import LoadingStepper from '../../LoadingStepper';

import {
  Form,
  DatePickerContainer,
} from './styles';

const Filters = () => {
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const {
    startDate,
    endDate,
  } = useExpensesReport();

  const { dispatchEvent } = useAnalytics();
  const toast = useToast();
  const { userInformation } = useUser();
  const { mode } = useViewMode();

  const ownerId = useMemo(() => (userInformation?.owner?.id || undefined), [userInformation]);
  const viewModeOwnerId = useMemo(() => mode?.user_info?.owner?.id || undefined, [mode]);

  const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');

  const formik = useFormik({
    initialValues: {
      startDate,
      endDate,
    },
    onSubmit: async (values) => {
      try {
        setIsDownloading(true);
        const params = {
          start_date: formatDate(values.startDate),
          end_date: formatDate(values.endDate),
          owner_id: ownerId || viewModeOwnerId,
        };

        const args = {
          params,
          setProgress: setDownloadProgress,
        };

        await downloadGuestListCsvFile(args);

        if (downloadProgress >= 100) {
          setTimeout(() => {
            setIsDownloading(false);
          }, 1500);
        }

        dispatchEvent({
          action: 'Baixou a lista de hóspedes com sucesso',
        });
      } catch {
        dispatchEvent({
          action: 'Falhou em baixar a lista de hóspedes',
        });
        setIsDownloading(false);
        toast.error('Algo deu errado, por favor tente novamente');
      }
    },
  });

  useEffect(() => {
    if (downloadProgress >= 100) {
      setTimeout(() => {
        setIsDownloading(false);
      }, 1500);
    }
  }, [downloadProgress]);

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <DatePickerContainer>
          <CustomDatePickerRange
            id1="startDate"
            id2="endDate"
            calendars={1}
            formik={formik}
            hasInitialDates
            disableCloseOnSelect={false}
            minDate={new Date('2000-01-02')}
            dataCyTextField1="owner-report-expenses-textfield-1"
            dataCyTextField2="owner-report-expenses-textfield-2"
            labelVariant="secondary"
            inputVariant="secondary"
          />
          <DownloadButton
            type="submit"
            label="Baixar lista"
            variantForMobile="secondary"
            variantForDesktop="primary"
          />
        </DatePickerContainer>
      </Form>

      {isDownloading && <LoadingStepper progress={downloadProgress} />}
    </>
  );
};

export default Filters;
