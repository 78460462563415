import React from 'react';

import { StatusProps, Status } from './status';

import { Value } from '../styles';
import { StatusContainer } from './styles';
import { useHostPropertiesFilter } from '../../../../../hooks/useHostPropertiesFilter/useHostPropertiesFilter';

interface StatusComponentProps {
  dataCy: string;
  status: StatusProps;
}

const StatusComponent = ({ status, dataCy }: StatusComponentProps) => {
  const [clicked, setClicked] = React.useState(false);
  const { handleChangeStatusFilter, statusFilter } = useHostPropertiesFilter();

  const handleSelectStatus = () => {
    if (status === Status.Active) {
      // handleChangeStatusFilter(1);
      setClicked(true);
    } else if (status === Status.Inactive) {
      // handleChangeStatusFilter(2);
      setClicked(true);
    } else if (status === Status.Closed) {
      // handleChangeStatusFilter(3);
      setClicked(true);
    } else if (status === Status.Onboarding) {
      // handleChangeStatusFilter(4);
      setClicked(true);
    }
  };

  const hadleClickAgain = () => {
    setClicked(false);
    handleChangeStatusFilter(0);
  };

  return (
    <StatusContainer
      status={status}
      selectedStatus={statusFilter as StatusProps}
    >
      <button
        onClick={clicked ? hadleClickAgain : handleSelectStatus}
        type="button"
      >
        <Value
          data-cy={dataCy}
        >
          {status === 'Active' && 'Ativo'}
          {status === 'Inactive' && 'Inativo'}
          {status === 'Closed' && 'Fechado'}
          {status === 'Onboarding' && 'Onboarding'}
        </Value>
      </button>
    </StatusContainer>
  );
};

export default StatusComponent;
