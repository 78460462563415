import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import Upload from '../../../../../../Upload';
import { useFile } from '../../../../../../../hooks/FileHook/useFile';
import {
  FileAWSFormat,
  FileProps,
  FileReference,
  FileReferenceResponse,
} from '../../../../../../../context/FileContext/types';
import { useToast } from '../../../../../../../context/ToastContext';

import addPhotoSketchIcon from '../../../../../../../assets/icons/expense/photoSketch.svg';
import { Guest } from '../../../../../../../services/CompReservations/types';

interface IUploadComponent {
  guestInformations: Guest;
  setFile: (x: FileProps) => void;
  type: keyof Guest;
}

const UploadComponent = ({
  guestInformations,
  setFile,
  type,
}: IUploadComponent) => {
  const toast = useToast();

  const [documentPhotoExists] = useState<boolean>(false);
  const [documentClicked, setDocumentClicked] = useState(false);
  const { createFileReference, uploadFileToS3 } = useFile();

  const [documentPhoto, setDocumentPhoto] = useState<FileProps>({
    id: guestInformations?.[type]?.uid || uuid(),
    uid: guestInformations?.[type]?.uid || '',
    name: guestInformations?.[type]?.name || '',
    size: guestInformations?.[type]?.size || 0,
    readableSize: '0 KB',
    file: guestInformations?.[type]?.url || addPhotoSketchIcon,
    MIMEtype: '',
    previewURL: guestInformations?.[type]?.url || addPhotoSketchIcon,
    url: guestInformations?.[type]?.url || addPhotoSketchIcon,
    uploadProgress: 0,
    uploaded: false,
    error: false,
  });

  const handleUploadFile = async (file: FileProps) => {
    let fileResponse: FileReferenceResponse | { uid: null } = { uid: null };

    try {
      const fileReference: FileReference = {
        category: 'document',
        name: file.name,
        content_type: file.MIMEtype,
      };

      const responseFile: FileReferenceResponse = await createFileReference(fileReference);

      const params: FileAWSFormat = {
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        content_type: fileReference.content_type,
        key: responseFile.storage.fields.key,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        file: documentPhoto.file,
        fileId: responseFile.uid,
      };

      await uploadFileToS3(file, params);

      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
    } catch (e: unknown) {
      toast.error('Não foi possível fazer o upload do documento!');
    }

    return fileResponse;
  };

  async function handleSelectFile(file: FileProps) {
    if (!documentClicked) {
      setDocumentClicked(true);

      let responseDocumentFile: FileReferenceResponse | { uid: null } = { uid: null };
      responseDocumentFile = await handleUploadFile(file);

      if (!responseDocumentFile.uid) {
        toast.error('Não foi possível realizar o upload do arquivo do documento');
      } else {
        setDocumentPhoto((prevFile) => ({
          ...prevFile,
          ...file,
          uid: responseDocumentFile?.uid || '',
        }));
        setFile({
          ...file,
          uid: responseDocumentFile?.uid || '',
        });
      }
    }
  }

  return (
    <Upload
      file={documentPhoto}
      setFile={(file: FileProps) => handleSelectFile(file)}
      onCloseFile={documentPhotoExists || false}
      accept={['image/*', '.pdf']}
    />
  );
};

export default UploadComponent;
