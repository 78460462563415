import { motion } from 'framer-motion';
import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../hooks/useMobile/useMobile';

export const MinimalContainer = styled(motion.div)`
  padding: 5px;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    padding: 10px;
  }

  h1 {
    color: var(--darkk-blue-900, #001840);
    text-align: center;

    /* Heading 4 */
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;

    @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
      font-size: 1.3rem;
    }
  }
`;

export const Container = styled(motion.div)`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    p {
      max-width: 600px;
    }
  }

  h1 {
    color: var(--darkk-blue-900, #001840);
    text-align: center;

    /* Heading 4 */
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */

    @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
      font-size: 1.5rem;
    }
  }
  p {
    color: var(--gray-800, #394760);
    text-align: center;

    /* fonte/corpo/legenda */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */

    @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
      font-size: 1.3rem;
    }
  }
`;
