import styled, { css } from 'styled-components';
import { PARTNERS_EXEPTION_PADDING } from './utils/exeptionPadding';

export const getMaxWidthDesktop = css`
  padding: 30px 15px;

  @media (min-width: 1200px) {
    width: 100%;

    ${PARTNERS_EXEPTION_PADDING.map((item) => css`
        .${item} {
          max-width: 100%;
        }
      `)}

    max-width: 1200px;
  }
`;

export const Container = styled.div<{
  disablePadding?: boolean;
}>`
  width: 100%;
  height: 100%;
  padding: 0px 0 250px 0;
  background: ${({ theme }) => theme.palette.white._300.hex()};

  ${({ disablePadding }) => disablePadding
    && css`
      padding: 0;
    `}

  div {
    -webkit-tap-highlight-color: transparent;
  }

  // Desktop only
  @media (min-width: 1000px) and (min-height: 620px) {
    padding: 0;

    top: 0;
    left: 0;
    z-index: 99;
    position: fixed;
    overflow-y: scroll;

    // color blue scrollbar

    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #394760;
      height: 20px;
    }
  }

  button {
    cursor: pointer;
  }

  p,
  a,
  h1,
  button {
    font-family: "Inter";
    -webkit-tap-highlight-color: transparent;
  }
`;

export const DesktopWrapper = styled.div`
  position: relative;
`;

export const DesktopWrapperContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const WhiteShadow = styled.div`
  bottom: 0;
  width: 100%;
  height: 20px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
`;

export const Content = styled.div<{
  disablePadding?: boolean;
}>`
  @media (min-width: 1200px) {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 270px;

    ${({ disablePadding }) => disablePadding
      && css`
        padding-bottom: 0;
      `}

      /* &::before {
      left: 0;
      bottom: 0;
      width: 100%;
      content: "";
      height: 70px;
      z-index: 9999;
      position: fixed;
      pointer-events: none;

      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9) 95%
      );
      }*/
  }
`;
