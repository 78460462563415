import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  width: fit-content;
  max-width: 360px;
  height: auto;
  margin: 16px;
  border: 1px solid #d9dcdf;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  padding: 1rem;

  .react-calendar {
    text-align: center;
    color: #949ba0;
    button {
      border: none;
      background-color: ${({ theme }) => theme.palette.white.main.hex()};
      cursor: pointer;
    }
    span {
      font-family: ${({ theme }) => theme.fonts.familys._6};
      font-weight: ${({ theme }) => theme.fonts.weights.bold};
      line-height: 20px;
    }
    abbr {
      font-family: ${({ theme }) => theme.fonts.familys._6};
    }
    .react-calendar__navigation {
      padding-bottom: 0.813rem;
      border-bottom: 1px solid
      ${({ theme }) => theme.palette.grey._400.hex()};
    }
    .react-calendar__navigation__next-button,
    .react-calendar__navigation__prev-button{
      padding: 0.5rem;
    }
    .react-calendar__navigation__label::first-letter {
      text-transform: uppercase;
    }
    .react-calendar__month-view__weekdays {
      margin: 0.5rem 0;
      font-family: ${({ theme }) => theme.fonts.familys._6};
      font-size: 14px;
      font-weight: ${({ theme }) => theme.fonts.weights.regular};

    }
    .tile-class {
      font-style: normal;
      font-size: 14px;
      font-weight: ${({ theme }) => theme.fonts.weights.regular};
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      border: none;
      background-color: ${({ theme }) => theme.palette.white.main.hex()};
      margin: 0.4rem 0 0;
      cursor: pointer;
      height: 35px;
      width: 35px;
      transition: all 400ms;
      &:hover {
        color: ${({ theme }) => theme.palette.blue._950.hex()};
      }
    }
    .react-calendar__tile:enabled:hover {
      background: ${({ theme }) => theme.palette.grey._400.hex()};
      border-radius: 10px;
    }
    .react-calendar__tile--active {
      background: linear-gradient(180deg, #0D4BD0 0%, #215789 100%);
      color: white;
      border-radius: 10px;
      padding: 0.5rem;
      border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
    }

    .react-calendar__tile--now {
      background-color: ${({ theme }) => theme.palette.grey._550.hex()};
      color: white;
      border-radius: 10px;
    }
    .react-calendar__tile--hasActive{
      background: linear-gradient(180deg, #0D4BD0 0%, #215789 100%);
      color: white;
      border-radius: 10px;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${({ theme }) => theme.palette.grey._450.hex()};
    }
  }
`;
