import { FormikFields } from '../types';

export const secGuestMessage = ', é obrigatória para este tipo de propriedade.';

type RequiredField = {
  [key: string]: FormikFields[];
};

const requiredField: RequiredField = {
  hotel: [FormikFields.NAME],

  house: [
    FormikFields.NAME,
    FormikFields.CPF,
    FormikFields.PHONE,
    FormikFields.BACK_DOCUMENT_PHOTO,
    FormikFields.FRONT_DOCUMENT_PHOTO,
  ],

  apartment: [
    FormikFields.NAME,
    FormikFields.CPF,
    FormikFields.PHONE,
    FormikFields.BACK_DOCUMENT_PHOTO,
    FormikFields.FRONT_DOCUMENT_PHOTO,
  ],
};

export function validateSecGuestFields(propertyType: string, field: FormikFields): boolean {
  // faz a validação para os hóspedes que não sejam o hóspede principal
  // a propriedade pode ser do tipo: hotel, apartment e house
  // se for house e apartment, todos os campos são obrigatórios menos o email
  // se for hotel, apenas o nome e o telefone são obrigatórios

  const lowerPropertyType = propertyType?.toLowerCase();

  const filed = requiredField[lowerPropertyType];
  const isRequired = filed ? filed.includes(field) : false;

  return !isRequired;
}
