export const MAX_SIZE_MOBILE = 900; // 900px;

export const mobileGridLayout = '70% 30%';
export const desktopGridLayout = '25% 25% 25% 25%';

export const itemHeightMobile = 90; // 90px
export const itemsVisibleInMobileBeforeScroll = 4;

export const itemHeightDesktop = 70; // 70px
export const itemsVisibleInDesktopBeforeScroll = 4;

export const buttonsWidthDesktop = 150; // 150px;
export const buttonsWidthMobile = 56; // 56px;

export const buttonsHeightDesktop = 38.5; // 38.5px;
export const buttonsHeightMobile = 38.5; // 38.5px;

export const expensesFiltersWidthDesktop = 723; // 723px
export const inputsGap = 16; // 16px
