import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../hooks/useMobile/useMobile';

export const Container = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
