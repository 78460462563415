import styled, { css, keyframes } from 'styled-components';

const MAX_SIZE_MOBILE = 900;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<{ loading: boolean }>`
  position: absolute;
  width: 75px;
  height: 75px;
  left: 308px;
  top: 150px;
  border-radius: 50%;
  z-index: 10;
  background: 
    radial-gradient(circle, white 60%, transparent 60%),
    conic-gradient(from 0deg at 50% 50%, #2B52A2 0deg, rgba(196, 196, 196, 0) 360deg);

  ${({ loading }) => (loading
    ? css`
      animation: ${rotate} 1s linear infinite;
  ` : css`
      display: none;
    `)}

    @media(max-width: ${MAX_SIZE_MOBILE}px) {
      left: 40%;
      top: 40%;
    }
`;
