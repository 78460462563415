import styled, { keyframes } from 'styled-components';

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-40px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const ManagementPanelComponentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.palette.white._300.hex()};
`;

export const ManagementPanelComponentContent = styled.main`
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  opacity: 0;
  animation-name: ${FadeInOpacity};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
`;
