import React from 'react';
import { IColor } from '../../../../types';
import { Color, StatusContainer } from './styles';

interface IStatus {
  status?: number;
  onlyStatus?: boolean;
}

const Status = ({
  status,
  onlyStatus = false,
}: IStatus) => {
  const [color, setColor] = React.useState<IColor>('not_concluded');

  React.useEffect(() => {
    const handleChoiseColorByStatus = () => {
      if (status) {
        if (status >= 100) {
          setColor('concluded');
        } else {
          setColor('not_concluded');
        }
      }
    };

    handleChoiseColorByStatus();
  }, []);

  const fixedStatus = status && status.toFixed(2);

  if (status === undefined) {
    return (
      <StatusContainer>
        <h1>Status: </h1>
        <Color color={'not_defined'} />
        <h1>Não definido</h1>
      </StatusContainer>
    );
  }

  if (color === 'concluded') {
    return (
      <StatusContainer>
        <h1>Status: </h1>
        <Color color={color} />
        <h1>
          KPIs estão alinhados
        </h1>
      </StatusContainer>
    );
  }

  return (
    <StatusContainer>
      <h1>Status: </h1>
      <Color color={color} />
      {!onlyStatus && (
        <h1>
          {fixedStatus}
          % concluídos
        </h1>
      )}
      {onlyStatus && (
        <h1>
          KPIs não alinhados
        </h1>
      )}
    </StatusContainer>
  );
};

Status.defaultProps = {
  status: undefined,
  onlyStatus: false,
};

export default Status;
