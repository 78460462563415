import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  svg {
    min-width: 1440px;
  }
`;
