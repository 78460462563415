import request from '../request';

import {
  ResponsePaymentDataProps,
  ResponsePaymentDataStatus,
  ResponseSendPaymentDataProps,
} from './types';

import { PaymentDataProps } from '../../context/PaymentVoucher/types/paymentData.types';

import { formatPaymentData, formatPaymentDataStatus } from './utils';

interface UpdatePaymentDataByIdProps {
  id: number;
  paymentData: PaymentDataProps;
}

export const getPaymentsData = async () => {
  const { data } = await request.get<ResponsePaymentDataProps>('/reservation_payment/');
  const dataFormatted = formatPaymentData(data);
  return dataFormatted;
};

export const getPaymentStatus = async (search?: string) => {
  if (search) {
    const { data } = await request.get<ResponsePaymentDataStatus>(`/reservation_payment_status/?guest=${search}`);
    const dataFormatted = formatPaymentDataStatus(data);
    return dataFormatted;
  }

  const { data } = await request.get<ResponsePaymentDataStatus>('/reservation_payment_status/');
  const dataFormatted = formatPaymentDataStatus(data);
  return dataFormatted;
};

export const sendPaymentData = async (paymentData: ResponseSendPaymentDataProps) => {
  const { data } = await request.post('/reservation_payment/', {
    ...paymentData,
  });
  return data;
};

export const deletePaymentData = async (id: number) => {
  const { data } = await request.delete(`/reservation_payment/${id}/`);
  return data;
};

export const getPaymentDataById = async (id: number) => {
  const { data } = await request.get(`/reservation_payment/?reservation=${id}`);
  const dataFormatted = formatPaymentData(data);
  return dataFormatted;
};

export const updatePaymentDataById = async ({ id, paymentData }: UpdatePaymentDataByIdProps) => {
  const { data } = await request.put(`/reservation_payment/${id}/`, {
    ...paymentData,
  });
  return data;
};
