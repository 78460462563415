import React, {
  FC,
  createContext,
  useContext,
  ReactElement,
  useState,
} from 'react';

export interface EditFormType {
  openForm: boolean,
  formActive: number,
  setOpenForm: Function,
  setFormActive: Function,
}

export const EditFormContext = createContext<EditFormType>({
  openForm: false,
  setOpenForm: () => {},
  formActive: 0,
  setFormActive: () => {},
});

export const EditFormProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formActive, setFormActive] = useState<number>(0);

  return (
    <EditFormContext.Provider
      value={{
        openForm,
        setOpenForm,
        formActive,
        setFormActive,
      }}
    >
      {children}
    </EditFormContext.Provider>

  );
};

export const useEditForm = () => useContext(EditFormContext);
