import styled from 'styled-components';
import { PartnerClusters } from '../../../types';

const getClusterColor = (cluster: PartnerClusters) => {
  if (cluster === 'Gold') return '#DDBA00';
  if (cluster === 'Platinum') return '#22797E';
  if (cluster === 'Silver') return '#616161';
  return '#000';
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  
  p {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    
    font-size: 1.2rem;
    font-family: Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.blue._970.hex()};
  }

  strong {
    font-size: 1.2rem;
    font-family: Inter;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    padding: 0 0.3rem;
  }
`;

export const Cluster = styled.div<{ cluster: PartnerClusters }>`
  font-size: 1.4rem;
  font-family: Inter;
  font-weight: 700;
  color: ${({ cluster }) => getClusterColor(cluster)};
  position: relative;
  bottom: 2.5px;
`;
