import React, {
  FC, useState, useEffect, Fragment, memo,
} from 'react';

import { useFormik } from 'formik';
import { useInView } from 'react-intersection-observer';

import theme from '../../../../styles/themes';
import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../assets/icons/generals/arrowTop.svg';
import arrowBottomWhite from '../../../../assets/icons/generals/arrowBottomWhite.svg';
import arrowTopWhite from '../../../../assets/icons/generals/arrowTopWhite.svg';

import {
  Container,
  Content,
  ContainerTable,
  Table,
  TableHeader,
  TableBody,
  Tr, Th, Td,
  ThContainer,
  TdContainer,
  TitleContainer,
  Title,
  Subtitle,
  ContainerArrows,
  Image,
  MoreVertButtonContainer,
  MoreVertButton,
  MoreVertIcon,
  RadioButton,
  ExpandIcon,
  KeyboardArrowUpIcon,
  WrapperGridRow,
  ContainerGridRow,
  CollapseIcon,
  CollapseRow,
  manualFitColor,
  ContainerInformation,
  ContentInformation,
  ContainerSpinner,
  Spinner,
} from '../styles';

import Tooltip from '../../../Tooltip';
import TransferIcon from '../../../IconsComponents/TransferIcon';

import { useFinancialClose } from '../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseOwner } from '../../../../hooks/FinancialCloseHook/useFinancialCloseOwner';

import {
  GridOwnerProps,
  OrderGridOptions,
  PropertiesProps,
} from '../../../../context/FinancialClosePage/FinancialCloseOwnerContext/types';

import { numberToCurrency } from '../../../../utils/Formatter';
import { sortFinancialDataOfOwner } from '../../../../context/FinancialClosePage/FinancialCloseOwnerContext/utils';

const GridOwner: FC = () => {
  const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    openActions,
    handleOpenActions,
    actionSelected,
    activeLinearProgress,
  } = useFinancialClose();

  const {
    financialDataOwner,
    handleSetFinancialDataOwner,
    handleUpdateCheckedOfOwnerSelected,
    handleUpdateExpandedOfOwnerSelected,
    handleUpdateAllCheckedsOfOwnerSelected,
  } = useFinancialCloseOwner();

  const [expandAdjustment, setExpandAdjustment] = useState<boolean>(false);
  const [expandTransfer, setExpandTransfer] = useState<boolean>(false);

  const [orderGridBy, setOrderGridBy] = useState<OrderGridOptions>({
    order_by: 'owner',
    order: 'asc',
  });

  const DEFAULT_LIMIT = 15;
  const [limitFinancialData, setLimitFinancialData] = useState<number>(financialDataOwner.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : financialDataOwner.length);

  const [isLoadMoreDatas, setIsLoadMoreDatas] = useState<boolean>(false);

  const { ref, inView } = useInView();

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const handleSortFinancialCloseData = () => {
    let response: GridOwnerProps[] = financialDataOwner;

    if (orderGridBy.order_by === 'owner') {
      response = sortFinancialDataOfOwner(
        financialDataOwner, orderGridBy.order_by, orderGridBy.order,
      );
    } else {
      response = sortFinancialDataOfOwner(
        financialDataOwner,
        orderGridBy.order_by,
        orderGridBy.order,
        financialDataOwner.filter((item) => item.expanded).map((item) => item.id),
      );
    }
    return response;
  };

  useEffect(() => {
    const response = handleSortFinancialCloseData();
    handleSetFinancialDataOwner([...response]);
  }, [orderGridBy]);

  const handleUpdateChecked = (owner: GridOwnerProps) => {
    handleUpdateCheckedOfOwnerSelected(owner.id);
  };

  const handleUpdateExpandedRows = (id: number) => {
    handleUpdateExpandedOfOwnerSelected(id);
  };

  const handleClickMoreVertButton = (owner: GridOwnerProps) => {
    handleOpenActions(!openActions);
    handleUpdateAllCheckedsOfOwnerSelected(false);
    handleUpdateCheckedOfOwnerSelected(owner.id, true);
  };

  const handleLoadNext = () => {
    setLimitFinancialData(financialDataOwner && limitFinancialData >= financialDataOwner.length
      ? financialDataOwner.length : limitFinancialData + DEFAULT_LIMIT);
  };

  const checkIfAllOwnersIsSelected = () => {
    const totalSelected = financialDataOwner.filter((item) => item.checked).length;
    return totalSelected === financialDataOwner.length;
  };

  const handleCheckAll = () => {
    const allIsChecked: boolean = checkIfAllOwnersIsSelected();
    handleUpdateAllCheckedsOfOwnerSelected(!allIsChecked);
  };

  useEffect(() => {
    if (inView) {
      setIsLoadMoreDatas(true);

      setTimeout(() => {
        handleLoadNext();
      }, 300);
    }
  }, [inView]);

  return (
    <Container>
      <Content
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <ContainerTable>
          <Table>

            {/* Header Main Grid */}
            <TableHeader>
              <Tr>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={20}
                    maxWidth={widthWindow < 1440 ? 34 : 60}
                  >
                    <Tooltip text="Selecionar todos">
                      <RadioButton
                        data-cy="radio-button-select-all"
                        type="radio"
                        id={'radio'}
                        name={'radio'}
                        checked={checkIfAllOwnersIsSelected()}
                        onClick={() => handleCheckAll()}
                      />
                    </Tooltip>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={180}
                  >
                    <TitleContainer>
                      <Title data-cy="title-owner" disableWidth>Proprietário</Title>
                      <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar proprietários (Z-A)' : 'Ordenar proprietários (A-Z)'}`}>
                        <ContainerArrows onClick={() => setOrderGridBy({
                          order_by: 'owner',
                          order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                        })}
                        >
                          <Image src={arrowTop} alt="Seta para cima" />
                          <Image src={arrowBottom} alt="Seta para baixo" />
                        </ContainerArrows>
                      </Tooltip>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-balance-initial">Saldo Inicial</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-expenses">Despesas</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-reservations-income">Receita Reservas</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-reservations-revenue">Faturamento Reservas</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-reservations-commission">Comissão</Title>
                    </TitleContainer>
                  </ThContainer>
                </Th>
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableMarginHorizontal={expandAdjustment}
                    disableBorderTopRightRadius={expandAdjustment}
                    disableBorderRight={expandAdjustment}
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-adjustments">Ajustes</Title>
                      {!expandAdjustment
                      && (
                        <Tooltip text="Expandir">
                          <ExpandIcon
                            data-cy="btn-expand-adjustments"
                            onClick={() => setExpandAdjustment(true)}
                          />
                        </Tooltip>
                      )}
                    </TitleContainer>
                    <Subtitle data-cy={!expandAdjustment ? 'subtitle-adjustments-all' : 'subtitle-adjustments-properties'}>
                      {!expandAdjustment ? 'Todos' : 'Ajustes Imóveis'}
                    </Subtitle>
                  </ThContainer>
                </Th>
                { expandAdjustment && (
                  <Th
                    className="th-main-grid-fixed-top"
                    customColor={theme.palette.white.main.hex()}
                  >
                    <ThContainer
                      disableMarginHorizontal={expandAdjustment}
                      disableBorderTopLeftRadius={expandAdjustment}
                      disableBorderLeft={expandAdjustment}
                      disableBorderBottom
                      minWidth={120}
                    >
                      <TitleContainer expanded={expandAdjustment}>
                        <Tooltip text="Recolher">
                          <CollapseIcon
                            data-cy="btn-collapse-adjustments"
                            onClick={() => setExpandAdjustment(false)}
                          />
                        </Tooltip>
                      </TitleContainer>
                      <Subtitle data-cy="subtitle-adjustments-owner">Ajustes Proprietário</Subtitle>
                    </ThContainer>
                  </Th>
                )}
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableMarginHorizontal={expandTransfer}
                    disableBorderTopRightRadius={expandTransfer}
                    disableBorderRight={expandTransfer}
                    disableBorderBottom
                    minWidth={120}
                  >
                    <TitleContainer>
                      <Title data-cy="title-transfer">Repasse</Title>
                      {!expandTransfer
                      && (
                        <Tooltip text="Expandir">
                          <ExpandIcon
                            data-cy="btn-expand-transfer"
                            onClick={() => setExpandTransfer(true)}
                          />
                        </Tooltip>
                      )}
                    </TitleContainer>
                    <Subtitle data-cy={!expandTransfer ? 'subtitle-transfer-all' : 'subtitle-transfer-negative-balance-rebate'}>
                      {!expandTransfer ? 'Todos' : 'Abatimento de saldo negativo'}
                    </Subtitle>
                  </ThContainer>
                </Th>
                { expandTransfer && (
                  <Th
                    className="th-main-grid-fixed-top"
                    customColor={theme.palette.white.main.hex()}
                  >
                    <ThContainer
                      disableMarginHorizontal={expandTransfer}
                      disableBorderTopLeftRadius={expandTransfer}
                      disableBorderLeft={expandTransfer}
                      disableBorderBottom
                      minWidth={120}
                    >
                      <TitleContainer expanded={expandTransfer}>
                        <Tooltip text="Recolher">
                          <CollapseIcon
                            data-cy="btn-collapse-transfer"
                            onClick={() => setExpandTransfer(false)}
                          />
                        </Tooltip>
                      </TitleContainer>
                      <Subtitle data-cy="subtitle-transfer">Repasse</Subtitle>
                    </ThContainer>
                  </Th>
                )}
                <Th
                  className="th-main-grid-fixed-top"
                  customColor={theme.palette.white.main.hex()}
                >
                  <ThContainer
                    disableBorder
                    minWidth={20}
                  />
                </Th>
              </Tr>
            </TableHeader>

            {/* Body Main Grid */}
            <TableBody>
              { financialDataOwner.length > 0 && financialDataOwner
                .slice(0, limitFinancialData).map((item) => (
                  <Fragment key={item.id}>
                    <Tr data-cy="grid-row-owner" key={item.id}>
                      <Td customColor={item.status}>
                        <TdContainer
                          disableBorder
                          disableBorderRadius
                          minWidth={20}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <RadioButton
                            data-cy={`radio-button-select-owner-${item.id}`}
                            type="radio"
                            id={`radio_${item.id}`}
                            name={`radio_${item.id}`}
                            checked={item.checked}
                            onClick={() => handleUpdateChecked(item)}
                          />
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          maxWidth={800}
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._350.hex()}
                        >
                          <TitleContainer>
                            <Title data-cy={`owner-${item.id}-name`} disableWidth>
                              {item.owner.name}
                            </Title>
                            {(item.expanded) ? (
                              <Tooltip text="Recolher">
                                <CollapseIcon onClick={() => handleUpdateExpandedRows(item.id)} />
                              </Tooltip>
                            ) : (
                              <Tooltip text="Expandir">
                                <ExpandIcon onClick={() => handleUpdateExpandedRows(item.id)} />
                              </Tooltip>
                            )}
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{numberToCurrency(item.balance_initial)}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{numberToCurrency(item.expense_value)}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{numberToCurrency(item.reserves.income)}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{numberToCurrency(item.reserves.revenue)}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{numberToCurrency(item.commission)}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder={!expandAdjustment}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded={expandAdjustment}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {!expandAdjustment
                                ? numberToCurrency(item.adjustments.total)
                                : numberToCurrency(item.adjustments.properties)}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      { expandAdjustment && (
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title
                              customColor={
                                manualFitColor[['0', '0,00', '0.00']
                                  .includes(item?.manual_fit?.value?.toString() || '0') ? 'default'
                                  : item?.manual_fit?.is_adding?.toString() || 'default']
                              }
                            >
                              {numberToCurrency(item.adjustments.owner)}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      )}
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorder={!expandTransfer}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded={expandTransfer}
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>
                              {!expandTransfer
                                ? numberToCurrency(item.transfer.total)
                                : numberToCurrency(item.transfer.negative_balance_rebate)}
                            </Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      { expandTransfer && (
                      <Td customColor={item.status}>
                        <TdContainer
                          loading={item.checked && activeLinearProgress && actionSelected === 'update'}
                          disableBorderRadius
                          customColor={item.status === 'Closed' ? theme.palette.green._100.hex() : theme.palette.white._230.hex()}
                          expanded
                          minWidth={120}
                        >
                          <TitleContainer>
                            <Title>{numberToCurrency(item.transfer.transfer_value)}</Title>
                          </TitleContainer>
                        </TdContainer>
                      </Td>
                      )}
                      <Td customColor={item.status}>
                        <MoreVertButtonContainer>
                          <MoreVertButton
                            data-cy={`btn-more-options-${item.id}`}
                            type="button"
                            onClick={() => handleClickMoreVertButton(item)}
                          >
                            <MoreVertIcon />
                          </MoreVertButton>
                        </MoreVertButtonContainer>
                      </Td>
                    </Tr>

                    {item.expanded && (
                      <Tr>
                        <Td
                          colSpan={100}
                          customColor={theme.palette.white._350.hex()}
                          className="td-secondary-grid-fixed-top"
                        >

                          {/* Properties Grid */}
                          <WrapperGridRow>
                            <ContainerGridRow>
                              <Title className="reservation-list" data-cy="title-reservations-list">Lista de imóveis</Title>
                              <ContainerTable className="reservation-table">
                                <Table>

                                  {/* Header Properties Grid */}
                                  <TableHeader>
                                    <Tr>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Imóvel</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Imóvel (Z-A)' : 'Ordenar Imóvel (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_code',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Saldo Inicial</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Saldo Inicial (Z-A)' : 'Ordenar Saldo Inicial (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_initial_balance',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Faturamento</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Faturamento (Z-A)' : 'Ordenar Faturamento (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_revenue',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Receita</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Receita (Z-A)' : 'Ordenar Receita (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_income',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Despesas</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Despesas (Z-A)' : 'Ordenar Despesas (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_expenses',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Limpezas</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Limpezas (Z-A)' : 'Ordenar Limpezas (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_cleanings',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Ajustes</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Ajustes (Z-A)' : 'Ordenar Ajustes (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_manualfit',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Repasse</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Repasse (Z-A)' : 'Ordenar Repasse (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_transfer',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Comissão</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Comissão (Z-A)' : 'Ordenar Comissão (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_commission',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={150}
                                        >
                                          <TitleContainer>
                                            <Title disableWidth>Saldo Final</Title>
                                            <Tooltip text={`${orderGridBy.order === 'asc' ? 'Ordenar Saldo Final (Z-A)' : 'Ordenar Saldo Final (A-Z)'}`}>
                                              <ContainerArrows onClick={() => setOrderGridBy({
                                                order_by: 'property_final_balance',
                                                order: orderGridBy.order === 'asc' ? 'desc' : 'asc',
                                              })}
                                              >
                                                <Image src={arrowTopWhite} alt="Seta para cima" />
                                                <Image src={arrowBottomWhite} alt="Seta para baixo" />
                                              </ContainerArrows>
                                            </Tooltip>
                                          </TitleContainer>
                                        </ThContainer>
                                      </Th>
                                      <Th
                                        className="th-secondary-grid-fixed-top"
                                        customColor={theme.palette.grey._330.hex()}
                                        customHeight={30}
                                      >
                                        <ThContainer
                                          disableBorder
                                          disableMarginVertical
                                          minWidth={1000}
                                        />
                                      </Th>
                                    </Tr>
                                  </TableHeader>

                                  {/* Body Properties Grid */}
                                  <TableBody>
                                    {item.properties.length === 0
                                      ? (
                                        <ContainerInformation>
                                          <ContentInformation>
                                            <Title>Nenhuma propriedade foi encontrada!</Title>
                                          </ContentInformation>
                                        </ContainerInformation>
                                      ) : item.properties.map((property: PropertiesProps) => (
                                        <Tr key={property.id}>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>{property.property.code}</Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.initial_balance)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.revenue)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.income)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.expenses)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.cleanings)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.manual_fit)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.transfer)}
                                                </Title>
                                                <TransferIcon color={property.is_to_keep_funds_in_seazone ? 'red' : 'green'} />
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.commission)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              customColor={theme.palette.grey._310.hex()}
                                              minWidth={150}
                                            >
                                              <TitleContainer>
                                                <Title disableWidth>
                                                  {numberToCurrency(property.final_balance)}
                                                </Title>
                                              </TitleContainer>
                                            </TdContainer>
                                          </Td>
                                          <Td
                                            customColor={theme.palette.grey._330.hex()}
                                            customHeight={30}
                                          >
                                            <TdContainer
                                              disableMarginVertical
                                              disableBorderRadius
                                              disableBorder
                                              minWidth={1000}
                                              customColor={theme.palette.grey._330.hex()}
                                            />
                                          </Td>
                                        </Tr>
                                      ))}
                                  </TableBody>
                                </Table>
                              </ContainerTable>
                              <CollapseRow>
                                <KeyboardArrowUpIcon
                                  onClick={() => handleUpdateExpandedRows(item.id)}
                                />
                              </CollapseRow>
                            </ContainerGridRow>
                          </WrapperGridRow>
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                ))}
            </TableBody>
          </Table>

          {financialDataOwner && financialDataOwner?.length > DEFAULT_LIMIT
            && financialDataOwner?.length > limitFinancialData && (
            <ContainerSpinner ref={ref}>
              <Spinner isLoading={isLoadMoreDatas} />
            </ContainerSpinner>
          )}
        </ContainerTable>

      </Content>
    </Container>
  );
};

export default memo(GridOwner);
