import React from 'react';
import { useParams } from 'react-router-dom';
import CardModel from './CardModel';
import Modal from './CardModel/Modal';
import { Container } from './style';

const CardList = () => {
  const { filter } = useParams();
  const size = filter === 'all' ? 10 : 2;
  const images = Array(size).fill(0);

  return (
    <>
      <Modal />
      <Container>
        {images.map((_, index) => (
          <CardModel index={index} />
        ))}
      </Container>
    </>
  );
};

export default CardList;
