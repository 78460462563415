import { Divider } from '@mui/material';
import alertAnimation from '../../assets/animations/source/alert.json';
import discoverAnimation from '../../assets/animations/source/discover.json';

import { GeneralAnimation } from '../../assets/animations/components/GeneralAnimation';

import {
  Container,
  TitleContainer,
  ButtonContainer,
  AnimationContainer,
  LinkButtonContainer,
  ButtonsContainer,
} from './styles';

interface LinksButtonProps {
  source: any;
  link: string;
  children: string;
  disabled?: boolean;
}

const links = {
  'Formulario primeira visita implantação': 'https://app.pipefy.com/public/form/I96wBxVW',
  'Danos do hospede': 'https://docs.google.com/forms/d/e/1FAIpQLSc4XkeNr5HBn0xn_wAFgKwNJu_sBFkUwSJsvkE-86YmxSiiAw/viewform',
};

const LinksButton = ({
  link,
  source,
  children,
  disabled = false,
}: LinksButtonProps) => {
  const handleGoToLink = (path: string) => {
    if (disabled) {
      return;
    }
    window.open(path, '_blank');
  };

  return (
    <LinkButtonContainer disabled={disabled} onClick={() => handleGoToLink(link)}>
      <AnimationContainer disabled={disabled}>
        <div>
          <GeneralAnimation source={source} />
        </div>
      </AnimationContainer>
      <ButtonContainer disabled={disabled}>
        <button disabled={disabled} type="button">{children}</button>
      </ButtonContainer>
    </LinkButtonContainer>
  );
};

LinksButton.defaultProps = {
  disabled: false,
};

const LinksPage = () => (
  <Container>
    <TitleContainer>
      <h2>Selecione uma das opções</h2>
    </TitleContainer>
    <Divider />
    <ButtonsContainer>
      <LinksButton
        link={links['Formulario primeira visita implantação']}
        source={discoverAnimation}
      >
        Formulário primeira visita implantação
      </LinksButton>
      <LinksButton
        link={links['Danos do hospede']}
        source={alertAnimation}
        disabled
      >
        Danos de hospede (desabilitado)
      </LinksButton>
    </ButtonsContainer>
  </Container>
);

export default LinksPage;
