import { motion } from 'framer-motion';

export const BookSVGIcon = () => (
  <motion.svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6 9.9V11H5.4V9.9H0.6C0.44087 9.9 0.288258 9.84205 0.175736 9.73891C0.0632141 9.63576 0 9.49587 0 9.35V0.550002C0 0.404132 0.0632141 0.264238 0.175736 0.161093C0.288258 0.0579478 0.44087 1.54475e-06 0.6 1.54475e-06H4.2C4.54055 -0.000368832 4.87727 0.0658674 5.18767 0.194287C5.49807 0.322708 5.77501 0.510356 6 0.744702C6.22499 0.510356 6.50193 0.322708 6.81233 0.194287C7.12273 0.0658674 7.45945 -0.000368832 7.8 1.54475e-06H11.4C11.5591 1.54475e-06 11.7117 0.0579478 11.8243 0.161093C11.9368 0.264238 12 0.404132 12 0.550002V9.35C12 9.49587 11.9368 9.63576 11.8243 9.73891C11.7117 9.84205 11.5591 9.9 11.4 9.9H6.6ZM10.8 8.8V1.1H7.8C7.48174 1.1 7.17652 1.21589 6.95147 1.42218C6.72643 1.62847 6.6 1.90826 6.6 2.2V8.8H10.8ZM5.4 8.8V2.2C5.4 1.90826 5.27357 1.62847 5.04853 1.42218C4.82348 1.21589 4.51826 1.1 4.2 1.1H1.2V8.8H5.4Z"
      fill="black"
    />
  </motion.svg>
);

export const GridSVGIcon = () => (
  <motion.svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.11111 3.88889H3.88889V6.11111H6.11111V3.88889ZM7.22222 3.88889V6.11111H8.88889V3.88889H7.22222ZM6.11111 8.88889V7.22222H3.88889V8.88889H6.11111ZM7.22222 8.88889H8.88889V7.22222H7.22222V8.88889ZM6.11111 1.11111H3.88889V2.77778H6.11111V1.11111ZM7.22222 1.11111V2.77778H8.88889V1.11111H7.22222ZM2.77778 3.88889H1.11111V6.11111H2.77778V3.88889ZM2.77778 8.88889V7.22222H1.11111V8.88889H2.77778ZM2.77778 1.11111H1.11111V2.77778H2.77778V1.11111ZM0.555556 0H9.44444C9.59179 0 9.7331 0.0585316 9.83728 0.162718C9.94147 0.266905 10 0.408213 10 0.555556V9.44444C10 9.59179 9.94147 9.7331 9.83728 9.83728C9.7331 9.94147 9.59179 10 9.44444 10H0.555556C0.408213 10 0.266905 9.94147 0.162718 9.83728C0.0585316 9.7331 0 9.59179 0 9.44444V0.555556C0 0.408213 0.0585316 0.266905 0.162718 0.162718C0.266905 0.0585316 0.408213 0 0.555556 0Z"
      fill="black"
    />
  </motion.svg>
);

export const ArrowSVGIcon = () => (
  <motion.svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.88889 0V1.11111H1.11111V8.88889H8.88889V6.11111H10V9.44444C10 9.59179 9.94147 9.7331 9.83728 9.83728C9.7331 9.94147 9.59179 10 9.44444 10H0.555556C0.408213 10 0.266905 9.94147 0.162718 9.83728C0.0585316 9.7331 0 9.59179 0 9.44444V0.555556C0 0.408213 0.0585316 0.266905 0.162718 0.162718C0.266905 0.0585316 0.408213 0 0.555556 0H3.88889ZM8.10333 1.11111H5.55556V0H10V4.44444H8.88889V1.89667L5 5.78556L4.21445 5L8.10333 1.11111Z"
      fill="black"
    />
  </motion.svg>
);
