import React, { useState } from 'react';

import FormButtonOwner from '../../FormButtonOwner';
import ModalPropertyLock from '../../Modal/ModalPropertyLock';

import { useMobile } from '../../../../hooks/useMobile/useMobile';

import {
  ButtonContainer,
} from './styles';
import { useAnalytics } from '../../../../hooks/useAnalytics';

interface Props {
  className: string;
}

const RequestBlockProperty = ({ className }: Props) => {
  const { isDesktop } = useMobile();
  const { dispatchEvent } = useAnalytics();
  const [openModalPropertyLock, setOpenModalPropertyLock] = useState<boolean>(false);

  const handleClick = () => {
    dispatchEvent({ action: 'Clicou em solicitar bloqueio do imóvel no botão principal' });
    setOpenModalPropertyLock(true);
  };

  return (
    <>
      <ButtonContainer className={className}>
        <FormButtonOwner
          dataCy="btn-blocking"
          variant={isDesktop ? 'outlined' : undefined}
          onClick={handleClick}
        >
          Solicitar bloqueio do imóvel
        </FormButtonOwner>
      </ButtonContainer>
      <ModalPropertyLock
        openModalPropertyLock={openModalPropertyLock}
        onClose={setOpenModalPropertyLock}
        dataCy="modal-1"
      />
    </>
  );
};

export default RequestBlockProperty;
