import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;

  height: 61px;

  .label {
    width: 100%;
    justify-content: flex-start;
    text-align: left;
  }

  button {
    h1 {
      text-align: center;
      font-size: 1.1rem;
      text-transform: capitalize;
    }

    border-radius: 10px;
    padding: 7px 9px;
    min-width: 150px;
    border: 2px solid #949ba0;
    color: #62656e;
    &:hover {
      border: 2px solid #949ba0;
    }
  }
`;

export const DatePickerContainer = styled.div`
  user-select: none;

  position: relative;
  margin-top: -40px;

`;
