import React, {
  FC,
  useState,
  useEffect,
  memo,
  useMemo,
  useContext,
} from 'react';

import { useFormik } from 'formik';
import moment from 'moment';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { X } from 'react-feather';
import TrashIcon from '../../../../assets/icons/generals/bashRed.svg';

import {
  Container,
  Content,
  FormContainer,
  Header,
  Title,
  CardContainer,
  CardPropertyContainer,
  CardPropertyContent,
  CardAddRemoveValue,
  ContainerQuestion,
  ContainerAddRemove,
  SwitchButton,
  SwitchCircle,
  ContainerValue,
  ContainerDescription,
  ButtonsContainer,
  CloseButton,
  Backdrop,
  ContentLoadMoreDatas,
  ArrowDownIcon,
  ButtonAllManualFits,
  ModalInformation,
  CardInfoContainer,
  CardInfoContent,
  CardInfoItemsContainer,
  CardInfoDeleteButton,
} from '../styles';

import FormButton from '../../../FormButton';
import TextField from '../../../TextField';
import DatePicker from '../../../DatePicker';

import {
  GridOwnerProps,
} from '../../../../context/FinancialClosePage/FinancialCloseOwnerContext/types';

import { OwnerCloseContext } from '../../OwnerClose/OwnerClose';

import { FinancialCloseParams, ManualFitProps } from '../../../../services/FinancialClose/types';

import { deleteManualFitOwner, getManualFitOwner, postManualFitOwner } from '../../../../services/FinancialClose/request';

import { currencyToNumber, numberToCurrency } from '../../../../utils/Formatter';
import { currency } from '../../../../utils/InputMask/Number';

import { useFinancialClose } from '../../../../hooks/FinancialCloseHook/useFinancialClose';
import { useFinancialCloseOwner } from '../../../../hooks/FinancialCloseHook/useFinancialCloseOwner';
import { useToast } from '../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../utils/Messages';

import { formatDate, sortManualFitList } from '../../../../context/FinancialClosePage/FinancialCloseContext/utils';

const ManualFitOwner: FC = () => {
  const {
    openModalManualFit,
    handleOpenModalManualFit,
    handleOpenActions,
    dateSelected,
    handleActiveLinearProgress,
  } = useFinancialClose();

  const {
    financialDataOwner,
    handleUpdateCheckedOfOwnerSelected,
  } = useFinancialCloseOwner();

  const {
    refetchSelectedOwners,
  } = useContext(OwnerCloseContext);

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  type SwitchProps = {
    id: number;
    active: boolean;
  };

  type ManualFitVisibilityProps = {
    id: number;
    visible: boolean;
  };

  const DEFAULT_LIMIT = 3;
  const [limitFinancialData, setLimitFinancialData] = useState<number>(financialDataOwner.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : financialDataOwner.length);

  const params: FinancialCloseParams = {
    start_date: formatDate(dateSelected).start_date,
  };

  const [switchs, setSwitchs] = useState<SwitchProps[]>([]);
  const [manualFitVisibility, setManualFitVisibility] = useState<ManualFitVisibilityProps[]>([]);

  type ManualFitRecordProps = Record<string, ManualFitProps[]>;
  const [manualFitList, setManualFitList] = useState<ManualFitRecordProps>({});

  const [ownersSelected, setOwnersSelected] = useState<GridOwnerProps[]>(financialDataOwner
    .filter((item) => item.checked));

  useEffect(() => {
    if (openModalManualFit) {
      const owners = financialDataOwner.filter((item) => item.checked);

      setSwitchs(owners.length > 0
        ? owners.map((item: GridOwnerProps) => ({
          id: item.id,
          active: false,
        })) : []);

      setManualFitVisibility(owners.length > 0
        ? owners.map((item: GridOwnerProps) => ({
          id: item.id,
          visible: false,
        })) : []);

      setOwnersSelected(() => [...owners]);
    }
  }, [openModalManualFit, financialDataOwner]);

  const getManualFits = async () => {
    let allManualFits: ManualFitRecordProps = {};

    await Promise.all(ownersSelected.map(async (item) => {
      const response: ManualFitProps[] = await getManualFitOwner({
        ...params,
        owner_id: item.owner.id,
      });

      allManualFits = {
        ...allManualFits,
        [item.owner.id]: sortManualFitList(response),
      };
    }));

    setManualFitList(allManualFits);
  };

  useEffect(() => {
    if (openModalManualFit && ownersSelected.length !== 0) {
      getManualFits();
    }
  }, [openModalManualFit, ownersSelected]);

  const handleUpdateSwitch = (id: number) => {
    setSwitchs((prevSwitchs) => prevSwitchs.map((item) => ({
      ...item,
      active: item.id === id ? !item.active : item.active,
    })));
  };

  const handleUpdateManualFitVisibility = (id: number) => {
    setManualFitVisibility((prevState) => prevState.map((item) => ({
      ...item,
      visible: item.id === id ? !item.visible : item.visible,
    })));
  };

  const handleRemoveItem = (owner: GridOwnerProps) => {
    handleUpdateCheckedOfOwnerSelected(owner.id);
  };

  const findIndexOfItemSelected = (id: number) => ownersSelected
    .findIndex((item) => item.id === id);

  const manualFitListIsVisible = (id: number) => manualFitVisibility?.
    [findIndexOfItemSelected(id)]?.visible || false;

  const handlePostManualFit = async (manualFitData: ManualFitProps) => {
    try {
      const response = await postManualFitOwner(manualFitData);
      return response.data;
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  const initialValuesFormik = useMemo(() => {
    let initial: any = {};
    const owners = financialDataOwner.filter((item) => item.checked);

    if (owners.length > 0) {
      owners.forEach((item) => {
        initial = {
          ...initial,
          [`is_adding_${item.id}`]: false,
          [`description_${item.id}`]: '',
          [`value_${item.id}`]: numberToCurrency(0),
          [`date_${item.id}`]: new Date(),
        };
      });
    }

    return { ...initial };
  }, [financialDataOwner]);

  const formik = useFormik({
    initialValues: initialValuesFormik,
    onSubmit: async (values) => {
      handleActiveLinearProgress(true);

      await Promise.all(ownersSelected.map(async (item) => {
        const value = values?.[`value_${item.id}`];
        const description = values?.[`description_${item.id}`];
        const isAdding = switchs?.[findIndexOfItemSelected(item.id)]?.active || false;
        const dateRef = values?.[`date_${item.id}`];

        await handlePostManualFit({
          date_ref: dateRef ? dateRef.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }) : new Date(),
          value: typeof value === 'string' ? currencyToNumber(currency(value)) : value,
          is_adding: isAdding,
          owner: item.owner.id,
          description,
        });
      }));

      await refetchSelectedOwners({
        ...params,
        owners: ownersSelected.map((item) => `${item.owner.id}`),
      });

      formik.resetForm();

      setTimeout(() => {
        handleOpenActions(false);
        handleOpenModalManualFit(false);
        handleActiveLinearProgress(false);
        toast.success('Ajustes realizados com sucesso!');
      }, 1000);
    },
  });

  const handleLoadNext = () => {
    setLimitFinancialData(financialDataOwner.length > 0
      && limitFinancialData >= financialDataOwner.length
      ? financialDataOwner.length : limitFinancialData + DEFAULT_LIMIT);
  };

  const handleDeleteManualFit = async (id?: number, owner_id?: number) => {
    handleActiveLinearProgress(true);

    if (!id || !owner_id) return null;

    try {
      await deleteManualFitOwner(id);
      const newManualFitList = manualFitList?.[owner_id]?.filter((item) => item.id !== id);

      setManualFitList({
        ...manualFitList,
        [owner_id]: newManualFitList,
      });

      await refetchSelectedOwners({
        ...params,
        owners: ownersSelected.map((item) => `${item.owner.id}`),
      });

      handleOpenActions(false);
      handleOpenModalManualFit(false);
      handleActiveLinearProgress(false);
      toast.success('Ajustes deletado com sucesso!');
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
    return null;
  };

  return (
    <>
      <Container isOpen={openModalManualFit}>
        <CloseButton onClick={() => handleOpenModalManualFit(false)}>
          <X size={16} />
          <p>Fechar</p>
        </CloseButton>

        <FormContainer onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
          <Content>
            <Header>
              <Title>Ajustes Manual</Title>
            </Header>
            {ownersSelected.length > 0
            && ownersSelected.slice(0, limitFinancialData).map((item) => (
              <CardContainer key={item.id}>
                <CardPropertyContainer>
                  <CardPropertyContent>
                    <div>
                      <p>
                        Proprietário
                      </p>
                      <strong data-cy={`title-owner-name-${item.id}`}>
                        {`- ${item.owner.name}`}
                      </strong>
                    </div>
                    <button type="button" onClick={() => handleRemoveItem(item)}>
                      <img src={TrashIcon} alt="Remover" />
                    </button>
                  </CardPropertyContent>
                </CardPropertyContainer>

                <CardAddRemoveValue>
                  <ContainerQuestion>
                    <p>
                      Você pretende
                      {' '}
                      <strong>remover</strong>
                      {' '}
                      ou
                      {' '}
                      <br />
                      <strong>adicionar</strong>
                      {' '}
                      um valor?
                    </p>
                  </ContainerQuestion>
                  <ContainerAddRemove switchIsActive={switchs?.
                    [findIndexOfItemSelected(item.id)]?.active || false}
                  >
                    <p>Remover</p>
                    <SwitchButton data-cy={`btn-add-remove-${item.id}`} type="button" onClick={() => handleUpdateSwitch(item.id)}>
                      <SwitchCircle switchIsActive={switchs?.
                        [findIndexOfItemSelected(item.id)]?.active || false}
                      />
                    </SwitchButton>
                    <p>Adicionar</p>
                  </ContainerAddRemove>
                </CardAddRemoveValue>

                <ContainerValue>
                  <DatePicker
                    dataCy={`input-date-${item.id}`}
                    id={`date_${item.id}`}
                    formik={formik}
                    label="Data de referência"
                    minDate={new Date('1850-01-02')}
                    hasInitialDates
                  />
                </ContainerValue>

                <ContainerValue>
                  <TextField
                    dataCy={`input-value-${item.id}`}
                    id={`value_${item.id}`}
                    formik={formik}
                    label="Valor"
                    mask="money"
                    placeholder="R$ 0,00"
                  />
                </ContainerValue>

                <ContainerDescription>
                  <TextField
                    dataCy={`textarea-description-${item.id}`}
                    formik={formik}
                    id={`description_${item.id}`}
                    label="Descrição"
                    type="textarea"
                    placeholder="Descrição"
                  />
                </ContainerDescription>

                <ButtonAllManualFits
                  type="button"
                  onClick={() => handleUpdateManualFitVisibility(item.id)}
                  isHidden={manualFitListIsVisible(item.id)}
                >
                  {!manualFitListIsVisible(item.id)
                    ? (
                      <>
                        <span>Ver ajustes manuais realizados</span>
                        <VisibilityOff />
                      </>
                    )
                    : (
                      <>
                        <span>Ocultar ajustes manuais realizados</span>
                        <Visibility />
                      </>
                    )}
                </ButtonAllManualFits>

                {manualFitListIsVisible(item.id) && (
                  <ModalInformation>
                    <CardInfoContainer>
                      {manualFitList?.[item.owner.id]?.length === 0
                        && <p>Nenhum ajuste manual foi realizado ainda!</p>}

                      {manualFitList?.[item.owner.id]?.map((manualFit) => (
                        <CardInfoContent key={manualFit.id}>
                          <CardInfoItemsContainer>
                            <p>
                              <strong>{'Registrado em: '}</strong>
                              <span>
                                {`${moment(manualFit?.created_at || new Date()).format('DD/MM/YYYY')} às
                              ${moment(manualFit?.created_at || new Date()).format('HH:mm:ss')}
                              `}
                              </span>
                            </p>
                            <p>
                              <strong>{'Data de referência: '}</strong>
                              <span>
                                {`${moment(manualFit?.date_ref || new Date()).format('DD/MM/YYYY')}`}
                              </span>
                            </p>
                            <p>
                              <strong>{`Valor ${manualFit.is_adding ? 'adicionado' : 'removido'}: `}</strong>
                              <span className={`${manualFit.is_adding ? 'isAdding' : 'isRemoving'}`}>
                                {numberToCurrency(Number(manualFit?.value || 0))}
                              </span>
                            </p>
                            <p>
                              <strong>{'Descrição: '}</strong>
                              <span>{manualFit?.description || ''}</span>
                            </p>
                          </CardInfoItemsContainer>

                          <CardInfoDeleteButton
                            onClick={() => handleDeleteManualFit(manualFit.id, item.owner.id)}
                          >
                            <img src={TrashIcon} alt="deletar" />
                          </CardInfoDeleteButton>
                        </CardInfoContent>
                      ))}
                    </CardInfoContainer>
                  </ModalInformation>
                )}
              </CardContainer>
            ))}

            {ownersSelected.length > DEFAULT_LIMIT && (
            <ContentLoadMoreDatas
              onClick={() => handleLoadNext()}
            >
              <p>Mostrar mais</p>
              <ArrowDownIcon />
            </ContentLoadMoreDatas>
            )}
          </Content>

          <ButtonsContainer className="btn-submit">
            <FormButton dataCy="btn-cancel" type="button" variant="outlined" onClick={() => handleOpenModalManualFit(false)}>Cancelar</FormButton>
            <FormButton dataCy="btn-confirm" type="submit">Confirmar</FormButton>
          </ButtonsContainer>
        </FormContainer>
      </Container>

      { openModalManualFit && (
        <Backdrop onClick={() => handleOpenModalManualFit(false)} />
      )}
    </>
  );
};

export default memo(ManualFitOwner);
