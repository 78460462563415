import styled from 'styled-components';

export const Container = styled.button`
  background: ${({ theme }) => theme.palette.white._300.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 230px;
  min-height: 134px;
  flex-direction: column;
  border: none;
  cursor: pointer;
  border-radius: 10px;

  font-size: 1.5rem;
  line-height: 1.6rem;
  transition: 400ms;
  padding: 1rem;

  &:hover {
    background: ${({ theme }) => theme.palette.white._650.hex()};
    box-shadow: 0 0 2px 1px ${({ theme }) => theme.palette.blue._850.hex()};
    div {
      background-color: ${({ theme }) => theme.palette.blue._930.hex()};
    }
  }

  @media (max-width: 350px){
      min-width: 200px;
    }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.palette.blue._920.hex()};
  border-radius: 50%;
  transition: 400ms;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
