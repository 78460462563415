import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../hooks/useMobile/useMobile';
import { getMaxWidthDesktop } from '../../style';

export const Container = styled.div`
  gap: 30px;
  display: flex;
  transition: 0.3s;
  padding-bottom: 200px;
  flex-direction: column;
  padding: 30px 0;
  ${getMaxWidthDesktop};

  height: 100%;
  width: 100%;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    padding-bottom: 0;
  }
`;

export const TitleContainer = styled.div``;

export const TitleContent = styled.div`
  background: white;
  padding: 16px;
  border-radius: 10px 10px 0 0;
`;
