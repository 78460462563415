import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.form`
  gap: 16px;
  display: flex;
  flex-direction: row;
`;

export const StyledInput = styled.input`
  &&&&& {
    border-radius: 10px;
    border: 1px solid var(--neuter-300, #d9d9d9);
    background: #f9f9f9;
    width: 100%;
    padding: 8px 16px;
    height: 40px;
  }
`;

export const StyledButton = styled(motion.button)`
  border-radius: 10px;
  background: #0d4bd0;
  width: 182px;
  border: none;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  h1 {
    font-size: 16px;
    color: var(--branco, #fff);
  }
`;
