/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import Grid from './Grid';

import Header from './Header';

import { Container, Dividers, Content } from './styles';

const SendingVouchers = () => (
  <Container>
    <Content>
      <Header />
      <Dividers>
        <Grid color="normal" />
      </Dividers>
    </Content>
  </Container>
);

export default SendingVouchers;
