import styled from 'styled-components';

export const QuestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & p{
    display: block;
  }
`;

export const QuestContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    color: var(--gray-800, #394760);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    span {
      color: ${({ theme }) => theme.palette.orange.main.hex()};
    }
  }
`;
