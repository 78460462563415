import React from 'react';
import { Divider } from '@mui/material';
import { Title } from './style';

const Header = () => (
  <>
    <Title>Painel de Perspectiva do Proprietário</Title>
    <Divider style={{ color: '#EBEBF5', opacity: 0.7 }} />
  </>
);

export default Header;
