import { Dispatch, SetStateAction } from 'react';
import RadioButton from '../../../components/RadioButton';
import {
  RefundDamageHeaderContentContainer,
  RefundDamageHeaderContainer,
  RefundDamageHeaderItem,
  Title,
  ButtonContainer,
} from './styles';
import FormButton from '../../../../FormButton/FormButton';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { numberToCurrency } from '../../../../../utils/Formatter';

const validateOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

interface RefundDamageHeaderProps{
  isReceivingConfirmed: boolean;
  setIsReceivingConfirmed: Dispatch<SetStateAction<boolean>>;
}

export const RefundDamageHeader = ({
  isReceivingConfirmed,
  setIsReceivingConfirmed,
}: RefundDamageHeaderProps) => {
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  return (
    <RefundDamageHeaderContainer>
      <Title>Validação do recebimento</Title>
      <RefundDamageHeaderContentContainer>
        <div style={{
          display: 'flex', justifyContent: 'center', gap: '1rem', flexDirection: 'column',
        }}
        >
          <RefundDamageHeaderItem>
            <p>
              <span>Valor Total do Dano:</span>
              {' '}
              {numberToCurrency(selectedGuestDamageNegotiation.amount_to_receive) || '-'}
            </p>
          </RefundDamageHeaderItem>
          <RefundDamageHeaderItem>
            <p>
              <span>Diferença:</span>
              {' '}
              {numberToCurrency(selectedGuestDamageNegotiation.amount_difference) || '-'}
            </p>
          </RefundDamageHeaderItem>
          <RefundDamageHeaderItem>
            <p>
              <span>Valor Recebido:</span>
              {' '}
              {numberToCurrency(selectedGuestDamageNegotiation.amount_received) || '-'}
            </p>
          </RefundDamageHeaderItem>
        </div>
        <div style={{
          display: 'flex', gap: '1rem', flexDirection: 'column',
        }}
        >
          <RadioButton
            label="Transferência recebida?"
            labelClassName="label"
            options={validateOptions}
            selectedOption={isReceivingConfirmed}
            setSelectedOption={setIsReceivingConfirmed}
          />
        </div>
      </RefundDamageHeaderContentContainer>
      <ButtonContainer>
        <FormButton>Salvar</FormButton>
      </ButtonContainer>
    </RefundDamageHeaderContainer>
  );
};
