import { TextSmall } from '../styles';
import { Container, Input } from './styles';

interface ICheckbox {
  label?: any,
  checked: boolean,
  setChecked: () => void,
  id?: string,
}
const Checkbox = ({
  label,
  checked,
  setChecked,
  id = 'checkbox',
}: ICheckbox) => (
  <Container>
    <Input
      type="checkbox"
      data-cy={`${id}-checkbox`}
      data-testid={`${id}-checked_is_${checked}`}
      checked={checked}
      onClick={setChecked}
    />
    <TextSmall data-testid={`${id}-checkbox-label`}>{label}</TextSmall>
  </Container>
);

Checkbox.defaultProps = {
  label: '',
  id: 'checkbox',
};

export default Checkbox;
