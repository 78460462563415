import styled from 'styled-components';

export const notebook = '1500px';

export const Container = styled.div`
  div {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.white.main.hex()};
    justify-content: center;
    text-align: center;
    border-radius: 6px;
  }
`;

export const HotBedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  max-height: 1.5rem;
  max-width: 9rem;
  background: ${({ theme }) => theme.palette.yellow.main.hex()};
`;

export const ToReciveContainer = styled.div`
  background: ${({ theme }) => theme.palette.red._400.hex()};
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.familys._1};

  @media (max-width: ${notebook}) {
    font-size: 1.5rem;
  }

  @media (max-width: 1600px) {
    font-size: 1.4em;
  }

  @media (max-width: 2000px) {
    font-size: 1.4rem;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  color: #FFFFFF;
  width: 100%;
  font-weight: 700;
`;
