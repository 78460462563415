import { useFinancialStatement } from '../../../../hooks/HostDashboardHook/HostGridFinances/useFinancialStatement';
import { Property } from '../../../../services/Property/types';

import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import DownloadButton from '../../../OwnerPage/OwnerReport/Buttons/DownloadButton';
import Filters from './Filters';

import {
  HeaderContainer,
  HeaderContent,
  Title,
  ButtonDownloadContainer,
} from './styles';

interface IHeader {
  title: string
  propertiesCodes?: SelectOption[];
  propertiesHost: Property[];
}

const Header: React.FC<IHeader> = ({ title, propertiesCodes, propertiesHost }) => {
  const { setOpenFinancialStatementModal } = useFinancialStatement();

  return (
    <HeaderContainer>
      <HeaderContent>
        <Title data-cy="title-grid-revenue">{title}</Title>

        {propertiesCodes && (
        <ButtonDownloadContainer>
          <DownloadButton
            type="button"
            label="Baixar extrato"
            onClick={() => setOpenFinancialStatementModal(true)}
            variantForMobile="secondary"
            variantForDesktop="secondary"
          />
        </ButtonDownloadContainer>
        )}
      </HeaderContent>

      {propertiesCodes
        && <Filters propertiesCodes={propertiesCodes} propertiesHost={ propertiesHost } />}

    </HeaderContainer>
  );
};

export default Header;
