import { motion } from 'framer-motion';
import React from 'react';

interface IPortfolioProps {
  delay: number;
}

const Portfolio = ({
  delay,
}: IPortfolioProps) => (
  <motion.svg
    whileTap={{
      scale: 1.2,
    }}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.g id="container">
      <motion.rect
        id="background"
        width="32"
        height="32"
        rx="10"
        fill="#E7EAF1"
      />
      <motion.g id="houseContainer">
        <motion.path
          initial={{
            y: -1,
            scale: 0,
            opacity: 0,
          }}
          animate={{
            y: 0,
            scale: 1,
            opacity: 1,
          }}
          exit={{
            y: -1,
            scale: 0,
            opacity: 0,
          }}
          transition={{
            delay: 1.5 + (delay * 0.1),
          }}
          id="house"
          d="M22.2105 7H13.263C12.2761 7 11.4735 7.80259 11.4735 8.78949V13.7876L7.26197 17.9991C7.13688 18.1243 7.05169 18.2837 7.01718 18.4572C6.98267 18.6307 7.00039 18.8106 7.0681 18.9741C7.1358 19.1376 7.25046 19.2773 7.39756 19.3756C7.54467 19.4739 7.71762 19.5264 7.89456 19.5265V24.0002C7.89456 24.2375 7.98882 24.4651 8.15662 24.6329C8.32442 24.8007 8.552 24.8949 8.7893 24.8949H23.1053C23.3426 24.8949 23.5701 24.8007 23.7379 24.6329C23.9057 24.4651 24 24.2375 24 24.0002V8.78949C24 7.80259 23.1974 7 22.2105 7ZM15.0525 23.1054H9.68405V18.1074L12.3683 15.4231L15.0525 18.1074V23.1054ZM22.2105 23.1054H16.842V19.5265C17.0192 19.5268 17.1924 19.4746 17.3398 19.3764C17.4872 19.2781 17.602 19.1383 17.6698 18.9746C17.7376 18.811 17.7552 18.6309 17.7205 18.4572C17.6857 18.2835 17.6001 18.1241 17.4746 17.9991L13.263 13.7876V8.78949H22.2105V23.1054Z"
          fill="#B8C6DC"
        />
        <motion.path
          initial={{
            scale: 0,
            rotate: 360,
          }}
          animate={{
            scale: 1,
            rotate: 0,
          }}
          transition={{ delay: 1.5 + (delay * 0.1) }}
          id="dots"
          d="M15.0524 10.579H16.8419V12.3685H15.0524V10.579ZM18.6314 10.579H20.4209V12.3685H18.6314V10.579ZM18.6314 14.1857H20.4209V15.9475H18.6314V14.1857ZM18.6314 17.737H20.4209V19.5264H18.6314V17.737ZM11.4734 18.6317H13.2629V20.4212H11.4734V18.6317Z"
          fill="#B8C6DC"
        />
      </motion.g>
    </motion.g>
  </motion.svg>
);

export default Portfolio;
