import { useContextSelector } from 'use-context-selector';
import { PartnersPortfolioContext } from '../../../context/Partners';

export default function usePortfolio() {
  const isShowAll = useContextSelector(PartnersPortfolioContext,
    (state) => state.isShowAll);

  const setIsShowAll = useContextSelector(PartnersPortfolioContext,
    (state) => state.setIsShowAll);

  const modal = useContextSelector(PartnersPortfolioContext,
    (state) => state.modal);

  const handleOpenModal = useContextSelector(PartnersPortfolioContext,
    (state) => state.handleOpenModal);

  const handleCloseModal = useContextSelector(PartnersPortfolioContext,
    (state) => state.handleCloseModal);

  return {
    isShowAll,
    setIsShowAll,
    modal,
    handleOpenModal,
    handleCloseModal,
  };
}
