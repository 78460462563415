import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

import { CompReservationsType } from '../../services/CompReservations/types';
import { getReservationsListUpdated } from './utils';

interface ICompleteReservation {
  reservationId: number;
  setReservationId: (id: number) => void;
  reservationsList: CompReservationsType[];
  setReservationsList: (reservations: CompReservationsType[]) => void;
  updateReservationsList: (currentData: CompReservationsType[],
    dataUpdated: CompReservationsType[]) => void;
  guestFormIsValid: boolean;
  setGuestFormIsValid: Function;
}

export const CompleteReservationContext = createContext<ICompleteReservation>({
} as ICompleteReservation);

export const CompleteReservationProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [reservationId, setReservationId] = useState<number>(0);
  const [reservationsList, setReservationsList] = useState<CompReservationsType[]>([]);
  const [guestFormIsValid, setGuestFormIsValid] = useState<boolean>(false);

  const handleChangeReservationId = useCallback((id: number) => {
    setReservationId(id);
  }, []);

  const handleSetReservationsList = useCallback((reservations: CompReservationsType[]) => {
    setReservationsList(reservations);
  }, []);

  const handleUpdateReservationsList = useCallback((currentData: CompReservationsType[],
    dataUpdated: CompReservationsType[]) => {
    const newData: CompReservationsType[] = [...currentData];

    dataUpdated.forEach((itemUpdated) => {
      const indexItem = currentData
        .findIndex((currentItem) => currentItem.reservation_id === itemUpdated.reservation_id);
      newData[indexItem] = { ...getReservationsListUpdated(itemUpdated) };
    });

    setReservationsList(newData);
  }, []);

  const handleSetGuestFormIsValid = useCallback((isValid: boolean) => {
    setGuestFormIsValid(isValid);
  }, []);

  const value = useMemo(() => ({
    reservationId,
    setReservationId: handleChangeReservationId,
    reservationsList,
    setReservationsList: handleSetReservationsList,
    updateReservationsList: handleUpdateReservationsList,
    guestFormIsValid,
    setGuestFormIsValid: handleSetGuestFormIsValid,
  }), [
    reservationId,
    setReservationId,
    reservationsList,
    setReservationsList,
    guestFormIsValid,
    setGuestFormIsValid,
  ]);

  return (
    <CompleteReservationContext.Provider
      value={value}
    >
      {children}
    </CompleteReservationContext.Provider>
  );
};
