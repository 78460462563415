import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import { getConciliations, getReservationConciliation } from '../../../services/Conciliation/request';
import { FiltersType } from '../../../services/Conciliation/types';
import { formatedDateOfRequest } from '../../../utils/Formatter';
import DatePicker from '../../DatePicker';
import FormButton from '../../FormButton';
import Tooltip from '../../Tooltip';
import ModalUploadCSV from './ModalUploadCSV';
import { Container, ButtonToReconcile, ButtonConciliation } from './styles';

interface Props {
  setOpenDisconnectConciliation: (a: boolean) => void;
  openDisconnectConciliation: boolean;
}

const HeaderConciliation = ({
  setOpenDisconnectConciliation,
  openDisconnectConciliation,
}: Props): JSX.Element => {
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const { removeReservation, setListReservation, setListConciliations } = useConciliation();

  function handleOpenDisconnectConciliation() {
    setOpenDisconnectConciliation(true);
    removeReservation();
  }

  function handleCloseDisconnectConciliation() {
    setOpenDisconnectConciliation(false);
    removeReservation();
  }

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
    },
    onSubmit: async (values) => {
      const filterArray = [];
      if (values.startDate) {
        filterArray.push({ name: 'start_date', value: formatedDateOfRequest(values.startDate) } as FiltersType);
      }
      if (values.endDate) {
        filterArray.push({ name: 'end_date', value: formatedDateOfRequest(values.endDate) } as FiltersType);
      }

      if (openDisconnectConciliation) {
        const results = await getConciliations(filterArray);
        setListConciliations(results);
      } else {
        const results = await getReservationConciliation(filterArray);
        setListReservation(results);
      }
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <div className="dates">
        <div className="date">
          <DatePicker
            id="startDate"
            label="De:"
            formik={formik}
            viewsCustom={['day', 'month', 'year']}
          />
        </div>
        <div className="date">
          <DatePicker
            id="endDate"
            label="Até:"
            formik={formik}
            viewsCustom={['day', 'month', 'year']}
          />
        </div>
        <div className="btn-filter">
          <FormButton type="submit">Filtrar</FormButton>
        </div>
      </div>
      <div className="buttons-rigth">
        {openDisconnectConciliation ? (
          <Tooltip text="Voltar para tela de conciliação">
            <ButtonConciliation type="button" onClick={handleCloseDisconnectConciliation}>Conciliar</ButtonConciliation>
          </Tooltip>
        ) : (
          <Tooltip text="Ir para tela de desconciliação">
            <ButtonToReconcile type="button" onClick={handleOpenDisconnectConciliation}>Desconciliar</ButtonToReconcile>
          </Tooltip>
        )}
        <div className="button-csv"><FormButton onClick={() => setOpenModalUpload(true)}>Upload CSV</FormButton></div>
      </div>
      <ModalUploadCSV open={openModalUpload} onClose={setOpenModalUpload} />

    </Container>
  );
};

export default HeaderConciliation;
