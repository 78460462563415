import {
  CreateCompleteUser,
  UserAuth,
  UserDetail,
  UserInformation,
  UserMeProps,
} from './types';
import request from '../request';
import { checkIfTokenExpired } from '../../utils/Auth';
import { AddressDetail } from '../Address/types';
import { GetUserDetailsById } from '../InsertData/types';

export const login = (formData: UserAuth) => request.post('/account/login/token/', formData);

export const resetpassword = async ({ email }: { email: string }) => {
  const { data } = await request.post('/account/login/resetpassword/', {
    email,
  });

  return data;
};

export const changepassword = async ({
  token,
  newPassword,
}: {
  token: string;
  newPassword: string;
}) => {
  const { data } = await request.post('/account/login/changepassword/', {
    token,
    new_password: newPassword,
  });

  return data;
};

export const getAccountUserDetailsByUserId = async (id: number) => {
  const { data } = await request.get<GetUserDetailsById>(`/account/user/${id}`);
  return data;
};

export const create = async (data: UserDetail) => request.post('/account/user/', data);

export const postUser = async (data: CreateCompleteUser) => {
  const { data: response } = await request.post('/account/user/', data);
  return response as GetUserDetailsById;
};

export const createGuest = async (data: UserDetail) => {
  await create(data);
};

export const putUser = async (id: number, data: UserDetail) => {
  await request.put(`/account/user/${id}/`, {
    ...data,
  });
};

export const patchUser = async (id: number, data: UserDetail) => {
  await request.patch(`/account/user/${id}/`, {
    ...data,
  });
};

export const getUserMe = async () => {
  try {
    const { data } = await request.get<UserMeProps>('/account/user/me/');
    return data as UserMeProps;
  } catch (e) {
    const tokenExpired = checkIfTokenExpired();
    if (tokenExpired) {
      window.localStorage.removeItem('sapron-pms-user');
    }
    return null;
  }
};

const getRoles = (response: UserMeProps): string[] => {
  let roles: string[] = response?.roles || [];
  const departmanet = response?.seazone?.departmanet?.trim() || '';

  if (
    roles.includes('Seazone')
    && ['Onboarding', 'Administrative'].includes(departmanet)
  ) {
    roles.push(`Seazone${departmanet}`);
    roles = roles.filter((role) => role !== 'Seazone');
  }

  return roles;
};

export const getUserInformation = async () => {
  const response = await getUserMe();
  if (response) {
    const userDetails: UserInformation = {
      ...response,
      user_id: response?.id?.toString() || '',
      main_role: response?.main_role || '',
      first_name: response?.first_name || '',
      is_individual: response.is_individual || false,
      last_name: response?.last_name || '',
      trading_name: response.trading_name || '',
      corporate_name: response.corporate_name || '',
      phone_number1: response.phone_number1 || '',
      roles: [...getRoles(response)],
      nickname: response.nickname,
      gender: response.gender,
      cnpj: response.cnpj || '',
      cpf: response.cpf || '',
      required_actions: response.required_actions || {},
      seazone: {
        id: response?.seazone?.id || -1,
        departmanet: response?.seazone?.departmanet || '',
      },
      host: {
        id: response?.host?.id,
        is_host_ops: response?.host?.is_host_ops,
      },
      owner: {
        id: response?.owner?.id,
      },
      main_address: response.main_address || ({} as AddressDetail),
    };
    return userDetails;
  }
  return null;
};

export interface IChangeRequiredActions {
  user_id: UserInformation['user_id']
  body: UserInformation['required_actions'];
}

export const changeRequiredActions = async ({
  user_id,
  body,
}: IChangeRequiredActions) => {
  const response = await request.patch(`/account/user/${user_id}/required_actions/`, {
    required_actions: body,
  });

  return response as unknown as IChangeRequiredActions;
};
