/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';

import {
  GridOnOutlined,
  Add,
  Search,
  FilterList as FilterListIcon,
  ViewAgenda as ViewAgendaIcon,
  ImportExport as ImportExportIcon,
} from '@mui/icons-material';

import { Fab } from '@mui/material';

import { useFormik } from 'formik';

import {
  Backdrop,
  Container,
  MobileSearchModalBackdrop,
  MobileSearchModalContainer,
} from './styles';

import TedListDatePicker from '../Header/DatePicker';
import TedListTextField from '../Header/TextField/NFListTextField';

import {
  IResponseGenerateNFSpreadsheet,
} from '../../../services/NFlist/types';

import {
  useNfList,
} from '../../../hooks/NfListHook/useNfList';

import {
  generateNFSCsv,
  getFilesByFileUid,
} from '../../../services/NFlist/request';

import {
  useToast,
} from '../../../context/ToastContext';

import {
  sleep,
} from '../../../utils/Sleep';

const TedListFab = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [openFunctionToDownloadFiles, setOpenFunctionToDownloadFiles] = useState<boolean>(false);
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);

  const { handleLoadingFiles } = useNfList();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      period: new Date(),
      search: '',
    },
    onSubmit: () => {},
  });

  const handleCloseAll = () => {
    setOpen(false);
    setOpenSearchModal(false);
    setOpenDatePicker(false);
  };

  const handleGenerateUUID = async (): Promise<
  IResponseGenerateNFSpreadsheet | undefined
  > => {
    try {
      const month: number = formik.values.period.getMonth() + 1;
      const year: number = formik.values.period.getFullYear();
      let monthString: string = month.toString();
      if (month < 10) {
        monthString = `0${monthString}`;
      }
      const params = {
        period: `${year}-${monthString}`,
      };
      const responseUUID = await generateNFSCsv(params);
      return responseUUID;
    } catch (e) {
      toast.error('Erro ao gerar o token');
      return undefined;
    }
  };

  const handleGenerateNFSpreadsheet = async () => {
    handleLoadingFiles(true);
    const responseID = await handleGenerateUUID();

    if (responseID) {
      let response = await getFilesByFileUid(responseID.file_uid);

      const tries = 10;
      let counter = 0;

      while (response === 'Processing') {
        await sleep(2000);
        response = await getFilesByFileUid(responseID.file_uid);

        if (response !== 'Processing') {
          break;
        }

        if (counter === tries) {
          toast.error('Erro ao gerar o arquivo, tente novamente mais tarde');
          break;
        }

        counter += 1;
      }

      if (response) {
        const link = response?.url;
        window.open(link, '_blank');
      }
    }

    handleLoadingFiles(false);
  };

  const handleExport = () => {
    setOpenFunctionToDownloadFiles(false);
    setOpenDatePicker(true);
  };

  useEffect(() => {
    if (openFunctionToDownloadFiles) {
      handleGenerateNFSpreadsheet();
    }
  }, [openFunctionToDownloadFiles]);

  if (openSearchModal) {
    return (
      <>
        <MobileSearchModalContainer>
          <h1>Pesquise por algum proprieatário</h1>
          <TedListTextField id="search" formik={formik} />
        </MobileSearchModalContainer>
        <MobileSearchModalBackdrop onClick={() => handleCloseAll()} />
      </>
    );
  }

  return (
    <>
      {openDatePicker && (
        <TedListDatePicker
          formik={formik}
          customOpen={openDatePicker}
          setCustomOpen={setOpenDatePicker}
          setOpenFunctionToDownloadFiles={setOpenFunctionToDownloadFiles}
        />
      )}

      <Container>
        {open && (
          <>
            <div>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="search"
                onClick={() => setOpenSearchModal(true)}
              >
                <Search style={{ marginRight: '5px' }} />
                Pesquisar
              </Fab>
            </div>

            <div>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="export-csv"
              >
                <ImportExportIcon style={{ marginRight: '5px' }} />
                Exportar csv
              </Fab>
            </div>

            <div>
              <Fab
                onClick={() => setOpenDatePicker(true)}
                variant="extended"
                size="small"
                color="primary"
                aria-label="export-csv"
              >
                <FilterListIcon style={{ marginRight: '5px' }} />
                Filtrar por data
              </Fab>
            </div>

            <div>
              <Fab
                onClick={() => handleExport()}
                variant="extended"
                size="small"
                color="primary"
                aria-label="export-csv"
              >
                <GridOnOutlined style={{ marginRight: '5px' }} />
                Exportar planilha
              </Fab>
            </div>

            <div>
              <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="export-csv"
              >
                <ViewAgendaIcon style={{ marginRight: '5px' }} />
                Gerar ou carregar listas
              </Fab>
            </div>
          </>
        )}

        <Fab
          size="medium"
          onClick={() => setOpen(!open)}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Container>

      {open && <Backdrop onClick={() => setOpen(false)} />}
    </>
  );
};

export default TedListFab;
