import { useContextSelector } from 'use-context-selector';
import { FileContext } from '../../context/FileContext/FileContext';

export function useFile() {
  const uploadedFiles = useContextSelector(FileContext, (file) => file.uploadedFiles);
  const setUploadedFiles = useContextSelector(FileContext, (file) => file.setUploadedFiles);
  const createFileReference = useContextSelector(FileContext, (file) => file.createFileReference);
  const uploadFileToS3 = useContextSelector(FileContext, (file) => file.uploadFileToS3);
  const updateFile = useContextSelector(FileContext, (file) => file.updateFile);
  const deleteFile = useContextSelector(FileContext, (file) => file.deleteFile);

  return {
    uploadedFiles,
    setUploadedFiles,
    createFileReference,
    uploadFileToS3,
    updateFile,
    deleteFile,
  };
}
