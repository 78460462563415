import { CopyContainer, CopyItem, Link } from './styles';
import copyIcon from '../../../../assets/icons/generals/copy.svg';
import { useToast } from '../../../../context/ToastContext';
import Tooltip from '../../../Tooltip';

interface CopyProps {
  onClick: () => void;
}

const Copy = ({ onClick }: CopyProps) => (
  <CopyContainer onClick={onClick} src={copyIcon} alt="icone de copiar" className="copyIcon" />
);

interface CopyLinkProps {
  link?: string;
}
export const CopyLink = ({ link }:CopyLinkProps) => {
  const toast = useToast();

  function handleClick(text: string) {
    window.open(text, '_blank');
  }

  function handleCopy(text: string) {
    navigator.clipboard.writeText(text);
    toast.alert('Copiado!');
  }

  if (link) {
    return (
      <CopyItem>
        <Tooltip text={link}>
          <Link onClick={() => handleClick(link)}>Acesse</Link>
        </Tooltip>
        <Copy onClick={() => handleCopy(link)} />
      </CopyItem>
    );
  }

  return (<p>-</p>);
};

CopyLink.defaultProps = {
  link: undefined,
};
