import styled from 'styled-components';

export const Container = styled.div`
    position: sticky;
    background: ${({ theme }) => theme.palette.grey._300.hex()};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    left: 0;
`;

export const Content = styled.form`
  width: 185px;
  padding: 0 0.8rem;

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  input, div {
    margin: 0;
    padding: 0;
  }

  svg, img {
    cursor: pointer;
    margin-top: 0.2rem;
  }

  svg {
    fill: ${({ theme }) => theme.palette.grey._900.hex()};
  }

  .closed-icon{
    right: 0;
    margin:  4px 16px 0 0;
    position: absolute;
  }

  @media (max-width: 900px) {
    width: 125px;
    padding-right: 0;
  }

  @media (max-width: 415px) {
    width: 165px;
  }

  @media (max-width: 375px) {
    width: 150px;
  }
`;

export const SearchContainer = styled.div<{ inputEnabled: boolean }>`
  & fieldset {
    border: 0;
  }

  && {
    .MuiOutlinedInput-root {
      background: ${({ inputEnabled, theme }) => (inputEnabled ? theme.palette.grey._400.hex() : theme.palette.grey._300.hex())};
      min-width: 80px;
      border: 0;

      @media (max-width: 900px) {
        min-width: 50px;
      }

      svg {
        fill: transparent;
        display: none;
      }


      &:hover fieldset{
        border: 0;
      }

      &.Mui-focused fieldset {
        border: solid 1px ${({ theme }) => theme.palette.grey._420.hex()};
      }
    }

    input{
      margin-bottom: 0.2rem;

      ::placeholder {
        font-size: 15px;
        border: 0;
      }
    }
  }
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img, svg {
      cursor: pointer;
      padding: 0.33rem;
      width: 25px;
      height: auto;
    }
    img{
      margin-right: 1rem;

      @media (max-width: 900px) {
        margin-right: 0;
      }
    }

    img:hover, svg:hover {
      background: ${({ theme }) => theme.palette.grey._400.hex()};
    }
`;

export const ImgFilter = styled.img``;

export const IconContent = styled.div`
  :hover {
    background: ${({ theme }) => theme.palette.grey._400.hex()};
  }
`;

export const ButtonClearFilters = styled.button`
  border: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.red._400.hex()};
  background: transparent;

  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  font-size: 0.7rem;

`;
