import React, { useState, useEffect } from 'react';

import moment from 'moment';
import Card from './Cards';
import Header from './Header';

import { Container, Dividers, Content } from './styles';
import { getCompReservations } from '../../services/CompReservations/request';
import { useCompleteReservation } from '../../hooks/CompleteReservationHook/useCompleteReservation';
import { FiltersType } from '../../services/Conciliation/types';
import { formatedDateOfRequest } from '../../utils/Formatter';
import { useToastErrorMessage } from '../../utils/Messages';

const CompReservation = () => {
  const [isFinishOpen, setIsFinishOpen] = useState(false);
  const [isIncompleteOpen, setIsIncompleteOpen] = useState(true);
  const [isTwoCardOpen, setIsTwoCardOpen] = useState(isFinishOpen && isIncompleteOpen);
  const { setReservationsList } = useCompleteReservation();
  const [guestName, setGuestName] = useState<string>();
  const [code, setCode] = useState<string>();
  const [dateCheckin, setDateCheckin] = useState<string>();
  const [propertyType, setPropertyType] = useState<string>();
  const [typeSort, setTypeSort] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toastErrorRequest = useToastErrorMessage();

  useEffect(() => {
    if (isIncompleteOpen && isFinishOpen) {
      setIsTwoCardOpen(true);
    } else {
      setIsTwoCardOpen(false);
    }
  }, [isIncompleteOpen, isFinishOpen]);

  useEffect(() => {
    async function listCompReservations() {
      const results = await getCompReservations();
      setReservationsList(results);
    }
    listCompReservations();
  }, []);

  useEffect(() => {
    const filterArray: FiltersType[] = [];
    const listCompReservations = async () => {
      setIsLoading(true);
      try {
        const results = await getCompReservations(filterArray);
        setReservationsList(results);
      } catch (err) {
        if (err instanceof Error) {
          toastErrorRequest(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (guestName) {
      filterArray.push({ name: 'guest_name', value: guestName } as FiltersType);
      listCompReservations();
    }

    if (code) {
      filterArray.push({ name: 'property_code', value: code } as FiltersType);
      listCompReservations();
    }

    if (propertyType) {
      filterArray.push({ name: 'property_type', value: propertyType } as FiltersType);
      listCompReservations();
    }

    if (dateCheckin) {
      const endDate = moment(dateCheckin).add(1, 'days').format('YYYY-MM-DD');
      filterArray.push({ name: 'start_date', value: formatedDateOfRequest(dateCheckin) } as FiltersType);
      filterArray.push({ name: 'end_date', value: endDate } as FiltersType);
      listCompReservations();
    }

    if (typeSort) {
      filterArray.push({ name: 'ordering', value: typeSort } as FiltersType);
      listCompReservations();
    }
  }, [
    code,
    typeSort,
    guestName,
    dateCheckin,
    propertyType,
  ]);

  return (
    <Container>
      <Content>
        <Header
          setPropertyType={setPropertyType}
          setCode={setCode}
          setDateCheckin={setDateCheckin}
          setGuestName={setGuestName}
        />
        <Dividers>
          <>
            <Card
              isOpenCard={isIncompleteOpen}
              isTwoCardOpen={isTwoCardOpen}
              setIsOpenCard={setIsIncompleteOpen}
              title="Incompletas"
              setTypeSort={setTypeSort}
              isLoading={isLoading}
            />
            <Card
              isOpenCard={isFinishOpen}
              isTwoCardOpen={isTwoCardOpen}
              setIsOpenCard={setIsFinishOpen}
              title="Finalizadas"
              color="green"
              setTypeSort={setTypeSort}
              isLoading={isLoading}
            />
          </>
        </Dividers>
      </Content>
    </Container>
  );
};

export default CompReservation;
