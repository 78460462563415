export default function ArrowTop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.442 5.80771L16.692 12.0577C16.7501 12.1158 16.7962 12.1847 16.8276 12.2606C16.859 12.3365 16.8752 12.4178 16.8752 12.4999C16.8752 12.582 16.859 12.6633 16.8276 12.7392C16.7962 12.8151 16.7501 12.884 16.692 12.9421C16.634 13.0002 16.565 13.0462 16.4892 13.0776C16.4133 13.1091 16.332 13.1252 16.2499 13.1252C16.1677 13.1252 16.0864 13.1091 16.0105 13.0776C15.9347 13.0462 15.8657 13.0002 15.8077 12.9421L9.99986 7.13349L4.19205 12.9421C4.07477 13.0594 3.91571 13.1252 3.74986 13.1252C3.58401 13.1252 3.42495 13.0594 3.30767 12.9421C3.1904 12.8248 3.12451 12.6657 3.12451 12.4999C3.12451 12.334 3.1904 12.175 3.30767 12.0577L9.55767 5.80771C9.61572 5.7496 9.68465 5.7035 9.76052 5.67205C9.83639 5.64059 9.91772 5.62441 9.99986 5.62441C10.082 5.62441 10.1633 5.64059 10.2392 5.67205C10.3151 5.7035 10.384 5.7496 10.442 5.80771Z"
        fill="#0D4BD0"
      />
    </svg>
  );
}
