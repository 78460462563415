import { motion } from 'framer-motion';
import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../hooks/useMobile/useMobile';

export const Container = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 99999;
  position: fixed;
  padding: 50px 30px;
  background-color: #000f2d;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}){
    width: 600px;
  }
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 8rem;
`;

export const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  backdrop-filter: blur(1px);
`;
