import styled from 'styled-components';

import { shimmerAnimation, animationTime } from '../animationShimmer';

export const Container = styled.div`
  position: relative;
  animation: ${shimmerAnimation} ${animationTime}s linear infinite;
  border-radius: 10px;
  height: 410px;
  
  div {
    padding: 1rem;
    position: relative;
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: white;
    box-shadow: 5px 4px 15px 1px rgba(0, 0, 0, 0.075);

    div {
      animation: ${shimmerAnimation} ${animationTime}s linear infinite;
      box-shadow: 5px 4px 15px 1px rgba(0, 0, 0, 0.075);
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
  }
`;
