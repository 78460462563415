import {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { createContext } from 'use-context-selector';

interface IAbortRequest {
  abortController: any,
  setAbortController: Function,
}

export const AbortRequestContext = createContext<IAbortRequest>({
  abortController: new AbortController(),
  setAbortController: () => {},
});

export const AbortRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [abortController, setAbortController] = useState<any>(new AbortController());

  const handleChangeController = useCallback((controller: any) => {
    setAbortController(controller);
  }, []);

  const value = useMemo(() => ({
    abortController,
    setAbortController: handleChangeController,
  }), [
    abortController,
    setAbortController,
  ]);

  return (
    <AbortRequestContext.Provider
      value={value}
    >
      {children}
    </AbortRequestContext.Provider>
  );
};
