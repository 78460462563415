import React from 'react';

import {
  IType,
  EnumType,
  IIndicationsStatus,
} from '../../../../../../../types';

import TooltipComponent from './Tooltip';

import { findStatus } from '../../../../../../../utils/indication';
import { formatDateToShow } from '../../../../../../../../../utils/Formatter';
import {
  actualSize,
  // useMobile
} from '../../../../../../../../../hooks/useMobile/useMobile';
import allotmentIcon from '../../../../../../../../../assets/icons/partners/allotmentIcon.svg';
import locationIcon from '../../../../../../../../../assets/icons/partners/location.svg';
import spotIcon from '../../../../../../../../../assets/icons/partners/spot.svg';

import {
  Body,
  Left,
  Right,
  Header,
  Circle,
  Container,
  DateContent,
  PhoneContainer,
  StatusContainer,
  Section,
  NameContainer,
} from './style';

interface ICard extends IStatusProps {
  type: IType;
  name: string;
  phone: string;
  comment: string;
  changedDate: Date;
  createdDate: Date;
  city?: string;
  state?: string;
  number?: string;
  street?: string;
  neighborhood?: string;
  pipedriveStage?: string;
  lostReason?: string;
  allotmentValue?: string;
}

interface IStatusProps {
  status: IIndicationsStatus;
}

function translateType(type: IType) {
  switch (type) {
    case EnumType.location:
      return 'Locação';
    case EnumType.terrain:
      return 'Terreno';
    case EnumType.spot:
      return 'Spot';
    default:
      return 'Não informado';
  }
}

const PhoneSVGIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.8981 9.90362L10.9538 8.58425L10.9456 8.5805C10.7928 8.51512 10.626 8.48888 10.4605 8.50416C10.2949 8.51944 10.1358 8.57575 9.99751 8.668C9.98122 8.67875 9.96557 8.69044 9.95063 8.703L8.42938 9.99987C7.46563 9.53175 6.47063 8.54425 6.00251 7.593L7.30126 6.04862C7.31376 6.033 7.32563 6.01737 7.33688 6.0005C7.42715 5.86256 7.48192 5.70445 7.49631 5.54023C7.5107 5.37601 7.48428 5.21078 7.41938 5.05925V5.05175L6.09626 2.10237C6.01047 1.90441 5.86296 1.73951 5.67575 1.63227C5.48854 1.52504 5.27166 1.48122 5.05751 1.50737C4.21061 1.61882 3.43324 2.03473 2.87059 2.67743C2.30794 3.32014 1.99847 4.14568 2.00001 4.99987C2.00001 9.96237 6.03751 13.9999 11 13.9999C11.8542 14.0014 12.6797 13.6919 13.3224 13.1293C13.9651 12.5666 14.3811 11.7893 14.4925 10.9424C14.5187 10.7283 14.475 10.5115 14.3679 10.3243C14.2607 10.1371 14.096 9.98951 13.8981 9.90362ZM11 12.9999C8.87898 12.9976 6.8455 12.154 5.34571 10.6542C3.84592 9.15438 3.00232 7.12089 3.00001 4.99987C2.99765 4.38955 3.21754 3.79925 3.61859 3.33919C4.01964 2.87913 4.57444 2.58079 5.17938 2.49987C5.17913 2.50237 5.17913 2.50488 5.17938 2.50737L6.49188 5.44487L5.20001 6.99112C5.18689 7.00621 5.17498 7.0223 5.16438 7.03925C5.07033 7.18357 5.01515 7.34975 5.0042 7.52166C4.99325 7.69358 5.0269 7.86541 5.10188 8.0205C5.66813 9.17862 6.83501 10.3367 8.00563 10.9024C8.16186 10.9767 8.33468 11.0091 8.50722 10.9966C8.67976 10.9842 8.8461 10.9271 8.99001 10.8311C9.00605 10.8203 9.02149 10.8086 9.03626 10.7961L10.5556 9.49987L13.4931 10.8155C13.4931 10.8155 13.4981 10.8155 13.5 10.8155C13.4201 11.4213 13.1222 11.9772 12.662 12.3793C12.2019 12.7813 11.611 13.0019 11 12.9999Z"
      fill="#394760"
    />
  </svg>
);

const Card = ({
  city,
  state,
  number,
  street,
  neighborhood,
  name,
  type,
  phone,
  status,
  comment,
  changedDate,
  createdDate,
  lostReason,
  pipedriveStage,
  allotmentValue,
}: ICard) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const statusFound = findStatus(status);

  return (
    <Container open={openTooltip} type={type}>
      <Header>
        <Left>
          <DateContent>
            <h1>Data de indicação</h1>
            <p>{formatDateToShow(createdDate)}</p>
          </DateContent>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            marginTop: '0.5rem',
          }}
          >
            {type === 'terreno' && (
            <img
              style={{ width: '24px', height: '24px' }}
              src={allotmentIcon}
              alt="Ícone do tipo terreno"
            />
            )}
            {type === 'locacao' && (
            <img
              style={{ width: '24px', height: '24px' }}
              src={locationIcon}
              alt="Ícone do tipo locação"
            />
            )}
            {type === 'spot' && (
            <img
              style={{ width: '24px', height: '24px' }}
              src={spotIcon}
              alt="Ícone do tipo locação"
            />
            )}
            <h2 style={{ fontWeight: 400, color: '#969696' }}>{translateType(type)}</h2>
          </div>
        </Left>

        <Right>
          <TooltipComponent
            city={city}
            name={name}
            state={state}
            phone={phone}
            street={street}
            number={number}
            comment={comment}
            changedDate={changedDate}
            open={openTooltip}
            setOpen={setOpenTooltip}
            neighborhood={neighborhood}
            status={statusFound.translated}
            type={type}
            allotmentValue={allotmentValue}
          />
          {type !== 'terreno' && (
            <Section>
              <StatusContainer>
                <Circle background={statusFound.color} />
                <h1>{statusFound.translated}</h1>
              </StatusContainer>
              <p>{formatDateToShow(changedDate)}</p>
            </Section>
          )}
        </Right>
      </Header>

      <Body>
        <Left>
          <NameContainer>
            <h1 style={{ maxWidth: actualSize === 'Desktop' ? 'none' : '120px', textOverflow: 'ellipsis' }}>{name}</h1>
          </NameContainer>
          {type !== 'terreno' && (
            <PhoneContainer>
              <PhoneSVGIcon />
              <h1>{phone}</h1>
            </PhoneContainer>
          )}
        </Left>
        <Right>
          {statusFound.name === 'In_Progress' && (
            <div
              style={{
                gap: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <h1 style={{ color: '#8998b2' }}>Status</h1>
              <p style={{ color: 'initial' }}>{pipedriveStage}</p>
            </div>
          )}
          {statusFound.name === 'Lost'
          && lostReason
          && type !== 'terreno'
          && (
            <div
              style={{
                gap: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <h1 style={{ color: '#8998b2' }}>Motivo da perda</h1>
              <p style={{ color: 'initial' }}>{lostReason}</p>
            </div>
          )}
        </Right>
      </Body>
    </Container>
  );
};

Card.defaultProps = {
  city: undefined,
  state: undefined,
  number: undefined,
  street: undefined,
  neighborhood: undefined,
  lostReason: undefined,
  pipedriveStage: undefined,
  allotmentValue: undefined,
};

export default Card;
