import axios, { AxiosRequestHeaders } from 'axios';
import { ErrorMessage } from '../utils/Messages';

class ErrorResponse extends Error {
  message: string = '';

  statusCode: number;

  detail: any;

  constructor(
    message: string,
    statusCode: number,
    detail: any,
  ) {
    super(message);
    this.message = message;
    this.statusCode = statusCode;
    this.detail = detail;
  }
}

const request = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

request.interceptors.request.use((config) => {
  const url = new URL(config.url || '', config.baseURL || '');
  if (window?.location?.pathname) {
    url.searchParams.append('actual_route', String(window.location.pathname)); // Use the current front-end route
  }

  return {
    ...config,
    url: decodeURIComponent(url.toString()), // Decode the URL to remove special characters
    headers: {
      ...config.headers,
    },
  };
});

request.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location.href = '/login#expired';
  }

  let detail = typeof error.response.data === 'object' ? error.response.data : error.response.data.detail || ErrorMessage.default();
  let errorMessage;
  if (error.response.data.detail) {
    errorMessage = error.response.data.detail;
  } else if (error.response.data.error) {
    errorMessage = error.response.data.error;
    detail = errorMessage;
  } else {
    errorMessage = ErrorMessage.default();
  }
  return Promise.reject(new ErrorResponse(
    errorMessage,
    error.response.status,
    detail,
  ));
});

request.interceptors.request.use((config) => {
  const headers: AxiosRequestHeaders = {
    ...config.headers,
    'Content-Type': 'application/json',
  };

  const userData = localStorage.getItem('sapron-pms-user');
  if (userData) {
    const { access } = JSON.parse(userData);
    headers.Authorization = `Bearer ${access}`;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...headers,
    },
  };
});

export default request;
