import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { motion, Variants } from 'framer-motion';
import {
  ArrowsButton,
  ArrowsContainer,
  LinkText,
  TableContainer,
  Td,
} from './styles';
import { useGuestDamage } from '../../../../../../hooks/GuestDamage/useGuestDamage';
import { numberToCurrency } from '../../../../../../utils/Formatter';
import { GetGuestDamage } from '../../../../../../services/GuestDamage/types';

const Arrows = () => (
  <ArrowsContainer>
    <KeyboardArrowUp style={{ marginBottom: '-6px' }} />
    <KeyboardArrowDown style={{ marginTop: '-6px' }} />
  </ArrowsContainer>
);

const tableVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 120, transition: { duration: 0.2 } },
};
const theadVariants: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};
const tbodyVariants: Variants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 1,
      staggerChildren: 0.2,
    },
  },
};

const animationDiv: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const animationDivWithDelay: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';

interface DamageResumeTableProps {
  onlyOwnerDamage?: boolean;
  filteredReservations: GetGuestDamage[];
  handleSortGuestDamage: (sortBy: GuestDamageSortedBy) => void;
}

export const SeazoneResumeTable = ({
  onlyOwnerDamage,
  filteredReservations,
  handleSortGuestDamage,
}:DamageResumeTableProps) => {
  const { handleOpenFile, handleOpenLink } = useGuestDamage();

  return (
    <TableContainer>
      <motion.table
        variants={tableVariants}
        initial="closed"
        animate="open"
        key="table"
      >
        <motion.thead variants={theadVariants} initial="hidden" animate="visible">
          <tr>
            <th>
              <motion.div variants={animationDiv} key="1">
                Tipo de dano
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('type')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="2">
                Classificação
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('item_type')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="3">
                Item
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('item_name')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="4">
                O que precisa ser feito
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('resolution')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="5">
                Evidencias
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="6">
                NF / Comprovante
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="7">
                Link de Cotação
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="8">
                Dono do Reembolso
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('refund_holder')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="9">
                Quantidade
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('item_quantity')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
            <th>
              <motion.div variants={animationDiv} key="10">
                Valor
                {' '}
                <ArrowsButton type="button" onClick={() => handleSortGuestDamage('price')}>
                  <Arrows />
                </ArrowsButton>
              </motion.div>
            </th>
          </tr>
        </motion.thead>
        <motion.tbody
          variants={tbodyVariants}
          initial="hidden"
          animate="visible"
        >
          {filteredReservations.map((guestDamage) => (
            <motion.tr key={guestDamage.id} variants={animationDivWithDelay}>
              <td>
                <div>
                  {guestDamage?.damage_type || '-'}
                </div>
              </td>
              <td>
                {guestDamage?.item_type || '-'}
              </td>
              <td>
                {guestDamage?.item_name || '-'}
              </td>
              <td>
                {guestDamage?.resolution || '-'}
              </td>
              <td style={{ cursor: 'pointer' }}>
                <div style={{
                  display: 'flex', flexDirection: 'column', gap: '0.5rem',
                }}
                >
                  {guestDamage.evidences
                    && guestDamage?.evidences?.length > 0
                    ? guestDamage.evidences?.map((evidence) => (
                      <LinkText
                        onClick={() => handleOpenFile(evidence.evidence?.url!,
                          evidence.evidence?.name!)}
                      >
                        {evidence.evidence?.name}
                      </LinkText>
                    ))
                    : ('-')}
                </div>
              </td>
              <Td
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenFile(guestDamage.quotation_file?.url!,
                  guestDamage.quotation_file?.name!)}
              >
                <>
                  {guestDamage.quotation_file?.name ? (
                    <LinkText>
                      {guestDamage.quotation_file.name}
                    </LinkText>
                  ) : '-'}
                </>
              </Td>
              {guestDamage?.quotation_link ? (
                <Td
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenLink(guestDamage?.quotation_link!)}
                >

                  <LinkText>Clique aqui para acessar</LinkText>
                </Td>
              ) : (<td>-</td>)}
              <td>
                {guestDamage?.refund_holder || '-'}
              </td>
              <td>
                {guestDamage?.item_quantity || '-'}
              </td>
              <td>
                {onlyOwnerDamage && (
                  <>
                    {numberToCurrency(Number(guestDamage?.total_price)) || '-'}
                  </>
                )}
              </td>
            </motion.tr>
          ))}
        </motion.tbody>
      </motion.table>
    </TableContainer>
  );
};

SeazoneResumeTable.defaultProps = {
  onlyOwnerDamage: false,
};
