import React, { useEffect } from 'react';
import OwnersPage from '../../components/OwnerPage';
import WhatsappFloatButton from '../../components/WhatsappFloatButton';
import { DateProvider } from '../../context/OwnerPage/DateContext';
import { OwnerCardPropertiesProvider } from '../../context/OwnerPage/OwnerCardProperties/OwnerCardPropertiesContext';
import { OwnerExtractProvider } from '../../context/OwnerPage/OwnerExtractContext/OwnerExtractContext';
import { TagProvider } from '../../context/OwnerPage/OwnerGridFinances/TagContext';

const Owners: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Proprietário';
  }, []);

  return (
    <>
      <WhatsappFloatButton phoneNumber="554831121173" />
      <DateProvider>
        <OwnerCardPropertiesProvider>
          <OwnerExtractProvider>
            <TagProvider>
              <OwnersPage />
            </TagProvider>
          </OwnerExtractProvider>
        </OwnerCardPropertiesProvider>
      </DateProvider>
    </>
  );
};

export default Owners;
