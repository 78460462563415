import styled, { css } from 'styled-components';
import { ExpenseStatus } from '../../../services/Expenses/types';

const padding = '10px 32px';

export const Container = styled.div<{
  loading?: boolean;
}>`
  width: 100%;
  overflow: auto;
  position: relative;

  border: 1px solid var(--Neuter-300, #d9d9d9);
  border-radius: 12px;

  ${({ loading }) => loading
    && css`
      overflow: hidden;
    `}

  @media (max-width: 1000px) {
    max-height: 500px;
  }
`;

type Props = { activeSort: boolean };
export const HeadItem = styled.button<Props>`
  font-family: Inter;
  font-weight: 500;

  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  svg {
    width: 13px;
    height: 13px;
  }

  span {
    position: relative;
    transition: 0.2s ease all;

    &::after {
      transition: 0.3s ease all;
      content: '';
      width: 0;
      height: 1px;
      background-color: #0d4bd0;
      border-radius: 50%;
      position: absolute;
      left: 0;
      bottom: -5px;
    }
  }

  ${({ activeSort }) => activeSort
    && css`
      span {
        color: #0d4bd0;

        &::after {
          width: 100%;
        }
      }
    `}
`;

export const BodyItem = styled.tr<{
  status: string;
}>`
  cursor: pointer;

  background: white;

  ${({ status }) => status === 'Approved'
    && css`
      background: ${({ theme }) => theme.palette.green._50.hex()};
    `}

  @media (min-width: 1200px) {
    &:hover {
      border: 2px solid black;
    }
  }
`;

export const Arrows = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 12px;
  z-index: 1;

  display: grid;

  * {
    white-space: nowrap;
    transition: 0.5s ease all;
  }

  thead {
    padding: 10px;
    top: 0;
    z-index: 2;
    color: #000;
    position: sticky;
    background: white;
    border-bottom: 1px solid var(--Neuter-300, #d9d9d9);
  }

  tbody {
    display: grid;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }

  tbody > tr {
    padding: ${padding};
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  tr {
    display: flex;
    width: 100%;
    align-items: center;
  }

  th {
    display: flex;
    padding: ${padding};
    align-items: center;
  }

  td {
    width: 100%;
    height: 100%;
    text-align: left;
    justify-content: center;
  }

  @media (min-width: 1000px) {
    tbody tr:hover {
      border: 1px solid #000;
    }
  }
`;

export const RadioButton = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
`;

export const Circle = styled.div<{
  status: ExpenseStatus;
}>`
   border-radius: 10px;
    display: flex;

    width: 10px;
    height: 10px;

    background: ${({ status, theme }) => status === 'Pre_Approved' && `${theme.palette.blue._100.hex()}`};
    background: ${({ status, theme }) => status === 'Approved' && `${theme.palette.green.main.hex()}`};
    background: ${({ status, theme }) => status === 'Analyzing' && `${theme.palette.yellow._500.hex()}`};
    background: ${({ status, theme }) => status === 'Denied' && `${theme.palette.orange._700.hex()}`};
    background: ${({ status, theme }) => status === 'Canceled' && `${theme.palette.grey._450.hex()}`};
    background: ${({ status, theme }) => status === 'Pending' && `${theme.palette.orange._600.hex()}`};
`;
