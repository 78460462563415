import React, { useState, useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';

import { useReservationForm } from '../../../../hooks/ReservationHook/useReservationForm';

import QuantityField from '../../../QuantityField';
import DatePicker from '../../../DatePicker';
import AutoComplete, { SelectProps } from '../../../Autocomplete/Autocomplete';

import person from '../../../../assets/icons/generals/personRed.svg';
import propertyImg from '../../../../assets/icons/generals/property-not-found.svg';

import {
  ButtonClose,
  Container,
  Dates,
  Property,
  QuantityGuest,
  Row,
} from './styles';
import { GetDaillyBudgetProps, getDailyBudget } from '../../../../services/DaillyBudget/request';
import { numberToCurrency } from '../../../../utils/Formatter';
import { initialValues as propertyUseInitialValues } from '../utils/initialValues';
import { useToast } from '../../../../context/ToastContext';
import { Budget, RequestDataProperty } from '../../../../services/DaillyBudget/types';
import { PropertyDetails } from '../../../../services/Property/types';

interface PropsCardBudget {
  isProperty: boolean;
  idProperty?: number;
  property?: RequestDataProperty;
  remove?: (a: number) => void;
  testid?: string;
  isTest?: boolean;
  listProperties: RequestDataProperty[];
  setListProperties: Function;
  propertiesListSearchField: Array<PropertyDetails>;
}

const CardBudget = ({
  isProperty,
  idProperty,
  remove,
  property,
  testid,
  isTest = false,
  listProperties,
  setListProperties,
  propertiesListSearchField,
}: PropsCardBudget): JSX.Element => {
  const { data } = useReservationForm();
  const [newProperty,
    setNewProperty] = useState<boolean>(property?.newProperty ? property?.newProperty : false);
  const [totalNigth, setTotalNigth] = useState(0);
  const [checkinDate, setCheckinDate] = useState(moment());
  const [checkoutDate, setCheckoutDate] = useState(moment());

  const toast = useToast();

  const validation = Yup.object().shape({
    checkInDate: Yup.date().required(),
    checkOutDate: Yup
      .date()
      .when('checkInDate',
        (checkInDate: any, schema: Yup.DateSchema) => (
          checkInDate && schema.min(checkInDate, 'A data deve ser maior que data de check-in')))
      .required(),
  });

  const initialValues = {
    guestsCapacity: 0,
    checkInDate: data?.start?.toDate(),
    checkOutDate: data?.start?.format('YYYY-MM-DD') === data?.end?.format('YYYY-MM-DD')
      ? data?.end?.add(1, 'day')?.toDate() : data?.end?.toDate(),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async () => {
      //
    },
  });

  function setDatesField(id: string, value: Moment) {
    formik.setFieldValue(id, value.toDate());
    formik.setFieldTouched(
      id, true, false,
    );
  }

  async function getPropertyByIdSearch(id: string) {
    setNewProperty(true);

    if (data && id) {
      const args: GetDaillyBudgetProps = {
        endDate: data?.end,
        startDate: data?.start,
        propertiesIds: id,
      };

      const response = await getDailyBudget(args);

      if (response) {
        const listAvaliableProperties = listProperties.filter((item) => item.id !== '');
        setListProperties([...listAvaliableProperties,
          {
            ...response[Object.keys(response)[0] as keyof Budget],
            id: Object.keys(response)[0],
            newProperty: true,
            checkinDate: formik.values.checkInDate,
            checkoutDate: formik.values.checkOutDate,
          }]);
      } else {
        toast.alert('Nenhuma propriedade foi encontrada no período selecionado');
      }
    }
  }

  const handleGetProperty = async ():
  Promise<SelectProps[]> => propertiesListSearchField.map((propertyMap) => (
    {
      optionText: `${propertyMap.code}`,
      optionValue: propertyMap?.id?.toString() || '',
    }
  ));

  function handleCheckinDate(checkin: Date) {
    const duration = moment.duration(checkoutDate.diff(checkin));

    setTotalNigth(Math.round(duration.asDays()));
  }

  function handleCheckoutDate(checkout: Date) {
    const duration = moment.duration(moment(checkout).diff(checkinDate));

    setTotalNigth(Math.round(duration.asDays()));
  }

  function isRemove() {
    if (remove && idProperty) {
      remove(idProperty);
    }
  }

  async function handleUpdateInfoCard(id: string, qtdeNumber: number) {
    setNewProperty(true);
    if (data && id) {
      const args: GetDaillyBudgetProps = {
        endDate: moment(formik.values.checkOutDate),
        startDate: moment(formik.values.checkInDate),
        propertiesIds: id,
        qtdGuest: qtdeNumber,
      };

      const response = await getDailyBudget(args);

      if (response) {
        const propertyAux = {
          ...response[Object.keys(response)[0] as keyof Budget],
          id: Object.keys(response)[0],
          newProperty: true,
        };
        const arrayAux = listProperties.map((item) => {
          const aux = item;
          if (item.id === id) {
            aux.rent_price = propertyAux.rent_price;
            aux.checkinDate = moment(formik.values.checkInDate);
            aux.checkoutDate = moment(formik.values.checkOutDate);
          }
          return aux;
        });

        setListProperties(arrayAux);
      } else {
        toast.alert('Nenhuma propriedade foi encontrada no período selecionado');
      }
    }
  }

  useEffect(() => {
    if (newProperty) {
      handleUpdateInfoCard(property?.id || '0', formik.values.guestsCapacity);
    }
  }, [formik.values.checkInDate, formik.values.checkOutDate]);

  useEffect(() => {
    if (!isTest) {
      setDatesField('checkInDate', data?.start || moment());
      setDatesField('checkOutDate', (data?.start?.format('YYYY-MM-DD') === data?.end?.format('YYYY-MM-DD')
        ? data?.end?.add(1, 'day') : data?.end) || moment());

      setCheckinDate(data?.start || moment());
      setCheckoutDate((data?.start?.format('YYYY-MM-DD') === data?.end?.format('YYYY-MM-DD')
        ? data?.end?.add(1, 'day') : data?.end) || moment());

      const duration = moment.duration(data?.end.diff(data?.start));
      setTotalNigth(Math.round(duration.asDays()));
    }
  }, [data]);

  useEffect(() => {
    setNewProperty(property?.newProperty ? property?.newProperty : false);
  }, [property]);

  return (
    <Container data-testid={testid}>
      <ButtonClose onClick={isRemove}>
        <Close />
      </ButtonClose>

      {(!isProperty && !newProperty) && (
      <AutoComplete
        id="property_id"
        asyncOptions={handleGetProperty}
        formik={formik}
        onChange={(value) => getPropertyByIdSearch(value)}
        placeholder="Buscar imóvel"
      />
      )}

      {newProperty && (
      <>
        <Property>
          <p>
            <strong>
              {property?.code}
              .
            </strong>
            {' '}
            {property?.address?.street}
            {', '}
            {property?.address?.number}
            {', '}
            {property?.address?.neighborhood}
            {', '}
            {property?.address?.city}
            {' - '}
            {property?.address?.state}
            {' CEP '}
            {property?.address?.complement}
          </p>
          <img src={propertyImg} alt="" />
        </Property>
        <QuantityGuest>
          <section>
            <img src={person} alt="" />
            <p>
              Até
              {' '}
              {property?.guest_capacity}
              {' '}
              hóspedes
            </p>
          </section>
          <QuantityField maxQuantity={property?.guest_capacity} id="guestsCapacity" formik={formik} border={false} justify={false} callback={(qtdeNumber) => handleUpdateInfoCard(property?.id || '0', qtdeNumber)} />
        </QuantityGuest>
        <Dates>
          <DatePicker
            hasInitialDates
            id="checkInDate"
            minDate={new Date('2000-01-02')}
            className="filter"
            label="De"
            formik={formik}
            onChange={handleCheckinDate}
          />
          <DatePicker
            hasInitialDates
            id="checkOutDate"
            minDate={new Date('2000-01-02')}
            className="filter"
            label="Até"
            formik={formik}
            onChange={handleCheckoutDate}
          />
        </Dates>
        <Row>
          <p>Total de noites</p>
          <p>{totalNigth}</p>
        </Row>
        <Row>
          <p>Valor por noite</p>
          <p>
            {numberToCurrency(property?.rent_price?.daily_price || 0)}
          </p>
        </Row>
        <Row>
          <p>Taxa de limpeza</p>
          <p>
            {property?.rent_price?.fees && property?.rent_price?.fees.length > 0
              ? numberToCurrency(property?.rent_price?.fees[0].value) : 0}
          </p>
        </Row>
        <Row>
          <p>Valor total</p>
          <p>{numberToCurrency(property?.rent_price?.total_price) || 0}</p>
        </Row>
      </>
      )}
    </Container>
  );
};

CardBudget.defaultProps = {
  idProperty: 0,
  property: propertyUseInitialValues[0],
  remove: (a: number) => a,
  testid: '',
  isTest: false,
};

export default CardBudget;
