import React from 'react';
import { Container } from './style';
import { IText, Item } from '../types';
import { Text } from '../../style';

const chooseText = (text: Item['text']) => {
  switch (typeof text) {
    // valida para uma string simples
    case 'string':
      return <Text>{text}</Text>;

    case 'object':
      // valida para a tipagem customizada
      if ('is' in text) {
        const iText = text as IText;
        return <Text style={{ ...iText.style }}>{iText.is}</Text>;
      }

      // valida para caso seja um componente JSX
      return text;

    default:
      return null;
  }
};

interface ItemProps {
  item: Item;
}

const ItemComponent = ({ item }: ItemProps) => {
  const selectedText = React.useMemo(() => chooseText(item.text), [item]);

  return (
    <Container>
      {item.icon}
      {selectedText}
    </Container>
  );
};

export default ItemComponent;
