import React from 'react';

import { AnimatePresence, MotionStyle } from 'framer-motion';
import { Burger } from '../components/Burger';

import { LogoSVGIcon } from './svg';
import { Container, Content } from './style';
import { useMobile } from '../../../hooks/useMobile/useMobile';

const Header = (styles?: MotionStyle) => (
  <Container
    style={styles}
    initial={{
      width: 0,
    }}
    animate={{
      width: '100%',
    }}
    exit={{
      opacity: 0,
      left: '50%',
      top: '-10px',
      transition: {
        duration: 0.2,
      },
    }}
  >
    <Content>
      <Burger />
      <LogoSVGIcon />
    </Content>
  </Container>
);

const HeaderPresence = () => {
  const { isDesktop } = useMobile();

  return (
    <AnimatePresence>
      <Header
        borderRadius={isDesktop ? '5px' : '0'}
        key={'header_partners_page_open=true'}
      />
    </AnimatePresence>
  );
};

export default HeaderPresence;
