import { useContextSelector } from 'use-context-selector';
import { ChangePropertyHost } from '../../context/ChangePropertyHostContext/ChangePropertyHostContext';

export function useChangePropertyHost() {
  const hosts = useContextSelector(ChangePropertyHost, (state) => state.hosts);

  const setHosts = useContextSelector(ChangePropertyHost,
    (state) => state.setHosts);

  const hostsListSorted = useContextSelector(ChangePropertyHost,
    (state) => state.hostsListSorted);

  const exchangedHosts = useContextSelector(ChangePropertyHost, (state) => state.exchangedHosts);

  const setExchangedHosts = useContextSelector(ChangePropertyHost,
    (state) => state.setExchangedHosts);

  const updateExchangedHosts = useContextSelector(ChangePropertyHost,
    (state) => state.updateExchangedHosts);

  const openModalConfirmExchangeHost = useContextSelector(ChangePropertyHost, (state) => state
    .openModalConfirmExchangeHost);

  const setOpenModalConfirmExchangeHost = useContextSelector(ChangePropertyHost,
    (state) => state.setOpenModalConfirmExchangeHost);

  const modalEditExchangeHost = useContextSelector(ChangePropertyHost, (state) => state
    .modalEditExchangeHost);

  const setModalEditExchangeHost = useContextSelector(ChangePropertyHost,
    (state) => state.setModalEditExchangeHost);

  const modalDeleteExchangeHost = useContextSelector(ChangePropertyHost, (state) => state
    .modalDeleteExchangeHost);

  const setModalDeleteExchangeHost = useContextSelector(ChangePropertyHost,
    (state) => state.setModalDeleteExchangeHost);

  const payloadExchangedHost = useContextSelector(ChangePropertyHost, (state) => state
    .payloadExchangedHost);

  const setPayloadExchangedHost = useContextSelector(ChangePropertyHost,
    (state) => state.setPayloadExchangedHost);

  const exchangeIsConfirmed = useContextSelector(ChangePropertyHost, (state) => state
    .exchangeIsConfirmed);

  const setExchangeIsConfirmed = useContextSelector(ChangePropertyHost,
    (state) => state.setExchangeIsConfirmed);

  const orderGridBy = useContextSelector(ChangePropertyHost, (state) => state
    .orderGridBy);

  const setOrderGridBy = useContextSelector(ChangePropertyHost,
    (state) => state.setOrderGridBy);

  const isSubmitting = useContextSelector(ChangePropertyHost, (state) => state
    .isSubmitting);

  const setIsSubmitting = useContextSelector(ChangePropertyHost,
    (state) => state.setIsSubmitting);

  return {
    hosts,
    setHosts,
    hostsListSorted,
    exchangedHosts,
    setExchangedHosts,
    updateExchangedHosts,
    openModalConfirmExchangeHost,
    setOpenModalConfirmExchangeHost,
    modalEditExchangeHost,
    setModalEditExchangeHost,
    modalDeleteExchangeHost,
    setModalDeleteExchangeHost,
    payloadExchangedHost,
    setPayloadExchangedHost,
    exchangeIsConfirmed,
    setExchangeIsConfirmed,
    orderGridBy,
    setOrderGridBy,
    isSubmitting,
    setIsSubmitting,
  };
}
