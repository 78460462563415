import styled from 'styled-components';
import { motion } from 'framer-motion';
import { shimmerAnimation } from '../../../../Calendar/LoadingShimmer/animationShimmer';

export const SearchTableContainer = styled(motion.div)`
 flex: 1;
  background: #E4EAEF;
  border-radius: 10px;
  overflow: auto;
  max-height: 500px;

  @media (max-width: 900px) {
    max-height: 300px;
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0.5rem;
    width: 0.5rem;
    background-color: ${({ theme }) => theme.palette.grey._400.hex()};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.blue._400.hex()};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.palette.blue._50.hex()};
  }
`;

export const Table = styled(motion.table)`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  min-width: 1000px;

  thead {
    position: sticky;
    top: 0;
    background: #E4EAEF;
    z-index: 1;
    height: 100%;

    th {
      padding: 1rem;
      text-align: center;
      color: #394760;
      font-size: 1rem;
      line-height: 1.6;
      font-weight: 700;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }
    }
  }

  tbody {
    td {
      background: #EBF0F5;
      padding: 1rem;
      font-size: 1rem;
      line-height: 1.6;
      text-align: center;
      position: relative;

      > span {
        background: ${({ theme }) => theme.palette.green.main.hex()};
        color: #ffffff;
        padding: 0.5rem 1rem;
        display: flex;
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-radius: 20px;
        font-weight: 600;
      }

      &::after {
        content: '';
        position: absolute;
        top: 15%;
        left: 0;
        right: 0;
        height: 70%;
        border-right: 1px solid #D9D9D9;
      }
      &:first-child {
        padding-left: 1.5rem;
      }
      &:last-child {
        padding-right: 1.5rem;
        background: #E4EAEF;
        width: 10%;
      }
      &:nth-last-child(-n+2) {
        &::after {
          content: none;
        }
      }
    }
  }
`;
export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #0D4BD0;
`;

export const LineShimmer = styled.div`
  display: flex;
  width: 100%;
  height: 2rem;
  border-radius: 10px;
  animation: ${shimmerAnimation} 1s forwards infinite;
  &:after{
    content: '';
  }
`;

export const AnimationDiv = styled(motion.div)``;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  button {
    width: 50%;
  }
`;

export const ArrowsButton = styled.button`
  border: none;
  background-color: transparent;
  z-index: 20000000 !important;
`;
