import React from 'react';
import { useMobile } from '../../../hooks/useMobile/useMobile';

import Mobile from './Mobile/Mobile';

import { ICard } from './types';
import Desktop from './Desktop/Desktop';

const ChoiseCard = ({
  card,
  title,
  image,
}: ICard) => {
  const { isDesktop } = useMobile();

  if (isDesktop) {
    return (
      <Desktop
        card={card}
        title={title}
        image={image}
      />
    );
  }

  return (
    <Mobile
      card={card}
      title={title}
      image={image}
    />
  );
};

export default ChoiseCard;
