import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900; // 900px

export const CardContainer = styled.div<{ isMain: boolean }>`
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${(props) => (props.isMain ? '#AAE0AC' : '#E8E8E8')};
  box-sizing: border-box;
  border-radius: 8px;
  margin: 16px 0;
  padding: 8px;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    width: 95%;
    height: 241px;
    margin: 24px 0 8px 16px;
    padding: 16px;
  }
`;

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BankContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h3 {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.palette.grey._560.hex()};
    }
  }

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    h3 {
      font-size: 18px;
      line-height: 24px;
    } 
    div {
      h3 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
`;

export const TagMain = styled.div`
  width: auto;
  padding: 10px;
  background: ${({ theme }) => theme.palette.green._300.hex()};
  border-radius: 8px;
  margin: 0 8px;

  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 4px;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    line-height: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const ContainerButtons = styled.div<{ isMainAccount: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 0 0 30px 0px; 
  svg,
  img {
    cursor: pointer;
    &.delete {
      fill: ${({ theme }) => theme.palette.orange.main.hex()};
    }
  }
  @media(min-width: ${MAX_SIZE_MOBILE}px) { 
    margin: 0 6px 32px 0px 
  }
`;

export const Body = styled.div`
  width: 100%;

  &.firstContent, &.secondaryContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.firstContent {
    flex-direction: column;
  }
  &.secondaryContent {
    flex-direction: row;
  }

  div {
    min-width: 50%;
    max-width: max-content;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.grey._560.hex()};
  }

  span {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    &.firstContent {
      flex-direction: row;
      
      div {
        span {
          margin-left: 8px;
        }
      }

      div:last-child {
        margin-left: 16px;
      }
    }

    div {
      min-width: 25%;
      max-width: 40%;
      margin-top: 0px;
    }
  
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const ButtonEdit = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
