import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../hooks/useMobile/useMobile';

interface IContainer {
  cWidth?: string;
  cHeight?: string;
  cPosition?: {
    x: string;
    y: string;
    transform?: string;
  };
}

export const Backdrop = styled(motion.button)`
  z-index: 999;
  border: none;
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  opacity: 0.4;
  backdrop-filter: blur(15px);
  background-color: ${({ theme }) => theme.palette.black.main.hex()};
`;

export const Container = styled(motion.div)<IContainer>`
  position: fixed;
  z-index: 9999;

  left: 5%;
  top: 5%;

  width: 90%;
  height: 90%;

  background-color: ${({ theme }) => theme.palette.white.main.hex()};

  border-radius: 10px;

  padding: 60px 15px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }


  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    width: ${({ cWidth }) => cWidth || '80%'};
    height: ${({ cHeight }) => cHeight || '80%'};
  }

  ${({ cPosition }) => cPosition
    && css`
      top: ${cPosition.y};
      left: ${cPosition.y};
      transform: ${cPosition.transform};
    `}
`;

export const Header = styled.button`
  position: absolute;
  cursor: pointer;
  border: none;
  background-color: transparent;
  right: 20px;
  top: 20px;
`;
export const Content = styled.div``;
