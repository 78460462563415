import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { BookingContainer, SubTitle, Title } from './styles';

const BookingButton = () => {
  const handleButtonClick = () => {
    const siteUrl = 'https://seazone.com.br/';
    window.open(siteUrl, '_blank');
  };

  return (
    <BookingContainer
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleButtonClick}
    >
      <Title>Você já conhece o site de reservas da Seazone?</Title>
      <div>
        <SubTitle>Clique aqui e conheça agora</SubTitle>
        <ArrowForwardIosIcon />
      </div>
    </BookingContainer>
  );
};

export default BookingButton;
