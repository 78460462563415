import styled from 'styled-components';

export const ContainerBox = styled.div<{ isMonthModalOpen: boolean }>`
  width: 100%;
  max-width: 380px;
  padding: 1rem;
  height: ${({ isMonthModalOpen }) => (isMonthModalOpen ? '400px' : 'initial')};
  overflow: hidden !important;
  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 16px;
  margin-top: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Text = styled.strong`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 600 !important;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.black.main.hex()};
  margin: 1rem 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: -48%;
`;

export const CalendarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
