import {
  FC,
} from 'react';

import momentLib from 'moment';
import { extendMoment } from 'moment-range';

import {
  Container,
  Wrapper,
  DetailsContainer,
  TransactionContainer,
  SmallText,
  SmallTextBold,
} from './styles';

import { useReservationDetails } from '../../../context/ReservationDetailsContext';
import { numberToCurrency } from '../../../utils/Formatter';

interface Props {
  props?: object;
}

/* @ts-ignore */
const moment = extendMoment(momentLib);

const FinanceForm: FC<Props> = () => {
  const { reservationDetails } = useReservationDetails();

  const typePayments: any = {
    ted: 'TED',
    pix: 'PIX',
    paypal: 'Paypal',
    credit_card: 'Cartão de credito',
    deposit: 'Deposito',
  };

  return (
    <Container>
      <div style={{ padding: '16px', flex: 1, height: '100%' }}>
        <DetailsContainer>
          <Wrapper>
            <SmallText>Valor total das diárias</SmallText>
            <SmallText>
              {numberToCurrency(reservationDetails?.payment?.gross_daily_value)}
            </SmallText>
          </Wrapper>

          <Wrapper>
            <SmallText>Valor total da reserva</SmallText>
            <SmallText>
              {
              numberToCurrency(reservationDetails?.payment?.daily_net_value)
}
            </SmallText>
          </Wrapper>
          <SmallTextBold>Pagamento</SmallTextBold>
          <Wrapper>
            <SmallText>Total já recebido</SmallText>
            <SmallText>{numberToCurrency(reservationDetails?.payment?.paid_amount)}</SmallText>
          </Wrapper>
          <SmallTextBold>Histórico de transações</SmallTextBold>
        </DetailsContainer>

        <TransactionContainer>

          <Wrapper className="date">
            <SmallTextBold>Data</SmallTextBold>
            {reservationDetails?.payment?.payments?.length > 0
              && reservationDetails?.payment?.payments?.map((payment: any) => (
                <SmallText>{moment(payment.date).format('DD/MM/YYYY')}</SmallText>
              ))}
          </Wrapper>
          <Wrapper className="value">
            <SmallTextBold>Valor</SmallTextBold>
            {reservationDetails?.payment?.payments?.length > 0
              && reservationDetails?.payment?.payments?.map((payment: any) => (
                <SmallText>{numberToCurrency(payment.value)}</SmallText>
              ))}
          </Wrapper>
          <Wrapper className="payment">
            <SmallTextBold>Forma de pagto.</SmallTextBold>
            {reservationDetails?.payment?.payments?.length > 0
              && reservationDetails?.payment?.payments?.map((payment: any) => (
                <SmallText>{typePayments[payment.payment_method]}</SmallText>
              ))}
          </Wrapper>
        </TransactionContainer>
      </div>
    </Container>
  );
};

export default FinanceForm;
