import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(motion.h1)`
  font-weight: bold;
  font-size: 1.2rem;
  color: #394760;
`;
