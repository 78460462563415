import React from 'react';
import { Container } from './style';

interface IconProps {
  children: React.ReactNode;
}

const IconTemplate = ({ children }: IconProps) => (
  <Container>
    {children}
  </Container>
);

export default IconTemplate;
