import React, { useEffect, useState } from 'react';
import { UseFormik } from '../../utils/Formik/types';
import FormButton from '../FormButton';
import InputCheckBox from '../InputCheckBox';
import {
  ButtonClear, Buttons, Container, ContainerCheckbox, Line,
} from './styles';

export interface CheckboxOptions{
  value: string | number;
  valueLabel: string;
  checked: boolean;
}

export interface ISelectProps {
  options: Array<CheckboxOptions>;
  handleClose: () => void;
  formik?: UseFormik<any>,
  id: string;
  defaultValue: Array<string>;
}

const SelectCheckbox: React.FC<ISelectProps> = ({
  options,
  handleClose,
  formik,
  id,
  defaultValue,
}) => {
  const [checkBox, setCheckbox] = useState<Array<CheckboxOptions>>();

  useEffect(() => {
    const newState = options && options.map((check: CheckboxOptions) => {
      const checkAux = check;
      const isOption = defaultValue.find((item) => item === check.value);
      if (isOption) {
        checkAux.checked = true;
      }
      return checkAux;
    });
    setCheckbox(newState);
  }, [options]);

  function handleCheck(value: string | number) {
    const newState = checkBox && checkBox.map((check: CheckboxOptions) => {
      const checkAux = check;
      if (checkAux.value === value) {
        checkAux.checked = !checkAux.checked;
      }
      return checkAux;
    });

    setCheckbox(newState);
  }

  function handleApply() {
    const checkboxChecked: Array<string> = [];
    checkBox?.forEach((check) => {
      if (check.checked) {
        checkboxChecked.push(check.value.toString());
      }
    });
    if (formik && checkboxChecked && checkboxChecked.length > 0) {
      formik.setFieldValue(id, checkboxChecked);
      formik.setFieldTouched(
        id, true, false,
      );
    }
    handleClose();
  }

  function handleClear() {
    const newState = checkBox && checkBox.map((check: CheckboxOptions) => {
      const checkAux = check;
      checkAux.checked = false;
      return checkAux;
    });

    if (formik) {
      formik.setFieldValue(id, ['Selecione']);
      formik.setFieldTouched(
        id, true, false,
      );
    }
    setCheckbox(newState);
  }

  return (
    <>
      <Container>
        <ContainerCheckbox>
          {checkBox && checkBox.map(({
            value,
            valueLabel,
            checked,
          }) => (
            <InputCheckBox
              className="styleCheckbox"
              key={value}
              checked={checked}
              labelName={valueLabel}
              onChange={() => handleCheck(value)}
            />
          ))}
        </ContainerCheckbox>
        <Line />
        <Buttons>
          <ButtonClear onClick={handleClear}>Limpar</ButtonClear>
          <FormButton type="button" variant="outlined" onClick={handleApply}>Aplicar</FormButton>
        </Buttons>
      </Container>
    </>
  );
};

export default SelectCheckbox;
