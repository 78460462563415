import React from 'react';
import Lottie from 'lottie-react';

import animationSource from '../source/imageShimmer.json';

export const ImageShimmer = () => (
  <Lottie
    animationData={animationSource}
  />
);
