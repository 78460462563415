import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  gap: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none;
  background: transparent;
  h1 {
    color: #0d4bd0;
  }
`;

export const ButtonContainer = styled(motion.button)`
  border: none;
  background: transparent;
  color: #0d4bd0;
`;
