import styled from 'styled-components';

export const Wrapper = styled.div<{
  openModal: boolean;
}>`
  position: fixed;
  z-index: 9999;
  left: 0;
  display: ${({ openModal }) => (openModal ? 'flex' : 'none')};
  justify-content: flex-end;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  align-items: center;
  justify-content: center;
`;

export const Container = styled.form`
  position: absolute;
  z-index: 99999;

  display: flex;
  flex-direction: column;

  width: 560px;
  height: 90%;
  padding: 15px 50px;
  border-radius: 10px;
  background-color: white;

  section {
    display: flex;
  }

  @media(max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 0;
  top: 0;

  margin-right: 15px;
  margin-top: 10px;
  width: 30px;
  color: ${({ theme }) => theme.palette.orange.main.hex()};
  background: transparent;
  border: none;

  font-size: 10px;

  :hover {
      cursor: pointer;
      filter: brightness(0.9);
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);

    }
`;

export const HeaderModalNewBankAccount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 39px;

  h1 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
  }

  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

export const ContentInputs = styled.div`
  position: relative;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
}

`;

export const RowInput = styled.section`
  margin-bottom: 20px;

  display: flex;
    align-items: center;
    justify-content: space-between;

  &.selector {
    border: 1px solid ${({ theme }) => theme.palette.grey._445.hex()};
    border-radius: 10px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 530px){
      flex-direction: column;
    }
  }
  .column {
    width: 48%;
  }

  span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  section {
    width: 40%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      position: unset !important;
      height: 40px !important;
      background: transparent !important;
    }

    label {
      margin: 0;
    }

    @media(max-width: 530px){
      width: 100%;
      justify-content: center;
    }
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }


`;

export const ButtonSave = styled.button`
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};

  width: 50%;
  height: 40px;

  border-radius: 10px;
  border: none;

  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  transition: filter(0.2s);

  :hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
`;

export const ButtonCancel = styled.button`
  background: #EBEBF5;
  width: 50%;
  height: 40px;
  margin-right: 15px;

  border-radius: 10px;
  border: 1px solid #D9DCDF;

  color: #B5B5B5;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  transition: filter(0.2s);

  :hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;

export const RowButtons = styled.div`
  width: 100%;
  height: 100px;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
