import { IFormValues } from '../../../types';
import { UseFormik } from '../../../../../../../../utils/Formik/types';
import { useMobile } from '../../../../../../../../hooks/useMobile/useMobile';
import CountrySelector from '../../../../../../../../components/CountrySelector/CountrySelector';
import HelperText from '../../../../../../../../components/HelperText';
import { Type } from '../../../../../../../../components/HelperText/types';

import {
  Container,
  OverWriteCountrySelector,
  StarSymbol,
  ContainerLabel,
} from './styles';

interface IPhoneComponent {
  formik: UseFormik<IFormValues>;
  label: string;
  required?: boolean;
}

const Phone = ({
  formik,
  label,
  required = false,
}: IPhoneComponent) => {
  const { isMobile } = useMobile();

  const isHelperTextError = formik?.touched?.phone && formik?.errors?.phone !== ''
  && formik?.errors?.phone;

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          {formik?.errors?.phone}
        </>
      );
    }
    return '';
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  return (
    <Container>
      <ContainerLabel>
        <label htmlFor={label}>{label}</label>
        {required && <StarSymbol>*</StarSymbol>}
      </ContainerLabel>

      <OverWriteCountrySelector>
        <CountrySelector
          country="br"
          autoFormat
          enableLongNumbers
          value={formik.values.phone}
          enableClickOutside={!isMobile}
          onChange={(value) => formik.setFieldValue('phone', value)}
          countryCodeEditable={false}
          overWrite="css_overwrite_react-tel-input"
        />
      </OverWriteCountrySelector>

      {!!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
      )}
    </Container>
  );
};

Phone.defaultProps = {
  required: false,
};

export default Phone;
