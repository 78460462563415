/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
  Label,
  Error,
  Section,
  FormButton,
  FormContent,
  SelectStyled,
  SubText,
} from '../../style';

import { InputEarning } from '../../components';
import { validateError } from '../../components/Input/Input';
import { useToast } from '../../../../../../../../context/ToastContext';
import { usePartners } from '../../../../../../../../context/Partners/Partner/PartnerContext';
import { usePartnersEarning } from '../../../../../../../../context/Partners/Earning/Earning';
import { postPartnersFinancialWithdrawRequest } from '../../../../../../../../services/Partner/Financial';
import SuccessPage from '../../../../../../components/SuccessPage';
import { currencyToNumber, formatCNPJToPost, formatCPFtoSubmit } from '../../../../../../../../utils/Formatter';
import { analyticsEvent } from '../../../../../../utils/analytics';
import { useUser } from '../../../../../../../../context/UserContext';
import Banks from '../../components/Banks/Banks';
import { postPartnersBankDetails } from '../../../../../../../../services/Partner/Financial/BankDetails/request';
import { validation } from './validation';

const Two = () => {
  const { userInformation } = useUser();
  const { resume, setFinancialWithdrawRequest } = usePartners();
  const { prevStep, formValues, setFormValues } = usePartnersEarning();

  const [sucess, setSucess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      bankName: formValues.bankName,
      bankNumber: formValues.bankNumber,
      bankAgency: formValues.bankAgency,
      bankAccountType: formValues.bankAccountType,
      bankAccountNumber: formValues.bankAccountNumber,
      wantToReceiveByPix: formValues.wantToReceiveByPix,
      cpfOrCnpjOfFavored: formValues.cpfOrCnpjOfFavored,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      setFormValues((oldValues) => ({
        ...oldValues,
        ...values,
      }));

      if (resume?.partner.id) {
        try {
          setLoading(true);
          const bankDetails = await postPartnersBankDetails({
            bank_id: formValues.bankId,
            account_number: values.bankAccountNumber,
            account_type: values.bankAccountType,
            branch_number: values.bankAgency,
            user_id: resume.partner.user.id,
            entity_name: formValues.favoredName,

            cpf:
              formValues.cpfOrCnpj === 'cpf'
                ? formatCPFtoSubmit(formValues.cpfOrCnpjValue)
                : '',

            cnpj:
            formValues.cpfOrCnpj === 'cnpj'
              ? formatCNPJToPost(formValues.cpfOrCnpjValue)
              : '',
          });

          const response = await postPartnersFinancialWithdrawRequest({
            status: 'Requested',
            invoice_notice: false,
            partner: resume.partner.id,
            payment_method: 'Ted',
            bank_details: bankDetails.id,
            value: currencyToNumber(formValues.rescueValue),
            date_requested: moment(new Date()).format('YYYY-MM-DD'),
          });

          setFinancialWithdrawRequest((oldValues) => [...oldValues, response]);

          toast.success('Dados bancários salvos com sucesso');
          analyticsEvent({
            name: 'Concluiu a solicitação de saque',
            userInformation,
          });
          setSucess(true);
        } catch {
          toast.error('Não foi possível salvar os dados bancários');
        } finally {
          setLoading(false);
        }
      } else {
        toast.error('Não foi possível identificar o parceiro');
        analyticsEvent({
          name: 'Um erro aconteceu durante a solicitação',
          userInformation,
        });
      }
    },
  });

  React.useEffect(() => {
    setFormValues((oldValues) => ({
      ...oldValues,
      ...formik.values,
    }));
  }, [formik.values]);

  if (sucess) {
    return (
      <SuccessPage
        onClose={() => {
          navigate('/parceiros/ganhos');
        }}
      />
    );
  }

  return (
    <FormContent onSubmit={formik.handleSubmit}>
      <InputEarning
        formik={formik}
        id="cpfOrCnpjOfFavored"
        title="CPF/CNPJ do favorecido"
        placeholder="Ex: 00000"
      />

      <Section>
        <Label>Banco</Label>
        <SubText>
          Escolha um entre os bancos abaixo
        </SubText>
        <Banks formik={formik} />
      </Section>

      <InputEarning
        formik={formik}
        id="bankAgency"
        title="Agência"
        placeholder="Ex: 0000"
      />

      <Section>
        <Label>Tipo de conta</Label>
        <SelectStyled
          id="bankAccountType"
          placeholder="Selecione"
          onChange={formik.handleChange}
          value={formik.values.bankAccountType}
        >
          <option defaultChecked value="Selecione">Selecione</option>
          <option value="Joint_Checking_Account">
            Conta corrente conjunta
          </option>
          <option value="Saving_Account">Conta poupança</option>
          <option value="Individual_Checking_Account">
            Conta corrente individual
          </option>
        </SelectStyled>
        {validateError(
          formik, 'bankAccountType', false,
        ) && (
        <Error>{formik.errors.bankAccountType}</Error>
        )}
      </Section>

      <InputEarning
        formik={formik}
        id="bankAccountNumber"
        title="Número da conta"
        subtext="Com o dígito"
        placeholder="Ex: 00000"
      />

      <Section direction="row">
        <FormButton type="button" variants="outline" onClick={prevStep}>
          Voltar
        </FormButton>

        <FormButton type="submit">
          {loading ? (
            <>
              <span>Enviando</span>
              <CircularProgress size={20} />
            </>
          ) : 'Enviar'}
        </FormButton>
      </Section>
    </FormContent>
  );
};

export default Two;
