/* eslint-disable max-len */
import React from 'react';
import {
  Container,
  ItemList,
  ListContainer,
  Logo,
  LogoContainer,
  LogoText,
  Text,
  Title,
} from './style';
import SeazoneLogo from '../../../../../../../../../assets/icons/generals/logoBlack.svg';

const Terms = () => (
  <Container>
    <LogoContainer>
      <Logo src={SeazoneLogo} alt="Seazone Logo" />
      <LogoText>SEU LUGAR FORA DE CASA</LogoText>
    </LogoContainer>
    <Title>
      Os Termos e Condições de uso do programa de indicações Seazone definem as diretrizes para as indicações de proprietários e seus respectivos imóveis para locação.
    </Title>
    <Text>
      Por meio dos termos e condições elencados no presente regulamento,
      {' '}
      <span>SEAZONE SERVICOS LTDA</span>
      , inscrita no CNPJ sob o nº 32.018.829/0001-08, designada simplesmente
      {' '}
      <span>SEAZONE</span>
      , resolve promover e coordenar o Programa e Plataforma de Indicações, com o objetivo de
      regulamentar a indicação de imóveis para locação, respectivos formatos de pagamento das
      comissões e demais serviços associados. Todas as diretrizes e condições específicas estarão
      previstas no presente Regulamento, ficando os casos omissos resguardados à apreciação e
      deliberação exclusiva por parte da
      {' '}
      <span>SEAZONE</span>
      .
    </Text>
    <Text>
      <span>CONSIDERANDO</span>
      {' '}
      que:
    </Text>
    <ListContainer>
      <ItemList>
        A
        {' '}
        <span>SEAZONE</span>
        {' '}
        é uma empresa de tecnologia especializada em oferecer soluções inovadoras que otimizam a jornada do proprietário e do anfitrião em aluguéis de imóveis por curta temporada;
      </ItemList>
      <ItemList>
        O
        {' '}
        <span>PARCEIRO</span>
        {' '}
        poderá indicar à
        {' '}
        <span>SEAZONE</span>
        {' '}
        potenciais proprietários de imóveis para os negócios desenvolvidos. Cada contrato fechado que for concretizado após indicação realizada pelo PARCEIRO de potenciais proprietários à
        {' '}
        <span>SEAZONE</span>
        , serão consideradas para fins desta Parceria como
        {' '}
        <span>“ganhos”</span>
        ;
      </ItemList>
      <ItemList>
        A
        {' '}
        <span>SEAZONE</span>
        {' '}
        é detentora dos direitos de propriedade intelectual da plataforma Seazone e dos serviços disponibilizados a partir desta (os
        {' '}
        <span>“Serviços Seazone”</span>
        ), que atendem de forma satisfatória às necessidades dos proprietários de imóveis indicados pelo
        {' '}
        <span>PARCEIRO</span>
        {' '}
        (os
        {' '}
        <span>“Proprietários”</span>
        );
      </ItemList>
      <ItemList>
        O
        {' '}
        <span>PARCEIRO</span>
        {' '}
        se compromete a empenhar esforços para que os
        {' '}
        <span>Proprietários</span>
        {' '}
        promovam a adesão aos
        {' '}
        <span>Serviços Seazone</span>
        .
      </ItemList>
    </ListContainer>
    <Title>CLÁUSULA PRIMEIRA - DO OBJETO</Title>
    <Text>
      <span>1.1.</span>
      {' '}
      O objetivo do presente instrumento é a indicação, pelo
      {' '}
      <span>PARCEIRO</span>
      , para licenciamento, de forma não exclusiva, de potenciais
      {' '}
      <span>Proprietários</span>
      {' '}
      com interesse na locação por temporada de seus imóveis à
      {' '}
      <span>SEAZONE</span>
      , a fim de possibilitar a oferta dos
      {' '}
      <span>Serviços Seazone</span>
      .
    </Text>
    <Text>
      <span>1.2.</span>
      {' '}
      As indicações do
      {' '}
      <span>PARCEIRO</span>
      {' '}
      conterão as seguintes informações, a serem entregues ao executivo de parcerias da
      {' '}
      <span>SEAZONE</span>
      :
    </Text>
    <ListContainer>
      <ItemList>
        Localização dos imóveis;
      </ItemList>
      <ItemList>
        Quantidade de quartos dos imóveis;
      </ItemList>
      <ItemList>
        Nome completo e informações de contato dos
        {' '}
        <span>Proprietários</span>
        .
      </ItemList>
    </ListContainer>
    <Title>CLÁUSULA SEGUNDA – DOS DIREITOS E OBRIGAÇÕES</Title>
    <Text>
      <span>2.1</span>
      {' '}
      Por este instrumento, o
      {' '}
      <span>PARCEIRO</span>
      {' '}
      obriga-se a:
    </Text>
    <ListContainer>
      <ItemList>
        <ItemList>
          Não agir em desacordo com as instruções fornecidas pela
          {' '}
          <span>SEAZONE</span>
          , sendo vedada a concessão de qualquer abatimento, desconto ou prorrogação de prazos de pagamento sem prévia e expressa autorização desta;
        </ItemList>
        <ItemList>
          Disponibilizar à
          {' '}
          <span>SEAZONE</span>
          {' '}
          todas as informações necessárias à execução do serviço objeto desta Parceria;
        </ItemList>
        <ItemList>
          Não exercer, durante a vigência da Parceria, quaisquer atividades que possam concorrer direta ou indiretamente com as atividades desempenhadas pela
          {' '}
          <span>SEAZONE</span>
          {' '}
          ou que possam prejudicar a comercialização dos
          {' '}
          <span>Serviços Seazone</span>
          ;
        </ItemList>
        <ItemList>
          Não realizar qualquer cópia ou reprodução total ou parcial dos
          {' '}
          <span>Serviços Seazone</span>
          {' '}
          sem a devida autorização da
          {' '}
          <span>SEAZONE</span>
          ;
        </ItemList>
        <ItemList>
          Não realizar negociação referente aos
          {' '}
          <span>Serviços Seazone</span>
          {' '}
          com os
          {' '}
          <span>Proprietários</span>
          {' '}
          sem o prévio e expresso consentimento da
          {' '}
          <span>SEAZONE</span>
          ;
        </ItemList>
        <ItemList>
          Ser integralmente responsável pelas suas ações ou omissões, obrigando-se a reparar, exclusivamente às suas custas, todos os defeitos, erros, falhas e quaisquer outras irregularidades verificadas na execução dos serviços, bem como ressarcir a
          {' '}
          <span>SEAZONE</span>
          {' '}
          pelas perdas e danos diretos a que der causa;
        </ItemList>
        <ItemList>Tratar como sigilosas e confidenciais todas as informações escritas ou orais a que tiver acesso, documentos e demais materiais que contenham informações técnicas, econômicas ou de mercado, não disponibilizando tais informações a quaisquer terceiros, salvo autorização escrita em contrário, independentemente de ter sido o status de confidencialidade indicado oralmente ou por escrito.</ItemList>
      </ItemList>
    </ListContainer>
    <Text>
      <span>2.2.</span>
      {' '}
      A
      {' '}
      <span>SEAZONE</span>
      , por meio deste instrumento, obriga-se a:
    </Text>
    <ListContainer>
      <ItemList>
        Fornecer todas as informações, diretrizes e documentos necessários ao fiel cumprimento da Parceria;
      </ItemList>
      <ItemList>
        Realizar os pagamentos devidos ao
        {' '}
        <span>PARCEIRO</span>
        , na forma estipulada em Cláusula 3;
      </ItemList>
      <ItemList>
        Apoiar as atividades desenvolvidas pelo
        {' '}
        <span>PARCEIRO</span>
        , agindo de boa-fé e não intervindo nos serviços por ele prestados, bem como repassando instruções claras e efetivas, a fim de possibilitar uma melhor execução do objeto da presente Parceria;
      </ItemList>
      <ItemList>
        Ser integralmente responsável pelas suas ações ou omissões, obrigando-se a reparar, exclusivamente às suas custas, todos os defeitos, erros, falhas e quaisquer outras irregularidades verificadas na execução dos serviços, bem como ressarcir o
        {' '}
        <span>PARCEIRO</span>
        {' '}
        pelas perdas e danos diretos a que der causa.
      </ItemList>
    </ListContainer>
    <Text>
      <span>2.3.</span>
      {' '}
      A
      {' '}
      <span>SEAZONE</span>
      {' '}
      terá plena autonomia e poder decisório em relação a todos os aspectos que envolvam o seu modelo de negócio de locação por temporada, sendo certo que o
      {' '}
      <span>PARCEIRO</span>
      {' '}
      não terá qualquer influência sobre as decisões tomadas pela
      {' '}
      <span>SEAZONE</span>
      {' '}
      no âmbito estratégico da empresa.
    </Text>
    <Title>CLÁUSULA TERCEIRA – DAS COMISSÕES</Title>
    <Text>
      <span>3.1.</span>
      {' '}
      O
      {' '}
      <span>PARCEIRO</span>
      {' '}
      receberá da
      {' '}
      <span>SEAZONE</span>
      {' '}
      as seguintes comissões:
    </Text>
    <ListContainer>
      <ItemList>
        Como regra, o
        {' '}
        <span>PARCEIRO</span>
        {' '}
        poderá optar por receber a porcentagem de
        {' '}
        <span>2% (dois por cento)</span>
        {' '}
        sobre o faturamento dos imóveis dos
        {' '}
        <span>Proprietários</span>
        , durante o primeiro ano de faturamento destes, de acordo com os valores das reservas realizadas durante o período, ou o repasse da quantia de
        {' '}
        <span>R$400,00 (quatrocentos reais)</span>
        {' '}
        no momento de ativação de cada imóvel;
      </ItemList>
      <ItemList>
        Caso o
        {' '}
        <span>PARCEIRO</span>
        {' '}
        contabilize
        {' '}
        <span>3 (três) ganhos</span>
        {' '}
        dentro de um período de 180 (cento e oitenta) dias, este passará a receber a comissão de
        {' '}
        <span>2% (dois por cento)</span>
        {' '}
        sobre o faturamento dos imóveis dos
        {' '}
        <span>Proprietários</span>
        , de acordo com os valores das reservas realizadas durante o período, ou o repasse da quantia de
        {' '}
        <span>R$600,00 (seiscentos reais)</span>
        {' '}
        no momento de ativação de cada imóvel;
      </ItemList>
      <ItemList>
        Na hipótese do
        {' '}
        <span>PARCEIRO</span>
        {' '}
        não contabilizar, em períodos subsequentes de 180 (cento e oitenta) dias, os
        {' '}
        <span>3 (três) ganhos</span>
        {' '}
        dispostos em alínea acima, cessará a aplicação das condições dispostas em alínea “b”, aplicando-se as determinações estabelecidas em alínea “a”.
      </ItemList>
    </ListContainer>
    <Text>
      <span>3.2.</span>
      {' '}
      O
      {' '}
      <span>PARCEIRO</span>
      {' '}
      declara-se ciente de que a política de
      {' '}
      <span>ganhos</span>
      {' '}
      pode ser alterada e/ou suprimida a qualquer tempo pela
      {' '}
      <span>SEAZONE</span>
      , mediante prévio envio de comunicado ao
      {' '}
      <span>PARCEIRO</span>
      , não atingindo os contratos firmados e assinados antes da alteração.
    </Text>
    <Text>
      <span>3.3.</span>
      {' '}
      Os valores referentes às comissões serão acumulados a cada mês, e o repasse dependerá exclusivamente de solicitação pelo
      {' '}
      <span>PARCEIRO</span>
      , não havendo repasse mensal obrigatório pela
      {' '}
      <span>SEAZONE</span>
      {' '}
      das comissões devidas.
    </Text>
    <div style={{ paddingLeft: '2rem' }}>
      <Text>
        <span>3.3.1.</span>
        {' '}
        O pedido de pagamento será realizado via formulário de solicitação apresentado pelo
        {' '}
        <span>PARCEIRO</span>
        {' '}
        à
        {' '}
        <span>SEAZONE</span>
        , com as notas fiscais em anexo, devendo ser enviado ao seguinte endereço eletrônico:
        {' '}
        <a href="mailto:administrativo@seazone.com.br">administrativo@seazone.com.br</a>
        .
      </Text>
      <div style={{ paddingLeft: '2rem' }}>
        <Text>
          <span>3.3.1.1.</span>
          {' '}
          Não serão aceitas notas fiscais que declarem ou apresentem o CNAE referente à atividade de corretagem de imóveis.
        </Text>
      </div>
      <Text>
        <span>3.3.2.</span>
        {' '}
        Após apresentação do pedido de pagamento pelo
        {' '}
        <span>PARCEIRO</span>
        , a
        {' '}
        <span>SEAZONE</span>
        {' '}
        fará o lançamento cumulativo do saldo de créditos de comissão disponíveis, no prazo de 7 (sete) dias após a solicitação.
      </Text>
      <div style={{ paddingLeft: '2rem' }}>
        <Text>
          <span>3.3.2.1.</span>
          {' '}
          Caso a quantia constante em pedido de pagamento seja inferior ao saldo de comissões disponível, a
          {' '}
          <span>SEAZONE</span>
          {' '}
          realizará o repasse do valor descrito no pedido, mantendo o saldo remanescente como crédito em favor do
          {' '}
          <span>PARCEIRO</span>
          .
        </Text>
      </div>
    </div>
    <Text>
      <span>3.4.</span>
      {' '}
      Após o período de 12 (doze) meses, contados da assinatura de cada Contrato de Prestação de Serviços firmado entre a
      {' '}
      <span>SEAZONE</span>
      {' '}
      e proprietário indicado pelo
      {' '}
      <span>PARCEIRO</span>
      , este terá um prazo de 12 (doze) meses para solicitar o pagamento dos créditos de comissão acumulados. Findo o prazo, o
      {' '}
      <span>PARCEIRO</span>
      {' '}
      perderá o direito aos créditos de comissão referentes ao imóvel do respectivo proprietário.
    </Text>
    <Text>
      <span>3.5.</span>
      {' '}
      O
      {' '}
      <span>PARCEIRO</span>
      {' '}
      não terá direito às comissões descritas em Cláusula 3.1 caso ocorram as seguintes situações:
    </Text>
    <ListContainer>
      <ItemList>
        Caso a venda e/ou execução dos
        {' '}
        <span>Serviços Seazone</span>
        , após indicação do cliente pelo
        {' '}
        <span>PARCEIRO</span>
        , não se concretize;
      </ItemList>
      <ItemList>
        Por eventual insolvência do proprietário.
      </ItemList>
    </ListContainer>
    <Title>CLÁUSULA QUARTA - DA VIGÊNCIA</Title>
    <Text>
      <span>4.1.</span>
      {' '}
      A presente
      {' '}
      Parceria
      {' '}
      tem vigência de 12 (doze) meses, que se iniciará na data de assinatura deste instrumento.
    </Text>
    <div style={{ paddingLeft: '2rem' }}>
      <Text>
        <span>4.1.1.</span>
        {' '}
        Ultrapassada a vigência e sem manifestação contrária por qualquer uma das
        {' '}
        <span>PARTES</span>
        , o presente instrumento será automaticamente renovado por igual período.
      </Text>
    </div>
    <Text>
      <span>4.2.</span>
      {' '}
      Para a não renovação da
      {' '}
      Parceria
      , o
      {' '}
      <span>PARCEIRO</span>
      {' '}
      deverá informar a
      {' '}
      <span>SEAZONE</span>
      {' '}
      com no mínimo 30 (trinta) dias de antecedência.
    </Text>
    <Title>CLÁUSULA QUINTA - DA NÃO EXCLUSIVIDADE</Title>
    <Text>
      <span>5.1.</span>
      {' '}
      Não haverá exclusividade na área de atuação do
      {' '}
      <span>PARCEIRO</span>
      , sendo permitido à
      {' '}
      <span>SEAZONE</span>
      {' '}
      firmar outras parcerias na mesma região, preservando, no entanto, a exclusividade do
      {' '}
      <span>PARCEIRO</span>
      {' '}
      aos
      {' '}
      <span>Proprietários</span>
      {' '}
      por ele já atendidos.
    </Text>
    <Title>CLÁUSULA SEXTA - DA RESCISÃO</Title>
    <Text>
      <span>6.1.</span>
      {' '}
      As
      {' '}
      <span>PARTES</span>
      {' '}
      poderão, a qualquer momento, rescindir o presente instrumento, devendo a parte rescindente notificar a outra com 30 (trinta) dias de antecedência.
    </Text>
    <Text>
      <span>6.2.</span>
      {' '}
      Ocorrendo justo motivo para rescisão pela
      {' '}
      <span>SEAZONE</span>
      , conforme as hipóteses previstas em Cláusula 6.3, esta poderá reter as comissões não repassadas ao
      {' '}
      <span>PARCEIRO</span>
      , com o fim de ressarcir-se de eventuais perdas e danos por ele causados.
    </Text>
    <Text>
      <span>6.3.</span>
      {' '}
      O presente instrumento também será rescindido de pleno direito nos seguintes casos, sem prejuízo das penalidades, se assim convier à parte não infratora:
    </Text>
    <ListContainer>
      <ItemList>
        Pelo não cumprimento de qualquer obrigação das
        {' '}
        <span>PARTES</span>
        ;
      </ItemList>
      <ItemList>
        Por insolvência, impetração ou solicitação de recuperação judicial ou extrajudicial ou falência;
      </ItemList>
      <ItemList>
        Por inadimplemento contratual.
      </ItemList>
    </ListContainer>
    <Text>
      <span>6.4.</span>
      {' '}
      A
      {' '}
      <span>SEAZONE</span>
      {' '}
      poderá, ainda, rescindir a presente
      {' '}
      Parceria
      {' '}
      caso, durante o período de 12 (doze) meses, não ocorram novas indicações pelo
      {' '}
      <span>PARCEIRO</span>
      .
    </Text>
    <Title>CLÁUSULA SÉTIMA - DA PROPRIEDADE INTELECTUAL</Title>
    <Text>
      <span>7.1.</span>
      {' '}
      O
      {' '}
      <span>PARCEIRO</span>
      {' '}
      concorda que os direitos patrimoniais e todos e quaisquer outros direitos de propriedade intelectual e industrial relativos às tecnologias de cada um dos
      {' '}
      <span>PARCEIROS</span>
      {' '}
      são e permanecerão sendo de propriedade exclusiva de cada uma delas, sendo que esta
      {' '}
      Parceria
      em nenhuma hipótese implicará em transferência de tecnologia de uma Parte à outra, no todo ou em parte.
    </Text>
    <Text>
      <span>7.2.</span>
      {' '}
      Sem prejuízo do disposto no item 7.1, os direitos de propriedade intelectual e os direitos intelectuais e industriais que resultarem dos trabalhos desenvolvidos na vigência desta parceria, e os resultados técnicos deles decorrentes, inclusive invento, aperfeiçoamento ou inovação, criação, obtenção de processo ou produto, privilegiados ou não, pertencerão à
      {' '}
      <span>SEAZONE</span>
      .
    </Text>
    <Title>CLÁUSULA OITAVA – PROTEÇÃO DE DADOS PESSOAIS: </Title>
    <Text>
      <span>8.1.</span>
      {' '}
      Os
      {' '}
      <span>PARCEIROS</span>
      {' '}
      compartilharão dados pessoais entre si para fins de cumprimento do objeto desta
      {' '}
      Parceria
      . Sendo assim, as informações compartilhadas serão utilizadas exclusivamente para cumprir com o objeto deste instrumento, que foi estipulado em observância da Lei Federal nº 13.709.2018 (“LGPD”).
    </Text>
    <Text>
      <span>8.2.</span>
      {' '}
      Todas as informações que serão compartilhadas para permitir o cumprimento do objeto desta
      {' '}
      Parceira
      {' '}
      devem ser coletadas em observância da LGPD, garantindo a existência de legítima expectativa do titular dos dados, devendo estes serem devidamente informados sobre o compartilhamento, permitindo que os titulares exerçam os direitos garantidos pela LGPD.
    </Text>
    <Text>
      <span>8.3.</span>
      {' '}
      Os
      {' '}
      <span>PARCEIROS</span>
      {' '}
      se obrigam a cumprir rigorosamente com todos os regulamentos e leis em vigor no Brasil sobre proteção de dados, em especial a LGPD.
    </Text>
    <Title>
      CLÁUSULA NONA - DA NÃO CONTRATAÇÃO DE PROFISSIONAIS
    </Title>
    <Text>
      <span>9.1.</span>
      {' '}
      Na vigência do presente
      {' '}
      Contrato
      {' '}
      e pelo prazo de 1 (um) ano após o término deste, o
      {' '}
      <span>PARCEIRO</span>
      {' '}
      não poderá contratar de forma direta, ou através de terceiros, os funcionário(s), colaborador(es) e/ou prestador(es) de serviço da
      {' '}
      <span>SEAZONE</span>
      {' '}
      que, direta ou indiretamente, participaram do projeto, nem, tampouco, lhe(s) fazer proposta(s) de trabalho, salvo mediante prévia e expressa autorização, por escrito.
    </Text>
    <Text>
      <span>9.2.</span>
      {' '}
      Em caso de não cumprimento dessa obrigação de não fazer, ficará resolvido de pleno direito o
      {' '}
      Contrato
      , devendo a Parte infratora indenizar a outra no equivalente a 24 (vinte e quatro) vezes a última remuneração percebida pelo(s) funcionário(s), colaborador(es), e/ou prestador(es) de serviço assediado(s) e/ou retirado(s) da equipe da Parte inocente, além da indenização por perdas e danos provenientes da resolução contratual motivada.
    </Text>
    <Title>CLÁUSULA DÉCIMA - DA INDEPENDÊNCIA DAS PARTES E DA INEXISTÊNCIA DE VÍNCULO EMPREGATÍCIO</Title>
    <Text>
      <span>10.1.</span>
      {' '}
      Cada um dos
      {' '}
      <span>PARCEIROS</span>
      {' '}
      agirá e será um contratado independente em todos os aspectos de sua atuação no
      {' '}
      Projeto
      . Nenhum dos
      {' '}
      <span>PARCEIROS</span>
      {' '}
      agirá ou terá autoridade para agir em nome da outra Parte como seu agente por qualquer motivo. Nada constituirá uma Parte como um agente da outra ou conferirá à outra a autoridade para fazer declarações ou contratar em nome da outra Parte.
    </Text>
    <Text>
      <span>10.2.</span>
      {' '}
      Os
      {' '}
      <span>PARCEIROS</span>
      {' '}
      acordam que não há qualquer vínculo empregatício entre os prepostos, empregados e/ou contratados de um dos
      {' '}
      Parceiros
      {' '}
      para com o outro, obrigando-se, expressamente, a responderem por todos os encargos de natureza que forem decorrentes da Legislação Trabalhista, Previdência Social e Acidentes de Trabalho, de acordo com as leis vigentes, e da relação que tenha com seus empregados e/ou contratados, bem como assumir o polo passivo de qualquer medida judicial de caráter trabalhista ou previdenciário que qualquer um de seus empregados e/ou contratados mova contra a outra Parte, ficando esta última excluída do processo, e tendo ainda o direito de regresso de quaisquer verbas que porventura seja condenada a pagar.
    </Text>
    <Text>
      <span>10.3.</span>
      {' '}
      Os
      {' '}
      <span>PARCEIROS</span>
      {' '}
      deverão imediatamente notificar a outra Parte assim que tomarem conhecimento de qualquer procedimento judicial ou administrativo que envolva o presente instrumento, de forma a propiciar o conhecimento e, eventualmente, a confecção de defesa quanto ao procedimento.
    </Text>
    <Text>
      <span>10.4.</span>
      {' '}
      Cada um dos
      {' '}
      <span>PARCEIROS</span>
      {' '}
      assume a responsabilidade em todos os âmbitos pelos serviços que prestar, respondendo exclusivamente por eventuais danos ou prejuízos que, por sua culpa ou dolo, venham causar, direta ou indiretamente, a todos que aderirem seus serviços, arcando, isoladamente, com as indenizações decorrentes.
    </Text>
    <Title>CLÁUSULA DÉCIMA PRIMEIRA – DAS DISPOSIÇÕES GERAIS</Title>
    <Text>
      <span>11.1.</span>
      {' '}
      A celebração do presente instrumento não implica em nenhuma espécie de sociedade, associação, solidariedade obrigacional, nem em qualquer responsabilidade direta ou indireta, seja societária, comercial, tributária, trabalhista, previdenciária ou de qualquer outra natureza, nem em alienação ou sucessão, seja entre as partes, seus empregados ou prepostos, seja perante terceiros, estando preservada a autonomia jurídica e funcional de cada uma das partes.
    </Text>
    <Text>
      <span>11.2.</span>
      {' '}
      Toda e qualquer omissão, tolerância, ou autorização, por parte da
      {' '}
      <span>SEAZONE</span>
      , quanto ao descumprimento ou cumprimento parcial das obrigações ora assumidas pelo
      {' '}
      <span>PARCEIRO</span>
      , será considerada como mera liberalidade, não gerando precedente nem direito adquirido de qualquer espécie à Parte faltosa, sendo que as ocasionais concessões efetuadas por aquela a esta serão consideradas eventos isolados, não importando em qualquer alteração ou novação dos termos pactuados no presente instrumento.
    </Text>
    <Text>
      <span>11.3.</span>
      {' '}
      Os
      {' '}
      <span>PARCEIROS</span>
      {' '}
      reconhecem a validade da assinatura do presente contrato por meios eletrônicos que possibilitem a autenticação das mesmas, tal como possibilita o §2º do art. 10 da MP n. 2.200-2/01.
    </Text>
    <Text>
      <span>11.4.</span>
      {' '}
      Caso qualquer termo ou condição deste instrumento seja, por qualquer razão, reputado inválido ou ineficaz, permanecerão plenamente válidos e vinculantes todos os termos e condições restantes, gerando efeitos em sua máxima extensão, como forma de alcançar a vontade dos
      {' '}
      <span>PARCEIROS</span>
      .
    </Text>
    <Text>
      <span>11.5.</span>
      {' '}
      Para dirimir questões oriundas da presente
      {' '}
      Parceria
      , as partes elegem o Foro da Comarca de Florianópolis, Santa Catarina, com renúncia expressa aos demais, por mais privilegiados que sejam.
    </Text>
  </Container>
);

export default Terms;
