import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 90vh;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  width: 95%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const ContainerInformation = styled.div`
  width: 100%;
  height: auto;

  position: fixed;
  top: 50%;
  left: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Information = styled.h2`
  color: ${({ theme }) => theme.palette.grey._440.hex()};
  font-size: 1.8rem;
`;
