import React from 'react';
import { ICard } from '../types';
import { useUser } from '../../../../../../../../../../context/UserContext';
import { analyticsEvent } from '../../../../../../../../utils/analytics';
import { ShareContainer } from '../style';
import { WhatsApp } from '../icons';

interface IShareButton {
  title: ICard['title'];
  withShareButton: ICard['withShareButton'];
}

const ShareButton = ({ title, withShareButton }: IShareButton) => {
  const { userInformation } = useUser();

  const handleClick = () => {
    if (withShareButton?.is) {
      analyticsEvent({
        name: `Compartilhou pelo whatsapp o card: ${title} - na sessão: CMS`,
        userInformation,
      });

      const link = `https://api.whatsapp.com/send?text=${withShareButton.link}`;
      window.open(link);
    }
  };

  return (
    <ShareContainer onClick={handleClick}>
      <h1>Compartilhar</h1>
      <WhatsApp />
    </ShareContainer>
  );
};

export default ShareButton;
