import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  .content-income-future {
    margin-top: 3rem;
  }
`;

export const Content = styled.div``;

export const Divider = styled.div<{ isDashed?: boolean }>`
  border-top: 0.5px ${({ isDashed }) => (isDashed ? 'dashed' : 'solid')} ${({ theme }) => theme.palette.grey._305.hex()};
  margin: 0 1rem;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;

  span {
    width: 100%;
    max-width: fit-content;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.19rem;
  }
`;

export const SummaryContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.5rem;
  margin-left: 3.5rem;

  .origin-platform {
    width: 100%;
    max-width: fit-content;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 17px;
  }

  @media(max-width: 768px) {
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
`;

export const SummaryContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0 1rem;

  .range-dates, .refund-reason {
    width: 100%;
    max-width: fit-content;
    strong, span {
      color: ${({ theme }) => theme.palette.grey._680.hex()};
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 0.938rem;
      line-height: 1.2rem;
      font-style: normal;
    }

    span {
      color: ${({ theme }) => theme.palette.grey._810.hex()};
      font-weight: 400;
    }
  }

  .total-value {
    width: 100%;
    max-width: fit-content;
    margin-right: 3.5rem;

    span {
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  @media(max-width: 768px) {
    margin: 0.5rem 0 0 0.5rem;

    .total-value {
      margin-right: 1rem;
    }
  }
`;

export const WrapperBox = styled.div`
  width: 95%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5rem;
  margin-left: 2rem;

  background: ${({ theme }) => theme.palette.grey._300.hex()};

  strong {
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-left: 1rem;
  }
  
  @media(max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: justify;
    margin-left: 0rem;
    height: 50px;

    strong {
      line-height: 0.975rem;
      margin: 0.2rem;
    }
  }
`;
