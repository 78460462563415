import React from 'react';

import {
  Backdrop, Container, Content, GridContent,
} from './style';

import { Item } from './types';
import ItemComponent from './ItemComponent';

interface ICard {
  items?: Item[];
  children?: React.ReactNode;
  desktopCustomColumns?: string;
  style?: React.CSSProperties | undefined;
}

const Card = ({
  style, items, children, desktopCustomColumns,
}: ICard) => (
  <Container style={style}>
    <Backdrop />

    <Content>
      <GridContent desktopCustomColumns={desktopCustomColumns}>
        {items?.map((item) => (
          <ItemComponent key={item.id} item={item} />
        ))}
      </GridContent>

      {children || null}
    </Content>
  </Container>
);

Card.defaultProps = {
  items: undefined,
  children: undefined,
  desktopCustomColumns: undefined,
  style: undefined,
};

export default Card;
