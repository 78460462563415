import { FC, MouseEventHandler, useMemo } from 'react';

import calendarIcon from '../../../../assets/icons/ownerPage/calendarIcon.svg';
import houseIcon from '../../../../assets/icons/ownerPage/houseIcon.svg';

import { useMobile } from '../../../../hooks/useMobile/useMobile';
import { useOwnerExtract } from '../../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';

import { getMonthName } from '../../../../utils/Date';

import {
  Container,
  HeaderTitleContainer,
  HeaderContent,
  CloseOutlinedIcon,
  HeaderContainer,
  Title,
  TitleInfo,
  ButtonDownload,
  DateHourContainer,
  DateHourContent,
} from './styles';

interface Props {
  setOpenModal: Function;
  handleClickDownload: MouseEventHandler<HTMLButtonElement> | undefined,
}

const Header: FC<Props> = ({
  setOpenModal,
  handleClickDownload,
}) => {
  const { isMobile } = useMobile();

  const {
    showExtractDate,
    extractGeneratedIn,
    period,
    property,
  } = useOwnerExtract();

  const monthSelected = useMemo(() => getMonthName[period.getMonth()], [period]);
  const yearSelected = useMemo(() => period.getFullYear(), [period]);

  return (
    <Container>
      <DateHourContainer showExtractDate={showExtractDate}>
        <DateHourContent>
          <strong>Extrato emitido em:</strong>
          {` ${extractGeneratedIn}`}
        </DateHourContent>
      </DateHourContainer>

      <CloseOutlinedIcon onClick={() => setOpenModal(false)} />
      <HeaderContainer>
        <HeaderTitleContainer>
          <Title>
            Extrato Financeiro
          </Title>
          <div className="download-button">
            <ButtonDownload
              id="download-financial-statement-button-header"
              type="button"
              onClick={handleClickDownload}
            >
              {`${!isMobile ? 'Baixar extrato' : 'Baixar'}`}
            </ButtonDownload>
          </div>
        </HeaderTitleContainer>
        <HeaderContent>
          <TitleInfo>
            <img src={calendarIcon} alt="Ícone de calendário" />
            <span>
              Período:
            </span>
            <h1>{`${monthSelected}/${yearSelected}`}</h1>
          </TitleInfo>
          <TitleInfo>
            <img src={houseIcon} alt="Ícone de casa" />
            <span>
              Imóvel:
            </span>
            <h1>{property.code}</h1>
          </TitleInfo>
        </HeaderContent>
      </HeaderContainer>
    </Container>
  );
};
export default Header;
