import styled from 'styled-components';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.familys._3} !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 41px !important;
  letter-spacing: -0.02em !important;
  text-align: center;
  margin-bottom: 18px;
`;

export const Container = styled.form`
  width: 453px;
  height: 249px;
  padding: 26px 42px;
  background: #FFFFFF;
  border-radius: 10px;
  label {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: 700 !important;
  }

  .button-upload {
    width: 250px;
    margin-top: 32px;

    .custom-file-input::-webkit-file-upload-button {
      display: inline-block;
      background: ${({ theme }) => theme.palette.blue._930.hex()};
      width: 150px;
      height: 40px;
      border: none;
      border-radius: 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      color: #fff;
      font-weight: 700;
      font-size: 10pt;
    }


    svg {
      margin-left: 8px;
    }
  }
`;

export const ButtonSave = styled.button`
  background-color: ${({ theme }) => theme.palette.blue._850.hex()};
  width: 50%;
  height: 40px;
  border-radius: 10px;
  border: none;
  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  :hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
`;

export const ButtonCancel = styled.button`
  background: #EBEBF5;
  width: 50%;
  height: 40px;
  margin-right: 15px;
  border-radius: 10px;
  border: 1px solid #D9DCDF;
  color: #B5B5B5;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  :hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;

export const ContentButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 50px;
`;
