import React, { FC } from 'react';

import { motion } from 'framer-motion';
import { Container } from './style';

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -100 },
};

interface Props {
  useMinimalAnimations?: boolean;
  useHouseOnly?: boolean;
}

const AnimatedLogo: FC<Props> = ({
  useMinimalAnimations,
  useHouseOnly,
}) => {
  const fullAnimated = !useMinimalAnimations;

  if (useHouseOnly) {
    return (
      <Container
        whileTap={{
          scale: 0.9,
        }}
      >
        <motion.svg xmlns="http://www.w3.org/2000/svg" width="227" height="233" viewBox="0 0 227 233" fill="none">
          <motion.path
            id="A"
            initial={{
              scale: 0,
              rotate: 360,
              opacity: 0,
            }}
            animate={{
              scale: 1,
              rotate: 0,
              opacity: 1,
            }}
            transition={{
              delay: fullAnimated ? 0.75 : 0,
            }}
            className="cls-1"
            d="M218.156 71.93L177.866 48.2C178.018 47.6521 178.132 47.094 178.206 46.53V10.73C178.203 7.88331 177.07 5.15412 175.056 3.14214C173.042 1.13016 170.312 3.4263e-06 167.466 4.66026e-06H145.986C144.576 -0.00130934 143.179 0.275261 141.876 0.813905C140.573 1.35255 139.389 2.14271 138.392 3.13921C137.394 4.13571 136.603 5.31902 136.063 6.6215C135.523 7.92398 135.246 9.32008 135.246 10.73V23.25L120.626 14.68C117.651 12.9813 114.247 12.1838 110.828 12.3844C107.408 12.585 104.121 13.7752 101.366 15.81C101.276 15.81 101.176 15.81 101.096 15.92L8.41558 69.69C0.685581 74.29 -2.5344 85.02 2.2456 93.05C4.63308 96.9408 8.44563 99.7454 12.8708 100.866C17.296 101.987 21.9839 101.335 25.9356 99.05L31.4856 95.84C24.5397 109.17 20.8846 123.969 20.8256 139C20.8256 164 30.4456 188.71 48.1756 206.5C55.1576 213.522 63.2708 219.319 72.1756 223.65C79.004 227.099 86.266 229.611 93.7656 231.12C103.716 232.94 114.036 232 124.116 232.04C134.989 232.04 145.866 232.04 156.746 232.04C166.606 232.04 176.456 232.11 186.426 232.12C188.918 232.105 191.371 231.502 193.586 230.36C196.146 229.086 198.341 227.183 199.966 224.83C202.205 222.03 203.469 218.575 203.566 214.99V102.32C210.956 104.99 219.916 102.68 224.326 95.32C229.086 87.26 225.886 76.53 218.156 71.93ZM171.796 140.81C171.796 175.06 148.606 202.92 113.606 202.92C77.9156 202.92 52.9356 173.29 52.9356 139.02C52.9356 104.75 78.6356 76.2 113.606 76.2C147.886 76.2 171.796 102.64 171.796 136.53V140.81Z"
            fill="black"
          />
        </motion.svg>
      </Container>
    );
  }

  return (
    <Container
      whileTap={{
        scale: 0.9,
      }}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1404.71 232.27"
      >
        <motion.g id="Camada_2" data-name="Camada 2">
          <motion.g
            initial="hidden"
            animate="visible"
            variants={fullAnimated ? list : item}
            data-name="Camada 1"
          >
            <motion.path
              id="S"
              className="cls-1"
              variants={item}
              d="M33.22,96.56c0-10.37,12.48-21.42,38.55-21.42,17.83,0,33.19,8.57,40.32,15.7,3.58,3.58,7.51,7.15,13.22,7.15a14.87,14.87,0,0,0,15.34-14.63c0-14.29-24.63-36.79-68.53-36.79C30.72,46.57,1.8,70.51,1.8,99.41c0,64.6,113.87,42.11,113.87,80.65,0,13.59-17.49,23.21-42.12,23.21-21.06,0-36.06-7.51-46.4-18.55C24.64,182.21,20.71,179,15,179A14.67,14.67,0,0,0,0,194c0,13.2,26.44,38.2,73.19,38.2,39.62,0,75.67-20.35,75.67-55C148.86,113,33.22,131.88,33.22,96.56Z"
            />
            <motion.path
              id="E"
              variants={item}
              className="cls-1"
              d="M267.17,46.57c-50,0-92.44,40.7-92.44,92.45,0,53.17,40.7,93.15,94.23,93.15a118.53,118.53,0,0,0,60.33-16.41,16,16,0,0,0,7.5-13.55,14.27,14.27,0,0,0-14.64-14.64,19.28,19.28,0,0,0-8.57,2.12c-9.63,5.36-25,13.23-44.62,13.23-30.69,0-56.4-19.29-60.68-49.61H335c11.06,0,20-10.72,20-21.79C355,88.69,318.22,46.57,267.17,46.57Zm-58.89,79.24c2.86-28.53,28.91-50.67,58.89-50.67,30.7,0,54.62,26.41,57.13,50.67Z"
            />
            <motion.path
              id="A"
              initial={{
                scale: 0,
                rotate: 360,
                opacity: 0,
              }}
              animate={{
                scale: 1,
                rotate: 0,
                opacity: 1,
              }}
              transition={{
                delay: fullAnimated ? 0.75 : 0,
              }}
              className="cls-1"
              d="M599.07,71.93,558.78,48.2a12.06,12.06,0,0,0,.34-1.67V10.73A10.74,10.74,0,0,0,548.38,0H526.9a10.73,10.73,0,0,0-10.74,10.73V23.25l-14.62-8.57a17.67,17.67,0,0,0-19.26,1.13c-.09,0-.19,0-.27.11L389.33,69.69c-7.73,4.6-10.95,15.33-6.17,23.36a17.51,17.51,0,0,0,23.69,6l5.55-3.21A94.2,94.2,0,0,0,401.74,139c0,25,9.62,49.71,27.35,67.5a88.32,88.32,0,0,0,24,17.15,85.15,85.15,0,0,0,21.59,7.47c9.95,1.82,20.27.88,30.35.92q16.31,0,32.63,0c9.86,0,19.71.07,29.68.08a15.82,15.82,0,0,0,7.16-1.76,16.9,16.9,0,0,0,6.38-5.53,16.46,16.46,0,0,0,3.6-9.84V102.32c7.39,2.67,16.35.36,20.76-7C610,87.26,606.8,76.53,599.07,71.93Zm-46.36,68.88c0,34.25-23.19,62.11-58.19,62.11-35.69,0-60.67-29.63-60.67-63.9s25.7-62.82,60.67-62.82c34.28,0,58.19,26.44,58.19,60.33Z"
            />
            <motion.path
              variants={item}
              id="Z"
              className="cls-1"
              d="M771.64,200.36H679L778.67,80.67c4.42-5.17,6.68-8.87,6.68-15.56a18.64,18.64,0,0,0-18.93-18.54h-113c-9.64,0-16.69,6.3-16.69,15.56,0,9.67,7,15.95,16.69,15.95H739.4l-98.93,119.3c-5.19,6.3-7.06,9.63-7.06,15.56a18.75,18.75,0,0,0,18.92,18.89H771.64c9.62,0,17.05-6.29,17.05-15.17C788.69,207.39,781.26,200.36,771.64,200.36Z"
            />
            <motion.path
              variants={item}
              id="o"
              className="cls-1"
              d="M895.79,46.59c-52.13,0-93.15,40.68-93.15,92.44s40.31,93.14,93.15,93.14A93.29,93.29,0,0,0,989.29,139C989.29,87.63,947.54,46.59,895.79,46.59Zm0,156.32c-35.35,0-61.75-30-61.75-63.88s27.49-62.82,61.75-62.82c34.6,0,61.37,28.91,61.37,62.82S930.75,202.91,895.79,202.91Z"
            />
            <motion.path
              variants={item}
              id="n"
              className="cls-1"
              d="M1113.77,47A370,370,0,0,0,1074,45.81c-12.71.16-25.61-.23-38.3.34a21.74,21.74,0,0,0-8.56,1.94,18.15,18.15,0,0,0-1.9,1,20.66,20.66,0,0,0-6.94,6.76c-2,3.25-2,6.24-1.81,9.92.17,3.45,0,7,0,10.43q-.06,18.92-.11,37.86c0,8.05-.1,100.94-.1,100.94,0,8.91,7.85,17.15,17.11,17.15s17.15-8.24,17.15-17.15v-79.2c0-36.43,26.77-59.29,56.38-59.29s56.4,22.86,56.4,59.29V215c0,8.91,7.52,17.15,16.79,17.15s17.48-8.24,17.48-17.15V138.32C1197.53,90.93,1159.92,51.35,1113.77,47Z"
            />
            <motion.path
              variants={item}
              id="e"
              className="cls-1"
              d="M1404.71,131.52c0-42.83-36.78-85-87.83-85-50,0-92.42,40.7-92.42,92.45,0,53.17,40.66,93.15,94.21,93.15A118.58,118.58,0,0,0,1379,215.76a16,16,0,0,0,7.49-13.55,14.27,14.27,0,0,0-14.64-14.64,19.32,19.32,0,0,0-8.57,2.12c-9.63,5.36-25,13.23-44.62,13.23-30.7,0-56.38-19.29-60.66-49.61H1384.7C1395.78,153.31,1404.71,142.59,1404.71,131.52ZM1258,125.81c2.85-28.53,28.91-50.67,58.87-50.67,30.72,0,54.62,26.41,57.14,50.67Z"
            />
          </motion.g>
        </motion.g>
      </motion.svg>
    </Container>
  );
};

export default AnimatedLogo;
