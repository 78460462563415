/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, {
  useState,
  useEffect,
} from 'react';

import {
  Container,
  FormContainer,
  Content,
  Wrapper,
  CardContainer,
  TitleContainer,
  SmallText,
  SmallTextBold,
  MediumTextBold,
  PhotosContainer,
  Photo,
} from './styles';

import { useToast } from '../../../../context/ToastContext';
import { translateGender } from '../../../../utils/Translator';
import { useReservation } from '../../../../context/ReservationContext';

import { getBankDetailByIdUser } from '../../../../services/Bank/request';
import { BankDetail } from '../../../../services/Bank/types';
import { getReservationById } from '../../../../services/Reservation/request';
import { Reservation } from '../../../../services/Reservation/types';
import { Gender } from '../../../../services/types';
import { Guest } from '../../../../services/CompReservations/types';
import { PhotoModal } from './PhotoModal/PhotoModal';
import FormButton from '../../../FormButton/FormButton';
import { preCheckinGenerateLink } from '../../../../services/PreCheckin';
import { ButtonContainer } from '../../../ControllerPage/Modal/style';

interface Props {
  props?: object;
  idReservation: number;
  mainGuest: Guest;
}

interface PhotoProps {
  src?: string;
  alt?: string;
}

const GuestForm = ({ idReservation, mainGuest }: Props): JSX.Element => {
  const [dataReservation, setDataReservation] = useState<Reservation>({} as Reservation);
  const [selectedPhoto, setSelectedPhoto] = useState<PhotoProps>({} as PhotoProps);

  useEffect(() => {
    async function getReservation() {
      const result = await getReservationById(idReservation);
      setDataReservation(result);
    }
    getReservation();
  }, []);

  const addressGuest = `${dataReservation.guest?.user?.main_address?.street || ''} ${dataReservation.guest?.user?.main_address?.number || ''} ${dataReservation.guest?.user?.main_address?.neighborhood || ''} ${dataReservation.guest?.user?.main_address?.complement || ''} ${dataReservation.guest?.user?.main_address?.city || ''} ${dataReservation.guest?.user?.main_address?.state || ''} ${dataReservation.guest?.user?.main_address?.country || ''} ${dataReservation.guest?.user?.main_address?.postal_code || ''}`;

  const toast = useToast();
  const { reservationData } = useReservation();
  const client = `${reservationData?.guest?.user?.first_name || ''} ${reservationData?.guest?.user?.last_name || ''}`;

  const initialValuesBankDetails = {
    id: 0,
    entity_name: '',
    bank_name: '',
    bank_number: '',
    branch_number: '',
    account_number: '',
    account_type: '',
    cpf: '',
    cnpj: '',
    pix_key: '',
    user: 0,
  };

  const [bankDetail, setBankDetail] = useState<BankDetail>(initialValuesBankDetails);

  const getBankDetails = async () => {
    if (dataReservation.guest?.user?.id) {
      try {
        const idUser = dataReservation.guest?.user?.id;
        const bank: BankDetail[] = await getBankDetailByIdUser(idUser);
        if (bank.length > 0) {
          setBankDetail({ ...bank[0] });
        } else {
          setBankDetail(initialValuesBankDetails);
        }
      } catch (e: any) {
        toast.alert('Não foi possível recuperar os dados');
      }
    }
  };

  function handleOpenPhotoModal(src: string, alt: string) {
    setSelectedPhoto({
      src,
      alt,
    });
  }

  function handleClosePhotoModal() {
    setSelectedPhoto({
      src: undefined,
      alt: undefined,
    });
  }
  async function handleGeneratePreCheckinLink() {
    try {
      const response = await preCheckinGenerateLink({
        reservation: idReservation,
      });
      return response.link;
    } catch {
      toast.error('Ocorreu um erro ao gerar o link');
      return null;
    }
  }
  const appendQueryParameter = (
    url: string, key: string, value: string,
  ): string => {
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}${key}=${encodeURIComponent(value)}`;
  };
  async function handleEditPreCheckin() {
    const response = await handleGeneratePreCheckinLink();
    if (response) {
      const url = appendQueryParameter(
        response, 'mode', 'view',
      );
      window.open(url);
    }
  }

  useEffect(() => {
    getBankDetails();
    return () => setBankDetail(initialValuesBankDetails);
  }, [dataReservation]);

  const guestName = dataReservation.guest?.user?.trading_name
    ? dataReservation.guest?.user?.trading_name
    : `${dataReservation.guest?.user?.first_name} ${dataReservation.guest?.user?.last_name}`;

  return (
    <Container>
      {selectedPhoto.src && (
        <PhotoModal
          src={selectedPhoto.src}
          alt={selectedPhoto.alt}
          handleCloseModal={handleClosePhotoModal}
        />
      )}
      <FormContainer
        onContextMenu={(event) : void => {
          event.stopPropagation();
        }}
      >
        <Content>
          <CardContainer>
            <TitleContainer>
              <MediumTextBold>Dados Pessoais</MediumTextBold>
            </TitleContainer>
            <Wrapper>
              <>
                <SmallTextBold>Nome completo</SmallTextBold>
                <SmallText>{guestName}</SmallText>
                <SmallTextBold>Sexo</SmallTextBold>
                <SmallText>
                  {translateGender(dataReservation.guest?.user?.gender as Gender)}
                </SmallText>
                <SmallTextBold>Data de nascimento</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.birth_date}`}</SmallText>
                <SmallTextBold>Tipo de pessoa</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.is_individual ? 'Física' : 'Jurídica'}`}</SmallText>
                <SmallTextBold>{`${dataReservation.guest?.user?.is_individual ? 'CPF' : 'CNPJ'}`}</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.is_individual ? dataReservation.guest?.user?.cpf : dataReservation.guest?.user?.cpf}`}</SmallText>
              </>
            </Wrapper>
          </CardContainer>

          <CardContainer>
            <TitleContainer>
              <MediumTextBold>Contato</MediumTextBold>
            </TitleContainer>
            <Wrapper>
              <>
                <SmallTextBold>E-mail</SmallTextBold>
                <SmallText>{dataReservation.guest?.user?.email}</SmallText>
                <SmallTextBold>Telefone/Celular</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.phone_number1}`}</SmallText>
                <SmallTextBold>Endereço</SmallTextBold>
                <SmallText>
                  {addressGuest}
                </SmallText>
              </>
            </Wrapper>
          </CardContainer>
          <CardContainer>
            <TitleContainer>
              <MediumTextBold>Dados do Pré check-in</MediumTextBold>
            </TitleContainer>
            <Wrapper>
              <>
                <SmallTextBold>Nome completo</SmallTextBold>
                <SmallText>{mainGuest?.name || '-'}</SmallText>
                <SmallTextBold>Nacionalidade</SmallTextBold>
                {/* <SmallText>{mainGuest?.}</SmallText> */}
                <SmallTextBold>CPF</SmallTextBold>
                <SmallText>{mainGuest?.document || '-'}</SmallText>
                <SmallTextBold>Email</SmallTextBold>
                <SmallText>{mainGuest?.email || '-'}</SmallText>
                <SmallTextBold>Telefone</SmallTextBold>
                <SmallText>
                  {mainGuest?.phone_number ? `+${mainGuest?.phone_number}` : '-'}
                </SmallText>
                <SmallTextBold>Fotos do documento</SmallTextBold>
                {mainGuest?.front_document_photo && mainGuest?.back_document_photo ? (
                  <PhotosContainer>
                    <Photo
                      src={mainGuest?.front_document_photo?.url}
                      alt={mainGuest?.front_document_photo?.name}
                      onClick={() => handleOpenPhotoModal(mainGuest?.front_document_photo?.url,
                        mainGuest?.front_document_photo?.name)}
                    />
                    <Photo
                      src={mainGuest?.back_document_photo?.url}
                      alt={mainGuest?.back_document_photo?.name}
                      onClick={() => handleOpenPhotoModal(mainGuest?.back_document_photo?.url,
                        mainGuest?.back_document_photo?.name)}
                    />
                  </PhotosContainer>
                ) : (
                  <SmallText>{'-'}</SmallText>
                )}
                <ButtonContainer>
                  <FormButton
                    type="button"
                    onClick={handleEditPreCheckin}
                  >
                    Editar dados
                  </FormButton>
                </ButtonContainer>
              </>
            </Wrapper>
          </CardContainer>
          <CardContainer>
            <TitleContainer>
              <MediumTextBold>Dados bancários</MediumTextBold>
            </TitleContainer>
            <Wrapper>
              <>
                <SmallTextBold>Banco</SmallTextBold>
                <SmallText>{bankDetail?.bank_name || '-'}</SmallText>
                <SmallTextBold>Titular</SmallTextBold>
                <SmallText>{client || '-'}</SmallText>
                <SmallTextBold>Conta</SmallTextBold>
                <SmallText>{`${bankDetail?.bank_number || ''} ${bankDetail?.branch_number || ''} ${bankDetail?.account_number || ''} ${bankDetail?.account_type || ''}`}</SmallText>
                <SmallTextBold>Pix</SmallTextBold>
                <SmallText>{bankDetail?.pix_key || '-'}</SmallText>
              </>
            </Wrapper>
          </CardContainer>
        </Content>
      </FormContainer>
    </Container>
  );
};

export default GuestForm;
