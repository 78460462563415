import styled, { css } from 'styled-components';

export const Container = styled.div<{
  activeBorder?: boolean,
}>`
  padding: 3px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  cursor: pointer;
  
  display: flex;
  align-items: center;
  justify-content: center;

  svg: {
    width: 15px;
    height: 15px;
  }

  ${(props) => props.activeBorder && css`
    background: ${({ theme }) => theme.palette.grey._310.hex()};;
    border-radius: 45%;
  `}
`;

export const Link = styled.a``;
