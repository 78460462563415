import React from 'react';

import arrowTop from '../../../../assets/icons/generals/arrowTop.svg';
import arrowBottom from '../../../../assets/icons/generals/arrowBottom.svg';

import {
  CheckIn,
  WhatsApp,
  GridTitle,
  Container,
  GridHeader,
  ImmobileCode,
  ReservationCode,
  ReservationDate,
  ReservationStaff,
  ReservationOta,
} from './styles';

const Header = () => (
  <Container>
    <GridHeader>
      <ReservationCode>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Código
              {' '}
              <br />
              da reserva
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </ReservationCode>
      <ImmobileCode>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Imóvel
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </ImmobileCode>

      <ReservationDate>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Check-in
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </ReservationDate>

      <ReservationStaff>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              Dono
              {' '}
              <br />
              {' '}
              da reserva
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </ReservationStaff>

      <WhatsApp>
        <GridTitle>
          <div className="titleContainer">
            <h1>WhatsApp</h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </WhatsApp>

      <ReservationOta>
        <GridTitle>
          <div className="titleContainer">
            <h1>
              OTA
            </h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </ReservationOta>

      <CheckIn>
        <GridTitle>
          <div className="titleContainer">
            <h1>Enviado ao hospede?</h1>
            <div className="arrowContainer">
              <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
              <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
            </div>
          </div>
        </GridTitle>
      </CheckIn>
    </GridHeader>
  </Container>
);

export default Header;
