/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Radio } from '@mui/material';

import {
  Card,
  Inline,
  Container,
  ItemContainer,
  RadioContainer,
} from './style';

import { UseFormik } from '../../../../../../../../utils/Formik/types';
import { usePartners } from '../../../../../../../../context/Partners/Partner/PartnerContext';
import { usePartnersEarning } from '../../../../../../../../context/Partners/Earning/Earning';
import { GetPartnersBankDetailsBase } from '../../../../../../../../services/Partner/Financial/BankDetails/types';

import {
  Label,
  Section,
  SubText,
} from '../../style';

interface ISavedBanks {
  formik: UseFormik<any>;
}

interface ItemProps {
  text: string;
  label: string;
}

const Item = ({ text, label }: ItemProps) => {
  if (!text) return null;

  return (
    <ItemContainer>
      <span>{label}</span>
      <p>{text}</p>
    </ItemContainer>
  );
};

function translateTypePixKey(type: 'Phone_Number' | 'Random' | 'CPF' | 'CNPJ' | 'Email') {
  switch (type) {
    case 'CPF':
      return 'CPF';
    case 'CNPJ':
      return 'CNPJ';
    case 'Phone_Number':
      return 'Telefone';
    case 'Email':
      return 'Email';
    case 'Random':
      return 'Aleatória';
    default:
      return 'Aleatória';
  }
}

const SavedBanks = ({ formik }: ISavedBanks) => {
  const [active, setActive] = React.useState<number>(0);

  const { bankDetails } = usePartners();
  const { setFormValues } = usePartnersEarning();

  const handleReset = () => {
    setActive(0);

    formik.setValues({
      selectedBankFromSavedList: null,
      favoredName: '',
      bankAccountNumber: '',
      bankAccountType: '',
      bankAgency: '',
      bankId: '',
      bankName: '',
      bankNumber: '',
      pixKey: '',
      typePixKey: '',
      cpfOrCnpj: '',
      cpfOrCnpjValue: '',
      wantToReceiveByPix: '',
    });

    setFormValues((old) => ({
      ...old,
      cpfOrCnpj: '',
      cpfOrCnpjValue: '',
      selectedBankFromSavedList: null,
    }));
  };

  const handleClick = (bank: GetPartnersBankDetailsBase) => {
    if (bank.id === active) {
      handleReset();
      return;
    }

    setActive(bank.id);

    formik.setValues({
      selectedBankFromSavedList: bank.id,
      favoredName: bank.entity_name,
      bankAccountNumber: bank.account_number,
      bankAccountType: bank.account_type,
      bankAgency: bank.branch_number,
      bankId: bank.bank.id,
      bankName: bank.bank.long_name,
      bankNumber: bank.bank.bank_number,
      pixKey: bank.pix_key,
      typePixKey: bank.pix_key_type,
      cpfOrCnpj: bank.cpf === '' ? 'cnpj' : 'cpf',
      cpfOrCnpjValue: bank.cpf === '' ? bank.cnpj : bank.cpf,
      wantToReceiveByPix: bank.pix_key === '' ? 'no' : 'yes',
    });

    setFormValues((old) => ({
      ...old,
      selectedBankFromSavedList: bank.id,
      cpfOrCnpj: bank.cpf === '' ? 'cnpj' : 'cpf',
    }));
  };

  return (
    <Section style={{ margin: '20px 0', width: '100%' }}>
      <Label>Bancos já cadastrados</Label>
      <SubText>
        Clique em um banco para selecioná-lo e preencher os campos automaticamente
        <br />
        Você pode mudar os dados a qualquer momento
      </SubText>
      <Container style={{ gridTemplateColumns: `repeat(${bankDetails.length}, 1fr)` }}>
        {bankDetails.map((bank) => (
          <Card active={bank.id === active} key={bank.id} onClick={() => handleClick(bank)}>
            <RadioContainer>
              <Radio style={{ color: '#0D4BD0' }} checked={bank.id === active} />
            </RadioContainer>

            <Item label="Banco" text={bank.bank.short_name} />
            <Inline>
              <Item label="Tipo" text={bank.account_number ? 'Ted' : 'Pix'} />
              <Item label="Favorecido" text={bank.entity_name} />
            </Inline>

            {bank.account_number && (
            <Inline>
              <Item label="Agência" text={bank.branch_number} />
              <Item label="Conta" text={bank.account_number} />
            </Inline>
            )}

            {!bank.account_number && (
            <Inline>
              <Item label="Tipo" text={translateTypePixKey(bank.pix_key_type)} />
              <Item label="Chave" text={bank.pix_key} />
            </Inline>
            )}
          </Card>
        ))}
      </Container>
    </Section>
  );
};

export default SavedBanks;
