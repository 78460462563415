import styled from 'styled-components';

import {
  MAX_SIZE_MOBILE,
  desktopGridLayout,
  mobileGridLayout,
  itemHeightMobile,
  itemHeightDesktop,
} from '../../../utils';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${desktopGridLayout};
  width: 100%;
  height: ${itemHeightDesktop}px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};

  .property-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.blue._970.hex()};
  }

  .register-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.grey._560.hex()};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100vw;
  }

  .value {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    grid-template-columns: ${mobileGridLayout};
    height: ${itemHeightMobile}px;
    
    .property-code {
      font-size: 12px;
      font-weight: 500;
      line-height: 30px;
    }

    .register-date {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    .description {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      width: 60vw;
    }

    .value {
      font-size: 14px;
      margin-right: 1rem;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;

  p {
    text-align: start;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: ${({ theme }) => theme.palette.blue._250.hex()};
    font-weight: 400;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-right: 3rem;
  gap: 0.5rem;
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    gap: 0;
    position: relative;
    right: 12px;
  }
`;
