import React from 'react';

const Location = () => (
  <svg
    width="31"
    height="33"
    viewBox="0 0 31 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="location"
      d="M30.8406 29.8683L28.6019 23.1H26.1454L27.5095 29.7H3.49133L4.85552 23.1H2.399L0.158656 29.8683C-0.409618 31.5909 0.597554 33 2.399 33H28.6019C30.4033 33 31.4105 31.5909 30.8406 29.8683ZM23.6888 8.25C23.6888 6.06196 22.8261 3.96354 21.2905 2.41637C19.7549 0.869194 17.6721 0 15.5004 0C13.3287 0 11.246 0.869194 9.71036 2.41637C8.17474 3.96354 7.31204 6.06196 7.31204 8.25C7.31204 16.1287 15.5004 24.75 15.5004 24.75C15.5004 24.75 23.6888 16.1287 23.6888 8.25ZM11.0787 8.349C11.0787 7.76404 11.1931 7.18481 11.4153 6.6444C11.6376 6.10398 11.9633 5.61298 12.3739 5.19942C12.7845 4.78587 13.272 4.45787 13.8084 4.23417C14.3449 4.01046 14.9198 3.89543 15.5004 3.89565C16.6729 3.89565 17.7974 4.36493 18.6265 5.20025C19.4556 6.03557 19.9214 7.1685 19.9214 8.34982C19.9214 9.53115 19.4556 10.6641 18.6265 11.4994C17.7974 12.3347 16.6729 12.804 15.5004 12.804C14.3277 12.804 13.203 12.3346 12.3738 11.4992C11.5446 10.6637 11.0787 9.53054 11.0787 8.349Z"
      fill="#32A56E"
    />
  </svg>
);

export default Location;
