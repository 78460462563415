import { useContextSelector } from 'use-context-selector';
import { TagContext } from '../../../context/HostDashboard/HostGridFinances/TagContext';

export function useTag() {
  const isExpandedDailyExecuted = useContextSelector(TagContext,
    (state) => state.isExpandedDailyExecuted);
  const handleChangeIsExpandedDailyExecuted = useContextSelector(TagContext,
    (state) => state.setIsExpandedDailyExecuted);

  const isExpandedCleaningExecuted = useContextSelector(TagContext,
    (state) => state.isExpandedCleaningExecuted);
  const handleChangeIsExpandedCleaningExecuted = useContextSelector(TagContext,
    (state) => state.setIsExpandedCleaningExecuted);

  const isExpandedDailyPaid = useContextSelector(TagContext,
    (state) => state.isExpandedDailyPaid);
  const handleChangeIsExpandedDailyPaid = useContextSelector(TagContext,
    (state) => state.setIsExpandedDailyPaid);

  const isExpandedCleaningPaid = useContextSelector(TagContext,
    (state) => state.isExpandedCleaningPaid);
  const handleChangeIsExpandedCleaningPaid = useContextSelector(TagContext,
    (state) => state.setIsExpandedCleaningPaid);

  const isExpandedOthersReceipts = useContextSelector(TagContext,
    (state) => state.isExpandedOthersReceipts);
  const handleChangeIsExpandedOthersReceipts = useContextSelector(TagContext,
    (state) => state.setIsExpandedOthersReceipts);

  const isExpandedCashOut = useContextSelector(TagContext,
    (state) => state.isExpandedCashOut);
  const handleChangeIsExpandedCashOut = useContextSelector(TagContext,
    (state) => state.setIsExpandedCashOut);

  const isExpandedWallet = useContextSelector(TagContext,
    (state) => state.isExpandedWallet);
  const handleChangeIsExpandedWallet = useContextSelector(TagContext,
    (state) => state.setIsExpandedWallet);

  return {
    isExpandedDailyExecuted,
    handleChangeIsExpandedDailyExecuted,
    isExpandedCleaningExecuted,
    handleChangeIsExpandedCleaningExecuted,
    isExpandedDailyPaid,
    handleChangeIsExpandedDailyPaid,
    isExpandedCleaningPaid,
    handleChangeIsExpandedCleaningPaid,
    isExpandedOthersReceipts,
    handleChangeIsExpandedOthersReceipts,
    isExpandedCashOut,
    handleChangeIsExpandedCashOut,
    isExpandedWallet,
    handleChangeIsExpandedWallet,
  };
}
