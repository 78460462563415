import React from 'react';

import { Fade, Modal } from '@mui/material';
import { motion } from 'framer-motion';

import { UserInformation } from '../../../../../../../services/User/types';
import { changeRequiredActions } from '../../../../../../../services/User/request';
import { useUser } from '../../../../../../../context/UserContext';

import {
  Span,
  Container,
  TopContent,
  BottomContent,
  RadioContainer,
} from './style';

const WelcomeMessage = () => {
  const [open, setOpen] = React.useState(true);
  const [notShowAgain, setNotShowAgain] = React.useState(false);
  const { userInformation, setUserInformation } = useUser();

  const handleClose = async () => {
    if (notShowAgain && userInformation?.user_id) {
      const partnerActions: string[] = userInformation?.required_actions?.Partner || [];
      const response = await changeRequiredActions({
        user_id: userInformation.user_id,
        body: {
          Partner: partnerActions.filter((item) => item !== 'PARTNER_INDICATION_POPUP'),
        },
      });

      setUserInformation({
        ...userInformation,
        main_address: { ...userInformation?.main_address },
        host: { ...userInformation?.host },
        ...response.body,
      } as UserInformation);
    }
    setOpen(false);
  };

  const handleShowAgain = () => {
    setNotShowAgain((prev) => !prev);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{
          sx: {
            height: '100%',
            overflow: 'hidden',
          },
        }}
      >
        <Fade in={open}>
          <Container>
            <TopContent>
              <h1>Torne sua probabilidade de conversão ainda maior!</h1>
              <p>
                Agora você pode verificar se a sua indicação possui uma
                probabilidade de conversão
                {' '}
                <Span status="low">baixa</Span>
                ,
                {' '}
                <Span status="moderate">moderada</Span>
                ,
                {' '}
                <Span status="medium">média</Span>
                ,
                {' '}
                <Span status="hight">alta</Span>
                {' '}
                ou
                {' '}
                <Span status="excellent">excelente</Span>
                !
              </p>
              <p>
                Os principais fatores que influenciam a probabilidade de conversão
                estão sinalizados no formulário com o ícone:
              </p>
            </TopContent>
            <BottomContent>
              <RadioContainer whileTap={{ x: 10 }} onClick={handleShowAgain}>
                <input
                  id="radio"
                  type="radio"
                  name="radio"
                  checked={notShowAgain}
                />
                <p>Não mostrar novamente</p>
              </RadioContainer>
              <motion.button onClick={handleClose} whileTap={{ scale: 0.95 }} type="button">
                Continuar
              </motion.button>
            </BottomContent>
          </Container>
        </Fade>
      </Modal>
    </>
  );
};

export default WelcomeMessage;
