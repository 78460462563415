import { useContextSelector } from 'use-context-selector';
import { AbortRequestContext } from '../../context/RequestContext/AbortRequestContext';

export function useAbortRequest() {
  const abortController = useContextSelector(AbortRequestContext, (state) => state.abortController);
  const setAbortController = useContextSelector(AbortRequestContext,
    (state) => state.setAbortController);

  return {
    abortController,
    setAbortController,
  };
}
