import React from 'react';

import { Container, Item } from './styles';

const GridItemShimmer = () => (
  <Container>
    <Item>
      <div />
    </Item>
    <Item>
      <div />
    </Item>
    <Item>
      <div />
    </Item>
    <Item>
      <div />
    </Item>
    <Item>
      <div />
    </Item>
  </Container>
);

export default GridItemShimmer;
