import React, { useRef } from 'react';

import { useFormik } from 'formik';
import { IFormik, ILoading } from '../types';
import { validationSchema } from '../validation';
import { useToast } from '../../../../../../../../context/ToastContext';
import { IGuestFront } from '../../../../../../../../services/PreCheckin/types';
import { translateBedArrangement } from '../../../../../../../../utils/Translator';
import { usePreCheckin } from '../../../../../../../../context/PreCheckin/PreCheckin';

import {
  patchGuests,
  patchPreCheckin,
  postGuests,
} from '../../../../../../../../services/PreCheckin';

interface IGuest {
  guest: IGuestFront;
  isMain: boolean;
}

const WAIT_TIME = 1000;

function handleValidateInitialExpanded(guest: IGuestFront, isMain: boolean) {
  if (guest?.expanded?.is) {
    return true;
  }

  return isMain;
}

// faz a lógica para todo formik do hóspede
export function useGuest({ guest, isMain }: IGuest) {
  const initialExpanded = React.useMemo(() => {
    const value = handleValidateInitialExpanded(guest, isMain);
    return value;
  }, [guest.expanded]);

  const toast = useToast();

  const {
    info, token, guests, setGuests, bedOrganization, setHasIsPrincipal,
  } = usePreCheckin();

  const ref = useRef<HTMLDivElement>(null);
  const time = React.useRef<NodeJS.Timeout>();

  const [expanded, setExpanded] = React.useState(initialExpanded);
  const [loading, setLoading] = React.useState<ILoading>({
    is: false,
    status: undefined,
  });

  const validateBedOrganization = React.useMemo(() => {
    if (bedOrganization) {
      return bedOrganization?.length > 0;
    }

    return false;
  }, [bedOrganization]);

  const handleReset = () => {
    setExpanded(false);
    window.scrollTo({
      behavior: 'smooth',
      top: window.innerHeight,
    });
  };

  const formik = useFormik<IFormik>({
    initialValues: {
      isPrincipal: isMain,
      name: guest.name || '',
      email: guest.email || '',
      cpf: guest.document || '',
      phone: guest.phone_number || '',
      nationality: 'brazilian',

      front_document_photo: guest?.front_document_photo || null,
      back_document_photo: guest?.back_document_photo || null,

      bedOrganization: translateBedArrangement(info?.bed_arrangement) || '',
    },

    validationSchema: validationSchema({
      info,
      guests,
      singleGuest: guest,
      capacity: info?.property?.guest_capacity,
      hasBedOrganization: validateBedOrganization,
    }),

    onSubmit: async (values) => {
      const data = {
        id: guest.id,
        name: values.name,
        is_principal: isMain,
        email: values.email || null,
        document: values.cpf || null,
        reservation: guest.reservation,
        phone_number: values.phone || null,
      };

      function getDataForPatch() {
        const documentUpdated = guest.isPut?.updated?.documentPhoto;

        if (documentUpdated?.back) {
          return {
            ...data,
            back_document_photo: values?.back_document_photo?.uid || null,
          };
        }
        if (documentUpdated?.front) {
          return {
            ...data,
            front_document_photo: values?.front_document_photo?.uid || null,
          };
        }
        return {
          ...data,
        };
      }

      // -- Get bed Organization --
      const handleBedOrganization = async () => {
        const validate = guest.isPut?.updated?.bedOrganization;
        const bedOrganizationId = Number(formik.values.bedOrganization);
        const getBedOrganizationById = bedOrganization
          ?.find((item) => item?.id === bedOrganizationId);

        if (token && validate) {
          try {
            await patchPreCheckin({
              token,
              reservation: guest.reservation,
              data: {
                bed_arrangement: getBedOrganizationById?.bed_arrangement,
              },
            });

            setGuests((prev) => ({
              ...prev,
              list: prev.list.map((item) => {
                if (item.id === guest.id) {
                  return {
                    ...item,
                    bedOrganization: '',
                    expanded: {
                      ...item.expanded,
                      is: false,
                    },
                  };
                }
                return item;
              }),
            }));
          } catch {
            time.current = setTimeout(() => {
              toast.error('Erro ao atualizar a organização de cama');
            }, WAIT_TIME);
          }
        }
      };

      if (guest?.isPut?.is && token) {
        // --- PATCH ---
        try {
          setLoading((prev) => ({ ...prev, is: true }));
          handleReset();

          const response = await patchGuests({
            token,
            id: guest.id,
            data: getDataForPatch(),
          });

          await handleBedOrganization();

          if (response) {
            // valida para o hóspede principal vs hóspede padrão
            if (isMain) {
              // seta o objeto de main dentro de guests
              setGuests((prev) => ({
                ...prev,
                main: {
                  ...prev.main,
                  ...response,
                  expanded: {
                    ...prev.main.expanded,
                    is: false,
                  },
                },
              }));
              setHasIsPrincipal((prev) => ({
                ...prev,
                is: true,
                guest: response,
              }));
            } else {
              setGuests((prev) => ({
                ...prev,
                list: prev.list.map((item) => {
                  if (item.id === guest.id) {
                    return {
                      ...item,
                      ...response,
                      expanded: {
                        ...item.expanded,
                        is: false,
                      },
                    };
                  }
                  return item;
                }),
              }));
            }
          }

          time.current = setTimeout(() => {
            toast.success('Convidado atualizado com sucesso');
          }, WAIT_TIME);
        } catch (e) {
          if (e instanceof Error) {
            time.current = setTimeout(() => {
              toast.error('Erro ao atualizar convidado');
            }, WAIT_TIME);
          }
        } finally {
          time.current = setTimeout(() => {
            setLoading((prev) => ({ ...prev, is: false }));
          }, WAIT_TIME);
        }

        // --- POST ---
      } else if (token) {
        try {
          setLoading((prev) => ({ ...prev, is: true }));
          handleReset();

          const response = await postGuests({
            data: {
              ...data,
              back_document_photo: values?.back_document_photo?.uid || null,
              front_document_photo: values?.front_document_photo?.uid || null,
            },
            token,
          });

          await handleBedOrganization();

          // valida para o hóspede principal vs hóspede padrão
          if (response) {
            if (isMain) {
              setGuests((prev) => ({
                ...prev,
                main: {
                  ...prev.main,
                  ...response,
                  isPut: {
                    ...prev.main.isPut,
                    is: true,
                  },
                  expanded: {
                    ...prev.main.expanded,
                    is: false,
                  },
                  id: response.id,
                },
              }));
              setHasIsPrincipal((prev) => ({ ...prev, is: true }));
            } else {
              setGuests((prev) => ({
                ...prev,
                list: prev.list.map((item) => {
                  if (item.id === guest.id) {
                    return {
                      ...item,
                      ...response,
                      isPut: {
                        ...item.isPut,
                        is: true,
                      },
                      id: response.id,
                      expanded: {
                        ...item.expanded,
                        is: false,
                      },
                    };
                  }
                  return item;
                }),
              }));
            }
          }

          time.current = setTimeout(() => {
            toast.success('Convidado adicionado com sucesso');
          }, WAIT_TIME);
        } catch (e) {
          if (e instanceof Error) {
            time.current = setTimeout(() => {
              toast.error('Erro ao adicionar convidado');
            }, WAIT_TIME);
          }
        } finally {
          time.current = setTimeout(() => {
            setLoading((prev) => ({ ...prev, is: false }));
          }, WAIT_TIME);
        }
      }
    },
  });

  React.useEffect(() => {
    if (time.current) {
      clearTimeout(time.current);
    }

    return () => {
      if (time.current) {
        clearTimeout(time.current);
      }
    };
  }, []);

  return {
    ref,
    formik,
    loading,
    expanded,
    setExpanded,
  };
}
