export const numberRegex = /.*[0-9].*/;
export const lowercaseRegex = /.*[a-z].*/;
export const uppercaseRegex = /.*[A-Z].*/;
export const specialCharRegex = /.*[!@#$%^&*()_\-+={}[\]|:;"'<>,.?/].*/;
export const phoneRegExp = /^\+[1-9][0-9]{0,2} \([1-9]{2}\) [2-9][0-9]{3,4}-[0-9]{4}$/;
export const commonPasswords = [
  'user',
  'email',
  'teste',
  'senha',
  'admin',
  '123456',
  'password',
  'username',
  'teste1234',
  'teste123!',
  'Teste123!',
];
