/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { InputAdornment } from '@mui/material';
import { Eye, EyeOff } from 'react-feather';

import { CreateCompleteUser } from '../../../services/User/types';
import { RequestPartner } from '../../../services/Partner/types';

import { formatCNPJToPost, formatCPFtoSubmit, isBrazil } from '../../../utils/Formatter';
import { useToastErrorMessage } from '../../../utils/Messages';

import { postAddress, deleteAddress } from '../../../services/Address/request';
import { deleteUser } from '../../../services/InsertData/request';
import { deleteOwner, postOwner } from '../../../services/Owner/request';
import { postUser } from '../../../services/User/request';
import { getPartners } from '../../../services/Partner';
import { normalizeCountry } from '../../../services/Address/countries';

import { useOnboardingHandover } from '../../../context/OnboardingContext/OnboardingHandoverContext';
import { useToast } from '../../../context/ToastContext';
import { useOnboardingPage } from '../../../hooks/useOnboarding/useOnboarding';
import { useMobile } from '../../../hooks/useMobile/useMobile';
import { useIBGE } from '../../../hooks/useIBGE/useIBGE';
import { normalizeState } from '../../../hooks/useIBGE/utils';

import { compareList } from '../../../utils/Sorting';
import { checkActive, handleValidation } from '../../../utils/Onboarding';
import {
  numberRegex,
  uppercaseRegex,
  lowercaseRegex,
  commonPasswords,
  specialCharRegex,
} from './utils';
import { translateMaritalStatus } from '../../../utils/Translator';

import {
  TextField,
  DatePicker,
  FormButton,
  RadioTypePerson,
  SimpleSelect,
  FormAddress,
} from '../..';

import { SelectOption } from '../../SimpleSelect/SimpleSelect';
import OnboardingSwitch from '../OnboardingSwitch';

import {
  Title,
  ModalCard,
  InputSpace,
  DoubleInput,
  CloseContainer,
  ModalContainer,
  InputsContainer,
  SwitchContainer,
  ButtonsContainer,
  StyledCountrySelector,
} from './styles';

const maritalStatus = [
  {
    value: 'Single',
    valueLabel: 'Solteiro(a)',
  },
  {
    value: 'Married',
    valueLabel: 'Casado(a)',
  },
  {
    value: 'Divorced',
    valueLabel: 'Divorciado(a)',
  },
  {
    value: 'Widowed',
    valueLabel: 'Viúvo(a)',
  },
];

const phoneRegExp = /^\+55 \([1-9]{2}\) [2-9][0-9]{3,4}-[0-9]{4}$/;

export default function NewOwnerModal() {
  const { isMobile } = useMobile();
  const { handleClickPhase, setIsOpenModal, phases } = useOnboardingPage();
  const { infosPipeDrive, owner, setOwner } = useOnboardingHandover();
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const [partners, setPartners] = useState<RequestPartner[]>([]);
  const [type, setType] = useState('password');
  const [typeFieldConfirm, setTypeFieldConfirm] = useState('password');
  const [defaultPartnerSelected, setDefaultPartnerSelected] = useState<SelectOption>({ valueLabel: '', value: '' });

  const { states } = useIBGE({});
  const { stateMappings } = states;

  function selectInitialDates() {
    const pipeData = infosPipeDrive?.owner_infos?.birth_date;

    if (pipeData) {
      return moment(pipeData).format('MM-DD-YYYY');
    }
    return '';
  }

  function clearString(str: string) {
    return str.replace(/[,*\-().]/g, '');
  }

  function formatPhoneToShow(phone: string) {
    if (['null', 'undefined'].includes(`${phone}`)) return '';

    const phoneNumber = `${phone}`.replace(/\D/g, '');

    if (!phoneNumber.startsWith('+55') && !phoneNumber.startsWith('55')) {
      return `+55 ${phoneNumber}`;
    }

    return phoneNumber;
  }

  function formatPhoneToSubmit(phone: string) {
    if (['null', 'undefined'].includes(`${phone}`)) return '';

    const phoneNumber = `${phone}`.replace(/\D/g, '');

    if (!phoneNumber.startsWith('+')) {
      return `+${phoneNumber}`;
    }

    return phoneNumber;
  }

  const initialValues = {
    rg: '',
    gender: 'Not informed',
    password: '',
    personID: infosPipeDrive?.owner_infos?.owner_person_id?.toString() || '',
    passwordConfirm: '',
    born: selectInitialDates(),
    cpf: infosPipeDrive?.owner_infos?.cpf || '',
    cnpj: infosPipeDrive?.owner_infos?.cnpj || '',
    email: infosPipeDrive?.owner_infos?.email || '',
    name: infosPipeDrive?.owner_infos?.first_name || '',
    phone: formatPhoneToShow(infosPipeDrive?.owner_infos?.phone_number || ''),
    lastname: infosPipeDrive?.owner_infos?.last_name || '',
    profession: infosPipeDrive?.owner_infos?.owner_profession || '',
    nationality: infosPipeDrive?.owner_infos?.nationality || '',
    marital_status: translateMaritalStatus[`${infosPipeDrive?.owner_infos?.marital_status || ''}`.toLowerCase()],
    city: infosPipeDrive?.owner_infos?.owner_address?.owner_city || '',
    indicationName: infosPipeDrive?.partner_name || '',
    isPartnerIndication: infosPipeDrive?.is_partner_indication || false,
    number: infosPipeDrive?.owner_infos?.owner_address?.owner_number || '',
    street: infosPipeDrive?.owner_infos?.owner_address?.owner_street || '',
    zipCode: infosPipeDrive?.owner_infos?.owner_address?.owner_postal_code || '',
    complement: infosPipeDrive?.owner_infos?.owner_address?.owner_complement || '',
    neighborhood: infosPipeDrive?.owner_infos?.owner_address?.owner_neighborhood || '',
    typePerson: infosPipeDrive?.owner_infos?.is_individual ? 'individual' : 'legal',
    country: normalizeCountry(infosPipeDrive?.owner_infos?.owner_address?.owner_country || 'Brasil'),
    state: normalizeState(stateMappings, infosPipeDrive?.owner_infos?.owner_address?.owner_state || ''),   
  };

  const validation = Yup.object().shape({
    typePerson: Yup.string(),
    name: Yup.string().required('O nome é obrigatório'),
    email: Yup.string().required().email('E-mail inválido'),
    personID: Yup.string().required('O personID é obrigatório'),
    lastname: Yup.string().required('O Sobrenome é obrigatório'),
    born: Yup.string().required('A data de nascimento é obrigatória'),
    phone: Yup.string()
      .test(
        'is-valid-phone',
        'O número de telefone deve estar no formato +55 (00) 00000-0000',
        (value) => {
          if (!value) return true;

          if (value.startsWith('55')) {
            const formattedValue = `+55 (${value.slice(2, 4)}) ${value.slice(4, 9)}-${value.slice(
              9,
            )}`;
            return phoneRegExp.test(formattedValue);
          }

          return false;
        },
      )
      .required('O telefone é obrigatório'),
    gender: Yup.string(),
    cpf: Yup.string().when('typePerson', {
      is: 'individual',
      then: Yup.string().required('O CPF é obrigatório caso seja uma pessoa física'),
    }),
    cnpj: Yup.string().when('typePerson', {
      is: 'legal',
      then: Yup.string().required('O CNPJ é obrigatório caso seja uma pessoa jurídica'),
    }),

    street: Yup.string().required('É necessário informar uma rua'),
    city: Yup.string().required('É necessário informar uma cidade'),
    country: Yup.string().required('É necessário informar um país'),
    neighborhood: Yup.string().required('É necessário informar um bairro'),
    state: Yup.string().required('É necessário informar uma unidade federativa'),
    zipCode: Yup.string().when('country', {
      is: (country: string | undefined) => isBrazil(country),
      then: Yup.string()
        .min(9, 'Cep inválido. Ex. 88036-600')
        .max(9, 'Cep inválido. Ex. 88036-600')
        .required('O cep é obrigatório'),
      otherwise: Yup.string().max(10, 'Cep inválido').required('O cep é obrigatório'),
    }),
    nationality: Yup.string().required('É necessário informar a nacionalidade'),
    marital_status: Yup.string()
      .nullable()
      .oneOf(['Single', 'Married', 'Divorced', 'Widowed'], 'O estado civil selecionado é inválido')
      .required('É necessário informar o estado civil'),
    password: Yup.string()
      .required('A senha é obrigatória')
      .matches(specialCharRegex, 'A senha deve conter pelo menos um caracter especial')
      .matches(uppercaseRegex, 'A senha deve conter pelo menos uma letra maiúscula')
      .matches(lowercaseRegex, 'A senha deve conter pelo menos uma letra minúscula')
      .matches(numberRegex, 'A senha deve conter pelo menos um número')
      .notOneOf(commonPasswords, 'A senha não pode ser uma senha comum')
      .min(8, 'A senha deve ter pelo menos 8 caracteres'),

    passwordConfirm: Yup.string()
      .required('É necessário confirmar a senha')
      .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      let mainAddress = 0;
      let userId = 0;
      let ownerId = 0;

      try {
        const addressResponse = await postAddress({
          city: values.city,
          state: normalizeState(stateMappings, values.state),
          street: clearString(values.street),
          number: values.number,
          country: normalizeCountry(values.country),
          postal_code: values.zipCode,
          complement: values.complement,
          neighborhood: clearString(values.neighborhood),
        });

        mainAddress = addressResponse.id || 0;

        const base: CreateCompleteUser = {
          is_staff: false,
          is_active: true,
          trading_name: '',
          corporate_name: '',
          main_role: 'Owner',
          email: values.email,
          gender: values.gender,
          first_name: values.name,
          password: values.password,
          last_name: values.lastname,
          phone_number1: formatPhoneToSubmit(values.phone),
          main_address: mainAddress,
          pipedrive_person_id: values.personID,
          password_confirmation: values.passwordConfirm,
          is_individual: values.typePerson === 'individual',
          birth_date: moment(values.born).format('YYYY-MM-DD'),
          cpf: values.typePerson === 'individual' ? formatCPFtoSubmit(values.cpf) : '',
          cnpj: values.typePerson !== 'individual' ? formatCNPJToPost(values.cnpj) : '',
        };

        const userResponse = await postUser({
          ...base,
          pipedrive_person_id: infosPipeDrive?.owner_infos?.owner_person_id
            ? `${infosPipeDrive?.owner_infos?.owner_person_id}`
            : undefined,
        });

        userId = userResponse.id;

        const ownerResponse = await postOwner({
          user: userId,
          invoice_address: mainAddress,
          profession: clearString(
            `${values.profession}`.trim() === '' ? 'Não informado' : values.profession,
          ),
          nationality: clearString(
            `${values.nationality}`.trim() === '' ? 'Não informado' : values.nationality,
          ),
          marital_status: values.marital_status,
          is_partner_indication: values.isPartnerIndication,
          referrer_partner:
            `${values.indicationName}`.trim() === '' ? undefined : values.indicationName,
        });

        ownerId = ownerResponse.id;
 
        setOwner({
          ...owner,
          user_id: userId,
          owner_id: ownerId,
          name: `${userResponse.first_name} ${userResponse.last_name}`,
          cpf: `${userResponse.cpf}`,
          cnpj: `${userResponse.cnpj}`,
          pipedrive_person_id: Number(userResponse.pipedrive_person_id),
          account_user_details: {
            phone_number1: `${userResponse.phone_number1}`,
            email: `${userResponse.email}`,
            main_address: { ...addressResponse },
          }
        });

        setIsOpenModal(false);
        handleClickPhase(1);
        resetForm();
      } catch (e: unknown) {
        if (e instanceof Error) {
          if (mainAddress && !userId) {
            await deleteAddress(mainAddress);
          } else if (mainAddress && userId && !ownerId) {
            await deleteUser(userId);
            await deleteAddress(mainAddress);
          } else if (mainAddress && userId && ownerId) {
            await deleteUser(userId);
            await deleteOwner(ownerId);
            await deleteAddress(mainAddress);
          }
          toastErrorRequest(e);
        }
      }
    },
  });

  const handleGetPartners = async () => {
    const response = await getPartners();
    const sorted = response
      .filter((partner) => partner.user.is_active)
      .sort((a, b) => compareList(a.user.first_name, b.user.first_name));
    setPartners(sorted);
  };

  useEffect(() => {
    handleGetPartners();
  }, []);

  useEffect(() => {
    if (infosPipeDrive) {
      formik.setValues({
        rg: '',
        gender: 'Not informed',
        password: '',
        personID: infosPipeDrive?.owner_infos?.owner_person_id?.toString() || '',
        passwordConfirm: '',
        born: selectInitialDates(),
        cpf: infosPipeDrive?.owner_infos?.cpf || '',
        cnpj: infosPipeDrive?.owner_infos?.cnpj || '',
        email: infosPipeDrive?.owner_infos?.email || '',
        name: infosPipeDrive?.owner_infos?.first_name || '',
        phone: formatPhoneToShow(infosPipeDrive?.owner_infos?.phone_number || ''),
        lastname: infosPipeDrive?.owner_infos?.last_name || '',
        profession: infosPipeDrive?.owner_infos?.owner_profession || '',
        nationality: infosPipeDrive?.owner_infos?.nationality || '',
        marital_status: translateMaritalStatus[`${infosPipeDrive?.owner_infos?.marital_status || ''}`.toLowerCase()],
        city: infosPipeDrive?.owner_infos?.owner_address?.owner_city || '',
        indicationName: infosPipeDrive?.partner_name || '',
        isPartnerIndication: infosPipeDrive?.is_partner_indication || false,
        number: infosPipeDrive?.owner_infos?.owner_address?.owner_number || '',
        street: infosPipeDrive?.owner_infos?.owner_address?.owner_street || '',
        zipCode: infosPipeDrive?.owner_infos?.owner_address?.owner_postal_code || '',
        complement: infosPipeDrive?.owner_infos?.owner_address?.owner_complement || '',
        neighborhood: infosPipeDrive?.owner_infos?.owner_address?.owner_neighborhood || '',
        typePerson: infosPipeDrive?.owner_infos?.is_individual ? 'individual' : 'legal',
        country: normalizeCountry(infosPipeDrive?.owner_infos?.owner_address?.owner_country || 'Brasil'),
        state: normalizeState(stateMappings, infosPipeDrive?.owner_infos?.owner_address?.owner_state || ''),   
      });

      const filteredPartner = partners.filter((item) => `${item?.user?.first_name} ${item?.user?.last_name}`.toLowerCase().trim().includes(`${infosPipeDrive?.partner_name}`.toLowerCase().trim()));
      if (filteredPartner.length !== 0) {
        const partnerName = `${filteredPartner?.[0]?.user?.first_name} ${filteredPartner?.[0]?.user?.last_name}`;
        const partnerId = `${filteredPartner?.[0]?.id}`;

        formik.setFieldValue('indicationName', partnerId);
        setDefaultPartnerSelected({
          valueLabel: partnerName,
          value: partnerId,
        });
      } else {
        const filteredDefault = partners.filter((item) => `${item?.user?.first_name} ${item?.user?.last_name}`.toLowerCase().trim() === 'a definir');
        const partnerName = `${filteredDefault?.[0]?.user?.first_name} ${filteredDefault?.[0]?.user?.last_name}`;
        const partnerId = `${filteredDefault?.[0]?.id}`;

        formik.setFieldValue('indicationName', partnerId);
        setDefaultPartnerSelected({
          valueLabel: partnerName,
          value: partnerId,
        });
      };
    }
   }, [infosPipeDrive, partners]);

  useEffect(() => {
    if (checkActive(phases, 'Proprietário PF/PJ'))
      handleValidation(formik, toast);
  }, [phases]);

  return (
    <>
      <ModalContainer isModalOpen>
        <ModalCard>
          <CloseContainer onClick={() => setIsOpenModal(false)}>
            <Close />
            <p>Fechar</p>
          </CloseContainer>
          <Title>Dados novo proprietário</Title>
          <InputsContainer onSubmit={formik.handleSubmit}>
            <InputSpace />
            <TextField
              label="*Person ID"
              id="personID"
              formik={formik}
              value={infosPipeDrive?.owner_infos?.owner_person_id || ''}
            />
            <InputSpace />
            <TextField
              label="*Nome"
              id="name"
              formik={formik}
              value={infosPipeDrive?.owner_infos?.first_name || ''}
            />
            <InputSpace />
            <TextField
              label="*Sobrenome"
              id="lastname"
              formik={formik}
              dataCy="lastname"
              value={infosPipeDrive?.owner_infos?.last_name || ''}
            />
            <InputSpace />
            <TextField
              label="*E-mail"
              id="email"
              dataCy="email"
              type="email"
              formik={formik}
              value={infosPipeDrive?.owner_infos?.email || ''}
            />
            <InputSpace />
            <StyledCountrySelector
              id="phone"
              formik={formik}
              country={'br'}
              label="*Telefone/Celular"
              enableLongNumbers
              autoFormat
              enableTerritories
              value={formik.values.phone}
              enableClickOutside={!isMobile}
              onChange={(value) => formik.setFieldValue('phone', value)}
              disableDropdown
              placeholder="+xx (xx) xxxxx-xxxx"
              masks={{
                br: '(..) .....-....',
              }}
            />
            <InputSpace />
            <TextField
              label="Profissão"
              id="profession"
              formik={formik}
              value={infosPipeDrive?.owner_infos?.owner_profession || ''}
            />
            <InputSpace />
            <TextField
              label="Nacionalidade"
              id="nationality"
              formik={formik}
              value={infosPipeDrive?.owner_infos?.nationality || ''}
            />
            <InputSpace />
            <DoubleInput>
              <SimpleSelect
                id="marital_status"
                label="Estado civil"
                className="selector"
                options={maritalStatus}
                formik={formik}
                placeholder="Selecione..."
                labelClassName="labelClass"
              />
              <DatePicker
                formik={formik}
                label="*Data de nasc."
                id="born"
                dataCy="born"
                minDate={new Date('1850-01-02')}
                viewsCustom={['day', 'month', 'year']}
                disableCloseOnSelect={false}
                disableFuture
                hasInitialDates={false}
                onlyAlertErrorWhenTouched
              />
            </DoubleInput>
            <InputSpace />
            <RadioTypePerson
              labelPlacement="end"
              id="typePerson"
              formLabel="Pessoa"
              formik={formik}
            />
            <InputSpace />

            {formik.values.typePerson === 'individual' && (
              <TextField
                label="*CPF"
                id="cpf"
                dataCy="cpf"
                mask="cpf"
                formik={formik}
                value={infosPipeDrive?.owner_infos?.cpf || ''}
              />
            )}
            <InputSpace />
            {formik.values.typePerson === 'legal' && (
              <TextField
                label="*CNPJ"
                id="cnpj"
                dataCy="cnpj"
                mask="cnpj"
                formik={formik}
                value={infosPipeDrive?.owner_infos?.cnpj || ''}
              />
            )}
            <InputSpace />
            <SwitchContainer flexDirection="column">
              <label htmlFor="isPartnerIndication">Foi indicado por algum parceiro ?</label>
              <OnboardingSwitch
                id="isPartnerIndication"
                formik={formik}
                value={formik.values.isPartnerIndication}
                alignment="center"
              />
            </SwitchContainer>
            <InputSpace />
            <SimpleSelect
              id="indicationName"
              dataCy="indicationName"
              placeholder="Selecione o parceiro"
              disabled={!formik.values.isPartnerIndication}
              required={formik.values.isPartnerIndication}
              disableRequireSymbol
              formik={formik}
              defaultSelected={`${defaultPartnerSelected?.valueLabel || 'A Definir'}`}
              options={(partners || []).map<SelectOption>(({ id, user: partner }) => ({
                value: id,
                valueLabel: `${partner.first_name} ${partner.last_name}`,
              }))}
            />
            <InputSpace />
            <TextField
              label="*Senha"
              id="password"
              formik={formik}
              type={type}
              endAdornment={
                <InputAdornment position="start">
                  {type === 'password' ? (
                    <EyeOff
                      onClick={() => setType('text')}
                      strokeWidth={'1'}
                      cursor="pointer"
                      size={20}
                    />
                  ) : (
                    <Eye
                      onClick={() => setType('password')}
                      strokeWidth={'1'}
                      cursor="pointer"
                      size={20}
                    />
                  )}
                </InputAdornment>
              }
            />
            <InputSpace />
            <TextField
              label="*Confirmar senha"
              id="passwordConfirm"
              formik={formik}
              type={typeFieldConfirm}
              endAdornment={
                <InputAdornment position="start">
                  {typeFieldConfirm === 'password' ? (
                    <EyeOff
                      onClick={() => setTypeFieldConfirm('text')}
                      strokeWidth={'1'}
                      cursor="pointer"
                      size={20}
                    />
                  ) : (
                    <Eye
                      onClick={() => setTypeFieldConfirm('password')}
                      strokeWidth={'1'}
                      cursor="pointer"
                      size={20}
                    />
                  )}
                </InputAdornment>
              }
            />
            <InputSpace />
            <FormAddress
              formik={formik}
              addressProp={{
                city: infosPipeDrive?.owner_infos?.owner_address?.owner_city,
                country: normalizeCountry(infosPipeDrive?.owner_infos?.owner_address?.owner_country || 'Brasil'),
                neighborhood: infosPipeDrive?.owner_infos?.owner_address?.owner_neighborhood,
                number: infosPipeDrive?.owner_infos?.owner_address?.owner_number,
                postal_code: infosPipeDrive?.owner_infos?.owner_address?.owner_postal_code,
                street: infosPipeDrive?.owner_infos?.owner_address?.owner_street,
                complement: infosPipeDrive?.owner_infos?.owner_address?.owner_complement,
                state: normalizeState(stateMappings, infosPipeDrive?.owner_infos?.owner_address?.owner_state || ''),
              }}
            />
            <InputSpace />

            <ButtonsContainer>
              <FormButton
                dataCy="btn-cancel"
                type="button"
                customColor="grey"
                onClick={() => setIsOpenModal(false)}
              >
                Cancelar
              </FormButton>
              <FormButton dataCy="btn-confirm-block" type="submit">
                Confirmar
              </FormButton>
            </ButtonsContainer>
            <InputSpace />
          </InputsContainer>
        </ModalCard>
      </ModalContainer>
    </>
  );
}
