import styled, { keyframes } from 'styled-components';

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  } to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;

  translate: 0.5s;

  background: #ffffff;
  opacity: 0.5;
`;

export const Container = styled.div`
  z-index: 10;
  position: absolute;

  bottom: 5rem;
  right: 5rem;

  opacity: 0;
  animation: ${zoomIn} 0.4s 0.1s ease forwards;

  @media (max-width: 900px) {
    bottom: 100px;
    right: 20px;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    opacity: 0;

    &:nth-child(n) {
      animation: ${zoomIn} 0.2s 0.05s ease forwards;
    }

    &:nth-child(2n) {
      animation: ${zoomIn} 0.2s 0s ease forwards;
    }
  }

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;
`;

export const MobileSearchModalContainer = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  z-index: 25;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 20px 0;

  flex-direction: column;
  gap: 20px;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.1rem;
    opacity: 0.6;
  }
`;

export const MobileSearchModalBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;

  translate: 0.5s;

  background: white;
  opacity: 1;

  display: flex;
`;

export const SearchContent = styled.div`
  background: red;
  z-index: 20;
`;
