import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0 100px 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ::-webkit-scrollbar{
    width: 5px;
    height: 10px;
    border-radius: 0;
  }

  ::-webkit-scrollbar-track{
    border-radius: 0;
    background: #FFFFFF;
  }
  
  ::-webkit-scrollbar-thumb{
    border-radius: 0;
    background: #C4C4C4;  
  }
`;
