import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Container = styled.form`
  overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
  border-radius: 10px;
  gap: 2rem;

  &::-webkit-scrollbar {
    width: 0.3rem;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background: #c0c0c0;
  }
`;

export const Content = styled.div`
  padding: 30px 30px;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.35s ease forwards;
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.35s ease forwards;
`;

export const Row = styled.div`
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 10px;
`;

export const Text = styled.h1`
  font-weight: bold;
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;

export const Header = styled.div``;

export const AddGuestContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.35s ease forwards;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  max-width: 500px;
  transition: 1s;
  height: 12vh;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #00000018;
  position: fixed;
  bottom: 0;
  top: 100;

  right: 0;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
  background: #ebebf5;

  animation: ${fadeIn} 0.5s 0.45s ease forwards;
  opacity: 0;
`;

export const ButtonsContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  animation: ${fadeIn} 0.5s 0.45s ease forwards;
  opacity: 0;
  grid-area: "buttons";
  align-items: center;
  justify-content: space-between;

  button,
  svg {
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 900px) {
      margin: 0;
      margin-right: 3px;
    }

    @media (max-width: 320px) {
      margin: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 320px) {
    padding-right: 3rem;
  }
`;
export const Error = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.red._400.hex()};
  background: ${({ theme }) => theme.palette.white._400.hex()};
  padding: 5px;
  border-radius: 3px;
  border-color: ${({ theme }) => theme.palette.red._400.hex()};

  animation: ${fadeIn} 0.5s 0.45s ease forwards;
  opacity: 0;

  display: flex;
  align-items: center;
  gap: 20px;

  z-index: 5;

  position: absolute;
  top: 10px;
  left: 1rem;
  margin: 0px 0px 20px 5px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;

export const GuestCapacityText = styled.p`
  font-size: 1.1rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  color: ${({ theme }) => theme.palette.red._400.hex()};
`;
