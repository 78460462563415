import styled from 'styled-components';
import { MAX_SIZE_MOBILE } from '../utils';

export const Container = styled.div`
  position: absolute;
  transition: 0.5s;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  opacity: 1;
  z-index: 25;
  width: 100%;
  max-width: 450px;
  height: 380px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;

  @media(max-width: ${MAX_SIZE_MOBILE}px) {
    max-width: calc(100% - 1rem);
    height: 300px;
  }
`;
