import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.main<{ shouldDisplayStepCard: boolean }>`
  background: ${({ theme }) => theme.palette.white._300.hex()};
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({ shouldDisplayStepCard }) => (shouldDisplayStepCard ? '1fr 320px' : '1fr')};
  column-gap: 1rem;
  padding: 2rem;
  overflow: hidden;

  &::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    &:hover {
      background: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    display: flex;
    gap: 1rem;
  }
`;

export const CardContainer = styled(motion.div)<{ noMobile?: boolean }>`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  padding: 2rem;

  @media(max-width: 900px) {
    display: ${({ noMobile }) => noMobile && 'none'};
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    &:hover {
      background: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }

  scrollbar-color: ${({ theme }) => theme.palette.blue._400.hex()} ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const Title = styled.h1`
  font-size: 2rem;
  color: #394760;
  font-family: 'Inter';
`;

export const ButtonContainer = styled.div`
  width: 20%;
  @media (max-width: 1500px) {
    width: 30%;
  }
  @media (max-width: 1000px) {
    width: 40%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  color: #394760;
  margin-bottom: 1rem;
  font-family: 'Inter';
`;

export const CardsContainer = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;

  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
  }
`;

export const BackInitialButton = styled.button`
  margin-top: 1rem;
  color: #0D4BD0;
  cursor: pointer;
  border: none;
  text-decoration: underline;

  display: flex;
  align-items: center;
  background-color: transparent;
`;
