/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, {
  useState,
  useEffect,
} from 'react';

import {
  Container,
  FormContainer,
  Content,
  Wrapper,
  CardContainer,
  TitleContainer,
  SmallText,
  SmallTextBold,
  MediumTextBold,
} from './styles';

import { useToast } from '../../../../context/ToastContext';
import { translateGender } from '../../../../utils/Translator';
import { useReservation } from '../../../../context/ReservationContext';

import { getBankDetailByIdUser } from '../../../../services/Bank/request';
import { BankDetail } from '../../../../services/Bank/types';
import { getReservationById } from '../../../../services/Reservation/request';
import { Reservation } from '../../../../services/Reservation/types';
import { Gender } from '../../../../services/types';

interface Props {
  props?: object;
  idReservation: number;
}

const GuestForm = ({ idReservation }: Props): JSX.Element => {
  const [dataReservation, setDataReservation] = useState<Reservation>({} as Reservation);

  useEffect(() => {
    async function getReservation() {
      const result = await getReservationById(idReservation);
      setDataReservation(result);
    }
    getReservation();
  }, []);

  const addressGuest = `${dataReservation.guest?.user?.main_address?.street || ''} ${dataReservation.guest?.user?.main_address?.number || ''} ${dataReservation.guest?.user?.main_address?.neighborhood || ''} ${dataReservation.guest?.user?.main_address?.complement || ''} ${dataReservation.guest?.user?.main_address?.city || ''} ${dataReservation.guest?.user?.main_address?.state || ''} ${dataReservation.guest?.user?.main_address?.country || ''} ${dataReservation.guest?.user?.main_address?.postal_code || ''}`;

  const toast = useToast();
  const { reservationData } = useReservation();
  const client = `${reservationData?.guest?.user?.first_name || ''} ${reservationData?.guest?.user?.last_name || ''}`;

  const initialValuesBankDetails = {
    id: 0,
    entity_name: '',
    bank_name: '',
    bank_number: '',
    branch_number: '',
    account_number: '',
    account_type: '',
    cpf: '',
    cnpj: '',
    pix_key: '',
    user: 0,
  };

  const [bankDetail, setBankDetail] = useState<BankDetail>(initialValuesBankDetails);

  const getBankDetails = async () => {
    if (dataReservation.guest?.user?.id) {
      try {
        const idUser = dataReservation.guest?.user?.id;
        const bank: BankDetail[] = await getBankDetailByIdUser(idUser);
        if (bank.length > 0) {
          setBankDetail({ ...bank[0] });
        } else {
          setBankDetail(initialValuesBankDetails);
        }
      } catch (e: any) {
        toast.alert('Não foi possível recuperar os dados');
      }
    }
  };

  useEffect(() => {
    getBankDetails();
    return () => setBankDetail(initialValuesBankDetails);
  }, [dataReservation]);
  const guestName = dataReservation.guest?.user?.trading_name
    ? dataReservation.guest?.user?.trading_name
    : `${dataReservation.guest?.user?.first_name} ${dataReservation.guest?.user?.last_name}`;

  return (
    <Container>
      <FormContainer
        onContextMenu={(event) : void => {
          event.stopPropagation();
        }}
      >
        <Content>
          <CardContainer>
            <TitleContainer>
              <MediumTextBold>Dados Pessoais</MediumTextBold>
            </TitleContainer>
            <Wrapper>
              <>
                <SmallTextBold>Nome completo</SmallTextBold>
                <SmallText>{guestName}</SmallText>
                <SmallTextBold>Sexo</SmallTextBold>
                <SmallText>
                  {translateGender(dataReservation.guest?.user?.gender as Gender)}
                </SmallText>
                <SmallTextBold>Data de nascimento</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.birth_date}`}</SmallText>
                <SmallTextBold>Tipo de pessoa</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.is_individual ? 'Física' : 'Jurídica'}`}</SmallText>
                <SmallTextBold>{`${dataReservation.guest?.user?.is_individual ? 'CPF' : 'CNPJ'}`}</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.is_individual ? dataReservation.guest?.user?.cpf : dataReservation.guest?.user?.cpf}`}</SmallText>
              </>
            </Wrapper>
          </CardContainer>

          <CardContainer>
            <TitleContainer>
              <MediumTextBold>Contato</MediumTextBold>
            </TitleContainer>
            <Wrapper>
              <>
                <SmallTextBold>E-mail</SmallTextBold>
                <SmallText>{dataReservation.guest?.user?.email}</SmallText>
                <SmallTextBold>Telefone/Celular</SmallTextBold>
                <SmallText>{`${dataReservation.guest?.user?.phone_number1}`}</SmallText>
                <SmallTextBold>Endereço</SmallTextBold>
                <SmallText>
                  {addressGuest}
                </SmallText>
              </>
            </Wrapper>
          </CardContainer>

          <CardContainer>
            <TitleContainer>
              <MediumTextBold>Dados bancários</MediumTextBold>
            </TitleContainer>
            <Wrapper>
              <>
                <SmallTextBold>Banco</SmallTextBold>
                <SmallText>{bankDetail?.bank_name || ''}</SmallText>
                <SmallTextBold>Titular</SmallTextBold>
                <SmallText>{client}</SmallText>
                <SmallTextBold>Conta</SmallTextBold>
                <SmallText>{`${bankDetail?.bank_number || ''} ${bankDetail?.branch_number || ''} ${bankDetail?.account_number || ''} ${bankDetail?.account_type || ''}`}</SmallText>
                <SmallTextBold>Pix</SmallTextBold>
                <SmallText>{bankDetail?.pix_key || ''}</SmallText>
              </>
            </Wrapper>
          </CardContainer>
        </Content>
      </FormContainer>
    </Container>
  );
};

export default GuestForm;
