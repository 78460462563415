import React, { useEffect } from 'react';
import LinksPageComponent from '../../components/LinksPage';

import { Container } from './styles';

const CompReservation = () => {
  useEffect(() => {
    document.title = 'Sapron | Links';
  }, []);

  return (
    <>
      <Container>
        <LinksPageComponent />
      </Container>
    </>
  );
};

export default CompReservation;
