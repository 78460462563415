import styled from 'styled-components';

export const B2BContainerCondiminiumName = styled.div`
  width: calc(50% - 4px);
  padding: 0 16px;
  margin-bottom: 8px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const B2BFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 8px;
  padding: 0 16px 16px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const B2BScroll = styled.div`
  height: 80%;
  overflow: auto;

  @media (max-width: 900px) {
    height: 75%;
  }
`;
