import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  padding: 20px;
  background: #fff;
  border-radius: 10px;

  display: flex;
  font-family: "Inter";
  flex-direction: column;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-weight: normal;
  font-size: 1rem;
`;

export const Money = styled.h2`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
`;

export const Content = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
