import { FC } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import moment from 'moment';
import { FormContainer, FormContent, ButtonsContainer } from './styles';

import FormGridRow from '../../../../FormGridRow';

import {
  TextField, RadioTypePerson, RadioGender, DatePicker, FormButton,
} from '../../../../index';

import { useToast } from '../../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../../utils/Messages';
import { useEditForm } from '../../../../../context/EditFormContext';
import { putGuest } from '../../../../../services/Guest/request';
import { useLoader } from '../../../../../context/LoaderContext';
import { useReservationDetails } from '../../../../../context/ReservationDetailsContext';

interface Props {
  activeButton: (a: boolean) => void;
}

const PersonalDataForm: FC<Props> = ({ activeButton }) => {
  const {
    reservationDetails, setReservationDetails, items, setItems,
  } = useReservationDetails();
  const toast = useToast();
  const { setLoad } = useLoader();
  const toastErrorRequest = useToastErrorMessage();
  const { setOpenForm, setFormActive } = useEditForm();

  const {
    id,
    guestFirstName,
    guestLastName,
    isIndividual,
    idNumber,
    cnpj,
    cpf,
    gender,
    address,
    birthDate,
    corporate_name,
    trading_name,
  } = reservationDetails.guest;

  const handleCloseEditForm = () => {
    setOpenForm(false);
    setFormActive(0);
    activeButton(false);
  };

  function replaceAll(
    string: string, search: string, replace: string,
  ) {
    return string.split(search).join(replace);
  }

  const validation = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    gender: Yup.string().required(),
    typePerson: Yup.string().required(),
    born: Yup.string(),
    idNumber: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: guestFirstName,
      lastName: guestLastName,
      gender,
      typePerson: isIndividual ? 'individual' : 'legal',
      cpf,
      cnpj,
      idNumber,
      born: new Date(moment(birthDate).format('YYYY-MM-DD')),
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      setLoad(true);
      try {
        const guest: any = {
          id: reservationDetails.guest.id,
          user: {
            corporate_name,
            trading_name,
            first_name: values.firstName,
            last_name: values.lastName,
            gender: values.gender,
            is_individual: values.typePerson === 'individual',
            birth_date: moment(values.born).format('YYYY-MM-DD'),
            cpf: replaceAll(
              replaceAll(
                values.cpf, '.', '',
              ), '-', '',
            ),
            cnpj: replaceAll(
              replaceAll(
                values.cpf, '.', '',
              ), '-', '',
            ).replace('/', ''),
            id_number: values.idNumber,
            main_role: 'Guest',
            main_address: address,
          },
        };
        if (
          guest.user.cpf === cpf
          || values.typePerson !== 'individual'
        ) {
          delete guest.user.cpf;
        }
        if (
          values.typePerson === 'individual'
          || cnpj === guest.user.cnpj
        ) {
          delete guest.user.cnpj;
        }

        await putGuest(guest.id, guest);

        guest.user.cpf = cpf;
        guest.user.cnpj = cnpj;
        guest.user.id = id;

        const newReservation = {
          ...reservationDetails,
          guest: {
            ...reservationDetails.guest,
            client: `${values.firstName} ${values.lastName}`,
            guestFirstName: values.firstName,
            guestLastName: values.lastName,
            birthDate: guest.user.birth_date,
            idNumber: guest.user.id_number,
            cpf: values.cpf,
            cnpj: values.cnpj,
            isIndividual: guest.user.is_individual,
          },
        };

        setReservationDetails(newReservation);

        const newItems = items
          .map((item: any) => {
            if (item.id !== reservationDetails.id) return item;

            return {
              ...item,
              title: `${values.firstName} ${values.lastName}`,
            };
          });

        setItems(newItems);
        toast.success('Os dados pessoais foram salvos');
        setLoad(false);
      } catch (e: unknown) {
        if (e instanceof Error) {
          setLoad(false);
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <FormContainer
      onContextMenu={(event): void => {
        event.stopPropagation();
      }}
    >
      <FormContent onSubmit={formik.handleSubmit}>
        <FormGridRow>
          <TextField
            label="Primeiro nome"
            formik={formik}
            id="firstName"
            value={guestFirstName}
          />
        </FormGridRow>
        <FormGridRow>
          <TextField
            label="Restante do nome"
            formik={formik}
            id="lastName"
            value={guestLastName}
          />
        </FormGridRow>

        <FormGridRow>
          <RadioGender labelPlacement="end" id="gender" formik={formik} />
        </FormGridRow>

        <FormGridRow>
          <DatePicker
            formik={formik}
            label="*Data de nascimento"
            id="born"
            minDate={new Date('1850-01-02')}
          />
        </FormGridRow>

        <FormGridRow>
          <RadioTypePerson labelPlacement="end" id="typePerson" formik={formik} />
        </FormGridRow>

        {formik.values.typePerson === 'individual' ? (
          <>
            <FormGridRow>
              <TextField
                label="*CPF"
                formik={formik}
                id="cpf"
                mask="cpf"
                value={cpf}
              />
            </FormGridRow>
            <FormGridRow>
              <TextField
                label="Nº de identidade"
                formik={formik}
                id="idNumber"
                value={idNumber}
              />
            </FormGridRow>
          </>
        ) : (
          <>
            <FormGridRow>
              <TextField
                label="*CNPJ"
                formik={formik}
                id="cnpj"
                mask="cnpj"
                value={cnpj}
              />
            </FormGridRow>
            <FormGridRow>
              <TextField label="Razão social" formik={formik} id="razao" />
            </FormGridRow>
          </>
        )}

        <ButtonsContainer>
          <FormButton type="button" customColor="grey" onClick={() => handleCloseEditForm()}>
            Cancelar
          </FormButton>
          <FormButton onClick={() => formik.handleSubmit}>Salvar</FormButton>
        </ButtonsContainer>
      </FormContent>
    </FormContainer>
  );
};

export default PersonalDataForm;
