import { CopyContainer } from './styles';
import copyIcon from '../../../../assets/icons/generals/copy.svg';
import { useToast } from '../../../../context/ToastContext';
import Tooltip from '../../../Tooltip';

interface CopyItemButtonProps {
  copyItem?: number;
}

const Copy = () => (
  <img src={copyIcon} alt="icone de copiar" className="copyIcon" />
);

function CopyItem({ copyItem }: CopyItemButtonProps) {
  const toast = useToast();
  function handleClick(text: string) {
    navigator.clipboard.writeText(text);
    toast.alert('Copiado!');
  }
  return (
    <Tooltip text="Copiar Id">
      <CopyContainer whileTap={{ scale: 0.8 }} onClick={() => handleClick(String(copyItem) || '')}>
        <Copy />
      </CopyContainer>
    </Tooltip>
  );
}

CopyItem.defaultProps = {
  copyItem: undefined,
};

export default CopyItem;
