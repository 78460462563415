import { FC, useState } from 'react';

import {
  Content,
  Wrapper,
  CardContainer,
  TitleContainer,
  SmallText,
  SmallTextBold,
  MediumTextBold,
} from './styles';

import { translateGender } from '../../../utils/Translator';
import PersonalDataForm from './EditGuestForm/Personal';
import ContactDataForm from './EditGuestForm/Contact';

import { useReservationDetails } from '../../../context/ReservationDetailsContext';

interface Props {
  props?: object;
}

const GuestForm: FC<Props> = () => {
  const { reservationDetails } = useReservationDetails();

  const {
    phoneNumber1,
    phoneNumber2,
    client,
    born,
    isIndividual,
    idNumber,
    cnpj,
    cpf,
    email,
    gender,
    address,
    addressGuest,
  } = reservationDetails.guest;

  const [openPersonalForm, setOpenPersonalForm] = useState<boolean>(false);
  const [openContactForm, setOpenContactForm] = useState<boolean>(false);

  const {
    street,
    number,
    neighborhood,
    city,
    state,
    country,
    postalCode,
  } = address || '';

  return (
    <Content>
      <CardContainer>
        <TitleContainer>
          <MediumTextBold>Dados Pessoais</MediumTextBold>
          {/* {!roles.includes('Host') && !openPersonalForm && (
            <FormButton type="button" onClick={() => setOpenPersonalForm(true)}>
              Editar
            </FormButton>
          )} */}
        </TitleContainer>
        <Wrapper>
          {openPersonalForm ? (
            <PersonalDataForm activeButton={setOpenPersonalForm} />
          ) : (
            <>
              <SmallTextBold>Nome completo</SmallTextBold>
              <SmallText>{client}</SmallText>
              <SmallTextBold>Sexo</SmallTextBold>
              <SmallText>{translateGender(gender)}</SmallText>
              <SmallTextBold>Data de nascimento</SmallTextBold>
              <SmallText>{`${born}`}</SmallText>
              <SmallTextBold>Tipo de pessoa</SmallTextBold>
              <SmallText>{`${isIndividual ? 'Física' : 'Jurídica'}`}</SmallText>
              <SmallTextBold>{`${isIndividual ? 'CPF' : 'CNPJ'}`}</SmallTextBold>
              <SmallText>{`${isIndividual ? cpf : cnpj}`}</SmallText>
              <SmallTextBold>Nº de identidade</SmallTextBold>
              <SmallText>{idNumber}</SmallText>
            </>
          )}
        </Wrapper>
      </CardContainer>

      <CardContainer>
        <TitleContainer>
          <MediumTextBold>Contato</MediumTextBold>
          {/* {!roles.includes('Host') && !openContactForm && (
            <FormButton type="button" onClick={() => setOpenContactForm(true)}>
              Editar
            </FormButton>
          )} */}
        </TitleContainer>
        <Wrapper>
          {openContactForm ? (
            <ContactDataForm
              email={email}
              phone1={phoneNumber1}
              street={street}
              zipCode={postalCode}
              number={number}
              neighborhood={neighborhood}
              city={city}
              state={state}
              country={country}
              addressExists={address !== null}
              activeButton={setOpenContactForm}
            />
          ) : (
            <>
              <SmallTextBold>E-mail</SmallTextBold>
              <SmallText>{email}</SmallText>
              <SmallTextBold>Telefone/Celular</SmallTextBold>
              <SmallText>{`${phoneNumber1} ${phoneNumber2}`}</SmallText>
              <SmallTextBold>Endereço</SmallTextBold>
              <SmallText>{addressGuest}</SmallText>
            </>
          )}
        </Wrapper>
      </CardContainer>
    </Content>
  );
};

export default GuestForm;
