import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { createContext } from 'use-context-selector';

import { PaymentDataProps } from './types/paymentData.types';
import { PaymentProps } from './types/paymentStatus.types';

import { checkedListInitialValues, paymentDataInitialValues, paymentVoucherInitialValues } from './utils';

interface PaymentVoucherContextProps {
  isFinishOpen: boolean;
  setIsFinishOpen: Function;
  isIncompleteOpen: boolean;
  setIsIncompleteOpen: Function;
  isTwoCardOpen: boolean;
  setIsTwoCardOpen: Function;
  paymentData: PaymentDataProps[];
  setPaymentData: Function;
  paymentDataStatus: PaymentProps ;
  setPaymentDataStatus: Function;
  loading: boolean;
  setLoading: Function;
  checked: boolean;
  setChecked: Function;
  modalOpen: boolean;
  setModalOpen: Function;
  checkedList: PaymentProps;
  setCheckedList: Function;
  checkedCounter: number;
  setCheckedCounter: Function;
  reservationIdEditedButton: number;
  setReservationIdEditedButton: Function;
}

export const PaymentVoucherContext = createContext<PaymentVoucherContextProps>({
  isFinishOpen: false,
  setIsFinishOpen: () => {},
  isIncompleteOpen: false,
  setIsIncompleteOpen: () => {},
  isTwoCardOpen: false,
  setIsTwoCardOpen: () => {},
  paymentData: paymentDataInitialValues,
  setPaymentData: () => {},
  paymentDataStatus: paymentVoucherInitialValues,
  setPaymentDataStatus: () => {},
  loading: false,
  setLoading: () => {},
  checked: false,
  setChecked: () => {},
  modalOpen: false,
  setModalOpen: () => {},
  checkedList: checkedListInitialValues,
  setCheckedList: () => {},
  checkedCounter: 0,
  setCheckedCounter: () => {},
  reservationIdEditedButton: 0,
  setReservationIdEditedButton: () => {},
});

export const PaymentVoucherProvider: React.FC = ({ children }) => {
  const [
    reservationIdEditedButton,
    setReservationIdEditedButton,
  ] = useState<number>(0);

  const [
    checked,
    setChecked,
  ] = useState(false);

  const [
    modalOpen,
    setModalOpen,
  ] = useState(false);

  const [
    isFinishOpen,
    setIsFinishOpen,
  ] = useState(false);

  const [
    isIncompleteOpen,
    setIsIncompleteOpen,
  ] = useState(false);

  const [
    isTwoCardOpen,
    setIsTwoCardOpen,
  ] = useState(isFinishOpen && isIncompleteOpen);

  const [
    paymentData,
    setPaymentData,
  ] = useState<PaymentDataProps[]>(paymentDataInitialValues);

  const [
    paymentDataStatus,
    setPaymentDataStatus,
  ] = useState<PaymentProps>(paymentVoucherInitialValues);

  const [
    checkedList,
    setCheckedList,
  ] = useState<PaymentProps>(checkedListInitialValues);

  const [
    checkedCounter,
    setCheckedCounter,
  ] = useState(0);

  const [
    loading,
    setLoading,
  ] = useState<boolean>(false);

  const handleChangeReservationIdEditedButton = useCallback((id: number) => {
    setReservationIdEditedButton(id);
  }, []);

  const handleChangeLoading = useCallback((newLoading: boolean) => {
    setLoading(newLoading);
  }, []);

  const handleChangeIsFinishOpen = useCallback((newData: boolean) => {
    setIsFinishOpen(newData);
  }, []);

  const handleChangeIsIncompleteOpen = useCallback((newData: boolean) => {
    setIsIncompleteOpen(newData);
  }, []);

  const handleChangeIsTwoCardsOpen = useCallback((newData: boolean) => {
    setIsTwoCardOpen(newData);
  }, []);

  const handleChangePaymentData = useCallback((newData: PaymentDataProps[]) => {
    setPaymentData(newData);
  }, []);

  const handleChangePaymentDataStatus = useCallback((newData: PaymentProps) => {
    setPaymentDataStatus(newData);
  }, []);

  const handleChangeChecked = useCallback((newData: boolean) => {
    setChecked(newData);
  }, []);

  const handleChangeModalOpen = useCallback((newData: boolean) => {
    setModalOpen(newData);
  }, []);

  const handleChangeCheckedList = useCallback((newData: PaymentProps) => {
    setCheckedList(newData);
  }, []);

  const handleChangeCheckedCounter = useCallback((newData: number) => {
    setCheckedCounter(newData);
  }, []);

  useEffect(() => {
    if (isIncompleteOpen && isFinishOpen) {
      setIsTwoCardOpen(true);
    } else {
      setIsTwoCardOpen(false);
    }
  }, [isIncompleteOpen, isFinishOpen]);

  const values = useMemo(() => ({
    isFinishOpen,
    setIsFinishOpen: handleChangeIsFinishOpen,
    isIncompleteOpen,
    setIsIncompleteOpen: handleChangeIsIncompleteOpen,
    isTwoCardOpen,
    setIsTwoCardOpen: handleChangeIsTwoCardsOpen,
    paymentData,
    setPaymentData: handleChangePaymentData,
    paymentDataStatus,
    setPaymentDataStatus: handleChangePaymentDataStatus,
    loading,
    setLoading: handleChangeLoading,
    checked,
    setChecked: handleChangeChecked,
    modalOpen,
    setModalOpen: handleChangeModalOpen,
    checkedList,
    setCheckedList: handleChangeCheckedList,
    checkedCounter,
    setCheckedCounter: handleChangeCheckedCounter,
    reservationIdEditedButton,
    setReservationIdEditedButton: handleChangeReservationIdEditedButton,
  }), [
    isFinishOpen,
    handleChangeIsFinishOpen,
    isIncompleteOpen,
    handleChangeIsIncompleteOpen,
    isTwoCardOpen,
    handleChangeIsTwoCardsOpen,
    paymentData,
    handleChangePaymentData,
    paymentDataStatus,
    handleChangePaymentDataStatus,
    loading,
    handleChangeLoading,
    checked,
    handleChangeChecked,
    modalOpen,
    handleChangeModalOpen,
    checkedList,
    handleChangeCheckedList,
    checkedCounter,
    handleChangeCheckedCounter,
    reservationIdEditedButton,
    handleChangeReservationIdEditedButton,
  ]);

  return (
    <PaymentVoucherContext.Provider
      value={values}
    >
      {children}
    </PaymentVoucherContext.Provider>
  );
};
