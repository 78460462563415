import { useGuestDamageNegotiation } from '../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { SearchForm } from './SearchForm';
import { SearchTable } from './SearchTable';
import { SearchCardContainer, Title } from './styles';

interface SearchCardProps {
  havePendency: boolean;
}

export const SearchCard = ({ havePendency }: SearchCardProps) => {
  const { guestDamageNegotiations, isLoading } = useGuestDamageNegotiation();
  const isLoadingData = isLoading === true;
  const isLoadedData = guestDamageNegotiations.length > 0 && isLoading === false;

  function getTitle() {
    if (isLoadingData) {
      return 'Buscando...';
    }
    if (isLoadedData) {
      return 'Resultado de busca';
    }
    return 'Selecione os filtros e clique em buscar para exibir os dados!';
  }

  return (
    <SearchCardContainer>
      <SearchForm havePendency={havePendency} />
      <Title>{getTitle()}</Title>
      <SearchTable isLoading={isLoading} />
    </SearchCardContainer>
  );
};
