import styled from 'styled-components';

export const TitleFilter = styled.div`
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.white._300.hex()};
  border-radius: 0 15px 15px 0;
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

export const Text = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.palette.blue._950.hex()};
`;

export const Row = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-top: 12px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${({ theme }) => theme.palette.white._230.hex()};
  position: fixed;
  bottom: 0;
  padding: 12px 0;

  button {
    max-width: 93px !important;
  }

  @media(min-width: 900px) {
    width: 270px;
  }
`;

export const FormContent = styled.form<{ disableScroll?: boolean }>`
  width: 100%;
  height: 100%;
  padding-bottom: 60px;

  @media(min-width: 900px) {
    max-width: 260px;
  }
`;
