import React from 'react';

import {
  Value,
  Content,
  Container,
  OwnerContainer,
  BedroomsContainer,
  // CommissionContainer,
  TaxCleaningContainer,
  StartOfContractContainer,
} from './styles';

import {
  Status,
  StatusProps,
} from './Status/status';

import Address from './Address';
import Immobile from './Immobile';
import ProprietaryPhone from './ProprietaryPhone';
import StatusComponent from './Status/StatusComponent';
import { useHostPropertiesFilter } from '../../../../hooks/useHostPropertiesFilter/useHostPropertiesFilter';
import { AddressDetail } from '../../../../services/Address/types';
import ExpansiveBedsItem from './ExpansiveBedsItem';
import ExpansiveBathroomsItem from './ExpansiveBathroomsItem';
import { formatedDateToShow } from '../../../../utils/Formatter';
import { useUser } from '../../../../context/UserContext';
import Category from './Category/Category';
import ImmobileID from './ImmobileID/ImmobileID';

interface ItemsProps {
  beds?: {
    double_bed_quantity: number;
    double_sofa_bed_quantity: number;
    king_bed_quantity: number;
    queen_bed_quantity: number;
    single_bed_quantity: number;
    single_sofa_bed_quantity: number;
    total: number;
  };
  owner?: string;
  address?: AddressDetail;
  immobile?: string;
  bedrooms?: number;
  bathrooms?: {
    bathroom_quantity: number,
    lavatory_quantity: number,
    total: number,
  };
  // commission?: string;
  taxCleaning?: string;
  status?: StatusProps;
  startOfContract?: string;
  proprietaryPhone?: string;
  id: number;
  host: string,
  category: string;
}

const Items = ({
  beds,
  owner,
  status,
  address,
  immobile,
  bedrooms,
  bathrooms,
  category,
  // commission,
  taxCleaning,
  startOfContract,
  proprietaryPhone,
  id,
  host,
}: ItemsProps) => {
  const { bedsGridExpanded, bathroomsGridExpanded } = useHostPropertiesFilter();
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];
  const validateHostSearch: boolean = roles.includes('SeazoneOnboarding') || roles.includes('Admin') || roles.includes('Attendant');

  const defaultAddress = {
    id: 0,
    street: 'Av. Beira Mar - Centro',
    number: '',
    complement: '',
    neighborhood: '',
    city: 'Rio de Janeiro',
    state: 'RJ',
    postal_code: '20021-060',
    country: 'Brasil',
  };
  const defaultBedQuatity = {
    double_bed_quantity: 0,
    double_sofa_bed_quantity: 0,
    king_bed_quantity: 0,
    queen_bed_quantity: 0,
    single_bed_quantity: 0,
    single_sofa_bed_quantity: 0,
    total: 0,
  };
  const defaultBathrooms = {
    bathroom_quantity: 0,
    lavatory_quantity: 0,
    total: 0,
  };
  // const formattedCommission = Number(commission) * 100;

  return (
    <Container data-cy="grid-row-container">
      <Content
        isBedExpanded={bedsGridExpanded}
        isBathroomsExpanded={bathroomsGridExpanded}
        validateHostSearch={validateHostSearch}
      >
        <ImmobileID dataCy={`propertie-${id}-code`} immobile={id || 0} />
        <Immobile dataCy={`propertie-${id}-code`} id={id} immobile={immobile || ''} />
        <Category dataCy={`property-${id}-category`} category={category || ''} />
        <StatusComponent dataCy={`propertie-${id}-status`} status={status || Status.Default} />

        <StartOfContractContainer>
          <Value>{startOfContract ? formatedDateToShow(startOfContract) : ''}</Value>
        </StartOfContractContainer>

        <OwnerContainer>
          <Value>{owner}</Value>
        </OwnerContainer>

        <ProprietaryPhone proprietaryPhone={proprietaryPhone || ''} />
        {(roles.includes('SeazoneOnboarding') || roles.includes('Admin') || roles.includes('Attendant')) && (
        <OwnerContainer>
          <Value>{host}</Value>
        </OwnerContainer>
        )}
        <Address address={address || defaultAddress} />

        {/* <CommissionContainer>
          <Value>
            {formattedCommission}
            %
          </Value>
        </CommissionContainer> */}

        <TaxCleaningContainer>
          <Value>
            R$
            {' '}
            {taxCleaning}
          </Value>
        </TaxCleaningContainer>

        <ExpansiveBedsItem
          open={bedsGridExpanded}
          value={beds || defaultBedQuatity}
        />

        <BedroomsContainer>
          <Value
            data-cy={`propertie-${id}-rooms`}
          >
            {bedrooms}
          </Value>
        </BedroomsContainer>

        <ExpansiveBathroomsItem
          open={bathroomsGridExpanded}
          value={bathrooms || defaultBathrooms}
        />
      </Content>
    </Container>
  );
};

Items.defaultProps = {
  beds: '109',
  bedrooms: 15,
  bathrooms: {
    bathroom_quantity: 0,
    lavatory_quantity: 0,
    total: 0,
  },
  // commission: '40%',
  immobile: 'ADJ205',
  status: Status.Inactive,
  taxCleaning: '223,00',
  startOfContract: '12/12/2021',
  proprietaryPhone: '+55 17 22444-2422',
  owner: 'Alina Santiago da Silva Lopes',
  address: {
    id: 0,
    street: 'Av. Beira Mar - Centro',
    number: '',
    complement: '',
    neighborhood: '',
    city: 'Rio de Janeiro',
    state: 'RJ',
    postal_code: '20021-060',
    country: 'Brasil',
  },

};

export default Items;
