import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  padding: 30px;
  display: flex;
  background: #E5E5E5;
  align-items: center;
  flex-direction: column;

  @media (min-width: 1000px) {
    height: 100vh;
  }

  span{
    color: ${({ theme }) => theme.palette.green.main.hex()};
    font-weight: bold;
    font-size: 1.4rem;
    font-family: monospace;
    background: white;
    padding: 15px 20px;
    border-radius: 10px;
    width: fit-content;
  }

  div{
    display: flex;
    flex-direction: column;
    gap: -10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
  }

  h2{
    font-weight: bold;
    text-align: left;
    font-size: 1.5rem;
    margin-top: 1rem;
    color: ${({ theme }) => theme.palette.grey._720.hex()};
  }

  p{
    font-size: 1.5rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.palette.grey._600.hex()};
  }
`;
