import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: end;
  flex-direction: row;
  justify-content: space-between;
`;

export const TagContainer = styled.div<{
  active?: boolean;
}>`
  border: 0.5px solid ${({ active }) => (active ? '#7BD1B7' : '#cccc')};
  padding: 5px;
  border-radius: 10px;

  p{
    font-weight: bold;
    font-size: 0.9rem;
  }
`;

export const ReserveButton = styled.button`
  border: none;
  background: transparent;

  gap: 10px;
  display: flex;
  flex-direction: row;

  color: white;
  font-weight: bold;
`;
