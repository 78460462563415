import styled from 'styled-components';

export const Container = styled.div``;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
