import { FC, useEffect, useState } from 'react';
import { getAddressByCep } from '../../../../../services/Address/request';
import { GetOwnersData } from '../../../../../services/InsertData/types';
import { onlyNumbers } from '../../../../../utils/Formatter';
import { UseFormik } from '../../../../../utils/Formik/types';
import { compareList } from '../../../../../utils/Sorting';
import { SelectProps } from '../../../../Autocomplete/Autocomplete';
import ControlledSwitchButton from '../../../../ControlledSwitchButton';
import DatePicker from '../../../../DatePicker';
import DropdownAutocomplete from '../../../../DropdownAutocomplete';
import SimpleSelect from '../../../../SimpleSelect';
import CountrySelect from '../../../../SimpleSelect/CountrySelect';
import StateSelect from '../../../../SimpleSelect/StateSelect';
import TextField from '../../../../TextField';
import { Container, PasswordText } from './styles';
import { getPartners } from '../../../../../services/Partner';
import { RequestPartner } from '../../../../../services/Partner/types';

const genderOptions = [
  {
    value: 'Not informed',
    valueLabel: 'Não informado',
  },
  {
    value: 'Male',
    valueLabel: 'Masculino',
  },
  {
    value: 'Female',
    valueLabel: 'Feminino',
  },
];

type FormState = 'user' | 'partner';
type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed' | undefined;
interface AddPartnerFormikValuesType {
  formType: FormState;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber1: string;
  phoneNumber2: string;
  gender: string;
  birthDate: Date | undefined;
  cpf?: string;
  cnpj?: string;
  corporateName: string;
  tradingName: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  nationality: string;
  maritalStatus: MaritalStatus;
  profession: string;
  emailForOperation: string;
  zipCode: string;
  partnerId?: number;
  isIndividual: boolean;
  signedContract: boolean;
  link: string,
  phoneNumberAttendent: string,
  attendent: string,
  executive: number,
  pipedrivePartnerId?: string,
  pipedrivePersonId?: string,
}

type FormAddUserProps = {
  formik?: UseFormik<AddPartnerFormikValuesType>,
  isEditingForm: boolean,
  handleChangePersonType: (manualType?: boolean) => void;
};

const FormAddUser:FC<FormAddUserProps> = ({
  formik, isEditingForm, handleChangePersonType,
}) => {
  const [partnersList, setPartnersList] = useState<RequestPartner[]>([]);
  const partnersIds: string[] = [];
  const partnersNames: string[] = [];

  async function handleNewAddress() {
    if (formik) {
      const response = await getAddressByCep(onlyNumbers(formik.values.postalCode));

      if ('erro' in response) {
        formik.setFieldError('postalCode', 'O cep não é válido!');
      } else {
        const {
          bairro, complemento, localidade, logradouro, uf,
        } = response;
        formik.setFieldValue('neighborhood', bairro);
        formik.setFieldValue('complement', complemento);
        formik.setFieldValue('city', localidade);
        formik.setFieldValue('street', logradouro);
        formik.setFieldValue('state', uf);
        formik.setFieldValue('country', 'Brasil');
        formik.setFieldValue('number', '');
      }
    }
  }

  const getSelectPartners = async () => {
    const results = await getPartners();
    setPartnersList(results);
  };

  const handleGetPartner = (): SelectProps[] => {
    partnersList.forEach((partner) => {
      partnersIds.push(`${partner.id}`);
      partnersNames.push(`${partner.user.first_name} ${partner.user.last_name}`);
    });

    return partnersNames
      .map((name, index) => ({
        optionText: name,
        optionValue: partnersIds[index],
      }))
      .sort((a, b) => compareList(a.optionText, b.optionText));
  };

  const getPartnerSelected = (partnerId: number) => {
    const index = partnersIds.findIndex((id) => Number(id) === partnerId);
    return partnersNames?.[index] || '';
  };

  useEffect(() => {
    getSelectPartners();
  }, []);

  useEffect(() => {
    if (formik) {
      if (formik?.values.postalCode.length >= 9) {
        handleNewAddress();
      }
    }
  }, [formik?.values.postalCode]);

  return (
    <Container>
      {isEditingForm && (
        <div className="one-column">
          <DropdownAutocomplete
            required
            label="Parceiro"
            id="partnerId"
            options={handleGetPartner()}
            formik={formik}
            placeholder={formik?.values?.partnerId ? `${getPartnerSelected(formik?.values?.partnerId)}` : 'Digite aqui...'}
            labelClassName="labelClass"
          />
        </div>
      )}

      <div className="two-columns">
        <TextField
          required
          id="firstName"
          label="Nome"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.firstName}
          disabled={isEditingForm}
        />
        <TextField
          required
          id="lastName"
          label="Sobrenome"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.lastName}
          disabled={isEditingForm}
        />
      </div>
      <div className="one-column">
        <TextField
          id="nickname"
          label="Apelido"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.nickname}
        />
      </div>
      <div className="one-column">
        <TextField
          required={!isEditingForm}
          id="email"
          type="email"
          label="E-mail"
          placeholder="Digite aqui..."
          formik={formik}
          labelClassName="labelClass"
          value={formik?.values.email}
        />
      </div>
      {!isEditingForm && (
        <>
          <div className="two-columns">
            <TextField
              required
              labelClassName="labelClass"
              id="password"
              type="password"
              label="Senha"
              placeholder="Digite aqui..."
              formik={formik}
              value={formik?.values.password}
            />
            <TextField
              required
              labelClassName="labelClass"
              value={formik?.values.passwordConfirmation}
              id="passwordConfirmation"
              type="password"
              label="Confirmar senha"
              placeholder="Digite aqui..."
              formik={formik}
            />
          </div>
          <div className="one-column">
            <PasswordText>
              no mín.: 8 caracteres, diferente do nome ou outra informação pessoal,
              conter letras e números, não pode ser senha comum
            </PasswordText>
          </div>
        </>
      )}
      <div className="two-columns">
        <TextField
          required={!isEditingForm}
          labelClassName="labelClass"
          value={formik?.values.phoneNumber1}
          id="phoneNumber1"
          mask="phone"
          label="Telefone 1"
          placeholder="+00 (00) 00000-0000"
          formik={formik}
        />
        <TextField
          labelClassName="labelClass"
          value={formik?.values.phoneNumber2}
          id="phoneNumber2"
          mask="phone"
          label="Telefone 2"
          placeholder="+00 (00) 00000-0000"
          formik={formik}
        />
      </div>
      <div className="two-columns">
        <SimpleSelect
          required
          id="gender"
          label="Gênero"
          labelClassName="labelClass"
          className="selector"
          options={genderOptions}
          placeholder="Selecione..."
          firstValue="not_informed"
          formik={formik}
        />
        <DatePicker
          label="Data de nascimento"
          id="birthDate"
          viewsCustom={['day', 'month', 'year']}
          labelClassName="labelClass"
          disableFuture
          showDaysOutsideCurrentMonth
          formik={formik}
        />
      </div>
      <div className="one-column">
        <div className="switch">
          <h2>É pessoa jurídica?</h2>
          <ControlledSwitchButton
            checked={!formik?.values.isIndividual}
            handleChange={() => handleChangePersonType()}
          />
        </div>
      </div>
      {!formik?.values.isIndividual ? (
        <>
          <div className="one-column">
            <TextField
              required={!isEditingForm && !formik?.values.isIndividual}
              labelClassName="labelClass"
              value={formik?.values.cnpj}
              id="cnpj"
              mask="cnpj"
              label="CNPJ"
              placeholder="Apenas números"
              formik={formik}
            />
          </div>
          <div className="two-columns">
            <TextField
              labelClassName="labelClass"
              value={formik?.values.corporateName}
              id="corporateName"
              label="Razão social"
              placeholder="Digite aqui..."
              formik={formik}
              required={!formik?.values.isIndividual}
            />
            <TextField
              labelClassName="labelClass"
              value={formik?.values.tradingName}
              id="tradingName"
              label="Nome fantasia"
              placeholder="Digite aqui..."
              formik={formik}
              required={!formik?.values.isIndividual}
            />
          </div>
        </>
      ) : (
        <div className="one-column">
          <TextField
            required={!isEditingForm && formik.values.isIndividual}
            labelClassName="labelClass"
            value={formik?.values.cpf}
            id="cpf"
            mask="cpf"
            label="CPF"
            placeholder="Apenas números"
            formik={formik}
          />
        </div>
      )}

      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          value={formik?.values.postalCode}
          id="postalCode"
          mask="cep"
          label="CEP"
          placeholder="Apenas números"
          formik={formik}
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          value={formik?.values.street}
          id="street"
          label="Endereço do parceiro"
          placeholder="Digite aqui..."
          formik={formik}
          required
        />
      </div>
      <div className="two-columns">
        <TextField
          labelClassName="labelClass"
          value={formik?.values.number}
          id="number"
          label="Número"
          placeholder="Apenas números"
          formik={formik}
        />
        <TextField
          labelClassName="labelClass"
          value={formik?.values.complement}
          id="complement"
          label="Complemento"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          value={formik?.values.neighborhood}
          id="neighborhood"
          label="Bairro"
          placeholder="Digite aqui..."
          formik={formik}
        />
        <TextField
          required
          labelClassName="labelClass"
          value={formik?.values.city}
          id="city"
          label="Cidade"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="two-columns">
        <StateSelect
          required
          id="state"
          formik={formik}
          label="Estado"
          labelClassName="labelClass"
        />
        <CountrySelect
          required
          id="country"
          formik={formik}
          label="País"
          labelClassName="labelClass"
          valueIsFullName
        />
      </div>
    </Container>
  );
};

export default FormAddUser;
