/* eslint-disable max-len */
import React from 'react';

import { v4 as uuid } from 'uuid';
import { FormikValues } from 'formik';
import { useFile } from '../FileHook/useFile';
import { UseFormik } from '../../utils/Formik/types';
import { useToast } from '../../context/ToastContext';

import {
  FileAWSFormat,
  FileProps,
  FileReference,
  FileReferenceResponse,
} from '../../context/FileContext/types';

import addPhotoSketchIcon from '../../assets/icons/expense/photoSketch.svg';
import { IFileReferenceCustomHeader } from '../../context/FileContext/FileContext';

export interface Callbacks {
  error?: Function;
  success?: (file?: FileProps) => void;
}

interface Config {
  createFileReference?: {
    params?: FileReference;
    customHeader?: IFileReferenceCustomHeader;
  };
}

type UseSingleUpload<T extends FormikValues, X extends string> = {
  config?: Config;
  formik?: UseFormik<T>;
  callbacks?: Callbacks;
  type?: X;
  sendToS3?: boolean;
};

export default function useSingleUpload<T extends FormikValues, X extends string>({
  type,
  formik,
  config,
  callbacks,
  sendToS3 = true,
}: UseSingleUpload<T, X>) {
  const toast = useToast();
  const { createFileReference, uploadFileToS3 } = useFile();

  const [loading, setLoading] = React.useState(false);
  const [fileClicked, setFileClicked] = React.useState(false);

  const [file, setFile] = React.useState<FileProps>({
    MIMEtype: '',
    error: false,
    uploaded: false,
    uploadProgress: 0,
    readableSize: '0 KB',
    uid: formik?.values[type as keyof T]?.uid || '',
    size: formik?.values[type as keyof T]?.size || 0,
    name: formik?.values[type as keyof T]?.name || '',
    id: formik?.values[type as keyof T]?.uid || uuid(),
    url: formik?.values[type as keyof T]?.url || addPhotoSketchIcon,
    file: formik?.values[type as keyof T]?.url || addPhotoSketchIcon,
    previewURL: formik?.values[type as keyof T]?.url || addPhotoSketchIcon,
  });

  const handleUploadFile = async (newFile: FileProps) => {
    let fileResponse: FileReferenceResponse | { uid: null } = { uid: null };
    try {
      setLoading(true);
      const fileReference: FileReference = {
        name: config?.createFileReference?.params?.name || newFile.name,
        category: config?.createFileReference?.params?.category || 'document',
        content_type:
          config?.createFileReference?.params?.content_type || newFile.MIMEtype,
      };

      const responseFile: FileReferenceResponse = await createFileReference(fileReference,
        config?.createFileReference?.customHeader);

      const params: FileAWSFormat = {
        file: file.file,
        fileId: responseFile.uid,
        url: responseFile.storage.url,
        key: responseFile.storage.fields.key,
        acl: responseFile.storage.fields.acl,
        content_type: fileReference.content_type,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
      };

      if (sendToS3) {
        await uploadFileToS3(newFile, params);
      }

      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
    } catch (e: unknown) {
      toast.error('Não foi possível fazer o upload do documento!');
    } finally {
      setLoading(false);
    }

    return fileResponse;
  };

  const handleSelectFile = async (newFile: FileProps) => {
    if (!fileClicked) {
      setFileClicked(true);
      let responseDocumentFile: FileReferenceResponse | { uid: null } = {
        uid: null,
      };
      responseDocumentFile = await handleUploadFile(newFile);
      if (!responseDocumentFile.uid) {
        toast.error('Não foi possível realizar o upload do arquivo');
        if (callbacks?.error) {
          callbacks?.error();
        }
      } else {
        if (callbacks?.success) {
          callbacks?.success({
            ...newFile,
            uid: responseDocumentFile?.uid || '',
          });
        }
        setFile((prevFile) => ({
          ...prevFile,
          ...newFile,
          uid: responseDocumentFile?.uid || '',
        }));
      }
    }
  };

  return {
    loading,
    file,
    onUpload: handleSelectFile,
  };
}
