import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { TextField } from '../../../../../..';

import { BodyContent, Container, PhotoContainer } from './styles';

import UploadComponent from './Upload';
import { FileProps } from '../../../../../../../context/FileContext/types';
import { Guest } from '../../../../../../../services/CompReservations/types';
import { useCompleteReservation } from '../../../../../../../hooks/CompleteReservationHook/useCompleteReservation';

interface BodyProps {
  open: boolean;
  isMain?: boolean;
  guestSecondary: Guest[];
  guestInformations: Guest;
  setGuestMain?: (guest: Guest) => void;
  setGuestsSecondary: (guests: Guest[]) => void;
}

const Body = ({
  open,
  isMain,
  setGuestMain,
  guestSecondary,
  guestInformations,
  setGuestsSecondary,
}: BodyProps) => {
  const { setGuestFormIsValid } = useCompleteReservation();
  const [documentPhoto, setDocumentPhoto] = useState({
    front_document_photo: guestInformations.front_document_photo,
    back_document_photo: guestInformations.back_document_photo,
  });

  const initialValues = {
    name: guestInformations?.name || '',
    cpf: guestInformations?.document || '',
    email: guestInformations?.email || '',
    phone: guestInformations?.phone_number || '',
  };

  const validation = Yup.object().shape({
    name: Yup.string().min(1, 'Campo obrigatório').required(),
    cpf: Yup.string().min(11, 'CPF inválido'),
    email: Yup.string().email('E-mail inválido'),
    phone: Yup.string().min(13, 'Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async () => {},
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const name = formik.values?.name || '';
    const email = formik.values?.email || '';

    const isNameValid = name.length >= 1;

    const isEmailValid = email ? emailRegex.test(email) : true;

    const isValid = isMain ? isNameValid && isEmailValid : isNameValid;
    setGuestFormIsValid(isValid);
  }, [formik.values?.name, formik.values?.email, formik.values?.cpf]);

  useEffect(() => {
    if (isMain) {
      if (setGuestMain) {
        setGuestMain({
          id: guestInformations?.id,
          name: formik.values?.name || '',
          email: formik.values?.email || '',
          document: formik.values?.cpf || '',
          phone_number: formik.values?.phone || '',
          isNewGuest: guestInformations?.isNewGuest || false,
          is_principal: true,
          front_document_photo: documentPhoto?.front_document_photo?.uid || null,
          back_document_photo: documentPhoto?.back_document_photo?.uid || null,
        });
      }
    } else {
      const data = guestSecondary.map((item) => {
        const itemAux = item;
        if (item.id === guestInformations.id) {
          itemAux.document = formik.values?.cpf || '';
          itemAux.email = formik.values?.email || '';
          itemAux.name = formik.values?.name || '';
          itemAux.phone_number = formik.values?.phone || '';
          itemAux.isNewGuest = item?.isNewGuest || false;
          itemAux.is_principal = false;
          itemAux.front_document_photo = documentPhoto?.front_document_photo?.uid || null;
          itemAux.back_document_photo = documentPhoto?.back_document_photo?.uid || null;
        }
        return itemAux;
      });
      setGuestsSecondary(data);
    }
  }, [formik.values, documentPhoto]);

  return (
    <Container isOpen={open}>
      <BodyContent>
        <div className="form-1">
          <TextField
            label="Nome"
            id={'name'}
            value={formik.values?.name || ''}
            placeholder="Digite seu nome"
            formik={formik}
          />
        </div>

        <div className="form-2">
          <TextField
            label="CPF"
            id={'cpf'}
            mask="cpf"
            placeholder="Apenas números"
            formik={formik}
            value={formik.values?.cpf || ''}
          />
        </div>

        <div className="form-3">
          <TextField
            label="E-mail"
            id={'email'}
            placeholder="Digite seu e-mail"
            formik={formik}
            value={formik.values?.email || ''}
          />
        </div>

        <div className="form-4">
          <TextField
            label="Telefone Principal"
            placeholder="+00 (00) 00000-0000"
            id={'phone'}
            mask="phone"
            formik={formik}
            value={formik.values?.phone || ''}
          />
        </div>
        <div className="form-6" style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h1>Envie foto do seu documento</h1>
          <PhotoContainer>
            <UploadComponent
              type="front_document_photo"
              guestInformations={guestInformations}
              setFile={(x: FileProps) =>
                setDocumentPhoto((prev) => ({
                  ...prev,
                  front_document_photo: x,
                }))
              }
            />
            <UploadComponent
              type="back_document_photo"
              guestInformations={guestInformations}
              setFile={(x: FileProps) =>
                setDocumentPhoto((prev) => ({
                  ...prev,
                  back_document_photo: x,
                }))
              }
            />
          </PhotoContainer>
        </div>
      </BodyContent>
    </Container>
  );
};

Body.defaultProps = {
  isMain: false,
  setGuestMain: null,
};

export default Body;
