/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LinearProgress } from '@mui/material';
import { UseFormik } from '../../../utils/Formik/types';
import { LoginButton } from '../styles';
import { Container } from './styles';
import { ILoading } from '../Login';

interface Props {
  loading: ILoading;
}

const LoginWithReCaptcha = ({ loading }: Props) => (
  <Container loading={loading.is.toString()}>
    <LoginButton
      type="submit"
    >
      {loading.is ? 'Carregando...' : 'Entrar'}
    </LoginButton>

    <LinearProgress
      id="linear-progress"
      color="primary"
    />
  </Container>
);

export default LoginWithReCaptcha;
