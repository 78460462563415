import { useContextSelector } from 'use-context-selector';
import { ExpenseContext } from '../../context/ExpensesPage/ExpenseContext';

export function useExpense() {
  const openModalExpenses = useContextSelector(ExpenseContext,
    (expense) => expense.openModalExpenses);
  const handleOpenModalExpenses = useContextSelector(ExpenseContext,
    (expense) => expense.setOpenModalExpenses);

  const openExpensesInformationModal = useContextSelector(ExpenseContext,
    (expense) => expense.openExpensesInformationModal);
  const handleOpenExpensesInformationModal = useContextSelector(ExpenseContext,
    (expense) => expense.setOpenExpensesInformationModal);

  const isEditingModal = useContextSelector(ExpenseContext,
    (expense) => expense.isEditingModal);
  const handleIsEditingModal = useContextSelector(ExpenseContext,
    (expense) => expense.setIsEditingModal);

  const indexExpenseSelected = useContextSelector(ExpenseContext,
    (expense) => expense.indexExpenseSelected);
  const handleIndexExpenseSelected = useContextSelector(ExpenseContext,
    (expense) => expense.setIndexExpenseSelected);

  const expenseData = useContextSelector(ExpenseContext,
    (expense) => expense.expenseData);
  const handleExpenseData = useContextSelector(ExpenseContext,
    (expense) => expense.setExpenseData);

  const filteredValues = useContextSelector(ExpenseContext,
    (expense) => expense.filteredValues);
  const handleFilteredValues = useContextSelector(ExpenseContext,
    (expense) => expense.setFilteredValues);

  const hasFiltered = useContextSelector(ExpenseContext,
    (expense) => expense.hasFiltered);
  const handleHasFiltered = useContextSelector(ExpenseContext,
    (expense) => expense.setHasFiltered);

  const filterParams = useContextSelector(ExpenseContext,
    (expense) => expense.filterParams);
  const handleFilterParams = useContextSelector(ExpenseContext,
    (expense) => expense.setFilterParams);

  const nextPage = useContextSelector(ExpenseContext,
    (expense) => expense.nextPage);
  const handleNextPage = useContextSelector(ExpenseContext,
    (expense) => expense.setNextPage);

  const page = useContextSelector(ExpenseContext,
    (expense) => expense.page);
  const handlePage = useContextSelector(ExpenseContext,
    (expense) => expense.setPage);

  const hasMoreExpenses = useContextSelector(ExpenseContext,
    (expense) => expense.hasMoreExpenses);
  const handleHasMoreExpenses = useContextSelector(ExpenseContext,
    (expense) => expense.setHasMoreExpenses);

  const globalFilter = useContextSelector(ExpenseContext,
    (expense) => expense.globalFilter);
  const setGlobalFilter = useContextSelector(ExpenseContext,
    (expense) => expense.setGlobalFilter);

  const openExportExpensesModal = useContextSelector(ExpenseContext,
    (expense) => expense.openExportExpensesModal);
  const setOpenExportExpensesModal = useContextSelector(ExpenseContext,
    (expense) => expense.setOpenExportExpensesModal);

  const openModalSelectWhoPays = useContextSelector(ExpenseContext,
    (expense) => expense.openModalSelectWhoPays);
  const setModalSelectWhoPays = useContextSelector(ExpenseContext,
    (expense) => expense.setModalSelectWhoPays);

  const expensesList = useContextSelector(ExpenseContext,
    (expense) => expense.expensesList);
  const setExpensesList = useContextSelector(ExpenseContext,
    (expense) => expense.setExpensesList);

  const fetchExpenseData = useContextSelector(ExpenseContext,
    (expense) => expense.fetchExpenseData);

  const expensesPage = useContextSelector(ExpenseContext,
    (expense) => expense.expensesPage);

  const loading = useContextSelector(ExpenseContext,
    (expense) => expense.loading);
  const setLoading = useContextSelector(ExpenseContext,
    (expense) => expense.setLoading);

  const properties = useContextSelector(ExpenseContext,
    (expense) => expense.properties);
  const setProperties = useContextSelector(ExpenseContext,
    (expense) => expense.setProperties);

  return {
    openModalExpenses,
    handleOpenModalExpenses,
    openExpensesInformationModal,
    handleOpenExpensesInformationModal,
    isEditingModal,
    handleIsEditingModal,
    indexExpenseSelected,
    handleIndexExpenseSelected,
    expenseData,
    handleExpenseData,
    filteredValues,
    handleFilteredValues,
    hasFiltered,
    handleHasFiltered,
    filterParams,
    handleFilterParams,
    nextPage,
    handleNextPage,
    page,
    handlePage,
    hasMoreExpenses,
    handleHasMoreExpenses,
    globalFilter,
    setGlobalFilter,
    openExportExpensesModal,
    setOpenExportExpensesModal,
    openModalSelectWhoPays,
    setModalSelectWhoPays,
    expensesList,
    setExpensesList,
    fetchExpenseData,
    expensesPage,
    loading,
    setLoading,
    properties,
    setProperties,
  };
}
