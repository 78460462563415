import { motion } from 'framer-motion';
import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../../../../hooks/useMobile/useMobile';

export const ButtonContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const ButtonBackDrop = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: #ff4741;
  z-index: -1;
`;

export const SeeRegionsButton = styled(motion.button)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: var(--rosa, #fc605b);
  border: none;
  padding: 20px 40px;

  color: #fff;
  text-align: center;
  font-family: Asap;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: normal;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    font-size: 2.5rem;
  }
`;
