import { compareDates, compareList } from '../../utils/Sorting';
import { ChangePropertyHostProps, Order, OrderBy } from './types';

export const initialValues: ChangePropertyHostProps[] = [
  {
    id: 1,
    property: {
      id: 1,
      code: '',
    },
    old_host: {
      id: 1,
      first_name: '',
      last_name: '',
      trading_name: '',
      is_individual: true,
      full_name: '',
    },
    new_host: {
      id: 2,
      first_name: '',
      last_name: '',
      trading_name: '',
      is_individual: true,
      full_name: '',
    },
    replacement_date: '',
    updated_at: '',
    created_at: '',
  },
];

export const getExchangedHostsUpdated = (data: ChangePropertyHostProps) => ({
  ...data,
  property: {
    ...data.property,
  },
  old_host: {
    ...data.old_host,
  },
  new_host: {
    ...data.new_host,
  },
});

export const sortAllExchangedHostsList = (
  listItems: ChangePropertyHostProps[],
  orderBy: OrderBy,
  order: Order,
): ChangePropertyHostProps[] => {
  let sorted: ChangePropertyHostProps[] = listItems;

  switch (orderBy) {
    case 'property_code':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.property.code, b.property.code, order,
      ));
      break;

    case 'register_date':
      sorted = listItems.map((item) => item).sort((a, b) => compareDates(
        a.created_at, b.created_at, order,
      ));
      break;

    case 'old_host':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.old_host.full_name, b.old_host.full_name, order,
      ));
      break;

    case 'new_host':
      sorted = listItems.map((item) => item).sort((a, b) => compareList(
        a.new_host.full_name, b.new_host.full_name, order,
      ));
      break;

    case 'replacement_date':
      sorted = listItems.map((item) => item).sort((a, b) => compareDates(
        a.replacement_date, b.replacement_date, order,
      ));
      break;

    default:
      return sorted;
  }

  return sorted;
};

export const sortPartialExchangedHostsList = (
  listItems: ChangePropertyHostProps[],
  orderBy: OrderBy,
  order: Order,
  order_start_position: number,
  order_end_position: number,
): ChangePropertyHostProps[] => {
  let sorted: ChangePropertyHostProps[] = listItems;

  const start = order_start_position;
  const end = order_end_position;

  const beforeSort = listItems.slice(0, start);
  const toSort = listItems.slice(start, end);
  const afterSort = listItems.slice(end + 1 > listItems.length
    ? listItems.length : end + 1, listItems.length);

  switch (orderBy) {
    case 'property_code':
      sorted = toSort.map((item) => item).sort((a, b) => compareList(
        a.property.code, b.property.code, order,
      ));
      break;

    case 'register_date':
      sorted = toSort.map((item) => item).sort((a, b) => compareDates(
        a.created_at, b.created_at, order,
      ));
      break;

    case 'old_host':
      sorted = toSort.map((item) => item).sort((a, b) => compareList(
        a.old_host.full_name, b.old_host.full_name, order,
      ));
      break;

    case 'new_host':
      sorted = toSort.map((item) => item).sort((a, b) => compareList(
        a.new_host.full_name, b.new_host.full_name, order,
      ));
      break;

    case 'replacement_date':
      sorted = toSort.map((item) => item).sort((a, b) => compareDates(
        a.replacement_date, b.replacement_date, order,
      ));
      break;

    default:
      return sorted;
  }

  return beforeSort.concat(sorted).concat(afterSort);
};

export const translateHeaderLabel: Record<string, string> = {
  Imóvel: 'property_code',
  Registro: 'register_date',
  'Anfitrião atual': 'old_host',
  'Anfitrião novo': 'new_host',
  'Data de entrega': 'replacement_date',
};
