import React, { useState } from 'react';

import {
  motion,
  AnimatePresence,
  AnimateSharedLayout,
} from 'framer-motion';

import cardsJSON from './cards.json';

import {
  Title,
  Content,
  ListItem,
  Container,
  ListContent,
  Description,
  TitleContainer,
  ListsContainer,
  DescriptionContainer,
} from './style';

const list = {
  visible: { opacity: 1, transition: { when: 'beforeChildren', staggerChildren: 0.1 } },
  hidden: { opacity: 0, transition: { when: 'afterChildren' } },
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 },
};

interface IContentProps {
  description: string[];
  lists: string[] | undefined;
}

interface ItemProps extends IContentProps {
  title: string;
}

function CardContent({ description, lists }: IContentProps) {
  return (
    <Content
      layout
      exit={{ opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
    >
      <DescriptionContainer>
        {description.map((desc) => (
          <Description key={desc}>
            {desc}
          </Description>
        ))}
      </DescriptionContainer>

      {lists && (
        <ListsContainer>
          <ul>
            {lists.map((listItem) => (
              <li key={listItem}>{listItem}</li>
            ))}
          </ul>
        </ListsContainer>
      )}
    </Content>
  );
}

const Item = ({
  lists,
  title,
  description,
}: ItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <ListItem layout initial={{ borderRadius: 10 }}>
      <TitleContainer whileTap={{ scale: 0.9 }} isOpen={isOpen} onClick={toggleOpen} layout>
        <Title>{title}</Title>
      </TitleContainer>
      <AnimatePresence>
        {isOpen && (
          <CardContent
            lists={lists}
            description={description}
            key="cardContent_parners_page"
          />
        )}
      </AnimatePresence>
    </ListItem>
  );
};

const CardsList = () => (
  <Container
    variants={list}
    initial="hidden"
    animate="visible"
  >
    <AnimateSharedLayout>
      <ListContent layout>
        {cardsJSON.map((card) => (
          <motion.div key={card?.id || card.title} variants={item}>
            <Item title={card.title} description={card.description} lists={card?.lists} />
          </motion.div>
        ))}
      </ListContent>
    </AnimateSharedLayout>
  </Container>
);

export default CardsList;
