import moment from 'moment';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import {
  BillingHistoryNegotiationsHeaderContainer,
  BillingHistoryNegotiationsHeaderItem,
} from './styles';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { numberToCurrency } from '../../../../../utils/Formatter';

export const BillingHistoryNegotiationsHeader = () => {
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const { reservationGuestDamages } = useGuestDamage();
  const formattedCheckInDate = moment(selectedGuestDamageNegotiation.reservation?.check_in_date).format('DD/MM/YYYY');
  const formattedCheckOutDate = moment(selectedGuestDamageNegotiation.reservation?.check_out_date).format('DD/MM/YYYY');
  const totalPrice = reservationGuestDamages
    .reduce((accPrice, guestDamage) => accPrice + Number(guestDamage.total_price!), 0);

  return (
    <BillingHistoryNegotiationsHeaderContainer>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Nome do hóspede</h3>
        <p>{selectedGuestDamageNegotiation.reservation?.guest_name || '-'}</p>
      </BillingHistoryNegotiationsHeaderItem>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Anfitrião responsável pelo imóvel</h3>
        <p>{selectedGuestDamageNegotiation.reservation?.responsible_host || '-'}</p>
      </BillingHistoryNegotiationsHeaderItem>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Data de check-in</h3>
        <p>{formattedCheckInDate}</p>
      </BillingHistoryNegotiationsHeaderItem>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Data de check-out</h3>
        <p>{formattedCheckOutDate}</p>
      </BillingHistoryNegotiationsHeaderItem>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Valor total</h3>
        <p>{numberToCurrency(Number(totalPrice))}</p>
      </BillingHistoryNegotiationsHeaderItem>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Plataforma</h3>
        <p>{selectedGuestDamageNegotiation.reservation?.ota_name || '-'}</p>
      </BillingHistoryNegotiationsHeaderItem>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Código de reserva</h3>
        <p>{selectedGuestDamageNegotiation.reservation?.code || '-'}</p>
      </BillingHistoryNegotiationsHeaderItem>
      <BillingHistoryNegotiationsHeaderItem>
        <h3>Telefone</h3>
        <p>{selectedGuestDamageNegotiation.reservation?.guest_phone_number || '-'}</p>
      </BillingHistoryNegotiationsHeaderItem>
    </BillingHistoryNegotiationsHeaderContainer>
  );
};
