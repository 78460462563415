import React, {
  FC, useState, useCallback, ReactElement,
} from 'react';

import { createContext } from 'use-context-selector';

import { Property } from '../services/Property/types';

interface SearchContextType {
  loadingSearch: boolean;
  setLoadingSearch: Function;
  contentSearch: string;
  setContentSearch: Function;
  filteredResults: Property[];
  setFilteredResults: Function;
  isFirstPage: boolean;
  setIsFirstPage: Function;
}

export const SearchContext = createContext<SearchContextType>({
  loadingSearch: false,
  setLoadingSearch: () => {},

  contentSearch: '',
  setContentSearch: () => {},

  filteredResults: [],
  setFilteredResults: () => {},

  isFirstPage: false,
  setIsFirstPage: () => {},
});

export const SearchProvider: FC<{ children: ReactElement | any }> = ({
  children,
}) => {
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [contentSearch, setContentSearch] = useState<string>('');
  const [filteredResults, setFilteredResults] = useState<Property[]>([]);
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);

  const handleChangeContentSearch = useCallback((content: string) => {
    setContentSearch(content);
  }, []);

  const handleChangeLoadingSearch = useCallback((loading: boolean) => {
    setLoadingSearch(loading);
  }, []);

  return (
    <SearchContext.Provider
      value={{
        loadingSearch,
        setLoadingSearch: handleChangeLoadingSearch,
        contentSearch,
        setContentSearch: handleChangeContentSearch,
        filteredResults,
        setFilteredResults,
        isFirstPage,
        setIsFirstPage,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
