import React from 'react';

import Image from './Image';
import Header from './Header';
import Footer from './Footer';

import {
  Content,
  Container,
} from './style';

interface ICardModel {
  index: number;
}

const CardModel = ({
  index,
}: ICardModel) => (
  <Container
    initial={{
      y: 10,
      opacity: 0,
    }}
    animate={{
      y: 0,
      opacity: 1,
    }}
    transition={{
      delay: index * 0.2,
    }}
  >
    <Content>
      <Header />
      <Footer />
    </Content>

    <Image />
  </Container>

);
export default CardModel;
