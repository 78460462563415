/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { UseFormik } from '../../../../../utils/Formik/types';
import SimpleSelect from '../../../../SimpleSelect';
import CountrySelect from '../../../../SimpleSelect/CountrySelect';
import StateSelect from '../../../../SimpleSelect/StateSelect';
import TextField from '../../../../TextField';
import { Container } from './styles';
import { getBanks } from '../../../../../services/Bank/request';
import { Bank } from '../../../../../services/Bank/types';
import { getAddressByCep } from '../../../../../services/Address/request';
import { isBrazil, onlyNumbers } from '../../../../../utils/Formatter';
import DropdownAutocomplete from '../../../../DropdownAutocomplete';
import { SelectProps } from '../../../../Autocomplete/Autocomplete';
import { compareList } from '../../../../../utils/Sorting';
import InputCheckBox from '../../../../InputCheckBox';
import { accountTypeOptions } from '../../../../../services/Bank/utils';
import { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import { PixKeyType } from '../../../../../services/InsertData/types';
import { AddressRequest } from '../../../../../services/Address/types';

type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed' | undefined;

const maritalStatus = [
  {
    value: 'Single',
    valueLabel: 'Solteiro(a)',
  },
  {
    value: 'Married',
    valueLabel: 'Casado(a)',
  },
  {
    value: 'Divorced',
    valueLabel: 'Divorciado(a)',
  },
  {
    value: 'Widowed',
    valueLabel: 'Viúvo(a)',
  },
];

const pixKeyTypeOptions = [
  {
    value: 'Phone_Number',
    valueLabel: 'Número de telefone',
  },
  {
    value: 'CPF',
    valueLabel: 'CPF',
  },
  {
    value: 'CNPJ',
    valueLabel: 'CNPJ',
  },
  {
    value: 'Email',
    valueLabel: 'E-mail',
  },
  {
    value: 'Random',
    valueLabel: 'Chave aleatória',
  },
  {
    value: 'Dont_Have',
    valueLabel: 'Não possui chave PIX',
  },
];

type FormState = 'user' | 'owner';
interface AddOwnerFormikValuesType {
  formType: FormState;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber1: string;
  phoneNumber2: string;
  gender: string;
  birthDate?: Date | null;
  cpf?: string;
  cnpj?: string;
  corporateName: string;
  tradingName: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  nationality: string;
  maritalStatus: MaritalStatus;
  profession: string;
  emailForOperation: string;
  transferDay: number;
  bank: number;
  branchNumber: string;
  accountNumber: string;
  accountType: string;
  pixKey: string;
  pixKeyType?: PixKeyType | 'Dont_Have';
  entityName: string;
  bankCpfOrCnpj: string;
  invoiceEntityName: string;
  invoiceCpfOrCnpj: string;
  invoiceEmail: string;
  invoicePhoneNumber1: string;
  invoiceStreet: string;
  invoiceAddressNumber: string;
  invoiceAddressComplement: string;
  invoiceDistrict: string;
  invoiceCity: string;
  invoiceState: string;
  invoiceCountry: string;
  invoicePostalCode: string;
  ownerId?: number;
  isIndividual: boolean;
}
interface FormAddOwnerProps {
  formik?: UseFormik<AddOwnerFormikValuesType>;
  isSameAddress: boolean;
  handleToggleIsSameAddres: () => void;
  isEditingForm: boolean;
}

const FormAddOwner = ({
  formik,
  handleToggleIsSameAddres,
  isSameAddress,
  isEditingForm,
}: FormAddOwnerProps) => {
  const [bankList, setBankList] = useState<Bank[]>();
  const [isPixDisabled, setIsPixDisabled] = useState(true);
  const banksIds: string[] = [];
  const banksNames: string[] = [];

  const handleGetBank = (): SelectProps[] => {
    bankList?.forEach((bank) => {
      banksIds.push(`${bank.id}`);
      banksNames.push(`${bank.long_name} - ${bank.bank_number}`);
    });

    return banksNames
      .map((name, index) => ({
        optionText: name,
        optionValue: banksIds[index],
      }))
      .sort((a, b) => compareList(a.optionText, b.optionText));
  };
  const getBankSelected = (ownerId: number) => {
    const index = banksIds.findIndex((id) => Number(id) === ownerId);
    return banksNames?.[index] || '';
  };
  const getPixMask = (type?: PixKeyType | 'Dont_Have') => {
    switch (type) {
      case 'CNPJ': {
        return 'cnpj';
      }
      case 'CPF': {
        return 'cpf';
      }
      case 'Phone_Number': {
        return 'phone';
      }
      default: {
        return undefined;
      }
    }
  };
  async function getListBank() {
    const result = await getBanks();
    setBankList(result);
  }

  useEffect(() => {
    getListBank();
  }, []);

  async function handleGetAddress() {
    if (formik) {
      const response: AddressRequest | any = await getAddressByCep(onlyNumbers(formik
        .values.invoicePostalCode));

      if ('erro' in response) {
        formik.setFieldError('invoicePostalCode', 'O cep digitado não é válido!');
      } else {
        const bairro = isEditingForm && formik.values?.invoiceDistrict !== '' ? formik.values?.invoiceDistrict : response?.bairro;
        const complemento = isEditingForm && formik.values?.invoiceAddressComplement !== '' ? formik.values?.invoiceAddressComplement : response?.complemento;
        const localidade = isEditingForm && formik.values?.invoiceCity !== '' ? formik.values?.invoiceCity : response?.localidade;
        const logradouro = isEditingForm && formik.values?.invoiceStreet !== '' ? formik.values?.invoiceStreet : response?.logradouro;
        const uf = isEditingForm && formik.values?.invoiceState !== '' ? formik.values?.invoiceState : response?.uf;

        formik.setFieldValue('invoiceDistrict', bairro);
        formik.setFieldValue('invoiceAddressComplement', complemento);
        formik.setFieldValue('invoiceCity', localidade);
        formik.setFieldValue('invoiceStreet', logradouro);
        formik.setFieldValue('invoiceState', uf);
        formik.setFieldValue('invoiceCountry', 'Brasil');
      }
    }
  }

  useEffect(() => {
    if (formik  && isBrazil(formik?.values?.invoiceCountry)) {
      if (formik.values.invoicePostalCode.length >= 9) {
        handleGetAddress();
      }
    }
  }, [formik?.values.invoicePostalCode]);

  useEffect(() => {
    if (formik?.values.pixKeyType === 'Dont_Have' || !formik?.values.pixKeyType) {
      setIsPixDisabled(true);
      formik?.setFieldValue('pixKey', undefined);
    } else {
      setIsPixDisabled(false);
    }
  }, [formik?.values.pixKeyType]);

  return (
    <Container>
      <div className="two-columns">
        <TextField
          labelClassName="labelClass"
          value={formik?.values.nationality}
          id="nationality"
          label="Nacionalidade"
          placeholder="Digite aqui..."
          formik={formik}
        />
        <SimpleSelect
          id="maritalStatus"
          label="Estado civil"
          className="selector"
          options={maritalStatus}
          formik={formik}
          placeholder="Selecione..."
          labelClassName="labelClass"
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          id="profession"
          value={formik?.values.profession}
          label="Profissão"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          id="emailForOperation"
          value={formik?.values.emailForOperation}
          type="email"
          label="E-mail para operação"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <h1>Dados Bancários</h1>
      {/* <div className="one-column">
        <TextField
          required={!isEditingForm}
          labelClassName="labelClass"
          id="transferDay"
          value={formik?.values.transferDay}
          label="Dia de transferência"
          placeholder="Apenas números"
          formik={formik}
        />
      </div> */}
      <div className="one-column">
        <DropdownAutocomplete
          required={`${formik?.values?.bank}` === ''}
          label="Banco"
          id="bank"
          options={handleGetBank()}
          formik={formik}
          placeholder={formik?.values?.bank ? `${getBankSelected(formik?.values?.bank)}` : 'Digite aqui...'}
          labelClassName="labelClass"
        />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="branchNumber"
          value={formik?.values.branchNumber}
          label="Agência"
          placeholder="Digite aqui..."
          formik={formik}
          mask="agency"
        />
        <TextField
          required
          labelClassName="labelClass"
          id="accountNumber"
          value={formik?.values.accountNumber}
          label="Conta"
          placeholder="Digite aqui..."
          formik={formik}
          mask="account"
        />
      </div>
      <div className="one-column">
        <SimpleSelect
          required
          labelClassName="labelClass"
          id="accountType"
          label="Tipo de conta"
          className="selector"
          options={
            (accountTypeOptions || []).map<SelectOption>(({
              label,
              value,
            }) => ({ valueLabel: label, value }))
          }
          firstValue="not_informed"
          formik={formik}
          placeholder="Selecione..."
        />
      </div>
      <div className="one-column">
        <SimpleSelect
          id="pixKeyType"
          label="Tipo de Chave PIX"
          className="selector"
          options={pixKeyTypeOptions}
          formik={formik}
          placeholder="Selecione..."
          labelClassName="labelClass"
          required
        />
      </div>
      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          value={formik?.values.pixKey}
          id="pixKey"
          label="PIX"
          placeholder="Digite aqui..."
          formik={formik}
          mask={getPixMask(formik?.values.pixKeyType)}
          disabled={isPixDisabled}
        />
      </div>
      <div className="one-column">
        <TextField
          required
          labelClassName="labelClass"
          id="entityName"
          value={formik?.values.entityName}
          label="Nome destinatário"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="one-column">
        <TextField
          required
          labelClassName="labelClass"
          id="bankCpfOrCnpj"
          value={formik?.values.bankCpfOrCnpj}
          mask="cpfOrCnpj"
          label="CPF/CNPJ"
          placeholder="Apenas números"
          formik={formik}
        />
      </div>

      <h1>Dados nota fiscal</h1>
      <div className="one-column">
        <TextField
          required
          labelClassName="labelClass"
          id="invoiceEntityName"
          value={formik?.values.invoiceEntityName}
          label="Nome destinatátio"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="one-column">
        <TextField
          required
          labelClassName="labelClass"
          id="invoiceCpfOrCnpj"
          value={formik?.values.invoiceCpfOrCnpj}
          mask="cpfOrCnpj"
          label="CPF/CNPJ"
          placeholder="Apenas números"
          formik={formik}
        />
      </div>
      <div className="one-column">
        <TextField
          required
          labelClassName="labelClass"
          id="invoiceEmail"
          type="email"
          value={formik?.values.invoiceEmail}
          label="E-mail"
          placeholder="Digite aqui..."
          formik={formik}
        />
      </div>
      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="invoicePhoneNumber1"
          value={formik?.values.invoicePhoneNumber1}
          mask="phone"
          label="Telefone 1"
          placeholder="+00 (00) 00000-0000"
          formik={formik}
        />
      </div>
      {!isEditingForm && (
        <div className="one-column">
          <InputCheckBox
            className="labelClassCheckBox"
            checked={isSameAddress}
            onChange={handleToggleIsSameAddres}
            labelName="Mesmo endereço do proprietário"
          />
        </div>
      )}

      <div className="one-column">
        <CountrySelect
          required={!isEditingForm}
          id="invoiceCountry"
          label="País"
          formik={formik}
          labelClassName="labelClass"
          valueIsFullName
          disabled
        />
      </div>

      <div className="two-columns">
        <TextField
          required
          labelClassName="labelClass"
          id="invoicePostalCode"
          value={formik?.values.invoicePostalCode}
          {...(isBrazil(formik?.values.invoiceCountry) ? { mask: 'cep' } : {})}
          label="CEP"
          placeholder="Apenas números"
          formik={formik}
          disabled={isSameAddress === true}
        />
        {isBrazil(formik?.values.invoiceCountry) ? (
          <StateSelect
            id="invoiceState"
            formik={formik}
            label="Estado"
            required
            labelClassName="labelClass"
            disabled={isSameAddress === true}
          />
        ): (
          <TextField
            required
            id="invoiceState"
            formik={formik}
            label="Estado"
            placeholder="Digite aqui..."
            value={formik?.values.invoiceState}
            labelClassName="labelClass"
          />
        )}
      </div>

      <div className="two-columns">
        <TextField
          labelClassName="labelClass"
          id="invoiceStreet"
          value={formik?.values.invoiceStreet}
          label="Lagradouro"
          placeholder="Digite aqui..."
          formik={formik}
          required
          disabled={isSameAddress === true}
        />
        <TextField
          labelClassName="labelClass"
          id="invoiceAddressNumber"
          value={formik?.values.invoiceAddressNumber}
          label="Número"
          placeholder="Digite aqui..."
          formik={formik}
          disabled={isSameAddress === true}
        />
      </div>

      <div className="two-columns">
        <TextField
          labelClassName="labelClass"
          id="invoiceDistrict"
          value={formik?.values.invoiceDistrict}
          label="Bairro"
          placeholder="Digite aqui..."
          formik={formik}
          required
          disabled={isSameAddress === true}
        />
        <TextField
          labelClassName="labelClass"
          id="invoiceCity"
          value={formik?.values.invoiceCity}
          label="Cidade"
          placeholder="Digite aqui..."
          formik={formik}
          required
          disabled={isSameAddress === true}
        />
      </div>

      <div className="one-column">
        <TextField
          labelClassName="labelClass"
          id="invoiceAddressComplement"
          value={formik?.values.invoiceAddressComplement}
          label="Complemento"
          placeholder="Digite aqui..."
          formik={formik}
          disabled={isSameAddress === true}
        />
      </div>
    </Container>
  );
};
FormAddOwner.defaultProps = {
  formik: undefined,
};

export default FormAddOwner;
