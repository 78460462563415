import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white._300.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 2rem;

  .content {
    width: 100%;
    padding: 2rem;
    height: 100%;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    border-radius: 10px;
    overflow: auto;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-end: 4;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 2rem;

    @media (max-width: 1450px){
      grid-template-columns: repeat(3,1fr);
    }

    @media (max-width: 1150px){
      grid-template-columns: repeat(2,1fr);
    }

    @media (max-width: 600px){
      grid-template-columns: 1fr;
    }
  }
`;

export const Title = styled.h1`
  margin: 1.125rem 0;

  font-size: 1.875rem;
  line-height: 2.125rem;
`;

export const Subtitle = styled.p`
  margin-bottom: 1.125rem;

  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.palette.grey._750.hex()};
`;

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.grey._750.hex()};
  margin-top: 2rem;
`;
