import { useFormik } from 'formik';
import React from 'react';

import TedListDatePicker from '../../OwnerTedList/Header/DatePicker/TedListDatePicker';

import {
  Title,
  Container,
  DateContainer,
} from './styles';

import { useFinancialFeedback } from '../../../hooks/FinancialFeedbackContext/FinancialFeedbackContext';

const Header = () => {
  const { handleDate } = useFinancialFeedback();

  const formik = useFormik({
    initialValues: {
      period: new Date(),
    },
    onSubmit: () => {},
  });

  React.useEffect(() => {
    if (formik.values.period) {
      handleDate(formik.values.period);
    }
  }, [formik]);

  return (
    <Container>
      <Title>Gabarito Financeiro</Title>
      <DateContainer>
        <TedListDatePicker formik={formik} />
      </DateContainer>
    </Container>
  );
};

export default Header;
