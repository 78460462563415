import { GridPropertyProps } from '../../../context/FinancialClosePage/FinancialClosePropertyContext/types';

import {
  gridRowIsExpanded,
  initialValuesFinancialCloseProperty,
  propertyIsSelected,
} from '../../../context/FinancialClosePage/FinancialClosePropertyContext/utils';

import {
  FinancialClosePropertyResponse,
  PropertyStatus,
} from './types';

import {
  checkEmptyString,
  formatDateToShow,
  getStringFormatted,
  numberToCurrency,
} from '../../../utils/Formatter';

import {
  translatedPaidBy,
  translateExpenseReason,
  translateExpenseStatus,
  translatePropertyStatus,
} from '../../../utils/Translator';

import { ReservationStatus } from '../../Reservation/types';
import { getManualFitValueColor } from '../utils';

const MAX_LENGHT_USERNAME = 80;
const MAX_DIGITS_SUBSTRING = 20;

export const formatFinancialDataProperty = (data: FinancialClosePropertyResponse):
GridPropertyProps[] => {
  const financialData: GridPropertyProps[] = [];

  data.data.forEach((item, index) => {
    const dataFormatted: GridPropertyProps = { ...initialValuesFinancialCloseProperty };

    dataFormatted.id = item?.property?.id || index + 1;

    dataFormatted.property = {
      id: item?.property?.id || index + 1,
      code: checkEmptyString(`${item?.property?.code}`) || 'Não informado',
    };

    dataFormatted.owner = {
      id: item?.owner?.id || index + 1,
      name: getStringFormatted(
        checkEmptyString(`${item?.owner?.name}`) || 'Não informado', MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
      ),
    };

    dataFormatted.host = {
      id: item?.host?.id || 0,
      name: getStringFormatted(
        checkEmptyString(`${item?.host?.name}`) || 'Não informado', MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
      ),
    };

    dataFormatted.balance_initial = numberToCurrency(item?.initial_balance || 0);
    dataFormatted.balance_final = numberToCurrency(item?.final_balance || 0);

    dataFormatted.revenue = {
      airbnb: numberToCurrency(item?.revenue?.airbnb || 0),
      booking: numberToCurrency(item?.revenue?.booking || 0),
      contract: numberToCurrency(item?.revenue?.contract || 0),
      expedia: numberToCurrency(item?.revenue?.expedia || 0),
      homeaway: numberToCurrency(item?.revenue?.homeaway || 0),
      stays: numberToCurrency(item?.revenue?.stays || 0),
      decolar: numberToCurrency(item?.revenue?.decolar || 0),
      total: numberToCurrency(item?.revenue?.total || 0),
    };

    dataFormatted.income = {
      airbnb: numberToCurrency(item?.income?.airbnb || 0),
      booking: numberToCurrency(item?.income?.booking || 0),
      contract: numberToCurrency(item?.income?.contract || 0),
      expedia: numberToCurrency(item?.income?.expedia || 0),
      homeaway: numberToCurrency(item?.income?.homeaway || 0),
      stays: numberToCurrency(item?.income?.stays || 0),
      decolar: numberToCurrency(item?.income?.decolar || 0),
      total: numberToCurrency(item?.income?.total || 0),
    };

    dataFormatted.expense = {
      expense_host: numberToCurrency(item?.expense?.expense_host || 0),
      expense_seazone: numberToCurrency(item?.expense?.expense_seazone || 0),
      expense_owner: numberToCurrency(item?.expense?.expense_owner || 0),
      total: numberToCurrency(item?.expense?.total || 0),
    };

    dataFormatted.refund = {
      refund_host: numberToCurrency(item?.refund?.refund_host || 0),
      refund_seazone: numberToCurrency(item?.refund?.refund_seazone || 0),
      refund_owner: numberToCurrency(item?.refund?.refund_owner || 0),
      total: numberToCurrency(item?.refund?.total || 0),
    };

    dataFormatted.transfer = numberToCurrency(item?.transfer || 0);

    dataFormatted.property_manual_fit = {
      value: numberToCurrency(item?.property_manual_fit || 0),
      customColor: getManualFitValueColor(item?.property_manual_fit || 0),
    };

    dataFormatted.property_manual_fits = item?.property_manual_fits || [];

    dataFormatted.commission = {
      host: numberToCurrency(item?.commission?.host || 0),
      seazone: numberToCurrency(item?.commission?.seazone || 0),
      total: numberToCurrency(item?.commission?.total || 0),
    };

    dataFormatted.property_status = translatePropertyStatus(item?.property?.status || '') as PropertyStatus;

    const reservationMock = initialValuesFinancialCloseProperty.reservations[0];

    dataFormatted.reservations = item?.reservations && item?.reservations?.length > 0
      ? item?.reservations?.map((reservation) => ({
        id: reservation?.id || index + 1,
        code: reservation?.code || reservationMock.code,
        check_in_date: reservation?.check_in_date ? reservation.check_in_date.split('-').reverse().join('/')
          : reservationMock.check_in_date,
        check_out_date: reservation?.check_out_date ? reservation.check_out_date.split('-').reverse().join('/')
          : reservationMock.check_out_date,
        total_value: numberToCurrency(reservation?.total_price || 0),
        gross_daily_value: numberToCurrency(reservation?.gross_daily_value || 0),
        daily_net_value: numberToCurrency(reservation?.daily_net_value || 0),
        daily_manual_fit: {
          value: numberToCurrency(reservation?.reservation_manual_fit || 0),
          customColor: getManualFitValueColor(reservation?.reservation_manual_fit || 0),
        },
        cleaning_fee_value: numberToCurrency(reservation?.cleaning_fee_value || 0),
        cleaning_net_value: numberToCurrency(reservation?.net_cleaning_fee || 0),
        cleaning_manual_fit: {
          value: numberToCurrency(reservation?.cleaning_fee_manual_fit || 0),
          customColor: getManualFitValueColor(reservation?.cleaning_fee_manual_fit || 0),
        },
        ota_comission: numberToCurrency(reservation?.ota_comission || 0),
        platform: reservation?.ota_name || '',
        guest: reservation?.guest_name || '',
        status: (reservation?.status || reservationMock.status) as ReservationStatus,
        comment: reservation?.comment || reservationMock.comment,
        conciliada: reservation?.conciliada || reservationMock.conciliada,
        ota_id: reservation?.ota_id || 0,
      })) : [];

    const expensesMock = initialValuesFinancialCloseProperty.expenses[0];
    dataFormatted.expenses = item?.expenses && item?.expenses?.length > 0
      ? item?.expenses?.map((expense) => ({
        id: expense?.id || index + 1,
        code: expense?.id?.toString() || expensesMock.code,
        register_date: expense?.register_date ? formatDateToShow(new Date(expense.register_date))
          : expensesMock.register_date,
        expense_date: expense?.expense_date ? formatDateToShow(new Date(expense?.expense_date))
          : expensesMock.expense_date,
        cash_date: expense?.cash_date ? formatDateToShow(new Date(expense.cash_date))
          : expensesMock.cash_date,
        category: translateExpenseReason[expense?.reason || expensesMock.category],
        description: expense?.description || expensesMock.description,
        value: numberToCurrency(expense?.value || 0),
        owner_approved: expense?.owner_approval || expensesMock.owner_approved,
        status: translateExpenseStatus[expense?.expense_status || expensesMock.status],
        paid_by: translatedPaidBy[expense?.paid_by || expensesMock.paid_by],
      })) : [];

    dataFormatted.status = 'Open';
    dataFormatted.property_status = item?.property?.status || '';

    dataFormatted.checked = propertyIsSelected(dataFormatted.id);
    dataFormatted.expanded = gridRowIsExpanded(dataFormatted.id);
    dataFormatted.is_to_keep_funds_in_seazone = item?.is_to_keep_funds_in_seazone || false;

    financialData.push(dataFormatted);
  });

  return financialData;
};
