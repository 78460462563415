import React, { useEffect, useState } from 'react';
import letters from '../../assets/loader/letters.png';
import icon from '../../assets/loader/icon.png';

import {
  Container,
  ContainerLogo,
  Leeters,
  Icon,
} from './styles';

type Props = {
  show?: boolean;
};

const Loader: React.FC<Props> = ({ show = true }) => {
  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setHide(!show);
      }, 500);
      return;
    }
    setHide(!show);
  }, [show]);

  return (!hide ? (
    <Container show={show}>
      <ContainerLogo>
        <Leeters src={letters} />
        <Icon src={icon} />
      </ContainerLogo>
    </Container>
  ) : null);
};

export default Loader;
