import { PropertiesList } from '../../../services/GuestDamage/types';
import { compareList } from '../../../utils/Sorting';

// import { translatePropertyStatus } from '../../../utils/Translator';
// ${translatePropertyStatus(property.status)}

export const formatPropertiesDropdown = (properties?: PropertiesList[]) => {
  const formattedData = properties?.map((property) => ({
    optionText: `${property.code}`,
    optionValue: `${property.code}`,
  }));

  const sortData = formattedData?.sort((a, b) => compareList(a.optionText, b.optionText));
  return sortData;
};
