import React from 'react';

import {
  usePartnersEarning,
} from '../../../../../../context/Partners/Earning/Earning';

import {
  Title,
  SubText,
} from './style';

const Header = () => {
  const { filterChoiseStep } = usePartnersEarning();

  return (
    <>
      <Title>{filterChoiseStep.title}</Title>
      <SubText>
        {filterChoiseStep.description}
      </SubText>
    </>
  );
};

export default Header;
