import ReactGA from 'react-ga4';
import { motion } from 'framer-motion';
import { useUser } from '../../../../context/UserContext';
import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.75 9.37503C18.749 8.38078 18.3535 7.42756 17.6505 6.72452C16.9475 6.02148 15.9942 5.62606 15 5.62503H11.8906C11.6633 5.61174 7.70156 5.33284 3.92891 2.16878C3.74672 2.01577 3.52466 1.91793 3.2888 1.88676C3.05294 1.85558 2.81309 1.89236 2.59741 1.99278C2.38173 2.09319 2.19918 2.25307 2.07122 2.45364C1.94325 2.6542 1.87518 2.88712 1.875 3.12503V15.625C1.87503 15.863 1.943 16.096 2.0709 16.2967C2.19881 16.4974 2.38135 16.6574 2.59705 16.7579C2.81276 16.8584 3.05268 16.8953 3.28861 16.8641C3.52454 16.833 3.74667 16.7351 3.92891 16.5821C6.87969 14.1071 9.94453 13.3977 11.25 13.1992V15.6774C11.2497 15.8833 11.3004 16.0862 11.3974 16.2679C11.4945 16.4496 11.6349 16.6045 11.8062 16.7188L12.6656 17.2914C12.8317 17.4023 13.022 17.4718 13.2204 17.494C13.4189 17.5162 13.6198 17.4906 13.8063 17.4192C13.9928 17.3478 14.1595 17.2328 14.2925 17.0837C14.4254 16.9347 14.5207 16.756 14.5703 16.5625L15.4898 13.0969C16.392 12.9769 17.2198 12.5333 17.8195 11.8487C18.4191 11.1642 18.7498 10.2851 18.75 9.37503ZM3.125 15.6196V3.12503C6.46953 5.93049 9.89297 6.64065 11.25 6.81565V11.9313C9.89453 12.1094 6.47188 12.818 3.125 15.6196ZM13.3594 16.2446V16.2532L12.5 15.6805V13.125H14.1875L13.3594 16.2446ZM15 11.875H12.5V6.87503H15C15.663 6.87503 16.2989 7.13842 16.7678 7.60726C17.2366 8.0761 17.5 8.71198 17.5 9.37503C17.5 10.0381 17.2366 10.674 16.7678 11.1428C16.2989 11.6116 15.663 11.875 15 11.875Z" fill="#E7E9FA" />
  </svg>
);

const FORM_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLScFxRJWsu-1Vg3ApDK8KI2ecy5uTkkt19cEoRx3_OLYhrMw7w/viewform?pli=1';

export default function FeedbackButton() {
  const { userInformation } = useUser();
  const { isMobile } = useScreenResize();

  const handleClick = () => {
    ReactGA.event(`Owners - ${userInformation?.nickname || userInformation?.first_name}`, {
      category: `${userInformation?.main_role}-${userInformation?.user_id}-`,
      action: `Clique no botão, ${userInformation?.user_id}`,
    });
    window.open(FORM_LINK, '_blank');
  };

  return (
    <motion.button
      type="button"
      onClick={handleClick}
      whileTap={{ scale: 0.95 }}
    >
      {isMobile ? 'Feedback' : 'Deixe aqui seu Feedback!'}
      <Icon />
    </motion.button>
  );
}
