import styled, { keyframes } from 'styled-components';

const showSearchBar = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 350px;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.151);
  }
`;

const fadeInSearchBar = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 0.5;
    transform: translateY(-50%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 3rem;
    font-weight: bolder;
    opacity: 0;
    animation: ${fadeIn} 0.5s 0.2s ease forwards;
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: normal;
    opacity: 0;
    font-size: 1.5rem;
    animation: ${fadeIn} 0.5s 0.3s ease forwards;
  }


  @media (max-width: 1400px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
`;

export const SearchBar = styled.div`
  display: flex;
  position: relative;
  transition: 0.4s;

  img {
    opacity: 0;
    animation: ${fadeInSearchBar} 0.5s 0.2s ease forwards;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
    width: 20px;
    cursor: pointer;
    transition: 0.2s;
    z-index: 2;
    &:hover {
      transform: translateY(-70%);
    }
  }

  input {
    opacity: 0;
    animation: ${showSearchBar} 0.5s ease forwards;
    width: 350px;
    padding: 15px 20px 15px 50px;
    border-radius: 10px;
    border: none;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.281);
    }

    &::placeholder {
      padding: 10px;
      font-size: 1.2rem;
      font-family: ${({ theme }) => theme.fonts.familys._1};
    }

    @media (max-width: 1400px) {
      width: 300px;
    }
  }
`;
