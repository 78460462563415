import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px)
  } to {
    opacity: 1;
    transform: translateY(0px)
  }
`;

function getRandomArbitrary() {
  const max = 100;
  const min = 10;

  return Math.random() * (max - min) + min;
}

export const shimmerAnimation = keyframes`
  0% {
      background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
      background-size: 400% 400%;
      background-position: 0% 0%;
    }
  50% {
    background-position: -135% 0%;
  }
  100%{
    background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
    background-size: 400% 400%;
    background-position: 0% 0%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  opacity: 0;
  animation: ${fade} 0.5s ease forwards;
`;

export const Content = styled.div`
  height: 81px;
  width: 100%;
  border-radius: 10px;

  animation: ${shimmerAnimation} 3s infinite forwards ease-in-out;

  padding: 20px;

  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: left;

  div {
    &:nth-child(n) {
      width: ${() => `${getRandomArbitrary()}%`};
    }

    &:nth-child(2n) {
      width: ${() => `${getRandomArbitrary()}%`};
    }

    &:last-child {
      width: ${() => `${getRandomArbitrary()}%`};
    }

    animation: ${shimmerAnimation} 1s 0.2s infinite forwards ease-in-out;
    width: 50%;
    border-radius: 5px;
    height: 70%;
  }
`;
