import { useContextSelector } from 'use-context-selector';
import { TagContext } from '../../../context/OwnerPage/OwnerGridFinances/TagContext';

export function useTag() {
  const isExpandedDailyExecuted = useContextSelector(TagContext,
    (state) => state.isExpandedDailyExecuted);
  const handleChangeIsExpandedDailyExecuted = useContextSelector(TagContext,
    (state) => state.setIsExpandedDailyExecuted);

  const isExpandedDailyPaid = useContextSelector(TagContext,
    (state) => state.isExpandedDailyPaid);
  const handleChangeIsExpandedDailyPaid = useContextSelector(TagContext,
    (state) => state.setIsExpandedDailyPaid);

  const isExpandedPayout = useContextSelector(TagContext,
    (state) => state.isExpandedPayout);
  const handleChangeIsExpandedPayout = useContextSelector(TagContext,
    (state) => state.setIsExpandedPayout);

  const isExpandedWallet = useContextSelector(TagContext,
    (state) => state.isExpandedWallet);
  const handleChangeIsExpandedWallet = useContextSelector(TagContext,
    (state) => state.setIsExpandedWallet);

  return {
    isExpandedDailyExecuted,
    handleChangeIsExpandedDailyExecuted,
    isExpandedDailyPaid,
    handleChangeIsExpandedDailyPaid,
    isExpandedPayout,
    handleChangeIsExpandedPayout,
    isExpandedWallet,
    handleChangeIsExpandedWallet,
  };
}
