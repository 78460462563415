import { useDate } from '../../../../hooks/DateHook/useDate';

import {
  Container,
  GridContainer,
  GridContent,
  GridHeaderContainer,
  GridHeaderContent,
} from './styles';

interface IGrid {
  children: any
}

const Grid = ({ children }: IGrid) => {
  const { gridYear } = useDate();

  const months: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  return (
    <Container>
      <GridContainer>
        <GridContent>
          <GridHeaderContainer>
            <GridHeaderContent>
              <span className="txt-origin">Origem</span>
            </GridHeaderContent>
            <p className="spacing" />

            {months.map((month, index) => (
              <GridHeaderContent key={month}>
                <p className={index > new Date().getMonth() && gridYear === new Date().getFullYear() ? 'highLigth' : ''}>{month}</p>
              </GridHeaderContent>
            ))}

            <GridHeaderContent>
              <p className="txt-total">Total</p>
            </GridHeaderContent>
          </GridHeaderContainer>
          {children}
        </GridContent>
      </GridContainer>
    </Container>
  );
};

export default Grid;
