import styled from 'styled-components';
import { getMaxWidthDesktop } from '../../style';

export const Container = styled.div`
  ${getMaxWidthDesktop};
`;

export const Content = styled.div`
  gap: 50px;
  display: flex;
  padding: 30px 0;
  flex-direction: column;
`;
