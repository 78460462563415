import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { IType } from '../../../../../../../types';

export const Container = styled(motion.div)<{
  type: IType;
  open: boolean;
}>`
  gap: 20px;
  display: flex;
  transition: 0.3s;
  padding: 15px 20px;
  padding-top: 15px;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  transition: 0.4s ease;
  border: 1px solid #0e0e0e;

  ${({ open }) => open && 'border: 1px solid rgba(0, 0, 0, 0.5);'}

  h1,h2,p {
    font-family: "Inter";
    font-weight: normal;
    font-size: 1rem;

    @media (max-width: 320px) {
      font-size: 12px;
    }
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
`;

export const Right = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    color: #8998b2;
  }
`;

export const DateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  h1 {
    color: #394760;
  }
  h2 {
    color: #8c9dbb;
  }

  h1,
  p {
    font-weight: 500;
  }

  h1 {
    color: var(--gray-800, #394760);
  }

  p {
    color: var(--darkk-blue-900, #001840);
  }
`;

export const Section = styled.div`
  gap: 5px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  p {
    font-weight: 500;
    color: var(--neuter-500, #969696);
  }
`;

export const NameContainer = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.1rem;
    text-overflow: ellipsis;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 320px) {
    h1 {
      font-size: 14px;
    }
  }
`;

export const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: 13px 1fr;
  align-items: center;

  h1 {
    color: var(--darkk-blue-900, #001840);
    line-height: 1rem;
  }
`;

export const Circle = styled.div<{
  background: string;
}>`
  height: 10px;
  width: 10px;
  border-radius: 100px;
  ${({ background }) => background
    && css`
      background: ${background};
    `};
`;

export const Body = styled.div`
  gap: 15px;
  display: grid;
  grid-template-columns: 2fr 1fr;

  h1 {
    font-style: normal;
    color: var(--gray-800, #394760);
  }
`;

export const PhoneContainer = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
