import { FC } from 'react';
import Calendar, { Detail } from 'react-calendar';

import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import { UseFormik } from '../../../utils/Formik/types';
import { useDate } from '../../../hooks/DateHook/useDate';

import {
  CalendarWrapper,
  Container,
  Content,
} from './styles';

interface ICustomCalendar {
  id: string;
  onClose: () => void;
  defaultView?: Detail | undefined;
  defaultValue?: Date | undefined;
  formik?: UseFormik<any>;
  handleSetDate?: (datte: Date) => void;
}

const CustomCalendar: FC<ICustomCalendar> = ({
  id,
  onClose,
  defaultView,
  defaultValue,
  handleSetDate,
}) => {
  const { dateSelected, handleDateSelected } = useDate();

  const handleOutClick = (event: any) => {
    if (event.target.id === id) onClose();
  };

  const handleClickDate = (date: Date) => {
    handleDateSelected(date);

    if (handleSetDate) {
      handleSetDate(date);
    }
  };

  return (
    <CalendarWrapper
      id={id}
      onClick={handleOutClick}
    >
      <Container className="react-calendar-container">
        <Content className="react-calendar-content">
          <Calendar
            defaultView={defaultView}
            className="react-calendar"
            onChange={(date: Date) => handleDateSelected(date)}
            value={defaultValue || dateSelected}
            prevLabel={<ArrowBackIosIcon />}
            nextLabel={<ArrowForwardIosIcon />}
            next2Label={null}
            prev2Label={null}
            defaultValue={defaultValue}
            tileClassName="tile-class"
            onClickDay={(day) => handleClickDate(day)}
            onClickMonth={(month) => handleClickDate(month)}
            onClickYear={(year) => handleClickDate(year)}
            formatShortWeekday={(_, value) => ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'][value.getDay()]}
          />
        </Content>
      </Container>
    </CalendarWrapper>
  );
};

CustomCalendar.defaultProps = {
  defaultView: 'month',
  defaultValue: undefined,
};

export default CustomCalendar;
