import React, { useEffect } from 'react';
import { Container } from './styles';
import InsertPropertyRulesDataComponent from '../../../components/InsertData/InsertPropertyRulesData';
import { PropertyRulesProvider } from '../../../context/InsertPropertyRulesContext/InsertPropertyRulesContext';

const InsertPropertyRulesDataPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Inserção de Dados';
  }, []);

  return (
    <Container>
      <PropertyRulesProvider>
        <InsertPropertyRulesDataComponent />
      </PropertyRulesProvider>
    </Container>
  );
};

export default InsertPropertyRulesDataPage;
