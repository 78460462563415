import { FC, useEffect, useState } from 'react';

import { ArrowRightAlt, AccessTime } from '@mui/icons-material';
import HouseImage from '../../../../assets/icons/ownerPage/houselock.svg';
import HouseImageBlue from '../../../../assets/icons/ownerPage/houselockBlue.svg';

import ModalTemplate from '../ModalTemplate';
import AlertMessage from '../ModalConfirmLock/AlertMessage';

import {
  ContainerBox,
  ContentBox,
  ContainerImage,
  Title,
  DatesSelected,
  DateGrid,
  ButtonContainer,
} from './styles';

interface Props {
  open?: boolean;
  startDate?: string;
  endDate?: string;
  propertyCode?: string;
  dataCy?: string;
  handleCloseModalSuccessLock?: () => void;
  variant?: 'red' | 'blue'
}

const ModalSuccessLock: FC<Props> = ({
  open = false,
  startDate = 'dd/mm/yyyy',
  endDate = 'dd/mm/yyyy',
  propertyCode = '',
  dataCy = 'modal-1',
  variant = 'red',
  handleCloseModalSuccessLock = () => {},
}) => {
  const [savedStartDate, setSavedStartDate] = useState(startDate);
  const [savedEndDate, setSavedEndDate] = useState(endDate);

  useEffect(() => {
    if (startDate !== 'Data inválida') {
      setSavedStartDate(startDate);
    }
    if (endDate !== 'Data inválida') {
      setSavedEndDate(endDate);
    }
  }, [startDate, endDate]);

  return (
    (
      <ModalTemplate open={open}>
        <ContainerBox>
          <ContentBox variant={variant}>
            <ContainerImage>
              <img src={variant === 'red' ? HouseImage : HouseImageBlue} alt="house icon" />
            </ContainerImage>
            <Title data-cy={`${dataCy}-txt-block-success`}>
              Bloqueio do imóvel
              {' '}
              <span>
                {propertyCode}
                {' '}
              </span>
              realizado com sucesso!
            </Title>

            <DatesSelected>
              <DateGrid variant={variant}>
                <div className="date">
                  <strong>De </strong>
                  <span>{savedStartDate}</span>
                </div>
                <div className="time">
                  <AccessTime />
                  <span>15h</span>
                </div>
              </DateGrid>

              <DateGrid variant={variant}>
                <ArrowRightAlt className="arrow-icon" />
              </DateGrid>

              <DateGrid variant={variant}>
                <div className="date">
                  <strong>Até </strong>
                  <span>{savedEndDate}</span>
                </div>
                <div className="time">
                  <AccessTime />
                  <span>11h</span>
                </div>
              </DateGrid>
            </DatesSelected>

            <AlertMessage startDate={savedStartDate} endDate={savedEndDate} disableSubtitle />

            <ButtonContainer variant={variant}>
              <button type="button" onClick={handleCloseModalSuccessLock}>Ok</button>
            </ButtonContainer>
          </ContentBox>
        </ContainerBox>
      </ModalTemplate>
    )
  );
};

export default ModalSuccessLock;
