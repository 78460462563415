import React from 'react';
import { useCompleteReservation } from '../../../../../../hooks/CompleteReservationHook/useCompleteReservation';
import { ConfirmationLinkSentModalPreCheckin } from '../../../../../Precheckin';

export type IModal = {
  open: boolean;
  token: string;
  isHotel: boolean;
  reservation: {
    code: string | undefined;
    owner: string | undefined;
    check_in: string | undefined;
    id: string | undefined;
    reservationID: number | undefined;
  };
};

interface IPreCheckinModal {
  modal: IModal;
  setModal: React.Dispatch<React.SetStateAction<IModal>>;
}

const Modal = ({ modal, setModal }: IPreCheckinModal) => {
  const { setReservationsList, reservationsList } = useCompleteReservation();
  const reservation = reservationsList.find((item) => item?.code === modal.reservation?.id);

  const callback = (value: boolean) => {
    if (reservation?.code) {
      const newReservationList = reservationsList.map((item) => {
        if (item.code === reservation.code) {
          return {
            ...item,
            is_pre_checkin_link_sent: value,
          };
        }

        return {
          ...item,
        };
      });
      setReservationsList(newReservationList);
    }
  };

  return (
    <ConfirmationLinkSentModalPreCheckin
      modal={modal}
      setModal={setModal}
      callback={callback}
      is_pre_checkin_link_sent={reservation?.is_pre_checkin_link_sent || false}
    />
  );
};

export default Modal;
