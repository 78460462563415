import { Dispatch, SetStateAction } from 'react';
import {
  ContainerLabel, RadioGroup, RadioInput, RadioLabel,
} from './styles';

interface Option {
  value: boolean;
  label: string;
}

interface RadioButtonProps {
  options: Option[];
  selectedOption: boolean;
  setSelectedOption: Dispatch<SetStateAction<boolean>>;
  labelClassName?: string;
  label?: string;
}

function RadioButton({
  options,
  selectedOption,
  setSelectedOption,
  label,
  labelClassName = 'styledTextFieldLabel',
}: RadioButtonProps) {
  function handleClick(option: boolean) {
    setSelectedOption(option);
  }
  return (
    <>
      <ContainerLabel>
        <label className={labelClassName} htmlFor={label}>{label}</label>
      </ContainerLabel>
      <RadioGroup>
        {options.map((option) => (
          <RadioLabel key={option.label}>
            <RadioInput
              checked={selectedOption === option.value}
              onClick={() => handleClick(option.value)}
            />
            {option.label}
          </RadioLabel>
        ))}
      </RadioGroup>
    </>
  );
}

RadioButton.defaultProps = {
  label: '',
  labelClassName: '',
};

export default RadioButton;
