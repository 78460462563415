import {
  MoreVert as MoreVertIcon, Edit, ArrowForward as Arrow,
} from '@mui/icons-material';
import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { Text } from '../../Text';
import {
  Col,
  InfoContainer,
  InfoIcon,
  PropertyImage,
  SmallText,
  Wrapper,
  Separator,
  WrapperLabels,
  CopyContainer,
  CopyButton,
  NotesContainer,
  BarLabel,
  SmallTextBold,
  Space,
  SmallTextNote,
  FinanceContent,
  TitleContainer,
  PhoneContainer,
  ButtonStyled,
  MediumTextBold,
  LastSmallText,
  ButtonsContainer,
  Container,
  ContainerOverflow,
  InputLabel,
  Menu,
  Overlay,
} from './styles';

import {
  translatePaymentStatus,
  translateReservationStatus,
  translateBlockingReason,
} from '../../../utils/Translator';
import whatsapp from '../../../assets/icons/controll/whatsapp.svg';

import Box from '../../Box';
import Tooltip from '../../Tooltip';
import { onlyNumbers, removeSecondsHourToShow } from '../../../utils/Formatter';

import { Contract } from '../../Calendar/Reservation/ModalGeneral/styles';
import CopyIcon from '../../../assets/icons/generals/copywhite.svg';
import { useUser } from '../../../context/UserContext';
import FormButton from '../../FormButton';
import { ReservationStatus } from '../../../types/Reservations';
import { useReservationDetails } from '../../../context/ReservationDetailsContext';
import { patchReservation } from '../../../services/Reservation/request';
import { getFilesByFileUid } from '../../../services/NFlist/request';
import { useLoader } from '../../../context/LoaderContext';

interface Props {
  openFinance: () => void
  onUpdateStatus: (id: number, status: ReservationStatus) => void
  onClose: () => void
}

const InformationReservation: FC<Props> = ({ openFinance, onUpdateStatus, onClose }) => {
  const { setLoad } = useLoader();
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  const {
    reservationDetails, setReservationDetails, items, setItems,
  } = useReservationDetails();

  const [contractLink, setContractLink] = useState<string>('');
  const [hostCanCancelReservation, setHostCanCancelReservation] = useState(false);
  const [modalStatus, setModalStatus] = useState('');
  const [updateStatus, setUpdateStatus] = useState('');

  const getCheckInValue = (checkInTime: any, isBlocking: any) => {
    if (!checkInTime) return null;
    return isBlocking ? '00:00' : removeSecondsHourToShow(checkInTime);
  };

  const getCheckOutValue = (checkOutTime: any, isBlocking: any) => {
    if (!checkOutTime) return null;
    return isBlocking ? '00:00' : removeSecondsHourToShow(checkOutTime);
  };

  const options = [
    {
      title: 'Concluída',
      value: 'Concluded',
      action: () => {
        setUpdateStatus('Concluded');
      },
    },
    {
      title: 'Cancelada',
      value: 'Canceled',
      action: () => {
        setUpdateStatus('Canceled');
      },
    },
    {
      title: 'No Show',
      value: 'No-Show',
      action: () => {
        setUpdateStatus('No-Show');
      },
    },
  ];

  function openContract(urlContract: string) {
    window.open(urlContract, '_blank');
  }

  const reservationItems = [
    { label: 'Código da reserva', value: reservationDetails.code },
    { label: 'Total de noites', value: reservationDetails.number_of_nights },
    {
      label: 'Valor total líquido',
      value: reservationDetails.totalPrice,
      tooltip: 'Valor já com o desconto da taxa de OTA',
      icon: <InfoIcon />,
    },
    { label: 'Valor líquido da taxa de limpeza', value: reservationDetails?.netCleaningFee },
    {
      label: 'Horário check-in',
      value: getCheckInValue(reservationDetails.checkInTime, reservationDetails.is_blocking),
    },
    {
      label: 'Horário check-out',
      value: getCheckOutValue(reservationDetails.checkOutTime, reservationDetails.is_blocking),
    },
    {
      label: 'Data de criação',
      value: `${reservationDetails.createdAtDate} ${removeSecondsHourToShow(reservationDetails.createdAtHour)}`,
    },
    {
      label: 'Origem da reserva',
      value: !reservationDetails?.is_blocking ? reservationDetails.otaName : null,
    },
    {
      label: 'N°. de hóspedes',
      value: `${reservationDetails.adultGuests} adulto(s), ${reservationDetails.childrenGuests} criança(s) e ${reservationDetails.pet_quantity} pet(s)`,
    },
    {
      label: 'Contrato',
      value: reservationDetails?.is_monthly ? (
        <Contract type="button" onClick={() => openContract(contractLink)}>
          Ver contrato
        </Contract>
      ) : null,
    },
    { label: 'Hóspede já contatado?', value: reservationDetails.wasContacted ? 'Sim' : 'Não' },
  ];

  async function handleUpdateStatus() {
    setLoad(true);
    const dataRequest = {
      check_in_date: reservationDetails.checkInDate.replaceAll('/', '-'),
      check_out_date: reservationDetails.checkOutDate.replaceAll('/', '-'),
      comment: reservationDetails.comment || '',
      conciliada: reservationDetails.conciliada || false,
      status: updateStatus as ReservationStatus,
    };

    await patchReservation(reservationDetails.id, dataRequest);

    onUpdateStatus(reservationDetails.id, updateStatus as ReservationStatus);
    setReservationDetails((prev: any) => ({ ...prev, status: updateStatus }));

    if (roles.includes('Host') && updateStatus === 'Canceled') {
      const newItems = items.filter((item: any) => item.id !== reservationDetails.id);
      setItems(newItems);
      onClose();
    }
    setLoad(false);
  }

  async function getContract() {
    const result = await getFilesByFileUid(reservationDetails?.monthly_contract || '');
    setContractLink(result?.url || '');
  }

  useEffect(() => {
    if (reservationDetails?.is_monthly) {
      getContract();
    }
  }, []);

  useEffect(() => {
    if (roles.includes('Host')) {
      const ArrayStatus = ['Host', 'Maintenance', 'Cleaning', 'Owner use'];
      const hostCanBlockReasons = ArrayStatus.includes(reservationDetails?.blocking_reason);

      if (hostCanBlockReasons && reservationDetails.is_blocking === true) {
        setHostCanCancelReservation(true);
      } else {
        setHostCanCancelReservation(false);
      }
    } else {
      setHostCanCancelReservation(true);
    }
  }, [roles, reservationDetails.blocking_reason]);

  return (
    <Container>
      <ContainerOverflow>
        <TitleContainer>
          <Wrapper $flexDireciton="column" $alignItems="flex-start">
            <div>
              {!reservationDetails?.is_blocking && (
              <MediumTextBold>{reservationDetails?.guest?.client}</MediumTextBold>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <SmallText>
                {`${reservationDetails.adultGuests} adulto(s) . ${reservationDetails.pet_quantity} pet(s) . ${reservationDetails.number_of_days - 1} diária(s) . `}
              </SmallText>
              <LastSmallText>
                {roles.includes('Host') ? '-' : reservationDetails.totalPrice}
              </LastSmallText>
            </div>
          </Wrapper>

          <Wrapper $flexDireciton="column">
            <ButtonStyled
              type="button"
              reservationStatus={reservationDetails?.status}
              blocked={
                (reservationDetails?.is_blocking && reservationDetails?.status !== 'Canceled')
                || false
              }
            >
              <SmallTextBold>
                {!reservationDetails?.is_blocking
                  ? translateReservationStatus(reservationDetails?.status)
                  : 'Bloqueada'}
              </SmallTextBold>
            </ButtonStyled>
            {reservationDetails?.is_blocking ? (
              <SmallText>
                {`Motivo: ${translateBlockingReason(reservationDetails?.blocking_reason || '')}`}
              </SmallText>
            ) : (
              <PhoneContainer
                onClick={() => window.open(`https://api.whatsapp.com/send?phone=${onlyNumbers(reservationDetails.guest.phoneNumber1)}`,
                  '_blank')}
              >
                <img src={whatsapp} alt="Phone" />
                <SmallText>{reservationDetails.guest.phoneNumber1}</SmallText>
              </PhoneContainer>
            )}
          </Wrapper>
        </TitleContainer>

        <Box>
          <div style={{ maxWidth: '50%' }}>
            <Text $color="#151B26" $fontSize="14">
              <strong>{reservationDetails.propertyCode}</strong>
              {reservationDetails.propertyAddress}
            </Text>
          </div>
          <PropertyImage backgroundImage="" />
        </Box>

        <Wrapper style={{ margin: '8px 0' }}>
          <Col>
            <SmallTextBold>Check-in</SmallTextBold>
            <SmallText>{moment(reservationDetails.checkInDate).format('DD/MMM/YYYY')}</SmallText>
          </Col>
          <Arrow />
          <Col>
            <SmallTextBold>Check-out</SmallTextBold>
            <SmallText>{moment(reservationDetails.checkOutDate).format('DD/MMM/YYYY')}</SmallText>
          </Col>
        </Wrapper>

        {reservationItems.map((detail) => detail.value !== null && (
          <div key={detail.label}>
            <WrapperLabels key={detail.label}>
              {detail.tooltip ? (
                <>
                  <InfoContainer>
                    <SmallText>{detail.label}</SmallText>
                    <Tooltip text={detail.tooltip}>{detail.icon}</Tooltip>
                  </InfoContainer>
                  <SmallText>{detail.value}</SmallText>
                </>
              ) : (
                <>
                  <SmallText>{detail.label}</SmallText>
                  <SmallText>{detail.value}</SmallText>
                </>
              )}
            </WrapperLabels>
          </div>
        ))}

        <CopyContainer>
          <CopyButton
            onClick={
              () => navigator.clipboard.writeText(reservationDetails.reservationCopyInformation)
}
            type="button"
          >
            <img src={CopyIcon} alt="icone de copiar" />
            Copiar informações da reserva
          </CopyButton>
        </CopyContainer>

        <NotesContainer>
          <BarLabel>Notas</BarLabel>
          {reservationDetails.is_blocking && reservationDetails.commentSplitted[0]?.length >= 1 && (
            <>
              <Space />
              <SmallTextBold className="notes">Imóvel bloqueado</SmallTextBold>
              <SmallTextNote
                disabled
                rows={reservationDetails.commentSplitted[0]?.length > 300 ? 10 : 5}
                cols={40}
              >
                {reservationDetails.commentSplitted[0]}
              </SmallTextNote>
            </>
          )}
          {reservationDetails.is_blocking && reservationDetails.status === 'Canceled' && (
            <Separator />
          )}
          {reservationDetails.status === 'Canceled' && (
            <>
              {reservationDetails?.commentSplitted[1]?.length > 1 && (
                <>
                  <Space />
                  <SmallTextBold className="notes">
                    {reservationDetails.is_blocking ? 'Bloqueio cancelado' : 'Reserva cancelada'}
                  </SmallTextBold>
                  <SmallTextNote
                    disabled
                    rows={reservationDetails?.commentSplitted[1]?.length > 300 ? 10 : 3}
                    cols={40}
                  >
                    {reservationDetails?.commentSplitted[1]}
                  </SmallTextNote>
                </>
              )}
            </>
          )}
        </NotesContainer>

        {!reservationDetails.is_blocking && !roles.includes('Host') && (
          <div>
            <BarLabel>Financeiro</BarLabel>
            <FinanceContent>
              <div>
                <Wrapper>
                  <SmallText>Status pagamento</SmallText>
                  <SmallText>{translatePaymentStatus(reservationDetails?.paymentStatus)}</SmallText>
                </Wrapper>
                <Wrapper>
                  <SmallText>Valor caução</SmallText>
                  <SmallText>{reservationDetails?.bondAmount}</SmallText>
                </Wrapper>
              </div>
              <button type="button" onClick={() => openFinance()}>
                Mais informações
              </button>
            </FinanceContent>
          </div>
        )}
      </ContainerOverflow>

      {((reservationDetails?.status === 'Canceled'
        && !roles.includes('Host')) || hostCanCancelReservation) && (
        <ButtonsContainer>
          {reservationDetails?.status === 'Canceled'
        && !roles.includes('Host') ? (
          <FormButton disable customColor="grey" type="button">
            {reservationDetails?.is_blocking
              ? 'Bloqueio cancelado'
              : !roles.includes('Host') && 'Reserva cancelada'}
          </FormButton>
            ) : <div />}

          {hostCanCancelReservation && (
          <>
            <Tooltip text="Mais opções">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => setModalStatus('startProccess')}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>

            {modalStatus === 'startProccess' && (
            <>
              <Overlay onClick={() => setModalStatus('')} />
              <Menu>
                <>
                  <Wrapper $justifyContent="flex-start">
                    <Edit
                      style={{
                        color: 'rgb(13, 75, 208)',
                        margin: '0px',
                        fontSize: '16px',
                      }}
                    />
                    <Text $color="000" $fontSize="14">
                      Atualizar status
                    </Text>
                  </Wrapper>
                  {options.map((option) => (
                    <Wrapper key={option.value} $justifyContent="flex-start">
                      <InputLabel htmlFor={option.title}>
                        <input
                          id={option.title}
                          name={option.title}
                          type="radio"
                          value={option.value}
                          onChange={option.action}
                          checked={updateStatus === option.value}
                        />
                        <Text $color="000" $fontSize="14">
                          {option.title}
                        </Text>
                      </InputLabel>
                    </Wrapper>
                  ))}
                  <FormButton type="button" onClick={() => handleUpdateStatus()}>
                    Confirmar
                  </FormButton>
                </>
              </Menu>
            </>
            )}
          </>
          )}
        </ButtonsContainer>
      )}

    </Container>
  );
};

export default InformationReservation;
