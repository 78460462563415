import styled from 'styled-components';
import { UrlLink } from '../../../../services/types';
import defaultImage from '../../../../assets/icons/generals/property-not-found.svg';

export const ContainerBox = styled.form``;

export const HorizontalRow = styled.div`
  margin: 0.5rem 0;
  border-top: 2px solid ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const BodyBox = styled.div`
  margin: 2rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-style: normal;
  font-weight: 500;
`;

export const BodyBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas:
    'property status button'
  ;

  :first-child > div:first-child {
    grid-area: 'property';
  }

  :first-child > div:last-child {
    grid-area: 'status';
  }

  :first-child > div > p{
    font-weight: bold;
  }

  @media(min-width: 320px)  and (max-width: 780px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'property'
      'status'
      'button'
    ;

    :first-child > div:last-child {
      display: none;
    }
  }
`;

export const PropertyContainer = styled.div`
  width: 100%;
  grid-area: 'property';

  display: flex;
  justify-content: space-between;
  align-items: center;


  @media(min-width: 320px)  and (max-width: 780px) {
    justify-content: flex-start;

    h2 {
      font-size: 1.4rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  @media(min-width: 320px)  and (max-width: 980px) {
    justify-content: center;
    align-items: center;
  }
`;

export const PropertyImage = styled.div<{ backgroundImage?: UrlLink, status: string }>`
  display: flex;
  justify-content: flex-end;
  min-width: 52px;
  width: 60px;
  height: 44px;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : `url(${defaultImage})`)};
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  position: relative;
  opacity: ${({ status }) => (status === 'available' ? 1 : 0.5)};
`;

export const PropertyContent = styled.div`
  margin: 1rem 2rem;
`;

export const PropertyCode = styled.h2<{ status: string }>`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  letter-spacing: 0.1rem;
  font-size: 2rem;
  color: ${({ theme, status }) => (status === 'available' ? theme.palette.grey._900.hex() : theme.palette.grey._400.hex())};
`;

export const PropertyAlias = styled.p<{ status: string }>`
  font-size: 1.1rem;
  color: ${({ theme, status }) => (status === 'available' ? theme.palette.grey._900.hex() : theme.palette.grey._400.hex())};
`;

export const StatusContainer = styled.div`
  width: 100%;
  height: auto;

  grid-area: 'status';

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-left: 3px solid ${({ theme }) => theme.palette.grey._350.hex()};
  border-right: 3px solid ${({ theme }) => theme.palette.grey._350.hex()};
  padding-left: 2rem;

  @media(min-width: 320px)  and (max-width: 780px) {
    padding: 0 0 2rem 0;
    justify-content: center;
    align-items: center;
    border: 0;
  }

`;

export const StatusContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-width: 320px)  and (max-width: 780px) {
    span {
      font-size: 1rem;
    }
  }
`;

export const Circle = styled.div<{ status: string }>`
  width: 18px;
  height: 18px;
  border: 4px solid ${({ theme, status }) => (status === 'available' ? theme.palette.green.main.hex() : theme.palette.grey._400.hex())};
  border-radius: 50%;
`;

export const Status = styled.span`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: bold;
  margin-left: 1rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  grid-area: 'button';
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiButton-root > .MuiButton-label {
    font-size: 1rem !important;
  }

  padding-left: 10rem;
  @media(min-width: 320px)  and (max-width: 900px) {
    padding-left: 0;

    h2 {
      font-size: 1rem;
    }
  }
`;
