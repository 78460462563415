/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import { getReservationGuestDamages } from '../../../../services/GuestDamage/request';
import { OwnerResume } from './OwnerResumeCard';
import { RefundDamageHeader } from './RefundDamgeHeader';
import { SeazoneResume } from './SeazoneResumeCard';

import {
  RefundDamageCardContainer, CardContainer, Subtitle,
} from './styles';
import { useGuestDamageNegotiation } from '../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { GetGuestDamage } from '../../../../services/GuestDamage/types';
import { compareList } from '../../../../utils/Sorting';

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';
type Order = 'asc' | 'desc';

export const ViewRefundDamageCard = () => {
  const { reservationGuestDamages, setReservationGuestDamages } = useGuestDamage();
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const [sortedBy, setSortedBy] = useState<GuestDamageSortedBy>('type');
  const [order, setOrder] = useState<Order>('asc');
  const [sortedGuestDamages,
    setSortedGuestDamages] = useState<GetGuestDamage[]>(reservationGuestDamages);
  const seazoneReservationGuestDamages = sortedGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Seazone');
  const haveSeazoneReservations = seazoneReservationGuestDamages.length > 0;
  const ownerReservationGuestDamages = sortedGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Proprietário');
  const haveOwnerReservations = ownerReservationGuestDamages.length > 0;

  async function handleGetReservationGuestDamages(id:number) {
    const data = await getReservationGuestDamages(id);
    setReservationGuestDamages(data);
  }
  function handleSortGuestDamage(sortBy: GuestDamageSortedBy) {
    switch (sortBy) {
      case 'type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type!, b.damage_type!, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type!, b.damage_type!, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.damage_type!, b.damage_type!, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type!, b.item_type!, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type!, b.item_type!, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_type!, b.item_type!, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_name': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name!, b.item_name!, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name!, b.item_name!, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_name');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_name!, b.item_name!, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'resolution': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution!, b.resolution!, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution!, b.resolution!, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('resolution');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.resolution!, b.resolution!, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'refund_holder': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder!, b.refund_holder!, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder!, b.refund_holder!, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('refund_holder');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.refund_holder!, b.refund_holder!, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_quantity': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity!, b.item_quantity!, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity!, b.item_quantity!, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_quantity');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_quantity!, b.item_quantity!, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'price': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('price');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            (a?.item_quantity * (a?.item_price || 0)) || '-',
            (b?.item_quantity * (b?.item_price || 0)) || '-',
            'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      default: {
        const sortedNegotiations = reservationGuestDamages.sort((a, b) => compareList(
          a.damage_type!, b.damage_type!, 'asc',
        ));
        setSortedGuestDamages(sortedNegotiations);
        break;
      }
    }
  }

  useEffect(() => {
    handleSortGuestDamage('type');
  }, [reservationGuestDamages]);

  useEffect(() => {
    if (selectedGuestDamageNegotiation.reservation?.id) {
      handleGetReservationGuestDamages(selectedGuestDamageNegotiation.reservation.id);
    }
  }, [selectedGuestDamageNegotiation?.reservation?.id]);

  return (
    <RefundDamageCardContainer>
      <CardContainer
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
        }}
        exit={{ y: -100, opacity: 0 }}
        style={{ width: '100%' }}
      >
        <Subtitle>Reembolso do dano</Subtitle>
        <RefundDamageHeader />
      </CardContainer>
      {haveOwnerReservations && (
        <CardContainer
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
          }}
          exit={{ y: -100, opacity: 0 }}
          style={{ width: '100%' }}
        >
          <Subtitle>Resumo de danos lançados - Proprietário</Subtitle>
          <OwnerResume
            handleSortGuestDamage={handleSortGuestDamage}
            ownerReservationGuestDamages={ownerReservationGuestDamages}
          />
        </CardContainer>
      )}
      {haveSeazoneReservations && (
        <CardContainer
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
          }}
          exit={{ y: -100, opacity: 0 }}
          style={{ width: '100%' }}
        >
          <Subtitle>Resumo de danos lançados - Seazone</Subtitle>
          <SeazoneResume
            handleSortGuestDamage={handleSortGuestDamage}
            seazoneReservationGuestDamages={seazoneReservationGuestDamages}
          />
        </CardContainer>
      )}
    </RefundDamageCardContainer>
  );
};
