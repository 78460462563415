import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from  {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const showElementAnimation = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.151);
  }
`;

export const FadeTransition = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: auto;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  width: 100%;
  height: 100px;
`;

export const Content = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-bottom: 5px solid ${({ theme }) => theme.palette.white._300.hex()};

  width: 100%;
  height: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 460px;
  margin-left: 2rem;
`;

export const InputContainer = styled.div`
  width: 100%;

  && {
    .MuiOutlinedInput-root {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      min-width: 80px;
      border: 0;

      svg {
        fill: ${({ theme }) => theme.palette.blue._400.hex()};
      }

      & fieldset {
        border: solid 1px ${({ theme }) => theme.palette.grey._450.hex()};
        animation: ${showElementAnimation} 0.5s ease forwards;
      }

      &:hover fieldset{
        border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
      }

      &.Mui-focused fieldset {
        border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
      }
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  max-width: 150px;
  height: auto;

  margin: 0 2rem 1.3rem 0;

  p:first-child {
    font-size: 0.9rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    padding-bottom: 0.3rem;
  }
`;

export const CalendarContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
  max-width: 150px;
  height: 40px;

  border: solid 1px ${({ theme }) => theme.palette.grey._450.hex()};
  border-radius: 10px;

  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.palette.blue._400.hex()};
  }

  p {
    color: ${({ theme }) => theme.palette.grey._700.hex()};
  }

  &:hover{
    border: solid 1px ${({ theme }) => theme.palette.blue._400.hex()};
    p {
      color: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }
`;

export const CalendarModalContainer = styled.div<{
  isOpen?: boolean;
}>`
  animation: ${FadeTransition} 0.2s ease-in-out;
  #monthModalViewer {
    position: absolute;
    .container {
      position: fixed;
      right: 130px;
      top: 98px;
    }
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => `${theme.palette.grey._450.hex()}`} !important;
  }

  .react-calendar__tile--hasActive {
    background: linear-gradient(180deg, #eb6b57 0%, #cf4f44 100%) !important;
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonActions = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 101px;
  height: 40px;

  border: 0;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.blue._400.hex()};
  margin-right: 2rem;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.palette.white.main.hex()};
  }

  p {
    color: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const ModalActions = styled.div<{ showActionsOptions: boolean }>`
  display: ${({ showActionsOptions }) => (showActionsOptions ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 195px;
  height: auto;
  border-radius: 10px;

  position: absolute;
  top: 105px;
  margin-right: 105px;
  z-index: 11;
`;

export const Action = styled.div<{ loading?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 40px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  padding: 0 1.2rem;

  position: relative;
  background: ${({ theme }) => theme.palette.white.main.hex()};

  animation: ${showElementAnimation} 0.5s ease forwards;

  cursor: pointer;

  &.styled {
    height: 60px;
  }

  img {
    width: 20px;
    height: 20px;
    animation: ${fadeIn} 2s ease forwards;
  }

  p {
    color: ${({ theme }) => theme.palette.blue._950.hex()};
    font-size: 16px;
    animation: ${fadeIn} 2s ease forwards;
  }

  ${({ loading }) => loading && css`
    img {
      animation: ${rotate} 1s linear infinite;
    }
  `}
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
`;
