import React, { useState } from 'react';
import {
  Row,
  Alert,
  Choise,
  Section,
  Container,
} from './style';

import StyledSection, { ISection } from '../Section';
import { IOSSwitch } from '../../../../../../../../../components/SwitcherButton/SwitcherButton';
import { usePreCheckin } from '../../../../../../../../../context/PreCheckin/PreCheckin';
import { patchPreCheckin } from '../../../../../../../../../services/PreCheckin';

interface ISwitcher {
  label: string;
}

interface ITemplate extends ISection {}

const Template = ({ children, ...props }: ITemplate) => (
  <StyledSection {...props}>
    {children}
  </StyledSection>
);

const Switcher = ({
  label,
}: ISwitcher) => {
  const {
    setInfo, info, mode, token,
  } = usePreCheckin();
  const [active, setActive] = useState(info.has_pet > 0);

  const handleClick = async () => {
    const value = !active;

    setActive((old) => !old);
    setInfo((old) => ({ ...old, has_pet: value ? 1 : 0 }));

    if (mode._default === 'view') {
      const reservationId = info?.reservation_id;
      if (reservationId && token) {
        await patchPreCheckin({
          token,
          reservation: reservationId,
          data: {
            has_pet: value ? 1 : 0,
          },
        });
      }
    }
  };

  return (
    <Section>
      <p>{label}</p>
      <Row>
        <Choise active={!active}>Não</Choise>
        <IOSSwitch disabled={mode.is === 'view'} checked={active} onChange={() => handleClick()} />
        <Choise active={active}>Sim</Choise>
      </Row>
    </Section>
  );
};

const GuestOptions = () => {
  const { info } = usePreCheckin();
  const propertyType = info?.property?.property_type;

  if (propertyType !== 'Hotel') {
    return (
      <Template divider label="Opções">
        <Container>
          <Alert>
            Esta solicitação está sujeita a disponibilidade e deve ser aprovada pelo atendimento.
            <br />
            <span>Consulte valores extras de cobrança.</span>
          </Alert>

          <Switcher label="Vai levar pet ?" />
        </Container>
      </Template>
    );
  }

  return null;
};

export default GuestOptions;
