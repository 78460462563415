import { LocaleIDProps, TimezoneProps } from './types';

type DateStr = string; // "YYYY-MM-DD" | "DD-MM-YYYY" | "YYYY/MM/DD" | "DD/MM/YYYY";

export const changeTimeZoneOfDate = (
  date: Date | DateStr,
  timeZone: TimezoneProps = 'America/Los_Angeles',
  localeFormat: LocaleIDProps = 'en-US', // "en-US" use date format: "YYYY-MM-DD",
): Date => {
  if (typeof date === 'string') {
    return new Date(new Date(date).toLocaleString(localeFormat, {
      timeZone,
    }));
  }

  return new Date(date.toLocaleString(localeFormat, {
    timeZone,
  }));
};

export const NEW_DATE_DEFAULT = changeTimeZoneOfDate(new Date(), 'America/Sao_Paulo');

export const getMonthName: Record<number, string> = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

export const getMonthNumber: Record<number, string> = {
  0: '01',
  1: '02',
  2: '03',
  3: '04',
  4: '05',
  5: '06',
  6: '07',
  7: '08',
  8: '09',
  9: '10',
  10: '11',
  11: '12',
};
