import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  gap: 100px;
  align-items: baseline;

  @media (max-width: 900px){
    flex-direction: column;
  }
`;
