import { Tooltip } from '@mui/material';

import { useState } from 'react';
import { Button, MobileContainer } from '../style';
import { ConfirmModal } from './Modal';
import { usePreCheckin } from '../../../../../../context/PreCheckin/PreCheckin';
import { containerAnimation } from '../animation';

export const Edit = () => {
  const { hasIsPrincipal } = usePreCheckin();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const desktopTooltipMessage = !hasIsPrincipal.is && (
    <p>Indisponível pois você ainda não adicionou o hóspede principal</p>
  );

  return (
    <>
      <ConfirmModal setOpen={setOpen} open={open} />
      <Tooltip
        title={(
          <>
            <h1>Concluir Pré-checkin</h1>
            {desktopTooltipMessage}
          </>
        )}
      >
        <MobileContainer {...containerAnimation}>
          <Button whileTap={{ scale: 0.95 }} disabled={!hasIsPrincipal.is} onClick={handleClick} type="button">
            Concluir
          </Button>
        </MobileContainer>
      </Tooltip>
    </>
  );
};
