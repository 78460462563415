import styled from 'styled-components';

export const SettingsButton = styled.button`

  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 4vh;
  right: 2vw;
  border-radius:10px;
  border: none;
  color: white;
  background: linear-gradient(180deg, #62656F 0%, #4A4C51 100%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 16;

  @media (max-width: 900px) {
    right: 3vh;
    bottom: 14vh;
  }

  &:hover{

    opacity: 0.85;

  }

`;
