import { useContextSelector } from 'use-context-selector';
import { FinancialCloseHostContext } from '../../context/FinancialClosePage/FinancialCloseHostContext/FinancialCloseHostContext';

export function useFinancialCloseHost() {
  const financialDataHost = useContextSelector(FinancialCloseHostContext, (state) => state
    .financialDataHost);

  const handleSetFinancialDataHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.setFinancialDataHost);

  const handleUpdateFinancialDataHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.updateFinancialDataHost);

  const handleUpdateExpenseHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.updateExpenseHost);

  const handleUpdateCheckedOfHostSelected = useContextSelector(FinancialCloseHostContext,
    (state) => state.updateCheckedOfHostSelected);

  const handleUpdateAllCheckedsOfHostSelected = useContextSelector(FinancialCloseHostContext,
    (state) => state.updateAllCheckedsOfHostSelected);

  const handleUpdateExpandedOfHostSelected = useContextSelector(FinancialCloseHostContext,
    (state) => state.updateExpandedOfHostSelected);

  const handleAddReservationsAndExpensesHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.addReservationsHost);

  const openModalAddManualFitHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.openModalAddManualFitHost);
  const handleOpenModalAddManualFitHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.setOpenModalAddManualFitHost);

  const openModalEditManualFitHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.openModalEditManualFitHost);
  const handleOpenModalEditManualFitHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.setOpenModalEditManualFitHost);

  const openModalFranchiseFeeHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.openModalFranchiseFeeHost);
  const handleOpenModalFranchseFeeHost = useContextSelector(FinancialCloseHostContext,
    (state) => state.setOpenModalFranchiseFeeHost);

  return {
    financialDataHost,
    handleSetFinancialDataHost,
    handleUpdateFinancialDataHost,
    handleUpdateExpenseHost,
    handleUpdateCheckedOfHostSelected,
    handleUpdateAllCheckedsOfHostSelected,
    handleUpdateExpandedOfHostSelected,
    handleAddReservationsAndExpensesHost,
    openModalAddManualFitHost,
    handleOpenModalAddManualFitHost,
    openModalEditManualFitHost,
    handleOpenModalEditManualFitHost,
    openModalFranchiseFeeHost,
    handleOpenModalFranchseFeeHost,
  };
}
