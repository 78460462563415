import { PhotoModalContainer, PhotoModalOverlay } from './styles';

interface PhotoModalProps {
  src?: string;
  alt?: string;
  handleCloseModal: () => void;
}
export function PhotoModal({ src, alt, handleCloseModal }: PhotoModalProps) {
  return (
    <PhotoModalOverlay onClick={handleCloseModal}>
      <PhotoModalContainer>
        <img src={src} alt={alt} />
      </PhotoModalContainer>
    </PhotoModalOverlay>
  );
}

PhotoModal.defaultProps = {
  src: '',
  alt: '',
};
