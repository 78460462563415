import { Fragment, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ViewDamageDetailsCardContainer,
  DamageDetailsHeaderContainer,
  DamageDetailsHeaderItem,
  DamageTypeContainer,
  TypeButton,
  ButtonContainer,
} from './styles';

import trousseauIcon from '../../../../assets/icons/guestDamage/trousseauIcon.svg';
import itemDamageIcon from '../../../../assets/icons/guestDamage/itemDamageIcon.svg';
import itemLossIcon from '../../../../assets/icons/guestDamage/itemLossIcon.svg';
import propertyDamageIcon from '../../../../assets/icons/guestDamage/propertyDamageIcon.svg';
import pendenciesIcon from '../../../../assets/icons/guestDamage/pendenciesIcon.svg';
import TrousseauFormView from './TrousseauFormView';
import PropertyDamageFormView from './PropertyDamageFormView';
import ItemLossFormView from './ItemLossFormView';
import ItemDamageFormView from './ItemDamageFormView';
import PendencyFormView from './PendencyFormView';
import { DamageResume } from './DamageResume';
import {
  deleteGuestDamageEvidence,
  getReservationGuestDamages,
  patchGuestDamage,
  patchGuestDamageNegotiation,
  postGuestDamageEvidences,
} from '../../../../services/GuestDamage/request';
import { useGuestDamageNegotiation } from '../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { useToast } from '../../../../context/ToastContext';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import { EvidencesProps, GetGuestDamage, GuestDamageItemType } from '../../../../services/GuestDamage/types';
import { currencyToNumber } from '../../../../utils/Formatter';
import FormButton from '../../../FormButton';
import { useUser } from '../../../../context/UserContext';
import { useGuestDamageStep } from '../../../../hooks/GuestDamage/useGuestDamageStep';
import { FileProps } from '../../../../context/FileContext/types';

interface DamageTypeProblemsProps {
  name: string;
  icon: string;
  value: string;
  iconAlt: string;
  isActive: boolean;
}

const initialDamageTypeProblems: DamageTypeProblemsProps[] = [
  {
    name: 'Enxoval',
    value: 'Enxoval',
    icon: trousseauIcon,
    iconAlt: 'ícone do enxoval',
    isActive: false,
  },
  {
    name: 'Danos a itens(ex: móveis, eletrodomésticos, utensílios, banheiro)',
    value: 'Danos a itens',
    icon: itemDamageIcon,
    iconAlt: 'ícone de danos a itens',
    isActive: false,
  },
  {
    name: 'Extravio de item',
    value: 'Extravio de item',
    icon: itemLossIcon,
    iconAlt: 'ícone de extravio de item',
    isActive: false,
  },
  {
    name: 'Danos à Propriedade (ex: janela, porta, parede)',
    value: 'Danos à propriedade',
    icon: propertyDamageIcon,
    iconAlt: 'ícone de danos a propriedade',
    isActive: false,
  },
  {
    name: 'Pendências (multa, restaurante)',
    icon: pendenciesIcon,
    value: 'Pendências',
    iconAlt: 'ícone de pendências',
    isActive: false,
  },
];

const validation = Yup.object().shape({
  itemName: Yup.string().required('Este campo é obrigatório!'),
  resolution: Yup.string().required('Este campo é obrigatório!'),
  itemPrice: Yup.string().required('Este campo é obrigatório!'),
  quotationLink: Yup.string().matches(/^(https?:\/\/).*/,
    'A URL deve começar com http:// ou https://'),
  other: Yup.string().when('itemName', {
    is: 'Outro',
    then: Yup.string().required('Este campo é obrigatório!'),
    otherwise: Yup.string(),
  }),
});
export const ViewDamageDetailsCard = () => {
  const [damageTypeProblems, setDamageTypeProblems] = useState<
  DamageTypeProblemsProps[]
  >(initialDamageTypeProblems);
  const [quantity, setQuantity] = useState(1);
  const [isEditingForm, setIsEditingForm] = useState(false);
  const {
    selectedGuestDamageNegotiation,
    setSelectedGuestDamageNegotiation,
  } = useGuestDamageNegotiation();
  const {
    setIsLoading,
    setReservationGuestDamages,
    reservationGuestDamages,
    selectedGuestDamage,
    quotationFile,
    setSelectedGuestDamage,
    setEvidences,
    setQuotationFile,
    evidences,
  } = useGuestDamage();
  const { handleInsertDamageDetailsStep } = useGuestDamageStep();
  const toast = useToast();
  const { userInformation } = useUser();
  const roles = userInformation?.roles;
  const initialValues = {
    itemName: selectedGuestDamage.item_name,
    resolution:
      selectedGuestDamage.resolution || (undefined as undefined | string),
    itemPrice:
      selectedGuestDamage.item_price || (undefined as undefined | number),
    description:
      selectedGuestDamage.description || (undefined as undefined | string),
    itemType:
      selectedGuestDamage.item_type
      || (undefined as undefined | GuestDamageItemType | 'Outro'),
    observation:
      selectedGuestDamage.observation || (undefined as undefined | string),
    other: undefined as undefined | string,
    quotationLink:
      selectedGuestDamage.quotation_link || (undefined as undefined | string),
  };

  function findUidsToDelete(newEvidences: FileProps[], oldEvidences: EvidencesProps[]): number[] {
    return oldEvidences
      .filter((oldEvidence) => !newEvidences.some((newEvidence) => newEvidence
        .uid === oldEvidence.evidence?.uid))
      .map((evidenceToDelete) => evidenceToDelete.id!);
  }

  function findUidsToAdd(newEvidences: FileProps[], oldEvidences: EvidencesProps[]): string[] {
    return newEvidences
      .filter((newEvidence) => !oldEvidences.some((oldEvidence) => oldEvidence
        .evidence?.uid === newEvidence.uid))
      .map((newEvidenceToAdd) => newEvidenceToAdd.uid || '');
  }

  async function handleDeleteEvidences(ids: number[]) {
    const promises = ids
      .map((id) => deleteGuestDamageEvidence(id));

    await Promise.all(promises);
  }

  async function handleAddEvidences(ids: string[]) {
    const promises = ids
      .map((id) => postGuestDamageEvidences(Number(selectedGuestDamage.id!), id));

    await Promise.all(promises);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const evidencesToDelete = findUidsToDelete(evidences,
          selectedGuestDamage.evidences || []);
        const evidencesToAdd = findUidsToAdd(evidences, selectedGuestDamage.evidences || []);

        if (evidencesToDelete.length > 0) {
          await handleDeleteEvidences(evidencesToDelete);
        }

        if (evidencesToAdd.length > 0) {
          await handleAddEvidences(evidencesToAdd);
        }
        const response = await patchGuestDamage(selectedGuestDamage.id as number,
          {
            ...selectedGuestDamage,
            item_type:
              values.itemType === 'Outro' ? undefined : values.itemType,
            item_name:
              values.itemName === 'Outro' || values.itemType === 'Outro'
                ? values.other!
                : values.itemName || '',
            item_quantity: quantity,
            resolution: values.resolution,
            description: values.description,
            item_price: currencyToNumber(String(values.itemPrice)),
            observation: values.observation,
            quotation_file: quotationFile?.uid ? quotationFile?.uid : '',
            quotation_link: values.quotationLink,
            evidences: undefined,
          });

        setSelectedGuestDamage(response);

        setReservationGuestDamages((oldValues) => oldValues.map((guestDamage) => {
          if (guestDamage.id === response.id) {
            const updatedGuestDamage = {
              ...response,
            };
            return updatedGuestDamage;
          }
          return guestDamage;
        }));
        setEvidences([]);
        setQuotationFile({} as FileProps);
        setSelectedGuestDamage({} as GetGuestDamage);
        toast.success('Dados atualizados com sucesso!');
        setIsEditingForm(false);
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Erro na atualização dos dados!');
        }
      }
    },
  });

  const handleGetReservationGuestDamages = async (reservation?: number) => {
    if (reservation) {
      try {
        setIsLoading(true);
        const response = await getReservationGuestDamages(reservation);
        if (response.length > 1) {
          setReservationGuestDamages(response);
        }
        if (response.length === 1) {
          setReservationGuestDamages([...response]);
        }
        setIsLoading(false);
      } catch (err) {
        toast.error('Não foi possível recuperar os danos do hóspede!');
        setIsLoading(false);
      }
    } else {
      toast.error('Não foi possível recuperar os danos do hóspede!');
    }
  };

  useEffect(() => {
    handleGetReservationGuestDamages(selectedGuestDamageNegotiation?.reservation?.id);
  }, [selectedGuestDamageNegotiation.reservation?.id]);

  const foundGuestDamage = reservationGuestDamages.find((guestDamage) => {
    if (guestDamage.description === undefined) {
      return false;
    }
    return guestDamage;
  });

  const handleShowProblemForm = (name: string) => {
    setDamageTypeProblems((oldState) => {
      const updatedDamageType = oldState.map((damageType) => {
        if (damageType.name === name) {
          return {
            ...damageType,
            isActive: !damageType.isActive,
          };
        }
        return damageType;
      });
      return updatedDamageType;
    });
  };

  const formattedCheckoutDate = moment(selectedGuestDamageNegotiation.reservation?.check_out_date)
    .format('DD/MM/YYYY') || '-';

  async function handleResetValidationsGuestDamage() {
    const promises = reservationGuestDamages
      .map((reservationGuestDamage) => patchGuestDamage(reservationGuestDamage.id as number, {
        are_evidences_and_quotation_validated: null,
        missing_information: '',
      }));

    await Promise.all(promises);
  }

  async function handleFinishPendency() {
    try {
      await handleResetValidationsGuestDamage();

      const responseNegotiation = await patchGuestDamageNegotiation(selectedGuestDamageNegotiation
        .id as number,
      {
        status: 'Anfitrião finalizou as pendências',
      });

      setSelectedGuestDamageNegotiation((oldValues) => ({
        ...oldValues,
        status: responseNegotiation.status,
      }));

      toast.success('Dados atualizados com sucesso!');
    } catch (e) {
      if (e instanceof Error) {
        toast.error('Erro na atualização dos dados!');
      }
    }
  }

  useEffect(() => {
    formik.setValues({
      ...initialValues,
    });

    if (selectedGuestDamage?.quotation_file) {
      setQuotationFile(selectedGuestDamage.quotation_file);
    }

    if (selectedGuestDamage?.evidences && selectedGuestDamage.evidences.length > 0) {
      const selectedGuestDamageEvidences = selectedGuestDamage
        .evidences.map((evidence) => evidence.evidence);

      setEvidences(selectedGuestDamageEvidences);
    }
  }, [selectedGuestDamage]);

  return (
    <ViewDamageDetailsCardContainer>
      <DamageDetailsHeaderContainer>
        <DamageDetailsHeaderItem>
          <h3>Imóvel</h3>
          <p>
            {selectedGuestDamageNegotiation.reservation?.property_code || '-'}
          </p>
        </DamageDetailsHeaderItem>
        <DamageDetailsHeaderItem>
          <h3>Data de check-out</h3>
          <p>{formattedCheckoutDate}</p>
        </DamageDetailsHeaderItem>
        <DamageDetailsHeaderItem>
          <h3>Nome do hóspede</h3>
          <p>{selectedGuestDamageNegotiation.reservation?.guest_name}</p>
        </DamageDetailsHeaderItem>
        <DamageDetailsHeaderItem>
          <h3>Plataforma</h3>
          <p>{selectedGuestDamageNegotiation.reservation?.ota_name}</p>
        </DamageDetailsHeaderItem>
        <DamageDetailsHeaderItem>
          <h3>Ocorrido</h3>
          <p>{foundGuestDamage?.description || '-'}</p>
        </DamageDetailsHeaderItem>
        {selectedGuestDamage.id && (
          <>
            <DamageDetailsHeaderItem>
              <h3>Evidências e orçamentos são válidos</h3>
              <p>
                {selectedGuestDamage.are_evidences_and_quotation_validated
                  ? 'Sim'
                  : 'Não'}
              </p>
            </DamageDetailsHeaderItem>
            <DamageDetailsHeaderItem>
              <h3>Quais informações estão faltando</h3>
              <p>{selectedGuestDamage.missing_information || '-'}</p>
            </DamageDetailsHeaderItem>
          </>
        )}
      </DamageDetailsHeaderContainer>
      <DamageTypeContainer onSubmit={formik.handleSubmit}>
        {selectedGuestDamage.id !== undefined && <h3>Tipo do problema</h3>}
        {damageTypeProblems.map((damageType) => (
          <Fragment key={damageType.name}>
            {selectedGuestDamage.damage_type === damageType.value && (
              <>
                <TypeButton
                  key={damageType.name}
                  isActive={damageType.isActive}
                  type="button"
                  onClick={() => handleShowProblemForm(damageType.name)}
                  initial={{ opacity: 0, x: -150 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{
                    duration: 0.4,
                  }}
                  exit={{ x: 150, opacity: 0 }}
                >
                  <img src={damageType.icon} alt={damageType.iconAlt} />
                  {damageType.name}
                </TypeButton>
                <AnimatePresence>
                  {damageType.isActive && damageType.name === 'Enxoval' && (
                    <TrousseauFormView
                      formik={formik}
                      key={damageType.name}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      isEditingForm={isEditingForm}
                      setIsEditingForm={setIsEditingForm}
                    />
                  )}
                  {damageType.isActive
                    && damageType.value === 'Danos a itens' && (
                      <ItemDamageFormView
                        formik={formik}
                        key={damageType.name}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        isEditingForm={isEditingForm}
                        setIsEditingForm={setIsEditingForm}
                      />
                  )}
                  {damageType.isActive
                    && damageType.value === 'Extravio de item' && (
                      <ItemLossFormView
                        formik={formik}
                        key={damageType.name}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        isEditingForm={isEditingForm}
                        setIsEditingForm={setIsEditingForm}
                      />
                  )}
                  {damageType.isActive
                    && damageType.value === 'Danos à propriedade' && (
                      <PropertyDamageFormView
                        formik={formik}
                        key={damageType.name}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        isEditingForm={isEditingForm}
                        setIsEditingForm={setIsEditingForm}
                      />
                  )}
                  {damageType.isActive && damageType.value === 'Pendências' && (
                    <PendencyFormView
                      formik={formik}
                      key={damageType.name}
                      isEditingForm={isEditingForm}
                      setIsEditingForm={setIsEditingForm}
                    />
                  )}
                </AnimatePresence>
              </>
            )}
          </Fragment>
        ))}
        <ButtonContainer />
      </DamageTypeContainer>
      <DamageResume />
      {(roles?.includes('Host') || roles?.includes('Admin'))
        && selectedGuestDamageNegotiation.status
          === 'Atendimento verificou pendências na validação de informações' && (
          <ButtonContainer>
            <FormButton onClick={handleFinishPendency} type="button">
              Pendencias Resolvidas
            </FormButton>
          </ButtonContainer>
      )}
      <ButtonContainer>
        <FormButton onClick={handleInsertDamageDetailsStep} type="button">
          Adicionar mais danos
        </FormButton>
      </ButtonContainer>
    </ViewDamageDetailsCardContainer>
  );
};
