import React from 'react';

import { Container } from './styles';

const GridCardsShimmer = () => {
  const randomAnimationTime = () => Math.floor(Math.random() * (1 * 2)) + 1;

  return (
    <>
      <Container animationTime={randomAnimationTime()} />
    </>
  );
};

export default GridCardsShimmer;
