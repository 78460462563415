import React from 'react';

import {
  AmountPaid, ContentInfo, Content, Text, Text2,
} from './styles';

const CardSkeleton: React.FC = () => (
  <>
    {[1, 2, 3, 4, 5].map((item) => (
      <Content key={item}>
        <ContentInfo>
          <Text />
          <Text2 />
          <Text />
          <Text />
        </ContentInfo>
        <AmountPaid />
      </Content>
    ))}
  </>
);

export default CardSkeleton;
