import styled from 'styled-components';

export type ColorProps = 'green' | 'red';

export const TextValue = styled.h1<{
  color: ColorProps;
}>`
  color: ${({ theme, color }) => theme.palette[color]._250.hex()};
  font-size: 1rem;
  font-weight: bold;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  border: 1px dashed #00000018;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  div{
    width: 200px;
  }
`;
