export const ImageSVGIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00042 1.6419C7.37067 0.411655 9.48817 0.452488 10.8083 1.7749C12.1278 3.0979 12.1733 5.20491 10.9459 6.57924L5.99926 11.5329L1.05376 6.57924C-0.173576 5.20491 -0.127492 3.0944 1.19142 1.7749C2.51267 0.454238 4.62609 0.409905 6.00042 1.6419Z"
      fill="white"
    />
  </svg>
);

export const DotSVGIcon = () => (
  <svg
    width="3"
    height="12"
    viewBox="0 0 3 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.5" cy="1.5" r="1.5" fill="white" />
    <circle cx="1.5" cy="5.69995" r="1.5" fill="white" />
    <circle cx="1.5" cy="9.90002" r="1.5" fill="white" />
  </svg>
);
