import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';

import { LinearProgress } from '@mui/material';

import { useOnboardingPage } from '../../../../hooks/useOnboarding/useOnboarding';
import { useOnboardingHandover } from '../../../../context/OnboardingContext/OnboardingHandoverContext';

import FormButton from '../../../FormButton';
import Grid from './Grid';
import TextField from '../../../TextField';

import {
  Section,
  SearchBar,
  SearchContainer,
  ButtonDiv,
  Title,
} from './styles';

const CardOwner = () => {
  const {
    setIsOpenModal,
    listOwner,
    loadingOwner,
    listOwnerFiltered,
    setListOwnerFiltered
  } = useOnboardingPage();

  const {
    setOwner,
    infosPipeDrive,
    setResetFormik,
  } = useOnboardingHandover();

  function searchOwner(query: string) {
    const searchQuery = `${query}`.toLowerCase().trim();

    const filtered = listOwner.filter((item) => {
      const fullName = (`${item?.name}`.toLowerCase().trim());
      const cpf = `${item?.cpf}`.toLowerCase().trim();
      const cnpj = `${item?.cnpj}`.toLowerCase().trim();
      const codes = item?.property_codes || [];

      return [fullName, cpf, cnpj].some((field) => field.includes(searchQuery)) || codes.some((code) => `${code}`.toLowerCase().trim().includes(searchQuery));
    });
    setResetFormik(true);
    return filtered;
  }

  function searchOwnerByName(name: string) {
    return listOwner.filter((item) => {
      const fullName = (`${item?.name}`.toLowerCase().trim());
      const typedName = `${name}`.toLowerCase().trim() || '';
      return fullName.includes(typedName);
    });
  }

  const formik = useFormik({
    initialValues: {
      searchTerm: '',
    },
    onSubmit: async (values) => {
      const resultFilter = searchOwner(values.searchTerm);
      setListOwnerFiltered(resultFilter);
    },
  });

  useEffect(() => {
    function search() {
      const result = searchOwnerByName(`${infosPipeDrive?.owner_infos?.first_name} ${infosPipeDrive?.owner_infos?.last_name}`);
      if (result.length > 0) {
        if (result.length === 1) {
          const fullName = `${result[0]?.name}`;
          formik.setFieldValue('searchTerm', fullName);
          setOwner(result[0]);
          setListOwnerFiltered(result);
        } else {
          setListOwnerFiltered(result);
        }
      } else {
        setIsOpenModal(true);
      }
    }
    if (infosPipeDrive?.owner_infos?.first_name && listOwner.length > 0) {
      search();
    }
  }, [infosPipeDrive, listOwner]);

  const sortedListOwnerFiltered = useMemo(() => listOwnerFiltered.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }), [listOwnerFiltered]);

  return (
    <>
      <Section>
        <Title>Pesquisar Proprietário</Title>

        <SearchContainer onSubmit={formik.handleSubmit}>
          <SearchBar style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <TextField
              type="text"
              id="searchTerm"
              placeholder="Pesquisar por nome, CPF, CNPJ ou imóvel"
              formik={formik}
              value={formik.values.searchTerm}
              disabled={loadingOwner}
            />

            {loadingOwner && (
              <LinearProgress
                color="primary"
                style={{
                  position: 'absolute',
                  width: '100%',
                  bottom: -2,
                  left: 0,
                  height: 2,
                }}
              />
            )}
          </SearchBar>
          <ButtonDiv>
            <FormButton type="submit" disable={loadingOwner}>
              Buscar
            </FormButton>
          </ButtonDiv>
        </SearchContainer>

        <Grid ownersList={sortedListOwnerFiltered || []} />

      </Section>
    </>
  );
};

export default CardOwner;
