import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';

import { useFormik } from 'formik';
import { LinearProgress } from '@mui/material';

import UploadFile from './UploadFile';

import {
  Title,
  Container,
  ContentForm,
  FormContainer,
  BodyContainer,
  HeaderContainer,
  ButtonsContainer,
  LoadingContainer,
} from './styles';

import FormButton from '../../../../FormButton';

import { TextField, DatePicker, SimpleSelect } from '../../../..';

import {
  FileProps,
  FileAWSFormat,
  FileReference,
  FileReferenceResponse,
} from '../../../../../context/FileContext/types';

import {
  SendingFileProps,
  ReceiptImageType,
  ResponseSendPaymentDataProps,
} from '../../../../../services/PaymentVoucher/types';

import { useToast } from '../../../../../context/ToastContext';
import { useFile } from '../../../../../hooks/FileHook/useFile';
import { useLoader } from '../../../../../context/LoaderContext';
import { currencyToNumber } from '../../../../../utils/Formatter';
import { initialValuesFile } from '../../../../../context/FileContext/FileContext';
import { usePaymentVoucher } from '../../../../../hooks/PaymentVoucherHook/usePaymentVoucher';

import { sendPaymentData } from '../../../../../services/PaymentVoucher/request';

interface AddVoucherScreenProps {
  showConfirmaionScreen: Function;
  handleCloseShowAddVoucherScreen: Function;
}

const AddVoucherScreen = ({
  showConfirmaionScreen,
  handleCloseShowAddVoucherScreen,
}: AddVoucherScreenProps) => {
  const toast = useToast();
  const { setLoad } = useLoader();
  const { reservationIdEditedButton } = usePaymentVoucher();
  const { uploadFileToS3, createFileReference } = useFile();
  const { loading, handleChangeLoading } = usePaymentVoucher();

  const [, setHasSendedFile] = useState<boolean>(false);
  const [showDestinationAccount, setShowDestinationAccount] = useState<boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false);

  const [fileArray, setFileArray] = useState<FileProps[]>([]);
  const [voucherFile, setVoucherFile] = useState<FileProps>(initialValuesFile);
  const [sendingFileArray, setSendingFileArray] = useState<SendingFileProps[]>([]);

  const handleUploadFile = async (fileItem: FileProps) => {
    let fileResponse: SendingFileProps = { uid: null };
    try {
      const fileReference: FileReference = {
        name: fileItem.name,
        category: 'voucher-file',
        content_type: fileItem.MIMEtype,
      };
      const responseFile: FileReferenceResponse = await createFileReference(fileReference);
      const params: FileAWSFormat = {
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        content_type: fileReference.content_type,
        key: responseFile.storage.fields.key,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        file: fileItem.file,
        fileId: responseFile.uid,
      };
      await uploadFileToS3(fileItem, params);
      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
      setHasSendedFile(true);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoad(false);
      }
    }
    return fileResponse;
  };

  const validation = Yup.object().shape({
    date: Yup.date().required(),
    amount: Yup.string(),
    depositedBy: Yup.string().required(),
  });

  async function sendFileDataToS3() {
    try {
      handleChangeLoading(true);
      if (fileArray.length > 0) {
        fileArray.forEach(async (item) => {
          const response = await handleUploadFile(item);

          setSendingFileArray([...sendingFileArray, response]);
        });
      }
      handleChangeLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error('Não foi possível fazer o upload da imagem');
      }
      handleChangeLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      amount: '',
      depositedBy: '',
      date: new Date(),
      paymentMethods: '',
      destinationAccount: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        handleChangeLoading(true);
        const formatedDate = new Date(values.date);
        const formatAmountToNumber = currencyToNumber(formik.values.amount);

        const getUUIDfromSendingFileArray: ReceiptImageType = fileArray
          .length > 0 ? sendingFileArray[0]?.uid : null;

        const paymentDataFormated: ResponseSendPaymentDataProps = {
          value: formatAmountToNumber,
          date: formatedDate,
          depositor: values.depositedBy,
          reservation: reservationIdEditedButton,
          destination_bank_account: values.destinationAccount,
          payment_method: values.paymentMethods,
          receipt_image:
            fileArray.length > 0 ? getUUIDfromSendingFileArray : null,
        };

        await sendPaymentData(paymentDataFormated);
        setLoad(false);
        handleCloseShowAddVoucherScreen();
        handleChangeLoading(false);
        showConfirmaionScreen();
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Não foi possível cadastrar o pagamento');
          toast.error(e.message);
          handleChangeLoading(false);
        }
      }
    },
  });

  const handleSelectComponentsToShow = () => {
    const value = formik.values.paymentMethods;

    if (value === 'Credit_Card' || value === 'Paypal') {
      setShowHeader(false);
      setShowUploadFiles(false);
      setShowDestinationAccount(true);
    } else if (value === 'Ted' || value === 'Pix') {
      setShowHeader(true);
      setShowUploadFiles(true);
      setShowDestinationAccount(true);
    } else {
      setShowHeader(false);
      setShowUploadFiles(false);
      setShowDestinationAccount(false);
    }
  };

  useEffect(() => {
    handleSelectComponentsToShow();
  }, [formik.values.paymentMethods]);

  // returns

  const DestinationAccountComponent = () => (
    <div>
      <h1>*Selecione uma conta de destino</h1>
      <SimpleSelect
        id="destinationAccount"
        formik={formik}
        placeholder="Conta de destino"
        options={[
          { value: 'Khanto_Reservations', valueLabel: 'Khanto Reservas' },
          { value: 'Seazone_Services', valueLabel: 'Seazone Serviços' },
        ]}
      />
    </div>
  );

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Title>Adicionar comprovante de pagamento</Title>

      {showUploadFiles && (
        <UploadFile
          sendFileDataToS3={sendFileDataToS3}
          setSendingFileArray={setSendingFileArray}
          fileArray={fileArray}
          setFileArray={setFileArray}
          voucherFile={voucherFile}
          setVoucherFile={setVoucherFile}
          setHasSendedFile={setHasSendedFile}
        />
      )}

      <FormContainer>
        <ContentForm>
          <div>
            <h1>*Selecione um método de pagamento</h1>
            <SimpleSelect
              id="paymentMethods"
              formik={formik}
              placeholder="Métodos de pagamento"
              options={[
                { value: 'Ted', valueLabel: 'Ted' },
                { value: 'Pix', valueLabel: 'Pix' },
                { value: 'Paypal', valueLabel: 'PayPal' },
                { value: 'Credit_Card', valueLabel: 'Cartão de Crédito' },
              ]}
            />
          </div>

          {showDestinationAccount && <DestinationAccountComponent />}

          {showHeader && (
            <HeaderContainer>
              <TextField
                id="amount"
                formik={formik}
                mask="money"
                label="Valor"
                placeholder="Digite o valor aqui"
              />

              <DatePicker
                formik={formik}
                className="date-container-picker"
                id="date"
                label="Data"
              />
            </HeaderContainer>
          )}

          <BodyContainer>
            <TextField
              formik={formik}
              id="depositedBy"
              label="Depositado por:"
              placeholder="Digite aqui o responsavel pelo depósito"
            />
          </BodyContainer>
        </ContentForm>
      </FormContainer>

      {loading && (
        <LoadingContainer>
          <LinearProgress />
        </LoadingContainer>
      )}

      <ButtonsContainer>
        <FormButton
          onClick={() => handleCloseShowAddVoucherScreen()}
          type="button"
          variant="outlined"
        >
          Cancelar
        </FormButton>
        <FormButton type="submit">Confirmar</FormButton>
      </ButtonsContainer>
    </Container>
  );
};

export default AddVoucherScreen;
