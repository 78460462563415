import { GridOwnerProps } from '../../context/FinancialClosePage/FinancialCloseOwnerContext/types';

import {
  initialValuesFinancialCloseOwner, ownerIsSelected,
} from '../../context/FinancialClosePage/FinancialCloseOwnerContext/utils';

import {
  FinancialCloseOwnerResponse,
} from './types';

import {
  checkEmptyString, getStringFormatted,
} from '../../utils/Formatter';

const MAX_LENGHT_USERNAME = 80;
const MAX_DIGITS_SUBSTRING = 20;

export const getManualFitValueColor = (value: number) => {
  if (value > 0) return 'green';
  if (value < 0) return 'red';
  return 'default';
};

export const isBeforeOctober2022 = (dateStr: string) => {
  const selectedDate = new Date(dateStr);
  const october2022 = new Date('2022-10-01');
  return selectedDate < october2022;
};

export const calculateTotal = (accumulator: number, curr: number) => accumulator + curr;

export const formatFinancialDataOwner = (
  data: FinancialCloseOwnerResponse,
  year: string,
  month: string,
): GridOwnerProps[] => {
  const financialData: GridOwnerProps[] = [];

  Object.keys(data).forEach((ownerId: string, index: number) => {
    const keyOwner = data?.[ownerId];
    const key = keyOwner?.[year]?.[`${Number(month)}`];

    const dataFormatted: GridOwnerProps = { ...initialValuesFinancialCloseOwner };

    dataFormatted.id = Number(ownerId);

    dataFormatted.owner = {
      id: keyOwner?.owner?.id || 0,
      name: keyOwner?.owner?.user?.is_individual
        ? getStringFormatted(
          checkEmptyString(`${keyOwner?.owner?.user?.first_name} ${keyOwner?.owner?.user?.last_name}`)?.trim() || 'Não informado', MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
        )
        : getStringFormatted(
          checkEmptyString(`${keyOwner?.owner?.user?.trading_name}`)?.trim() || 'Não informado', MAX_LENGHT_USERNAME, MAX_DIGITS_SUBSTRING,
        ),
    };

    dataFormatted.balance_initial = key?.initial_balance || 0;

    dataFormatted.expense_value = key?.expenses || 0;

    dataFormatted.reserves = {
      income: key?.incomes || 0,
      revenue: key?.revenues || 0,
    };

    dataFormatted.commission = key?.commission || 0;

    dataFormatted.adjustments = {
      properties: key?.manual_fit?.property || 0,
      owner: Number(key?.manual_fit?.owner?.value || 0),
      total: [
        key?.manual_fit?.property || 0,
        Number(key?.manual_fit?.owner?.value || 0),
      ].reduce(calculateTotal),
    };

    dataFormatted.manual_fit = {
      id: key?.manual_fit?.owner?.id || undefined,
      owner: key?.manual_fit?.owner?.owner || undefined,
      date_ref: key?.manual_fit?.owner?.date_ref || undefined,
      is_adding: key?.manual_fit?.owner?.is_adding !== undefined
        ? key?.manual_fit?.owner?.is_adding : undefined,
      description: key?.manual_fit?.owner?.description || undefined,
      value: key?.manual_fit?.owner?.value || undefined,
    };

    dataFormatted.transfer = {
      transfer_value: key?.transfer?.transfer || 0,
      negative_balance_rebate: key?.transfer?.negative_rebate || 0,
      total: [
        key?.transfer?.transfer || 0,
      ].reduce(calculateTotal),
    };

    dataFormatted.status = key?.status_owner || 'Open';
    dataFormatted.revenue_ids = key?.revenue_ids || [];

    dataFormatted.checked = ownerIsSelected(dataFormatted.id);
    dataFormatted.expanded = false;

    const propertyMock = initialValuesFinancialCloseOwner.properties[0];

    dataFormatted.properties = key?.properties && key?.properties?.length > 0
      ? key?.properties?.map((item) => ({
        id: item?.id || index + 1,
        property: {
          id: item?.id || propertyMock.property.id,
          code: item?.code || propertyMock.property.code,
        },
        is_to_keep_funds_in_seazone: item?.is_to_keep_funds_in_seazone || false,
        initial_balance: [
          item?.initial_balance?.host || 0,
          item?.initial_balance?.seazone || 0,
        ].reduce(calculateTotal),
        revenue: [
          item?.revenue_ota?.airbnb || 0,
          item?.revenue_ota?.booking || 0,
          item?.revenue_ota?.contract || 0,
          item?.revenue_ota?.expedia || 0,
          item?.revenue_ota?.homeaway || 0,
          item?.revenue_ota?.stays || 0,
          item?.revenue_ota?.decolar || 0,
        ].reduce(calculateTotal),
        income: [
          item?.income_ota?.airbnb || 0,
          item?.income_ota?.booking || 0,
          item?.income_ota?.contract || 0,
          item?.income_ota?.expedia || 0,
          item?.income_ota?.homeaway || 0,
          item?.income_ota?.stays || 0,
          item?.income_ota?.decolar || 0,
        ].reduce(calculateTotal),
        expenses: [
          item?.expenses?.host || 0,
          item?.expenses?.seazone || 0,
          item?.expenses?.owner || 0,
        ].reduce(calculateTotal),
        cleanings: item?.cleaning || propertyMock.cleanings,
        manual_fit: item?.manual_fit?.property || propertyMock.manual_fit,
        transfer: [
          item?.transfer?.month_balance || 0,
          item?.transfer?.value || 0,
        ].reduce(calculateTotal),
        commission: item?.commission || propertyMock.commission,
        final_balance: [
          item?.final_balance?.host || 0,
          item?.final_balance?.seazone || 0,
        ].reduce(calculateTotal),
      })) : [];

    financialData.push(dataFormatted);
  });

  return financialData;
};
