import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { createContext } from 'use-context-selector';

import {
  GridHostProps, ExpenseProps, GridHostDetailsProps,
} from './types';
import { getFinancialCloseHostUpdated } from './utils';

interface IFinancialCloseHost {
  financialDataHost: GridHostProps[],
  setFinancialDataHost: Function,
  updateFinancialDataHost: Function,
  updateExpenseHost: Function,
  updateCheckedOfHostSelected: Function,
  updateAllCheckedsOfHostSelected: Function,
  updateExpandedOfHostSelected: Function,
  addReservationsHost: Function,
  openModalAddManualFitHost: boolean,
  setOpenModalAddManualFitHost: Function,
  openModalEditManualFitHost: boolean,
  setOpenModalEditManualFitHost: Function,
  openModalFranchiseFeeHost: boolean,
  setOpenModalFranchiseFeeHost: Function,
}

export const FinancialCloseHostContext = createContext<IFinancialCloseHost>({
  financialDataHost: [],
  setFinancialDataHost: () => {},
  updateFinancialDataHost: () => {},
  updateExpenseHost: () => {},
  updateCheckedOfHostSelected: () => {},
  updateAllCheckedsOfHostSelected: () => {},
  updateExpandedOfHostSelected: () => {},
  addReservationsHost: () => {},
  openModalAddManualFitHost: false,
  setOpenModalAddManualFitHost: () => {},
  openModalEditManualFitHost: false,
  setOpenModalEditManualFitHost: () => {},
  openModalFranchiseFeeHost: false,
  setOpenModalFranchiseFeeHost: () => {},
});

export const FinancialCloseHostProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [financialDataHost, setFinancialDataHost] = useState<GridHostProps[]>([]);
  const [openModalAddManualFitHost,
    setOpenModalAddManualFitHost] = useState<boolean>(false);
  const [openModalEditManualFitHost,
    setOpenModalEditManualFitHost] = useState<boolean>(false);
  const [openModalFranchiseFeeHost,
    setOpenModalFranchiseFeeHost] = useState<boolean>(false);

  const handleSetFinancialDataHost = useCallback((data: GridHostProps[]) => {
    setFinancialDataHost([...data]);
  }, []);

  const handleUpdateFinancialDataHost = useCallback((currentData: GridHostProps[],
    dataUpdated: GridHostProps[]) => {
    const newData: GridHostProps[] = [...currentData];

    dataUpdated.forEach((itemUpdated) => {
      const indexItem = currentData.findIndex((currentItem) => currentItem.id === itemUpdated.id);
      newData[indexItem] = { ...getFinancialCloseHostUpdated(itemUpdated) };
    });

    setFinancialDataHost(newData);
  }, []);

  const handleUpdateExpenseHost = useCallback((data: ExpenseProps) => {
    setFinancialDataHost((state) => state.map((host) => ({
      ...host,
      expenses: host.expenses
        .map((expense: ExpenseProps) => (expense.id === data.id
          ? { ...data } : { ...expense })),
    })));
  }, []);

  const handleUpdateCheckedOfHostSelected = useCallback((id: number, checkedUpdated?: boolean) => {
    if (checkedUpdated) {
      setFinancialDataHost((state) => state.map((item) => ({
        ...getFinancialCloseHostUpdated(item),
        checked: item.id === id ? checkedUpdated : item.checked,
      })));
    } else {
      setFinancialDataHost((state) => state.map((item) => ({
        ...getFinancialCloseHostUpdated(item),
        checked: item.id === id ? !item.checked : item.checked,
      })));
    }
  }, []);

  const handleUpdateAllCheckedsOfHostSelected = useCallback((checkedUpdated: boolean) => {
    setFinancialDataHost((state) => state.map((item) => ({
      ...getFinancialCloseHostUpdated(item),
      checked: checkedUpdated,
    })));
  }, []);

  const handleUpdateExpandedOfHostSelected = useCallback((id: number) => {
    setFinancialDataHost((state) => state.map((item) => ({
      ...getFinancialCloseHostUpdated(item),
      expanded: item.id === id ? !item.expanded : item.expanded,
    })));
  }, []);

  const handleAddReservationsAndExpensesHost = useCallback((id: number,
    data: GridHostDetailsProps) => {
    setFinancialDataHost((state) => state.map((item) => ({
      ...getFinancialCloseHostUpdated(item),
      reservations: item.id === id ? data.reservations : item.reservations,
      expenses: item.id === id ? data.expenses : item.expenses,
    })));
  }, []);

  const handleChangeOpenModalAddManualFit = useCallback((isOpen: boolean) => {
    setOpenModalAddManualFitHost(isOpen);
  }, []);

  const handleChangeOpenModalEditManualFit = useCallback((isOpen: boolean) => {
    setOpenModalEditManualFitHost(isOpen);
  }, []);

  const handleChangeOpenModalFranchiseFeeHost = useCallback((isOpen: boolean) => {
    setOpenModalFranchiseFeeHost(isOpen);
  }, []);

  type CheckProps = Record<number, boolean>;
  useEffect(() => {
    const checkeds: CheckProps = financialDataHost.reduce((acc, item) => {
      acc[item.id] = item.checked;
      return acc;
    }, {} as CheckProps);

    localStorage.setItem('@saprom-pms-web/financialclose_host_selected', JSON.stringify(checkeds));
  }, [financialDataHost]);

  const value = useMemo(() => ({
    financialDataHost,
    setFinancialDataHost: handleSetFinancialDataHost,
    updateFinancialDataHost: handleUpdateFinancialDataHost,
    updateExpenseHost: handleUpdateExpenseHost,
    updateCheckedOfHostSelected: handleUpdateCheckedOfHostSelected,
    updateAllCheckedsOfHostSelected: handleUpdateAllCheckedsOfHostSelected,
    updateExpandedOfHostSelected: handleUpdateExpandedOfHostSelected,
    addReservationsHost: handleAddReservationsAndExpensesHost,
    openModalAddManualFitHost,
    setOpenModalAddManualFitHost: handleChangeOpenModalAddManualFit,
    openModalEditManualFitHost,
    setOpenModalEditManualFitHost: handleChangeOpenModalEditManualFit,
    openModalFranchiseFeeHost,
    setOpenModalFranchiseFeeHost: handleChangeOpenModalFranchiseFeeHost,
  }), [
    financialDataHost,
    setFinancialDataHost,
    openModalAddManualFitHost,
    setOpenModalAddManualFitHost,
    openModalEditManualFitHost,
    setOpenModalEditManualFitHost,
    openModalFranchiseFeeHost,
    setOpenModalFranchiseFeeHost,
  ]);

  return (
    <FinancialCloseHostContext.Provider
      value={value}
    >
      {children}
    </FinancialCloseHostContext.Provider>
  );
};
