import { CircularProgress } from '@mui/material';
import styled, { css } from 'styled-components';
import { shimmerAnimation } from '../../../../../../../../../../components/Calendar/LoadingShimmer/animationShimmer';

export const defaultStyle = css`
  width: 80px;
  height: 70px;
  background: #e7e9fa;
  border-radius: 10px;
  border: 1.5px dashed #ccc;

  cursor: pointer;
  user-select: none;
`;

export const Container = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  p {
    opacity: 0.7;
  }
`;

export const StyledUpload = styled.div`
  ${defaultStyle}

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const StyleProgress = styled(CircularProgress)`
  top: 25%;
  left: 30%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const UploadPreview = styled.img`
  ${defaultStyle};
  object-fit: cover;
  background: #fff;
  animation: ${shimmerAnimation} 1s linear infinite;
`;
