import styled, { keyframes } from 'styled-components';

export const fadeOpacityAnimation = keyframes`
  from{
    opacity: 1;
  }
  to{
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  width: 100%;
  text-align: right;
  border-left: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 10% 2rem 10% 0;
  margin: 0;
  .observations, .checklists{
    cursor: pointer;
    &:hover{
      filter: brightness(0.4);
    }
  }


`;

export const TitlePhase = styled.div<{
  active?: boolean;
}>`
  :not(last-child) {
    margin-top: 5%;
  }

  :last-child {
    margin-bottom: 5%;
  }

  justify-content: center;
  flex-direction: column;
  display: flex;
  color: ${({ theme }) => theme.palette.grey._50.hex()};
  color: ${({ active, theme }) => active && theme.palette.blue._950.hex()};
  font-weight: ${({ active }) => (active ? 'bold' : 'bold')};
  transition: 0.3s;
  font-size: 1.3rem;
`;

export const Finish = styled.div<{
  finish?: boolean;
}>`
  transition: 0.3s;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.palette.red._400.hex()};
  filter: ${({ finish }) => (finish ? 'none' : 'opacity(0.5)')};
`;

export const Initial = styled.div<{
  finish?: boolean;
}>`
  font-size: 1.3rem;
  filter: ${({ finish }) => (!finish ? 'none' : 'opacity(0.5)')};
  color: ${({ theme }) => theme.palette.red._400.hex()};
  transition: 0.3s;
`;

export const TextContainer = styled.div`
  display: flex;
  scrollbar-width: none;

  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
`;

export const ProgessBar = styled.div`
  width: 100%;
  background: orange;
  height: 100%;
  max-height: 10px;
`;
