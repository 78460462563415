import styled, { keyframes } from 'styled-components';

import { NFLISTPAGEMARGIN } from './utils';

const fadeIN = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  } to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

const fadeINSecond = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  border-radius: 10px;
  margin: ${NFLISTPAGEMARGIN / 2}px;
  width: calc(100% - ${NFLISTPAGEMARGIN}px);
  height: calc(100% - ${NFLISTPAGEMARGIN}px);

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow: hidden;

  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0px;
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  align-items: center;
  justify-content: center;
  text-align: center;

  opacity: 0;
  animation: ${fadeIN} 1s 0.2s ease forwards;

  h1 {
    color: white;
    font-weight: 1000;
    font-size: 2rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};

    opacity: 0;
    animation: ${fadeINSecond} 1s 0.3s ease forwards;
  }

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 35;
`;
export const AnimationBackdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  animation: ${fadeINSecond} 0.2s ease-in-out forwards;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 32, 32, 0.883);
  z-index: 30;
`;
