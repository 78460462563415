import { useSteps } from '../../context';
import { CheckBoxContainer } from './style';

export const CheckBox = () => {
  const {
    notShowAgainCheck,
    setNotShowAgainCheck,
    options,
    handleFinish,
  } = useSteps();

  const handleChange = () => {
    const newValue = !notShowAgainCheck;
    setNotShowAgainCheck(newValue);

    if (newValue) {
      handleFinish();

      if (options?.showNotShowAgainCheckbox?.onTrue) {
        options?.showNotShowAgainCheckbox?.onTrue(newValue);
      }
    } else if (options?.showNotShowAgainCheckbox?.onFalse) {
      options?.showNotShowAgainCheckbox?.onFalse(newValue);
    }
  };

  return (
    <CheckBoxContainer>
      <input
        type="checkbox"
        checked={notShowAgainCheck}
        onChange={handleChange}
      />
      <p>Não quero mais receber essa mensagem</p>
    </CheckBoxContainer>
  );
};
