import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { Close } from '@mui/icons-material';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import FormButton from '../../FormButton';
import Tooltip from '../../Tooltip';
import CardPayment from '../CardPayment';
import CardReservation from '../CardReservation';
import ModalConfirm from '../ModalConcililiation/ModalConfirm';
import {
  ButtonRemove,
  CardAreaSelected, Container, Content, ContentButtons, Header, HeaderCard, TitleCard,
} from './styles';

import arrow from '../../../assets/icons/conciliation/arrow.svg';

const ContentConciliation: React.FC = () => {
  const {
    paymentSelected, reservation, removePayment, removeReservation,
  } = useConciliation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const useDropArray = useDrop({
    accept: 'Card',
    drop: () => ({ name: 'payment-area' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  function handleClearCard() {
    removePayment();
    removeReservation();
  }
  return (
    <>
      <Container>
        <Header><h1>Conciliação</h1></Header>
        <Content>
          {reservation.id && (
          <HeaderCard>
            <TitleCard>Reserva</TitleCard>
            <Tooltip text="Remover reserva">
              <ButtonRemove type="button" onClick={removeReservation}><Close /></ButtonRemove>
            </Tooltip>
          </HeaderCard>
          )}
          <CardAreaSelected ref={useDropArray[1]}>
            {reservation.id && (
            <CardReservation reservation={reservation} />
            )}
            {!reservation.id && (
            <>
              <img src={arrow} alt="Seta" />
              <h2>Selecione uma reserva</h2>
              <p>Selecione ou arraste</p>
            </>
            )}
          </CardAreaSelected>
        </Content>
        <Content>
          {paymentSelected.id && (
            <HeaderCard>
              <TitleCard>Pagamento</TitleCard>
              <Tooltip text="Remover pagamento">
                <ButtonRemove type="button" onClick={removePayment}><Close /></ButtonRemove>
              </Tooltip>
            </HeaderCard>
          )}
          <CardAreaSelected>
            {paymentSelected.id && (
              <CardPayment payment={paymentSelected} />
            )}
            {!paymentSelected.id && (
            <>
              <img src={arrow} alt="Seta" className="rigth" />
              <h2>Selecione um pagamento</h2>
              <p>Selecione ou arraste</p>
            </>
            )}
          </CardAreaSelected>
        </Content>
        <ContentButtons>
          <FormButton type="button" onClick={() => setOpenModal(true)}>Conciliar</FormButton>
          <FormButton type="button" variant="outlined" onClick={() => handleClearCard()}>Cancelar</FormButton>
        </ContentButtons>
      </Container>

      <ModalConfirm
        guestReservation={`${reservation.guest?.user?.first_name} ${reservation.guest?.user?.last_name}`}
        type="Conciliar"
        value={paymentSelected.amount_paid}
        open={openModal}
        setOpen={setOpenModal}
      />
    </>
  );
};

export default ContentConciliation;
