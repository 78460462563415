import { useState, FC } from 'react';
import { UseFormik } from '../../utils/Formik/types';
import {
  Wrapper,
  WrapperQuantity,
  Title,
  IconWrapper,
  IconValueWrapper,
  GreyMinusCircle,
  GreyPlusCircle,
  ErrorIcon,
  AddCircleIconStyled,
  RemoveCircleIconStyled,
  ArrowBackIosIconStyled,
  ArrowDiv,
  ArrowForwardIosIconStyled,
} from './styles';
import HelperText, { TypeHelperText } from '../HelperText';

interface InputQuantityField {
  title?: string,
  id: string,
  dataCy?: string,
  formik?: UseFormik<any>,
  border?: boolean,
  justify?: boolean,
  initialQuantity?: number,
  maxQuantity?: number,
  isOnboardingPage?: boolean,
  isDeploymentFeeValues?: boolean,
  radioChecked?: boolean,
  isPercent?: boolean,
  callback?: (a: number) => void,
}

const QuantityField: FC<InputQuantityField> = ({
  title,
  id,
  dataCy = '',
  formik,
  border = true,
  justify = true,
  initialQuantity = 0,
  isOnboardingPage = false,
  isDeploymentFeeValues = false,
  radioChecked = false,
  isPercent = false,
  callback = null,
  maxQuantity = null,
}) => {
  const [fieldQuantity, setFieldQuantity] = useState<number>(initialQuantity);

  const handleQuantity = (operation?: string) => {
    const count = operation === 'add' ? 1 : -1;

    if (formik) {
      const value = Number.isNaN(formik?.values[id]) ? initialQuantity : formik?.values[id];
      if (maxQuantity !== null && value + count > maxQuantity) {
        return;
      }
      formik?.setFieldValue(id, value + count);
      formik?.setFieldTouched(
        id, true, false,
      );
      if (callback) callback(value + count);
      return;
    }

    setFieldQuantity(fieldQuantity + count);
  };

  const getValue = () => formik?.values[id] || fieldQuantity;
  const isError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  return (
    <Wrapper border={border}>
      <WrapperQuantity border={border} justify={justify}>
        <Title>
          {title}
        </Title>
        <IconValueWrapper>
          <IconWrapper data-testid="button-test" disabled={getValue() === initialQuantity} onClick={() => handleQuantity()}>
            {isOnboardingPage ? (
              <>
                {isPercent && (
                  <ArrowDiv disable={!radioChecked}>
                    <ArrowBackIosIconStyled />
                  </ArrowDiv>
                )}
                {isDeploymentFeeValues && !isPercent && (
                  <RemoveCircleIconStyled disable={!radioChecked} />
                )}
                {!isDeploymentFeeValues && !isPercent && (
                  <RemoveCircleIconStyled disable={getValue() === initialQuantity} />
                )}
              </>
            ) : (
              <GreyMinusCircle />
            )}
          </IconWrapper>
          <Title isOnboardingPage={isOnboardingPage} style={isDeploymentFeeValues ? { width: '35px' } : { width: '20px' }}>
            {isDeploymentFeeValues && !isPercent && `${getValue()} x` }
            {isDeploymentFeeValues && isPercent && `${getValue()} %` }
            {!isDeploymentFeeValues && !isPercent && `${getValue()}` }
            {!isDeploymentFeeValues && isPercent && `${getValue()}%` }
          </Title>
          <IconWrapper data-testid="button-add" data-cy={dataCy} style={{ marginRight: '15px' }} onClick={() => handleQuantity('add')}>
            {isOnboardingPage ? (
              <>
                {isPercent && isDeploymentFeeValues && (
                  <ArrowDiv disable={!radioChecked}>
                    <ArrowForwardIosIconStyled />
                  </ArrowDiv>
                )}
                {isDeploymentFeeValues && !isPercent && (
                  <AddCircleIconStyled disable={!radioChecked} />
                )}
                {!isDeploymentFeeValues && !isPercent && (
                  <AddCircleIconStyled disable={false} />
                )}
              </>
            ) : (
              <GreyPlusCircle />
            )}
          </IconWrapper>
        </IconValueWrapper>
      </WrapperQuantity>
      { isError && (
        <HelperText type={TypeHelperText.error}>
          <ErrorIcon />
          {formik?.errors[id]}
        </HelperText>
      )}
    </Wrapper>
  );
};
export default QuantityField;
