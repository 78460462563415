import React from 'react';

import { createPortal } from 'react-dom';

import {
  Content,
  Backdrop,
  Container,
  Separator,
  ItemContainer,
  IconContainer,
  TextContainer,
  TitleContainer,
} from './style';

import { findStatus } from '../../../utils/indication';
import { usePartnerIndicate } from '../../../../../context/Partners/IndicateContext/IndicateContext';

const list = {
  visible: {
    opacity: 0.8,
    transition: {
      when: '',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const items = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -100 },
};

type IUseList = {
  onClick?: Function;
  icon?: JSX.Element;
  text: string;
};

interface IModal {
  open: boolean;
  title: string;
  coors: {
    x: number;
    y: number;
  };
  onClose: Function;
  children?: JSX.Element;
  useList?: IUseList[] | undefined;
}

const Item = ({
  text,
  icon,
  onClick,
  active,
}: {
  text: string;
  icon: JSX.Element;
  onClick: Function;
  active: boolean;
}) => (
  <ItemContainer
    active={active}
    variants={items}
    onClick={() => onClick()}
  >
    <IconContainer>{icon}</IconContainer>
    <TextContainer>
      <h1>{text}</h1>
    </TextContainer>
  </ItemContainer>
);

const Modal = ({
  open,
  coors,
  onClose,
  children,
  title,
  useList = undefined,
}: IModal) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const { activeFilter } = usePartnerIndicate();

  const handleDefineActiveItem = (text: string) => {
    if (text === 'Todos' && !activeFilter) {
      return true;
    }

    if (text === (activeFilter && findStatus(activeFilter).translated)) {
      return true;
    }

    return false;
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current
        && !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const PortalRender = () => (
    <>
      {open && (
        <>
          <Container
            ref={modalRef}
            offsetTop={coors.y}
            offsetLeft={coors.x}
            style={{ originY: 0, originX: 1 }}
            exit={{
              x: 5,
              opacity: 0,
            }}
            initial={{ y: 20, opacity: 0 }}
            animate={{ opacity: 1, y: '' }}
          >
            <TitleContainer>
              <h1>{title}</h1>
            </TitleContainer>
            <Content
              initial="hidden"
              animate="visible"
              variants={list}
            >
              {useList
              && useList.map((item, index) => (
                <>
                  {index !== 0 && <Separator />}
                  <Item
                    active={handleDefineActiveItem(item.text)}
                    text={item.text}
                    icon={item.icon || <></>}
                    onClick={item.onClick ? item.onClick : () => {}}
                  />
                </>
              ))}
            </Content>
            {children}
          </Container>
          <Backdrop
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
          />
        </>
      )}
    </>
  );

  const body = window.document.getElementById('modal_portal');

  if (body) {
    return createPortal(<PortalRender />, body);
  }

  return null;
};

Modal.defaultProps = {
  children: <></>,
  useList: undefined,
};

export default Modal;
