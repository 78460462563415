import styled from 'styled-components';

export const Container = styled.div`
`;

export const FormContainer = styled.div``;

export const FormContent = styled.form`
  width: 100%;
  height: auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin: 0 0.5rem;
  }
`;
