import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Modal from '@mui/material/Modal';
import { AnimatePresence, motion } from 'framer-motion';
import TextField from '../../components/TextField';
import { Backdrop } from '../../components/Fab/styles';
import Logo from '../../assets/icons/generals/logo.png';
import { resetpassword } from '../../services/User/request';

import {
  Text,
  Title,
  LogoImage,
  BackButton,
  NextButton,
  ColoredBar,
  PageContainer,
  InputContainer,
  ButtonsContainer,
  RecoveryContainer,
  ModalContainer,
} from './styles';
import { useToast } from '../../context/ToastContext';

const MidiaButton = ({ link, text }: { link: string, text: string }) => (
  <motion.button
    type="button"
    whileTap={{ scale: 0.95 }}
    onClick={() => window.open(link)}
  >
    {text}
  </motion.button>
);

const ModalComponent = ({ open, onClose }: { open: boolean, onClose: () => void }) => (
  <motion.div
    exit={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    initial={{ y: 10, opacity: 0 }}
  >
    <Modal
      onClose={onClose}
      sx={{
        display: 'flex',
        padding: '20px',
        height: '100vh',
      }}
      open={open}
    >
      <ModalContainer>
        <h1>Um email de recuperação foi enviado para você !</h1>
        <p>Acesse seu email e clique no link para recuperar sua senha</p>
        <h2>
          Após 30 minutos o link do email perderá a validade
        </h2>
        <div style={{
          gap: '10px',
          width: '100%',
          display: 'flex',
        }}
        >
          <MidiaButton
            text="Gmail"
            link="https://mail.google.com/mail/"
          />
          <MidiaButton
            text="Outlook"
            link="https://outlook.live.com/mail/"
          />
          <MidiaButton
            text="Yahoo"
            link="https://mail.yahoo.com/"
          />
        </div>
      </ModalContainer>
    </Modal>
  </motion.div>
);

const Loading = ({ show }: { show: boolean }) => (
  <AnimatePresence>
    {show && (
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
      >
        <Backdrop>
          <CircularProgress />
        </Backdrop>
      </motion.div>
    )}
  </AnimatePresence>
);

const validation = Yup.object().shape({
  email: Yup.string()
    .required('E-mail obrigatório')
    .email('Digite um e-mail válido'),
});

const ForgotPassword = () => {
  const toast = useToast();

  const [response, setResponse] = useState({ status: '', isLoading: false });
  const [modal, setModal] = useState({ open: false });

  const timer = useRef<NodeJS.Timeout>({} as NodeJS.Timeout);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: validation,
    onSubmit: async (data) => {
      try {
        setResponse((old) => ({ ...old, isLoading: true }));
        const res = await resetpassword({ email: data.email });

        if (res) {
          setResponse((old) => ({
            ...old,
            status: 'success',
          }));
          setModal((old) => ({ ...old, open: true }));
        } else {
          setResponse((old) => ({
            ...old,
            status: 'error',
          }));
        }
      } catch {
        toast.alert('Email não encontrado na base de dados');
      } finally {
        timer.current = setTimeout(() => {
          setResponse((old) => ({ ...old, isLoading: false }));
          window.clearTimeout(timer.current);
        }, 1500);
      }
    },
  });

  const handleBackButton = () => {
    navigate('/login');
  };

  const handleCloseModal = () => {
    setModal((old) => ({ ...old, open: false }));
  };

  useEffect(() => {
    document.title = 'Sapron | Recuperar senha';

    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  return (
    <>
      <ModalComponent open={modal.open} onClose={handleCloseModal} />
      <Loading show={response.isLoading} />
      <PageContainer>
        <ColoredBar>
          <LogoImage src={Logo} alt="Seazone Logo" />
        </ColoredBar>

        <form onSubmit={formik.handleSubmit}>
          <RecoveryContainer>
            <Title>Recuperar a Senha</Title>
            <Text>Informe o seu e-mail</Text>

            <InputContainer>
              <TextField
                id="email"
                type="email"
                dataCy="email"
                formik={formik}
                placeholder="Seu e-mail"
              />
            </InputContainer>

            <ButtonsContainer>
              <BackButton onClick={handleBackButton}>
                <ArrowBackIcon />
                Voltar
              </BackButton>
              <NextButton type="submit">
                Enviar e-mail de recuperação
              </NextButton>
            </ButtonsContainer>
          </RecoveryContainer>
        </form>
      </PageContainer>
    </>
  );
};
export default ForgotPassword;
