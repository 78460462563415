import React from 'react';
import { Container } from './styles';
import Main from '../../components/FinancialFeedback';
import { FinancialFeedbackProvider } from '../../context/FinancialFeedback/FinancialFeedbackContext';

const FinancialFeedBack = () => (
  <FinancialFeedbackProvider>
    <Container>
      <Main />
    </Container>
  </FinancialFeedbackProvider>
);

export default FinancialFeedBack;
