import { Container } from './styles';

interface IArrowButton {
  onClick?: Function;
}

const ArrowButton = ({
  onClick = () => {},
}: IArrowButton) => (
  <Container type="button" onClick={() => onClick()}>
    <strong>Ver detalhes</strong>
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.69205 7.44217L1.44205 13.6922C1.38398 13.7502 1.31504 13.7963 1.23917 13.8277C1.1633 13.8592 1.08198 13.8753 0.999859 13.8753C0.917737 13.8753 0.83642 13.8592 0.760549 13.8277C0.684678 13.7963 0.61574 13.7502 0.557671 13.6922C0.499603 13.6341 0.45354 13.5652 0.422113 13.4893C0.390687 13.4134 0.374512 13.3321 0.374512 13.25C0.374512 13.1679 0.390687 13.0865 0.422113 13.0107C0.45354 12.9348 0.499603 12.8659 0.557671 12.8078L6.36627 6.99998L0.557671 1.19217C0.440396 1.07489 0.374512 0.915834 0.374512 0.749981C0.374512 0.584129 0.440396 0.425069 0.557671 0.307794C0.674947 0.190518 0.834007 0.124634 0.999859 0.124634C1.16571 0.124634 1.32477 0.190518 1.44205 0.307794L7.69205 6.55779C7.75016 6.61584 7.79626 6.68477 7.82771 6.76064C7.85916 6.83652 7.87535 6.91785 7.87535 6.99998C7.87535 7.08212 7.85916 7.16344 7.82771 7.23932C7.79626 7.31519 7.75016 7.38412 7.69205 7.44217Z" fill="#0D4BD0" />
    </svg>
  </Container>
);

ArrowButton.defaultProps = {
  onClick: () => {},
};

export default ArrowButton;
