import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ptBR from 'date-fns/locale/pt';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { CalendarPickerView } from '@mui/x-date-pickers';
import { UseFormik } from '../../utils/Formik/types';

import {
  StyledLocalizationProvider,
  StyledTextField,
  StyledDatePicker,
  Container,
  ContainerLabel,
  StarSymbol,
} from './styles';

import { ErrorMessage } from '../../utils/Messages';
import HelperText from '../HelperText/HelperText';
import { ErrorIcon } from '../TextField/styles';
import { Type } from '../HelperText/types';

interface DatePickerProps {
  viewsCustom?: CalendarPickerView[];
  allowKeyboardControlCustom?: boolean;
  label?: string;
  id: string;
  dataCy?: string,
  disablePast?: boolean;
  disableFuture?: boolean;
  showDaysOutsideCurrentMonth?: boolean;
  disableCloseOnSelect?: boolean;
  className?: string;
  labelClassName?: string;
  formik?: UseFormik<any>;
  minDate?: Date;
  maxDate?: Date;
  showOnlyMonth?: Date;
  helperText?: string;
  hidden?: boolean,
  required?: boolean,
  requireSymbolPosition?: 'left' | 'right',
  hasInitialDates?: boolean,
  onChange?: (a: Date) => void,
  disableDate?: boolean;
  disableInput?: boolean;
  customOpen?: boolean;
  onCustomClose?: Function;
  showOnlyModalPicker?: boolean;
  onMonthChange?: Function;
  onlyAlertErrorWhenTouched?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  id,
  viewsCustom,
  dataCy = '',
  disablePast,
  disableFuture,
  showDaysOutsideCurrentMonth,
  disableCloseOnSelect,
  className,
  labelClassName = 'datePickerLabel',
  formik,
  minDate,
  maxDate,
  showOnlyMonth,
  helperText,
  hidden = false,
  required = false,
  requireSymbolPosition = 'left',
  hasInitialDates = false,
  onChange = () => {},
  disableDate = false,
  disableInput = false,
  customOpen = false,
  onCustomClose,
  showOnlyModalPicker,
  onMonthChange,
  onlyAlertErrorWhenTouched = false,
  ...rest
}) => {
  const [onOpen, setOnOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(hasInitialDates
    ? formik?.values[id] : new Date());

  useEffect(() => {
    setSelectedDate(formik?.values[id]);
  }, [formik?.values[id]]);

  const isHelperTextError = formik?.errors[id] !== '' && formik?.errors[id];
  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  const handleDateChange = (newDate?: Date | undefined) => {
    if (newDate) {
      formik?.setFieldTouched(
        id, true, false,
      );

      const date = moment((newDate as Date));
      const year = Number(date.format('YYYY'));
      if ((year < 1870 || Number.isNaN(year)) && newDate !== null) {
        if (formik?.errors[id] !== ErrorMessage.invalidDate()) {
          formik?.setFieldError(id, ErrorMessage.invalidDate());
        }
        return;
      }

      const minDateMoment = moment(minDate);
      if (minDate && Number(date.format('YYYYMMDD')) < Number(minDateMoment.format('YYYYMMDD'))) {
        if (formik?.errors[id] !== ErrorMessage.minDate(minDate)) {
          formik?.setFieldError(id, ErrorMessage.minDate(minDate));
        }
        return;
      }

      formik?.setFieldError(id, '');
      formik?.setFieldValue(id, newDate);
      setSelectedDate(newDate as Date);
      onChange(newDate);
    }
  };

  useEffect(() => {
    if (onlyAlertErrorWhenTouched) {
      if (onOpen) {
        formik?.setFieldTouched(
          id, true, false,
        );
      }
    }
  }, [onOpen]);

  const helperTextValue = () => {
    if (onlyAlertErrorWhenTouched) {
      const touched = formik?.touched[id];
      if (touched && isHelperTextError) {
        return (
          <>
            <ErrorIcon />
            {formik?.errors[id]}
          </>
        );
      }

      return null;
    }

    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const minmaxDates = () => {
    if (showOnlyMonth) {
      const month = showOnlyMonth.getMonth();
      const year = showOnlyMonth.getFullYear();

      const currentDate = new Date(
        year, month, 1,
      );

      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const firstDate = new Date(
        currentYear, currentMonth - 1, 1,
      );

      const lastDate = new Date(
        currentYear, currentMonth, 0,
      );

      return { firstDate, lastDate };
    }

    return {
      firstDate: minDate,
      lastDate: maxDate,
    };
  };

  return (
    <Container className="datePickerContainer" showOnlyModalPicker={showOnlyModalPicker} hidden={hidden}>
      <StyledLocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
        <ContainerLabel>
          {required && requireSymbolPosition === 'left' && <StarSymbol>*</StarSymbol>}
          <label htmlFor={label} className={labelClassName}>{label}</label>
          {required && requireSymbolPosition === 'right' && <StarSymbol>*</StarSymbol>}
        </ContainerLabel>

        <StyledDatePicker
          {...rest}
          onMonthChange={onCustomClose ? () => onCustomClose() : () => {}}
          open={customOpen || onOpen}
          views={viewsCustom}
          onClose={onCustomClose ? () => onCustomClose() : () => setOnOpen(false)}
          minDate={minmaxDates().firstDate}
          maxDate={minmaxDates().lastDate}
          value={selectedDate}
          onChange={(date: any) => handleDateChange(date)}
          showToolbar={false}
          toolbarTitle=""
          disablePast={disablePast}
          disableFuture={disableFuture}
          showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
          closeOnSelect={disableCloseOnSelect}
          className={className}
          disabled={disableDate}
          disableMaskedInput={disableInput}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              showOnlyModalPicker={showOnlyModalPicker}
              margin="none"
              variant="outlined"
              size="small"
              helperText={null}
              value={selectedDate}
              onChange={disableInput ? () => {} : (date: any) => handleDateChange(date)}
              className="customStyledTextField"
              onClick={onCustomClose ? () => onCustomClose() : () => setOnOpen((state) => !state)}
              data-cy={dataCy}
              disabled={disableInput}
            />
          )}
        />
        { !!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
        )}
      </StyledLocalizationProvider>
    </Container>
  );
};

DatePicker.defaultProps = {
  customOpen: undefined,
  showOnlyModalPicker: false,
  onCustomClose: undefined,
  viewsCustom: ['day'],
  allowKeyboardControlCustom: true,
  onMonthChange: undefined,
  showOnlyMonth: undefined,
};

export default DatePicker;
