/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { useConciliation } from '../../../context/ConciliationPage/ConciliationContext';
import {
  GuestConciliation, OtaConciliation, PropertyConciliation, ReservationConciliation,
} from '../../../services/Conciliation/types';
import { numberToCurrency } from '../../../utils/Formatter';
import {
  AmountPaid,
  Date, Text,
  MissingValues,
  Amount,
  Container,
  ContentInfo,
  GuestName,
  ImmobileOta,
  TotalValue,
} from './styles';

interface Props {
  reservation: ReservationConciliation,
}

const CardReservation = ({ reservation }: Props) => {
  const { setReservationMove, addNewReservation } = useConciliation();

  const [{ isDragging }, drag] = useDrag({
    type: 'Card',
    item: {
      id: '1',
      name: 'Fernanda Cruz',
      property: {} as PropertyConciliation,
      guest: {} as GuestConciliation,
      check_in_date: '',
      check_out_date: '',
      ota: {} as OtaConciliation,
    },
    end: (draggedItem, monitor) => {
      addNewReservation();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  useEffect(() => {
    if (isDragging) {
      setReservationMove(reservation);
    }
  }, [isDragging]);

  return (
    <Container ref={drag} opacity={opacity}>
      <ContentInfo>
        <div>
          <GuestName>
            {reservation.guest.user.first_name}
            {' '}
            {reservation.guest.user.last_name}
          </GuestName>
          <ImmobileOta>
            {reservation.property.code}
            {' '}
            |
            {' '}
            {reservation.ota.name}
          </ImmobileOta>
        </div>
        <div className="dates">
          <Date>
            Check-in:
            {' '}
            {moment(reservation.check_in_date).format('DD/MM/YYYY')}
          </Date>
          <Date>
            Check-out:
            {' '}
            {moment(reservation.check_out_date).format('DD/MM/YYYY')}
          </Date>
        </div>
        <TotalValue>
          { numberToCurrency(reservation.total_price || 0)}
        </TotalValue>
      </ContentInfo>
      <AmountPaid>
        <Text>Valor pago:</Text>
        <Amount>
          {numberToCurrency(reservation.payment?.amount_paid
            ? parseFloat(reservation.payment?.amount_paid)
            : parseFloat(reservation.paid_amount || '0'))}
        </Amount>
      </AmountPaid>
      <MissingValues>
        <Text>Valor faltante:</Text>
        <Amount>{numberToCurrency(reservation.owing_value)}</Amount>
      </MissingValues>
    </Container>
  );
};

export default CardReservation;
