import styled from 'styled-components';
import { animationTime, shimmerAnimation } from '../animationShimmer';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  overflow-y: hidden;
  z-index: 9999;
  
  @media (max-width: 900px) {
    height: 100vh;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100px;
  background: #ff000042;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 10rem;

  :after {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.grey._400.hex()};
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  }

  :before {
    content: "";
    display: block;
    width: 200px;
    height: 25px;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
    background: ${({ theme }) => theme.palette.grey._400.hex()};
  }

  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};

  animation: ${shimmerAnimation} ${animationTime + 2}s ease-in-out infinite;


  @media (max-width: 900px) {
    padding: 0 2rem;
  }
`;

export const LateralModal = styled.div`
  width: 300px;
  height: calc(100vh - 180px);
  display: flex;
  background: #091180e4;

  align-items: center;
  text-align: right;
  justify-content: center;
  flex-direction: column-reverse;

  border-left: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};

  animation: ${shimmerAnimation} ${animationTime + 2}s ease-in-out infinite;

  :after {
    content: "";
    height: 30px;
    width: 150px;
    display: flex;
    border-radius: 7px;
    background: ${({ theme }) => theme.palette.grey._400.hex()};
    animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  }

  :before {
    margin-top: 4rem;
    content: "";
    display: flex;
    width: 150px;
    height: 30px;
    border-radius: 7px;
    background: ${({ theme }) => theme.palette.grey._400.hex()};
    animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 700px;
  height: 400px;
  border-radius: 2rem;
  background: #358009e3;
  display: flex;

  margin-left: calc(50% - 500px);
  text-align: center;
  align-content: center;
  justify-content: center;

  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;

  @media (max-width: 900px) {
    margin: 1rem 3rem;
    border: none;
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  justify-content: space-between;

  @media (max-width: 900px) {
    border: none;
  }
`;

export const Footer = styled.div`
  height: 80px;
  width: 100%;
  background: #ea00ff42;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 10rem;

  :after {
    content: "";
    display: block;
    width: 150px;
    height: 40px;
    border-radius: 7px;
    background: green;
    animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  }

  :before {
    border-radius: 7px;
    content: "";
    display: block;
    width: 150px;
    height: 40px;
    background: purple;
    animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  }

  border-top: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};

  animation: ${shimmerAnimation} ${animationTime + 2}s ease-in-out infinite;


  @media (max-width: 900px) {
    padding: 0 2rem;

    :after {
      margin: 0 20px;
    }

    :before {
      margin: 0 20px;
    }
  }
`;
