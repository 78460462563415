import React from 'react';

import WhatsappFloatButton from '../../../components/WhatsappFloatButton';
import CardPropertyDetails from '../../../components/OwnerPage/CardPropertyDetails';

import { DateProvider } from '../../../context/OwnerPage/DateContext';
import { OwnerCardPropertiesProvider } from '../../../context/OwnerPage/OwnerCardProperties/OwnerCardPropertiesContext';

const PropertyDetailsOwner: React.FC = () => (
  <>
    <WhatsappFloatButton phoneNumber="5548991020350" />
    <DateProvider>
      <OwnerCardPropertiesProvider>
        <CardPropertyDetails />
      </OwnerCardPropertiesProvider>
    </DateProvider>
  </>
);

export default PropertyDetailsOwner;
