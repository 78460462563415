import { useFormik } from 'formik';
import React from 'react';
import DatePicker from '../../DatePicker';
import FormButton from '../../FormButton';

import TextField from '../../TextField';

import { Container, SearchBar } from './styles';
import SimpleSelect, { SelectOption } from '../../SimpleSelect/SimpleSelect';

interface Props {
  setGuestName: (a: string) => void;
  setPropertyType: (a: string) => void;
  setCode: (a: string) => void;
  setDateCheckin: (a: string) => void;
}

const propertyTypes:SelectOption[] = [
  { value: 'House', valueLabel: 'Casa' },
  { value: 'Apartment', valueLabel: 'Apartamento' },
  { value: 'Hotel', valueLabel: 'Hotel' },
];

const Header = ({
  setCode, setDateCheckin, setGuestName, setPropertyType,
}: Props) => {
  const initialValues = {
    guestName: '',
    propertyType: '',
    code: '',
    checkin: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setCode(values.code);
      setDateCheckin(values.checkin);
      setGuestName(values.guestName);
      setPropertyType(values.propertyType);
    },
  });

  return (
    <Container>
      <div>
        <h1>Reservas para completar</h1>
        <h2>Verifique as confirmações</h2>
      </div>
      <SearchBar
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        <div className="guest-name">
          <TextField placeholder="Digite o nome do hóspede" id="guestName" label="Nome do hóspede" formik={formik} />
        </div>
        <div className="property-type">
          <SimpleSelect placeholder="Selecione.." id="propertyType" label="Tipo do imóvel" formik={formik} options={propertyTypes} />
        </div>
        <div className="code">
          <TextField placeholder="Ex: ILC" id="code" label="Cód. do imóvel" formik={formik} />
        </div>
        <div className="checkin">
          <DatePicker id="checkin" viewsCustom={['day', 'month', 'year']} label="Check-in" formik={formik} />
        </div>
        <div className="save-button">
          <FormButton type="submit">Buscar</FormButton>
        </div>
      </SearchBar>
    </Container>
  );
};

export default Header;
