import { useContextSelector } from 'use-context-selector';
import { ModalContext } from '../../context/ModalContext';

export function useModal() {
  const open = useContextSelector(ModalContext, (modal) => modal.open);
  const handleOpen = useContextSelector(ModalContext, (modal) => modal.handleOpen);
  const modalActive = useContextSelector(ModalContext, (modal) => modal.modalActive);
  const handleModalActive = useContextSelector(ModalContext, (modal) => modal.handleModalActive);

  return {
    open,
    handleOpen,
    modalActive,
    handleModalActive,
  };
}
