import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface Props {
  title: string;
  description?: string;
  children: JSX.Element;
  open?: boolean;
  background?: string,
  textColor?: string,
}

type TooltipComponentProps = TooltipProps & {
  background?: string,
  textColor?: string,
};

const TooltipComponent = styled(({ className, ...props }: TooltipComponentProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ background, textColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: background,
    color: textColor,
    padding: '1rem',
    borderRadius: '10px',
    maxWidth: '550px',
  },
}));

const StyledTooltip: React.FC<Props> = ({
  title,
  description,
  children,
  open,
  background = '#FF9900',
  textColor = '#001840',
}) => {
  if (title === '') {
    return <>{children}</>;
  }

  return (
    <TooltipComponent
      background={background}
      textColor={textColor}
      arrow
      open={open}
      placement="bottom-start"
      followCursor
      enterDelay={200}
      leaveDelay={200}
      title={(
        <div style={{
          display: 'flex',
          gap: '0.5rem',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <h2 style={{
            fontSize: '1.4rem',
            fontWeight: 700,
            fontFamily: 'Inter',
          }}
          >
            {title}
          </h2>
          {description && (
            <p style={{
              fontSize: '1rem',
              fontWeight: 400,
              fontFamily: 'Inter',
            }}
            >
              {description}
            </p>
          )}
        </div>
      )}

    >
      {children}
    </TooltipComponent>
  );
};

export default StyledTooltip;
