import { Switch, alpha } from '@mui/material';
import styled, { css } from 'styled-components';

export const CustomSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#ed2b2bb0',
    '&:hover': {
      backgroundColor: alpha('#ed2b2bb0', 0.5),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#ed2b2bb0',
  },
}));

export const Container = styled.div`
  display: flex;
  background: #f8f8f8;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 15px;
  min-height: 50px;
  background: #001840;

  p,strong {
    color: #FF9900;
  }

  p{
    font-size: 1rem;
  }

  strong{
    gap: 20px;
    font-weight: bold;
    display: flex;
    font-size: 1.1rem;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 5px 20px;

  h1 {
    font-weight: 500;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const Yes = styled.h1<{
  active: boolean;
}>`
  ${({ active }) => active
    && css`
      color: #ed2b2bb0;
    `}
`;
export const No = styled.h1<{
  active: boolean;
}>`
  ${({ active }) => !active
    && css`
      color: #ed2b2bb0;
    `}
`;
