import styled from 'styled-components';

export const HeadConfigContainer = styled.div<{
  mobile: boolean;
}>`
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    color: #394760;
    background: none;
    border: none;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 1.4rem;
    cursor: pointer;
    transition: 0.3s;
  }

  .container-buttons {
    display: flex;
    justify-content: space-between;
    width: auto;
  }

  button.lastWeek {
    &:hover {
      transform: translateX(-0.5rem);
    }

    @media (max-width: 1700px) {
      transform: scale(0.8);

      &:hover {
        transform: scale(0.8) translateX(-0.5rem);
      }
    }
  }

  button.nextWeek {
    &:hover {
      transform: translateX(0.5rem);
    }

    @media (max-width: 1700px) {
      transform: scale(0.8);

      &:hover {
        transform: scale(0.8) translateX(0.5rem);
      }
    }
  }
`;

export const HeadConfig = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }

  h2 {
    color: ${({ theme }) => theme.palette.grey._720.hex()};
    font-size: 1.3rem;
    font-weight: bolder;
  }
`;

export const HeadTitle = styled.h2``;

export const LastWeek = styled.button`
  font-weight: 600;
  padding: 1rem;

  img {
    margin-right: 1rem;
  }
`;

export const NextWeek = styled.button`
  font-weight: 600;
  padding: 1rem;

  img {
    margin-left: 1rem;
  }
`;

export const InputContainer = styled.div`
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 900px) {
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
    padding: 0 1rem;
  }
`;
