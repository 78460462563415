import React, { FC } from 'react';

import { OutlinedButton } from './styles';

import {
  Color,
  ButtonProps,
  ColorsEnum,
  ButtonTypeProps,
  ButtonTypeEnum,
} from './types';

interface FormButtonProps extends ButtonProps {
  link?: boolean;
  dataCy?: string;
  disable?: boolean;
  invisible?: boolean;
  customColor?: Color;
  type?: ButtonTypeProps;
  isFull?: boolean;
  customClassName?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  id?: string;
  newStyle?: boolean;
}

const FormButton: FC<FormButtonProps> = ({
  link,
  type,
  dataCy,
  disable,
  variant,
  onClick,
  children,
  invisible,
  customColor,
  id,
  isFull,
  customClassName,
  newStyle,
}) => (
  <OutlinedButton
    id={id}
    newStyle={newStyle}
    type={type}
    data-cy={dataCy}
    variant={variant}
    onClick={onClick}
    disabled={disable}
    linkto={link ? 1 : 0}
    invisible={invisible}
    customcolorbg={customColor}
    className={variant ? `${variant}` : `grey ${customClassName}`}
    isFull={isFull}
    data-testid={dataCy}
  >
    {children}
  </OutlinedButton>
);

FormButton.defaultProps = {
  dataCy: '',
  link: false,
  invisible: false,
  onClick: () => {},
  customClassName: '',
  type: ButtonTypeEnum.SUBMIT,
  customColor: ColorsEnum.BLUE,
  isFull: false,
};

export default FormButton;
