import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  border-radius: 10px;
  justify-content: center;
  border: 3px dashed ${({ theme }) => theme.palette.grey._400.hex()};

  color: ${({ theme }) => theme.palette.grey._550.hex()};

  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  padding: 30px;
  text-align: center;
  flex-direction: column;

  h1 {
    font-size: 3rem;
    font-weight: 200;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;
