import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.button)<{
  variant?: 'primary' | 'outline';
}>`
  border: 0;
  width: 100%;
  display: flex;
  border-radius: 5px;
  padding: 15px 20px;
  background: #0d4bd0;
  justify-content: center;

  &:disabled {
    background: #bec6cc;
    position: relative;

    &::after {
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 3px;
      height: 100%;
      width: 100%;
      position: absolute;
      border-radius: inherit;
      background: transparent;
      border: 2px solid #bec6cc;
    }
  }

  h1 {
    color: white;
    font-weight: 600;
  }

  ${({ variant }) => variant === 'outline'
    && css`
      background: #fefefe;
      border: 1px solid #0d4bd0;
      h1 {
        color: #0d4bd0;
      }
    `}

  ${({ variant }) => variant === 'primary'
    && css`
      background: #0d4bd0;
      h1 {
        color: white;
      }
    `}
`;
