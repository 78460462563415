import { useContextSelector } from 'use-context-selector';
import { DateContext } from '../../context/OwnerPage/DateContext';

export function useDate() {
  const dateSelected = useContextSelector(DateContext, (date) => date.dateSelected);
  const handleDateSelected = useContextSelector(DateContext, (date) => date.setDateSelected);
  const direction = useContextSelector(DateContext, (date) => date.direction);
  const handleDirection = useContextSelector(DateContext, (date) => date.setDirection);
  const gridMonth = useContextSelector(DateContext, (date) => date.month);
  const setGridMonth = useContextSelector(DateContext, (date) => date.setMonth);
  const gridYear = useContextSelector(DateContext, (date) => date.year);
  const setGridYear = useContextSelector(DateContext, (date) => date.setYear);
  const selectInModal = useContextSelector(DateContext, (date) => date.selectInModal);
  const setSelectInModal = useContextSelector(DateContext, (date) => date.setSelectInModal);

  return {
    dateSelected,
    handleDateSelected,
    direction,
    handleDirection,
    gridMonth,
    setGridMonth,
    gridYear,
    setGridYear,
    selectInModal,
    setSelectInModal,
  };
}
