import React, {
  createContext, useState, ReactNode, useContext,
} from 'react';

interface GridCalendarReloadContextData {
  reload: boolean;
  setReload: (a: boolean) => void;
}

interface Props {
  children: ReactNode;
}

const GridCalendarReloadContext = createContext<GridCalendarReloadContextData>({} as
  GridCalendarReloadContextData);

export const GridCalendarReloadProvider = ({
  children,
}: Props) => {
  const [reload, setReload] = useState<boolean>(false);

  return (
    <GridCalendarReloadContext.Provider value={{
      reload,
      setReload,
    }}
    >
      {children}
    </GridCalendarReloadContext.Provider>
  );
};

export function useReload(): GridCalendarReloadContextData {
  const { reload, setReload } = useContext(GridCalendarReloadContext);
  return { reload, setReload };
}
