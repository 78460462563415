import styled from 'styled-components';

export const AddressContainer = styled.div<{
  open: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0;


  button{
    border: none;
  }

  div{
    width: 100%;
    display: flex;
    padding: 0;
    gap: 10px;
    border: none;
  }

  transition: 0.2s;
  
  border: 1px solid transparent;
  ${({ open }) => open && 'border: 1px solid #C4C4C4;'}

  .AddressCopyIcon {
    width: 15px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: translateY(-5px);
    }
  }
`;

export const BottomSideContainer = styled.div`
  display: flex;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
  gap: 10px;
  width: 100%;
  margin-top: 5px;
`;

export const Link = styled.p`
  color: #1D9BE1;
  text-align: left;
  font-size: 1rem;
  padding: 0;
  font-weight: 700;
  text-decoration: underline;
`;
