import React from 'react';

import {
  Title,
  Image,
  Section,
  CardTitle,
  Container,
  Description,
  CardContent,
  ImageContainer,
  ButtonContainer,
  Button,
} from './style';

import { ICard } from '../types';

import StepsBall from '../../StepsBall/StepsBall';
import { useSteps } from '../../context';
import { CheckBox } from '../Checkbox/Checkbox';

const Desktop = ({
  title,
  image,
  card,
}: ICard) => {
  const {
    options,
    filterButtons,
    handleNextStep,
    handlePrevStep,
    handleFinish,
  } = useSteps();

  return (
    <Container>
      <ImageContainer>
        {image && <Image>{image}</Image>}
        <StepsBall />
      </ImageContainer>

      <Section>
        {title && <Title>{title}</Title>}
        <CardContent>
          <CardTitle>{card?.title}</CardTitle>
          <Description>{card.description}</Description>
          <ButtonContainer>
            {filterButtons?.map((auxButton) => (
              <Button
                type="button"
                key={auxButton.text}
                whileTap={{ scale: 0.9 }}
                variant={auxButton?.style}
                onClick={() => auxButton?.onClick({
                  finish: handleFinish,
                  nextStep: handleNextStep,
                  prevStep: handlePrevStep,
                })}
              >
                {auxButton?.text}
              </Button>
            ))}
          </ButtonContainer>

          {options?.showNotShowAgainCheckbox?.is && <CheckBox />}
        </CardContent>
      </Section>
    </Container>
  );
};

export default Desktop;
