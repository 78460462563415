import React from 'react';
import { useNavigate } from 'react-router-dom';
import { partnersRoutes } from '../../../../types';
import useSelectRoles from '../../../../../../context/useSelectRole';
import logoutAndClearData from '../../../../../../utils/LogoutAndClearData';

import {
  Option,
  Container,
  ListOptions,
  OptionsContainer,
} from './style';

interface IBody {
  onClose: Function;
}

const Body = ({
  onClose,
}: IBody) => {
  const navigate = useNavigate();
  const { routesArray, resolveNavigateRouteWithPrefix } = useSelectRoles();

  const handleClickDoubts = () => {
    onClose();
    navigate(partnersRoutes.questions.main);
  };

  return (
    <Container>
      <OptionsContainer>
        <ListOptions>
          {routesArray?.map((role, index) => (
            <Option
              key={role?.id || index}
              onClick={() => {
                onClose();
                navigate(role?.link || '');
              }}
            >
              {resolveNavigateRouteWithPrefix(role)}
            </Option>
          ))}

          <Option onClick={handleClickDoubts}>
            Dúvidas
          </Option>

          <Option onClick={() => logoutAndClearData()}>Sair</Option>
        </ListOptions>
      </OptionsContainer>
    </Container>
  );
};

export default Body;
