import styled, { keyframes } from 'styled-components';

const showItems = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  content-visibility: auto;
`;

export const Title = styled.h1`
  font-size: 1.1rem;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;

export const TitleReservationCode = styled.h1`
  font-size: 1.1rem;
  width: fit-content;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const ItemContainer = styled.div<{
  index: number;
  checked: boolean;
  color: 'normal' | 'green';
}>`
  background: ${({ color }) => (color === 'normal' ? '#ebebf5' : '#DFEEEB')};
  background: ${({ checked }) => checked && '#C7CBD3'};
  border-radius: 7px;
  padding: 15px 10px;
  border: 1px solid transparent;
  position: relative;
  transition: 0.2s;

  &:nth-child(n){
    opacity: 0;
    animation: ${showItems} 0.4s 0.0s ease forwards;
  }

  &:nth-child(2n){
    opacity: 0;
    animation: ${showItems} 0.4s 0.3s ease forwards;
  }

  :hover {
    border: 1px solid #00000029;
  }
`;

export const EditButton = styled.button`
  background: #f1f1f6;
  border: none;
  height: 100%;
  width: 60px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  transition: 0.3s;

  @media (max-width: 1400px) {
    width: 5%;
  }

  &:disabled {
    background: ${({ theme }) => theme.palette.grey._650.hex()}
  }

  img {
    transition: 0.2s;
  }
  :hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    img {
      transform: translateY(-3px);
    }
  }
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.2fr 1.1fr 1.1fr 1.1fr 1.2fr;
  align-items: center;
  @media (max-width: 1400px) {
    grid-template-columns: 0.3fr 1fr 1.5fr 1fr 1fr 1fr;
  }
`;

export const ReservationCode = styled.div``;

export const ImmobileCode = styled.div``;

export const ReservationDate = styled.div``;

export const ReservationStaff = styled.div``;

export const WhatsApp = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  img {
    width: 15px;
    cursor: pointer;
    transition: 0.2s;
    margin: 0;

    :hover {
      transform: translateY(-3px);
    }
  }
`;

export const ContainerWithRedText = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: row;

  img {
    width: 15px;
    margin: 0px;
    cursor: pointer;
    transition: 0.2s;

    :hover {
      transform: translateY(-3px);
    }
  }

  p {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: red;
  }
`;

export const PendingValue = styled.div`
  h1 {
    font-weight: 500;
    color: ${({ theme }) => theme.palette.red._550.hex()}
  }
`;

export const TotalValue = styled.div``;

export const CheckBoxContainer = styled.div``;
