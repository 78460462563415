import styled from 'styled-components';

export const Container = styled.div`
  width: 70%;
  height: 80vh;
  padding: 11px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 0px 0px 10px 0px;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin: 25px 0;
  }
`;

export const ContentConciledPayments = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 100px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 0;

  border-top: 1px solid ${({ theme }) => theme.palette.grey._370.hex()};
`;

export const ButtonDisconnectConciliation = styled.button`
  padding: 8px 15px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border: 1px solid ${({ theme }) => theme.palette.red._600.hex()};
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.red._600.hex()};
  margin: 0 37px;
  cursor: pointer;

  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.palette.red._50.hex()};

    box-shadow:
    0px 0.2px 0.5px rgba(0, 0, 0, 0.029),
    0px 0.4px 1.1px rgba(0, 0, 0, 0.043),
    0px 0.8px 2.1px rgba(0, 0, 0, 0.053),
    0px 1.3px 3.8px rgba(0, 0, 0, 0.06),
    0px 2.5px 7.1px rgba(0, 0, 0, 0.065),
    0px 6px 17px rgba(0, 0, 0, 0.07)
  }
`;

export const HeaderInformations = styled.div`
  width: 100%;
  padding: 12px 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed ${({ theme }) => theme.palette.grey._280.hex()};
  border-radius: 11px;
`;

export const NameGuest = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

export const ImmobileOta = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: -0.02em;
  margin-top: 6px;
`;

export const Dates = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
`;
export const Value = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;

`;
export const RemoveSelection = styled.button`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 28px;
  letter-spacing: -0.003px;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;

  color: ${({ theme }) => theme.palette.red._600.hex()};
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`;
