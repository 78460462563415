import { useContextSelector } from 'use-context-selector';
import { OwnerCardPropertiesContext } from '../../../context/OwnerPage/OwnerCardProperties/OwnerCardPropertiesContext';

export function useOwnerCardProperties() {
  const propertiesList = useContextSelector(OwnerCardPropertiesContext,
    (state) => state.propertiesList);

  const setPropertiesList = useContextSelector(OwnerCardPropertiesContext,
    (state) => state.setPropertiesList);

  const handleUpdatePropertyCondition = useContextSelector(OwnerCardPropertiesContext,
    (state) => state.handleUpdatePropertyCondition);

  return {
    propertiesList,
    setPropertiesList,
    handleUpdatePropertyCondition,
  };
}
