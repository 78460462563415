import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../hooks/useMobile/useMobile';

export const Container = styled(motion.div)<{
  offsetTop: number;
  offsetLeft: number;
}>`
  z-index: 99999;
  width: 200px;
  height: fit-content;
  position: absolute;
  border-radius: 5px;
  background-color: #fff;

  border: 1px solid #ccc;

  top: ${({ offsetTop }) => `${offsetTop}px`};
  left: ${({ offsetLeft }) => `calc(${offsetLeft}px - 50%)`};

  @media screen and (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    left: ${({ offsetLeft }) => `${offsetLeft - 200}px`};
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #cccc;
  }

  .partners-container {
    overflow: hidden;
  }
`;

export const Content = styled(motion.div)`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 10px;
  overflow-x: hidden;
`;

export const TitleContainer = styled(motion.div)`
  gap: 20px;
  color: #fff;
  display: flex;
  padding: 10px 0;
  align-items: center;
  background: #3f50e5;
  flex-direction: column;
  justify-content: center;

  border-radius: 5px 5px 0 0;

  h1 {
    font-weight: bolder;
  }
`;

export const ItemContainer = styled(motion.div)<{
  active: boolean;
}>`
  gap: 20px;
  width: 100%;
  display: flex;
  opacity: 0.8;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${({ active }) => active && css`
    color: #3f50e5;
  `}
`;

export const IconContainer = styled(motion.div)`
  display: flex;
  padding: 20px 0;
`;

export const TextContainer = styled(motion.div)`
  cursor: pointer;
  display: flex;
  user-select: none;
  width: 100%;
`;

export const Separator = styled(motion.div)`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background: #000;
`;

export const Backdrop = styled(motion.button)`
  background: transparent;
  border: none;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
`;
