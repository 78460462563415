import styled, { keyframes } from 'styled-components';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SlieFadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  } to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 155px;

  .selector {
    padding: 10px;
    width: 155px;
    font-weight: bold;

    & .MuiSelect-icon {
      opacity: 0;
      animation-name: ${SlieFadeAnimation};
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;

      color: #eb6b57;
      border-radius: 10px;
      transform: scale(1.4);
    }

    &
      .MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
      opacity: 0;
      animation-name: ${SlieFadeAnimation};
      animation-duration: 0.5s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;

      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: "Quicksand", sans-serif;
      font-weight: 700;
      font-size: 14px;
      padding-top: 9px;
      padding-bottom: 11px;
    }

    & option {
      color: ${({ theme }) => theme.palette.blue._950.hex()};
      font-family: "Quicksand", sans-serif;
      font-weight: 700;
      font-size: 14px;
    }

    && {
      opacity: 0;
      animation-name: ${SlieFadeAnimation};
      animation-duration: 0.5s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
    }
  }


`;
