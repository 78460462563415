import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../context/UserContext';
import { PopupTemplate } from '../../../Popups';
import { data } from './data';

interface IWelcomePopup {
  open: boolean,
  setOpen: Function,
}

const WelcomePopup = ({
  open,
  setOpen,
}: IWelcomePopup) => {
  const navigate = useNavigate();
  const { userInformation } = useUser();

  const scrollToSection = () => {
    setOpen(false);

    setTimeout(() => {
      const section = document.getElementById('resumo-financeiro');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  const navigateToSection = () => {
    setOpen(false);
    navigate('/meusdados#controle-de-repasse');
  };

  if (!open) {
    return null;
  }

  return (
    <PopupTemplate
      data={data}
      options={{
        showNotShowAgainCheckbox: {
          is: true,
        },
        buttons: [
          {
            step: 0,
            is: true,
            onClick: () => navigateToSection(),
            text: 'Quero definir agora!',
          },
          {
            is: true,
            step: 0,
            onClick: ({ nextStep }) => {
              nextStep();
            },
            text: 'Próximo',
            style: 'outline',
          },
          {
            is: true,
            step: 1,
            onClick: () => scrollToSection(),
            text: 'Ver resumo financeiro',
          },
          {
            step: 1,
            is: true,
            text: 'Voltar',
            style: 'outline',
            onClick: ({ prevStep }) => {
              prevStep();
            },
          },
        ],
      }}
      newBody={{
        Owner: userInformation?.required_actions?.Owner?.filter((item) => item !== 'OWNER_FINANCIAL_SUMMARY_POPUP'),
      }}
      onFinish={{}}
      onClose={() => setOpen(false)}
    />
  );
};

export default WelcomePopup;
