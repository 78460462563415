import React, { FC } from 'react';

import { X } from 'react-feather';

import { useFormik } from 'formik';
import { Reservation } from '../../../../services/Reservation/types';

import {
  ContainerBox,
  ContentBox,
  HeaderContainer,
  Title,
  CloseButtonContainer,
  ButtonCanceled,
} from './styles';

import FormGridRow from '../../../FormGridRow';
import ModalTemplate from '../ModalTemplate';

import { putReservationStatus } from '../../../../services/Reservation/request';
import { TextField } from '../../..';
import { useToast } from '../../../../context/ToastContext';
import { useReload } from '../../../../context/OwnerPage/GridCalendarReload';

interface Props {
  id: number;
  onClose: Function;
  open?: boolean;
  reservation: Reservation;
}

const ModalCancelLock: FC<Props> = ({
  id,
  open = false,
  onClose,
  reservation,
}) => {
  const toast = useToast();
  const { setReload } = useReload();

  const formik = useFormik({
    initialValues: {
      notes: '',
    },
    onSubmit: async (values) => {
      const newStatus = 'Canceled';
      const newComment = values.notes;

      try {
        await putReservationStatus(
          reservation,
          newStatus,
          newComment,
          id,
        );
        onClose();
        setReload(true);
        toast.success('O bloqueio foi cancelado com sucesso!');
      } catch (error) {
        toast.error('Não foi possível cancelar o bloqueio!');
      }
    },
  });

  return (
    <>
      <ModalTemplate
        open={open}
        handleClose={onClose}
      >
        <ContainerBox>
          <HeaderContainer>
            <Title>Cancelar bloqueio</Title>
            <CloseButtonContainer
              onClick={() => onClose(false)}
            >
              <X
                size={20}
              />
              <strong>Fechar</strong>
            </CloseButtonContainer>
          </HeaderContainer>
          <form onSubmit={formik.handleSubmit}>
            <ContentBox>
              <FormGridRow>
                <TextField
                  dataCy="block-reason"
                  label="Descreva o motivo do cancelamento"
                  id="notes"
                  formik={formik}
                  type="textarea"
                />
              </FormGridRow>
              <FormGridRow>
                <ButtonCanceled data-cy="btn-cancel-block-confirm" type="submit">Cancelar bloqueio</ButtonCanceled>
              </FormGridRow>
            </ContentBox>
          </form>
        </ContainerBox>
      </ModalTemplate>
    </>
  );
};

export default ModalCancelLock;
