import React, { useState, useCallback } from 'react';

import { createContext, useContextSelector } from 'use-context-selector';

import ToastComponent from './ToastComponent';

import { ToastPlaceContainer } from './styles';

import {
  ID,
  IToasts,
  IToastContext,
  IToastWhithID,
  IToastProvider,
} from './types';

export const CustomToastContext = createContext<IToastContext>({
  addToast: () => {},
  removeToast: () => {},
});

export const CustomToastProvider = ({ children }: IToastProvider) => {
  const [toasts, setToasts] = useState<IToastWhithID[]>([]);

  const addToast = useCallback(({
    type,
    title,
    size,
    customIcon,
    description,
    customMaxTime,
    hideTimeLine,
  }: IToasts) => {
    setToasts((oldToast) => [
      {
        id: oldToast.length + 1,
        type,
        title,
        size,
        customIcon,
        description,
        customMaxTime,
        hideTimeLine,
      },
      ...oldToast,
    ]);
  },
  []);

  const removeToast = useCallback((id: ID) => {
    setToasts((oldToasts) => oldToasts.filter((toast) => toast.id !== id));
  },
  []);

  const value = React.useMemo(() => ({
    addToast,
    removeToast,
  }), [
    addToast,
    removeToast,
  ]);

  return (
    <CustomToastContext.Provider value={value}>
      <ToastPlaceContainer>
        {toasts.map((toast) => (
          <ToastComponent
            key={toast.id}
            id={toast.id}
            size={toast.size}
            type={toast.type}
            title={toast.title}
            customIcon={toast.customIcon}
            description={toast.description}
            customMaxTime={toast.customMaxTime}
            hideTimeLine={toast.hideTimeLine}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </ToastPlaceContainer>

      {children}
    </CustomToastContext.Provider>
  );
};

export function useCustomToast() {
  const addToast = useContextSelector(CustomToastContext,
    (state) => state.addToast);
  const removeToast = useContextSelector(CustomToastContext,
    (state) => state.removeToast);

  return {
    addToast,
    removeToast,
  };
}
