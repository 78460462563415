import { useContextSelector } from 'use-context-selector';
import { HostFinancialStatementContext } from '../../../context/HostDashboard/HostFinancialStatement/HostFinancialStatementContext';

export function useFinancialStatement() {
  const period = useContextSelector(HostFinancialStatementContext,
    (state) => state.period);
  const setPeriod = useContextSelector(HostFinancialStatementContext,
    (state) => state.setPeriod);

  const propertyId = useContextSelector(HostFinancialStatementContext,
    (state) => state.propertyId);
  const setPropertyId = useContextSelector(HostFinancialStatementContext,
    (state) => state.setPropertyId);

  const openFinancialStatementModal = useContextSelector(HostFinancialStatementContext,
    (state) => state.openFinancialStatementModal);
  const setOpenFinancialStatementModal = useContextSelector(HostFinancialStatementContext,
    (state) => state.setOpenFinancialStatementModal);

  return {
    period,
    setPeriod,
    propertyId,
    setPropertyId,
    openFinancialStatementModal,
    setOpenFinancialStatementModal,
  };
}
