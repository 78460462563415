import { useContextSelector } from 'use-context-selector';
import { CompleteReservationContext } from '../../context/CompleteReservationContext/CompleteReservationContext';

export function useCompleteReservation() {
  const reservationId = useContextSelector(CompleteReservationContext, (state) => state
    .reservationId);

  const setReservationId = useContextSelector(CompleteReservationContext,
    (state) => state.setReservationId);

  const reservationsList = useContextSelector(CompleteReservationContext, (state) => state
    .reservationsList);

  const setReservationsList = useContextSelector(CompleteReservationContext,
    (state) => state.setReservationsList);

  const updateReservationsList = useContextSelector(CompleteReservationContext,
    (state) => state.updateReservationsList);

  const guestFormIsValid = useContextSelector(CompleteReservationContext, (state) => state
    .guestFormIsValid);

  const setGuestFormIsValid = useContextSelector(CompleteReservationContext,
    (state) => state.setGuestFormIsValid);

  return {
    reservationId,
    setReservationId,
    reservationsList,
    setReservationsList,
    updateReservationsList,
    guestFormIsValid,
    setGuestFormIsValid,
  };
}
