import React from 'react';
import { Container } from './style';

interface IButtonProps {
  children: React.ReactNode;
  variant?: 'primary' | 'outline';
  [key: string]: any;
}

const Button = ({ children, variant, ...rest }: IButtonProps) => (
  <Container variant={variant} type="button" {...rest} whileTap={{ scale: 0.9 }}>
    <h1>{children}</h1>
  </Container>
);

Button.defaultProps = {
  variant: 'primary',
};

export default Button;
