import request from '../../request';
import { ICategoryes, IGetDocuments, IGetImages } from './type';

type IType = 'Document' | 'Image';

type IPostPropertyDocuments = {
  type: IType;
  propertyID: number;
  documentUUID: string;
  category?: ICategoryes | undefined;
};

const endpoints = {
  doc: '/property/documents/',
  img: '/property/images/',
};

export const getPropertyDocuments = async (type: IType, id?: number): Promise<IGetDocuments[]> => {
  const selectEndPoint = type === 'Document' ? endpoints.doc : endpoints.img;

  const { data } = await request.get(selectEndPoint + (id || ''));
  return data;
};

export const getPropertyImages = async (type: IType, id?: number): Promise<IGetImages[]> => {
  const selectEndPoint = type === 'Document' ? endpoints.doc : endpoints.img;

  const { data } = await request.get(selectEndPoint + (id || ''));
  return data;
};

type IDelete = (type: IType, id: string | number) => Promise<IGetDocuments[]>;
export const deletePropertyDocuments: IDelete = async (type, id) => {
  const selectEndPoint = type === 'Document' ? endpoints.doc : endpoints.img;
  const { data } = await request.delete(selectEndPoint + (id || ''));

  return data;
};

export const postPropertyCocuments = async ({
  type,
  category,
  propertyID,
  documentUUID,
}: IPostPropertyDocuments): Promise<IPostPropertyDocuments & { id: number }> => {
  const selectEndPoint = type === 'Document' ? endpoints.doc : endpoints.img;

  const { data } = await request.post(selectEndPoint, {
    category,
    property: propertyID,
    document: documentUUID,
    image: documentUUID,
  });

  return data;
};
