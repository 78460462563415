import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import { AnimatePresence } from 'framer-motion';

import { General, Content, Container } from './style';

import { usePreCheckin } from '../../../../context/PreCheckin/PreCheckin';
import animationSource from '../../../../assets/animations/source/houseLoading.json';

const HouseLoading = () => <Lottie animationData={animationSource} />;

const Loading = () => {
  const [exit, setExit] = React.useState(false);

  const { loading, setLoading } = usePreCheckin();

  useEffect(() => {
    if (!loading.aux) {
      setExit(true);
    }
  }, [loading]);

  const handleFinishEnd = () => {
    setLoading((prev) => ({ ...prev, main: false, aux: false }));
  };

  return (
    <General exit={exit}>
      <AnimatePresence onExitComplete={handleFinishEnd}>
        {!exit && (
          <Container
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{
              scale: 1.6,
              opacity: 0,
              transition: {
                duration: 0.7,
                ease: [1, -0.05, 0.66, 1],
              },
            }}
          >
            <Content>
              <HouseLoading />
              <p>Estamos carregando o pré-checkin ...</p>
            </Content>
          </Container>
        )}
      </AnimatePresence>
    </General>
  );
};

export default Loading;
