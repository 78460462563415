import { useEffect, useState } from 'react';
import {
  DamageResumeContainer,
  Title,
  TotalContainer,
  TotalText,
} from './styles';

import { DamageResumeTable } from './DamageResumeTable';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { getReservationGuestDamages } from '../../../../../services/GuestDamage/request';

import { GetGuestDamage } from '../../../../../services/GuestDamage/types';
import { compareList } from '../../../../../utils/Sorting';

type GuestDamageSortedBy = 'type' | 'item_type' | 'item_name' | 'resolution' | 'refund_holder' | 'item_quantity' | 'price';
type Order = 'asc' | 'desc';

export const DamageResume = () => {
  const { reservationGuestDamages, setReservationGuestDamages } = useGuestDamage();
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const [sortedBy, setSortedBy] = useState<GuestDamageSortedBy>('type');
  const [order, setOrder] = useState<Order>('asc');
  const [sortedGuestDamages,
    setSortedGuestDamages] = useState<GetGuestDamage[]>(reservationGuestDamages);

  function handleSortGuestDamage(sortBy: GuestDamageSortedBy) {
    switch (sortBy) {
      case 'type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.damage_type, b.damage_type, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.damage_type, b.damage_type, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_type': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_type || '', b.item_type || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_type');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_type || '', b.item_type || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_name': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name, b.item_name, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_name, b.item_name, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_name');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_name, b.item_name, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'resolution': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution, b.resolution, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.resolution, b.resolution, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('resolution');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.resolution, b.resolution, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'refund_holder': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.refund_holder || '', b.refund_holder || '', 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('refund_holder');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.refund_holder || '', b.refund_holder || '', 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'item_quantity': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              a.item_quantity, b.item_quantity, 'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('item_quantity');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            a.item_quantity, b.item_quantity, 'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      case 'price': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'desc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          } else {
            setOrder('asc');
            const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
              (a?.item_quantity * (a?.item_price || 0)) || '-',
              (b?.item_quantity * (b?.item_price || 0)) || '-',
              'asc',
            ));
            setSortedGuestDamages(sortGuestDamage);
          }
        } else {
          setOrder('asc');
          setSortedBy('price');
          const sortGuestDamage = reservationGuestDamages.sort((a, b) => compareList(
            (a?.item_quantity * (a?.item_price || 0)) || '-',
            (b?.item_quantity * (b?.item_price || 0)) || '-',
            'asc',
          ));
          setSortedGuestDamages(sortGuestDamage);
        }
        break;
      }
      default: {
        const sortedNegotiations = reservationGuestDamages.sort((a, b) => compareList(
          a.damage_type, b.damage_type, 'asc',
        ));
        setSortedGuestDamages(sortedNegotiations);
        break;
      }
    }
  }

  useEffect(() => {
    handleSortGuestDamage('type');
  }, [reservationGuestDamages]);
  const ownerReservationGuestDamages = sortedGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Proprietário');
  const seazoneReservationGuestDamages = sortedGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Seazone');
  const totalValue = reservationGuestDamages.reduce((total, item) => {
    const itemPrice = item.item_price || 0;
    const itemQuantity = item.item_quantity || 1;
    return total + itemPrice * itemQuantity;
  }, 0);
  const ownerTotalValue = reservationGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Proprietário')
    .reduce((total, item) => {
      const itemPrice = item.item_price || 0;
      const itemQuantity = item.item_quantity || 1;
      return total + itemPrice * itemQuantity;
    }, 0);

  const seazoneTotalValue = reservationGuestDamages
    .filter((guestDamage) => guestDamage.refund_holder === 'Seazone')
    .reduce((total, item) => {
      const itemPrice = item.item_price || 0;
      const itemQuantity = item.item_quantity || 1;

      return total + itemPrice * itemQuantity;
    }, 0);

  async function handleGetReservationGuestDamages(id:number) {
    const data = await getReservationGuestDamages(id);
    setReservationGuestDamages(data);
  }

  useEffect(() => {
    if (selectedGuestDamageNegotiation.reservation?.id) {
      handleGetReservationGuestDamages(selectedGuestDamageNegotiation.reservation.id);
    }
  }, [selectedGuestDamageNegotiation?.reservation?.id]);

  return (
    <DamageResumeContainer>
      <Title>Resumo de danos lançados</Title>
      <DamageResumeTable
        handleSortGuestDamage={handleSortGuestDamage}
        filteredReservations={reservationGuestDamages}
      />
      <TotalContainer>
        <TotalText>
          <span>Total:</span>
          {numberToCurrency(totalValue)}
        </TotalText>
      </TotalContainer>
      {seazoneReservationGuestDamages.length > 0 && (
        <>
          <Title>Resumo de danos - Seazone</Title>
          <DamageResumeTable
            handleSortGuestDamage={handleSortGuestDamage}
            filteredReservations={seazoneReservationGuestDamages}
          />
          <TotalContainer>
            <TotalText>
              <span>Total:</span>
              {numberToCurrency(seazoneTotalValue)}
            </TotalText>
            <TotalText>
              <span>Valor Recebido:</span>
              {numberToCurrency(selectedGuestDamageNegotiation.amount_received)}
            </TotalText>
            <TotalText>
              <span>Valor do Reembolso:</span>
              {numberToCurrency(selectedGuestDamageNegotiation.transfer_to_seazone)}
            </TotalText>
          </TotalContainer>
        </>
      )}

      {ownerReservationGuestDamages.length > 0 && (
        <>
          <Title>Resumo de danos - Proprietário</Title>
          <DamageResumeTable
            handleSortGuestDamage={handleSortGuestDamage}
            filteredReservations={ownerReservationGuestDamages}
          />
          <TotalContainer>
            <TotalText>
              <span>Total:</span>
              {numberToCurrency(ownerTotalValue)}
            </TotalText>
            <TotalText>
              <span>Valor Recebido:</span>
              {numberToCurrency(selectedGuestDamageNegotiation.amount_received)}
            </TotalText>
            <TotalText>
              <span>Valor do Reembolso:</span>
              {numberToCurrency(selectedGuestDamageNegotiation.transfer_to_owner)}
            </TotalText>
          </TotalContainer>
        </>
      )}

    </DamageResumeContainer>
  );
};
