import styled from 'styled-components';

export const Container = styled.div`
  width: 414px;
  min-width: 414px;
  height: 410px;
  position: relative;

  padding: 32px;
  margin-right: 25px;

  background: ${({ theme }) => theme.palette.white._330.hex()};
  border-radius: 13px;

  p, strong {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;

    color: ${({ theme }) => theme.palette.blue._950.hex()};
  }

  strong {
    font-weight: 700;
  }
`;

export const ButtonClose = styled.button`
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.palette.grey._340.hex()};
  border: 1px solid ${({ theme }) => theme.palette.white._430.hex()};
  border-radius: 50px;

  color: ${({ theme }) => theme.palette.orange.main.hex()};

  position: absolute;
  top: 0;
  margin-top: -15px;
  right: 0;
  margin-right: -15px;

  cursor: pointer;
  transition: filter(0.2s);

  :hover {
    filter: brightness(0.9);
    border: 1px solid ${({ theme }) => theme.palette.grey._550.hex()}
  }
`;

export const Property = styled.div`
  width: 350px;
  height: 83px;
  background:${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 52px;
    height: 44px;
    border-radius: 4px;
  }
`;

export const QuantityGuest = styled.div`
  width: 350px;
  height: 42px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 15px;

  section {
    width: 50%;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  div {
    width: 40%;
  }

`;

export const Dates = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 10px 0;

  label {
    color: ${({ theme }) => theme.palette.orange.main.hex()} !important;
  }

  .css-1nrlq1o-MuiFormControl-root {
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    border-radius: 10px;
  }
`;

export const Row = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #${({ theme }) => theme.palette.white._650.hex()};

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;
