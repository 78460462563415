import styled, { css } from 'styled-components';
import { Dialog, DialogContent } from '@mui/material';

export const Background = styled(Dialog)<{
  newStyles?: boolean;
}>`
  && {
    .MuiPaper-rounded {
      border-radius: 10px;
      min-width: 330px !important;
      
      max-width: 1045px;
    }
    .MuiPaper-elevation24 {
      box-shadow: none;
    }
  }

  ${({ newStyles }) => newStyles && css`
    && {
      .MuiPaper-rounded {
        border-radius: 15px;
        max-width: 570px;
      }

      .MuiPaper-elevation24 {
        box-shadow: none;
      }
    }

    @media (max-width: 1000px) {
      && {
        .MuiPaper-rounded {
          min-width: 90% !important;
        }
      }
    }
  `}

 
`;

export const ModalBox = styled(DialogContent)`
  && {
    padding: 0px !important;
  }

  && {
    p, strong, h1, input, .MuiButton-root > .MuiButton-label {
      font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
      font-style: normal;
      font-weight: 500;
    }
  }
`;
