import { useEffect } from 'react';

import Main from '../../components/DashboardPage';
import { Container } from './styles';
import { DateProvider } from '../../context/OwnerPage/DateContext';
import { HostGridFinancesProvider } from '../../context/HostDashboard/HostGridFinances/HostGridFinancesContext';
import { HostFinancialStatementProvider } from '../../context/HostDashboard/HostFinancialStatement/HostFinancialStatementContext';

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Sapron | Dashboard';
  }, []);

  return (
    <Container>
      <DateProvider>
        <HostGridFinancesProvider>
          <HostFinancialStatementProvider>
            <Main />
          </HostFinancialStatementProvider>
        </HostGridFinancesProvider>
      </DateProvider>
    </Container>
  );
};

export default Dashboard;
