import { formatDateToShow, numberToCurrency } from '../../../utils/Formatter';
import { translateExpenseReason } from '../../../utils/Translator';
import { ExpenseReason } from '../../Expenses/types';
import request from '../../request';

import {
  IReports,
  IExpenses,
  IExpensesReport,
  IExpensesAPIReturns,
  IReportsPaginatedApiReturn,
  IExpensesPaginationAPIReturns,
} from './types';

export const getOwnerExpenses = async ({
  start_date,
  end_date,
  page,
  owner_id,
  property_id,
}: IExpenses): Promise<IReportsPaginatedApiReturn> => {
  const params = {
    approval_date_start: start_date,
    approval_date_end: end_date,
    owner_id,
    property_id,
    page_size: 99999999,
  };

  const { data } = await request.get(`/expenses/?page=${page}`, {
    params,
  });

  const dataTypedPaginated: IExpensesPaginationAPIReturns = data;
  const dataTyped = dataTypedPaginated.results;

  const dataFormatted: IReports[] = [];

  dataTyped.map((expense: IExpensesAPIReturns, index: number) => dataFormatted.push({
    id: expense?.id || index,
    property_code: expense?.property?.code || 'Não informado',
    approval_date: expense?.approval_date ? formatDateToShow(new Date(expense.approval_date))
      : 'Não informado',
    register_date: expense?.register_date ? formatDateToShow(new Date(expense.register_date))
      : 'Não informado',
    reference_date: expense?.approval_date ? formatDateToShow(new Date(expense.approval_date))
      : 'Não informado',
    reason: expense?.reason
      ? translateExpenseReason[expense.reason] as ExpenseReason
      : translateExpenseReason.Nothing as ExpenseReason,
    description: expense?.description || '',
    value: expense?.value ? numberToCurrency(Number(expense.value)) : numberToCurrency(0),
    expenses_files: expense?.statement_files?.length > 0 ? expense?.statement_files : [],
  }));

  const dataPaginatedWithFormatted: IReportsPaginatedApiReturn = {
    count: dataTypedPaginated.count,
    next: Number(dataTypedPaginated.next?.split('page=')?.[1]) || 1,
    previous: Number(dataTypedPaginated.previous?.split('page=')?.[1]) || 1,
    results: dataFormatted,
  };

  return dataPaginatedWithFormatted;
};

// --- Section download CSV ----

interface IdownloadCsvFile {
  params: IExpensesReport;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
}

// Guest List
export const downloadGuestListCsvFile = async ({
  params,
  setProgress,
}: IdownloadCsvFile) => {
  await request.get('/reservations/owner_report/', {
    params,
    responseType: 'blob',
    onDownloadProgress: (event) => {
      const progress: number = Math.round((event.loaded * 100) / event.total);
      setProgress(progress);
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `lista_de_hospedes_${params.start_date}_${params.end_date}.csv`);
    document.body.appendChild(link);
    link.click();
  });
};

// Expenses
export const downloadOwnerExpensesCSV = async ({
  params,
  setProgress,
}: IdownloadCsvFile) => {
  await request.get('/expense_report/', {
    params,
    responseType: 'blob',
    onDownloadProgress: (event) => {
      const progress: number = Math.round((event.loaded * 100) / event.total);
      setProgress(progress);
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `despesas_${params.start_date}_${params.end_date}.csv`);
    document.body.appendChild(link);
    link.click();
  });
};
