import { motion } from 'framer-motion';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import {
  Button,
  IconButton,
  PagesContainer,
  PaginationContainer,
} from './styles';

interface PaginationProps {
  pageIndex: number;
  totalCount: number;
  perPage: number;
  onPageChange: (pageIndex: number) => Promise<void> | void;
}

export function Pagination({
  pageIndex,
  perPage,
  totalCount,
  onPageChange,
}: PaginationProps): JSX.Element | null {
  const pages = Math.ceil(totalCount / perPage) || 1;
  if (pages <= 1) {
    return null;
  }

  const getPageNumbers = (): (number | '...')[] => {
    const pageNumbers: (number | '...')[] = [];
    const maxVisiblePages = 2;

    const addPageNumber = (num: number | '...') => {
      if (!pageNumbers.includes(num)) {
        pageNumbers.push(num);
      }
    };

    const addEllipsis = () => {
      pageNumbers.push('...');
    };

    if (pageIndex <= 1) {
      for (let i = 1; i <= Math.min(pages, maxVisiblePages + 1); i += 1) {
        addPageNumber(i);
      }
      if (pages > maxVisiblePages + 1) {
        addEllipsis();
        addPageNumber(pages);
      }
    } else if (pageIndex === 2) {
      for (let i = 1; i <= Math.min(pages, maxVisiblePages + 2); i += 1) {
        addPageNumber(i);
      }
      if (pages > maxVisiblePages + 1) {
        addEllipsis();
        addPageNumber(pages);
      }
    } else if (pageIndex === 3) {
      addPageNumber(1);
      if (pages > maxVisiblePages + 3) {
        addEllipsis();
      }
      for (let i = Math.max(3, pageIndex - 1); i
      <= Math.min(pages, pageIndex + maxVisiblePages); i += 1) {
        addPageNumber(i);
      }
      if (pages > pageIndex + maxVisiblePages) {
        addEllipsis();
        addPageNumber(pages);
      }
    } else if (pageIndex === pages - 3) {
      addPageNumber(1);
      if (pages > maxVisiblePages + 3) {
        addEllipsis();
      }
      for (let i = Math.max(3, pageIndex - 1); i
      <= Math.min(pages, pageIndex + maxVisiblePages); i += 1) {
        addPageNumber(i);
      }
      if (pages > pageIndex + maxVisiblePages) {
        addPageNumber(pages);
      }
    } else if (pageIndex === pages - 2) {
      addPageNumber(1);
      if (pages > maxVisiblePages + 3) {
        addEllipsis();
      }
      for (let i = Math.max(3, pageIndex - 1); i
      <= Math.min(pages, pageIndex + maxVisiblePages); i += 1) {
        addPageNumber(i);
      }
      if (pages > pageIndex + maxVisiblePages) {
        addEllipsis();
        addPageNumber(pages);
      }
    } else if (pageIndex === pages - 1) {
      addPageNumber(1);
      if (pages > maxVisiblePages + 3) {
        addEllipsis();
      }
      for (let i = pageIndex - 1; i
      <= Math.min(pages, pageIndex + maxVisiblePages); i += 1) {
        addPageNumber(i);
      }
      if (pages > pageIndex + maxVisiblePages) {
        addEllipsis();
        addPageNumber(pages);
      }
    } else {
      addPageNumber(1);
      if (pages > maxVisiblePages + 3) {
        addEllipsis();
      }
      for (let i = pageIndex; i
      <= Math.min(pages, pageIndex + maxVisiblePages); i += 1) {
        addPageNumber(i);
      }
      if (pages > pageIndex + maxVisiblePages) {
        addEllipsis();
        addPageNumber(pages);
      }
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <PaginationContainer>
      <motion.div
        whileTap={pageIndex <= 0 ? { scale: 1 } : { scale: 0.9 }}
      >
        <IconButton
          onClick={() => onPageChange(pageIndex - 1)}
          disabled={pageIndex <= 0}
        >
          <ChevronLeft />
        </IconButton>
      </motion.div>
      <PagesContainer>
        {pageNumbers.map((page, index) => (
          <motion.div
            key={index.toString()}
            whileTap={{ scale: 0.9 }}
          >
            {page === '...' ? (
              <Button key={index.toString()} isActive={false} disabled>...</Button>
            ) : (
              <Button
                key={index.toString()}
                onClick={() => onPageChange(page - 1)}
                isActive={page === pageIndex + 1}
              >
                {page}
              </Button>
            )}
          </motion.div>
        ))}
      </PagesContainer>
      <motion.div
        whileTap={pageIndex + 1 >= pages ? { scale: 1 } : { scale: 0.9 }}
      >
        <IconButton
          onClick={() => onPageChange(pageIndex + 1)}
          disabled={pageIndex + 1 >= pages}
        >
          <ChevronRight />
        </IconButton>
      </motion.div>
    </PaginationContainer>
  );
}
