import styled, { DefaultTheme, keyframes } from 'styled-components';
import { InfoOutlined } from '@mui/icons-material';
import { UrlLink } from '../../../../services/types';
import defaultImage from '../../../../assets/icons/generals/property-not-found.svg';
import { ReservationStatus } from '../../../../services/Reservation/types';

export type Position = {
  top: number,
  left: number,
  side: string,
  width: number,
  height: number,
  vertical: string

};

const getBackgroundStatus = (
  theme: DefaultTheme, status: ReservationStatus, blocked: boolean = false,
): string => {
  const color = {
    Concluded: theme.palette.green.main.hex(),
    Active: theme.palette.green.main.hex(),
    'In progress': theme.palette.green.main.hex(),
    'Pre-booking': theme.palette.yellow.main.hex(),
    Canceled: theme.palette.grey._550.hex(),
  };

  if (blocked) {
    return theme.palette.red._200.hex();
  }

  return color[status];
};

const FadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const FadeInOpacity = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const ModalUpdateReservationStatus = styled.div<{
  openModal: boolean;
}>`
  display: ${({ openModal }) => (openModal ? 'flex' : 'none')};
  flex-direction: column;
  width: auto;
  height: auto;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  z-index: 9999;
  border: 1px solid ${({ theme }) => theme.palette.grey._420.hex()};
  padding: 20px;
  justify-content: center;

  left: auto;
  right: 0;
  top: auto;
  bottom: 0;

  margin-bottom: 85px;
  margin-right: 58px;

  div {
    &.row {
      display: flex;
      align-items: center;
      width: 100%;
      & +.row {
        margin-top: 15px;
      }

    }
  }

  svg {
    color: ${({ theme }) => theme.palette.blue._400.hex()};
    margin-right: 10px;
  }
`;

export const RadioButton = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;

  border: 1px solid blue !important;
`;

export const ButtonConfirmUpdateStatus = styled.button`
  border: none;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div< { role: string } >`
  width: 100vw;
  max-width: 500px;
  height: ${({ role }) => (role !== 'Host' ? 'calc(100vh - 120px)' : '100vh')};
  overflow-y: auto;

  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-areas:
    'form nav'
    'buttons nav'
    ;
`;

export const IconImage = styled.img`
  margin-right: 10px;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
`;

export const FormContainer = styled.div`
  grid-area: 'form';
`;

export const FormContent = styled.form`
  width: 100%;
  height: auto;
  margin: 1rem;

  animation-delay: .4s;
  animation-name: ${FadeInOpacity};
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  line-height: 2.5rem;
  div {
    display: flex;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img{
    margin-right: 0.3rem;
  }
  p{
    font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
  }

  @media (max-width: 900px) {
    width: 135px;
    img{
      width: 15px;
    }
    p{
      font-size: 12px;
      font-weight: ${({ theme }) => theme.fonts.weights.regular};
    }
  }
`;

export const AddressContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  border-radius: 0.8rem;
  padding: 1rem;

  p, img{
    max-width: 50%;
  }

  @media (max-width: 900px) {
    p {
      max-width: 70%;
    }

    img {
      max-width: 30%;
    }
  }
`;

export const CheckContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  width: 100%;

  div{
    display: flex;
    justify-content: space-between;
    align-items: center;

    line-height: 3rem;
    border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  }
`;

export const NotesContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  padding: 1rem 0;
  margin: 1rem 0;
  line-height: 2rem;
  > p:first-child{
    line-height: 3rem;
  }
`;

export const Separator = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
`;

export const FinanceContainer = styled.div``;

export const FinanceContent = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  border-radius: 0.8rem;
  margin: 0.5rem 0;
  padding: 1rem;
  line-height: 2.5rem;

  > div > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};

  button{
    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.palette.blue._850.hex()};
    font-weight: bold;
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  grid-area: 'buttons';
  width: 100%;
  max-width: 500px;
  transition: 1s;
  height: 12vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background:  ${({ theme }) => theme.palette.white._400.hex()};
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 4rem;
  button, svg{
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 900px) {
      margin: 0 2px;
      width: auto;
      bottom: unset;
      right: unset;
      padding: 0;
    }
    @media (max-width: 320px) {
      margin: 0;
      width: auto;
      bottom: unset;
      right: unset;
      padding: 0;
    }
  }

  @media (max-width: 320px) {
    padding-right: 2.5rem;
  }
`;

export const SmallText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
`;

export const Contract = styled.button`
  width: 100px;
  padding: 5px;
  border-radius: 10px;
  border: none;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  background-color: ${({ theme }) => theme.palette.blue._400.hex()};
  font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
  font-weight: 600;
  cursor: pointer;
`;
export const LastSmallText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.smaller}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
  margin-left: 5px;
  `;

export const SmallTextNote = styled.textarea`
  border: none;
  padding-right: 2rem;
  width: 100%;
  resize: none;
  text-align: justify;
  background: transparent;
  &::-webkit-scrollbar {
    border-radius: 20px;
    height: 10px;
    width: 7px;
    border: 1px solid ${({ theme }) => theme.palette.white._600.hex()};
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.white._600.hex()};
    width: 5px;
    border-radius: 100px;
    transform: scale(0.3);
  }

  &::-webkit-scrollbar-thumb:hover{
    background: ${({ theme }) => theme.palette.orange._600.hex()};
  }

`;

export const SmallTextBold = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};

  &.notes {
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
  }
`;

export const MediumText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.regular}rem;
  font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
`;

export const MediumTextBold = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

export const ButtonStyled = styled.button<{ reservationStatus: ReservationStatus, blocked?: boolean }>`
  width: 100%;
  background: ${({ theme, reservationStatus, blocked }) => getBackgroundStatus(
    theme, reservationStatus, blocked,
  )};
  color: ${({ theme }) => theme.palette.white._400.hex()};
  border: 0;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
`;

export const PropertyImage = styled.div<{ backgroundImage?: UrlLink }>`
  display: flex;
  justify-content: flex-end;
  min-width: 52px;
  width: 52px;
  height: 44px;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : `url(${defaultImage})`)};
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  position: relative;

  &:after {
    content: '';
    left: 45px;
    top: -7px;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
    background-color: ${({ theme }) => theme.palette.green.main.hex()};
    border-radius: 50%;
    position: absolute;
    width: 15px;
    height: 15px;
  }
`;

export const ConfirmationButtonContainer = styled.div`
  display: grid;
  transition: 1s;
  text-align: center;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;

  animation-name: ${FadeIn};
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;
  transform: translateY(0);
`;

export const ConfirmationContainer = styled.div`
  animation-name: ${FadeIn};
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  opacity: 0;
  display: grid;
  text-align: center;
  width: 100%;
  grid-template-columns: 1fr;
  gap: .5rem;
  align-items: center;
  justify-content: center;
`;

export const Space = styled.div`
  height: 20px;
  `;

export const BarLabel = styled.div`
height: 30px;
width: 100%;
display: flex;
align-items: center;
font-size: 20px !important;
font-family: ${({ theme }) => theme.fonts.familys._1}, ${({ theme }) => theme.fonts.familys._2};
font-weight: 700;
border-bottom: 2px solid ${({ theme }) => theme.palette.grey._420.hex()};
`;

export const CopyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 1.5rem;
`;

export const CopyButton = styled.button`
  background: linear-gradient(
    360deg,
    ${({ theme }) => theme.palette.blue._800.hex()} 0%,
    ${({ theme }) => theme.palette.blue._850.hex()} 100%
  );
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);
      box-shadow: 1px 3px 12px 2px rgba(0,0,0,0.35);


  img{
    margin-right: 0.8rem;
  }

  &:hover{
    opacity: 0.85;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const InfoIcon = styled(InfoOutlined)`
  color: ${({ theme }) => theme.palette.blue._850.hex()};
  cursor: pointer;
  transition: all 400ms;

  &:hover{
    color: ${({ theme }) => theme.palette.blue._400.hex()};
  }
`;

export const IconContainer = styled.div`
  display: flex;
`;
