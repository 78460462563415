import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  overflow-x: scroll;
  border-radius: 10px 10px 20px 20px;
  
  @media (max-width: 1000px) {
    background: white;
  }
  
  &::-webkit-scrollbar {
    width: 0;
    height: 3px;
  }
`;

export const StyledTR = styled(motion.tr)<{
  lenght: number;
}>`
  width: 100%;
  gap: 5px;
  height: 100%;
  display: grid;
  background: white;

  grid-template-columns: ${({ lenght }) => css`repeat(${lenght}, 1fr)`};
  @media (max-width: 768px) {
    grid-template-columns: ${({ lenght }) => css`repeat(${lenght}, 150px)`};
  }
`;

export const NotFound = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: #0d4bd0;
  padding-left: 10px;
`;

export const StyledTH = styled(motion.th)`
  width: 100%;
  z-index: 2;
  padding: 7px;
  color: #f9f9f9;
  font-weight: 500;
  border-radius: 5px;
  background-color: #0d4bd0;
`;

export const StyledTD = styled(motion.td)`
  margin: 10px 0;
`;

export const StyledTBody = styled(motion.tbody)`
`;

export const Styledtable = styled(motion.table)`
  gap: 1px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  text-align: center;
`;

export const StyledTHead = styled(motion.thead)`
  width: 100%;
`;
