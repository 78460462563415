import React from 'react';

// import { Checkbox } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import Tooltip from '../../../../Tooltip';

import { Value } from '../styles';
import { BlockCalendarButton, ImmobileContainer } from './styles';
import CalendarBlockIcon from '../../../../../assets/icons/multicalendar/create-block.svg';
import { useCreateBlockPropertyHostPageData } from '../../../../../context/CreateBlockPropertyInHostPage/CreateBlockPropertyInHostpage';
import { useUser } from '../../../../../context/UserContext';

interface ImmobileProps {
  dataCy: string;
  immobile: string;
  id: number;
}

const Immobile = ({ dataCy, immobile, id }: ImmobileProps) => {
  const TooltipCalendarMessage = `Realizar pedido de bloqueio no ${immobile}`;
  const {
    handleOpenModal,
    handleSelectProperty,
    handleBlockPropertyCode,
  } = useCreateBlockPropertyHostPageData();

  const onOpenModal = () => {
    handleOpenModal(true);
    handleSelectProperty(id);
    handleBlockPropertyCode(immobile);
  };

  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  return (
    <ImmobileContainer>
      {/* <Checkbox
        sx={{
          color: '#1D9BE1',
          '&.Mui-checked': {
            color: '#3CC3EE',
          },
        }}
      /> */}
      <Value data-cy={dataCy}>{immobile}</Value>
      <div>
        <Tooltip text="Ir para imóvel">
          <Link
            to={`/buscadepropriedades/${id}`}
            state={{
              title: 'Imóveis',
              path: '/buscadepropriedades',
            }}
          >
            <OpenInNew style={{ color: '#1D9BE1' }} />
          </Link>
        </Tooltip>
      </div>
      {roles.includes('Host') && (
        <BlockCalendarButton type="button" onClick={onOpenModal}>
          <Tooltip text={TooltipCalendarMessage}>
            <img src={CalendarBlockIcon} alt="calendário de bloqueio" />
          </Tooltip>
        </BlockCalendarButton>
      )}
    </ImmobileContainer>
  );
};

export default Immobile;
