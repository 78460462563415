/* eslint-disable max-len */
import moment from 'moment';
import request from '../request';

import {
  SummaryProps,
  ChecklistProgressProps,
  CleaningReportProps,
  FinancialCloseHostParams,
  FinancialCloseHostProps,
  FinancialCloseHostResponse,
  ProperPayHostDashboard,
  ParamsHostFinancialStatementSpredsheet,
  ResponseHostFinancialStatementSpredsheet,
} from './types';

import { formatFinancialCloseHost } from '../../utils/DashboardFormatter';

import {
  getMonthlyFormattedFinancialData,
  sumExecuteds,
  sumPaids,
  sumOthersReceipts,
} from './utils';

export const getChecklistProgress = async (): Promise<ChecklistProgressProps> => {
  const { data } = await request.get('/host_controller/dash/checklist/');
  return data;
};

export const getSummary = async (): Promise<SummaryProps> => {
  const { data } = await request.get('/host_controller/dash/summary/');
  return data;
};

export const getCleaningsReport = async (params: CleaningReportProps) => {
  await request
    .get('/cleaning_report/', { params, responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `limpezas_${params.year_month}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};

export const generateHostFinancialStatement = async (params: ParamsHostFinancialStatementSpredsheet): Promise<ResponseHostFinancialStatementSpredsheet> => {
  const { data } = await request.post('/proper_pay/host/annual-results/export/', {
    ...params,
  });

  return data;
};

export const getFinancialClosingHost = async (props?: FinancialCloseHostProps) => {
  const year = props?.year || moment(new Date()).format('YYYY');

  function selectParams() {
    if (props?.is2023) {
      return {
        start_date: `${year}-4`,
        end_date: `${year}-12`,
      };
    }
    if (props?.month) {
      return {
        start_date: `${year}-${props?.month.start}`,
        end_date: `${year}-${props?.month.end}`,
      };
    }
    return {
      start_date: `${year}-01`,
      end_date: `${year}-12`,
    };
  }

  let allParams: FinancialCloseHostParams = selectParams();

  if (props?.host_id) { allParams = { ...allParams, host_id: props.host_id }; }
  if (props?.property_id) { allParams = { ...allParams, property_id: props.property_id }; }

  const { data } = await request.get<FinancialCloseHostResponse>('/financial_closing_host/',
    {
      params: {
        ...allParams,
      },
    });
  const financialData = formatFinancialCloseHost(data, year.toString());

  return financialData;
};

export const getProperPayHostDashboard = async (
  year: number,
  hostId?: number,
  propertyId?: string,
): Promise<ProperPayHostDashboard> => {
  const { data } = await request.get<ProperPayHostDashboard>('/proper_pay/host/annual-results', {
    params: {
      year,
      host_id: hostId,
      property_id: propertyId,
    },
  });

  const months = Array.from({ length: 12 }, (_, i) => `${i + 1}`);

  const executedDailys: any = {
    total: sumExecuteds(Number(data?.executed_dailys_to_receive?.total || 0),
      Number(data?.executed_dailys_to_receive_following_month?.total || 0)),
  };

  const executedCleaning: any = {
    total: sumExecuteds(Number(data?.executed_cleaning_to_receive?.total || 0),
      Number(data?.executed_cleaning_to_receive_following_month?.total || 0)),
  };

  const paidDailys: any = {
    total: sumPaids(Number(data?.paid_dailys_revenue?.total || 0),
      Number(data?.paid_dailys_revenue_prior_month?.total || 0)),
  };

  const paidCleaning: any = {
    total: sumPaids(Number(data?.paid_cleaning_revenue?.total || 0),
      Number(data?.paid_cleaning_revenue_prior_month?.total || 0)),
  };

  const othersReceipts: any = {
    total: sumOthersReceipts(Number(data?.others_receipts_refunds_paid?.total || 0),
      Number(data?.others_receipts_manual_fit?.total || 0)),
  };

  const numberCleanings: any = {
    total: sumExecuteds(Number(data?.number_of_cleanings?.total || 0),
      Number(data?.number_of_cleanings_next_month?.total || 0)),
  };

  const numberDailys: any = {
    total: sumExecuteds(Number(data?.number_of_dailys?.total || 0),
      Number(data?.number_of_dailys_next_month?.total || 0)),
  };

  const valuesMocked: any = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    total: 0,
  };

  const franchiseFee: any = data?.franchise_fee || valuesMocked;
  const cashOut: any = data?.cash_out || valuesMocked;

  const results: any = {
    total: 0,
  };

  months.forEach((monthRef) => {
    executedDailys[monthRef] = sumExecuteds(Number(data?.executed_dailys_to_receive?.[monthRef] || 0),
      Number(data?.executed_dailys_to_receive_following_month?.[monthRef] || 0));

    executedCleaning[monthRef] = sumExecuteds(Number(data?.executed_cleaning_to_receive?.[monthRef] || 0),
      Number(data?.executed_cleaning_to_receive_following_month?.[monthRef] || 0));

    paidDailys[monthRef] = sumPaids(Number(data?.paid_dailys_revenue?.[monthRef] || 0),
      Number(data?.paid_dailys_revenue_prior_month?.[monthRef] || 0));

    paidCleaning[monthRef] = sumPaids(Number(data?.paid_cleaning_revenue?.[monthRef] || 0),
      Number(data?.paid_cleaning_revenue_prior_month?.[monthRef] || 0));

    othersReceipts[monthRef] = sumOthersReceipts(Number(data?.others_receipts_refunds_paid?.[monthRef] || 0),
      Number(data?.others_receipts_manual_fit?.[monthRef] || 0));

    numberCleanings[monthRef] = sumPaids(Number(data?.number_of_cleanings?.[monthRef] || 0),
      Number(data?.number_of_cleanings_next_month?.[monthRef] || 0));

    numberDailys[monthRef] = sumPaids(Number(data?.number_of_dailys?.[monthRef] || 0),
      Number(data?.number_of_dailys_next_month?.[monthRef] || 0));

    let convertFranchiseFee = franchiseFee[monthRef];

    if (convertFranchiseFee < 0) convertFranchiseFee = (franchiseFee[monthRef] * -1).toFixed(2);

    // [(Diárias Pagas - Taxa de franquia) + Limpezas Pagas + outros recebimentos) - (Saídas)]
    // Observação: o campo de Saídas(cash_out)  está sendo somado no cálculo abaixo pois este é retornado da api com sinal negativo
    results[monthRef] = (paidDailys[monthRef] - Number(convertFranchiseFee)) + paidCleaning[monthRef] + othersReceipts[monthRef] + cashOut[monthRef];
  });

  results.total = Object.keys(results)
  .filter(key => key !== "total")
  .reduce((sum, key) => sum + results[key], 0);

  const properyPayPropertyResponse: ProperPayHostDashboard = {
    executed_dailys: getMonthlyFormattedFinancialData(executedDailys),
    executed_dailys_to_receive: getMonthlyFormattedFinancialData(data?.executed_dailys_to_receive),
    executed_dailys_to_receive_following_month: getMonthlyFormattedFinancialData(data?.executed_dailys_to_receive_following_month),
    executed_cleaning: getMonthlyFormattedFinancialData(executedCleaning),
    executed_cleaning_to_receive: getMonthlyFormattedFinancialData(data?.executed_cleaning_to_receive),
    executed_cleaning_to_receive_following_month: getMonthlyFormattedFinancialData(data?.executed_cleaning_to_receive_following_month),
    paid_dailys: getMonthlyFormattedFinancialData(paidDailys),
    paid_dailys_revenue: getMonthlyFormattedFinancialData(data?.paid_dailys_revenue),
    paid_dailys_revenue_prior_month: getMonthlyFormattedFinancialData(data?.paid_dailys_revenue_prior_month),
    paid_cleaning: getMonthlyFormattedFinancialData(paidCleaning),
    paid_cleaning_revenue: getMonthlyFormattedFinancialData(data?.paid_cleaning_revenue),
    paid_cleaning_revenue_prior_month: getMonthlyFormattedFinancialData(data?.paid_cleaning_revenue_prior_month),
    others_receipts: getMonthlyFormattedFinancialData(othersReceipts),
    others_receipts_refunds_paid: getMonthlyFormattedFinancialData(data?.others_receipts_refunds_paid),
    others_receipts_manual_fit: getMonthlyFormattedFinancialData(data?.others_receipts_manual_fit),
    results: getMonthlyFormattedFinancialData(results),
    franchise_fee: getMonthlyFormattedFinancialData(franchiseFee),
    transfer: getMonthlyFormattedFinancialData(data?.transfer),
    balance: getMonthlyFormattedFinancialData(data?.balance),
    cash_out: getMonthlyFormattedFinancialData(cashOut),
    number_of_cleanings: numberCleanings,
    number_of_cleanings_to_receive: data.number_of_cleanings,
    number_of_cleanings_next_month: data.number_of_cleanings_next_month,
    number_of_dailys: numberDailys,
    number_of_dailys_to_receive: data.number_of_dailys,
    number_of_dailys_next_month: data.number_of_dailys_next_month,
  };

  return properyPayPropertyResponse;
};
