import React, { FC } from 'react';

import {
  ContainerBox, Text, ButtonContainer,
} from './styles';

import ModalTemplate from '../ModalTemplate';
import FormButton from '../../../FormButton';
import {
  getConciliations, getPaymentAirbnbConciliation, getReservationConciliation, postConciliation,
} from '../../../../services/Conciliation/request';
import { useConciliation } from '../../../../context/ConciliationPage/ConciliationContext';
import { ErrorMessage } from '../../../../utils/Messages';
import { useToast } from '../../../../context/ToastContext';
import { numberToCurrency } from '../../../../utils/Formatter';

interface Props {
  open?: boolean;
  setOpen: (a: boolean) => void;
  type: string;
  value: string;
  guestReservation: string;
  dataCy?: string;
}

const ModalConfirm: FC<Props> = ({
  open = false,
  guestReservation,
  type,
  value,
  setOpen,
}) => {
  const {
    reservation,
    paymentMove,
    removePayment,
    removeReservation,
    setListReservation,
    setListPayments,
    setListConciliations,
  } = useConciliation();
  const toast = useToast();

  async function handleConciliation() {
    try {
      await postConciliation({
        airbnb_payment: paymentMove.id,
        reservation: parseInt(reservation.id, 10),
        conciliation: type === 'Conciliar',
      });
      setOpen(false);
      toast.success(type === 'Conciliar' ? 'Conciliação feita com sucesso!' : 'Desconciliação feita com sucesso!');
      removeReservation();
      removePayment();
      if (type === 'Conciliar') {
        const responseReservations = await getReservationConciliation();
        setListReservation(responseReservations);
        const responsePayments = await getPaymentAirbnbConciliation();
        setListPayments(responsePayments);
      } else {
        const response = await getConciliations();
        setListConciliations(response);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
      }
    }
  }

  return (
    <ModalTemplate
      open={open}
      handleClose={setOpen}
    >
      <ContainerBox>
        <Text>
          Você realmente deseja
          {' '}
          <strong className="highlight">
            {type}
          </strong>
          {' '}
          o pagamento de
          {' '}
          <strong>
            {numberToCurrency(parseFloat(value))}
          </strong>
          {' '}
          a reserva de
          {' '}
          <strong>
            {guestReservation}
          </strong>
          {' '}
          ?
        </Text>
        <ButtonContainer>
          <FormButton variant="outlined" onClick={() => setOpen(false)}>Cancelar</FormButton>
          <FormButton type="button" onClick={() => handleConciliation()}>Salvar</FormButton>
        </ButtonContainer>
      </ContainerBox>
    </ModalTemplate>

  );
};

export default ModalConfirm;
