import { ReportProblemOutlined } from '@mui/icons-material';
import styled, { keyframes } from 'styled-components';

const mobile = '1000px';

const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
    }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: #00000050;
  backdrop-filter: blur(1px);

  justify-content: center;
  align-items: center;
  transition: 0.4s;
  z-index: 999;

  @media (max-width: ${mobile}) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 2rem;
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const Container = styled.div`
  position: absolute;
  display: flex;

  width: 100%;
  max-width: 950px;
  height: calc(100% - 50px);
  max-height: 800px;

  z-index: 3;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 15px;
  padding: 2rem;

  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  user-select: none;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${mobile}) {
    border-radius: 0px;
    overflow-y: scroll;

  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled.div`
  width: 20px;
  height: 20px;

  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    filter: invert(1);
    transition: 0.5s;
    &:hover {
      opacity: 1;
      transform: rotate(90deg);
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  div {
    display: flex;
    
    img {
      width: 45px;
    }
  
    h1 {
      font-size: 2.3rem;
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts.familys._1};
      margin-left: 1rem;
    }
  }

  @media (max-width: ${mobile}) {
    div {
      h1 {
        font-size: 1.5rem;
      }
      img {
        width: 30px;
      }
    }
  }
`;

export const FormContainer = styled.form`
  width: 100%;
  height: 100%;
`;

export const FormContent = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  .messageError {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .autocomplete-host {
    margin-top: 0.7rem;
    text-align: start;
  }

  .labelClass {
    font-weight: 600 !important;
    font-family: Inter !important;
  }
  @media (max-width: ${mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const LeftSideContainer = styled.div`
  width: 100%;
  border-right: 1px solid #00000024;
  padding-right: 1rem;

  div {
    label {
      display: flex;
    }
  }
  .styledTextFieldContainer {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 1rem;
    .styledTextFieldLabel {
      text-align: left;
    }

    .styledMoney {
      width: 200px;
    }
  }

  .simpleSelectContainer {
    margin-top: 1rem;
    .simpleSelectLabel {
      text-align: left;
    }
  }

  .datePickerContainer {
    margin-top: 1rem;
    text-align: left;
    justify-content: left;

    .datePickerLabel {
      text-align: left;
    }

    .customStyledTextField {
      width: 200px;
    }
  }

  @media (max-width: ${mobile}) {
    border-right: 0;
    padding: 0;
  }
`;

export const RightSideContainer = styled.div`
  padding-left: 1rem;
  text-align: left;
  justify-content: left;

  @media (max-width: ${mobile}) {
    border-right: 0;
    padding: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  height: 100%;
  max-height: 150px;
  margin: 1rem 0;
`;

export const PhotoContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: ${mobile}) {
    margin-top: 2rem;
  }
`;

export const NoteContainer = styled.div`
  margin-top: 1rem;
  display: flex;

  .row-description {
    width: 100%;
  }

`;

export const RadiosButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1.5rem 0 1rem 0;
`;

export const ErrorIcon = styled(ReportProblemOutlined)`
  && {
    margin-right: 3px;
    height: 16px;
  }
`;

export const FieldCodeProperty = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.2rem 0;
  border-radius: 3px;

  h1 {
    background: ${({ theme }) => theme.palette.grey._300.hex()};
    letter-spacing: 0.1rem;
    padding: 0.3rem;
  }

  h1, p {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
  }
`;

export const PendingReasonContainer = styled(FieldCodeProperty)`
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;

  p {
    margin-left: 0.3rem;
  }
`;

export const ContainerSupplier = styled.div`
  margin-top: 1rem;
`;

export const ValueContainer = styled.div`
  margin-top: 1rem;
`;
