import React, { useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment, { Moment } from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';

import { UseFormik } from '../../utils/Formik/types';
import { ContainerLabel, Label, StarSymbol } from './styles';

interface DatePickerProps {
  formik: UseFormik<any>;
  id1: string;
  id2: string;
  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  orientation?: 'horizontal' | 'vertical';
  readOnly?: boolean,
  inputIconPosition?: 'before' | 'after';
  anchorDirection?: 'left' | 'right';
  showDefaultInputIcon?: boolean,
  label?: string;
  required?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const NewDatePickerRange: React.FC<DatePickerProps> = ({
  formik,
  id1 = 'start_date_id',
  id2 = 'end_date_id',
  startDatePlaceholderText = 'Check-in',
  endDatePlaceholderText = 'Check-out',
  orientation = 'horizontal',
  readOnly = true,
  inputIconPosition = 'after',
  anchorDirection = 'left',
  showDefaultInputIcon = true,
  label,
  required = false,
  disablePast = false,
  disableFuture = false,
}) => {
  const [inputFocused, setInputFocused] = useState<FocusedInputShape | null>(null);

  const handleDatesChange = ({ startDate, endDate }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    formik?.setFieldTouched(
      id1, true, false,
    );
    formik?.setFieldValue(id1, startDate);

    formik?.setFieldTouched(
      id2, true, false,
    );

    formik?.setFieldValue(id2, endDate);
  };

  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    setInputFocused(focusedInput);
  };

  const firstSelectedDate = formik.values?.[id1] || formik.values?.[id2];

  const isOutsideRange = (selectedDay: Moment) => {
    if (disablePast) {
      return moment().startOf('day').isBefore(selectedDay);
    }
    if (disableFuture) {
      return moment().startOf('day').isAfter(selectedDay);
    }
    return false;
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <ContainerLabel>
        {required && <StarSymbol>*</StarSymbol>}
        <Label htmlFor={id1}>
          {label}
        </Label>
      </ContainerLabel>
      <div className="datepicker_container">
        <DateRangePicker
          startDate={formik.values?.[id1] || null}
          startDateId={id1}
          endDate={formik.values?.[id2] || null}
          endDateId={id2}
          onDatesChange={handleDatesChange}
          focusedInput={inputFocused}
          onFocusChange={handleFocusChange}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={(day) => isOutsideRange(day)}
          startDatePlaceholderText={startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
          orientation={orientation}
          numberOfMonths={2}
          navPosition="navPositionBottom"
          monthFormat="MMMM YYYY"
          anchorDirection={anchorDirection}
          hideKeyboardShortcutsPanel
          weekDayFormat="ddd"
          initialVisibleMonth={() => (firstSelectedDate || moment())}
          showDefaultInputIcon={showDefaultInputIcon}
          inputIconPosition={inputIconPosition}
          readOnly={readOnly}
          daySize={40}
          small
          showClearDates
        />
      </div>
    </div>
  );
};

export default NewDatePickerRange;
