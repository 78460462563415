import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  font-weight: 500;
  user-select: none;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.7);
  font-family: ${({ theme }) => theme.fonts.familys._6};
`;

export const TitleContainer = styled(motion.div)<{
  isOpen: boolean;
}>`
  gap: 10px;
  padding: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: row;

  border-bottom: 1px solid transparent;

  ${({ isOpen }) => isOpen && css`
    border-bottom: 1px solid rgba(0,0,0,0.3);
  `}
`;

export const ListItem = styled(motion.li)`
  gap: 20px;
  padding: 0px;
  display: flex;
  background: #fff;
  flex-direction: column;
  border: 1px solid #c4c4c4;
`;
