import {
  CentralizedModalWrapper,
  CloseButton,
  ModalHeader,
  Overlay,
} from './styles';

import { Text } from '../../../Text';

interface ModalProps {
  children?: any
  onClose: () => void;
}

const CentralizedModal: React.FC<ModalProps> = ({ children, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Overlay onClick={handleClose} />
      <CentralizedModalWrapper>
        <ModalHeader>
          <Text $color="#151B26" $fontSize="18">
            O que você deseja fazer ?
          </Text>
          <CloseButton onClick={handleClose}>&times;</CloseButton>
        </ModalHeader>
        {children}
      </CentralizedModalWrapper>
    </>
  );
};

export default CentralizedModal;
