import styled from 'styled-components';

export const B2BContainer = styled.div`
  background: ${({ theme }) => theme.palette.white._300.hex()};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  padding: 32px;
`;

export const B2BCard = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  `;

export const B2BHeader = styled.header`
  display: flex;
  border-bottom: 1px solid #C2C8F1;
  padding: 32px 16px 16px;
`;

export const B2BTitle = styled.h1`
  font-size: 24px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: #001840;
`;

export const B2BForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  height: 90%;

  @media (max-width: 900px) {
    width: 100%;
    height: 82%;
  }

  .labelClass {
    font-weight: ${({ theme }) => theme.fonts.weights.normal} !important;
    font-family: ${({ theme }) => theme.fonts.familys._6};
  }
`;

export const B2BButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px;
  background: rgba(0, 24, 64, 0.05);
  border-radius: 0 0 10px 10px;
  height: 20%;

  @media (max-width: 900px) {
    height: 25%;
    align-items: center;
  }

  > div {
    width: 30%;
    justify-content: flex-end;
    gap: 0.5rem;
    display: flex;
    justify-items: flex-end;
    align-items: center;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
`;
