import styled, { keyframes } from 'styled-components';

export const animationTime = 1.5;

export const shimmerAnimation = keyframes`
  0% {
      background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
      background-size: 400% 400%;
      background-position: 0% 0%;
    }
  50% {
    background-position: -135% 0%;
  }
  100%{
    background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
    background-size: 400% 400%;
    background-position: 0% 0%;
  }
`;

const fadeIn = keyframes`
  from { 
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  box-shadow: rgba(0, 0, 0, 0.096) 0px 25px 20px -20px;

  opacity: 0;
  animation: ${fadeIn} 0.5s 0.5s ease forwards;

  display: flex;
  flex-direction: column;
`;

export const SideContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: normal;
    color: red;
  }
`;

export const Header = styled.div`
  h1 {
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: normal;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SubInfo = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.2rem;
    font-weight: normal;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 25px;
    }
  }

  img {
    width: 15px;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: flex-end;

  img {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`;

export const ImageShimmer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: red;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  animation: ${shimmerAnimation} ${animationTime}s ease infinite;
`;

export const Days = styled.div``;
export const RightContainer = styled.div``;
