import styled, { keyframes, css } from 'styled-components';

const fadeIn = keyframes`
  from { 
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const shakeAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div<{
  animationIndex: number;
}>`
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  border: 1px solid transparent;
  transition: 0.4s;
  
  &:hover {
    border: 1px solid #d4d4d4;
  }

  font-family: ${({ theme }) => theme.fonts.familys._1};
  box-shadow: rgba(0, 0, 0, 0.096) 0px 25px 20px -20px;
  opacity: 0;
  animation: ${fadeIn} 0.5s ${({ animationIndex }) => animationIndex * 0.1}s
    ease forwards;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftSide = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    span {
      margin-top: -5px;
      display: flex;
      justify-content: baseline;
      flex-direction: row;
      align-items: center;
      font-size: 1.4rem;
      font-family: ${({ theme }) => theme.fonts.familys._1};
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
  }

  img {
    transition: 0.3s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
    width: 12px;
    cursor: pointer;
  }
`;
export const Title = styled.div`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: bold;
  cursor: pointer;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;

  img {
    width: 20px;
    transition: 0.4s;

    &:hover {
      animation: ${shakeAnimation} 0.8s ease;
    }
  }
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  text-align: left;
  justify-content: flex-start;
  gap: 1rem;

  h1{
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  img {
    display: flex;
    width: 100%;
    max-width: 100px;
    cursor: pointer;
    transition: 0.4s;
    object-fit: cover;
  }
`;

function formDelay() {
  let styles = '';
  for (let i = 1; i <= 10; i += 1) {
    styles += `
      @keyframes fadeIna {
        from {
          opacity: 0;
          transform: translateY(10px);
        }
        to {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      div.form-${i} {
        opacity: 0;
        animation: fadeIna 0.3s ${i * 0.19}s ease forwards;
      }
    `;
  }
  return css`
    ${styles}
  `;
}

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${formDelay()}
`;

export const Body = styled.div<{
  isOpen: boolean;
}>`
  animation: ${({ isOpen }) => !isOpen && `${css`$\{hideBody}`} 0.5s ease forwards`};
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  margin: 0px;
  overflow: hidden;
`;
