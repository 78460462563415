import request from '../request';
import { IAccountSimpleHostsParams, IAccountSimpleHostsResponse } from './types';

export const getAccountSimpleHosts = async (params?: IAccountSimpleHostsParams) => {
  if (params) {
    const { data } = await request.get<IAccountSimpleHostsResponse[]>('/account/host_simple/', {
      params: {
        ...params,
      },
    });
    return data;
  }

  const { data } = await request.get<IAccountSimpleHostsResponse[]>('/account/host_simple/');
  return data;
};
