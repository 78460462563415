import { FC, useEffect, useState } from 'react';

import moment from 'moment';
import newExpense from '../../../assets/icons/expense/newExpense.svg';
import NoPhoto from '../../../assets/icons/expense/photoSketch.svg';
import iconClose from '../../../assets/icons/generals/iconClose.svg';

import { ExpenseResponseProps, ImageProps } from '../../../services/Expenses/types';
import { translateExpenseReason, translateExpenseStatus } from '../../../utils/Translator';

import { useUser } from '../../../context/UserContext';
import { useExpense } from '../../../hooks/ExpenseHook/useExpense';
import useKeys from '../../../hooks/GlobalHook/useKeys';
import { useStarRating } from '../../../hooks/StarRatingHook/useStarRating';

import StarRating from '../../StarRating';
import ModalTemplate from './ModalTemplate';
import FilesList from '../Header/NewExpensesModal/UploadButtons/CardsList/Item/CardContent/FilesList/FilesList';

import {
  AuthorizationContainer,
  CloseButton,
  Container,
  Content,
  ContentContainer,
  DescriptionContainer,
  FieldCodeProperty,
  FieldStatusContainer,
  FilesContainer,
  FormsContainer,
  HeaderContainer,
  LeftSideContainer,
  MaintenanceContainer,
  PhotosContainer,
  ReceiptContainer,
  RightSideContainer,
} from './styles';

const formatedDateToShow = (date?: string) => {
  if (date && date !== 'Pendente') {
    const dateFormated = moment(date).format('DD/MM/YYYY');
    return dateFormated;
  }
  return '-';
};

const ExpensesInformationModal: FC = () => {
  const {
    expenseData,
    handleOpenExpensesInformationModal,
    openExpensesInformationModal,
  } = useExpense();
  const { handleChangeRating } = useStarRating();
  const esc = useKeys('Escape');
  if (esc) handleOpenExpensesInformationModal(false);
  const [dataFormatted,
    setdataFormatted] = useState<ExpenseResponseProps>({} as ExpenseResponseProps);
  const { userInformation } = useUser();

  useEffect(() => {
    setdataFormatted({
      ...expenseData,
      description:
        expenseData.description === ''
          ? 'Não há descrição'
          : expenseData.description || 'Não há descrição',
    });
  }, [expenseData]);
  const statementImage = expenseData?.statement_image as ImageProps;
  const maintenanceImage = expenseData?.maintenance_image as ImageProps;

  useEffect(() => {
    handleChangeRating(Number(dataFormatted?.supplier_rating || 0));
  }, [dataFormatted]);

  return (
    <ModalTemplate
      open={openExpensesInformationModal}
      handleClose={() => handleOpenExpensesInformationModal(false)}
    >
      <Container data-cy="expenses-information-modal">
        <Content>
          <CloseButton
            onClick={() => handleOpenExpensesInformationModal(false)}
          >
            <img src={iconClose} alt="close" />
          </CloseButton>
          {userInformation?.main_role !== 'Host' && (
            <HeaderContainer>
              <img src={newExpense} alt="newExpenseIcon" />
              <h1>Informações da Despesa</h1>
            </HeaderContainer>
          )}
          {userInformation?.main_role === 'Host' && (
            <HeaderContainer>
              <img src={newExpense} alt="newExpenseIcon" />
              <h1>Informações do Reembolso</h1>
            </HeaderContainer>
          )}
          <ContentContainer>
            <form>
              <FormsContainer>
                <LeftSideContainer>
                  <div className="row">
                    <FieldCodeProperty>
                      <h1>
                        {dataFormatted.property?.code
                          ? 'Código Imóvel'
                          : 'Não pertence a um imóvel'}
                      </h1>
                      <p data-cy="txt-information-modal-property-code">
                        {dataFormatted.property?.code || ''}
                      </p>
                    </FieldCodeProperty>
                  </div>
                  <div className="row">
                    <FieldCodeProperty>
                      <h1>Nome do fornecedor</h1>
                      <p data-cy="txt-information-modal-supplier">
                        {dataFormatted.supplier || ''}
                      </p>
                    </FieldCodeProperty>
                    <FieldCodeProperty>
                      <h1>Telefone do fornecedor</h1>
                      <p data-cy="txt-information-modal-phone-supplier">
                        {dataFormatted.supplier_phonenumber
                          || '+00 (00) 00000-0000'}
                      </p>
                    </FieldCodeProperty>
                    <FieldCodeProperty>
                      <h1>Avaliação do Fornecedor</h1>
                      <StarRating disableChangeStar />
                    </FieldCodeProperty>
                  </div>
                  <div className="row">
                    <FieldCodeProperty>
                      <h1>Valor</h1>
                      <p data-cy="txt-information-modal-value">
                        {`R$  ${dataFormatted.value || ''}`}
                      </p>
                    </FieldCodeProperty>
                  </div>
                  <div className="row">
                    <FieldCodeProperty>
                      <h1>Motivo</h1>
                      <p data-cy="txt-information-modal-reason">
                        {dataFormatted.reason
                          ? translateExpenseReason[dataFormatted.reason]
                          : 'Motivo não selecionado'}
                      </p>
                    </FieldCodeProperty>
                  </div>
                  {userInformation?.main_role !== 'Host' && (
                    <div className="row">
                      <FieldCodeProperty>
                        <h1>Data da despesa</h1>
                        <p data-cy="txt-information-modal-expense_date">
                          {formatedDateToShow(dataFormatted.expense_date || '') || ''}
                        </p>
                      </FieldCodeProperty>
                    </div>
                  )}
                  {userInformation?.main_role === 'Host' && (
                    <div className="row">
                      <FieldCodeProperty>
                        <h1>Data do reembolso</h1>
                        <p data-cy="txt-information-modal-expense_date">
                          {formatedDateToShow(dataFormatted.expense_date || '') || ''}
                        </p>
                      </FieldCodeProperty>
                    </div>
                  )}
                  <div className="row">
                    <FieldCodeProperty>
                      <h1>Status</h1>
                      <p data-cy="txt-information-modal-expense_status">
                        {dataFormatted.expense_status
                          ? translateExpenseStatus[dataFormatted.expense_status]
                          : 'Status não selecionado'}
                      </p>
                    </FieldCodeProperty>
                  </div>
                  <div className="row">
                    <FieldCodeProperty>
                      <h1>Registrado por</h1>
                      <p data-cy="txt-information-modal-registered_by">
                        {dataFormatted.registered_by?.trading_name === ''
                          ? `${dataFormatted.registered_by.first_name}  ${dataFormatted.registered_by.last_name}`
                          : dataFormatted.registered_by?.trading_name || ''}
                      </p>
                    </FieldCodeProperty>
                  </div>
                  <div className="row">
                    <FieldCodeProperty>
                      <h1>Responsável</h1>
                      <p data-cy="txt-information-modal-responsible_user">
                        {dataFormatted.responsible_user?.trading_name === ''
                          ? `${dataFormatted.responsible_user?.first_name}  ${dataFormatted.responsible_user?.last_name}`
                          : dataFormatted.responsible_user?.trading_name || ''}
                      </p>
                    </FieldCodeProperty>
                  </div>
                </LeftSideContainer>
                <RightSideContainer>
                  <PhotosContainer>
                    {statementImage !== undefined
                      && statementImage !== null && (
                        <ReceiptContainer
                          onClick={() => window.open(statementImage?.url, '_blank')}
                        >
                          <h1>Foto do recibo</h1>
                          <img
                            data-cy="txt-information-modal-statementImage"
                            src={statementImage.url || NoPhoto}
                            alt="teste"
                          />
                        </ReceiptContainer>
                    )}
                    {expenseData.statement_files
                      && expenseData.statement_files.length > 0
                      && expenseData.statement_files.map((expenseFile, index) => (
                        <FilesContainer key={expenseFile.id}>
                          <FilesList
                            index={index}
                            MIMEtype={expenseFile.file.MIMEtype}
                            preview={
                              expenseFile.file?.previewURL
                              || expenseFile.file?.url
                            }
                            id={expenseFile.file.id}
                            text={expenseFile.file.name}
                            onDelete={() => {}}
                            noDelete
                          />
                        </FilesContainer>
                      ))}
                    {maintenanceImage !== undefined
                      && maintenanceImage !== null && (
                        <MaintenanceContainer
                          onClick={() => window.open(maintenanceImage?.url, '_blank')}
                        >
                          <h1>Foto da manutenção</h1>
                          <img
                            data-cy="txt-information-modal-statementImage"
                            src={maintenanceImage.url || NoPhoto}
                            alt="teste"
                          />
                        </MaintenanceContainer>
                    )}
                    {expenseData.maintenance_files
                      && expenseData.maintenance_files.length > 0
                      && expenseData.maintenance_files.map((expenseFile, index) => (
                        <FilesContainer key={expenseFile.id}>
                          <FilesList
                            index={index}
                            MIMEtype={expenseFile.file.MIMEtype}
                            preview={
                                expenseFile.file?.previewURL
                                || expenseFile.file?.url
                              }
                            id={expenseFile.file.id}
                            text={expenseFile.file.name}
                            onDelete={() => {}}
                            noDelete
                          />
                        </FilesContainer>
                      ))}
                  </PhotosContainer>

                  {userInformation?.main_role !== 'Host' && (
                    <DescriptionContainer>
                      <h1>Descrição da despesa</h1>
                      <p data-cy="txt-information-modal-description">
                        {dataFormatted.description}
                      </p>
                    </DescriptionContainer>
                  )}
                  {userInformation?.main_role === 'Host' && (
                    <DescriptionContainer>
                      <h1>Descrição do reembolso</h1>
                      <p data-cy="txt-information-modal-description">
                        {dataFormatted.description}
                      </p>
                    </DescriptionContainer>
                  )}
                  <AuthorizationContainer>
                    <h1>Foi pedido a autorização do proprietário?</h1>
                    <p data-cy="txt-information-modal-owner_approval">
                      {dataFormatted.owner_approval
                        ? 'Sim'
                        : 'Não' || 'Não respondido'}
                    </p>
                  </AuthorizationContainer>
                  <div className="row">
                    <FieldStatusContainer>
                      <h1>Reembolso</h1>
                      <p data-cy="txt-information-modal-refund">
                        {formatedDateToShow(dataFormatted.refund)}
                      </p>
                    </FieldStatusContainer>
                  </div>
                  {dataFormatted?.expense_status === 'Pending' && (
                    <div className="row">
                      <FieldCodeProperty>
                        <h1 data-cy="txt-pending-reason">Motivo da pendência</h1>
                        <p data-cy="txt-information-modal-pending_reason">
                          {dataFormatted?.pending_reason || '-'}
                        </p>
                      </FieldCodeProperty>
                    </div>
                  )}
                </RightSideContainer>
              </FormsContainer>
            </form>
          </ContentContainer>
        </Content>
      </Container>
    </ModalTemplate>
  );
};

export default ExpensesInformationModal;
