import React, { Dispatch, SetStateAction } from 'react';

import arrowBottom from '../../../../../assets/icons/generals/arrowBottom.svg';
import arrowTop from '../../../../../assets/icons/generals/arrowTop.svg';

import {
  Container,
  GridHeader,
  ReservationCode,
  ImmobileCode,
  ReservationDate,
  ReservationStaff,
  WhatsApp,
  CheckIn,
  GridTitle,
  Email,
  RemainingDate,
  PreCheckinButton,
} from './styles';

interface Props {
  setTypeSort: Dispatch<SetStateAction<string | undefined>>;
}

const Header = ({ setTypeSort }: Props) => {
  function handleTypeOfSort(typeOfSort: string) {
    setTypeSort((state) => {
      if (state === typeOfSort) {
        return `-${typeOfSort}`;
      }
      return typeOfSort;
    });
  }
  return (
    <Container>
      <GridHeader>
        <ReservationCode>
          <GridTitle>
            <h2>
              Código
              {' '}
              <br />
              da reserva
            </h2>
          </GridTitle>
        </ReservationCode>
        <ImmobileCode>
          <GridTitle
            onClick={() => handleTypeOfSort('listing__property__code')}
            onKeyDown={() => handleTypeOfSort('listing__property__code')}
          >
            <div className="titleContainer">
              <h2>
                Código
                {' '}
                <br />
                {' '}
                do imovel
              </h2>
              <div
                className="arrowContainer"
                role="button"
                tabIndex={0}
              >
                <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
                <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
              </div>
            </div>
          </GridTitle>
        </ImmobileCode>

        <ReservationDate>
          <GridTitle>
            <div className="titleContainer">
              <h2>
                Data
                {' '}
                <br />
                {' '}
                da reserva
              </h2>

            </div>
          </GridTitle>
        </ReservationDate>

        <ReservationStaff>
          <GridTitle
            onClick={() => handleTypeOfSort('guest__user__first_name')}
            onKeyDown={() => handleTypeOfSort('guest__user__first_name')}
          >
            <div className="titleContainer">
              <h2>
                Dono
                {' '}
                <br />
                {' '}
                da reserva
              </h2>
              <div
                className="arrowContainer"
                role="button"
                tabIndex={0}
              >
                <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
                <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
              </div>
            </div>
          </GridTitle>
        </ReservationStaff>

        <WhatsApp>
          <GridTitle>
            <div className="titleContainer">
              <h2>WhatsApp</h2>
            </div>
          </GridTitle>
        </WhatsApp>

        <CheckIn>
          <GridTitle>
            <div
              className="titleContainer"
              onClick={() => handleTypeOfSort('check_in_date')}
              onKeyDown={() => handleTypeOfSort('check_in_date')}
              role="button"
              tabIndex={0}
            >
              <h2>Check-in</h2>
              <div>
                <img src={arrowTop} alt="Seta para ordenar os códigos da reserva" />
                <img src={arrowBottom} alt="Seta para ordenar os códigos da reserva" />
              </div>
            </div>
          </GridTitle>
        </CheckIn>

        <Email>
          <GridTitle>
            <h2>
              Email foi
              {' '}
              <br />
              Enviado?
            </h2>
          </GridTitle>
        </Email>

        <RemainingDate>
          <GridTitle>
            <h2>
              Dias para o
              <br />
              check in
            </h2>
          </GridTitle>
        </RemainingDate>

        <PreCheckinButton>
          <GridTitle>
            <h2>
              Pré Checkin
            </h2>
          </GridTitle>
        </PreCheckinButton>
      </GridHeader>
    </Container>
  );
};

export default Header;
