import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: 100%;
`;

export const ScrollBox = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 1rem;
`;

export const BodyContainer = styled.div<{
  isTwoCardOpen?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  transition: 0.4s;
  margin-top: 1rem;
  max-height: ${({ isTwoCardOpen }) => (isTwoCardOpen ? '25vh' : '57vh')};
  min-height: 100px;
  padding: 0 10px 0 0;

  @media (max-width: 1400px) {
    max-height: ${({ isTwoCardOpen }) => (isTwoCardOpen ? '19vh' : '46vh')};
  }

  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;

export const ContainerSpinner = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const Spinner = styled.div<{ isLoading: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.palette.grey._440.hex()};
  border-left-color: ${({ theme }) => theme.palette.blue._850.hex()};

  ${({ isLoading }) => isLoading
    && css`
      animation: ${rotate} 0.5s linear infinite;
  `}
`;
