import { motion } from 'framer-motion';
import React from 'react';
import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';
import { Container } from './style';

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -100 },
};

interface IIcon {
  onClick: Function;
}

const Icon = ({
  onClick,
}: IIcon) => {
  const { hideElements } = usePartners();

  return (
    <Container
      whileTap={{
        scale: 0.9,
      }}
      onClick={() => onClick()}
    >
      <motion.svg
        initial="hidden"
        animate="visible"
        variants={hideElements.header?.animate ? list : item}
        width="29"
        transition={{
          delay: hideElements.header?.animate ? 0 : 0.2,
        }}
        height="19"
        viewBox="0 0 29 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M26.9957 7.9165H1.50416C0.673436 7.9165 0 8.58994 0 9.42067V9.579C0 10.4097 0.673436 11.0832 1.50416 11.0832H26.9957C27.8265 11.0832 28.4999 10.4097 28.4999 9.579V9.42067C28.4999 8.58994 27.8265 7.9165 26.9957 7.9165Z"
          variants={item}
          fill="#001840"
        />
        <motion.path
          d="M26.9957 15.8333H1.50416C0.673436 15.8333 0 16.5067 0 17.3374V17.4957C0 18.3265 0.673436 18.9999 1.50416 18.9999H26.9957C27.8265 18.9999 28.4999 18.3265 28.4999 17.4957V17.3374C28.4999 16.5067 27.8265 15.8333 26.9957 15.8333Z"
          variants={item}
          fill="#001840"
        />
        <motion.path
          d="M26.9957 0H1.50416C0.673436 0 0 0.673436 0 1.50416V1.66249C0 2.49322 0.673436 3.16666 1.50416 3.16666H26.9957C27.8265 3.16666 28.4999 2.49322 28.4999 1.66249V1.50416C28.4999 0.673436 27.8265 0 26.9957 0Z"
          variants={item}
          fill="#001840"
        />
      </motion.svg>
    </Container>
  );
};

export default Icon;
