import React, { useState } from 'react';

import SendEmail from './SendEmail';
import MainContent from './MainContent';

import {
  Container,
  Backdrop,
  CloseButton,
  X,
} from './styles';

import { CompReservationsType } from '../../../../services/CompReservations/types';

interface ModalProps {
  onCloseModal: Function;
  idProperty: number;
  checkin: string;
  checkout: string;
  daysLeft: number;
  idLocation: number;
  reservation: CompReservationsType;
  allReservations: CompReservationsType[];
}

const Modal = ({
  onCloseModal,
  idProperty,
  checkin,
  checkout,
  daysLeft,
  idLocation,
  reservation,
  allReservations,
}: ModalProps) => {
  const [openSendEmailScreen, setOpenSendEmailScreen] = useState(false);

  return (
    <>
      <Container>
        <CloseButton onClick={() => onCloseModal()}>
          <X />
          <h1>Fechar</h1>
        </CloseButton>

        {openSendEmailScreen ? (
          <SendEmail
            onCloseModal={() => onCloseModal()}
            onCloseScreen={setOpenSendEmailScreen}
          />
        ) : (
          <MainContent
            reservation={reservation}
            allReservations={allReservations}
            idProperty={idProperty}
            checkin={checkin}
            checkout={checkout}
            daysLeft={daysLeft}
            idLocation={idLocation}
            onClickFinish={() => setOpenSendEmailScreen(true)}
          />
        )}
      </Container>
      <Backdrop onClick={() => onCloseModal()} />
    </>
  );
};

export default Modal;
