import styled from 'styled-components';
import { animationTime, shimmerAnimation } from '../animationShimmer';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main.hex()};
  z-index: 100;
  display: flex;
  border: 1px dashed ${({ theme }) => theme.palette.grey._400.hex()};

  &:first-child {
    background: #000;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 110px;
  height: 75px;
  margin: 0 0.1rem;
  padding: 0.1rem;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-right: 1px dashed ${({ theme }) => theme.palette.grey._400.hex()};

`;

export const Month = styled.div`
  width: 50px;
  height: 30px;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  margin-top: 1.5rem;
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
`;

export const Day = styled.div`
  width: 40px;
  height: 5px;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  margin-top: 0.5rem;
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
`;
