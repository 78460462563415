import React from 'react';

import { Value } from '../styles';

import { Container, Content } from './styles';

interface ExpansiveBedsItemProps {
  open: boolean;
  value:{
    double_bed_quantity: number;
    double_sofa_bed_quantity: number;
    king_bed_quantity: number;
    queen_bed_quantity: number;
    single_bed_quantity: number;
    single_sofa_bed_quantity: number;
    total: number;
  };
}

const ExpansiveBedsItem = ({ open, value }: ExpansiveBedsItemProps) => (
  <Container>
    <Content isOpen={open}>
      <Value>{value.total}</Value>
      {open && (
      <>
        <Value>{value.double_bed_quantity}</Value>
        <Value>{value.single_bed_quantity}</Value>
        <Value>{value.king_bed_quantity}</Value>
        <Value>{value.queen_bed_quantity}</Value>
        <Value>{value.single_sofa_bed_quantity}</Value>
        <Value>{value.double_sofa_bed_quantity}</Value>
      </>
      )}
    </Content>
  </Container>
);
export default ExpansiveBedsItem;
