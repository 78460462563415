import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CircularProgress } from '@mui/material';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import DatePicker from '../DatePicker';
import FormButton from '../FormButton';
import SimpleSelect from '../SimpleSelect';
import { SelectOption } from '../SimpleSelect/SimpleSelect';
import TextField from '../TextField';

import { Gender } from '../../services/types';
import { compareList } from '../../utils/Sorting';
import {
  Header,
  ButtonClose,
  ButtonsContainer,
  DatePickerContainer,
  FirstLoginOwnerModalBackDrop,
  FirstLoginOwnerModalCard,
  Form,
  SimpleSelectContainer,
  Title,
  TwoColumns,
  TwoUnevenColumns,
} from './styles';

import {
  getOwnerDetailsById,
  patchEditAddress,
  patchEditOwner,
  patchEditUser,
} from '../../services/InsertData/request';

import { useToast } from '../../context/ToastContext';
import { UserMeProps } from '../../services/User/types';
import { phone, phoneToNumber } from '../../utils/InputMask/Number';
import { useToastErrorMessage } from '../../utils/Messages';

import {
  currencyToNumber,
  numberToCurrency,
  formatCEPtoSubmit,
  formatCEPToShow,
  isBrazil,
} from '../../utils/Formatter';
import CountrySelect from '../SimpleSelect/CountrySelect';
import StateSelect from '../SimpleSelect/StateSelect';

type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed';

type Recommendation = 'Realtor' | 'Friend' | 'Google' | 'Facebook' | 'Others';

interface FirstLoginOwnerModalFormikData {
  nickname?: string;
  firstName: string;
  lastName: string;
  birthDate: Date | null;
  bithCity: string;
  phoneNumber: string;
  propertiesOwned?: number;
  propertiesToRent?: number;
  profession?: string;
  income?: string;
  maritalStatus?: MaritalStatus;
  gender?: Gender;
  meetSeazone?: Recommendation;
  invoice_address: number;
  nationality?: string;
  neighborhood: string;
  country: string;
  street: string;
  number?: string;
  complement?: string;
  city: string;
  state: string;
  postal_code: string;
  condominium?: string;
  addressId: number | undefined;
}

interface FirstLoginOwnerModalProps {
  userDetails: UserMeProps | null;
  handleIsOwnerFirstLogin: () => Promise<void>;
}

const FirstLoginOwnerModal = ({
  userDetails,
  handleIsOwnerFirstLogin,
}: FirstLoginOwnerModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(true);
  const [ownerInformation, setOwnerInformation] = useState({} as FirstLoginOwnerModalFormikData);
  const [isOpenModal, setIsOpenModal] = useState(true);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const initialValues: FirstLoginOwnerModalFormikData = {
    firstName: '',
    lastName: '',
    nickname: '',
    birthDate: null,
    bithCity: '',
    phoneNumber: '',
    propertiesOwned: undefined,
    propertiesToRent: undefined,
    profession: undefined,
    income: undefined,
    maritalStatus: undefined,
    gender: 'Not informed' as Gender,
    meetSeazone: undefined,
    invoice_address: 0,
    nationality: undefined,
    neighborhood: '',
    country: '',
    street: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    postal_code: '',
    condominium: '',
    addressId: undefined,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    birthDate: Yup.date().required('Preencha a data!').typeError('Campo obrigatório'),
    maritalStatus: Yup.string().required(),
    gender: Yup.string().required(),
    profession: Yup.string().required(),
    nationality: Yup.string().required(),
    neighborhood: Yup.string().required(),
    country: Yup.string().required(),
    street: Yup.string().required(),
    number: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    postal_code: Yup.string().when('country', {
      is: (country: string | undefined) => isBrazil(country),
      then: Yup.string()
        .min(9, 'Cep inválido. Ex. 88036-600')
        .max(9, 'Cep inválido. Ex. 88036-600')
        .required('Cep obrigatório'),
      otherwise: Yup.string().max(10, 'Cep inválido').required('Cep obrigatório'),
    }),
    phoneNumber: Yup.string()
      .min(13, 'Telefone inválido. Ex. +55(48)99999-9999')
      .required('Telefone obrigatório'),
  });

  function padTo2Digits(number: number) {
    return number.toString().padStart(2, '0');
  }

  function formatDate(date?: Date | string) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return [
          current.getFullYear(),
          padTo2Digits(current.getMonth() + 1),
          padTo2Digits(current.getDate()),
        ].join('-');
      }
    }
    return undefined;
  }

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const formik = useFormik<FirstLoginOwnerModalFormikData>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // eslint-disable-next-line no-restricted-globals
      event?.preventDefault();
      if (userDetails?.id && userDetails?.owner?.id && userDetails?.main_address?.id) {
        try {
          setIsLoadingSubmit(true);
          await patchEditUser(userDetails.id, {
            first_name: values.firstName,
            last_name: values.lastName,
            gender:
              values.gender === ownerInformation.gender
                ? ownerInformation.gender
                : values.gender || undefined,
            main_role: userDetails.main_role || '',
            birth_date: values?.birthDate === null ? undefined : formatDate(values?.birthDate),
            phone_number1: phoneToNumber(values.phoneNumber),
            nickname:
              values.nickname === userDetails.nickname
                ? ownerInformation.nickname
                : values.nickname,
          });

          const formattedPropertiesOwned =
            values.propertiesOwned === -1 ? 0 : values.propertiesOwned;
          const formattedPropertiesToRent =
            values.propertiesToRent === -1 ? 0 : values.propertiesToRent;

          await patchEditAddress(userDetails?.main_address?.id, {
            neighborhood: values.neighborhood,
            country: values.country,
            street: values.street,
            number: values.number,
            complement: values.complement,
            city: values.city,
            state: values.state,
            postal_code: formatCEPtoSubmit(`${values.postal_code}`, values.country),
            condominium: values.condominium,
          });
          await patchEditOwner(userDetails.owner.id, {
            profession:
              values.profession === ownerInformation?.profession
                ? ownerInformation?.profession
                : values.profession,
            user: userDetails.id,
            invoice_address: values.invoice_address,
            birth_city:
              values.bithCity === ownerInformation.bithCity
                ? ownerInformation.bithCity
                : 'Não informado',
            hometown: 'Não informado',
            income:
              values.income === ownerInformation.income
                ? 0.01
                : currencyToNumber(values.income) || 0.01,
            marital_status: values.maritalStatus ? values.maritalStatus : undefined,
            meet_seazone: values.meetSeazone ? values.meetSeazone : 'Others',
            properties_owned: values.propertiesOwned ? formattedPropertiesOwned : 0,
            properties_to_rent: values.propertiesToRent ? formattedPropertiesToRent : 0,
            nationality:
              values.nationality === ownerInformation.nationality
                ? ownerInformation.nationality
                : values.nationality,
            nickname:
              values.nickname === userDetails.nickname
                ? ownerInformation.nickname
                : values.nickname,
          });
          await handleIsOwnerFirstLogin();

          formik.resetForm();
          toast.success('Dados salvos com  sucesso!');
          handleCloseModal();
        } catch (e: unknown) {
          if (e instanceof Error) {
            toastErrorRequest(e);
          }
        } finally {
          setIsLoadingSubmit(false);
        }
      }
    },
  });

  const maritalSelectOptions: SelectOption[] = [
    {
      value: 'Single',
      valueLabel: 'Solteiro(a)',
    },
    {
      value: 'Married',
      valueLabel: 'Casado(a)',
    },
    {
      value: 'Divorced',
      valueLabel: 'Divorciado(a)',
    },
    {
      value: 'Widowed',
      valueLabel: 'Viúvo(a)',
    },
  ].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

  const genderSelectOptions: SelectOption[] = [
    {
      value: 'Not informed',
      valueLabel: 'Não informado',
    },
    {
      value: 'Male',
      valueLabel: 'Masculino',
    },
    {
      value: 'Female',
      valueLabel: 'Feminino',
    },
  ].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

  const meetSeazoneSelectOptions: SelectOption[] = [
    {
      value: 'Realtor',
      valueLabel: 'Corretor de imóveis',
    },
    {
      value: 'Friend',
      valueLabel: 'Amigos',
    },
    {
      value: 'Google',
      valueLabel: 'Google',
    },
    {
      value: 'Facebook',
      valueLabel: 'Facebook',
    },
    {
      value: 'Others',
      valueLabel: 'Outros',
    },
  ].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

  async function getOwnerDetails(user: UserMeProps) {
    if (user.owner?.id) {
      try {
        setIsLoading(true);
        const ownerResponse = await getOwnerDetailsById(user.owner?.id);

        setOwnerInformation({
          firstName: user.first_name || '',
          invoice_address: ownerResponse.invoice_address || -1,
          nickname: ownerResponse.user.nickname,
          lastName: user.last_name || '',
          birthDate: moment(user.birth_date || formatDate(new Date())).toDate(),
          bithCity: ownerInformation.bithCity || 'Não informado',
          phoneNumber: phone(user.phone_number1!) || '',
          gender: user.gender as Gender,
          income: numberToCurrency(ownerResponse.income) || undefined,
          maritalStatus: ownerResponse.marital_status || undefined,
          meetSeazone: ownerResponse.meet_seazone || undefined,
          nationality: ownerResponse.nationality || undefined,
          profession: ownerResponse.profession || undefined,
          propertiesOwned: ownerResponse.properties_owned || undefined,
          propertiesToRent: ownerResponse.properties_to_rent || undefined,
          neighborhood: user.main_address?.neighborhood || '',
          country: user.main_address?.country || '',
          street: user.main_address?.street || '',
          number: user.main_address?.number || '',
          complement: user.main_address?.complement || '',
          city: user.main_address?.city || '',
          state: user.main_address?.state || '',
          postal_code: formatCEPToShow(user.main_address?.postal_code || ''),
          condominium: user.main_address?.condominium || '',
          addressId: user.main_address?.id,
        });

        let genderAux: Gender = 'Not informed';
        if (user?.gender === 'Male') {
          genderAux = 'Male';
        }
        if (user?.gender === 'Female') {
          genderAux = 'Female';
        }
        const ownerDetails: FirstLoginOwnerModalFormikData = {
          income: numberToCurrency(ownerResponse?.income),
          maritalStatus: ownerResponse?.marital_status,
          profession: ownerResponse?.profession,
          meetSeazone: ownerResponse?.meet_seazone,
          propertiesOwned: ownerResponse?.properties_owned || 0,
          propertiesToRent: ownerResponse?.properties_to_rent || 0,
          birthDate: moment(user.birth_date || formatDate(new Date())).toDate(),
          bithCity: ownerResponse.birth_city || '',
          firstName: user?.first_name || '',
          lastName: user?.last_name || '',
          nickname: user.nickname,
          phoneNumber: phone(user.phone_number1!) || '',
          gender: genderAux || 'Not informed',
          invoice_address: ownerResponse?.invoice_address,
          nationality: ownerResponse?.nationality,
          neighborhood: user?.main_address?.neighborhood || '',
          country: user.main_address?.country || '',
          street: user.main_address?.street || '',
          number: user.main_address?.number || '',
          complement: user.main_address?.complement || '',
          city: user.main_address?.city || '',
          state: user.main_address?.state || '',
          postal_code: formatCEPToShow(
            user.main_address?.postal_code || '',
            user.main_address?.country,
          ),
          condominium: user.main_address?.condominium || '',
          addressId: user.main_address?.id,
        };
        formik.setValues({
          ...ownerDetails,
        });
      } catch (e) {
        toast.error('Não foi possível buscar os dados!');
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (userDetails !== null) {
      getOwnerDetails(userDetails);
    }
  }, [userDetails]);

  function handleAge(date?: Date) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return new Date().getFullYear() - date.getFullYear();
      }
    }
    return 0;
  }

  function birthDateIsValid() {
    if (formik?.values?.birthDate && formik?.values?.birthDate !== ownerInformation.birthDate) {
      const age = handleAge(formik?.values?.birthDate);
      if (age < 12 || age > 123 || age === 0) {
        formik.setFieldError('birthDate', 'A idade não é válida!');
        return false;
      }
    }
    return true;
  }

  function postalCodeIsValid() {
    if (isBrazil(formik.values?.country) && formik.values?.postal_code?.length < 9) {
      formik.setFieldError('postal_code', 'Cep inválido. Ex. 88036-600');
      return false;
    }
    return true;
  }

  function fieldIsValid(field: string) {
    return !['', 'null', 'undefined'].includes(`${field}`.toLowerCase().trim());
  }

  useEffect(() => {
    const {
      firstName,
      lastName,
      birthDate,
      phoneNumber,
      nationality,
      profession,
      maritalStatus,
      gender,
      neighborhood,
      number,
      city,
      country,
      state,
      street,
    } = formik.values;

    if (
      fieldIsValid(`${firstName}`) &&
      fieldIsValid(`${lastName}`) &&
      fieldIsValid(`${phoneNumber}`) &&
      fieldIsValid(`${birthDate}`) &&
      birthDateIsValid() &&
      fieldIsValid(`${nationality}`) &&
      fieldIsValid(`${profession}`) &&
      fieldIsValid(`${maritalStatus}`) &&
      fieldIsValid(`${gender}`) &&
      fieldIsValid(`${neighborhood}`) &&
      fieldIsValid(`${number}`) &&
      fieldIsValid(`${city}`) &&
      fieldIsValid(`${country}`) &&
      fieldIsValid(`${state}`) &&
      fieldIsValid(`${street}`) &&
      postalCodeIsValid()
    ) {
      setIsLoadingSubmit(false);
    } else {
      setIsLoadingSubmit(true);
    }
  }, [formik.values]);

  return (
    <>
      {isOpenModal && (
        <FirstLoginOwnerModalBackDrop id="close-modal-out" onClick={() => handleCloseModal()}>
          <FirstLoginOwnerModalCard onClick={(e) => e.stopPropagation()}>
            <Header>
              <Title>
                Atualização de dados cadastrais
                {isLoading && <CircularProgress size={16} color="inherit" />}
              </Title>
              <ButtonClose id="close-modal">
                <Close onClick={() => handleCloseModal()} />
              </ButtonClose>
            </Header>
            <Form onSubmit={formik.handleSubmit}>
              <TwoColumns>
                <TextField
                  id="firstName"
                  label="Nome"
                  required
                  formik={formik}
                  value={formik.values.firstName}
                  placeholder="Digite..."
                />
                <TextField
                  id="lastName"
                  label="Sobrenome"
                  required
                  formik={formik}
                  value={formik.values.lastName}
                  placeholder="Digite..."
                />
                <TextField
                  id="nickname"
                  label="Como prefere ser chamado?"
                  formik={formik}
                  value={formik.values.nickname}
                  placeholder="Digite..."
                />
              </TwoColumns>
              <DatePickerContainer>
                <DatePicker
                  id="birthDate"
                  label="Data de nascimento"
                  formik={formik}
                  required={!!isLoadingSubmit}
                  viewsCustom={['day', 'month', 'year']}
                  showDaysOutsideCurrentMonth
                  disableInput
                />
              </DatePickerContainer>
              <h1>Seu endereço residencial</h1>
              <CountrySelect
                id="country"
                label="País"
                formik={formik}
                labelClassName="labelClass"
                valueIsFullName
                required
              />
              <TextField
                id="street"
                label="Logradouro"
                required
                formik={formik}
                value={formik.values.street}
                placeholder="Digite..."
              />
              <TwoColumns>
                <TextField
                  id="number"
                  label="Número"
                  required
                  formik={formik}
                  value={formik.values.number}
                  placeholder="Digite..."
                />
                <TextField
                  id="complement"
                  label="Complemento"
                  formik={formik}
                  value={formik.values.complement}
                  placeholder="Digite..."
                />
                <TextField
                  id="neiborhood"
                  label="Bairro"
                  required
                  formik={formik}
                  value={formik.values.neighborhood}
                  placeholder="Digite..."
                />
                <TextField
                  id="city"
                  label="Cidade"
                  required
                  formik={formik}
                  value={formik.values.city}
                  placeholder="Digite..."
                />
                {isBrazil(formik?.values.country) ? (
                  <StateSelect id="state" label="Estado" formik={formik} />
                ) : (
                  <TextField
                    id="state"
                    label="Estado"
                    required
                    formik={formik}
                    value={formik.values.state}
                    placeholder="Digite..."
                  />
                )}
                <TextField
                  id="postal_code"
                  label="CEP"
                  required
                  formik={formik}
                  value={formik.values.postal_code}
                  placeholder="Digite..."
                  {...(isBrazil(formik.values.country) ? { mask: 'cep' } : {})}
                />
              </TwoColumns>
              <p>
                Este endereço é do seu cadastro, caso precise alterar o endereço de emissão da nota
                fiscal solicite ao nosso time de atendimento ao proprietário.
              </p>
              <TextField
                labelClassName="labelClass"
                required
                value={formik?.values.nationality}
                id="nationality"
                label="Nacionalidade"
                placeholder="Digite aqui..."
                formik={formik}
              />
              <TwoColumns>
                <TextField
                  id="propertiesOwned"
                  label="Quantos imóveis possui?"
                  formik={formik}
                  value={formik.values.propertiesOwned}
                  placeholder="Digite..."
                  type="number"
                />
                <TextField
                  id="propertiesToRent"
                  label="Quantos imóveis você aluga?"
                  formik={formik}
                  value={formik.values.propertiesToRent}
                  placeholder="Digite..."
                  type="number"
                />
              </TwoColumns>
              <TwoUnevenColumns>
                <TextField
                  required
                  id="profession"
                  label="Profissão"
                  formik={formik}
                  value={formik.values.profession}
                  placeholder="Digite..."
                />
              </TwoUnevenColumns>
              <TwoColumns>
                <TextField
                  id="phoneNumber"
                  label="Telefone"
                  required
                  mask="phone"
                  formik={formik}
                  value={formik.values.phoneNumber}
                  placeholder="Digite..."
                />
              </TwoColumns>
              <TwoColumns>
                <SimpleSelect
                  required
                  id="maritalStatus"
                  label="Estado Civil"
                  formik={formik}
                  options={maritalSelectOptions}
                />
                <SimpleSelect
                  required
                  id="gender"
                  label="Gênero"
                  formik={formik}
                  options={genderSelectOptions}
                />
              </TwoColumns>
              <SimpleSelectContainer>
                <SimpleSelect
                  id="meetSeazone"
                  options={meetSeazoneSelectOptions}
                  label="Como você conheceu a gente?"
                  formik={formik}
                />
              </SimpleSelectContainer>
              <ButtonsContainer>
                <div className="button">
                  <FormButton id="save-data-owner" type="submit" disable={isLoadingSubmit}>
                    Salvar dados
                    {isLoadingSubmit}
                  </FormButton>
                </div>
              </ButtonsContainer>
              {userDetails?.required_actions?.Owner?.includes('COMPLETE_BANK_DETAILS') && (
                <h5>1/2</h5>
              )}
            </Form>
          </FirstLoginOwnerModalCard>
        </FirstLoginOwnerModalBackDrop>
      )}
    </>
  );
};

export default FirstLoginOwnerModal;
