/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { CircularProgress, SelectChangeEvent } from '@mui/material';
import { UseFormik } from '../../utils/Formik/types';

import {
  StyledFormControl,
  StyledSelect,
  Container,
  ImageCalendar,
  ContainerLabel,
  ContentLabel,
  StarSymbol,
  Label,
} from './styles';
import HelperText from '../HelperText/HelperText';
import { ErrorIcon } from '../TextField/styles';
import { Type } from '../HelperText/types';

export interface SelectOption {
  value?: string | number;
  valueLabel?: string | number;
  minDate?: Date;
}

export interface ISelectProps {
  id: string;
  dataCy?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  options: Array<SelectOption>;
  formik?: UseFormik<any>;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  showIcon?: boolean;
  icon?: string;
  onChange?: (event: SelectChangeEvent<any>) => any;
  onKeyPress?: KeyboardEventHandler<any>;
  required?: boolean;
  disableRequireSymbol?: boolean;
  requireSymbolPosition?: 'left' | 'right',
  firstValue?: string;
  isEstimate?: boolean;
  loading?: boolean;
  defaultSelected?: string;
}

const SimpleSelect: React.FC<ISelectProps> = ({
  id,
  dataCy = '',
  placeholder,
  label,
  disabled = false,
  options,
  formik,
  helperText,
  className,
  showIcon = false,
  onChange = (event: SelectChangeEvent<any>) => (event),
  onKeyPress,
  icon = '',
  required = false,
  disableRequireSymbol = false,
  requireSymbolPosition = 'left',
  firstValue = '',
  isEstimate = false,
  labelClassName = 'simpleSelectLabel',
  loading = false,
  defaultSelected = undefined,
}) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    formik?.handleChange(event);
    formik?.setFieldTouched(
      id, true, false,
    );
    if (onChange) {
      onChange(event);
    }
  };

  const [initialValue, setInitialValue] = useState(firstValue.length > 0
    ? firstValue : formik?.values[id]);
  const isHelperTextError = formik?.touched[id] && formik?.errors[id] !== '' && formik?.errors[id];

  const helperTextValue = () => {
    if (isHelperTextError) {
      return (
        <>
          <ErrorIcon />
          {formik?.errors[id]}
        </>
      );
    }

    return helperText;
  };

  const helperTextType = isHelperTextError ? Type.error : Type.primary;

  useEffect(() => {
    if (firstValue.length > 0) {
      setInitialValue(firstValue);
    }
  }, [firstValue]);

  useEffect(() => {
    setInitialValue(formik?.values[id]);
  }, [formik?.values[id]]);

  return (
    <Container>
      <StyledFormControl className="simpleSelectContainer" label={label} focused={false} variant="outlined">
        <ContainerLabel>
          <ContentLabel>
            {!disableRequireSymbol && required && requireSymbolPosition === 'left' && <StarSymbol>*</StarSymbol>}
            <Label className={labelClassName} htmlFor={id}>{label}</Label>
            {!disableRequireSymbol && required && requireSymbolPosition === 'right' && <StarSymbol>*</StarSymbol>}
            {loading && <CircularProgress size={14} color="inherit" />}
          </ContentLabel>
        </ContainerLabel>

        {showIcon && <ImageCalendar src={icon} alt="icone de calendário" />}
        <StyledSelect
          native
          id={id}
          autoWidth
          displayEmpty
          data-cy={dataCy}
          showIcon={showIcon}
          className={className}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          value={initialValue}
          isEstimate={isEstimate}
          disabled={disabled}
        >
          {!defaultSelected && (
            <option id="placeholder" disabled={disabled} value="" selected={initialValue === ''}>
              {placeholder}
            </option>
          )}

          {options.map(({ value, valueLabel }) => (
            <option key={value} value={value}>
              {valueLabel || value}
            </option>
          ))}
        </StyledSelect>
      </StyledFormControl>

      {!!helperTextValue() && (
        <HelperText type={helperTextType}>{helperTextValue()}</HelperText>
      )}
    </Container>
  );
};

export default SimpleSelect;
