import { UseFormik } from '../../../../../../utils/Formik/types';

export type IValidateList =
| 'name'
| 'phone'
| 'state'
| 'type'
| 'state/city'
| 'balance'
| 'dimensions'
| 'street'
| 'number'
| 'neighborhood'
| 'complement';

interface IValidate {
  formik: UseFormik<any>;
  checkbox: boolean;
  obj: IValidateList;
}

export const validateErrorMessage = ({
  obj,
  formik,
  checkbox,
}: IValidate) => {
  const formikErrorsObj = Object.keys(formik.errors).filter((key) => key === obj)[0];
  const formikTouchedObj = Object.keys(formik.touched).filter((key) => key === obj)[0];

  if (checkbox || (formikTouchedObj && formikErrorsObj)) {
    return true;
  }

  return false;
};
