import {
  createContext, ReactNode, useContext, useEffect, useState,
} from 'react';
import { useQuery } from 'react-query';
import { getMonthlyBudget } from '../../services/MonthlyBudget/request';
import { MonthlyBudgetPropertyDetails } from '../../services/MonthlyBudget/types';

interface EstimateFilterContextData {
  location: string,
  setLocationArray: (arg0: string[]) => void;
  category: string;
  setCategoryArray: (arg0: string[]) => void;
  bathrooms: number;
  setBathrooms: (arg0: number) => void;
  order: string;
  setOrder: (arg0: string) => void;
}

interface EstimateFilterProps {
  children: ReactNode;
}

const EstimateFilterContext = createContext<EstimateFilterContextData>({

} as EstimateFilterContextData);

export function EstimateFilterProvider({ children }: EstimateFilterProps) {
  const [categoryArray, setCategoryArray] = useState<string[]>([]);
  const [locationArray, setLocationArray] = useState<string[]>([]);
  const [bathrooms, setBathrooms] = useState(0);
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [order, setOrder] = useState('');

  useEffect(() => {
    setCategory(categoryArray.toString());
  }, [categoryArray]);
  useEffect(() => {
    setLocation(locationArray.toString());
  }, [locationArray]);

  return (
    <EstimateFilterContext.Provider
      value={{
        category,
        setCategoryArray,
        location,
        setLocationArray,
        bathrooms,
        setBathrooms,
        order,
        setOrder,
      }}
    >
      {children}
    </EstimateFilterContext.Provider>

  );
}

export function useEstimateFilterValues() {
  const context = useContext(EstimateFilterContext);
  return context;
}

export function useEstimateQuery(
  checkinDate: string,
  checkoutDate: string,
  isSearch: boolean,
  category?: string,
  location?: string,
  bathroomQuantity?: number,
  order?: string,
) {
  let categoryAux: string;
  if (category && category !== 'Selecione') {
    categoryAux = category;
  } else {
    categoryAux = '';
  }
  let locationAux: string;
  if (location && location !== 'Selecione') {
    locationAux = location;
  } else {
    locationAux = '';
  }
  let bathroomAux: string | number;
  if (bathroomQuantity && bathroomQuantity !== 0) {
    bathroomAux = bathroomQuantity;
  } else {
    bathroomAux = '';
  }
  let orderAux: string = '';
  let orderAux2: string = '';
  if (order === '1' || order === '2') {
    orderAux = 'total_price';
    if (order === '2') {
      orderAux2 = 'r';
    }
  }
  if (order === '2') {
    return useQuery(
      ['monthlybudget', categoryAux, locationAux, bathroomAux, checkinDate, checkoutDate, orderAux + orderAux2], async () => {
        const data = getMonthlyBudget(
          checkinDate, checkoutDate, categoryAux, locationAux, bathroomAux, orderAux,
        );
        const newData: MonthlyBudgetPropertyDetails[] = [];
        (await data).results.forEach((property) => {
          const aux = {
            ...property,
            intoCart: false,
          };
          newData.push(aux);
        });
        return {
          count: (await data).count,
          next: (await data).next,
          previous: (await data).previous,
          results: newData.reverse(),
        };
      }, {
        staleTime: 1000 * 60 * 15,
        enabled: isSearch === true,
      },
    );
  }
  return useQuery(
    ['monthlybudget', categoryAux, locationAux, bathroomAux, checkinDate, checkoutDate, orderAux], async () => {
      const data = getMonthlyBudget(
        checkinDate, checkoutDate, categoryAux, locationAux, bathroomAux, orderAux,
      );
      const newData: MonthlyBudgetPropertyDetails[] = [];
      (await data).results.forEach((property) => {
        const aux = {
          ...property,
          intoCart: false,
        };
        newData.push(aux);
      });
      return {
        count: (await data).count,
        next: (await data).next,
        previous: (await data).previous,
        results: newData,
      };
    }, {
      staleTime: 1000 * 60 * 15,
      enabled: isSearch === true,
    },
  );
}
