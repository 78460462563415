import styled, { keyframes } from 'styled-components';

const animationTime = '1s';
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  opacity: 0;
  animation: ${animationTime} ${fadeIn} 0.3s ease forwards;
  padding: 24px;

  h2 {
    font-size: 20px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    color: #001840;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;
  height: 100%;
`;

export const LinkButtonContainer = styled.div<{ disabled: boolean }>`
  &:nth-child(1) {
    animation: ${animationTime} ${fadeIn} 0.4s ease forwards;
  }

  &:nth-child(2) {
    animation: ${animationTime} ${fadeIn} 0.5s ease forwards;
  }

  &:nth-child(3) {
    animation: ${animationTime} ${fadeIn} 0.6s ease forwards;
  }

  &:nth-child(4) {
    animation: ${animationTime} ${fadeIn} 0.7s ease forwards;
  }

  &:nth-child(5) {
    animation: ${animationTime} ${fadeIn} 0.8s ease forwards;
  }

  &:nth-child(6) {
    animation: ${animationTime} ${fadeIn} 0.9s ease forwards;
  }

  opacity: 0;
  border: none;
  width: 100%;
  border-radius: 8px;
  transition: 0.3s;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  display: flex;
  align-items: center;
  outline: none;
  border: 1px solid transparent;
  padding: 16px;
  gap: 0.5rem;
  box-shadow: 0px 1px 3.5px 0px #00000026;
  background: #fff;
`;

export const AnimationContainer = styled.div<{ disabled: boolean }>`
  outline: none;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  align-self: flex-start;

  div {
    height: 26px;
    width: 26px;
  }
`;

export const ButtonContainer = styled.div<{ disabled: boolean }>`
  text-align: left;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #394760;
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-size: 16px;
    text-align: left;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    &:focus {
      outline: none;
    }
    &:disabled {
      cursor: not-allowed;
    }
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  }
`;

export const divider = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;
  margin-top: 24px;
  gap: 16px;
`;
