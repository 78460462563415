import styled from 'styled-components';
import { AppBar, Button } from '@mui/material';
import { NavLink as NavLinkComponent } from 'react-router-dom';

export const Wrapper = styled.div`
  @media (max-width: 900px) {
    .mobile {
      @media (max-width: 900px) {
        flex-direction: column !important;
        width: 100%;
        padding-top: 20px;
      }
    }
  }
`;

export const ColoredBar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.palette.blue._950.hex()};
    display: flex;
    height: 100%;
    width:100%;
    max-height: 50px;

    @media (max-width: 900px) {
      flex-direction: column;
      height: auto;
      max-height: none;
    }
  }


`;

export const LogoImage = styled.img`
  max-width: 100px;

  @media (max-width: 900px) {
      max-width: none;
      width: 150px;
    }
`;

export const ContainerImages = styled.div`
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    button {
    background: none;
    border: none;
    }
  }
`;

export const MenuMobile = styled.img`
  display: none;
  @media (max-width: 900px){
    display: flex;
  }
`;

export const NavLink = styled(NavLinkComponent)`
  && {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    padding: 16px 20px;
    align-content: space-around;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.white.main.hex()};
    cursor: pointer;
    position: relative;

    @media (max-width: 900px) {
      font-size: 18px;
    }

    &.active {
      background-color: ${({ theme }) => theme.palette.blue._900.hex()};
      &:after {
        background-color: ${({ theme }) => theme.palette.red._400.hex()};
        border-radius: 4px;
        width: 100%;
        height: 5px;
        display: block;
        top: 45px;
        left: 0px;
        content: '';
        position: absolute;
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.palette.blue._900.hex()};
      transition: background-color 1s linear;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0%;
    margin: 0;
  }
`;

export const TextContainer = styled.div`
  padding-left: 22px;
  display: flex;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    padding-left: 0;
  }
`;

export const WrapperNav = styled.div<{
  open: boolean,
}>`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    width: 100%;

  }
`;

export const MenuItemButton = styled(Button)`
  && {
    height: 100%;
    font-family: 'Quicksand', sans-serif;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  & .account-menu {
    top: 1px;
  }

  .logoutimage{
    height: 30px;
    margin-right: 10px;
  }
`;

export const Username = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-size: 1.1rem;
  font-weight: bold;
`;
