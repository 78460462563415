import { FilterBar } from '../../context/FilterBar';
import { PropertyDetails } from '../../services/Property/types';

export const removeParams = (filterBar: FilterBar, params: PropertyDetails) => {
  const paramsAux = params;
  if (!filterBar.moreSearch) {
    delete paramsAux.bathroom_quantity;
    delete paramsAux.bedroom_quantity;
  }

  if (!filterBar.search) {
    delete paramsAux?.start_date;
    delete paramsAux?.end_date;
  }

  if (filterBar.host !== undefined && Number.isNaN(filterBar.host)) {
    delete paramsAux.host;
  }

  if (filterBar.checkInDate === '' || filterBar.checkInDate.toString() === 'Invalid date'
    || filterBar.checkOutDate === '' || filterBar.checkInDate.toString() === 'Invalid date') {
    delete paramsAux?.start_date;
    delete paramsAux?.end_date;
  }

  if (paramsAux.location === 'Selecione' || paramsAux.location === '') {
    delete paramsAux.location;
  }

  if (paramsAux.categories === 'Selecione' || paramsAux.categories === '') {
    delete paramsAux.categories;
  }

  if (paramsAux.property_type === '') {
    delete paramsAux.property_type;
  }

  if (filterBar.guestsCapacity === 0) {
    delete paramsAux.guest_capacity__gte;
  }

  if (filterBar.bathroom === 'Selecione' || filterBar.bathroom === '') {
    delete paramsAux.bathroom_quantity;
  }
  if (filterBar.bedroom === 'Selecione' || filterBar.bedroom === '') {
    delete paramsAux.bedroom_quantity;
  }

  if (filterBar.seaView === false) {
    delete paramsAux.sea_view;
  }

  if (filterBar.allowPet === false) {
    delete paramsAux.allow_pet;
  }

  if (filterBar.barbecue === false) {
    delete paramsAux.barbecue;
  }

  return paramsAux;
};
