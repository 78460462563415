import { compareList } from '../../utils/Sorting';
import { Rules, PropertyRulesValuesType } from './types';

export const initialValuesRules = [
  {
    name: 'Adequado para bebês?',
    key: 'suitable_for_babies',
    value: false,
  },
  {
    name: 'Adequado para crianças?',
    key: 'suitable_for_children',
    value: false,
  },
  {
    name: 'Permite animais?',
    key: 'allow_pet',
    value: false,
  },
  {
    name: 'Permite fumar?',
    key: 'smoking_permitted',
    value: false,
  },
  {
    name: 'Adequado para eventos?',
    key: 'events_permitted',
    value: false,
  },
].sort((a, b) => compareList(a.name, b.name));

export const initialValuesForm = {
  suitable_for_babies: false,
  suitable_for_children: false,
  allow_pet: false,
  smoking_permitted: false,
  events_permitted: false,
};

export const handleUpdateFormPropertyRules = (allRules: Rules[]) => {
  const propertyRules: PropertyRulesValuesType = {
    ...initialValuesForm,
  };
  allRules.forEach((rule) => {
    switch (rule.key) {
      case 'suitable_for_babies':
        propertyRules.suitable_for_babies = rule.value;
        break;
      case 'suitable_for_children':
        propertyRules.suitable_for_children = rule.value;
        break;
      case 'allow_pet':
        propertyRules.allow_pet = rule.value;
        break;
      case 'smoking_permitted':
        propertyRules.smoking_permitted = rule.value;
        break;
      case 'events_permitted':
        propertyRules.events_permitted = rule.value;
        break;
      default:
        break;
    }
  });
  return propertyRules;
};
