import React from 'react';

import { motion } from 'framer-motion/dist/framer-motion';

import {
  Container,
  ProfilePic,
  ProfileName,
  ProfileTexts,
  ProfileSubtitle,
  ProfileContainer,
  CloseIconContainer,
} from './style';
import { useUser } from '../../../../../../context/UserContext';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RANDOM_IMAGE = 'https://source.unsplash.com/random/1920x720?profile';

const CloseIcon = () => (
  <motion.div
    whileTap={{
      rotate: 90,
    }}
  >
    <motion.svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M17.0799 15.2867L2.64496 0.851752C2.17456 0.381342 1.41187 0.381342 0.941461 0.851752L0.851803 0.94141C0.381393 1.41182 0.381394 2.1745 0.851803 2.64491L15.2868 17.0799C15.7572 17.5503 16.5198 17.5503 16.9903 17.0799L17.0799 16.9902C17.5503 16.5198 17.5503 15.7571 17.0799 15.2867Z"
        fill="white"
      />
      <motion.path
        d="M15.4127 0.924111L0.977728 15.3591C0.507319 15.8295 0.507319 16.5922 0.977728 17.0626L1.06739 17.1522C1.5378 17.6226 2.30048 17.6226 2.77089 17.1522L17.2058 2.71727C17.6763 2.24686 17.6763 1.48418 17.2058 1.01377L17.1162 0.92411C16.6458 0.453701 15.8831 0.453702 15.4127 0.924111Z"
        fill="white"
      />
    </motion.svg>
  </motion.div>
);

interface IHeader {
  onClose: Function;
}

const Header = ({ onClose }: IHeader) => {
  const { userInformation, translateMainRole } = useUser();

  const name = `Olá, ${userInformation?.first_name} ${userInformation?.last_name}`;
  const roleTranslated = translateMainRole(userInformation?.main_role) || 'Não definida';

  return (
    <Container>
      <ProfileContainer>
        <ProfilePic loading="lazy" src={'https://cdn-icons-png.flaticon.com/512/847/847969.png'} alt="imagem do perfil" />
        <ProfileTexts>
          <ProfileName>
            {name}
          </ProfileName>
          <ProfileSubtitle>{roleTranslated}</ProfileSubtitle>
        </ProfileTexts>
      </ProfileContainer>
      <CloseIconContainer onClick={() => onClose()}>
        <CloseIcon />
      </CloseIconContainer>
    </Container>
  );
};

export default Header;
