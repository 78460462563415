import { motion } from 'framer-motion';
import styled from 'styled-components';
import { getMaxWidthDesktop } from '../style';

export const DesktopContainer = styled(motion.div)`
  backdrop-filter: blur(10px);
  background: var(--darkk-blue-900, #001840);
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
`;

export const Container = styled(motion.div)`
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  display: flex;
  z-index: 20;

  backdrop-filter: blur(10px);
  background: var(--darkk-blue-900, #001840);
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  ${getMaxWidthDesktop};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HamburguerMenu = styled.div`
  width: 100%;
  filter: invert(1);
  img {
    width: 20px;
  }
`;
