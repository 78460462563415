import styled from 'styled-components';

export const Container = styled.div`
  height: 80%;
  overflow: auto;

  @media (max-width: 900px) {
    height: 75%;
  }
`;

export const Content = styled.div`
  padding: 0 0 16px 16px;
  max-width: 50%;
`;
