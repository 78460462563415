import React from 'react';
import { useMobile } from '../../../../../../../../../../hooks/useMobile/useMobile';
import ScrollButtons from '../ScrollButtons';
import { IScrollButtons } from '../../types';

const ChoiseMobile = ({ children, cardsRef }: IScrollButtons) => {
  const { isDesktop } = useMobile();

  if (isDesktop) {
    return <ScrollButtons cardsRef={cardsRef}>{children}</ScrollButtons>;
  }

  return <>{children}</>;
};

export default ChoiseMobile;
