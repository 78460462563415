import React from 'react';

import {
  AmountPaid, Capacity, Date, Content, Text2,
} from './styles';

const CardSkeleton: React.FC = () => (
  <>
    {[1, 2, 3, 4, 5].map((item) => (
      <Content key={item}>
        <AmountPaid />
        <Capacity />
        <div className="dates">
          <Date />
          <Date />
        </div>
        <Text2 />
        <Text2 />
        <Text2 />
      </Content>
    ))}
  </>
);

export default CardSkeleton;
