import { useEffect, useState } from 'react';
import moment from 'moment';
import { useReservations } from '../../../../hooks/GuestDamage/useReservations';
import { useLoadMoreData } from '../../../../hooks/useLoadMoreData';
import { ReservationDetails } from '../../../../services/GuestDamage/types';
import { ReconcileDamageReservationForm } from './ReconcileDamageReservationForm';
import { ReconcileDamageReservationTable } from './ReconcileDamageReservationTable';
import { ReconcileDamageReservationCardContainer, Title } from './styles';
import { compareDates, compareList } from '../../../../utils/Sorting';

const ITEMS_PER_PAGE = 10;
type ReservationSortedBy = 'code' | 'guest_name' | 'platform' | 'check-in_date' | 'check-out_date';
type Order = 'asc' | 'desc';

export const ReconcileDamageReservationCard = () => {
  const { reservations, isLoading } = useReservations();
  const [sortedBy, setSortedBy] = useState<ReservationSortedBy>('code');
  const [order, setOrder] = useState<Order>('asc');
  const {
    actualPageData: reservationsPageData,
    haveANextPage: haveMoreReservationsData,
    handleNextPage: handleNextPageReservations,
  } = useLoadMoreData<ReservationDetails[]>({
    pageLength: ITEMS_PER_PAGE,
    data: reservations || [],
  });

  const [sortedReservations,
    setSortedReservations] = useState<ReservationDetails[]>(reservationsPageData);

  function handleSortReservations(sortBy: ReservationSortedBy) {
    switch (sortBy) {
      case 'code': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
              a.property_code || '', b.property_code || '', 'desc',
            ));
            setSortedReservations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
              a.property_code || '', b.property_code || '', 'asc',
            ));
            setSortedReservations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('code');
          const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
            a.property_code || '', b.property_code || '', 'asc',
          ));
          setSortedReservations(sortedNegotiations);
        }
        break;
      }
      case 'guest_name': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
              a.guest_name || '', b.guest_name || '', 'desc',
            ));
            setSortedReservations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
              a.guest_name || '', b.guest_name || '', 'asc',
            ));
            setSortedReservations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('guest_name');
          const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
            a.guest_name || '', b.guest_name || '', 'asc',
          ));
          setSortedReservations(sortedNegotiations);
        }
        break;
      }
      case 'platform': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
              a.ota_name || '', b.ota_name || '', 'desc',
            ));
            setSortedReservations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
              a.ota_name || '', b.ota_name || '', 'asc',
            ));
            setSortedReservations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('platform');
          const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
            a.ota_name || '', b.ota_name || '', 'asc',
          ));
          setSortedReservations(sortedNegotiations);
        }
        break;
      }
      case 'check-in_date': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = reservationsPageData.sort((a,
              b) => compareDates(
              moment(a.check_in_date).format('DD/MM/YYYY'), moment(b.check_in_date).format('DD/MM/YYYY'), 'desc',
            ));
            setSortedReservations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = reservationsPageData.sort((a,
              b) => compareDates(
              moment(a.check_in_date).format('DD/MM/YYYY'), moment(b.check_in_date).format('DD/MM/YYYY'), 'asc',
            ));
            setSortedReservations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('check-in_date');
          const sortedNegotiations = reservationsPageData.sort((a,
            b) => compareDates(
            moment(a.check_in_date).format('DD/MM/YYYY'), moment(b.check_in_date).format('DD/MM/YYYY'), 'asc',
          ));
          setSortedReservations(sortedNegotiations);
        }
        break;
      }
      case 'check-out_date': {
        if (sortBy === sortedBy) {
          if (order === 'asc') {
            setOrder('desc');
            const sortedNegotiations = reservationsPageData.sort((a,
              b) => compareDates(
              moment(a.check_out_date).format('DD/MM/YYYY'), moment(b.check_out_date).format('DD/MM/YYYY'), 'desc',
            ));
            setSortedReservations(sortedNegotiations);
          } else {
            setOrder('asc');
            const sortedNegotiations = reservationsPageData.sort((a,
              b) => compareDates(
              moment(a.check_out_date).format('DD/MM/YYYY'), moment(b.check_out_date).format('DD/MM/YYYY'), 'asc',
            ));
            setSortedReservations(sortedNegotiations);
          }
        } else {
          setOrder('asc');
          setSortedBy('check-out_date');
          const sortedNegotiations = reservationsPageData.sort((a,
            b) => compareDates(
            moment(a.check_out_date).format('DD/MM/YYYY'), moment(b.check_out_date).format('DD/MM/YYYY'), 'asc',
          ));
          setSortedReservations(sortedNegotiations);
        }
        break;
      }
      default: {
        const sortedNegotiations = reservationsPageData.sort((a, b) => compareList(
          a.property_code || '', b.property_code || '', 'asc',
        ));
        setSortedReservations(sortedNegotiations);
        break;
      }
    }
  }

  useEffect(() => {
    handleSortReservations('code');
  }, [reservationsPageData]);

  const isLoadingData = isLoading === true;
  const isLoadedData = reservations.length > 0 && isLoading === false;

  function getTitle() {
    if (isLoadingData) {
      return 'Buscando...';
    }
    if (isLoadedData) {
      return 'Resultado de busca';
    }
    return 'Selecione os filtros e clique em buscar para exibir os dados';
  }
  return (
    <ReconcileDamageReservationCardContainer>
      <ReconcileDamageReservationForm />
      <Title>{getTitle()}</Title>
      <ReconcileDamageReservationTable
        isLoading={isLoading}
        haveNextPage={haveMoreReservationsData}
        handleNextPage={handleNextPageReservations}
        handleSortReservations={handleSortReservations}
        reservations={sortedReservations}
      />
    </ReconcileDamageReservationCardContainer>
  );
};
