import moment from 'moment';
import 'moment/locale/pt-br';
import { Text } from '../../Text';
import { DateWrapper, DayCircle, HeaderContainer } from './styles';

const RenderDateHeader = ({ getIntervalProps, intervalContext }: any) => {
  const { interval } = intervalContext;
  const startDay = moment.unix(interval.startTime / 1000).format('D');
  const startMonth = moment.unix(interval.startTime / 1000).format('MMM');
  const endTime = moment.unix(interval.startTime / 1000).format('dddd');
  const isWeekend = endTime === 'sexta-feira' || endTime === 'sábado';
  const isToday = moment(interval.startTime).isSame(moment(), 'day');
  const day = endTime.split('-');

  return (
    <HeaderContainer
      {...getIntervalProps()}
      isWeekend={isWeekend}
      style={{ ...getIntervalProps().style }}
      className="sticky"
    >
      <div>
        <DateWrapper>
          <DayCircle isToday={isToday}>
            <Text $color={isToday ? 'white' : '#000'} $fontSize="16">
              {startDay}
            </Text>
          </DayCircle>
          <Text $color="#000" $fontSize="16">
            {startMonth}
          </Text>
        </DateWrapper>
        <Text $color={isWeekend ? '#E05845' : '#A9A9A9'} $fontSize="16">
          {day[0]}
        </Text>
      </div>
    </HeaderContainer>
  );
};

export default RenderDateHeader;
