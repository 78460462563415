import styled from 'styled-components';
import { DEFAULT_WIDTH_TO_DESKTOP } from '../../../../../../../../../hooks/useMobile/useMobile';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.palette.grey._50.hex()};
`;

export const Title = styled.h1`
  font-size: 22px;
`;

export const CloseButton = styled.div`
  width: 20px;
  height: 20px;

  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    filter: invert(1);
    transition: 0.5s;
    &:hover {
      opacity: 1;
      transform: rotate(90deg);
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  max-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    border-radius: 20px;
    height: 10px;
    width: 7px;
    border: 1px solid ${({ theme }) => theme.palette.white._600.hex()};
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.grey._50.hex()};
    width: 5px;
    border-radius: 100px;
    transform: scale(0.3);
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 480px;
  height: auto;
  
  margin: 0;

  display: grid;
  justify-content: center;
  align-items: center;

  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-auto-flow: row;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border: 10px solid ${({ theme }) => theme.palette.white.main.hex()};

  gap: 5px;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    max-width: 480px;
  }
`;

export const ContentImage = styled.div`
  width: 80px;
  height: 80px;
  border:  1px dashed ${({ theme }) => theme.palette.grey._345.hex()};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;

  @media (min-width: ${DEFAULT_WIDTH_TO_DESKTOP}px){
    width: 150px;
    height: 150px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
