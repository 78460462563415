import { IState } from '../types';
import dataJSON from './data.json';

export type IActionPlaces = {
  nome: string;
  uf: string;
  id?: string;
}[];

export const actionPlacesJSON: IActionPlaces = dataJSON;

// how to use: states.filter(actionsStates)
export const actionsStates = (state: IState) => {
  const filter = actionPlacesJSON.filter((item) => item.uf === state.sigla);
  return filter.length > 0;
};

export const actionsCitysByState = (uf: string) => {
  const result = actionPlacesJSON?.filter((item, index) => {
    if (uf === item?.uf) {
      return {
        ...item,
        id: index,
      };
    }
    return undefined;
  });
  return result;
};
