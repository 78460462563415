import { AnimatePresence } from 'framer-motion';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { useGuestDamageNegotiation } from '../../../../../hooks/GuestDamage/useGuestDamageNegotiation';
import { numberToCurrency } from '../../../../../utils/Formatter';
import { ValidationAndHistoryTable } from './ValidationAndHistoryTable';
import {
  ValidationAndHistoryItem,
  Title,
  ValidationAndHistoryContainer,
  LinkText,
} from './styles';

export const ValidationAndHistory = () => {
  const { selectedGuestDamageNegotiation } = useGuestDamageNegotiation();
  const { handleOpenFile } = useGuestDamage();
  return (
    <ValidationAndHistoryContainer>
      <Title>Validação de evidências e orçamento</Title>
      <ValidationAndHistoryItem>
        <h3>Código Aircover</h3>
        <p>{selectedGuestDamageNegotiation.aircover_code || '-'}</p>
      </ValidationAndHistoryItem>
      <ValidationAndHistoryItem>
        <h3>Valor Recebido</h3>
        <p>{numberToCurrency(selectedGuestDamageNegotiation.amount_received) || '-'}</p>
      </ValidationAndHistoryItem>
      <ValidationAndHistoryItem>
        <h3>Upload do comprovante</h3>
        {selectedGuestDamageNegotiation?.guest_payment_receipts?.map((receipt) => (
          <>
            {receipt?.guest_payment_receipt !== undefined
              ? (
                <LinkText
                  onClick={
                    () => handleOpenFile(receipt.guest_payment_receipt?.url!,
                      receipt.guest_payment_receipt?.name!)
                  }
                >
                  {receipt.guest_payment_receipt.name}
                </LinkText>
              )
              : '-'}
          </>
        ))}
      </ValidationAndHistoryItem>
      <ValidationAndHistoryItem>
        <h3>Status</h3>
        <p>{selectedGuestDamageNegotiation.status}</p>
      </ValidationAndHistoryItem>
      <ValidationAndHistoryItem>
        <h3>Informar Proprietário</h3>
        <p>{selectedGuestDamageNegotiation.is_to_inform_owner ? 'Sim' : 'Não'}</p>
      </ValidationAndHistoryItem>
      <AnimatePresence>
        {selectedGuestDamageNegotiation?.histories
          && selectedGuestDamageNegotiation.histories?.length > 0 && (
          <ValidationAndHistoryItem>
            <h3>Histórico data de contato</h3>
            <ValidationAndHistoryTable
              histories={selectedGuestDamageNegotiation.histories}
            />
          </ValidationAndHistoryItem>
        )}
      </AnimatePresence>
    </ValidationAndHistoryContainer>
  );
};
