/* eslint-disable consistent-return */

import { useEffect, useRef } from 'react';
import { useOwnerPerspectiveDashboard } from '../../../../../context/OwnerPerspectiveDashboard/OwnerPerspectiveDashboard';

const LoadingMoreData = () => {
  const { addMoreOwners, viewOwners } = useOwnerPerspectiveDashboard();
  const lastElement = useRef(null);

  useEffect(() => {
    if (lastElement.current) {
      const observer = new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          if (viewOwners.next) {
            addMoreOwners();
          }
        }
      },
      { threshold: 1 });
      observer.observe(lastElement.current);
      return () => observer.disconnect();
    }
  }, [lastElement]);

  return (
    <div ref={lastElement} />
  );
};

export default LoadingMoreData;
