import styled, { css } from 'styled-components';

export const Container = styled.div<{
  activeBorder?: boolean,
}>`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;

  svg: {
    width: 25px;
    height: 25px;
  }

  ${(props) => props.activeBorder && css`
    background: ${({ theme }) => theme.palette.grey._310.hex()};;
    border-radius: 45%;
  `}
`;
