import styled, { keyframes } from 'styled-components';

const MAX = 180;
const MIN = 55;

const hideHeight = keyframes`
  from {
    height: ${MAX}px
  }
  to {
    height: ${MIN}px
  }
`;

const showHeight = keyframes`
  from {
    height: ${MIN}px
  }
  to {
    height: ${MAX}px
  }
`;

export const Container = styled.div<{
  isMaxBar: boolean;
}>`
  width: 100%;
  transition: height 1s ease-out;
  animation-name: ${({ isMaxBar }) => (isMaxBar ? showHeight : hideHeight)};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  position: fixed;
  bottom: 71px;
  z-index: 100;
  background: var(--blue-50, #e7e9fa);
`;
