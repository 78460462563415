import styled from 'styled-components';
import { CloseOutlined } from '@mui/icons-material';

export const Container = styled.div`
  width: 100%;
  max-width: 325px;
  height: 100%;
  max-height: 200px;
  border-radius: 5px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseOutlinedIcon = styled(CloseOutlined)`
  cursor: pointer;
`;

export const ContentBody = styled.div`
  margin-top: 1rem;
  `;

export const ContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.strong`
  margin-left: 0.8rem;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 1rem;
`;

export const Subtitle = styled.p`
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  line-height: 18px;
  font-size: 0.9rem;
  
  p, strong {
    line-height: 18px;
    font-size: 0.9rem;
  }
`;

const sizeMap = {
  sm: '14px',
  md: '16px',
  lg: '20px',
};

export const InfoIconContainer = styled.div<{ color: string, size: 'sm' | 'md' | 'lg', marginRight?: string }>`
  width: ${({ size }) => sizeMap[size]};
  height: ${({ size }) => sizeMap[size]};

  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}` : 0)};

  svg {
    cursor: pointer;
    color: ${({ color }) => color};
    width: ${({ size }) => sizeMap[size]};
    height: ${({ size }) => sizeMap[size]};
  }

  :hover {
    svg {
      color: ${({ theme }) => theme.palette.blue._850.hex()};
      transition: 0.6s;
    }
  }
`;
