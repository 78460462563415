import styled from 'styled-components';

export const TableContainer = styled.div`
  background: #E4EAEF;
  flex: 1;
  border-radius: 10px;
  overflow: auto;

  table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    width: 100%;
    min-width: 600px;

    thead {
      position: sticky;
      top: 0;
      background: #E4EAEF;
      z-index: 1;

      th {
        color: #394760;
        font-size: 1rem;
        line-height: 1.6;
        font-weight: 700;

        &:first-child {
          padding-left: 1.5rem;
        }

        &:last-child {
          padding-right: 1.5rem;
        }
      }
    }

    tbody {
      td {
        background: #EBF0F5;
        padding: 1rem;
        font-size: 1rem;
        line-height: 1.6;
        text-align: center;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 15%;
          left: 0;
          right: 0;
          height: 70%;
          border-right: 1px solid #D9D9D9;
        }
        &:first-child {
          padding-left: 1.5rem;
        }

        &:nth-last-child(-n+1) {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.blue._400.hex()};
    &:hover {
      background: ${({ theme }) => theme.palette.blue._400.hex()};
    }
  }
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #0D4BD0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;
