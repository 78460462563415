import React from 'react';

import {
  Container,
} from './style';

const IsPrincipal = () => (
  <Container>
    <strong>
      Esse é o hóspede principal
    </strong>
    <p>Os dados a seguir são obrigatorios!</p>
  </Container>
);

export default IsPrincipal;
