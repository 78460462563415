import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
import { useInView } from 'react-intersection-observer';

import moment from 'moment';
import Header from './Header/Header';
import Body from './Body/Body';

import {
  Container,
  BodyContainer,
  ContainerSpinner,
  Spinner,
  ScrollBox,
} from './styles';

import { CompReservationsType } from '../../../../services/CompReservations/types';
import { useCompleteReservation } from '../../../../hooks/CompleteReservationHook/useCompleteReservation';

interface GridProps {
  index?: number;
  color: 'normal' | 'green';
  isTwoCardOpen?: boolean;
  isLoading: boolean;
  onClickEditButton: Function;
  setIdProperty: (a: number) => void;
  setCheckin: (a: string) => void;
  setCheckout: (a: string) => void;
  setDaysLeft: (a: number) => void;
  setIdLocation: (a: number) => void;
  allReservations: CompReservationsType[];
  setReservation: (a: CompReservationsType) => void;
  setTypeSort: Dispatch<SetStateAction<string | undefined>>;
}

const Grid = ({
  color, index, isTwoCardOpen, onClickEditButton,
  setIdProperty, setCheckin, setCheckout, setDaysLeft, setIdLocation, setTypeSort,
  setReservation, allReservations, isLoading,
}: GridProps) => {
  const { setReservationId } = useCompleteReservation();
  const { ref, inView } = useInView();

  const DEFAULT_LIMIT = 15;
  const [limitData, setLimitData] = useState<number>(allReservations?.length
    > DEFAULT_LIMIT ? DEFAULT_LIMIT : allReservations?.length);

  const [isLoadMoreDatas, setIsLoadMoreDatas] = useState<boolean>(false);

  function handleEditButton(reservation: CompReservationsType) {
    onClickEditButton();
    setReservationId(reservation.reservation_id);
    setIdProperty(reservation.property.id);
    setCheckin(reservation.check_in_date);
    setCheckout(reservation.check_out_date);
    setDaysLeft(moment(reservation.check_in_date).diff(moment(), 'day'));
    setIdLocation(reservation.property.location.id);
    setReservation(reservation);
  }

  const handleLoadMoreDatas = () => {
    setLimitData(allReservations?.length > 0
      && limitData >= allReservations?.length
      ? allReservations?.length : limitData + DEFAULT_LIMIT);
  };

  useEffect(() => {
    if (inView) {
      setIsLoadMoreDatas(true);

      setTimeout(() => {
        handleLoadMoreDatas();
        setIsLoadMoreDatas(false);
      }, 300);
    }
  }, [inView]);

  return (
    <Container>
      <ScrollBox>
        <Header setTypeSort={setTypeSort} />
        <BodyContainer isTwoCardOpen={isTwoCardOpen}>
          {allReservations?.length > 0 && !isLoading && allReservations
            .slice(0, limitData).map((item) => (
              <>
                <Body
                  whatsApp={item.guest_owner.phone_number1 || ''}
                  onClickEditButton={() => handleEditButton(item)}
                  color={color}
                  index={index}
                  checkIn={item.check_in_date}
                  immobileCode={item.property.code}
                  reservationCode={item.code}
                  reservationStaff={item.guest_owner.name}
                  reservationId={item.reservation_id}
                  emailSent={item.email_sent}
                  reservationDate={item.created_at}
                  isPrecheckinLinkSent={item.is_pre_checkin_link_sent}
                  isHotel={item.property.property_type === 'Hotel'}
                  isPreCheckinFullFiled={item.is_pre_checkin_completed}
                  mainGuest={item.guests[0]}
                />
              </>
            ))}

          {((
            allReservations && allReservations?.length > DEFAULT_LIMIT
            && allReservations?.length > limitData) || isLoading) && (
            <ContainerSpinner ref={ref}>
              <Spinner isLoading={isLoadMoreDatas || isLoading} />
            </ContainerSpinner>
          )}
        </BodyContainer>
      </ScrollBox>
    </Container>
  );
};

Grid.defaultProps = {
  isTwoCardOpen: true,
  index: 1,
};

export default Grid;
