import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { ShoppingCartOutlined as CartIcon } from '@mui/icons-material';

import moment from 'moment';

import SimpleSelect, { SelectOption } from '../SimpleSelect/SimpleSelect';
import CardProperty from './CardProperty';
import HeaderFilter from './HeaderFilter';
import ModalLateral from './ModalLateral';
import { useEstimateCart } from '../../context/EstimateCart';
import NonePropertyIcon from '../../assets/icons/estimatePage/noneProperty.svg';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  Cart,
  CircularDiv,
  Container,
  ContainerLoadMoreDatas,
  ContentLoadMoreDatas,
  ContentProperties,
  InicialText,
  NotFound,
  Ordination,
  Quantity,
  QuantityProperties,
  Row,
  SecondaryTitleDiv,
  SecundaryTitle,
  Title,
} from './styles';
import { useEstimateFilterValues, useEstimateQuery } from '../../hooks/useEstimate/useEstimatePage';
import { CountShimmer } from './LoadShimmer/CountShimmer';
import { CardShimmer } from './LoadShimmer/CardShimmer';
import ModalGenerateContract from './ModalGenerateContract';
import { useToast } from '../../context/ToastContext';

const order = [
  {
    id: '1',
    name: 'Maior preço',
  },
  {
    id: '2',
    name: 'Menor preço',
  },
];

const EstimateComponent: React.FC = () => {
  const [openModalLateral, setOpenModalLateral] = useState<boolean>(false);
  const [openModalGenerateContract, setOpenModalGenerateContract] = useState<boolean>(false);
  const { count, idRemove, estimateCart } = useEstimateCart();
  const toast = useToast();
  const [isSearch, setIsSearch] = useState(false);

  const initialDate = moment().format('YYYY-MM-DD');
  const initialDateSplitted = initialDate.split('-');
  const initialDay = Number(initialDateSplitted[2]);
  const initialMonth = initialDateSplitted[1];

  const finalDate = initialMonth === '01' && initialDay >= 29 ? `${moment().year()}-03-05`
    : moment().add(1, 'month').format('YYYY-MM-DD');

  const [checkinDate, setCheckinDate] = useState<string>(initialDate);
  const [checkoutDate, setCheckoutDate] = useState<string>(finalDate);

  const {
    category, location, bathrooms, setOrder, order: ordernation,
  } = useEstimateFilterValues();

  const {
    isLoading,
    data: monthlybudgetData,
    isSuccess,
    isFetching,
  } = useEstimateQuery(
    checkinDate, checkoutDate, isSearch, category, location, bathrooms, ordernation,
  );

  const DEFAULT_LIMIT = 9;
  const [limitMonthlybudgetData, setLimitMonthlybudgetData] = useState<number>(0);
  const [textLoadMoreMonthlybudgetData, setTextLoadMoreMonthlybudgetData] = useState<string>('Mostar mais');

  useEffect(() => {
    if (isSuccess && monthlybudgetData?.results !== null
      && monthlybudgetData?.results !== undefined && monthlybudgetData?.results.length > 0) {
      setLimitMonthlybudgetData(monthlybudgetData.results.length > DEFAULT_LIMIT ? DEFAULT_LIMIT
        : monthlybudgetData?.results?.length);
    }
  }, [monthlybudgetData]);

  const handleCloseModalLateral = () => {
    setOpenModalLateral(false);
  };
  const handleCloseModalMonthly = () => {
    setOpenModalGenerateContract(false);
  };

  const handleOpenModalMonthly = () => {
    if (estimateCart?.categories.length === 1
      && estimateCart?.categories[0].properties.length === 1) {
      if (estimateCart.categories[0].properties[0].monthlyPrice[0].value === 'no monthly price found for this category') {
        toast.error('Imóvel sem valor mensal cadastrado!');
      } else {
        setOpenModalGenerateContract(true);
      }
    } else {
      toast.error('Para prosseguir você pode ter apenas um imóvel no carrinho');
    }
  };
  const handleCheckProperty = (id: number, check: boolean) => {
    monthlybudgetData?.results.map((item) => {
      const itemAux = item;
      if (item.id === id) {
        itemAux.intoCart = !check;
      }
      return itemAux;
    });
  };

  const handleOrderChange = (ordering: string) => {
    setOrder(ordering);
  };

  const handleOpenLateralModal = () => {
    setOpenModalLateral(true);
  };

  const handleLoadNext = () => {
    setLimitMonthlybudgetData(monthlybudgetData?.results
      && limitMonthlybudgetData >= monthlybudgetData?.results?.length
      ? DEFAULT_LIMIT : limitMonthlybudgetData + DEFAULT_LIMIT);
  };

  const handleLoadPrev = () => {
    setLimitMonthlybudgetData(monthlybudgetData?.results
      && limitMonthlybudgetData >= monthlybudgetData?.results?.length
      ? DEFAULT_LIMIT : limitMonthlybudgetData - DEFAULT_LIMIT);
  };

  useEffect(() => {
    handleCheckProperty(idRemove, true);
  }, [idRemove]);

  useEffect(() => {
    if (monthlybudgetData?.results
      && limitMonthlybudgetData >= monthlybudgetData?.results?.length) {
      setTextLoadMoreMonthlybudgetData('Mostrar menos');
    } else {
      setTextLoadMoreMonthlybudgetData('Mostrar mais');
    }
  }, [limitMonthlybudgetData]);

  return (
    <>
      <Cart data-cy="cart" onClick={handleOpenLateralModal}>
        <Quantity>{count}</Quantity>
        <CartIcon />
      </Cart>
      <Container>
        <Title>
          <strong>Preços mensais,</strong>
          {' '}
          busque o imóvel aqui!
        </Title>
        <HeaderFilter
          checkinDate={checkinDate}
          checkoutDate={checkoutDate}
          setCheckinDate={setCheckinDate}
          setCheckoutDate={setCheckoutDate}
          setIsSearch={setIsSearch}
        />
        <Row>
          <div>
            <SecondaryTitleDiv>
              <SecundaryTitle>Resultado</SecundaryTitle>
              {isFetching && !isLoading && (
              <CircularDiv>
                <CircularProgress size={20} />
              </CircularDiv>
              )}
            </SecondaryTitleDiv>
            {isLoading && (<CountShimmer />)}
            {isSuccess && (
              (
                <QuantityProperties data-cy="quantity-properties">
                  {monthlybudgetData?.count}
                  {' '}
                  imóveis
                </QuantityProperties>
              )
            )}
          </div>
          <Ordination>
            <SimpleSelect
              id="ordenar"
              placeholder="Ordenar"
              options={(order || []).map<SelectOption>(({
                id,
                name,
              }) => ({ value: id, valueLabel: name }))}
              onChange={
              (e) => handleOrderChange(e.target.value as string)
            }
            />
          </Ordination>
        </Row>
        <ContentProperties>
          {isLoading && (
          <>
            <CardShimmer />
            <CardShimmer />
            <CardShimmer />
            <CardShimmer />
            <CardShimmer />
            <CardShimmer />
          </>
          )}
          {!isSearch ? (
            <>
              <InicialText>
                Selecione os filtros e clique em ‘buscar’ para carregar os imóveis.
              </InicialText>
            </>
          ) : (
            <>
              {isSearch && isSuccess
                  && monthlybudgetData?.results.length !== 0
                ? monthlybudgetData?.results.slice(0, limitMonthlybudgetData).map((property) => (
                  <>
                    <CardProperty
                      code={property.code}
                      address={property.address}
                      guest={property.guest_capacity}
                      bedroom={property.bedroom_quantity.toString()}
                      category={property.category.code}
                      id={property.id}
                      key={property.id}
                      intoCart={property.intoCart !== undefined && property.intoCart !== false}
                      handleCheckProperty={handleCheckProperty}
                      totalPrice={property.total_price}
                      monthlyPrice={property.monthly_price}
                    />
                  </>
                )) : (
                  <NotFound>
                    <img src={NonePropertyIcon} alt="Nenhuma prorpriedade" />
                    <p>Nenhuma propriedade encontrada</p>
                  </NotFound>
                )}
            </>
          )}

        </ContentProperties>
        {isSuccess && monthlybudgetData !== undefined && monthlybudgetData.results
            && monthlybudgetData.results?.length > DEFAULT_LIMIT && (
            <ContainerLoadMoreDatas>
              <ContentLoadMoreDatas onClick={() => handleLoadNext()}>
                <p>
                  {textLoadMoreMonthlybudgetData}
                </p>
                {monthlybudgetData.results
                  && limitMonthlybudgetData >= monthlybudgetData.results.length
                  ? <ArrowUpIcon />
                  : <ArrowDownIcon />}
              </ContentLoadMoreDatas>
              {limitMonthlybudgetData > DEFAULT_LIMIT && textLoadMoreMonthlybudgetData !== 'Mostrar menos' && (
              <ContentLoadMoreDatas
                onClick={() => handleLoadPrev()}
              >
                <p>Mostrar menos</p>
                <ArrowUpIcon />
              </ContentLoadMoreDatas>
              )}
            </ContainerLoadMoreDatas>
        )}
        {openModalLateral && (
          <ModalLateral
            checkinDate={checkinDate}
            checkoutDate={checkoutDate}
            handleCloseModalLateral={handleCloseModalLateral}
            handleOpenModalMonthly={handleOpenModalMonthly}
          />
        )}

        {openModalGenerateContract && (
        <ModalGenerateContract
          checkin={checkinDate}
          checkout={checkoutDate}
          handleCloseModalLateral={handleCloseModalMonthly}
        />
        )}

      </Container>
    </>
  );
};

export default EstimateComponent;
