import styled, { keyframes } from 'styled-components';

const animationTime = '0.25s';

export const loadAnimation = keyframes`
  from{
    opacity: 0;
    width: -0%;
    filter: blur(5px)
  }
  to{
    filter: blur(0px)
    opacity: 1;
    width: 100%;
  }
`;

export const laodButton = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div``;

export const ButtonsContainer = styled.div<{
  mobile: boolean | undefined;
}>`
  width: 100%;
  transition: 1s;
  height: ${({ mobile }) => (mobile ? '11vh' : '9vh')};
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: ${({ theme }) => theme.palette.white._400.hex()};
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  bottom: 0;
  right: 0;
  align-items: center;
  button,
  svg {
    position: relative;
    margin: ${({ mobile }) => (!mobile && '0 7rem')};
    margin: ${({ mobile }) => (mobile && '2rem 3rem')};
    padding: ${({ mobile }) => (mobile && '1.5rem')};
    max-width: ${({ mobile }) => (!mobile && '8vw')};
    cursor: pointer;
    padding: ${({ mobile }) => (!mobile && '0')};
    @media (min-width: 2000px) {
      padding: 2.3vh 0;
    }
  }

  @media (min-width: 2000px) {
    height: 9vh;
  }
  
  opacity: 0;
  animation-name: ${laodButton};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 0.3s;
`;
