import styled from 'styled-components';

export const ContainerCard = styled.div`
  width: 100%;
  height: auto;
  
  border-radius: 10px;
  padding: 1rem;
`;

export const TitleProperty = styled.div`
  margin-top: 10px;

  a {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    color: ${({ theme }) => theme.palette.grey._900.hex()};
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    height: 30px;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.palette.grey._720.hex()};
    }
  }
`;

export const ResumeInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 3px;
  height: 28px;

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
  }
`;

export const Price = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #D9DCDF;

  p {
    padding-top: 10px;
    padding-bottom: 5px;
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const ValorTotal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Total = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export const Sep = styled.div`
  display: flex;
`;

export const ValueTotal = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

export const CashValue = styled.div`
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: #219653;
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
`;
export const Image = styled.img`
  width: 100%;
  height: 108px;
  object-fit: cover;
  border-radius: 10px;
`;

export const IconImage = styled.img`
  margin-right: 10px;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
`;
