import Lottie from 'lottie-react';
import React from 'react';

import confirmAnimation from '../source/confirmationAnimation.json';

export const ConfirmationAnimation = () => (
  <Lottie
    animationData={confirmAnimation}
  />
);
