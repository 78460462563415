import { Container, SwitcherOption } from './style';
import { IOSSwitch } from '../../../../SwitcherButton/SwitcherButton';

interface ISwitcher {
  active: boolean;
  onChange: Function;
}

const Switcher = ({
  active,
  onChange,
}: ISwitcher) => (
  <Container>
    <SwitcherOption active={!active}>Não</SwitcherOption>
    <IOSSwitch
      value={active}
      checked={active}
      onChange={() => onChange()}
    />
    <SwitcherOption active={active}>Sim</SwitcherOption>
  </Container>
);

export default Switcher;
