import styled, { keyframes } from 'styled-components';
import { ICustomPosition } from './types';

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  } to {
    opacity: 1;
    transform: scale(1);
  }
`;

const finalRotateDEG = 134;

const rotateIN = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(${finalRotateDEG}deg);
  }
`;
const rotateOUT = keyframes`
  from {
    transform: rotate(${finalRotateDEG}deg);
  } to {
    transform: rotate(0deg);
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;

  translate: 0.5s;

  background: #ffffff;
  opacity: 0.5;
`;

export const Container = styled.div<{
  open: boolean;
  customPosition?: ICustomPosition;
}>`
  z-index: 10;
  position: fixed;

  right: ${({ customPosition }) => (customPosition?.desktop?.right ? customPosition?.desktop?.right : '5rem')};
  bottom: ${({ customPosition }) => (customPosition?.desktop?.bottom ? customPosition?.desktop?.bottom : '5rem')};

  opacity: 0;
  animation: ${zoomIn} 0.4s 0.1s ease forwards;

  .fabmain__icon {
    transition: 0.3s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-name: ${({ open }) => (open ? rotateIN : rotateOUT)};
  }

  .fabmain__container {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    background: ${({ open, theme }) => (open ? theme.palette.blue._930.hex() : theme.palette.blue._920.hex())};

    &:focus {
      background: ${({ open, theme }) => (open ? theme.palette.blue._930.hex() : theme.palette.blue._920.hex())};
    }
  }

  @media (max-width: 900px) {
    right: ${({ customPosition }) => (customPosition?.mobile?.right ? customPosition?.mobile?.right : '20px')};
    bottom: ${({ customPosition }) => (customPosition?.mobile?.bottom ? customPosition?.mobile?.bottom : '20px')};
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    opacity: 0;

    &:nth-child(n) {
      animation: ${zoomIn} 0.2s 0.05s ease forwards;
    }

    &:nth-child(2n) {
      animation: ${zoomIn} 0.2s 0s ease forwards;
    }
  }

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;
`;
