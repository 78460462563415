/* eslint-disable new-cap */
import { RefObject, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const UNIT_PX_TO_MM = 0.26458333333; // 1 pixel is equal to 0.26458333333 millimeters

type PDFOrientationProps = 'portrait' | 'landscape';

type PDFProps = {
  orientation?: PDFOrientationProps,
  filename?: string,
};

export function useConversorHtmlToPdf() {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleConvertHtmlToPdf = async (componentRef: RefObject<HTMLDivElement>,
    pdfProps?: PDFProps) => {
    setIsDownloading(true);

    const component = componentRef?.current;

    if (component) {
      await html2canvas(component, { scale: 2 }).then((canvas) => {
        const image = canvas.toDataURL('image/png', 1);

        const componentWidthInMM = Math.ceil(component.clientWidth * UNIT_PX_TO_MM);
        const componentHeightInMM = Math.ceil(component.clientHeight * UNIT_PX_TO_MM) + 50;

        const pdf = new jsPDF({
          orientation: pdfProps?.orientation || 'portrait',
          format: [componentWidthInMM, componentHeightInMM],
          unit: 'mm',
          compress: true,
          putOnlyUsedFonts: true,
        });

        const imageProps = pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imageProps.height * pdfWidth) / imageProps.width;

        const positionX = 0;
        const positionY = 0;

        pdf.addImage(
          image,
          'PNG',
          positionX,
          positionY,
          pdfWidth,
          pdfHeight,
        );

        pdf.save(`${pdfProps?.filename || 'filename'}.pdf`);
        setIsDownloading(false);
      }).catch(() => {
        setIsDownloading(false);
      });
    }
  };

  return {
    handleConvertHtmlToPdf,
    isDownloading,
  };
}
