import React from 'react';

import {
  Title,
  Image,
  CardTitle,
  Container,
  Description,
  CardContent,
  Button,
  ButtonContainer,
} from './style';

import { ICard } from '../types';
import { useSteps } from '../../context';
import { CheckBox } from '../Checkbox/Checkbox';

const Mobile = ({ card, image, title }: ICard) => {
  const {
    options,
    filterButtons,
    handlePrevStep,
    handleNextStep,
    handleFinish,
  } = useSteps();

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {image && <Image>{image}</Image>}
      <CardContent className="popup-card">
        <CardTitle>{card?.title}</CardTitle>
        <Description>{card.description}</Description>
      </CardContent>

      <ButtonContainer>
        {filterButtons?.map((auxButton) => (
          <Button
            type="button"
            key={auxButton.text}
            whileTap={{ scale: 0.9 }}
            variant={auxButton?.style}
            onClick={() => auxButton?.onClick({
              finish: handleFinish,
              nextStep: handleNextStep,
              prevStep: handlePrevStep,
            })}
          >
            {auxButton?.text}
          </Button>
        ))}
      </ButtonContainer>

      {options?.showNotShowAgainCheckbox?.is && <CheckBox />}
    </Container>
  );
};

export default Mobile;
