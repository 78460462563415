import { motion } from 'framer-motion';
import styled from 'styled-components';
import { shimmerAnimation } from '../../../../../../../Calendar/LoadingShimmer/animationShimmer';
import { fadeInAnimation } from '../../utils';

export const Content = styled(motion.div)<{ isOpen: boolean }>`
  gap: 30px;
  padding: 0rem 2rem 1rem;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const UploadFilesContainer = styled.div``;
export const UploadedFilesContainer = styled.div`
  max-height: 100px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-top: -0.5rem;
`;
export const AddButton = styled.button`
  gap: 5px;
  display: flex;
  cursor: pointer;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  align-items: center;
  transition: 0.3s ease;
  flex-direction: column;
  background: transparent;
  border: 1px solid #c4c4c4;
  color: rgba(0, 0, 0, 0.7);
  justify-content: space-between;

  &:hover {
    background: #efefef;
    transform: translateY(-5px);
  }

  position: relative;

  input {
    top: 0;
    left: 0;
    /* opacity: 0; */
    width: 100%;
    z-index: 100;
    height: 100%;
    cursor: pointer;
    /* position: absolute; */
  }
`;

export const FilePreview = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: #d9d9d9;
  border-radius: 100px;
  border: 1px solid #cccc;
`;

export const FilePreviewShimmer = styled.div`
  width: 20px;
  height: 20px;
  background: #d9d9d9;
  border-radius: 100px;
  border: 1px solid #cccc;
  animation: ${shimmerAnimation} 2s ease infinite;
`;

export const FileSeparator = styled.div`
  height: 3px;
  width: 100%;
  background: #d9d9d9;
  border-radius: 5px;
`;

export const FilesContainer = styled.div`
  gap: 10px;
  opacity: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;

  animation: ${fadeInAnimation} ease 0.3s forwards;
`;

export const ImageTooltip = styled.img`
  margin: 0;
  width: 100px;
  height: 100px;
  padding: 0 -20px;
  border-radius: 0;
  object-fit: cover;
`;

export const FileDeleteIconSVG = styled.div`
  cursor: pointer;
`;

export const FileName = styled.h1`
  font-weight: normal;
  cursor: pointer;
`;
