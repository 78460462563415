import * as React from 'react';

import { CircularProgress } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import {
  Error,
  Label,
  Section,
  SubText,
  FormButton,
  FormContent,
  SelectStyled,
  InputMaskStyled,
} from '../../style';

import {
  ErrorEarning,
  InputEarning,
  RadioGroupEarning,
} from '../../components';

import { useStepOne } from './logic';
import { validateError } from '../../components/Input/Input';

import Banks from '../../components/Banks/Banks';
import SavedBanks from '../../components/SavedBanks/SavedBanks';
import PixKeyInput from '../../components/PixKeyInput/PixKeyInput';
import SuccessPage from '../../../../../../components/SuccessPage';
import { usePartners } from '../../../../../../../../context/Partners/Partner/PartnerContext';

const One = () => {
  const navigate = useNavigate();

  const {
    thereIsBankDetails,
  } = usePartners();

  const {
    formik,
    success,
    loading,
    formValues,
    isDisabledButton,
  } = useStepOne();

  if (success) {
    return (
      <SuccessPage
        onClose={() => {
          navigate('/parceiros/ganhos');
        }}
      />
    );
  }

  return (
    <FormContent onSubmit={formik.handleSubmit}>
      {thereIsBankDetails && <SavedBanks formik={formik} />}

      <Section>
        <Label>Banco</Label>
        <SubText>
          Escolha um entre os bancos abaixo
        </SubText>
        <Banks formik={formik} />
      </Section>

      <Section>
        <Label>CPF / CNPJ</Label>
        <SubText>
          Selecione uma das opções abaixo e insira o número do seu CPF ou CNPJ
        </SubText>

        <RadioGroupEarning
          formik={formik}
          id="cpfOrCnpj"
          isRow
          options={[
            {
              label: 'CPF',
              value: 'cpf',
            },
            {
              label: 'CNPJ',
              value: 'cnpj',
            },
          ]}
        />

        <InputMaskStyled
          formik={formik}
          id="cpfOrCnpjValue"
          initial={formValues.cpfOrCnpjValue}
          customValue={formik.values.cpfOrCnpjValue}
          error={validateError(formik, 'cpfOrCnpjValue')}
          option={formik.values.cpfOrCnpj === 'cpf' ? 'cpf' : 'cnpj'}
          placeholder={
            formik.values.cpfOrCnpj === 'cpf'
              ? 'Ex: 000.000.000-00'
              : 'Ex: 00.000.000/0000-00'
          }
        />
        <ErrorEarning validate={validateError(formik, 'cpfOrCnpjValue')}>
          {formik.errors.cpfOrCnpjValue}
        </ErrorEarning>
      </Section>

      <Section>
        <Label>Valor do resgate</Label>
        <InputMaskStyled
          option="money"
          formik={formik}
          id="rescueValue"
          placeholder="Ex: R$ 2500,00"
          initial={formValues.rescueValue}
          error={validateError(formik, 'rescueValue')}
        />
        <ErrorEarning validate={validateError(formik, 'rescueValue')}>
          {formik.errors.rescueValue}
        </ErrorEarning>
      </Section>

      <Section>
        <Label>Responda somente se você for CNPJ</Label>
        <RadioGroupEarning
          formik={formik}
          id="emailNF"
          options={[
            {
              label:
                'Enviei a NF por e-mail para administrativo@seazone.com.br',
              value: 'sendEmail',
            },
            {
              label:
                'Ainda não enviei, mas estou ciente de que o pagamento só será realizado após o envio',
              value: 'notSendEmail',
            },
          ]}
        />
        <ErrorEarning validate={validateError(formik, 'emailNF')}>
          {formik.errors.emailNF}
        </ErrorEarning>
      </Section>

      <InputEarning
        formik={formik}
        id="favoredName"
        title="Nome do Favorecido"
        placeholder="Ex: Silvio"
      />

      <Section>
        <Label>Quer receber por PIX?</Label>
        <RadioGroupEarning
          formik={formik}
          id="wantToReceiveByPix"
          options={[
            {
              label: 'Sim',
              value: 'yes',
            },
            {
              label: 'Não, preciso receber por transferência bancária normal',
              value: 'no',
            },
          ]}
        />
      </Section>

      <Section disabled={formik.values.wantToReceiveByPix === 'no'}>
        <Label>Tipo de chave PIX</Label>
        <SubText>
          Se você não possuir chave PIX, escolha a opção
          {' '}
          {'"Não tenho chave"'}
        </SubText>
        <SelectStyled
          id="typePixKey"
          placeholder="Selecione"
          onChange={formik.handleChange}
          value={formik.values.typePixKey}
          error={validateError(formik, 'typePixKey')}
        >
          <option>Não tenho chave</option>
          <option value="CPF">CPF</option>
          <option value="CNPJ">CNPJ</option>
          <option value="Email">E-mail</option>
          <option value="Random">Aleatória</option>
          <option value="Phone_Number">Telefone</option>
        </SelectStyled>
        <ErrorEarning validate={validateError(
          formik, 'typePixKey', true,
        )}
        >
          {formik.errors.typePixKey}
        </ErrorEarning>
      </Section>

      <PixKeyInput formik={formik} />

      <InputEarning
        formik={formik}
        id="bankAgency"
        disabled={formik.values.wantToReceiveByPix === 'yes'}
        title="Agência"
        placeholder="Ex: 0000"
      />

      <Section disabled={formik.values.wantToReceiveByPix === 'yes'}>
        <Label>Tipo de conta</Label>
        <SelectStyled
          id="bankAccountType"
          placeholder="Selecione"
          disabled={formik.values.wantToReceiveByPix === 'yes'}
          onChange={formik.handleChange}
          value={formik.values.bankAccountType}
        >
          <option defaultChecked value="Selecione">Selecione</option>
          <option value="Joint_Checking_Account">
            Conta corrente conjunta
          </option>
          <option value="Saving_Account">Conta poupança</option>
          <option value="Individual_Checking_Account">
            Conta corrente individual
          </option>
        </SelectStyled>
        {validateError(
          formik, 'bankAccountType', false,
        ) && (
          <Error>{formik.errors.bankAccountType}</Error>
        )}
      </Section>

      <InputEarning
        formik={formik}
        id="bankAccountNumber"
        title="Número da conta"
        subtext="Com o dígito"
        disabled={formik.values.wantToReceiveByPix === 'yes'}
        placeholder="Ex: 00000"
      />

      <FormButton type="submit" disabled={isDisabledButton()}>
        {loading ? (
          <>
            <span>Enviando</span>
            <CircularProgress size={20} />
          </>
        ) : (
          'Enviar'
        )}
      </FormButton>
    </FormContent>
  );
};

export default One;
