import {
  createContext,
  useState,
  ReactNode,
  useContext,
} from 'react';

import { AccountOwnerOnboardingProps, PipedriveOwnerInfo } from '../../services/Onboarding/types';

import {
  DatesType,
  PropertyInformation,
} from './types';

interface OnboardingHandoverContextData {
  owner: AccountOwnerOnboardingProps;
  setOwner: (a: AccountOwnerOnboardingProps) => void;
  dates: DatesType;
  setDates: (a: DatesType) => void;
  reloadOwners: boolean;
  setReloadOwners: (a: boolean) => void;
  dealID: string;
  setDealID: (a: string) => void;
  infosPipeDrive: PipedriveOwnerInfo;
  setInfosPipeDrive: (a: PipedriveOwnerInfo) => void;
  propertyDetails: PropertyInformation;
  setPropertyDetails: (a: PropertyInformation) => void;
  resetFormik: boolean;
  setResetFormik: (a: boolean) => void;
}

interface Props {
  children: ReactNode;
}

const OnboardingHandoverContext = createContext<OnboardingHandoverContextData>({} as
  OnboardingHandoverContextData);

export const OnboardingHandoverProvider = ({
  children,
}: Props) => {
  const [owner, setOwner] = useState<AccountOwnerOnboardingProps>({} as AccountOwnerOnboardingProps);
  const [dates, setDates] = useState<DatesType>({} as DatesType);
  const [reloadOwners, setReloadOwners] = useState<boolean>(false);
  const [dealID, setDealID] = useState('');
  const [propertyDetails, setPropertyDetails] = useState<PropertyInformation>({} as PropertyInformation);
  const [infosPipeDrive, setInfosPipeDrive] = useState<PipedriveOwnerInfo>({} as PipedriveOwnerInfo);
  const [resetFormik, setResetFormik] = useState<boolean>(false);

  return (
    <OnboardingHandoverContext.Provider value={{
      owner,
      setOwner,
      dates,
      setDates,
      reloadOwners,
      setReloadOwners,
      dealID,
      setDealID,
      infosPipeDrive,
      setInfosPipeDrive,
      propertyDetails,
      setPropertyDetails,
      resetFormik,
      setResetFormik,
    }}
    >
      {children}
    </OnboardingHandoverContext.Provider>
  );
};

export function useOnboardingHandover(): OnboardingHandoverContextData {
  const {
    owner,
    setOwner,
    dates,
    setDates,
    reloadOwners,
    setReloadOwners,
    dealID,
    setDealID,
    infosPipeDrive,
    setInfosPipeDrive,
    propertyDetails,
    setPropertyDetails,
    resetFormik,
    setResetFormik,
  } = useContext(OnboardingHandoverContext);

  return {
    owner,
    setOwner,
    dates,
    setDates,
    reloadOwners,
    setReloadOwners,
    dealID,
    setDealID,
    infosPipeDrive,
    setInfosPipeDrive,
    propertyDetails,
    setPropertyDetails,
    resetFormik,
    setResetFormik,
  };
}
