import styled, { keyframes } from 'styled-components';

const Rotate = keyframes`
  0%  {
    transform: rotate(0);
  }
  20%  {
    transform: rotate(0);
  }
  80% {
    transform: rotate(720deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

const Heartbeat = keyframes`
  0%  {
    transform: scale(.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.75);
  }
`;

const Show = keyframes`
  0%  {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div<{ show: boolean; }>`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1500;
  background-color: rgba(0,0,0,.8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s ease-in-out;
  animation-name: ${Show};
  animation-duration: .5s;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const ContainerLogo = styled.div`
  width: 224px;
  position: relative;
  animation-name: ${Heartbeat};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;

export const Leeters = styled.img`
  transform-origin: center;
`;

export const Icon = styled.img`
  position: absolute;
  left: 60px;
  top: -6px;
  animation-name: ${Rotate};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;
