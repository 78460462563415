import React from 'react';

import { Value } from '../styles';
import { CategoryContainer } from './styles';

interface CategoryProps {
  dataCy: string;
  category: string;
}

const Category = ({ dataCy, category }: CategoryProps) => (
  <CategoryContainer>
    <Value data-cy={dataCy}>{category}</Value>
  </CategoryContainer>
);

export default Category;
