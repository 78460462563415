import React, { useState } from 'react';

import sendEmailIcon from '../../../../../assets/icons/generals/sendEmail.svg';

import { ConfirmationEmailAnimation } from '../../../../../assets/animations';

import {
  Container,
  ButtonsContainer,
  IconContainer,
  ConfirmationTitle,
  IconContainerConfirmation,
} from './styles';

import { emailNotification } from '../../../../../services/CompReservations/request';
import { useCompleteReservation } from '../../../../../hooks/CompleteReservationHook/useCompleteReservation';

interface SendEmailProps {
  onCloseScreen: Function;
  onCloseModal: Function;
}

const SendEmail = ({
  onCloseScreen,
  onCloseModal,
}: SendEmailProps) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { reservationId } = useCompleteReservation();

  const sendEmail = async () => {
    try {
      setIsConfirmed(true);
      await emailNotification({ reservation: reservationId });
      setTimeout(() => {
        onCloseModal();
        setIsConfirmed(false);
      }, 5200);
    } catch (error) {
      //
    }
  };

  return (
    isConfirmed ? (
      <>
        <Container>
          <IconContainerConfirmation>
            <ConfirmationEmailAnimation />
            <ConfirmationTitle>Email enviado com sucesso !</ConfirmationTitle>
          </IconContainerConfirmation>
        </Container>
      </>
    ) : (
      <>
        <Container>
          <IconContainer>
            <img src={sendEmailIcon} alt="Enviar email icone" />
            <h1>Enviar Email ?</h1>
          </IconContainer>
          <ButtonsContainer>
            <button type="button" onClick={() => onCloseScreen()}>Voltar</button>
            <button type="button" onClick={() => sendEmail()}>Sim</button>
          </ButtonsContainer>
        </Container>
      </>
    )
  );
};

export default SendEmail;
