import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div``;

export const ArrowsContainer = styled.div`
  position: relative;
  height: 100%;
  width: 30px;
`;
export const ArrowContent = styled(motion.div)`
  position: absolute;
  top: 20%;

  svg {
    width: 20px;
  }
`;
