import styled, { keyframes } from 'styled-components';
import CountrySelector from '../../CountrySelector/CountrySelector';

const OpenSlide = keyframes`
  from {
    right: -457px;
    opacity: 0.2;
  }
  to {
    opacity: 1;
    right: 0;

  }
`;

export const StyledCountrySelector = styled(CountrySelector)`
  &&&.react-tel-input {
    width: 100%;
    height: 100%;

    input {
      height: 43px;
      border: 1px solid rgba(0, 0, 0, 0.33);
    }

    .flag-dropdown {
      border: 1px solid rgba(0, 0, 0, 0.33);
    }
  }
`;

export const ModalContainer = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  z-index: 40;
  left: 0;
  display: ${({ isModalOpen }) => (isModalOpen ? 'flex' : 'none')};
  justify-content: flex-end;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
`;

export const ModalCard = styled.div`
  animation-name: ${OpenSlide};
  animation-duration: 0.7s;
  animation-fill-mode: both;
  width: 457px;
  height: 98%;
  padding: 19px 27px;
  background-color: ${({ theme }) => theme.palette.white._300.hex()};
  border-radius: 10px 0 0 10px;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 41;
  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.grey._320.hex()};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.white._300.hex()};
    border-left: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
    border-right: 1px solid ${({ theme }) => theme.palette.grey._320.hex()};
  }
`;

export const CloseContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 43px;
  margin-left: -70px;
  top: 10px;
  margin-top: 10px;
  position: fixed;
  z-index: 42;
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  border: none;

  p {
    font-size: 10px;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  svg {
    fill: ${({ theme }) => theme.palette.orange.main.hex()};
  }

  :hover  {
    background-color: ${({ theme }) => theme.palette.grey._260.hex()};
    color: ${({ theme }) => theme.palette.orange._600.hex()};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.blue._950.hex()};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  font-size: 18px;
  line-height: 21px;
`;

export const InputsContainer = styled.form`
  margin-top: 26px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const InputSpace = styled.div`
  margin-bottom: 19px;
`;

export const DoubleInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: baseline;
`;

export const AddressTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-weight: ${({ theme }) => theme.fonts.weights.bold};
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.blue._950.hex()};
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  background: ${({ theme }) => theme.palette.white._400.hex()};
  width: 100%;
  margin-bottom: 10px;
`;

export const SwitchContainer = styled.div<{ flexDirection?: string }>`
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  align-items: center;
  justify-content: space-between;
  width: 100%;

  label {
    width: 100%;
    text-align: start;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.palette.black._100.hex()};
  }

  div {
    position: relative;
    right: 2rem;
  }
`;

export const ButtonPipedrive = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
`;
