import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  cursor: pointer;

  svg {
    path {
      fill: white;
    }
  }
`;
