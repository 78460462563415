import styled from 'styled-components';

type GridOptions = 'inprogress' | 'historic';

const GRID_INPROGRESS_WIDTH = '0.5fr 0.5fr 1.2fr 1.2fr 1fr 0.8fr';
const GRID_HISTORIC_WIDTH = '0.5fr 0.5fr 1.2fr 1.2fr 1fr';

const getGridWidth: Record<GridOptions, string> = {
  inprogress: GRID_INPROGRESS_WIDTH,
  historic: GRID_HISTORIC_WIDTH,
};

export const ContainerArrows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  img {
    padding: 0.05rem 0 0.05rem 0.5rem;
    cursor: pointer;
  }
`;

export const Image = styled.img``;

export const InfoOutlinedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 8px 0 4px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 16px;
  padding-bottom: 50px;
`;

export const GridContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 270px;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-radius: 10px;
`;

export const GridHeaderContainer = styled.div<{ activeGrid: GridOptions }>`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: ${({ activeGrid }) => getGridWidth[activeGrid]};
`;

export const ContentInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 50px;
  width: 100%;

  p {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-style: normal;
    font-weight: 400;
    font-size: 0.985rem;
    line-height: 15px;
  }
`;

export const GridHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    width: fit-content;
  }
  
  div {
    width: fit-content;
  }
`;

export const GridHeaderTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: ${({ theme }) => theme.palette.blue._250.hex()};
  text-align: start;
`;

export const GridBodyContainer = styled.div<{ activeGrid: GridOptions }>`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: ${({ activeGrid }) => getGridWidth[activeGrid]};
`;

export const GridBodyContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
  height: 60px;
`;

export const GridBodyTitle = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.familys._6};
  color: #161616;
  hyphens: auto;
  -webkit-hyphens: auto;
  max-width: 250px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;

  :hover {
    background: #CCE4FF;
  }

  :last-child {
    margin-left: 24px;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey._425.hex()};
`;

export const CardContentLeft = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
`;

export const CardContentRight = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 0;
`;

export const FormButtonContainer = styled.div`
  button {
    margin-top: 8px;
    width: 78%;
    gap: 10px;
  }
`;
