import ControlledSwitchButton from '../../../ControlledSwitchButton';
import { Container } from './styles';

type Amenities = {
  name: string,
  key: string,
  value: boolean,
};

type CardPropertyAmenitiesProps = {
  amenities: Amenities,
  updateAmenitie: Function,
  required?: boolean;
};

const CardPropertyAmenities = ({
  amenities, updateAmenitie, required,
}: CardPropertyAmenitiesProps) => (
  <Container>
    <h2>
      {required && (<span>*</span>)}
      {amenities.name}
    </h2>
    <ControlledSwitchButton
      checked={amenities.value}
      handleChange={() => updateAmenitie(amenities.key)}
    />
  </Container>
);

CardPropertyAmenities.defaultProps = {
  required: false,
};

export default CardPropertyAmenities;
