/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import Header from './Header/Header';
import Body from './Body/Body';

import { Container, BodyContainer } from './styles';

import { usePaymentVoucher } from '../../../../hooks/PaymentVoucherHook/usePaymentVoucher';
import { PaymentItemsProps } from '../../../../context/PaymentVoucher/types/paymentStatus.types';

import SelectedItems from '../SelectedItems';

import { ColorProps, CardDataTypeProps } from '../types';
import { formatteMoneyVoucher } from '../../../../services/PaymentVoucher/utils';

interface GridProps {
  color: ColorProps;
  data: PaymentItemsProps[];
  isSelectedItemsOpen: boolean;
  cardDataType?: CardDataTypeProps;
}

const Grid = ({
  data,
  color,
  cardDataType,
  isSelectedItemsOpen,
}: GridProps) => {
  const {
    isTwoCardOpen,
    checkedCounter,
    paymentDataStatus,
    handleChangeModalOpen,
    handleChangePaymentDataStatus,
  } = usePaymentVoucher();

  const getPaymentDataStatusCompletesOrPendings = () => (cardDataType === 'completes'
    ? paymentDataStatus.completes
    : paymentDataStatus.pendings);

  const handleCancelAllSelectedItems = () => {
    const newPaymentDataStatusCardType = getPaymentDataStatusCompletesOrPendings();

    const newPaymentDataStatusChecked = newPaymentDataStatusCardType.map((item) => ({
      ...item,
      checked: false,
    }));

    if (cardDataType === 'pending') {
      handleChangePaymentDataStatus({
        ...paymentDataStatus,
        pendings: newPaymentDataStatusChecked,
      });
    } else {
      handleChangePaymentDataStatus({
        ...paymentDataStatus,
        completes: newPaymentDataStatusChecked,
      });
    }
  };

  return (
    <>
      {/* {checkedCounter > 0 && (
        <SelectedItems
          counter={checkedCounter}
          handleOpenModal={() => handleChangeModalOpen(true)}
          handleCancelSelectedItemsModal={() => handleCancelAllSelectedItems()}
        />
      )} */}

      <Container>
        <Header />
        <BodyContainer
          isSelectedItemsOpen={isSelectedItemsOpen}
          isTwoCardOpen={isTwoCardOpen}
        >
          {data.map((item, index) => {
            const formattedCode = item.reservationCode.substring(0, 8);
            const formattedTotalValue = formatteMoneyVoucher(item.totalValue);
            const formattedPendingValue = formatteMoneyVoucher(item.pendingValue);

            return (
              <Body
                id={item.id}
                key={item.id}
                color={color}
                index={index}
                checkIn={item.checkin}
                cardDataType={cardDataType}
                totalValue={formattedTotalValue}
                pendingValue={formattedPendingValue}
                reservationId={item.reservationId}
                reservationCode={formattedCode}
                reservationStaff={item.reservationStaff}
              />
            );
          })}
        </BodyContainer>
      </Container>
    </>
  );
};

Grid.defaultProps = {
  cardDataType: 'default',
};

export default Grid;
