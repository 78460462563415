import axios from 'axios';
import request from '../../request';

import {
  FinancialClosePropertyResponse,
  ManualFitParams,
  ManualFitProps,
  // RevenuesPropertyProps,
} from './types';

import {
  formatFinancialDataProperty,
} from './utils';

import { formatDate } from '../../../context/FinancialClosePage/FinancialCloseContext/utils';
import { formatedDateOfRequest } from '../../../utils/Formatter';
import { FinancialCloseParams } from '../types';

export const getFinancialCloseProperty = async (params?: FinancialCloseParams,
  abortController?: any) => {
  try {
    let allParams: any = {
      period: params?.start_date || formatDate(new Date()).start_date,
    };

    if (params?.search) allParams = { ...allParams, search: params.search };

    const url = '/proper_pay/property/monthly/closing/';

    const payload = abortController ? {
      params: {
        ...allParams,
      },
      signal: abortController.signal,
    } : {
      params: {
        ...allParams,
      },
    };

    const { data } = await request.get<FinancialClosePropertyResponse>(url, payload);
    const financialData = formatFinancialDataProperty(data);

    return financialData;
  } catch (error) {
    if (axios.isCancel(error)) {
      return 'Request Canceled!';
    }
    return error;
  }
};

export const getManualFitProperty = async (params?: ManualFitParams) => {
  let allParams: ManualFitParams = {
    start_date: params?.start_date || formatDate(new Date()).start_date,
  };

  if (params?.end_date) allParams = { ...allParams, end_date: params.end_date };
  if (params?.is_adding) allParams = { ...allParams, is_adding: params.is_adding };
  if (params?.property_id) allParams = { ...allParams, property_id: params.property_id };

  const { data } = await request.get<ManualFitProps[]>('/property_manual_fit/', {
    params: allParams,
  });

  const dataFormatted = data.map((item) => ({
    ...item,
    value: Number(item.value),
  }));

  return dataFormatted;
};

export const deleteManualFitProperty = async (id: number) => request.delete(`/property_manual_fit/${id}/`);

export const postManualFitProperty = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    is_adding: data.is_adding,
    property: data.property,
    description: data.description,
  };

  return request.post<ManualFitProps>('/property_manual_fit/', {
    ...body,
  });
};

export const patchManualFitProperty = async (data: ManualFitProps) => {
  const body = {
    date_ref: formatedDateOfRequest(data.date_ref || '', 'DD/MM/YYYY'),
    value: data.value,
    is_adding: data.is_adding,
    property: data.property,
    description: data.description,
  };

  return request.patch<ManualFitProps>(`/property_manual_fit/${data.id}/`, {
    ...body,
  });
};
