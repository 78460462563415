import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';

import SelectModal from '../../SelectModal/SelectModal';
import SelectCheckbox from '../../SelectCheckbox/SelectCheckbox';
import { getLocations } from '../../../services/location/request';
import { RequestLocation } from '../../../services/location/types';
import { useFilterBar } from '../../../context/FilterBar';
import { RequestCategory } from '../../../services/Category/types';
import { getCategories } from '../../../services/Category/request';
import SimpleSelect, { SelectOption } from '../../SimpleSelect/SimpleSelect';
import DatePickerRange from '../../DatePickerRange/DatePickerRange';

import {
  BoldText,
  ButtonSearch,
  Container,
  Divider,
  InputHideSelect,
  InputModal,
  SelectBathroom,
  SelectCategory,
  SelectDate,
  SelectLocation,
} from './styles';

import { useEstimateFilterValues } from '../../../hooks/useEstimate/useEstimatePage';
import { useToast } from '../../../context/ToastContext';

const quantity = [
  {
    id: '1',
    quantityNumber: '1',
  },
  {
    id: '2',
    quantityNumber: '2',
  },
  {
    id: '3',
    quantityNumber: '3',
  },
  {
    id: '4',
    quantityNumber: '4',
  },
  {
    id: '5',
    quantityNumber: '5',
  },
  {
    id: '6',
    quantityNumber: '6',
  },
];

export interface CheckboxOptions{
  value: string | number;
  valueLabel: string;
  checked: boolean;
}

interface Props {
  checkinDate: string;
  checkoutDate: string;
  setCheckoutDate: (arg0: string) => void;
  setCheckinDate: (arg0: string) => void;
  setIsSearch: (arg0: boolean) => void;
}

const HeaderFilter = ({
  checkinDate, checkoutDate, setCheckinDate, setCheckoutDate, setIsSearch,
}: Props) : JSX.Element => {
  const { filterBar } = useFilterBar();
  const [categories, setCategories] = useState<Array<RequestCategory>>();
  const [locations, setLocations] = useState<Array<RequestLocation>>();
  const [openDialogLocation, setOpenDialogLocation] = useState(false);
  const [openDialogCategory, setOpenDialogCategory] = useState(false);
  const { setCategoryArray, setLocationArray, setBathrooms } = useEstimateFilterValues();
  const toast = useToast();

  const getRangeDates = (startDate: string | Date, endDate: string | Date) => {
    const checkinAux = moment(startDate).format('YYYY-MM-DD');
    const checkoutAux = moment(endDate).format('YYYY-MM-DD');

    const a = moment(checkinAux);
    const b = moment(checkoutAux);
    const diff = b.diff(a, 'days');

    return diff;
  };

  const formik = useFormik({
    initialValues: {
      categories: filterBar.categories || ['Selecione'],
      location: filterBar.location || ['Selecione'],
      checkInDate: checkinDate,
      checkOutDate: checkoutDate,
      bathroom: 0,
    },
    onSubmit: async (values) => {
      const range = getRangeDates(values.checkInDate, values.checkOutDate);

      if (range >= 30) {
        const locationAux: string[] = [];
        values.location.forEach((item) => {
          locationAux.push(item.split(' ')[0]);
        });
        setCategoryArray(values.categories);
        setLocationArray(locationAux);
        setBathrooms(values.bathroom);
        setCheckinDate(moment(values.checkInDate).format('YYYY-MM-DD'));
        setCheckoutDate(moment(values.checkOutDate).format('YYYY-MM-DD'));
        formik.setValues({
          ...formik.initialValues,
          checkInDate: moment(values.checkInDate).format('YYYY-MM-DD'),
          checkOutDate: moment(values.checkOutDate).format('YYYY-MM-DD'),
          categories: values.categories,
          location: locationAux,
          bathroom: values.bathroom,
        });
        setIsSearch(true);
      } else {
        toast.error('Selecione um range de datas maior que 30 dias!');
      }
    },
  });

  const getLocationList = async () => {
    const values = await getLocations();
    setLocations(values);
  };

  const getCategoriesList = async () => {
    const values = await getCategories();
    setCategories(values);
  };

  useEffect(() => {
    getLocationList();
    getCategoriesList();
  }, []);

  const handleOutMultipleSelect = (event: any) => {
    if (event.target.id === 'MultipleSelect') setOpenDialogLocation(false);
  };

  const handleClickOpen = () => {
    setOpenDialogLocation(true);
  };

  const handleClose = () => {
    setOpenDialogLocation(false);
  };

  const handleOutMultipleSelectCategory = (event: any) => {
    if (event.target.id === 'MultipleSelect') setOpenDialogCategory(false);
  };

  const handleClickOpenCategory = () => {
    setOpenDialogCategory(true);
  };

  const handleCloseCategory = () => {
    setOpenDialogCategory(false);
  };

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Divider>
          <SelectLocation>
            <BoldText>Local</BoldText>
            <SelectModal
              id="locations"
              dataCy="locations"
              onClick={handleClickOpen}
              value={formik.values.location}
            />
            { openDialogLocation && (
            <>
              <InputHideSelect id="MultipleSelect" onClick={handleOutMultipleSelect} />
              <InputModal className="input-modal-style">
                <SelectCheckbox
                  id="location"
                  formik={formik}
                  defaultValue={formik.values.location}
                  handleClose={handleClose}
                  options={
                (locations || []).map<CheckboxOptions>(({
                  location,
                }) => ({ value: location, valueLabel: location, checked: false }))
              }
                />
              </InputModal>
            </>
            )}
          </SelectLocation>
          <SelectCategory>
            <BoldText>Categoria</BoldText>
            <SelectModal
              id="category"
              dataCy="categories"
              onClick={handleClickOpenCategory}
              value={formik.values.categories}
            />
            { openDialogCategory && (
            <>
              <InputHideSelect id="MultipleSelect" onClick={handleOutMultipleSelectCategory} />
              <InputModal className="input-modal-style">
                <SelectCheckbox
                  id="categories"
                  formik={formik}
                  defaultValue={formik.values.categories}
                  handleClose={handleCloseCategory}
                  options={
                (categories || []).map<CheckboxOptions>(({
                  code,
                }) => ({ value: code, valueLabel: code, checked: false }))
              }
                />
              </InputModal>
            </>
            )}
          </SelectCategory>
          <SelectBathroom>
            <BoldText>Banheiros</BoldText>
            <SimpleSelect
              id="bathroom"
              dataCy="bathroom"
              placeholder="Selecione"
              isEstimate
              formik={formik}
              options={
            (quantity || []).map<SelectOption>(({
              id,
              quantityNumber,
            }) => ({ value: id, valueLabel: quantityNumber }))
          }
            />
          </SelectBathroom>
        </Divider>
        <Divider>
          <SelectDate>
            <DatePickerRange
              hasInitialDates
              formik={formik}
              id1="checkInDate"
              id2="checkOutDate"
              minDate={new Date('2000-01-02')}
              showingLabel={false}
              label="Checkin -> checkout"
              disablePast
            />
          </SelectDate>
          <ButtonSearch data-cy="btn-search" type="submit">Buscar</ButtonSearch>
        </Divider>
      </form>
    </Container>
  );
};

export default HeaderFilter;
