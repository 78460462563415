import { Property } from '../../../services/Property/types';

import { TagProvider } from '../../../context/HostDashboard/HostGridFinances/TagContext';

import { useGridFinances } from '../../../hooks/HostDashboardHook/HostGridFinances/useGridFinances';

import { Finances as FinancesShimmer } from '../LoadingShimmer/Finances/Finances';
import Header from './Header';
import Grid from './Grid';

import { Container, Content } from './styles';
import CountingGridContent from './CountingGridContent';

interface IFinancesCount {
  propertiesHost: Property[];
}

const FinancesCount = ({propertiesHost}:IFinancesCount) => {
  const { isLoadingFinances } = useGridFinances();
  return (
    <>
      <div style={{ marginTop: '16px' }} />
      <Container>
        <Header
          title="Contagem de diárias e limpezas" 
          propertiesHost={propertiesHost}
        />

        <Content>
          {isLoadingFinances ? <FinancesShimmer /> : (
            <>
              <TagProvider>
                <Grid>
                  <CountingGridContent />
                </Grid>
              </TagProvider>
            </>
          )}
        </Content>
      </Container>
    </>
  );
};

export default FinancesCount;
