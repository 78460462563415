import React from 'react';

import {
  Link,
} from 'react-router-dom';

import Header from './Header';
import Content from './Content';

import {
  partnersRoutes,
} from '../../../types';

import {
  Container,
  CloseButton,
} from './style';

import {
  EarningProvider,
} from '../../../../../context/Partners/Earning/Earning';
import { analyticsEvent } from '../../../utils/analytics';
import { useUser } from '../../../../../context/UserContext';

const path = `/${partnersRoutes.partners.main}/${partnersRoutes.earnings.main}`;

const Rescue = () => {
  const { userInformation } = useUser();

  React.useEffect(() => {
    analyticsEvent({
      name: 'Abriu o formulário para solicitar um saque',
      userInformation,
    });
  }, []);

  const handleCloseButton = () => {
    analyticsEvent({
      name: 'Saiu do formulário de solicitar um saque',
      userInformation,
    });
  };

  return (
    <Container>
      <Link
        to={path}
        onClick={handleCloseButton}
      >
        <CloseButton />
      </Link>
      <Header />
      <Content />
    </Container>
  );
};

const RescueProvided = () => (
  <EarningProvider>
    <Rescue />
  </EarningProvider>
);

export default RescueProvided;
