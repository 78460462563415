import { IGuestFront, IGuests } from '../../../../../../../services/PreCheckin/types';

export type ICallback = {
  sucess: Function;
  error: (err: any) => void;
};

export function translateBool(value: boolean | null | undefined): string {
  return value ? 'Sim' : 'Não';
}

export function guestToString(guest: IGuestFront, pet: boolean): string {
  let guestStr = `Convidado: ${guest.name}\n`;
  guestStr += `Documento: ${guest.document}\n`;
  guestStr += `Telefone: ${guest.phone_number || ''}\n`;
  guestStr += `Email: ${guest.email || ''}\n`;
  guestStr += `Reserva: ${guest.reservation}\n`;
  guestStr += `ID: ${guest.id}\n`;
  guestStr += `É Principal?: ${translateBool(guest.is_principal)}\n`;
  guestStr += `Organização de cama: ${guest.bedOrganization || ''}\n`;
  guestStr += `Vai levar pet ?: ${translateBool(pet) || ''}\n`;
  return guestStr;
}

export function copyGuestsToClipboard(
  guests: IGuests, pet: boolean, callback: ICallback,
): void {
  let allGuestsStr = '';

  allGuestsStr += guestToString(guests.main, pet);
  allGuestsStr += '\n===================\n';

  guests.list.forEach((guest) => {
    allGuestsStr += guestToString(guest, pet);
    allGuestsStr += '\n-------------------\n';
  });

  navigator.clipboard.writeText(allGuestsStr).then(() => {
    callback.sucess();
  }).catch((err) => {
    callback.error(err);
  });
}
