import { Dispatch, SetStateAction, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SimpleSelect, { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import {
  ButtonContainer, FullDiv, GridContainer, PropertyDamageFormContainer,
} from './styles';
import { compareList } from '../../../../../utils/Sorting';
// import { UploadInput } from '../../../components/UploadInput';
import TextField from '../../../../TextField/TextField';
import FormButton from '../../../../FormButton/FormButton';
import { UseFormik } from '../../../../../utils/Formik/types';
import { NumberInput } from '../../NumberInput';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { UploadInput } from '../../UploadInput';
import { useUser } from '../../../../../context/UserContext';

const propertyDamageClassificationOptions: SelectOption[] = [
  {
    value: 'Janela',
    valueLabel: 'Janela',
  },
  {
    value: 'Porta',
    valueLabel: 'Porta',
  },
  {
    value: 'Parede',
    valueLabel: 'Parede',
  },
  {
    value: 'Chão',
    valueLabel: 'Chão',
  },
  {
    value: 'Teto',
    valueLabel: 'Teto',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const needToDoneOptions: SelectOption[] = [
  {
    value: 'Repor item',
    valueLabel: 'Repor item',
  },
  {
    value: 'Conserto',
    valueLabel: 'Conserto',
  },
  {
    value: 'Limpeza',
    valueLabel: 'Limpeza',
  },
  {
    value: 'Contratar Serviço Terceirizado',
    valueLabel: 'Contratar Serviço Terceirizado',
  },
  {
    value: 'Cobrar Pendência',
    valueLabel: 'Cobrar Pendência',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));
interface PropertyFormProps {
  formik: UseFormik<any>;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  isEditingForm: boolean;
}

const PropertyDamageForm = ({
  formik, quantity, setQuantity, isEditingForm,
}:PropertyFormProps) => {
  const {
    evidences,
    setEvidences,
    quotationFile,
    setQuotationFile,
  } = useGuestDamage();
  const { userInformation } = useUser();
  const roles = userInformation?.roles;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  return (
    <PropertyDamageFormContainer
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      key="PropertyDamageForm"
      transition={{
        duration: 0.4,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <GridContainer>
        <SimpleSelect
          id="itemName"
          options={propertyDamageClassificationOptions}
          placeholder={formik.values.itemName ? formik.values.itemName : 'Selecione'}
          label="Classifique o dano a propriedade *"
          labelClassName="label"
          formik={formik}
        />
        <NumberInput
          id="quantity"
          onValueChange={setQuantity}
          value={quantity}
          label="Quantidade *"
          labelClassName="label"
        />
        <SimpleSelect
          id="resolution"
          options={needToDoneOptions}
          placeholder={formik.values.resolution ? formik.values.resolution : 'Selecione'}
          label="O que precisa ser feito? *"
          labelClassName="label"
          formik={formik}
        />
        <TextField
          id="quotationLink"
          labelClassName="label"
          label="Link do orçamento"
          placeholder="https://www.exemplo.com.br"
          formik={formik}
          value={formik.values.quotationLink}
        />
        <UploadInput
          label="Orçamento/nota fiscal"
          labelClassName="label"
          setUploadedFile={setQuotationFile}
          uploadedFile={quotationFile}
        />
        <div>
          <TextField
            id="itemPrice"
            labelClassName="label"
            label="Valor unitário"
            mask="money"
            placeholder="R$ 00,00"
            formik={formik}
            value={formik.values.itemPrice}
          />
          <p
            style={{
              marginTop: '0.5rem',
              fontSize: '1rem',
              color: '#B0BED8',
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <InfoOutlinedIcon />
            Caso seja adicionado mais de um item este valor será contabilizado conforme quantidade
            na seção de Resumo de danos
          </p>
        </div>
        <FullDiv>
          <UploadInput
            label="Evidências *"
            labelClassName="label"
            setUploadedFiles={setEvidences}
            uploadedFiles={evidences}
            isMultiple
            required
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </FullDiv>
        <FullDiv>
          <TextField
            id="observation"
            labelClassName="label"
            label="Observações"
            placeholder="Digite aqui"
            type="textarea"
            formik={formik}
            value={formik.values.observation}
          />
        </FullDiv>
      </GridContainer>
      {(roles?.includes('Attendant')
        || roles?.includes('Host')
        || roles?.includes('Admin'))
        && (
        <ButtonContainer>
          <FormButton disable={errorMessage !== undefined}>
            {isEditingForm ? 'Editar Item' : 'Adicionar item'}
          </FormButton>
        </ButtonContainer>
        )}
    </PropertyDamageFormContainer>
  );
};

export default PropertyDamageForm;
