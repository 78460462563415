import React from 'react';

type IUseLoadMoreData<T extends any[]> = {
  data: T,
  startAt?: number,
  pageLength?: number,
  filter?: string,
  key?: string,
};

/**
 * Hook para gerenciar a paginação de dados.
 *
 * @param {IUseLoadMoreData<T>}
 *   - `data`: Matriz dos dados a serem paginados.
 *   - `pageLength`: Número opcional de elementos por página. Padrão é 10.
 *   - `startAt`: Índice opcional para começar a paginação. Padrão é 0.
 *   - `filter`: Filtro opcional para filtrar os resultados. Padrão é undefined.
*    - `key`: Key utilizada para filtrar os resultados deve ser utilIzada junto com filter
*      . Padrão é undefined.
 *
 * @returns {Object}
 *   - `actualPageData`: Dados da página atual.
 *   - `handleNextPage`: Função para avançar para a próxima página.
 *   - `actualPage`: Número da página atual.
 *   - `pageLength`: Comprimento da página.
 *   - `haveANextPage`: Booleano que indica se há uma próxima página.
 */
export function useLoadMoreData<T extends any[]>({
  data,
  startAt = 0,
  pageLength = 5,
  filter = undefined,
  key = undefined,
}: IUseLoadMoreData<T>) {
  const [actualPage, setActualPage] = React.useState(1);

  const start = React.useMemo(() => startAt, [startAt]);
  const end = React.useMemo(() => actualPage * pageLength, [actualPage, pageLength]);
  let filteredData: T[] = data;
  if (filter && key) {
    filteredData = data.filter((item) => item[key] === filter);
  }
  const actualPageData: T = React
    .useMemo(() => filteredData.slice(start, end) as T, [filteredData, start, end]);

  const handleNextPage = React.useCallback(() => {
    if ((actualPage * pageLength) < filteredData.length) {
      setActualPage(actualPage + 1);
    }
  }, [actualPage, filteredData, pageLength]);

  const haveANextPage = React.useMemo(() => {
    const nextIndex = actualPage * pageLength;
    return nextIndex < (filteredData as T).length as boolean;
  }, [actualPage, filteredData]);

  return {
    actualPageData,
    handleNextPage,
    actualPage,
    pageLength,
    haveANextPage,
  };
}
