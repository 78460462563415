import React from 'react';

import { Container, Title, Text } from './styles';

import icon from '../../../assets/icons/estimatePage/noneProperty.svg';

const NonePropertiesInCart: React.FC = () => (
  <Container>
    <img src={icon} alt="Nenhuma propriedade" />
    <Title>Ops...</Title>
    <Text>parece que você não adicionou nenhum imóvel ao carrinho.</Text>
  </Container>
);

export default NonePropertiesInCart;
