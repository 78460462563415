import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  gap: 30px;
  padding: 30px;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #394760;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #394760;
  }
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #ffb649;
  }

  button {
    width: 60%;
    height: 40px;
    padding: 20px;
    display: flex;
    color: #042675;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 10px;
    align-items: center;
    background: transparent;
    justify-content: center;
    border: 2px solid #0d4bd0;
  }
`;
