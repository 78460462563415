import React, {
  useMemo,
  useState,
} from 'react';

import {
  createContext,
  useContextSelector,
} from 'use-context-selector';

import { setScrollToTop } from '../../../pages/Partners/utils';
import { choiseStep } from '../../../pages/Partners/pages/Earnings/Rescue/utils';
import { IChoiseSteps, IEarningFormikValues } from '../../../pages/Partners/pages/Earnings/Rescue/types';

interface IEarningContext {
  steps: number;
  filterChoiseStep: IChoiseSteps;
  nextStep: () => void;
  prevStep: () => void;
  setSteps: React.Dispatch<React.SetStateAction<number>>;
  formValues: IEarningFormikValues;
  setFormValues: React.Dispatch<React.SetStateAction<IEarningFormikValues>>;
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  totalBalance: number;
  setTotalBalance: React.Dispatch<React.SetStateAction<number>>;
}

interface IFilter {
  date: string; // YYYY-MM
}

const initialValueFilterDate = new Date().toISOString().slice(0, 7);

export const EarningContext = createContext<IEarningContext>({} as IEarningContext);

export const EarningProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [steps, setSteps] = useState(1);
  const [totalBalance, setTotalBalance] = useState(0);
  const [formValues, setFormValues] = useState<IEarningFormikValues>({} as IEarningFormikValues);
  const [filter, setFilter] = useState<IFilter>({
    date: initialValueFilterDate,
  });

  const filterChoiseStep = useMemo(() => {
    const choiseStepByReducer = choiseStep.filter((item) => item.step === steps);
    return choiseStepByReducer[0];
  }, [steps]);

  const nextStep = React.useCallback(() => {
    setSteps((old) => old + 1);
    setScrollToTop();
  }, []);

  const prevStep = React.useCallback(() => {
    setSteps((old) => old - 1);
    setScrollToTop();
  }, []);

  const value = {
    steps,
    setSteps,
    nextStep,
    prevStep,
    formValues,
    setFormValues,
    filterChoiseStep,
    filter,
    setFilter,
    totalBalance,
    setTotalBalance,
  };

  return (
    <EarningContext.Provider value={value}>{children}</EarningContext.Provider>
  );
};

export const usePartnersEarning = () => {
  const steps = useContextSelector(EarningContext,
    (state) => state.steps);
  const setSteps = useContextSelector(EarningContext,
    (state) => state.setSteps);

  const filterChoiseStep = useContextSelector(EarningContext,
    (state) => state.filterChoiseStep);

  const nextStep = useContextSelector(EarningContext,
    (state) => state.nextStep);
  const prevStep = useContextSelector(EarningContext,
    (state) => state.prevStep);

  const formValues = useContextSelector(EarningContext,
    (state) => state.formValues);
  const setFormValues = useContextSelector(EarningContext,
    (state) => state.setFormValues);

  const filter = useContextSelector(EarningContext,
    (state) => state.filter);
  const setFilter = useContextSelector(EarningContext,
    (state) => state.setFilter);

  const totalBalance = useContextSelector(EarningContext,
    (state) => state.totalBalance);
  const setTotalBalance = useContextSelector(EarningContext,
    (state) => state.setTotalBalance);

  return {
    filterChoiseStep,
    steps,
    setSteps,
    nextStep,
    prevStep,
    formValues,
    setFormValues,
    filter,
    setFilter,
    totalBalance,
    setTotalBalance,
  };
};
