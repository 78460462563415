import React from 'react';
import { Link } from 'react-router-dom';

import { Section } from '../style';
import { partnersRoutes } from '../../../types';
import { PartnersButton } from '../../../components';
import { usePartners } from '../../../../../context/Partners/Partner/PartnerContext';

const Cashout = () => {
  const { resume } = usePartners();

  function validateBalance() {
    const balance = resume?.total_balance.balance || 0;
    const balanceLessThanZero = balance < 0;

    const title = balanceLessThanZero ? 'Saldo insuficiente para o saque' : 'Sacar saldo disponível';
    const link = balanceLessThanZero ? '' : `/${partnersRoutes.partners.main}/${partnersRoutes.earnings.rescue.main}`;

    return {
      link,
      title,
      balanceLessThanZero,
    };
  }

  const validate = validateBalance();

  return (
    <Section>
      <Link to={validate.link}>
        <PartnersButton disabled={validate.balanceLessThanZero}>
          {validate.title}
        </PartnersButton>
      </Link>
    </Section>
  );
};

export default Cashout;
