import { useMemo } from 'react';
import { useFormik } from 'formik';

import { SimpleProperty } from '../../../services/Expenses/types';
import { patchExpenses } from '../../../services/Expenses/request';

import { useToastErrorMessage } from '../../../utils/Messages';
import { useToast } from '../../../context/ToastContext';
import { useExpense } from '../../../hooks/ExpenseHook/useExpense';
import { useUser } from '../../../context/UserContext';

import SimpleSelect from '../../SimpleSelect';
import ModalTemplate from './ModalTemplate';

import {
  Container,
  ButtonCancel,
  ButtonSave,
  ContentButtons,
  Title,
} from './styles';

type ExpenseChecked = {
  id: number;
  checked: boolean;
};

interface Props {
  listUpdateWhoPays: ExpenseChecked[];
}

const options = [
  {
    id: 'Host',
    name: 'Anfitrião',
  },
  {
    id: 'Owner',
    name: 'Proprietário',
  },
  {
    id: 'Seazone',
    name: 'Seazone',
  },
];

const ModalSelectWhoPays = ({
  listUpdateWhoPays,
}: Props) => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  const isSeazoneUser = () => ['Seazone', 'Admin'].includes(`${userInformation?.main_role}`) && (roles.includes('SeazoneAdministrative') || roles.includes('SeazoneOnboarding') || roles.includes('Admin'));

  const {
    setModalSelectWhoPays,
    openModalSelectWhoPays,
    expensesList,
    properties,
  } = useExpense();

  const allProperties = useMemo(() => [...properties.actives, ...properties.inactives], [properties]);

  const handleGetPaidByUser = (paidBy: string, item: SimpleProperty) => {
    const loggedUser = Number(userInformation?.user_id || -1);
    if (!isSeazoneUser) return loggedUser;

    switch (paidBy) {
      case 'Host':
        return item.host.user.id;
      case 'Owner':
        return item.owners[0].user.id;
      case 'Seazone':
        return loggedUser;
      default:
        return loggedUser;
    }
  };

  const formik = useFormik({
    initialValues: {
      paidBy: '',
    },
    onSubmit: async (values) => {
      try {
        await Promise.all(listUpdateWhoPays.map(async (item) => {
          const expense = expensesList?.results?.find((exp) => `${exp.id}` === `${item.id}`);
          const property = allProperties.find((prop) => `${prop.id}` === `${expense?.property?.id}`);

          await patchExpenses(item.id, { 
            paid_by: values.paidBy, 
            paid_by_user: property ? handleGetPaidByUser(values.paidBy, property) : undefined, 
          });      
        }));

        toast.success('Despesa atualizada com sucesso!');
        setModalSelectWhoPays(false);
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      } finally {
        formik.setFieldValue('paidBy', '');
      }
    },
  });

  return (
    <ModalTemplate
      open={openModalSelectWhoPays}
      handleClose={setModalSelectWhoPays}
    >
      <Container onSubmit={formik.handleSubmit}>
        <Title>Selecione quem pagará as despesas selecionadas</Title>
        <SimpleSelect
          id="paidBy"
          placeholder="Selecione"
          label="Quem paga:"
          formik={formik}
          options={
        (options).map(({ id, name }) => ({
          value: id,
          valueLabel: name,
        }))
      }
        />
        <ContentButtons>
          <ButtonCancel type="button" onClick={() => setModalSelectWhoPays(false)}>Cancelar</ButtonCancel>
          <ButtonSave type="submit">Salvar</ButtonSave>
        </ContentButtons>
      </Container>
    </ModalTemplate>
  );
};

export default ModalSelectWhoPays;
