import styled from 'styled-components';

export const BillingHistoryNegotiationsCardContainer = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`;
export const ValidationAndHistoryItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-family: 'Inter';
  color: #394760;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  > p {
    font-size: 0.75;
    font-weight: 400;
  }
`;
export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin-top: 2rem;
`;
