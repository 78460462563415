/* eslint-disable no-restricted-globals */
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { handleUpdateFormPropertyRules } from '../../../context/InsertPropertyRulesContext/utils';
import { useToast } from '../../../context/ToastContext';
import { usePropertyRules } from '../../../hooks/usePropertyRules/usePropertyRules';
import { patchPropertyRules, postPropertyRules } from '../../../services/InsertData/request';
import { PostPropertyRulesObject } from '../../../services/InsertData/types';
import { useToastErrorMessage } from '../../../utils/Messages';
import FormButton from '../../FormButton';
import FormAddPropertyRules from './FormAddPropertyRules/FormAddPropertyRules';
import {
  ButtonsContainer, Container, Divider, Form, Title,
} from './styles';

interface PropertyRulesFormikValuesType {
  id: number;
  property: number,
  check_in_time: string;
  check_out_time: string;
}

const InsertPropertyRulesData = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { allRules } = usePropertyRules();
  const toastErrorRequest = useToastErrorMessage();
  const validation = Yup.object().shape({
    property: Yup.number().required('Campo Obrigatório').min(0, 'Selecione uma propriedade'),
    check_in_time: Yup.string().required('Campo Obrigatório'),
    check_out_time: Yup.string().required('Campo Obrigatório'),
  });
  const formik = useFormik<PropertyRulesFormikValuesType>({
    initialValues: {
      id: -1,
      property: -1,
      check_in_time: '',
      check_out_time: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      event?.preventDefault();

      try {
        const propertyRules: PostPropertyRulesObject = {
          ...handleUpdateFormPropertyRules(allRules),
          check_in_time: values.check_in_time,
          check_out_time: values.check_out_time,
          property: Number(values.property),
        }; // Objeto enviado a API /property/amenities/
        if (values.id === -1) {
          await postPropertyRules(propertyRules);
          toast.success('Regras criadas com sucesso!');
        } else {
          await patchPropertyRules(values.id, propertyRules);
          toast.success('Regras alterdas com sucesso!');
        }
        navigate('/inserirdados');
        formik.resetForm();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });
  return (
    <Container>
      <div className="header">
        <div>
          <Title>Regras das propriedades</Title>
        </div>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="formContainer">
          <FormAddPropertyRules formik={formik} />
        </div>
        <ButtonsContainer>
          <div>
            <FormButton
              type="button"
              variant="outlined"
              onClick={() => { navigate('/inserirdados'); }}
            >
              Cancelar
            </FormButton>
            <FormButton type="submit">Salvar</FormButton>

          </div>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};
export default InsertPropertyRulesData;
