/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';

import AlertIcon from '../../../../assets/icons/generals/alert.svg';
import TrashIcon from '../../../../assets/icons/generals/bashRed.svg';
import addPhotoSketchIcon from '../../../../assets/icons/Onboarding/photoSketch.png';

import {
  FileAWSFormat,
  FileProps,
  FileReference,
  FileReferenceResponse,
} from '../../../../context/FileContext/types';
import { PlanOptions, PropertyInformation } from '../../../../context/OnboardingContext/types';
import { AddressDetail } from '../../../../services/Address/types';
import { AccountOwnerOnboardingProps, PaymentMethod, PipedriveOwnerInfo } from '../../../../services/Onboarding/types';

import {
  currencyToNumber,
  formatedDateOfRequest,
  numberToCurrency,
} from '../../../../utils/Formatter';
import { checkActive, handleValidation } from '../../../../utils/Onboarding';
import { compareList } from '../../../../utils/Sorting';

import { normalizeCountry } from '../../../../services/Address/countries';
import { postAddress, deleteAddress } from '../../../../services/Address/request';
import {
  deletePropertyManager,
  deletePropertyRules,
  postPropertyHandover,
  postPropertyManager,
  postPropertyRules,
} from '../../../../services/Onboarding/request';
import { postOwnerInvoice } from '../../../../services/Owner/request';
import { getAccountUserDetailsByUserId } from '../../../../services/User/request';

import { useFile } from '../../../../hooks/FileHook/useFile';
import { useToast } from '../../../../context/ToastContext';
import { useToastErrorMessage } from '../../../../utils/Messages';
import { useOnboardingHandover } from '../../../../context/OnboardingContext/OnboardingHandoverContext';
import { useOnboardingPage } from '../../../../hooks/useOnboarding/useOnboarding';

import Upload from '../../../Upload';
import FormButton from '../../../FormButton';
import FormGridRow from '../../../FormGridRow';
import OnboardingSwitch from '../../OnboardingSwitch';
import OnboardingTextField from '../../OnboardingTextArea';
import SimpleSelect from '../../../SimpleSelect/SimpleSelect';
import OnboardingPaymentMethod from '../../OnboardingPaymentMethod';
import CustomInputMask from '../../../CustomInputMask';
import DropdownAutocomplete from '../../../DropdownAutocomplete';
import { SelectProps } from '../../../Autocomplete/Autocomplete';

import { ErrorIcon } from '../../../TextField/styles';

import {
  End,
  Title,
  Border,
  Percent,
  BoxTitle,
  BoxHeader,
  BoxContent,
  UploadFile,
  UploadText,
  TrashButton,
  BoxContainer,
  ButtonSection,
  TitleContainer,
  TotalValueText,
  ErrorContainer,
  AlertContainer,
  UploadContainer,
  SwitchContainer,
  ContentContainer,
  SearchContainer,
  TotalBoxContainer,
  SearchBoxContainer,
  SelectBoxContainer,
  DescriptionContainer,
  DeploymentFeeValuesContainer,
} from './styles';

const photoInitialValues: FileProps = {
  size: 0,
  name: '',
  id: uuid(),
  error: false,
  MIMEtype: '',
  uid: undefined,
  uploaded: false,
  uploadProgress: 0,
  readableSize: '0 KB',
  url: addPhotoSketchIcon,
  file: addPhotoSketchIcon,
  previewURL: addPhotoSketchIcon,
};

const CardDeploymentFeeValues = () => {
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { deleteFile, uploadedFiles, uploadFileToS3, createFileReference } = useFile();
  const [documentClicked, setDocumentClicked] = useState(false);
  const [photos, setPhotos] = useState<FileProps>(photoInitialValues);
  const [documentPhotoRequired, setDocumentPhotoRequired] = useState<boolean>(false);
  const [defaultHostSelected, setDefaultHostSelected] = useState<SelectProps>({ optionText: '', optionValue: '' });

  const {
    handleClickPhase,
    phases,
    listHost,
    listOwner,
    setListOwner,
    listOwnerFiltered,
    setListOwnerFiltered,
  } = useOnboardingPage();

  const {
    dates,
    dealID,
    owner,
    setOwner,
    infosPipeDrive,
    setInfosPipeDrive,
    propertyDetails,
    setPropertyDetails,
    resetFormik,
    setResetFormik,
  } = useOnboardingHandover();

  const handleGetUserDetails = async (userId: number) => {
    const ownerDetails = await getAccountUserDetailsByUserId(userId);
    setOwner({
      ...owner,
      account_user_details: {
        email: ownerDetails?.email,
        main_address: ownerDetails?.main_address,
        phone_number1: ownerDetails?.phone_number1,
      }
    });
  }

  useEffect(() => {
    if (owner?.user_id && !owner?.account_user_details) {
      handleGetUserDetails(owner?.user_id);
    }
  }, [owner]);

  const ownerCountryIsBrazil = useMemo(() => {
    const country = `${owner?.account_user_details?.main_address?.country}`.trim().toLowerCase();
    return ['brasil', 'br'].includes(country);
  }, [owner]);

  const handleUploadFile = async (file: FileProps) => {
    let fileResponse: FileReferenceResponse | { uid: null } = { uid: null };

    try {
      const fileReference: FileReference = {
        category: 'document',
        name: file.name,
        content_type: file.MIMEtype,
      };

      const responseFile: FileReferenceResponse = await createFileReference(fileReference);

      const params: FileAWSFormat = {
        file: photos.file,
        fileId: responseFile.uid,
        url: responseFile.storage.url,
        acl: responseFile.storage.fields.acl,
        key: responseFile.storage.fields.key,
        content_type: fileReference.content_type,
        policy: responseFile.storage.fields.policy,
        signature: responseFile.storage.fields.signature,
        AWSAccessKeyId: responseFile.storage.fields.AWSAccessKeyId,
      };

      await uploadFileToS3(file, params);

      fileResponse = {
        ...responseFile,
        storage: {
          ...responseFile.storage,
          fields: {
            ...responseFile.storage.fields,
          },
        },
      };
    } catch (e: unknown) {
      toast.error('Não foi possível fazer o upload do documento!');
    }

    return fileResponse;
  };

  const handleSelectFile = async (file: FileProps) => {
    if (!documentClicked) {
      setDocumentClicked(true);

      let responseDocumentFile: FileReferenceResponse | { uid: null } = { uid: null };
      responseDocumentFile = await handleUploadFile(file);

      if (!responseDocumentFile.uid) {
        toast.error('Não foi possível realizar o upload do arquivo do documento');
      } else {
        setPhotos((prevFile) => ({
          ...prevFile,
          ...file,
          uid: responseDocumentFile?.uid || '',
        }));
      }
    }
  };

  const validation = Yup.object().shape({
    plan: Yup.string().required(),
    totalImplantationFee: Yup.string().required('O valor total é obrigatório'),
    commissions: Yup.number().required().max(100, 'Valor não pode ultrapassar 100'),
    hostReservationComissionFee: Yup.number()
      .typeError('O valor deve ser um número')
      .required('O campo é obrigatório')
      .test(
        'comission-host',
        'Taxa de comissão zerada é permitida apenas para Gestão Digital',
        function validate(value) {
          const { parent } = this;
          if (Number(value) <= 0 && parent.plan !== 'Digital_Management') return false;
          return true;
        },
      )
      .max(100, 'Valor não pode ultrapassar 100'),
    paymentMethod: Yup.string().required('Campo obrigatório'),
    host: Yup.string().required('Campo obrigatório'),
    haveBedding: Yup.string().required('Campo obrigatório'),
  });

  const isValid = (photoUid?: string) => {
    if (documentPhotoRequired && photoUid === undefined) {
      setDocumentPhotoRequired(true);
      return false;
    }
    return true;
  };

  type FormikProps = {
    host?: number;
    plan: PlanOptions;
    description: string;
    commissions?: number;
    haveBedding: boolean;
    paymentMethod: string;
    qtdInstallments: number;
    totalImplantationFee: string;
    valueBalanceDiscountRate: number;
    hostReservationComissionFee: number;
  };

  const initialValues: FormikProps = {
    qtdInstallments: 0,
    valueBalanceDiscountRate: 100,
    host: infosPipeDrive?.implantation_fees?.host_id || undefined,
    haveBedding: infosPipeDrive?.implantation_fees?.have_bedding || false,
    commissions: infosPipeDrive?.seazone_commission ? Number(infosPipeDrive?.seazone_commission) : undefined,
    description: infosPipeDrive?.implantation_fees?.description || '',
    paymentMethod: infosPipeDrive?.implantation_fees?.payment_method || '',
    totalImplantationFee: numberToCurrency(infosPipeDrive?.implantation_fees?.implantation_fee_value || 0),
    plan: infosPipeDrive?.implantation_fees?.plan_choosed as PlanOptions,
    hostReservationComissionFee: infosPipeDrive?.host_commission ? Number(infosPipeDrive?.host_commission) : 8,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (formValues) => {
      if (!isValid(photos?.uid)) return;

      let propertyAddress: AddressDetail = {
        id: -1,
        country: 'BR',
        city: propertyDetails?.city,
        state: propertyDetails?.state,
        street: propertyDetails?.street,
        number: propertyDetails?.number,
        complement: propertyDetails?.complement,
        condominium: propertyDetails?.condominium,
        postal_code: propertyDetails?.postal_code,
        neighborhood: propertyDetails?.neighborhood,
      };

      try {
        propertyAddress = await postAddress({
          city: propertyAddress.city,
          state: propertyAddress.state,
          number: propertyAddress.number,
          street: propertyAddress.street,
          country: normalizeCountry(propertyAddress.country),
          complement: propertyAddress.complement,
          condominium: propertyAddress.condominium,
          postal_code: propertyAddress.postal_code,
          neighborhood: propertyAddress.neighborhood,
        });

        const chooseCnpjOrCpf = () => {
          if (owner?.cnpj) return { cnpj: owner?.cnpj };
          if (owner?.cpf) return { cpf: owner?.cpf };
          return {};
        };

        await postOwnerInvoice({
          ...chooseCnpjOrCpf(),
          address: !ownerCountryIsBrazil
            ? propertyAddress.street
            : owner?.account_user_details?.main_address?.street,
          address_number: !ownerCountryIsBrazil
            ? propertyAddress.number
            : owner?.account_user_details?.main_address?.number,
          city: !ownerCountryIsBrazil ? propertyAddress.city : owner?.account_user_details?.main_address?.city,
          complement: !ownerCountryIsBrazil
            ? propertyAddress.complement
            : owner?.account_user_details?.main_address?.complement,
          state: !ownerCountryIsBrazil ? propertyAddress.state : owner?.account_user_details?.main_address?.state,
          district: !ownerCountryIsBrazil
            ? propertyAddress.neighborhood
            : owner?.account_user_details?.main_address?.neighborhood,
          postal_code: !ownerCountryIsBrazil
            ? propertyAddress.postal_code
            : owner?.account_user_details?.main_address?.postal_code,
          phone_number: owner?.account_user_details?.phone_number1,
          email: owner?.account_user_details?.email,
          user: owner?.user_id,
          invoice_entity_name: `${owner?.name}`,
        });

        try {
          const property = await postPropertyManager({
            address: propertyAddress.id,
            host: Number(formValues.host),
            code: `${propertyDetails?.propertycode}`,
            lavatory_quantity: propertyDetails?.lavabo,
            comission_fee: formValues.commissions ? `${formValues.commissions / 100}` : undefined,
            king_bed_quantity: propertyDetails?.kingBeds,
            property_type: propertyDetails?.propertyType,
            bathroom_quantity: propertyDetails?.bathrooms,
            queen_bed_quantity: propertyDetails?.queenBeds,
            guest_capacity: propertyDetails?.guestCapacity,
            single_bed_quantity: propertyDetails?.singleBeds,
            double_bed_quantity: propertyDetails?.doubleBeds,
            bedroom_quantity: propertyDetails?.bedroomsAmount,
            single_sofa_bed_quantity: propertyDetails?.singleSofaBeds,
            double_sofa_bed_quantity: propertyDetails?.sofaDoubleBeds,
            category_location: parseInt(propertyDetails?.category, 10),
            contract_start_date: formatedDateOfRequest(dates.start_date),
            balance_discount_rate: formValues.paymentMethod === 'Discount_Rate' ? `${formValues.valueBalanceDiscountRate / 100}` : '0',
            status: 'Onboarding',
            owners: [`${owner.owner_id}`],
            owner: owner.owner_id,
            host_reservation_comission_fee: formik.values.plan === 'Digital_Management' ? 0 : formik.values.hostReservationComissionFee / 100,
            host_cleaning_comission_fee: formik.values.plan === 'Digital_Management' ? 0 : 1,
            extra_day_preparation: Number(propertyDetails?.extraDayPreparation),
          });

          try {
            const rules = await postPropertyRules({
              allow_pet: false,
              property: property.id,
              check_in_time: '11:00',
              check_out_time: '15:00',
              events_permitted: false,
              smoking_permitted: false,
              suitable_for_babies: false,
              suitable_for_children: false,
            });

            try {
              await postPropertyHandover({
                rules: rules.id,
                plan: formValues.plan,
                property: property.id,
                pipedrive_deal_id: dealID || undefined,
                comment: propertyDetails?.comment,
                implatation_items_description: formValues.description,
                onboarding_contact_name: propertyDetails?.onboardingName,
                property_area_size_m2: propertyDetails?.propertyAreaSizeM2,
                payment_method: formValues.paymentMethod as PaymentMethod,
                bed_linen_photo: photos?.uid !== undefined ? photos?.uid : null,
                onboarding_contact_phonenumber: propertyDetails?.onboardingPhone,
                implantation_fee_total_value: currencyToNumber(formValues?.totalImplantationFee),
                payment_installments:
                  formValues.paymentMethod === 'Installments' ? formValues.qtdInstallments : 0,
              });

              const owners = listOwner.map((item) => ({
                ...item,
                property_codes: `${item.owner_id}` === `${owner.owner_id}` ? [...item.property_codes, propertyDetails?.propertycode].sort() : item.property_codes,
              }));

              const ownersFiltered = listOwnerFiltered.map((item) => ({
                ...item,
                property_codes: `${item.owner_id}` === `${owner.owner_id}` ? [...item.property_codes, propertyDetails?.propertycode].sort() : item.property_codes,
              }));

              setListOwner(owners);
              setListOwnerFiltered(ownersFiltered);
              setResetFormik(true);
              handleClickPhase(0);
              toast.success('Cadastro realizado com sucesso!');
            } catch (e: unknown) {
              if (e instanceof Error) {
                toastErrorRequest(e);
                if (rules.id) {
                  await deletePropertyRules(rules.id);
                }
                if (property.id) {
                  await deletePropertyManager(property.id);
                }
                if (propertyAddress?.id) {
                  await deleteAddress(propertyAddress.id);
                }
              }
            }
          } catch (e: unknown) {
            if (e instanceof Error) {
              toastErrorRequest(e);
              if (property.id) {
                await deletePropertyManager(property.id);
              }
              if (propertyAddress?.id) {
                await deleteAddress(propertyAddress.id);
              }
            }
          }
        } catch (e: unknown) {
          if (e instanceof Error) {
            toastErrorRequest(e);
            if (propertyAddress?.id && propertyAddress?.id !== -1) {
              await deleteAddress(propertyAddress.id);
            }
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  const handleDeleteFile = (fileId: string) => {
    deleteFile(fileId);
    setPhotos(photoInitialValues);
  };

  useEffect(() => {
    if (photos?.uid !== undefined) {
      handleDeleteFile(photos.id);
    }
    setDocumentPhotoRequired(formik.values.haveBedding);
  }, [formik.values.haveBedding]);

  useEffect(() => {
    if (uploadedFiles.length !== 0) {
      setDocumentPhotoRequired(false);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (infosPipeDrive?.implantation_fees) {
      formik.setValues({
        ...initialValues,
        qtdInstallments: 0,
        valueBalanceDiscountRate: 100,
        host: infosPipeDrive?.implantation_fees?.host_id || undefined,
        haveBedding: infosPipeDrive?.implantation_fees?.have_bedding || false,
        commissions: infosPipeDrive?.seazone_commission ? Number(infosPipeDrive?.seazone_commission) : undefined,
        description: infosPipeDrive?.implantation_fees?.description || '',
        paymentMethod: infosPipeDrive?.implantation_fees?.payment_method || '',
        totalImplantationFee: numberToCurrency(infosPipeDrive?.implantation_fees?.implantation_fee_value || 0),
        plan: infosPipeDrive?.implantation_fees?.plan_choosed as PlanOptions,
        hostReservationComissionFee: infosPipeDrive?.host_commission ? Number(infosPipeDrive?.host_commission) : 8,
      });

      const filteredHost = listHost.filter((item) => `${item?.full_name}`.toLowerCase().trim().includes(`${infosPipeDrive?.implantation_fees?.host_name}`.toLowerCase().trim()));
      if (filteredHost.length !== 0) {
        const hostName = `${filteredHost?.[0]?.full_name}`;
        const hostId = `${filteredHost?.[0]?.id}`;

        formik.setFieldValue('host', hostId);
        setDefaultHostSelected({
          optionText: hostName,
          optionValue: hostId,
        });
      } else {
        const filteredDefault = listHost.filter((item) => `${item?.full_name}`.toLowerCase().trim() === 'a definir');
        const hostName = `${filteredDefault?.[0]?.full_name}`;
        const hostId = `${filteredDefault?.[0]?.id}`;

        formik.setFieldValue('host', hostId);
        setDefaultHostSelected({
          optionText: hostName,
          optionValue: hostId,
        });
      };

    }
  }, []);

  useEffect(() => {
    if (checkActive(phases, 'Taxas e comissões'))
      handleValidation(formik, toast);
  }, [phases]);

  useEffect(() => {
    if (resetFormik) {
      setInfosPipeDrive({} as PipedriveOwnerInfo);
      setPropertyDetails({} as PropertyInformation);
      setOwner({} as AccountOwnerOnboardingProps);

      formik.resetForm();
      formik.setValues({
        ...initialValues,
        qtdInstallments: 0,
        valueBalanceDiscountRate: 100,
        host: undefined,
        haveBedding: false,
        commissions: undefined,
        description: '',
        paymentMethod: '',
        totalImplantationFee: '',
        plan: undefined as any,
        hostReservationComissionFee: 8,
      });

      const filteredDefault = listHost.filter((item) => `${item?.full_name}`.toLowerCase().trim() === 'a definir');
      const hostName = `${filteredDefault?.[0]?.full_name}`;
      const hostId = `${filteredDefault?.[0]?.id}`;

      formik.setFieldValue('host', hostId);
      setDefaultHostSelected({
        optionText: hostName,
        optionValue: hostId,
      });

      setResetFormik(false);
    }
  }, [resetFormik]);

  const handleGetHost = (): SelectProps[] => (listHost || []).map((item: any) => ({
    optionText: `${item?.full_name}`,
    optionValue: item?.id,
  })).sort((a: SelectProps, b: SelectProps) => compareList(a.optionText || '', b.optionText || ''));

  const handleSubmit = () => {
    handleValidation(formik, toast);
    formik.handleSubmit();
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <DeploymentFeeValuesContainer>
        <TitleContainer>
          <Title>Taxas e comissões</Title>
          <Border />
        </TitleContainer>
        <ContentContainer>
          <BoxContainer>
            <SimpleSelect
              id="plan"
              label="Plano Escolhido"
              placeholder="Selecione"
              disabled={false}
              options={[
                { value: 'Full', valueLabel: 'Full' },
                { value: 'Mid', valueLabel: 'Mid' },
                { value: 'Light', valueLabel: 'Light' },
                { value: 'Digital_Management', valueLabel: 'Gestão Digital' },
                { value: 'Essencial', valueLabel: 'Essencial' },
                { value: 'Plus', valueLabel: 'Plus' },
                { value: 'Pool', valueLabel: 'Pool' },
              ].sort()}
              formik={formik}
            />
          </BoxContainer>
          <TotalBoxContainer>
            <TotalValueText>Taxa de adesão * (R$)</TotalValueText>
            <CustomInputMask
              option="money"
              formik={formik}
              id="totalImplantationFee"
              placeholder={
                formik.values.totalImplantationFee
                  ? formik.values.totalImplantationFee
                  : 'Insira o valor em R$'
              }
            />
          </TotalBoxContainer>
          <AlertContainer>
            <img src={AlertIcon} alt="Alert icon" />
            <p>
              Favor enviar comprovante de pagamento por email para administrativo@seazone.com.br
            </p>
          </AlertContainer>

          <End />
          <BoxContainer>
            <BoxContent>
              <BoxHeader>
                <BoxTitle>Descrição de itens cobrados na taxa de implantação *</BoxTitle>
              </BoxHeader>

              <FormGridRow grid>
                <Grid item xs={12} sm={6}>
                  <BoxContent>
                    <SwitchContainer flexDirection="column">
                      <label htmlFor="haveBedding">Tem roupa de cama ?</label>
                      <OnboardingSwitch
                        value={formik.values.haveBedding}
                        id="haveBedding"
                        formik={formik}
                      />
                    </SwitchContainer>
                    <DescriptionContainer>
                      <OnboardingTextField
                        formik={formik}
                        id="description"
                        label="Descrição:"
                        placeholder="Digite aqui"
                        type="textarea"
                        value={formik.values.description}
                      />
                    </DescriptionContainer>
                  </BoxContent>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <BoxContent>
                    <UploadText>{`Imagem: ${formik.values.haveBedding ? '*' : ''}`}</UploadText>
                    <UploadContainer>
                      <Upload
                        dataCy="input-receipt-file"
                        file={photos}
                        setFile={(file: FileProps) => handleSelectFile(file)}
                        accept={['image/*', '.pdf']}
                        defaultPreview={addPhotoSketchIcon}
                        disableDragAndDrop={!formik.values.haveBedding || photos?.uid !== undefined}
                      />
                    </UploadContainer>

                    <UploadText marginTop={1}>
                      {`Foto da lista de roupas de cama e valor ${formik.values.haveBedding ? '*' : ''
                        }`}
                    </UploadText>

                    {uploadedFiles.length !== 0 && (
                      <UploadFile>
                        <p>{uploadedFiles[0].name}</p>
                        <TrashButton onClick={() => handleDeleteFile(uploadedFiles[0].id)}>
                          <img src={TrashIcon} alt="trashIcon" />
                        </TrashButton>
                      </UploadFile>
                    )}

                    {documentPhotoRequired && formik.values.haveBedding && (
                      <ErrorContainer>
                        <p className="error">
                          <ErrorIcon /> Faça o upload de uma imagem!
                        </p>
                      </ErrorContainer>
                    )}
                  </BoxContent>
                </Grid>
              </FormGridRow>
            </BoxContent>
          </BoxContainer>

          <SearchBoxContainer>
            <BoxTitle>Anfitrião responsável *</BoxTitle>
            <SearchContainer>
              <div className="autocomplete-host">
                <DropdownAutocomplete
                  dataCy="select-host-list"
                  labelClassName='labelClass'
                  label={''}
                  id="host"
                  required
                  disableStarSymbol
                  options={handleGetHost()}
                  formik={formik}
                  defaultSelected={defaultHostSelected}
                  placeholder={'Pesquise pelo nome do anfitrião'}
                />
              </div>
            </SearchContainer>
          </SearchBoxContainer>
          <End />
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              width: '100%',
              marginBottom: '-2rem',
            }}
          >
            <BoxContainer>
              <OnboardingTextField
                id="commissions"
                label="Comissão sobre reservas"
                required
                formik={formik}
                value={formik.values.commissions}
                placeholder="Ex.: 10%"
                endAdornment={<Percent>%</Percent>}
              />
            </BoxContainer>
            <BoxContainer>
              <OnboardingTextField
                disabled={formik.values.plan === 'Digital_Management'}
                id="hostReservationComissionFee"
                label="Comissão do anfitrião"
                required
                formik={formik}
                value={formik.values.plan === 'Digital_Management' ? 0 : formik.values.hostReservationComissionFee}
                placeholder="Ex.: 8%"
                endAdornment={<Percent>%</Percent>}
                tooltip={`Padrão: 8%
                  Madego: Plano Essencial - Dividir a comissão do imóvel por dois, se o valor for "quebrado" colocar um a mais para Seazone.
                  Exemplo: Comissão do imóvel é 25%, então comissão anfitrião é 12%. 
                  Plano Plus - Diminuir 2% da comissão do imóvel e divide por 2, seguindo o racional do Plano Essencial.`}
              />
            </BoxContainer>
          </div>
          <SelectBoxContainer>
            <OnboardingPaymentMethod
              id="paymentMethod"
              labelPlacement="bottom"
              formLabel="Forma de pagamento da taxa *"
              formik={formik}
              options={[
                { label: 'À vista', value: 'On_Budget', layout: '1' },
                { label: 'À prazo', value: 'Installments', layout: '2' },
                { label: 'Abatimento nas reservas', value: 'Discount_Rate', layout: '3' },
                { label: 'Boleto', value: 'Bank_Slip', layout: '1' },
                { label: 'Cartão de crédito', value: 'Credit_Card', layout: '1' },
                { label: 'Pix', value: 'PIX', layout: '1' },
              ]}
            />
          </SelectBoxContainer>
          <End />
        </ContentContainer>
      </DeploymentFeeValuesContainer>
      <ButtonSection>
        <FormButton
          type="button"
          customColor="blue"
          variant="outlined"
          onClick={() => handleClickPhase(1)}
        >
          Voltar
        </FormButton>
        <FormButton type="button" onClick={() => handleSubmit()}>
          Salvar
        </FormButton>
      </ButtonSection>
    </form>
  );
};

export default CardDeploymentFeeValues;
