import styled, { keyframes } from 'styled-components';
import { ReportProblemOutlined } from '@mui/icons-material';

const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  gap: 1rem;

  &:nth-child(1) {
    animation-delay: 0.6s;
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;

  @media (max-width: 1300px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SearchBtnContainer = styled.div`
  width: 40%;
  position: relative;

  @media (max-width: 1300px) {
    width: 100%;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  width: fit-content;
  gap: 16px;

  @media (max-width: 1300px) {
    width: 100%;
    flex-direction: column;
    
    .row {
      width: 100%;
    }

    align-items: flex-start;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: left;
  width: 100%;
  justify-content: left;
  gap: 16px;

  @media (max-width: 1300px) {
    width: 100%;
    flex-direction: column;
    
    .row {
      width: 100%;
    }

    align-items: flex-start;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 16px;
  width: 100%;

  button {
    width: 100%;
  }

  .row {
    min-width: 120px;

    label {
      margin-top: 0 !important;
    }
  }

  @media (max-width: 1590px) {
    flex-direction: column;
    align-items: flex-start;
  }
  
  @media (max-width: 1300px) {
    width: 100%;
    flex-direction: column;
    
    .row {
      width: 100%;
    }

    align-items: flex-start;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #D9D9D9;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InputHideModal = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const BoldText = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  min-width: 150px;
`;

export const InputModal = styled.div`
  position: absolute;
  box-shadow: 0 2px 4px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23);
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey._450.hex()};
  width: 500px;
  background-color:#FFFFFF;
  margin-top: 5px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 25px;
  padding-top: 15px;
  z-index: 999;

  strong {
    :first-child {
      margin-left: 0!important;
    }
  }

  &.input-modal-style {
    height: auto;
  }

  .messageError {
    font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
    color: ${({ theme }) => theme.palette.orange.main.hex()};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .css-1qz17ui-MuiPaper-root {
    z-index: 10010 !important;
  }

  .group-price {
    width: 100%;
    display: flex;
    flex-direction: row;

    span {
    margin-top: 35px;
    margin-left: 5px;
    margin-right: 6px;
    }

    .min {
      width: 127.5px;
    }

    .max {
      width: 127.5px;
    }
  }

  .buttonApply {
    margin-right: 0;
    margin-left: auto;
    margin-top: 5px;
  }

  @media (max-width: 1080px) {
    padding-left: 10px;
    padding-right: 10px;
    width: 450px;
    margin-left: -50px;
  }
  @media (max-width: 580px) {
    position: fixed;
    top: 50;
    right:0;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    margin-left: 0px;
  }
  @media (max-width: 580px) {
    width: 100%;
    display: flex

  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonClear = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1}, sans-serif;
  color: ${({ theme }) => theme.palette.grey._720.hex()};
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  width: 150px;
  cursor: pointer;
  user-select: none;
`;
export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right:15px;
  padding-top: 10px;

  .style-date {
    width: 150px !important;
  }
`;

export const Line = styled.div`
  width: 90%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.grey._420.hex()};
  margin-bottom: 10px;
  margin-top: 18px;
`;

export const ErrorIcon = styled(ReportProblemOutlined)`
  && {
    margin-right: 3px;
    height: 16px;
  }
`;
