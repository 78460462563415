import React, {
  FC, useState, useCallback, ReactElement, useEffect,
} from 'react';

import { v4 as uuid } from 'uuid';

import axios from 'axios';
import { createContext } from 'use-context-selector';
import request from '../../services/request';

import {
  FileProps,
  FileAWSFormat,
  FileReference,
  FileReferenceResponse,
} from './types';
import { preCheckinRequest } from '../../services/PreCheckin';

export type IFileReferenceCustomHeader = {
  is: boolean;
  token: string;
  value: string;
};

export const initialValuesFile: FileProps = {
  id: uuid(),
  name: '',
  size: 0,
  readableSize: '0 KB',
  file: '',
  MIMEtype: '',
  previewURL: null,
  url: null,
  uploadProgress: 0,
  uploaded: false,
  error: false,
};

interface FileContextType {
  uploadedFiles: FileProps[];
  setUploadedFiles: Function;
  createFileReference: (
    params: FileReference,
    customHeader?: IFileReferenceCustomHeader
  ) => Promise<FileReferenceResponse>;
  uploadFileToS3: Function;
  updateFile: Function;
  deleteFile: Function;
}

export const FileContext = createContext<FileContextType>({} as FileContextType);

export const FileProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);

  useEffect(() => () => {
    uploadedFiles.forEach((file) => file.previewURL && URL.revokeObjectURL(file.previewURL));
    setUploadedFiles([]);
  }, []);

  const updateFile = useCallback((id, data) => {
    setUploadedFiles((state) => state
      .map((file) => (file.id === id ? { ...file, ...data } : file)));
  }, []);

  const deleteFile = useCallback((id: string) => {
    setUploadedFiles((state) => state.filter((file) => file.id !== id));
  }, []);

  const createFileReference = useCallback(async (params:
  FileReference, customHeader?: IFileReferenceCustomHeader): Promise<FileReferenceResponse> => {
    if (customHeader?.is) {
      preCheckinRequest.defaults.headers.common[customHeader.value] = customHeader?.token;
      const { data } = await preCheckinRequest.post('/files/', params);
      return data;
    }

    const { data } = await request.post('/files/', params);
    return data;
  }, []);

  const convertBase64ToFile = (file: FileProps) => {
    const binary = atob(file.file.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], {
      type: file.MIMEtype,
    });
  };

  const uploadFileToS3 = useCallback(async (file: FileProps, params: FileAWSFormat) => {
    const data = new FormData();

    const fileFormatted = convertBase64ToFile(file);

    data.append('acl', params.acl);
    data.append('Content-Type', params.content_type);
    data.append('key', params.key);
    data.append('AWSAccessKeyId', params.AWSAccessKeyId);
    data.append('policy', params.policy);
    data.append('signature', params.signature);
    data.append('file', fileFormatted);

    await axios.post(
      `${params.url}`, data, {
        onUploadProgress: (e) => {
          const progress: number = Math.round((e.loaded * 100) / e.total);
          updateFile(file.id, { uploadProgress: progress });
        },
        headers: { 'content-type': 'multipart/form-data' },
      },
    ).then(() => {
      updateFile(file.id, {
        uploaded: true,
      });
    }).catch(() => {
      updateFile(file.id, {
        error: true,
      });
    });
  }, []);

  return (
    <FileContext.Provider
      value={{
        uploadedFiles,
        setUploadedFiles,
        createFileReference,
        uploadFileToS3,
        updateFile,
        deleteFile,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};
