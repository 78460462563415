import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isOpen: boolean;
}>`
  transition: 1s;
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: auto;
  overflow: hidden;
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  text-align: left;
  justify-content: flex-start;
  gap: 1rem;

  h1{
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  img {
    display: flex;
    width: 100%;
    max-width: 100px;
    cursor: pointer;
    transition: 0.4s;
    object-fit: cover;
  }
`;

function formDelay() {
  let styles = '';
  for (let i = 1; i <= 10; i += 1) {
    styles += `
      @keyframes fadeIna {
        from {
          opacity: 0;
          transform: translateY(10px);
        }
        to {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      div.form-${i} {
        opacity: 0;
        animation: fadeIna 0.3s ${i * 0.1}s ease forwards;
      }
    `;
  }
  return css`
    ${styles}
  `;
}

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${formDelay()}
`;
