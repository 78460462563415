import { useContextSelector } from 'use-context-selector';
import { FinancialClosePropertyContext } from '../../context/FinancialClosePage/FinancialClosePropertyContext/FinancialClosePropertyContext';

export function useFinancialCloseProperty() {
  const financialDataProperty = useContextSelector(FinancialClosePropertyContext, (state) => state
    .financialDataProperty);

  const handleSetFinancialDataProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.setFinancialDataProperty);

  const handleUpdateFinancialDataProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.updateFinancialDataProperty);

  const handleUpdateReservationProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.updateReservationProperty);

  const handleUpdateExpenseProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.updateExpenseProperty);

  const handleUpdateCheckedOfPropertySelected = useContextSelector(FinancialClosePropertyContext,
    (state) => state.updateCheckedOfPropertySelected);

  const selector = useContextSelector(FinancialClosePropertyContext,
    (state) => state.updateAllCheckedsOfPropertySelected);
  const handleUpdateAllCheckedsOfPropertySelected = selector;

  const handleUpdateExpandedOfPropertySelected = useContextSelector(FinancialClosePropertyContext,
    (state) => state.updateExpandedOfPropertySelected);

  const openModalAddManualFitProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.openModalAddManualFitProperty);
  const handleOpenModalAddManualFitProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.setOpenModalAddManualFitProperty);

  const openModalEditManualFitProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.openModalEditManualFitProperty);
  const handleOpenModalEditManualFitProperty = useContextSelector(FinancialClosePropertyContext,
    (state) => state.setOpenModalEditManualFitProperty);

  const openModalAddManualFitDaily = useContextSelector(FinancialClosePropertyContext,
    (state) => state.openModalAddManualFitDaily);
  const handleOpenModalAddManualFitDaily = useContextSelector(FinancialClosePropertyContext,
    (state) => state.setOpenModalAddManualFitDaily);

  const openModalEditManualFitDaily = useContextSelector(FinancialClosePropertyContext,
    (state) => state.openModalEditManualFitDaily);
  const handleOpenModalEditManualFitDaily = useContextSelector(FinancialClosePropertyContext,
    (state) => state.setOpenModalEditManualFitDaily);

  const openModalAddManualFitCleaning = useContextSelector(FinancialClosePropertyContext,
    (state) => state.openModalAddManualFitCleaning);
  const handleOpenModalAddManualFitCleaning = useContextSelector(FinancialClosePropertyContext,
    (state) => state.setOpenModalAddManualFitCleaning);

  const openModalEditManualFitCleaning = useContextSelector(FinancialClosePropertyContext,
    (state) => state.openModalEditManualFitCleaning);
  const handleOpenModalEditManualFitCleaning = useContextSelector(FinancialClosePropertyContext,
    (state) => state.setOpenModalEditManualFitCleaning);

  return {
    financialDataProperty,
    handleSetFinancialDataProperty,
    handleUpdateFinancialDataProperty,
    handleUpdateReservationProperty,
    handleUpdateExpenseProperty,
    handleUpdateCheckedOfPropertySelected,
    handleUpdateAllCheckedsOfPropertySelected,
    handleUpdateExpandedOfPropertySelected,
    openModalAddManualFitProperty,
    handleOpenModalAddManualFitProperty,
    openModalEditManualFitProperty,
    handleOpenModalEditManualFitProperty,
    openModalAddManualFitDaily,
    handleOpenModalAddManualFitDaily,
    openModalEditManualFitDaily,
    handleOpenModalEditManualFitDaily,
    openModalAddManualFitCleaning,
    handleOpenModalAddManualFitCleaning,
    openModalEditManualFitCleaning,
    handleOpenModalEditManualFitCleaning,
  };
}
