import { Icon } from '../Icon';
import { Text } from '../Text/Text';
import { Base } from '../../types';

export const View = ({ text }: { text: Base['text'] }) => (
  <>
    <Icon />
    <Text>{text}</Text>
  </>
);
