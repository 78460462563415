/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { keyframes } from 'styled-components';
import { Tabs, Tab } from '@mui/material';

const animationTime = '0.3s';
const modalWidth = '515px';

const SlideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(15px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Header = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey._400.hex()}`};
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${({ theme }) => `${theme.palette.white.main.hex()}`};

  .pin {
    transform: scale(0.8);
  }

  @media screen and (max-width: 2000px) {
    .pin {
      transform: scale(0.7);
    }
  }

  @media (max-width: 900px) {
    width: 100vw;
  }
`;

export const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderText = styled.p`
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  align-self: center;
  margin-left: 16px;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: right;
  color: ${({ theme }) => theme.palette.blue._950.hex()};

  &:hover {
    color: ${({ theme }) => theme.palette.red._400.hex()};
  }
`;

export const VerticalTabs = styled(Tabs)`
  && {
    position: fixed;
    right: 0;
    top: 40px;
    z-index: 5;
    border: 1px solid ${({ theme }) => theme.palette.grey._340.hex()};
    align-self: end;
    height: 100vh;
    background: ${({ theme }) => theme.palette.white._400.hex()};
    width: 60px;
    margin: auto;
    margin-right: 0px;
    &.MuiTab-wrapper {
      width: 20px !important;
    }
    &.MuiTab-root {
      width: 20px !important;
    }
  }
`;
export const SelectableTab = styled(Tab)`
  && {
    padding: 0px;
    &.MuiTab-root {
      width: 20px !important;
      min-width: 60px;
      :hover {
        background: ${({ theme }) => theme.palette.white.main.hex()};
      }
    }
    &.MuiTab-textColorInherit.Mui-selected {
      background: ${({ theme }) => theme.palette.white.main.hex()};
    }
    &.MuiTab-wrapper {
      width: 20px;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: ${modalWidth};
`;

export const Content = styled.div`
  min-width: ${modalWidth};
  max-width: ${modalWidth};
  width: 100%;
  padding: 0px 1.5rem;
  padding-bottom: 10rem;

  @media (max-width: 900px) {
    min-width: auto;
    max-width: none;
    width: 100vw;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    animation-delay: 0.1s;
    opacity: 0;
    animation-name: ${SlideIn};
    animation-duration: ${animationTime};
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  h1,
  p {
    font-weight: 400;
    font-size: 1.1rem;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  animation-delay: 0.1s;
  opacity: 0;
  animation-name: ${SlideIn};
  animation-duration: ${animationTime};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

export const ComingContainer = styled.div`
  display: flex;
  padding: 1.5rem 0px;
  justify-content: space-between;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const ComingTitle = styled.h1``;

export const ComingData = styled.p``;

export const ResCodeContainer = styled.div`
  display: flex;
  padding: 1.5rem 0px;
  justify-content: space-between;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const ResCodeTitle = styled.h1``;

export const ResCodeData = styled.p``;

export const ContentContainer = styled.div`
  margin-top: 2rem;
  animation-delay: 0.2s;
  opacity: 0;
  animation-name: ${SlideIn};
  animation-duration: ${animationTime};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  div {
    margin-bottom: 0.5rem;
    opacity: 0;
    animation-name: ${SlideIn};
    animation-duration: ${animationTime};
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  @media (max-width: 900px) {
    min-width: auto;
  }
`;

export const ButtonsContainer = styled.div`
  grid-area: "buttons";
  width: 100%;
  max-width: ${modalWidth};
  transition: 1s;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: ${({ theme }) => theme.palette.white._400.hex()};
  border-top: 1px solid ${({ theme }) => theme.palette.grey._50.hex()};
  position: fixed;
  bottom: 0;
  right: 0;

  button,
  svg {
    margin: 0 1rem;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    max-width: none !important;
    padding: 20px !important;
    width: 100vw;
    left: 0;
    height: auto;
    flex-direction: column-reverse;
 
    button {
      overflow: hidden;
      width: 70vw;
      margin-bottom: 10px;
      height: 100px !important;
      padding: 10px 0px;
    }
  }

  @media (max-width: 320px) {
    button {
      width: 70vw;
      margin-bottom: 5px;
      height: 100px !important;
      padding: 10px 0px;
    }
  }
`;

export const HeadContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

export const InputCheckBoxContainer = styled.div`
  div {
    margin: 0 0.6rem 0 0;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
  }
`;
