import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import React from 'react';

import { SubTitle } from '../styles';

import {
  Title,
  LeftSide,
  RightSide,
  // Container,
  GenericBorderRoundedContainer,
} from './styles';

interface ExpansiveItemProps {
  open: boolean;
  children: React.ReactNode;
  title: string;
  subTitle: string;
  onClick: Function;
}

const AddCircleStyled = () => (
  <RightSide>
    <AddCircleOutline style={{ color: '#64769C' }} />
  </RightSide>
);

const RemoveCircleStyled = () => (
  <RightSide>
    <RemoveCircleOutline style={{ color: '#64769C' }} />
  </RightSide>
);

const ExpansiveItem = ({
  title,
  subTitle,
  open,
  children,
  onClick,
}: ExpansiveItemProps) => (
  <GenericBorderRoundedContainer>
    <LeftSide>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </LeftSide>
    {open && (
      <>
        <RightSide>{children}</RightSide>
      </>
    )}
    <button type="button" onClick={() => onClick(!open)}>
      {open ? <RemoveCircleStyled /> : <AddCircleStyled />}
    </button>
  </GenericBorderRoundedContainer>
);

export default ExpansiveItem;
