import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 30px;
  overflow-y: scroll;
`;

export const ContentAccountBank = styled.div`
  width: 100%;
  height: 555px;
  background: #FFFFFF;
  border-radius: 0px 0px 10px 10px;
  padding: 30px 26px;
  margin-bottom: 20px;
  overflow-y: scroll;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 15px;
  }

  >p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 25px;

    color: #767676;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #000000;
  }

  .card-account {
    width: 55%;
    height: auto;
  }

  .fields {
    width: 50%;


  }

  .my-data {
    height: 655px;

  }
`;

export const Row = styled.div`
  margin-bottom: 15px;

  p {
    margin-top: 5px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    color: ${({ theme }) => theme.palette.grey._750.hex()};
    font-weight: 600;
    font-style: italic;
  }

  .line-field {
      display: flex;
      align-items: center;

      div {
        &:first-child {
          margin-right: 5px;
        }
      }
    }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #8F8F8F;
  margin-top: 46px;
  margin-bottom: 46px;
`;

export const ContentButons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonCancell = styled.button`
  background: #EBEBF5;
  border: 1px solid #D9DCDF;
  border-radius: 10px;
  width: 205px;
  height: 40px;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B5B5B5;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  cursor: pointer;
  animation: 0.3s;

  &:hover {
    filter: brightness(0.9);
    box-shadow: 3px 3px 10px #888888
  }
`;

export const ButtonSave = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.003px;
  color: #EBEBF5;


  background: #2043CF;
  border-radius: 10px;
  width: 205px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  cursor: pointer;
  animation: 0.3s;

  &:hover {
    filter: brightness(0.9);
    box-shadow: 3px 3px 10px #888888
  }
`;

export const ButtonEdit = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.003px;
  color: #EBEBF5;


  background: #2043CF;
  border-radius: 10px;
  width: 205px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  cursor: pointer;
  animation: 0.3s;

  &:hover {
    filter: brightness(0.9);
    box-shadow: 3px 3px 10px #888888
  }

  svg {
    margin-right: 8px;
  }
`;
