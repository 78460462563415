import {
  Dispatch, SetStateAction, useEffect,
} from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { CircularProgress } from '@mui/material';
import { Trash2 } from 'react-feather';
import { motion } from 'framer-motion';
import { FileProps } from '../../../../context/FileContext/types';

import {
  Container,
  ContainerLabel,
  UploadButtonContainer,
  UploadedLink,
  TrashButton,
  MultipleImagesContainer,
  InputContainer,
  ErrorContainer,
} from './styles';
import { useUpload } from '../../../../hooks/useUpload';
import {
  ContainerProgressbar, MdCheckCircle, MdError,
} from '../../../Upload/styles';
import useSingleUpload from '../../../../hooks/useUpload/useSingleUpload';
import { useGuestDamage } from '../../../../hooks/GuestDamage/useGuestDamage';
import { ErrorIcon } from '../../../TextField/styles';

export interface UploadInputProps {
  label?: string;
  labelClassName?: string;
  required?: boolean;
  setUploadedFile?: Dispatch<SetStateAction<FileProps>>;
  uploadedFile?: FileProps;
  isMultiple?: boolean;
  uploadedFiles?: FileProps[];
  setUploadedFiles?: Dispatch<SetStateAction<FileProps[]>>
  errorMessage?: string | undefined;
  setErrorMessage?: Dispatch<SetStateAction<string | undefined>>
}

export function UploadInput({
  label,
  labelClassName,
  required = false,
  setUploadedFile,
  uploadedFile,
  isMultiple = false,
  setUploadedFiles,
  uploadedFiles,
  errorMessage,
  setErrorMessage,
}: UploadInputProps) {
  const handleValidation = () => {
    if (required && !isMultiple && !uploadedFile && setErrorMessage) {
      setErrorMessage('Por favor faça o upload de um arquivo.');
    } else if (
      required
      && isMultiple
      && setErrorMessage
      && (!uploadedFiles || uploadedFiles.length === 0)
    ) {
      setErrorMessage('Por favor faça o upload de pelo menos um arquivo.');
    } else if (setErrorMessage) {
      setErrorMessage(undefined);
    }
  };
  const onSuccess = (file?: FileProps | undefined) => {
    if (file) {
      if (setUploadedFile) {
        setUploadedFile(file);
      }
      if (setUploadedFiles) {
        setUploadedFiles((oldValues) => [...oldValues, file]);
      }
    }
  };

  const { file, onUpload, loading } = useSingleUpload({
    callbacks: { success: onSuccess },
  });

  const { open, getRootProps, getInputProps } = useUpload({
    file,
    setFile: onUpload,
    accept: ['image/*', 'application/*', '.csv'],
  });

  const { handleOpenFile } = useGuestDamage();

  function handleRemovePhoto(id?: string) {
    if (isMultiple === true && setUploadedFiles) {
      setUploadedFiles((oldValues) => oldValues.filter((photo) => photo.uid! !== id));
    } else if (setUploadedFile) {
      setUploadedFile({} as FileProps);
    }
  }

  useEffect(() => {
    handleValidation();
  }, [required, isMultiple, uploadedFile, uploadedFiles]);

  return (
    <>
      <Container>
        <ContainerLabel>
          <label className={labelClassName} htmlFor={label}>{label}</label>
        </ContainerLabel>
        <UploadButtonContainer>
          {loading ? (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
            >
              <CircularProgress size={16} />
            </div>
          ) : (
            <>
              {isMultiple === true ? (
                <MultipleImagesContainer>
                  {uploadedFiles?.length! > 0 && uploadedFiles?.map((item) => (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      gap: '1rem',
                    }}
                    >
                      <UploadedLink
                        onClick={() => handleOpenFile(item.previewURL!, item.name)}
                      >
                        {item.name}
                      </UploadedLink>
                      <TrashButton
                        whileTap={{
                          scale: 0.8,
                        }}
                        onClick={() => handleRemovePhoto(item.uid)}
                      >
                        <Trash2 size={16} color="#FF2732" />
                      </TrashButton>
                    </div>
                  ))}
                  <InputContainer {...getRootProps()} onClick={open}>
                    <motion.p whileTap={{
                      scale: 0.8,
                    }}
                    >
                      Clique para fazer o upload
                    </motion.p>
                    <input id={file.id} {...getInputProps()} type="file" />
                  </InputContainer>
                </MultipleImagesContainer>
              ) : (
                <>
                  {uploadedFile?.name ? (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      gap: '1rem',
                    }}
                    >
                      <UploadedLink
                        onClick={
                        () => handleOpenFile(uploadedFile.previewURL!, uploadedFile.name)
                      }
                      >
                        {uploadedFile.name}
                      </UploadedLink>
                      <TrashButton
                        whileTap={{
                          scale: 0.8,
                        }}
                        onClick={() => handleRemovePhoto()}
                      >
                        <Trash2 size={16} color="#FF2732" />
                      </TrashButton>
                    </div>
                  ) : (
                    <InputContainer {...getRootProps()} onClick={open}>
                      <motion.p whileTap={{
                        scale: 0.8,
                      }}
                      >
                        Clique para fazer o upload
                      </motion.p>
                      <input id={file.id} {...getInputProps()} type="file" />
                    </InputContainer>
                  )}
                </>
              )}
            </>
          )}
          <ContainerProgressbar>
            {!file.uploaded && !file.error && file.uploadProgress !== 0 && (
            <CircularProgressbar
              styles={{
                root: {
                  width: 35,
                },
                path: {
                  stroke: '#3CC3EE',
                },
                text: {
                  fontSize: 40,
                  fontWeight: 'bold',
                },
              }}
              strokeWidth={10}
              text={`${file.uploadProgress === 0 ? '' : file.uploadProgress}`}
              value={file.uploadProgress || 0}
            />
            )}
            {file.uploaded && <MdCheckCircle />}
            {file.error && <MdError />}
          </ContainerProgressbar>
        </UploadButtonContainer>
      </Container>

      {errorMessage && (
      <ErrorContainer>
        <ErrorIcon />
        {errorMessage}
      </ErrorContainer>
      )}

    </>
  );
}
