import { UserLogged } from '../../../../services/User/types';

import sheetData from './data.json';

export type IMonitoringSheet = typeof sheetData[0] | undefined;

export const handleRedirectSpreadSheets = (url?: string) => {
  if (url) {
    window.open(url, '_blank');
  }
};

export const getMonitoringSheet = (user: UserLogged | null | undefined) => {
  const userEmail = user?.email.toLowerCase();

  const userMonitoringSheet = sheetData.find((sheet) => sheet.email === userEmail);
  const result: IMonitoringSheet = userMonitoringSheet;

  return result;
};
