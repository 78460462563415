import { KeyQuestions } from '../../../../context/Partners/IndicateContext/IndicateContext';

interface Data {
  id: number;
  title: string;
  progress: number;
  description: string;
  requireTrue?: KeyQuestions[];
  requireFalse?: KeyQuestions[];
}

export const data: Data[] = [
  {
    id: 1,
    title: 'Indicação com probabilidade de conversão baixa.',
    progress: 20,
    description:
      'O imóvel indicado está fora da área de abrangência da Seazone. A indicação ficará no nosso banco de dados para análise posterior.',
    requireFalse: ['IsIndicatedWithinSeazoneOperationArea'],
  },
  {
    id: 2,
    title: 'Indicação com probabilidade de conversão baixa.',
    progress: 20,
    description:
      'O imóvel está dentro da área de abrangência da Seazone, mas o imóvel não possui mobília. Nosso analista entrará em contato com o proprietário para verificar o interesse nos Serviços prestados pela Seazone.',
    requireTrue: ['IsIndicatedWithinSeazoneOperationArea'],
    requireFalse: ['doesThePropertyHaveFurniture'],
  },
  {
    id: 3,
    title: 'Indicação com probabilidade de conversão moderada',
    progress: 40,
    description:
      'O imóvel está dentro da área de abrangência da Seazone, mas o imóvel está em obra. Nosso analista entrará em contato com o proprietário para verificar o interesse nos Serviços prestados pela Seazone.',
    requireTrue: [
      'IsIndicatedWithinSeazoneOperationArea',
      'doesThePropertyHaveFurniture',
      'isThePropertyUnderConstruction',
    ],
  },
  {
    id: 4,
    title: 'Indicação com probabilidade de conversão média',
    progress: 60,
    description:
      'O imóvel está dentro da área de abrangência da Seazone, mas o proprietário não está ciente da indicação. Nosso analista entrará em contato com o proprietário para verificar o interesse nos Serviços prestados pela Seazone.',
    requireTrue: [
      'IsIndicatedWithinSeazoneOperationArea',
      'doesThePropertyHaveFurniture',
    ],
    requireFalse: [
      'isTheOwnerAwareOfTheIndication',
      'isThePropertyUnderConstruction',
    ],
  },
  {
    id: 5,
    title: 'Indicação com probabilidade de conversão alta',
    progress: 80,
    description:
      'O imóvel está dentro da área de abrangência da Seazone, mas o proprietário não está ciente da indicação. Nosso analista entrará em contato com o proprietário para verificar o interesse nos Serviços prestados pela Seazone.',
    requireTrue: [
      'IsIndicatedWithinSeazoneOperationArea',
      'doesThePropertyHaveFurniture',
      'isTheOwnerAwareOfTheIndication',
    ],
    requireFalse: [
      'isThePropertyUnderConstruction',
      'hasTheOwnerAlreadyReceivedTheEBook',
    ],
  },
  {
    id: 6,
    title: 'Indicação com probilidade de conversão excelente',
    progress: 100,
    description:
      'O imóvel está dentro da área de abrangência da Seazone e cumpre com todos os indicativos para conversão do imóvel! Nosso analista entrará em contato com o proprietário o mais breve possível para oferecer os serviços prestados pela Seazone.',
    requireTrue: [
      'IsIndicatedWithinSeazoneOperationArea',
      'hasTheOwnerAlreadyReceivedTheEBook',
      'doesThePropertyHaveFurniture',
      'isTheOwnerAwareOfTheIndication',
    ],
    requireFalse: ['isThePropertyUnderConstruction'],
  },
];
