import styled from 'styled-components';

export const GroupContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`;

export const TitleText = styled.span<{ $color: string, fontSize: string }>`
  color: ${({ $color }) => $color};
  font-size: ${({ fontSize }) => fontSize};
`;

export const RightTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RightTitleText = styled.span<{ $color: string, fontSize: string }>`
  color: ${({ $color }) => $color};
  font-size: ${({ fontSize }) => fontSize};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;
