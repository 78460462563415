/* eslint-disable react/require-default-props */
import React from 'react';
import { UseFormik } from '../../../../../utils/Formik/types';
import { Container, DefaultOption, Option } from './style';

interface ICustomSelectProps {
  id: string;
  name: string;
  formik?: UseFormik<any>;
  placeholder?: string;
  option: {
    id: number | string;
    text: string;
    value?: string;
    sigla?: string;
  }[] | undefined;
}

const CustomSelect = ({
  id,
  name,
  formik,
  placeholder,
  option,
}: ICustomSelectProps) => (
  <Container
    id={id}
    name={name}
    onBlur={formik?.handleBlur}
    onChange={formik?.handleChange}
    isDefaultOption={formik?.values[id] === ''}
  >
    <DefaultOption selected disabled value="">
      {placeholder}
    </DefaultOption>
    {option?.map((item) => (
      <Option
        key={item.id}
        value={item.value ? item.value : item.sigla}
      >
        {item.text}
      </Option>
    ))}
  </Container>
);

export default CustomSelect;
