import React, { FC, KeyboardEvent, useMemo } from 'react';

import {
  MoreVert,
  Cancel,
  Info,
  Edit,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Approval,
} from '@mui/icons-material';

import {
  MenuItem, Menu, IconButton,
} from '@mui/material';
import Tooltip from '../../../Tooltip';
import TextField from '../../../TextField';
import theme from '../../../../styles/themes';

import {
  DescriptionContainer,
  IconContainer,
  MenuItemContainer,
  ContainerNotes,
} from './styles';

import { useExpense } from '../../../../hooks/ExpenseHook/useExpense';
import { ExpenseProps, ExpenseResponseProps, ExpenseStatus } from '../../../../services/Expenses/types';
import { patchExpenses, postExpensesApprove } from '../../../../services/Expenses/request';
import { useToast } from '../../../../context/ToastContext';
import { useUser } from '../../../../context/UserContext';
import { getOneSignalExternalUserId, sendOneSignalPushNotificationByUserId } from '../../../../services/OneSignal/OneSignal';
import { OptionsProps } from './types';
import { translatedPaidBy, translatedReceivedBy } from '../../../../utils/Translator';
import { useLoader } from '../../../../context/LoaderContext';

enum MenuDescription {
  EnumCancel = 'Cancelar despesa',
  EnumCancelHost = 'Cancelar reembolso',
  EnumCancelInfo = 'Confirmar cancelamento?',
  EnumCancelDisabled = 'Despesa cancelada',
  EnumCancelDisabledHost = 'Reembolso cancelado',
  EnumEditExpense = 'Editar despesa',
  EnumEditStatus = 'Atualizar status',
  EnumAprovedStatus = 'Aprovar em massa',
  EnumStatusPreApproved = 'Pré Aprovar',
  EnumStatusApproved = 'Aprovar',
  EnumStatusNotApproved = 'Reprovar',
  EnumStatusPending = 'Pendente',
  EnumConfirmStatus = 'Confirmar atualização?',
  EnumConfirmInfo = 'Confirmar atualização em massa?',
}

type OptionProps = {
  visible: boolean,
  icon?: any,
  description: string,
  function: Function,
};

interface PlayerData {
  external_user_id: string;
}

const EditIconDisabled = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.334 1.99955C11.5091 1.82445 11.7169 1.68556 11.9457 1.5908C12.1745 1.49604 12.4197 1.44727 12.6673 1.44727C12.9149 1.44727 13.1601 1.49604 13.3889 1.5908C13.6177 1.68556 13.8256 1.82445 14.0007 1.99955C14.1757 2.17465 14.3146 2.38252 14.4094 2.61129C14.5042 2.84006 14.5529 3.08526 14.5529 3.33288C14.5529 3.58051 14.5042 3.8257 14.4094 4.05448C14.3146 4.28325 14.1757 4.49112 14.0007 4.66622L5.00065 13.6662L1.33398 14.6662L2.33398 10.9996L11.334 1.99955Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CancelIconDisabled = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3403_1798)">
      <path d="M8.00065 14.6673C11.6825 14.6673 14.6673 11.6825 14.6673 8.00065C14.6673 4.31875 11.6825 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6825 4.31875 14.6673 8.00065 14.6673Z" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 6L6 10" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 6L10 10" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_3403_1798">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Options: FC<OptionsProps> = ({
  item,
  index,
  listUpdateWhoPays,
  setListUpdateWhoPays,
}) => {
  const { setLoad } = useLoader();
  const id = item.id as number;
  const responsible = item.responsible_user;
  const status = item.expense_status || 'Analyzing';
  const paidBy = translatedPaidBy?.[`${item?.paid_by || ''}`];
  const receivedBy = translatedReceivedBy?.[`${item?.received_by || ''}`];
  const paidByUser = `${item?.paid_by_user|| 'Não informado'}`;
  const receivedByUser = `${item?.received_by_user || 'Não informado'}`;
  const reasonPending = item.pending_reason as string || '';

  const {
    handleExpenseData,
    handleOpenModalExpenses,
    handleIsEditingModal,
    handleIndexExpenseSelected,
    expensesList,
    setExpensesList,
  } = useExpense();

  const toast = useToast();

  const { userInformation } = useUser();
  const roles = userInformation?.roles || [];

  const [visible, setVisible] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statusToUpdate, setStatusToUpdate] = React.useState<ExpenseStatus>(status || 'Analyzing');
  const [showConfirmUpdateStatus, setConfirmUpdateStatus] = React.useState<boolean>(false);
  const [pendingNotes, setPendingNotes] = React.useState<string>(reasonPending?.trim() || '');
  const [showExpenseStatusOptions, setShowExpenseStatusOptions] = React.useState<boolean>(false);
  const [showExpenseApprove, setShowExpenseApprove] = React.useState<boolean>(false);

  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setVisible(false);
    setShowExpenseStatusOptions(false);
    setConfirmUpdateStatus(false);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowExpenseStatusOptions(false);
    setConfirmUpdateStatus(false);
    setShowExpenseApprove(false);
    setVisible(false);
    setAnchorEl(null);
  };

  const handleUpdateExpenseState = (newStatus: ExpenseStatus) => {
    const newExpenseList = expensesList?.results
      ?.map((expense: ExpenseResponseProps) => ({
        ...expense,
        expense_status: expense.id === id ? newStatus : expense.expense_status,
        pending_reason: expense.id === id && newStatus === 'Pending' ? pendingNotes : expense.pending_reason,
      }));

    setExpensesList((prev) => ({
      ...prev,
      results: newExpenseList,
    }));

    const newListUpdateWhoPays =  listUpdateWhoPays?.map((state) => ({
      ...state,
      status: state.id === id ? newStatus : state.status,
    }));

    setListUpdateWhoPays(newListUpdateWhoPays);
  };

  const handleSendPushNotification = async () => {
    const foundExpense = expensesList?.results
      ?.find((expense) => expense.id === id && expense.host_id);
    const oneSignalData = await getOneSignalExternalUserId();
    const players = oneSignalData?.players || [];
    players.forEach((player: PlayerData) => {
      if (player?.external_user_id && foundExpense
        // @ts-ignore
        && foundExpense?.host_id?.toString() === player.external_user_id && !window.Cypress) {
        sendOneSignalPushNotificationByUserId(player.external_user_id, `Olá ${responsible?.first_name}, você possui despesa(s) com pendências. Por favor, acesse o Sapron para corrigi-las.`);
      }
    });
  };

  const handleOpenExpensesModal = () => {
    handleCloseMenu();
    handleIndexExpenseSelected(index);
    handleIsEditingModal(true);
    handleExpenseData({
      ...item,
      maintenance_files: item.maintenance_files,
      statement_files: item.statement_files,
    });
    handleOpenModalExpenses(true);
  };

  const paidByAndReceivedByInformed = useMemo(() => !(
    ['Não informado'].includes(paidBy) ||
    ['Não informado'].includes(receivedBy) ||
    ['Não informado'].includes(receivedByUser) ||
    ['Não informado'].includes(paidByUser)), [paidBy, receivedBy, paidByUser, receivedByUser]);

  const handleUpdateStatusExpense = async (newStatus: ExpenseStatus, pendingReason?: string) => {
    try {
      if (id) {
        let newExpense: ExpenseProps = {
          expense_status: newStatus,
        };

        if (pendingReason) {
          newExpense = { ...newExpense, pending_reason: pendingReason };
        }

        if (['Approved', 'Pre_Approved'].includes(newStatus) && !paidByAndReceivedByInformed) {
          toast.error('Falha ao aprovar! É preciso preencher os campos "Quem paga" e "Quem recebe" da despesa antes de aprová-la.');
        } else if (status !== 'Pre_Approved' && newStatus === 'Approved') {
          toast.error('É necessário Pré-Aprovar a despesa antes de aprová-la.')
        } else {
          await patchExpenses(id, newExpense);
          handleUpdateExpenseState(newStatus);
          if (newStatus === 'Pending' && (roles.includes('SeazoneAdministrative') || roles.includes('SeazoneOnboarding'))) {
            handleSendPushNotification();
          }
          handleCloseMenu();

          if (roles.includes('SeazoneAdministrative') || roles.includes('SeazoneOnboarding')) {
            toast.success(newStatus === 'Canceled' ? 'Despesa cancelada com sucesso!'
              : 'Status atualizado com sucesso!');
          }

          if (roles.includes('Host')) {
            toast.success(newStatus === 'Canceled' ? 'Reembolso cancelado com sucesso!'
              : 'Status atualizado com sucesso!');
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.success(newStatus === 'Canceled' ? 'Ocorreu um erro ao cancelar a despesa!'
          : 'Ocorreu um erro ao atualizar o status da despesa!');
      }
    }
  };

  const shouldBeVisibleTo = (permissions: string[]) => {
    const hasPermission = permissions
      .some((role) => roles.includes(role));
    return hasPermission;
  };

  const handleOpenStatusOptions = () => {
    setShowExpenseStatusOptions(true);
    setConfirmUpdateStatus(false);
  };

  const handleOpenApproveInBulk = async () => {
    try {
      if (listUpdateWhoPays?.length === 0) {
        toast.error('Selecione pelo menos 1 despesa');
        return;
      }

      const allPreApprove = listUpdateWhoPays?.every((listItem) => listItem.status === 'Pre_Approved');

      if (!allPreApprove || !paidByAndReceivedByInformed) {
        toast.error('As despesas selecionadas não podem ser aprovadas. Verifique se o status das despesas selecionadas é "Pré-Aprovado" e se os campos "Quem paga" e "Quem recebe" já foram preenchidos.');
        return;
      }

      setLoad(true);

      const ids = (listUpdateWhoPays || []).map((listItem) => listItem.id);

      const payload = {
        ids,
        type: 'update-status',
        status: 'Approved',
      };

      await postExpensesApprove(payload);

      const filteredObjects = expensesList.results.filter((obj) => !ids.includes(obj.id || 0));
      setExpensesList((prev) => ({ ...prev, results: filteredObjects }));
      setListUpdateWhoPays([]);
      toast.success('As depesas selecionadas foram aprovadas');

      setLoad(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Ocorreu um erro ao aprovar as despesas');
      }
      setLoad(false);
    }
  };

  const handleChangeExpenseStatus = (newStatus: ExpenseStatus) => {
    setShowExpenseStatusOptions(false);
    setConfirmUpdateStatus(true);
    setStatusToUpdate(newStatus);
  };

  const handleConfirmationUpdateExpenseStatus = () => {
    if (statusToUpdate === 'Pending' && pendingNotes.trim() !== '') {
      handleUpdateStatusExpense(statusToUpdate, pendingNotes);
    } else {
      handleUpdateStatusExpense(statusToUpdate);
    }
  };

  const handleOpenInfo = () => {
    setVisible(true);
  };

  const options: OptionProps[] = [
    {
      visible:
        (showExpenseStatusOptions || showConfirmUpdateStatus ? false : !visible)
        && !showExpenseApprove,
      icon: Edit,
      description: MenuDescription.EnumEditExpense,
      function: () => handleOpenExpensesModal(),
    },
    {
      visible: shouldBeVisibleTo(['SeazoneOnboarding', 'SeazoneAdministrative']) && !visible && !showExpenseApprove,
      icon: status === 'Approved' ? EditIconDisabled : Edit,
      description: MenuDescription.EnumEditStatus,
      function: status === 'Approved' ? () => {} : () => handleOpenStatusOptions(),
    },
    {
      visible: shouldBeVisibleTo(['SeazoneOnboarding', 'SeazoneAdministrative']) && !visible && !showExpenseApprove && !showExpenseStatusOptions && !showConfirmUpdateStatus,
      icon: Approval,
      description: MenuDescription.EnumAprovedStatus,
      function: () => {
        if (status === 'Approved') return;
        setShowExpenseApprove(true);
      },
    },
    {
      visible: showExpenseStatusOptions && !showExpenseApprove,
      icon: status === 'Pre_Approved' ? RadioButtonChecked : RadioButtonUnchecked,
      description: MenuDescription.EnumStatusPreApproved,
      function: () => handleChangeExpenseStatus('Pre_Approved'),
    },
    {
      visible: showExpenseStatusOptions && !showExpenseApprove,
      icon: status === 'Approved' ? RadioButtonChecked : RadioButtonUnchecked,
      description: MenuDescription.EnumStatusApproved,
      function: () => handleChangeExpenseStatus('Approved'),
    },
    {
      visible: showExpenseStatusOptions && !showExpenseApprove,
      icon: status === 'Denied' ? RadioButtonChecked : RadioButtonUnchecked,
      description: MenuDescription.EnumStatusNotApproved,
      function: () => handleChangeExpenseStatus('Denied'),
    },
    {
      visible: showExpenseStatusOptions && !showExpenseApprove,
      icon: status === 'Pending' ? RadioButtonChecked : RadioButtonUnchecked,
      description: MenuDescription.EnumStatusPending,
      function: () => handleChangeExpenseStatus('Pending'),
    },
    {
      visible: pendingNotes.trim() === '' && statusToUpdate === 'Pending' ? false : showConfirmUpdateStatus,
      icon: Info,
      description: MenuDescription.EnumConfirmStatus,
      function: () => handleConfirmationUpdateExpenseStatus(),
    },
    {
      visible: shouldBeVisibleTo(['SeazoneOnboarding', 'SeazoneAdministrative']) && (showExpenseStatusOptions || showConfirmUpdateStatus ? false : !visible) && !showExpenseApprove,
      icon: status === 'Approved' ? CancelIconDisabled : Cancel,
      description: status === 'Canceled'
        ? MenuDescription.EnumCancelDisabled
        : MenuDescription.EnumCancel,
      function: ['Canceled', 'Approved'].includes(status) ? () => {} : handleOpenInfo,
    },
    {
      visible: shouldBeVisibleTo(['Host']) && (showExpenseStatusOptions || showConfirmUpdateStatus ? false : !visible) && !showExpenseApprove,
      icon: status === 'Approved' ? CancelIconDisabled : Cancel,
      description: shouldBeVisibleTo(['Host'])
       && status === 'Canceled'
        ? MenuDescription.EnumCancelDisabledHost
        : MenuDescription.EnumCancelHost,
      function: ['Canceled', 'Approved'].includes(status) ? () => {} : handleOpenInfo,
    },
    {
      visible: showExpenseStatusOptions || showConfirmUpdateStatus ? false : visible,
      icon: Info,
      description: MenuDescription.EnumCancelInfo,
      function: ['Canceled', 'Approved'].includes(status) ? () => {} : () => handleUpdateStatusExpense('Canceled'),
    },
    {
      visible: showExpenseApprove && !showExpenseStatusOptions,
      icon: Info,
      description: MenuDescription.EnumConfirmInfo,
      function: () => handleOpenApproveInBulk(),
    },

  ];

  const getColors: Record<string, string> = {
    [MenuDescription.EnumCancel]: `${theme.palette.red._500.hex()}`,
    [MenuDescription.EnumCancelHost]: `${theme.palette.red._500.hex()}`,
    [MenuDescription.EnumCancelInfo]: `${theme.palette.blue._100.hex()}`,
    [MenuDescription.EnumConfirmInfo]: `${theme.palette.blue._100.hex()}`,
    [MenuDescription.EnumStatusApproved]: `${theme.palette.blue._850.hex()}`,
    [MenuDescription.EnumStatusNotApproved]: `${theme.palette.blue._850.hex()}`,
    [MenuDescription.EnumStatusPending]: `${theme.palette.blue._850.hex()}`,
    [MenuDescription.EnumConfirmStatus]: `${theme.palette.blue._100.hex()}`,
    [MenuDescription.EnumCancelDisabled]: `${theme.palette.grey._900.hex()}`,
    [MenuDescription.EnumEditExpense]: `${theme.palette.blue._850.hex()}`,
    [MenuDescription.EnumEditStatus]: `${theme.palette.blue._850.hex()}`,
  };

  return (
    <div>
      <Tooltip text="Mais opções" zIndex={1}>
        <IconButton data-cy="btn-more-options" data-testid="btn-more-options" onClick={handleOpenMenu}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        open={openMenu}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            minWidth: '25ch',
            maxWidth: '35ch',
            transition: '0.3s',
            borderRadius: '10px',
            padding: '0.1rem',
            color: status === 'Approved' ? `${theme.palette.grey._400.hex()}` : `${theme.palette.blue._250.hex()}`,
          },
        }}
      >
        {options.map((option) => option.visible && (
        <MenuItem
          key={option.description}
        >
          {option.description === MenuDescription.EnumEditExpense && ['Analyzing', 'Pending'].includes(status) ? (
            <MenuItemContainer
              onClick={() => option.function()}
            >
              {option?.icon && (
              <IconContainer>
                <option.icon
                  style={{
                    color: getColors[option.description],
                  }}
                />
              </IconContainer>
              )}
              <DescriptionContainer>
                <p>{option.description}</p>
              </DescriptionContainer>
            </MenuItemContainer>
          ) : option.description !== MenuDescription.EnumEditExpense && (
          <MenuItemContainer
            onClick={() => option.function()}
          >
            {option?.icon && (
            <IconContainer>
              <option.icon
                style={{
                  color: getColors[option.description],
                }}
              />
            </IconContainer>
            )}
            <DescriptionContainer>
              <p>{option.description}</p>
            </DescriptionContainer>
          </MenuItemContainer>
          )}
        </MenuItem>
        ))}

        {showConfirmUpdateStatus && statusToUpdate === 'Pending' && (
        <ContainerNotes
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
          }}
        >
          {pendingNotes.trim() === '' ? (
            <TextField
              dataCy="input-expense-status-pending-reason"
              placeholder="Motivo da pendência"
              id="pending_reason"
              type="textarea"
              onChange={(e) => setPendingNotes(e.target.value)}
            />
          ) : (
            <TextField
              dataCy="input-expense-status-pending-reason"
              placeholder="Motivo da pendência"
              id="pending_reason"
              type="textarea"
              onChange={(e) => setPendingNotes(e.target.value)}
              value={pendingNotes}
            />
          )}
        </ContainerNotes>
        )}
      </Menu>
    </div>
  );
};
