import { useMemo, useEffect } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';

import { PatchPropertyChangeHostObject } from '../../../../../services/InsertData/types';
import { patchPropertyChangeHost } from '../../../../../services/InsertData/request';
import { requestErrorMessageTranslated } from '../../../utils';

import { useChangePropertyHost } from '../../../../../hooks/useChangePropertyHost/useChangePropertyHost';
import { useToast } from '../../../../../context/ToastContext';

import DragDrawerModal from '../../../../Modal/DragDrawerModal';
import TextField from '../../../../TextField';
import DatePicker from '../../../../DatePicker';
import FormGridRow from '../../../../FormGridRow';
import RulesMessage from '../../RulesMessage';
import Spinner from '../../Spinner';

import {
  Form,
  FormContent,
  DatePickerContainer,
  Spacing,
} from './styles';

interface ChangeHostPropertyFormikValuesType {
  property?: number;
  newHostId?: number;
  oldHostId?: number;
  oldHostName?: string;
  newHostName?: string;
  replacementDate?: string | null | Date;
}

const ModalEditChangeHost = () => {
  const toast = useToast();

  const {
    exchangedHosts,
    modalEditExchangeHost,
    setModalEditExchangeHost,
    updateExchangedHosts,
  } = useChangePropertyHost();

  const handleClose = () => {
    setModalEditExchangeHost({
      open: false,
      infos: null,
    });
  };

  const infos = useMemo(() => modalEditExchangeHost.infos, [modalEditExchangeHost]);

  const formikInitialValues: ChangeHostPropertyFormikValuesType = {
    oldHostName: infos?.old_host?.full_name || '',
    newHostName: infos?.new_host?.full_name || '',
    property: infos?.property?.id || undefined,
    oldHostId: infos?.old_host?.id || undefined,
    newHostId: infos?.new_host?.id || undefined,
    replacementDate: infos?.replacement_date
      ? moment(infos?.replacement_date, 'DD-MM-YYYY').toDate() : null,
  };

  const validation = Yup.object().shape({
    property: Yup.number(),
    oldHostId: Yup.number(),
    newHostId: Yup.number(),
    replacementDate: Yup.date().required('Campo Obrigatório'),
  });

  const formik = useFormik<ChangeHostPropertyFormikValuesType>({
    initialValues: formikInitialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        if (infos?.id) {
          const payload: PatchPropertyChangeHostObject = {
            replacement_date: moment(values.replacementDate).format('YYYY-MM-DD'),
          };

          const response = await patchPropertyChangeHost(infos?.id, payload);
          updateExchangedHosts(exchangedHosts, [{ ...response }]);

          toast.success('Edição Realizada');
          formik.resetForm();
          handleClose();
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          const msg = requestErrorMessageTranslated(e.message);
          toast.error(msg);
        }
      }
    },
  });

  useEffect(() => {
    const date = infos?.replacement_date
      ? moment(infos?.replacement_date, 'DD-MM-YYYY').toDate() : null;
    formik.setFieldValue('replacementDate', date);
  }, [infos]);

  return (
    <DragDrawerModal
      variant="withCancelAndAppyButton"
      title="Editar mudança"
      cancelButtonText="Cancelar"
      applyButtonText="Salvar"
      open={modalEditExchangeHost.open}
      onClose={handleClose}
      handleClickApplyButton={() => formik.handleSubmit()}
      handleClickCancelButton={handleClose}
      maxWidthForModalOnDesk={'693px'}
      maxHeightForModalOnDesk={'auto'}
      activeDragHandAnimation={false}
      disableApplyButton={formik.isSubmitting}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Spinner loading={formik.isSubmitting} />
        <FormContent isVisible={!formik.isSubmitting}>
          <RulesMessage
            title="Regras de mudança"
            description="Entrega das chaves deve ser feita até um dia antes da data escolhida de entrega."
          />
          <Spacing />
          <FormGridRow grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="property"
                label="Imóvel"
                formik={formik}
                value={infos?.property?.code}
                labelClassName="inputLabel"
                disabled
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="oldHostId"
                label="Anfitrião Atual"
                formik={formik}
                value={infos?.old_host?.full_name}
                labelClassName="inputLabel"
                disabled
              />
            </Grid>
          </FormGridRow>

          <FormGridRow grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="newHostId"
                label="Anfitrião Novo"
                formik={formik}
                value={infos?.new_host?.full_name}
                labelClassName="inputLabel"
                disabled
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DatePickerContainer>
                <DatePicker
                  id="replacementDate"
                  dataCy="datepicker-replacement-date"
                  formik={formik}
                  label="Data de Entrega"
                  labelClassName="inputLabel"
                  viewsCustom={['day']}
                  hasInitialDates
                  disableInput
                  disablePast
                  showDaysOutsideCurrentMonth
                />
              </DatePickerContainer>
            </Grid>
          </FormGridRow>
        </FormContent>
      </Form>
    </DragDrawerModal>
  );
};

export default ModalEditChangeHost;
