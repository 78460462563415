import { ExpenseProps, ManualFitProps } from '../../../../context/OwnerPage/OwnerExtractContext/types';
import { useOwnerExtract } from '../../../../hooks/OwnerPage/OwnerExtract/useOwnerExtract';

import Tag from '../Tag';

import {
  Container,
  Content,
  TitleContainer,
  SummaryContainer,
  SummaryContent,
  Divider,
} from './styles';

const ExpenseSection = () => {
  const { extractList } = useOwnerExtract();

  return (
    <Container>
      <Tag
        label="Saídas"
        disableDropIcon
        description="Total de débitos do mês"
        stickTag
      />

      <Content>
        <TitleContainer>
          <span>Despesas</span>
          <Divider />
          <span>{`${extractList?.[0]?.expenses?.total}`}</span>
        </TitleContainer>
      </Content>

      <Content>
        <SummaryContainer>
          <div className="origin-platform">
            <h4>Despesas lançadas</h4>
          </div>

          {extractList?.[0]?.expenses?.posted_expenses?.map((item: ExpenseProps) => (
            <SummaryContent key={item.id}>
              <div className="expense-reason">
                <strong>{`${item.description}`}</strong>
              </div>
              <Divider isDashed />
              <div className="total-value">
                <span>{`${item.value}`}</span>
              </div>
            </SummaryContent>
          ))}

          {extractList?.[0]?.expenses?.posted_expenses.length === 0 && (
            <span className="text-none-expenses">Até o momento, não foram registradas despesas para este período</span>
          )}
        </SummaryContainer>
      </Content>

      <Content className="content-commission">
        <TitleContainer>
          <span>Comissão</span>
          <Divider />
          <span className="total-commission">{`${extractList?.[0]?.commission?.total}`}</span>
        </TitleContainer>
      </Content>

      <Content className="implantation-fee">
        <TitleContainer>
          <span>Taxa de adesão</span>
          <Divider />
          <span className="total-implantation-fee">{`${extractList?.[0]?.implantation_fee}`}</span>
        </TitleContainer>
      </Content>

      <Content className="content-others">
        <TitleContainer>
          <span>Ajuste manual</span>
          <Divider />
          <span className="total-others">
            {`${extractList?.[0]?.others_expenses?.total}`}
          </span>
        </TitleContainer>

        {extractList?.[0]?.others_expenses?.manual_fits?.length > 0 && (
          <SummaryContainer disableMarginTop>
            {extractList?.[0]?.others_expenses?.manual_fits?.map((item: ManualFitProps) => (
              <SummaryContent key={item.id}>
                <div className="range-dates">
                  <strong>{`${item.description} `}</strong>
                </div>
                <Divider isDashed />
                <div className="total-value">
                  <span>{`${item.value}`}</span>
                </div>
              </SummaryContent>
            ))}
          </SummaryContainer>
        )}
      </Content>

    </Container>
  );
};

export default ExpenseSection;
