import {
  Container,
  Content,
  Button,
  Page,
} from './styles';

interface IPagination {
  page: number,
  totalPages: number,
  onClickPrevPage: Function,
  onClickNextPage: Function,
  disableButtonPrev?: boolean,
  disableButtonNext?: boolean,
}

const Pagination = ({
  page = 1,
  totalPages = 1,
  onClickPrevPage = () => {},
  onClickNextPage = () => {},
  disableButtonPrev = false,
  disableButtonNext = false,
}: IPagination) => {
  const ButtonPrev = () => (
    <Button type="button" onClick={() => onClickPrevPage()} disabled={disableButtonPrev}>
      <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.08942 1.07739C8.41486 1.40283 8.41486 1.93047 8.08942 2.2559L2.84534 7.49998L8.08942 12.7441C8.41485 13.0695 8.41485 13.5971 8.08942 13.9226C7.76398 14.248 7.23634 14.248 6.91091 13.9226L1.07757 8.08923C0.752136 7.7638 0.752136 7.23616 1.07757 6.91072L6.91091 1.07739C7.23634 0.751954 7.76398 0.751954 8.08942 1.07739Z" fill="#132644" />
      </svg>
      <p>Anterior</p>
    </Button>
  );

  const ButtonNext = () => (
    <Button type="button" onClick={() => onClickNextPage()} disabled={disableButtonNext}>
      <p>Próximo</p>
      <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.910582 13.9226C0.585145 13.5972 0.585145 13.0695 0.910582 12.7441L6.15466 7.50002L0.910581 2.25594C0.585144 1.93051 0.585144 1.40287 0.910581 1.07743C1.23602 0.751994 1.76366 0.751994 2.08909 1.07743L7.92243 6.91076C8.24786 7.2362 8.24786 7.76384 7.92243 8.08928L2.08909 13.9226C1.76366 14.248 1.23602 14.248 0.910582 13.9226Z" fill="#132644" />
      </svg>
    </Button>
  );

  return (
    <Container>
      <Content>
        <p>
          {'Página '}
          <strong>{page}</strong>
          {' / '}
          <strong>{totalPages}</strong>
        </p>
      </Content>

      <Content>
        <ButtonPrev />
        <Page>{page}</Page>
        <ButtonNext />
      </Content>
    </Container>
  );
};

Pagination.defaultProps = {
  disableButtonPrev: false,
  disableButtonNext: false,
};

export default Pagination;
