/* eslint-disable max-len */
import { FC } from 'react';

import statementIcon from '../../../../../assets/icons/ownerPage/statementIcon.svg';
import cleaningIcon from '../../../../../assets/icons/ownerPage/cleaningIcon.svg';
import entranceIcon from '../../../../../assets/icons/ownerPage/entranceIcon.svg';
import payoutIcon from '../../../../../assets/icons/ownerPage/payoutIcon.svg';
import reembolsoIcon from '../../../../../assets/icons/ownerPage/reembolsoIcon.svg';
import walletIcon from '../../../../../assets/icons/ownerPage/walletIcon.svg';

import TooltipInfo from '../../../../OwnerPage/TooltipInfo';

import {
  Container,
  TitleContainer,
  Icon,
  Title,
  ExpandMoreRoundedContainer,
  ExpandIcon,
  CollapseIcon,
  Description,
} from './styles';

export interface Data {
  [month: string]: string;
  total: string;
}

interface ITag {
  label?: string,
  variant?: '0' | '1' | '2' | '3' | '4' | '5',
  disableDropIcon?: boolean,
  description?: string | JSX.Element,
  isExpanded?: boolean,
  setIsExpanded?: Function,
  stickTag?: boolean,
  data: Data;
  transfer?: boolean;
  totalValue?: string;
  hideTotal?: boolean;
  hideTooltip?: boolean;
  hideExpandIcon?: boolean;
  isCountGrid?: boolean
}

const Tag: FC <ITag> = ({
  label = '',
  variant = '0',
  disableDropIcon = false,
  description = '',
  isExpanded = false,
  setIsExpanded = () => {},
  stickTag = false,
  data,
  totalValue = '',
  hideTotal = false,
  hideTooltip = false,
  hideExpandIcon = false,
  isCountGrid,
}) => {
  const getDescription: Record<string, string | JSX.Element> = {
    'Diárias executadas': 'Percentil sobre o valor bruto das diárias executadas no mês.',
    'Limpezas executadas': 'Valor total das taxas de limpeza realizadas, independente da plataforma de origem da reserva. Não é o valor a ser repassado.',
    'Diárias pagas': 'Valor total das diárias pagas no mês.',
    'Limpezas pagas': 'Valor total das limpezas pagas no mês com check-out no mês vigente das plataformas Airbnb e Website, e check-out no mês anterior das plataformas Booking, Expedia e Decolar.',
    'Outros recebimentos': 'Valor total dos reembolsos somado ao ajustes do mês.',
    Saídas: 'Ajustes financeiros negativos relacionados a problemas com reserva ou duplicidade de despesas e outras despesas.',
    Resultado: 'Valor das diárias pagas menos a taxa de franquia (quando houver), somadas às limpezas pagas e outros recebimentos, subtraindo as saídas.',
  };

  const getDescriptionCount: Record<string, string | JSX.Element> = {
    'Diárias executadas': 'Total de diárias executadas no mês.',
    'Limpezas executadas': 'Total de limpezas executadas no mês',
  };

  const months = Array.from({ length: 12 }, (_, i) => `${i + 1}`);

  const TagIcon: Record <string, string> = {
    0: statementIcon,
    1: entranceIcon,
    2: payoutIcon,
    3: reembolsoIcon,
    4: walletIcon,
    5: cleaningIcon,
  };

  const ExpansiveButton = () => (
    <>
      {!disableDropIcon ? (
        <ExpandMoreRoundedContainer
          onClick={() => setIsExpanded((prevState:boolean) => !prevState)}
          hideExpandIcon={hideExpandIcon}
        >
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </ExpandMoreRoundedContainer>
      ) : <Description>{description}</Description>}
    </>
  );

  return (
    <Container customColor={variant} stickTag={stickTag}>
      <TitleContainer customColor={variant}>
        <div>
          <ExpansiveButton />
          <Icon src={TagIcon[variant]} />
          <Title>{label}</Title>
        </div>
        {!hideTooltip && (
          <TooltipInfo
            title={label}
            subtitle={isCountGrid ? getDescriptionCount[label] : getDescription[label]}
            marginRight="1.2rem"
          />
        )}
      </TitleContainer>

      <p className="spacing" />

      {months.map((month) => <p>{data[month]}</p>)}
      {!hideTotal && <p style={{ fontWeight: 700 }}>{totalValue}</p>}
    </Container>
  );
};

export default Tag;
