import React, { useState } from 'react';
import * as Yup from 'yup';
import { CircularProgress, InputAdornment } from '@mui/material';
import { Eye, EyeOff } from 'react-feather';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion/dist/framer-motion';
import TextField from '../TextField';

import {
  ButtonCreateNewPassword,
  Container,
  ContentNewPassword,
  InputNewPassword,
  StyledForm,
  Text,
} from './styles';
import { changepassword } from '../../services/User/request';
import { useToast } from '../../context/ToastContext';
import { Backdrop } from '../Fab/styles';
import { UseFormik } from '../../utils/Formik/types';

interface Token {
  token: string;
}
function getTokenFromSearch(search: string) {
  const removeQuestionSimbol = search.split('?')[1];
  const getOnlyToken = removeQuestionSimbol.split('=');
  const obj: Token = {
    token: getOnlyToken[1],
  };

  return obj;
}

const Loading = ({ show }: { show: boolean }) => (
  <AnimatePresence>
    {show && (
      <motion.div
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        <Backdrop
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Backdrop>
      </motion.div>
    )}
  </AnimatePresence>
);

const Input = ({
  id,
  label,
  formik,
  placeholder,
}: {
  id: string;
  placeholder: string;
  label: string;
  formik: UseFormik<any>;
}) => {
  const [type, setType] = useState<string>('password');

  return (
    <InputNewPassword>
      <Text>
        <p>{label}</p>
        <span>*</span>
      </Text>

      <TextField
        id={id}
        type={type}
        formik={formik}
        placeholder={placeholder}
        endAdornment={(
          <InputAdornment position="start">
            {type === 'password' ? (
              <EyeOff
                onClick={() => setType('text')}
                strokeWidth={'1'}
                cursor="pointer"
                size={20}
              />
            ) : (
              <Eye
                onClick={() => setType('password')}
                strokeWidth={'1'}
                cursor="pointer"
                size={20}
              />
            )}
          </InputAdornment>
        )}
      />
    </InputNewPassword>
  );
};

const validation = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .required('Senha obrigatória!'),
  confirmationPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não correspondem!')
    .required('Confirmação de senha obrigatória!'),
});

let timer = {} as NodeJS.Timeout;
function stopLoading(callback: Function) {
  timer = setTimeout(() => {
    callback();
    return () => window.clearTimeout(timer);
  }, 1500);
}

const NewPassword: React.FC = () => {
  const [loading, setLoading] = useState({ is: false });

  const toast = useToast();

  const { search: locationSearch } = useLocation();
  const { token } = getTokenFromSearch(locationSearch);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmationPassword: '',
    },
    validationSchema: validation,
    onSubmit: async (data) => {
      try {
        setLoading((old) => ({ ...old, is: true }));
        await changepassword({
          token,
          newPassword: data.password,
        });

        stopLoading(() => {
          toast.success('Senha recuperada com sucesso');
        });
      } catch {
        stopLoading(() => {
          toast.error('Não foi possível recuperar a senha, confira se o token não expirou');
        });
      } finally {
        stopLoading(() => {
          setLoading((old) => ({ ...old, is: false }));
        });
      }
    },
  });

  return (
    <>
      <Loading show={loading.is} />
      <Container>
        <StyledForm onSubmit={formik.handleSubmit}>
          <ContentNewPassword>
            <h1>Definir nova senha</h1>

            <Input
              id="password"
              formik={formik}
              placeholder="Digite sua nova senha"
              label="Nova senha (mín. 8 caracteres)"
            />

            <Input
              formik={formik}
              id="confirmationPassword"
              label="Confirmar nova senha"
              placeholder="Digite a senha novamente"
            />

            <ButtonCreateNewPassword type="submit">
              Criar nova senha
            </ButtonCreateNewPassword>
          </ContentNewPassword>
        </StyledForm>
      </Container>
    </>
  );
};

export default NewPassword;
