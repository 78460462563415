import styled, { keyframes } from 'styled-components';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const ButtonContainer = styled.div`
  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  height: auto;
  &.mobile-width {
    button {
      width: 100% !important;
      height: 50px;
    }

    span {
      white-space: pre-wrap !important;
    }

  }

  @media (max-width: 1400px) {
    transform: scale(0.9) !important;
  }
  @media (max-width: 1276px) {
    transform: scale(0.8) !important;
  }

  @media (max-width: 1100px) {
    transform: unset !important;
  }




`;
