import { useContextSelector } from 'use-context-selector';

import { ExpensesReportContext } from '../../../context/OwnerPage/OwnerReport/ExpensesReportContext';

export function useExpensesReport() {
  const loading = useContextSelector(ExpensesReportContext,
    (state) => state.loading);

  const handleLoading = useContextSelector(ExpensesReportContext,
    (state) => state.setLoading);

  const ownerExpenses = useContextSelector(ExpensesReportContext,
    (state) => state.ownerExpenses);

  const handleOwnerExpenses = useContextSelector(ExpensesReportContext,
    (state) => state.setOwnerExpenses);

  const startDate = useContextSelector(ExpensesReportContext,
    (state) => state.startDate);

  const setStartDate = useContextSelector(ExpensesReportContext,
    (state) => state.setStartDate);

  const endDate = useContextSelector(ExpensesReportContext,
    (state) => state.endDate);

  const setEndDate = useContextSelector(ExpensesReportContext,
    (state) => state.setEndDate);

  const propertyId = useContextSelector(ExpensesReportContext,
    (state) => state.propertyId);

  const setPropertyId = useContextSelector(ExpensesReportContext,
    (state) => state.setPropertyId);

  const noDataFound = useContextSelector(ExpensesReportContext,
    (state) => state.noDataFound);

  const handleNoDataFound = useContextSelector(ExpensesReportContext,
    (state) => state.setNoDataFound);

  const expensesModal = useContextSelector(ExpensesReportContext,
    (state) => state.expensesModal);

  const handleExpensesModal = useContextSelector(ExpensesReportContext,
    (state) => state.setExpensesModal);

  const downloadOwnerExpensesFiles = () => {
    const expensesFiles = expensesModal.expense.expenses_files || [];
    if (expensesFiles.length > 0) {
      expensesFiles.forEach((file) => {
        const fileName = file.file.name;
        const fileUrl = file.file.url as string;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `${fileName}`);
        link.setAttribute('target', '_blank');
        link.download = fileName;
        link.click();
        document.body.appendChild(link);
      });
      return true;
    }
    return false;
  };

  return {
    loading,
    handleLoading,
    ownerExpenses,
    handleOwnerExpenses,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    propertyId,
    setPropertyId,
    noDataFound,
    handleNoDataFound,
    expensesModal,
    handleExpensesModal,
    downloadOwnerExpensesFiles,
  };
}
