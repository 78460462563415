import {
  PropertyStatusCircle,
  PropertyStatusContainer,
  PropertyStatusText,
} from './styles';

type OwnerPropertyCondition = 'Available' | 'Blocked' | 'Rented';
type OwnerPropertyStatus =
  | 'Active'
  | 'Inactive'
  | 'Onboarding'
  | 'Closed'
  | 'Signed_Contract';

interface PropertyStatusProps {
  propertyCondition?: OwnerPropertyCondition;
  status?: OwnerPropertyStatus;
}

export function PropertyStatus({
  propertyCondition = 'Blocked',
  status = 'Inactive',
}: PropertyStatusProps) {
  return (
    <PropertyStatusContainer>
      <PropertyStatusCircle status={status} condition={propertyCondition} />
      { status === 'Active' && propertyCondition === 'Available' && (
        <PropertyStatusText className="text-gray-800">Disponível</PropertyStatusText>
      )}
      { status === 'Active' && propertyCondition === 'Blocked' && (
        <PropertyStatusText className="text-gray-800">Bloqueado</PropertyStatusText>
      )}
      { status === 'Active' && propertyCondition === 'Rented' && (
        <PropertyStatusText className="text-gray-800">Locado</PropertyStatusText>
      )}
      { status === 'Onboarding' && (
        <PropertyStatusText className="text-gray-800">Implantação</PropertyStatusText>
      )}
      { status === 'Inactive' && (
        <PropertyStatusText className="text-gray-800">Inativo</PropertyStatusText>
      )}
    </PropertyStatusContainer>
  );
}

PropertyStatus.defaultProps = {
  propertyCondition: 'Blocked',
  status: 'Inactive',
};
