import React from 'react';

import {
  AnimateSharedLayout,
} from 'framer-motion';

import {
  Container,
  ListContent,
} from './style';

import Item from './Item';
import { IFiles } from './types';
import { item, list } from './utils';

const CardsList = ({
  title,
  category,
  propertyId,
  filesLoaded,
  imagesLoaded,
}: IFiles) => (
  <Container
    variants={list}
    initial="hidden"
    animate="visible"
  >
    <AnimateSharedLayout>
      <ListContent layout variants={item}>
        <Item
          title={title}
          category={category}
          propertyId={propertyId}
          filesLoaded={filesLoaded}
          imagesLoaded={imagesLoaded}
        />
      </ListContent>
    </AnimateSharedLayout>
  </Container>
);

export default CardsList;
