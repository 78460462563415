import React from 'react';
import { PageTitle } from '../../utils/style';

import Cards from './Cards';

const CardsSelection = () => (
  <>
    <PageTitle
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 10 }}
    >
      Escolha uma categoria para indicar
    </PageTitle>
    <Cards />
  </>
);

export default CardsSelection;
