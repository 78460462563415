import React, { useState } from 'react';

import { motion } from 'framer-motion';

import { LinearProgress } from '@mui/material';
import { Container, MainContent } from './style';

import Delete from './Delete';
import Arrows from './Arrows';

import { ILoading } from '../types';
import { BasePreCheckinProps } from '../types/props';
import { usePreCheckin } from '../../../../../../../../context/PreCheckin/PreCheckin';

interface IHeader extends BasePreCheckinProps {
  expanded: boolean;
  guestLoading: ILoading;
  setExpanded: (value: boolean) => void;
}

const Header = ({
  index,
  guest,
  isMain,
  expanded,
  setExpanded,
  guestLoading,
}: IHeader) => {
  const { mode } = usePreCheckin();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleClick = () => {
    if (guestLoading.is) {
      return;
    }
    setExpanded(!expanded);
  };

  function validateName() {
    function validateMain() {
      const { name } = guest;
      if (name.length > 3) {
        return name;
      }
      return 'Hóspede Principal';
    }

    function validateGuest() {
      const { name } = guest;
      if (name.length > 3) {
        return ` ${name}`;
      }
      return `Hóspede (${index + 1})`;
    }

    if (isMain) {
      return validateMain();
    }

    if (confirmDelete) {
      return 'Você tem certeza ? Esta ação não poderá ser desfeita';
    }

    return validateGuest();
  }

  if (isMain) {
    return (
      <Container disabled={guestLoading.is} expanded={expanded}>
        {guestLoading.is && (
          <LinearProgress
            style={{
              bottom: 0,
              left: 0,
              zIndex: 10,
              width: '100%',
              position: 'absolute',
            }}
          />
        )}
        <MainContent whileTap={{ scale: 0.9 }} onClick={handleClick}>
          {!confirmDelete && <Arrows open={expanded} />}
          <motion.h1 initial={{ y: 10 }} animate={{ y: 0 }}>
            {validateName()}
          </motion.h1>
        </MainContent>
      </Container>
    );
  }

  return (
    <Container disabled={guestLoading.is} expanded={expanded}>
      {guestLoading.is && (
      <LinearProgress
        style={{
          bottom: 0,
          left: 0,
          zIndex: 10,
          width: '100%',
          position: 'absolute',
        }}
      />
      )}
      <MainContent
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleClick}
        capitalize={!confirmDelete}
      >
        {!confirmDelete && <Arrows open={expanded} />}
        <motion.h1 initial={{ y: 10 }} animate={{ y: 0 }}>
          {validateName()}
        </motion.h1>
      </MainContent>

      {mode.is === 'edit' && (
        <Delete
          guest={guest}
          disabled={guestLoading.is}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
        />
      )}
    </Container>
  );
};

export default Header;
