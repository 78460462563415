import styled from 'styled-components';
import { animationTime, shimmerAnimation } from '../animationShimmer';

export const Container = styled.div`
  position: fixed;
  left: 20;
  background: ${({ theme }) => theme.palette.white.main.hex()};
  filter: drop-shadow(5px 25px 15px rgba(57,74,107,0.4));
  z-index: 10;
  height: 100vh;
`;

export const ContainerGrouped = styled.div`
  width: 185px;
  height: 100vh;
`;

export const Grouped = styled.div`
  display: flex;
  height: 2.4rem;
  margin-right: 0.8rem;
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ContainerProperty = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dashed ${({ theme }) => theme.palette.grey._400.hex()};
`;

export const PropertyImage = styled.div`
  width: 4.5rem;
  height: 3.5rem;
  position: relative;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  margin: 0.8rem;
  &:after {
    content: '';
    left: 45px;
    top: -7px;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
    background: ${({ theme }) => theme.palette.grey._400.hex()};
    border-radius: 50%;
    position: absolute;
    width: 15px;
    height: 15px;
  }
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;

`;

export const PropertyDesc = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const PropertyTitle = styled.div`
  width: 4rem;
  height: 1.5rem;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;

`;

export const PropertyUser = styled.p`
  width: 5rem;
  height: 0.6rem;
  margin-top: 0.5rem;
  background: ${({ theme }) => theme.palette.grey._400.hex()};
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
`;
