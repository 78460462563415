import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 30px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const CardItemContainer = styled(motion.div)<{
  inDevelopment?: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  padding: 10px 25px;
  border-radius: 10px;

  border: 1px solid transparent;

  cursor: pointer;

  min-height: 100px;
  width: 100%;

  justify-content: space-between;

  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: border 0.3s;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #ccc;
  }

  ${({ inDevelopment }) => inDevelopment
    && css`
      filter: grayscale(1);

      h1 {
        opacity: 0.6;
      }
    `}
`;

export const ItemContainer = styled(motion.div)`
  width: 50%;
  display: flex;
  justify-content: center;

  svg {
    path {
      fill: #042675;
    }
  }
`;

export const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-weight: 400;
  user-select: none;
  font-size: 1.5rem;
  font-style: normal;
  font-family: "Inter";
`;
