import { useEffect } from 'react';

import { getAccountSimpleHosts } from '../../services/AccountSimpleHosts/request';
import { getAccountOwnerOnboarding } from '../../services/Onboarding/request';

import { useOnboardingPage } from '../../hooks/useOnboarding/useOnboarding';
import OnboardingCard from './OnboardingCards';
import OnboardingPhases from './OnboardingPhases';

import {
  Wrapper,
  Container,
  Content,
  Header,
  Title,
  PhasesCard,
} from './styles';

const OnboardingPage = () => {
  const { setListOwner, setListHost, setLoadingOwner } = useOnboardingPage();

  function getAllOwners() {
    setLoadingOwner(true);

    getAccountOwnerOnboarding()
      .then((result) => {
        setListOwner(result);
        setLoadingOwner(false);
      }).catch(() => {
        setListOwner([]);
        setLoadingOwner(false);
      }
    );
  }
  
  function getAllHosts() {
    getAccountSimpleHosts()
      .then((result) => setListHost(result))
      .catch(() => setListHost([]));
  }
  
  useEffect(() => {
    getAllOwners();
    getAllHosts();
  }, []);

  return (
    <Wrapper>
      <Header>
        <Title>Handover Proprietário</Title>
      </Header>
      <Container>
        <Content>
          <OnboardingCard />
        </Content>
        <PhasesCard>
          <OnboardingPhases />
        </PhasesCard>
      </Container>
    </Wrapper>
);
}

export default OnboardingPage;
