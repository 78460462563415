import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px;
  background-color: ${({ theme }) => theme.palette.grey._240.hex()};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-size: 30px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.grey._550.hex()};
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.grey._550.hex()};
`;
