import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);

  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */

  transition: all 0.4s;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  border-radius: 20px;
  max-width: 100px;

  z-index: 20;
  width: 100%;
  height: 95%;

  padding-top: 10vh;
`;

export const BurgerContainer = styled(motion.div)`
  position: absolute;
  top: 50px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 50;

  background-color: #E6E6E6;
  padding: 10px;
  border-radius: 7px;

  svg{
    path{
      fill: #394760;
    }
  }
`;

export const Content = styled.div`
  gap: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  button {
    border: none;
    background: transparent;
  }

  a {
    text-decoration: none;
  }
`;
