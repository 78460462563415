import React from 'react';

import { Divider } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LocationOnOutlined, PersonOutline } from '@mui/icons-material';

import { formatedDateToShow } from '../../../../../utils/Formatter';
import { usePreCheckin } from '../../../../../context/PreCheckin/PreCheckin';

import {
  Cep,
  Code,
  Date,
  Title,
  Address,
  Section,
  Container,
  DateContent,
  DateContainer,
} from './style';

const CardInfo = () => {
  const { info } = usePreCheckin();

  function convertDate(date: string) {
    return formatedDateToShow(date);
  }

  function formatAddress() {
    const address = `${info?.property?.address?.street}, ${info?.property?.address?.number}, ${info?.property?.address?.neighborhood}, ${info?.property?.address?.city}, ${info?.property?.address?.state}`;
    return address;
  }

  return (
    <Container>
      <Title>
        <Code>{info?.property?.code}</Code>
        .
        {' '}
        <Address>{formatAddress()}</Address>
        <Cep>
          CEP:
          {' '}
          {info?.property?.address?.postal_code}
        </Cep>
      </Title>
      <Divider />
      <DateContainer>
        <CalendarMonthIcon style={{ fontSize: '1.8rem', color: '#0d4bd0' }} />
        <DateContent>
          {' '}
          <Date>{convertDate(info?.check_in_date)}</Date>
          {' '}
          <EastIcon style={{ color: '#0d4bd0' }} />
          {' '}
          <Date>{convertDate(info?.check_out_date)}</Date>
        </DateContent>
      </DateContainer>
      <Divider />
      <Section>
        <PersonOutline style={{ fontSize: '2rem', color: '#0d4bd0' }} />
        <h1>
          Até
          {' '}
          {info?.property?.guest_capacity}
          {' '}
          Hóspedes
        </h1>
      </Section>
      <Divider />
      <Section>
        <LocationOnOutlined style={{ fontSize: '1.8rem', color: '#0d4bd0' }} />
        <a target="_blank" href={`https://www.google.com.br/maps/place/${formatAddress()}`}>
          Ver localização no mapa
        </a>
      </Section>
    </Container>
  );
};

export default CardInfo;
