import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ManagementPanelCardContainer } from './styles';

type CardType = 'IMPORTSTAYSRESERVATION' | 'EXPORTCSV';

interface ManagementPanelCardProps {
  name: string;
  time: number;
  cardType: CardType;
  onClick: () => void;
}

const ManagementPanelCard = ({
  name, time, cardType, onClick,
}: ManagementPanelCardProps) => (
  <ManagementPanelCardContainer time={time} onClick={onClick}>
    {cardType === 'IMPORTSTAYSRESERVATION' && <AddToQueueIcon />}
    {cardType === 'EXPORTCSV' && <FileDownloadIcon />}
    <p>{name}</p>
  </ManagementPanelCardContainer>
);

export default ManagementPanelCard;
