import { Button } from './styles';

interface ICloseButton {
  onClose: Function;
}

const CloseButton = ({ onClose }: ICloseButton) => (
  <Button type="button" onClick={() => onClose()}>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.281 14.9785C15.3507 15.051 15.406 15.1372 15.4437 15.2321C15.4814 15.3269 15.5008 15.4285 15.5008 15.5312C15.5008 15.6338 15.4814 15.7355 15.4437 15.8303C15.406 15.9252 15.3507 16.0113 15.281 16.0839C15.2114 16.1565 15.1286 16.2141 15.0376 16.2534C14.9465 16.2927 14.849 16.3129 14.7504 16.3129C14.6519 16.3129 14.5543 16.2927 14.4632 16.2534C14.3722 16.2141 14.2895 16.1565 14.2198 16.0839L8.00042 9.60444L1.78104 16.0839C1.64031 16.2305 1.44944 16.3129 1.25042 16.3129C1.05139 16.3129 0.860523 16.2305 0.719792 16.0839C0.579062 15.9373 0.5 15.7385 0.5 15.5312C0.5 15.3239 0.579062 15.1251 0.719792 14.9785L6.9401 8.49995L0.719792 2.02143C0.579062 1.87484 0.5 1.67601 0.5 1.4687C0.5 1.26138 0.579062 1.06256 0.719792 0.915962C0.860523 0.769367 1.05139 0.687012 1.25042 0.687012C1.44944 0.687012 1.64031 0.769367 1.78104 0.915962L8.00042 7.39545L14.2198 0.915962C14.3605 0.769367 14.5514 0.687012 14.7504 0.687012C14.9494 0.687012 15.1403 0.769367 15.281 0.915962C15.4218 1.06256 15.5008 1.26138 15.5008 1.4687C15.5008 1.67601 15.4218 1.87484 15.281 2.02143L9.06073 8.49995L15.281 14.9785Z" fill="#394760" />
    </svg>
  </Button>
);

export default CloseButton;
