import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const Container = styled.div`
  gap: 20px;
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
`;

export const CloseButton = styled(CloseIcon)`
  top: 20px;
  right: 20px;
  color: #000;
  font-size: 1rem;
  position: absolute;
`;
