import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const EyeContainer = styled.button`
  border: none;
  cursor: pointer;
  background: none;
`;

export const MoneyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  width: 160px;
  min-height: 50px;
`;

export const HideMoney = styled(motion.div)`
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background: #EBEBF5;
  transform-origin: 0 50%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Money = styled(motion.h1)`
  font-weight: bold;
  font-size: 1.1rem;
  color: #001840;
`;

export const EyeButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const HideBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;

  width: 100%;
  gap: 10px;

  p {
    font-size: 1rem;
    opacity: 0.9;
  }

  svg {
    opacity: 0.5;
    width: 20px;
  }
`;
