import React from 'react';
import {
  Title,
  Header,
  Container,
  Description,
} from '../../../../common/style';
import { data } from './data';
import Card from '../../../../common/Card';

const CriteriaIndication = () => (
  <Container>
    <Header>
      <Title>Critérios para indicação:</Title>
      <Description>
        Quanto mais próxima a sua indicação dessas características, maiores serão
        as chances de conversão do contato na assinatura do contrato.
      </Description>
    </Header>

    <Card desktopCustomColumns="1fr 1fr" items={data} />
  </Container>
);

export default CriteriaIndication;
