import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Alert = styled(motion.p)`
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  flex-direction: column;
  gap: 7px;

  color: #ff9900;

  span {
    font-weight: bold;
  }
`;

export const Container = styled(motion.div)`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Section = styled(motion.div)`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const Row = styled(motion.div)`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Choise = styled.p<{
  active: boolean;
}>`
  &&&& {
    opacity: 0.4;
    font-size: 1.1rem;
    font-weight: 600;
    transition: opacity 0.2s;

    ${({ active }) => active
      && css`
        opacity: 1;
      `}
  }
`;
