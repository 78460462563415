export const data = {
  notes: '',
  concluded: false,
  questionList: [
    {
      id: 0,
      title: 'Banheiro',
      questions: [
        {
          id: 0,
          name: 'Olhou em baixo do sofa.',
          checked: false,
        },
        {
          id: 1,
          name: 'Assistiu uma série na sala para verificar se esta confortavel',
          checked: false,
        },
        {
          id: 2,
          name: 'Guardou todos os lenções de cama',
          checked: false,
        },
      ],
    },
    {
      id: 1,
      title: 'Quarto',
      questions: [
        {
          id: 3,
          name: 'Arrumar a cama',
          checked: false,
        },
        {
          id: 4,
          name: 'Organizar guarda roupa',
          checked: false,
        },
        {
          id: 5,
          name: 'Desligar luzes',
          checked: false,
        },
      ],
    },
    {
      id: 2,
      title: 'Sala',
      questions: [
        {
          id: 6,
          name: 'Desligar televisão',
          checked: false,
        },
        {
          id: 7,
          name: 'Homem aranha',
          checked: false,
        },
        {
          id: 8,
          name: 'Cochilar no sofá',
          checked: false,
        },
      ],
    },
    {
      id: 3,
      title: 'Cozinha',
      questions: [
        {
          id: 9,
          name: 'Testar o fogão',
          checked: false,
        },
        {
          id: 10,
          name: 'Limpar geladeira',
          checked: false,
        },
        {
          id: 11,
          name: 'Fazer uma boquinha antes de ir embora',
          checked: false,
        },

      ],
    },
    {
      id: 4,
      title: 'Área de serviço',
      questions: [
        {
          id: 12,
          name: 'rega as prantinha',
          checked: false,
        },
        {
          id: 13,
          name: 'Eliminar os Ratatouille',
          checked: false,
        },
        {
          id: 14,
          name: 'passar um cafezinho antes de ir embora',
          checked: false,
        },
      ],
    },
  ],
};
