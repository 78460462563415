import React from 'react';

import { Copy } from 'react-feather';

import {
  useToast,
} from '../../../../context/ToastContext';

import {
  StatusProps,
} from '../../types';

import {
  convertStatusPropsToStatusToShow,
} from '../../utils';

import {
  numberToCurrency,
} from '../../../../utils/Formatter';

import {
  Item,
  Title,
  Circle,
  Container,
  ItemContainer,
  // firstColumn,
} from './styles';

import Tooltip from '../../../Tooltip';
import AdjustButton from './AdjustButton';

interface BodyProps {
  id: number;
  cpf: string;
  host: string;
  name: string;
  email: string;
  address: string;
  address_number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  postal_code: string;
  nameOfNf: string;
  cnpj: string;
  commission: number;
  status: StatusProps;
}

const Body = ({
  id,
  cpf,
  cnpj,
  host,
  name,
  email,
  status,
  address,
  address_number,
  complement,
  district,
  city,
  state,
  postal_code,
  nameOfNf,
  commission,
}: BodyProps) => {
  const toast = useToast();

  const statusToShow = convertStatusPropsToStatusToShow(status);

  const copyString = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success('Texto copiado para a àrea de tranferência !!');
      return text;
    } catch (e) {
      return e;
    }
  };

  const reduceLetter = (text: string, max?: number) => {
    const defaultMax = 12;
    if (max) {
      if (text?.length > max) { return `${text.substring(0, max)}...`; }
      return text.substring(0, max);
    }
    if (text?.length > defaultMax) {
      return `${text.substring(0, defaultMax)}...`;
    }
    return text;
  };

  return (
    <Container>
      <ItemContainer>

        <Item>
          {/* <div className="firstColumn"> */}
          <Circle status={status || 'Not done'} />
          <Title>{statusToShow}</Title>
          <AdjustButton nfId={id} />
          {/* </div> */}
        </Item>
        <Item>
          <Title>{name}</Title>
        </Item>

        <Item>
          <Title>{nameOfNf}</Title>
        </Item>

        <Item>
          <Title>{host}</Title>
        </Item>

        <Item>
          <Title>{numberToCurrency(commission)}</Title>
        </Item>

        <Item onClick={() => copyString(cpf)}>
          <Tooltip text={cpf}>
            <Title pointer>
              {reduceLetter(cpf)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(cnpj)}>
          <Tooltip text={cnpj}>
            <Title pointer>
              {reduceLetter(cnpj)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(address)}>
          <Tooltip text={address}>
            <Title pointer>
              {reduceLetter(address, 35)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(address_number)}>
          <Tooltip text={address_number}>
            <Title pointer>
              {reduceLetter(address_number)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(complement)}>
          <Tooltip text={complement}>
            <Title pointer>
              {reduceLetter(complement)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(district)}>
          <Tooltip text={district}>
            <Title pointer>
              {reduceLetter(district)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(city)}>
          <Tooltip text={city}>
            <Title pointer>
              {reduceLetter(city)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(state)}>
          <Tooltip text={state}>
            <Title pointer>
              {reduceLetter(state)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(postal_code)}>
          <Tooltip text={postal_code}>
            <Title pointer>
              {reduceLetter(postal_code)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

        <Item onClick={() => copyString(email)}>
          <Tooltip text={email}>
            <Title pointer>
              {reduceLetter(email, 20)}
              <Copy className="copy-icon" size={17} />
            </Title>
          </Tooltip>
        </Item>

      </ItemContainer>
    </Container>
  );
};

export default Body;
