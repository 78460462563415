import React, {
  createContext, useState, ReactNode, useContext,
} from 'react';

interface MenuLateralContextData {
  open: boolean;
  setOpen: (a: boolean) => void;
}

interface Props {
  children: ReactNode;
}

const MenuLateralContext = createContext<MenuLateralContextData>({} as
  MenuLateralContextData);

export const MenuLateralProvider = ({
  children,
}: Props) => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <MenuLateralContext.Provider value={{
      open,
      setOpen,
    }}
    >
      {children}
    </MenuLateralContext.Provider>
  );
};

export function useMenuLateral(): MenuLateralContextData {
  const { open, setOpen } = useContext(MenuLateralContext);
  return { open, setOpen };
}
