import { Email, Person, Phone } from '@mui/icons-material';
import { GetOwnerRequest } from '../../../services/Owner/types';
import ModalTemplate from './ModalTemplate';
import {
  Container, Content, ContentLeft, ContentRight, Title, Wrapper,
} from './styles';

interface Props {
  onClose: Function;
  open: boolean;
  ownerSelected: GetOwnerRequest;
}

const ModalMoreInformation = ({
  open, onClose, ownerSelected,
}: Props) => (
  <ModalTemplate
    open={open}
    handleClose={onClose}
  >
    <Wrapper>
      <Title>Informações do proprietário</Title>
      <Container>
        <ContentLeft>
          <Content>
            <div className="row">
              <Person />
              {' '}
              {`${ownerSelected?.user?.first_name} ${ownerSelected?.user?.last_name}`}
            </div>
            <div className="row">
              <Phone />
              {' '}
              {ownerSelected.user?.phone_number1}
            </div>
            <div className="row">
              <Email />
              {' '}
              {ownerSelected?.user?.email}
            </div>
          </Content>
          <Content>
            <div className="row">
              <span>Profissão:</span>
              {' '}
              <p>{ownerSelected.profession || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Estado civil:</span>
              {' '}
              <p />
              {ownerSelected.marital_status || 'Não informado'}
            </div>
            <div className="row">
              <span>Nacionalidade:</span>
              {' '}
              <p>{ownerSelected.profession || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Cidade natal:</span>
              {' '}
              <p>{ownerSelected.hometown || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Instagram</span>
              {' '}
              <p>{ownerSelected.instagram_profile || 'Não informado'}</p>
            </div>
          </Content>
        </ContentLeft>
        <ContentRight>
          <Content>
            <div className="row">
              <span>E-mail para operação:</span>
              {' '}
              <p>{ownerSelected.email_for_operation || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Quantidade de propriedades:</span>
              {' '}
              <p>{ownerSelected.properties_owned || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Propriedades para alugar:</span>
              {' '}
              <p>{ownerSelected.properties_to_rent || 'Não informado'}</p>
            </div>

            <div className="row">
              <span>Renda:</span>
              {' '}
              <p>{ownerSelected.income || 'Não informado'}</p>
            </div>
            <div className="row">
              <span>Mora na mesma cidade que a propriedade:</span>
              {' '}
              <p>
                {ownerSelected.lives_same_town_as_property || 'Não informado'}
              </p>
            </div>
            <div className="row">
              <span>Meet Seazone:</span>
              {' '}
              <p>{ownerSelected.meet_seazone || 'Não informado'}</p>
            </div>
          </Content>
        </ContentRight>
      </Container>
    </Wrapper>
  </ModalTemplate>
);

export default ModalMoreInformation;
