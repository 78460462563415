import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { postEnterprise } from '../../../services/Enterprise/request';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';

import { FormAddSpot } from './Forms/FormAddSpot';
import FormButton from '../../FormButton';

import {
  Container,
  Card,
  Form,
  Header,
  Title,
  Buttons,
} from './styles';

const InsertSpot = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();

  const validationSchema = Yup.object().shape({
    enterpriseName: Yup.string().required('Campo obrigatório'),
    enterpriseUnits: Yup.string()
      .matches(
        /^(\d+-\d+)(,\s*\d+-\d+)*$/,
        'Insira as unidades em intervalos de números separados por vígulas: 1-5, 202-204, 403-405',
      )
      .required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      enterpriseName: '',
      enterpriseUnits: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        try {
          await postEnterprise({
            enterprise_name: values.enterpriseName,
            enterprise_units: values.enterpriseUnits,
          });
          navigate('/inserirdados');
          toast.success('Empreendimento salvo com sucesso!');
        } catch (e: unknown) {
          if (e instanceof Error) {
            toastErrorRequest(e);
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <Container>
      <Card>
        <Header>
          <Title>Inserir Spot para indicação</Title>
        </Header>
        <Form onSubmit={formik.handleSubmit}>
          <FormAddSpot formik={formik} />
          <Buttons>
            <div>
              <FormButton
                type="button"
                variant="outlined"
                onClick={() => navigate('/inserirdados')}
              >
                Cancelar
              </FormButton>
              <FormButton
                type="submit"
              >
                Salvar
              </FormButton>
            </div>
          </Buttons>
        </Form>
      </Card>
    </Container>
  );
};

export default InsertSpot;
