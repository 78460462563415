import moment from 'moment';
import request from '../request';
import { GuestFormData, RequestGuest, PatchGuestForm } from './types';
import { GuestUserDetail, UserMeProps } from '../User/types';

export const getGuest = async (search: string): Promise<RequestGuest[]> => {
  const { data } = await request.get(`/account/guest/autocomplete/?search=${search}`);
  return data;
};

export const getGuestById = async (id: number): Promise<GuestUserDetail> => {
  const { data } = await request.get(`/account/guest/${id}/`);
  return data as GuestUserDetail;
};

export const putGuest = async (id: number, data: GuestUserDetail) => {
  await request.put(`/account/guest/${id}/`, {
    ...data,
  });
};

export const postGuest = async (user: UserMeProps) => {
  const { data: guest } = await request.post('/account/guest/', {
    user: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone_number1: user.phone_number1,
      birth_date: moment(user.birth_date).format('DD/MM/YYYY'),
      gender: user.gender,
      main_role: 'Guest',
      is_individual: user.is_individual,
      cpf: user.cpf,
      cnpj: user.cnpj === '' ? null : user.cnpj,
      corporate_name: user.corporate_name,
      trading_name: user.trading_name,
      main_address: {
        street: user.main_address?.street,
        number: user.main_address?.number,
        neighborhood: user.main_address?.neighborhood,
        city: user.main_address?.city,
        state: user.main_address?.state,
        postal_code: user.main_address?.postal_code,
        country: user.main_address?.country,
      },
    },
  });

  return guest.id;
};

export const putGuestReservation = async (id: number, data: GuestFormData) => {
  await request.put(`/account/guest/${id}/`, {
    ...data,
  });
};

export const patchGuest = async (id: number, data: PatchGuestForm) => {
  await request.patch(`/account/guest/${id}/`, {
    ...data,
  });
};
