import React from 'react';
import { motion } from 'framer-motion';
import { Container } from './style';
import { useViewMode } from '../ViewMode';

const Message = () => {
  const { mode, exitViewMode } = useViewMode();

  const name = React.useMemo(() => mode?.user_info?.nickname
      || mode?.user_info?.first_name
      || 'Usuário não informado',
  [mode]);

  if (mode?.is) {
    return (
      <Container>
        <h1>
          Você esta vendo o sistema como:
          <span>
            {name}
            .
          </span>
        </h1>

        <p>
          Você esta no modo de visualização, neste modo você é capaz de ver o sistema como se fosse
          {' '}
          {name}
          ,
          {' '}
          porém, não sera possível realizar nenhuma ação em seu nome.
        </p>

        <motion.button
          onClick={exitViewMode}
          whileTap={{ scale: 0.95 }}
          type="button"
        >
          Sair do modo de visualização
        </motion.button>
      </Container>
    );
  }

  return null;
};

export default Message;
