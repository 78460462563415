import styled, { css } from 'styled-components';
import { animationTime, shimmerAnimation } from '../../../../Calendar/LoadingShimmer/animationShimmer';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-areas: 'content-header';
  border: 1px solid ${({ theme }) => theme.palette.grey._340.hex()};
`;

export const HeaderContent = styled.div<{ disableColor?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 'content-header';
  width: 80%;
  height: 80%;
  max-width: 130px;
  max-height: 100px;
  margin: 0.5rem;
  :first-child {
    padding-left: 1.5rem;
  }
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: ${({ disableColor }) => (disableColor ? '0px' : '2px')} solid ${({ theme }) => theme.palette.grey._340.hex()};
  background: ${({ disableColor, theme }) => (disableColor ? theme.palette.white.main.hex() : theme.palette.grey._450.hex())};
  
  ${({ disableColor }) => !disableColor && css`
    animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
  `};
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-areas: 'content-body';
  border: 2px solid ${({ theme }) => theme.palette.white.main.hex()};
  background: ${({ theme }) => theme.palette.grey._300.hex()};
`;

export const BodyContent = styled.div<{ disableColor?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 'content-body';
  width: 80%;
  height: 80%;
  max-width: 130px;
  max-height: 100px;
  margin: 0.5rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: ${({ disableColor }) => (disableColor ? '0px' : '2px')} solid ${({ theme }) => theme.palette.grey._340.hex()};
  background: ${({ disableColor, theme }) => (disableColor ? theme.palette.grey._300.hex() : theme.palette.grey._340.hex())};

  ${({ disableColor }) => !disableColor && css`
    animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
  `};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;

  p {
    visibility: hidden;
    margin: 0 0.1rem;
  }
`;

export const Subtitle = styled.div`
  width: 70%;
  height: 8px;
  border-radius: 5px;
  margin-top: 0.3rem;
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  p {
    visibility: hidden;
  }
`;

export const ContainerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleIcon = styled.div`
  width: 20px;
  height: 14px;
  background: ${({ theme }) => theme.palette.grey._340.hex()};
  animation: ${shimmerAnimation} ${animationTime}s ease-in-out infinite;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  margin: 0.25rem;
`;
