import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Title,
  Header,
  Container,
  Description,
} from '../../../../common/style';
import { ButtonContainer } from './style';
import { ButtonBackDrop, SeeRegionsButton } from '../WhichRegionsCanIndicate/style';
import { actualSize } from '../../../../../../../../../hooks/useMobile/useMobile';

const HowIndicateProperties = () => {
  const navigate = useNavigate();
  function handleRedirect() {
    navigate('/parceiros/indicar/formulario/locacao');
  }
  return (
    <Container>
      <Header>
        <Title>Como indicar imóveis?</Title>
        <Description>
          Para indicar é muito fácil, você pode acessar diretamente a página de
          Parceiros e preencher o formulário de indicações!
        </Description>
      </Header>
      <div style={{ maxWidth: actualSize === 'Desktop' ? '50%' : 'auto' }}>
        <ButtonContainer onClick={handleRedirect} whileTap={{ scale: 0.95 }}>
          <ButtonBackDrop />
          <SeeRegionsButton>FAÇA SUA INDICAÇÃO</SeeRegionsButton>
        </ButtonContainer>
      </div>
    </Container>
  );
};

export default HowIndicateProperties;
