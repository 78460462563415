import styled, { keyframes } from 'styled-components';

import { Button } from '@mui/material';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  @media (max-width: 1400px) {
    transform: scale(0.9);
  }
  @media (max-width: 1276px) {
    transform: scale(0.8);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.familys._1};

  opacity: 0;
  animation-name: ${FadeAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
`;

export const BackButtonStyled = styled(Button)<{
  isMobile: boolean
}>`
  && {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    background-color: transparent;
    border: ${({ theme, isMobile }) => (isMobile ? 'none' : `2px solid ${theme.palette.white._900.hex()}`)};
    border-radius: ${({ isMobile }) => (!isMobile && '10px')};
    padding: ${({ isMobile }) => (isMobile ? '10px 180px 10px 0' : '10px')};

    width: 100%;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.familys._1};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};

    margin-left: 1rem;
    opacity: 0;
    font-size: 10pt;
    animation-name: ${FadeAnimation};
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  &&:hover {
    border:${({ theme, isMobile }) => (isMobile ? 'none' : `2px solid ${theme.palette.orange._700.hex()}`)};
    color: ${({ theme }) => theme.palette.orange._700.hex()};
    background: ${({ isMobile }) => isMobile && 'none'};
  }
`;
