import React, { useEffect } from 'react';

import { Container } from './styles';
import HostClose from '../../../components/FinancialClose/HostClose';
import { FinancialCloseProvider } from '../../../context/FinancialClosePage/FinancialCloseContext/FinancialCloseContext';
import { FinancialCloseHostProvider } from '../../../context/FinancialClosePage/FinancialCloseHostContext/FinancialCloseHostContext';

const HostClosePage: React.FC = () => {
  useEffect(() => {
    document.title = 'Sapron | Fechamento';
  }, []);

  return (
    <FinancialCloseProvider>
      <FinancialCloseHostProvider>
        <Container>
          <HostClose />
        </Container>
      </FinancialCloseHostProvider>
    </FinancialCloseProvider>
  );
};
export default HostClosePage;
