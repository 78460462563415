/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { PageTitle } from '../utils/style';

import Cards from './Cards';

import { Container } from './style';

const Portfolio = () => (
  <Container>
    <PageTitle initial={{ opacity: 0, y: 20 }} animate={{ y: 0, opacity: 1 }}>
      Em breve, você verá nossos lançamentos por aqui!
    </PageTitle>
    {/* <Cards /> */}
  </Container>
);

export default Portfolio;
