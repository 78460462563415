import React, { useState } from 'react';

import { BedArrangementType } from '../../../../../../services/CompReservations/types';
import { UseFormik } from '../../../../../../utils/Formik/types';

import { transformStringBedArrangement, translateBedArrangement } from '../../../../../../utils/Translator';

import SelectCheckbox, { CheckboxOptions } from '../../../../../SelectCheckbox/SelectCheckbox';
import SelectModal from '../../../../../SelectModal/SelectModal';

import {
  Container,
  InputModal,
  InputHideSelect,
  OptionFilter,
  MutiSelectors,
} from './styles';

interface Props {
  listBedArrangement: BedArrangementType[];
  formik: UseFormik<any>;
}

const BedOrganization = ({ listBedArrangement, formik }: Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOutMultipleSelect = (event: any) => {
    if (event.target.id === 'MultipleSelect') {
      setOpenDialog(false);
    }
  };

  return (
    <Container>
      <h1>Organização camas</h1>
      <OptionFilter>
        <MutiSelectors>
          <SelectModal
            id="bedOrganization"
            onClick={() => setOpenDialog(true)}
            value={formik.values.bedOrganizationList
              ?.map((item: string) => translateBedArrangement(item))}
          />
        </MutiSelectors>

        { openDialog && (
        <>
          <InputHideSelect id="MultipleSelect" onClick={handleOutMultipleSelect} />
          <InputModal className="input-modal-style">
            <SelectCheckbox
              id="bedOrganizationList"
              formik={formik}
              defaultValue={formik.values.bedOrganizationList}
              handleClose={() => setOpenDialog(false)}
              options={(listBedArrangement || [])
                .map<CheckboxOptions>((item: BedArrangementType) => ({
                value: transformStringBedArrangement(item?.bed_arrangement || ''),
                valueLabel: translateBedArrangement(item?.bed_arrangement || ''),
                checked: false,
              }))}
            />
          </InputModal>
        </>
        )}
      </OptionFilter>
    </Container>
  );
};

export default BedOrganization;
