import styled, {
  css,
  keyframes,
} from 'styled-components';

import { Link } from 'react-router-dom';

const FadeIn = keyframes`
  from {
    width: 320px;
  }
  to {
    width: 120px;
  }
`;

const FadeOpacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeInAnimationItems = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const animationTime = '0.5s';

export const Container = styled.div<{
  openMenu: boolean;
  retractMenu: boolean;
  shouldBeVisible: boolean;
}>`

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  height: 100%;
  min-width: ${({ retractMenu }) => (!retractMenu ? '320px' : '120px')};
  transition: min-width 0.2 ease-in-out;
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ${({ retractMenu }) => (retractMenu ? 'ease-in' : 'ease-out')};

  background-color: ${({ theme }) => theme.palette.blue._950.hex()};
  
  @media (max-width: 900px) {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 99999;
    animation-name: ${FadeIn};
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    display: none;
  }

  .logoutRetractMenu {
    margin-left: 50%;
    margin-top: 10px;
  }

  user-select: none;

  ${({ shouldBeVisible }) => !shouldBeVisible
    && css`
      display: none;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 100px;
  opacity: 0;
  animation-name: ${FadeOpacity};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;

export const ContainerLogoSeazone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 30px;

  img {
    width: 290px;
    margin: 0px;
  }

  @media (max-width: 560px) {
    img {
      width: 145px;
      margin: 0px;
    }
  }

  .house {
    width: 30px;
    margin: 0px;
  }
`;

export const ContainerLogoSeazoneHouse = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  height: 35px;
  justify-content: center;
  margin: 1rem 1rem 1rem 2.3rem;

  img{
    width: 100%;
    height: 100%;
  }
`;

export const CloseButton = styled.img`
  display: none;
  top: 100px;
  position: relative;

  @media (max-width: 900px) {
    display: flex;
  }
`;

export const Divider = styled.div<{ isRetract?: boolean }>`
  width: 100%;
  max-width: ${({ isRetract }) => (isRetract ? 50 : 180)}px;
  border-bottom: 1px solid #2b3339;
  ${(props) => (props.isRetract
    ? css`
      position: absolute;
      top: 80px;
      left: 20px;
    `
    : css`
       margin: 0 30px;
    `)}
`;

export const RecoverButton = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  padding-left: 30px;
  z-index: 10;
`;

export const ResetMaxSizeButton = styled.div`
  margin-bottom: 20px;
  padding-left: 30px;
  margin-top: 15px;
  cursor: pointer;
`;

export const NavBar = styled.div`
  height: 60%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
`;

export const NavItem = styled(Link)<{ shouldBeVisible: boolean }>`
  height: 36px;
  display: ${({ shouldBeVisible }) => (shouldBeVisible ? 'flex' : 'none')};
  align-items: center;
  margin-bottom: 30px;
  text-decoration: none;

  opacity: 0;
  animation-name: ${FadeInAnimationItems};
  animation-duration: ${animationTime};
  animation-timing-function: ease;
  animation-fill-mode: forwards;


  &:nth-child(n) {
    animation-delay: 0.3s;
  }

  &:nth-child(2n) {
    animation-delay: 0.4s;
  }

  p, select {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 15px;
    font-weight: 400;
  }

  img {
    margin-right: 16px;
    padding-left: 40px;
  }

  select {
    cursor: pointer;
    border: 0;
    background: transparent;
  }

  option{
    color: ${({ theme }) => theme.palette.blue._950.hex()};
  }

  &:hover {
    transform: scale(1.1);

    img {
      filter: invert(50%) sepia(1%) saturate(7487%) hue-rotate(326deg)
        brightness(7899%) contrast(86%);
    }

    div {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.white.main.hex()};
          filter: brightness(1);
        }
      }
    }

  }

  &.active {
    ::before {
      background-color: ${({ theme }) => theme.palette.blue._850.hex()};
      border-radius: 0 25% 25% 0;
      content: "";
      height: 36px;
      width: 66px;
      position: absolute;
      z-index: 1;
      border-radius: 0 100px 100px 0;
    }

    p {
      color: ${({ theme }) => theme.palette.white.main.hex()};
      font-weight: 600;
    }

    img {
      filter: invert(50%) sepia(1%) saturate(7487%) hue-rotate(326deg)
        brightness(7899%) contrast(86%);
    }

    svg {
      path {
        fill: ${({ theme }) => theme.palette.white.main.hex()};
        filter: brightness(1);
      }
    }
  }
`;

export const Item = styled.div<{ shouldBeVisible: boolean }>`
  height: 36px;
  display: ${({ shouldBeVisible }) => (shouldBeVisible ? 'flex' : 'none')};
  align-items: center;
  margin-bottom: 30px;
  text-decoration: none;

  opacity: 0;
  animation-name: ${FadeInAnimationItems};
  animation-duration: ${animationTime};
  animation-timing-function: ease;
  animation-fill-mode: forwards;


  &:nth-child(n) {
    animation-delay: 0.3s;
  }

  &:nth-child(2n) {
    animation-delay: 0.4s;
  }

  p, select {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 15px;
    font-weight: 400;
  }

  img {
    margin-right: 16px;
    padding-left: 40px;
  }

  select {
    cursor: pointer;
    border: 0;
    background: transparent;
  }

  option{
    color: ${({ theme }) => theme.palette.blue._950.hex()};
  }

  &:hover {
    transform: scale(1.1);

    img {
      filter: invert(50%) sepia(1%) saturate(7487%) hue-rotate(326deg)
        brightness(7899%) contrast(86%);
    }

    div {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.white.main.hex()};
          filter: brightness(1);
        }
      }
    }

  }

  &.active {
    ::before {
      background-color: ${({ theme }) => theme.palette.blue._850.hex()};
      border-radius: 0 25% 25% 0;
      content: "";
      height: 36px;
      width: 66px;
      position: absolute;
      z-index: 1;
      border-radius: 0 100px 100px 0;
    }

    p {
      color: ${({ theme }) => theme.palette.white.main.hex()};
      font-weight: 600;
    }

    img {
      filter: invert(50%) sepia(1%) saturate(7487%) hue-rotate(326deg)
        brightness(7899%) contrast(86%);
    }

    svg {
      path {
        fill: ${({ theme }) => theme.palette.white.main.hex()};
        filter: brightness(1);
      }
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const Image = styled.img`
  z-index: 2;
  cursor: pointer;

  max-width: 168px;

  img {
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }

  svg {
    path {
      fill: ${({ theme }) => theme.palette.white.main.hex()};
    }
  }
`;

export const SVGContainer = styled.div`
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 168px;
  margin-left: 2.6rem;
  margin-right: 1rem;

  svg {
    path {
      fill: ${({ theme }) => theme.palette.white.main.hex()};
      filter: brightness(0.35);
    }
  }

`;

export const ImageResized = styled.img`
  z-index: 2;
  cursor: pointer;

  max-width: 56px;
  @media (max-width: 580px) {
    max-width: 56px;
  }

  img {
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }

  svg {
    path {
      fill: ${({ theme }) => theme.palette.white.main.hex()};
    }
  }
`;

export const Title = styled.p<{ retractMenu?: boolean }>`
  z-index: 2;

  ${({ retractMenu }) => retractMenu && css`
    left: -52px;
    position: relative;
  `}
`;

export const ProfileRetract = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background-color: #202938;
  width: calc(100% - 10px);
  height: auto;
  max-width: 85px;

  max-width: 90px;
  max-height: 100px;

  padding: 0.25rem;
  margin: 0.2rem;

  position: absolute;
  bottom: 10px;

  > img {
    width: 39px;
    height: 39px;
    border-radius: 100px;
    background-color: #fff;
  }

  p,
  span {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 12px;
    font-weight: 600;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    width: 100%;
  }
`;

export const EndContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
`;

export const Profile = styled.div`
  border-radius: 10px;
  background-color: #202938;
  margin: 20px;
  padding: 0.5rem;
  width: 100%;
  > img {
    width: 39px;
    height: 39px;
    border-radius: 100px;
    background-color: #fff;
    margin-right: 6px;
  }
  p,
  span {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 15px;
    font-weight: 600;
  }

  span {
    font-weight: 400;
    font-size: 12px;
  }
`;

export const ContentInfo = styled.div`
  img {
    margin-left: 5px;
  }
  p{
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

export const ProfileLogoutRetractContainer = styled.div`
  opacity: 0;
  animation-name: ${FadeOpacity};
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    height: 100%;
    margin-left: 10px;
  }
  svg{
    margin-left: -16px;
    margin-top: -5px;
    color: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  img{
    margin-right: 20px;
    height: 30px;
  }
  button{
    margin-top: -20px;
  }
  svg{
    color: ${({ theme }) => theme.palette.white.main.hex()}
  }
`;

export const NameContainer = styled.div`
  display: block;
`;

export const FinanceOptionsContainer = styled.div`
  width: 100%;
  position: relative;
  bottom: 32px;

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.25rem 0.25rem 0.25rem 0;

    div {
      margin-right: 16px;
      padding-left: 60px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5px;
`;
