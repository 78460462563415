import React from 'react';
import { Container } from './style';

import {
  AboutSection,
  FooterSection,
  AdvantagesBeingPartnerSection,
} from './Sections';

const LandingSection = () => (
  <Container>
    <AboutSection />
    <AdvantagesBeingPartnerSection />

    <FooterSection />
  </Container>
);

export default LandingSection;
