import { useContextSelector } from 'use-context-selector';
import { HostTedListContext } from '../../context/HostTedList/HostTedListContext';

export function useHostTedList() {
  const listItems = useContextSelector(HostTedListContext,
    (state) => state.listItems);
  const handleListItems = useContextSelector(HostTedListContext,
    (state) => state.setListItems);

  const filteredItems = useContextSelector(HostTedListContext,
    (state) => state.filteredItems);
  const handleFilteredItems = useContextSelector(HostTedListContext,
    (state) => state.setFilteredItems);

  const loading = useContextSelector(HostTedListContext,
    (state) => state.loading);
  const handleLoading = useContextSelector(HostTedListContext,
    (state) => state.setLoading);

  const openModalID = useContextSelector(HostTedListContext,
    (state) => state.openModalID);
  const handleOpenModalID = useContextSelector(HostTedListContext,
    (state) => state.setOpenModalID);

  const openModalConfirmDeleteVoucher = useContextSelector(HostTedListContext,
    (state) => state.openModalConfirmDeleteVoucher);
  const handleOpenModalConfirmDeleteVoucher = useContextSelector(HostTedListContext,
    (state) => state.setOpenModalConfirmDeleteVoucher);

  const deleteVoucherIsConfirmed = useContextSelector(HostTedListContext,
    (state) => state.deleteVoucherIsConfirmed);
  const handleDeleteVoucherIsConfirmed = useContextSelector(HostTedListContext,
    (state) => state.setDeleteVoucherIsConfirmed);

  const modalImage = useContextSelector(HostTedListContext,
    (state) => state.modalImage);
  const handleModalImage = useContextSelector(HostTedListContext,
    (state) => state.setModalImage);

  const orderGridBy = useContextSelector(HostTedListContext,
    (state) => state.orderGridBy);
  const setOrderGridBy = useContextSelector(HostTedListContext,
    (state) => state.setOrderGridBy);

  return {
    listItems,
    handleListItems,
    filteredItems,
    handleFilteredItems,
    loading,
    handleLoading,
    openModalID,
    handleOpenModalID,
    openModalConfirmDeleteVoucher,
    handleOpenModalConfirmDeleteVoucher,
    deleteVoucherIsConfirmed,
    handleDeleteVoucherIsConfirmed,
    modalImage,
    handleModalImage,
    orderGridBy,
    setOrderGridBy,
  };
}
