import styled from 'styled-components';

export const Wrapper = styled.div<{
  openModal: boolean;
}>`
  position: absolute;
  z-index: 9999;
  display: ${({ openModal }) => (openModal ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  transition: 0.2s;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  height: 100%;
  max-height: 174px;
  border-radius: 16px;
  padding: 20px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.grey._450.hex()};
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 32px;
    text-align: start;
    color: ${({ theme }) => theme.palette.blue._970.hex()};
  }

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;
    display: flex;
    text-align: start;
    color: ${({ theme }) => theme.palette.blue._250.hex()};
  }
`;

export const ButtonSave = styled.button`
  background-color: ${({ theme }) => theme.palette.orange._600.hex()};

  width: 50%;
  height: 40px;

  border-radius: 10px;
  border: none;

  color: white;
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 28px;

  :hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
`;

export const ButtonCancel = styled.button`
  background: ${({ theme }) => theme.palette.white._300.hex()};
  width: 50%;
  height: 40px;
  margin-right: 15px;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.white._650.hex()};

  color: ${({ theme }) => theme.palette.grey._370.hex()};
  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 28px;

  :hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;

export const RowButtons = styled.div`
  width: 100%;
  height: 60px;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.9;
`;

export const TitleContainer = styled.div`
  margin-left: 1rem;
`;
