import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)<{
  expanded: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  background: #fff;
  user-select: none;
  border-radius: 10px;
  border: 1px solid ${({ expanded }) => (expanded ? '#e0e0e0' : 'transparent')};
  position: relative;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;

  cursor: pointer;

  gap: 0;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 2fr 0.7fr;

  &&&& {
    -webkit-tap-highlight-color: transparent;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 2fr 0.3fr;
  }

  ${({ disabled }) => disabled
    && css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

export const MainContent = styled(motion.div)<{
  capitalize?: boolean;
}>`
  width: 100%;
  height: 100%;
  transition: height 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 5px;
  overflow: hidden;
  h1 {
    ${({ capitalize }) => capitalize && css`
      text-transform: capitalize;
    `}
  }
`;
