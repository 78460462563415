import styled from 'styled-components';

export const SearchCardContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: #394760;
  margin-bottom: 1rem;
  font-family: 'Inter';
`;
