import Card from './Card';

import {
  Container, Title, Divider,
} from './styles';

const editOptions = [
  {
    name: 'Proprietário',
    route: '/editardados/proprietario',
  },
  {
    name: 'Anfitrião',
    route: '/editardados/anfitriao',
  },
  {
    name: 'Propriedade',
    route: '/editardados/propriedade',
  },
  {
    name: 'Mudança de Anfitrião do Imóvel',
    route: '/editardados/mudar-anfitriao',
  },
  {
    name: 'Listing(anúncio)',
    route: '/editardados/listing',
  },
  {
    name: 'Parceiro',
    route: '/editardados/parceiro',
  },
];

const EditData = () => (
  <Container>
    <div className="content">
      <Title>Editar dados</Title>
      <Divider />
      <div className="cards">
        {editOptions.map((option) => (
          <Card
            name={option.name}
            route={option.route}
            key={option.route}
          />
        ))}
      </div>
    </div>
  </Container>
);

export default EditData;
