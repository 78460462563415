import request from '../request';
import { MonthlyBudgetType, MonthlyReservationPost } from './types';

export const getMonthlyBudget = async (
  startDate: string,
  endDate: string,
  category?: string,
  location?: string,
  bathroomQuantity?: number | string,
  order?: string,
): Promise<MonthlyBudgetType> => {
  const { data } = await request.get('/reservations/monthlybudget/', {
    params: {
      start_date: startDate,
      end_date: endDate,
      category__in: category,
      location__in: location,
      bathroom_quantity: bathroomQuantity,
      ordering: order,
    },
  });

  return data as MonthlyBudgetType;
};

export const getMonthlyBudgetById = async (
  id: number, startDate: string,
  endDate: string,
) => {
  const response = await request.get(`/reservations/monthlybudget/${id}/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
  return response.data;
};

export const postMonthlyReservation = async (data: MonthlyReservationPost) => {
  const response = await request.post('/reservations/monthly/', {
    ...data,
  });

  return response.data;
};
