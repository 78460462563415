import styled from 'styled-components';
import { Dialog, DialogContent } from '@mui/material';

type ColorsProps = 'green' | 'red';

export const RoundedDialog = styled(Dialog)`
  && {
    .MuiDialog-paperWidthSm {
      max-width: 1300px;
      width: 90%;
    }
    .MuiPaper-rounded {
      border-radius: 10px;
    }
    .MuiPaper-elevation24 {
      box-shadow: none;
    }
  }
`;

export const ModalBox = styled(DialogContent)`
  && {
    padding: 0px !important;
    background-color: ${({ theme }) => theme.palette.white.main.hex()};

    @media (max-width: 900px) {
      width: auto;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100px;
  background: ${({ theme }) => theme.palette.grey._240.hex()};
  padding: 10px 40px !important;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
    color: ${({ theme }) => theme.palette.blue._930.hex()};
    margin-bottom: 7px;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  img {
    margin-left: 7px;
  }
`;

export const Content = styled.div`
  width: auto;
  padding: 27px;
  display: flex;
  overflow-x: scroll;
`;

export const ButtonCopy = styled.button`
  width: 292px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: ${({ theme }) => theme.palette.white.main.hex()};

  border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};
  box-sizing: border-box;
  border-radius: 10px;

  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.grey._700.hex()};

  :hover {
    filter: brightness(0.8);
    border: 1px solid ${({ theme }) => theme.palette.grey._550.hex()};
  }
`;

export const AddReservation = styled.button`
  background: transparent;
  cursor: pointer;

  width: 194px;
  min-width: 194px;
  height: 261px;

  border: 2px dashed ${({ theme }) => theme.palette.grey._430.hex()};
  box-sizing: border-box;
  border-radius: 13px;

  &:hover {
    filter: brightness(0.6);
  }
  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 113%;

    color: ${({ theme }) => theme.palette.grey._460.hex()};
  }

  &:hover {
    border: 3px dashed ${({ theme }) => theme.palette.grey._460.hex()};
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 110px;
  background: ${({ theme }) => theme.palette.grey._240.hex()};
  border-radius: 10px 10px 0px 0px;
  padding: 10px 27px;

  div {
    &.row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const ButtonConcluded = styled.button`
  padding: 14px 24px;
  background: ${({ theme }) => theme.palette.blue._910.hex()};
  border: 1px solid ${({ theme }) => theme.palette.blue._910.hex()};
  border-radius: 10px;
  border: none;
  margin-right: 20px;

  cursor: pointer;

  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.white.main.hex()};

  transition: filter(0.2s);

  :hover {
    box-shadow: 0px 2px 7px 3px rgba(13, 75, 208, 0.25);
  }
`;

export const ButtonCancel = styled.button`
  padding: 14px 24px;
  background: #FFFFFF;
  border: 1px solid ${({ theme }) => theme.palette.blue._910.hex()};
  border-radius: 10px;
  cursor: pointer;

  font-family: ${({ theme }) => theme.fonts.familys._6};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.blue._910.hex()};
  transition: filter(0.2s);

  :hover {
    background: #E3E7EB;
  }
`;

export const Alert = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.palette.yellow._900.hex()};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
`;

export const Total = styled.div`
  width: 579px;
  height: 66px;
  padding: 15px;

  border: 1px dashed ${({ theme }) => theme.palette.grey._660.hex()};
  box-sizing: border-box;
  border-radius: 17px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin-right: 10px;
  }

  strong {
    width: 128px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.palette.white.main.hex()};
    border-radius: 4px;
    font-family: ${({ theme }) => theme.fonts.familys._1};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.palette.blue._960.hex()};
  }
`;

export const ButtonDiscount = styled.button<{
  color: ColorsProps;
}>`
  width: 188px;
  height: 40px;

  background: ${({ theme, color }) => (
    color === 'green'
      ? theme.palette.white.main.hex()
      : theme.palette.red._200.hex())};

  border: 1px dashed ${({ theme }) => theme.palette.green.main.hex()};
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${({ theme }) => theme.fonts.familys._1};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme, color }) => (
    color === 'green'
      ? theme.palette.green.main.hex()
      : theme.palette.white.main.hex())};

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    filter: brightness(80%);
  }
`;
