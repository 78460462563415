import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2.5rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateContainer = styled.div`
  max-width: 140px;
  cursor: pointer;
  user-select: none;
`;
