import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled(motion.div)<{
  active?: boolean;
}>`
  cursor: pointer;

  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: ${({ theme }) => theme.fonts.familys._6};
    font-weight: normal;
  }

  ${({ active }) => active
    && css`
      h1 {
        color: #394760;
      }

      svg {
        g {
          #background {
            fill: #394760;
          }

          #dots {
            path {
              stroke: white;
            }
          }
        }
      }
    `}

  ${({ active }) => !active
    && css`
      h1 {
        color: #b8c6dc;
      }
      svg {
        g {
          #background {
            fill: #e7eaf1;
          }

          #dots {
            path {
              stroke: #b8c6dc;
            }
          }
        }
      }
    `}
`;
