import request from '../../../../request';

import {
  IPartinersFinancialWithdrawBase, IPartinersFinancialWithdrawPost,
} from './types';

type GetPartnersWithdraw = () => Promise<IPartinersFinancialWithdrawBase[]>;
export const getPartnersFinancialWithdrawRequest: GetPartnersWithdraw = async (id?: number) => {
  const { data } = await request.get(`/financial/partners/commissions/withdraw_request/${id ? `${id}/` : ''}`);
  return data;
};

type IPostPartnerFinancialWithdraw = (
  data: IPartinersFinancialWithdrawPost
) => Promise<IPartinersFinancialWithdrawBase>;
export const postPartnersFinancialWithdrawRequest: IPostPartnerFinancialWithdraw = async (data) => {
  const { data: response } = await request.post('/financial/partners/commissions/withdraw_request/', data);
  return response;
};
