import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StepsContainer = styled(motion.div)`
  border-radius: 10px;
  padding: 2rem 0;
  background-color: rgb(57, 71, 96);
  width: 320px;

  h2 {
    font-weight: 600;
    color: white;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-family: 'Inter';
  }

  @media (max-width: 900px) {
    width: 100%;
    height: unset;
    width: unset;
  }

  @media (max-width: 350px) {
    padding: 1rem 0;
  }
`;

export const StepItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
  gap: 1rem;
  
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (max-width: 900px) {
    justify-content: flex-start;
    width: 100%;
    height: unset;
  }

  @media (max-width: 350px) {
    flex-direction: row;
    gap: 0.5rem;
  }
`;

export const StepItem = styled.div<{ isLastCard?: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 900px) {
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 100%;
    border-top: ${({ isLastCard }) => isLastCard && '1px solid white'};
    padding-top: ${({ isLastCard }) => isLastCard && '1rem'}
  }

  @media (max-width: 350px) {
    border-top: none;
    padding-top: 0;
    gap: 0.5rem;
  }
`;

export const StepText = styled.p<{ isActive: boolean, isLastCard?: boolean }>`
  font-size: ${({ isLastCard }) => (isLastCard ? '1rem' : '1.25rem')};
  padding-right: 2rem;
  color: ${({ isActive }) => (isActive ? '#41B592' : '#fff')};
  margin-right: 1rem;
  font-family: 'Inter';
  padding-left: ${({ isLastCard }) => isLastCard && '2rem'};
  
  @media (max-width: 900px) {
    font-size: 1rem;
    margin-right: 0;
    padding-left: ${({ isLastCard }) => isLastCard && '0'};
  }

  @media (max-width: 350px) {
    display: none;
  }
`;

export const StepIcon = styled.img<{ isLastCard?: boolean }>`
  width: 24px;
  height: 24px;
  margin-left: 2rem;
  margin-right: ${({ isLastCard }) => isLastCard && '2rem'};

  @media (max-width: 900px) {
    margin-right: ${({ isLastCard }) => isLastCard && '0'};
    order: -1;
  }
`;

export const Divider = styled.div<{ stepsLength: number, isActive: boolean }>`
  width: 96%;
  height: ${({ stepsLength }) => (stepsLength > 1 ? '80%' : '0')};
  border-right: ${({ isActive }) => (isActive ? '3px dotted #41B592' : '3px dotted #fff')};
  margin-top: 0.5rem;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const ItemContainer = styled.div`
  padding: 0 2rem;

  @media (max-width: 900px) {
    padding: 0;
  }
`;
