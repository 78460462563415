import { Backdrop } from '@mui/material';
import { StyleProgress } from '../../style';
import { Base } from '../../types';

export const Loading = ({ show }: { show: Base['show'] }) => {
  if (!show) return null;

  return (
    <>
      <StyleProgress
        style={{ marginTop: 5, zIndex: 20, color: 'white' }}
        size={25}
      />
      <Backdrop
        style={{
          zIndex: 10,
          borderRadius: '10px',
          position: 'absolute',
          backdropFilter: 'blur(2.5px)',
          backgroundColor: 'rgba(0,0,0,0.3)',
        }}
        open
      />
    </>
  );
};
