import { RevenuesOwnerProps, Status } from '../../../services/FinancialClose/types';
import { compareList } from '../../../utils/Sorting';
import { Order } from '../FinancialCloseContext/types';
import { GridOwnerProps, OrderBy } from './types';

export const initialValuesFinancialCloseOwner: GridOwnerProps = {
  id: 0,
  owner: {
    id: 0,
    name: 'Não informado',
  },
  balance_initial: 0,
  expense_value: 0,
  reserves: {
    income: 0,
    revenue: 0,
  },
  commission: 0,
  adjustments: {
    properties: 0,
    owner: 0,
    total: 0,
  },
  transfer: {
    transfer_value: 0,
    negative_balance_rebate: 0,
    total: 0,
  },
  status: 'Open',
  revenue_ids: [],
  checked: false,
  expanded: false,
  properties: [
    {
      id: 0,
      property: {
        id: 0,
        code: '',
      },
      initial_balance: 0,
      revenue: 0,
      income: 0,
      expenses: 0,
      cleanings: 0,
      manual_fit: 0,
      transfer: 0,
      commission: 0,
      final_balance: 0,
      is_to_keep_funds_in_seazone: false,
    },
  ],
};

export const getFinancialCloseOwnerUpdated = (data: GridOwnerProps) => ({
  ...data,
  owner: {
    ...data.owner,
  },
  reserves: {
    ...data.reserves,
  },
  adjustments: {
    ...data.adjustments,
  },
  transfer: {
    ...data.transfer,
  },
});

export const formatRevenuesOwner = (owners: GridOwnerProps[], status: Status) => {
  let revenuesIds: number[] = [];

  owners.forEach((owner) => {
    const ownerRevenues = owner?.revenue_ids || [];
    revenuesIds = [...revenuesIds, ...ownerRevenues];
  });

  const revenues: RevenuesOwnerProps = {
    status_owner: status,
    revenue_ids: revenuesIds,
  };

  return revenues;
};

export const sortFinancialDataOfOwner = (
  financialData: GridOwnerProps[],
  orderBy: OrderBy,
  order: Order,
  financialDataIds?: number[], // ids of financial data selected in grid
): GridOwnerProps[] => {
  let sorted: GridOwnerProps[] = financialData;

  switch (orderBy) {
    case 'owner':
      sorted = financialData.map((item) => item).sort((a, b) => compareList(
        a.owner.name, b.owner.name, order,
      ));
      break;

    case 'property_code':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.property.code, b.property.code, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_initial_balance':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.initial_balance, b.initial_balance, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_revenue':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.revenue, b.revenue, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_income':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.income, b.income, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_expenses':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.expenses, b.expenses, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_cleanings':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.cleanings, b.cleanings, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_manualfit':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.manual_fit, b.manual_fit, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_transfer':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.transfer, b.transfer, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_commission':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.commission, b.commission, order,
          )) : [...property.properties],
      }));
      break;

    case 'property_final_balance':
      sorted = financialData.map((property) => ({
        ...getFinancialCloseOwnerUpdated(property),
        reservations: financialDataIds && financialDataIds.includes(property.id)
          ? property.properties.sort((a, b) => compareList(
            a.final_balance, b.final_balance, order,
          )) : [...property.properties],
      }));
      break;

    default:
      return sorted;
  }
  return sorted;
};

export const ownerIsSelected = (id: number) => {
  const response = localStorage.getItem('@saprom-pms-web/financialclose_owner_selected');
  if (response) {
    const data = JSON.parse(response);
    return data?.[id] || false;
  }
  return false;
};
