import React from 'react';
import RadioButton, { IRadioButtonProps, RadioButtonOptions } from './RadioButton';

type Props = Omit<IRadioButtonProps, 'options'|'formLabel'> & {
  formLabel?: string,
};

const RadioTypePerson: React.FC<Props> = ({
  id,
  formLabel = 'Pessoa',
  labelPlacement,
  formik,
  ...rest
}) => {
  const options: RadioButtonOptions[] = [
    { value: 'individual', label: 'Física' },
    { value: 'legal', label: 'Jurídica' },
  ];

  return (
    <RadioButton
      formLabel={formLabel}
      id={id}
      labelPlacement={labelPlacement}
      options={options}
      formik={formik}
      {...rest}
    />
  );
};

export default RadioTypePerson;
