import styled, { DefaultTheme } from 'styled-components';
import { ReservationStatus } from '../../../services/Reservation/types';

const getBackgroundStatus = (
  theme: DefaultTheme,
  status: ReservationStatus,
  blocked: boolean = false,
  reason: boolean,
  isBookingOrBlockAutomatic?: boolean,
): string => {
  const color = {
    Concluded: theme.palette.green.main.hex(),
    Active: theme.palette.green.main.hex(),
    'In progress': theme.palette.green.main.hex(),
    'Pre-booking': theme.palette.yellow.main.hex(),
    Canceled: theme.palette.grey._550.hex(),
  };

  if (isBookingOrBlockAutomatic) {
    return theme.palette.blue._20.hex();
  }

  if (blocked) {
    return theme.palette.red._200.hex();
  }

  if (reason && status === 'Canceled') {
    return theme.palette.grey._550.hex();
  }

  return color[status];
};

export const ItemContainer = styled.div<{ $status: ReservationStatus, $blocked?: boolean, $reason: boolean, $isBookingOrBlockAutomatic?: boolean }>`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 6px;
  background-color: ${({
    theme, $status, $blocked, $reason, $isBookingOrBlockAutomatic,
  }) => getBackgroundStatus(theme, $status, $blocked, $reason, $isBookingOrBlockAutomatic)} !important;
  color: #FFF !important;
  border-radius: 18px;
  border-color: transparent;
  padding: 2px 8px;
  min-width: 55px !important;
`;

export const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  padding: 6px;
  border-radius: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const TextContainer = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: ${(props) => props.height}px;
  overflow: hidden;
  padding-left: 3px;
  max-width: 200px;
`;
