import React from 'react';

const Location = () => (
  <svg
    width="33"
    height="25"
    viewBox="0 0 33 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="speaker"
      d="M29.2444 0.410132C28.8916 0.182608 28.4881 0.0449611 28.0694 0.00929001C27.6508 -0.0263811 27.2297 0.0410164 26.8433 0.205558L14.181 5.17672C13.8812 5.29834 13.5606 5.36087 13.2369 5.36084H5.1306C4.45024 5.36084 3.79774 5.63025 3.31666 6.10982C2.83557 6.58938 2.5653 7.23981 2.5653 7.91802V8.12259H0V14.2598H2.5653V14.5258C2.58137 15.1933 2.85873 15.8281 3.33808 16.2944C3.81744 16.7608 4.46077 17.0218 5.1306 17.0216L8.20895 23.5271C8.4174 23.9651 8.74557 24.3357 9.15578 24.5963C9.56599 24.8568 10.0416 24.9968 10.528 25H11.8209C12.4977 24.9946 13.1449 24.7228 13.6216 24.2438C14.0982 23.7648 14.3657 23.1175 14.3657 22.4428V17.2671L26.8433 22.2382C27.1502 22.36 27.4775 22.4225 27.8078 22.4224C28.3203 22.4141 28.8193 22.2578 29.2444 21.9723C29.5819 21.7451 29.8604 21.4411 30.0567 21.0853C30.2529 20.7296 30.3614 20.3324 30.3731 19.9266V2.51725C30.3713 2.10097 30.2675 1.69145 30.0708 1.32424C29.874 0.957032 29.5904 0.643245 29.2444 0.410132V0.410132ZM11.8004 7.91802V14.5258H5.1306V7.91802H11.8004ZM11.8004 22.4428H10.5075L7.98321 17.0216H11.8004V22.4428ZM15.125 14.8326C14.8813 14.7085 14.6273 14.6058 14.3657 14.5258V7.77482C14.6248 7.72153 14.8788 7.64625 15.125 7.54979L27.8078 2.51725V19.8652L15.125 14.8326ZM30.4347 8.63403V13.7484C31.1151 13.7484 31.7676 13.479 32.2486 12.9994C32.7297 12.5199 33 11.8694 33 11.1912C33 10.513 32.7297 9.86258 32.2486 9.38301C31.7676 8.90345 31.1151 8.63403 30.4347 8.63403V8.63403Z"
      fill="#6E5ED5"
    />
  </svg>
);

export default Location;
