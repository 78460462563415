import { ReportProblemOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

export const PendencyModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  padding: 1rem;
`;

export const PendencyModalContainer = styled.div`
  padding: 5rem;
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 2rem;
  max-width: 540px;
`;

export const PendencyModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PendencyIcon = styled(ReportProblemOutlined)`
  && {
    color: #FF9900;
    height: 50px;
    width: 45px;
  }
`;

export const Close = styled(CloseIcon)`
  && {
    color: #000000;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
`;

export const PendencyModalText = styled.h2`
  padding: 0 1rem;
  color: #394760;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 3rem;
  button {
    font-family: Inter;
    width: 100%;
    font-size: 1.5rem;
  }
`;
