import React, { useEffect } from 'react';
import { Grid } from '@mui/material';

import { AddressDetail } from '../../../services/Address/types';
import { getAddressByCep } from '../../../services/Address/request';

import { UseFormik } from '../../../utils/Formik/types';
import { onlyNumber } from '../../../utils/Formatter';
import { ErrorMessage } from '../../../utils/Messages';

import { useToast } from '../../../context/ToastContext';
import { useOnboardingHandover } from '../../../context/OnboardingContext/OnboardingHandoverContext';

import FormGridRow from '../../FormGridRow';
import TextField from '../../TextField';
import StateSelect from '../../SimpleSelect/StateSelect';

import {
  Container,
  TextBold,
  StateAdjust,
} from './styles';

type Props = {
  formik: UseFormik<AddressDetail | any>,
};

const FormAddress: React.FC<Props> = ({
  formik,
}) => {
  const toast = useToast();
  const { infosPipeDrive} = useOnboardingHandover();

  const getAddressByZipCode = async (zipCode: string) => {
    try {
      const addressResponse = await getAddressByCep(zipCode);
      const createAddress = (name: string, value: string) => {
        const newAddres: any = addressResponse;
        const getValue = newAddres[value];

        formik.setFieldValue(name, getValue);
        formik.setFieldTouched(
          name, true, false,
        );

        return getValue;
      };

      createAddress('city', 'localidade');
      createAddress('state', 'uf');
      createAddress('neighborhood', 'bairro');
    } catch {
      toast.error(ErrorMessage.addressDefaultErro());
    }
  };

  useEffect(() => {
    if (formik?.values?.postal_code && !infosPipeDrive?.property_infos?.property_address) {
      const zipCode: string = onlyNumber(formik?.values?.postal_code);

      if (zipCode?.length >= 8) {
        getAddressByZipCode(zipCode);
      }
    }

    return () => {};
  }, [formik?.values?.postal_code, infosPipeDrive]);

  return (
    <Container>
      <TextBold>
        Endereço completo e número do apartamento *
      </TextBold>
      <FormGridRow grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="postal_code"
            placeholder="CEP"
            label="CEP"
            formik={formik}
            mask="cep"
            value={formik?.values?.postal_code}
            autoComplete="off"
            required
            disableRequireSymbol
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="street"
            placeholder="Logradouro"
            formik={formik}
            label="Rua"
            value={formik?.values?.street}
            required
            disableRequireSymbol
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id="number"
            type="number"
            placeholder="Número"
            formik={formik}
            label="Número"
            value={formik?.values?.number}
            required
            disableRequireSymbol
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="complement"
            placeholder="Complemento"
            formik={formik}
            label="Complemento"
            value={formik?.values?.complement}
          />
        </Grid>
      </FormGridRow>
      <FormGridRow grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="neighborhood"
            placeholder="Bairro"
            formik={formik}
            label="Bairro"
            value={formik?.values?.neighborhood}
            autoComplete="off"
            required
            disableRequireSymbol
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="city"
            placeholder="Cidade"
            formik={formik}
            label="Cidade"
            value={formik?.values?.city}
            required
            disableRequireSymbol
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StateAdjust />
          <StateSelect
            id="state"
            formik={formik}
            label="Estado"
            required
            labelClassName="state-label"
            disableRequireSymbol
          />
        </Grid>
      </FormGridRow>
    </Container>
  );
};

export default FormAddress;
