import React from 'react';
import PestControlOutlinedIcon from '@mui/icons-material/PestControlOutlined';
import { ReportBugButtonContainer } from './styles';

const ReportBugButton: React.FC = () => (
  <ReportBugButtonContainer data-cy="report-bug-button">
    <PestControlOutlinedIcon
      data-testid="report-bug-icon"
    />
    <p
      data-testid="report-bug-txt"
    >
      Reportar um erro

    </p>
  </ReportBugButtonContainer>
);

export default ReportBugButton;
