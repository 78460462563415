import React from 'react';
import { Props, Type } from './types';

import { Container } from './styles';

const TextField: React.FC<Props> = ({
  children,
  type = Type.primary,
}) => (
  <Container
    type={type}
    exit={{ opacity: 0 }}
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
  >
    {children}
  </Container>
);

export default TextField;
