import React from 'react';
import { usePreCheckin } from '../../../../../context/PreCheckin/PreCheckin';

import { View } from './View';
import { Edit } from './Edit';

const Finish = () => {
  const { mode } = usePreCheckin();

  if (mode._default === 'view') {
    return <View />;
  }

  return <Edit />;
};

export default React.memo(Finish);
