import { useState } from 'react';
import SimpleSelect, { SelectOption } from '../../../../SimpleSelect/SimpleSelect';
import {
  ButtonContainer, FullDiv, GridContainer, PendencyFormContainer,
} from './styles';
import { compareList } from '../../../../../utils/Sorting';
import TextField from '../../../../TextField/TextField';
import FormButton from '../../../../FormButton/FormButton';
import { UseFormik } from '../../../../../utils/Formik/types';
import { UploadInput } from '../../UploadInput';
import { useGuestDamage } from '../../../../../hooks/GuestDamage/useGuestDamage';
import { useUser } from '../../../../../context/UserContext';

const pendencyClassificationOptions: SelectOption[] = [
  {
    value: 'Muito barulho',
    valueLabel: 'Muito barulho',
  },
  {
    value: 'Multa Estacionamento',
    valueLabel: 'Multa Estacionamento',
  },
  {
    value: 'Multa Desrespeito às regras da casa',
    valueLabel: 'Multa Desrespeito às regras da casa',
  },
  {
    value: 'Pendência pagamento de consumos no Resort',
    valueLabel: 'Pendência pagamento de consumos no Resort',
  },
  {
    value: 'Outro',
    valueLabel: 'Outro',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));

const needToDoneOptions: SelectOption[] = [
  {
    value: 'Repor item',
    valueLabel: 'Repor item',
  },
  {
    value: 'Conserto',
    valueLabel: 'Conserto',
  },
  {
    value: 'Limpeza',
    valueLabel: 'Limpeza',
  },
  {
    value: 'Contratar Serviço Terceirizado',
    valueLabel: 'Contratar Serviço Terceirizado',
  },
  {
    value: 'Cobrar Pendência',
    valueLabel: 'Cobrar Pendência',
  },
].sort((a, b) => compareList(a.valueLabel, b.valueLabel));
interface PendencyFormProps {
  formik: UseFormik<any>;
  isEditingForm: boolean;
}

const PendencyForm = ({
  formik, isEditingForm,
}:PendencyFormProps) => {
  const {
    evidences,
    setEvidences,
    quotationFile,
    setQuotationFile,
  } = useGuestDamage();
  const { userInformation } = useUser();
  const roles = userInformation?.roles;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  return (
    <PendencyFormContainer
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      key="pendencyForm"
      transition={{
        duration: 0.4,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <GridContainer>
        <FullDiv>
          <SimpleSelect
            id="itemName"
            options={pendencyClassificationOptions}
            placeholder={formik.values.itemName ? formik.values.itemName : 'Selecione'}
            label="Classifique a pendência *"
            labelClassName="label"
            formik={formik}
          />
        </FullDiv>
        <TextField
          id="other"
          labelClassName="label"
          label={formik.values.itemName !== 'Outro' ? 'Outro' : 'Outro *'}
          placeholder="Digite aqui"
          disabled={formik.values.itemName !== 'Outro'}
          formik={formik}
          value={formik.values.other}
        />
        <SimpleSelect
          id="resolution"
          options={needToDoneOptions}
          placeholder={formik.values.resolution ? formik.values.resolution : 'Selecione'}
          label="O que precisa ser feito? *"
          labelClassName="label"
          formik={formik}
        />
        <TextField
          id="quotationLink"
          labelClassName="label"
          label="Link do orçamento"
          placeholder="https://www.exemplo.com.br"
          formik={formik}
          value={formik.values.quotationLink}
        />
        <UploadInput
          label="Orçamento/nota fiscal"
          labelClassName="label"
          setUploadedFile={setQuotationFile}
          uploadedFile={quotationFile}
        />
        <TextField
          id="itemPrice"
          labelClassName="label"
          label="Valor"
          mask="money"
          placeholder="R$ 00,00"
          formik={formik}
          value={formik.values.itemPrice}
        />
        <UploadInput
          label="Evidências *"
          labelClassName="label"
          setUploadedFiles={setEvidences}
          uploadedFiles={evidences}
          isMultiple
          required
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
        <FullDiv>
          <TextField
            id="observation"
            labelClassName="label"
            label="Observações"
            placeholder="Digite aqui"
            type="textarea"
            formik={formik}
            value={formik.values.observation}
          />
        </FullDiv>
      </GridContainer>
      {(roles?.includes('Attendant')
        || roles?.includes('Host')
        || roles?.includes('Admin'))
        && (
        <ButtonContainer>
          <FormButton disable={errorMessage !== undefined}>
            {isEditingForm ? 'Editar Item' : 'Adicionar item'}
          </FormButton>
        </ButtonContainer>
        )}
    </PendencyFormContainer>
  );
};

export default PendencyForm;
