import React, { useState, useEffect } from 'react';

import { Footer } from './Footer/Footer';
import { LateralContent } from './LateralContent/LateralContent';
import { MainContent } from './MainContent/MainContent';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import useLocalStorage from '../../../hooks/GlobalHook/useLocalStorage';

import {
  postChecklist,
  putChecklist,
  getChecklistById,
  getCheckin,
  getCheckout,
  getClearning,
} from '../../../services/Control/request';

import { data } from './data';

import {
  Checkin, Checkout, Clearning, ChecklistDataProps,
} from '../../../services/Control/types';

import { ChecklistShimmer } from '../LoadingShimmer/ChecklistShimmer/ChecklistShimmer';
import useKeys from '../../../hooks/GlobalHook/useKeys';

import {
  Title,
  Header,
  Content,
  Container,
  ProgressBar,
  CloseButton,
  MainContainer,
  LateralContainer,
  ProgressBarContainer,
} from './style';
import { useChecklist } from '../../../context/ControllerPage/ChecklistContext';

interface Props {
  mobile?: boolean;
  checkin?: boolean;
  checkout?: boolean;
  clearning?: boolean;
  code: number | undefined;
  handlePrevent?: Function | any;
  closeChecklist?: Function | any;
  reservationId?: number | undefined;
}

export const CheckList = ({
  code,
  mobile,
  checkin,
  checkout,
  clearning,
  handlePrevent,
  closeChecklist,
  reservationId,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [allDataChecklist, setAllDataChecklist] = useLocalStorage<ChecklistDataProps>(`data for ${code}`, data);
  const [notes, setNotes] = useState(allDataChecklist.notes);
  const [page, setPage] = useState(allDataChecklist.questionList);
  const [concluded, setConcluded] = useState(allDataChecklist.concluded);
  const [informationCheck, setInformationCheck] = useState(false);
  const [progress, setProgress] = useState(0);
  const [started, setStarted] = React.useState(false);
  const [allCheckedHasChecked, setAllCheckedHasChecked] = useState(false);
  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const { setFinishChecklist } = useChecklist();
  const [checked, setChecked] = useState<boolean[]>(data.questionList
    .map((items) => items.questions.map((item) => item.checked || false))
    .flat(1));

  const [loading, setLoading] = useState(true);

  const clearChecklistLocalStorage = () => {
    localStorage.removeItem(`data for ${code}`);
  };

  const resetState = () => {
    setAllDataChecklist(data);
    setPage(data.questionList);
    setChecked(data.questionList.map((items) => items.questions.map(() => false)).flat(1));
  };

  const handleGetChecklistById = async () => {
    let response = null;
    try {
      if (reservationId) {
        response = await getChecklistById(
          reservationId,
          checkin,
          checkout,
          clearning,
        );
        if (
          response.checklist !== null
          && response
          && response.checklist.questionList.length !== 0
        ) {
          setAllDataChecklist(response.checklist);
          setNotes(response.checklist.notes);
          setPage(response.checklist.questionList);
          setChecked(response.checklist.questionList
            .map((items) => items.questions.map((item) => item.checked))
            .flat(1));
          setConcluded(true);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else if (Object.keys(response?.checklist).length === 0) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
    } catch (err) {
      if (response && response.checklist !== null && Object.keys(response.checklist).length === 0) {
        setConcluded(true);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setConcluded(false);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
    try {
      if (checkin) {
        const checkinList: Checkin[] = await getCheckin(reservationId);
        if (checkinList[0].check_in_time && checkinList[0].was_contacted) {
          setInformationCheck(true);
        }
      } else if (checkout) {
        const checkoutList: Checkout[] = await getCheckout(reservationId);
        if (checkoutList[0].check_out_time && checkoutList[0].was_contacted) {
          setInformationCheck(true);
        }
      } else {
        const clearningtList: Clearning[] = await getClearning(reservationId);
        if (clearningtList[0].cleaning_date) {
          setInformationCheck(true);
        }
      }
    } catch (e) {
      setInformationCheck(false);
    }
  };

  useEffect(() => {
    resetState();

    if (reservationId) {
      handleGetChecklistById();
    }

    setTimeout(() => {
      clearChecklistLocalStorage();
    }, 10000);
  }, []);

  const handlePostChecklist = async () => {
    try {
      if (reservationId) {
        const newAllDataChecklist = {
          ...allDataChecklist,
          concluded: informationCheck,
          notes,
          questionList: allDataChecklist.questionList.map((item: any) => ({
            ...item,
            questions: item.questions.map((question: any) => ({
              ...question,
              checked: checked[question.id],
            })),
          })),
        };
        setAllDataChecklist(newAllDataChecklist);
        await postChecklist(
          reservationId,
          checkin,
          checkout,
          // clearning,
          newAllDataChecklist,
        );
        setFinishChecklist(true);
        toast.success('O Checklist foi finalizado com sucesso !');
        closeChecklist();
        clearChecklistLocalStorage();
      }
    } catch (err) {
      toastErrorRequest(err);
      closeChecklist();
      resetState();
    }
  };

  const handlePutChecklist = async () => {
    try {
      if (reservationId) {
        const id = reservationId;
        const newAllDataChecklist = { ...allDataChecklist };
        newAllDataChecklist.concluded = informationCheck;
        setAllDataChecklist(newAllDataChecklist);
        await putChecklist(
          id,
          checkin,
          checkout,
          // clearning,
          newAllDataChecklist,
        );
        setFinishChecklist(true);
        toast.success('O Checklist foi atualizado com sucesso !');
        closeChecklist();
        clearChecklistLocalStorage();
      }
    } catch (err) {
      toastErrorRequest(err);
    }
  };

  useEffect(() => {
    setPage(allDataChecklist.questionList);
    setProgress((currentPage / page.length) * 100);
  }, [currentPage]);

  useEffect(() => {
    if (progress === 100) {
      setAllCheckedHasChecked(true);
    } else {
      setAllCheckedHasChecked(false);
    }
  }, [progress]);

  useEffect(() => {
    const newAllDataChecklist = { ...allDataChecklist };
    newAllDataChecklist.notes = notes;
    setAllDataChecklist(newAllDataChecklist);
  }, [notes]);

  useEffect(() => {
    const newAllDataChecklist = { ...allDataChecklist };
    newAllDataChecklist.concluded = concluded;
    setAllDataChecklist(newAllDataChecklist);
  }, [concluded]);

  const escKey = useKeys('Escape');

  useEffect(() => {
    if (escKey) {
      closeChecklist();
    }
  }, [escKey]);

  return (
    <>
      {loading ? (
        <Container mobile={mobile} onClick={handlePrevent}>
          <ChecklistShimmer />
        </Container>
      ) : (
        <Container mobile={mobile} onClick={handlePrevent}>
          <Header>
            {checkin && <Title>Realizar Checkin</Title>}
            {checkout && <Title>Realizar Checkout</Title>}
            {clearning && <Title>Realizar Limpeza</Title>}
            <CloseButton onClick={closeChecklist}>x</CloseButton>
          </Header>
          <Content mobile={mobile}>
            <MainContainer mobile={mobile}>
              <MainContent
                page={page}
                mobile={mobile}
                started={started}
                checked={checked}
                setPage={setPage}
                setChecked={setChecked}
                currentPage={currentPage}
                finish={allCheckedHasChecked}
                checkin={checkin}
                checkout={checkout}
                clearning={clearning}
                notes={notes}
                setNotes={setNotes}
              />
            </MainContainer>
            <LateralContainer mobile={mobile}>
              <LateralContent
                content={data.questionList}
                started={started}
                finish={allCheckedHasChecked}
                setCurrentPage={setCurrentPage}
                setStarted={setStarted}
                page={page[currentPage]}
              />
            </LateralContainer>
          </Content>
          <ProgressBarContainer>
            <ProgressBar progress={progress} />
          </ProgressBarContainer>
          <Footer
            concluded={concluded}
            page={page}
            mobile={mobile}
            started={started}
            close={closeChecklist}
            setStarted={setStarted}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            finish={progress === 100 && !page[currentPage]}
            handlePutChecklist={handlePutChecklist}
            handlePostChecklist={handlePostChecklist}
          />
        </Container>
      )}
    </>
  );
};

CheckList.defaultProps = {
  closeChecklist: () => {},
  handlePrevent: () => {},
  checkin: false,
  checkout: false,
  clearning: false,
  mobile: false,
  reservationId: undefined,
};
