import request from '../request';
import { CreateCategoryProps, RequestCategory } from './types';

export const getCategories = async (): Promise<Array<RequestCategory>> => {
  const { data } = await request.get('/categories/');
  return data as RequestCategory[];
};

export const postCategory = async (payload: CreateCategoryProps): Promise<RequestCategory> => {
  const { data } = await request.post<RequestCategory>('/categories/', payload);
  return data;
};
